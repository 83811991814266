import React, { useEffect, useState } from "react"

import { ModalFormComponent } from "Components/Modal"
import { ButtonFaded, ButtonNotifi } from "Components/Button"
import SpinComponent from "Components/Spin"

import { CancelModalProps, CancelModalStatus } from "./types"
import { textByStatus } from "./constants"
import { useRequest } from "api/hooks"
import { QiwiTableStatus } from "../Table/types"

const CancelModal = ({ id, refreshTable, close }: CancelModalProps) => {
  const [status, changeStatus] = useState<CancelModalStatus>(
    CancelModalStatus.check
  )
  const text = textByStatus[status]
  const { request: checkStatus } = useRequest({
    url: textByStatus[CancelModalStatus.check].url,
    requestBody: { id }
  })
  const { request: cancelOrder } = useRequest({
    url: textByStatus[CancelModalStatus.cancel].url,
    requestBody: { id }
  })
  const [cancelPending, togglePending] = useState(false)
  useEffect(() => {
    checkStatus().then(({ data }: { data: QiwiTableStatus }) => {
      if (data == QiwiTableStatus.approved) {
        changeStatus(CancelModalStatus.block)
      } else {
        changeStatus(CancelModalStatus.cancel)
      }
    })
  }, [])
  return (
    <ModalFormComponent withoutCross={true} width={480}>
      <div className="QiwiCancelModalWrapper FlexColumn">
        <span className="PaddingBottom16 HeadlineSecondText">{text.title}</span>
        <span className="PaddingBottom24 TextTwo">{text.description}</span>
        <div className="MarginLeftAuto FlexRow Gap8 QiwiCancelModalButtons">
          {!!text.close && (
            <ButtonFaded onClick={close}>
              <span className="ButtonTextFirst">{text.close}</span>
            </ButtonFaded>
          )}
          {!!text.cancel && (
            <ButtonNotifi
              onClick={() => {
                togglePending(true)
                cancelOrder().then(() => {
                  refreshTable()
                  close()
                })
              }}
              color="red"
            >
              <span className="ButtonTextFirst">{text.cancel}</span>
            </ButtonNotifi>
          )}
          {((!text.close && !text.cancel) || cancelPending) && (
            <SpinComponent />
          )}
        </div>
      </div>
    </ModalFormComponent>
  )
}

export default CancelModal
