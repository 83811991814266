import React, { useMemo, useState } from "react"

import DashboardComponent from "Components/DashboardNew"
import TooltipComponent from "Components/Tooltip"
import { ReactComponent as InfoIcon } from "Components/icons/help_24.svg"
import { ModalRightDetail } from "Components/Modal"

import { GuestsHistoryReportData } from "../types"
import { ReactComponent as DateStick } from "../img/date_stick.svg"

import { GuestHistoryCardText } from "./constants"
import GuestsHistoryComment from "./Comments"
import { GuestStatus } from "./helper"
import FreebetsDetail from "./Freebets"

const GuestHistoryCard = ({
  data,
  phone
}: {
  data: GuestsHistoryReportData
  phone?: string
}) => {
  const [freebetsModal, toggleFreebetsModal] = useState(false)
  const open = () => toggleFreebetsModal(true)
  const guestText = useMemo(() => GuestHistoryCardText(data, open), [data])

  return (
    <div className="GuestHistoryCardWrapper FlexRow FlexWrap">
      <div className="GuestHistoryCardInfo">
        <div className="GuestHistoryCardInfoHeader FlexRow FlexWrap">
          <div className="GuestHistoryCardInfoHeaderTitle FlexRow">
            {data.name}
          </div>
          <GuestStatus status={data.status} />
        </div>
        <div className="GuestHistoryCardInfoDescription FlexRow">
          <TooltipComponent
            title={guestText.walletStatus.label}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            direction={guestText.walletStatus.direction}
            className="GuestHistoryCardInfoDescriptionWalletStatusTooltip"
          >
            <div
              className={`GuestHistoryCardInfoDescriptionWalletStatus ${
                data.wallet.active
                  ? `GuestHistoryCardInfoDescriptionActiveWallet`
                  : `GuestHistoryCardInfoDescriptionInactiveWallet`
              }`}
            />
          </TooltipComponent>
          {guestText.wallet}
        </div>
        <GuestsHistoryComment
          comments={data.comments_history}
          current={data.comment}
          phone={phone}
        />
        <DashboardComponent config={guestText.dashboards} />
      </div>
      <div className="GuestHistoryCardDate FlexRow">
        <div className="GuestHistoryCardDateData">
          <div className="GuestHistoryCardDateTitle HeadlineThirdText">
            <span>{guestText.visitTitle}</span>
            <div className="GuestHistoryCardDateTitleTooltip">
              <TooltipComponent title={guestText.visitTitleTooltip}>
                <InfoIcon />
              </TooltipComponent>
            </div>
          </div>
          <div className="GuestHistoryCardDateDataTitleWrapper">
            <div className="GuestHistoryCardDateDataTitle HeadlineThirdText">
              {guestText.lastVisitValue}
            </div>
            <div className="GuestHistoryCardDateDataDescription TextDefault">
              {guestText.lastVisit}
            </div>
          </div>
          <div className="GuestHistoryCardDateDataGrey">
            <div>
              <div className="GuestHistoryCardDateDataTitle HeadlineThirdText">
                {guestText.countTogether}
              </div>
              <div className="GuestHistoryCardDateDataDescription TextDefault">
                {guestText.together}
              </div>
            </div>
            <div>
              <div className="GuestHistoryCardDateDataTitle HeadlineThirdText">
                {guestText.countNotVisit}
              </div>
              <div className="GuestHistoryCardDateDataDescription TextDefault">
                {guestText.notVisit}
              </div>
            </div>
          </div>
          <div className="GuestHistoryCardDateDataTitleWrapper">
            <div className="GuestHistoryCardDateDataTitle HeadlineThirdText">
              {guestText.firstVisitValue}
            </div>
            <div className="GuestHistoryCardDateDataDescription TextDefault">
              {guestText.firstVisit}
            </div>
          </div>
        </div>
        <div className="GuestHistoryCardDateStick">
          <DateStick />
        </div>
      </div>
      {freebetsModal && (
        <ModalRightDetail close={() => toggleFreebetsModal(false)}>
          <FreebetsDetail
            data={data.dashboards.freebets}
            sum={data.dashboards.freebet}
          />
        </ModalRightDetail>
      )}
    </div>
  )
}

export default GuestHistoryCard
