import React, { useEffect, useState } from "react"

import { useRequest, useRequestParams } from "api/hooks"
import { parseOneDateWithoutGmtToBack } from "constants/index"

import EmptyTable from "Components/EmptyTable"

import { dataEmptyChart } from "Containers/GuestsHistory/constants"

import GuestHistoryBetsBySum from "./chart"
import { initialData } from "./constants"
import {
  BetsBySumByGameState,
  BetsBySumDataRequest,
  BetsBySumDataSeries,
  BetsBySumState,
  BetsChartProps
} from "./types"

const BetsChart = ({ filter, activeBest, activeWins }: BetsChartProps) => {
  const [chartData, setChartData] = useState<BetsBySumState>({
    pending: false,
    data: initialData
  })

  const [chartDataByGames, setChartDataByGames] = useState<
    BetsBySumByGameState
  >({})

  const { requestCurrentHall } = useRequestParams()
  const { request: getChartsData } = useRequest({
    url: `v1/guests/history/bets/sum`,
    requestBody: {
      hall: requestCurrentHall,
      ...filter,
      dates: !!filter.dates
        ? parseOneDateWithoutGmtToBack(filter.dates)
        : undefined,
      onlyActiveDays: undefined
    },
    method: `POST`
  })

  const requestDeps = JSON.stringify(filter)

  useEffect(() => {
    setChartData(prevState => ({ ...prevState, pending: true }))
    getChartsData()
      .then(({ data }: { data: BetsBySumDataRequest[] }) => {
        const initialSeries = initialData.series
        const initialCategories = []

        const initialBets: BetsBySumDataSeries = {
          ...initialSeries[0],
          data: []
        }
        const initialWins: BetsBySumDataSeries = {
          ...initialSeries[1],
          data: []
        }
        const initialCross: BetsBySumDataSeries = {
          ...initialSeries[2],
          data: []
        }
        if (!!data && !!data?.length) {
          for (let i = 0; i < data?.length; i++) {
            const bet = Number(data[i]?.data.bet_sum)
            const win = Number(data[i]?.data.win_sum)
            initialBets.data.push(bet)
            initialWins.data.push(win)
            initialCross.data.push(bet > win ? win : bet)
            initialCategories.push(data[i]?.game)

            setChartDataByGames(prevState => ({
              ...prevState,
              [`${data[i].game}`]: data[i].data
            }))
          }
        }
        setChartData({
          pending: false,
          data: {
            series: [initialBets, initialWins, initialCross],
            categories: initialCategories
          }
        })
      })
      .catch(() => setChartData(prevState => ({ ...prevState, pending: true })))
  }, [requestDeps])

  return (
    <div className="GuestHistoryBetsBySumWrapper">
      {!!chartData?.data?.categories?.length && (
        <GuestHistoryBetsBySum
          chartDataByGames={chartDataByGames}
          categories={chartData.data.categories}
          isFreebetActive={filter.includeFreebets}
          bets={
            activeBest
              ? chartData.data.series[0]
              : { ...chartData.data.series[0], data: [] }
          }
          wins={
            activeWins
              ? chartData.data.series[1]
              : { ...chartData.data.series[1], data: [] }
          }
          cross={
            activeWins && activeBest
              ? chartData.data.series[2]
              : { ...chartData.data.series[2], data: [] }
          }
        />
      )}
      {!chartData?.data?.categories?.length && !chartData.pending && (
        <div className="GuestHistoryEmptyDataWrapper">
          <EmptyTable
            mobile={dataEmptyChart.mobile}
            desktop={dataEmptyChart.desktop}
          />
        </div>
      )}
    </div>
  )
}

export default BetsChart
