import React, { useEffect, useRef, useState } from "react"
import {
  deleteMsg,
  editMsg,
  staffDeletePromptMsg
} from "Containers/Contacts/constants"
import { ReactComponent as MoreIcon } from "Components/icons/more_28.svg"
import { ReactComponent as EditIcon } from "Components/icons/edit_grey.svg"
import { ReactComponent as DeleteIcon } from "Components/icons/delete_red.svg"
import {
  ContactsStateDataBlocksStaffSingleProps,
  ContactsStateProps,
  showConfirmProps
} from "Containers/Contacts/types"

const StaffEditMenu = ({
  contactsState,
  setContactsState,
  showConfirm,
  record
}: {
  contactsState: ContactsStateProps
  setContactsState: (val: ContactsStateProps) => void
  record: ContactsStateDataBlocksStaffSingleProps
  showConfirm: ({ msg, newState }: showConfirmProps) => void
}) => {
  const [showActions, setShowActions] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  /* Для клика вне элемента, чтобы закрыть меню с выбором действий */

  useEffect(() => {
    const checkIfClickedOutside = ({ target }: MouseEvent) => {
      if (
        showActions &&
        ref &&
        ref.current &&
        !ref.current.contains(target as Node)
      ) {
        setShowActions(false)
      }
    }
    document.addEventListener("click", checkIfClickedOutside)
    return () => {
      document.removeEventListener("click", checkIfClickedOutside)
    }
  }, [showActions])

  return (
    <div className="ContactsEdit_actionsModal_trigger">
      <div>
        <MoreIcon onClick={() => setShowActions(true)} />
      </div>
      <div
        ref={ref}
        className={`ContactsEdit_actionsModal ${
          showActions
            ? `ContactsEdit_actionsModalActive`
            : `ContactsEdit_actionsModalHidden`
        }`}
      >
        <div
          className="ContactsEdit_actionsModal_line"
          onClick={() => {
            setContactsState({
              ...contactsState,
              isBlockEditing: false,
              isStaffEditing: true,
              editStaffId: record.staff_id
            })
          }}
        >
          <EditIcon />
          <span>{editMsg}</span>
        </div>
        <div
          className="ContactsEdit_actionsModal_line"
          onClick={() => {
            showConfirm({
              msg: staffDeletePromptMsg(record.name),
              newState: {
                ...contactsState,
                url: `v1/admin/contacts/stuff/${record.staff_id}`,
                method: `DELETE`,
                staffPending: true,
                editStaffId: record.staff_id
              }
            })
          }}
        >
          <DeleteIcon />
          {deleteMsg}
        </div>
      </div>
    </div>
  )
}

export default StaffEditMenu
