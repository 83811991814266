import { Moment } from "moment"
import { CheckboxValueType } from "antd/es/checkbox/Group"

export enum SuperwinReportTabs {
  MANAGE = `MANAGE`,
  HISTORY = `HISTORY`,
  WINS_HISTORY = `WINS_HISTORY`
}

export interface SuperwinFilter {
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
}

export type SuperwinTitleProps = {
  filter?: SuperwinFilterHistoryData
  isWin?: boolean
}

export type SuperwinFilterManageData = {
  halls?: number[]
}

export interface SuperwinFilterManage extends SuperwinFilter {
  data: SuperwinFilterManageData
}

export type SuperwinFilterHistoryData = {
  hall?: number
  halls?: CheckboxValueType[]
  dates?: { from: Moment; to: Moment } | [Moment, Moment]
  nominals?: number[]
  gmt?: string
}

export interface SuperwinFilterHistory extends SuperwinFilter {
  data: SuperwinFilterHistoryData
}

export type SuperwinReportState = {
  activeTab: SuperwinReportTabs
  toggleActiveTab: (val: SuperwinReportTabs) => void
  isSuperWinsHistory?: boolean
}
