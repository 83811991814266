import React from "react"
import ModalComponent from "Components/Modal"
import { ActiveTabs, SmsSuccessModalProps } from "../types"
import { smsConstants } from "../constants"
import { ReactComponent as CloseIcon } from "Components/icons/close.svg"
import { ReactComponent as SuccessIcon } from "Components/icons/ok_desktop.svg"

const SmsSuccessModal = ({
  setSuccessModal,
  smsState,
  setActiveTab,
  setSmsState
}: SmsSuccessModalProps) => {
  return (
    <ModalComponent
      close={() => {
        setSuccessModal(false)
        setActiveTab(ActiveTabs.history)
        setSmsState({
          ...smsState,
          ...smsConstants.smsStateInitWithoutHallId,
          smsSettings: smsState.smsSettings
        })
      }}
      width={smsConstants.smsModalWidth}
      closeIcon={<CloseIcon />}
      className="SMSSuccessModal SMSCreateModal"
    >
      <div className="SMSCreate_SuccessWrapper">
        <SuccessIcon />
        <div className="SMSCreate_SuccessTitle">
          {smsConstants.modalSuccessText}
        </div>
      </div>
    </ModalComponent>
  )
}

export default SmsSuccessModal
