import NotActiveFilter from "Components/NotActiveFilter"
import { ReactComponent as EmptyIcon } from "./icons/empty_table.svg"
import {
  EmptyTableProps,
  GetInitialFilterStateProps,
  NoncashFilter,
  NoncashFilterData,
  UseFilterProps
} from "./types"
import { getFilterStateFromLocalStorage } from "api/helpers"
import { isEmpty } from "lodash"
import { useRequestParams } from "api/hooks"
import React, { useState } from "react"
import { shiftMoscowGmtFirst } from "../../../constants"

export const initialFilterData: NoncashFilterData = {
  halls: [],
  dates: null,
  isShift: true,
  shift: [8, 8],
  gmt: `3`
}

export const EmptyFilter = ({ title, description }: EmptyTableProps) => (
  <NotActiveFilter
    title={title}
    description={description}
    icon={<EmptyIcon />}
  />
)

export const getInitialFilterState = ({
  storage,
  current_hall,
  locations
}: GetInitialFilterStateProps) => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage({}, storage)
  return {
    isOpen: true,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      ...initialFilterData,
      halls: current_hall,
      gmt: locations[0].gmt,
      shift: shiftMoscowGmtFirst({ gmt: locations[0].gmt }),
      ...initFilterStateFromStorage
    },
    mobileFilter: false
  }
}

export const useFilter = ({ storage }: UseFilterProps) => {
  const { current_hall, locations } = useRequestParams()
  const initFilterState = getInitialFilterState({
    current_hall,
    locations,
    storage
  })
  const [filterState, setFilterState] = useState<NoncashFilter>(initFilterState)

  return { filterState, setFilterState }
}
