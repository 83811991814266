import React from "react"
import { ConfigItem, WikiMenuItem, WikiOptions } from "./types"
import { MenuOptionsType } from "store/menu/types"
import { BreadcrumbItem } from "Components/Breadcrumbs/types"
import { Plugin } from "unified"
import { Node, Parent } from "unist"
import { Element } from "hast"
import { baseLayoutText, WIKI } from "../BaseLayout/constants"
import { ReactComponent as Home } from "./icons/home_br.svg"
import { SummaryPluginOptions, FlatConfig } from "./types"

const isElement = (node: Node): node is Element => {
  return node.type === "element"
}

const isParent = (node: Node): node is Parent => {
  return "children" in node
}

function isTextNode(node: Node): node is { type: "text"; value: string } {
  return node.type === "text"
}

export const rehypeSummaryPlugin: Plugin<[SummaryPluginOptions]> = options => {
  return (tree: Node) => {
    let summaryCounter = 0
    function processNode(node: Node) {
      if (isElement(node) && node.tagName === "summary") {
        const id = `summary-anchor-${summaryCounter}`
        summaryCounter += 1

        const textNode = node.children.find(isTextNode)
        const summaryText = textNode ? textNode.value : ""

        node.properties = {
          ...node.properties,
          id
        }

        options.onSummaryAnchorCreated(id, summaryText)
      }

      if (isParent(node)) {
        node.children.forEach(processNode)
      }
    }

    processNode(tree)
  }
}

export const buildConfigFromWikiMenu = (
  WikiMenu: WikiMenuItem[],
  config: ConfigItem[]
): { newConfig: MenuOptionsType[]; options: WikiOptions } => {
  // updated return type
  const newConfig: ConfigItem[] = []
  const options: WikiOptions = []

  for (const item of config) {
    if (
      item.route &&
      WikiMenu.some(menu => menu.associativeLink === item.route)
    ) {
      const menuItem = WikiMenu.find(
        menu => menu.associativeLink === item.route
      )
      if (menuItem) {
        options.push({
          label: menuItem.name,
          value: menuItem.associativeLink,
          filesLink: menuItem.filesUrl
        })
      }
      newConfig.push({ ...item, route: `${WIKI}${item.route}` })
    } else if (item.inside) {
      const {
        newConfig: nestedItems,
        options: nestedOptions
      } = buildConfigFromWikiMenu(
        // added options property
        WikiMenu,
        item.inside
      )
      if (nestedItems.length > 0) {
        newConfig.push({
          ...item,
          inside: nestedItems
        })
        options.push(...nestedOptions) // add nested options to parent options array
      }
    }
  }

  return { newConfig, options } // return object with both newConfig and options arrays
}

export const findBreadcrumbPath = (
  configItems: ConfigItem[],
  url: string,
  path: BreadcrumbItem[] = [
    {
      label: baseLayoutText.lkWiki,
      url: WIKI,
      isLink: false,
      icon: <Home className="WikiHomeBreadcrumb" />
    }
  ]
): BreadcrumbItem[] | null => {
  for (const item of configItems) {
    if (item.route === url || item?.name === url?.replace(/-/g, " ")) {
      return [
        ...path,
        { label: item.name, url: item.route || url, isLink: item.target }
      ]
    }

    if (item.inside) {
      const newPath = findBreadcrumbPath(item.inside, url, [
        ...path,
        { label: item.name, url: item.url as string, isLink: item.target }
      ])
      if (newPath) {
        return newPath
      }
    }
  }

  return null
}

function flattenConfigItems(
  items: ConfigItem[],
  flatItems: FlatConfig[] = []
): FlatConfig[] {
  for (const item of items) {
    // eslint-disable-next-line
    const { inside, ...flatItem } = item
    if (!item.url) {
      item.url = encodeURIComponent(item.name.replace(/ /g, "-"))
    }
    flatItems.push(flatItem as FlatConfig)
    if (item.inside) {
      flattenConfigItems(item.inside, flatItems)
    }
  }

  return flatItems
}

export function findInsideElementsByName(
  items: ConfigItem[],
  passedName: string
): { tree: ConfigItem[]; flat: FlatConfig[] } | null {
  for (const item of items) {
    if (item.name === passedName) {
      const tree = item.inside || []
      const flat = flattenConfigItems(tree)
      return { tree, flat }
    }

    if (item.inside) {
      const result = findInsideElementsByName(item.inside, passedName)
      if (result) {
        return result
      }
    }
  }

  return null
}
