import React from "react"
import TooltipComponent from "Components/Tooltip"
import { SelectComponent } from "Components/Select"
import { smsConstants } from "../../constants"
import { getSubTemplateOptions, getTemplateOptions } from "./step1Helper"
import { CreateStepProps } from "../../types"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"

const Step1 = ({
  smsState,
  setSmsState,
  templateSmsTextSelected
}: CreateStepProps) => {
  /* Опции селекта Подтипа шаблона */
  const templateOptions = getTemplateOptions(smsState.smsSettings.types)
  const subTemplateOptions = getSubTemplateOptions(smsState)

  return (
    <div className="CreateSms_step">
      <div className="CreateSms_stepTitle">
        <span>{smsConstants.step1.orderNumber}</span>
        <span>{smsConstants.step1.title}</span>
        <TooltipComponent title={smsConstants.step1.tooltip} direction="bottom">
          <InfoIcon />
        </TooltipComponent>
      </div>

      <section className="CreateSms_inputWrapper">
        <span className="CreateSms_inputLabel">
          {smsConstants.modalTypeText}
        </span>
        <SelectComponent
          greyBackground
          className={`CreateSms_templateInput ${!templateSmsTextSelected &&
            `CreateSms_templateInput_prefix`}`}
          dropdownClassName="CreateSms_templateInput_Dropdown"
          placeholder={smsConstants.step1.templatePlaceholder}
          allowClear={false}
          value={smsState.selectedTypeId}
          onChange={value => {
            setSmsState({
              ...smsState,
              ...smsConstants.paramsToResetSmsState,
              extraParams: {},
              selectedTypeId: value,
              selectedSubTypeId: null,
              selectedTextId: 0,
              customSmsText: undefined
            })
          }}
        >
          {templateOptions}
        </SelectComponent>
      </section>

      {!!smsState.selectedTypeId && !!subTemplateOptions.length && (
        <section className="CreateSms_inputWrapper">
          <span className="CreateSms_inputLabel">
            {smsConstants.modalSubTypeText}
          </span>
          <SelectComponent
            greyBackground
            className="CreateSms_subTemplateInput"
            placeholder={smsConstants.step1.subTypeTitle}
            allowClear={true}
            value={smsState.selectedSubTypeId}
            options={subTemplateOptions}
            onChange={value => {
              setSmsState({
                ...smsState,
                ...smsConstants.paramsToResetSmsState,
                extraParams: {},
                selectedSubTypeId: value,
                selectedTextId: 0,
                customSmsText: undefined
              })
            }}
          />
        </section>
      )}
    </div>
  )
}

export default Step1
