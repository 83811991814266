export type TabsComponentProps = {
  tabs: {
    label: string
    value: string | number
    disabled?: boolean
  }[]
  activeTab: string | number
  toggleActiveTab: (value: string | number) => void
}

export enum TableWidth {
  default = 920
}

export enum TableColumnWidth {
  xs = 140,
  s = 150
}
