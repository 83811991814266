import React from "react"

import { isMobile } from "constants/index"

import { defaultEmptyData, initialData } from "./helper"
import {
  EmptyTableDataProps,
  EmptyTableProps,
  EmptyTableVersion
} from "./types"

import { ReactComponent as EmptyDataIcon } from "./icons/empty_data.svg"
import { ReactComponent as EmptyDataSmallIcon } from "./icons/empty_data_small.svg"
import { ReactComponent as ReportIcon } from "../icons/report_outline_24.svg"

export const EmptyTableLine = ({ description }: { description: string }) => {
  return (
    <div className="EmptyTableLineWrapper GridCenter">
      <div className="FlexRow">
        <ReportIcon />
        <div className="TextDefault MarginLeft8 Gray600Color">
          {description}
        </div>
      </div>
    </div>
  )
}

export const EmptyData = ({
  title,
  subtitle,
  isSmall,
  pending,
  titleClass,
  className = ``,
  icon
}: EmptyTableDataProps) => {
  const defaultIcon =
    !!isSmall || isMobile ? <EmptyDataSmallIcon /> : <EmptyDataIcon />
  return (
    <div>
      {pending ? (
        <div className="EmptyTablePendingSpin" />
      ) : (
        <div className={`GridCenter ${className}`}>
          {icon || defaultIcon}
          <div className={titleClass || `EmptyTableTitle`}>
            {title || defaultEmptyData.title}
          </div>
          <div className="EmptyTableLabel">
            {subtitle || defaultEmptyData.subtitle}
          </div>
        </div>
      )}
    </div>
  )
}

const EmptyTable = (data: EmptyTableProps) => {
  const useSource = !!data.desktop ? data : initialData
  const currentVersion = isMobile
    ? EmptyTableVersion.MOBILE
    : EmptyTableVersion.DESKTOP
  return (
    <div className="GridCenter">
      {useSource[currentVersion]?.icon || initialData[currentVersion]?.icon}
      <div className="EmptyTableTitle">{useSource[currentVersion]?.title}</div>
      <div className="EmptyTableLabel">
        {useSource[currentVersion]?.subtitle}
      </div>
    </div>
  )
}

export default EmptyTable
