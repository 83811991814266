import { parseDateToBack } from "../../constants"
import betRequest from "api/request"
import moment from "moment"
import {
  ChartType,
  DashboardReport,
  GetDashboards,
  GetRegStat,
  GetVisit,
  GetVisitHours,
  MainFilter,
  RegStatReport,
  VisitAndRegsReport,
  VisitHoursChart,
  VisitHoursReport
} from "./types"
import { textConstants } from "./constants"

export const getVisitData = ({
  reportState,
  setReportState,
  filterState,
  logout,
  history
}: GetVisit) => {
  setReportState({
    ...reportState,
    pending: true
  })

  const { regUrl, visitUrl } = textConstants.visitAndRegsUrls

  betRequest({
    method: `POST`,
    url: !!filterState.data.onlyReg ? regUrl : visitUrl,
    requestBody: {
      ...filterState.data,
      hall: filterState.data.hall,
      shift: filterState.data.isShift
        ? filterState.data.shift[0]
        : filterState.data.shift,
      dates: parseDateToBack({
        date: filterState.data.dates,
        gmt: textConstants.gmtDefault
      })
    },
    history,
    logout
  })
    .then(({ data }: { data: VisitAndRegsReport }) => {
      setReportState({
        ...reportState,
        pending: false,
        chart: data.chart
      })
    })
    .catch(() =>
      setReportState({
        ...reportState,
        pending: false
      })
    )
}

export const getRegStatData = ({
  regStatState,
  setRegStatState,
  filterState,
  logout,
  history
}: GetRegStat) => {
  setRegStatState({
    ...regStatState,
    pending: true
  })

  betRequest({
    method: `POST`,
    url: textConstants.visitAndRegsUrls.regStatUrl,
    requestBody: {
      ...filterState.data,
      hall: filterState.data.hall,
      shift: filterState.data.isShift
        ? filterState.data.shift[0]
        : filterState.data.shift,
      dates: parseDateToBack({
        date: filterState.data.dates,
        gmt: textConstants.gmtDefault
      })
    },
    history,
    logout
  })
    .then(({ data }: { data: RegStatReport }) => {
      setRegStatState({
        ...regStatState,
        pending: false,
        chart: data.chart
      })
    })
    .catch(() =>
      setRegStatState({
        ...regStatState,
        pending: false
      })
    )
}

export const getVisitHoursData = ({
  filterState,
  visitHoursState,
  setVisitHoursState,
  logout,
  history
}: GetVisitHours) => {
  setVisitHoursState({
    ...visitHoursState,
    pending: true
  })

  betRequest({
    method: `POST`,
    url: textConstants.visitAndRegsUrls.visitHoursUrl,
    requestBody: {
      hall: filterState.data.hall,
      chart_days: visitHoursState.chart_days
    },
    history,
    logout
  })
    .then(({ data }: { data: VisitHoursReport }) => {
      setVisitHoursState({
        ...visitHoursState,
        pending: false,
        chart: data.chart,
        rushhour_weekday: data.rushhour_weekday
      })
    })
    .catch(() =>
      setVisitHoursState({
        ...visitHoursState,
        pending: false
      })
    )
}

export const getDashboardsData = ({
  filterState,
  setDashBoardState,
  dashBoardState,
  logout,
  history
}: GetDashboards) => {
  setDashBoardState({
    ...dashBoardState,
    pending: true
  })

  betRequest({
    method: `POST`,
    url: textConstants.visitAndRegsUrls.dashboards,
    requestBody: {
      hall: filterState.data.hall,
      shift: filterState.data.shiftDashboards
    },
    history,
    logout
  })
    .then(({ data }: { data: DashboardReport }) => {
      setDashBoardState({
        ...dashBoardState,
        ...data,
        pending: false
      })
    })
    .catch(() =>
      setDashBoardState({
        ...dashBoardState,
        pending: false
      })
    )
}

/* Определяет время с максимальным посещением.
 * Если нет макисмума возвращает -1
 */
export const findMostVisitedHour = (
  visitHoursState: VisitHoursReport
): number | string => {
  const chart = visitHoursState.chart[visitHoursState.day] ?? undefined
  if (!chart) return -1

  const max = chart.reduce((prev, current) => {
    return prev.count > current.count ? prev : current
  })
  /* Проверим есть ли еще значения с таким макисмумом */
  const count = chart.filter(item => item.count === max.count).length

  /* Если  */
  return count > 1 ? -1 : max.hour
}

export const getVisitPeriodFormat = (period: string) => {
  switch (period) {
    case textConstants.period.hourly:
      return { name: `HH:mm` }
      break
    case textConstants.period.daily:
      return { name: `DD.MM` }
      break
    case textConstants.period.monthly:
      return { name: `MMMM` }
      break
    case textConstants.period.yearly:
      return { name: `YYYY` }
      break
    default:
      return { name: `DD.MM` }
  }
}

export const getVisitChartType = (period: string) => {
  switch (period) {
    case textConstants.period.hourly:
      return ChartType.column
    case textConstants.period.daily:
    case textConstants.period.monthly:
    case textConstants.period.yearly:
      return ChartType.area
    default:
      return ChartType.area
  }
}

export const getRegColumnColor = (type: string) => {
  switch (type) {
    case textConstants.visitsType.all:
      return `#66D9E8`
    case textConstants.visitsType.new:
      return `#FFC078`
    case textConstants.visitsType.unique:
      return `#91A7FF`
    default:
      return `#66D9E8`
  }
}

export const getRegHoverColor = (type: string) => {
  switch (type) {
    case textConstants.visitsType.all:
      return `#3BC9DB`
    case textConstants.visitsType.new:
      return `#FFA94D`
    case textConstants.visitsType.unique:
      return `#748FFC`
    default:
      return `#3BC9DB`
  }
}

export const getVisitColumnColor = (type: string) => {
  switch (type) {
    case textConstants.visitsByGames.all:
      return `#66D9E8`
    case textConstants.visitsByGames.sport:
      return `#8CE99A`
    case textConstants.visitsByGames.tennis:
      return `#74C0FC`
    default:
      return `#66D9E8`
  }
}

export const getVisitHoverColor = (type: string) => {
  switch (type) {
    case textConstants.visitsByGames.all:
      return `#22B8CF`
    case textConstants.visitsByGames.sport:
      return `#69DB7C`
    case textConstants.visitsByGames.tennis:
      return `#4DABF7`
    default:
      return `#4DABF7`
  }
}

export const isEmptyPeaksChart = (chart: VisitHoursChart[]) => {
  if (chart.length === 0) return true
  let isEmpty = true
  chart.forEach(allDaysChart => {
    allDaysChart.forEach(dayChart => {
      if (dayChart.count !== 0) {
        isEmpty = false
      }
    })
  })

  return isEmpty
}

export const isDisabledFilter = (filter: MainFilter): boolean => {
  if (!filter.dates) return true

  const periodFormat = getVisitPeriodFormat(filter.period)

  const sameYears =
    moment(filter.dates[0]).format(textConstants.yearFormat) ===
    moment(filter.dates[1]).format(textConstants.yearFormat)

  const sameDate =
    moment(filter.dates[0]).format(periodFormat.name) ===
    moment(filter.dates[1]).format(periodFormat.name)

  switch (filter.period) {
    case textConstants.period.daily:
    case textConstants.period.monthly:
      return sameDate && sameYears
    case textConstants.period.yearly:
      return sameYears
    default:
      return false
  }
}
