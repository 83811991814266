import React from "react"
import { Button } from "antd"
import { ButtonProps } from "antd/lib/button"
import { ReactComponent as ArrowLeftIcon } from "../icons/arrow_left.svg"

interface ButtonComponentProps extends ButtonProps {
  disabled?: boolean
  children?: any
  active?: boolean
  color?: string
  rest?: any
  icon?: JSX.Element
  isMiddle?: boolean
  classNameAdditional?: string
}

export const buttonText = {
  apply: `Применить`,
  back: `Назад`,
  cancel: `Отменить`
}

export const ButtonPrimary = ({
  children,
  disabled,
  isMiddle,
  icon,
  classNameAdditional,
  ...rest
}: ButtonComponentProps) => {
  return (
    <div
      className={`ButtonPrimaryWrapper ${isMiddle &&
        `MiddleHeight`} ${classNameAdditional || ""}`}
    >
      <Button
        type="primary"
        icon={icon}
        disabled={disabled}
        className={disabled ? `ButtonPrimaryDisabled` : `ButtonPrimary`}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const ButtonIcon = ({
  children,
  disabled,
  ...rest
}: ButtonComponentProps) => {
  return (
    <div className="ButtonIconWrapper">
      <Button
        className={disabled ? `ButtonIconDisabled` : ``}
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const ButtonNotifi = ({
  children,
  disabled,
  color,
  active,
  isMiddle,
  className,
  ...rest
}: ButtonComponentProps) => {
  const isBlue = !!color && color === `blue`
  const isGreen = !!color && color === `green`
  const isRed = !!color && color === `red`
  const isRedBorder = !!color && color === `redBorder`
  const isGray = !!color && color === `gray`
  const notifiClass = `ButtonNotifiWrapper ${
    isBlue ? `ButtonNotifiBlue` : ``
  } ${isGreen ? `ButtonNotifiGreen` : ``} ${isRed ? `ButtonNotifiRed` : ``} ${
    isGray ? `ButtonNotifiGray` : ``
  } ${isRedBorder ? `ButtonNotifiRedBorder` : ``} ${
    isMiddle ? `MiddleHeight` : ``
  } ${active ? `ButtonNotifiActive` : ``}`
  return (
    <div className={`${notifiClass} ${className}`}>
      <Button
        className={disabled ? `ButtonNotifiDisabled` : ``}
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

const ButtonDefault = ({
  children,
  disabled,
  ...rest
}: ButtonComponentProps) => {
  return (
    <div className="ButtonDefaultWrapper">
      <Button
        className={disabled ? `ButtonDefaultDisabled` : `ButtonDefault`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const ButtonFaded = ({
  children,
  disabled,
  ...rest
}: ButtonComponentProps) => {
  return (
    <div className="ButtonFadedWrapper">
      <Button
        className={disabled ? `ButtonFadedDisabled` : `ButtonFaded`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const ButtonGreen = ({
  children,
  disabled,
  ...rest
}: ButtonComponentProps) => {
  return (
    <div className="ButtonGreenWrapper">
      <Button
        className={disabled ? `ButtonGreenDisabled` : `ButtonGreen`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const MobileBackButton = ({ onClick }: { onClick: () => void }) => (
  <div className="FlexRow MobileBackButtonWrapper" onClick={onClick}>
    <ArrowLeftIcon />
    <span className="MobileBackButtonSpan">{buttonText.back}</span>
  </div>
)

export const ButtonOutlined = ({
  children,
  disabled,
  ...rest
}: ButtonComponentProps) => (
  <div className="ButtonOutlinedWrapper">
    <Button disabled={disabled} {...rest}>
      {children}
    </Button>
  </div>
)

export default ButtonDefault
