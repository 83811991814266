import React from "react"

const NotActiveFilter = ({
  title,
  description,
  descriptionTitle,
  icon
}: {
  title?: string
  description?: string | JSX.Element
  descriptionTitle?: string
  icon: JSX.Element
}) => {
  return (
    <div className="NotActiveFilterWrapper">
      {!!title && (
        <div className="HeadlineFirstText NotActiveFilterTitle">{title}</div>
      )}
      <div className="NotActiveFilterIconWrapper">{icon}</div>
      <div className="PaddingTop24 NotActiveFilterDescription FlexColumn Gap8">
        {!!descriptionTitle && (
          <div className="HeadlineFirstText">{descriptionTitle}</div>
        )}
        {!!description && <div className="TextDefault">{description}</div>}
      </div>
    </div>
  )
}

export default NotActiveFilter
