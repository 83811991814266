import React, { useState } from "react"
import { Popover } from "antd"
import { ReactComponent as CancelIcon } from "Components/icons/cancel.svg"
import { ReactComponent as DetailsIcon } from "Components/icons/details.svg"
import { ReactComponent as EditIcon } from "Components/icons/edit.svg"
import { viewPenaltyModalText } from "../ViewPenaltyModal/helper"
import { AdminPenaltyData } from "../types"

export const adminPenaltyCardText = {
  detected: "Выявлен",
  set: "Выставлен",
  viewed: "Просмотрен",
  resolved: "Устранен",
  daysAgo: "дней назад",
  minutesAgo: "минут назад",
  hallNumber: "Клуб №",
  rubles: "₽",
  winNull: "Без повышения WIN",
  warning: "Предупреждение",
  canceled: "Отменен",
  rejected: "Отклонен",
  edited: "Изменен",
  now: "Сейчас"
}

export interface AdminPenaltyPopover {
  penalty: AdminPenaltyData
  setEditModalData: (value: AdminPenaltyData) => void
  setCancelFinalModal: () => void
}

export const AdminPenaltyPopover: React.FC<AdminPenaltyPopover> = ({
  penalty,
  setEditModalData,
  setCancelFinalModal
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <Popover
      trigger={"click"}
      className="ProposalPopover"
      open={visible}
      onOpenChange={x => setVisible(x)}
      content={
        <div className="ProposalPopoverWrapper">
          <div
            onClick={e => {
              e.stopPropagation()
              setVisible(false)
              setEditModalData(penalty)
            }}
            className="ProposalPopoverButton"
          >
            <EditIcon />
            <div className="ProposalPopoverButtonText">
              {viewPenaltyModalText.editButton}
            </div>
          </div>
          <div
            onClick={e => {
              e.stopPropagation()
              setVisible(false)
              setCancelFinalModal()
            }}
            className="ProposalPopoverButton"
          >
            <CancelIcon />
            <div className="ProposalPopoverButtonText">
              {viewPenaltyModalText.cancelButton}
            </div>
          </div>
        </div>
      }
    >
      <div
        onClick={e => {
          e.stopPropagation()
          setVisible(true)
        }}
        className="ProposalIconDetails"
      >
        <DetailsIcon />
      </div>
    </Popover>
  )
}
