import React, { useCallback, useState } from "react"

import { noop } from "lodash"

import { useRequestParams } from "api/hooks"

import { SideFilter } from "Components/SideFilter/SideFilter"
import { Filters } from "Components/SideFilter/types"
import ReportWithFilter from "Components/ReportWithFilter"
import Title from "Components/Title"

import { getInitialFilter, TOP_THREE_FILTERS, topThreeText } from "./constants"
import TopThreeTable from "./Table"
import { TopThreeFilter } from "./types"
import CustomDateFilter from "./CustomDateFilter"

const TopThreeComponent = () => {
  const { current_hall } = useRequestParams()
  const initialFilter = getInitialFilter(`${current_hall}`)
  const [filter, setFilter] = useState<TopThreeFilter>(initialFilter)
  const setFilterHistoryCb = useCallback(value => {
    setFilter(value)
  }, [])

  return (
    <div>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={TOP_THREE_FILTERS}
            filterState={filter}
            getDisabled={filter => !filter.date}
            setFilterState={setFilterHistoryCb}
            resetPagination={noop}
            filters={[Filters.hallPickerRadio, Filters.customItem]}
            CustomComponent={CustomDateFilter}
          />
        }
        mobileFilter={filter.mobileFilter}
        isOpen={filter.isOpen}
        toggleFilter={() =>
          setFilter({
            ...filter,
            isOpen: !filter.isOpen
          })
        }
        toggleMobileFilter={() => {
          setFilter({
            ...filter,
            mobileFilter: true
          })
        }}
        pageContent={
          <>
            <Title
              titleText={topThreeText.title}
              tooltipText={topThreeText.tooltip}
            />
            <TopThreeTable filter={filter.data} />
          </>
        }
        modalCmp={<div />}
      />
    </div>
  )
}

export default TopThreeComponent
