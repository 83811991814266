import { UserCrudFields, UserFields } from "./types"

export const userCrudText = {
  addTitle: `Добавить пользователя`,
  editTitle: `Редактирование`,
  [UserCrudFields.name]: {
    label: `Имя сотрудника`,
    placeholder: `Введите имя сотрудника`,
    rule: `Неверный формат поля`
  },
  [UserCrudFields.email]: {
    label: `Электронная почта`,
    placeholder: `Укажите электронную почту сотрудника`
  },
  [UserCrudFields.halls]: {
    label: `Клубы`,
    placeholder: `Выберите клубы`
  },
  finish_add: `Добавить пользователя`,
  finish_edit: `Сохранить изменения`,
  cancel: `Отмена`
}

export const initialFields: UserFields = {
  [UserCrudFields.name]: undefined,
  [UserCrudFields.email]: undefined,
  halls: []
}

export const userRule = [
  {
    validator: <T>(_: T, value: string) => {
      const regex = /^[a-zA-Zа-яА-Я\s-]+$/
      return value && regex.test(value)
        ? Promise.resolve()
        : Promise.reject(new Error(userCrudText[UserCrudFields.name].rule))
    }
  }
]
