import { GuestsHistoryFilterData } from "Containers/GuestsHistory/types"

export enum ChartPeriod {
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly"
}

export enum ChartType {
  in = "in",
  out = "out",
  attendance = "attendance"
}

export type GuestHistoryCashboxChartProps = {
  filter: GuestsHistoryFilterData
}

export type GuestHistoryCashboxChartData = {
  date: string
  in: number
  out: number
  bets: number
  win: number
  transfer_in: number
  transfer_out: number
  games: string[]
  attendance: number
}

export type GuestHistoryCashboxChartState = {
  pending: boolean
  data: GuestHistoryCashboxChartData[]
}
