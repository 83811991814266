import React, { useState } from "react"

import Title from "Components/Title"
import ExcelButton from "Components/ExcelButton"
import { ButtonFaded, ButtonPrimary } from "Components/Button"
import { ModalFormComponent } from "Components/Modal"

import { CrudModalState } from "./Modal/types"
import { initialData } from "./Modal/constants"
import QiwiTable from "./Table/index"
import { QiwiTableFields } from "./Table/types"

import { initialReq, mainText } from "./constants"
import { QiwiFilter } from "./types"
import { textByStatus } from "./Cancel/constants"
import { CancelModalStatus } from "./Cancel/types"
import { isMobile } from "../../../constants"

const QiwiOrder = () => {
  const [qiwiPending, setQiwiPending] = useState(false)
  const [reqData, setReqData] = useState<QiwiFilter>(initialReq)
  const [crudModal, setCrudModal] = useState<CrudModalState>(initialData)

  const [exelModal, toggleExelModal] = useState(false)
  const onlyOneHallFilter = reqData.filters[QiwiTableFields.halls]?.length === 1
  const closeExelModal = () => toggleExelModal(false)

  return (
    <div>
      <div className="MainPageContentWrapper">
        <div className="MainPageContentRestrictions">
          <div
            className={`${
              isMobile ? `FlexColumn` : `FlexRow`
            } RelativePosition`}
          >
            <Title
              titleText={mainText.title}
              tooltipText={mainText.tooltip}
              rightAdditionalCmp={
                <div className={isMobile ? `` : `MarginLeft12`}>
                  <ExcelButton
                    disabled={qiwiPending}
                    url={mainText.exelUrl}
                    requestBody={{ filter: reqData.filters }}
                    alternativeClick={
                      onlyOneHallFilter
                        ? undefined
                        : () => toggleExelModal(true)
                    }
                  />
                </div>
              }
            />
            <div className="QiwiOrderNewButton">
              <ButtonPrimary onClick={() => setCrudModal({ open: true })}>
                {mainText.new}
              </ButtonPrimary>
            </div>
          </div>
          <QiwiTable
            reqData={reqData}
            setReqData={setReqData}
            qiwiPending={qiwiPending}
            setQiwiPending={setQiwiPending}
            crudModal={crudModal}
            setCrudModal={setCrudModal}
          />
        </div>
      </div>
      {exelModal && (
        <ModalFormComponent
          withoutCross={true}
          close={closeExelModal}
          width={480}
        >
          <div className="FlexColumn Gap16">
            <div className="HeadlineSecondText">{mainText.exelModal.title}</div>
            <span className="TextTwo">{mainText.exelModal.description}</span>
            <div className="MarginLeftAuto">
              <ButtonFaded onClick={closeExelModal}>
                <span className="ButtonTextFirst">
                  {textByStatus[CancelModalStatus.block].close}
                </span>
              </ButtonFaded>
            </div>
          </div>
        </ModalFormComponent>
      )}
    </div>
  )
}

export default QiwiOrder
