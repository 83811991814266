import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { ReactComponent as BackIcon } from "Components/icons/back.svg"
import { ReactComponent as DropdownIcon } from "Components/icons/dropdown_12.svg"

import FileComponent from "Components/File"

import { checkFileFormat } from "api/helpers"
import { useRequestParams } from "api/hooks"

import { PinComponent } from "../constants"

import getCurrentUserMessage from "./helper"
import { MessagesComponentIdProps, MessagesState } from "./types"

import { storeMessage } from "store/message/actions"
import { MessageStoreState } from "store/message/types"
import moment from "moment"
import { currentMessageConst, initialMessage } from "./constants"

const MessagesComponentId = ({
  close,
  id,
  refresh
}: MessagesComponentIdProps) => {
  const { history, logout, addDeveloperLink, developer } = useRequestParams()
  const dispatch = useDispatch()
  const setStoreCount: (value: MessageStoreState) => void = (
    value: MessageStoreState
  ) => dispatch(storeMessage(value))

  const [message, setMessage] = useState<MessagesState>(initialMessage)

  useEffect(() => {
    getCurrentUserMessage({
      id,
      setMessage,
      message,
      addDeveloperLink,
      developer,
      history,
      logout,
      setStoreCount
    })
  }, [id])

  const [showAll, toggleShow] = useState(false)
  const filesCount = currentMessageConst.fileCount(message.data.files.length)

  return (
    <div className="MessagesComponentIdWrapper">
      <div
        className="MessagesComponentIdBack FlexRow"
        onClick={() => {
          close()
          refresh()
        }}
      >
        <BackIcon />
        <div>{currentMessageConst.back}</div>
      </div>
      <div className="MessagesComponentIdDataWrapper">
        <div className="FlexRow">
          <div className="MessagesComponentIdDataTitle">
            {message.data.title}
          </div>
          {!message.pending && !!message.data && (
            <PinComponent pin={message.data.pinned} id={id} isSmall={false} />
          )}
        </div>
        <div className="MessagesComponentIdDataDate">
          {moment(message.data.date).format(`DD MMMM YYYY, HH:mm`)}
        </div>
        <div className="MessagesComponentIdDataHallsWrapper FlexRow">
          <span className="MessagesRouteListItemHallsTitle">
            {currentMessageConst.halls}
          </span>
          <span className={showAll ? `` : `MessagesIdRouteListItemHalls `}>
            {message.data.halls.join(`, `)}
          </span>
          {message.data.halls &&
            currentMessageConst.maxHallsCount < message.data.halls.length && (
              <div
                className={`${
                  showAll
                    ? `MessagesIdRouteListItemHallsMoreDown`
                    : `MessagesIdRouteListItemHallsMoreUp`
                } MessagesIdRouteListItemHallsMore FlexRow`}
                onClick={() => toggleShow(!showAll)}
              >
                <span>
                  {showAll
                    ? currentMessageConst.show.hide
                    : currentMessageConst.show.all}
                </span>
                <DropdownIcon />
              </div>
            )}
        </div>
        <div className="MessagesComponentIdDataText">
          <span dangerouslySetInnerHTML={{ __html: message.data.text }} />
        </div>
        {!!message.data.files && !!message.data.files.length && (
          <div className="MessagesComponentIdDataFiles">
            {message.data.files.length > 1 && (
              <div className="MessagesComponentIdDownloadAll FlexRow">
                <div className="MessagesComponentIdDownloadAllInfo">
                  {filesCount}
                </div>
                <div
                  className="MessagesComponentIdDownloadAllLink"
                  onClick={() => {
                    const all = [].slice.call(
                      document.getElementsByClassName(`FileItemDownloadLink`)
                    )
                    all.forEach((item: HTMLBaseElement) => item.click())
                  }}
                >
                  {currentMessageConst.saveAll}
                </div>
              </div>
            )}
            <div className="FlexRow FlexWrap">
              {message.data.files.map(item => {
                const format = checkFileFormat(item.filename)
                return (
                  <FileComponent
                    key={`${item.filename}_${item.size}`}
                    name={item.filename}
                    type={format || undefined}
                    size={item.size}
                    link={item.link}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MessagesComponentId
