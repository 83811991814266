import React from "react"

import { GuidebookStatuses } from "../types"

import { ReactComponent as ConsiderationIcon } from "./icons/consideration.svg"
import { ReactComponent as ApproveIcon } from "./icons/approve.svg"
import { ReactComponent as RejectedIcon } from "./icons/rejected.svg"

export const cardConstants = {
  example: `Образец фото`,
  newText: `Добавить фото`,
  exitText: `Выход`,
  considerationText: `Сохранить`,
  saveError: `Ошибка сохранения`
}

export const statusBadge = {
  [GuidebookStatuses.approved]: <ApproveIcon />,
  [GuidebookStatuses.consideration]: <ConsiderationIcon />,
  [GuidebookStatuses.rejected]: <RejectedIcon />,
  null: <div />
}

export const useCardApiConst = {
  remove: {
    method: `DELETE`,
    url: `/v1/hall/guidebook/{hall}/{section}/{attachment}`
  },
  add: {
    method: `POST`,
    url: `/v1/hall/guidebook/{hall}/{section}`
  }
}
