import { BetsByTimeDataHourRequestData, BetsByTimeDataState } from "./types"

import { formatToCurrency, getDateByDigital } from "constants/index"
import { GetDateByDigitalTypes } from "constants/type"

export const initialData: BetsByTimeDataState = {
  pending: false,
  data: []
}

export const sumKey = `sum`

export const betsByTimeConstants = {
  options: [
    `00:00`,
    `01:00`,
    `02:00`,
    `03:00`,
    `04:00`,
    `05:00`,
    `06:00`,
    `07:00`,
    `08:00`,
    `09:00`,
    `10:00`,
    `11:00`,
    `12:00`,
    `13:00`,
    `14:00`,
    `15:00`,
    `16:00`,
    `17:00`,
    `18:00`,
    `19:00`,
    `20:00`,
    `21:00`,
    `22:00`,
    `23:00`,
    sumKey
  ],
  fullPercent: 100,
  activeOptions: [`00:00`, `04:00`, `08:00`, `12:00`, `16:00`, `20:00`, sumKey],
  allLabel: `всего за сутки`,
  tooltip: {
    avg_bet_sum: `Средняя сумма ставки`,
    avg_win_sum: `Средний выигрыш`,
    avg_bonus_sum: `Сред. сумма бонусов/фриб.`,
    win_in_percent: `WIN/IN`,
    minutes_in_play: `Время в игре`,
    bet_count: `Кол-во ставок`
  }
}

export const getTextByData = (data: BetsByTimeDataHourRequestData) => {
  const hoursInGame = Math.floor(data.minutes_in_play / 60)
  const minutesInGame = data.minutes_in_play - hoursInGame * 60

  const hoursText =
    getDateByDigital({
      value: hoursInGame,
      type: GetDateByDigitalTypes.hour
    }) || ``
  const minutesText =
    getDateByDigital({
      value: minutesInGame,
      type: GetDateByDigitalTypes.minute
    }) || ``

  return {
    avg_bet_sum: `${formatToCurrency({
      amount: Number(data.avg_bet_sum),
      withoutSpace: true
    })} ₽`,
    avg_win_sum: `${formatToCurrency({
      amount: Number(data.avg_win_sum),
      withoutSpace: true
    })} ₽`,
    avg_bonus_sum: formatToCurrency({
      amount: Number(data.avg_bonus_sum),
      withoutSpace: true
    }),
    win_in_percent: `${data.win_in_percent}%`,
    hours: `${hoursText}  ${minutesText}`
  }
}
