import React, { useEffect, useState } from "react"
import { ChangePhoneTableData } from "../types"

import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"
import { ReactComponent as ApproveIcon } from "Components/icons/success_large.svg"
import { ReactComponent as RejectIcon } from "Components/icons/reject_large.svg"
import InputComponent from "Components/Input"
import { ButtonNotifi, ButtonPrimary } from "Components/Button"

import betRequest from "api/request"

import { useHistory } from "react-router-dom"
import { logoutUser } from "store/auth/actions"
import { useDispatch, useSelector } from "react-redux"
import { IDevelopState } from "store/developer/types"
import { getDeveloperList } from "store/developer/selector"
import { addLink } from "store/developer/actions"

const EditModal = ({
  data,
  close,
  refresh
}: {
  data: ChangePhoneTableData
  close: () => void
  refresh: () => void
}) => {
  const history = useHistory()
  const logout: () => void = () => dispatch(logoutUser())
  const dispatch = useDispatch()
  const developer: IDevelopState = useSelector(getDeveloperList)
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))

  const [modalData, setModalData] = useState<{
    name: string | undefined
    pending: boolean
    response: string | undefined
  }>({ name: undefined, pending: false, response: undefined })
  const makeDecision = (status: boolean) => {
    setModalData({ ...modalData, pending: true })
    betRequest({
      logout,
      developer,
      history,
      addDeveloperLink,
      method: `POST`,
      url: `order/phone/edit`,
      requestBody: {
        id: data.id,
        status: status ? 2 : 3,
        manager: modalData.name
      }
    })
      .then(() =>
        setModalData({
          ...modalData,
          response: status ? `approve` : `reject`,
          pending: false
        })
      )
      .catch(() => setModalData({ ...modalData, pending: false }))
  }

  const onlyRightTextLength = !!modalData.name
    ? modalData.name.replace(/[^а-яёА-ЯЁ ]/g, "").replaceAll(` `, ``).length
    : 0
  const allTextLength = !!modalData.name
    ? `${modalData.name}`.replaceAll(` `, ``).length
    : 0
  const disableByName = onlyRightTextLength !== allTextLength

  const disabledButton = !modalData.name || modalData.pending || disableByName
  const isApprove = modalData.response === `approve`

  useEffect(() => {
    if (!!modalData.response) {
      const waitForIt = setTimeout(() => {
        refresh()
        close()
      }, 2000)
      return () => {
        clearTimeout(waitForIt)
      }
    }
  }, [modalData.response])

  return (
    <div>
      {!modalData.response ? (
        <div className="FlexRow ChangePhoneEditModal">
          <div className="ChangePhoneEditModalWrapper">
            <div className="ChangePhoneEditModalWrapperTitle FlexRow">
              <span>Смена номера телефона</span>
              <div className="ChangePhoneEditModalMobileClose" onClick={close}>
                <CloseIcon />
              </div>
            </div>
            <div className="ChangePhoneEditModalWrapperTable">
              <div className="ChangePhoneEditModalWrapperTableHeader FlexRow">
                <div className="ChangePhoneEditModalWrapperTableLeft">
                  Гость:
                </div>
                <div className="ChangePhoneEditModalWrapperTableRight">
                  {data.fio}
                </div>
              </div>
              <div className="FlexRow ChangePhoneEditModalWrapperTableRow ChangePhoneEditModalWrapperTableRowWithBorder">
                <div className="ChangePhoneEditModalWrapperTableLeft">
                  Старый номер:
                </div>
                <div className="ChangePhoneEditModalWrapperTableRight">
                  {data.old_phone}
                </div>
              </div>
              <div className="FlexRow ChangePhoneEditModalWrapperTableRow">
                <div className="ChangePhoneEditModalWrapperTableLeft">
                  Новый номер:
                </div>
                <div className="ChangePhoneEditModalWrapperTableRight">
                  {data.new_phone}
                </div>
              </div>
            </div>
            <div className="ChangePhoneEditModalUser">
              <div className="ChangePhoneEditModalUserTitle">
                Имя и фамилия заявителя
              </div>
              <form autoComplete="on">
                <InputComponent
                  name="changephone_fio"
                  className="ChangePhoneEditModalUserInput"
                  placeholder=""
                  value={modalData.name}
                  onChange={({ target: { value } }) =>
                    setModalData({ ...modalData, name: value || undefined })
                  }
                />
              </form>
            </div>
            <div className="ChangePhoneEditModalButtons FlexRow">
              <ButtonNotifi
                disabled={disabledButton}
                color="redBorder"
                onClick={() => makeDecision(false)}
              >
                Отказать
              </ButtonNotifi>
              <ButtonPrimary
                disabled={disabledButton}
                onClick={() => makeDecision(true)}
              >
                Одобрить
              </ButtonPrimary>
            </div>
          </div>
          <div className="ChangePhoneEditModalClose" onClick={close}>
            <CloseIcon />
          </div>
        </div>
      ) : (
        <div className="ChangePhoneEditSuccess">
          <div className="ChangePhoneEditSuccessTitle">
            {isApprove ? `Номер телефона` : `В смене номера телефона`}
            <br />
            {isApprove ? `успешно изменен` : `отказано`}
          </div>
          <div className="ChangePhoneEditSuccessIcon">
            {isApprove ? <ApproveIcon /> : <RejectIcon />}
          </div>
        </div>
      )}
      <div className="Overlay" />
    </div>
  )
}

export default EditModal
