import React from "react"

import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

import moment from "moment"

import { isMobile } from "constants/index"

import { ChartsDataType } from "../types"

interface GuestHistoryMetricChartProps {
  data: ChartsDataType[]
}

const GuestHistoryMetricChartComponent = ({
  data
}: GuestHistoryMetricChartProps) => {
  const heightGradient = `rgba(52, 199, 89, 0.2)`
  const lowGradient = `rgba(52, 199, 89, 0)`

  const xLabel = `DD.MM`

  const xAxisCategory = data.map(item => moment(item.x).format(xLabel))
  const seriesData = data.map(item => ({
    y: item.y,
    value: item.value,
    name: item.name
  }))

  const options: Highcharts.Options = {
    title: {
      text: ""
    },
    xAxis: {
      categories: xAxisCategory,
      crosshair: {
        className: `ChartsCrosshair`,
        width: 1,
        dashStyle: `Dash`,
        color: `rgba(52, 199, 89, 1)`
      },
      tickWidth: 0,
      labels: {
        align: `center`,
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this)
          return (
            '<span id="hoveredLabel" style="font-style: normal;' +
            "  font-family: Lato, sans-serif" +
            "  font-weight: normal;" +
            "  font-size: 11px;" +
            "  line-height: 13px;" +
            "  letter-spacing: -0.154px;" +
            "  color: #AEAEB2 !important;" +
            '">' +
            label +
            "</span>"
          )
        }
      }
    },
    yAxis: {
      crosshair: {
        width: 1,
        color: `#E9ECEF`
      },
      title: {
        text: ""
      },
      gridLineDashStyle: `Dash`,
      gridLineColor: `#F2F2F7`,
      gridLineWidth: 1,
      gridZIndex: 0,
      plotLines: [
        {
          dashStyle: `Solid`,
          color: "#AEAEB2",
          value: 0,
          width: 1
        }
      ]
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, heightGradient || ``],
            [1, lowGradient || ``]
          ]
        },
        marker: {
          radius: 1,
          fillColor: `rgba(52, 199, 89, 1)`,
          states: {
            hover: {
              radius: 8
            }
          }
        },
        lineWidth: 2,
        lineColor: `rgba(52, 199, 89, 1)`,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        threshold: null
      }
    },
    tooltip: {
      borderRadius: 20,
      borderColor: `#434345`,
      style: {
        padding: `8px 12px`
      },
      dateTimeLabelFormats: {
        day: "%e.%m",
        week: "%e.%m",
        month: "%e.%m",
        year: "%e.%m"
      },
      headerFormat: "<br />",
      pointFormat:
        '<b style="font-family: Gilroy, sans-serif;' +
        "  font-style: normal;" +
        "  font-weight: 600;" +
        "  font-size: 16px;" +
        "  line-height: 18px;" +
        "  letter-spacing: -0.154px;" +
        "  color: #FFDD2D !important;" +
        '">{point.value} ₽</b><br>' +
        '<b style="font-style: normal;' +
        "  font-weight: normal;" +
        "  font-size: 11px;" +
        "  line-height: 18px;" +
        "  letter-spacing: -0.154px;" +
        "  color: #D1D1D6 !important;" +
        '">{point.name}</b><br>',
      backgroundColor: `#434345`
    },
    chart: {
      type: "areaspline",
      scrollablePlotArea: {
        minWidth: isMobile ? 890 : seriesData.length * 50,
        opacity: 0.5
      }
    },
    series: [
      {
        type: "areaspline",
        data: seriesData
      }
    ]
  }

  return (
    <div className="ChartsWrapper">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default GuestHistoryMetricChartComponent
