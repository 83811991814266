import React from "react"
import { HallCardProps } from "../types"

const Card = ({ headerIcon, header, children }: HallCardProps) => {
  return (
    <div className="HallCard">
      <div className="HallCard__Header">
        {headerIcon}
        <span className="TableHeader">{header}</span>
      </div>
      <div className="HallCard__Content">{children}</div>
    </div>
  )
}

export default Card
