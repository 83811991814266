import React from "react"

import moment from "moment"

import { isMobile, MOSCOW_GMT, valueToCurrency } from "constants/index"

import { SuperwinTableItem } from "./types"

import { SuperwinFilter, SuperwinFilterHistory } from "../types"
import { SuperWinsTableColumnNames } from "../../Main/types"
import { superWinsColumnNames } from "../../Main/constants"
import { getFilterStateFromLocalStorage } from "api/helpers"

export const HISTORY_URL = "v1/superwins/history"
export const WIN_HISTORY_URL = `${HISTORY_URL}/wins`

export const SUPERWIN_FILTERS_HISTORY = `SUPERWIN_FILTERS_HISTORY`
export const SUPERWIN_FILTERS_HISTORY_WIN = `SUPERWIN_FILTERS_HISTORY_WIN`

const initialFilter: SuperwinFilter = {
  isOpen: true,
  mobileFilter: isMobile,
  isActive: true
}

export const initialHistoryFilter = (
  currentHall: string,
  storageKey: string,
  isSuperWinsHistory?: boolean
): SuperwinFilterHistory => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    storageKey
  )
  const filter: SuperwinFilterHistory = {
    ...initialFilter,
    data: {
      dates: undefined
    }
  }

  if (isSuperWinsHistory) {
    filter.data.halls = [currentHall]
    filter.data.gmt = MOSCOW_GMT
  } else {
    filter.data.nominals = []
    filter.data.hall = Number(currentHall)
  }
  return { ...filter, data: { ...filter.data, ...initFilterStateFromStorage } }
}

export const filterText = {
  nominals: `Номиналы`,
  all: `Все`
}

export const emptyText = {
  table: `По заданным фильтрам ничего не найдено`,
  hall: `Здесь будет история изменений супервыигрышей в вашем клубе. Пока ни одного не было`,
  filter: `Чтобы получить историю изменений \nсупервыигрышей, примените \nинтересующие вас фильтры`
}

export const TABLE_WIDTH = {
  small: 80,
  medium: 104,
  large: 208,
  all: 1180
}

export const columns = [
  {
    title: () => (
      <span className="TableHeaderFirst TableHeader">{`№ Клуба`}</span>
    ),
    width: TABLE_WIDTH.medium,
    dataIndex: "hall",
    key: "hall",
    render: (hall: string) => (
      <div className="TableItemMaxWidth TableHeaderFirst">{hall}</div>
    )
  },
  {
    title: () => <span className="TableHeader">{`Номинал`}</span>,
    width: TABLE_WIDTH.small,
    dataIndex: "nominal",
    key: "nominal",
    render: (nominal: number) => (
      <div className="TableItemMaxWidth TextOne">{nominal}</div>
    )
  },
  {
    title: () => (
      <div className="FlexColumn TableHeader">
        <span>{`Дата`}</span>
        <span>{`и время (мск)`}</span>
      </div>
    ),
    width: TABLE_WIDTH.medium,
    dataIndex: "date",
    key: "date",
    render: (date: string) => {
      const momentDate = moment(date, `YYYY-MM-DD HH:mm:ss`)
      const datePart = momentDate.format(`DD.MM.YYYY`)
      const timePart = momentDate.format(`HH:mm:ss`)
      return (
        <div className="TableItemMaxWidth FlexColumn">
          <span className="TextOne">{datePart}</span>
          <span className="TextOne Gray600Color">{timePart}</span>
        </div>
      )
    }
  },
  {
    title: () => (
      <div className="FlexColumn TableHeader MarginLeft24">
        <span>{`Зона`}</span>
        <span>{`клуба`}</span>
      </div>
    ),
    width: TABLE_WIDTH.small,
    dataIndex: "is_vip",
    key: "is_vip",
    render: (is_vip: boolean) => (
      <div className="TableItemMaxWidth TextOne">
        {is_vip ? `VIP` : `Общая зона`}
      </div>
    )
  },
  {
    title: () => (
      <div className="FlexColumn TableHeader">
        <span>{`Повышение`}</span>
        <span>{`лицевого`}</span>
        <span>{`счета`}</span>
      </div>
    ),
    width: TABLE_WIDTH.medium,
    dataIndex: "face_increase_sum",
    key: "face_increase_sum",
    render: (face_increase_sum: string) => {
      const value = valueToCurrency({ value: Number(face_increase_sum) })
      return <div className="TableItemMaxWidth TextOne">{value}</div>
    }
  },
  {
    title: () => (
      <div className="FlexColumn TableHeader">
        <span>{`Текущий`}</span>
        <span>{`лицевой счет`}</span>
      </div>
    ),
    width: TABLE_WIDTH.medium,
    dataIndex: "current_face_sum",
    key: "current_face_sum",
    render: (current_face_sum: string) => {
      const value = valueToCurrency({ value: Number(current_face_sum) })
      return <div className="TableItemMaxWidth TextOne">{value}</div>
    }
  },
  {
    title: () => (
      <div className="FlexColumn TableHeader">
        <span>{`Старый`}</span>
        <span>{`лицевой счет`}</span>
      </div>
    ),
    width: TABLE_WIDTH.medium,
    dataIndex: "old_face_sum",
    key: "old_face_sum",
    render: (old_face_sum: string) => {
      const value = valueToCurrency({ value: Number(old_face_sum) })
      return <div className="TableItemMaxWidth TextOne">{value}</div>
    }
  },
  {
    title: () => (
      <div className="FlexColumn TableHeader">
        <span>{`Текущий`}</span>
        <span>{`фоновый`}</span>
        <span>{`счет`}</span>
      </div>
    ),
    width: TABLE_WIDTH.medium,
    dataIndex: "current_back_sum",
    key: "current_back_sum",
    render: (current_back_sum: string) => {
      const value = valueToCurrency({ value: Number(current_back_sum) })
      return <div className="TableItemMaxWidth TextOne">{value}</div>
    }
  },
  {
    title: () => (
      <div className="FlexColumn TableHeader">
        <span>{`Старый`}</span>
        <span>{`фоновый`}</span>
        <span>{`счет`}</span>
      </div>
    ),
    width: TABLE_WIDTH.medium,
    dataIndex: "old_back_sum",
    key: "old_back_sum",
    render: (old_back_sum: string) => {
      const value = valueToCurrency({ value: Number(old_back_sum) })
      return <div className="TableItemMaxWidth TextOne">{value}</div>
    }
  },
  {
    title: () => <span className="TableHeader">{`Пользователь`}</span>,
    width: TABLE_WIDTH.large,
    dataIndex: "name",
    key: "name",
    render: (name: string, record: SuperwinTableItem) => (
      <div className="TableItemMaxWidth FlexColumn">
        <span className="TextOne">{name}</span>
        <span className="Gray600Color TextOne">{record.email}</span>
      </div>
    )
  }
]

export const hallCol = {
  title: superWinsColumnNames[SuperWinsTableColumnNames.hall],
  width: TABLE_WIDTH.large,
  dataIndex: SuperWinsTableColumnNames.hall,
  key: SuperWinsTableColumnNames.hall_zone
}
