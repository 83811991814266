import React from "react"

import { getReportTableDateHeader } from "api/helpers"

import NewTableComponent, { expandIcon } from "Components/TableNew"
import { PaginationValue } from "Components/Pagination/types"

import ConsolidatedInside from "./Inside"
import { ConsolidatedStateData } from "../types"
import { text } from "../constants"
import { TableColumnWidth } from "Components/Tabs/types"

type ContentProps = {
  report: ConsolidatedStateData
  pending: boolean
  isOpen: boolean
  period: string
  total: number
  pagination: PaginationValue
  setPagination: (value: PaginationValue) => void
}

const columns = [
  {
    title: "Сыграно BetBoom, ₽",
    dataIndex: "inw",
    key: "inw",
    render: (inw: string) => <div className="TableItemMaxWidth">{inw}</div>
  },
  {
    title: "Выиграно BetBoom, ₽",
    dataIndex: "outw",
    key: "outw",
    render: (outw: string) => <div className="TableItemMaxWidth">{outw}</div>
  },
  {
    title: "Сыграно бонусных BetBoom, Б",
    dataIndex: "inwBonus",
    key: "inwBonus",
    render: (inwBonus: string) => (
      <div className="TableItemMaxWidth">{inwBonus}</div>
    )
  },
  {
    title: "Результат BetBoom, ₽",
    dataIndex: "win",
    key: "win",
    render: (win: string) => (
      <div
        className={`
          TableItemMaxWidth ${
            `${win}`.includes(` - `) ? `TableItemNegativeValue` : ``
          }`}
      >
        {win}
      </div>
    )
  },
  {
    title: "Итого\nBetBoom, ₽",
    dataIndex: "sum",
    width: TableColumnWidth.s,
    key: "sum",
    render: (sum: string) => (
      <div
        className={`
          TableItemMaxWidth ${
            `${sum}`.includes(` - `) ? `TableItemNegativeValue` : ``
          }`}
      >
        {sum}
      </div>
    )
  },
  {
    title: "Сыграно SportBoom, ₽",
    dataIndex: "sportInw",
    key: "sportInw",
    render: (sportInw: string) => (
      <div className="TableItemMaxWidth">{sportInw}</div>
    )
  },
  {
    title: "Выграно SportBoom, ₽",
    dataIndex: "sportOutw",
    key: "sportOutw",
    render: (sportOutw: string) => (
      <div className="TableItemMaxWidth">{sportOutw}</div>
    )
  },
  {
    title: "Сыграно Freebet, Ф",
    dataIndex: "sportInwBonus",
    key: "sportInwBonus",
    render: (sportInwBonus: string) => (
      <div className="TableItemMaxWidth">{sportInwBonus}</div>
    )
  },
  {
    title: "Результат SportBoom, ₽",
    dataIndex: "sportWin",
    key: "sportWin",
    render: (sportWin: string) => (
      <div
        className={`
          TableItemMaxWidth ${
            `${sportWin}`.includes(` - `) ? `TableItemNegativeValue` : ``
          }`}
      >
        {sportWin}
      </div>
    )
  }
]

const ConsolidatedContent = ({
  report,
  pending,
  period,
  total,
  pagination,
  setPagination
}: ContentProps) => {
  const reportWithKey = report.map((item, key) => ({ ...item, key }))

  const columnWithDate = [
    {
      title: getReportTableDateHeader(period),
      width: 110,
      dataIndex: "date",
      key: "date",
      fixed: "left",
      render: (record: string | null) => (
        <span className="TextDefaultBold CapitalizeText">
          {record || text.total}
        </span>
      )
    },
    ...columns
  ]

  return (
    <div className="ReportTableContentWrapper">
      <NewTableComponent
        pagination={{
          value: pagination,
          total,
          onChange: setPagination
        }}
        loading={pending}
        columns={columnWithDate}
        data={reportWithKey}
        width={1374}
        withTotal={true}
        rowClassName="ExpandableTableRow"
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (
            record: any,
            index: any,
            indent: any,
            expanded: any
          ) => (
            <div>
              {expanded && !pending && (
                <ConsolidatedInside
                  data={record.inside || []}
                  date={record.date}
                />
              )}
            </div>
          ),
          expandIcon
        }}
      />
    </div>
  )
}

export default ConsolidatedContent
