import React, { useMemo } from "react"

import { v4 as uuidv4 } from "uuid"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DragAndDropList = ({
  list,
  changeOrder,
  renderListItem
}: {
  list: any[]
  changeOrder: (value: any[]) => void
  renderListItem: (val: any) => void
}) => {
  const listWithId = useMemo(
    () => list.map(item => ({ id: uuidv4(), content: item })),
    [list]
  )

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      listWithId,
      result.source.index,
      result.destination.index
    )

    const removeId = items.map((listItem: any) => listItem.content)

    changeOrder(removeId)
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {listWithId.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={snapshot.isDragging ? `DraggableItem` : ``}
                  >
                    {renderListItem(item.content)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragAndDropList
