import { initialColumnList, TableColumns } from "../constants"
import { TableConfigTemplateResponse } from "./types"

export const configModalText = {
  title: `Параметры таблицы`,
  template: {
    templateTitle: `Шаблон для таблицы`,
    templatePlaceholder: `Выберите или сохраните шаблон`,
    templateAdd: `+ Сохранить форматирование`,
    cancel: `Отменить `,
    add: `Сохранить`
  },
  initialTemplate: {
    label: `По умолчанию`,
    id: 0,
    data: initialColumnList.data
  },
  list: {
    title: `Порядок стобцов`,
    hideAll: `Скрыть все`,
    showAll: `Открыть все`
  },
  apply: `Применить`,
  removeText: {
    title: `Внимание!`,
    subtitle: (label: string) =>
      `Шаблон для таблицы «${label}» будет безвозвратно удален.`,
    cancel: `Отмена`,
    remove: `Удалить`
  }
}

export const templateListToConfig = (data: TableConfigTemplateResponse[]) =>
  data.map((item: TableConfigTemplateResponse) => {
    const { columns, name, id }: TableConfigTemplateResponse = item

    const keyToArr: TableColumns[] = Object.keys(columns) as TableColumns[]
    const fieldsForConfig = keyToArr.map(fieldItem => ({
      field: fieldItem,
      visible: columns[fieldItem]
    }))
    return {
      label: name,
      value: id,
      data: fieldsForConfig
    }
  })

export const initialRemove = {
  id: 0,
  label: ``,
  open: false
}
