import React from "react"

import { valueToCurrency } from "constants/index"

import { SortOrders } from "Components/TableNew/types"

import {
  FilterTable,
  FilterTableSort,
  PayrollTableFields,
  PayrollTableStatus
} from "../types"
import { statusText, textByField } from "../constants"
import moment from "moment"

export const tableComponentsConstants = {
  title: `Прикреплённые документы`,
  buttons: {
    clear: `Фильтры`,
    clearModalTitle: `Примененные фильтры`,
    clearModalBtn: `Очистить фильтры`
  }
}

// Clear modal component
const checkField = (filters: FilterTable, field: PayrollTableFields) =>
  !!filters[field] ||
  (field === PayrollTableFields.sum &&
    (filters[field]?.from || filters[field]?.to)) ||
  filters.sort?.field === field

export const checkClearIsEmpty = (filter: FilterTable): number => {
  let filterCount = 0

  for (const item in PayrollTableFields) {
    const currentField = item as PayrollTableFields
    if (checkField(filter, currentField)) {
      filterCount++
    }
  }

  return filterCount
}

const checkSort = (field: PayrollTableFields, sort: FilterTableSort) => {
  if (field === sort.field) {
    return `Сортировка по ${
      sort?.order === SortOrders.asc ? `возрастанию` : `убыванию`
    }`
  }
  return undefined
}

export const getFilterInfoByData = (filters: FilterTable): JSX.Element => {
  const dataArr = []

  for (const item in PayrollTableFields) {
    const currentField = item as PayrollTableFields
    const sortData = checkSort(currentField, filters.sort)
    if (
      (!!filters[currentField] || sortData) &&
      (currentField === PayrollTableFields.hall ||
        currentField === PayrollTableFields.document_number)
    ) {
      dataArr.push({
        value: filters[currentField],
        label: textByField[currentField].label,
        sort: sortData
      })
    }
    if (
      (!!filters[currentField] || sortData) &&
      currentField === PayrollTableFields.billing_period
    ) {
      dataArr.push({
        value: filters[currentField]?.format(`MMM YYYY`),
        label: textByField[currentField].label,
        sort: checkSort(currentField, filters.sort),
        isCapitalize: true
      })
    }
    if (
      currentField === PayrollTableFields.preparation_date &&
      (!!filters[currentField]?.from || !!filters[currentField]?.to || sortData)
    ) {
      let from
      let to
      if (!!filters[currentField]?.from) {
        from = `от ${moment(
          filters[currentField]?.from,
          `YYYY-MM-DDTHH:mm:ss+00:00`
        ).format(`DD.MM.YYYY`)}`
      }
      if (!!filters[currentField]?.to) {
        to = `до ${moment(
          filters[currentField]?.to,
          `YYYY-MM-DDTHH:mm:ss+00:00`
        ).format(`DD.MM.YYYY`)}`
      }
      dataArr.push({
        value: `${from || ``} ${to || ``}`,
        label: textByField[currentField].label,
        sort: checkSort(currentField, filters.sort)
      })
    }
    if (
      (!!filters[currentField] || sortData) &&
      currentField === PayrollTableFields.sum
    ) {
      const from = filters[currentField]?.from
        ? `от ${valueToCurrency({
            value: Number(filters[currentField]?.from),
            withoutFixed: true,
            withoutZero: true
          })} `
        : ``
      const to = filters[currentField]?.to
        ? `до ${valueToCurrency({
            value: Number(filters[currentField]?.to),
            withoutFixed: true,
            withoutZero: true
          })}`
        : ``
      dataArr.push({
        value: `${from}${to}`,
        label: textByField[currentField].label,
        sort: checkSort(currentField, filters.sort)
      })
    }
    if (!!filters[currentField] && currentField === PayrollTableFields.status) {
      const currentStatus = filters[currentField] as PayrollTableStatus
      dataArr.push({
        value: statusText[currentStatus],
        label: textByField[currentField].label,
        sort: checkSort(currentField, filters.sort)
      })
    }
  }

  return (
    <div className="FlexColumn Gap16 PaddingTop16">
      {dataArr.map(item => (
        <div key={`payroll_filter_${item.label}`} className="FlexColumn Gap4">
          <span className="BodySecondText">{item.label}</span>
          <span
            className={`TextDefaultBold Cyan700Color ${
              item.isCapitalize ? `CapitalizeText` : ``
            }`}
          >
            {item.value}
          </span>
          {!!item.sort && (
            <span className="TextOne Gray600Color">{item.sort}</span>
          )}
        </div>
      ))}
    </div>
  )
}
