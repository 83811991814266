import React, { useState } from "react"

import { MaskedInput } from "antd-mask-input"
import noop from "lodash/noop"

import GuestInfoFilterProps, { GuestInfoFilterState } from "./type"
import rangeRestrictions from "./helper"

import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"

import { HallListFilter } from "Components/HallPicker/HallPickerModal"
import { RangePickerComponent } from "Components/DatePicker"
import { ButtonPrimary } from "Components/Button"
import { SwitchFilterComponent } from "Components/Switch"

const LoyaltyGuestFilter = ({
  filterState,
  setFilterState,
  mobileFilterOpen,
  setMobileFilterOpen
}: GuestInfoFilterProps) => {
  const [filter, setFilter] = useState<GuestInfoFilterState>(filterState.data)
  const [phone, changePhone] = useState<string | undefined>(
    filterState.data.phone
  )
  const disabledApply =
    (!filter.isMonthly && !filter.dates) ||
    !phone ||
    (!!phone && phone.length < 10)
  return (
    <div
      className={`
      ${mobileFilterOpen ? `MainFilterMobile` : `MainFilterWrapper`}
      ${
        filterState.isOpen
          ? `MainFilterWrapperActive`
          : `MainFilterWrapperUnActive`
      }`}
    >
      <div
        className="MainFilterMobileClose"
        onClick={() => {
          if (mobileFilterOpen && setMobileFilterOpen) {
            setMobileFilterOpen(false)
          }
        }}
      >
        <CloseIcon />
      </div>
      <div className="MainFilterHeader FlexRow">
        <span>Фильтры</span>
      </div>
      <div className="MainFilterContent">
        <div className="FilterField">
          <div className="FilterContentLabel">Номер телефона</div>
          <div className="InputWrapper">
            <MaskedInput
              mask="+7 000 000-00-00"
              value={phone}
              allowClear={true}
              onChange={({ unmaskedValue }) => changePhone(unmaskedValue)}
            />
          </div>
        </div>

        <div className="FilterField">
          <HallListFilter
            current_halls={[]}
            setCurrentHalls={([]) => noop}
            radio={{
              value: filter.hall,
              setValue: value => setFilter({ ...filter, hall: value })
            }}
          />
        </div>
        <div className="FilterField">
          <SwitchFilterComponent
            label="Помесячно"
            checked={filter.isMonthly}
            onChange={() =>
              setFilter({ ...filter, isMonthly: !filter.isMonthly })
            }
          />
        </div>
        <div className="FilterField">
          <div
            className={`FilterContentLabel${
              filter.isMonthly ? ` FilterContentLabelDisabled` : ``
            }`}
          >
            Период
          </div>
          <RangePickerComponent
            value={filter.dates}
            disabledDate={rangeRestrictions}
            disabled={filter.isMonthly}
            allowClear={true}
            onChange={value =>
              setFilter({ ...filter, dates: value || undefined })
            }
          />
        </div>
      </div>
      <div className="MainFilterApplyWrapper">
        <ButtonPrimary
          disabled={disabledApply}
          onClick={() => {
            if (mobileFilterOpen && setMobileFilterOpen) {
              setMobileFilterOpen(false)
            }
            setFilterState({
              ...filterState,
              isActive: true,
              isOpen: false,
              data: { ...filter, phone }
            })
          }}
        >
          Применить
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default LoyaltyGuestFilter
