import React from "react"

import GuestHistoryTabs from "./Tabs"
import GuestHistoryMetrics from "./Metrics"
import GuestHistoryCashbox from "./Cashbox"

import { GuestHistoryTabItems, GuestsHistoryFilterData } from "../types"
import GuestHistoryBets from "./Bets"

const GuestHistoryReports = ({
  activeTab,
  changeTab,
  filter
}: {
  activeTab: GuestHistoryTabItems
  changeTab: (value: any) => void
  filter: GuestsHistoryFilterData
}) => {
  return (
    <div className="GuestHistoryReportsWrapper">
      <GuestHistoryTabs tab={activeTab} toggleTab={changeTab} />
      {activeTab === GuestHistoryTabItems.metric && (
        <GuestHistoryMetrics filter={filter} />
      )}
      {activeTab === GuestHistoryTabItems.cashbox && (
        <GuestHistoryCashbox filter={filter} />
      )}
      {activeTab === GuestHistoryTabItems.bets && (
        <GuestHistoryBets filter={filter} />
      )}
    </div>
  )
}

export default GuestHistoryReports
