import { GuestHistoryTabItems } from "../../types"

export const tabsConfig = [
  {
    label: `Пользовательские метрики`,
    value: GuestHistoryTabItems.metric
  },
  {
    label: `История па кассе`,
    value: GuestHistoryTabItems.cashbox
  },
  {
    label: `История ставок`,
    value: GuestHistoryTabItems.bets
  }
]
