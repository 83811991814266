import React, { useMemo } from "react"

import { useSelector } from "react-redux"

import { getAccessList } from "store/access/reducer"
import { MenuItemsAccess } from "store/access/types"

import Tree from "Components/Tree"

import { menuConstants } from "./constants"

const CrudAccessMenu = ({
  requiredList,
  disabledAll,
  checked,
  onChange
}: {
  disabledAll: boolean
  requiredList: string[]
  checked: string[]
  onChange: (val: string[]) => void
}) => {
  const { menu_items } = useSelector(getAccessList)

  const menuForTree = useMemo(() => {
    const allDataArr = menu_items
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const itemToOption = (item: MenuItemsAccess[]) => {
      return item.map(option => {
        const isDisabled = requiredList.includes(option.name) || disabledAll
        return {
          title: option.label,
          key: option.name,
          disabled: isDisabled,
          children: !!option.inside ? itemToOption(option.inside) : []
        }
      })
    }
    const options = itemToOption(allDataArr)
    return options
  }, [menu_items, requiredList, disabledAll])
  return (
    <div>
      <div className="SubtitleFirstText MarginBottom6">
        {menuConstants.label}
      </div>
      <div className="CrudAccessMenuWrapper Padding16">
        {!!menuForTree && (
          <Tree
            treeData={menuForTree}
            checkedKeys={checked}
            setCheckedKeys={onChange}
          />
        )}
      </div>
    </div>
  )
}

export default CrudAccessMenu
