import React from "react"
import "./App.less"
import { ConfigProvider } from "antd"
import * as Sentry from "@sentry/browser"
import ruRu from "antd/es/locale/ru_RU"
import BaseLayout from "./Containers/BaseLayout"

if (
  process.env.NODE_ENV === "production" &&
  window.location.host === "lk.betboom.ru"
) {
  Sentry.init({
    dsn:
      "https://6c5c40ec68be6d600111adff7c8d6f17@sentry.offline.shelopes.com/7",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

const App = () => {
  return (
    <ConfigProvider locale={ruRu}>
      <div className="App">
        <div className="font-face-gm">
          <BaseLayout />
        </div>
      </div>
    </ConfigProvider>
  )
}

export default App
