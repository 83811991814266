import { SportTypeData, TableData } from "../Table/types"

export const tableMobileConstants = (data: TableData) => {
  const haveType = !!data.sport_type[0]?.sport_type
  return {
    bet_number: `Ставка № ${data.bet_number}`,
    bet_start: data.bet_start?.slice(0, -3),
    status: `Ставка → WIN`,
    description: `${haveType ? `${data.sport_type[0]?.sport_type} · ` : ``}${
      data.guest
    }`,
    bet: `${data.bet}`.replace(`.`, `,`),
    result: `${data.result}`.replace(`.`, `,`),
    arrow: `→`
  }
}

export const detailEvent = (data: SportTypeData) =>
  `${data.name} · ${data.bet_type_description} · Коэф. ${data.coefficient}`
