export const hallPickerModalConstants = {
  title: `Выберите клубы`,
  placeholder: `Введите номер клуба`,
  storage: `hall`,
  numberLabel: `№`,
  reset: `Сбросить`,
  all: `Все`,
  searchPlaceholder: `Введите номер клуба`,
  getPickerType: (isRadio: boolean) => (isRadio ? `Клуб` : `Клубы`),
  modalWidth: 576
}
