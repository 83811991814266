import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getAddressList, getCurrentHall } from "store/halls/selector"
import { IDevelopState } from "store/developer/types"

import {
  DashboardColors,
  DashboardDetailsSuperWins,
  DashboardItem,
  DashboardQiwiData,
  DashboardSocket,
  DashboardSocketEvents,
  DashboardSocketMessage,
  MainPageDataFilterType,
  StaticDashboardData
} from "./types"
import socketDashboard, {
  initialDashboardState,
  makeSuperWinsModalColumns,
  makeSuperWinTitle,
  shouldSuperWinHideMoney,
  useStaticDashboardApi
} from "./helper"

import { ReactComponent as FilterIcon } from "Components/icons/filter_arrow.svg"
import Filter, { MAIN_FILTER_NAME } from "./Filter"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { getDeveloperList } from "store/developer/selector"
import { addLink } from "store/developer/actions"
import { logoutUser } from "store/auth/actions"
import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"
import {
  getFilterStateFromLocalStorage,
  saveFilterInLocalStorage
} from "api/helpers"
import { isArray, isEmpty } from "lodash"
import mainConstants, {
  initialQiwiData,
  initialStaticDashboardData
} from "./constants"
import MainSuperWin from "./SuperWin"
import BaseFooter from "Components/Footer"
import QiwiModalComponent from "Components/QiwiModal"
import starsImg from "Components/icons/dashboard/stars.png"
import {
  checkArrayRole,
  formatDateDefault,
  isMobile,
  shiftMoscowGmtFirstSingle
} from "constants/index"
import { getGamesList } from "store/dictionary/selector"
import StaticDashboardModal from "Components/StaticDashboardModal"
import { useRequestParams } from "api/hooks"
import { SUPERWIN } from "../BaseLayout/constants"
import { activeTabKeyLs, mergeHistoryKeyLs } from "../Superwin/constants"
import { SuperwinReportTabs } from "../Superwin/types"
import { superwinText } from "./SuperWin/constants"

import DashboardComponent from "Components/DashboardNew"
import {
  DashboardOptions,
  DashboardPrefixOptions,
  defaultDescriptions
} from "Components/DashboardNew/constants"

const MainPageComponent = () => {
  const current_hall: (string | CheckboxValueType)[] = useSelector(
    getCurrentHall
  )
  const { history } = useRequestParams()
  const developer: IDevelopState = useSelector(getDeveloperList)
  const locations: {
    gmt: string
    region: string
  }[] = useSelector(getAddressList)
  const dispatch = useDispatch()
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))
  const logout: () => void = () => dispatch(logoutUser())

  const isPartnerEkt = useMemo(
    () => (localStorage.getItem(`role`) || ``) === "PARTNER-EKT",
    [current_hall]
  )

  const [dashboardAttendance, setDashboardAttendance] = useState<DashboardItem>(
    { ...initialDashboardState }
  )
  const [dashboardPayment, setDashboardPayment] = useState<DashboardItem>({
    ...initialDashboardState
  })
  const [dashboardPaymentOut, setDashboardPaymentOut] = useState<DashboardItem>(
    { ...initialDashboardState }
  )
  const [dashboardNewGuest, setDashboardNewGuest] = useState<DashboardItem>({
    ...initialDashboardState
  })
  const [dashboardSuperWins, setDashboardSuperWins] = useState<DashboardItem>({
    ...initialDashboardState,
    pending: true
  })
  const [qiwiData, setQiwiData] = useState<DashboardQiwiData>(initialQiwiData)
  const [statidDashboardData, setStaticDashboardData] = useState<
    StaticDashboardData
  >(initialStaticDashboardData)

  const games = useSelector(getGamesList)

  const socket = useRef<DashboardSocket | null>(null)

  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    MAIN_FILTER_NAME
  )

  const [filterState, setFilterState] = useState<MainPageDataFilterType>({
    isOpen: true,
    data: {
      halls: isEmpty(initFilterStateFromStorage)
        ? current_hall
        : initFilterStateFromStorage.halls,
      gmt: locations[0].gmt,
      shift: shiftMoscowGmtFirstSingle(locations[0].gmt)
    }
  })

  const { getSuperWinsData } = useStaticDashboardApi({
    halls: filterState.data.halls,
    gmt: filterState.data.gmt
  })

  const { superWinTitle, superWinHideMoney } = useMemo(
    () => ({
      superWinTitle: makeSuperWinTitle(filterState, dashboardSuperWins),
      superWinHideMoney: shouldSuperWinHideMoney(
        filterState,
        dashboardSuperWins
      )
    }),
    [filterState, dashboardSuperWins]
  )

  const pendingDashboard = (val: boolean) => {
    setDashboardPayment({ ...dashboardPayment, pending: val })
    setDashboardAttendance({ ...dashboardAttendance, pending: val })
    setDashboardPaymentOut({ ...dashboardPaymentOut, pending: val })
    setDashboardNewGuest({ ...dashboardNewGuest, pending: val })
    setQiwiData({ ...qiwiData, pending: true })
  }

  useEffect(() => {
    let socketCurrent: null | DashboardSocket = null
    const socketMessage = JSON.stringify({
      data: {
        halls: filterState.data.halls.map(el => Number(el)),
        gmt: Number(filterState.data.gmt),
        shift: filterState.data.shift,
        isShift: true
      }
    })
    pendingDashboard(true)
    if (socket.current && socket.current?.OPEN) {
      socketCurrent = socket.current
      socketCurrent.send(socketMessage)
    } else {
      socketCurrent = socketDashboard()
      socket.current = socketCurrent
      socketCurrent.onopen = () => {
        socketCurrent?.send(socketMessage)
      }
    }

    socketCurrent.onmessage = function(event) {
      const incomingMessage: DashboardSocketMessage = JSON.parse(event.data)
      if (!!incomingMessage && isArray(incomingMessage)) {
        const dashboardEvents = Object.values(DashboardSocketEvents)
        dashboardEvents.forEach(dashboardSocketEventsKey => {
          switch (dashboardSocketEventsKey) {
            case DashboardSocketEvents.Attendance:
              const attendance = incomingMessage.find(
                item => item.type === DashboardSocketEvents.Attendance
              )
              setDashboardAttendance({
                ...dashboardAttendance,
                data: !!attendance
                  ? { value: attendance.value, details: attendance.detail }
                  : dashboardAttendance.data,
                pending: false,
                isVisible: !!attendance,
                rendered: true
              })
              break
            case DashboardSocketEvents.Balance:
              const balance = incomingMessage.find(
                item => item.type === DashboardSocketEvents.Balance
              )
              setDashboardPaymentOut({
                ...dashboardPaymentOut,
                data: !!balance
                  ? { value: balance.value, details: balance.detail }
                  : dashboardPaymentOut.data,
                pending: false,
                isVisible: !!balance,
                rendered: true
              })
              break
            case DashboardSocketEvents.Payments:
              const payments = incomingMessage.find(
                item => item.type === DashboardSocketEvents.Payments
              )
              setDashboardPayment({
                ...dashboardPayment,
                data: !!payments
                  ? { value: payments.value, details: payments.detail }
                  : dashboardPayment.data,
                pending: false,
                isVisible: !!payments,
                rendered: true
              })
              break
            case DashboardSocketEvents.New_guests:
              const newGuests = incomingMessage.find(
                item => item.type === DashboardSocketEvents.New_guests
              )
              setDashboardNewGuest({
                ...dashboardNewGuest,
                data: !!newGuests
                  ? { value: newGuests.value, details: newGuests.detail }
                  : dashboardNewGuest.data,
                pending: false,
                isVisible: !!newGuests,
                rendered: true
              })
              break
          }
        })
      }
    }
  }, [filterState.data])

  useEffect(() => {
    return () => {
      if (socket.current) {
        socket.current.close
        socket.current = null
      }
    }
  }, [])

  useEffect(() => {
    let nextSuperWinsData: DashboardItem = {
      ...initialDashboardState,
      pending: false
    }
    setDashboardSuperWins(prevState => ({ ...prevState, pending: true }))
    getSuperWinsData()
      .then(({ data }) => {
        if (filterState.data.halls.length > 1) {
          nextSuperWinsData = {
            ...nextSuperWinsData,
            data: {
              value: 0,
              details: data,
              imageLink: starsImg,
              color: DashboardColors.SuperWinsStars
            }
          }
        } else if (data.length === 1) {
          nextSuperWinsData = {
            ...nextSuperWinsData,
            data: {
              value: data[0].win_sum,
              date: data[0].date,
              details: data
            }
          }
          const foundedGame = games.find(game => game.id === data[0].game_id)
          if (foundedGame?.background_color && foundedGame?.image) {
            nextSuperWinsData = {
              ...nextSuperWinsData,
              data: {
                ...nextSuperWinsData.data,
                color: foundedGame.background_color as string,
                imageLink: foundedGame.image
              }
            }
          }
        } else {
          nextSuperWinsData = {
            ...nextSuperWinsData,
            isVisible: false,
            data: {
              ...nextSuperWinsData.data,
              color: DashboardColors.SuperWinsStars,
              imageLink: starsImg
            }
          }
        }
        setDashboardSuperWins(nextSuperWinsData)
      })
      .catch(() => {
        setDashboardSuperWins(nextSuperWinsData)
      })
  }, [filterState.data])

  const [qiwiModal, toggleQiwiModal] = useState(false)
  const [staticDashboardModal, setStaticDashboardModal] = useState(false)

  const handleDashboardClick = useCallback((data: DashboardQiwiData) => {
    setQiwiData(data)
    toggleQiwiModal(true)
  }, [])

  const handleDashboardClickStatic = useCallback(
    (data: StaticDashboardData) => {
      setStaticDashboardModal(true)
      setStaticDashboardData(data)
    },
    []
  )

  const handleCloseStaticDashboardModal = useCallback(() => {
    setStaticDashboardModal(false)
  }, [])

  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(isMobile)

  const showRise = checkArrayRole(superwinText.item.roleAccessArr)

  const superWinTrigger = () =>
    handleDashboardClickStatic.bind(null, {
      data: dashboardSuperWins.data.details as DashboardDetailsSuperWins,
      title: mainConstants.dashboards.superWins.modalTitle,
      pending: false,
      columns: makeSuperWinsModalColumns(games, true),
      selectedHalls: filterState.data.halls,
      onRedirect: () => {
        saveFilterInLocalStorage(filterState.data, MAIN_FILTER_NAME)
        localStorage.setItem(activeTabKeyLs, SuperwinReportTabs.WINS_HISTORY)
        localStorage.setItem(mergeHistoryKeyLs, "true")
        history.push(SUPERWIN)
      }
    })()

  const superwinDate = dashboardSuperWins?.data?.date
    ? formatDateDefault(dashboardSuperWins.data.date)
    : ``

  const dashboardConfig = useMemo(() => {
    const config: any = []
    if (dashboardAttendance.isVisible) {
      config.push({
        type: DashboardOptions.visit_count,
        description: defaultDescriptions.today,
        value: dashboardAttendance.data.value,
        tooltipText: mainConstants.dashboards.attendance.tooltip,
        extraLoading: dashboardAttendance.pending,
        isCurrency: false,
        onClick: () => {
          const details = dashboardAttendance.data.details as any
          handleDashboardClick({
            data: details,
            title: mainConstants.dashboards.attendance.title,
            pending: dashboardAttendance.pending,
            color: `#D0EBFF`
          })
        }
      })
    }
    if (!isPartnerEkt && dashboardPayment.isVisible) {
      config.push({
        type: DashboardOptions.cashbox_result,
        description: defaultDescriptions.today,
        value: dashboardPayment.data.value,
        tooltipText: mainConstants.dashboards.payment.tooltip,
        extraLoading: dashboardPayment.pending,
        isCurrency: true,
        prefix: DashboardPrefixOptions.RUB,
        onClick: () => {
          const details = dashboardPayment.data.details as any
          handleDashboardClick({
            data: details,
            title: mainConstants.dashboards.payment.title,
            pending: dashboardPayment.pending,
            color: `#F3D9FA`
          })
        }
      })
    }
    if (dashboardNewGuest.isVisible) {
      config.push({
        type: DashboardOptions.new_registrations,
        description: defaultDescriptions.today,
        value: dashboardNewGuest.data.value,
        tooltipText: mainConstants.dashboards.newGuest.tooltip,
        extraLoading: dashboardNewGuest.pending,
        isCurrency: false,
        onClick: () => {
          const details = dashboardNewGuest.data.details as any
          handleDashboardClick({
            data: details,
            title: mainConstants.dashboards.newGuest.title,
            pending: dashboardNewGuest.pending,
            color: `#DBE4FF`
          })
        }
      })
    }
    if (!isPartnerEkt && dashboardPaymentOut.isVisible) {
      config.push({
        type: DashboardOptions.balance_payout,
        description: defaultDescriptions.today,
        value: dashboardPaymentOut.data.value,
        tooltipText: mainConstants.dashboards.qiwi.tooltip,
        extraLoading: dashboardPaymentOut.pending,
        isCurrency: true,
        prefix: DashboardPrefixOptions.RUB,
        onClick: () => {
          const details = dashboardPaymentOut.data.details as any
          handleDashboardClick({
            data: details,
            title: mainConstants.dashboards.qiwi.title,
            pending: dashboardPaymentOut.pending,
            color: `#FFE8CC`
          })
        }
      })
    }
    if (showRise && dashboardSuperWins.isVisible) {
      config.push({
        type: DashboardOptions.super_win,
        title: superWinTitle,
        description: superwinDate,
        value: dashboardSuperWins.data.value,
        tooltipText: mainConstants.dashboards.superWins.tooltip,
        extraLoading: dashboardSuperWins.pending,
        isCurrency: true,
        isString: false,
        prefix: DashboardPrefixOptions.RUB,
        onClick: superWinTrigger
      })
    }
    return config
  }, [
    dashboardAttendance,
    dashboardPaymentOut,
    dashboardPayment,
    dashboardNewGuest,
    dashboardSuperWins
  ])

  return (
    <div>
      <div className="FlexRow">
        <div>
          <div
            className={`MainPageContentWrapper ${
              filterState.isOpen
                ? `MainPageContentWrapperFilter`
                : `MainPageContentWrapperUnFilter`
            }`}
          >
            <div
              className="MobileFilterIcon"
              onClick={() => setMobileFilterOpen(true)}
            >
              <FilterMobileIcon />
            </div>
            {mobileFilterOpen && (
              <Filter
                filterState={filterState}
                setFilterState={setFilterState}
                locations={locations}
                current_hall={current_hall}
                initialGmt={locations[0].gmt}
                mobileFilterOpen={mobileFilterOpen}
                setMobileFilterOpen={setMobileFilterOpen}
              />
            )}
            <div className="MainPageContentRestrictions">
              <div className="MainPageTitle">{mainConstants.title}</div>
              <div className="FlexRow">
                <div
                  className={`MainPageFilterButtonWrapper ${
                    filterState.isOpen
                      ? `MainPageFilterButtonWrapperActive`
                      : `MainPageFilterButtonWrapperUnActive`
                  }`}
                  onClick={() =>
                    setFilterState({
                      ...filterState,
                      isOpen: !filterState.isOpen
                    })
                  }
                >
                  <FilterIcon />
                </div>
              </div>
              <DashboardComponent config={dashboardConfig} />
              <div className="MainSuperWinContent">
                <MainSuperWin
                  logout={logout}
                  developer={developer}
                  addDeveloperLink={addDeveloperLink}
                  filterData={filterState.data}
                  defaultOpen={isPartnerEkt}
                />
              </div>
            </div>
          </div>
          <BaseFooter />
        </div>
        <Filter
          filterState={filterState}
          setFilterState={setFilterState}
          locations={locations}
          current_hall={current_hall}
          initialGmt={locations[0].gmt}
        />
        {qiwiModal && (
          <QiwiModalComponent
            close={() => toggleQiwiModal(false)}
            data={
              (qiwiData.data as unknown) as {
                [p: string]: number
                hall: number
              }[]
            }
            title={qiwiData.title}
            color={qiwiData.color}
          />
        )}
        {staticDashboardModal && (
          <StaticDashboardModal
            onClose={handleCloseStaticDashboardModal}
            data={statidDashboardData}
          />
        )}
      </div>
    </div>
  )
}

export default MainPageComponent
