import React, { useCallback, useEffect, useMemo, useState } from "react"

import {
  getPaginationDefault,
  useRequestBody,
  useRequestParams
} from "api/hooks"

import ReportWithFilter from "Components/ReportWithFilter"
import { SideFilter } from "Components/SideFilter/SideFilter"
import NotActiveFilter from "Components/NotActiveFilter"
import { Filters } from "Components/SideFilter/types"
import { ReactComponent as EmptyFilter } from "./not_active.svg"

import { SuperwinFilterHistory, SuperwinReportState } from "../types"
import { SuperwinTabsComponent, SuperwinTitleComponent } from "../index"
import { mergeHistoryKeyLs } from "../constants"
import {
  SUPERWIN_FILTERS_HISTORY,
  SUPERWIN_FILTERS_HISTORY_WIN
} from "./constants"

import { emptyText, initialHistoryFilter } from "./constants"
import SuperwinHistoryNominalsFilter from "./Filters"
import HistoryTable from "./Table"
import { getFilterStateFromLocalStorage } from "api/helpers"
import { MAIN_FILTER_NAME } from "Containers/Main/Filter"
import { isMobile } from "constants/index"
import { isEmpty } from "lodash"

const historyFilters = [
  Filters.hallPickerRadio,
  Filters.customItem,
  Filters.rangePicker
]

const historyWinsFilters = [Filters.InfoPanel]

const HistoryContent = ({
  activeTab,
  toggleActiveTab,
  isSuperWinsHistory
}: SuperwinReportState) => {
  const { current_hall } = useRequestParams()
  const initialHistoryFilterValues = useMemo(() => {
    return initialHistoryFilter(
      `${current_hall}`,
      isSuperWinsHistory
        ? SUPERWIN_FILTERS_HISTORY_WIN
        : SUPERWIN_FILTERS_HISTORY,
      isSuperWinsHistory
    )
  }, [isSuperWinsHistory, current_hall, activeTab])

  const [filterHistory, setFilterHistory] = useState<SuperwinFilterHistory>(
    initialHistoryFilterValues
  )

  useEffect(() => {
    if (isSuperWinsHistory && localStorage.getItem(mergeHistoryKeyLs)) {
      const mainHistoryFilters = getFilterStateFromLocalStorage(
        {},
        MAIN_FILTER_NAME
      )
      if (!isEmpty(mainHistoryFilters)) {
        // eslint-disable-next-line
        const { shift, ...rest } = mainHistoryFilters
        setFilterHistory(prev => ({
          ...prev,
          isActive: true,
          mobileFilter: isMobile,
          data: { ...rest }
        }))
        localStorage.setItem(
          SUPERWIN_FILTERS_HISTORY_WIN,
          JSON.stringify(mainHistoryFilters)
        )
        localStorage.removeItem(mergeHistoryKeyLs)
      }
    }
  }, [isSuperWinsHistory])

  const setFilterHistoryCb = useCallback(
    value => {
      setFilterHistory(value)
    },
    [isSuperWinsHistory]
  )
  const { pagination, setPagination, sort, setSort } = useRequestBody()

  return (
    <ReportWithFilter
      filterComponent={
        <SideFilter
          filterName={
            isSuperWinsHistory
              ? SUPERWIN_FILTERS_HISTORY_WIN
              : SUPERWIN_FILTERS_HISTORY
          }
          filterState={filterHistory}
          setFilterState={setFilterHistoryCb}
          resetPagination={() => setPagination(getPaginationDefault())}
          filters={
            isSuperWinsHistory && filterHistory.data.halls
              ? historyWinsFilters
              : historyFilters
          }
          filterConfig={{
            clearRange: true
          }}
          getDisabled={filter =>
            isSuperWinsHistory
              ? !filter.halls?.length
              : !filter?.nominals?.length
          }
          CustomComponent={
            isSuperWinsHistory ? undefined : SuperwinHistoryNominalsFilter
          }
          infoPanelConfig={{
            alwaysMoscow: false,
            clearRange: true,
            disabledShift: true,
            withoutPeriod: true,
            withoutGmt: false,
            gmtSelect: false,
            shiftSlider: false
          }}
          initialForClear={initialHistoryFilterValues}
        />
      }
      mobileFilter={filterHistory.mobileFilter}
      isOpen={filterHistory.isOpen}
      toggleFilter={() =>
        setFilterHistory({
          ...filterHistory,
          isOpen: !filterHistory.isOpen
        })
      }
      toggleMobileFilter={() => {
        setFilterHistory({
          ...filterHistory,
          mobileFilter: true
        })
      }}
      pageContent={
        <>
          <SuperwinTitleComponent
            filter={filterHistory.data}
            isWin={isSuperWinsHistory}
          />
          <SuperwinTabsComponent
            activeTab={activeTab}
            toggleActiveTab={toggleActiveTab}
          />
          {filterHistory.isActive ? (
            <HistoryTable
              filter={filterHistory.data}
              pagination={pagination}
              setPagination={setPagination}
              setSort={setSort}
              sort={sort}
              isSuperWinsHistory={isSuperWinsHistory}
            />
          ) : (
            <NotActiveFilter
              icon={<EmptyFilter />}
              description={emptyText.filter}
            />
          )}
        </>
      }
      modalCmp={<div />}
    />
  )
}

export default HistoryContent
