import { IDictionaryState } from "./types"

import { STORE_DICTIONARY } from "./constants"

const init: IDictionaryState = {
  games: [],
  branch_halls: [],
  locations: {
    country: [],
    city: {},
    region: {}
  },
  transaction: {
    statuses: []
  },
  transfer: {
    halls: []
  }
}

export function dictionaryReducer(
  state: IDictionaryState = init,
  action: { type: string; payload: IDictionaryState }
): IDictionaryState {
  switch (action.type) {
    case STORE_DICTIONARY: {
      return { ...state, ...action.payload }
    }

    default:
      return state
  }
}
