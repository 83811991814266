import betRequest from "api/request"
import { IDevelopState } from "store/developer/types"
import { RouteComponentProps } from "react-router-dom"

import { MessageListProp, MessageItemProp } from "./types"

import { parseDateToBack } from "constants/index"

import { Moment } from "moment"

type GetMessageProps = {
  logout: () => void
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  history: RouteComponentProps["history"]
  // id: number | string
  setPending: (data: boolean) => void
  setData: (data: MessageListProp) => void
  pagination: { limit: number; offset: number }
  filter: {
    text: string | undefined
    date: [Moment, Moment] | undefined
  }
  type: string
}

const getMessageList = ({
  developer,
  addDeveloperLink,
  history,
  logout,
  setPending,
  type,
  setData,
  pagination,
  filter
}: GetMessageProps) => {
  setPending(true)
  const dateFilter = !!filter.date
    ? parseDateToBack({ date: filter.date, gmt: `3`, isObj: true })
    : undefined

  betRequest({
    developer,
    addDeveloperLink,
    history,
    logout,
    method: `POST`,
    url: `message/all`,
    requestBody: {
      ...pagination,
      type,
      filter: { date: dateFilter, text: filter.text }
    }
  })
    .then(
      ({
        data: { result },
        headers
      }: {
        data: { result: { read: MessageItemProp[]; unread: MessageItemProp[] } }
        headers: any
      }) => {
        setData({ data: result, pending: false, total: headers[`total-count`] })
      }
    )
    .catch(() => {
      setPending(false)
    })
}

export default getMessageList
