import React, { useCallback, useMemo, useState } from "react"

import { isMobile } from "constants/index"

import {
  getColumns,
  useDetailUserRequests,
  useDetailRequests,
  getInitialByData
} from "./helper"
import { MailingDetailTableProps, ModalFromDetailState } from "./types"
import { detailText, detailTableText } from "./constant"

import { ReactComponent as AddUserIcon } from "./icons/user_add.svg"
import { ReactComponent as PauseIcon } from "./icons/user_pause.svg"
import { ReactComponent as PlayIcon } from "./icons/user_play.svg"
import { ReactComponent as DeleteIcon } from "./icons/user_delete.svg"
import { ReactComponent as AttentionIcon } from "./icons/attention.svg"

import MailingModal from "Containers/Mailing/Modal"
import { modalDataInitial } from "Containers/Mailing/Modal/constant"

import TableComponent from "Components/Table"
import { ButtonFaded } from "Components/Button"
import MailingDeleteModal from "./DeleteModal"

const MailingDetailTable = ({
  refresh,
  refreshHalls,
  close,
  data,
  users = [],
  pending
}: MailingDetailTableProps) => {
  const count = useMemo(() => detailText.count(users.length), [users])
  const isFull = users.length === detailTableText.countLimit.value
  const isActive = data.active

  const refreshAll = () => {
    refresh()
    refreshHalls()
  }

  const { activeAll, deleteAll } = useDetailRequests(data)
  const { activeCurrent, deleteCurrent } = useDetailUserRequests(data.hall)
  const [modalOpen, toggleOpen] = useState<ModalFromDetailState>({
    open: false,
    initial: modalDataInitial
  })

  const [deleteModal, toggleDeleteModal] = useState(false)

  const columns = useMemo(
    () =>
      getColumns({
        hall: data.hall,
        refreshAll,
        activeCurrent,
        toggleOpen,
        deleteCurrent
      }),
    []
  )

  const pauseAllCb = useCallback(() => {
    activeAll().then(() => refreshAll())
  }, [data.active])

  const getInitialCb = useCallback(
    (id?: number) => getInitialByData(data, id),
    [data]
  )

  return (
    <div className="MailingDetailTableWrapper">
      <div className="MailingHallDetailLabelSpan">{count}</div>
      <TableComponent
        columns={columns}
        data={users}
        tableClassName="MailingDetailTable"
      />
      <div className={`MailingDetailTableButtons ${isMobile ? `` : `FlexRow`}`}>
        <div className="MailingDetailTableButtonsAdd">
          <ButtonFaded
            disabled={isFull || pending}
            onClick={() => {
              const initial = getInitialCb()
              toggleOpen({ open: true, initial })
            }}
          >
            <div className="MailingDetailTableButtonItem FlexRow">
              <AddUserIcon />
              <span>{detailTableText.buttons.add}</span>
            </div>
          </ButtonFaded>
          {isFull && (
            <div className="MailingDetailTableButtonsDescription FlexRow">
              <AttentionIcon />
              <span>{detailTableText.countLimit.text}</span>
            </div>
          )}
        </div>
        <ButtonFaded disabled={pending} onClick={pauseAllCb}>
          <div className="MailingDetailTableButtonItem FlexRow">
            {isActive ? <PauseIcon /> : <PlayIcon />}
            <span>
              {isActive
                ? detailTableText.buttons.pause
                : detailTableText.buttons.play}
            </span>
          </div>
        </ButtonFaded>
        <ButtonFaded disabled={pending} onClick={() => toggleDeleteModal(true)}>
          <div className="MailingDetailTableButtonItem FlexRow">
            <DeleteIcon />
            <span>{detailTableText.buttons.clear}</span>
          </div>
        </ButtonFaded>
      </div>
      <div>
        {modalOpen.open && (
          <MailingModal
            successRefresh={refreshAll}
            initial={modalOpen.initial}
            close={() => toggleOpen(prev => ({ ...prev, open: false }))}
          />
        )}
        {deleteModal && (
          <MailingDeleteModal
            hall={data.hall}
            cancel={() => toggleDeleteModal(false)}
            accept={() => {
              deleteAll().then(() => {
                close()
                refreshHalls()
              })
            }}
          />
        )}
      </div>
    </div>
  )
}

export default MailingDetailTable
