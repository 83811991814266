import { Moment } from "moment"
import { GuestsHistoryFilterData } from "Containers/GuestsHistory/types"

export type GuestHistoryCashboxTableProps = {
  filter: GuestsHistoryFilterData
}

export enum GuestHistoryCashboxTableDataColumnsCalculation {
  cash = "cash",
  noncash = "non_cash",
  transfer = "transfer"
}

export enum GuestHistoryCashboxTableDataColumnsType {
  in = "in",
  out = "out"
}

export type GuestHistoryCashboxTableDataColumns = {
  date: Moment
  type: GuestHistoryCashboxTableDataColumnsType
  sum: number
  calculation_type: GuestHistoryCashboxTableDataColumnsCalculation
}

export type GuestHistoryCashboxTableData = {
  in: number
  out: number
  data: GuestHistoryCashboxTableDataColumns[]
}

export type GuestHistoryCashboxTableState = {
  pending: boolean
  total: number
  data: GuestHistoryCashboxTableData
}
