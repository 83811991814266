import React, { useMemo } from "react"

import DashboardComponent from "Components/DashboardNew"

import { CashboxState } from "../../types"
import { getDashboardConfig } from "./constants"

type CashboxDashboardProps = {
  haveQiwiModal: boolean | number
  cashboxState: CashboxState
  toggleQiwiModal: (val: boolean) => void
}

const CashboxDashboard = ({
  cashboxState,
  toggleQiwiModal
}: CashboxDashboardProps) => {
  const config = useMemo(
    () => getDashboardConfig(cashboxState, toggleQiwiModal),
    [cashboxState]
  )
  return <DashboardComponent config={config} loading={cashboxState.pending} />
}

export default CashboxDashboard
