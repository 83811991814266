import React, { useMemo, useState } from "react"

import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"

import TooltipComponent from "Components/Tooltip"
import ExcelButton from "Components/ExcelButton"
import QiwiModalComponent from "Components/QiwiModal"
import DashboardComponent from "Components/DashboardNew"

import TitleProps from "./types"
import { titleText } from "./helpers"

import { getDateDescriptionLayout } from "constants/index"

const Title = ({
  exel,
  filterState,
  qiwiSum,
  total,
  qiwiData,
  title,
  withTotal,
  titleInfo,
  loading
}: TitleProps) => {
  const dateTitle = getDateDescriptionLayout({
    dates: filterState.data.dates,
    shift: filterState.data.shift,
    isShift: filterState.data.isShift,
    show: filterState.isActive
  })

  const isMobile = window.innerWidth < 921

  const [showQiwi, toggleQiwiModal] = useState<boolean>(false)

  const dashboardConfig = useMemo(
    () =>
      titleText.getDashboardConfig({
        value: qiwiSum,
        total,
        toggleQiwiModal,
        withTotal,
        loading
      }),
    [qiwiSum, loading]
  )

  return (
    <div className="NoncashPageHeader">
      <div className="FlexColumn Gap16">
        <div>
          <div className="NoncashPageTitleDescription">
            <div className="NoncashPageTitle FlexRow">
              <div className="NoncashPageTitleHeader">{title}</div>
              <div className="NoncashPageTitleInfo">
                <TooltipComponent
                  direction={isMobile ? `rightTop` : `bottom`}
                  title={titleInfo}
                  className="TooltipNoncashReport"
                >
                  <InfoIcon />
                </TooltipComponent>
              </div>
            </div>
            <div className="NoncashPageTitleExel">
              {exel && (
                <ExcelButton url={exel.url} requestBody={exel.requestBody} />
              )}
            </div>
          </div>
          {filterState.data.dates && (
            <div className="FlexRow NoncashContentPeriodWrapper">
              <div className="CashboxContentPeriod">{dateTitle}</div>
            </div>
          )}
        </div>
        <DashboardComponent config={dashboardConfig} />
      </div>
      {showQiwi && (
        <QiwiModalComponent
          close={() => toggleQiwiModal(false)}
          data={qiwiData}
          name="value"
          title={titleText.balance}
          color="#FFF4E8"
        />
      )}
    </div>
  )
}

export default Title
