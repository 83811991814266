import moment from "moment"

export const userPenaltyCardText = {
  set: "Выставлен",
  hallNumber: "Клуб №",
  rubles: "₽",
  winNull: "без повышения WIN",
  winExist: "с повышением WIN на",
  warning: "Предупреждение"
}

export const formatDayMonthYear = (date: string | null) =>
  date ? moment(date).format("DD.MM.YYYY") : "—"
