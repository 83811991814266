import React, { useCallback, useEffect, useState } from "react"

import ModalComponent from "Components/Modal"
import InputComponent from "Components/Input"
import ButtonDefault, { ButtonPrimary } from "Components/Button"

import { ReactComponent as BackIcon } from "Components/icons/back.svg"

import { GuestsHistoryComment } from "../../types"
import { ReactComponent as CommentIcon } from "../../img/comment.svg"
import { ReactComponent as CommentDeleteIcon } from "../../img/comment_delete.svg"
import { ReactComponent as CommentMobileIcon } from "../../img/comment_mobile.svg"

import { commentText } from "./constants"

import moment from "moment"
import { useRequest, useRequestParams } from "api/hooks"

const GuestHistoryComment = ({
  comments,
  phone,
  current
}: {
  comments: GuestsHistoryComment[]
  phone?: string
  current: string
}) => {
  const { requestCurrentHall } = useRequestParams()

  const [history, addToHistory] = useState<GuestsHistoryComment[]>([])
  const [currentComment, setCurrentComment] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    addToHistory(comments)
    setCurrentComment(current)
  }, [current, comments])

  const [editMode, toggleEditMode] = useState(false)
  const [modalOpen, toggleModal] = useState(false)

  const [modalComment, changeModalComment] = useState<string | undefined>(
    undefined
  )

  const { request: addComment } = useRequest({
    url: `v1/guests/history/comment`,
    method: `POST`,
    requestBody: {
      hall: requestCurrentHall,
      phone,
      comment: modalComment || null
    }
  })

  const { request: deleteComment } = useRequest({
    url: `v1/guests/history/comment`,
    method: `DELETE`,
    requestBody: { hall: requestCurrentHall, phone }
  })

  const close = useCallback(() => {
    changeModalComment(undefined)
    toggleModal(false)
  }, [])

  return (
    <div>
      <div
        className={`GuestHistoryCommentWrapper FlexRow ${
          !!currentComment ? `GuestHistoryCommentActiveWrapper ` : ``
        } ${editMode ? `GuestHistoryCommentEditWrapper` : ``}`}
        onClick={() => {
          if (!!currentComment) {
            toggleEditMode(!editMode)
          } else {
            toggleModal(true)
          }
        }}
      >
        <CommentIcon className="GuestHistoryCommentIcon" />
        <div className="GuestHistoryCommentText">
          {currentComment || commentText.defaultCurrent}
        </div>
        <div
          className="GuestHistoryCommentCrossWrapper"
          onClick={() => toggleModal(true)}
        >
          <CommentDeleteIcon />
        </div>
      </div>

      {modalOpen && (
        <ModalComponent width={548} title={commentText.modalTitle}>
          <div className="GuestHistoryCommentChangeModal">
            <div
              className="GuestHistoryCommentChangeModalMobileBack"
              onClick={close}
            >
              <BackIcon />
              <span>{commentText.back}</span>
            </div>
            <div className="GuestHistoryCommentChangeModalTitle">
              {commentText.title}
            </div>
            <div className="GuestHistoryCommentChangeModalLabel">
              {commentText.labelInput}
            </div>
            <InputComponent
              placeholder={commentText.placeholder}
              value={modalComment}
              onChange={({ target: { value } }) => changeModalComment(value)}
              greyBackground={true}
              maxLength={commentText.maxLength}
            />
            <div className="GuestHistoryCommentChangeModalLabel">
              {commentText.labelHistory}
            </div>
            <div className="GuestHistoryCommentChangeModalData">
              {history.map(
                (item, key) =>
                  !!item.text && (
                    <div
                      key={`comment_${item.date}_${key}`}
                      className="GuestHistoryCommentChangeModalDataRow"
                    >
                      <div className="FlexRow">
                        <CommentMobileIcon />
                        <div className="GuestHistoryCommentChangeModalDataItem">
                          {item.text}
                        </div>
                      </div>
                      <div className="GuestHistoryCommentChangeModalDataItemDate">
                        {moment(item.date, commentText.format).format(
                          commentText.smallFormat
                        )}
                      </div>
                    </div>
                  )
              )}
              {!history.length && (
                <div className="TextLabelText GuestHistoryCommentEmptyHistory">
                  {commentText.emptyHistory}
                </div>
              )}
            </div>
            <div className="Rectangle" />
            <div className="GuestHistoryCommentChangeModalButtons FlexRow">
              <ButtonPrimary
                disabled={!currentComment && !modalComment}
                onClick={() => {
                  if (!!modalComment) {
                    addComment().then(({ data: newHistory }) => {
                      addToHistory(newHistory)
                      setCurrentComment(modalComment)
                      changeModalComment(undefined)
                      toggleModal(false)
                    })
                  } else {
                    deleteComment().then(({ data: newHistory }) => {
                      addToHistory(newHistory)
                      setCurrentComment(undefined)
                      changeModalComment(undefined)
                      toggleModal(false)
                    })
                  }
                }}
              >
                {!!modalComment || !currentComment
                  ? commentText.save
                  : commentText.add}
              </ButtonPrimary>
              <ButtonDefault onClick={close}>
                {commentText.cancel}
              </ButtonDefault>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  )
}

export default GuestHistoryComment
