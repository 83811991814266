import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useRequest } from "api/hooks"

import dashboardTextConfig, { getConfig } from "./helper"

import DashboardComponent from "Components/DashboardNew"
import {
  DashboardOptions,
  defaultDescriptions,
  defaultMoreText
} from "Components/DashboardNew/constants"

import { LoyaltyGuestDashboardType, LoyaltyGuestFilterDataType } from "../types"

const GuestDashboard = ({
  data,
  pending,
  filter
}: {
  pending: boolean
  data: LoyaltyGuestDashboardType
  filter: LoyaltyGuestFilterDataType
}) => {
  const dashboardConfig = useMemo(() => getConfig(data), [data])

  const deps = [filter.hall, filter.phone]

  const [promote, setPromote] = useState<{
    pending: boolean
    value: undefined | null | number
  }>({ pending: false, value: undefined })

  useEffect(() => {
    setPromote({ ...promote, value: undefined })
  }, deps)

  const { request } = useRequest({
    url: `reports/guestinfo/nextlevel`,
    method: `POST`,
    requestBody: { hall: filter.hall, phone: `7${filter.phone}` }
  })
  const getPromote = useCallback(() => {
    setPromote({ ...promote, pending: true })
    request()
      .then(({ data: { nextLevel } }: { data: { nextLevel: number } }) =>
        setPromote({ pending: false, value: nextLevel })
      )
      .catch(() => setPromote({ ...promote, pending: false }))
  }, deps)
  return (
    <div className="FlexRow GuestDashboardWrapper">
      <DashboardComponent
        loading={pending}
        withMore={true}
        config={[
          ...dashboardConfig,
          {
            type: DashboardOptions.levelup_status,
            description: defaultDescriptions.now,
            tooltipText: promote.value
              ? dashboardTextConfig.nextLevel.description
              : ``,
            value: promote.value || null,
            extraLoading: promote.pending,
            isCurrency: true,
            isString: true,
            moreToggle: getPromote,
            moreText: defaultMoreText.specify
          }
        ]}
      />
    </div>
  )
}

export default GuestDashboard
