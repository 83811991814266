import React, { useState } from "react"
import { Moment } from "moment"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"
import { RangePickerComponent } from "Components/DatePicker"
import InputComponent from "Components/Input"
import { ButtonPrimary } from "Components/Button"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"

export type MessagesRouteFilterDataType = {
  text: string | undefined
  date: [Moment, Moment] | undefined
  isActive: boolean
}

type MessagesRouteFilterProps = {
  filterState: MessagesRouteFilterDataType
  setFilterState: (data: MessagesRouteFilterDataType) => void
  mobileFilterOpen?: boolean
  setMobileFilterOpen?: (data: boolean) => void
  isOpen: boolean
}

const MessagesRouteFilter = ({
  filterState,
  setFilterState,
  setMobileFilterOpen,
  mobileFilterOpen,
  isOpen
}: MessagesRouteFilterProps) => {
  const [filter, setFilter] = useState<{
    text: string | undefined
    date: [Moment, Moment] | undefined
  }>({ text: filterState.text, date: filterState.date })
  return (
    <div className="MessagesRouteFilterWrapper">
      <div
        className={`
        ${mobileFilterOpen ? `MainFilterMobile` : `MainFilterWrapper`}
        ${isOpen ? `MainFilterWrapperActive` : `MainFilterWrapperUnActive`}`}
      >
        <div
          className="MainFilterMobileClose"
          onClick={() => {
            if (mobileFilterOpen && setMobileFilterOpen) {
              setMobileFilterOpen(false)
            }
          }}
        >
          <CloseIcon />
        </div>
        <div className="MainFilterHeader FlexRow">
          <span>Фильтры</span>
          {(!!filterState.date || !!filterState.text) && (
            <span
              className="MainFilterReset"
              onClick={() => {
                setFilter({ text: undefined, date: undefined })
                setFilterState({
                  date: undefined,
                  text: undefined,
                  isActive: true
                })
              }}
            >
              Сбросить
            </span>
          )}
        </div>
        <div className="MainFilterContent">
          <div>
            <div className="FilterContentLabel">Дата или период отчёта</div>
            <RangePickerComponent
              value={filter.date}
              allowClear={true}
              onChange={value =>
                setFilter({ ...filter, date: value || undefined })
              }
            />
          </div>
          <div className="MessagesRouteFilterContent">
            <div className="FilterContentLabel">Текст</div>
            <InputComponent
              value={filter.text}
              onChange={({ target: { value } }) =>
                setFilter({ ...filter, text: value || undefined })
              }
              allowClear={true}
              placeholder="Поиск..."
              suffix={<SearchIcon />}
            />
          </div>
        </div>
        <div className="MainFilterApplyWrapper">
          <ButtonPrimary
            disabled={
              filter.date === filterState.date &&
              filter.text === filterState.text
            }
            onClick={() => {
              setFilterState({
                date: filter.date,
                text: filter.text,
                isActive: true
              })
              if (mobileFilterOpen && setMobileFilterOpen) {
                setMobileFilterOpen(false)
              }
            }}
          >
            Применить
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

export default MessagesRouteFilter
