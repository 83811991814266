import React, { useState } from "react"

import { ReactComponent as RiseIcon } from "../../icons/btn.svg"
import { ReactComponent as VipIcon } from "../../icons/crown_24.svg"
import { ReactComponent as MainIcon } from "../../icons/club_24.svg"
import { rowType } from "../../types"

import { ManageListMobileItemProps, SuperwinMobileListProps } from "./types"
import { mobileListText, getMobileListItemText } from "./constants"

const ManageListMobileItem = ({
  row,
  setModal,
  is_vip,
  hall
}: ManageListMobileItemProps) => {
  return (
    <div className="FlexColumn ManageListMobileItemWrapper">
      <div
        className={`GridCenter ManageListMobileItemTitle ${
          is_vip
            ? `ManageListMobileItemTitleVip`
            : `ManageListMobileItemTitleMain`
        }`}
      >
        <div className="FlexRow Gap8">
          {is_vip ? <VipIcon /> : <MainIcon />}
          <div className="TextDefaultBold PaddingTop2">
            {mobileListText.getZone(is_vip)}
          </div>
        </div>
      </div>
      {row.map(rowItem => {
        const text = getMobileListItemText(rowItem)
        return (
          <div
            className="FlexRow ManageListMobileItemRow"
            key={`row_item_${rowItem.nominal}_${is_vip}`}
          >
            <div className="ManageListMobileItemRowItem ManageListMobileItemRowFirst TextOne">
              {text.nominal}
            </div>
            <div className="ManageListMobileItemRowItem FlexRow Gap8">
              <div className="PaddingTop4 TextOne">{text.current}</div>
              <div
                onClick={() =>
                  setModal({
                    data: rowItem,
                    hall,
                    is_vip
                  })
                }
              >
                <RiseIcon />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const ManageListMobile = ({ data, setModal }: SuperwinMobileListProps) => {
  const [activeHallKey, setActiveHallKey] = useState<number>(0)
  const activeData = data[activeHallKey]
  return (
    <div className="FlexColumn Gap16">
      <div className="ManageListMobileHallPicker FlexRow Gap8 MarginTop16">
        {data.map((item, key) => {
          const isActive = key === activeHallKey
          return (
            <div
              className={`FlexRow Gap4 ManageListMobileHallItem ${
                isActive ? `ManageListMobileHallItemActive` : ``
              }`}
              key={`mobile_hall_${item.hall}`}
              onClick={() => setActiveHallKey(key)}
            >
              <span className="TableHeader Grey600Color">
                {mobileListText.hall}
              </span>
              <span className="TableHeader">{item.hall}</span>
            </div>
          )
        })}
      </div>
      {!!activeData && (
        <div className="ManageListMobileData Gap16 FlexColumn">
          <ManageListMobileItem
            is_vip={false}
            row={activeData[rowType.MAIN]}
            setModal={setModal}
            hall={data[activeHallKey]?.hall}
          />
          {!!activeData[rowType.VIP] && (
            <ManageListMobileItem
              is_vip={true}
              row={activeData[rowType.VIP]}
              setModal={setModal}
              hall={data[activeHallKey]?.hall}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default ManageListMobile
