import React, { useState } from "react"

import betRequest from "api/request"

import { ReactComponent as DownloadExcelMiniIcon } from "Components/icons/excel_icon.svg"
import { ButtonIcon } from "Components/Button"

import { ExcelButtonProps } from "./types"
import { exelButtonText } from "./helpers"

import { useRequestParams } from "api/hooks"

const ExcelButton: React.FC<ExcelButtonProps> = ({
  disabled,
  requestBody,
  url,
  minimalist,
  onlyIcon,
  alternativeClick
}) => {
  const { history, developer, addDeveloperLink, logout } = useRequestParams()

  const [excelPending, setExcelPending] = useState<boolean>(false)

  const isDisabled = excelPending || disabled

  const click = () => {
    setExcelPending(true)
    betRequest({
      method: `POST`,
      url,
      requestBody: requestBody,
      history,
      logout,
      developer,
      addDeveloperLink
    })
      .then(
        ({
          data
        }: {
          data: {
            url: string
            name: string
          }
        }) => {
          setExcelPending(false)
          if (process.env.REACT_APP_AUTH_URL && data.name && data.url) {
            const baseUrl = process.env.REACT_APP_AUTH_URL.replace(
              "/api/site",
              ""
            )
            const fileUrl = `${baseUrl}${data.url}`
            const link = document.createElement("a")
            link.href = fileUrl
            link.download = data.name
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
          setExcelPending(false)
        }
      )
      .catch(() => setExcelPending(false))
  }

  return !minimalist ? (
    <ButtonIcon
      className="ButtonIcon ButtonIcon_excel"
      icon={<DownloadExcelMiniIcon />}
      disabled={isDisabled}
      onClick={() => {
        if (!!alternativeClick) {
          alternativeClick()
        } else if (!isDisabled) {
          click()
        }
      }}
    >
      {exelButtonText.upload}
    </ButtonIcon>
  ) : (
    <div
      className={`ButtonMiniExcel FlexRow ${
        isDisabled ? `ButtonMiniExcelDisabled` : ``
      } ${onlyIcon ? `ButtonMiniExcelOnlyIcon` : ``}`}
      onClick={() => {
        if (!!alternativeClick) {
          alternativeClick()
        } else if (!isDisabled) {
          click()
        }
      }}
    >
      <DownloadExcelMiniIcon />
      {!onlyIcon && (
        <span className="ButtonTextSecond">{exelButtonText.upload}</span>
      )}
    </div>
  )
}

export default ExcelButton
