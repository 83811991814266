import { getFilterStateFromLocalStorage } from "api/helpers"
import { isEmpty } from "lodash"
import { AccessFilters, AccessStatuses } from "./types"

export const HALL_ACCESS_FILTER = `HALL_ACCESS_FILTER`

export const StatusNames = {
  [AccessStatuses.active]: `Активен`,
  [AccessStatuses.paused]: `Приостановлен`,
  [AccessStatuses.resuming]: `Возобновление`
}

export const initialFilter = (current_halls: number[]): AccessFilters => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    HALL_ACCESS_FILTER,
    true
  )

  const haveFilterFromStorage = !isEmpty(initFilterStateFromStorage)

  const defaultData = {
    halls: current_halls,
    search: undefined,
    status: 0
  }

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: haveFilterFromStorage,
    data: haveFilterFromStorage ? initFilterStateFromStorage : defaultData
  }
}

export const accessBaseConstants = {
  title: `Доступ к кабинету`,
  tooltip: `Раздел позволяет создать учетную запись для входа в личный кабинет и другие партнёрские сервисы сотрудникам клуба. Вы можете воспользоваться готовыми шаблонами, в которых можно изменить доступность к разделам или сервисам, либо настроить свой шаблон выбрав в списке шаблонов “Сотрудник клуба”. Изменённые шаблоны сохраняются и распространяются на все учетные записи, которым был назначен данный шаблон.`,
  addButton: `Добавить доступ`
}
