import React, { useState } from "react"

import moment from "moment"

import { SearchTableHeader } from "Components/Table"
import { HeaderTypesList } from "Components/Table/types"
import ModalComponent from "Components/Modal"
import { ButtonPrimary } from "Components/Button"

import { ReactComponent as OrderedIcon } from "Components/icons/circle_blue16.svg"
import { ReactComponent as DoneIcon } from "Components/icons/circle_green16.svg"

import {
  columnCreatorProps,
  HistoryReportProps,
  SmsRequestStatuses
} from "../types"
import CancelButton from "../CancelButton"
import { smsConstants } from "../constants"

const CommentModal = ({ comment }: { comment: string }) => {
  const [open, toggleModal] = useState(false)

  return (
    <div>
      <div className="LinkText HoverItem" onClick={() => toggleModal(true)}>
        {smsConstants.tableComment.more}
      </div>
      {open && (
        <ModalComponent
          title="Comment"
          width={480}
          className="SmsHistoryCommentModal"
        >
          <div className="FlexColumn Gap16 Padding32">
            <div className="HeadlineSecondText">
              {smsConstants.tableComment.title}
            </div>
            <div className="HistoryTableCommentModal">{comment}</div>
            <div className="PaddingTop8">
              <ButtonPrimary onClick={() => toggleModal(false)}>
                {smsConstants.tableComment.close}
              </ButtonPrimary>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  )
}

const formatToDate = (time: string) =>
  !!time ? moment(time).format("DD.MM.YYYY") : ""

const formatToTime = (time: string) =>
  !!time ? moment(time).format("HH:mm") : ""

export const historyColumnsCreator = ({
  search,
  setSearch,
  requestStatuses,
  historyState,
  setHistoryState
}: columnCreatorProps) => {
  const requestStatusesById: Record<string, any> = {}
  requestStatuses.forEach(
    statusObj => (requestStatusesById[`${statusObj.id}`] = statusObj)
  )

  const getStatusStyle = (value: string) => {
    return {
      color: requestStatusesById[value]?.color ?? "",
      backgroundColor: requestStatusesById[value]?.background
        ? `rgb(${requestStatusesById[value]?.background})`
        : ``
    }
  }

  const renderDate = (value: string) => (
    <div className="SmsDateTimeCell">
      <div className="SmsDate">{formatToDate(value)}</div>
      <div className="SmsTime">{formatToTime(value)}</div>
    </div>
  )

  const statusesForSearch = requestStatuses.filter(
    statusObj => statusObj.id !== SmsRequestStatuses.gettingStatusesInfo
  )

  return [
    {
      title: () => (
        <div className="SmsHistoryHeaderGroupContainer">
          <OrderedIcon />
          <div>{smsConstants.tableColumns.orderedTitle}</div>
        </div>
      ),
      className: "SmsHistoryHeaderGroupCell SmsHistoryHeaderGroupCellOrdered",
      children: [
        {
          title: smsConstants.tableColumns.idText,
          dataIndex: smsConstants.tableColumnsKeys.id,
          className: "SmsHistoryColumn SmsHistoryColumnRequested",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.id,
          width: 104,
          minWidth: 104
        },
        {
          title: () => (
            <SearchTableHeader
              search={search["requestDate"]}
              setSearch={value =>
                setSearch({
                  ...search,
                  requestDate: value
                    ? [
                        `${moment(value[0]).format("YYYY-MM-DD")}`,
                        `${moment(value[1]).format("YYYY-MM-DD")}`
                      ]
                    : undefined
                })
              }
              title={smsConstants.tableColumns.requestDateText}
              type={HeaderTypesList.RangeDateSearch}
            />
          ),
          dataIndex: smsConstants.tableColumnsKeys.requestDate,
          className:
            "SmsHistoryColumn SmsHistoryColumnRequested SmsHistoryColumnRequestedDate",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.requestDate,
          width: 176,
          minWidth: 176,
          render: renderDate
        },
        {
          title: smsConstants.tableColumns.favoriteSendDateText,
          dataIndex: smsConstants.tableColumnsKeys.favoriteSendDate,
          className: "SmsHistoryColumn SmsHistoryColumnRequested",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.favoriteSendDate,
          width: 176,
          minWidth: 176,
          render: renderDate
        },
        {
          title: () => (
            <SearchTableHeader
              search={search["status"]}
              setSearch={value => setSearch({ ...search, status: value })}
              title={smsConstants.tableColumns.statusText}
              type={HeaderTypesList.SelectSearch}
              selectOptions={statusesForSearch.map(status => {
                return { label: status.name, value: status.id }
              })}
              popupClassName="SMS_statusOptions"
              passedClassName="SMS_statusSelected"
            />
          ),
          dataIndex: smsConstants.tableColumnsKeys.status,
          className:
            "SmsHistoryColumn SmsHistoryColumnRequested SmsHistoryColumnRequestedStatus",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.status,
          width: 136,
          minWidth: 136,
          maxWidth: 136,
          render: (value: string) => {
            return (
              <div style={getStatusStyle(value)} className="SmsStatusCell">
                {requestStatusesById[value]?.name}
              </div>
            )
          }
        },
        {
          title: smsConstants.tableColumns.commentText,
          dataIndex: smsConstants.tableColumnsKeys.comment,
          className: "SmsHistoryColumn SmsHistoryColumnRequested",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.comment,
          width: 224,
          minWidth: 224,
          render: (commentText: string) => {
            const comment = String(commentText)?.slice(0, 100)
            const commentHaveMore = String(commentText)?.slice(100)?.length > 0
            return (
              <div className="HistoryTableComment">
                {comment}
                {commentHaveMore && <CommentModal comment={commentText} />}
              </div>
            )
          }
        }
      ]
    },
    {
      title: () => (
        <div className="SmsHistoryHeaderGroupContainer">
          <DoneIcon />
          <div>{smsConstants.tableColumns.doneTitle}</div>
        </div>
      ),
      className: "SmsHistoryHeaderGroupCell SmsHistoryHeaderGroupCellDone",
      children: [
        {
          title: smsConstants.tableColumns.simpleText,
          dataIndex: smsConstants.tableColumnsKeys.text,
          className: "SmsHistoryColumn SmsHistoryColumnDone",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.text,
          width: 272,
          render: (text: string) => (
            <div className="SmsHistoryColumnLargeText">{text}</div>
          )
        },
        {
          title: smsConstants.tableColumns.sendedText,
          dataIndex: smsConstants.tableColumnsKeys.sendedText,
          className: "SmsHistoryColumn SmsHistoryColumnDone",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.sendedText,
          width: 280,
          render: (sendedText: string) => (
            <div className="SmsHistoryColumnLargeText">{sendedText}</div>
          )
        },
        {
          title: () => (
            <SearchTableHeader
              search={search["sendDate"]}
              setSearch={value =>
                setSearch({
                  ...search,
                  sendDate: value
                    ? [
                        `${moment(value[0]).format("YYYY-MM-DD")}`,
                        `${moment(value[1]).format("YYYY-MM-DD")}`
                      ]
                    : undefined
                })
              }
              title={smsConstants.tableColumns.sendDateText}
              type={HeaderTypesList.RangeDateSearch}
            />
          ),
          dataIndex: smsConstants.tableColumnsKeys.sendDate,
          className:
            "SmsHistoryColumn SmsHistoryColumnDone SmsHistoryColumnRequestedDateDone",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.sendDate,
          width: 280,
          minWidth: 280,
          render: renderDate
        },
        {
          title: smsConstants.tableColumns.smsCountText,
          dataIndex: smsConstants.tableColumnsKeys.smsCount,
          className: "SmsHistoryColumn SmsHistoryColumnDone",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.smsCount,
          width: 136,
          minWidth: 136
        },
        {
          title: smsConstants.tableColumns.smsSendedCountText,
          dataIndex: smsConstants.tableColumnsKeys.smsSendedCount,
          className: "SmsHistoryColumn SmsHistoryColumnDone",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.smsSendedCount,
          width: 136,
          minWidth: 136
        },
        {
          title: smsConstants.tableColumns.walletCountText,
          dataIndex: smsConstants.tableColumnsKeys.walletCount,
          className: "SmsHistoryColumn SmsHistoryColumnDone",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.walletCount,
          width: 136,
          minWidth: 136
        },
        {
          title: smsConstants.tableColumns.costText,
          dataIndex: smsConstants.tableColumnsKeys.cost,
          className: "SmsHistoryColumn SmsHistoryColumnDone",
          showSorterTooltip: false,
          key: smsConstants.tableColumnsKeys.cost,
          width: 136,
          minWidth: 136
        },
        {
          className: "SmsHistoryColumn SmsHistoryColumnDone",
          key: "actions",
          width: 170,
          minWidth: 170,
          render: (_: string, record: HistoryReportProps) => {
            return (
              record.status === SmsRequestStatuses.matching && (
                <CancelButton
                  historyState={historyState}
                  setHistoryState={setHistoryState}
                  requestId={record.id}
                />
              )
            )
          }
        }
      ]
    }
  ]
}
