import React, { useState } from "react"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"
import { ButtonPrimary } from "Components/Button"
import { ChangePhoneFilterDataType, ChangePhoneFilterType } from "../types"
import { RangePickerComponent } from "Components/DatePicker"
import InputComponent from "Components/Input"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"

const Filter = ({
  filterState,
  setFilterState,
  mobileFilterOpen,
  clearPagination,
  setMobileFilterOpen
}: {
  filterState: ChangePhoneFilterType
  setFilterState: (data: ChangePhoneFilterType) => void
  mobileFilterOpen?: boolean
  clearPagination: () => void
  setMobileFilterOpen?: (data: boolean) => void
}) => {
  const [filter, setFilter] = useState<ChangePhoneFilterDataType>(
    filterState.data
  )
  const showClear = !!filterState.data.text || !!filterState.data.dates
  return (
    <div
      className={`
      ${mobileFilterOpen ? `MainFilterMobile` : `MainFilterWrapper`}
      ${
        filterState.isOpen
          ? `MainFilterWrapperActive`
          : `MainFilterWrapperUnActive`
      }`}
    >
      <div
        className="MainFilterMobileClose"
        onClick={() => {
          if (mobileFilterOpen && setMobileFilterOpen) {
            setMobileFilterOpen(false)
          }
        }}
      >
        <CloseIcon />
      </div>
      <div className="MainFilterHeader FlexRow">
        <span>Фильтры</span>
        {showClear && (
          <span
            className="MainFilterReset"
            onClick={() => {
              setFilter({ text: undefined, dates: undefined })
              setFilterState({
                data: {
                  dates: undefined,
                  text: undefined
                },
                isOpen: true
              })
            }}
          >
            Сбросить
          </span>
        )}
      </div>
      <div className="MainFilterContent">
        <div>
          <div className="FilterContentLabel">Дата или период отчёта</div>
          <RangePickerComponent
            value={filter.dates}
            allowClear={true}
            onChange={value =>
              setFilter({ ...filter, dates: value || undefined })
            }
          />
        </div>
        <div className="MessagesRouteFilterContent">
          <div className="FilterContentLabel">Поиск</div>
          <InputComponent
            value={filter.text}
            onChange={({ target: { value } }) =>
              setFilter({ ...filter, text: value || undefined })
            }
            allowClear={true}
            placeholder="Поиск..."
            suffix={<SearchIcon />}
          />
        </div>
      </div>
      <div className="MainFilterApplyWrapper">
        <ButtonPrimary
          disabled={!filter.dates && !filter.text && !showClear}
          onClick={() => {
            if (mobileFilterOpen && setMobileFilterOpen) {
              setMobileFilterOpen(false)
            }
            setFilterState({ ...filterState, data: filter })
            clearPagination()
          }}
        >
          Применить
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default Filter
