import React, { useEffect, useMemo, useState } from "react"

import { MainFilterData } from "../types"

import { HallListFilter } from "Components/HallPicker/HallPickerModal"
import { ButtonPrimary } from "Components/Button"
import { RadioGroupComponent } from "Components/Radio"
import SliderComponent from "Components/Slider"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

import {
  getFilterStateFromLocalStorage,
  locationsByGmt,
  saveFilterInLocalStorage
} from "api/helpers"
import { uniqBy } from "lodash"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"
import { MainFilterProps } from "./types"
import {
  MOSCOW_GMT_VALUE,
  MOSCOW_GMT,
  SHIFT_LIMIT_MIN,
  SHIFT_LIMIT_MAX,
  HOURS_PER_DAY,
  shiftMoscowGmtFirstSingle
} from "constants/index"

export const MAIN_FILTER_NAME = "mainFilter"

export const LocationLabel = ({
  gmt,
  region
}: {
  gmt: string
  region: string
}) => (
  <div>
    <span className="MainFilterLocationLabel">{region}</span>
    <span className="MainFilterGmtLabel">{` UTC${Number(gmt) > 0 ? `+` : `-`}${
      Number(gmt) > 9 ? gmt : `0${gmt}`
    }:00`}</span>
  </div>
)

const MainFilter = ({
  current_hall,
  filterState,
  setFilterState,
  mobileFilterOpen,
  setMobileFilterOpen
}: MainFilterProps) => {
  const [modalState, setModalState] = useState<MainFilterData>(
    getFilterStateFromLocalStorage(
      {
        gmt: filterState.data.gmt,
        shift: filterState.data.shift,
        halls: filterState.data.halls
      },
      MAIN_FILTER_NAME
    )
  )

  const hallAddressesStorage: string =
    localStorage.getItem(`hallsAddress`) || ``
  const hallAddresses: {
    [key: string]: { gmt: string; region: string }
  } = hallAddressesStorage ? JSON.parse(hallAddressesStorage) : []

  const addresses: { gmt: string; region: string }[] = useMemo(
    () =>
      uniqBy(
        (modalState.halls.length
          ? modalState.halls
          : current_hall
        ).map((item: string | CheckboxValueType) =>
          locationsByGmt(hallAddresses[`${item}`].gmt || ``)
        ),
        `gmt`
      ),
    [modalState.halls]
  )

  useEffect(() => {
    const checkNewLocations =
      addresses.filter(item => item.gmt === modalState.gmt).length > 0
    if (!checkNewLocations)
      setModalState({ ...modalState, gmt: addresses[0].gmt })
  }, [addresses])

  const haveManyLocations = addresses.length > 1

  const canApply = modalState.halls.length

  const options = addresses.map(item => ({
    value: item.gmt,
    label: item.region,
    customLabel: <LocationLabel {...item} />
  }))

  useEffect(() => {
    if (modalState.gmt) {
      setModalState(prev => ({
        ...prev,
        shift: shiftMoscowGmtFirstSingle(modalState.gmt)
      }))
    }
  }, [modalState.gmt])

  const currentFilter = options.filter(item => item.value === modalState.gmt)
  const locationLabelForHour = currentFilter[0] ? currentFilter[0].label : `-`

  const moscowHour =
    modalState.shift - Number(modalState.gmt) + MOSCOW_GMT_VALUE
  const moscowTimeForHour =
    moscowHour >= 0 ? moscowHour : HOURS_PER_DAY + moscowHour

  return (
    <div>
      <div
        className={`
        ${mobileFilterOpen ? `MainFilterMobile` : `MainFilterWrapper`}
        ${
          filterState.isOpen
            ? `MainFilterWrapperActive`
            : `MainFilterWrapperUnActive`
        }`}
      >
        <div
          className="MainFilterMobileClose"
          onClick={() => {
            if (mobileFilterOpen && setMobileFilterOpen) {
              setMobileFilterOpen(false)
            }
          }}
        >
          <CloseIcon />
        </div>
        <div className="MainFilterHeader FlexRow">
          <span>Фильтры</span>
        </div>
        <div className="MainFilterContent">
          <HallListFilter
            current_halls={modalState.halls}
            setCurrentHalls={halls => {
              if (!halls.length) {
                setModalState({
                  ...modalState,
                  halls
                })
              } else {
                setModalState({
                  ...modalState,
                  halls
                })
              }
            }}
          />
          {haveManyLocations && (
            <div>
              <span className="MainFilterContentLabel">Часовой пояс</span>
              <div className="MainFilterGmtWrapper">
                <RadioGroupComponent
                  options={options}
                  value={modalState.gmt}
                  onChange={({ target: { value } }) =>
                    setModalState({ ...modalState, gmt: value })
                  }
                />
              </div>
            </div>
          )}
          <span className="MainFilterContentLabel">Начало периода</span>
          <div className="MainFilterHour">
            <SliderComponent
              min={SHIFT_LIMIT_MIN}
              max={SHIFT_LIMIT_MAX}
              periodType={true}
              defaultValue={modalState.shift}
              value={SHIFT_LIMIT_MAX - modalState.shift}
              onChange={(value: number) => {
                setModalState({ ...modalState, shift: SHIFT_LIMIT_MAX - value })
              }}
            />
          </div>
          <div className="MainFilterGmtHourWrapper FlexRow">
            <div className="MainFilterGmtHour">{`с ${modalState.shift}:00 (${locationLabelForHour}) `}</div>
            {modalState.gmt !== MOSCOW_GMT && (
              <div className="MainFilterGmtHour MainFilterGmtHourMoscow">{` | ${moscowTimeForHour}:00 (Москва)`}</div>
            )}
          </div>
        </div>
        <div className="MainFilterApplyWrapper">
          <ButtonPrimary
            disabled={!canApply}
            onClick={() => {
              if (mobileFilterOpen && setMobileFilterOpen) {
                setMobileFilterOpen(false)
              }
              setFilterState({ ...filterState, data: { ...modalState } })
              saveFilterInLocalStorage(modalState, MAIN_FILTER_NAME)
            }}
          >
            Применить
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

export default MainFilter
