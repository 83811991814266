import React, { useMemo, useState, useEffect } from "react"

import { useSelector } from "react-redux"

import { isEmpty } from "lodash"

import { getRequestTotal } from "api/request"
import { getFilterStateFromLocalStorage } from "api/helpers"
import { useRequest, useRequestBody, useRequestParams } from "api/hooks"

import { getAddressList } from "store/halls/selector"
import { getGamesList } from "store/dictionary/selector"

import {
  getDateDescriptionLayout,
  isMobile,
  parseDateByShift,
  parseDateToBack,
  shiftMoscowGmtFirst
} from "constants/index"

import { ReactComponent as FilterIcon } from "Components/icons/filter_arrow.svg"
import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"
import EmptyTable from "Components/EmptyTable"
import NotActiveFilter from "Components/NotActiveFilter"
import ExcelButton from "Components/ExcelButton"
import BaseFooter from "Components/Footer"
import Title from "Components/Title"

import Filter, { GAMES_FILTER_NAME } from "./Filter"
import GamesContent from "./Content"
import {
  initialFilterData,
  GameReportStateType,
  GameFilterState
} from "./types"
import { textConstants } from "./constant"
import { ReactComponent as EmptyIcon } from "./filter_appertain.svg"

const GameComponent = () => {
  const { current_hall } = useRequestParams()

  const games: { id: string | number; name: string }[] = useSelector(
    getGamesList
  )
  const allGameIds = games.map(item => item.id)

  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    GAMES_FILTER_NAME
  )
  const locations: {
    gmt: string
    region: string
  }[] = useSelector(getAddressList)

  const [filterState, setFilterState] = useState<GameFilterState>({
    isOpen: true,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      ...initialFilterData,
      shift: shiftMoscowGmtFirst({ gmt: locations[0].gmt }),
      games: allGameIds,
      halls: current_hall,
      ...initFilterStateFromStorage
    }
  })

  const { pagination, setPagination } = useRequestBody()

  const [reportState, setReportState] = useState<GameReportStateType>({
    pending: false,
    total: 0,
    data: []
  })
  const depsHalls = useMemo(() => current_hall.join(`,`), [current_hall])

  const requestDeps = useMemo(() => ({ ...filterState.data, ...pagination }), [
    filterState.data,
    pagination
  ])

  const dateForRequest = filterState.data.dates
    ? parseDateToBack({
        date: parseDateByShift({
          dates: filterState.data.dates,
          isShift: filterState.data.isShift,
          shift: filterState.data.shift
        }),
        gmt: filterState.data.gmt
      })
    : null

  const { request: getTableData } = useRequest({
    method: `POST`,
    url: `reports/games`,
    requestBody: {
      ...filterState.data,
      halls: filterState.data.halls,
      dates: dateForRequest,
      shift: filterState.data.isShift
        ? filterState.data.shift[0]
        : filterState.data.shift,
      pagination
    }
  })

  useEffect(() => {
    if (filterState.isActive && !reportState.pending)
      setReportState({ ...reportState, pending: true })
    getTableData()
      .then(({ data, headers }) => {
        setReportState({
          ...reportState,
          pending: false,
          total: getRequestTotal(headers),
          data: data
        })
      })
      .catch(() =>
        setReportState({
          ...reportState,
          pending: false
        })
      )
  }, [depsHalls, requestDeps])

  const { dates, isShift, shift } = filterState.data

  const shiftForExel = isShift && !!shift ? shift[0] : shift

  const [mobileFilterOpen, setMobileFilterOpen] = useState(isMobile)

  return (
    <div className="FlexRow">
      <div>
        <div
          className={`MainPageContentWrapper ${
            filterState.isOpen
              ? `MainPageContentWrapperFilter`
              : `MainPageContentWrapperUnFilter`
          }`}
        >
          <div
            className="MobileFilterIcon"
            onClick={() => setMobileFilterOpen(true)}
          >
            <FilterMobileIcon />
          </div>
          <div className="MainPageContentRestrictions">
            {filterState.isActive && (
              <Title
                titleText={textConstants.title}
                tooltipText={textConstants.tooltip}
                defaultBottomCmpMargin
                bottomAdditionalCmp={
                  <>
                    {dates &&
                      getDateDescriptionLayout({
                        dates,
                        shift,
                        isShift,
                        show: filterState.isActive
                      })}
                  </>
                }
                rightAdditionalCmp={
                  !!reportState?.data?.length && (
                    <ExcelButton
                      disabled={reportState.pending}
                      url={textConstants.exelConfig.url}
                      requestBody={{
                        ...filterState.data,
                        dates: dateForRequest,
                        shift: shiftForExel
                      }}
                    />
                  )
                }
                rightAdditionalClassName="CashboxExcelButton"
              />
            )}
            {!filterState.isActive && !reportState.pending && (
              <NotActiveFilter
                title={textConstants.empty.title}
                description={textConstants.empty.description}
                icon={<EmptyIcon />}
              />
            )}
            {!!reportState?.data?.length && filterState.isActive && (
              <GamesContent
                total={reportState.total}
                report={reportState.data}
                pending={reportState.pending}
                filter={filterState.data}
                pagination={pagination}
                setPagination={setPagination}
              />
            )}
            {!!reportState.data &&
              !reportState.data.length &&
              !reportState.pending &&
              filterState.isActive && <EmptyTable />}
            <div className="FlexRow">
              <div
                className={`MainPageFilterButtonWrapper ${
                  filterState.isOpen
                    ? `MainPageFilterButtonWrapperActive`
                    : `MainPageFilterButtonWrapperUnActive`
                }`}
                onClick={() =>
                  setFilterState({
                    ...filterState,
                    isOpen: !filterState.isOpen
                  })
                }
              >
                <FilterIcon />
              </div>
            </div>
          </div>
          {mobileFilterOpen && (
            <Filter
              current_hall={current_hall}
              filterState={filterState}
              setFilterState={setFilterState}
              mobileFilterOpen={mobileFilterOpen}
              setMobileFilterOpen={setMobileFilterOpen}
            />
          )}
        </div>
        <BaseFooter />
      </div>
      <Filter
        current_hall={current_hall}
        filterState={filterState}
        setFilterState={setFilterState}
      />
    </div>
  )
}

export default GameComponent
