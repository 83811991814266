import React from "react"
import { Moment } from "moment"

export enum HistoryBetsColumns {
  game_number = "game_number",
  is_bonus_balance = "is_bonus_balance",
  game_start_date = "game_start_date",
  game_end_date = "game_end_date",
  game_kind_id = "game_kind_id",
  nominal = "nominal",
  bet_drop_order = "bet_drop_order",
  drop_order = "drop_order",
  win_sum = "win_sum"
}

export interface HistoryBetsFilter {
  game_number?: string
  bets_date?: Moment
  shift?: number
  win_type?: string
  win_sum?: {
    from?: number
    to?: number
  }
  bet_sum?: {
    from?: number
    to?: number
  }
  game_ids?: number[]
  hall?: number
}

export interface HistoryBetsFilterState {
  isOpen: boolean
  isActive: boolean
  mobileFilter: boolean
  data: HistoryBetsFilter
}

export interface UseHistoryBetsRequests {
  filter: HistoryBetsFilter
  sort: any
  pagination: any
}

export interface HistoryBetsDataElement {
  bet_drop_order: number[] | string
  drop_order: number[]
  game_end_date: string
  game_kind_id: number
  game_number: number
  game_start_date: string
  is_bonus_balance: boolean
  nominal: number
  win_sum: number
}

export interface HistoryBetsDataSummary {
  bets_count?: number
  bonus_bets_count?: number
  bonus_bets_sum?: number
  guests_count?: number
  money_bets_count?: number
  money_bets_sum?: number
}

export interface HistoryBetsDataState {
  isLoading: boolean
  total: number
  data: HistoryBetsDataElement[]
}

export interface HistoryBetsDataSummaryState {
  isLoading: boolean
  data: HistoryBetsDataSummary
}

export interface CustomFilterProps {
  filter: HistoryBetsFilter
  setFilter: React.Dispatch<React.SetStateAction<HistoryBetsFilter>>
}

export interface DateTimeCellProps {
  date: string
  time: string
}

export interface GameCellProps {
  value: number[] | string
  record: HistoryBetsDataElement
  passedKey: HistoryBetsColumns.bet_drop_order | HistoryBetsColumns.drop_order
}

export enum HistoryBetsSummaryKeys {
  bets_count = "bets_count",
  bonus_bets_count = "bonus_bets_count",
  bonus_bets_sum = "bonus_bets_sum",
  guests_count = "guests_count",
  money_bets_count = "money_bets_count",
  money_bets_sum = "money_bets_sum"
}

export interface InputRangeComponentProps {
  valueFrom?: number | undefined
  valueTo?: number | undefined
  prefixFrom: string
  prefixTo: string
  onChangeFrom: (value?: number) => void
  onChangeTo: (value?: number) => void
  className?: string
}
