import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { useRequest, useRequestParams } from "api/hooks"

import messagesConst from "./constants"
import { MessageComponentProps } from "./types"

import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { PinComponent } from "Containers/Messages/constants"

import { closeEmergency, storeEmergency } from "store/message/actions"
import { MessageStoreState } from "store/message/types"

const MessagesComponent = ({ message }: MessageComponentProps) => {
  const [count, setCount] = useState<number>(0)
  const [pending, setPending] = useState<boolean>(false)

  const { current_hall } = useRequestParams()

  const dispatch = useDispatch()
  const closeEmergencyMessage: () => void = () => dispatch(closeEmergency())

  const setStoreEmergency: (value: MessageStoreState) => void = (
    value: MessageStoreState
  ) => dispatch(storeEmergency(value))

  const { request: getMessage } = useRequest({
    url: `message/emergency`,
    method: "POST",
    requestBody: { hall_id: current_hall[0] }
  })

  const { request } = useRequest({
    url: messagesConst.url,
    method: `POST`,
    requestBody: {
      id: message.data[count].id
    }
  })

  const next = () => {
    count + 1 === message.data.length
      ? closeEmergencyMessage()
      : setCount(count + 1)
  }

  return (
    <div>
      <div className="FlexRow MessagesHeader">
        <span>{messagesConst.attention}</span>
        <PinComponent
          pin={!!message.data[count].pinned}
          id={message.data[count].id}
          isSmall={false}
        />
      </div>
      {!!message.data[count] && (
        <div>
          <div className="MessageTextWrapper">
            <span
              dangerouslySetInnerHTML={{ __html: message.data[count].text }}
            />
          </div>
          <div className="MessagesFooter FlexRow">
            <div className="FlexRow">
              <span className="MessageCount">{count + 1}</span>
              <span className="MessageCount MessageCountTotal">
                /{message.data.length}
              </span>
            </div>
            <div className="FlexRow MessagesFooterButtons">
              <ButtonDefault
                onClick={() => {
                  const currentDate = new Date().getTime()
                  localStorage.setItem(`emergency_wait`, `${currentDate}`)
                  setTimeout(() => {
                    getMessage().then(({ data: { result }, headers }) => {
                      if (!!headers[`total-count`]) {
                        setStoreEmergency({
                          message_count: headers[`total-count`],
                          emergency: result,
                          emergency_open: true
                        })
                      }
                    })
                  }, messagesConst.waitTime)
                  closeEmergencyMessage()
                }}
              >
                {messagesConst.skip}
              </ButtonDefault>
              <ButtonPrimary
                onClick={() => {
                  if (count + 1 <= message.data.length) {
                    setPending(true)
                    request()
                      .then(() => {
                        setPending(false)
                        next()
                      })
                      .catch(() => setPending(false))
                  }
                }}
                disabled={pending}
              >
                {messagesConst.read}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MessagesComponent
