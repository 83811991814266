import React, { useEffect, useState, useMemo } from "react"

import { useGameList, useRequest } from "api/hooks"
import { parseOneDateToBack } from "constants/index"

import NotActiveFilter from "Components/NotActiveFilter"
import TableComponent from "Components/TableNew"

import { TopThreeFilterData, TopThreeState } from "./types"
import {
  getColumns,
  getCurrenHallFromFilter,
  TABLE_WIDTH,
  topThreeText
} from "./constants"

const TopThreeTable = ({ filter }: { filter: TopThreeFilterData }) => {
  const currentHall = getCurrenHallFromFilter(filter)
  const [table, setTable] = useState<TopThreeState>({
    pending: false,
    data: []
  })

  const { request: getTableData } = useRequest({
    url: `v1/promotions/top3`,
    requestBody: {
      hall: currentHall,
      date: filter.date
        ? parseOneDateToBack({ date: filter.date })
        : filter.date
    }
  })

  const { pending: gamesPending, games, request: requestGames } = useGameList({
    halls: [currentHall]
  })

  const columns = useMemo(() => getColumns(games), [games])

  useEffect(() => {
    requestGames()
  }, [currentHall])

  useEffect(() => {
    setTable({ ...table, pending: true })
    getTableData()
      .then(({ data }) => {
        setTable({ ...table, pending: false, data })
      })
      .catch(() => setTable({ ...table, pending: false }))
  }, [filter])

  return (
    <div>
      {!table.pending && !table.data.length && (
        <NotActiveFilter
          description={topThreeText.empty.description}
          descriptionTitle={topThreeText.empty.title}
          icon={topThreeText.empty.icon}
        />
      )}
      {!!table.data.length && (
        <TableComponent
          columns={columns}
          data={table.data}
          loading={table.pending || gamesPending}
          width={TABLE_WIDTH}
        />
      )}
    </div>
  )
}

export default TopThreeTable
