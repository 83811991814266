import { MailingModalData } from "Containers/Mailing/Modal/types"

export type MailingHallDetailProps = {
  hall: number
  refreshHalls: () => void
  close: () => void
}

export enum DetailPeriod {
  full = 24,
  half = 12
}

export type DetailDataUsers = {
  id: number
  name: string
  date: string
  active: boolean
  contacts: {
    phone: string
    email: string
  }
  replenishment: number | string
  withdrawal: number | string
  win: number | string
}

export type DetailData = {
  hall: number
  period: DetailPeriod
  date: string | null
  active: boolean
  users: DetailDataUsers[]
}

export type DetailDataState = {
  data: DetailData
  pending: boolean
  trigger: {
    period: DetailPeriod | undefined
  }
}

export type MailingDetailTableProps = {
  refresh: () => void
  refreshHalls: () => void
  close: () => void
  data: DetailData
  users: DetailDataUsers[]
  pending: boolean
}

export type ModalFromDetailState = {
  open: boolean
  initial: MailingModalData
}

export type GetColumnsProps = {
  hall: number
  refreshAll: () => void
  activeCurrent: (data: {
    id: number
    active: boolean
    refresh: () => void
  }) => void
  toggleOpen: (data: ModalFromDetailState) => void
  deleteCurrent: ({ id, refresh }: { id: number; refresh: () => void }) => void
}
