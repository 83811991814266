import React, { useMemo } from "react"

import { formatToCurrency } from "constants/index"

import { LoyaltyGuestReportType } from "../types"

import { ReactComponent as HelpIcon } from "Components/icons/help_16.svg"

import NewTableComponent from "Components/TableNew"
import TooltipComponent from "Components/Tooltip"
import { PaginationValue } from "Components/Pagination/types"

const getColumn = (isMonthly: boolean) => [
  {
    title: () => {
      const title = isMonthly ? `Месяц` : `Дата`
      return (
        <div className="FlexRow TableHeaderFirstTh">
          <span>{title}</span>
          <TooltipComponent
            title="Дата формирования данных."
            direction="bottom"
          >
            <div className="TableHeaderHelpWrapper">
              <HelpIcon />
            </div>
          </TooltipComponent>
        </div>
      )
    },
    dataIndex: "date",
    key: "date",
    className: `TableColumnNormalSpacing`,
    width: `20%`,
    // eslint-disable-next-line react/display-name
    render: (date: string) => <div className="GuestInfoReportDate">{date}</div>
  },
  {
    title: () => (
      <div className="FlexRow">
        <span>Статус гостя</span>
        <TooltipComponent
          title="Статус, присвоенный гостю в указанную дату."
          direction="bottom"
        >
          <div className="TableHeaderHelpWrapper">
            <HelpIcon />
          </div>
        </TooltipComponent>
      </div>
    ),
    dataIndex: "guestStatus",
    key: "guestStatus",
    className: `TableColumnNormalSpacing`,
    width: `20%`
  },
  {
    title: () => (
      <div className="FlexRow">
        <span>Процент кэшбэка</span>
        <TooltipComponent
          title="Процент кэшбэка гостя на указанную дату, в соответствии со статусом."
          direction="bottom"
        >
          <div className="TableHeaderHelpWrapper">
            <HelpIcon />
          </div>
        </TooltipComponent>
      </div>
    ),
    dataIndex: "cashback",
    className: `TableColumnNormalSpacing`,
    width: `20%`,
    key: "cashback"
  },
  {
    title: () => (
      <div className="FlexRow">
        <span>Сумма бонусов</span>
        <TooltipComponent
          title="Сумма наколенных/зачисленных бонусов гостю на указанную дату."
          direction="bottom"
        >
          <div className="TableHeaderHelpWrapper">
            <HelpIcon />
          </div>
        </TooltipComponent>
      </div>
    ),
    dataIndex: "sum",
    width: `20%`,
    className: `TableBoldValue TableColumnNormalSpacing`,
    key: "sum",
    // eslint-disable-next-line react/display-name
    render: (sum: number) => formatToCurrency({ amount: sum })
  },
  {
    title: () => (
      <div className="FlexRow">
        <span>Статус начисления</span>
        <TooltipComponent
          title="Информация о состоянии бонусных начислений гостя на указанную дату: зачислено - бонусы, зачисленные на бонусный баланс гостя; накоплено - бонусы, доступные для зачисления на бонусный баланс гостя."
          direction="bottom"
        >
          <div className="TableHeaderHelpWrapper">
            <HelpIcon />
          </div>
        </TooltipComponent>
      </div>
    ),
    dataIndex: "status",
    className: `TableColumnNormalSpacing`,
    key: "status",
    width: `20%`,
    // eslint-disable-next-line react/display-name
    render: (status: string) => {
      let statusConfig

      switch (status) {
        case `income`:
          statusConfig = {
            back: `#EBFBEE`,
            color: `#40C057`,
            text: `Накоплено`
          }
          break
        case `accumulated`:
          statusConfig = {
            back: `#F1F3F5`,
            color: `#212529`,
            text: `Зачислено`
          }
          break
        default:
          statusConfig = {
            back: `#F1F3F5`,
            color: `#212529`,
            text: `Зачислено`
          }
      }
      return (
        <div
          style={{
            backgroundColor: statusConfig.back,
            color: statusConfig.color
          }}
          className="GuestInfoReportStatus"
        >
          {statusConfig.text}
        </div>
      )
    }
  }
]

type GuestInfoReportProps = {
  isMonthly: boolean
  total: number
  report: LoyaltyGuestReportType[]
  loading: boolean
  pagination: PaginationValue
  setPagination: (value: PaginationValue) => void
}

const GuestInfoReport = ({
  loading,
  report,
  total,
  isMonthly,
  pagination,
  setPagination
}: GuestInfoReportProps) => {
  const column = useMemo(() => getColumn(isMonthly), [isMonthly])

  return (
    <div>
      <NewTableComponent
        columns={column}
        data={report}
        loading={loading}
        pagination={{
          value: pagination,
          onChange: setPagination,
          total
        }}
      />
    </div>
  )
}

export default GuestInfoReport
