import React, { useState } from "react"

import DataSwitcher from "Components/DataSwitcher"
import EmptyTable from "Components/EmptyTable"

import GuestHistoryCashboxChart from "./Chart"
import GuestHistoryCashboxTable from "./Table"

import { GuestsHistoryFilterData } from "../../types"
import { dataWithoutPeriod } from "../../constants"

const GuestHistoryCashbox = ({
  filter
}: {
  filter: GuestsHistoryFilterData
}) => {
  const [cashboxView, toggleView] = useState(false)

  return (
    <div className="GuestHistoryCashboxWrapper">
      {filter.dates ? (
        <div>
          <div className="GuestHistoryCashboxSwitcher FlexRow">
            <DataSwitcher
              activeContent={cashboxView}
              toggleActiveContent={toggleView}
            />
          </div>
          {cashboxView ? (
            <GuestHistoryCashboxTable filter={filter} />
          ) : (
            <GuestHistoryCashboxChart filter={filter} />
          )}
        </div>
      ) : (
        <EmptyTable
          mobile={dataWithoutPeriod.mobile}
          desktop={dataWithoutPeriod.desktop}
        />
      )}
    </div>
  )
}

export default GuestHistoryCashbox
