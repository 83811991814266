import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { Radio } from "antd"

import moment from "moment"
import { isEmpty } from "lodash"

import { getCurrentHall } from "store/halls/selector"
import { logoutUser } from "store/auth/actions"

import {
  getDateDescriptionLayout,
  isMobile,
  parseDateToBack
} from "constants/index"
import {
  getFilterStateFromLocalStorage,
  saveFilterInLocalStorage
} from "api/helpers"

import ExcelButton from "Components/ExcelButton"
import SpinComponent from "Components/Spin"
import NotActiveFilter from "Components/NotActiveFilter"
import BaseFooter from "Components/Footer"
import TooltipComponent from "Components/Tooltip"
import DashboardComponent from "Components/DashboardNew"

import {
  DashboardOptions,
  defaultDescriptions
} from "Components/DashboardNew/constants"

import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"
import { ReactComponent as PeakHoursIcon } from "Components/icons/peak_hours.svg"
import { ReactComponent as ClockIcon } from "Components/SelectHour/clock_select.svg"
import { ReactComponent as FilterIcon } from "Components/icons/filter_arrow.svg"
import { ReactComponent as InfoIcon } from "Components/icons/help_24.svg"

import { getInitialFilterData, textConstants } from "./constants"
import {
  DashboardsStateProps,
  RegStatReport,
  VisitAndRegsReport,
  VisitFilterProps,
  VisitHoursReport
} from "./types"
import {
  findMostVisitedHour,
  getDashboardsData,
  getRegStatData,
  getVisitData,
  getVisitHoursData,
  isEmptyPeaksChart
} from "./helper"

import VisitFilter, { VISIT_FILTER_NAME } from "./VisitFilter"
import { ReactComponent as EmptyIcon } from "./visit_empty.svg"
import { ReactComponent as EmptyDataIcon } from "./emptyDataIcon.svg"

import VisitAndReg from "./VisitAndReg"
import VisitPeaks from "./VisitPeaks"
import RegStat from "./RegStat"
import VisitCalendar from "./VisitCalendar"
import TimeShift from "./TimeShift"

const VisitComponent = () => {
  const history = useHistory()
  const current_hall: (string | CheckboxValueType)[] = useSelector(
    getCurrentHall
  )
  const dispatch = useDispatch()
  const logout: () => void = () => dispatch(logoutUser())

  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    VISIT_FILTER_NAME
  )

  const [filterState, setFilterState] = useState<VisitFilterProps>({
    isOpen: true,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      ...getInitialFilterData(parseInt(current_hall[0] as string)),
      ...initFilterStateFromStorage
    }
  })

  const { dates, isShift, shift } = filterState.data
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(
    window.innerWidth < 921
  )

  const [reportState, setReportState] = useState<VisitAndRegsReport>({
    pending: false,
    chart: []
  })

  const [regStatState, setRegStatState] = useState<RegStatReport>({
    pending: false,
    chart: []
  })

  const [visitHoursState, setVisitHoursState] = useState<VisitHoursReport>({
    pending: false,
    day: 0,
    chart_days: textConstants.daysAnaliticsOptions_1,
    chart: [],
    rushhour_weekday: []
  })

  useEffect(() => {
    if (filterState.isActive) {
      getVisitData({
        reportState,
        setReportState,
        filterState,
        logout,
        history
      })
    }
  }, [
    filterState.data.type,
    filterState.data.onlyReg,
    filterState.data.period,
    filterState.data.dates,
    filterState.data.isShift,
    filterState.data.shift,
    filterState.isActive
  ])

  const isNotHourlyPeriod =
    filterState.data.period !== textConstants.period.hourly

  useEffect(() => {
    if (filterState.isActive && isNotHourlyPeriod) {
      getRegStatData({
        regStatState,
        setRegStatState,
        filterState,
        logout,
        history
      })
    }
  }, [
    filterState.data.type,
    filterState.data.period,
    filterState.data.dates,
    filterState.data.isShift,
    filterState.data.shift,
    filterState.isActive
  ])

  const datesBlockInfo = getDateDescriptionLayout({
    dates,
    shift,
    isShift,
    show: filterState.isActive,
    hideTime: true
  })

  const now = moment()
  const before = moment().subtract(visitHoursState.chart_days, "days")
  const datesPeaksHours = getDateDescriptionLayout({
    dates: [before, now],
    shift,
    isShift: false,
    show: filterState.isActive,
    hideTime: true
  })

  useEffect(() => {
    isNotHourlyPeriod &&
      getVisitHoursData({
        visitHoursState,
        setVisitHoursState,
        filterState,
        logout,
        history
      })
  }, [
    filterState.data.type,
    filterState.data.period,
    filterState.data.dates,
    filterState.data.isShift,
    filterState.data.shift,
    filterState.isActive,
    visitHoursState.chart_days
  ])

  const excelUrl = filterState.data.onlyReg
    ? textConstants.exelConfig.regUrl
    : textConstants.exelConfig.visitUrl

  const exelRequestBody = {
    ...filterState.data,
    shift: filterState.data.isShift
      ? filterState.data.shift[0]
      : filterState.data.shift,
    dates: parseDateToBack({
      date: filterState.data.dates,
      gmt: textConstants.gmtDefault
    })
  }

  const createdRadios = textConstants.weekDays.map((name, index) => {
    const mostVisitedClass =
      visitHoursState.rushhour_weekday.indexOf(index) != -1
        ? "PeaksHoursMostVisited"
        : ""
    return (
      <Radio key={name} className={mostVisitedClass} value={index}>
        {name}
      </Radio>
    )
  })

  const [dashBoardState, setDashBoardState] = useState<DashboardsStateProps>({
    pending: false
  })

  const showDashboards =
    filterState.isActive &&
    dashBoardState.all_visits !== undefined &&
    dashBoardState.new_guests !== undefined &&
    dashBoardState.unique_guests !== undefined

  useEffect(() => {
    filterState.isActive &&
      getDashboardsData({
        filterState,
        setDashBoardState,
        dashBoardState,
        logout,
        history
      })
  }, [filterState.data.shiftDashboards, filterState.isActive])

  const mostVisitedHour = findMostVisitedHour(visitHoursState)
  const showMostVisitedHour = mostVisitedHour !== -1
  const isHiddenPeaksChart = isEmptyPeaksChart(visitHoursState.chart)

  return (
    <div className="FlexRow">
      <div>
        <div
          className={`MainPageContentWrapper VisitsPageContentWrapper ${
            filterState.isOpen
              ? `MainPageContentWrapperFilter`
              : `MainPageContentWrapperUnFilter`
          }`}
        >
          <div
            className="MobileFilterIcon"
            onClick={() => setMobileFilterOpen(true)}
          >
            <FilterMobileIcon />
          </div>
          <div className="MainPageContentRestrictions">
            {filterState.isActive && (
              <div className="MainPageTitle VisitsPageTitle FlexRow MainPageTitleWithExel">
                <span className="VisitsPageTitleInner">
                  {textConstants.title}
                  <TooltipComponent
                    className="VisitMainTooltip"
                    direction="right"
                    title={textConstants.visitClubTooltip}
                  >
                    <InfoIcon />
                  </TooltipComponent>
                </span>
                {!!reportState?.chart?.length && (
                  <ExcelButton
                    disabled={reportState.pending}
                    url={excelUrl}
                    requestBody={exelRequestBody}
                  />
                )}
              </div>
            )}
            <div className="VisitsContentPeriodWrapper">{datesBlockInfo}</div>
            {showDashboards && (
              <div className="VisitsDashboardsWrapper">
                <div className="VisitsDashboardsHours">
                  <ClockIcon />
                  <span>{textConstants.timeShiftDashboardsTitle}</span>
                  <TimeShift
                    from={filterState.data.shiftDashboards}
                    onChange={(value: number) => {
                      setFilterState({
                        ...filterState,
                        data: {
                          ...filterState.data,
                          shiftDashboards: value
                        }
                      })
                      saveFilterInLocalStorage(
                        {
                          ...filterState.data,
                          shiftDashboards: value
                        },
                        VISIT_FILTER_NAME
                      )
                    }}
                  />
                  <span className="VisitsDashboardsCity">
                    {textConstants.timeShiftDashboardsSubTitle}
                  </span>
                </div>
                <div className="MarginTop12 MarginBottom12">
                  <DashboardComponent
                    config={[
                      {
                        type: DashboardOptions.visit_count,
                        value: dashBoardState.all_visits ?? 0,
                        isCurrency: false,
                        description: defaultDescriptions.now,
                        tooltipText: textConstants.visitCountTooltip
                      },
                      {
                        type: DashboardOptions.new_registrations_orange,
                        value: dashBoardState.new_guests ?? 0,
                        isCurrency: false,
                        description: defaultDescriptions.now,
                        tooltipText: textConstants.newRegTooltip
                      },
                      {
                        type: DashboardOptions.uniq_registrations,
                        value: dashBoardState.unique_guests ?? 0,
                        isCurrency: false,
                        description: defaultDescriptions.now,
                        tooltipText: textConstants.uniqueRegTooltip
                      }
                    ]}
                  />
                </div>
              </div>
            )}
            {reportState.pending && (
              <SpinComponent color={textConstants.spinColor} />
            )}
            {!filterState.isActive && !reportState.pending && (
              <NotActiveFilter
                title={textConstants.empty.title}
                description={textConstants.empty.description}
                icon={<EmptyIcon />}
              />
            )}
            {!reportState.pending && filterState.isActive && (
              <div>
                <VisitAndReg
                  reportState={reportState}
                  filterState={filterState}
                  setFilterState={setFilterState}
                />
                {isNotHourlyPeriod && (
                  <div className="VisitsBlockContainer">
                    <div className="VisitsBlock VisitsBlockSecondary">
                      <div className="VisitsBlockTitle">
                        <span>{textConstants.statRegTitle}</span>
                        <TooltipComponent
                          direction="right"
                          title={textConstants.regStatTooltip}
                        >
                          <InfoIcon />
                        </TooltipComponent>
                      </div>
                      <div className="VisitsContentPeriodWrapper">
                        {datesBlockInfo}
                      </div>
                      <RegStat
                        regStatState={regStatState}
                        filterState={filterState}
                      />
                    </div>
                    <div className="VisitsBlock VisitsBlockSecondary">
                      <div>
                        <div className="VisitsBlockTitleWrapper">
                          <div className="VisitsBlockTitle">
                            <span>{textConstants.visitPeaksTitle}</span>
                            <TooltipComponent
                              direction="right"
                              title={textConstants.peakHoursTooltip}
                            >
                              <InfoIcon />
                            </TooltipComponent>
                          </div>
                          <div className="VisitsContentPeriodWrapper">
                            {datesPeaksHours}
                          </div>
                        </div>
                        <VisitCalendar
                          visitHoursState={visitHoursState}
                          setVisitHoursState={setVisitHoursState}
                        />
                      </div>
                      {!isHiddenPeaksChart && (
                        <div className="VisitWeekDaysWrapper">
                          <Radio.Group
                            className="VisitWeekDays"
                            optionType="button"
                            value={visitHoursState.day}
                            onChange={({ target: { value } }) => {
                              setVisitHoursState({
                                ...visitHoursState,
                                day: value
                              })
                            }}
                          >
                            {createdRadios}
                          </Radio.Group>
                        </div>
                      )}
                      {isMobile && showMostVisitedHour && (
                        <div className="mostVisitedHoursWrapper">
                          <PeakHoursIcon />
                          <span className="mostVisitedHoursTime">
                            {mostVisitedHour}
                          </span>
                          <span className="mostVisitedHoursText">
                            {textConstants.maxHoursDescription}
                          </span>
                        </div>
                      )}
                      {!isHiddenPeaksChart ? (
                        <div>
                          <VisitPeaks visitHoursState={visitHoursState} />
                        </div>
                      ) : (
                        <div>
                          <div className="VisitChartPeaks_EmptyDataIcon">
                            <EmptyDataIcon />
                          </div>
                          <div className="EmptyDataPeaksTitle">
                            {textConstants.emptyDataTitle}
                          </div>
                          <div className="EmptyDataPeaksText">
                            {textConstants.emptyDataText}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="FlexRow">
              <div
                className={`MainPageFilterButtonWrapper ${
                  filterState.isOpen
                    ? `MainPageFilterButtonWrapperActive`
                    : `MainPageFilterButtonWrapperUnActive`
                }`}
                onClick={() =>
                  setFilterState({
                    ...filterState,
                    isOpen: !filterState.isOpen
                  })
                }
              >
                <FilterIcon />
              </div>
            </div>
          </div>
          {mobileFilterOpen && (
            <VisitFilter
              current_hall={current_hall}
              filterState={filterState}
              setFilterState={setFilterState}
              mobileFilterOpen={mobileFilterOpen}
              setMobileFilterOpen={setMobileFilterOpen}
            />
          )}
        </div>
        <BaseFooter />
      </div>
      <VisitFilter
        current_hall={current_hall}
        filterState={filterState}
        setFilterState={setFilterState}
      />
    </div>
  )
}

export default VisitComponent
