import { NotifiCountProps } from "./types"

export const initialState: NotifiCountProps = {
  title: undefined,
  body: undefined,
  isRequire: false,
  id: undefined,
  all: []
}

export const notifiCountText = {
  title: ` Новое сообщение`
}
