import { parseDateToBack } from "constants/index"
import moment, { Moment } from "moment"
import { useRequest, useRequestBody, useRequestParams } from "api/hooks"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import produce from "immer"
import { GetRequestBodyProps, UseNoncashRequestProps } from "./types"

const getRequestBody = ({
  filterState,
  pagination,
  search,
  sort
}: GetRequestBodyProps) => {
  const datesForRequest = filterState.data.dates
    ? parseDateToBack({
        date: filterState.data.isShift
          ? filterState.data.dates
          : [
              filterState.data.dates[0],
              moment(filterState.data.dates[1]).add(
                filterState.data.shift[0] >= filterState.data.shift[1] ? 1 : 0,
                `day`
              )
            ],
        gmt: filterState.data.gmt,
        isObj: false
      })
    : undefined
  return {
    limit: pagination.limit,
    offset: pagination.offset,
    dates: datesForRequest,
    halls: filterState.data.halls.map(x => Number(x)),
    isShift: filterState.data.isShift,
    shift: filterState.data.isShift
      ? filterState.data.shift[0]
      : filterState.data.shift,
    gmt: filterState.data.gmt,
    search: Object.keys(search).length ? search : undefined,
    sort: sort.order || (sort.details && sort.details.order) ? sort : undefined
  }
}

export default getRequestBody

export const useNoncashRequest = ({
  url,
  filterState,
  setData,
  report
}: UseNoncashRequestProps) => {
  const { current_hall } = useRequestParams()
  const depsHalls = useMemo(() => current_hall.join(`,`), [current_hall])

  const {
    pagination,
    setPagination,
    setSort,
    sort,
    resetPagination
  } = useRequestBody()
  const [search, setSearch] = useState<{ [key: string]: any }>({})

  const setSearchCb = useCallback(
    (searchArg: string | undefined, id: string) => {
      if (search) {
        setSearch(
          produce(search, draft => {
            draft[id] = searchArg?.trim()
          })
        )
      }
    },
    [search]
  )

  const requestBody = useMemo(
    // eslint-disable-next-line
    //@ts-ignore
    () => getRequestBody({ filterState, pagination, sort, search }),
    [filterState, pagination, sort, search]
  )
  const { request } = useRequest({
    url,
    method: `POST`,
    requestBody
  })

  useEffect(() => {
    if (filterState.isActive) {
      setData({ ...report, pending: true })
      request()
        .then(({ data, headers }) => {
          const total = headers["total-count"]
          setData({ data, pending: false, total })
        })
        .catch(() => {
          setData({ ...report, pending: false })
        })
    }
  }, [
    depsHalls,
    filterState.data,
    filterState.isActive,
    pagination,
    sort,
    search
  ])

  return {
    pagination,
    setPagination,
    resetPagination,
    setSort,
    sort,
    search,
    setSearch: setSearchCb,
    requestBody
  }
}

export const getColumnActiveClass = (field: string | undefined, key: string) =>
  `NoncashTableColumn ${field === key ? `ActiveSortTableColumn` : ``}`

export const ColumnDateTime: React.FC<{ date?: string | Moment }> = ({
  date
}) => {
  return (
    <div className="FlexRow">
      <span className="NoncashTableDate">
        {moment(date).format(`DD.MM.YYYY`)}
      </span>
      <span className="NoncashTableTime">{moment(date).format(`HH:mm`)}</span>
    </div>
  )
}
