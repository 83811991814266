import React from "react"

import { isMobile } from "constants/index"

import { ModalRightDetail } from "Components/Modal"

import { detailButtons } from "./constants"
import TableDetail from "./index"

import { DetailModalProps } from "../types"

type CompareBetsProps = {
  betsList: number[]
  openDetailModal: () => void
  closeModal: () => void
  clear: () => void
  openDetail: boolean
  detailOpen: DetailModalProps
  removeListItem: (val: number) => void
  onWalletIdClick: (walletId: number) => void
}

const CompareBets = ({
  betsList,
  openDetailModal,
  clear,
  openDetail,
  closeModal,
  removeListItem,
  detailOpen,
  onWalletIdClick
}: CompareBetsProps) => {
  const compareLength = betsList.length
  return (
    <div>
      {compareLength > 1 && (
        <div className="SportboomCompareWrapper">
          <div className="SportboomCompareApply" onClick={openDetailModal}>
            <span>{detailButtons.open_detail(compareLength)}</span>
          </div>
          <div className="SportboomCompareCancel" onClick={clear}>
            {detailButtons.cancel_check}
          </div>
        </div>
      )}
      {openDetail && (
        <ModalRightDetail
          initialWidth={1280}
          close={closeModal}
          hideClose={isMobile}
        >
          <TableDetail
            onWalletIdClick={onWalletIdClick}
            detailOpen={detailOpen}
            list={betsList}
            removeItem={removeListItem}
            close={closeModal}
          />
        </ModalRightDetail>
      )}
    </div>
  )
}

export default CompareBets
