import { useRequest } from "api/hooks"
import { NewHallContent } from "./types"

export const newHallText = {
  title: "Открыть новый клуб",
  tooltip:
    "Раздел содержит актуальную информацию о свободных регионах, возможных для сотрудничества, а также информацию об этапах открытия клуба BetBoom",
  saveButton: "Сохранить изменения",
  cancelButton: "Отменить"
}

const NEW_HALL_CONTENT_URL = "v1/handbook/newHall"
const NEW_HALL_SET_URL = "v1/admin/handbook/newHall"
export const allowedRoles = ["COMPANY-DEVELOPER", "COMPANY-SUPPORT"]

export const useNewHallRequests = (content: NewHallContent | null) => {
  const { request: getNewHallContent } = useRequest({
    url: NEW_HALL_CONTENT_URL,
    method: "GET"
  })

  const { request: setNewHallContent } = useRequest({
    url: NEW_HALL_SET_URL,
    method: "PATCH",
    requestBody: { text: content?.text }
  })

  return { getNewHallContent, setNewHallContent }
}
