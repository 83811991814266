import React from "react"
import DatePickerComponent from "Components/DatePicker"
import { PickerFormats } from "Components/FilterInfoPanel/types"
import { CustomDateFilterProps } from "../types"
import { textConstants } from "../constants"
import { RangePickerProps } from "antd/es/date-picker"
import moment from "moment/moment"

const CustomDateFilter = ({ filter, setFilter }: CustomDateFilterProps) => {
  const disabledDate: RangePickerProps["disabledDate"] = current => {
    const threeMonthsAgo = moment().subtract(3, "months")
    return (
      current &&
      (current > moment() || current < threeMonthsAgo.startOf("month"))
    )
  }
  return (
    <div className="FlexColumn Gap8">
      <div className="SubtitleFirstText">{textConstants.filterTitle}</div>
      <DatePickerComponent
        value={filter.date}
        picker={"month"}
        format={PickerFormats.fullMonthYear}
        onChange={date => setFilter({ ...filter, date })}
        disabledDate={disabledDate}
      />
    </div>
  )
}

export default CustomDateFilter
