import React, { useEffect, useState } from "react"

import { Skeleton } from "antd"

import { useRequest } from "api/hooks"
import { isMobile } from "constants/index"

import EmptyTable from "Components/EmptyTable"

import SportboomChart from "./chart"
import {
  ChartRequestData,
  ChartRequestDetailData,
  ChartState,
  ChartTypeSwitcherItemProps,
  SportBoomContentItemProps
} from "./types"
import {
  chartTypesOptions,
  dataEmptyChart,
  defaultChartTypeStyles,
  ChartTypes
} from "./constants"

import { parseDates } from "../../constants"

type ChartTypeSwitchItemProps = {
  item: ChartTypeSwitcherItemProps
  isCurrent: boolean
  toggleChartType: (val: ChartTypes) => void
}

const ChartTypeSwitchItem = ({
  item,
  isCurrent,
  toggleChartType
}: ChartTypeSwitchItemProps) => (
  <div
    key={item.value}
    className={`SportboomChartTypePickerItem GridCenter ${
      !isCurrent ? `SportboomChartTypePickerItemDefault` : ``
    }`}
    style={{
      borderColor: isCurrent ? item.color : defaultChartTypeStyles.color,
      backgroundColor: isCurrent
        ? item.background
        : defaultChartTypeStyles.background
    }}
    onClick={() => {
      if (!isCurrent) toggleChartType(item.value)
    }}
  >
    <div className="FlexRow">
      <div
        className="SportboomChartTypePickerCircle"
        style={{ backgroundColor: item.color }}
      />
      <span className={isMobile ? `OverlineText` : `ChartDecodingText`}>
        {item.label}
      </span>
    </div>
  </div>
)

const SportBoomChart = ({
  activeTab,
  filter,
  chartPeriod,
  setDateFromChart
}: SportBoomContentItemProps) => {
  // toggle chart types retention <-> guest_count <-> all_bets <-> win
  const [chartType, toggleChartType] = useState<ChartTypes>(
    ChartTypes.guest_count
  )

  const [chartState, setChartState] = useState<ChartState>({
    data: {
      series: [],
      categories: [],
      dataByDate: {}
    },
    pending: false
  })
  const { request: getChartData } = useRequest({
    url: `v1/sportboom/chart`,
    requestBody: {
      filters: {
        ...filter,
        dates: parseDates(filter)
      },
      type: activeTab,
      chart_type: chartType,
      chart_period: chartPeriod
    },
    method: `POST`
  })
  const requestDeps = JSON.stringify({
    ...filter,
    activeTab,
    chartPeriod,
    chartType
  })
  useEffect(() => {
    setChartState({ ...chartState, pending: true })
    getChartData()
      .then(({ data }: { data: ChartRequestData[] }) => {
        const series: number[] = []
        const categories: string[] = []
        const dataByDate: {
          [key: string]: ChartRequestDetailData
        } = {}

        data.map(item => {
          series.push(Number(item?.value))
          categories.push(item?.date)
          dataByDate[item.date] = item.detail
        })

        setChartState({
          ...chartState,
          pending: false,
          data: {
            series,
            categories,
            dataByDate
          }
        })
      })
      .catch(() => setChartState({ ...chartState, pending: false }))
  }, [requestDeps])

  return (
    <>
      {chartState.pending && (
        <div className="SportboomChartWrapper">
          <Skeleton.Button className="SportboomChartSkeletonWrapper" active />
        </div>
      )}
      {!!chartState.data.series?.length && !chartState.pending && (
        <SportboomChart
          setDateFromChart={setDateFromChart}
          chartState={chartState.data}
          period={chartPeriod}
          type={chartType}
        />
      )}
      {!chartState.data.series?.length && !chartState.pending && (
        <div className="GuestHistoryEmptyDataWrapper">
          <EmptyTable
            mobile={dataEmptyChart.mobile}
            desktop={dataEmptyChart.desktop}
          />
        </div>
      )}
      <div className="SportboomChartTypePicker GridCenter ">
        {isMobile ? (
          <div className="SportboomChartTypeMobilePicker Gap8 FlexColumn">
            <div className="FlexRow FlexWrap SportboomChartTypeMobilePickerRow">
              {chartTypesOptions.map(item => (
                <ChartTypeSwitchItem
                  key={`switch_cart_type_${item.label}`}
                  item={item}
                  isCurrent={item.value === chartType}
                  toggleChartType={toggleChartType}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="FlexRow FlexWrap SportboomChartTypePickerRow">
            {chartTypesOptions.map(item => {
              const isCurrent = item.value === chartType
              return (
                <div key={`switch_cart_type_${item.value}`}>
                  <ChartTypeSwitchItem
                    item={item}
                    isCurrent={isCurrent}
                    toggleChartType={toggleChartType}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

export default SportBoomChart
