import React, { useCallback, useEffect, useState } from "react"

import { ReactComponent as EmptyHall } from "Components/icons/illustrations/alarm_dude_large.svg"
import NewTableComponent from "Components/TableNew"
import { EmptyData } from "Components/EmptyTable"

import { emptyText, TABLE_WIDTH } from "./constants"
import { SuperwinTableTableState, HistoryContentProps } from "./types"
import { useSelector } from "react-redux"
import { getGamesList } from "store/dictionary/selector"
import { makeHistoryColumns, useSuperWinHistoryRequests } from "./helpers"
import { Sorter } from "Components/Table/types"
import { sortForTable } from "Components/Table"

const HistoryContent = ({
  filter,
  pagination,
  setPagination,
  isSuperWinsHistory,
  setSort,
  sort
}: HistoryContentProps) => {
  const [history, setHistory] = useState<SuperwinTableTableState>({
    pending: false,
    total: undefined,
    data: []
  })

  const games = useSelector(getGamesList)

  const { getSuperWinHistory } = useSuperWinHistoryRequests({
    filter,
    pagination,
    isSuperWinsHistory,
    sort
  })

  useEffect(() => {
    const makeRequest = isSuperWinsHistory
      ? filter.halls?.length
      : filter.hall && filter.nominals?.length

    if (makeRequest) {
      setHistory({ ...history, pending: true })
      getSuperWinHistory().then(({ data }) => {
        setHistory({
          data: data.items,
          pending: false,
          total: data.total_count
        })
      })
    }
  }, [filter, pagination, sort])

  const isNoSupewinHall =
    !filter.dates && history.total === 0 && !history.pending
  const isEmptyTable = !!filter.dates && history.total === 0 && !history.pending

  const handleSortChange = useCallback(
    (pagination: any, filters: any, sorter: Sorter) => {
      sortForTable({ sorter, setSort })
    },
    [setSort]
  )

  return (
    <div className="PaddingTop16">
      {!isNoSupewinHall && !isEmptyTable && !!history.total && (
        <NewTableComponent
          width={TABLE_WIDTH.all}
          loading={history.pending}
          data={history.data}
          onChange={handleSortChange}
          columns={makeHistoryColumns(games, isSuperWinsHistory)}
          pagination={{
            value: pagination,
            total: history.total || 0,
            onChange: setPagination
          }}
        />
      )}
      {isEmptyTable && (
        <EmptyData
          className="SuperwinHistoryContentEmpty"
          title=""
          subtitle={emptyText.table}
        />
      )}
      {isNoSupewinHall && (
        <EmptyData
          title=""
          className="SuperwinHistoryContentEmpty"
          subtitle={emptyText.hall}
          icon={<EmptyHall />}
        />
      )}
    </div>
  )
}

export default HistoryContent
