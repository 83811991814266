import React, { useEffect, useState } from "react"
import { Form, Modal } from "antd"
import { appealModalText, FormItemNames } from "./helper"
import { AppealModalData, AppealModalProps } from "./types"
import { UploadAttachment, UploadAttachments } from "../types"
import { userPenaltyCardText } from "../UserPenaltyCard/helper"
import { FieldData } from "rc-field-form/lib/interface"
import { getFilesBase64 } from "utils/files"
import { uniqBy } from "lodash"
import { proposalModalText } from "../../../Proposal/ProposalModal/helper"
import { ReactComponent as ArrowLeftIcon } from "Components/icons/arrow_left.svg"
import { AppealModalContent } from "./AppealModalContent"
import { moneyRender } from "constants/index"
import { addEditModalText } from "../../constants"

const initialModalData: AppealModalData = {
  name: "",
  comment: "",
  attachments: []
}

export const AppealModal: React.FC<AppealModalProps> = ({
  appealModal,
  winOptions,
  closeAppealModal,
  viewModalData,
  setAppealModalData,
  loading,
  successModal,
  isMobile,
  rules
}) => {
  const [form] = Form.useForm()

  const [modalData, setModalData] = useState(initialModalData)

  const [finalPenaltyValue, setFinalPenaltyValue] = useState("")
  const [finalWinValue, setFinalWinValue] = useState("")

  useEffect(() => {
    if (!appealModal) {
      form.resetFields()
      setModalData(initialModalData)
    }
  }, [appealModal])

  useEffect(() => {
    if (modalData.attachments.length > 5) {
      form.setFields([
        {
          name: FormItemNames.attachments,
          errors: [addEditModalText.fileUploadCountError]
        }
      ])
    } else if (modalData.attachments.length <= 5) {
      const totalSize = (modalData.attachments as [UploadAttachment]).reduce(
        (acc: number, att: UploadAttachment) => {
          acc += Number(att.size)
          return acc
        },
        0
      )
      if (totalSize > 4e7) {
        form.setFields([
          {
            name: FormItemNames.attachments,
            errors: [addEditModalText.fileSizeValidationError]
          }
        ])
      } else {
        form.setFields([
          {
            name: FormItemNames.attachments,
            errors: []
          }
        ])
      }
    } else {
      form.setFields([
        {
          name: FormItemNames.attachments,
          errors: []
        }
      ])
    }
  }, [modalData.attachments])

  const onFieldsChange = (e: FieldData[]) => {
    if ((e[0].name as [string])[0] === FormItemNames.attachments) {
      getFilesBase64([e[0].value.file], true).then(data => {
        setModalData(prev => ({
          ...prev,
          attachments: uniqBy(
            [...prev.attachments, ...data],
            "name"
          ) as UploadAttachments
        }))
      })
    }
  }

  useEffect(() => {
    const final = viewModalData?.penaltyValue
    let result
    const finalWin = viewModalData?.win
    let resulWin
    if (!final) {
      result = userPenaltyCardText.warning
    } else {
      result = `${appealModalText.onSum} ${moneyRender(final)} ${
        userPenaltyCardText.rubles
      }`
    }

    if (finalWin !== null) {
      const foundedWin = winOptions.find(x => x.id == viewModalData?.win)?.value
      if (foundedWin) {
        resulWin = `${userPenaltyCardText.winExist} ${foundedWin}`
      } else {
        resulWin = userPenaltyCardText.winNull
      }
    } else {
      resulWin = userPenaltyCardText.winNull
    }
    setFinalPenaltyValue(result)
    setFinalWinValue(resulWin)
  }, [viewModalData])

  const onFinish = (val: any) => {
    const errors = form.getFieldError(FormItemNames.attachments)
    if (!errors.length) {
      setAppealModalData({
        name: val.name,
        comment: val.comment,
        attachments: modalData.attachments
      })
    }
  }

  return isMobile ? (
    <>
      {appealModal && (
        <div className="ProposalModalContentMobileView">
          <div
            onClick={() => {
              closeAppealModal()
            }}
            className="ProposalModalContentMobileViewBack"
          >
            <ArrowLeftIcon />
            {proposalModalText.back}
          </div>
          <AppealModalContent
            successModal={successModal}
            viewModalData={viewModalData}
            finalPenaltyValue={finalPenaltyValue}
            finalWinValue={finalWinValue}
            form={form}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            modalData={modalData}
            setModalData={setModalData}
            loading={loading}
            closeAppealModal={closeAppealModal}
            isMobile={isMobile}
            rules={rules}
          />
        </div>
      )}
    </>
  ) : (
    <Modal
      className="SupportProposalModal"
      visible={appealModal}
      footer={null}
      width={678}
      closeIcon={<></>}
    >
      <AppealModalContent
        successModal={successModal}
        viewModalData={viewModalData}
        finalPenaltyValue={finalPenaltyValue}
        finalWinValue={finalWinValue}
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        modalData={modalData}
        setModalData={setModalData}
        loading={loading}
        closeAppealModal={closeAppealModal}
        isMobile={isMobile}
        rules={rules}
      />
    </Modal>
  )
}
