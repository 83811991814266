import React, { useCallback, useEffect, useMemo, useState } from "react"

import { getRequestTotal } from "api/request"
import { useNow, useRequest, useRequestBody } from "api/hooks"

import MailingTabs from "../Tabs"

import { MailingTabsProps } from "../types"

import NotActiveFilter from "Components/NotActiveFilter"
import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import EmptyTable from "Components/EmptyTable"

import TableComponent, { sortForTable } from "Components/Table"
import PaginationComponent from "Components/Pagination"
import { Sorter } from "Components/Table/types"

import { ReactComponent as FilterNotActive } from "./icons/empty_filter.svg"
import { MailingHistoryFilter, MailingHistoryState } from "./types"
import {
  MAILING_HISTORY_FILTER,
  initialPagination,
  initialHistoryState,
  historyUrl,
  emptyFilterText,
  columnKeys
} from "./constant"
import {
  getInitialFilterState,
  getDateDescriptionLayout,
  historyColumn
} from "./helper"

const MailingHistory = ({ activeTab, setActiveTab }: MailingTabsProps) => {
  const { timestamp, refresh } = useNow()
  const { pagination, setPagination, sort, setSort } = useRequestBody(
    initialPagination.limit
  )
  const resetPagination = useCallback(
    () => setPagination(initialPagination),
    []
  )

  const initFilterState = getInitialFilterState()
  const [filterState, setFilterState] = useState<MailingHistoryFilter>(
    initFilterState
  )
  const setFilterStateCb = useCallback(value => setFilterState(value), [])
  const disableFilter =
    !filterState.data?.halls?.length || !filterState.data?.dates

  const [historyData, setHistoryData] = useState<MailingHistoryState>(
    initialHistoryState
  )

  const columns = useMemo(() => historyColumn({ sort }), [sort])

  const { request: getData } = useRequest({
    url: historyUrl,
    method: `POST`,
    requestBody: {
      ...filterState.data,
      sort: !sort.field || !sort.order ? undefined : [sort],
      pagination
    }
  })

  useEffect(() => {
    if (!disableFilter) {
      setHistoryData(prev => ({ ...prev, pending: true }))
      getData()
        .then(({ data, headers }) =>
          setHistoryData(prev => ({
            ...prev,
            data,
            pending: false,
            total: getRequestTotal(headers)
          }))
        )
        .catch(() => setHistoryData(prev => ({ ...prev, pending: false })))
    }
  }, [timestamp, filterState.data, sort, pagination])

  const dateDescription = useMemo(
    () => getDateDescriptionLayout(filterState.data.dates),
    [filterState.data.dates]
  )

  return (
    <div>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={MAILING_HISTORY_FILTER}
            filterState={filterState}
            filterText={{
              rangePickerFilter: { header: columnKeys.date.header }
            }}
            setFilterState={setFilterStateCb}
            resetPagination={resetPagination}
            filters={[Filters.hallPicker, Filters.rangePicker]}
            getDisabled={filter => !filter?.halls?.length || !filter?.dates}
          />
        }
        isOpen={filterState.isOpen}
        mobileFilter={filterState.mobileFilter}
        toggleFilter={() =>
          setFilterState(prev => ({ ...prev, isOpen: !prev.isOpen }))
        }
        toggleMobileFilter={() =>
          setFilterState(prev => ({
            ...prev,
            mobileFilter: !prev.mobileFilter
          }))
        }
        pageContent={
          <div>
            <MailingTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              successRefresh={refresh}
            />
            <div>
              <div className="MailingHistoryPeriodWrapper">
                {dateDescription}
              </div>
              <div className="MailingHistoryTable">
                {historyData.total > 0 && (
                  <TableComponent
                    columns={columns}
                    loading={historyData.pending}
                    data={historyData.data}
                    onChange={(pagination: any, filters: any, sorter: Sorter) =>
                      sortForTable({ sorter, setSort })
                    }
                  />
                )}
                {filterState.isActive && historyData.total === 0 && (
                  <EmptyTable />
                )}
                {!filterState.isActive && (
                  <NotActiveFilter
                    title={emptyFilterText.title}
                    description={emptyFilterText.description}
                    icon={<FilterNotActive />}
                  />
                )}
                <div className="MailingHistoryTablePagination">
                  <PaginationComponent
                    value={pagination}
                    onChange={value => setPagination(value)}
                    pageSize={initialPagination.limit}
                    total={historyData.total}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default MailingHistory
