import React from "react"
import moment from "moment"
import { canShowStep7, smsDateRestrictions } from "../../helper"
import TooltipComponent from "Components/Tooltip"
import DatePickerComponent, { TimePickerComponent } from "Components/DatePicker"
import { CreateStep7Props } from "../../types"
import { smsConstants } from "../../constants"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"

const Step7 = ({ smsState, setSmsState, stepNumber }: CreateStep7Props) => {
  const showStep7 = canShowStep7(smsState)
  return (
    <div className="CreateSms_step">
      <div
        className={
          showStep7 ? "CreateSms_stepTitle" : "CreateSms_stepTitle_inactive"
        }
      >
        <span>{stepNumber}</span>
        <span>{smsConstants.step7.title}</span>
        <TooltipComponent title={smsConstants.step7.tooltip} direction="bottom">
          <InfoIcon />
        </TooltipComponent>
      </div>

      {showStep7 ? (
        <section className="CreateSms_inputWrapper">
          <div className="CreateSms_inputContainer">
            <div className="CreateSms_input CreateSms_inputShort CreateSms_inputShort_single">
              <label className="CreateSms_inputLabel">
                {smsConstants.step7.dateTitle}
              </label>
              <div className="CreateSms_inputInner">
                <DatePickerComponent
                  checkOffset
                  disabledDate={smsDateRestrictions}
                  error={smsState.errors?.dttmToSend}
                  value={
                    !!smsState.dateToSend
                      ? moment(smsState.dateToSend)
                      : undefined
                  }
                  onChange={value =>
                    setSmsState({
                      ...smsState,
                      dateToSend: moment(value).valueOf()
                    })
                  }
                  greyBackground
                  placeholder={smsConstants.step7.datePlaceholder}
                />
              </div>
            </div>
            <div className="CreateSms_input CreateSms_inputShort CreateSms_inputShort_single">
              <label className="CreateSms_inputLabel">
                {smsConstants.step7.timeTitle}
              </label>
              <div className="CreateSms_inputInner">
                <TimePickerComponent
                  error={smsState.errors?.dttmToSend}
                  value={
                    !!smsState.timeToSend
                      ? moment(smsState.timeToSend)
                      : undefined
                  }
                  onChange={value =>
                    setSmsState({
                      ...smsState,
                      timeToSend: moment(value).valueOf()
                    })
                  }
                  greyBackground
                  placeholder={smsConstants.step7.timePlaceholder}
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <hr className="CreateSms_divider" />
      )}
    </div>
  )
}

export default Step7
