import { getFilterStateFromLocalStorage } from "api/helpers"

import { SuperwinFilter, SuperwinFilterManage } from "../types"

export const SUPERWIN_FILTERS_MANAGE = `SUPERWIN_FILTERS_MANAGE`

const initialFilter: SuperwinFilter = {
  isOpen: true,
  mobileFilter: false,
  isActive: true
}

export const initialManageFilter = (
  currentHall: string
): SuperwinFilterManage => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    SUPERWIN_FILTERS_MANAGE
  )
  return {
    ...initialFilter,
    data: {
      halls: [Number(currentHall)],
      ...initFilterStateFromStorage
    }
  }
}

export const superwinMainText = {
  title: `Супервыигрыши`,
  tooltip: `Раздел позволяет увеличить сумму супервыигрыша в пределах установленных ограничений. По каждому номиналу присутствуют пороговые значения супервыигрыша, до которого возможно производить увеличение, а также ограничение на повышение в сутки. Отследить изменения можно в истории изменений, при необходимости  отфильтровав по интересующему номиналу и периоду, а история выигрышей покажет динамику розыгрыша супервыигрыша.`,
  notActiveFilter: {
    description: ``,
    title: ``
  },
  gameListLabel: `Игры, в которых копится Супервыигрыш:`,
  hallZone: "Зона клуба"
}
