import { SortOrders } from "Components/TableNew/types"

export const fromToConstants = {
  checkboxLabel: `Сортировать`,
  applyText: `Применить фильтр`,
  applyShortText: `Применить`,
  clearText: `Очистить`,
  options: [
    {
      value: SortOrders.asc,
      label: "По возрастанию"
    },
    {
      value: SortOrders.desc,
      label: "По убыванию"
    }
  ]
}
