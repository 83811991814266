import React, { useState } from "react"
import { getFilterStateFromLocalStorage } from "api/helpers"
import { isEmpty, noop } from "lodash"
import DateTime from "Components/DateTime"
import {
  NewProposalButtonProps,
  ProposalData,
  StatusLabelProps,
  UploadAttachmentRequest,
  UseProposalRequests
} from "./types"
import { Popover } from "antd"
import { ReactComponent as AttachIcon } from "Components/icons/attach_grey.svg"
import { ReactComponent as MessageIcon } from "Components/icons/message.svg"
import { ReactComponent as DetailsIcon } from "Components/icons/details.svg"
import { ReactComponent as CancelIcon } from "Components/icons/cancel.svg"
import { ReactComponent as ReloadIcon } from "Components/icons/reload.svg"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"
import { proposalModalText } from "./ProposalModal/helper"
import { useRequest } from "api/hooks"
import { ButtonPrimary } from "Components/Button"
import { getColumnActiveClass } from "Components/Table/helpers"
import TooltipComponent from "Components/Tooltip"
import Text from "antd/es/typography/Text"
import { TableColumnWidth } from "Components/Tabs/types"

export const SUPPORT_PROPOSAL_FILTER = "SUPPORT_PROPOSAL_FILTER"
export const TOTAL_COUNT_HEADER_KEY = "total-count"
const SUPPORT_PROPOSAL_BASE_URL_API = "v1/support/proposal"
const SUPPORT_PROPOSAL_DICT_URL_API = "v1/dictionary/support/proposal"

export const proposalText = {
  title: "Обратная связь",
  newProposal: "Новое обращение",
  emptyFilterTitle: "Ничего не найдено",
  emptyFilterDescription:
    "По заданным фильтрам нет совпадений.Попробуйте их изменить и применить ещё раз",
  tooltip:
    "Раздел предназначен для составления обращений в техническую поддержку, при необходимости возможно приложить файлы. После отправки, обращение отобразится в списке со статусом “В работе”, также можно отменить своё обращение - в этом случае оно получит статус “Отменено”. Как только обращение будет закрыто технической поддержкой, оно получит статус “Выполнено”. " +
    "Доступна фильтрация по статусу, периоду и поиск по ключевым словам или номеру обращения.",
  filterHeader: "Фильтры",
  filterStatus: "Статус",
  filterInterval: "Период обращения",
  filterSearch: "Ключевое слово или номер обращения",
  filterSearchPlaceholder: "Введите текст или номер",
  filterApplyButton: "Применить",
  idNullColumnTooltip:
    "Как только служба поддержки получит обращение, будет присвоен номер. Перезагрузите страницу или зайдите позже",
  idNullColumnMask: "#000000"
}

const columnHeaderText = {
  number: "Номер",
  dateTime: "Дата и время",
  status: "Статус",
  text: "Текст обращения",
  additional: "Доп. функционал"
}

const columnKeys = {
  zendeskId: "zendeskId",
  date: "date",
  status: "status",
  text: "text",
  details: "details",
  additional: "additional"
}

const statusesMap: { [key: number]: string } = {
  1: "ProposalStatusSuccess",
  2: "ProposalStatusCanceled",
  3: "ProposalStatusPending"
}

export const actionAllowed = (statusId?: number) => statusId === 3

export const formatZendeskId = (id: number) => `#${id}`

export const ProposalNullishZendeskId: React.FC = () => {
  return (
    <div className="ProposalNullishZendeskIdWrapper">
      <div className="ProposalNullishZendeskIdMask">
        {proposalText.idNullColumnMask}
      </div>
      <TooltipComponent title={proposalText.idNullColumnTooltip}>
        <InfoIcon className="ProposalNullishZendeskIdIcon" />
      </TooltipComponent>
    </div>
  )
}

export const useProposalRequests = ({
  filterState,
  pagination,
  sort,
  cancelId,
  currentHall,
  modalData
}: UseProposalRequests) => {
  const { request: getProposalList } = useRequest({
    url: SUPPORT_PROPOSAL_BASE_URL_API,
    method: "POST",
    requestBody: {
      ...filterState.data,
      ...pagination,
      sort,
      hall: currentHall
    }
  })

  const { request: getProposalDict } = useRequest({
    url: SUPPORT_PROPOSAL_DICT_URL_API,
    method: "GET"
  })

  const { request: newProposal } = useRequest({
    url: `${SUPPORT_PROPOSAL_BASE_URL_API}/new`,
    method: "POST",
    requestBody: {
      hall: currentHall,
      ...modalData,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      attachments: modalData?.attachments.map((x: UploadAttachmentRequest) => {
        x.size = String(x.size)
        return x
      })
    }
  })

  const { request: cancelProposal } = useRequest({
    url: `${SUPPORT_PROPOSAL_BASE_URL_API}/cancel/${cancelId}`,
    method: "GET"
  })

  return { getProposalList, getProposalDict, cancelProposal, newProposal }
}

export const StatusLabel: React.FC<StatusLabelProps> = ({ statuses, id }) => {
  const validStatus = statuses.find(x => x.id === id)
  return (
    <div
      className={`ProposalStatus ${validStatus && statusesMap[validStatus.id]}`}
    >
      <div>{validStatus?.name}</div>
    </div>
  )
}

export interface ProposalPopoverProps {
  setCancelId: (id: number) => void
  duplicate: (value: ProposalData) => void
  proposal: ProposalData
}

export const ProposalPopover: React.FC<ProposalPopoverProps> = ({
  setCancelId,
  duplicate,
  proposal
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <Popover
      trigger={"click"}
      className="ProposalPopover"
      visible={visible}
      onVisibleChange={x => setVisible(x)}
      content={
        <div className="ProposalPopoverWrapper">
          <div
            onClick={e => {
              e.stopPropagation()
              setVisible(false)
              duplicate(proposal)
            }}
            className="ProposalPopoverButton"
          >
            <ReloadIcon />
            <div className="ProposalPopoverButtonText">
              {proposalModalText.buttonDuplicate}
            </div>
          </div>
          {actionAllowed(proposal.status) && (
            <div
              onClick={e => {
                e.stopPropagation()
                setVisible(false)
                setCancelId(proposal.id)
              }}
              className="ProposalPopoverButton"
            >
              <CancelIcon />
              <div className="ProposalPopoverButtonText">
                {proposalModalText.buttonCancel}
              </div>
            </div>
          )}
        </div>
      }
    >
      <div
        onClick={e => {
          e.stopPropagation()
          setVisible(true)
        }}
        className="ProposalIconDetails"
      >
        <DetailsIcon />
      </div>
    </Popover>
  )
}

const proposalTableHeaderCell = (text: string) => (
  <span className="TableHeaderWithoutSpacing">{text}</span>
)

export const proposalColumn = (
  field: string | undefined,
  search: { [key: string]: any },
  setSearch: (value: string | undefined, id: string) => void,
  statuses: [{ id: number; name: string }] | [],
  setCancelId: (id: number) => void,
  duplicate: (value: ProposalData) => void,
  filter?: string
) => [
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {columnHeaderText.number}
      </div>
    ),
    dataIndex: columnKeys.zendeskId,
    className: getColumnActiveClass(field, columnKeys.zendeskId),
    showSorterTooltip: false,
    key: columnKeys.zendeskId,
    width: 120,
    minWidth: 120,
    render: (value: number | null) => (
      <div className="TableHeaderFirstTh">
        {value ? formatZendeskId(value) : <ProposalNullishZendeskId />}
      </div>
    )
  },
  {
    title: proposalTableHeaderCell(columnHeaderText.dateTime),
    dataIndex: columnKeys.date,
    className: getColumnActiveClass(field, columnKeys.date),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.date,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    render: (date?: string) => date && <DateTime date={date} />
  },
  {
    title: proposalTableHeaderCell(columnHeaderText.status),
    dataIndex: columnKeys.status,
    className: getColumnActiveClass(field, columnKeys.status),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.status,
    width: 120,
    minWidth: 120,
    maxWidth: 120,
    render: (type: number) => <StatusLabel statuses={statuses} id={type} />
  },
  {
    title: proposalTableHeaderCell(columnHeaderText.text),
    dataIndex: columnKeys.text,
    className: getColumnActiveClass(field, columnKeys.text),
    showSorterTooltip: false,
    key: columnKeys.text,
    render: (text: string) => {
      if (!!filter && !!text) {
        const re = new RegExp(filter, "gi")
        const start = text.search(re)
        const end = filter.length + start
        const left = text.substring(0, start)
        const right = text.substring(end)
        const founded = text.substring(start, end)
        return (
          <div className="ProposalTextColumn">
            {left}
            <Text mark>{founded}</Text>
            {right}
          </div>
        )
      }
      return <div className="ProposalTextColumn">{text}</div>
    }
  },
  {
    title: () => (
      <div className="SupportProposalAdditionalCell">
        {columnHeaderText.additional}
      </div>
    ),
    width: TableColumnWidth.xs,
    minWidth: TableColumnWidth.xs,
    maxWidth: TableColumnWidth.xs,
    whiteSpace: "nowrap",
    dataIndex: columnKeys.additional,
    key: columnKeys.additional,
    render: (_: undefined, proposal: ProposalData) => {
      return (
        <div className="PopoverGridTools">
          {!!proposal.details.attachments.length && (
            <div className="ProposalIcon">
              <AttachIcon />
              <div className="ProposalIconText">
                {proposal.details.attachments.length}
              </div>
            </div>
          )}
          {!!proposal.details.supportMessages.length && (
            <div className="ProposalIcon">
              <MessageIcon />
              <div className="ProposalIconText">
                {proposal.details.supportMessages.length}
              </div>
            </div>
          )}
          <ProposalPopover
            setCancelId={setCancelId}
            duplicate={duplicate}
            proposal={proposal}
          />
        </div>
      )
    }
  }
]

const initialFilterData = {
  hall: null,
  statuses: [],
  dates: null,
  gmt: `3`
}

export type GetInitialFilterState = {
  storage: string
  currentHall: number
  locations: {
    gmt: string
    region: string
  }[]
}

export const getInitialFilterState = ({
  storage,
  currentHall,
  locations
}: GetInitialFilterState) => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    storage,
    true
  )
  return {
    isOpen: true,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      ...initialFilterData,
      hall: currentHall,
      gmt: locations[0].gmt,
      ...initFilterStateFromStorage,
      statuses: isEmpty(initFilterStateFromStorage)
        ? [1, 2, 3]
        : initFilterStateFromStorage.statuses
    },
    mobileFilter: false
  }
}

export const NewProposalButton: React.FC<NewProposalButtonProps> = ({
  showModal
}) => {
  return (
    <div className="ProposalTitleButtonNew">
      <ButtonPrimary onClick={showModal}>
        {proposalText.newProposal}
      </ButtonPrimary>
    </div>
  )
}
