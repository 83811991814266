import React from "react"

export enum ChartType {
  in = "in",
  out = "out",
  attendance = "attendance",
  bets_in = `bets_in`,
  bets_out = "bets_out"
}

const typeOptions = {
  [ChartType.in]: {
    color: `#51CF66`,
    background: `#EBFBEE`,
    label: `Пополнения`
  },
  [ChartType.out]: {
    color: `#FF6B6B`,
    background: `#FFF5F5`,
    label: `Выплаты`
  },
  [ChartType.bets_in]: {
    color: `#51CF66`,
    background: `#EBFBEE`,
    label: `Поставлено`
  },
  [ChartType.bets_out]: {
    color: `#FF6B6B`,
    background: `#FFF5F5`,
    label: `Выиграно`
  },
  [ChartType.attendance]: {
    color: `#22B8CF`,
    background: `#E3FAFC`,
    label: `Посещения`
  }
}

export const ChartTypeComponent = ({
  isActive,
  type,
  switchActive
}: {
  isActive: boolean
  type: ChartType
  switchActive: () => void
}) => {
  const currentType = typeOptions[type]

  return (
    <div
      style={{ backgroundColor: isActive ? currentType.background : `#F8F9FA` }}
      className="ChartTypeComponentWrapper FlexRow"
      onClick={switchActive}
    >
      <div
        className="ChartTypeComponentDot"
        style={{ backgroundColor: isActive ? currentType.color : `#CED4DA` }}
      />
      <span
        className={`ButtonTextFirst ChartTypeComponentLabel ${
          isActive ? `ChartTypeComponentActiveLabel` : ``
        }`}
      >
        {currentType.label}
      </span>
    </div>
  )
}
