import React from "react"

import { FilterDurations } from "Components/FilterInfoPanel/types"

import { ReportHallFilter } from "./types"
import { ReactComponent as NotActiveFilterIcon } from "./Not_Active.svg"
import { Moment } from "moment"

export enum reportTypes {
  balance = `balance`,
  income = `income`,
  acquiring = `acquiring`,
  documents_debt = `documents_debt`
}

export const reportText = {
  title: `Отчет по клубу`,
  tooltip: `Раздел позволяет посмотреть и скачать отчеты по клубу, такие как: Баланс (финансовое состояние клуба), Поступления (поступления на расчетный счет клуба), Эквайринг (отчет по безналичным операциям) и Задолженность по документам (показывает задолженности по предоставлению или оплате по документам по лицензионной деятельности).`,
  period: `За период:`,
  filter: {
    store_name: `REPORTS_HALL`,
    title: `Фильтры`,
    period_type: {
      label: `Информация по`,
      getOptions: (report_type: reportTypes) => {
        const isBalanceOrAcquiring =
          report_type === reportTypes.balance ||
          report_type === reportTypes.acquiring
        return [
          {
            label: `Дням`,
            value: FilterDurations.daily,
            disabled: isBalanceOrAcquiring
          },
          {
            label: `Месяцам`,
            value: FilterDurations.monthly
          },
          {
            label: `Годам`,
            value: FilterDurations.yearly,
            disabled: isBalanceOrAcquiring
          }
        ]
      }
    },
    report_type: {
      label: `Тип отчета`,
      tooltip: `Для типов отчетов Баланс и Эквайринг выбор периода отчета возможен только в рамках месяца. Выгрузка произойдет за указанный месяц и за предыдущий указанному.`,
      options: [
        {
          label: `Баланс`,
          value: reportTypes.balance
        },
        {
          label: `Поступления`,
          value: reportTypes.income
        },
        {
          label: `Эквайринг`,
          value: reportTypes.acquiring
        },
        {
          label: `Задолженность по документам`,
          value: reportTypes.documents_debt
        }
      ]
    },
    period: {
      label: `Период отчета`,
      picker: {
        [FilterDurations.monthly]: `month`,
        [FilterDurations.daily]: `day`,
        [FilterDurations.yearly]: `year`,
        [FilterDurations.hourly]: `day`
      },
      format: {
        [FilterDurations.monthly]: `MMM YYYY`,
        [FilterDurations.daily]: `DD.MM.YYYY`,
        [FilterDurations.yearly]: `YYYY`,
        [FilterDurations.hourly]: `DD.MM.YYYY`
      }
    }
  },
  notActiveFilter: {
    title: `Чтобы получить отчет по клубу, \nвыберите нужные фильтры и нажмите кнопку «Применить»`,
    description: `Отчет по клубу. Содержит детальную информацию по долгам, \nпереплатам и прочим движениям денежных средств`,
    icon: <NotActiveFilterIcon />
  },
  emptyData: {
    title: `Ничего не найдено`,
    subtitle: `По заданным фильтрам ничего не найдено`
  },
  mobileLinkText: `Открыть PDF на просмотр`
}

export const getDescriptionByType = ({
  period,
  period_type,
  onlyMonth
}: {
  period: {
    from: Moment
    to: Moment
  }
  period_type: FilterDurations
  onlyMonth: boolean
}) => {
  let period_description = { from: ``, to: `` }

  switch (period_type) {
    case FilterDurations.daily: {
      period_description = {
        from: period.from.utc().format(`DD.MM.YYYY`),
        to: period.to.utc().format(`DD.MM.YYYY`)
      }
      break
    }
    case FilterDurations.monthly: {
      period_description = {
        from: period.from.utc().format(`MMMM YYYY`),
        to: period.to.utc().format(`MMMM YYYY`)
      }
      break
    }
    case FilterDurations.yearly: {
      period_description = {
        from: period.from.utc().format(`YYYY`),
        to: period.to.utc().format(`YYYY`)
      }
      break
    }
    default:
      period_description = {
        from: period.from.utc().format(`DD.MM.YYYY`),
        to: period.to.utc().format(`DD.MM.YYYY`)
      }
  }

  return !onlyMonth
    ? `${period_description.from} — ${period_description.to}`
    : period_description.from
}

export const initialFilterValues = {
  period: undefined,
  period_type: FilterDurations.monthly,
  report_type: reportTypes.balance
}

export const getInitialFilter = (hall: string): ReportHallFilter => {
  const initialWithHalls = { ...initialFilterValues, hall: Number(hall) }

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: false,
    data: {
      ...initialWithHalls
    }
  }
}

export const downloadFileHallPdf = (url: string, fileName: string) => {
  const xhr = new XMLHttpRequest()
  xhr.open("GET", url, true)
  xhr.responseType = "blob"

  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = xhr.response
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.href = url
      link.download = fileName
      link.click()

      window.URL.revokeObjectURL(url)
    }
  }

  xhr.send()
}
