import { Select } from "antd"
import React from "react"
import { hoursOptions } from "Components/SelectHour"
import { SelectHourProps } from "../types"

const TimeShift = ({ from, onChange }: SelectHourProps) => {
  return (
    <div className="FlexRow">
      <Select
        className="VisitTimeShiftSelect"
        dropdownClassName="VisitTimeShiftSelect_dropdown"
        options={hoursOptions}
        value={from}
        onChange={(value: number) => onChange(value)}
      />
    </div>
  )
}

export default TimeShift
