import { DictElements } from "../types"

export interface AppealModalData {
  status: number
  resolveComment: string
  resolvePartner: string
  penaltyValueResolved: number
  winResolved: null | number
}

export interface AppealModalProps {
  appealModal: boolean
  id?: string
  winOptions: DictElements | []
  win?: number | null
  amount?: number
  closeAppealModal: () => void
  setAppealModalData: (value: AppealModalData) => void
  loading: boolean
}

export enum FormItemNames {
  amount = "amount",
  win = "win",
  resolveComment = "resolveComment",
  partner = "partner"
}

export interface FinishFormItem {
  [FormItemNames.amount]: number
  [FormItemNames.win]: number | null
  [FormItemNames.resolveComment]: string
  [FormItemNames.partner]: string
}
