import React from "react"

import { Image, Progress } from "antd"

import { UploadImageProps } from "./types"
import { ReactComponent as DeleteIcon } from "./icons/delete.svg"
import { ReactComponent as PlayIcon } from "./icons/play.svg"
import { ReactComponent as ChangeIcon } from "./icons/change.svg"
import { uploadConstants } from "./constants"

const UploaderAttach = ({
  video,
  haveVideoBadge,
  uid,
  link,
  isEditMode,
  deleteItem,
  statusBadge,
  actionBadge,
  updateProgress,
  onAttachClicked,
  changeItem,
  old,
  showChangeIcon,
  showDeleteIcon
}: UploadImageProps) => {
  const loading = !!updateProgress && updateProgress < 100
  return (
    <div
      key={`attach_${uid}`}
      className="UploadAttachmentsImage"
      onClick={e => {
        !loading && onAttachClicked(e)
      }}
    >
      {!!video ? (
        <video className="UploaderVideo" onClick={onAttachClicked}>
          <source src={video} />
        </video>
      ) : (
        <Image preview={false} src={link || video} onClick={onAttachClicked} />
      )}
      {isEditMode && <div className="GuidebookCardAttachmentsImageEditable" />}
      {isEditMode && showDeleteIcon && (
        <div
          onClick={deleteItem}
          className="UploadImageBadge UploadImageBadgeDelete"
        >
          {actionBadge || <DeleteIcon />}
        </div>
      )}
      {isEditMode && showChangeIcon && (
        <div
          onClick={changeItem}
          className={`UploadImageBadge ${
            showDeleteIcon ? "UploadImageBadgeChange" : "UploadImageBadgeDelete"
          }`}
        >
          <ChangeIcon />
        </div>
      )}
      {old && (
        <div className="UploadAttachChanged">{uploadConstants.changed}</div>
      )}
      {!!statusBadge && (
        <div className="GuidebookCardAttachmentsImageStatusBadge">
          {statusBadge}
        </div>
      )}
      {loading && (
        <div className="UploadLoadingWrapper">
          <div className="UploadLoadingOverlayWrapper" />
          <Progress
            strokeWidth={10}
            showInfo={false}
            className="UploadLoadingProgress"
            type="circle"
            strokeColor="#fff"
            trailColor="rgba(33, 37, 41, 0.6)"
            percent={updateProgress}
          />
        </div>
      )}
      {!loading && !!haveVideoBadge && (
        <div className="UploadLoadingProgress" onClick={onAttachClicked}>
          <PlayIcon />
        </div>
      )}
    </div>
  )
}

export default UploaderAttach
