import React, { useMemo, useState } from "react"

import moment from "moment"

import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import * as HighchartsOne from "highcharts"

import { isMobile } from "constants/index"

import { getChartLabelByPeriod, yAxisLabels } from "Components/Chart/constants"
import { ChartPeriod } from "Components/Chart/types"

import { ChartRequestDetailData, ChartStateData } from "./types"
import {
  chartColortByType,
  chartConfig,
  ChartLabelByData,
  ChartTypes
} from "./constants"

type SportboomChartProps = {
  setDateFromChart?: (dates: { from: string; to: string }) => void
  chartState: ChartStateData
  period: ChartPeriod
  type: ChartTypes
}

const SportboomChart = ({
  setDateFromChart,
  chartState,
  period,
  type
}: SportboomChartProps) => {
  const { series, dataByDate, categories } = chartState
  const [columnPosition, setColumnPosition] = useState(0)
  const { xLabel, yLabel } = useMemo(() => {
    const xLabel = getChartLabelByPeriod(period)
    const yLabel = yAxisLabels()

    return { xLabel, yLabel }
  }, [period])

  const minWidth = !!series?.length ? series?.length * 60 : 0

  const options: Highcharts.Options = {
    ...chartConfig,
    chart: {
      ...chartConfig.chart,
      scrollablePlotArea: {
        minWidth
      }
    },
    xAxis: {
      ...chartConfig.xAxis,
      categories,
      crosshair: {
        ...chartConfig.xAxis.crosshair,
        dashStyle: `Dash`,
        color: chartColortByType[type]
      },
      labels: xLabel
    },
    yAxis: {
      ...chartConfig.yAxis,
      gridLineDashStyle: `Dash`,
      plotLines: [
        {
          ...chartConfig.yAxis.plotLines[0],
          dashStyle: `Solid`
        }
      ],
      labels: yLabel
    },
    tooltip: {
      ...chartConfig.tooltip,
      positioner: function(labelWidth, labelHeight, point) {
        const tooltipX = isMobile
          ? point.plotX - columnPosition + 80
          : point.plotX - labelWidth / 2
        const tooltipY = point.plotY - labelHeight - 8
        return {
          x: tooltipX,
          y: tooltipY
        }
      },
      formatter: function() {
        const pointData: ChartRequestDetailData = dataByDate[this.x]

        const renderData = ChartLabelByData(
          pointData,
          period,
          `${this.x}`,
          type
        )

        return renderData
      },
      backgroundColor: chartColortByType[type]
    },
    plotOptions: {
      column: {
        ...chartConfig.plotOptions.column,
        color: chartColortByType[type],
        events: {
          click: e => {
            if (!isMobile) {
              const currentDate = e.point.category
              const dateRightFormat = moment(currentDate, `DD.MM.YYYY`).format(
                `YYYY-MM-DD`
              )
              let endDate = ``
              if (!!setDateFromChart && period === ChartPeriod.daily) {
              }
              if (period === ChartPeriod.daily) {
                endDate = dateRightFormat
              }
              if (period === ChartPeriod.weekly) {
                endDate = moment(currentDate, `DD.MM.YYYY`)
                  .add(1, `week`)
                  .format(`YYYY-MM-DD`)
              }
              if (period === ChartPeriod.monthly) {
                endDate = moment(currentDate, `DD.MM.YYYY`)
                  .add(1, `month`)
                  .format(`YYYY-MM-DD`)
              }
              if (!!setDateFromChart) {
                setDateFromChart({
                  from: `${dateRightFormat}T00:00:00.000Z`,
                  to: `${endDate}T23:59:59.000Z`
                })
              }
            } else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setColumnPosition(e?.clientX)
            }
          }
        }
      }
    },
    series: [
      {
        name: chartConfig.series.name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data: series
      }
    ]
  }

  return (
    <div className="SportboomChartWrapper">
      <HighchartsReact highcharts={HighchartsOne} options={options} />
    </div>
  )
}

export default SportboomChart
