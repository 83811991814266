import { SuperWinState, SuperWinTwoData } from "./types"
import betRequest from "api/request"
import { IDevelopState } from "store/developer/types"
import { RouteComponentProps } from "react-router-dom"

type getSuperWinDataProps = {
  filterData: { gmt: string; shift: number }
  winData: SuperWinState
  setWinData: (data: SuperWinState) => void
  logout: () => void
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  history: RouteComponentProps["history"]
}

const getSuperWinData = ({
  filterData,
  winData,
  setWinData,
  logout,
  history,
  developer,
  addDeveloperLink
}: getSuperWinDataProps) => {
  setWinData({ ...winData, pending: true })
  betRequest({
    url: `superwins`,
    developer,
    addDeveloperLink,
    history,
    logout,
    method: `POST`,
    requestBody: {
      isShift: true,
      ...filterData
    }
  })
    .then(({ data }: { data: SuperWinTwoData }) => {
      setWinData({ ...winData, pending: false, data })
    })
    .catch(() => {
      setWinData({ ...winData, pending: false })
    })
}

export default getSuperWinData
