export enum ConfirmTypes {
  pauseAll = `pauseAll`,
  pauseOne = `pauseOne`,
  resumeAll = `resumeAll`,
  resumeOne = `resumeOne`,
  removeAll = `removeAll`,
  removeOne = `removeOne`
}

export type ConfirmModalData = {
  type: ConfirmTypes
  oneText?: string
  is_active?: boolean
  id?: number
}
