import React, { useState, useEffect, useCallback } from "react"

import { Tree } from "antd"
import { TreeDataNode, TreeProps } from "antd"

import { DataNode, EventDataNode } from "rc-tree/lib/interface"

const TreeComponent = ({
  treeData,
  checkedKeys,
  setCheckedKeys
}: {
  treeData: TreeDataNode[]
  checkedKeys: React.Key[]
  setCheckedKeys: (value: string[]) => void
}) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])
  const [halfCheckedKeys, setHalfCheckedKeys] = useState<React.Key[]>([])

  // Initialize expandedKeys with all keys
  useEffect(() => {
    const allKeys = getAllKeys(treeData)
    setExpandedKeys(allKeys)
  }, [])

  const getAllKeys = (data: DataNode[]): React.Key[] => {
    let keys: React.Key[] = []
    data.forEach(item => {
      keys.push(item.key)
      if (item.children) {
        keys = keys.concat(getAllKeys(item.children))
      }
    })
    return keys
  }

  const onExpand = (keys: React.Key[]) => {
    setExpandedKeys(keys)
  }

  const onCheck = (checked: any, { checkedNodes, halfCheckedKeys }: any) => {
    setCheckedKeys(checked)
    setHalfCheckedKeys(halfCheckedKeys)
  }

  const onNodeClick = (
    event: React.MouseEvent<HTMLSpanElement>,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    node: EventDataNode
  ) => {
    event.stopPropagation()
    const key = node.key as React.Key
    const haveChildren = node?.children?.length
    if (node.disabled || !haveChildren) {
      return
    }
    setExpandedKeys(
      expandedKeys.includes(key)
        ? expandedKeys.filter(k => k !== key)
        : [...expandedKeys, key]
    )
  }

  const renderTreeNodes = useCallback(
    (data: DataNode[]): React.ReactNode =>
      data.map(item => (
        <Tree.TreeNode
          title={
            <div onClick={e => onNodeClick(e, item)}>{renderTitle(item)}</div>
          }
          key={item.key}
          disabled={item.disabled} // Pass the disabled prop to the TreeNode
        >
          {item.children ? renderTreeNodes(item.children) : null}
        </Tree.TreeNode>
      )),
    [checkedKeys]
  )

  const renderTitle = (item: DataNode) => {
    return <span className="TextOne">{item.title}</span>
  }

  return (
    <Tree
      checkable
      expandedKeys={expandedKeys}
      onExpand={onExpand}
      checkedKeys={checkedKeys}
      onCheck={onCheck}
      titleRender={node => renderTitle(node)}
    >
      {renderTreeNodes(treeData)}
    </Tree>
  )
}

export default TreeComponent
