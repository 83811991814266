import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InputComponent, { InputPasswordComponent } from "Components/Input"

import { ReactComponent as LKIcon } from "Components/icons/lk-logo.svg"

import { IUSERState } from "store/auth/types"
import { ButtonPrimary } from "Components/Button"
import makeLogin from "./helper"
import { IDevelopState } from "store/developer/types"
import { getDeveloperList } from "store/developer/selector"
import { addLink } from "store/developer/actions"

export interface IAuthentication {
  isAuth?: boolean
  login: (data: IUSERState) => void
}

export interface ILoginData {
  username: string
  password: string
  errors: boolean
  pending: boolean
}

const Authentication = ({ login }: IAuthentication) => {
  const [loginData, setLoginData] = useState<ILoginData>({
    username: ``,
    password: ``,
    errors: false,
    pending: false
  })
  const developer: IDevelopState = useSelector(getDeveloperList)
  const dispatch = useDispatch()
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))

  const makeAuth = () =>
    makeLogin({
      loginData,
      setLoginData,
      login,
      developer,
      addDeveloperLink
    })

  return (
    <div className="LoginWrapper">
      <div className="LoginFormLogoLabel">
        <LKIcon />
        <span>Личный кабинет</span>
      </div>
      <div className="LoginFormWrapper GridCenter">
        <div className="LoginFormLabel">Вход в систему</div>
        <form>
          <div
            className="GridCenter LoginFormFieldWrapper"
            style={{ paddingBottom: !loginData.errors ? `32px` : `` }}
          >
            <div className="LoginFormField">
              <InputComponent
                placeholder="Логин"
                value={loginData.username}
                style={{
                  border: loginData.errors ? `1px solid #ff0025` : ``,
                  width: `320px`
                }}
                onChange={({ target: { value } }) =>
                  setLoginData({ ...loginData, username: value, errors: false })
                }
                onPressEnter={makeAuth}
              />
            </div>
            <div className="LoginFormField">
              <InputPasswordComponent
                placeholder="Пароль"
                value={loginData.password}
                style={{
                  border: loginData.errors ? `1px solid #ff0025` : ``,
                  width: `320px`
                }}
                onChange={({ target: { value } }) =>
                  setLoginData({ ...loginData, password: value, errors: false })
                }
                onPressEnter={makeAuth}
              />
            </div>
          </div>
        </form>
        {loginData.errors && (
          <div className="ErrorMessage">
            {loginData.errors || `Ошибка авторизации`}
          </div>
        )}
        <div className="LoginFormButtons">
          <ButtonPrimary
            disabled={loginData.pending}
            style={{ width: `320px` }}
            loading={loginData.pending}
            className="LoginFormEnter"
            onClick={makeAuth}
          >
            Войти
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

export default Authentication
