import { SelectProps } from "antd/lib/select"

export enum ExteriorPlaces {
  Entrance = "Входная группа",
  Facade = "Фасад здания"
}
export enum InteriorPlaces {
  Main = "Основная зона",
  BarCash = "Барная/кассовая зона",
  Toilet = "Санузел",
  Vestibule = "Тамбур",
  SportZone = "Спортивная зона",
  Vip = "VIP зона"
}

export interface CardState {
  zone: string
  image: string | null
  link?: string | null
  uploaded_at?: string | null
  group: ImageGroup.EXTERIOR | ImageGroup.INTERIOR
}

export interface StateProps {
  state: CardState[]
  minCount: number
  setState: (state: CardState[]) => void
  minCardsText: string
  titleText: string
  selectOptions: { value: string; label: string }[]
  initialCard: CardState
  hallType: ImageGroup.INTERIOR | ImageGroup.EXTERIOR
  openPreview: (val: PreviewData) => void
}

export type PreviewData = {
  link: string
  description: string
  zone: string
}

export interface UploadComponentProps extends SelectProps<any> {
  key?: string
  zone: string
  setZone: (zone: string, index: number) => void
  index: number
  date?: string | null
  setPhotoLink: (link: string, index: number) => void
  deleteCardLink: (index: number) => void
  imageStateLink: string | null
  openPreview: (val: PreviewData) => void
}

export interface RulesPopupProps {
  close: () => void
  isOpened: boolean
  isVisited: boolean
}
export enum ImageGroup {
  EXTERIOR = "exterior",
  INTERIOR = "interior"
}
