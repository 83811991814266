import React from "react"
import { ProposalMaskedInputProps } from "./types"
import { InputMaskComponent } from "Components/Input"

export const proposalModalText = {
  newTitle: "Новое обращение",
  currentTitle: "Обращение",
  username: "Имя",
  usernameInputPlaceholder: "Представьтесь, пожалуйста",
  phone: "Контактный телефон",
  phoneMask: "+7 000 000-00-00",
  text: "Текст обращения",
  textInputPlaceholder: "Введите обращение для службы поддержки",
  buttonSend: "Отправить",
  inputValidationError: "Необходимо заполнить поле",
  inputValidationLengthError: "Длина имени не должна превышать 100 символов",
  textareaValidationLengthError:
    "Длина текста не должна превышать 65000 символов",
  maskedInputValidationError: "Номер телефона должен состоять из 11 цифр",
  fileUploadCountError: "Возможно загрузить не более 10 файлов",
  fileSizeValidationError: "Общий размер файлов не должен превышать 40МБ",
  attachments: "Вложения",
  buttonCancel: "Отменить",
  buttonDuplicate: "Дублировать",
  supportResponseHeader: "Ответ технической поддержки",
  success: "Обращение\n успешно отправлено",
  back: "Назад"
}

export const formRules = {
  input: [
    {
      validator: <T, D>(_: T, value: D) => {
        const val = value && ((value as unknown) as string)
        if (val && val.trim()) {
          if (val.length > 100) {
            return Promise.reject(
              new Error(proposalModalText.inputValidationLengthError)
            )
          } else {
            return Promise.resolve()
          }
        } else {
          return Promise.reject(
            new Error(proposalModalText.inputValidationError)
          )
        }
      }
    }
  ],
  phoneInput: [
    {
      validator: <T,>(_: T, value: string) =>
        value && ((value as unknown) as string).length === 11
          ? Promise.resolve()
          : Promise.reject(
              new Error(proposalModalText.maskedInputValidationError)
            )
    }
  ],
  textarea: [
    {
      validator: <T, D>(_: T, value: D) => {
        const val = value && ((value as unknown) as string)
        if (val && val.trim()) {
          if (val.length > 65000) {
            return Promise.reject(
              new Error(proposalModalText.textareaValidationLengthError)
            )
          } else {
            return Promise.resolve()
          }
        } else {
          return Promise.reject(
            new Error(proposalModalText.inputValidationError)
          )
        }
      }
    }
  ]
}

export const ProposalMaskedInput: React.FC<ProposalMaskedInputProps> = ({
  value,
  onChange
}) => {
  return (
    <div className="InputWrapper ProposalModalPhoneInput">
      <InputMaskComponent
        mask={proposalModalText.phoneMask}
        value={value}
        allowClear={true}
        onChange={x => {
          onChange && onChange(`7${x.unmaskedValue}`)
        }}
        greyBackground
      />
    </div>
  )
}

export const allowedTypes =
  ".pdf,.doc,.docx,.docm,.bmp,.jpg,.jpeg,.xls,.xlsx,.png"

export enum FormItemNames {
  text = "text",
  username = "username",
  phone = "phone",
  file = "file"
}

export enum ModalTypes {
  new = "new",
  view = "view"
}
