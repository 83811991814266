import React, { useCallback, useState } from "react"

import { useNow } from "api/hooks"

import Title from "Components/Title"
import { ButtonPrimary } from "Components/Button"
import { MainPageFooter } from "Components/ReportWithFilter"

import { initialModal, mainText } from "./constants"
import { SportsBroadcastData, SportsBroadcastState } from "./types"
import SportsBroadcastInfo from "./Info"
import SportsBroadcastModal from "./Modal"
import SportsBroadcastTable from "./Table"

export const EditButton = ({ open }: { open: () => void }) => (
  <ButtonPrimary onClick={() => open()}>{mainText.button}</ButtonPrimary>
)

const SportsBroadcast = () => {
  const [modal, changeModal] = useState<SportsBroadcastState>(initialModal)

  const closeModal = useCallback(
    () => changeModal({ open: false, isEmptyData: modal.isEmptyData }),
    []
  )
  const openModal = useCallback(
    (data?: SportsBroadcastData) =>
      changeModal({ open: true, isEmptyData: modal.isEmptyData, data }),
    []
  )
  const changeEmpty = useCallback(
    (isEmptyData: boolean) => changeModal({ open: false, isEmptyData }),
    []
  )

  const [infoLink, setInfoLink] = useState<string | undefined>(undefined)

  const { timestamp, refresh } = useNow()

  return (
    <div className="FlexColumn">
      <div className="MainPageContentWrapper">
        <div className="MainPageContentRestrictions">
          <Title
            titleText={mainText.title}
            tooltipText={mainText.tooltip}
            rightAdditionalClassName="MarginLeftAuto"
            rightAdditionalCmp={
              modal.isEmptyData ? (
                <div className="SportsBroadcast__TitleButton">
                  <EditButton open={openModal} />
                </div>
              ) : (
                <div />
              )
            }
          />
          <SportsBroadcastInfo link={infoLink} />
          <SportsBroadcastTable
            changeEmpty={changeEmpty}
            openModal={openModal}
            refreshTrigger={timestamp}
            refresh={refresh}
            setInfoLink={setInfoLink}
          />
          {modal.open && (
            <SportsBroadcastModal
              data={modal.data}
              close={closeModal}
              refresh={refresh}
            />
          )}
        </div>
      </div>
      <MainPageFooter />
    </div>
  )
}

export default SportsBroadcast
