import React from "react"
import HighchartsReact from "highcharts-react-official"
import * as Highcharts from "highcharts"
import moment from "moment"
import {
  chartXLabelMinimize,
  chartXLabelMobileMinimize,
  chartXLabelRotation,
  textConstants
} from "Containers/Visit/constants"
import EmptyTable from "Components/EmptyTable"
import { VisitChartProps } from "Containers/Visit/types"
import {
  getRegColumnColor,
  getRegHoverColor,
  getVisitChartType,
  getVisitColumnColor,
  getVisitHoverColor,
  getVisitPeriodFormat
} from "Containers/Visit/helper"
// eslint-disable-next-line
//@ts-ignore
import borderRadius from "highcharts-border-radius"
import { isMobile } from "constants/index"
borderRadius(Highcharts)

const VisitChart = ({ reportState, filterState }: VisitChartProps) => {
  const periodFormat = getVisitPeriodFormat(filterState.data.period)

  const seriesData = reportState.chart.map(item => ({
    y: item.count as number,
    value: item.count as number,
    name: moment(item.date).format(periodFormat.name)
  }))

  const visitRegYAxisTitle = filterState.data.onlyReg
    ? textConstants.regCountsTitle
    : textConstants.visitCountsTitle

  const visitRegYAxisTitleXPosition = filterState.data.onlyReg
    ? textConstants.visitRegYAxisTitleXRegPosition
    : textConstants.visitRegYAxisTitleXVisitPosition

  const chartType = getVisitChartType(filterState.data.period)

  const columnColor = filterState.data.onlyReg
    ? getRegColumnColor(filterState.data.type)
    : getVisitColumnColor(filterState.data.type)

  const hoverColor = filterState.data.onlyReg
    ? getRegHoverColor(filterState.data.type)
    : getVisitHoverColor(filterState.data.type)

  const getDivider = (filterPeriod: string): number => {
    const limitList = isMobile ? chartXLabelMobileMinimize : chartXLabelMinimize
    if (reportState.chart.length <= limitList[filterPeriod]) return 1
    return Math.ceil(reportState.chart.length / limitList[filterPeriod])
  }

  const hallAddressesStorage: string =
    localStorage.getItem(`hallsAddress`) || ``

  const hallAddresses = hallAddressesStorage
    ? JSON.parse(hallAddressesStorage)
    : []

  const hall = filterState.data.hall

  /* Пересчитаем часы в минуты */
  const gmt = (hallAddresses[hall].gmt ?? textConstants.gmtDefault) * 60

  const options: Highcharts.Options = {
    chart: {
      height: 520,
      marginTop: 50,
      zoomType: "x",
      marginLeft: 50,
      marginRight: 20,
      spacingLeft: 0,
      spacingRight: 0
    },
    title: {
      text: ""
    },
    xAxis: {
      width: "100%",
      left: 50,
      tickInterval: getDivider(filterState.data.period),
      categories: reportState.chart.map(item => {
        const category = moment(item.date)
          .utcOffset(gmt)
          .format(periodFormat.name)
        if (filterState.data.period === textConstants.period.monthly) {
          return `${category.charAt(0).toUpperCase()}${category.slice(1)}`
        }
        return category
      }),
      crosshair: {
        className: `ChartsCrosshair`,
        width: 1,
        dashStyle: `Dash`,
        color: columnColor
      },
      tickWidth: 0,
      labels: {
        align: `center`,
        rotation: chartXLabelRotation[filterState.data.period],
        y: 50,
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this)
          return `<span id="hoveredLabel" class="VisitLabel">${label}</span>`
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      showLastLabel: false,
      left: 60,
      crosshair: {
        width: 1,
        color: `#fff`
      },
      title: {
        useHTML: true,
        text: visitRegYAxisTitle,
        align: "high",
        style: {
          fontWeight: "500",
          fontSize: "12px",
          textAlign: "left",
          color: "#868E96"
        },
        rotation: 0,
        x: visitRegYAxisTitleXPosition,
        y: -40
      },
      labels: {
        align: `center`,
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this)
          return `<span id="hoveredLabel" class="VisitLabelYAxis">${label}</span>`
        }
      },
      gridLineDashStyle: `Dash`,
      gridLineColor: `#F2F2F7`,
      gridLineWidth: 1,
      gridZIndex: 0
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        // eslint-disable-next-line
        //@ts-ignore
        borderRadiusTopLeft: 8,
        // eslint-disable-next-line
        //@ts-ignore
        borderRadiusTopRight: 8
      },
      column: {
        pointPadding: 0,
        groupPadding: 0,
        states: {
          hover: {
            color: hoverColor
          }
        }
      },
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, `${hoverColor}20`],
            [1, `${hoverColor}00`]
          ]
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 8
            }
          }
        },
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        threshold: null
      }
    },
    tooltip: {
      borderRadius: 20,
      style: {
        height: 44,
        width: 68
      },
      borderColor: `#434345`,
      useHTML: true,
      dateTimeLabelFormats: {
        day: "%e.%m",
        week: "%e.%m",
        month: "%e.%m",
        year: "%e.%m"
      },
      formatter: function() {
        return `<div class="VisitRegTooltipWrapper FlexColumn"><span class="VisitRegPointUp">${this.y} ${textConstants.tooltipPeople}</span><span class="VisitRegPointDown">${this.x}</span></div>`
      },
      backgroundColor: `#292929`
    },
    series: [
      {
        type: chartType,
        data: seriesData,
        color: columnColor
      }
    ]
  }

  const showEmptyData = seriesData.length <= 1

  return (
    <div className="CashboxChartsWrapper VisitAndRegChartsWrapper">
      {showEmptyData ? (
        <EmptyTable />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  )
}

export default VisitChart
