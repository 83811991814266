import React, { useMemo, useState } from "react"

import { ButtonFaded, ButtonPrimary, buttonText } from "Components/Button"
import { SelectComponent } from "Components/Select"

import SortTableFilter from "../SortFilter"
import { fromToConstants } from "../FromToFilter/constants"

import { SortOrders } from "../../types"

const searchText = {
  label: `Применить фильтр`
}

type SelectFilterProps = {
  value?: string
  currentSortedField?: string
  currentField: string
  isHall?: boolean
  withSort: boolean
  options?: any[]
  placeholder: string
  label?: string
  closeFunc?: () => void
  showSearch?: boolean
  currentSort?: { order?: SortOrders; field?: string }
  changeFilter: (value: {
    value?: string
    sort: { order?: SortOrders; field?: string }
  }) => void
}

type SelectFilterState = {
  value?: string
  order?: SortOrders
  sortField?: string
  hasSort: boolean
}

const SelectFilter = ({
  placeholder,
  label,
  value,
  closeFunc,
  currentField,
  currentSort,
  showSearch,
  isHall,
  withSort,
  options,
  changeFilter
}: SelectFilterProps) => {
  const activeSort = currentSort?.field === currentField
  const initialState: SelectFilterState = {
    value,
    sortField: activeSort ? currentField : currentSort?.field,
    hasSort: activeSort,
    order: !activeSort ? SortOrders.asc : currentSort?.order
  }

  const [state, setState] = useState<SelectFilterState>(initialState)

  const showClear = value || (withSort && activeSort)

  const optionsByHall = useMemo(() => {
    if (!isHall) {
      return options
    } else {
      const halls = JSON.parse(
        localStorage.getItem(`halls`) || `[]`
      ) as string[]
      return halls.map(item => ({
        label: item,
        value: item
      }))
    }
  }, [])
  const disabledApply = !state.value && !state.hasSort

  return (
    <div>
      {withSort && (
        <div>
          <SortTableFilter
            order={state.order}
            changeOrder={value =>
              setState({
                ...state,
                order: value
              })
            }
            hasSort={state.hasSort}
            changeHasSort={value =>
              setState({
                ...state,
                hasSort: value,
                sortField: value ? currentField : currentSort?.field
              })
            }
          />
        </div>
      )}
      <div className="SubtitleFirstText">{label || searchText.label}</div>
      <SelectComponent
        filterOption={(input, option) =>
          !!input && option?.value === Number(input)
        }
        showSearch={showSearch}
        value={state.value}
        onChange={option => setState({ ...state, value: option })}
        options={optionsByHall}
        placeholder={placeholder}
        allowClear={false}
      />
      <div className="FilterButtonsWrapper MarginTop16">
        {showClear && (
          <ButtonFaded
            onClick={() => {
              changeFilter({
                value: undefined,
                sort: {
                  field: activeSort ? undefined : currentSort?.field,
                  order: activeSort ? undefined : currentSort?.order
                }
              })

              !!closeFunc && closeFunc()
            }}
          >
            {fromToConstants.clearText}
          </ButtonFaded>
        )}
        <ButtonPrimary
          disabled={disabledApply}
          onClick={() => {
            changeFilter({
              value: state.value,
              sort: {
                order: state.order,
                field: state.hasSort ? currentField : currentSort?.field
              }
            })
            if (!!closeFunc) {
              closeFunc()
            }
          }}
        >
          {buttonText.apply}
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default SelectFilter
