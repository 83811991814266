import React from "react"

import { ReactComponent as PlatinumIcon } from "./icons/platinum.svg"
import { ReactComponent as PremiumIcon } from "./icons/premium.svg"
import { ReactComponent as CrownIcon } from "./icons/crown.svg"
import { ReactComponent as StandardIcon } from "./icons/standard.svg"

export const GuestStatus = ({ status }: { status: string }) => {
  let config = { background: ``, icon: <div /> }
  if (status.includes(`VIP`)) {
    if (status === `VIP Платина`) {
      config = {
        background: `linear-gradient(94.86deg, #D1A792 1.63%, #F2CFB9 96.33%)`,
        icon: <PlatinumIcon />
      }
    }
    if (status === `VIP Золото`) {
      config = {
        background: `linear-gradient(94.86deg, #FCD400 1.63%, #FFF59E 96.33%)`,
        icon: <CrownIcon />
      }
    }
    if (status === `VIP Серебро`) {
      config = {
        background: `linear-gradient(94.86deg, #E3E9EE 1.63%, #EDF0F4 96.33%)`,
        icon: <CrownIcon />
      }
    }
  } else if (status.includes(`Премьер`)) {
    config = {
      background: `#A5D8FF`,
      icon: <PremiumIcon />
    }
  } else {
    config = {
      background: `#99E9F2`,
      icon: <StandardIcon />
    }
  }
  return (
    <div
      className="GuestHistoryStatus"
      style={{ color: `#212529`, background: config.background }}
    >
      {config.icon}
      <span className="CaptionTextBold">{status}</span>
    </div>
  )
}
