import React, { useState } from "react"

import { useNow } from "api/hooks"

import { isMobile } from "constants/index"

import Title from "Components/Title"
import { ButtonPrimary } from "Components/Button"

import { payrollText } from "./constants"

// Table components
import PayrollTable from "./Table"
import ClearFilter from "./Table/Components/ClearButton"
import { FilterState } from "./Table/types"
import { initialFilter } from "./Table/constants"

// CRUD component
import PayrollModalComponent from "./Modal"
import { ModalData } from "./Modal/types"
import { initialModalData, modalText } from "./Modal/constants"
import StatusNotifyModal from "../Components"

const Payrolls = () => {
  const [filters, setFilter] = useState<FilterState>(initialFilter)
  const [payrollModal, setPayrollModal] = useState<ModalData>(initialModalData)

  const { refresh: refreshTable, timestamp: refreshTrigger } = useNow()

  return (
    <div>
      <div className="MainPageContentWrapper">
        <div className="MainPageContentRestrictions">
          <div className="FlexRow">
            <Title
              titleText={payrollText.title}
              tooltipText={payrollText.tooltip}
            />
            {!isMobile && (
              <div className="PayrollsTitleButtons FlexRow Gap16">
                <ClearFilter filters={filters} setFilter={setFilter} />
                <StatusNotifyModal baseUrl={payrollText.notifyBaseUrl} />
                <ButtonPrimary
                  onClick={() =>
                    setPayrollModal({ open: true, editData: undefined })
                  }
                >
                  {modalText.trigger}
                </ButtonPrimary>
              </div>
            )}
          </div>
          {isMobile && (
            <div className="PayrollsTitleButtons FlexColumn Gap16 MarginBottom16 MarginLeft16">
              <ButtonPrimary
                onClick={() =>
                  setPayrollModal({ open: true, editData: undefined })
                }
              >
                {modalText.trigger}
              </ButtonPrimary>
              <StatusNotifyModal baseUrl={payrollText.notifyBaseUrl} />
              <ClearFilter filters={filters} setFilter={setFilter} />
            </div>
          )}
          <PayrollTable
            filters={filters}
            setFilter={setFilter}
            setPayrollModal={setPayrollModal}
            refreshTrigger={refreshTrigger}
            refreshTable={refreshTable}
          />
          {payrollModal.open && (
            <PayrollModalComponent
              data={payrollModal.editData}
              close={() => {
                setPayrollModal({ open: false, editData: undefined })
                refreshTable()
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Payrolls
