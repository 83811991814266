import { checkNegative } from "constants/index"

export const columnCashbox = [
  {
    title: "Выручка, ₽",
    dataIndex: "income",
    key: "income",
    width: "27%",
    ...checkNegative
  },
  {
    title: "Выплаты, ₽",
    dataIndex: "outcome",
    key: "outcome",
    width: "27%",
    ...checkNegative
  },
  {
    title: "Результат, ₽",
    dataIndex: "result",
    key: "result",
    width: "27%",
    ...checkNegative
  }
]
