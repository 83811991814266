import { BetsBySumData } from "./types"

export const initialData: BetsBySumData = {
  categories: [],
  series: [
    {
      data: [],
      color: "rgba(140, 233, 154, 1)",
      pointPadding: -2
    },
    {
      data: [],
      color: "rgba(255, 168, 168, 1)",
      pointPadding: -1
    },
    {
      data: [],
      color: "rgba(255, 107, 107, 1)",
      pointPadding: 0
    }
  ]
}
