import React from "react"

import moment from "moment"

import { checkRole } from "constants/index"

import { ReactComponent as MobileCalendarIcon } from "Components/icons/mobile/calendar_outline_16.svg"

import { TableFields, columnsTextTitle } from "./constants"
import { TableData } from "./types"

const RegistrationCheckMobileTable = ({ data }: { data: TableData[] }) => {
  const cashboxColumn = checkRole(`PARTNER`)
  return (
    <div className="Gap12 FlexColumn Margin16">
      {data.map(item => {
        const dateByFormat = moment(item.registered_at)
        const date = dateByFormat.format(`DD.MM.YYYY`)
        const time = dateByFormat.format(`HH:mm:ss`)

        return (
          <div
            className="RegistrationCheckMobileTableWrapper "
            key={`registration_check_${item.hall}`}
          >
            <div className="RegistrationCheckMobileTableHeader FlexRow">
              <div className="FlexRow Gap4">
                <span className="CaptionText Gray600Color MarginTop2">
                  {columnsTextTitle.mobile_hall}
                </span>
                <span className="SubtitleFirstText">{item.hall}</span>
              </div>
              <div className="FlexRow Gap4 MarginLeftAuto">
                <div className="MarginTop2">
                  <MobileCalendarIcon />
                </div>
                <div className="FlexRow Gap8">
                  <span className="TextOne">{date}</span>
                  <span className="TextOne Gray600Color">{time}</span>
                </div>
              </div>
            </div>
            <div className="RegistrationCheckMobileTableContent FlexRow Gap18">
              <div className="FlexColumn Gap4">
                <span className="CaptionText Gray600Color">
                  {columnsTextTitle[TableFields.wallet_number]}
                </span>
                <span className="TextOne">{item.wallet_number}</span>
              </div>
              {cashboxColumn && (
                <div className="FlexColumn Gap4">
                  <span className="CaptionText Gray600Color">
                    {columnsTextTitle[TableFields.in]}
                  </span>
                  <span className="TextOne">{item.cashbox?.in}</span>
                </div>
              )}
              {cashboxColumn && (
                <div className="FlexColumn Gap4">
                  <span className="CaptionText Gray600Color">
                    {columnsTextTitle[TableFields.out]}
                  </span>
                  <span className="TextOne">{item.cashbox?.out}</span>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
export default RegistrationCheckMobileTable
