import moment from "moment"

export const currentMessageConst = {
  maxHallsCount: (window.innerWidth - 320) / 40,
  back: `Назад`,
  halls: `Клубы: `,
  saveAll: `Сохранить все`,
  fileCount: (value: number) => `${value} вложения`,
  show: {
    hide: `Скрыть`,
    all: `Все`
  }
}

export const initialMessage = {
  data: {
    id: 0,
    pinned: false,
    date: moment(),
    title: ``,
    text: ``,
    files: [],
    halls: []
  },
  pending: false
}
