import { NotifyModalState } from "./types"

export const statusNotifyConstants = {
  button: `Оповещения о статусах`,
  title: `Оповещения о статусах`,
  description: (count: number) => `Пользователей в рассылке: ${count}`,
  buttons: {
    add: `Добавить пользователя`,
    pause: `Остановить рассылки`,
    remove: `Очистить список`
  },
  hallPrefix: `№`
}

export const getInitialState = (hall: number): NotifyModalState => ({
  open: undefined,
  editData: undefined,
  activeHall: hall,
  pending: false
})
