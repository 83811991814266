import React, { useEffect, useRef, useState } from "react"
import { CalcStateProps, CreateTabsProps, sendingsTypeValues } from "../types"
import { ReactComponent as IconBlue } from "Components/icons/icon_blue.svg"
import SmsTabs from "../SmsTabs"
import {
  getCalculate,
  canShowStep4,
  canShowStep5,
  canShowStep8,
  findMacros,
  getSmsSettings,
  getSmsText,
  getStepName,
  isValidRuPhone,
  validate
} from "../helper"
import { smsConstants } from "../constants"
import { ButtonPrimary } from "Components/Button"
import Step1 from "../Steps/Step1"
import Step2 from "../Steps/Step2"
import Step3 from "../Steps/Step3"
import Step4 from "../Steps/Step4"
import Step5 from "../Steps/Step5"
import Step6 from "../Steps/Step6"
import Step7 from "../Steps/Step7"
import Step8 from "../Steps/Step8"
import SmsPhonePreview from "../SmsPhonePreview"
import SmsModalPreview from "../SmsModalPreview"
import SmsSuccessModal from "../SmsSuccessModal"
import { useRequestParams } from "api/hooks"
import { CancelTokenSource } from "axios"
import moment from "moment/moment"
import { isEmpty } from "lodash"

const CreateSms = ({
  smsState,
  setSmsState,
  activeTab,
  setActiveTab
}: CreateTabsProps) => {
  const [calcState, setCalcState] = useState<CalcStateProps>({
    ...smsConstants.calcStateInit
  })
  const [isTimerCleared, setIsTimerCleared] = useState(false)

  const { history, logout } = useRequestParams()

  /* Запрашиваем начальные настройки для заявок СМС */
  useEffect(() => {
    getSmsSettings({
      smsState,
      setSmsState,
      logout,
      history
    })
  }, [])

  /* Флаги, разрешающие показ шагов */
  const showStep4 = canShowStep4(smsState)
  const showStep5 = canShowStep5(smsState)
  const showStep8 = canShowStep8(smsState)

  const phonesString = `${smsState.phones}`

  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const cancelRef = useRef<CancelTokenSource | null>(null)

  const clearTimer = (timer: NodeJS.Timeout | null) => {
    if (timer) {
      clearTimeout(timer)
    }
  }

  useEffect(() => {
    /* Доп условие запуска калькуляции для превью
     * (калькуляция включает в себя частичную валидацию)
     */
    const doCalculate =
      /* Если выбран кастомный текст и он меняется и открыт шаг 4 */
      (smsState.selectedTypeId === smsConstants.customSmsOptionValue &&
        !!smsState.customSmsText?.length &&
        smsState.sendingType === sendingsTypeValues.all &&
        showStep4) ||
      /* Если выбран тип рассылки "По всем номерам" */
      (smsState.sendingType === sendingsTypeValues.all && showStep4) ||
      /* Или если выбраны типы рассылки "По указанным номерам" и заполнены телефоны */
      (smsState.sendingType !== sendingsTypeValues.all && !!phonesString.length)

    // Когда выбирают период с датами, запрос должен идти не при выборе периода,
    // а когда заполнены даты в выпадающем RangePicker
    const isCheckedDatesPeriod =
      (smsState.period === smsConstants.periodTypeOptionsDates &&
        !isEmpty(smsState.dates)) ||
      smsState.period !== smsConstants.periodTypeOptionsDates

    if (doCalculate && isCheckedDatesPeriod) {
      clearTimer(timerRef.current)
      setIsTimerCleared(false)
      cancelRef.current?.cancel()
      const { calculate, cancelToken } = getCalculate({
        smsState,
        setSmsState,
        calcState,
        setCalcState,
        logout,
        history
      })
      cancelRef.current = cancelToken
      timerRef.current = setTimeout(() => {
        calculate()
        clearTimer(timerRef.current)
        timerRef.current = null
        setIsTimerCleared(true)
      }, smsConstants.calcTimeOut)
      return () => {
        clearTimer(timerRef.current)
        timerRef.current = null
      }
    }
  }, [
    smsState.sumFrom,
    smsState.sumTo,
    smsState.visitFrom,
    smsState.visitTo,
    smsState.sendingType,
    showStep4,
    phonesString.length,
    smsState.period,
    smsState.selectedTypeId,
    smsState.customSmsText,
    smsState.extraParams,
    smsState.dates
  ])

  const [modal, setModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  /* Найденные в тексте макросы */
  const macrosFindedArr = findMacros(getSmsText(smsState), smsState)

  /* Флаг выбора готового текста шаблона */
  const templateSmsTextSelected =
    smsState.selectedTypeId !== smsConstants.customSmsOptionValue

  /* Флаг сокрытия Шага 3 */
  const isHiddenStep3 =
    (!macrosFindedArr.length && !!smsState.selectedTextId) ||
    !!smsState.customSmsText

  /* Флаг переименовывания (на 1 назад) номеров шагов при сокрытии Шага 3 */
  const renameStepNumber = isHiddenStep3 || !templateSmsTextSelected

  const showSendBtn =
    showStep8 &&
    !!smsState.contactName.length &&
    !!smsState.contactPhone.length &&
    !!smsState.dateToSend &&
    !!smsState.timeToSend

  const createHandler = () => {
    if (!isValidRuPhone(smsState.contactPhone)) {
      setSmsState({
        ...smsState,
        contactPhoneError: smsConstants.step8.phoneErrorText
      })

      return
    }

    validate({
      smsState,
      setSmsState,
      setModal,
      history,
      logout
    })
  }

  return (
    <article>
      <SmsTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="SmsWrapper CreateSmsWrapper">
        <section className="CreateSms_inputs">
          <div className="CreateSms_infoBlock">
            <IconBlue />
            <span>{smsConstants.infoText}</span>
          </div>

          <Step1
            smsState={smsState}
            setSmsState={setSmsState}
            templateSmsTextSelected={templateSmsTextSelected}
          />

          <Step2
            smsState={smsState}
            setSmsState={setSmsState}
            templateSmsTextSelected={templateSmsTextSelected}
          />

          {templateSmsTextSelected && !isHiddenStep3 && (
            <Step3
              smsState={smsState}
              setSmsState={setSmsState}
              macrosFindedArr={macrosFindedArr}
            />
          )}

          <Step4
            smsState={smsState}
            setSmsState={setSmsState}
            stepNumber={getStepName(
              smsConstants.step4.orderNumber,
              renameStepNumber
            )}
            showStep={showStep4}
          />

          <Step5
            smsState={smsState}
            setSmsState={setSmsState}
            stepNumber={getStepName(
              smsConstants.step5.orderNumber,
              renameStepNumber
            )}
            showStep={showStep5}
          />

          <Step6
            smsState={smsState}
            setSmsState={setSmsState}
            stepNumber={getStepName(
              smsConstants.step6.orderNumber,
              renameStepNumber
            )}
          />

          <Step7
            smsState={smsState}
            setSmsState={setSmsState}
            stepNumber={getStepName(
              smsConstants.step7.orderNumber,
              renameStepNumber
            )}
          />

          <Step8
            smsState={smsState}
            setSmsState={setSmsState}
            stepNumber={getStepName(
              smsConstants.step8.orderNumber,
              renameStepNumber
            )}
            showStep={showStep8}
          />

          {showSendBtn && (
            <ButtonPrimary
              disabled={
                smsState.pending ||
                calcState.calculating ||
                !isTimerCleared ||
                !!smsState.errors.phonesTotal
              }
              loading={
                smsState.pending || calcState.calculating || !isTimerCleared
              }
              onClick={createHandler}
              className="CreateSms_applyButton"
            >
              {smsConstants.applyText}
            </ButtonPrimary>
          )}
        </section>

        <SmsPhonePreview
          smsState={smsState}
          calcState={calcState}
          showStep4={showStep4}
          templateSmsTextSelected={templateSmsTextSelected}
        />
      </div>
      {modal && (
        <SmsModalPreview
          smsState={smsState}
          setSmsState={setSmsState}
          setModal={setModal}
          templateSmsTextSelected={templateSmsTextSelected}
          calcState={calcState}
          logout={logout}
          history={history}
          setSuccessModal={setSuccessModal}
        />
      )}
      {successModal && (
        <SmsSuccessModal
          smsState={smsState}
          setSmsState={setSmsState}
          setActiveTab={setActiveTab}
          setSuccessModal={setSuccessModal}
        />
      )}
    </article>
  )
}

export default CreateSms
