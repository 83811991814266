import React from "react"
import InputComponent from "Components/Input"
import { macros, Step3CommandFieldProps } from "Containers/Sms/types"
import { smsConstants } from "Containers/Sms/constants"

const IncrementedMacro = ({
  macroName,
  smsState,
  setSmsState,
  placeHolder
}: Step3CommandFieldProps) => {
  const macroNumber = macroName
    .replace(macros.command, ``)
    .replace(macros.sum, ``)
  const paramForExtra = macroName.replace(smsConstants.ampersandValue, ``)

  return (
    <div className="CreateSms_input CreateSms_inputShort CreateSms_inputShort_single">
      <label className="CreateSms_inputLabel">{`${placeHolder} ${macroNumber}`}</label>
      <div className="CreateSms_inputInner">
        <InputComponent
          error={
            smsState.errors?.macros && smsState.errors?.macros[paramForExtra]
          }
          greyBackground
          value={smsState.extraParams[paramForExtra]}
          onChange={({ target: { value } }) => {
            setSmsState({
              ...smsState,
              extraParams: {
                ...smsState.extraParams,
                [paramForExtra]: value
              }
            })
          }}
          placeholder={placeHolder}
        />
      </div>
    </div>
  )
}

export default IncrementedMacro
