import React, { useMemo } from "react"
import { ModalFormComponent } from "Components/Modal"
import { WalletModalColumns, WalletModalProps } from "./types"
import {
  columnsModalWallet,
  columnsModalWalletFull,
  MobileCell,
  text
} from "./constants"
import { ButtonPrimary } from "Components/Button"
import { TableColumns } from "../Table/constants"
import TableNew from "Components/TableNew"
import { ReactComponent as NotFoundIcon } from "Components/icons/illustrations/not_found.svg"
import SpinComponent from "Components/Spin"
import { isMobile, roleConstants } from "constants/index"

export const WalletModal: React.FC<WalletModalProps> = ({
  onClose,
  data,
  id,
  onHistoryButtonClick,
  loading
}) => {
  const tableData = useMemo(() => {
    if (data && id) {
      return [{ [TableColumns.wallet_id]: id, ...data }]
    }
    return []
  }, [data, id])

  const { isRiskManagement } = roleConstants()

  const isEmpty = tableData.length <= 0

  return (
    <ModalFormComponent
      width={500}
      title={isEmpty ? "" : text.title}
      className={`SportBoomWalletModal ${
        isEmpty ? "SportBoomWalletModal_empty" : ""
      } ${isMobile ? "SportBoomWalletModal_mobile" : ""}`}
      close={onClose}
      withoutCross
    >
      {loading ? (
        <SpinComponent />
      ) : (
        <div
          className={`SportBoomWalletModalContainer ${
            isEmpty ? "SportBoomWalletModalContainer_empty" : ""
          }`}
        >
          {isEmpty ? (
            <div className="SportBoomWalletModalEmptyWrapper">
              <div className="SportBoomWalletModalEmptyWrapper_title">
                {text.title}
              </div>
              <NotFoundIcon />
              <div className="SportBoomWalletModalEmptyWrapper_subtitle">
                {text.empty}
              </div>
            </div>
          ) : isMobile ? (
            <div className="SportBoomWalletModalMobileTableWrapper">
              <table className="SportBoomWalletModalMobileTable">
                <tbody>
                  <tr>
                    <MobileCell
                      title={text.columns[TableColumns.wallet_id]}
                      value={tableData[0][TableColumns.wallet_id]}
                    />
                    <MobileCell
                      title={text.columns[WalletModalColumns.risk_profile_name]}
                      value={tableData[0][WalletModalColumns.risk_profile_name]}
                      tooltip={
                        tableData[0][
                          WalletModalColumns.risk_profile_description
                        ]
                      }
                    />
                  </tr>
                  <tr>
                    <MobileCell
                      title={text.columns[WalletModalColumns.limit_percent]}
                      value={tableData[0][WalletModalColumns.limit_percent]}
                    />
                    {isRiskManagement && (
                      <MobileCell
                        title={text.columns[WalletModalColumns.delay]}
                        value={tableData[0][WalletModalColumns.delay]}
                      />
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <TableNew
              columns={
                isRiskManagement ? columnsModalWalletFull : columnsModalWallet
              }
              data={tableData}
            />
          )}
          <div className="SportBoomWalletModalButtonsContainer">
            <ButtonPrimary
              onClick={onClose}
              classNameAdditional="SportBoomWalletModalButtonRoger"
              isMiddle
            >
              {text.rogerThatButton}
            </ButtonPrimary>
            {!isEmpty && isRiskManagement && (
              <ButtonPrimary onClick={onHistoryButtonClick} isMiddle>
                {text.historyButton}
              </ButtonPrimary>
            )}
          </div>
        </div>
      )}
    </ModalFormComponent>
  )
}
