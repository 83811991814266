import { ModalData, PaymentModalFields, PaymentModalFormValues } from "./types"

import { FilesList } from "Components/File/types"
import { omit } from "lodash"
import {
  FilterDurations,
  PickerDurations
} from "Components/FilterInfoPanel/types"
import moment, { Moment } from "moment"

export const initialModalData: ModalData = {
  open: false,
  editData: undefined
}

export const allowedTypes = ".doc, .docx, .xls, .xlsx, .jpeg, .jpg, .png, .pdf"

export const documentNumberRule = [
  {
    validator: <T>(_: T, value?: string) => {
      const regex = /^[a-zA-Z0-9а-яёА-ЯЁ\/-]+$/
      return !!value && !regex.test(value)
        ? Promise.reject(new Error(`Неверный формат номера документа`))
        : Promise.resolve()
    }
  }
]

export const fileRule = (empty: boolean) =>
  empty
    ? Promise.resolve()
    : Promise.reject(new Error(`Обязательно прикрепить хотя бы один файл`))

export const paymentPeriodTypeOptions = [
  {
    label: "День",
    value: FilterDurations.daily
  },
  {
    label: "Месяц",
    value: FilterDurations.monthly
  }
]

export const paymentPeriodTypeDict = {
  [FilterDurations.monthly]: PickerDurations.month,
  [FilterDurations.daily]: PickerDurations.date
}

export const initialValues: PaymentModalFormValues = {
  [PaymentModalFields.contractor]: undefined,
  [PaymentModalFields.inn]: undefined,
  [PaymentModalFields.sum]: undefined,
  [PaymentModalFields.payment_purpose]: undefined,
  [PaymentModalFields.comment]: undefined,
  [PaymentModalFields.payment_period_type]: paymentPeriodTypeOptions[0].value,
  [PaymentModalFields.payment_period]: undefined,
  [PaymentModalFields.files]: []
}

export const modalText = {
  trigger: `Добавить заявку`,
  title_add: `Новая заявка на оплату`,
  [PaymentModalFields.contractor]: {
    label: `Контрагент`,
    placeholder: `Укажите название, макс. 100 символов`
  },
  [PaymentModalFields.inn]: {
    label: `ИНН`,
    placeholder: `10 цифр для юр.лица, 12 цифр для физ.лица`
  },
  [PaymentModalFields.payment_period_type]: "Тип периода",
  [PaymentModalFields.payment_purpose]: "Назначение платежа",
  [PaymentModalFields.sum]: {
    label: `Сумма, ₽`,
    placeholder: `Укажите сумму к оплате`
  },
  [PaymentModalFields.payment_period]: `Период оплаты`,
  [PaymentModalFields.comment]: {
    label: `Комментарий`,
    placeholder: `Укажите комментарий, макс. 100 символов`
  },
  upload: {
    descriptionOne: `Прикрепите до 2 файлов, не более 5Мб`,
    descriptionTwo: `Формат: doc, docx, xls, xlsx, jpeg, jpg, png, pdf`
  },
  apply: {
    add: `Отправить`,
    save: `Сохранить`
  },
  success: {
    add_title: `Заявка на оплату добавлена`,
    button: `Хорошо`
  }
}

type PrepareDataToSaveType = {
  values: PaymentModalFormValues
  files: FilesList[]
}

export const paymentPurposeOptions = [
  {
    label: "Аренда",
    value: "Аренда"
  },
  {
    label: "Депозит по аренде",
    value: "Депозит по аренде"
  },
  {
    label: "Интернет",
    value: "Интернет"
  },
  {
    label: "Тревожная сигнализация",
    value: "Тревожная сигнализация"
  },
  {
    label: "Пожарная сигнализация",
    value: "Пожарная сигнализация"
  },
  {
    label: "ЧОП",
    value: "ЧОП"
  },
  {
    label: "Оборудование",
    value: "Оборудование"
  },
  {
    label: "Прочее",
    value: "Прочее"
  }
]

export const prepareDataToSave = ({ values, files }: PrepareDataToSaveType) => {
  const filesData = files.map(item => omit(item, [`uid`]))
  const sumWithDot = values[PaymentModalFields.sum]
    ? parseFloat(values[PaymentModalFields.sum].replace(`,`, `.`)).toFixed(2)
    : ""
  const dates = (values?.[PaymentModalFields.payment_period] as any) as [
    Moment,
    Moment
  ]
  const [from, to] = dates
  const datesNew = {
    from: `${moment(from).format("YYYY-MM-DD")}T00:00:00.000Z`,
    to: `${moment(to).format("YYYY-MM-DD")}T00:00:00.000Z`
  }

  if (
    values?.[PaymentModalFields.payment_period_type] === FilterDurations.monthly
  ) {
    datesNew.from = `${moment(from)
      .startOf("month")
      .format("YYYY-MM-DD")}T00:00:00.000Z`
    datesNew.to = `${moment(to)
      .endOf("month")
      .format("YYYY-MM-DD")}T00:00:00.000Z`
  }
  return {
    ...values,
    [PaymentModalFields.files]: filesData,
    [PaymentModalFields.sum]: sumWithDot,
    [PaymentModalFields.payment_period]: datesNew
  }
}
