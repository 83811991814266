import React, { useEffect, useState } from "react"

import { Form } from "antd"
import { UploadFile } from "antd/lib/upload/interface"

import { useRequestParams } from "api/hooks"
import betRequest from "api/request"

import { isMobile } from "constants/index"

import { ModalFormComponent } from "Components/Modal"
import { ButtonPrimary } from "Components/Button"
import { FilesList } from "Components/File/types"
import { SelectComponent } from "Components/Select"
import { RangePickerComponent, RangePickerPeriod } from "Components/DatePicker"
import InputComponent from "Components/Input"
import FileComponent, { UploadComponent } from "Components/File"
import { rules } from "Components/Form/rules"

import { ReactComponent as SuccessIcon } from "Components/icons/illustrations/thumb_up.svg"

import {
  PaymentModalFields,
  PaymentModalFormValues,
  PaymentModalProps
} from "./types"
import {
  allowedTypes,
  fileRule,
  initialValues,
  modalText,
  paymentPeriodTypeDict,
  paymentPeriodTypeOptions,
  paymentPurposeOptions,
  prepareDataToSave
} from "./constants"
import { paymentText } from "../constants"
import moment from "moment"
import {
  FilterDurations,
  PickerFormats
} from "Components/FilterInfoPanel/types"

const PaymentModal = ({ data, close }: PaymentModalProps) => {
  const isCopy = !!data
  const [form] = Form.useForm()
  const [pending, setPending] = useState(false)
  const {
    developer,
    addDeveloperLink,
    history,
    logout,
    current_hall
  } = useRequestParams()

  const [reqFiles, setReqFiles] = useState<FilesList[]>([])
  const [fileList, changeFileList] = useState<UploadFile[]>([])
  const [isSuccess, toggleSuccess] = useState(false)
  const [items, setItems] = useState(paymentPurposeOptions)
  const [newOption, setNewOption] = useState("")
  const [
    selectedPeriodType,
    setSelectedPeriodType
  ] = useState<null | FilterDurations>(null)

  const handleSearch = (value: string) => {
    setNewOption(value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && newOption) {
      setItems(prev => [...prev, { label: newOption, value: newOption }])
      setNewOption("")
      form.setFieldsValue({ [PaymentModalFields.payment_purpose]: newOption })
    }
  }

  const onFinish = (values: PaymentModalFormValues) => {
    setPending(true)
    const requestBody = prepareDataToSave({
      values,
      files: reqFiles
    })

    betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      method: `POST`,
      url: `v1/requests/payments/save`,
      requestBody: { ...requestBody, hall: Number(current_hall[0]) }
    })
      .then(() => {
        setPending(false)
        toggleSuccess(true)
      })
      .catch(() => setPending(false))
  }

  const applyButton = (
    <ButtonPrimary htmlType="submit" disabled={pending}>
      {isCopy ? modalText.apply.save : modalText.apply.add}
    </ButtonPrimary>
  )
  const disableUpload = reqFiles.length === 2
  const hideUploadMobile = isMobile ? disableUpload : false

  useEffect(() => {
    if (selectedPeriodType) {
      form.setFieldValue(
        PaymentModalFields.payment_period_type,
        selectedPeriodType
      )
    }
  }, [selectedPeriodType])

  return (
    <ModalFormComponent width={880} close={close}>
      {!isSuccess ? (
        <div className="PaymentModalWrapper FlexColumn Gap24">
          <div className={isMobile ? `HeadlineThirdText` : `HeadlineFirstText`}>
            {modalText.title_add}
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={value => {
              if (value[PaymentModalFields.payment_period_type]) {
                setSelectedPeriodType(
                  value[PaymentModalFields.payment_period_type]
                )
              }
            }}
            initialValues={
              data
                ? {
                    ...data,
                    [PaymentModalFields.payment_period]:
                      data?.[PaymentModalFields.payment_period]?.to &&
                      data?.[PaymentModalFields.payment_period]?.from
                        ? {
                            from: moment(
                              data?.[PaymentModalFields.payment_period].from
                            ),
                            to: moment(
                              data?.[PaymentModalFields.payment_period].to
                            )
                          }
                        : undefined
                  }
                : {
                    ...initialValues
                  }
            }
            autoComplete="off"
          >
            <div className="PaymentModalContentWrapper FlexColumn">
              <div
                className={`PaymentModalFormFirstRow ${
                  isMobile ? `FlexColumn` : `FlexRow Gap24`
                }`}
              >
                <Form.Item
                  name={PaymentModalFields.contractor}
                  label={
                    <span className="SubtitleFirstText">
                      {modalText[PaymentModalFields.contractor].label}
                    </span>
                  }
                  rules={rules.required}
                >
                  <InputComponent
                    placeholder={
                      modalText[PaymentModalFields.contractor].placeholder
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={PaymentModalFields.payment_purpose}
                  label={
                    <span className="SubtitleFirstText">
                      {modalText[PaymentModalFields.payment_purpose]}
                    </span>
                  }
                  rules={rules.required}
                >
                  <SelectComponent
                    placeholder={paymentText.paymentPurposeSelect}
                    options={items}
                    onSearch={handleSearch}
                    onKeyDown={handleKeyDown}
                    showSearch
                  />
                </Form.Item>
              </div>
              <div
                className={`PaymentModalFormSecondRow ${
                  isMobile ? `FlexColumn` : `FlexRow Gap24`
                }`}
              >
                <Form.Item
                  name={PaymentModalFields.inn}
                  label={
                    <span className="SubtitleFirstText">
                      {modalText[PaymentModalFields.inn].label}
                    </span>
                  }
                  rules={[...rules.required, ...rules.inn]}
                >
                  <InputComponent
                    placeholder={modalText[PaymentModalFields.inn].placeholder}
                    type="number"
                    hideControls
                  />
                </Form.Item>
                <Form.Item
                  name={PaymentModalFields.sum}
                  label={
                    <span className="SubtitleFirstText">
                      {modalText[PaymentModalFields.sum].label}
                    </span>
                  }
                  rules={[...rules.required]}
                  normalize={(value, prevValue) => {
                    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                      return value
                    }
                    if (!prevValue) {
                      return ""
                    } else {
                      return prevValue
                    }
                  }}
                >
                  <InputComponent
                    placeholder={modalText[PaymentModalFields.sum].placeholder}
                    hideControls
                  />
                </Form.Item>
              </div>
              <div
                className={`PaymentModalFormFirstRow ${
                  isMobile ? `FlexColumn` : `FlexRow Gap24`
                }`}
              >
                <Form.Item
                  name={PaymentModalFields.payment_period_type}
                  label={
                    <span className="SubtitleFirstText">
                      {modalText[PaymentModalFields.payment_period_type]}
                    </span>
                  }
                  rules={rules.required}
                >
                  <SelectComponent
                    options={paymentPeriodTypeOptions}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item
                  name={PaymentModalFields.payment_period}
                  label={
                    <span className="SubtitleFirstText">
                      {modalText[PaymentModalFields.payment_period]}
                    </span>
                  }
                  className="PaymentModalRangePicker"
                  rules={rules.required_date.long}
                >
                  <RangePickerComponent
                    picker={
                      form.getFieldValue(PaymentModalFields.payment_period_type)
                        ? (paymentPeriodTypeDict[
                            form.getFieldValue(
                              PaymentModalFields.payment_period_type
                            ) as FilterDurations.daily | FilterDurations.monthly
                          ] as RangePickerPeriod)
                        : (paymentPeriodTypeDict[
                            data?.[PaymentModalFields.payment_period_type] as
                              | FilterDurations.daily
                              | FilterDurations.monthly
                          ] as RangePickerPeriod)
                    }
                    format={
                      selectedPeriodType === FilterDurations.monthly
                        ? PickerFormats.month
                        : PickerFormats.dayMonthYear
                    }
                    datesAsObject={!!data}
                  />
                </Form.Item>
              </div>
              <div
                className={`PaymentModalFormSecondRow ${
                  isMobile ? `FlexColumn` : `FlexRow Gap24`
                }`}
              >
                <Form.Item
                  name={PaymentModalFields.comment}
                  label={
                    <span className="SubtitleFirstText">
                      {modalText[PaymentModalFields.comment].label}
                    </span>
                  }
                  rules={rules.max_length_100}
                >
                  <InputComponent
                    placeholder={
                      modalText[PaymentModalFields.comment].placeholder
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <div className="FlexRow FlexWrap Gap8">
                  {reqFiles.map((file: FilesList) => (
                    <FileComponent
                      key={`file_${file.name}`}
                      name={file.name}
                      size={file.size}
                      deleteFile={() => {
                        changeFileList(
                          prevList =>
                            prevList.filter(
                              item => item.uid !== file.uid
                            ) as UploadFile[]
                        )
                        setReqFiles(
                          prevList =>
                            prevList.filter(
                              item => item.uid !== file.uid
                            ) as FilesList[]
                        )
                      }}
                    />
                  ))}
                </div>
              </div>
              <div
                className={`PaymentModalBottom ${
                  isMobile ? `FlexColumn` : `FlexRow`
                } Gap16 PaddingTop24 MarginTop16`}
              >
                {!isMobile && applyButton}
                {!hideUploadMobile && (
                  <div className="FlexRow">
                    <div
                      className={
                        fileList.length
                          ? `UploadComponentFormWrapper`
                          : `UploadComponentFormError`
                      }
                    >
                      <Form.Item
                        name={PaymentModalFields.files}
                        rules={[
                          {
                            validator: () => fileRule(!!fileList.length)
                          }
                        ]}
                      >
                        <UploadComponent
                          fileList={fileList}
                          pending={pending}
                          setPending={setPending}
                          changeFileList={changeFileList}
                          allowedTypes={allowedTypes}
                          disabled={disableUpload}
                          addFile={data => setReqFiles([...reqFiles, data])}
                          maxSize={5}
                          maxCount={2}
                        />
                      </Form.Item>
                    </div>
                    <div className="FlexColumn Gap2 Padding8">
                      <span className="CaptionText Gray600Color">
                        {modalText.upload.descriptionOne}
                      </span>
                      <span className="CaptionText Gray600Color">
                        {modalText.upload.descriptionTwo}
                      </span>
                    </div>
                  </div>
                )}
                {isMobile && applyButton}
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <div className="FlexColumn Gap24">
          <div className="GridCenter">
            <span className="HeadlineFirstText">
              {modalText.success.add_title}
            </span>
          </div>
          <div className="GridCenter">
            <SuccessIcon />
          </div>
          <div className="GridCenter PaddingBottom40">
            <ButtonPrimary onClick={close}>
              {modalText.success.button}
            </ButtonPrimary>
          </div>
        </div>
      )}
    </ModalFormComponent>
  )
}

export default PaymentModal
