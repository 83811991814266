import { appealModalText, FormItemNames } from "./helper"
import { ReactComponent as SuccessIcon } from "Components/icons/success_large.svg"
import { formatDate } from "../ViewPenaltyModal/helper"
import { Form, Upload } from "antd"
import { allowedTypes, formRules } from "../../Admin/AddEditPenaltyModal/helper"
import InputComponent, { InputTextAreaComponent } from "Components/Input"
import { UploadAttachment, UploadAttachments } from "../types"
import FileComponent from "Components/File"
import ButtonDefault, { ButtonNotifi, ButtonPrimary } from "Components/Button"
import { ReactComponent as AttachIcon } from "Components/icons/attach.svg"
import { CheckBoxComponent } from "Components/CheckBox"
import React from "react"
import { AppealModalContentProps, AppealModalData } from "./types"
import { Link } from "react-router-dom"
import { addEditModalText } from "../../constants"

export const AppealModalContent: React.FC<AppealModalContentProps> = ({
  successModal,
  viewModalData,
  finalPenaltyValue,
  finalWinValue,
  form,
  onFinish,
  onFieldsChange,
  modalData,
  setModalData,
  loading,
  closeAppealModal,
  isMobile,
  rules
}) => {
  return successModal ? (
    <div className="SupportProposalSuccess">
      <div className="SupportProposalSuccessText">
        {appealModalText.success}
      </div>
      <SuccessIcon className="SupportProposalSuccessTextIcon" />
    </div>
  ) : (
    <>
      <div className="ProposalModalTitleWrapper">
        <div className="ProposalModalTextTitleText">
          {appealModalText.title}
        </div>
      </div>
      <div className="AppealModalPenaltyTextElementFirst SubtitleFirstText">{`${
        appealModalText.penaltyFrom
      } ${formatDate(
        viewModalData?.setDate
      )} ${finalPenaltyValue} ${finalWinValue}`}</div>
      <div className="AppealModalPenaltyTextElement">
        {viewModalData?.reason}
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        autoComplete="off"
      >
        <div className="AppealModalCFormContentWrapper">
          <Form.Item
            name={FormItemNames.name}
            label={
              <div className="ProposalModalSubTitleWrapper AppealModalSubtitleWrapper ">
                {appealModalText.responsible}
              </div>
            }
            rules={formRules.inputText}
          >
            <InputComponent
              className="AddEditPenaltyModalAmountInput"
              placeholder={appealModalText.responsiblePlaceholder}
              greyBackground
            />
          </Form.Item>
          <Form.Item
            name={FormItemNames.comment}
            label={
              <div className="ProposalModalSubTitleWrapper AppealModalSubtitleWrapper">
                {appealModalText.comment}
              </div>
            }
            rules={formRules.textarea}
          >
            <InputTextAreaComponent
              className="ProposalModalTextTextArea"
              placeholder={appealModalText.commentPlaceholder}
              autoSize={{ minRows: 6, maxRows: 6 }}
              greyBackground
            />
          </Form.Item>
          <div>
            <div className="ProposalModalSubTitleWrapper AppealModalSubtitleWrapper">
              {appealModalText.attachments}
            </div>
            <div className="ProposalModalFilesWrapper UserPenaltyModal">
              {(modalData?.attachments).map((file: UploadAttachment) => (
                <FileComponent
                  key={file.size + file.name}
                  name={file.name}
                  size={Number(file.size)}
                  link={file.link}
                  deleteFile={() => {
                    setModalData((prev: AppealModalData) => {
                      const next = prev.attachments.filter(
                        att => att.name !== file.name
                      ) as UploadAttachments
                      return { ...prev, attachments: next }
                    })
                  }}
                />
              ))}
              <Form.Item name={FormItemNames.attachments}>
                <Upload
                  customRequest={() => null}
                  itemRender={() => null}
                  accept={allowedTypes}
                  multiple
                >
                  <ButtonNotifi className="AddEditModalPenaltyAttachmentsButton">
                    <AttachIcon className="AddEditModalPenaltyAttachmentsIcon" />
                  </ButtonNotifi>
                </Upload>
              </Form.Item>
              {!modalData.attachments.length && (
                <div className="AddEditModalPenaltyAttachmentsTooltip">
                  {addEditModalText.attachmentsTooltip}
                </div>
              )}
            </div>
          </div>

          <Form.Item
            name={FormItemNames.rulesCheck}
            label={
              <div className="ProposalModalSubTitleWrapper AppealModalSubtitleWrapper AppealModalSubtitleWrapper">
                {appealModalText.checkRulesTitle}
              </div>
            }
            rules={formRules.check}
            valuePropName="checked"
          >
            <CheckBoxComponent
              className="AppealModalPenaltyCheckboxWrapper"
              label={
                <>
                  <div className="SubtitleSecondText">
                    {appealModalText.checkRulesText}
                  </div>
                  <div className="SubtitleSecondText AppealModalRulesLink">
                    <Link
                      to={`${rules.url}`}
                      target="_blank"
                      download={rules.name}
                    >
                      {appealModalText.checkRulesLink}
                    </Link>
                  </div>
                </>
              }
            />
          </Form.Item>
        </div>

        <div
          className={`ProposalModalButtonsGroup EditCancelRejectFinalModalButtonsGroup AppealModalButtonsGroup ${
            isMobile ? "AppealModalButtonsGroupMobile" : ""
          }`}
        >
          <Form.Item>
            <ButtonPrimary htmlType="submit" loading={loading}>
              {addEditModalText.sendButton}
            </ButtonPrimary>
          </Form.Item>
          <ButtonDefault onClick={() => closeAppealModal()}>
            {isMobile ? addEditModalText.cancelButton : addEditModalText.back}
          </ButtonDefault>
        </div>
      </Form>
    </>
  )
}
