import { MenuOptionsType } from "store/menu/types"

export const baseLayoutColors = {
  fff: "#fff",
  f4: "#F4F4F4"
}

export const buildUrlForExternalMenuItem = (
  item: MenuOptionsType | null,
  token?: string
) => {
  if (item && item?.url) {
    const url = new URL(item?.url || "")
    if (token && item?.isAuthTokenRequired) {
      url.searchParams.append("token", token)
    }
    return url.toString()
  }
  return ""
}
