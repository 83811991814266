import React, { useEffect, useMemo, useState } from "react"
import TableComponent, { sortForTable } from "Components/Table"
import { ReportLegoModalProps } from "../ReportLego/types"
import {
  getTransactionDetailsUrl,
  transactionModalColumn,
  transactionsModalTitleIconMap,
  transactionsText
} from "./helper"
import { ReactComponent as CloseModalIcon } from "Components/icons/close_reports_modal.svg"
import moment from "moment"
import { UseRequestBodySort } from "api/hooks"
import { Sorter } from "Components/Table/types"
import betRequest from "api/request"
import { useHistory } from "react-router-dom"
import { IDevelopState } from "store/developer/types"
import { useDispatch, useSelector } from "react-redux"
import { getDeveloperList } from "store/developer/selector"
import { logoutUser } from "store/auth/actions"

export const TransactionModal: React.FC<ReportLegoModalProps> = ({
  modalData,
  toggleModal,
  statuses
}) => {
  const [sort, setSort] = useState<UseRequestBodySort>({
    field: undefined,
    order: undefined
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [ownModalData, setOwnModalData] = useState([])

  const history = useHistory()
  const developer: IDevelopState = useSelector(getDeveloperList)
  const dispatch = useDispatch()
  const logout: () => void = () => dispatch(logoutUser())

  // eslint-disable-next-line
  //@ts-ignore
  const headerIcon = transactionsModalTitleIconMap[modalData.status]

  const columns = useMemo(() => {
    return transactionModalColumn(!!sort?.order ? sort?.field : undefined)
  }, [sort, statuses])

  useEffect(() => {
    setLoading(true)
    betRequest({
      method: `POST`,
      url: getTransactionDetailsUrl(modalData.id),
      requestBody: sort.order ? { sort } : undefined,
      history,
      logout,
      developer
    })
      .then(({ data }) => {
        setOwnModalData(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [sort])

  return (
    <>
      <div className="ReportModalWrapper">
        <div className="NoncashPageHeader">
          <div className="NoncashPageHeaderDescriptionWrapper">
            <div className="NoncashPageTitleDescription">
              <div className="NoncashPageTitle FlexRow">
                {transactionsText.modalId} {modalData.id}
                <div className="InfoModalIcon">{headerIcon}</div>
                <CloseModalIcon
                  className="CloseModalIcon"
                  onClick={() => toggleModal(false)}
                />
              </div>
            </div>
            <div className="FlexRow NoncashContentPeriodWrapper">
              <div className="CashboxContentPeriod">
                {`${transactionsText.club} ${modalData.hall}`} ·{" "}
                {moment(modalData.date).format(`DD.MM.YYYY`)} ·{" "}
                {statuses[modalData.status].name}
              </div>
            </div>
          </div>
        </div>
        <TableComponent
          columns={columns}
          data={[...ownModalData]}
          loading={loading}
          scroll={{ x: 800, y: "calc(100vh - 15rem)" }}
          onChange={(pagination: any, filters: any, sorter: Sorter) => {
            sortForTable({ sorter, setSort })
          }}
        />
      </div>
      <div className="Overlay" onClick={() => toggleModal(false)} />
    </>
  )
}
