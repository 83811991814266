import React, { useEffect, useState } from "react"

import { useRequest } from "api/hooks"

import { SortProps } from "Components/TableNew/types"
import NewTableComponent from "Components/TableNew"
import EmptyTable from "Components/EmptyTable"

import RegistrationCheckMobileTable from "./MobileTable"
import { emptyTableConfig, getColumns } from "./constants"
import { ContentData } from "./types"

import { isMobile } from "../../constants"

const RegistrationCheckTable = ({ phone }: { phone?: string }) => {
  const [sort, setSort] = useState<SortProps | undefined>(undefined)
  const columns = getColumns({ sort, setSort })

  const [tableData, setTableData] = useState<ContentData>({
    pending: false,
    data: []
  })
  const { request: getData } = useRequest({
    url: `v1/guests/registration-check`,
    method: `POST`,
    requestBody: {
      filter: { phone },
      sort
    }
  })

  useEffect(() => {
    if (phone) {
      setTableData({ ...tableData, pending: true })
      getData()
        .then(response => {
          const { data } = response
          setTableData({
            pending: false,
            data
          })
        })
        .catch(() => setTableData({ ...tableData, pending: false }))
    }
  }, [phone, sort])

  const tableComponent = isMobile ? (
    <RegistrationCheckMobileTable data={tableData.data} />
  ) : (
    <NewTableComponent
      data={tableData.data}
      loading={tableData.pending}
      columns={columns}
    />
  )

  return (
    <>
      {!tableData.pending && !!tableData.data?.length ? (
        tableComponent
      ) : (
        <EmptyTable
          desktop={emptyTableConfig.desktop}
          mobile={emptyTableConfig.mobile}
        />
      )}
    </>
  )
}

export default RegistrationCheckTable
