import React, { useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"

import { MAIN_PAGE } from "Containers/BaseLayout/constants"

import { ButtonPrimary } from "Components/Button"
import { ReactComponent as NotFoundIcon } from "./not_found.svg"
import { ReactComponent as SomethingWrongIcon } from "./something_wrong.svg"
import { ReactComponent as TechnicalWorkIcon } from "./technical_works.svg"
import { ReactComponent as AccessDeniedIcon } from "./access_denied.svg"
import { ErrorPageProps } from "./types"

const ErrorPage: React.FC<ErrorPageProps> = ({ baseUrl }) => {
  const history = useHistory()
  const backToMainPage = useCallback(() => {
    history.push(baseUrl ? baseUrl : MAIN_PAGE)
  }, [baseUrl])
  let errorConfig: {
    title: string
    description: string
    icon: JSX.Element
    back_to_main: boolean
  }
  const errorCode: string = localStorage.getItem(`error`) || ``
  useEffect(() => {
    if (!errorCode) {
      backToMainPage()
    }
  })
  switch (errorCode) {
    case `404`:
      errorConfig = {
        title: `Страница не найдена`,
        description: `Не переживайте — попробуйте начать сначала`,
        icon: <NotFoundIcon />,
        back_to_main: true
      }
      break

    case `500` || `502`:
      errorConfig = {
        title: `Технические работы`,
        description: `Скоро всё заработает, обязательно возвращайтесь`,
        icon: <TechnicalWorkIcon />,
        back_to_main: true
      }
      break

    case `401`:
      errorConfig = {
        title: `Нет доступа`,
        description: `Доступ запрещён — попробуйте начать сначала `,
        icon: <AccessDeniedIcon />,
        back_to_main: true
      }
      break
    default:
      errorConfig = {
        title: `Что-то пошло не так...`,
        description: `Пожалуйста перезагрузите страницу`,
        icon: <SomethingWrongIcon />,
        back_to_main: false
      }
      break
  }
  return (
    <div className="ErrorPageWrapper GridCenter">
      <div className="ErrorPageTitle">{errorConfig.title}</div>
      <div className="ErrorPageDescription">{errorConfig.description}</div>
      <div className="ErrorPageIcon">{errorConfig.icon}</div>
      {errorConfig.back_to_main && (
        <div className="ErrorPageButton">
          <ButtonPrimary onClick={backToMainPage}>
            Вернуться на главную
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

export default ErrorPage
