import { ChartPeriod } from "./types"
import moment from "moment"

export const dateLabelByPeriod = (period: ChartPeriod, currentDate: string) => {
  let dateX = ``
  const currentMoment = moment(currentDate, `DD.MM.YYYY`)
  if (period === ChartPeriod.daily) {
    dateX = currentDate?.slice(0, 5)
  }
  if (period === ChartPeriod.weekly) {
    const start = currentMoment.format(`DD.MM`)
    const end = moment(currentDate, `DD.MM.YYYY`)
      .add(1, `week`)
      .format(`DD.MM`)
    dateX = `${start} - ${end}`
  }
  if (period === ChartPeriod.monthly) {
    const date = currentMoment.format(`MMM YYYY`)
    dateX = date
  }

  return dateX
}

export const getChartLabelByPeriod = (period: ChartPeriod) => {
  const highchartsOptionsLabels: any = {
    align: `center`,
    formatter: function() {
      const value = dateLabelByPeriod(period, this.value)
      const isWeekly = period === ChartPeriod.weekly

      const weeklyFirst = `${value}`?.slice(0, 5)
      const weeklySecond = `${value}`?.slice(8, 13)
      return !isWeekly
        ? `<div class="CaptionText" style="color: #868E96">` + value + `</div>`
        : `<div class="CaptionText" style="color: #868E96">` +
            weeklyFirst +
            `-` +
            `<br />` +
            weeklySecond +
            `</div>`
    }
  }
  return highchartsOptionsLabels
}

export const yAxisLabels = () => {
  const yAxis: any = {
    formatter: function() {
      const label = this.axis.defaultLabelFormatter.call(this)
      const fullLabel = Number(label.replace(`k`, `000`).replace(`M`, `000000`))
      if (!!fullLabel) {
        if (fullLabel < 1000) {
          return `${fullLabel}`
        } else if (fullLabel < 1000000) {
          return `${fullLabel / 1000}к`
        } else {
          return `${fullLabel / 1000000}кк`
        }
      }
      return label
    }
  }
  return yAxis
}

export const prepareValueForChart = (str: string): number => {
  if (!str) return 0
  else {
    const withoutSpaces = str.replaceAll(` `, ``) || ``
    return Number(withoutSpaces.replace(`,`, `.`))
  }
}
