import { uniqBy } from "lodash"
import { Reducer, AnyAction } from "redux"

import { IHallsState } from "./types"

import { STORE_HALLS, SET_CURRENT_HALLS } from "./constants"

const init: IHallsState = {
  halls: [],
  current_hall: [],
  addresses: []
}

export const hallsReducer: Reducer<IHallsState, AnyAction> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state,
  action
): IHallsState => {
  // get hall's list from storage
  const hallsFromStorage: string = localStorage.getItem(`halls`) || ``
  const halls: string[] = hallsFromStorage ? JSON.parse(hallsFromStorage) : []
  // get picked hall's from storage
  const currentHallFromStorage: string = localStorage.getItem(`hall`) || ``
  const current_hall: string[] = currentHallFromStorage
    ? JSON.parse(currentHallFromStorage)
    : []
  // get hall's addresses from storage
  const hallAddressesStorage: string =
    localStorage.getItem(`hallsAddress`) || ``
  const hallAddresses: {
    [key: string]: { gmt: string; region: string }
  } = currentHallFromStorage ? JSON.parse(hallAddressesStorage) : []

  const addresses = uniqBy(
    current_hall.map((item: string) => ({
      gmt: hallAddresses[item].gmt || `3`,
      region: hallAddresses[item].region || ``
    })),
    `gmt`
  )

  const { type, payload } = action

  const stateFromStorage: IHallsState =
    halls && current_hall
      ? { addresses: addresses, halls: halls, current_hall: current_hall }
      : init

  switch (type) {
    case STORE_HALLS: {
      return { ...stateFromStorage, ...payload }
    }

    case SET_CURRENT_HALLS: {
      const storeCurrentHalls = JSON.stringify(payload)
      localStorage.setItem(`hall`, storeCurrentHalls)
      const setAddresses = uniqBy(
        payload.map((item: string) => ({
          gmt: hallAddresses[item].gmt || `3`,
          region: hallAddresses[item].region || ``
        })),
        `gmt`
      )
      return {
        ...stateFromStorage,
        current_hall: payload,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        addresses: setAddresses
      }
    }

    default:
      return stateFromStorage
  }
}
