import { Button } from "antd"
import { ButtonProps } from "antd/es/button"
import React, { FC } from "react"
import { ReactComponent as Dropdown } from "Components/icons/dropdown_12.svg"

export interface DropdownButtonProps extends ButtonProps {
  dropdown?: boolean
}

export const DropdownButton: FC<DropdownButtonProps> = ({
  dropdown,
  children,
  ...props
}) => {
  const dinamycClassName = dropdown ? "dropdown" : ""

  return (
    <Button {...props} type="link">
      {children}
      <Dropdown
        className={`MarginLeft2 DropdownButtonIcon ${dinamycClassName}`}
      />
    </Button>
  )
}
