import React, { useEffect, useState } from "react"

import { getRequestTotal } from "api/request"
import { useRequest, useRequestBody } from "api/hooks"
import { parseDateToBack } from "constants/index"

import textConfig from "./helper"
import { LoyaltyGuestStateType, LoyaltyGuestFilterType } from "./types"

import { ReactComponent as FilterIcon } from "Components/icons/filter_arrow.svg"
import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"
import { ReactComponent as EmptyIcon } from "./Empty_table.svg"
import { ReactComponent as HelpIcon } from "Components/icons/help_24.svg"

import SpinComponent from "Components/Spin"
import TooltipComponent from "Components/Tooltip"
import NotActiveFilter from "Components/NotActiveFilter"
import EmptyTable from "Components/EmptyTable"

import Filter from "./Filter"
import GuestDashboard from "./Dashboard"
import Report from "./Report"

const isMobile = window.innerWidth < 921

const LoyaltyGuest = () => {
  const hall = Number(JSON.parse(localStorage.getItem(`hall`) || ``)[0])
  const [filterState, setFilterState] = useState<LoyaltyGuestFilterType>({
    data: {
      hall: hall,
      phone: undefined,
      isMonthly: false,
      dates: undefined
    },
    isOpen: true,
    isActive: false
  })
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false)

  const [guestState, setGuestState] = useState<LoyaltyGuestStateType>({
    pending: false,
    report: [],
    total: 0,
    dashboard: {
      bonus: 0,
      status: ``,
      cashback: 0,
      accumulatedBonus: 0
    }
  })

  const { pagination, setPagination } = useRequestBody()

  const { request } = useRequest({
    url: `reports/guestinfo`,
    method: `POST`,
    requestBody: {
      pagination,
      ...filterState.data,
      hall: Number(filterState.data.hall || 0),
      phone: `7${filterState.data.phone}`,
      dates:
        filterState.data.dates && !filterState.data.isMonthly
          ? parseDateToBack({ date: filterState.data.dates, gmt: `3` })
          : undefined
    }
  })

  const requestDeps = JSON.stringify({
    ...filterState.data,
    ...pagination,
    isActive: filterState.isActive
  })

  useEffect(() => {
    setGuestState({ ...guestState, pending: true })
    if (filterState.isActive) {
      request()
        .then(({ data, headers }) => {
          setGuestState({
            ...guestState,
            pending: false,
            total: getRequestTotal(headers),
            dashboard: data.dashboard,
            report: data.report
          })
        })
        .catch(() => setGuestState({ ...guestState, pending: false }))
    }
  }, [requestDeps])

  const isTableReady =
    !!guestState.report && !!guestState.report.length && !guestState.pending
  const isEmptyTable =
    !!guestState.report && !guestState.report.length && !guestState.pending

  return (
    <div className="FlexRow">
      <div>
        <div
          className={`MainPageContentWrapper ${
            filterState.isOpen
              ? `MainPageContentWrapperFilter`
              : `MainPageContentWrapperUnFilter`
          }`}
        >
          <div
            className="MobileFilterIcon"
            onClick={() => setMobileFilterOpen(true)}
          >
            <FilterMobileIcon />
          </div>
          <div className="MainPageContentRestrictions">
            {filterState.isActive && (
              <div>
                <div className="MainPageTitle FlexRow">
                  <div>{textConfig.title}</div>
                  <TooltipComponent
                    title={textConfig.description}
                    direction={isMobile ? `bottom` : `rightTop`}
                  >
                    <div className="MainPageTitleHelp">
                      <HelpIcon />
                    </div>
                  </TooltipComponent>
                </div>
                <GuestDashboard
                  pending={guestState.pending}
                  data={guestState.dashboard}
                  filter={filterState.data}
                />
                <div className="ChangePhoneTableWrapper">
                  {isTableReady && (
                    <Report
                      isMonthly={filterState.data.isMonthly}
                      report={guestState.report}
                      total={guestState.total}
                      loading={guestState.pending}
                      pagination={pagination}
                      setPagination={setPagination}
                    />
                  )}
                  {guestState.pending && <SpinComponent />}
                  {isEmptyTable && <EmptyTable />}
                </div>
              </div>
            )}
            {!filterState.isActive && (
              <NotActiveFilter
                title="Чтобы получить информацию по гостю, вам необходимо выбрать нужные параметры фильтрации"
                description="Информация по гостю содержит данные о датах операций с бонусами, статусах гостя, процентах кэшбэка, суммам бонусов, а также статусах по проведённым начислениям"
                icon={<EmptyIcon />}
              />
            )}
            <div className="FlexRow">
              <div
                className={`MainPageFilterButtonWrapper ${
                  filterState.isOpen
                    ? `MainPageFilterButtonWrapperActive`
                    : `MainPageFilterButtonWrapperUnActive`
                }`}
                onClick={() =>
                  setFilterState({
                    ...filterState,
                    isOpen: !filterState.isOpen
                  })
                }
              >
                <FilterIcon />
              </div>
            </div>
          </div>
          {mobileFilterOpen && (
            <Filter
              filterState={filterState}
              setFilterState={setFilterState}
              setMobileFilterOpen={setMobileFilterOpen}
              mobileFilterOpen={mobileFilterOpen}
            />
          )}
        </div>
      </div>
      <Filter
        filterState={filterState}
        setFilterState={setFilterState}
        setMobileFilterOpen={setMobileFilterOpen}
        mobileFilterOpen={mobileFilterOpen}
      />
    </div>
  )
}

export default LoyaltyGuest
