import React, { useState, useMemo, useEffect } from "react"

import { useNow } from "api/hooks"
import moment from "moment"

import { isMobile } from "constants/index"

import { ReactComponent as CloseIcon } from "Components/icons/close-big.svg"
import { RadioGroupComponent } from "Components/Radio"
import { MobileBackButton } from "Components/Button"
import TooltipComponent from "Components/Tooltip"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"

import { ReactComponent as StatusActive } from "./icons/status_active.svg"
import { ReactComponent as StatusPause } from "./icons/status_pause.svg"

import MailingDetailTable from "./DetailTable"

import { emptyDate } from "../helper"

import { useDetailRequests } from "./helper"
import { MailingHallDetailProps, DetailDataState, DetailPeriod } from "./types"
import { initialDetailState, detailText, periodOptions } from "./constant"

const MailingHallDetail: React.FC<MailingHallDetailProps> = ({
  hall,
  close,
  refreshHalls
}) => {
  const { timestamp, refresh } = useNow()
  const initial = useMemo(() => initialDetailState(hall), [hall])

  const [detailData, setDetailData] = useState<DetailDataState>(initial)

  const { getDetail, switchPeriod } = useDetailRequests(detailData.data)

  useEffect(() => {
    setDetailData(prev => ({ ...prev, pending: true }))
    getDetail()
      .then(({ data }) =>
        setDetailData(prev => ({ ...prev, data, pending: false }))
      )
      .catch(() => setDetailData(prev => ({ ...prev, pending: false })))
  }, [timestamp])

  useEffect(() => {
    if (!!detailData.trigger.period) {
      setDetailData(prev => ({ ...prev, pending: true }))
      switchPeriod()
        .then(() => setDetailData(prev => ({ ...prev, pending: false })))
        .catch(() =>
          setDetailData(prev => {
            const value =
              prev.data.period === DetailPeriod.half
                ? DetailPeriod.full
                : DetailPeriod.half
            return {
              ...prev,
              data: {
                ...prev.data,
                period: value
              },
              trigger: { ...prev.trigger, period: undefined },
              pending: false
            }
          })
        )
    }
  }, [detailData.trigger.period])

  const title = useMemo(() => detailText.title(hall), [])

  return (
    <div>
      <div className="MailingHallDetailWrapper">
        <div className="MailingHallDetailHeaderCloseMobile">
          <MobileBackButton onClick={close} />
        </div>
        <div className="MailingHallDetailHeader FlexRow">
          <div>
            <div className="MailingHallDetailTitle FlexRow">
              <span>{title}</span>
              {detailData.data.active ? <StatusActive /> : <StatusPause />}
            </div>
            <div className="MailingHallDetailTitleDate">
              {detailData.data.date
                ? moment(detailData.data.date).format(detailText.dateFormat)
                : emptyDate}
            </div>
          </div>
          <div className="MailingHallDetailHeaderClose" onClick={close}>
            <CloseIcon />
          </div>
        </div>
        <div className="MailingHallDetailPeriod">
          <div className="MailingHallDetailPeriodTitle FlexRow">
            <span className="MailingHallDetailLabelSpan">
              {detailText.period.title}
            </span>
            <TooltipComponent
              direction={isMobile ? `rightTop` : `bottom`}
              title={detailText.period.tooltip}
            >
              <InfoIcon />
            </TooltipComponent>
          </div>
          <RadioGroupComponent
            className="MailingHallDetailPeriodRadio"
            value={detailData.data.period}
            onChange={({ target: { value } }) => {
              setDetailData(prev => ({
                ...prev,
                data: { ...prev.data, period: value },
                trigger: {
                  ...prev.trigger,
                  period: value
                }
              }))
            }}
            options={periodOptions}
          />
        </div>
        <MailingDetailTable
          refresh={refresh}
          refreshHalls={refreshHalls}
          close={close}
          data={detailData.data}
          users={detailData.data.users}
          pending={detailData.pending}
        />
      </div>
      <div className="Overlay" />
    </div>
  )
}

export default MailingHallDetail
