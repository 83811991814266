import React, { useMemo, useState, useEffect } from "react"
import { allowedContactsEditingRoles, getContacts } from "./helpers"
import { ContactsStateProps } from "./types"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logoutUser } from "store/auth/actions"
import EmptyContactsPage from "./EmptyContactsPage"
import ContactsHeader from "./ContactsHeader"
import ContactsPage from "./ContactsPage"
import ContactsSectionModalContent from "./EditModals/ContactsSectionModal"
import ContactsBlockModalContent from "./EditModals/ContactsBlockModal"
import ContactsStaffModalContent from "./EditModals/ContactsStaffModal"
import { Modal } from "antd"
import { Method } from "axios"
import { ReactComponent as DeleteIcon } from "Components/icons/delete_red.svg"

const { confirm } = Modal

export const isContactsMobile = window.innerWidth < 921

const ContactComponent = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const logout: () => void = () => dispatch(logoutUser())

  const allowEditContacts = useMemo(() => {
    const roleFromLs = localStorage.getItem("role")
    return allowedContactsEditingRoles.some(role => role === roleFromLs)
  }, [])

  const [contactsState, setContactsState] = useState<ContactsStateProps>({
    url: "",
    method: undefined,
    sectionPending: false,
    blockPending: false,
    staffPending: false,
    errors: [],
    sectionErrors: [],
    blockErrors: [],
    staffErrors: [],
    pending: false,
    isEditing: false,
    data: [],
    activeModal: false,
    isSectionEditing: false,
    isBlockEditing: false,
    isStaffEditing: false,
    editSectionId: null,
    editBlockId: null,
    editStaffId: null,
    reloadPage: false,
    movingId: 0
  })

  const allowToShowSectionModal =
    contactsState.isSectionEditing &&
    !contactsState.isBlockEditing &&
    !contactsState.isStaffEditing

  const allowToShowBlockModal =
    contactsState.isBlockEditing &&
    !contactsState.isSectionEditing &&
    !contactsState.isStaffEditing

  const allowToShowStaffModal =
    contactsState.isStaffEditing &&
    !contactsState.isSectionEditing &&
    !contactsState.isBlockEditing

  const allowToShowEmptyPage =
    !contactsState.data.length && !contactsState.pending

  useEffect(() => {
    getContacts({
      contactsState,
      setContactsState,
      history,
      logout
    })
  }, [])

  useEffect(() => {
    contactsState.reloadPage &&
      getContacts({
        contactsState,
        setContactsState,
        history,
        logout
      })
  }, [contactsState.reloadPage])

  const showConfirm = ({
    msg,
    newState
  }: {
    msg: string
    newState: {
      blockPending?: boolean
      sectionPending?: boolean
      staffPending?: boolean
      url: string
      method: Method
    }
  }) => {
    confirm({
      wrapClassName: "ContactsConfirmWrapper",
      title: <div className="ContactsConfirmDelete_title">Внимание!</div>,
      content: <div className="ContactsConfirmDelete_title">{msg}</div>,
      okText: (
        <>
          <DeleteIcon />
          Удалить
        </>
      ),
      onOk() {
        setContactsState({
          ...contactsState,
          ...newState
        })
      }
    })
  }

  return (
    <div className="ContactsMainPageWrapper">
      <ContactsHeader
        allowEditContacts={allowEditContacts}
        contactsState={contactsState}
        setContactsState={setContactsState}
      />
      {allowToShowEmptyPage ? (
        <EmptyContactsPage
          allowEditContacts={allowEditContacts}
          contactsState={contactsState}
          setContactsState={setContactsState}
        />
      ) : (
        <ContactsPage
          contactsState={contactsState}
          setContactsState={setContactsState}
        />
      )}
      {contactsState.activeModal && <div className="ContactsOverlay" />}
      <div
        /* Для плавного движения единого блока редактирования необходимо,
         * чтобы его содержимое удалялось реактом после окончания движения (transform)
         */
        onTransitionEnd={e => {
          if (
            e.propertyName !== "transform" ||
            e.target !== e.currentTarget ||
            contactsState.activeModal
          )
            return

          /* Для редактирования блока */
          if (contactsState.isBlockEditing) {
            setContactsState({
              ...contactsState,
              isBlockEditing: false,
              editSectionId: null
            })
          } else if (contactsState.isSectionEditing) {
            /* Для редактирования раздела */
            setContactsState({
              ...contactsState,
              isSectionEditing: false
            })
          } else if (contactsState.isStaffEditing) {
            /* Для редактирования блока */
            setContactsState({
              ...contactsState,
              isStaffEditing: false
            })
          }
        }}
        className={`MainModalWrapper 
        ${
          contactsState.isBlockEditing
            ? `MainModalWrapperWide`
            : `MainModalWrapperNarrow`
        }
        ${
          contactsState.activeModal
            ? `MainModalWrapperActive`
            : `MainModalWrapperUnActive`
        }`}
      >
        {allowToShowSectionModal && (
          <ContactsSectionModalContent
            showConfirm={showConfirm}
            contactsState={contactsState}
            setContactsState={setContactsState}
          />
        )}
        {allowToShowBlockModal && (
          <ContactsBlockModalContent
            showConfirm={showConfirm}
            contactsState={contactsState}
            setContactsState={setContactsState}
          />
        )}
        {allowToShowStaffModal && (
          <ContactsStaffModalContent
            showConfirm={showConfirm}
            contactsState={contactsState}
            setContactsState={setContactsState}
          />
        )}
      </div>
    </div>
  )
}

export default ContactComponent
