import React from "react"
import Title from "Components/Title"
import { ActiveTabs, SmsTabsProps } from "../types"
import { smsConstants } from "../constants"
import ExcelButton from "Components/ExcelButton"

const SmsTabs = ({ activeTab, setActiveTab, requestBody }: SmsTabsProps) => {
  const isCreating = activeTab === ActiveTabs.creating
  const isHistory = activeTab === ActiveTabs.history

  const excelRequestBody = {
    ...requestBody,
    pagination: {
      limit: 0,
      offset: 0
    }
  }

  return (
    <div className="SmsContentPageTop">
      <div className="SmsContentTitleWrapper">
        <Title
          titleText={smsConstants.smsTabsTitleText.title}
          tooltipText={smsConstants.smsTabsTitleText.tooltip}
          rightAdditionalClassName="MailingContentTitleRight"
          direction="bottom"
        />
        {isHistory && (
          <ExcelButton
            url={smsConstants.urls.excel}
            requestBody={excelRequestBody}
          />
        )}
      </div>
      <div className="SmsContentTabs FlexRow">
        <div className="FlexColumn">
          <div
            className={`SmsContentTabsItem ${
              isCreating
                ? `SmsContentTabsItemActive`
                : `SmsContentTabsInactiveItem`
            }`}
            onClick={() => setActiveTab(ActiveTabs.creating)}
          >
            {smsConstants.smsTabsTitleText.tabs.creating}
          </div>
          {isCreating && <div className="SmsContentTabsActiveRestriction" />}
        </div>
        <div className="FlexColumn">
          <div
            className={`SmsContentTabsItem ${
              isHistory
                ? `SmsContentTabsItemActive`
                : `SmsContentTabsInactiveItem`
            }`}
            onClick={() => setActiveTab(ActiveTabs.history)}
          >
            {smsConstants.smsTabsTitleText.tabs.history}
          </div>
          {isHistory && <div className="SmsContentTabsActiveRestriction" />}
        </div>
      </div>
      <div className="MailingContentTabsRestriction" />
    </div>
  )
}

export default SmsTabs
