import React, { useMemo } from "react"

import { useSelector } from "react-redux"
import { getGamesList } from "store/dictionary/selector"

import { superwinMainText } from "../constants"

const SuperwinsGameList = () => {
  const games = useSelector(getGamesList)
  const filteredList = useMemo(
    () => games?.filter(item => !!item.is_super_win),
    []
  )
  return (
    <div className="SuperwinsGameListWrapper">
      <div className="SuperwinsGameListLabel TextDefault PaddingBottom16">
        {superwinMainText.gameListLabel}
      </div>
      <div className="FlexRow Gap8 FlexWrap">
        {filteredList.map(item => {
          return (
            <div
              key={`superwin_game_${item.id}`}
              className="SuperwinsGameListItem FlexRow Gap8"
            >
              <img src={item.image} />
              <div className="BodySecondText">{item.name}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SuperwinsGameList
