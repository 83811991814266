import React from "react"
import * as Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import moment from "moment"

import { ChartsDataType } from "../types"

interface ICashboxCharts {
  data: { chart: ChartsDataType[]; color: string }
  period: string
  total: number
}

const CashboxCharts = ({ data, period, total }: ICashboxCharts) => {
  const currentTypeGradient = (opacity: string) =>
    data.color ? `${data.color.slice(0, data.color.length - 2)}${opacity})` : ``
  const heightGradient = currentTypeGradient(`0.2`)
  const lowGradient = currentTypeGradient(`0`)

  let xLabel: string
  switch (period) {
    case `hourly`:
      xLabel = `HH:mm`
      break
    case `daily`:
      xLabel = `DD.MM`
      break
    case `monthly`:
      xLabel = `MMMM YYYY`
      break
    case `yearly`:
      xLabel = `YYYY`
      break
    default:
      xLabel = `DD.MM`
  }

  const xAxisCategory = data.chart.map(item => moment(item.x).format(xLabel))
  const seriesData = data.chart.map(item => ({
    y: item.y,
    value: item.value,
    name: item.name
  }))

  const options: Highcharts.Options = {
    title: {
      text: ""
    },
    xAxis: {
      categories: xAxisCategory,
      crosshair: {
        className: `ChartsCrosshair`,
        width: 1,
        dashStyle: `Dash`,
        color: data.color
      },
      tickWidth: 0,
      labels: {
        align: `center`,
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this)
          return (
            '<span id="hoveredLabel" style="font-style: normal;' +
            "  font-family: Lato, sans-serif" +
            "  font-weight: normal;" +
            "  font-size: 11px;" +
            "  line-height: 13px;" +
            "  letter-spacing: -0.154px;" +
            "  color: #AEAEB2 !important;" +
            "  background-color: red !important;" +
            '">' +
            label +
            "</span>"
          )
        }
      }
    },
    yAxis: {
      crosshair: {
        width: 1,
        color: `#fff`
      },
      title: {
        text: ""
      },
      labels: {
        align: `center`,
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this)
          return (
            '<span id="hoveredLabel" style="font-style: normal;' +
            "  font-family: Lato, sans-serif" +
            "  font-weight: normal;" +
            "  font-size: 11px;" +
            "  line-height: 13px;" +
            "  letter-spacing: -0.154px;" +
            "  color: #AEAEB2 !important;" +
            "  background-color: red !important;" +
            '">' +
            label +
            "</span>"
          )
        }
      },
      gridLineDashStyle: `Dash`,
      gridLineColor: `#F2F2F7`,
      gridLineWidth: 1,
      gridZIndex: 0,
      plotLines: [
        {
          dashStyle: `Solid`,
          color: "#AEAEB2",
          value: 0,
          width: 1
        }
      ]
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, heightGradient || ``],
            [1, lowGradient || ``]
          ]
        },
        marker: {
          radius: 1,
          fillColor: data.color || ``,
          states: {
            hover: {
              radius: 8
            }
          }
        },
        lineWidth: 2,
        lineColor: data.color || ``,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        threshold: null
      }
    },
    chart: {
      scrollablePlotArea: { minWidth: total * 40 }
    },
    tooltip: {
      borderRadius: 20,
      borderColor: `#434345`,
      style: {
        padding: `8px 12px`
      },
      dateTimeLabelFormats: {
        day: "%e.%m",
        week: "%e.%m",
        month: "%e.%m",
        year: "%e.%m"
      },
      pointFormat:
        '<b style="font-family: Gilroy, sans-serif;' +
        "  font-style: normal;" +
        "  font-weight: 600;" +
        "  font-size: 16px;" +
        "  line-height: 18px;" +
        "  letter-spacing: -0.154px;" +
        "  color: #FFDD2D !important;" +
        '">{point.value} ₽</b><br>' +
        '<b style="font-style: normal;' +
        "  font-weight: normal;" +
        "  font-size: 11px;" +
        "  line-height: 18px;" +
        "  letter-spacing: -0.154px;" +
        "  color: #D1D1D6 !important;" +
        '">{point.name}</b><br>',
      className: `CahsboxChartsTooltipWrapper`,
      backgroundColor: `#434345`
    },
    series: [
      {
        type: "area",
        data: seriesData
      }
    ]
  }
  return (
    <div className="CashboxChartsWrapper">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default CashboxCharts
