import React, { useEffect, useState, useMemo } from "react"

import { useSelector } from "react-redux"

import { isEmpty } from "lodash"

import { isMobile, shiftMoscowGmtFirst } from "constants/index"

import { getFilterStateFromLocalStorage } from "api/helpers"
import { useRequestBody, useRequestParams } from "api/hooks"

import { getAddressList } from "store/halls/selector"

import { ReactComponent as FilterIcon } from "Components/icons/filter_arrow.svg"
import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"
import NotActiveFilter from "Components/NotActiveFilter"
import BaseFooter from "Components/Footer"

import CashboxFilter from "./CashboxFilter"
import CashboxContent from "./CashboxContent"
import { CASHBOX_FILTER_NAME } from "./CashboxFilter/helper"

import {
  CashboxFilterProps,
  CashboxState,
  initialCashboxData,
  initialFilterData
} from "./types"
import { getCashboxData } from "./helper"
import cashboxConstants from "./constants"

import { ReactComponent as EmptyIcon } from "./cashbox_filter_appertain.svg"

const CashboxComponent = () => {
  const {
    history,
    logout,
    developer,
    addDeveloperLink,
    current_hall
  } = useRequestParams()
  const locations: {
    gmt: string
    region: string
  }[] = useSelector(getAddressList)

  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    CASHBOX_FILTER_NAME
  )

  const initState = {
    isOpen: true,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      ...initialFilterData,
      halls: current_hall,
      gmt: locations[0].gmt,
      shift: shiftMoscowGmtFirst({
        gmt: locations[0].gmt,
        isShift: initialFilterData.isShift
      }),
      ...initFilterStateFromStorage
    }
  }

  const [filterState, setFilterState] = useState<CashboxFilterProps>(initState)

  const [cashboxState, setCashboxState] = useState<CashboxState>(
    initialCashboxData
  )
  const isEmptyData =
    cashboxState.data.report && !cashboxState.data.report.length
  const depsHalls = useMemo(() => current_hall.join(`,`), [current_hall])

  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(isMobile)

  const { pagination, setPagination } = useRequestBody()

  const requestDeps = JSON.stringify({ ...filterState.data, ...pagination })

  useEffect(() => {
    if (filterState.isActive) {
      getCashboxData({
        cashboxState,
        setCashboxState,
        filterState,
        addDeveloperLink,
        developer,
        logout,
        history,
        pagination
      })
    }
  }, [depsHalls, requestDeps, filterState.isActive])

  return (
    <div className="FlexRow">
      {mobileFilterOpen && (
        <CashboxFilter
          filterState={filterState}
          setFilterState={setFilterState}
          current_hall={current_hall}
          mobileFilterOpen={mobileFilterOpen}
          setMobileFilterOpen={setMobileFilterOpen}
        />
      )}
      <div
        className="MobileFilterIcon"
        onClick={() => setMobileFilterOpen(true)}
      >
        <FilterMobileIcon />
      </div>
      <div className="FlexRow">
        <div
          className={`MainPageFilterButtonWrapper ${
            filterState.isOpen
              ? `MainPageFilterButtonWrapperActive`
              : `MainPageFilterButtonWrapperUnActive`
          }`}
          onClick={() =>
            setFilterState({ ...filterState, isOpen: !filterState.isOpen })
          }
        >
          <FilterIcon />
        </div>
      </div>
      <div>
        <div
          className={`MainPageContentWrapper ${
            filterState.isOpen
              ? `MainPageContentWrapperFilter`
              : `MainPageContentWrapperUnFilter`
          }`}
        >
          <div className="MainPageContentRestrictions">
            <div className="CashboxComponentContentWrapper">
              {filterState.isActive ? (
                <CashboxContent
                  current_hall={current_hall}
                  cashboxState={cashboxState}
                  setCashboxState={setCashboxState}
                  isEmptyData={isEmptyData}
                  qiwiData={cashboxState.data.qiwi_balances}
                  types={filterState.data.types}
                  dates={filterState.data.dates}
                  shift={filterState.data.shift}
                  isShift={filterState.data.isShift}
                  period={filterState.data.period}
                  showDescription={filterState.isActive}
                  pagination={pagination}
                  setPagination={setPagination}
                  total={cashboxState.total}
                />
              ) : (
                <NotActiveFilter
                  title={cashboxConstants.notActive.title}
                  description={cashboxConstants.notActive.description}
                  icon={<EmptyIcon />}
                />
              )}
            </div>
          </div>
        </div>
        <BaseFooter />
      </div>
      <div
        className={`MainFilterWrapper ${
          filterState.isOpen
            ? `MainFilterWrapperActive`
            : `MainFilterWrapperUnActive`
        }`}
      >
        <CashboxFilter
          filterState={filterState}
          setFilterState={setFilterState}
          current_hall={current_hall}
        />
      </div>
    </div>
  )
}

export default CashboxComponent
