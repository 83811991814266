import { TooltipPlacement } from "antd/lib/tooltip"
import { InsurancesColumns, InsurancesWinnersColumns } from "./types"

const insurancesConstants = {
  INSURANCES_FILTER: `INSURANCES_FILTER`,
  url: `v1/action/insurance/list`,
  tooltipDirection: `bottom` as TooltipPlacement,
  title: `Страховка`,
  mainTooltip: `Раздел содержит информацию по всем проводимым в клубе акциям “Страховка”. Можно увидеть информацию как по текущей подключенной акции, так и по уже прошедшим. В таблице содержится информация о периоде акции и статусе, список игр, участвующих в акции, и список победителей, а также информация о максимальной сумме возврата по акции. При наличии победителей, нажав на соответствующую кнопку в строке акции, откроется боковая шторка со списком победителей и призовыми начислениями.`,
  dateFormat: `DD-MM-YYYY`,
  scroll: {
    x: 800,
    y: "calc(100vh - 17rem)"
  },
  columnNames: {
    [InsurancesColumns.id]: `№ акции`,
    [InsurancesColumns.period]: `Период акции`,
    [InsurancesColumns.status]: `Статус`,
    [InsurancesColumns.maxPayout]: `Макс. сумма\nвозврата, Б`,
    [InsurancesColumns.games]: `Список игр`,
    [InsurancesColumns.winners]: `Победители`
  },
  winnersColumnNames: {
    [InsurancesWinnersColumns.walletId]: `№\nкошелька`,
    [InsurancesWinnersColumns.payoutSum]: `Сумма\nвыплат, Б`,
    [InsurancesWinnersColumns.phone]: `Номер\nтелефона`
  },
  winnersEmpty: `Нет победителей`,
  winnersWord: "чел.",
  winnerModalTitle: "Победители",
  showMore: "...еще",
  notShowMore: "свернуть",
  delimeter: `·`,
  scrollY: `calc(100vh - 210px)`
}

export default insurancesConstants
