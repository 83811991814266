import React from "react"

import { useRequestParams } from "api/hooks"
import betRequest from "api/request"

import { UseCardApiParams, UseCardApiParamsAdd } from "./types"
import { useDispatch } from "react-redux"
import {
  GuidebookStoreAddImg,
  GuidebookStoreConsolidateSection,
  GuidebookStoreRemoveImg,
  GuidebookStoreSwitchImg
} from "../store/types"

import {
  addImgStore,
  considerationSectionStore,
  removeImgStore,
  switchChanges
} from "../store"
import {
  GuidebookAttachments,
  GuidebookPageState,
  GuidebookStatuses
} from "../types"
import { statusConfig } from "../constants"

export const getCardStyleByStatus = (status: GuidebookStatuses) =>
  status && status != GuidebookStatuses.consideration
    ? {
        borderColor: statusConfig[status].section.color
      }
    : {}

export const cartApiMethods = () => {
  const {
    developer,
    addDeveloperLink,
    history,
    logout,
    current_hall
  } = useRequestParams()

  const removeImg = ({ section, attachment }: UseCardApiParams) => {
    return betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      url: `v1/hall/guidebook/${current_hall}/${section}/${attachment}`,
      method: `DELETE`
    })
  }

  const switchImg = ({ section, attachment, data }: UseCardApiParams) => {
    return betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      url: `v1/hall/guidebook/${current_hall}/${section}/${attachment}`,
      method: `POST`,
      requestBody: data
    })
  }

  const addImg = ({ section, data }: UseCardApiParamsAdd) => {
    return betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      requestBody: { ...data },
      url: `v1/hall/guidebook/${current_hall}/${section}`,
      method: `POST`
    })
  }

  const getSection = ({ section }: { section: number }) => {
    return betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      url: `v1/hall/guidebook/${current_hall}/${section}`,
      method: `GET`
    })
  }

  const considerationSection = ({ section }: { section: number }) => {
    return betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      url: `v1/hall/guidebook/${current_hall}/${section}/consideration`,
      method: `POST`
    })
  }

  const uploadVideo = ({
    section,
    body
  }: {
    section: number
    body: FormData
  }) => {
    return betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      requestBody: body,
      url: `v1/hall/guidebook/${current_hall}/${section}/video`,
      method: `POST`,
      contentTpe: "multipart/form-data"
    })
  }

  return {
    removeImg,
    addImg,
    switchImg,
    considerationSection,
    getSection,
    uploadVideo
  }
}

export const useStoreMethods = () => {
  const dispatch = useDispatch()

  const switchSectionChange: (data: GuidebookStoreSwitchImg) => void = (
    data: GuidebookStoreSwitchImg
  ) => dispatch(switchChanges(data))
  const storeImg: (data: GuidebookStoreAddImg) => void = (
    data: GuidebookStoreAddImg
  ) => dispatch(addImgStore(data))

  const considerationSectionAttach: (
    data: GuidebookStoreConsolidateSection
  ) => void = (data: GuidebookStoreConsolidateSection) =>
    dispatch(considerationSectionStore(data))

  const deleteImg: (data: GuidebookStoreRemoveImg) => void = (
    data: GuidebookStoreRemoveImg
  ) => dispatch(removeImgStore(data))

  const changeState: (data: GuidebookPageState) => void = (
    data: GuidebookPageState
  ) => dispatch(changeState(data))

  return {
    switchSectionChange,
    storeImg,
    considerationSectionAttach,
    deleteImg,
    changeState
  }
}

export const generateInitialList = ({
  isVideoSection,
  item,
  attach,
  index,
  rootTitle,
  date,
  preview,
  old
}: {
  isVideoSection: boolean
  item: GuidebookAttachments
  attach: React.ReactElement
  index: number
  rootTitle: string
  date: string
  preview: string
  old?: string
}) => ({
  uid: item.preview,
  id: item.id,
  name: ``,
  isVideoSection,
  type: isVideoSection ? `image/jpeg` : `image/png`,
  status: item.status,
  extra_status: item.status,
  attach_status: attach,
  url: item.link,
  thumbUrl: item.preview,
  index,
  rootTitle,
  uploadDate: date,
  preview,
  old
})
