import React from "react"

import { GuidebookPageState, GuidebookStatuses } from "./types"

import { ReactComponent as ConsiderationIcon } from "./icons/consideration.svg"
import { ReactComponent as RejectedIcon } from "./icons/rejected.svg"
import { ReactComponent as DoneIcon } from "./icons/done.svg"

export const statusConfig = {
  [GuidebookStatuses.consideration]: {
    title: `На согласовании`,
    color: `#FD7E14`,
    section: {
      color: `#FD7E14`,
      background: `#FFF4E6`
    },
    icon: <ConsiderationIcon />
  },
  [GuidebookStatuses.rejected]: {
    title: `Не согласовано`,
    color: `#FA5252`,
    section: {
      color: `#FA5252`,
      background: `#FFF5F5`
    },
    icon: <RejectedIcon />
  },
  [GuidebookStatuses.approved]: {
    title: `Согласовано`,
    color: `#40C057`,
    section: {
      color: `#40C057`,
      background: `#EBFBEE`
    },
    icon: <DoneIcon />
  }
}

export const textConstants = {
  url: `v1/hall/guidebook`,
  title: `Соответствие гайдбуку`,
  archive: `Скачать архив`,
  save: `Сохранить`,
  send: "Отправить на согласование",
  saveError: `Ошибка сохранения`,
  dateFormat: `DD.MM.YYYY`,
  timeFormat: `HH:mm`,
  fullDate: `DD.MM.YYYY HH:mm`,
  status: {
    [GuidebookStatuses.consideration]: {
      title: `На согласовании`,
      color: `#FD7E14`,
      icon: <ConsiderationIcon />
    },
    [GuidebookStatuses.rejected]: {
      title: `Не согласовано`,
      color: `#FA5252`,
      icon: <RejectedIcon />
    },
    [GuidebookStatuses.approved]: {
      title: `Согласовано`,
      color: `#40C057`,
      icon: <DoneIcon />
    }
  },
  photoNumber: "фото №",
  dateUpload: "Дата загрузки",
  editButton: "Редактировать",
  deleteButton: "Удалить",
  newPhoto: "Новое",
  oldPhoto: "Старое"
}

export const initialState: GuidebookPageState = {
  pending: false,
  data: {
    status: null,
    info: [],
    date: null
  }
}

export const initialRightPanel = {
  isOpen: true,
  isActive: true,
  mobileFilter: false
}

export const ComponentByStatus = ({
  status,
  isSection
}: {
  status: GuidebookStatuses
  isSection?: boolean
}) => {
  const currentStatus = statusConfig[status]
  const style = isSection
    ? {
        color: currentStatus.section.color,
        backgroundColor: currentStatus.section.background
      }
    : { backgroundColor: currentStatus.color, color: `#FFF` }
  const icon = isSection ? <div /> : currentStatus.icon
  return (
    <div
      className={`FlexRow ${
        isSection
          ? `ComponentByStatusSectionWrapper`
          : `ComponentByStatusWrapper`
      }`}
      style={{ ...style }}
    >
      {icon}
      <span>{currentStatus.title}</span>
    </div>
  )
}

export const modalSelectOptions = [
  {
    value: GuidebookStatuses.consideration,
    label: statusConfig[GuidebookStatuses.consideration].title
  },
  {
    value: GuidebookStatuses.rejected,
    label: statusConfig[GuidebookStatuses.rejected].title
  },
  {
    value: GuidebookStatuses.approved,
    label: statusConfig[GuidebookStatuses.approved].title
  }
]
