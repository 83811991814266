// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, { useMemo, useState } from "react"

import { rightPanelConstants } from "./constants"
import { GuidebookRightPanelProps } from "./types"
import { textConstants } from "../constants"
import moment from "moment"
import { ReactComponent as Empty } from "../icons/empty_img.svg"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"
import Chat from "../Chat"

export enum RightPanelTabs {
  comments = "comments",
  discuss = "discuss"
}

const GuidebookRightPanel: React.FC<GuidebookRightPanelProps> = ({
  info,
  setModalData,
  toggleMobileFilter,
  socket,
  unreadMessagesCounter,
  resetUnreadMessages,
  isOpen,
  setChatActive
}) => {
  const isMobile = window.innerWidth < 921

  const [activeTab, setActiveTab] = useState(RightPanelTabs.comments)

  const comments = useMemo(() => {
    return info
      .reduce((acc, el) => {
        const comments = el.attachments.reduce(
          (commentsAcc, commentsEl, index) => {
            const nextComments = commentsEl.comments.map(comment => {
              return {
                ...comment,
                title: el.title,
                index: index + 1,
                id: commentsEl.id,
                link: commentsEl.link,
                status: commentsEl.status,
                attachId: commentsEl.id,
                commentId: comment.id,
                sectionId: el.id
              }
            })
            return commentsAcc.concat(nextComments)
          },
          []
        )

        return acc.concat(comments)
      }, [])
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  }, [info])

  return (
    <div
      className={`GuidebookRightPanelWrapper ${isMobile &&
        "MainFilterMobile"} ${
        !isOpen ? "GuidebookRightPanelWrapperHidden" : ""
      }`}
    >
      <div className="MainFilterMobileClose" onClick={toggleMobileFilter}>
        <CloseIcon />
      </div>
      <div className="GuidebookRightPanelTitle HeadlineThirdText">
        {rightPanelConstants.title}
      </div>
      <div className="GuidebookRightPanelTabs FlexRow">
        <div
          className="GuidebookRightPanelTab FlexColumn"
          onClick={() => {
            setChatActive(false)
            setActiveTab(RightPanelTabs.comments)
          }}
        >
          <span className="CaptionTextBold">
            {rightPanelConstants.comments}
          </span>
          {activeTab === RightPanelTabs.comments && (
            <div className="GuidebookRightPanelActiveTab" />
          )}
        </div>
        <div
          className="GuidebookRightPanelTab FlexColumn"
          onClick={() => {
            setChatActive(true)
            setActiveTab(RightPanelTabs.discuss)
          }}
        >
          <div className="GuidebookRightPanelTabWithBadge">
            <span className="CaptionTextBold">
              {rightPanelConstants.discussion}
            </span>
            {unreadMessagesCounter > 0 && (
              <div className="GuidebookRightPanelTabBadge">
                {unreadMessagesCounter}
              </div>
            )}
          </div>
          {activeTab === RightPanelTabs.discuss && (
            <div className="GuidebookRightPanelActiveTab" />
          )}
        </div>
      </div>
      {activeTab === RightPanelTabs.comments ? (
        <div className="GuidebookRightPanelMessages">
          {comments.length ? (
            comments.map((x, i) => (
              <div
                key={i}
                className="GuidebookRightPanelComment"
                onClick={() => {
                  setModalData({
                    visible: true,
                    comments: [
                      {
                        index: x.index,
                        rootTitle: x.title,
                        uploadDate: x.rootDate,
                        uplStatus: x.status,
                        link: x.link,
                        commentText: x.text,
                        position: x.position,
                        commentDate: x.date,
                        attachId: x.attachId,
                        text: x.text,
                        commentId: x.commentId,
                        sectionId: x.sectionId
                      }
                    ],
                    index: x.index,
                    rootTitle: x.title,
                    uploadDate: x.rootDate,
                    uplStatus: x.status,
                    link: x.link,
                    commentText: x.text,
                    position: x.position,
                    commentDate: x.date,
                    attachId: x.attachId,
                    isSingle: true,
                    old: x.old
                  })
                }}
              >
                <div className="GuidebookRightPanelCommentHeader BodyFirstText">
                  <div className="GuidebookRightPanelCommentHeaderPrimaryText">
                    {x.title}
                  </div>{" "}
                  <div className="GuidebookRightPanelCommentHeaderSecondaryText">{`${textConstants.photoNumber}${x.index}`}</div>
                </div>
                <div className="TextDefault">{x.text}</div>
                <div className="GuidebookRightPanelCommentHeaderDateTime CaptionText">
                  <div>{moment(x.date).format(textConstants.dateFormat)}</div>
                  <div>{moment(x.date).format(textConstants.timeFormat)}</div>
                </div>
              </div>
            ))
          ) : (
            <div className="GuidebookRightPanelEmptyContainer">
              <Empty />
              <div className="CaptionText GuidebookRightPanelEmptyText">
                {rightPanelConstants.empty}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="GuidebookRightPanelChatWrapper">
          <Chat
            socket={socket}
            resetUnreadMessages={resetUnreadMessages}
            unreadMessagesCounter={unreadMessagesCounter}
          />
        </div>
      )}
    </div>
  )
}

export default GuidebookRightPanel
