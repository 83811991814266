import React, { useCallback, useEffect, useState } from "react"
import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import { useRequest, useRequestBody, useRequestParams } from "api/hooks"
import { createDashboardConfig, guestBalanceConsts } from "./constants"
import CustomZeroBalanceComponent from "./CustomZeroBalanceComponent"
import DashboardComponent from "Components/DashboardNew"
import TableComponent from "Components/TableNew"
import Title from "Components/Title"
import ExcelButton from "Components/ExcelButton"
import { ModalRightDetail } from "Components/Modal"
import GuestBalanceFreeBetsDetail from "./FreeBetsDetail"
import {
  createColumns,
  createRequestBody,
  initialFilterStateCreator
} from "./helpers"
import {
  FreeBetsDetailProps,
  GuestBalanceFilter,
  GuestBalancePaginationType,
  GuestBalanceReport,
  Limits
} from "./types"

const GuestsBalance = () => {
  const { current_hall } = useRequestParams()

  const [filterState, setFilterState] = useState<GuestBalanceFilter>(
    initialFilterStateCreator(current_hall[0] as number)
  )

  const setFilterStateCb = useCallback(value => {
    setFilterState({ ...value, balance: {} })
  }, [])

  const [reportData, setReportData] = useState<GuestBalanceReport>(
    guestBalanceConsts.initialReport
  )

  const [isInitialRender, setIsInitialRender] = useState(true)
  const [defaultFilterStateLimits, setDefaultFilterStateLimits] = useState<
    Limits | undefined
  >()

  const { pagination, setPagination, resetPagination } = useRequestBody(
    GuestBalancePaginationType.per_20
  )

  const requestBody = createRequestBody(filterState, pagination)

  const { request } = useRequest({
    url: guestBalanceConsts.url,
    method: `POST`,
    requestBody
  })

  useEffect(() => {
    setReportData({ ...reportData, pending: true })

    request()
      .then(({ data, headers }: any) => {
        setReportData({
          ...reportData,
          pending: false,
          total: headers[`total-count`],
          data
        })
        if (isInitialRender) {
          setDefaultFilterStateLimits(data.limits)
        }
        setIsInitialRender(false)
      })
      .catch(() => {
        setReportData({ ...reportData, pending: false })
      })
  }, [
    filterState.data.halls,
    filterState.data.zeroBalance,
    pagination.offset,
    pagination.limit,
    filterState.data.sort.order,
    filterState.data.sort.field,
    filterState.data.balance
  ])

  const [freeBetsDetailState, setFreeBetsDetailState] = useState<
    FreeBetsDetailProps
  >({
    walletId: 0,
    freeBetSum: "",
    freeBetCount: 0,
    hallId: Number(filterState.data.halls[0]),
    isOpen: false,
    pending: false,
    report: []
  })

  const { request: freeBetRequest } = useRequest({
    url: guestBalanceConsts.urlFreeBets,
    method: `POST`,
    requestBody: {
      walletId: freeBetsDetailState.walletId,
      hall: Number(filterState.data.halls[0])
    }
  })

  useEffect(() => {
    if (!freeBetsDetailState.isOpen) return

    setFreeBetsDetailState({ ...freeBetsDetailState, pending: true })

    freeBetRequest()
      .then(({ data }: any) => {
        setFreeBetsDetailState({
          ...freeBetsDetailState,
          pending: false,
          report: [...data]
        })
      })
      .catch(() => {
        setFreeBetsDetailState({ ...freeBetsDetailState, pending: false })
      })
  }, [freeBetsDetailState.isOpen])

  const columns = createColumns({
    reportData,
    filterState,
    setFilterState,
    resetPagination,
    freeBetsDetailState,
    setFreeBetsDetailState,
    initialLimits: defaultFilterStateLimits
  })

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={guestBalanceConsts.GUEST_BALANCE_FILTER}
            filterState={filterState}
            setFilterState={setFilterStateCb}
            resetPagination={resetPagination}
            filters={[Filters.hallPickerRadio, Filters.customItem]}
            getDisabled={filter => filter.isActive}
            CustomComponent={CustomZeroBalanceComponent}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <div className={filterState.isOpen ? `GuestBalance_filterOpen` : ``}>
            <Title
              titleText={guestBalanceConsts.mainTitle}
              tooltipText={guestBalanceConsts.mainTooltip}
              wrapperClass={"GuestBalanceTitleWrapper"}
              rightAdditionalCmp={
                <ExcelButton
                  disabled={false}
                  url={guestBalanceConsts.urlExcel}
                  requestBody={{
                    filter: requestBody.filter,
                    sort: requestBody.sort
                  }}
                />
              }
              rightAdditionalClassName={"GuestBalanceTitle"}
            />
            <div className={"GuestsBalancePage"}>
              <div className="MarginBottom16">
                <DashboardComponent
                  config={createDashboardConfig(reportData.data)}
                />
              </div>
              <TableComponent
                columns={columns}
                withMinHeight
                data={reportData.data?.wallets}
                loading={reportData.pending}
                pagination={{
                  value: pagination,
                  total: reportData.total,
                  onChange: setPagination
                }}
              />
            </div>
          </div>
        }
      />
      {freeBetsDetailState.isOpen && (
        <ModalRightDetail
          close={() =>
            setFreeBetsDetailState({ ...freeBetsDetailState, isOpen: false })
          }
        >
          <GuestBalanceFreeBetsDetail state={freeBetsDetailState} />
        </ModalRightDetail>
      )}
    </>
  )
}

export default GuestsBalance
