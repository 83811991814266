import React from "react"

import TabsComponent from "Components/Tabs"

import { tabsConfig } from "./constant"
import { GuestHistoryTabItems } from "../../types"

const GuestHistoryTabs = ({
  tab,
  toggleTab
}: {
  tab: GuestHistoryTabItems
  toggleTab: (value: any) => void
}) => {
  return (
    <div>
      <TabsComponent
        tabs={tabsConfig}
        activeTab={tab}
        toggleActiveTab={toggleTab}
      />
    </div>
  )
}

export default GuestHistoryTabs
