import React, { memo } from "react"
import { Switch } from "antd"
import { SwitchProps } from "antd/lib"

interface ISwitchComponentProps extends SwitchProps {
  rest?: any
  label?: string
}

const SwitchComponents = ({ label, ...rest }: ISwitchComponentProps) => {
  return (
    <div className="SwitchComponentWrapper">
      <Switch {...rest} />
      <span className="SwitchComponentLabel SubtitleFirstText">{label}</span>
    </div>
  )
}

export const SwitchFilterComponent = ({
  label,
  ...rest
}: ISwitchComponentProps) => {
  return (
    <div className="SwitchComponentWrapper SwitchFilterWrapper FlexRow">
      <span className="SideFilterContentLabel">{label}</span>
      <Switch {...rest} />
    </div>
  )
}

export default memo(SwitchComponents)
