import React, { useCallback, useState } from "react"

import MailingHall from "./Halls"
import MailingHistory from "./History"

import { ActiveTabs } from "./types"

const Mailing: React.FC = () => {
  const [activeTab, setActiveTab] = useState<ActiveTabs>(ActiveTabs.hall)

  const isHall = activeTab === ActiveTabs.hall
  const isHistory = activeTab === ActiveTabs.history

  const switchTabCb = useCallback(
    (tab: ActiveTabs) => {
      if (tab !== activeTab) {
        setActiveTab(tab)
      }
    },
    [activeTab]
  )

  return (
    <div className="MailingWrapper">
      {isHall && (
        <MailingHall activeTab={activeTab} setActiveTab={switchTabCb} />
      )}
      {isHistory && (
        <MailingHistory activeTab={activeTab} setActiveTab={switchTabCb} />
      )}
    </div>
  )
}

export default Mailing
