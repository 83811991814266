import React from "react"

import { ModalFormComponent } from "Components/Modal"
import ButtonDefault from "Components/Button"

import { ReactComponent as DownloadIcon } from "Components/icons/download_outline_20.svg"
import { ReactComponent as CloseIcon } from "Components/icons/close_28.svg"

type PhotoPreviewModalProps = {
  link: string
  close: () => void
  title: string
  description: string
}

const PhotoPreviewModal = ({
  link,
  close,
  description,
  title
}: PhotoPreviewModalProps) => {
  const handleDownload = () => {
    const link = document.createElement("a")
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    link.href = link
    link.download = "image.jpg"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <ModalFormComponent
      width={1080}
      close={close}
      withoutCross={true}
      contentClassName="PhotoPreviewModal__Content"
    >
      <div className="PhotoPreviewModal__ImageWrapper">
        <img
          src={link}
          alt="Загруженное изображение"
          className="PhotoPreviewModal__ImageWrapper__Image"
        />
        <div
          className="PhotoPreviewModal__ImageWrapper__Close HoverItem"
          onClick={close}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="PhotoPreviewModal__Data FlexRow">
        <div className="FlexColumn Gap8">
          <span className="HeadlineThirdText">{title}</span>
          <span className="CaptionText Gray600Color">{description}</span>
        </div>
        <div className="PhotoPreviewModal__Data__Download">
          <ButtonDefault onClick={handleDownload}>
            <DownloadIcon />
          </ButtonDefault>
        </div>
      </div>
    </ModalFormComponent>
  )
}

export default PhotoPreviewModal
