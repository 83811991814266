import { IDevelopState } from "store/developer/types"
import { RouteComponentProps } from "react-router-dom"
import { Moment } from "moment"

interface RequestParamsProps {
  logout: () => void
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  history: RouteComponentProps["history"]
}

export interface GetDateDescriptionLayout {
  dates: [Moment, Moment] | null
  datesAsIs?: boolean
  shift?: [number, number]
  isShift: boolean
  show: boolean
  hideTime?: boolean
  className?: string
  hidePeriodTitle?: boolean
  calendarIcon?: boolean
}

export enum GetDateByDigitalTypes {
  minute = `minute`,
  hour = `hour`,
  days = "day",
  month = "month",
  year = "year"
}

export default RequestParamsProps
