import {
  DashboardOptions,
  DashboardPrefixOptions,
  defaultDescriptions
} from "Components/DashboardNew/constants"

type GetDashboardConfigProps = {
  value: string
  total: string
  toggleQiwiModal: (val: boolean) => void
  withTotal?: boolean
  loading: boolean
}

export const titleText = {
  qiwiTooltip:
    "Расчетный баланс клуба/клубов для безналичных выплат на банковские карты гостей на текущий момент.",
  balance: "Баланс для \nвыплат, ₽",
  totalTooltip:
    "Общая сумма транзакций по клубу/ам за выбранный в фильтрах период.",
  total: "Общая \nсумма, ₽",
  getDashboardConfig: ({
    value,
    total,
    withTotal,
    toggleQiwiModal,
    loading
  }: GetDashboardConfigProps) => {
    const balanceDashboard = [
      {
        type: DashboardOptions.balance_payout,
        value,
        isCurrency: true,
        description: defaultDescriptions.now,
        tooltipText: titleText.qiwiTooltip,
        prefix: DashboardPrefixOptions.RUB,
        loading,
        onClick: () => toggleQiwiModal(true)
      }
    ]
    return withTotal
      ? [
          ...balanceDashboard,
          {
            type: DashboardOptions.money_balance,
            title: `Общая\nсумма`,
            value: total || 0,
            isCurrency: true,
            prefix: DashboardPrefixOptions.RUB,
            description: defaultDescriptions.current_period,
            tooltipText: titleText.totalTooltip,
            loading
          }
        ]
      : balanceDashboard
  }
}
