import React from "react"
import TableComponent from "Components/Table"
import { getDateDescriptionLayout, isMobile } from "constants/index"
import { MobileBackButton } from "Components/Button"
import { WinnersDetailProps } from "../types"
import insurancesConstants from "../constants"
import moment from "moment"
import {
  getInsuranceStatusClass,
  getWinnersColumn,
  translateInsuranceStatus
} from "../helpers"

const WinnersDetail = ({
  data = [],
  insuranceId,
  close
}: WinnersDetailProps) => {
  const insurance = data.find(item => item.id === insuranceId)

  const datesTitle = getDateDescriptionLayout({
    dates: [moment(insurance?.period[0]), moment(insurance?.period[1])],
    shift: [8, 20],
    hideTime: true,
    isShift: false,
    show: true,
    className: "InsuranceDatesDescription"
  })

  return (
    <div className="InsurancesDetailWrapper">
      <div className="InsurancesDetailHeader">
        {isMobile && (
          <div className="MarginBottom16">
            <MobileBackButton onClick={close} />
          </div>
        )}
        <div className="FlexColumn">
          <span className="HeadlineSecondText">
            {insurancesConstants.winnerModalTitle}
          </span>
          <div className="InsurancesDetailSubtitle">
            <span className="InsurancesDetailText">{`№ ${insurance?.id}`}</span>
            <span className="InsurancesDetailSeparator">
              {insurancesConstants.delimeter}
            </span>
            <span
              className={`TextDefault ${getInsuranceStatusClass(
                insurance?.status
              )}`}
            >
              {translateInsuranceStatus(insurance?.status)}
            </span>
            <span className="InsurancesDetailSeparator">
              {insurancesConstants.delimeter}
            </span>
            <span className="InsurancesDetailText">{datesTitle}</span>
          </div>
        </div>
      </div>
      <div className="InsurancesDetailTable">
        <TableComponent
          columns={getWinnersColumn()}
          data={insurance?.winners || []}
          scroll={{ y: insurancesConstants.scrollY }}
          tableClassName="OrangeHeaderTable"
        />
      </div>
    </div>
  )
}

export default WinnersDetail
