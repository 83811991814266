import React, { useState } from "react"

import moment, { Moment } from "moment"

import { ButtonFaded, ButtonPrimary, buttonText } from "Components/Button"
import DatePickerComponent from "Components/DatePicker"

import SortTableFilter from "../SortFilter"
import { fromToConstants } from "../FromToFilter/constants"

import { SortOrders } from "../../types"

type DateFilterProps = {
  currentField: string
  value?: Moment
  label: string
  picker: string
  format: string
  currentSortedField?: string
  currentSortedOrder?: SortOrders
  changeFilter: (val: {
    value?: Moment
    sort: {
      order?: SortOrders
      field?: string
    }
  }) => void
  closeFunc?: () => void
}

type DateFilterState = {
  value?: Moment
  order?: SortOrders
  sortField?: string
  hasSort: boolean
}

const DateFilter = ({
  changeFilter,
  closeFunc,
  currentField,
  currentSortedField,
  currentSortedOrder,
  value,
  label,
  picker,
  format
}: DateFilterProps) => {
  const isSortedByCurrentField = currentSortedField === currentField
  const initialState: DateFilterState = {
    value: value ? moment(value) : undefined,
    sortField: currentSortedField,
    hasSort: isSortedByCurrentField,
    order: currentSortedOrder || SortOrders.asc
  }

  const [state, setState] = useState<DateFilterState>(initialState)

  const showClear = !!value || isSortedByCurrentField

  return (
    <div>
      <SortTableFilter
        order={state.order}
        changeOrder={value =>
          setState({
            ...state,
            order: value
          })
        }
        hasSort={state.hasSort}
        changeHasSort={value =>
          setState({
            ...state,
            hasSort: value,
            sortField: value ? currentField : currentSortedField
          })
        }
      />
      <div className={"TableHeaderModalSubTitle"}>
        {label || fromToConstants.applyText}
      </div>
      <DatePickerComponent
        value={state.value}
        onChange={date => setState({ ...state, value: date || undefined })}
        picker={picker}
        format={format}
      />
      <div className="FilterButtonsWrapper MarginTop16">
        {showClear && (
          <ButtonFaded
            onClick={() => {
              changeFilter({
                value: undefined,
                sort: {
                  order: isSortedByCurrentField
                    ? undefined
                    : currentSortedOrder,
                  field: isSortedByCurrentField ? undefined : currentSortedField
                }
              })

              !!closeFunc && closeFunc()
            }}
          >
            {fromToConstants.clearText}
          </ButtonFaded>
        )}
        <ButtonPrimary
          onClick={() => {
            changeFilter({
              value: state.value,
              sort: {
                order: state.order,
                field: state.hasSort ? currentField : currentSortedField
              }
            })
            if (!!closeFunc) {
              closeFunc()
            }
          }}
        >
          {buttonText.apply}
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default DateFilter
