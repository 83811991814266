import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { useNow, useRequest } from "api/hooks"

import { listUrl, setList } from "store/access/reducer"
import { TemplatesList } from "store/access/types"

import { ViewProps } from "./types"

import AccessModal from "./CRUD"
import AccessBase from "./base"
import { CardsListData } from "./Cards/types"
import AccessSuccess from "./success"

const AccessReport = () => {
  const { refresh, timestamp } = useNow()
  const [view, setView] = useState<ViewProps>({ isCrud: false })
  const closeModal = useCallback(() => {
    setView({ isCrud: false })
  }, [])
  const openModal = useCallback((data?: CardsListData) => {
    setView({ isCrud: true, data })
  }, [])

  const { request: getTemplatesList } = useRequest({ url: listUrl })

  const dispatch = useDispatch()
  const storeAccessTemplates: (data: TemplatesList) => void = data =>
    dispatch(setList(data))
  useEffect(() => {
    getTemplatesList().then(({ data }) => storeAccessTemplates(data))
  }, [])

  const [success, toggleSuccess] = useState<{ open: boolean; isEdit: boolean }>(
    { open: false, isEdit: false }
  )
  const closeSuccessCb = useCallback(
    () => toggleSuccess({ open: false, isEdit: false }),
    [success]
  )

  return (
    <>
      {view.isCrud ? (
        <AccessModal
          closeModal={(withRefresh?: boolean, isEdit?: boolean) => {
            closeModal()
            if (withRefresh) {
              refresh()
              toggleSuccess({ open: true, isEdit: !!isEdit })
            }
          }}
          data={view.data}
        />
      ) : (
        <AccessBase
          openModal={openModal}
          refresh={refresh}
          refreshTrigger={timestamp}
        />
      )}
      {success.open && (
        <AccessSuccess close={closeSuccessCb} isEdit={success.isEdit} />
      )}
    </>
  )
}

export default AccessReport
