import React from "react"

import { ModalFormComponent } from "Components/Modal"
import { convertSizeToString } from "Components/File/constans"

import { ReactComponent as PreviewIcon } from "Components/icons/view_on.svg"
import { ReactComponent as DownloadIcon } from "Components/icons/download.svg"

import { ReactComponent as PdfFile } from "Components/icons/files/pdf.svg"
import { ReactComponent as DocFile } from "Components/icons/files/doc.svg"
import { ReactComponent as JpgFile } from "Components/icons/files/jpg.svg"
import { ReactComponent as PngFile } from "Components/icons/files/png.svg"
import { ReactComponent as XlsFile } from "Components/icons/files/xls.svg"

import { FilesModalProps } from "./types"
import { tableComponentsConstants } from "./constants"

enum MimeType {
  PNG = "image/png",
  JPG = "image/jpeg",
  PDF = "application/pdf",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}

const getConfigByName = (name: string, size: number) => {
  let fileConfig
  switch (name) {
    case MimeType.PDF: {
      fileConfig = {
        canPreview: true,
        icon: <PdfFile />
      }
      break
    }
    case MimeType.DOC || MimeType.DOCX:
      fileConfig = {
        canPreview: false,
        icon: <DocFile />
      }
      break
    case MimeType.JPG:
      fileConfig = {
        canPreview: false,
        icon: <JpgFile />
      }
      break
    case MimeType.PNG:
      fileConfig = {
        canPreview: false,
        icon: <PngFile />
      }
      break
    case MimeType.XLS || MimeType.XLSX:
      fileConfig = {
        canPreview: false,
        icon: <XlsFile />
      }
      break
  }

  const sizeText = convertSizeToString(size)
  fileConfig = { ...fileConfig, size: sizeText }

  return fileConfig
}

const FilesModal = ({ close, data }: FilesModalProps) => {
  return (
    <ModalFormComponent
      width={400}
      close={close}
      title={tableComponentsConstants.title}
      className="PaymentFilesModalWrapper"
    >
      <div className="PaymentFilesModalContentWrapper FlexColumn Gap8">
        {data.map(file => {
          const config = getConfigByName(file.mime_type, file.size)
          return (
            <div
              key={`file_${file.name}`}
              className="PaymentFilesItemWrapper FlexRow"
            >
              <div className="FlexRow Gap10">
                {config?.icon}
                <div className="FlexColumn">
                  <span className="TextDefault">{file.name}</span>
                  <span className="Gray600Color CaptionText">
                    {config?.size}
                  </span>
                </div>
              </div>
              <div className="PaymentFilesItemButtons FlexRow MarginLeftAuto Gap12 PaddingTop6">
                <div className="HoverItem">
                  {config?.canPreview && (
                    <a href={file.url} target="_blank" rel="noreferrer">
                      <PreviewIcon />
                    </a>
                  )}
                </div>
                <div className="HoverItem">
                  <a href={file.url} download={file.name}>
                    <DownloadIcon />
                  </a>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </ModalFormComponent>
  )
}

export default FilesModal
