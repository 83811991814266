import { IDevelopState } from "./types"
import { ADD_LINK } from "./constants"
const init: IDevelopState = {}

export function developerReducer(
  state: IDevelopState = init,
  action: { type: string; payload: IDevelopState }
): IDevelopState {
  const { type, payload } = action
  switch (type) {
    case ADD_LINK: {
      return { ...state, ...payload }
    }

    default:
      return state
  }
}
