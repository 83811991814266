import { getFilterStateFromLocalStorage } from "api/helpers"

import { isEmpty } from "lodash"

import { SportBoomFilterData } from "./types"

import {
  checkArrayRole,
  parseDateByShift,
  parseDateToBack
} from "../../constants"

export const SPORT_BOOM_FILTERS = `SPORT_BOOM_FILTERS`

export const isSpecialRoles = () =>
  checkArrayRole([`COMPANY-RISK-MANAGEMENT`, `COMPANY-SUPPORT`])

export const initialFilterValues = {
  dates: undefined,
  gmt: `3`,
  isShift: true,
  shift: [8, 8],
  bet_number: undefined,
  wallet_number: undefined
}

export enum SportBoomTabs {
  sport_bets = "sport_bets",
  positive_win = "top_win_positive",
  negative_win = "top_win_negative"
}

export const sportboomMainText = {
  title: `SportBoom`,
  tooltip: `Отчет предназначен для вывода информации по всем ставкам, совершенным на спортивную линию. Показатели за период позволяют провести финансовый анализ по совершенным ставкам, а графическое представление отчета по количеству гостей, объему денежных ставок, WIN и удержанию поможет визуализировать динамику по показателям. Отчет позволяет получить информацию по конкретной ставке и общую по номеру кошелька гостя (только с учетом периода). Доступна информация по ставкам в Топ 10 турниров по WIN и в Топ 10 турниров по отрицательному WIN в отдельных вкладках. Несколько ставок возможно сравнить. Ставки содержат детальную информацию о госте, событии, сумме ставки и коэффициенте. Ставки на события, которые на момент формирования отчета уже начались, будут иметь значок “Live”. Время смены по умолчанию с 8:00 по мск.`,
  notActiveFilter: {
    title: `Чтобы получить отчёт по SportBoom,\nвам необходимо выбрать нужные \nпараметры фильтрации`,
    description: `Отчёт Sportboom содержит данные, такие как суммы и типы \nставок, дату и время операции, а также результаты событий`
  },
  customFilter: {
    bet_number: {
      label: `Номер ставки`,
      placeholder: `Введите номер ставки`
    },
    wallet_number: {
      label: `Номер кошелька`,
      placeholder: `Введите номер кошелька`
    }
  },
  tabs: {
    [SportBoomTabs.sport_bets]: {
      label: `Ставки на спорт`,
      url: `sport_bets`
    },
    [SportBoomTabs.positive_win]: {
      label: `Топ 10 турниров по WIN`,
      url: `positive_win`
    },
    [SportBoomTabs.negative_win]: {
      label: `Топ 10 турниров по отрицательному WIN`,
      url: `negative_win`
    }
  },
  tableConfig: {
    title: `Параметры таблицы`,
    emptyInside: `Дополнительная информация по ставке скрыта. Для ее отображения добавьте столбцы в разделе «Параметры таблицы».`
  }
}

export const tabsConfig = (isDisable: boolean) => [
  {
    label: sportboomMainText.tabs[SportBoomTabs.sport_bets].label,
    value: SportBoomTabs.sport_bets
  },
  {
    label: sportboomMainText.tabs[SportBoomTabs.positive_win].label,
    value: SportBoomTabs.positive_win,
    disabled: isDisable
  },
  {
    label: sportboomMainText.tabs[SportBoomTabs.negative_win].label,
    value: SportBoomTabs.negative_win,
    disabled: isDisable
  }
]

export const initialValuesWithHalls = (hall: string) => {
  const hallsFromStorage: number[] =
    JSON.parse(localStorage.getItem(`halls`) || ``) || []
  const halls = !isSpecialRoles()
    ? [hall]
    : hallsFromStorage?.map(item => `${item}`)

  return {
    ...initialFilterValues,
    halls
  }
}

export const parseDates = (
  filter: Omit<SportBoomFilterData, "bet_number" | "wallet_number">
) =>
  filter.dates
    ? parseDateToBack({
        date: parseDateByShift({
          dates: filter.dates,
          isShift: filter.isShift,
          shift: filter.shift
        }),
        gmt: filter.gmt
      })
    : undefined

export const getInitialFiltersValue = (hall: string) => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    SPORT_BOOM_FILTERS
  )

  const haveFilterFromStorage = !isEmpty(initFilterStateFromStorage)
  const initialWithHalls = initialValuesWithHalls(hall)

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: haveFilterFromStorage,
    data: {
      ...initialWithHalls,
      ...initFilterStateFromStorage
    }
  }
}

export const walletModalColumns = [
  {
    title: ""
  }
]

export const initialWalletDetailsData = {
  isWalletModalActive: false,
  isWalletSideModalActive: false,
  loading: false,
  data: null,
  activeWalletId: null
}
