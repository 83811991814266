import React from "react"

import { checkNegative, checkNegativeWithShow } from "constants/index"

import { TableColumnWidth } from "Components/Tabs/types"

import { ReactComponent as InfoIcon } from "Components/icons/info.svg"
import { TitleTooltipComponent } from "Components/Title/Title"

export const textConstants = {
  title: `Игровой отчёт`,
  tooltip:
    "Отчет по игровой прибыли. Содержит такие данные как детализация по играм, игровая статистика, информация по ставкам по игре/набору игр, а также детализацию по бонусным и денежным ставкам.",
  exelConfig: {
    url: `reports/games/excel`,
    title: `Игровой отчёт`
  },
  empty: {
    title: `Чтобы получить отчёт по игровой прибыли,вам необходимо выбрать нужные параметры фильтрации`,
    description: `Отчёт по игровой прибыли. Содержит такие данные как детализация по играм, игровая статистика, информация по ставкам по игре/набору игр, а также детализацию по бонусным и денежным ставкам.`
  },
  total: "Итого"
}

const ExpectedIncomeTitle = () => (
  <div className="FlexColumn">
    <span className="TableHeader">{`Ожидаемая`}</span>
    <div className="FlexRow GamesReport_Table_Column">
      <span className="TableHeader">{`прибыль, ₽`}</span>
      <TitleTooltipComponent
        icon={<InfoIcon />}
        direction="left"
        tooltipText={`"Ожидаемая прибыль" по спорту доступна только в случае построения отчета по смене с 8:00-8:00 по МСК`}
      />
    </div>
  </div>
)

export const TABLE_WIDTH = 420
export const columnGames = [
  {
    title: "Сыграно\nBetBoom, ₽",
    width: TableColumnWidth.s,
    dataIndex: "in",
    key: "in",
    ...checkNegative
  },
  {
    title: "Выиграно\nBetBoom, ₽",
    width: TableColumnWidth.s,
    dataIndex: "out",
    key: "out",
    ...checkNegative
  },
  {
    title: "Сыграно бонусных\nBetBoom, Б",
    width: TableColumnWidth.s,
    dataIndex: "inBonus",
    key: "inBonus",
    ...checkNegative
  },
  {
    title: "Результат\nBetBoom, ₽",
    width: TableColumnWidth.s,
    dataIndex: "result",
    key: "result",
    ...checkNegative
  },
  {
    title: "Итого\nBetBoom, ₽",
    width: TableColumnWidth.s,
    dataIndex: "total",
    key: "total",
    ...checkNegative
  },
  {
    title: () => <ExpectedIncomeTitle />,
    width: TableColumnWidth.s,
    dataIndex: "expectedIncome",
    key: "expectedIncome",
    ...checkNegativeWithShow
  }
]

export const columnSport = [
  {
    title: "Сыграно\nSportBoom, ₽",
    width: TableColumnWidth.s,
    dataIndex: "inSport",
    key: "inSport",
    ...checkNegative
  },
  {
    title: "Выиграно\nSportBoom, ₽",
    width: TableColumnWidth.s,
    dataIndex: "outSport",
    key: "outSport",
    ...checkNegative
  },
  {
    title: "Сыграно\nFreeBet, Ф",
    width: TableColumnWidth.s,
    dataIndex: "inBonus",
    key: "inBonus",
    ...checkNegative
  },
  {
    title: "Результат\nSportBoom, ₽",
    width: TableColumnWidth.s,
    dataIndex: "resultSport",
    key: "resultSport",
    ...checkNegative
  },
  {
    title: "Итого\nSportBoom, ₽",
    width: TableColumnWidth.s,
    dataIndex: "totalSport",
    key: "totalSport",
    ...checkNegative
  },
  {
    title: () => <ExpectedIncomeTitle />,
    width: TableColumnWidth.s,
    dataIndex: "expectedIncome",
    key: "expectedIncome",
    ...checkNegativeWithShow
  }
]
