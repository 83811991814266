import React, { useEffect, useRef, useState } from "react"
import useChatApi, { allowedTypes } from "./helper"
import { ReactComponent as SendIcon } from "../icons/send_chat.svg"
import { ReactComponent as AttachIcon } from "../icons/attach.svg"
import { ReactComponent as PhotosIcon } from "../icons/photos_icon.svg"
import { ReactComponent as FilesIcon } from "../icons/file_icon.svg"
import { ReactComponent as ReplyIcon } from "../icons/reply.svg"
import { ReactComponent as CancelIcon } from "../icons/rejected.svg"
import { InputTextAreaComponent } from "Components/Input"
import { message, Popover, Upload } from "antd"
import { SocketChatEvents } from "./constants"
import moment from "moment"
import { useSelector } from "react-redux"
import { getCurrentHall } from "store/halls/selector"
import noop from "lodash/noop"
import FileComponent from "Components/File"
import SpinComponent from "Components/Spin"
import { ChatProps, Message, NewMessage } from "./types"

const Chat: React.FC<ChatProps> = ({
  socket,
  resetUnreadMessages,
  unreadMessagesCounter
}) => {
  const { getAllMessages } = useChatApi()

  const hallState = useSelector(getCurrentHall)
  const hallLs = JSON.parse(localStorage.getItem("hall") || "")
  const currentHall = hallState?.length > 0 ? hallState : hallLs

  const [messages, setMessages] = useState<[Message] | []>([])
  const [newMessage, setNewMessage] = useState<NewMessage | null>(null)
  const [loading, setLoading] = useState({ fileName: "", active: false })
  const [quoteMessage, setQuoteMessage] = useState<Message | null>()

  const userId = JSON.parse(localStorage.getItem("user") || ``).userId
  const lastElRef = useRef()

  useEffect(() => {
    getAllMessages().then(res => {
      setMessages(res.data.reverse())
      resetUnreadMessages && resetUnreadMessages()
    })
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lastElRef?.current?.scrollIntoView()
  }, [messages])

  useEffect(() => {
    if (unreadMessagesCounter !== 0) {
      resetUnreadMessages && resetUnreadMessages()
    }
  }, [unreadMessagesCounter])

  useEffect(() => {
    socket.on(SocketChatEvents.newMessage, (data: Message) => {
      if (data?.attachment?.name.includes(loading.fileName)) {
        setLoading({ fileName: "", active: false })
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setMessages(prev => [...prev, data])
    })
  }, [socket])

  return (
    <div className="ChatWrapper">
      <div className="MessagesWrapper">
        {messages.map((el, i) => {
          const [first, second] = el.role.toUpperCase().split(" ")
          const avatarTitle = `${first[0]}${second ? second[0] : ""}`
          const replyMessage = el.replyId
            ? messages.find(message => message.id === el.replyId)
            : null
          const newDate = moment(el.date).format("DD.MM.YYYY HH:mm")

          return (
            <div
              className={`ChatMessageContainer ${
                userId !== el.userId ? "ChatMessageContainerRight" : ""
              }`}
              key={Math.random() * 100 * el?.id + el?.date}
            >
              {userId === el.userId && (
                <div className="ChatIcon">{avatarTitle}</div>
              )}
              <div className="ChatRoleDateTextContainer">
                <div className="RoleDateWrapper">
                  <div className="ChatRole">{el.role}</div>
                  <div className="ChatDate">{newDate}</div>
                </div>
                {el.attachment?.link &&
                el.attachment?.name &&
                el.attachment?.size ? (
                  <>
                    {replyMessage && (
                      <div className="ChatQuoteWrapper">
                        <div className="ChatTextWrapperRole">
                          {replyMessage.role}
                        </div>
                        <div className="ChatTextWrapperQuote">
                          {replyMessage.text}
                        </div>
                      </div>
                    )}
                    <FileComponent
                      name={el.attachment.name}
                      link={el.attachment.link}
                      size={el.attachment.size}
                      view
                      customDownloadLink={`https://chat.bingo.zone${el.attachment.link}`}
                    />
                  </>
                ) : (
                  <>
                    {replyMessage && (
                      <div className="ChatQuoteWrapper">
                        <div className="ChatTextWrapperRole">
                          {replyMessage.role}
                        </div>
                        <div className="ChatTextWrapperQuote">
                          {replyMessage.text}
                        </div>
                      </div>
                    )}
                    <div className="ChatTextContainer">
                      <div className="ChatTextWrapper">{el.text}</div>
                      <div
                        className="ChatReplyIconContainer"
                        onClick={() => {
                          setQuoteMessage(el)
                        }}
                      >
                        <ReplyIcon />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {userId !== el.userId && (
                <div className="ChatIcon">{avatarTitle}</div>
              )}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*@ts-ignore*/}
              {i === messages.length - 1 && <div ref={lastElRef} />}
            </div>
          )
        })}
      </div>

      <div className="ChatSendMessageContainer">
        {quoteMessage && (
          <div className="ChatQuoteWrapperContainer">
            <div className="ChatQuoteWrapper">
              <div className="ChatTextWrapperRole">{quoteMessage.role}</div>
              <div className="ChatTextWrapperQuote">{quoteMessage.text}</div>
            </div>

            <div
              className="ChatQuoteCancelIcon"
              onClick={() => {
                setQuoteMessage(null)
              }}
            >
              <CancelIcon />
            </div>
          </div>
        )}

        <div className="ChatSendMessageContainerChildren">
          <Popover
            trigger={"click"}
            content={
              <Upload
                customRequest={() => noop}
                itemRender={() => noop}
                multiple={false}
                accept={allowedTypes}
                onChange={({ file }) => {
                  if (file?.size && file.size <= 6e6) {
                    setLoading({ fileName: file.name, active: true })
                    const typeFile = { ...file, base64: undefined }
                    const reader = new FileReader()
                    reader.onload = e => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      typeFile.base64 = e.target?.result
                      if (
                        !!e.target?.result &&
                        typeof e.target?.result === `string`
                      ) {
                        const base: string = e.target?.result

                        socket.emit(SocketChatEvents.sendMessage, {
                          hall: currentHall,
                          attachment: {
                            name: file.name,
                            content: base
                          }
                        })
                      }
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    reader.readAsDataURL(typeFile?.originFileObj)
                  } else {
                    message.error("Размер файла не должен превышать 5мб")
                  }
                }}
              >
                <div>
                  <div className="ProposalPopoverWrapper">
                    <div className="ProposalPopoverButton">
                      <PhotosIcon />
                      <div className="ProposalPopoverButtonText">Фото</div>
                    </div>
                    <div className="ProposalPopoverButton">
                      <FilesIcon />
                      <div className="ProposalPopoverButtonText">Файл</div>
                    </div>
                  </div>
                </div>
              </Upload>
            }
          >
            {loading.active ? (
              <SpinComponent />
            ) : (
              <AttachIcon className="ChatAttachIcon" />
            )}
          </Popover>

          <InputTextAreaComponent
            className="ChatInput"
            value={newMessage?.text}
            autoSize={{ minRows: 2, maxRows: 2 }}
            onChange={e => {
              setNewMessage({
                text: e.target.value,
                hall: currentHall,
                replyId: quoteMessage?.id
              })
            }}
            placeholder={"Напишите сообщение..."}
          />
          <SendIcon
            className={`ChatSendIcon ${
              newMessage?.text && newMessage?.text.length > 0
                ? "ChatSendIconActive"
                : ""
            }`}
            onClick={() => {
              if (newMessage?.text && newMessage?.text.length > 0) {
                socket.emit(SocketChatEvents.sendMessage, newMessage)
                setNewMessage(null)
                setQuoteMessage(null)
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Chat
