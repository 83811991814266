import { HallCards } from "./types"

export const hallCardTextConst = {
  header: "Карточка клуба",
  tooltipText:
    "Раздел отображает информацию по регистрационным данным клуба, а также по курирующим менеджерам от офиса компании. Информацию возможно распечатать прямо из раздела.",
  print: "Распечатать",
  main: "Основные данные",
  registration: "Регистрация",
  license: "Лицензия",
  managers: "Ваши курирующие менеджеры"
}

export const cardConst = {
  general: {
    hall_id: "Номер клуба",
    op_code: "Код ОП",
    address: "Адрес клуба"
  },
  registration: {
    date_op: "Дата постановки на учет ОП",
    date_pps: "Дата постановки на учет ППС",
    address: "Адрес ОП"
  },
  license: {
    license_number: "Номер вписания в лицензию",
    license_date: "Дата вписания в лицензию",
    op_order_date: "Дата приказа о создании ОП",
    extract_number: "№ в выписке"
  },
  managers: {
    email: "Email: ",
    phone: "Телефон: "
  }
}

export const HallCardDto: HallCards = {
  general: {
    hall: 0,
    op_code: "",
    address: ""
  },
  registration: {
    date_op: "",
    date_pps: "",
    address: ""
  },
  license: {
    license_number: 0,
    license_date: "",
    op_order_date: "",
    extract_number: 0
  },
  managers: [
    {
      manager_role: "",
      manager_name: "",
      manager_email: "",
      manager_phone: ""
    }
  ]
}
