import React, { useEffect } from "react"

import SwitchComponents from "Components/Switch"
import SliderComponent from "Components/Slider"
import SelectHours from "Components/SelectHour"

import { SideFilterData } from "../../types"

import { shiftText } from "./constants"
import {
  SHIFT_LIMIT_MAX,
  shiftMoscowGmtFirst,
  SHIFT_LIMIT_MIN
} from "constants/index"

const SideFilterShift = ({
  filterState,
  setFilterState,
  label
}: {
  filterState: SideFilterData | any
  setFilterState: React.Dispatch<React.SetStateAction<SideFilterData>>
  label: string
}) => {
  const disabled = !filterState?.shift
  const start = filterState?.shift ? filterState?.shift[0] : 8
  const finish = filterState?.shift ? filterState?.shift[1] : 8

  useEffect(() => {
    if (filterState.gmt) {
      setFilterState(prev => ({
        ...prev,
        shift: shiftMoscowGmtFirst({
          gmt: filterState.gmt,
          isShift: filterState.isShift
        })
      }))
    }
  }, [filterState])

  return (
    <div>
      <div className="SideFilterContentLabel">{label}</div>
      {!disabled && (
        <div className="FlexRow">
          <span className="SideFilterContentLabel">{shiftText.change}</span>
          <div className="InfoPanelWithoutShiftTypeSwitcher">
            <SwitchComponents
              label=""
              disabled={disabled}
              checked={filterState.isShift || disabled}
              onChange={checked =>
                setFilterState({
                  ...filterState,
                  isShift: checked,
                  shift: shiftMoscowGmtFirst({
                    gmt: filterState.gmt,
                    isShift: checked
                  })
                })
              }
            />
          </div>
        </div>
      )}
      {filterState.isShift || disabled ? (
        <SliderComponent
          min={SHIFT_LIMIT_MIN}
          max={SHIFT_LIMIT_MAX}
          disabled={disabled}
          defaultValue={start}
          onChange={(value: number) =>
            setFilterState({ ...filterState, shift: [value, value] })
          }
        />
      ) : (
        <SelectHours
          from={start}
          to={finish}
          disabled={false}
          onChange={(value: [number, number]) =>
            setFilterState({ ...filterState, shift: value })
          }
        />
      )}
    </div>
  )
}

export default SideFilterShift
