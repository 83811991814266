import React, { useState } from "react"
import { ButtonIcon } from "Components/Button"
import SpinComponent from "Components/Spin"
import { useRequest } from "api/hooks"
import { CancelButtonProps } from "../types"
import { ReactComponent as CancelIcon } from "Components/icons/cancel_circle.svg"
import { smsConstants } from "../constants"

const CancelButton = ({
  requestId,
  historyState,
  setHistoryState
}: CancelButtonProps) => {
  const [cancellingState, setCancellingState] = useState(false)

  const { request: cancelRequest } = useRequest({
    url: smsConstants.urls.cancel,
    method: `POST`,
    requestBody: {
      hallId: parseInt(`${historyState.hallId}`),
      requestId
    }
  })

  return (
    <div>
      {!cancellingState ? (
        <ButtonIcon
          className="SmsCancelBtn"
          icon={<CancelIcon />}
          onClick={() => {
            setCancellingState(true)

            cancelRequest()
              .then(() => {
                setHistoryState({
                  ...historyState,
                  reload: true
                })
                setCancellingState(false)
              })
              .catch(() => {
                setCancellingState(false)
              })
          }}
        >
          {smsConstants.cancelBtnTxt}
        </ButtonIcon>
      ) : (
        <SpinComponent />
      )}
    </div>
  )
}

export default CancelButton
