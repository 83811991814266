import React from "react"
import { WikiMenuItem, WikiSubheaderMapType } from "./types"
import { ReactComponent as Billhead } from "./icons/billhead.svg"
import { ReactComponent as Cashbox } from "./icons/cashbox.svg"
import { ReactComponent as Education } from "./icons/education.svg"
import { ReactComponent as FileHosting } from "./icons/file_hosting.svg"
import { ReactComponent as Home } from "./icons/home.svg"
import { ReactComponent as Market } from "./icons/market.svg"
import { ReactComponent as Write } from "./icons/write.svg"
import { ReactComponent as Promo } from "./icons/promo.svg"
import { ReactComponent as Support } from "./icons/support.svg"
import { ReactComponent as Users } from "./icons/users.svg"
import { ReactComponent as Hall } from "./icons/hall.svg"
import { ReactComponent as Bets } from "./icons/bets.svg"
import { ReactComponent as Nominal } from "./icons/nominal.svg"
import { ReactComponent as Registration } from "./icons/registration.svg"

export const WikiMenu: WikiMenuItem[] = [
  {
    name: "Главная",
    filesUrl: "/dashboard/Dashboard.md",
    associativeLink: "/dashboard"
  },
  {
    name: "Сообщения",
    filesUrl: "/messages/Messages.md",
    associativeLink: "/messages"
  },
  {
    name: "Штрафы",
    filesUrl: "/penalty/Penalty.md",
    associativeLink: "/support/penalty/user"
  },
  {
    name: "Касса",
    filesUrl: "/cashbox/CashboxReport.md",
    associativeLink: "/cashbox"
  },
  {
    name: "Проверка регистрации",
    filesUrl: "/registration_check/RegistrationCheck.md",
    associativeLink: "/registration-check"
  },
  {
    name: "История гостя",
    filesUrl: "/guests_history/GuestsHistory.md",
    associativeLink: "/guests_history"
  },
  {
    name: "Страховка",
    filesUrl: "/insurances/Insurances.md",
    associativeLink: "/insurance"
  },
  {
    name: "Золотая лихорадка",
    filesUrl: "/gold_rush/GoldRush.md",
    associativeLink: "/promotions/gold_rush"
  },
  {
    name: "БетБонус",
    filesUrl: "/bet_bonus/BetBonus.md",
    associativeLink: "/promotions/bet_bonus"
  },
  {
    name: "День рождения гостя",
    filesUrl: "/birthday/Birthday.md",
    associativeLink: "/promotions/guests_birthday"
  },
  {
    name: "Информация по гостю",
    filesUrl: "/guest_info/GuestInfo.md",
    associativeLink: "/guestinfo"
  },
  {
    name: "Гонка",
    filesUrl: "/race/Race.md",
    associativeLink: "/race"
  },
  {
    name: "Топ 3",
    filesUrl: "/top_3/Top3.md",
    associativeLink: "/top3"
  },
  {
    name: "Супервыигрыши",
    filesUrl: "/superwins/Superwins.md",
    associativeLink: "/superwins"
  },
  {
    name: "Операции с балансом",
    filesUrl: "/balance_operation/BalanceOperation.md",
    associativeLink: "/reports/noncash/balanceOperation"
  },
  {
    name: "Игровой отчет",
    filesUrl: "/games_report/Games.md",
    associativeLink: "/games"
  },
  {
    name: "Отчеты по клубу",
    filesUrl: "/reports_hall/ReportsHall.md",
    associativeLink: "/reports/hall"
  },
  {
    name: "Сводный отчет",
    filesUrl: "/consolidated_report/ConsolidatedReport.md",
    associativeLink: "/summary"
  },
  {
    name: "Отчет по ЦУПИС",
    filesUrl: "/cupis_report/CupisReport.md",
    associativeLink: "/reports/cupis"
  },
  {
    name: "SportBoom",
    filesUrl: "/sportboom/SportBoom.md",
    associativeLink: "/sportboom"
  },
  {
    name: "История игровых ставок",
    filesUrl: "/history_bets/HistoryBets.md",
    associativeLink: "/history/bets"
  },
  {
    name: "Баланс клуба",
    filesUrl: "/hall_balance/HallBalanceReport.md",
    associativeLink: "/reports/noncash/hallbalance"
  },
  {
    name: "Транзакции",
    filesUrl: "/transactions/Transactions.md",
    associativeLink: "/reports/noncash/transaction"
  },
  {
    name: "​Спорт трансляция",
    filesUrl: "/broadcast_sport/BroadcastSport.md",
    associativeLink: "/hall/sport_broadcast"
  },
  {
    name: "​Фото для геосервисов",
    filesUrl: "/yandexfeed_photo/YandexfeedPhoto.md",
    associativeLink: "/hall/geoservices"
  },
  {
    name: "Соответствие гайдбуку",
    filesUrl: "/compliance_with_the_guidebook/ComplianceWithTheGuidebook.md",
    associativeLink: "/guidebook"
  },
  {
    name: "Отчет для сверки СОПД",
    filesUrl: "/registration_report/RegistrationReport.md",
    associativeLink: "/sopd-report"
  },
  {
    name: "Рассылка о пополнениях и выплатах",
    filesUrl:
      "/deposit_and_payout_notifications/DepositAndPayoutNotifications.md",
    associativeLink: "/mailing"
  },
  {
    name: "Посещаемость клуба",
    filesUrl: "/club_attendance/ClubAttendance.md",
    associativeLink: "/attendance"
  },
  {
    name: "Изменение номера телефона гостя",
    filesUrl: "/change_guest_phone/ChangePhone.md",
    associativeLink: "/order/changephone"
  },
  {
    name: "Создать рассылку",
    filesUrl: "/sms_request/SMS.md",
    associativeLink: "/sms"
  },
  {
    name: "Служебные СМС",
    filesUrl: "/service_sms/ServiceSMS.md",
    associativeLink: "/sms/service"
  },
  {
    name: "Заявки на оплату",
    filesUrl: "/payment/Payment.md",
    associativeLink: "/order/payment"
  },
  {
    name: "Платёжные ведомости",
    filesUrl: "/payrolls/Payrolls.md",
    associativeLink: "/order/payrolls"
  },
  {
    name: "Балансы клуба",
    filesUrl: "/qiwi/Qiwi.md",
    associativeLink: "/order/payouts"
  },
  {
    name: "Номиналы",
    filesUrl: "/nominals/Nominals.md",
    associativeLink: "/nominal"
  },
  {
    name: "Балансы гостей",
    filesUrl: "/guests_balance/GuestsBalance.md",
    associativeLink: "/guests_balance"
  },
  {
    name: "Фильтры по гостям",
    filesUrl: "/filters_by_guests/FiltersByGuests.md",
    associativeLink: "/guest/filters"
  },
  {
    name: "Обратная связь",
    filesUrl: "/support_proposal/Proposal.md",
    associativeLink: "/support/proposal"
  }
]

export const text = {
  main: "Добро пожаловать в справочник личного кабинета",
  mainSubheader: "Справки по сервисам",
  optionsTooltip: "Поиск по всем справкам",
  sidenav: "В этой справке:",
  groupSubHeader:
    "В этом разделе вы можете ознакомиться со следующими справками:",
  reports:
    "Раздел содержит в себе перечень отчетов, которые позволяют просматривать информацию по: движению безналичных денежных средств, денежному обороту, суммам ставок, статистике с сайта betboom.ru и др.",
  noncash:
    "Раздел содержит отчеты о транзакциях в клубах. Данные отчеты позволяют отслеживать движение безналичных денежных средств на клубном балансе.",
  hall: "Раздел содержит данные и настройки по клубу.",
  promo:
    "Раздел предназначен для просмотра информации по акциям, которые проходят или проходили в клубе. Для различных акций доступен просмотр разных метрик, данные можно просматривать по отдельному гостю или по клубу в целом.",
  requests:
    "Раздел предназначен для создания заявок на согласование по различным типам событий.",
  sms:
    "Раздел содержит инструменты для работы с СМС-рассылками. С помощью подпунктов раздела можно посмотреть количество отправленных служебных СМС гостям, а также создать заявку на рассылку по различным типам событий.",
  guests:
    "Раздел предназначен для просмотра детальной информации по гостям клуба.",
  support:
    "Раздел предназначен для составления обращений в техническую поддержку и получения обратной связи от специалистов.",
  bets: "Раздел содержит отчеты по ставкам на спорт и играм Теннис"
}
export enum MenuKeys {
  none = "",
  main = "Основное",
  reports = "Отчеты",
  messages = "Сообщения",
  cashbox = "Касса",
  files = "Файлообменник",
  requests = "Заявки",
  nominal = "Номиналы",
  guidebook = "Гайдбук",
  internetMarket = "Интернет-магазин",
  requestsLearn = "Заявки на обучение",
  promo = "Акции",
  support = "Поддержка",
  noncash = "Отчет по безналичному расчету",
  sms = "СМС рассылки",
  guests = "Гости",
  hall = "Клуб",
  bets = "Ставки",
  registration = "Проверка регистрации"
}

export const WikiSubheaderMap: WikiSubheaderMapType = {
  [MenuKeys.none]: "",
  [MenuKeys.reports]: text.reports,
  [MenuKeys.noncash]: text.noncash,
  [MenuKeys.hall]: text.hall,
  [MenuKeys.promo]: text.promo,
  [MenuKeys.requests]: text.requests,
  [MenuKeys.guests]: text.guests,
  [MenuKeys.support]: text.support,
  [MenuKeys.sms]: text.sms,
  [MenuKeys.bets]: text.bets
}

export const DashboardWikiMap = [
  {
    name: MenuKeys.main,
    icon: <Home />,
    elements: ["/dashboard", "/messages", "/support/penalty/user"]
  },
  {
    name: MenuKeys.cashbox,
    icon: <Cashbox />,
    elements: ["/cashbox"]
  },
  {
    name: MenuKeys.registration,
    icon: <Registration />,
    elements: ["/registration-check"]
  },
  {
    name: MenuKeys.reports,
    icon: <Billhead />,
    elements: [
      "/reports/noncash/balanceOperation",
      "/reports/noncash/hallbalance",
      "/reports/noncash/transaction",
      "/reports/hall",
      "/games",
      "/summary",
      "/reports/cupis"
    ]
  },
  {
    name: MenuKeys.bets,
    icon: <Bets />,
    elements: ["/sportboom", "/history/bets"]
  },
  {
    name: MenuKeys.hall,
    icon: <Hall />,
    elements: [
      "/mailing",
      "/attendance",
      "/guidebook",
      "/sopd-report",
      "/hall/sport_broadcast",
      "/hall/geoservices"
    ]
  },
  {
    name: MenuKeys.promo,
    icon: <Promo />,
    elements: [
      "/insurance",
      "/guestinfo",
      "/reports/noncash/transaction",
      "/race",
      "/top3",
      "/superwins",
      "/promotions/gold_rush",
      "/promotions/bet_bonus",
      "/promotions/guests_birthday"
    ]
  },
  {
    name: MenuKeys.files,
    icon: <FileHosting />,
    elements: []
  },
  {
    name: MenuKeys.requests,
    icon: <Write />,
    elements: [
      "/order/changephone",
      "/order/payment",
      "/order/payrolls",
      "/order/payouts",
      "/sms",
      "/sms/service"
    ]
  },
  {
    name: MenuKeys.nominal,
    icon: <Nominal />,
    elements: ["/nominal"]
  },
  {
    name: MenuKeys.internetMarket,
    icon: <Market />,
    elements: []
  },
  {
    name: MenuKeys.requestsLearn,
    icon: <Education />,
    elements: []
  },
  {
    name: MenuKeys.guests,
    icon: <Users />,
    elements: ["/guests_history", "/guests_balance", "/guest/filters"]
  },
  {
    name: MenuKeys.support,
    icon: <Support />,
    elements: ["/support/proposal"]
  }
]
