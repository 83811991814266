import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { useNow, useRequestBody, useRequestParams } from "api/hooks"

import moment from "moment"

import { ReactComponent as BellIcon } from "Components/icons/notification_bell.svg"
import { ReactComponent as ReadIcon } from "Components/icons/done_circle.svg"
import { ReactComponent as AttachIcon } from "Components/icons/attach.svg"
import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"
import { ReactComponent as FilterIcon } from "Components/icons/filter_arrow.svg"

import PaginationComponent from "Components/Pagination"
import EmptyTable from "Components/EmptyTable"
import BaseFooter from "Components/Footer"

import MessagesRouteFilter, { MessagesRouteFilterDataType } from "./Filter"
import getMessageList from "./helper"

import { MESSAGES_PAGE } from "Containers/BaseLayout/constants"
import MessagesComponentId from "Containers/Messages/Current"

import { MessageActiveTab, MessageListProp } from "./types"
import {
  initialFilterState,
  initialMessageList,
  messageText,
  PinComponent
} from "./constants"

const MessagesRoute = () => {
  const { history, logout, addDeveloperLink, developer } = useRequestParams()
  const { pagination, setPagination } = useRequestBody(messageText.perPage)

  const { timestamp, refresh } = useNow()

  const [messageList, setMessageList] = useState<MessageListProp>(
    initialMessageList
  )

  const pending = messageList.pending

  const [filterState, setFilterState] = useState<MessagesRouteFilterDataType>(
    initialFilterState
  )

  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false)

  const { pathname } = useLocation()
  const id =
    !!pathname && pathname !== messageText.route
      ? Number(pathname.replace(messageText.idRoute, ``))
      : undefined

  const [contentSwitcher, switchContent] = useState<number | undefined>(id)

  useEffect(() => {
    if (!!pathname && pathname !== messageText.route) {
      switchContent(Number(pathname.replace(messageText.idRoute, ``)))
    } else {
      switchContent(undefined)
    }
  }, [pathname])

  const [activeTabs, changeActiveTab] = useState<MessageActiveTab>(
    MessageActiveTab.all
  )

  const showSeparate =
    activeTabs === MessageActiveTab.all ||
    activeTabs === MessageActiveTab.pinned

  const isPinnedList = activeTabs === MessageActiveTab.pinned

  const successPinnedAction = () => {
    if (isPinnedList) {
      refresh()
    }
  }

  useEffect(() => {
    if (pagination.offset !== 0) {
      setPagination({ limit: messageText.perPage, offset: 0 })
    } else refresh()
  }, [activeTabs])

  useEffect(() => {
    getMessageList({
      developer,
      addDeveloperLink,
      history,
      logout,
      type: activeTabs,
      filter: { date: filterState.date, text: filterState.text },
      setPending: (value: boolean) =>
        setMessageList({ ...messageList, pending: value }),
      setData: (value: MessageListProp) => setMessageList({ ...value }),
      pagination
    })
  }, [pagination, filterState.text, filterState.date, timestamp])

  return (
    <div>
      {!contentSwitcher && (
        <div className="FlexRow">
          {mobileFilterOpen && (
            <MessagesRouteFilter
              filterState={filterState}
              setFilterState={setFilterState}
              mobileFilterOpen={mobileFilterOpen}
              setMobileFilterOpen={setMobileFilterOpen}
              isOpen={filterState.isActive}
            />
          )}
          <div
            className="MobileFilterIcon"
            onClick={() => setMobileFilterOpen(true)}
          >
            <FilterMobileIcon />
          </div>
          <div className="FlexRow">
            <div
              className={`MainPageFilterButtonWrapper ${
                filterState.isActive
                  ? `MainPageFilterButtonWrapperActive`
                  : `MainPageFilterButtonWrapperUnActive`
              }`}
              onClick={() =>
                setFilterState({
                  ...filterState,
                  isActive: !filterState.isActive
                })
              }
            >
              <FilterIcon />
            </div>
          </div>
          <div
            className={`MainPageContentWrapper ${
              filterState.isActive
                ? `MainPageContentWrapperFilter`
                : `MainPageContentWrapperUnFilter`
            }`}
          >
            <div className="MainPageContentRestrictions">
              <div className="MainPageTitle MessagesPageTitle">
                {messageText.title}
              </div>
              <div className="NotificationsHeaderTabs MessagesHeaderTabs FlexRow">
                <div
                  className={`FlexColumn NotificationsHeaderTabsItem ${
                    activeTabs === MessageActiveTab.all
                      ? `NotificationsHeaderTabsActiveItemText`
                      : ``
                  }`}
                  onClick={() => {
                    if (activeTabs !== MessageActiveTab.all) {
                      changeActiveTab(MessageActiveTab.all)
                    }
                  }}
                >
                  <span>{messageText[MessageActiveTab.all]}</span>
                  {activeTabs === MessageActiveTab.all && (
                    <div className="NotificationsHeaderTabsActiveItem" />
                  )}
                </div>
                <div
                  className={`FlexColumn NotificationsHeaderTabsItem ${
                    activeTabs === MessageActiveTab.unread
                      ? `NotificationsHeaderTabsActiveItemText`
                      : ``
                  }`}
                  onClick={() => {
                    if (activeTabs !== MessageActiveTab.unread) {
                      changeActiveTab(MessageActiveTab.unread)
                    }
                  }}
                >
                  <span>{messageText[MessageActiveTab.unread]}</span>
                  {activeTabs === MessageActiveTab.unread && (
                    <div className="NotificationsHeaderTabsActiveItem" />
                  )}
                </div>
                <div
                  className={`FlexColumn NotificationsHeaderTabsItem ${
                    activeTabs === MessageActiveTab.read
                      ? `NotificationsHeaderTabsActiveItemText`
                      : ``
                  }`}
                  onClick={() => {
                    if (activeTabs !== MessageActiveTab.read) {
                      changeActiveTab(MessageActiveTab.read)
                    }
                  }}
                >
                  <span>{messageText[MessageActiveTab.read]}</span>
                  {activeTabs === MessageActiveTab.read && (
                    <div className="NotificationsHeaderTabsActiveItem" />
                  )}
                </div>
                <div
                  className={`FlexColumn NotificationsHeaderTabsItem ${
                    isPinnedList ? `NotificationsHeaderTabsActiveItemText` : ``
                  }`}
                  onClick={() => {
                    if (activeTabs !== MessageActiveTab.pinned) {
                      changeActiveTab(MessageActiveTab.pinned)
                    }
                  }}
                >
                  <span>{messageText[MessageActiveTab.pinned]}</span>
                  {isPinnedList && (
                    <div className="NotificationsHeaderTabsActiveItem" />
                  )}
                </div>
              </div>
              <div className="MessagesRouteListWrapper">
                {!!messageList.data.unread &&
                !!messageList.data.unread.length &&
                showSeparate ? (
                  <div className="MessagesRouteIconWrapper FlexRow">
                    <BellIcon />
                    <div className="MessagesRouteListTitle">
                      {messageText[MessageActiveTab.unread]}
                    </div>
                  </div>
                ) : (
                  <div style={{ paddingBottom: `24px` }} />
                )}
                <div
                  className={
                    messageList.data.unread && !!messageList.data.unread.length
                      ? `MessagesRouteList`
                      : ``
                  }
                >
                  {messageList.data.unread.map((item, key) => {
                    const isFirst = key === 0
                    const isLast = key === messageList.data.unread.length - 1
                    const firstLine = (item.text || ``).split(`\n`)[0]
                    return (
                      <div key={`message_${item.id}`}>
                        <div
                          className={`MessagesRouteListItem FlexColumn ${
                            isLast ? `MessagesRouteListLastItem` : ``
                          }  ${isFirst ? `MessagesRouteListFirstItem` : ``} ${
                            pending ? `MessagesRouteListPendingItem` : ``
                          }`}
                          onClick={() =>
                            history.push(`${MESSAGES_PAGE}/${item.id}`)
                          }
                        >
                          <div className="MessagesRouteListItemRow FlexRow">
                            <PinComponent
                              pin={item.pinned}
                              isSmall={true}
                              id={item.id}
                              successAction={successPinnedAction}
                            />
                            <div className="MessagesRouteListItemReadCircleWrapper">
                              <div className="MessagesRouteListItemReadCircle" />
                            </div>
                            <div className="FlexRow MessagesRouteListItemDataMobile">
                              <div className="MessagesRouteListItemTitle">
                                {item.title}
                              </div>
                              <div className="MessagesRouteListItemText">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: firstLine
                                  }}
                                />
                              </div>
                            </div>
                            <div className="FlexRow MessagesRouteListItemRight">
                              {!!item.files && !!item.files.length && (
                                <div className="MessagesRouteListItemAttach">
                                  <AttachIcon />
                                </div>
                              )}
                              <div className="MessagesRouteListItemDate">
                                {moment(item.date).format(`DD.MM.YY`)}
                              </div>
                            </div>
                          </div>
                          {!!item.halls && !!item.halls.length && (
                            <div className="FlexRow">
                              <div className="MessagesRouteListItemHallsTitle">
                                Клубы:
                              </div>
                              <div className="MessagesRouteListItemHalls">
                                {item.halls.join(`, `)}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="MessagesRouteListWrapper">
                {!!messageList.data.read &&
                  !!messageList.data.read.length &&
                  showSeparate && (
                    <div className="MessagesRouteIconWrapper FlexRow">
                      <ReadIcon />
                      <div className="MessagesRouteListTitle">
                        {messageText[MessageActiveTab.read]}
                      </div>
                    </div>
                  )}
                <div
                  className={
                    messageList.data.read && !!messageList.data.read.length
                      ? `MessagesRouteList`
                      : ``
                  }
                >
                  {messageList.data.read.map((item, key) => {
                    const isFirst = key === 0
                    const isLast = key === messageList.data.read.length - 1
                    const firstLine = (item.text || ``).split(`\n`)[0]
                    return (
                      <div key={`message_${item.id}`}>
                        <div
                          className={`MessagesRouteListItem FlexColumn ${
                            isLast ? `MessagesRouteListLastItem` : ``
                          } ${isFirst ? `MessagesRouteListFirstItem` : ``}  ${
                            pending ? `MessagesRouteListPendingItem` : ``
                          }`}
                          onClick={() =>
                            history.push(`${MESSAGES_PAGE}/${item.id}`)
                          }
                        >
                          <div className="MessagesRouteListItemDataMobileUnread FlexRow">
                            <PinComponent
                              pin={item.pinned}
                              isSmall={true}
                              id={item.id}
                              successAction={successPinnedAction}
                            />
                            <div className="MessagesRouteListItemTitle">
                              {item.title}
                            </div>
                            <div className="MessagesRouteListItemText">
                              <span
                                dangerouslySetInnerHTML={{ __html: firstLine }}
                              />
                            </div>
                            <div className="FlexRow MessagesRouteListItemRight">
                              {!!item.files && !!item.files.length && (
                                <div className="MessagesRouteListItemAttach">
                                  <AttachIcon />
                                </div>
                              )}
                              <div className="MessagesRouteListItemDate">
                                {moment(item.date).format(`DD.MM.YY`)}
                              </div>
                            </div>
                          </div>
                          {!!item.halls && !!item.halls.length && (
                            <div className="FlexRow">
                              <div className="MessagesRouteListItemHallsTitle">
                                Клубы:
                              </div>
                              <div className="MessagesRouteListItemHalls">
                                {item.halls.join(`, `)}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="MessagesRouteListPaginationWrapper">
                <PaginationComponent
                  value={pagination}
                  pageSize={messageText.perPage}
                  total={messageList.total}
                  onChange={setPagination}
                />
              </div>
              {messageList.total == 0 &&
                (!!filterState.text || !!filterState.date) && (
                  <div className="NotifiEmptyTableWrapper">
                    <EmptyTable />
                  </div>
                )}
            </div>
          </div>
          <div
            className={`MainFilterWrapper ${
              filterState.isActive
                ? `MainFilterWrapperActive`
                : `MainFilterWrapperUnActive`
            }`}
          >
            <MessagesRouteFilter
              filterState={filterState}
              setFilterState={setFilterState}
              isOpen={filterState.isActive}
            />
          </div>
        </div>
      )}
      {!!contentSwitcher && (
        <div className="MainPageContentWrapper MainPageContentWrapperUnFilter">
          <div className="MainPageContentRestrictions">
            <MessagesComponentId
              id={contentSwitcher}
              close={() => history.push(MESSAGES_PAGE)}
              refresh={refresh}
            />
          </div>
        </div>
      )}
      <BaseFooter />
    </div>
  )
}

export default MessagesRoute
