import React, { useEffect, useMemo, useState } from "react"

import { useRequest, useRequestParams } from "api/hooks"

import TableComponent from "Components/TableNew"
import { ReactComponent as EmptyTable } from "Components/icons/illustrations/alarm_dude_large.svg"

import DeleteModal from "./Delete"
import { SportsBroadcastTableState } from "./types"
import { getColumns, tableText } from "./constants"

import { EditButton } from "../index"
import { SportsBroadcastData } from "../types"

const SportsBroadcastTable = ({
  changeEmpty,
  openModal,
  refreshTrigger,
  refresh,
  setInfoLink
}: {
  changeEmpty: (data: boolean) => void
  openModal: (data?: SportsBroadcastData) => void
  refreshTrigger: number
  refresh: () => void
  setInfoLink: (val?: string) => void
}) => {
  const [deleteModal, toggleDeleteModal] = useState<number | undefined>(
    undefined
  )
  const [tableState, setTableState] = useState<SportsBroadcastTableState>({
    pending: false,
    data: []
  })

  const { currentHallAsNumber } = useRequestParams()
  const { request: getData } = useRequest({
    url: `v1/hall/sports_broadcast`,
    requestBody: { hall: currentHallAsNumber }
  })

  useEffect(() => {
    setTableState({ ...tableState, pending: true })
    getData()
      .then(({ data }) => {
        setTableState({ pending: false, data: data.items })
        setInfoLink(data.channels_file_url)
        changeEmpty(!!data?.items?.length)
      })
      .catch(() => {
        setTableState({ ...tableState, pending: false })
      })
  }, [refreshTrigger])

  const isEmptyTable = !tableState.data.length

  const column = useMemo(
    () =>
      getColumns({
        toggleEdit: openModal,
        toggleDelete: (tv: number) => toggleDeleteModal(tv)
      }),
    []
  )

  return (
    <>
      {isEmptyTable && !tableState.pending ? (
        <div className="FlexColumn Gap24 MarginTop64">
          <div className="GridCenter">
            <EmptyTable />
          </div>
          <div className="GridCenter SubtitleSecondText Gray700Color">
            {tableText.empty}
          </div>
          <div className="GridCenter">
            <EditButton open={() => openModal()} />
          </div>
        </div>
      ) : (
        <div className="MarginTop16">
          <TableComponent
            width={800}
            loading={tableState.pending}
            columns={column}
            data={tableState.data}
          />
        </div>
      )}
      {!!deleteModal && (
        <DeleteModal
          tv={deleteModal}
          toggleDeleteModal={toggleDeleteModal}
          refresh={refresh}
        />
      )}
    </>
  )
}

export default SportsBroadcastTable
