import { Moment } from "moment/moment"

export type OfficialSmsFilterData = {
  dates?: { from: Moment; to: Moment }
  halls: [number]
}

export type OfficialSmsFilter = {
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
  data: OfficialSmsFilterData
}

export enum OfficialSmsColumns {
  date = "date",
  cashboxCount = "cashboxCount",
  checksCount = "checksCount",
  smsCount = "smsCount"
}

export type OfficialSmsReportItem = {
  date?: string
  cashboxCount: number
  checksCount: number
  smsCount: number
}

export type OfficialSmsReport = {
  pending: boolean
  total: number
  data: OfficialSmsReportItem[]
}
