import React from "react"

import moment, { Moment } from "moment"

import { isMobile } from "constants/index"

import { FilterRangeFields, GuestFiltersData, GuestFiltersState } from "./types"

import { ReactComponent as DotIcon } from "./dot.svg"
import { ReactComponent as GuestsIcon } from "./users_outline.svg"

export const initialFilterBets = (val: boolean) => ({
  bets_sum: {
    enabled: val
  },
  win_sum: {
    enabled: val
  },
  bonus_bets_sum: {
    enabled: val
  },
  freebet_bets_sum: {
    enabled: val
  },
  sportboom: false,
  game_ids: []
})

export const initialFilterCashbox = (val: boolean) => ({
  cash_in: {
    enabled: val
  },
  noncash_in: {
    enabled: val
  },
  cash_out: {
    enabled: val
  },
  noncash_out: {
    enabled: val
  }
})

export const initialFilterCurrentBalance = (val: boolean) => ({
  money_balance: {
    enabled: val
  },
  bonus_balance: {
    enabled: val
  }
})

export const initialFilterTransferBalance = (val: boolean) => ({
  transfer_to: {
    enabled: val
  },
  transfer_from: {
    enabled: val
  }
})

export const getInitialFilter = (): GuestFiltersState => {
  const initialBets = initialFilterBets(false)
  const initialCashbox = initialFilterCashbox(false)
  const initialCurrentBalance = initialFilterCurrentBalance(false)
  const initialTransferBalance = initialFilterTransferBalance(false)
  return {
    isOpen: true,
    mobileFilter: false,
    isActive: false,
    sort: { field: undefined, order: undefined },
    data: {
      dates: undefined,
      gmt: `3`,
      isShift: true,
      shift: [8, 8],
      period: `daily`,
      ...initialBets,
      ...initialCashbox,
      ...initialCurrentBalance,
      ...initialTransferBalance
    }
  }
}

export const guestFiltersText = {
  title: `Фильтры по гостям`,
  notActive: {
    title: `Чтобы получить отчёт по гостям, \nвыберите нужные фильтры и нажмите кнопку «Применить»`,
    description: `Отчёт для определения группы гостей клуба по одному или\nнескольким фильтрам, таким как фильтр по сумме ставок, по\nсумме кассовых операций, по текущему балансу.`
  },
  description: `Отчет позволяет сегментировать гостей клуба по заданным в отчете параметрам. Доступные параметры фильтрации по ставкам и выигрышам в игру/игры и SportBoom, по пополнениям и выплатам через кассу, по текущему балансу. Важно, что при фильтрации по ставкам возможно выбрать только игры или только SportBoom, при этом фильтр по бонусам будет доступен, если выбрана хоть одна игра, а фильтр по фрибетам - если выбран SportBoom. В табличном виде отчета будут отображены те столбцы, фильтры по которым были выставлены, при этом колонки с № кошелька, ФИО гостя и количеством визитов будут отображены всегда.`,
  filterName: `GUEST_FILTERS_FILTER`,
  infoFilter: {
    dates: {
      tooltip: `При выборе дат, фильтр «По текущему балансу» отфильтрует гостей, которые посещали клуб в этот период. Если выбран только фильтр «По текущему балансу», отчёт можно построить без указания периода.`,
      periodOptions: [
        { value: `daily`, label: `Дням` },
        { value: `monthly`, label: `Месяцам` }
      ]
    }
  }
}

export const disableDates = (current: Moment | null): boolean => {
  const startDate = moment("2020-01-01")
  const today = moment()
  return current ? !current.isBetween(startDate, today, "day", "[]") : false
}

export const checkFilterForDisabled = (filterData: GuestFiltersData) => {
  const colorArray = Object.values(FilterRangeFields)

  let countOfActive = 0
  const allEnabled = colorArray.filter(item => {
    const field = item as FilterRangeFields
    const fieldObj = filterData[field]

    if (!fieldObj) return false
    if (!fieldObj.enabled) return false
    if (fieldObj.enabled) {
      if (
        field === FilterRangeFields.freebet_bets_sum &&
        !filterData.sportboom
      ) {
        return false
      }
      if (
        field === FilterRangeFields.bonus_bets_sum &&
        !filterData.game_ids?.length
      ) {
        return false
      }

      countOfActive = countOfActive + 1
      const from = fieldObj.range?.from
      const to = fieldObj.range?.to
      if (from && to && from >= to) {
        return true
      }
    }
    return false
  })

  //Кнопка Отправить активна если нет дат, но
  //1 Выбраны ТОЛЬКО два фильтра и это бонусный и денежный балансы
  const isOnlyBothBonusMoneyChecked =
    countOfActive === 2 &&
    filterData[FilterRangeFields.money_balance].enabled &&
    filterData[FilterRangeFields.bonus_balance].enabled

  //2 Выбран ТОЛЬКО один из балансов (бонусный или денежный)
  const isEitherBonusMoneyChecked =
    countOfActive === 1 &&
    (filterData[FilterRangeFields.money_balance].enabled ||
      filterData[FilterRangeFields.bonus_balance].enabled)

  if (
    !filterData.dates &&
    !(isEitherBonusMoneyChecked || isOnlyBothBonusMoneyChecked)
  ) {
    return true
  }

  return !!allEnabled.length || !countOfActive
}

export const GuestTotalTitle = ({ total }: { total: number }) => {
  const title = `Всего гостей: ${total}`
  return (
    <div className="FlexRow Gap8 GuestFiltersTotalTitle PaddingLeft4">
      {!isMobile && (
        <div>
          <DotIcon />
        </div>
      )}
      <div className="PaddingTop4">
        <GuestsIcon />
      </div>
      <div className="PaddingTop2">
        <span className="TextTwoBold">{title}</span>
      </div>
    </div>
  )
}
