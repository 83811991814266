import React, { useState } from "react"

import { useHistory } from "react-router-dom"

import { Collapse } from "antd"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

import { isMobile } from "constants/index"
import { saveFilterInLocalStorage } from "api/helpers"

import SwitchComponents from "Components/Switch"
import { ButtonGreen } from "Components/Button"

import { superwinText } from "./constants"
import { SuperWinTableDataProps } from "./types"
import { SuperWinMobileTable, SuperWinTable } from "./Tables"

import { ReactComponent as TennisOneIcon } from "./icons/tennis_one.svg"
import { ReactComponent as TennisTwoIcon } from "./icons/tennis_two.svg"
import { ReactComponent as TennisOneFightIcon } from "./icons/tennis_one_fight.svg"
import { ReactComponent as TennisTwoFightIcon } from "./icons/tennis_two_fight.svg"
import { ReactComponent as RiseIcon } from "./icons/rise.svg"

import { SUPERWIN_FILTERS_MANAGE } from "../../Superwin/ManageContent/constants"
import { activeTabKeyLs } from "../../Superwin/constants"
import { SuperwinReportTabs } from "../../Superwin/types"
import { SUPERWIN } from "../../BaseLayout/constants"

const { Panel } = Collapse

const HallItem = ({
  hall,
  defaultOpen,
  showRise,
  haveVip,
  dataMain,
  dataVip
}: {
  hall: string | CheckboxValueType
  defaultOpen?: boolean
  showRise: boolean
  haveVip: boolean
  dataMain: SuperWinTableDataProps[]
  dataVip: SuperWinTableDataProps[]
}) => {
  const history = useHistory()
  const [showVip, toggleVip] = useState(false)
  return (
    <div key={`super_win_hall_${hall}`} className="MainSuperWinWrapper">
      <Collapse
        ghost
        defaultActiveKey={defaultOpen ? [`super_win_hall_${hall}`] : []}
      >
        <Panel
          key={`super_win_hall_${hall}`}
          showArrow={false}
          header={
            <div className="MainSuperHeaderWrapper">
              {superwinText.item.title}
              <span className="MainSuperHeaderHallWrapper">
                {superwinText.item.getHall(hall)}
              </span>
            </div>
          }
        >
          <div className="FlexRow MainSuperGamesHeader">
            <div className="MainSuperGamesWrapper FlexRow FlexWrap Gap16">
              <div className="MainSuperGamesLogo FlexRow">
                <div className="MainSuperGamesLogoImg">
                  <TennisOneIcon />
                </div>
                <span className="ButtonTextSecond">
                  {superwinText.item.games.tennisOne}
                </span>
              </div>
              <div className="MainSuperGamesLogo FlexRow">
                <div className="MainSuperGamesLogoImg">
                  <TennisTwoIcon />
                </div>
                <span className="ButtonTextSecond">
                  {superwinText.item.games.tennisTwo}
                </span>
              </div>
              <div className="MainSuperGamesLogo FlexRow">
                <div className="MainSuperGamesLogoImg">
                  <TennisOneFightIcon />
                </div>
                <span className="ButtonTextSecond">
                  {superwinText.item.games.tennisFightOne}
                </span>
              </div>
              <div className="MainSuperGamesLogo FlexRow">
                <div className="MainSuperGamesLogoImg">
                  <TennisTwoFightIcon />
                </div>
                <span className="ButtonTextSecond">
                  {superwinText.item.games.tennisFightTwo}
                </span>
              </div>
            </div>
            <div className="FlexRow Gap32">
              {haveVip && (
                <div className="PaddingTop16">
                  <SwitchComponents
                    label={superwinText.item.showVipLabel}
                    checked={showVip}
                    onChange={value => toggleVip(value)}
                  />
                </div>
              )}
              {!defaultOpen && showRise && (
                <div className="MainSuperButtonWrapper">
                  <ButtonGreen
                    onClick={() => {
                      saveFilterInLocalStorage(
                        { halls: [Number(hall)] },
                        SUPERWIN_FILTERS_MANAGE
                      )
                      localStorage.setItem(
                        activeTabKeyLs,
                        SuperwinReportTabs.MANAGE
                      )
                      history.push(SUPERWIN)
                    }}
                  >
                    <div className="MainSuperButtonLabel FlexRow">
                      <RiseIcon />
                      <span>{superwinText.item.increaseButton}</span>
                    </div>
                  </ButtonGreen>
                </div>
              )}
            </div>
          </div>
          <div className="MainSuperTablesWrapper FlexColumn">
            {isMobile ? (
              <SuperWinMobileTable data={dataMain} />
            ) : (
              <SuperWinTable data={dataMain} />
            )}
            {showVip &&
              haveVip &&
              (isMobile ? (
                <SuperWinMobileTable data={dataVip} isVip={true} />
              ) : (
                <SuperWinTable data={dataVip} isVip={true} />
              ))}
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default HallItem
