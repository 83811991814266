import { DetailDataState, DetailPeriod } from "./types"

export const initialDetailState = (hall: number): DetailDataState => ({
  data: {
    hall,
    period: 12,
    date: null,
    active: false,
    users: []
  },
  pending: false,
  trigger: {
    period: undefined
  }
})

export const periodOptions = [
  { label: `${DetailPeriod.half}`, value: DetailPeriod.half },
  { label: `${DetailPeriod.full}`, value: DetailPeriod.full }
]

export const statusByActive = {
  active: {
    text: `В рассылке`,
    color: `#40C057`,
    background: `#EBFBEE`
  },
  pause: {
    text: `Остановлена`,
    color: `#212529`,
    background: `#F1F3F5`
  }
}

export const detailText = {
  title: (hall: number) => `Клуб № ${hall}`,
  dateFormat: `Последняя рассылка DD.MM.YYYY в HH:mm`,
  period: {
    title: `Период суммирования при пополнении и выводе`,
    tooltip: `Выбрав значение 12 или 24 часа, оповещение поступит при однократном или суммарном достижении гостем указанного при настройках значения в типах операций "пополнение" и/или "вывод" в выбранном временном диапазоне.`,
    value: DetailPeriod
  },
  count: (count: number) => `Список рассылки · ${count} из 5`
}

export const detailTableText = {
  columns: {
    id: `id`,
    name: {
      label: `Имя сотрудника`,
      field: `name`
    },
    contacts: {
      label: `Контакты`,
      field: `contacts`
    },
    replenishment: {
      label: `Пополнение, ₽`,
      field: `replenishment`
    },
    withdrawal: {
      label: `Вывод, ₽`,
      field: `withdrawal`
    },
    win: {
      label: `Выигрыш, ₽`,
      field: `win`
    },
    date: {
      label: `Дата добавления (мск)`,
      field: `date`,
      dateFormat: `DD.MM.YYYY`,
      timeFormat: `HH:mm`
    },
    active: {
      label: `Статус`,
      field: `active`
    },
    actions: {
      label: `Действия`,
      field: `actions`,
      edit: `Редактировать`,
      delete: `Удалить`,
      pause: `Остановить рассылку`,
      play: `Возобновить рассылку`
    }
  },
  buttons: {
    add: `Добавить пользователя`,
    pause: `Остановить рассылку`,
    play: `Возобновить рассылку`,
    clear: `Очистить список`
  },
  countLimit: {
    value: 5,
    text: `Лимит на рассылку исчерпан`
  }
}
