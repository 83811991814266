import { CancelModalStatus } from "./types"

export const textByStatus = {
  [CancelModalStatus.check]: {
    title: `Проверяем статус заявки`,
    description: `Проверка может занять несколько секунд`,
    close: undefined,
    cancel: undefined,
    url: `v1/order/qiwi/status`
  },
  [CancelModalStatus.block]: {
    title: `Заявка уже одобрена`,
    description: `По вопросам обратитесь в финансовый отдел`,
    close: `Понятно`,
    cancel: undefined
  },
  [CancelModalStatus.cancel]: {
    title: `Отменить заявку?`,
    description: `Вы действительно хотите отменить заявку?`,
    close: `Закрыть`,
    cancel: `Да, отменить`,
    url: `v1/order/qiwi/cancel`
  }
}
