import React from "react"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PdfFile from "./sports_channels.pdf"

import { ReactComponent as InfoIcon } from "Components/icons/info_address.svg"

const text = {
  first: {
    middle: `1. Стоимость «Трансляция каналов семейства Матч» — `,
    middle_sum: `24 000 ₽`,
    end: ` в месяц `
  },
  second: {
    start: `2. `,
    important: `Важно!`,
    end: ` При утере или невозврате в офис карт, штраф — 1500 ₽ за каждую карту`
  },
  third: {
    start: `5. Список спортивных каналов по пакетам можно просмотреть `,
    link: `по ссылке`
  }
}

const SportsBroadcastInfo = ({ link }: { link?: string }) => {
  return (
    <div className="SportsBroadcast__InfoWrapper FlexRow Padding16 Blue10Background Gap4">
      <div className="SportsBroadcast__InfoWrapper__Icon">
        <InfoIcon />
      </div>
      <div className="SportsBroadcastInfoList FlexColumn">
        <span className="TextOne Blue600Color">
          {text.first.middle}
          <span className="TextOneBold">{text.first.middle_sum}</span>
          {text.first.end}
        </span>
        <span className="TextOne Blue600Color">
          {text.second.start}
          <span className="TextOneBold Orange600Color">
            {text.second.important}
          </span>
          {text.second.end}
        </span>
        <span className="TextOne Blue600Color">
          {text.third.start}
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            type="application/pdf"
            className="TextOneBold LinkText TextUnderline"
          >
            {text.third.link}
          </a>
        </span>
      </div>
    </div>
  )
}

export default SportsBroadcastInfo
