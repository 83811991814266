import React from "react"

import { ReactComponent as EmptyFilterIcon } from "./empty_filter.svg"

const NotifiEmptyTable = () => {
  return (
    <div className="NotifiEmptyTableWrapper">
      <EmptyFilterIcon />
      <div className="NotifiEmptyTableTitle">Ничего не найдено</div>
      <div className="NotifiEmptyTableText">
        По заданным фильтрам нет совпадений. Попробуйте их изменить и применить
        ещё раз
      </div>
    </div>
  )
}

export default NotifiEmptyTable
