import { FormInstance } from "antd/es/form/Form"

export type MailingModalData = {
  id?: number
  halls: number[]
  name?: string
  replenishment?: number | string
  withdrawal?: number | string
  win?: number | string
  phone?: string
  email?: string
}

export type MailingModalActiveFields = {
  replenishment: boolean
  withdrawal: boolean
  win: boolean
  phone: boolean
  email: boolean
}

export type GetInitialActiveFields = {
  data?: MailingModalData
  isNew: boolean
}

export type MailingModalProps = {
  close: () => void
  successRefresh?: () => void
  initial?: MailingModalData
}

export type MailingModalState = {
  data: MailingModalData
  pending: boolean
  request: boolean
}

export enum CheckboxDict {
  replenishment = `replenishment`,
  withdrawal = `withdrawal`,
  win = `win`,
  phone = `phone`,
  email = `email`
}

export type FieldCheckBoxProps = {
  form: FormInstance
  field: CheckboxDict
  isType: boolean
  active: MailingModalActiveFields
  setActive: (data: MailingModalActiveFields) => void
}
