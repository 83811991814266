export enum GuidebookStatuses {
  consideration = `consideration`,
  approved = `approved`,
  rejected = `rejected`
}

export enum GuidebookUploadTypes {
  photo = `photo`,
  video = `video`
}

export type GuidebookCommentsData = {
  text: string
  status: GuidebookStatuses
  position: {
    top: number
    left: number
  }
  date?: string
  title?: string
  index?: number
  id?: number
}

export type GuidebookAttachments = {
  id: number
  status: GuidebookStatuses
  link: string
  preview: string
  comments: GuidebookCommentsData[]
  created: string
  old?: string
}

export type GuidebookCardInfo = {
  id: number
  status: GuidebookStatuses
  title: string
  description: string
  attachmentsDescription: string
  required: boolean
  updatedDate?: string
  uploadType: GuidebookUploadTypes
  example: {
    link: string
  }
  maxCount: number
  minCount: number
  attachments: GuidebookAttachments[]
}

export type GuidebookPageData = {
  info: GuidebookCardInfo[]
  status: GuidebookStatuses | null
  date: string | null
}

export type GuidebookPageState = {
  data: GuidebookPageData
  pending: boolean
}

export interface ModalData {
  visible?: boolean
  link?: string
  rootTitle?: string
  index?: number
  status?: string
  uploadDate?: string
  comments?: [
    {
      commentPosition?: {
        top: number
        left: number
      }
      position?: {
        top: number
        left: number
      }
      commentDate?: string
      commentText?: string
      text?: string
      date?: string
      id?: number
      commentId?: number
      sectionId?: number
    }
  ]
  commentPosition?: {
    top: number
    left: number
  }
  commentDate?: string
  commentText?: string
  attachId?: number
  loading?: boolean
  isVideo?: boolean
  uplStatus?: GuidebookStatuses
  isSingle?: boolean
  old?: string
  exampleText?: string
  isExample?: boolean
}
