import { FilterRangeFields } from "../types"

export const customFilterText = {
  label: {
    bets: `По ставкам`,
    cashbox: `По кассе`,
    currentBalance: `По текущему балансу`,
    transferBalance: `Перенос баланса`
  },
  sportboom_tooltip: `Возможно выбрать только игры или только SportBoom. При установке чекбокса SportBoom, чекбоксы с игр автоматически удалятся, и наоборот.`,
  fieldLabels: {
    [FilterRangeFields.bets_sum]: `Сумма ставок`,
    [FilterRangeFields.win_sum]: `Сумма выигрышей`,
    [FilterRangeFields.bonus_bets_sum]: `Сумма ставок за бонусы`,
    sportboom: `Сумма ставок за фрибеты`,
    [FilterRangeFields.cash_in]: `Ввод наличными`,
    [FilterRangeFields.noncash_in]: `Ввод безналичными`,
    [FilterRangeFields.cash_out]: `Вывод наличными`,
    [FilterRangeFields.noncash_out]: `Вывод безналичными`,
    [FilterRangeFields.bonus_balance]: `Бонусный баланс`,
    [FilterRangeFields.money_balance]: `Денежный баланс`,
    [FilterRangeFields.transfer_to]: `Перенос в клуб`,
    [FilterRangeFields.transfer_from]: `Перенос из клуба`
  },
  sportboom: `Sportboom`,
  games: `Играл в`,
  errorRange: `Значение От не должно превышать значение До`
}

export const rangeText = {
  from: `от`,
  to: `до`
}
