import React from "react"

import ButtonDefault from "../Button"

import { ReactComponent as ListIcon } from "../icons/list.svg"
import { ReactComponent as StatisticIcon } from "../icons/statistics.svg"

import { DateSwitcherProps } from "./types"
import { dataSwitcherText } from "./constants"

const DataSwitcher = ({
  activeContent,
  toggleActiveContent
}: DateSwitcherProps) => {
  return (
    <div className="DataSwitcherWrapper">
      <div className="DataSwitcherTitle">{dataSwitcherText.title}</div>
      <ButtonDefault
        className={`DataSwitcherButton DataSwitcherTable ${
          !activeContent ? `DataSwitcherButtonDisabled` : ``
        }`}
        onClick={() => {
          if (!activeContent) toggleActiveContent(true)
        }}
        style={{ marginRight: `8px` }}
      >
        <ListIcon />
      </ButtonDefault>
      <ButtonDefault
        className={`DataSwitcherButton DataSwitcherStatistic ${
          activeContent ? `DataSwitcherButtonDisabled` : ``
        }`}
        onClick={() => {
          if (activeContent) toggleActiveContent(false)
        }}
      >
        <StatisticIcon />
      </ButtonDefault>
    </div>
  )
}

export default DataSwitcher
