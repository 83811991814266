import { SuperwinReportTabs } from "./types"
export const EXEL_URL = `v1/superwins/history/excel`
export const EXEL_WINS_URL = `v1/superwins/history/wins/excel`

export const tabsData = [
  {
    label: `Управление`,
    value: SuperwinReportTabs.MANAGE,
    disabled: false
  },
  {
    label: `История изменений`,
    value: SuperwinReportTabs.HISTORY,
    disabled: false
  },
  {
    label: `История выигрышей`,
    value: SuperwinReportTabs.WINS_HISTORY,
    disabled: false
  }
]

export const activeTabKeyLs = "superWinsActiveTab"
export const mergeHistoryKeyLs = "mergeHistoryKey"
