/* eslint react/prop-types: "off" */
import React, { memo } from "react"
import { Radio } from "antd"
import { RadioProps, RadioGroupProps } from "antd/lib"

interface IRadioComponent extends RadioProps {
  disabled?: boolean
  children?: any
  rest?: any
}

interface RadioGroupComponentProps extends RadioGroupProps {
  error?: boolean | string
  options: {
    label: string
    value: number | string
    customLabel?: JSX.Element
    disabled?: boolean
  }[]
  className?: string
}

export const MemoRadioComponent = ({
  disabled,
  children,
  ...rest
}: IRadioComponent) => {
  return (
    <div className="RadioWrapper">
      <Radio disabled={disabled} {...rest}>
        {children}
      </Radio>
    </div>
  )
}

export const RadioComponent = memo(MemoRadioComponent)

export const RadioGroupComponent = (props: RadioGroupComponentProps) => {
  const { onChange, value, options, className, error } = props

  return (
    <div
      className={error ? `ErrorInputWrapper` : ``}
      style={{ marginBottom: `-8px` }}
    >
      <Radio.Group onChange={onChange} value={value} className={className}>
        {options.map(item => (
          <div key={`${item.label}_${item.value}`} className="RadioGroupOption">
            <MemoRadioComponent value={item.value} disabled={item.disabled}>
              {item.customLabel || item.label}
            </MemoRadioComponent>
          </div>
        ))}
      </Radio.Group>
      {!!error && <div className="ErrorText">{error}</div>}
    </div>
  )
}
