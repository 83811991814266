import React from "react"

import TooltipComponent from "Components/Tooltip"

import { ReactComponent as SearchIcon } from "Components/icons/help_16.svg"

import { AccessFiltersData, AccessStatuses } from "../types"

import { customFiltersConstants } from "./constants"
import { RadioGroupComponent } from "Components/Radio"
import { StatusNames } from "../constants"

const AccessStatusFilter = ({
  filter,
  setFilter
}: {
  filter: AccessFiltersData
  setFilter: (data: AccessFiltersData) => void
}) => {
  const options = Object.keys(AccessStatuses).map(item => {
    const status = item as AccessStatuses
    return {
      value: item,
      label: StatusNames[status]
    }
  })
  return (
    <div className="FlexColumn Gap8">
      <div className="FlexRow Gap4">
        <span className="SubtitleFirstText">
          {customFiltersConstants.status.label}
        </span>
        <TooltipComponent
          title={
            <div className="FlexColumn Gap8">
              <span className="TextOne ColorWhite100">
                {customFiltersConstants.status.tooltip.first}
              </span>
              <span className="TextOne ColorWhite100">
                {customFiltersConstants.status.tooltip.second}
              </span>
              <span className="TextOne ColorWhite100">
                {customFiltersConstants.status.tooltip.third}
              </span>
            </div>
          }
        >
          <div className="MarginTop4">
            <SearchIcon />
          </div>
        </TooltipComponent>
      </div>
      <RadioGroupComponent
        options={[
          { value: 0, label: customFiltersConstants.status.any },
          ...options
        ]}
        value={filter.status}
        onChange={({ target: { value } }) =>
          setFilter({ ...filter, status: value })
        }
      />
    </div>
  )
}

export default AccessStatusFilter
