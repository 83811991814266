import { DetailData, DetailState } from "./types"
import { valueToCurrency } from "constants/index"
import { sportboomMainText, SportBoomTabs } from "../../constants"

export const initialDetail: DetailState = {
  pending: false,
  data: {
    all_bets_count: 0,
    all_freebets_count: 0,
    bets_count: 0,
    freebets_count: 0,
    ggr: `0.00`,
    guest_count: 0,
    retention: `0.00`,
    win: 0,
    win_bets_sum: 0,
    bets_sum: 0
  }
}

export const detailText = {
  title: `Показатели за период`,
  guest_count: `Кол-во гостей`,
  guest_count_tooltip: `Гостями считаются только те, кто совершал ставки на спорт как с денежного, так и с фрибетных балансов в выбранный период.`,
  win: `WIN`,
  ggr: `GGR`,
  ggr_tooltip: `GGR или Gross Gaming Revenue (Валовой игровой доход) - игровая прибыль. Рассчитывается по формуле: GGR = IN – OUT, где IN – сумма всех ставок, OUT – сумма всех выплат по ставкам.`,
  retention: `Удержание`,
  retention_tooltip: `Удержание - маржинальность спортивных ставок. Показывает, какую долю составляет GGR в объеме всех ставок в клубе. Показатель выражается в процентах.`,
  bets_count: `Кол-во ставок денежный`,
  freebets_count: `Кол-во ставок фрибеты`,
  all_bets_count: `Объем ставок денежный`,
  all_freebets_count: `Объем ставок фрибеты`
}

export const detailConfigByData = (
  data: DetailData,
  openModal: (val: boolean) => void
) => {
  const ggr = valueToCurrency({ value: Number(data.ggr), withoutSpace: true })
  const win = valueToCurrency({ value: Number(data.win), withoutSpace: true })
  const all_bets_count = valueToCurrency({
    value: Number(data.all_bets_count),
    withoutSpace: true
  })
  const freebets = valueToCurrency({
    value: Number(data.all_freebets_count),
    withoutFixed: true
  })
  return {
    firstRow: [
      {
        label: detailText.guest_count,
        value: data.guest_count,
        tooltip: detailText.guest_count_tooltip
      },
      {
        label: detailText.win,
        value: `${win} ₽`
      },
      {
        label: detailText.bets_count,
        value: data.bets_count
      },
      {
        label: detailText.freebets_count,
        value: data.freebets_count
      }
    ],
    lastRow: [
      {
        label: detailText.ggr,
        tooltip: detailText.ggr_tooltip,
        value: `${ggr} ₽`
      },
      {
        label: detailText.retention,
        tooltip: detailText.retention_tooltip,
        value: `${data.retention}%`
      },
      {
        label: detailText.all_bets_count,
        value: `${all_bets_count} ₽`,
        openPopup: () => openModal(true)
      },
      {
        label: detailText.all_freebets_count,
        value: `${freebets} Ф`
      }
    ]
  }
}

export const getDetailPopupText = (data: DetailData) => {
  const winBetsSumValue = valueToCurrency({ value: data.win_bets_sum })
  const betsSumValue = valueToCurrency({ value: data.bets_sum })
  return {
    title: `Объем денежных ставок`,
    win_bets_sum: `Сумма сыгравших ставок`,
    bets_sum: `Сумма ставок в игре`,
    close: `Понятно`,
    winBetsSumValue,
    betsSumValue
  }
}
export const detailTooltipText = {
  title: (tab: SportBoomTabs) =>
    `Список ${sportboomMainText.tabs[tab].label.toLowerCase()}`,
  placeTitle: (place: number) => `${place} место`
}
