export const addEditModalText = {
  newTitle: "Добавить штраф",
  editTitle: "Редактировать штраф",
  cancelTitle: "Отменить штраф",
  rejectTitle: "Отклонить запрос",
  back: "Вернуться к штрафу",
  hallNumber: "Номер клуба",
  hallNumberPlaceholder: "Введите номер клуба",
  date: "Дата нарушения",
  datePlaceHolder: "Выберите дату",
  reason: "Причина штрафа",
  reasonTooltip: "Не выбрана",
  amount: "Сумма штрафа, ₽",
  amountPlaceholder: "Укажите сумму",
  win: "Повышение WIN клуба",
  winPlaceholder: "Введите WIN",
  comment: "Комментарий",
  justification: "Обоснование",
  commentPlaceholder: "Введите сообщение для клуба",
  attachments: "Подтверждающие документы",
  attachmentsTooltip:
    "Прикрепите до 5 файлов png, jpeg, jpg, pdf не более 50 Мб",
  fileUploadCountError: "Возможно загрузить не более 5 файлов",
  fileSizeValidationError: "Общий размер файлов не должен преывашть 50мб",
  sendButton: "Отправить",
  addButton: "Добавить",
  newElement: "Новый элемент",
  inputError: "Необходимо заполнить поле",
  warning: "Предупреждение",
  actions: "Принимаемая мера в отношении клуба",
  check: "Необходимо принять правила",
  cancelButton: "Отмена"
}
