import React, { useCallback, useState } from "react"
import Title from "Components/Title"
import { nominalsText } from "./constants"
import TabsComponent from "Components/Tabs"
import { tabsConfig, tabsIds } from "./constants"
import { ChangeTab, HistoryTab } from "./Components"

export const Nominals = () => {
  const [activeTab, setActiveTab] = useState(tabsIds[nominalsText.changeTab])
  const handleToggleActiveTab = useCallback(tab => {
    setActiveTab(tab)
  }, [])

  return (
    <div className="MainPageContentWrapper MainPageContentWrapper_nominals">
      <div className="MainPageContentRestrictions">
        <Title
          titleText={nominalsText.title}
          tooltipText={nominalsText.tooltip}
        />
        <TabsComponent
          tabs={tabsConfig}
          activeTab={activeTab}
          toggleActiveTab={handleToggleActiveTab}
        />
        <div className="NominalsContentWrapper">
          {activeTab === tabsIds[nominalsText.changeTab] && <ChangeTab />}
          {activeTab === tabsIds[nominalsText.historyTab] && <HistoryTab />}
        </div>
      </div>
    </div>
  )
}
