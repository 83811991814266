import { useSelector } from "react-redux"

import { useRequest } from "api/hooks"

import { textConstants } from "./constants"

import { getCurrentHall } from "store/halls/selector"

export interface UseGuidebookApi {
  addCommentData?: {
    text?: string
    attachment?: number
    position: {
      top: number
      left: number
    }
  }
  editCommentData?: {
    text?: string
    position?: {
      top: number
      left: number
    }
  }
  sectionData?: {
    section: number
  }
  status?: string
  commentId?: number
}

const useGuidebookApi = ({
  addCommentData,
  status,
  commentId,
  editCommentData
}: UseGuidebookApi) => {
  const hallState = useSelector(getCurrentHall)
  const hallLs = JSON.parse(localStorage.getItem("hall") || "")
  const currentHall = hallState?.length > 0 ? hallState : hallLs
  const { request: getInitialData } = useRequest({
    url: `${textConstants.url}/${currentHall}`,
    method: `GET`
  })

  const { request: sendOnApprove } = useRequest({
    url: `${textConstants.url}/${currentHall}`,
    method: `POST`
  })

  const { request: addComment } = useRequest({
    url: `${textConstants.url}/${currentHall}/comments`,
    method: `POST`,
    requestBody: {
      text: addCommentData?.text,
      attachment: addCommentData?.attachment,
      position: {
        top: addCommentData?.position.top,
        left: addCommentData?.position.left
      }
    }
  })

  const { request: deleteComment } = useRequest({
    url: `${textConstants.url}/${currentHall}/comments/${String(commentId)}`,
    method: `DELETE`
  })

  const { request: editComment } = useRequest({
    url: `${textConstants.url}/${currentHall}/comments/${String(commentId)}`,
    method: `PUT`,
    requestBody: {
      text: editCommentData?.text,
      attachment: addCommentData?.attachment,
      position: {
        top: editCommentData?.position?.top,
        left: editCommentData?.position?.left
      }
    }
  })

  const { request: getArchive } = useRequest({
    url: `${textConstants.url}/${currentHall}/zip`,
    method: `GET`
  })

  const { request: changeStatus } = useRequest({
    url: `${textConstants.url}/${currentHall}/status`,
    method: `POST`,
    requestBody: {
      attachment: addCommentData?.attachment,
      status: status
    }
  })

  return {
    getInitialData,
    sendOnApprove,
    addComment,
    changeStatus,
    getArchive,
    deleteComment,
    editComment
  }
}

export default useGuidebookApi
