import React, { useEffect, useState } from "react"

import { FromToFilterProps, FromToFilterState } from "./types"
import { fromToConstants } from "./constants"

import { SortOrders } from "Components/TableNew/types"

import { ButtonFaded, ButtonPrimary } from "Components/Button"
import InputComponent, { InputNumberClear } from "Components/Input"
import { SliderRangeComponent } from "Components/Slider"
import { getErrorText, validateInput } from "Components/Input/helpers"

import SortTableFilter from "../SortFilter"
import { InputType } from "Components/Input/types"

export const FromToFilter = ({
  from,
  to,
  min,
  max,
  label,
  currentSort,
  defaultSort,
  currentFilter,
  setter,
  fieldName,
  closeFunc,
  suffix,
  isFloatNumber
}: FromToFilterProps) => {
  /* Является ли текущая сортировка в таблице сортировкой по данному полю */
  const isSortedByCurrentField = currentSort?.field === fieldName

  /* Является ли текущая фильтрация в таблице фильтрацией по данному полю */

  const isFilteredByCurrentField =
    (currentFilter?.field === fieldName &&
      !!currentFilter?.from &&
      currentFilter?.from !== min) ||
    (!!currentFilter?.to && currentFilter?.to !== max)

  const checkTrueOrZero = (val?: number | string) =>
    !!val || val === 0 || val === `0`

  const initState: FromToFilterState = {
    from: checkTrueOrZero(from) ? from : undefined,
    to: checkTrueOrZero(to) ? to : undefined,
    order: isSortedByCurrentField ? currentSort?.order : SortOrders.asc,
    filerField: currentFilter?.field,
    fromIsValid: true,
    toIsValid: true,
    sortField: currentSort?.field,
    hasSort: isSortedByCurrentField
  }

  const [state, setState] = useState<FromToFilterState>(initState)

  useEffect(() => {
    if (
      (!state.fromIsValid || !state.toIsValid) &&
      !getErrorText(
        Number(state.from),
        Number(state.to),
        min,
        max,
        InputType.min
      ) &&
      !getErrorText(
        Number(state.to),
        Number(state.from),
        min,
        max,
        InputType.max
      )
    ) {
      setState(prev => ({ ...prev, fromIsValid: true, toIsValid: true }))
    }
  }, [state.fromIsValid, state.toIsValid])
  const disabledApply =
    !state.hasSort &&
    ((!state.from && state.from !== 0) || (!state.to && state.to !== 0))
  return (
    <div>
      <SortTableFilter
        order={state.order}
        changeOrder={value => setState({ ...state, order: value || undefined })}
        hasSort={state.hasSort}
        changeHasSort={value =>
          setState({
            ...state,
            hasSort: value,
            sortField: value ? fieldName : defaultSort?.field
          })
        }
      />
      <div className={"TableHeaderModalSubTitle"}>
        {label || fromToConstants.applyText}
      </div>
      <div className={"TableHeaderModal_inputInner"}>
        {isFloatNumber ? (
          <InputNumberClear
            value={checkTrueOrZero(state.from) ? state.from : undefined}
            placeholder={!min ? `От` : ``}
            onChange={value => {
              const minMaxValidate =
                min !== undefined && !!max
                  ? validateInput(`${value}`, min, max, InputType.min)
                  : true
              if (minMaxValidate) {
                setState({
                  ...state,
                  from: value ? Number(value) : undefined,
                  filerField: fieldName
                })
              }
            }}
            suffix={suffix}
          />
        ) : (
          <InputComponent
            value={checkTrueOrZero(state.from) ? state.from : undefined}
            placeholder={!min ? `От` : ``}
            type="number"
            hideControls
            constantErrorHeight={35}
            onChange={({ target: { value } }) => {
              const stringValue = value !== null ? String(value) : ""
              const validation =
                min !== undefined && !!max
                  ? validateInput(
                      stringValue,
                      min,
                      max,
                      InputType.min,
                      state.to ? String(state.to) : ""
                    )
                  : { isValid: true, isPotential: true }

              setState({
                ...state,
                from: Number(value),
                filerField: fieldName,
                fromIsValid: validation.isValid
              })
            }}
            error={
              state.fromIsValid
                ? undefined
                : getErrorText(
                    Number(state.from),
                    Number(state.to),
                    min,
                    max,
                    InputType.min
                  )
            }
            suffix={suffix}
            greyBackground
          />
        )}
        {isFloatNumber ? (
          <InputNumberClear
            value={checkTrueOrZero(state.to) ? state.to : undefined}
            placeholder={!max ? `До` : ``}
            onChange={value => {
              const minMaxValidate =
                min !== undefined && !!max
                  ? validateInput(`${value}`, min, max, InputType.max)
                  : true
              if (minMaxValidate) {
                setState({
                  ...state,
                  to: !!value || value === `0` ? Number(value) : undefined,
                  filerField: fieldName
                })
              }
            }}
            suffix={suffix}
          />
        ) : (
          <InputComponent
            value={checkTrueOrZero(state.to) ? state.to : undefined}
            placeholder={!max ? `До` : ``}
            type="number"
            hideControls
            constantErrorHeight={35}
            onChange={({ target: { value } }) => {
              const stringValue = value !== null ? String(value) : ""
              const validation =
                min !== undefined && !!max
                  ? validateInput(
                      stringValue,
                      min,
                      max,
                      InputType.max,
                      state.from ? String(state.from) : ""
                    )
                  : { isValid: true, isPotential: true }

              setState({
                ...state,
                to: Number(value),
                filerField: fieldName,
                toIsValid: validation.isValid
              })
            }}
            error={
              state.toIsValid
                ? undefined
                : getErrorText(
                    Number(state.to),
                    Number(state.from),
                    min,
                    max,
                    InputType.max
                  )
            }
            suffix={suffix}
            greyBackground
          />
        )}
      </div>
      {min !== undefined && !!max && (
        <SliderRangeComponent
          range={{ draggableTrack: true }}
          min={min}
          max={max}
          value={[Number(state.from), Number(state.to)]}
          onChange={([from, to]) => {
            const validationFrom = validateInput(
              String(from),
              min,
              max,
              InputType.min,
              state.to ? String(state.to) : ""
            )
            const validationTo = validateInput(
              String(to),
              min,
              max,
              InputType.max,
              state.from ? String(state.from) : ""
            )
            setState({
              ...state,
              from: from,
              to: to,
              filerField: fieldName,
              fromIsValid: validationFrom.isValid,
              toIsValid: validationTo.isValid
            })
          }}
        />
      )}
      <div className="FilterButtonsWrapper">
        {(isFilteredByCurrentField || isSortedByCurrentField) && (
          <ButtonFaded
            onClick={() => {
              const sortData = isSortedByCurrentField
                ? {
                    sortField: undefined,
                    filerField: undefined
                  }
                : {
                    sortField: currentSort.field,
                    filerField: currentSort.order
                  }
              setter({
                ...sortData,
                from: undefined,
                to: undefined,
                order: undefined
              })

              !!closeFunc && closeFunc()
            }}
          >
            {fromToConstants.clearText}
          </ButtonFaded>
        )}
        <ButtonPrimary
          onClick={() => {
            const canApply = isFloatNumber
              ? true
              : state.fromIsValid && state.toIsValid
            if (canApply) {
              const applyFrom = checkTrueOrZero(state.from)
                ? Number(state.from)
                : undefined
              const applyTo = checkTrueOrZero(state.to)
                ? Number(state.to)
                : undefined

              setter({
                from: applyFrom,
                to: applyTo,
                sortField: state.hasSort ? state.sortField : currentSort.field,
                filerField: fieldName,
                order: state.hasSort ? state.order : currentSort.order
              })

              !!closeFunc && closeFunc()
            }
          }}
          disabled={!state.fromIsValid || !state.toIsValid || disabledApply}
        >
          {fromToConstants.applyShortText}
        </ButtonPrimary>
      </div>
    </div>
  )
}
