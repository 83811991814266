import betRequest from "api/request"
import RequestParamsProps from "constants/type"
import { MessageDataType } from "./types"
import { MessageStoreState } from "store/message/types"

interface GetCurrentUserMessageProps extends RequestParamsProps {
  id: number
  setMessage: (data: { data: MessageDataType; pending: boolean }) => void
  message: { data: MessageDataType; pending: boolean }
  setStoreCount: (value: MessageStoreState) => void
}

const getCurrentUserMessage = ({
  id,
  developer,
  addDeveloperLink,
  logout,
  message,
  setMessage,
  history,
  setStoreCount
}: GetCurrentUserMessageProps) => {
  setMessage({ ...message, pending: true })
  betRequest({
    developer,
    addDeveloperLink,
    url: `message/${id}`,
    logout,
    history,
    method: `GET`
  }).then(({ data: { result }, headers }) => {
    setMessage({ data: result, pending: false })
    const unread = headers[`unread-count`]
    if (!!unread || unread === 0)
      setStoreCount({
        message_count: headers[`unread-count`],
        emergency: [],
        emergency_open: false
      })
  })
}

export default getCurrentUserMessage
