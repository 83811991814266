import React, { useCallback } from "react"
import { Breadcrumb } from "antd"
import { BreadcrumbsProps, BreadcrumbItem } from "./types"

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  onBreadcrumbClick
}) => {
  const handleBreadcrumbClick = useCallback(
    (value: BreadcrumbItem) => () => {
      onBreadcrumbClick && onBreadcrumbClick(value)
    },
    [onBreadcrumbClick]
  )

  return (
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, index) => (
        <Breadcrumb.Item
          key={index}
          onClick={handleBreadcrumbClick(breadcrumb)}
          href={breadcrumb.isLink ? breadcrumb.url : undefined}
        >
          {breadcrumb.icon ? breadcrumb.icon : breadcrumb.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default Breadcrumbs
