import React, { useEffect, useMemo, useState } from "react"
import { UserPenaltyCardProps } from "./types"
import { userPenaltyCardText, formatDayMonthYear } from "./helper"
import { ReactComponent as AttachIcon } from "Components/icons/attach_grey.svg"
import { ReactComponent as MessageIcon } from "Components/icons/message.svg"
import { StatusLabel } from "../helper"
import { DictElements } from "../types"
import { PenaltyStatuses } from "../../helpers"
import { moneyRender } from "constants/index"

export const UserPenaltyCard: React.FC<UserPenaltyCardProps> = ({
  penalty,
  statuses,
  setViewModalData,
  winDict
}) => {
  const [finalPenaltyValue, setFinalPenaltyValue] = useState("")
  const [prevValue, setPrevValue] = useState("")
  const [lineThroughState, setLineThroughState] = useState(false)

  useEffect(() => {
    let final = penalty?.penaltyValue
    let result
    let resultPrev = ""
    let showPrev = false
    let lineThrough = false
    if (penalty?.status === PenaltyStatuses.reviewed) {
      final = penalty?.penaltyValueResolved
      if (
        penalty.penaltyValue !== penalty?.penaltyValueResolved &&
        penalty.penaltyValue !== null &&
        penalty?.penaltyValue !== 0
      ) {
        showPrev = true
      }
    }

    if (penalty?.status === PenaltyStatuses.cancel && final) {
      lineThrough = true
    }

    if (showPrev) {
      resultPrev = `${moneyRender(penalty.penaltyValue)} ${
        userPenaltyCardText.rubles
      }`
    }

    if (!final) {
      result = userPenaltyCardText.warning
    } else {
      result = `${moneyRender(final)} ${userPenaltyCardText.rubles}`
    }

    setFinalPenaltyValue(result)
    if (showPrev) {
      setPrevValue(resultPrev)
    }
    setLineThroughState(lineThrough)
  }, [penalty])

  const getWinText = (
    winDict: DictElements | [],
    win: number | null,
    withWinExistText?: boolean
  ) => {
    if (win !== null) {
      const value = winDict.find(x => x.id === win)?.value
      if (value) {
        return withWinExistText
          ? `${userPenaltyCardText.winExist} ${value}`
          : value
      } else {
        return withWinExistText ? `${userPenaltyCardText.winExist} ${win}` : win
      }
    } else {
      return userPenaltyCardText.winNull
    }
  }

  const winText = useMemo(() => {
    if (
      penalty?.status === PenaltyStatuses.reviewed &&
      penalty?.win !== penalty?.winResolved
    ) {
      if (penalty?.win === null && penalty?.winResolved !== null) {
        return (
          <div className="PenaltyCardWinWinResolvedColumn">
            <div className="TextDefaultBoldWinResolved">
              {getWinText(winDict, penalty?.win, true)}
            </div>
            <div>{getWinText(winDict, penalty?.winResolved, true)}</div>
          </div>
        )
      } else if (penalty?.win !== null && penalty?.winResolved !== null) {
        return (
          <div className="PenaltyCardWinWinResolved">
            {userPenaltyCardText.winExist}
            <div className="TextDefaultBoldWinResolved">
              {getWinText(winDict, penalty?.win)}
            </div>
            <div>{getWinText(winDict, penalty?.winResolved)}</div>
          </div>
        )
      } else if (penalty?.win !== null && penalty?.winResolved === null) {
        return (
          <div>
            <div className="TextDefaultBoldWinResolved">
              {userPenaltyCardText.winExist} {getWinText(winDict, penalty?.win)}
            </div>
            <div>{getWinText(winDict, penalty?.winResolved)}</div>
          </div>
        )
      }
    } else if (penalty?.status === PenaltyStatuses.cancel) {
      if (penalty?.win === null) {
        return getWinText(winDict, penalty?.win, true)
      } else {
        return (
          <div className="TextDefaultBoldWinResolved">
            {getWinText(winDict, penalty?.win, true)}
          </div>
        )
      }
    } else {
      return getWinText(winDict, penalty?.win, true)
    }
  }, [penalty, winDict])

  const setDateFormatted = useMemo(() => {
    return formatDayMonthYear(penalty.setDate)
  }, [penalty.findDate, penalty.setDate, penalty.fixDate, penalty.viewDate])

  return (
    <div
      className="PenaltyUserCard"
      key={penalty.id}
      onClick={() => setViewModalData(penalty)}
    >
      <div className="PenaltyUserCardHeaderWrapper">
        <div className="PenaltyUserCardHeaderHall BodySecondText">{`${userPenaltyCardText.hallNumber} ${penalty.hall}`}</div>
        <div className="PenaltyUserCardHeaderSetDate">{`${userPenaltyCardText.set} ${setDateFormatted}`}</div>
      </div>

      <div className="PenaltyUserCardPenaltyWrapper">
        {!!prevValue && (
          <div className="PenaltyUserCardPenalty HeadlineSecondText PenaltyUserCardPenaltyResolvedFirst">
            {prevValue}
          </div>
        )}
        <div
          className={`PenaltyUserCardPenaltyResolved HeadlineSecondText ${
            lineThroughState ? "PenaltyUserCardPenaltyResolvedFirst" : ""
          }`}
        >
          {finalPenaltyValue}
        </div>
      </div>

      <div className="PenaltyUserCardWinWrapper">
        <div className="TextDefaultBold">{winText}</div>
      </div>

      <div className="PenaltyUserCardWinReason TextDefault">
        {penalty.reason}
      </div>

      <div className="PenaltyUserCardFooter">
        <div className="PenaltyUserCardStatus">
          <StatusLabel statuses={statuses} id={penalty.status} />
        </div>

        <div className="PenaltyUserCardComment">
          <MessageIcon />
          {penalty.reasonComment && 1}
        </div>

        {!!penalty.attachments.length && (
          <div className="PenaltyUserCardAttach">
            <AttachIcon />
            {penalty.attachments.length}
          </div>
        )}
      </div>
    </div>
  )
}
