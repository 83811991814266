import { UseRequestBodySort } from "api/hooks"

import { SideFilterDates } from "Components/SideFilter/types"

export type MailingHistoryFilterData = {
  dates?: SideFilterDates
  halls: number[]
}

export type MailingHistoryFilter = {
  isOpen: boolean
  isActive: boolean
  data: MailingHistoryFilterData
  mobileFilter: boolean
}
export enum HistoryTypeDict {
  replenishment = `replenishment`,
  withdrawal = `withdrawal`,
  win = `win`
}

export type MailingHistoryData = {
  date: string
  hall: number
  type: HistoryTypeDict
  sum: number
  phone: string
}

export interface MailingHistoryState {
  pending: boolean
  data: MailingHistoryData[]
  total: number
}

export type GetColumnsProps = {
  sort: UseRequestBodySort
}
