import React, { useState } from "react"
import FilterComponentType, { NoncashFilterData } from "./types"
import { saveFilterInLocalStorage } from "api/helpers"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"
import InfoPanelComponent from "Components/FilterInfoPanel"
import { ButtonPrimary } from "Components/Button"

import { useRequestParams } from "api/hooks"
import { CheckboxValueType } from "antd/es/checkbox/Group"

const NoncashFilter = ({
  storage,
  filterState,
  setFilterState,
  resetPagination
}: FilterComponentType) => {
  const { current_hall } = useRequestParams()
  const [filter, setFilter] = useState<NoncashFilterData>(filterState.data)

  const mobileFilterOpen = window.innerWidth < 921

  return (
    <div
      className={
        filterState.mobileFilter && mobileFilterOpen
          ? `MainFilterMobile`
          : `CashboxFilterComponentWrapper`
      }
    >
      <div
        className="MainFilterMobileClose"
        onClick={() => {
          setFilterState({
            ...filterState,
            mobileFilter: false
          })
        }}
      >
        <CloseIcon />
      </div>
      <div className="MainFilterHeader">Фильтры</div>
      <div className="MainFilterContent">
        <InfoPanelComponent<NoncashFilterData>
          withoutPickDateType={true}
          filterHalls={filter.halls}
          filter={filter}
          setFilter={setFilter}
          current_hall={current_hall as CheckboxValueType[]}
        />
      </div>
      <div className="MainFilterApplyWrapper">
        <ButtonPrimary
          disabled={!filter.dates || !filter.halls.length}
          onClick={() => {
            setFilterState({
              ...filterState,
              data: filter,
              isActive: true,
              mobileFilter: false
            })
            resetPagination()
            saveFilterInLocalStorage(filter, storage)
          }}
        >
          Применить
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default NoncashFilter
