import { IRootState } from "store/index"
import {
  IMenuData,
  MenuOptionsType,
  MenuMainType,
  IMenuState
} from "store/menu/types"

export const getMenuData = (state: IRootState): IMenuData => state.menu.data
export const getMenu = (state: IRootState): IMenuState => state.menu
export const getMenuMain = (state: IRootState): MenuMainType[] =>
  state.menu.data.main
export const getMenuBottom = (state: IRootState): MenuMainType[] =>
  state.menu.data.bottom
export const getMenuOptions = (state: IRootState): MenuOptionsType[] =>
  state.menu.data.options
export const getMenuRoutes = (state: IRootState): (string | null)[] =>
  state.menu.routes
