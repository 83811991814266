import React, { useMemo } from "react"

import ModalComponent from "Components/Modal"
import Input, { InputMaskComponent } from "Components/Input"
import { ButtonPrimary } from "Components/Button"
import { TemplateCard } from "./TemplateCard"

import { ReactComponent as CloseIcon } from "Components/icons/close.svg"
import { ReactComponent as SuccessIcon } from "Components/icons/ok_desktop.svg"

import {
  NominalSectionKeys,
  RequestModalProps,
  NominalsNewModal
} from "../types"
import { nominalsText } from "../constants"

interface SortByTypeParams {
  data?: NominalsNewModal
  type: NominalSectionKeys
}

export const RequestModal: React.FC<RequestModalProps> = ({
  onClose,
  onCloseSuccess,
  isMinMax,
  nominalsData,
  showSuccessImg,
  loading,
  onPhoneInputChange,
  onNameInputChange,
  disabled,
  onChangeSend,
  hideVipTemplate,
  userName
}) => {
  const sortNominalData = useMemo(() => {
    const sortByType = ({ data, type }: SortByTypeParams) => {
      if (!!data && data[type].limits) {
        const mainMin = data[type].limits?.min.sort(
          (a, b) => a.number_count - b.number_count
        )
        const mainMax = data[type].limits?.max.sort(
          (a, b) => a.number_count - b.number_count
        )
        return {
          ...data[type],
          limits: { ...data[type].limits, min: mainMin, max: mainMax }
        }
      } else if (!!data && data[type].max_limit) {
        return data[type]
      }
      return []
    }

    const sortMain = sortByType({
      data: nominalsData,
      type: NominalSectionKeys.main
    })
    const sortVip = sortByType({
      data: nominalsData,
      type: NominalSectionKeys.vip
    })

    return {
      ...nominalsData,
      [NominalSectionKeys.main]: sortMain,
      [NominalSectionKeys.vip]: sortVip
    }
  }, [nominalsData]) as NominalsNewModal

  return (
    <div>
      <ModalComponent
        close={showSuccessImg ? undefined : onClose}
        closeIcon={<CloseIcon />}
        className="NominalsModal"
      >
        <div className="NominalsModalContainer">
          {showSuccessImg ? (
            <div className="NominalsModalSuccessContainer">
              <div className="NominalsModalSuccessTitle">
                {nominalsText.sentNominalsText}
              </div>
              <SuccessIcon />
              <ButtonPrimary onClick={onCloseSuccess}>
                {nominalsText.okBtn}
              </ButtonPrimary>
            </div>
          ) : (
            <>
              <div className="HeadlineFirstText">{nominalsText.modalTitle}</div>
              <div className="NominalsModalInput">
                <div className="SubtitleFirstText">{nominalsText.fullName}</div>
                <Input
                  value={userName}
                  placeholder={nominalsText.fullNamePlaceholder}
                  greyBackground
                  onChange={onNameInputChange}
                />
              </div>
              <div className="NominalsModalInput">
                <div className="SubtitleFirstText">
                  {nominalsText.phoneNumber}
                </div>
                <InputMaskComponent
                  placeholder={nominalsText.phoneNumberPlaceholder}
                  greyBackground
                  mask={nominalsText.phoneNumberMask}
                  onChange={onPhoneInputChange}
                />
              </div>
              {sortNominalData && (
                <div className="NominalsResultContainer">
                  <TemplateCard
                    sectionName={NominalSectionKeys.main}
                    template={sortNominalData[NominalSectionKeys.main]}
                    isMinMax={isMinMax}
                    isModalTemplate
                  />
                  {!hideVipTemplate && (
                    <TemplateCard
                      sectionName={NominalSectionKeys.vip}
                      template={sortNominalData[NominalSectionKeys.vip]}
                      isMinMax={isMinMax}
                      isModalTemplate
                    />
                  )}
                </div>
              )}
              <ButtonPrimary
                loading={loading}
                disabled={disabled}
                onClick={onChangeSend}
              >
                {nominalsText.sendButton}
              </ButtonPrimary>
            </>
          )}
        </div>
      </ModalComponent>
    </div>
  )
}
