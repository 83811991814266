import React from "react"

import moment, { Moment } from "moment"

import { RangePickerComponent } from "Components/DatePicker"
import TooltipComponent from "Components/Tooltip"
import { ReactComponent as InfoIcon } from "Components/icons/help_16.svg"

import { RangePickerFilterProps } from "./types"
import { defaultRangePickerFilterText } from "./helpers"

export const RangePickerFilter: React.FC<RangePickerFilterProps> = ({
  text,
  dates,
  onChange,
  dateRender,
  disabled,
  allowClear,
  disableDates,
  rangeTooltip
}) => {
  const disabledDateDefault = (current: Moment) =>
    current && current > moment().endOf(`day`)

  return (
    <div className="SideFilterField">
      <div className="FlexRow Gap4">
        <span className="SideFilterContentLabel">
          {text?.header || defaultRangePickerFilterText.header}
        </span>
        {!!rangeTooltip && (
          <TooltipComponent title={rangeTooltip} placement="bottom">
            <div className="MarginTop4">
              <InfoIcon />
            </div>
          </TooltipComponent>
        )}
      </div>
      <RangePickerComponent
        value={dates}
        dateRender={dateRender}
        disabled={disabled}
        datesAsObject={true}
        disabledDate={disableDates || disabledDateDefault}
        allowClear={allowClear}
        onChange={(value?: [Moment, Moment]) => {
          if (!!value) {
            const start = moment(value[0]).startOf("day")
            const end = moment(value[1]).endOf("day")
            onChange(!!value ? { from: start, to: end } : undefined)
          } else {
            onChange(undefined)
          }
        }}
      />
    </div>
  )
}
