import React, { useMemo, useState } from "react"

import { isMobile } from "constants/index"
import { useRequest } from "api/hooks"

import { ButtonNotifi } from "Components/Button"
import { ModalFormComponent } from "Components/Modal"

import { CardsListData } from "./types"
import { removeText } from "./constants"

const RemoveAccess = ({
  data,
  close
}: {
  data: CardsListData
  close: (withRefresh: boolean) => void
}) => {
  const text = useMemo(() => removeText(data.full_name), [data])

  const { request: removeRq } = useRequest({
    url: `v1/access/remove`,
    requestBody: { id: data.id }
  })

  const [removeLoading, setRemoveLoading] = useState(false)

  return (
    <ModalFormComponent
      width={480}
      close={() => {
        if (!removeLoading) {
          close(false)
        }
      }}
      withoutCross={true}
    >
      <div className={`FlexColumn Gap16 ${isMobile ? `` : `Padding8`}`}>
        <div className="HeadlineSecondText">{text.title}</div>
        <div className="TextTwo MarginBottom8">{text.content}</div>
        <div className="MarginLeftAuto FlexRow Gap8 RemoveAccessButtons">
          <ButtonNotifi
            color="gray"
            disabled={removeLoading}
            onClick={() => {
              if (!removeLoading) {
                close(false)
              }
            }}
          >
            <span className="ButtonTextFirst">{text.cancel}</span>
          </ButtonNotifi>
          <ButtonNotifi
            disabled={removeLoading}
            color="red"
            onClick={() => {
              setRemoveLoading(true)
              removeRq()
                .then(() => {
                  close(true)
                })
                .finally(() => setRemoveLoading(false))
            }}
          >
            <span className="ButtonTextFirst">{text.apply}</span>
          </ButtonNotifi>
        </div>
      </div>
    </ModalFormComponent>
  )
}

export default RemoveAccess
