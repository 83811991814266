import { SortOrders } from "Components/Table/types"
import { FromToFilterFieldProps } from "Components/TableNew/TableFilters/FromToFilter/types"

export enum GuestBalanceType {
  money = "money",
  bonus = "bonus",
  walletId = "walletId",
  freeBetCount = "freeBetCount",
  freeBetSum = "freeBetSum"
}

export enum GuestBalancePaginationType {
  per_20 = 20,
  per_40 = 40,
  per_60 = 60
}

export type GuestBalanceFilterData = {
  halls: number[]
  zeroBalance: boolean
  balance: {
    [key in GuestBalanceType]: FromToFilterFieldProps
  }
  sort: GuestSort
}

export type GuestSort = {
  field: GuestBalanceType
  order?: SortOrders
}

export type GuestBalanceFilter = {
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
  data: GuestBalanceFilterData
}

export type GuestBalanceFilterProps = {
  filter: GuestBalanceFilterData
  setFilter: (data: GuestBalanceFilterData) => void
}

export type GuestBalanceReportDataWallets = {
  walletId: string
  money: string
  bonus: string
  freeBetCount: number
  freeBetSum: string
}

export type GuestBalanceReportLimitsData = {
  min: number
  max: number
}

export type Limits = {
  money: GuestBalanceReportLimitsData
  bonus: GuestBalanceReportLimitsData
  walletId?: GuestBalanceReportLimitsData
  freeBetCount: GuestBalanceReportLimitsData
  freeBetSum: GuestBalanceReportLimitsData
}

export type GuestBalanceReportData = {
  totalMoney: string | null
  totalBonus: string | null
  totalWallets: number | null
  totalFreeBetsSum: string | null
  wallets: GuestBalanceReportDataWallets[]
  limits: Limits
}

export type GuestBalanceReport = {
  pending: boolean
  total: number
  data: GuestBalanceReportData
}

export type CreateColumnsProps = {
  reportData: GuestBalanceReport
  filterState: GuestBalanceFilter
  setFilterState: (value: GuestBalanceFilter) => void
  resetPagination: () => void
  setFreeBetsDetailState: (value: FreeBetsDetailProps) => void
  freeBetsDetailState: FreeBetsDetailProps
  initialLimits?: Limits
}

export type FreeBetsDetailProps = {
  walletId: number
  hallId: number
  freeBetSum: string
  freeBetCount: number
  isOpen: boolean
  pending: boolean
  report: FreeBetsDetailData[]
}

export type FreeBetsDetailData = {
  freeBetId: string
  value: number
  beginDatetime: string
  beginTimestamp: string
  endDatetime: string | null
  endTimestamp: string | null
  betMinFactor: number
  betMaxFactor: number
  betType: string
}
