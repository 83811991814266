import moment from "moment"
import { omit } from "lodash"

import { ModalData, PayrollModalFields, PayrollModalFormValues } from "./types"

import { FilesList } from "Components/File/types"

export const initialModalData: ModalData = {
  open: false,
  editData: undefined
}

export const allowedTypes = ".doc, .docx, .xls, .xlsx, .jpeg, .jpg, .png, .pdf"

export const documentNumberRule = [
  {
    validator: <T>(_: T, value?: string) => {
      const regex = /^[a-zA-Z0-9а-яёА-ЯЁ\/-]+$/
      return !!value && !regex.test(value)
        ? Promise.reject(new Error(`Неверный формат номера документа`))
        : Promise.resolve()
    }
  }
]

export const fileRule = (empty: boolean) =>
  empty
    ? Promise.resolve()
    : Promise.reject(new Error(`Обязательно прикрепить хотя бы одни файл`))

export const getFilesRule = (isResolve: boolean) =>
  isResolve
    ? Promise.resolve()
    : Promise.reject(new Error(`Обязательно прикрепить хотя бы одни файл`))

export const initialValues: PayrollModalFormValues = {
  [PayrollModalFields.document_number]: undefined,
  [PayrollModalFields.sum]: undefined,
  [PayrollModalFields.files]: [],
  [PayrollModalFields.billing_period]: moment(),
  [PayrollModalFields.preparation_date]: undefined
}

export const modalText = {
  trigger: `Добавить ведомость`,
  title_add: `Новая платёжная ведомость`,
  title_edit: `Редактирование платёжной ведомости`,
  [PayrollModalFields.hall]: `Клуб`,
  [PayrollModalFields.document_number]: {
    label: `№ документа`,
    placeholder: `Укажите номер ведомости`
  },
  [PayrollModalFields.billing_period]: `Расчётный период`,
  [PayrollModalFields.preparation_date]: {
    label: `Дата составления`,
    placeholder: `Дата из ведомости`
  },
  [PayrollModalFields.sum]: {
    label: `Сумма, ₽`,
    placeholder: `Укажите сумму из ведомости`
  },
  upload: {
    descriptionOne: `Прикрепите до 3 файлов, не более 5Мб`,
    descriptionTwo: `Формат: doc, docx, xls, xlsx, jpeg, jpg, png, pdf`
  },
  apply: {
    add: `Отправить`,
    save: `Сохранить`
  },
  success: {
    add_title: `Платёжная ведомость добавлена`,
    edit_title: `Платёжная ведомость изменена`,
    button: `Хорошо`
  },
  edit_cancel: {
    title: `Выйти из редактирования?`,
    description: `Уверены, что хотите выйти из редактирования платёжной ведомости? Изменения не сохранятся.`,
    cancel: `Отмена`,
    exit: `Выйти`
  }
}

type PrepareDataToSaveType = {
  values: PayrollModalFormValues
  files: FilesList[]
  id?: string
}

export const editPrepareSum = (sum: string) => sum?.replace(`.`, `,`)
export const prepareSum = (sum?: string) =>
  !!Number(sum) ? `${sum}.00` : sum?.replace(`,`, `.`)

export const prepareDataToSave = ({
  values,
  files,
  id
}: PrepareDataToSaveType) => {
  const filesData = files.map(item => omit(item, [`uid`]))
  const sumWithDot = prepareSum(values[PayrollModalFields.sum])
  return {
    ...values,
    id,
    [PayrollModalFields.files]: filesData,
    [PayrollModalFields.sum]: sumWithDot
  }
}
