import React from "react"

import moment from "moment"

import { getPaginationDefault } from "api/hooks"

import { valueToCurrency } from "constants/index"

import { getColumnSort } from "Components/TableNew/helpers"
import { TableHeader } from "Components/TableNew"
import SearchFilter from "Components/TableNew/TableFilters/SearchFilter"
import SelectFilter from "Components/TableNew/TableFilters/SelectFilter"
import { FromToFilter } from "Components/TableNew/TableFilters/FromToFilter"
import FromToDates from "Components/TableNew/TableFilters/FromToDates"
import MultipleSelectFilter from "Components/TableNew/TableFilters/MultipleSelectFilter"
import { Sorter } from "Components/Table/types"
import { FromToFilterSetterProps } from "Components/TableNew/TableFilters/FromToFilter/types"
import {
  ColumnsArg,
  QiwiTableFields,
  QiwiTableStatus,
  QiwiTableTypes
} from "./types"
import TooltipComponent from "Components/Tooltip"

import { ReactComponent as RubleIcon } from "Components/icons/money_circle_outline.svg"
import { ReactComponent as CopyIcon } from "Components/icons/crud/copy_20.svg"
import { ReactComponent as CancelIcon } from "Components/icons/crud/unblock_24.svg"
import { ReactComponent as InfoIcon } from "Components/icons/info.svg"

export const tableConst = {
  url: `v1/order/qiwi`,
  cancel: `v1/order/qiwi/cancel`,
  status: `v1/order/qiwi/status`,
  save: `v1/order/qiwi/save`
}

export const statusText = {
  [QiwiTableStatus.canceled]: `Отменена`,
  [QiwiTableStatus.rejected]: `Отклонена`,
  [QiwiTableStatus.approved]: `Одобрена`,
  [QiwiTableStatus.new]: `Новая`
}

const statusOptions = [
  {
    value: QiwiTableStatus.canceled,
    label: statusText[QiwiTableStatus.canceled]
  },
  {
    value: QiwiTableStatus.new,
    label: statusText[QiwiTableStatus.new]
  },
  {
    value: QiwiTableStatus.approved,
    label: statusText[QiwiTableStatus.approved]
  },
  {
    value: QiwiTableStatus.rejected,
    label: statusText[QiwiTableStatus.rejected]
  }
]

export const textByField = {
  main: {
    filterLabel: `Фильтровать`
  },
  [QiwiTableFields.id]: {
    title: `№`
  },
  [QiwiTableFields.date]: {
    title: `Дата \nи время (мск)`,
    label: `Дата и время`
  },
  [QiwiTableFields.halls]: {
    title: `Клуб`,
    label: `Клуб`,
    placeholder: `Выберите клубы`
  },
  [QiwiTableFields.type]: {
    title: `Тип заявки`,
    [QiwiTableTypes.order]: `Возврат \nэквайринга`,
    [QiwiTableTypes.replenishment]: `Пополнение баланса`,
    [QiwiTableTypes.replenishment_yandex_taxi]: `Яндекс такси`
  },
  [QiwiTableFields.name]: {
    title: `ФИО`,
    label: `ФИО`,
    placeholder: `Введите ФИО ответственного`
  },
  [QiwiTableFields.phone]: {
    title: `Номер \nтелефона`,
    label: `Номер телефона`
  },
  [QiwiTableFields.email]: {
    title: `Эл. почта`
  },
  [QiwiTableFields.sum]: {
    title: `Cумма \nв заявке, ₽`,
    label: `Cумма в заявке, ₽`
  },
  replenishment: {
    title: `Cумма \nпополнения, ₽`
  },
  [QiwiTableFields.status]: {
    title: `Статус`,
    label: `Статус`,
    placeholder: `Выберите статус`
  },
  actions: {
    copy: `Дублировать`,
    cancel: `Отменить`
  }
}

export const getColumns = ({
  reqData,
  setReqData,
  setCrudModal,
  setCancelModal
}: ColumnsArg) => {
  return [
    {
      title: () => (
        <div className="MarginLeft16">
          {textByField[QiwiTableFields.id].title}
        </div>
      ),
      width: 80,
      dataIndex: QiwiTableFields.id,
      key: QiwiTableFields.id,
      render: (id: number) => <div className="MarginLeft16 TextOne">{id}</div>
    },
    {
      title: () => {
        const isSort =
          reqData.sort &&
          !!reqData.sort?.order &&
          reqData.sort?.field === QiwiTableFields.date
            ? getColumnSort(reqData.sort?.order)
            : undefined
        const fromVal = reqData.filters[QiwiTableFields.date]
          ? reqData.filters[QiwiTableFields.date]?.from
          : undefined
        const toVal = reqData.filters[QiwiTableFields.date]
          ? reqData.filters[QiwiTableFields.date]?.to
          : undefined
        const fromDate = !!fromVal ? moment(fromVal) : undefined
        const toDate = !!toVal ? moment(toVal) : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!reqData.filters[QiwiTableFields.date] ||
              reqData.sort?.field === QiwiTableFields.date
            }
            className=""
            title={textByField[QiwiTableFields.date].title}
            modalTitle={textByField[QiwiTableFields.date].label}
          >
            <FromToDates
              rangeOnly
              hasSort={reqData.sort?.field === QiwiTableFields.date}
              order={reqData.sort?.order}
              fromDate={fromDate}
              toDate={toDate}
              changeFilter={(newFilters: any) => {
                const falseSort =
                  reqData.sort?.field === QiwiTableFields.date
                    ? undefined
                    : reqData.sort
                setReqData({
                  ...reqData,
                  filters: {
                    ...reqData.filters,
                    [QiwiTableFields.date]:
                      newFilters.from || newFilters.to
                        ? {
                            from: moment(newFilters.from).utc(true),
                            to: moment(newFilters.to).utc(true)
                          }
                        : undefined
                  },
                  sort: newFilters.hasSort
                    ? {
                        order: newFilters.order,
                        field: QiwiTableFields.date
                      }
                    : falseSort,
                  pagination: getPaginationDefault(reqData.pagination.limit)
                })
              }}
            />
          </TableHeader>
        )
      },
      width: 120,
      dataIndex: QiwiTableFields.date,
      key: QiwiTableFields.date,
      render: (recordDate: string) => {
        const dateByFormat = moment(recordDate, `YYYY-MM-DDTHH:mm:ss`)
        const date = dateByFormat.format(`DD.MM.YYYY`)
        const time = dateByFormat.format(`HH:mm`)
        return (
          <div className="FlexColumn Gap4">
            <span className="TextOne">{date}</span>
            <span className="TextOne Gray600Color">{time}</span>
          </div>
        )
      }
    },
    {
      title: () => {
        const isSort =
          reqData.sort &&
          !!reqData.sort?.order &&
          reqData.sort?.field === `hall`
            ? getColumnSort(reqData.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!reqData.filters[QiwiTableFields.halls] ||
              reqData.sort?.field === QiwiTableFields.halls
            }
            className=""
            title={textByField[QiwiTableFields.halls].label}
          >
            <MultipleSelectFilter
              value={reqData.filters[QiwiTableFields.halls]}
              isHall={true}
              withSort={true}
              label={textByField.main.filterLabel}
              placeholder={textByField[QiwiTableFields.halls].placeholder}
              currentSort={reqData.sort}
              currentField="hall"
              changeFilter={newValue => {
                const sortValue =
                  newValue.sort.order && newValue.sort.field
                    ? newValue.sort
                    : undefined
                const sort = sortValue as Sorter
                const value = newValue.value as number[]
                setReqData({
                  pagination: getPaginationDefault(reqData.pagination.limit),
                  sort: sort,
                  filters: {
                    ...reqData.filters,
                    [QiwiTableFields.halls]: value?.length ? value : undefined
                  }
                })
              }}
            />
          </TableHeader>
        )
      },
      width: 80,
      dataIndex: QiwiTableFields.hall,
      key: QiwiTableFields.hall,
      render: (hall: string) => <div>{hall}</div>
    },
    {
      title: textByField[QiwiTableFields.type].title,
      width: 120,
      dataIndex: QiwiTableFields.type,
      key: QiwiTableFields.type,
      render: (type: QiwiTableTypes, record: any) => (
        <div className="FlexRow Gap4">
          <div className="TextOne">
            {textByField[QiwiTableFields.type][type]}
          </div>
          {!!record.comment && (
            <div className="OrderQiwiTableComment MarginAuto">
              <TooltipComponent title={record.comment} direction="bottom">
                <InfoIcon />
              </TooltipComponent>
            </div>
          )}
        </div>
      )
    },
    {
      title: () => {
        const isSort =
          reqData.sort &&
          !!reqData.sort?.order &&
          reqData.sort?.field === QiwiTableFields.name
            ? getColumnSort(reqData.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!reqData.filters[QiwiTableFields.name] ||
              reqData.sort?.field === QiwiTableFields.name
            }
            className=""
            title={textByField[QiwiTableFields.name].title}
            modalTitle={textByField[QiwiTableFields.name].label}
          >
            <SearchFilter
              value={reqData.filters[QiwiTableFields.name]}
              withEqual={false}
              iconSearch={true}
              label={textByField.main.filterLabel}
              field={QiwiTableFields.name}
              placeholder={textByField[QiwiTableFields.name].placeholder}
              changeFilter={newValue =>
                setReqData({
                  ...reqData,
                  pagination: getPaginationDefault(reqData.pagination.limit),
                  filters: {
                    ...reqData.filters,
                    [QiwiTableFields.name]: newValue?.value || undefined
                  }
                })
              }
            />
          </TableHeader>
        )
      },
      width: 110,
      dataIndex: QiwiTableFields.name,
      key: QiwiTableFields.name,
      render: (name: string) => <div className="TextOne">{name}</div>
    },
    {
      title: () => {
        const isSort =
          reqData.sort &&
          !!reqData.sort?.order &&
          reqData.sort?.field === QiwiTableFields.phone
            ? getColumnSort(reqData.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!reqData.filters[QiwiTableFields.phone] ||
              reqData.sort?.field === QiwiTableFields.phone
            }
            className=""
            title={textByField[QiwiTableFields.phone].title}
            modalTitle={textByField[QiwiTableFields.phone].label}
          >
            <SearchFilter
              value={reqData.filters[QiwiTableFields.phone]}
              withEqual={false}
              iconSearch={true}
              isPhone={true}
              label={textByField.main.filterLabel}
              field={QiwiTableFields.phone}
              changeFilter={newValue =>
                setReqData({
                  ...reqData,
                  pagination: getPaginationDefault(reqData.pagination.limit),
                  filters: {
                    ...reqData.filters,
                    [QiwiTableFields.phone]: newValue?.value || undefined
                  }
                })
              }
            />
          </TableHeader>
        )
      },
      width: 150,
      dataIndex: QiwiTableFields.phone,
      key: QiwiTableFields.phone,
      render: (name: string) => <div className="TextOne">{name}</div>
    },
    {
      title: textByField[QiwiTableFields.email].title,
      width: 140,
      dataIndex: QiwiTableFields.email,
      key: QiwiTableFields.email,
      render: (email: string) => <div className="TextOne">{email}</div>
    },
    {
      title: () => {
        const isSort =
          reqData.sort &&
          !!reqData.sort?.order &&
          reqData.sort?.field === QiwiTableFields.sum
            ? getColumnSort(reqData.sort?.order)
            : undefined
        return (
          <TableHeader
            isActiveField={!!reqData.filters[QiwiTableFields.sum]}
            isSort={isSort}
            title={textByField[QiwiTableFields.sum].title}
          >
            <FromToFilter
              suffix={<RubleIcon />}
              isFloatNumber={false}
              label={textByField.main.filterLabel}
              fieldName={QiwiTableFields.sum}
              title={textByField[QiwiTableFields.sum].label}
              from={reqData.filters[QiwiTableFields.sum]?.from}
              to={reqData.filters[QiwiTableFields.sum]?.to}
              currentSort={reqData.sort || {}}
              defaultFilter={{
                field: QiwiTableFields.sum,
                from: reqData.filters[QiwiTableFields.sum]?.from,
                to: reqData.filters[QiwiTableFields.sum]?.to
              }}
              currentFilter={{
                ...reqData.filters[QiwiTableFields.sum],
                field: QiwiTableFields.sum
              }}
              setter={(fromToState: FromToFilterSetterProps) => {
                const newSort =
                  fromToState.sortField && fromToState.order
                    ? {
                        field: fromToState.sortField,
                        order: fromToState.order
                      }
                    : undefined
                setReqData({
                  sort: newSort,
                  pagination: getPaginationDefault(reqData.pagination.limit),
                  filters: {
                    ...reqData.filters,
                    [QiwiTableFields.sum]:
                      fromToState.from ||
                      fromToState.to ||
                      fromToState.from === 0 ||
                      fromToState.to === 0
                        ? {
                            from: fromToState.from,
                            to: fromToState.to
                          }
                        : undefined
                  }
                })
              }}
            />
          </TableHeader>
        )
      },
      width: 120,
      dataIndex: QiwiTableFields.sum,
      key: QiwiTableFields.sum,
      render: (sum: string) => {
        const currency = valueToCurrency({
          value: Number(sum),
          withoutSpace: true
        })
        return <div>{currency}</div>
      }
    },
    {
      title: textByField.replenishment.title,
      width: 120,
      dataIndex: QiwiTableFields.approved_sum,
      key: QiwiTableFields.approved_sum,
      render: (approved_sum: string) => {
        const currency = valueToCurrency({
          value: Number(approved_sum),
          withoutSpace: true
        })
        return <div className="TextOne">{currency}</div>
      }
    },
    {
      title: () => {
        const isSort =
          reqData.sort &&
          !!reqData.sort?.order &&
          reqData.sort?.field === QiwiTableFields.status
            ? getColumnSort(reqData.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={!!reqData.filters[QiwiTableFields.status]}
            className=""
            title={textByField[QiwiTableFields.status].label}
          >
            <SelectFilter
              value={reqData.filters[QiwiTableFields.status]}
              placeholder={textByField[QiwiTableFields.status].placeholder}
              withSort={false}
              options={statusOptions}
              currentSort={reqData.sort}
              currentField={QiwiTableFields.status}
              changeFilter={newValue => {
                const value = newValue.value as QiwiTableStatus
                setReqData({
                  ...reqData,
                  pagination: getPaginationDefault(reqData.pagination.limit),
                  filters: {
                    ...reqData.filters,
                    [QiwiTableFields.status]: value
                  }
                })
              }}
            />
          </TableHeader>
        )
      },
      width: 80,
      dataIndex: QiwiTableFields.status,
      key: QiwiTableFields.status,
      render: (status: QiwiTableStatus) => (
        <div
          className={`QiwiStatusTableColumn QiwiStatus-${status} FlexRow PaddingBottom6`}
        >
          <span className="StatusText">{statusText[status]}</span>
        </div>
      )
    },
    {
      title: () => <div className="MarginRight16">{""}</div>,
      width: 80,
      dataIndex: QiwiTableFields.id,
      key: QiwiTableFields.id,
      align: `right`,
      render: (id: number, record: any) => {
        const isNew = record.status === QiwiTableStatus.new
        const copyData = {
          halls: [record.hall],
          name: record[QiwiTableFields.name],
          [QiwiTableFields.form_sum]: record[QiwiTableFields.sum],
          [QiwiTableFields.type]: record[QiwiTableFields.type],
          [QiwiTableFields.comment]: record.comment || undefined,
          phone: record[QiwiTableFields.phone],
          email: record[QiwiTableFields.email]
        }
        return (
          <div className="FlexRow Gap16">
            {isNew && (
              <TooltipComponent
                title={textByField.actions.cancel}
                direction="bottom"
              >
                <div
                  className="FlexRow Gap8"
                  onClick={() => setCancelModal(record[QiwiTableFields.id])}
                >
                  <CancelIcon />
                </div>
              </TooltipComponent>
            )}
            <TooltipComponent
              title={textByField.actions.copy}
              direction="bottom"
            >
              <div
                className="FlexRow Gap8"
                onClick={() => {
                  setCrudModal({ initial: copyData, open: true })
                }}
              >
                <CopyIcon />
              </div>
            </TooltipComponent>
          </div>
        )
      }
    }
  ]
}
