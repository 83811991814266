import React, { useCallback, useState } from "react"

import { useNow } from "api/hooks"

import { isMobile } from "constants/index"

import Title from "Components/Title"
import { ButtonPrimary } from "Components/Button"

import { paymentText } from "./constants"

// Table components
import PaymentTable from "./Table"
import ClearFilter from "./Table/Components/ClearButton"
import { FilterState } from "./Table/types"
import { initialFilter } from "./Table/constants"

// CRUD component
import PaymentModalComponent from "./Modal"
import { ModalData } from "./Modal/types"
import { initialModalData, modalText } from "./Modal/constants"
import StatusNotifyModal from "../Components"
import { initialMoreModalState } from "./Table/Components/constants"
import { MoreModal } from "./Table/Components/MoreModal"
import { MoreModalState } from "./Table/Components/types"

const Payment = () => {
  const [filters, setFilter] = useState<FilterState>(initialFilter)
  const [PaymentModal, setPaymentModal] = useState<ModalData>(initialModalData)
  const [moreModal, setMoreModal] = useState<MoreModalState>(
    initialMoreModalState
  )

  const openMoreModal = useCallback(value => {
    setMoreModal(value)
  }, [])

  const closeMoreModal = useCallback(() => {
    setMoreModal(initialMoreModalState)
  }, [])

  const { refresh: refreshTable, timestamp: refreshTrigger } = useNow()

  return (
    <div>
      <div className="MainPageContentWrapper">
        <div className="MainPageContentRestrictions">
          <div className="FlexRow">
            <Title
              titleText={paymentText.title}
              tooltipText={paymentText.tooltip}
            />
            {!isMobile && (
              <div className="PaymentTitleButtons FlexRow Gap16">
                <ClearFilter filters={filters} setFilter={setFilter} />
                <StatusNotifyModal baseUrl={paymentText.notifyBaseUrl} />
                <ButtonPrimary
                  onClick={() =>
                    setPaymentModal({ open: true, editData: undefined })
                  }
                  className="PaymentTriggerButton"
                >
                  {modalText.trigger}
                </ButtonPrimary>
              </div>
            )}
          </div>
          {isMobile && (
            <div className="PaymentTitleButtons FlexColumn Gap16 MarginBottom16 MarginLeft16">
              <ButtonPrimary
                onClick={() =>
                  setPaymentModal({ open: true, editData: undefined })
                }
                className="PaymentTriggerButton"
              >
                {modalText.trigger}
              </ButtonPrimary>
              <StatusNotifyModal baseUrl={paymentText.notifyBaseUrl} />
              <ClearFilter filters={filters} setFilter={setFilter} />
            </div>
          )}
          <PaymentTable
            filters={filters}
            setFilter={setFilter}
            setPaymentModal={setPaymentModal}
            refreshTrigger={refreshTrigger}
            refreshTable={refreshTable}
            onMoreClick={openMoreModal}
          />
          {PaymentModal.open && (
            <PaymentModalComponent
              data={PaymentModal.editData}
              close={() => {
                setPaymentModal({ open: false, editData: undefined })
                refreshTable()
              }}
            />
          )}
          {moreModal.isActive && (
            <MoreModal
              onCloseModal={closeMoreModal}
              comment={moreModal.comment}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Payment
