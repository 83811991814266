import React, { useMemo } from "react"

import moment from "moment"

import { TooltipCustom, TooltipCustomSize } from "Components/Tooltip"
import { RadioGroupComponent } from "Components/Radio"
import { FilterDurations } from "Components/FilterInfoPanel/types"
import DatePickerComponent, {
  RangePickerComponent,
  RangePickerPeriod
} from "Components/DatePicker"

import { ReportHallFilterData } from "./types"
import { reportText, reportTypes } from "./constants"
import { Moment } from "moment/moment"

const HallReportFilter = ({
  filter,
  setFilter
}: {
  filter: ReportHallFilterData
  setFilter: (data: ReportHallFilterData) => void
}) => {
  const optionsForPeriod = useMemo(
    () => reportText.filter.period_type.getOptions(filter.report_type),
    [filter.report_type]
  )
  const isBalanceOrAcquiring =
    filter.report_type === reportTypes.balance ||
    filter.report_type === reportTypes.acquiring

  const rangePicker = reportText.filter.period.picker[
    filter.period_type
  ] as RangePickerPeriod
  const rangeFormat = reportText.filter.period.format[
    filter.period_type
  ] as RangePickerPeriod

  return (
    <div>
      <div className="FlexColumn Gap24">
        <div className="FlexColumn Gap8">
          <div className="FlexRow Gap4">
            <div className="SubtitleFirstText">
              {reportText.filter.report_type.label}
            </div>
            <div className="PaddingTop4">
              <TooltipCustom
                size={TooltipCustomSize.small}
                title={reportText.filter.report_type.tooltip}
              />
            </div>
          </div>
          <RadioGroupComponent
            className="HallReportFilterRadio"
            value={filter.report_type}
            onChange={({ target: { value } }) => {
              const changePeriodType =
                (value === reportTypes.balance ||
                  value === reportTypes.acquiring) &&
                filter.period_type !== FilterDurations.monthly
              setFilter({
                ...filter,
                report_type: value,
                period_type: changePeriodType
                  ? FilterDurations.monthly
                  : filter.period_type,
                period: undefined
              })
            }}
            options={reportText.filter.report_type.options}
          />
        </div>
        <div className="FlexColumn Gap8">
          <div className="SubtitleFirstText">
            {reportText.filter.period_type.label}
          </div>
          <RadioGroupComponent
            className="HallReportFilterRadio"
            value={filter.period_type}
            onChange={({ target: { value } }) =>
              setFilter({ ...filter, period_type: value, period: undefined })
            }
            options={optionsForPeriod}
          />
        </div>
        <div className="FlexColumn Gap8">
          <div className="SubtitleFirstText">
            {reportText.filter.period.label}
          </div>
          {isBalanceOrAcquiring ? (
            <DatePickerComponent
              disabledDate={(current: Moment) =>
                current && current > moment().endOf(`month`)
              }
              value={filter.period?.to}
              onChange={date => {
                const from = moment(date)
                  .utc()
                  .startOf("month")

                const to = moment(date)
                  .utc()
                  .endOf("month")

                setFilter({
                  ...filter,
                  period: date ? { from, to } : undefined
                })
              }}
              picker="month"
              format="MMMM YYYY"
            />
          ) : (
            <RangePickerComponent
              value={
                filter.period
                  ? [filter.period.from, filter.period.to]
                  : undefined
              }
              onChange={date => {
                let from = date[0]
                let to = date[1]
                if (filter.period_type === FilterDurations.monthly) {
                  from = moment(date[0], `DD.MM.YYYY`)
                    .utc()
                    .startOf(`month`)
                  to = moment(date[1], `DD.MM.YYYY`)
                    .utc()
                    .endOf(`month`)
                } else if (filter.period_type === FilterDurations.yearly) {
                  from = moment(date[0], `DD.MM.YYYY`)
                    .utc()
                    .startOf(`year`)
                  to = moment(date[1], `DD.MM.YYYY`)
                    .utc()
                    .endOf(`year`)
                } else {
                  from = moment(from, `DD.MM.YYYY`).utc()
                }
                setFilter({
                  ...filter,
                  period: date ? { from, to } : undefined
                })
              }}
              picker={rangePicker}
              format={rangeFormat}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default HallReportFilter
