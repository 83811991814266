import { SuperWinsTableColumnNames, SuperWinsZones } from "./types"
import vipImg from "Components/icons/dashboard/vip.svg"
import mainImg from "Components/icons/dashboard/main.svg"

const mainConstants = {
  title: `Статистика по клубам`,
  rubles: "₽",
  dashboards: {
    details: "Подробнее",
    attendance: {
      title: `Количество посещений`,
      tooltip: `Количество гостей с 8 утра по Москве (по умолчанию) по текущий момент в данном клубе/клубах. Гостями считаются только те, кто совершал ставки, как с денежного, так и с бонусного балансов. При изменении времени начала периода или часового пояса данные строятся относительно выбранных параметров по текущий момент.`
    },
    payment: {
      title: `Кассовый результат, ₽`,
      tooltip: `Разница между пополнениями и выплатами по кассе в данном клубе/клубах с 8 утра по Москве (по умолчанию) по текущий момент. При изменении времени начала периода или часового пояса данные строятся относительно выбранных параметров по текущий момент.`
    },
    newGuest: {
      title: `Регистрации`,
      tooltip: `Количество гостей, для которых был заведен новый кошелек в данном клубе/клубах с 8 утра по Москве (по умолчанию) по текущий момент. При изменении времени начала периода или часового пояса данные строятся относительно выбранных параметров по текущий момент.`
    },
    superWins: {
      title: "Супервыигрыш",
      modalTitle: "Информация о супервыигрыше",
      manyHallsTitle: "Последние супервыигрыши",
      singleHallEmptyTitle: "Cупервыигрышей не было",
      tooltip: `Последний выпавший Супервыигрыш в клубе`
    },
    qiwi: {
      title: `Баланс для выплат, ₽`,
      tooltip: `Расчетный баланс клуба/клубов для безналичных выплат на банковские карты гостей на текущий момент.`
    }
  }
}

export const superWinsColumnNames = {
  [SuperWinsTableColumnNames.date]: "Дата",
  [SuperWinsTableColumnNames.hall_zone]: "Зона клуба",
  [SuperWinsTableColumnNames.game_id]: "Игра",
  [SuperWinsTableColumnNames.nominal]: "Номинал",
  [SuperWinsTableColumnNames.win_sum]: "Сумма выигрыша",
  [SuperWinsTableColumnNames.date]: "Дата выигрыша",
  [SuperWinsTableColumnNames.hall]: "Клуб"
}

export const zonesIconsDict = {
  [SuperWinsZones.vip]: { img: vipImg, title: "VIP" },
  [SuperWinsZones.main]: { img: mainImg, title: "Общая" }
}

export default mainConstants

export const initialQiwiData = {
  data: [],
  pending: true,
  title: ``,
  color: ``
}

export const initialStaticDashboardData = {
  title: ``,
  data: [],
  columns: [],
  selectedHalls: [],
  pending: true
}
