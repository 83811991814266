import React from "react"
import { hallCardTextConst } from "../constants"
import ButtonDefault from "Components/Button"
import { ReactComponent as PrinterIcon } from "Components/icons/printer_outline_20.svg"
import { PrintButtonProps } from "../types"
import { useRequestParams } from "api/hooks"
import betRequest from "api/request"

const PrintButton = ({ hallId }: PrintButtonProps) => {
  const { history, developer, addDeveloperLink, logout } = useRequestParams()

  // Handlers
  const getHallCardPdf = () => {
    betRequest({
      method: "POST",
      url: "v1/hall/card/pdf",
      requestBody: {
        hall: hallId
      },
      history,
      logout,
      developer,
      addDeveloperLink
    }).then(({ data }: { data: { url: string; name: string } }) => {
      if (process.env.REACT_APP_AUTH_URL && data.name && data.url) {
        const baseUrl = process.env.REACT_APP_AUTH_URL.replace("/api/site", "")
        const fileUrl = `${baseUrl}${data.url}`
        const link = document.createElement("a")
        link.href = fileUrl
        link.download = data.name
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  // Render
  return (
    <ButtonDefault onClick={getHallCardPdf} className="ButtonSecondary">
      <div className="HallCardPrinterBtn">
        <PrinterIcon />
        <span className="HallCardPrinterBtn__Text">
          {hallCardTextConst.print}
        </span>
      </div>
    </ButtonDefault>
  )
}

export default PrintButton
