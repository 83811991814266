import React, { useMemo, useState } from "react"

import ButtonDefault, { ButtonFaded } from "Components/Button"
import { ModalFormComponent } from "Components/Modal"

import {
  checkClearIsEmpty,
  getFilterInfoByData,
  tableComponentsConstants
} from "./constants"

import { initialFilter } from "../constants"

import { FilterProps } from "../../types"

const ClearFilter = ({ filters, setFilter }: FilterProps) => {
  const [showModal, toggleModal] = useState(false)

  const filterCount = useMemo(() => checkClearIsEmpty(filters.table), [
    filters.table
  ])
  const filterText = useMemo(() => getFilterInfoByData(filters.table), [
    filters.table
  ])

  return !!filterCount ? (
    <div>
      <ButtonDefault onClick={() => toggleModal(true)}>
        <div className="FlexRow Gap4">
          <span className="PaymentClearButtonText">
            {tableComponentsConstants.buttons.clear}
          </span>
          <div className="PaymentClearButtonCount ButtonTextSecond">
            {filterCount}
          </div>
        </div>
      </ButtonDefault>
      {showModal && (
        <ModalFormComponent
          title={tableComponentsConstants.buttons.clearModalTitle}
          width={384}
          close={() => toggleModal(false)}
        >
          {filterText}
          <div className="PaddingTop12">
            <ButtonFaded
              className="PaymentClearModalButton"
              onClick={() => {
                setFilter({
                  ...initialFilter,
                  table: { sort: {} },
                  pagination: { ...filters.pagination, offset: 0 }
                })
                toggleModal(false)
              }}
            >
              {tableComponentsConstants.buttons.clearModalBtn}
            </ButtonFaded>
          </div>
        </ModalFormComponent>
      )}
    </div>
  ) : (
    <div />
  )
}

export default ClearFilter
