import React, { memo, useCallback, useEffect, useMemo, useState } from "react"

import NewTableComponent from "Components/TableNew"
import { sortForTable } from "Components/Table"

import ReportWithFilter from "Components/ReportWithFilter"
import EmptyTable from "Components/EmptyTable"

import { useNoncashRequest } from "../helper"

import Title from "../Title"
import { EmptyFilter, useFilter } from "../Filter/helpers"

import ReportLegoProps, { initialReportData, ReportDataState } from "./types"
import "./styles.less"
import { Sorter } from "Components/Table/types"
import NoncashFilter from "../Filter"

const ReportLego = ({
  title,
  titleInfo,
  exelConfig,
  report: { tableScroll, getColumn, url },
  filterName,
  emptyFilterTitle,
  emptyFilterDescription,
  modalCmp,
  disableLastRowClick,
  withTotal
}: ReportLegoProps) => {
  const { filterState, setFilterState } = useFilter({
    storage: filterName
  })

  const [reportData, setReportData] = useState<ReportDataState>(
    initialReportData
  )

  const [modal, toggleModal] = useState(false)

  const setModal = useCallback((value: boolean) => toggleModal(value), [])

  const [modalData, setModalData] = useState(null)

  const {
    pagination,
    setPagination,
    resetPagination,
    setSort,
    sort,
    search,
    setSearch,
    requestBody
  } = useNoncashRequest({
    filterState,
    setData: setReportData,
    report: reportData,
    url
  })

  const [isEmptyData, setIsEmptyData] = useState(true)

  useEffect(() => {
    setIsEmptyData(!reportData?.data?.report?.length)
  }, [reportData])

  const columns = useMemo(() => {
    const col = getColumn(
      !!sort.order ? sort.field : undefined,
      search,
      setSearch
    )
    return col
  }, [sort, search])

  const ModalWithProps =
    modalCmp &&
    memo(
      // eslint-disable-next-line
      //@ts-ignore
      modalCmp({
        modalData,
        toggleModal: setModal,
        tableScroll
      }),
      [modalData]
    )
  const rowWithClick = !!modalCmp

  return (
    <ReportWithFilter
      filterComponent={
        <NoncashFilter
          storage={filterName}
          filterState={filterState}
          setFilterState={setFilterState}
          resetPagination={resetPagination}
        />
      }
      mobileFilter={filterState.mobileFilter}
      isOpen={filterState.isOpen}
      toggleFilter={() =>
        setFilterState({ ...filterState, isOpen: !filterState.isOpen })
      }
      toggleMobileFilter={() =>
        setFilterState({
          ...filterState,
          mobileFilter: !filterState.mobileFilter
        })
      }
      pageContent={
        <div className="ReportLegoComponentContentWrapper">
          {!filterState.isActive && (
            <EmptyFilter
              title={emptyFilterTitle}
              description={emptyFilterDescription}
            />
          )}
          {filterState.isActive && (
            <div>
              <Title
                title={title}
                titleInfo={titleInfo}
                loading={reportData.pending}
                exel={
                  exelConfig && {
                    requestBody,
                    url: exelConfig.url,
                    title: exelConfig.title
                  }
                }
                withTotal={withTotal}
                filterState={filterState}
                qiwiSum={reportData.data.qiwi_balance.sum}
                total={reportData.data.total_sum}
                qiwiData={reportData.data.qiwi_balance.data}
              />
              {!reportData.pending && isEmptyData ? (
                <EmptyTable />
              ) : (
                <div>
                  <NewTableComponent
                    pagination={{
                      value: pagination,
                      total: reportData.total,
                      onChange: setPagination
                    }}
                    onChange={(pagination: any, filters: any, sorter: Sorter) =>
                      sortForTable({ sorter, setSort })
                    }
                    loading={reportData.pending}
                    columns={columns}
                    data={reportData.data.report}
                    width={tableScroll}
                    rowClassNameByRecord={(record: any) => {
                      if (rowWithClick && !disableLastRowClick) {
                        return `ExpandableTableRow`
                      } else if (
                        rowWithClick &&
                        disableLastRowClick &&
                        record.date
                      ) {
                        return `ExpandableTableRow`
                      }
                    }}
                    onRow={(record: any) => {
                      return {
                        onClick: () => {
                          if (rowWithClick && !disableLastRowClick) {
                            toggleModal(true)
                            setModalData(record)
                          }
                          if (
                            rowWithClick &&
                            disableLastRowClick &&
                            record.date
                          ) {
                            toggleModal(true)
                            setModalData(record)
                          }
                        }
                      }
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      }
      modalCmp={modal && ModalWithProps ? <ModalWithProps /> : undefined}
    />
  )
}

export default ReportLego
