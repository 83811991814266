import React from "react"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const SpinComponent = ({ color, size }: { color?: string; size?: number }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size || 32 }} spin />

  return (
    <div
      className={`GridCenter ${
        color ? `YellowSpinComponent` : `GraySpinComponent`
      }`}
    >
      <Spin indicator={antIcon} />
    </div>
  )
}

export const UltimatePending = () => (
  <div className="UltimatePendingWrapper GridCenter">
    <SpinComponent color="yellow" />
  </div>
)

export default SpinComponent
