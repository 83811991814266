import React, { useState } from "react"

import { ChartTypeComponent, ChartType } from "../../Components/chartType"

import { GuestsHistoryFilterData } from "Containers/GuestsHistory/types"

import GuestHistoryBetsBySum from "./BySum"
import GuestHistoryBetsByTime from "./ByTime"

const BetsChart = ({ filter }: { filter: GuestsHistoryFilterData }) => {
  // eslint-disable-next-line
  const [chartPeriod, toggleChartPeriod] = useState(0)

  const [activeBest, toggleBets] = useState(true)
  const [activeWins, toggleWins] = useState(false)

  return (
    <div className="GuestHistoryBetsChartWrapper">
      <div className="GuestHistoryBetsChartHeader">
        {/* Temporary comment, wait business  */}
        {/*<SelectComponent*/}
        {/*  value={chartPeriod}*/}
        {/*  onChange={value => toggleChartPeriod(value)}*/}
        {/*  greyBackground={true}*/}
        {/*  options={gamesConstants.switcher}*/}
        {/*  allowClear={false}*/}
        {/*/>*/}
        <ChartTypeComponent
          isActive={activeBest}
          type={ChartType.bets_in}
          switchActive={() => {
            if (!activeBest || activeWins) {
              toggleBets(!activeBest)
            }
          }}
        />
        <ChartTypeComponent
          isActive={activeWins}
          type={ChartType.bets_out}
          switchActive={() => {
            if (!activeWins || activeBest) {
              toggleWins(!activeWins)
            }
          }}
        />
      </div>
      <div className="GuestHistoryBetsBySumWrapper">
        {!!chartPeriod ? (
          <GuestHistoryBetsByTime
            filter={filter}
            activeBest={activeBest}
            activeWins={activeWins}
          />
        ) : (
          <GuestHistoryBetsBySum
            filter={filter}
            activeBest={activeBest}
            activeWins={activeWins}
          />
        )}
      </div>
    </div>
  )
}

export default BetsChart
