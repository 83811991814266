import { Filters } from "./types"

export const defaultFilterText = {
  header: "Фильтры",
  applyButton: "Применить",
  reset: "Сбросить",

  labels: {
    [Filters.phoneInput]: `Номер телефона`,
    [Filters.rangePicker]: `Период отчета`,
    [Filters.gamesPicker]: `Игры`,
    [Filters.shiftSwitch]: ``
  },
  infoPanel: {
    periodOptions: [
      { value: `daily`, label: `Дням` },
      { value: `monthly`, label: `Месяцам` },
      { value: `yearly`, label: `Годам` }
    ]
  }
}
