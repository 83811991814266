import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  DetailsData,
  ProposalData,
  ProposalDataListResponse,
  ReportDataState,
  StatusesDict,
  SupportFilter
} from "./types"
import ReportWithFilter from "Components/ReportWithFilter"
import { sortForTable } from "Components/Table"
import NewTableComponent from "Components/TableNew"
import { Sorter } from "Components/Table/types"

import {
  getInitialFilterState,
  NewProposalButton,
  proposalColumn,
  proposalText,
  SUPPORT_PROPOSAL_FILTER,
  TOTAL_COUNT_HEADER_KEY,
  useProposalRequests
} from "./helper"
import Title from "Components/Title"
import { useRequestBody, useRequestParams } from "api/hooks"
import produce from "immer"
import ProposalModal from "./ProposalModal"
import { ModalTypes } from "./ProposalModal/helper"
import ProposalMobileCard from "./ProposalMobileCard"
import NotifiEmptyTable from "../../Tools/Notifications/Table/EmptyTable"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import { TablePaginationConfig } from "antd"
import { FilterValue } from "antd/lib/table/interface"

const initialReportData: ReportDataState = {
  data: [],
  total: 0,
  pending: false
}

const Proposal: React.FC = () => {
  const { currentHallAsNumber, locations } = useRequestParams()
  const { pagination, setPagination, setSort, sort } = useRequestBody(20)
  const initFilterState = getInitialFilterState({
    currentHall: currentHallAsNumber,
    locations,
    storage: SUPPORT_PROPOSAL_FILTER
  })

  const isMobile = window.innerWidth < 921

  const [statusesDict, setStatusesDict] = useState<StatusesDict>([])
  const [initDataRequested, setInitDataRequested] = useState(false)
  const [filterState, setFilterState] = useState<SupportFilter>(initFilterState)
  const [cancelId, setCancelId] = useState<number | null>(null)
  const [reportData, setReportData] = useState<ReportDataState>(
    initialReportData
  )
  const [search, setSearch] = useState<{ [key: string]: string | undefined }>(
    {}
  )
  const [modal, toggleModal] = useState(false)
  const [modalType, setModalType] = useState<ModalTypes>()
  const [currentProposal, setCurrentProposal] = useState<ProposalData | null>(
    null
  )
  const [modalData, setModalData] = useState<DetailsData | null>(null)
  const [successModal, setSuccessModal] = useState(false)
  const successTimerRef = useRef<NodeJS.Timeout | null>(null)

  const {
    getProposalDict,
    getProposalList,
    cancelProposal,
    newProposal
  } = useProposalRequests({
    filterState,
    pagination,
    sort,
    cancelId,
    currentHall: currentHallAsNumber,
    modalData
  })

  const clearTimer = (timer: NodeJS.Timeout | null) => {
    if (timer) {
      clearTimeout(timer)
    }
  }

  const setNextReportDataList = (response: ProposalDataListResponse) => {
    setReportData(prev => ({
      ...prev,
      data: response.data,
      total: response.headers[TOTAL_COUNT_HEADER_KEY],
      pending: false
    }))
  }

  const createProposal = async () => {
    clearTimer(successTimerRef.current)
    setReportData(prev => ({ ...prev, pending: true }))
    await newProposal()
    setSuccessModal(true)
    successTimerRef.current = setTimeout(async () => {
      const resp = await getProposalList()
      setNextReportDataList(resp)
      toggleModal(false)
      setCurrentProposal(null)
      setModalData(null)
      setModalType(ModalTypes.new)
      clearTimer(successTimerRef.current)
      setSuccessModal(false)
      setReportData(prev => ({
        ...prev,
        pending: false
      }))
    }, 1500)
  }

  useEffect(() => {
    if (modalData) {
      createProposal().catch(err => {
        setReportData(prev => ({ ...prev, pending: false }))
        setSuccessModal(false)
        throw err
      })
    }
  }, [modalData])

  useEffect(() => {
    if (cancelId) {
      cancelProposal().then(resp => {
        setReportData(prev => {
          const next = prev.data.filter(x => x.id !== resp.data.id)
          return { ...prev, data: [resp.data, ...next] }
        })
        toggleModal(false)
      })
    }
  }, [cancelId])

  useEffect(() => {
    if (filterState.isActive || !initDataRequested) {
      setReportData(prev => ({ ...prev, pending: true }))
      getProposalList().then(resp => {
        setNextReportDataList(resp)
        setInitDataRequested(true)
      })
    }
  }, [filterState, sort, pagination])

  useEffect(() => {
    getProposalDict().then(resp => {
      setStatusesDict(resp.data)
    })
    return () => {
      clearTimer(successTimerRef.current)
    }
  }, [])

  const resetPagination = useCallback(() => {
    setPagination({ offset: 0, limit: 20 })
  }, [sort, pagination])

  const setSearchCb = useCallback(
    (searchArg: string | undefined, id: string) => {
      if (search) {
        setSearch(
          produce(search, draft => {
            draft[id] = searchArg?.trim()
          })
        )
      }
    },
    [search]
  )

  const toggleModalCb = useCallback((value: boolean) => {
    toggleModal(value)
    setModalType(ModalTypes.new)
  }, [])

  const showModal = useCallback(() => {
    toggleModal(true)
    setModalType(ModalTypes.new)
  }, [])

  const setCancelIdCb = useCallback((value: number | null) => {
    setCancelId(value)
  }, [])

  const duplicate = useCallback(value => {
    setModalType(ModalTypes.new)
    setCurrentProposal(value)
    toggleModal(true)
  }, [])

  const resetProposal = useCallback(() => {
    setCurrentProposal(null)
    setModalType(ModalTypes.new)
  }, [])

  const onRowClicked = useCallback(record => {
    return {
      onClick: () => {
        setCurrentProposal(record)
        toggleModal(true)
        setModalType(ModalTypes.view)
      }
    }
  }, [])

  const setPaginationCb = useCallback(
    value => {
      setPagination(value)
      setFilterState(prev => ({ ...prev, isActive: true }))
    },
    [sort, filterState]
  )

  const setSortCb = useCallback(
    value => {
      setSort(value)
      setFilterState(prev => ({ ...prev, isActive: true }))
    },
    [filterState]
  )

  const setModalDataCb = useCallback(
    value => {
      setModalData(value)
    },
    [modalData]
  )

  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  const toggleFilterCb = useCallback(() => {
    setFilterState({
      ...filterState,
      isOpen: !filterState.isOpen,
      isActive: false
    })
  }, [filterState.isOpen])

  const toggleMobileFilterCb = useCallback(() => {
    setFilterState({
      ...filterState,
      mobileFilter: !filterState.mobileFilter,
      isActive: false
    })
  }, [filterState.mobileFilter])

  const columns = useMemo(() => {
    return proposalColumn(
      !!sort.order ? sort.field : undefined,
      search,
      setSearchCb,
      statusesDict,
      setCancelId,
      duplicate,
      filterState.data?.search
    )
  }, [sort, search, statusesDict, filterState.data.search])

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={SUPPORT_PROPOSAL_FILTER}
            filterState={filterState}
            statusesDict={statusesDict}
            setFilterState={setFilterStateCb}
            resetPagination={resetPagination}
            filters={[
              Filters.statusesCheckBox,
              Filters.rangePicker,
              Filters.searchInput
            ]}
            getDisabled={filter => !filter?.statuses?.length}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={toggleFilterCb}
        toggleMobileFilter={toggleMobileFilterCb}
        pageContent={
          <>
            <Title
              titleText={proposalText.title}
              tooltipText={proposalText.tooltip}
              rightAdditionalCmp={<NewProposalButton showModal={showModal} />}
              rightAdditionalClassName={"ProposalNewButtonTitleContainer"}
            />
            {(reportData?.pending || !!reportData?.data.length) && (
              <div>
                {isMobile ? (
                  reportData?.data.map(proposal => (
                    <ProposalMobileCard
                      key={proposal?.id}
                      proposal={proposal}
                      statuses={statusesDict}
                      setCancelId={setCancelIdCb}
                      duplicate={duplicate}
                      onClick={() => {
                        setCurrentProposal(proposal)
                        toggleModal(true)
                        setModalType(ModalTypes.view)
                      }}
                    />
                  ))
                ) : (
                  <NewTableComponent
                    pagination={{
                      value: pagination,
                      total: reportData.total,
                      onChange: setPaginationCb
                    }}
                    columns={columns}
                    data={reportData?.data}
                    loading={reportData.pending}
                    width={900}
                    withTotal={false}
                    onChange={(
                      pagination: TablePaginationConfig,
                      filters: Record<string, FilterValue | null>,
                      sorter: Sorter
                    ) => sortForTable({ sorter, setSort: setSortCb })}
                    onRow={onRowClicked}
                    rowClassName="ExpandableTableRow"
                    tableClassName="ProposalTable"
                  />
                )}
              </div>
            )}
            {!reportData.pending && !reportData.data?.length && (
              <NotifiEmptyTable />
            )}
          </>
        }
        modalCmp={
          <ProposalModal
            toggleModal={toggleModalCb}
            passedModalData={currentProposal}
            viewMode={modalType === ModalTypes.view}
            statuses={statusesDict}
            visible={modal}
            resetProposal={resetProposal}
            setCancelId={setCancelIdCb}
            duplicate={duplicate}
            setModalDataCb={setModalDataCb}
            isMobile={isMobile}
            loading={reportData.pending}
            successModal={successModal}
          />
        }
      />
    </>
  )
}

export default Proposal
