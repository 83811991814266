import React from "react"
import betRequest from "api/request"
import { RouteComponentProps } from "react-router-dom"
import {
  ContactsBlockModalProps,
  ContactsSectionModalProps,
  ContactsStaffModalProps,
  ContactsStateDataBlocksStaffSingleProps,
  ContactsStateDataProps,
  ContactsStateProps,
  keyOfPhoneRenderProps
} from "./types"
import { blockStateStorageName, defaultIcon } from "./constants"
import { isEmpty } from "lodash"
import { clearPhone, formatPhone } from "constants/index"

export const allowedContactsEditingRoles = [
  "COMPANY-DEVELOPER",
  "COMPANY-SUPPORT"
]

type getContactsProps = {
  contactsState: ContactsStateProps
  setContactsState: (data: ContactsStateProps) => void
  logout: () => void
  history: RouteComponentProps["history"]
}

export const getContacts = ({
  contactsState,
  setContactsState,
  logout,
  history
}: getContactsProps) => {
  setContactsState({ ...contactsState, pending: true })

  betRequest({
    method: `GET`,
    url: `v1/contacts`,
    history,
    logout
  })
    .then(({ data }: { data: ContactsStateDataProps }) => {
      setContactsState({
        ...contactsState,
        pending: false,
        data,
        reloadPage: false
      })
    })
    .catch(() =>
      setContactsState({
        ...contactsState,
        pending: false,
        reloadPage: false
      })
    )
}

export const getHrefFromSectionId = (id: number): string => `#${id}`

/* Ссылка на whatsapp должна быть без знака "+" (желательно, но работает и с "+") */
export const convertPhoneToWhatsApp = (phone: string) =>
  phone.replace(/\+/g, "")

export const findSectionPosition = (contactsState: ContactsStateProps) =>
  contactsState.data.findIndex(section => {
    return section.section_id === contactsState.editSectionId
  })

export const findBlockPosition = ({
  sectionPosition,
  contactsState
}: {
  sectionPosition: number
  contactsState: ContactsStateProps
}) => {
  return contactsState.data[sectionPosition].blocks.findIndex(block => {
    return block.block_id === contactsState.editBlockId
  })
}

export const findStaffPosition = ({
  sectionPosition,
  blockPosition,
  contactsState
}: {
  sectionPosition: number
  blockPosition: number
  contactsState: ContactsStateProps
}) => {
  return contactsState.data[sectionPosition].blocks[
    blockPosition
  ].staff.findIndex(person => {
    return person.staff_id === contactsState.editStaffId
  })
}

export const sectionRequest = ({
  sectionState,
  contactsState,
  setContactsState,
  logout,
  history
}: {
  contactsState: ContactsStateProps
  sectionState: ContactsSectionModalProps
  setContactsState: (data: ContactsStateProps) => void
  logout: () => void
  history: RouteComponentProps["history"]
}) => {
  let requestBody = {
    title:
      sectionState.title && sectionState.title.trim()
        ? sectionState.title.trim()
        : null,
    subtitle:
      sectionState.subtitle && sectionState.subtitle.trim()
        ? sectionState.subtitle.trim()
        : null,
    map_link:
      sectionState.address && sectionState.address.trim()
        ? sectionState.address.trim()
        : null,
    tab_anchor:
      sectionState.tabAnchor && sectionState.tabAnchor.trim()
        ? sectionState.tabAnchor.trim()
        : null,
    description:
      sectionState.description && sectionState.description.trim()
        ? sectionState.description.trim()
        : null,
    icon: sectionState.icon,
    order_number: sectionState.orderNumber
  }

  if (contactsState.method === "POST") {
    /* Найдем последний раздел (с максимальным order_number) */
    const orderNumbers = contactsState.data.map(object => {
      return object.order_number
    })

    const maxSectionId = !!orderNumbers.length ? Math.max(...orderNumbers) : 0

    /* Новый раздел будет располгаться за последним */
    requestBody = { ...requestBody, order_number: maxSectionId + 1 }
  }

  if (contactsState.method === "PUT") {
    requestBody = { ...requestBody, icon: sectionState.icon }
  }

  betRequest({
    method: contactsState.method,
    url: contactsState.url,
    history,
    logout,
    requestBody
  })
    .then(() => {
      setContactsState({
        ...contactsState,
        sectionPending: false,
        activeModal: false,
        editSectionId: null,
        reloadPage: true,
        sectionErrors: []
      })
    })
    .catch(({ response }) => {
      setContactsState({
        ...contactsState,
        sectionPending: false,
        sectionErrors: response.data.errors
      })
    })
}

type orderRequestProps = {
  section_id: number
  order_number: number
}[]

export const orderRequest = ({
  newData,
  setContactsState,
  contactsState,
  logout,
  history
}: {
  newData: orderRequestProps
  contactsState: ContactsStateProps
  setContactsState: (data: ContactsStateProps) => void
  logout: () => void
  history: RouteComponentProps["history"]
}) => {
  betRequest({
    method: "PUT",
    url: "v1/admin/contacts/section/order",
    history,
    logout,
    requestBody: newData
  }).then(() => {
    setContactsState({
      ...contactsState,
      reloadPage: true,
      blockErrors: []
    })
  })
}

export const blockRequest = ({
  blockState,
  contactsState,
  setContactsState,
  logout,
  history
}: {
  contactsState: ContactsStateProps
  blockState: ContactsBlockModalProps
  setBlockState: (data: ContactsBlockModalProps) => void
  setContactsState: (data: ContactsStateProps) => void
  logout: () => void
  history: RouteComponentProps["history"]
}) => {
  const requestBody = {
    title:
      blockState.title && blockState.title.trim()
        ? blockState.title.trim()
        : null,
    description:
      blockState.description && blockState.description.trim()
        ? blockState.description.trim()
        : null,
    section_id: blockState.sectionId ? blockState.sectionId : null
  }

  betRequest({
    method: contactsState.method,
    url: contactsState.url,
    history,
    logout,
    requestBody
  })
    .then(() => {
      setContactsState({
        ...contactsState,
        blockPending: false,
        activeModal: false,
        editBlockId: null,
        editSectionId: null,
        reloadPage: true,
        blockErrors: []
      })
    })
    .catch(({ response }) => {
      localStorage.removeItem(blockStateStorageName)
      setContactsState({
        ...contactsState,
        blockPending: false,
        blockErrors: response.data.errors
      })
    })
}

const prepareField = (param: string) => (!!param && param.trim()) || null

export const staffRequest = ({
  staffState,
  contactsState,
  setContactsState,
  logout,
  history
}: {
  staffState?: ContactsStaffModalProps
  contactsState: ContactsStateProps
  setContactsState: (data: ContactsStateProps) => void
  logout: () => void
  history: RouteComponentProps["history"]
}) => {
  const requestBody = staffState
    ? {
        name: prepareField(staffState.name),
        position: prepareField(staffState.position),
        phone: clearPhone(prepareField(staffState.phone)),
        phone2: clearPhone(prepareField(staffState.phone2)),
        email: prepareField(staffState.email),
        whatsapp: clearPhone(prepareField(staffState.whatsapp)),
        telegram: prepareField(staffState.telegram),
        office_hours: prepareField(staffState.office_hours),
        extension_number: prepareField(staffState.extension_number),
        extension_number2: prepareField(staffState.extension_number2),
        block_id: contactsState.editBlockId ? contactsState.editBlockId : null
      }
    : null

  betRequest({
    method: contactsState.method,
    url: contactsState.url,
    history,
    logout,
    requestBody
  })
    .then(response => {
      const sectionPosition = findSectionPosition(contactsState)
      const blockPosition = findBlockPosition({
        sectionPosition,
        contactsState
      })
      /* Собираем новый contactsState.data */
      const newData = [...contactsState.data]

      if (contactsState.method === "DELETE") {
        setContactsState({
          ...contactsState,
          staffPending: false,
          isStaffEditing: false,
          isBlockEditing: false,
          editStaffId: null,
          activeModal: false,
          reloadPage: true,
          staffErrors: []
        })
        localStorage.removeItem(blockStateStorageName)
      } else if (contactsState.method === "POST" && staffState) {
        const blockStateStorage = JSON.parse(
          localStorage.getItem(blockStateStorageName) || "{}"
        )

        if (Object.keys(blockStateStorage).length !== 0) {
          blockStateStorage.staff = [
            ...blockStateStorage.staff,
            {
              ...requestBody,
              staff_id: response.data.id
            }
          ]
        }

        localStorage.setItem(
          blockStateStorageName,
          JSON.stringify(blockStateStorage)
        )

        setContactsState({
          ...contactsState,
          staffPending: false,
          isStaffEditing: false,
          isBlockEditing: true,
          editStaffId: null,
          reloadPage: true,
          staffErrors: []
        })
      } else if (contactsState.method === "PUT" && staffState) {
        localStorage.removeItem(blockStateStorageName)
        const staffPosition = findStaffPosition({
          sectionPosition,
          blockPosition,
          contactsState
        })
        newData[sectionPosition].blocks[blockPosition].staff[staffPosition] = {
          staff_id: staffState.staff_id,
          name: staffState.name,
          position: staffState.position,
          phone: staffState.phone,
          phone2: staffState.phone2,
          extension_number: staffState.extension_number,
          extension_number2: staffState.extension_number2,
          email: staffState.email,
          whatsapp: staffState.whatsapp,
          telegram: staffState.telegram,
          office_hours: staffState.office_hours
        }

        setContactsState({
          ...contactsState,
          data: newData,
          staffPending: false,
          isStaffEditing: false,
          isBlockEditing: true,
          editStaffId: null,
          reloadPage: true,
          staffErrors: []
        })
      }
    })
    .catch(({ response }) => {
      localStorage.removeItem(blockStateStorageName)
      setContactsState({
        ...contactsState,
        staffPending: false,
        staffErrors: response.data.errors
      })
    })
}

export const createInitBlockState = (contactsState: ContactsStateProps) => {
  const isBlockEdit = !!contactsState.editBlockId

  let initBlockState: ContactsBlockModalProps = {
    title: "",
    block_id: contactsState.editBlockId,
    description: "",
    sectionId: contactsState.editSectionId,
    sectionTitle: "",
    staff: []
  }

  if (isBlockEdit) {
    const editingSection = contactsState.data.filter(
      section => section.section_id === contactsState.editSectionId
    )

    const editingBlock =
      editingSection[0] &&
      editingSection[0].blocks.filter(
        block => block.block_id === contactsState.editBlockId
      )

    const { title, description, staff } = editingBlock[0]

    initBlockState = {
      ...initBlockState,
      title,
      description,
      sectionTitle: title,
      staff
    }
  }

  const blockStateFromStorage = JSON.parse(
    localStorage.getItem(blockStateStorageName) || "{}"
  )
  if (!isEmpty(blockStateFromStorage)) {
    initBlockState = {
      ...initBlockState,
      ...blockStateFromStorage
    }
  }

  return initBlockState
}

/* Метод рендера тел номера с добавочным в скобках */
export const renderPhone = (
  fieldName: keyOfPhoneRenderProps,
  record: ContactsStateDataBlocksStaffSingleProps
) => {
  let extNumber: string
  switch (fieldName) {
    case "phone":
      extNumber = record.extension_number ?? ""
      break
    case "phone2":
      extNumber = record.extension_number2 ?? ""
      break
    default:
      extNumber = ""
  }

  return record[fieldName] ? (
    <div>
      <div>{formatPhone(record[fieldName])}</div>
      {extNumber && <div>(доб. {extNumber})</div>}
    </div>
  ) : (
    ""
  )
}

export const createInitSectionState = (contactsState: ContactsStateProps) => {
  const isSectionEdit = !!contactsState.editSectionId

  let initSectionState: ContactsSectionModalProps = {
    icon: defaultIcon,
    orderNumber: 0,
    title: "",
    subtitle: "",
    address: "",
    tabAnchor: "",
    description: ""
  }

  if (isSectionEdit) {
    const editingSection = contactsState.data.filter(
      section => section.section_id === contactsState.editSectionId
    )

    const {
      order_number,
      icon,
      title,
      subtitle,
      map_link,
      tab_anchor,
      description
    } = editingSection[0]

    initSectionState = {
      ...initSectionState,
      orderNumber: order_number,
      icon,
      title,
      subtitle,
      address: map_link,
      tabAnchor: tab_anchor,
      description: description
    }
  }

  return initSectionState
}

export const createInitStaffState = (contactsState: ContactsStateProps) => {
  const isStaffEdit = !!contactsState.editStaffId

  let initStaffState: ContactsStaffModalProps = {
    email: "",
    extension_number: "",
    extension_number2: "",
    name: "",
    office_hours: "",
    phone: "",
    phone2: "",
    position: "",
    staff_id: 0,
    telegram: "",
    whatsapp: ""
  }

  if (isStaffEdit) {
    const editingSection = contactsState.data.filter(
      section => section.section_id === contactsState.editSectionId
    )

    const editingBlock =
      editingSection[0] &&
      editingSection[0].blocks.filter(
        block => block.block_id === contactsState.editBlockId
      )

    const editingStaff = editingBlock[0].staff.filter(
      staff => staff.staff_id === contactsState.editStaffId
    )

    const {
      email,
      extension_number,
      extension_number2,
      name,
      office_hours,
      phone,
      phone2,
      position,
      staff_id,
      telegram,
      whatsapp
    } = editingStaff[0]

    initStaffState = {
      ...initStaffState,
      email,
      extension_number,
      extension_number2,
      name,
      office_hours,
      phone: !!phone ? formatPhone(phone) : "",
      phone2: !!phone2 ? formatPhone(phone2) : "",
      position,
      staff_id,
      telegram,
      whatsapp: !!whatsapp ? formatPhone(whatsapp) : ""
    }
  }

  return initStaffState
}
