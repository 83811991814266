import React, { useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentHall } from "store/halls/selector"
import { storeHalls } from "store/halls/actions"
import HallPickerModalComponent from "./HallPickerModal"
import { SelectComponent } from "../Select"
import { HallSelectProps } from "./types"

const HallPickerComponent = () => {
  const current_hall = useSelector(getCurrentHall)
  const dispatch = useDispatch()
  const getHalls: () => void = () => dispatch(storeHalls())

  useEffect(() => getHalls(), [])

  const [openModal, setOpenModal] = useState<boolean>(false)

  return (
    <div>
      <div className="HallPickerWrapper" onClick={() => setOpenModal(true)}>
        <div className="HallPickerPoint" />
        <span>{current_hall[0]}</span>
      </div>
      {openModal && (
        <HallPickerModalComponent
          current_hall={current_hall}
          close={() => setOpenModal(false)}
        />
      )}
    </div>
  )
}

export const HallSelect = ({
  value,
  setValue,
  allowClear
}: HallSelectProps) => {
  const optionsByHall = useMemo(() => {
    const halls = JSON.parse(localStorage.getItem(`halls`) || `[]`) as string[]
    return halls.map(item => ({
      label: item,
      value: item
    }))
  }, [])
  return (
    <SelectComponent
      options={optionsByHall}
      allowClear={!!allowClear}
      value={value}
      onChange={value => {
        if (setValue) {
          setValue(value)
        }
      }}
    />
  )
}

export default HallPickerComponent
