import React, { useEffect, useState } from "react"

import { CashboxFilterData } from "../types"

import { ButtonPrimary } from "Components/Button"
import InfoPanelComponent from "Components/FilterInfoPanel"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"
import {
  getFilterStateFromLocalStorage,
  saveFilterInLocalStorage
} from "api/helpers"
import { Checkbox } from "antd"
import { CashboxFilterState } from "./types"
import {
  CASHBOX_FILTER_NAME,
  CashBoxFilterOptions,
  CashBoxFilterOptionsCanary
} from "./helper"
import { useSelector } from "react-redux"
import { getTransferHalls } from "store/dictionary/selector"

const CheckboxGroup = Checkbox.Group

const CashboxFilter: React.FC<CashboxFilterState> = ({
  filterState,
  setFilterState,
  current_hall,
  mobileFilterOpen,
  setMobileFilterOpen
}) => {
  const [filter, setFilter] = useState<CashboxFilterData>(
    getFilterStateFromLocalStorage(filterState.data, CASHBOX_FILTER_NAME)
  )
  const [isShowFilter, setIsShowFilter] = useState(false)
  const transferHalls = useSelector(getTransferHalls)

  useEffect(() => {
    if (transferHalls.length && filter.halls.length) {
      setIsShowFilter(
        transferHalls.some(el => filter.halls.includes(String(el)))
      )
    }
  }, [filter.halls, transferHalls])

  return (
    <div
      className={
        mobileFilterOpen ? `MainFilterMobile` : `CashboxFilterComponentWrapper`
      }
    >
      <div
        className="MainFilterMobileClose"
        onClick={() => {
          if (mobileFilterOpen && setMobileFilterOpen) {
            setMobileFilterOpen(false)
          }
        }}
      >
        <CloseIcon />
      </div>
      <div className="MainFilterHeader">Фильтры</div>
      <div className="MainFilterContent">
        <InfoPanelComponent<CashboxFilterData>
          reportType={
            <div>
              <span className="FilterContentLabel">Тип отчёта</span>
              <div className="CashboxFilterContent">
                <CheckboxGroup
                  className="GamesListFilterCheckboxGroupWrapper"
                  value={filter.types}
                  options={
                    isShowFilter
                      ? CashBoxFilterOptionsCanary
                      : CashBoxFilterOptions
                  }
                  onChange={value => {
                    setFilter({
                      ...filter,
                      types: value
                    })
                  }}
                />
              </div>
            </div>
          }
          filterHalls={filter.halls}
          filter={filter}
          setFilter={setFilter}
          current_hall={current_hall}
        />
      </div>
      <div className="MainFilterApplyWrapper">
        <ButtonPrimary
          disabled={
            !filter.dates || !filter.halls.length || !filter.types?.length
          }
          onClick={() => {
            setFilterState({
              ...filterState,
              data: filter,
              isActive: true
            })
            saveFilterInLocalStorage(filter, CASHBOX_FILTER_NAME)
            if (mobileFilterOpen && setMobileFilterOpen) {
              setMobileFilterOpen(false)
            }
          }}
        >
          Применить
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default CashboxFilter
