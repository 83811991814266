import React, { useState } from "react"

import { useRequest } from "api/hooks"
import { isMobile, valueToCurrency } from "constants/index"

import ModalComponent from "Components/Modal"
import { CurrencyInputComponent } from "Components/Input"
import SliderComponent from "Components/Slider"
import { ButtonPrimary } from "Components/Button"

import { ReactComponent as CloseIcon } from "Components/icons/close_28.svg"
import { ReactComponent as AlertIcon } from "Components/icons/alert_20.svg"
import { ReactComponent as RubIcon } from "Components/icons/rub_icon.svg"

import { increaseModalText } from "./constants"

import { IncreaseModalProps } from "./types"

const IncreaseModal = ({
  data,
  is_vip,
  close,
  hall,
  success
}: IncreaseModalProps) => {
  const text = increaseModalText(data, is_vip)
  const [increase, setIncrease] = useState<string | undefined | number>(
    Number(text.canAddValue)
  )

  // prepare currency format to usual Number
  const slideValue = !!increase ? Number(`${increase}`.replaceAll(` `, ``)) : 0

  const { request: makeIncrease } = useRequest({
    url: `v1/superwins/increase`,
    requestBody: {
      hall,
      rng_id: data.rng_id,
      nominal: data.nominal,
      is_vip,
      sum: Number(`${increase}`.replaceAll(` `, ``))
    }
  })

  const disableIncreaseButton =
    (!increase && !text.daySum && !text.maxSum) || increase === `0`

  return (
    <ModalComponent width={500} withMargin={true}>
      <div className="IncreaseModalWrapper FlexColumn Gap16">
        <div className="FlexRow">
          <div
            className={isMobile ? `HeadlineThirdText` : `HeadlineSecondText`}
          >
            {text.title}
          </div>
          <div className="IncreaseModalTitleClose HoverItem" onClick={close}>
            <CloseIcon />
          </div>
        </div>
        <div className="IncreaseModalAlertArea">
          <div className="FlexRow Gap8">
            <AlertIcon />
            <div className="BodySecondText">{text.alert}</div>
          </div>
          {text.increaseRestriction}
          {text.limit}
          {text.canAdd}
        </div>
        <div className="IncreaseModalTable FlexColumn">
          <div className="FlexRow IncreaseModalTableFirstRow">
            <div className="FlexColumn IncreaseModalTableItem">
              <div className="Overline">{text.area}</div>
              <div className="SubtitleSecondText">{text.areaValue}</div>
            </div>
            <div className="FlexColumn IncreaseModalTableItem">
              <div className="Overline">{text.nominal}</div>
              <div className="SubtitleSecondText">{text.nominalValue}</div>
            </div>
          </div>
          <div className="FlexColumn IncreaseModalTableItem">
            <div className="Overline">{text.current}</div>
            <div className="SubtitleSecondText">{text.currentValue}</div>
          </div>
        </div>
        {!text.daySum && !text.maxSum && (
          <div className="FlexColumn Gap8">
            <div className="BodyFirstText">{text.increaseLabel}</div>
            <div>
              <CurrencyInputComponent
                value={increase}
                suffix={<RubIcon />}
                setValue={value => {
                  if (!value) {
                    setIncrease(undefined)
                  } else {
                    const restriction = text.canAddValue
                    const valNumber = Number(`${value}`.replaceAll(` `, ``))
                    const setValue =
                      valNumber <= Number(restriction) ? value : increase
                    setIncrease(setValue)
                  }
                }}
              />
            </div>
            <div className="SuperwinIncreaseSliderWrapper">
              <SliderComponent
                value={slideValue}
                min={0}
                max={Number(text.canAddValue)}
                onChange={value => {
                  const currency = valueToCurrency({
                    value,
                    withoutFixed: true,
                    withoutZero: true
                  })
                  setIncrease(currency)
                }}
              />
            </div>
          </div>
        )}
        {text.maxSum && (
          <div className="IncreaseModalLimit">
            <div className="IncreaseModalLimitText">{text.limitAlert}</div>
          </div>
        )}
        {text.daySum && !text.maxSum && (
          <div className="IncreaseModalLimit">
            <div className="IncreaseModalLimitText TextOne">
              {text.dateAlert}
            </div>
          </div>
        )}
        <div className="IncreaseModalApply">
          <ButtonPrimary
            onClick={() => {
              if (!!increase) {
                makeIncrease().then(() => {
                  success({ nominal: data.nominal, sum: increase })
                  close()
                })
              } else {
                close()
              }
            }}
            disabled={disableIncreaseButton}
          >
            {text.apply}
          </ButtonPrimary>
        </div>
      </div>
    </ModalComponent>
  )
}

export default IncreaseModal
