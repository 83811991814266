import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { message } from "antd"
import { UploadFile } from "antd/es/upload/interface"

import moment from "moment"
import { isEmpty } from "lodash"

import { roleConstants } from "constants/index"

import { ReactComponent as CameraIcon } from "./icons/camera.svg"
import { ReactComponent as EditIcon } from "../icons/edit.svg"

import ButtonDefault, { ButtonPrimary } from "Components/Button"

import UploaderComponent from "./Uploader"
import {
  cartApiMethods,
  generateInitialList,
  getCardStyleByStatus,
  useStoreMethods
} from "./helper"
import { cardConstants, statusBadge } from "./constants"
import { GuidebookCardProps } from "./types"

import { ComponentByStatus, textConstants } from "../constants"

import { getList } from "../store/main"

const GuidebookCard = ({
  data,
  count,
  setEditCardId,
  editCardId,
  setModalData
}: GuidebookCardProps) => {
  const sectionId = data.id
  const { isAdmin } = roleConstants()

  const title = useMemo(() => `${count}. ${data.title}`, [count])

  const isMobile = window.innerWidth < 921

  // eslint-disable-next-line
  const [newAttachList, setNewAttachList] = useState<UploadFile[]>([])

  const {
    addImg,
    removeImg,
    switchImg,
    considerationSection,
    uploadVideo
  } = cartApiMethods()

  const {
    switchSectionChange,
    storeImg,
    considerationSectionAttach,
    deleteImg
  } = useStoreMethods()

  const isEditMode = editCardId === sectionId
  const showPen = !editCardId

  const cardStyleByStatus = getCardStyleByStatus(data.status)
  const list = useSelector(getList)

  const isFirstCreate = !list.status

  const info = list.info[sectionId]
  const isVideoSection = data.uploadType === `video`
  const attachments = info.attachments
  const cardAttachEmpty = attachments && isEmpty(attachments)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const initialUploadImg: UploadFile[] = useMemo(() => {
    return (Object.values(attachments) || []).map((item, index) => {
      return generateInitialList({
        isVideoSection,
        item,
        attach: statusBadge[attachments[item.id]?.status || null],
        index: index + 1,
        rootTitle: data.title,
        date: item.created,
        preview: item.preview,
        old: item?.old
      })
    })
  }, [attachments])

  return (
    <div
      className={`GuidebookCardWrapper ${
        isMobile ? "GuidebookCardWrapperMobile" : ""
      }`}
      style={{ ...cardStyleByStatus }}
    >
      <div className="FlexRow">
        <div
          className={`GuidebookCardTitle ${
            isMobile ? "FlexColumn" : "FlexRow"
          }`}
        >
          <div className="GuidebookCardTitleTextStatusWrapper">
            <div className="HeadlineThirdText">{title}</div>
            {!!info.status && (
              <ComponentByStatus status={info.status} isSection={true} />
            )}
          </div>
          {!!info.updatedDate && (
            <div
              className={`FlexRow GuidebookTitleDateDescription ${
                isMobile ? "GuidebookTitleDateDescriptionMobile" : ""
              }`}
            >
              <span>
                {moment(info.updatedDate).format(textConstants.dateFormat)}
              </span>
              <span className="GuidebookTitleTimeDescription">
                {moment(info.updatedDate).format(textConstants.timeFormat)}
              </span>
            </div>
          )}
        </div>
        {!isAdmin && (
          <div className="GuidebookCardEditToggle">
            {showPen && (
              <ButtonDefault onClick={() => setEditCardId(sectionId)}>
                <EditIcon />
              </ButtonDefault>
            )}
            {isEditMode && info.haveChanges && (
              <ButtonPrimary
                onClick={() => {
                  if (
                    !isVideoSection &&
                    Object.values(attachments).length < data.minCount
                  ) {
                    message.error(
                      `Минимальное количество фотографий в этой секции - ${data.minCount}`
                    )
                  } else {
                    considerationSection({ section: sectionId })
                      .then(({ data }) => {
                        considerationSectionAttach({ sectionId, info: data })
                        setEditCardId(0)
                      })
                      .catch(error => {
                        message.error(
                          error.response?.data?.errors ||
                            cardConstants.saveError
                        )
                      })
                  }
                }}
              >
                <span>{cardConstants.considerationText}</span>
              </ButtonPrimary>
            )}
            {isEditMode && !info.haveChanges && (
              <ButtonDefault onClick={() => setEditCardId(0)}>
                <span>{cardConstants.exitText}</span>
              </ButtonDefault>
            )}
          </div>
        )}
      </div>
      <div className="GuidebookCardDescription">{data.description}</div>
      <div className="FlexRow GuidebookCardExample">
        {!isVideoSection && (
          <div
            className={"GuidebookCardExampleTextAndIcon"}
            onClick={() => {
              setModalData({
                visible: true,
                link: data.example.link,
                exampleText: "образец",
                rootTitle: data.title,
                isExample: true
              })
            }}
          >
            <CameraIcon />
            <div className="GuidebookCardExampleLink ButtonTextSecond">
              {cardConstants.example}
            </div>
          </div>
        )}
        {!isMobile && (
          <div className="GuidebookCardExampleDescription CaptionText">
            {data.attachmentsDescription}
          </div>
        )}
      </div>
      <div className="GuidebookCardAttachments FlexRow">
        <UploaderComponent
          sectionId={sectionId}
          isVideoSection={isVideoSection}
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          initial={initialUploadImg}
          large={cardAttachEmpty}
          setImages={setNewAttachList}
          isEditMode={!isAdmin && (isEditMode || isFirstCreate)}
          removeReq={(id: number) =>
            removeImg({ attachment: id, section: data.id }).then(response => {
              deleteImg({ sectionId, imgId: id })
              return response
            })
          }
          changeReq={(
            id: number,
            nextData: { uri: string; type: string; name: string }
          ) =>
            switchImg({
              attachment: id,
              section: data.id,
              data: nextData
            }).then(response => {
              switchSectionChange({
                sectionId: data.id,
                value: true,
                attach: response.data
              })
              return response
            })
          }
          addReq={data =>
            addImg({ section: sectionId, data }).then(response => {
              storeImg({ sectionId, attach: response.data })
              return response
            })
          }
          onAttachClick={data => {
            setModalData({
              visible: true,
              ...data
            })
          }}
          uploadVideo={data =>
            uploadVideo({ section: sectionId, body: data }).then(res => {
              return res
            })
          }
        />
      </div>
    </div>
  )
}

export default GuidebookCard
