import React from "react"

import moment, { Moment } from "moment"

import { GetDateByDigitalTypes, GetDateDescriptionLayout } from "./type"
import { ReactComponent as CalendarIcon } from "Components/icons/calendar_outline_20.svg"
import { GameReportStateDataType } from "../Containers/Games/types"

export const DEFAULT_SHIFT = 8
export const DEFAULT_SHIFT_END = 20
export const MOSCOW_GMT = "3"
export const MOSCOW_GMT_VALUE = Number(MOSCOW_GMT)
export const SHIFT_LIMIT_MIN = 0
export const SHIFT_LIMIT_MAX = 23
export const HOURS_PER_DAY = 24

export const gameKinds = {
  BINGO1: 1,
  SEABATTLE1: 2,
  LUCKYLINE: 3,
  BINGO2: 4,
  SEABATTLE2: 5,
  TENNIS80: 6,
  TENNIS_GOLD: 7,
  POCKER: 8,
  FRUITS: 9,
  TENNIS1: 11,
  TENNIS_BATTLE1: 12,
  LUCKY_LINE: 13,
  TENNIS2: 14,
  TENNIS_BATTLE2: 15,
  TENNIS37: 18,
  B37_V2: 19,
  WHEEL: 20,
  TENNIS38: 23,
  LIVE_RACE: 107
}

export const gameKindsTranslator = {
  [gameKinds.BINGO1]: "Bingo1",
  [gameKinds.SEABATTLE1]: "SeaBattle1",
  [gameKinds.LUCKYLINE]: "LuckyLine",
  [gameKinds.BINGO2]: "Bingo2",
  [gameKinds.SEABATTLE2]: "SeaBattle2",
  [gameKinds.TENNIS80]: "Теннис 80",
  [gameKinds.TENNIS_GOLD]: "Теннис Gold",
  [gameKinds.POCKER]: "Poker",
  [gameKinds.FRUITS]: "Fruits",
  [gameKinds.TENNIS1]: "Теннис 1",
  [gameKinds.TENNIS_BATTLE1]: "Теннисный бой 1",
  [gameKinds.LUCKY_LINE]: "lucky_line",
  [gameKinds.TENNIS2]: "Теннис 2",
  [gameKinds.TENNIS_BATTLE2]: "Теннисный бой 2",
  [gameKinds.TENNIS37]: "Теннис 37",
  [gameKinds.B37_V2]: "b37_v2",
  [gameKinds.WHEEL]: "wheel",
  [gameKinds.TENNIS38]: "Теннис 38",
  [gameKinds.LIVE_RACE]: "LiveRace"
}

export const isMobile = window.innerWidth < 921
export const isLaptop = window.innerWidth < 1366
export const datesFormat = {
  dayMonthYear: "DD.MM.YYYY",
  dayMonthYearTime: "DD.MM.YYYY HH:mm"
}

export enum CanaryReleases {
  transfer = "transfer"
}

export const roleConstants = () => {
  const userData = localStorage.getItem(`user`) || ``
  const user = userData ? JSON.parse(userData) : undefined
  const role = user?.role

  const isSupport = role === `COMPANY-SUPPORT`

  const isAdmin =
    role === `COMPANY-LOGIST` ||
    isSupport ||
    role === `COMPANY-DEVELOPER` ||
    role === `COMPANY-MANAGER` ||
    role === `COMPANY-DESIGNER` ||
    role === `COMPANY-RISK-MANAGEMENT` ||
    role === `COMPANY-FINANCE` ||
    role === `COMPANY-INVEST` ||
    role === `COMPANY-CONTRACTOR` ||
    role === `COMPANY-ANALYTICS`

  // TODO remove after canary
  const isAccessCanary =
    isSupport || role === `COMPANY-DEVELOPER` || role === `PARTNER` || isSupport

  const isCanary =
    isSupport || role === `COMPANY-DEVELOPER` || role === `COMPANY-FINANCE`

  const isSportBoomWalletFeature =
    role === `PARTNER` ||
    `PARTNER-EKT` ||
    role === `COMPANY-RISK-MANAGEMENT` ||
    isSupport

  const isRiskManagement = role === `COMPANY-RISK-MANAGEMENT` || isSupport

  return {
    role,
    isAdmin,
    isCanary,
    isAccessCanary,
    isSportBoomWalletFeature,
    isRiskManagement,
    isSupport
  }
}

export const checkRole = (role: string) => {
  const userData = localStorage.getItem(`user`) || ``
  const user = userData ? JSON.parse(userData) : undefined
  const current = user?.role

  return !!current && current === role
}

export const checkArrayRole = (roles: string[]) => {
  const userData = localStorage.getItem(`user`) || ``
  const user = userData ? JSON.parse(userData) : undefined
  const current = user?.role

  return !!roles?.includes(current)
}

export const checkNegative = {
  render: (value: string) => (
    <div className={`${value}`.includes(`-`) ? `TableItemNegativeValue` : ``}>
      {value}
    </div>
  )
}

export const checkNegativeWithShow = {
  render: (value: string, record: GameReportStateDataType) => {
    const classByShowKey = record.showExpectedIncome ? `` : `PaddingLeft64`
    const classByNegative = `${value}`.includes(`-`)
      ? `TableItemNegativeValue`
      : ``
    return (
      <div className={`${classByNegative} ${classByShowKey}`}>
        {record.showExpectedIncome ? value : `-`}
      </div>
    )
  }
}

// info about hall address
export const getInfoContent = ({ hall }: { hall: string }) => {
  const userData = localStorage.getItem(`user`) || ``
  const user: {
    hallsAddress: {
      [key: string]: {
        full_address: string
      }
    }
  } = userData ? JSON.parse(userData) : userData
  const currentAddress =
    user && user.hallsAddress && user.hallsAddress[hall]
      ? user.hallsAddress[hall].full_address
      : `` || `Адрес не найден`
  return (
    <div className="CashboxInsidePopoverInfo Shark500Color Padding8">
      {currentAddress}
    </div>
  )
}

// parse moment dates to format: 2021-03-30T00:00:00.000+03:00
export const parseOneDateToBack = ({
  date,
  gmt = `3`
}: {
  date: Moment
  gmt?: string
}) => {
  const gmtForFormat = Number(gmt) > 9 ? gmt : `0${gmt}`
  const gmtSymbolForFormat = Number(gmt) > 0 ? `+` : `-`
  return `${moment(date).format(`YYYY-MM-DD`)}T${moment(date).format(
    `HH:mm`
  )}:00.000${gmtSymbolForFormat}${gmtForFormat}:00`
}

export const parseOneDateWithoutGmtToBack = (dates: {
  from: string | Moment
  to: string | Moment
}) => {
  return [
    moment(dates.from).format(`YYYY-MM-DD`),
    moment(dates.to).format(`YYYY-MM-DD`)
  ]
}

export const parseDateByShift = ({
  isShift,
  dates,
  shift
}: {
  isShift: boolean
  dates: [Moment, Moment]
  shift: [number, number]
}): [Moment, Moment] =>
  isShift
    ? dates
    : [dates[0], moment(dates[1]).add(shift[0] >= shift[1] ? 1 : 0, `day`)]

export const parseDateToBack = ({
  date,
  gmt,
  objDate,
  isObj,
  isFromTo
}: {
  oneDate?: Moment | null
  date?: [Moment, Moment] | null
  objDate?: { from: Moment; to: Moment } | null
  gmt?: string
  isObj?: boolean
  isFromTo?: boolean
}):
  | string[]
  | { start: string; end: string }
  | { from: string; to: string } => {
  const haveGmt = gmt || `3`
  let startDate
  let startEnd

  if (!!date) {
    startDate = date[0]
    startEnd = date[1]
  }
  if (!!objDate) {
    startDate = objDate.from
    startEnd = objDate.to
  }

  if ((date || objDate) && haveGmt) {
    const gmtForFormat = Number(haveGmt) > 9 ? haveGmt : `0${haveGmt}`
    const gmtSymbolForFormat = Number(haveGmt) > 0 ? `+` : `-`
    const firstDateForFormat = `${moment(startDate).format(
      `YYYY-MM-DD`
    )}T00:00:00.000${gmtSymbolForFormat}${gmtForFormat}:00`
    const secondDateForFormat = `${moment(startEnd).format(
      `YYYY-MM-DD`
    )}T23:59:59.000${gmtSymbolForFormat}${gmtForFormat}:00`
    const isObjFormat = isFromTo
      ? { from: firstDateForFormat, to: secondDateForFormat }
      : { start: firstDateForFormat, end: secondDateForFormat }
    return isObj ? isObjFormat : [firstDateForFormat, secondDateForFormat]
  }
  return isObj ? { start: ``, end: `` } : []
}

export const parseDateToBackNewConventions = ({
  date,
  gmt,
  isObj
}: {
  date: [Moment, Moment] | null | undefined
  gmt: string
  isObj?: boolean
}): string[] | { from: string; to: string } => {
  if (date && gmt) {
    const gmtForFormat = Number(gmt) > 9 ? gmt : `0${gmt}`
    const gmtSymbolForFormat = Number(gmt) > 0 ? `+` : `-`
    const firstDateForFormat = `${moment(date[0]).format(
      `YYYY-MM-DD`
    )}T00:00:00.000${gmtSymbolForFormat}${gmtForFormat}:00`
    const secondDateForFormat = `${moment(date[1]).format(
      `YYYY-MM-DD`
    )}T23:59:59.000${gmtSymbolForFormat}${gmtForFormat}:00`
    return isObj
      ? { from: firstDateForFormat, to: secondDateForFormat }
      : [firstDateForFormat, secondDateForFormat]
  }
  return isObj ? { from: ``, to: `` } : []
}

export const wordsByNumber = (value: number, words: Array<string>): any => {
  if ((value > 4 && value <= 20) || value === 0) return words[2]
  if (value > 1 && value < 5) return words[1]
  if (value == 1) return words[0]
  if (value > 20) return wordsByNumber(Number(`${value}`.slice(-1)), words)
}

export const getUserFromStorage = (field: string) => {
  const storageUser = localStorage.getItem(`user`) || ``
  const user = JSON.parse(storageUser)
  if (!user) return null
  else {
    return !!field ? user[field] : user
  }
}

export const countCurrency = (str: string | number | null) => {
  if (!str && str != 0) return { superMainPart: ``, decimalsPart: `` }
  if (str == 0) return { superMainPart: `0`, decimalsPart: `` }
  let value = str
  if (typeof str === "string") {
    value = str.replaceAll(" ", "").replace(`,`, `.`)
  }
  const splitFloat = (value: number) => {
    const superMainPart = `${Math.trunc(value)}`
    const decimalsPart = `${Math.abs(value % 1)
      .toFixed(2)
      .slice(2)}`
    return {
      superMainPart,
      decimalsPart
    }
  }

  const { superMainPart, decimalsPart } = splitFloat(Number(value))

  return { superMainPart, decimalsPart }
}

export const currencyString = (str: string | number | null) => {
  const { superMainPart, decimalsPart } = countCurrency(str)
  return `${superMainPart}, ${decimalsPart}`
}

export const valueToCurrency = ({
  value,
  withoutFixed,
  withoutSpace,
  withoutZero,
  customEmpty
}: {
  value: number
  withoutFixed?: boolean
  withoutSpace?: boolean
  withoutZero?: boolean
  customEmpty?: any
}) => {
  const emptyValue = customEmpty ? customEmpty : 0
  const replaceValue = withoutSpace ? `,` : `, `
  if (!value || value == 0) {
    return emptyValue
  } else {
    const correctValue = Number(`${value}`.replace(`,`, `.`))
    if (!correctValue) {
      return emptyValue
    }
    const fixedValue = correctValue
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$& ")
      .replace(`.`, replaceValue)

    if (withoutFixed) {
      if (fixedValue.includes(`,00`)) {
        return fixedValue?.replace(`,00`, ``)
      } else {
        return fixedValue?.slice(0, -4)
      }
    } else if (withoutZero) {
      return fixedValue?.slice(0, -3)
    } else {
      return fixedValue
    }
  }
}

export const getCurrencyLayout = (
  str: string | number | null,
  hideDecimal?: boolean
) => {
  const { superMainPart, decimalsPart } = countCurrency(str)
  const main = superMainPart
    ? valueToCurrency({
        value: Number(superMainPart.replaceAll(" ", "")),
        withoutZero: true,
        withoutFixed: true
      })
    : ``
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mainExist = !!superMainPart || superMainPart == 0
  return (
    <div className="DashboardItemCurrencyContainer">
      {mainExist || decimalsPart ? (
        <div>
          <span className="HeadlineSecondText">{main}</span>
          {!hideDecimal && (
            <span className="DashboardItemLabelValueDecimal">
              ,{decimalsPart}
            </span>
          )}
        </div>
      ) : (
        <span className="HeadlineSecondText">{main}</span>
      )}
    </div>
  )
}

export const formatToCurrency = ({
  amount,
  isDash,
  withoutFixed,
  withoutZero,
  withoutSpace,
  customEmpty
}: {
  amount: number
  isDash?: boolean
  withoutFixed?: boolean
  withoutZero?: boolean
  withoutSpace?: boolean
  customEmpty?: any
}) => {
  const currencyString = valueToCurrency({
    value: amount,
    withoutFixed,
    withoutSpace,
    withoutZero,
    customEmpty
  })

  return isDash ? getCurrencyLayout(currencyString) : currencyString
}

const defaultTitle = `За период:`

export const getDateDescriptionLayout = ({
  dates,
  shift = [8, 20],
  isShift,
  show,
  hideTime,
  className,
  hidePeriodTitle,
  calendarIcon,
  datesAsIs = false
}: GetDateDescriptionLayout) => {
  const format = hideTime
    ? datesFormat.dayMonthYear
    : datesFormat.dayMonthYearTime

  const addDay =
    !datesAsIs && ((shift && shift[0] >= shift[1]) || isShift) ? 1 : 0

  let datesDescription: [string, string] = [``, ``]

  if (dates && !datesAsIs) {
    datesDescription = [
      `${moment(dates[0])
        .startOf(`day`)
        .add(shift[0], "hour")
        .format(format)}`,

      `${moment(dates[1])
        .startOf(`day`)
        .add(isShift ? shift[0] : shift[1], "hour")
        .add(addDay, `day`)
        .format(format)}`
    ]
  }

  if (dates && datesAsIs) {
    datesDescription = [
      `${moment(dates[0]).format(format)}`,

      `${moment(dates[1]).format(format)}`
    ]
  }

  return dates && show ? (
    <div className={`FlexRow ContentPeriodWrapper ${className || ""}`}>
      {!hidePeriodTitle && (
        <div className="ContentPeriodDescription">{defaultTitle}</div>
      )}
      {calendarIcon && (
        <div className="CalendarIconDescription">
          <CalendarIcon />
        </div>
      )}
      <div className="FlexRow ContentPeriod">
        <div className="ContentPeriodMain">
          {datesDescription[0].substr(0, 11)}
        </div>
        {!hideTime && (
          <div className="ContentPeriodTime">
            {`${datesDescription[0].substr(11, 5)}`}
          </div>
        )}
        <div
          className={
            hideTime ? "ContentPeriodSeparatorHideTime" : "TextDefaultBold"
          }
        >
          {" "}
          —{" "}
        </div>
        <div className="ContentPeriodMain">
          {datesDescription[1].substr(0, 11)}
        </div>
        {!hideTime && (
          <div className="ContentPeriodTime">
            {`  ${datesDescription[1].substr(11, 5)}`}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div />
  )
}

export const shiftMoscowGmtFirstSingle = (
  gmt: string,
  defaultEnd?: boolean
) => {
  const shift = defaultEnd ? DEFAULT_SHIFT_END : DEFAULT_SHIFT
  return gmt === MOSCOW_GMT ? shift : Number(gmt) - MOSCOW_GMT_VALUE + shift
}

export const shiftMoscowGmtFirst = ({
  gmt,
  isShift,
  alwaysMoscow
}: {
  gmt: string
  isShift?: boolean
  alwaysMoscow?: boolean
}): [number, number] => {
  return isShift
    ? [
        alwaysMoscow ? DEFAULT_SHIFT : shiftMoscowGmtFirstSingle(gmt),
        shiftMoscowGmtFirstSingle(gmt)
      ]
    : [
        alwaysMoscow
          ? DEFAULT_SHIFT
          : shiftMoscowGmtFirstSingle(gmt, alwaysMoscow),
        shiftMoscowGmtFirstSingle(gmt, true)
      ]
}

export const formatPhone = (
  phoneString: string,
  hideBrackets?: boolean
): string => {
  if (!phoneString) return ``
  phoneString = phoneString.replace(/[^0-9\.]+/g, ``)
  const length = phoneString.length

  const countryCode = phoneString.slice(0, 1)
  const areaCode = phoneString.slice(1, 4)
  const nextThreeDigits = phoneString.slice(4, 7)
  const trailerFirst = phoneString.slice(7, 9)
  const trailerSecond = phoneString.slice(9, 11)

  const openBracket = hideBrackets ? "" : "("
  const closedBracket = hideBrackets ? "" : ")"

  switch (length) {
    case 0:
      return ``
    case 1:
      return `+${countryCode}`

    case 2:
    case 3:
    case 4:
      return `+${countryCode} ${openBracket}${areaCode}`

    case 5:
    case 6:
    case 7:
      return `+${countryCode} ${openBracket}${areaCode}${closedBracket} ${nextThreeDigits}`

    case 8:
    case 9:
      return `+${countryCode} ${openBracket}${areaCode}${closedBracket} ${nextThreeDigits}-${trailerFirst}`

    default:
      return `+${countryCode} ${openBracket}${areaCode}${closedBracket} ${nextThreeDigits}-${trailerFirst}-${trailerSecond}`
  }
}

export default wordsByNumber

export const clearPhone = (phone: string | null, replace = false) => {
  if (!phone) return null

  phone = phone.replace(/\D/g, "")

  if (replace && phone[0] === "8") {
    phone = "7" + phone.slice(1)
  }

  return phone
}

export const getDateByDigital = ({
  value,
  type
}: {
  value: number
  type: GetDateByDigitalTypes
}) => {
  let dateArr: string[] = []
  if (type === GetDateByDigitalTypes.minute) {
    dateArr = [`минута`, `минуты`, `минут`]
  }
  if (type === GetDateByDigitalTypes.hour) {
    dateArr = [`час`, `часа`, `часов`]
  }
  if (type === GetDateByDigitalTypes.days) {
    dateArr = [`день`, `дня`, `дней`]
  }
  if (type === GetDateByDigitalTypes.month) {
    dateArr = [`месяц`, `месяца`, `месяцев`]
  }
  if (type === GetDateByDigitalTypes.year) {
    dateArr = [`год`, `года`, `лет`]
  }

  const stringValue = `${value}`
  const lastDigital = stringValue.charAt(stringValue.length - 1)
  const lastDigitalNumber =
    value > 10 && value < 20 ? value : Number(lastDigital)

  if (lastDigitalNumber === 1) {
    return `1 ${dateArr[0]}`
  } else if (1 < lastDigitalNumber && lastDigitalNumber < 5) {
    return `${value} ${dateArr[1]}`
  } else if (5 <= lastDigitalNumber || lastDigitalNumber === 0) {
    return `${value} ${dateArr[2]}`
  }
}

export const moneyRender = (value?: number) =>
  value && new Intl.NumberFormat("ru-RU").format(value)

export const moneyRenderSafe = (value?: number, moneyCurrency?: string) => {
  const money = moneyRender(value)
  if (Number(value) >= 0) {
    return `${money} ${moneyCurrency || ""}`
  }
  return "-"
}

export const formatDateDefault = (date: Moment) =>
  moment(date).format("DD.MM.YYYY")

export const isNumberWithinRange = (value: string) => {
  const inputValue = parseInt(value, 10)

  if (
    isNaN(inputValue) ||
    inputValue < 0 ||
    inputValue > Number.MAX_SAFE_INTEGER
  ) {
    return false
  }

  if (/[^0-9]/.test(value)) {
    return false
  }

  return true
}
