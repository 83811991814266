import React from "react"
import { ReactComponent as Crown } from "./icons/crown.svg"
import { ReactComponent as Hall } from "./icons/hall.svg"
import { ReactComponent as Tennis80 } from "Components/icons/nominals/tennis_80.svg"
import { ReactComponent as Tennis37 } from "Components/icons/nominals/tennis_37.svg"
import { ReactComponent as Tennis38 } from "Components/icons/nominals/tennis_38.svg"
import {
  NominalHistoryStatus,
  NominalSectionKeys,
  NominalsKeys,
  NominalsMinMaxKeys,
  NominalsNew
} from "./types"

export const nominalsText = {
  title: "Номиналы",
  tooltip:
    "Раздел позволяет оставить заявку для согласования на изменение номиналов как в основной зоне клуба, так и в VIP зоне. Изменить номиналы возможно в играх Теннис 80, Теннис 37 и Теннис 38. При желании, можно воспользоваться уже подготовленными шаблонами с рекомендуемыми номиналами. Также в разделе присутствует “История заявок”, где можно отследить статус оставленной заявки.",
  historyTab: "История заявок",
  changeTab: "Изменение номиналов",
  tennis80: "Теннис 80",
  tennis37: "Теннис 37",
  tennis38: "Теннис 38",
  more: "Еще",
  comment: "Комментарий",
  rogerThat: "Понятно",
  change: "Заменить на",
  main: "Основная зона",
  vip: "VIP зона",
  rubles: "₽",
  zoneType: "Тип зоны клуба",
  maxLimit: "Максимальный лимит",
  minLimit: "Минимальный лимит",
  mockTitle: "Шаблоны номиналов",
  mockTooltip:
    "Вы можете применить один из представленных шаблонов. После применения шаблона, значения в выбранной зоне клуба проставляются автоматически с возможностью их скорректировать.",
  bet: "Ставка",
  maxLimitShort: "Макс. лимит",
  minLimitShort: "Мин. лимит",
  nominalColName: "Номинал",
  plural: ["число", "числа", "чисел"],
  templateTitle: "Шаблон №",
  sendButton: "Отправить заявку",
  modalTitle: "Заявка на изменение номиналов",
  fullName: "ФИО",
  fullNamePlaceholder: "Представьтесь, пожалуйста",
  phoneNumber: "Контактный телефон",
  phoneNumberPlaceholder: "+7",
  phoneNumberMask: "+7 (000) 000 00 00",
  sentNominalsText: "Ваша заявка отправлена\n на согласование",
  okBtn: "Хорошо",
  dateNTime: "Дата\nи время",
  reqNumber: "№\nзаявки",
  applicant: "Заявитель",
  phone: "Номер телефона",
  game: "Игра",
  status: "Статус",
  showLimits: "Показать лимиты",
  hideLimits: "Скрыть лимиты"
}

export const sectionsDict = {
  [NominalSectionKeys.vip]: { icon: <Crown />, text: nominalsText.vip },
  [NominalSectionKeys.main]: { icon: <Hall />, text: nominalsText.main }
}

export const tabsIds = {
  [nominalsText.changeTab]: 1,
  [nominalsText.historyTab]: 2
}

export const tennisTabsIds = {
  [nominalsText.tennis80]: 80,
  [nominalsText.tennis37]: 37,
  [nominalsText.tennis38]: 38
}

export const tabsConfig = [
  {
    label: nominalsText.changeTab,
    value: tabsIds[nominalsText.changeTab],
    disabled: false
  },
  {
    label: nominalsText.historyTab,
    value: tabsIds[nominalsText.historyTab],
    disabled: false
  }
]

export const games = [
  {
    label: nominalsText.tennis80,
    id: 6,
    icon: <Tennis80 />
  },
  {
    label: nominalsText.tennis37,
    id: 18,
    icon: <Tennis37 />
  },
  {
    label: nominalsText.tennis38,
    id: 23,
    icon: <Tennis38 />
  }
]

export const initialNominalsNew: NominalsNew = {
  main: {
    nominals: []
  },
  vip: {
    nominals: []
  },
  mergeNeeded: true
}

export const initialNominalsNewMinMax: NominalsNew = {
  main: {
    nominals: [],
    limits: { [NominalsMinMaxKeys.max]: [], [NominalsMinMaxKeys.min]: [] }
  },
  vip: {
    nominals: [],
    limits: { [NominalsMinMaxKeys.max]: [], [NominalsMinMaxKeys.min]: [] }
  },
  mergeNeeded: true
}

export const initialNominalsErrors = {
  [NominalSectionKeys.vip]: {
    nominals: [],
    limits: {
      [NominalsMinMaxKeys.min]: [],
      [NominalsMinMaxKeys.max]: []
    }
  },
  [NominalSectionKeys.main]: {
    nominals: [],
    limits: {
      [NominalsMinMaxKeys.min]: [],
      [NominalsMinMaxKeys.max]: []
    }
  }
}

export const initialModalState = {
  comment: "",
  isActive: false
}

export const initialTableFilters = {
  game_ids: [],
  dates: { from: undefined, to: undefined }
}

export const sections: NominalsKeys[] = [
  NominalSectionKeys.main,
  NominalSectionKeys.vip
]

export const NominalsStatusesDict = {
  [NominalHistoryStatus.CANCELED]: "Отменена",
  [NominalHistoryStatus.PROCESSING]: "На рассмотрении",
  [NominalHistoryStatus.COMPLETED]: "Выполнена"
}
