import React, { useCallback, useState } from "react"

import { Form } from "antd"
import { UploadFile } from "antd/lib/upload/interface"

import { useRequestParams } from "api/hooks"
import betRequest from "api/request"

import { isMobile } from "constants/index"

import { ModalFormComponent } from "Components/Modal"
import { ButtonFaded, ButtonNotifi, ButtonPrimary } from "Components/Button"
import { FilesList } from "Components/File/types"
import { HallSelect } from "Components/HallPicker"
import DatePickerComponent from "Components/DatePicker"
import InputComponent from "Components/Input"
import FileComponent, { UploadComponent } from "Components/File"
import { rules } from "Components/Form/rules"

import { ReactComponent as SuccessIcon } from "Components/icons/illustrations/thumb_up.svg"

import {
  PayrollModalFields,
  PayrollModalFormValues,
  PayrollModalProps
} from "./types"
import {
  modalText,
  initialValues,
  allowedTypes,
  documentNumberRule,
  fileRule,
  prepareDataToSave,
  editPrepareSum
} from "./constants"
import { ReactComponent as CloseIcon } from "./close_28.svg"

const PayrollModal = ({ data, close }: PayrollModalProps) => {
  const isEdit = !!data
  const [form] = Form.useForm()
  const [pending, setPending] = useState(false)
  const {
    developer,
    addDeveloperLink,
    history,
    logout,
    current_hall
  } = useRequestParams()

  const [reqFiles, setReqFiles] = useState<FilesList[]>(data?.files || [])
  const [fileList, changeFileList] = useState<UploadFile[]>([])

  const [isSuccess, toggleSuccess] = useState(false)

  const onFinish = (values: PayrollModalFormValues) => {
    setPending(true)
    const requestBody = prepareDataToSave({
      values,
      files: reqFiles,
      id: data?.id
    })

    betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      method: `POST`,
      url: `v1/requests/payrolls/save`,
      requestBody
    })
      .then(() => {
        setPending(false)
        toggleSuccess(true)
      })
      .catch(() => setPending(false))
  }

  const applyButton = (
    <ButtonPrimary htmlType="submit" disabled={pending}>
      {isEdit ? modalText.apply.save : modalText.apply.add}
    </ButtonPrimary>
  )
  const disableUpload = reqFiles.length === 3
  const hideUploadMobile = isMobile ? disableUpload : false

  const [cancelEdit, setCancelEdit] = useState(false)

  const closeModal = useCallback(() => {
    if (isEdit) {
      setCancelEdit(true)
    } else {
      close()
    }
  }, [])

  return (
    <div>
      {!cancelEdit ? (
        <ModalFormComponent width={880} withoutCross={true} close={closeModal}>
          {!isSuccess ? (
            <div className="PayrollModalWrapper FlexColumn Gap24">
              <div
                className={isMobile ? `HeadlineThirdText` : `HeadlineFirstText`}
              >
                {isEdit ? modalText.title_edit : modalText.title_add}
              </div>
              <div className="PayrollModalClose HoverItem" onClick={closeModal}>
                <CloseIcon />
              </div>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={
                  {
                    ...data,
                    [PayrollModalFields.sum]: editPrepareSum(data?.sum || ``)
                  } || {
                    ...initialValues,
                    [PayrollModalFields.hall]: Number(current_hall[0])
                  }
                }
                autoComplete="off"
              >
                <div className="PayrollModalContentWrapper FlexColumn">
                  <div
                    className={`PayrollModalFormFirstRow ${
                      isMobile ? `FlexColumn` : `FlexRow Gap24`
                    }`}
                  >
                    <Form.Item
                      name={PayrollModalFields.hall}
                      label={
                        <span className="SubtitleFirstText">
                          {modalText[PayrollModalFields.hall]}
                        </span>
                      }
                    >
                      <HallSelect
                        value={form.getFieldValue(`hall`)}
                        setValue={value => {
                          form.setFieldValue(`hall`, value)
                        }}
                        allowClear={false}
                      />
                    </Form.Item>
                    <Form.Item
                      name={PayrollModalFields.billing_period}
                      label={
                        <span className="SubtitleFirstText">
                          {modalText[PayrollModalFields.billing_period]}
                        </span>
                      }
                    >
                      <DatePickerComponent picker="month" format="MMMM YYYY" />
                    </Form.Item>
                    <Form.Item
                      name={PayrollModalFields.preparation_date}
                      label={
                        <span className="SubtitleFirstText">
                          {modalText[PayrollModalFields.preparation_date].label}
                        </span>
                      }
                      rules={rules.required_date.short}
                    >
                      <DatePickerComponent
                        format="DD.MM.YYYY"
                        placeholder={
                          modalText[PayrollModalFields.preparation_date]
                            .placeholder
                        }
                      />
                    </Form.Item>
                  </div>
                  <div
                    className={`PayrollModalFormSecondRow ${
                      isMobile ? `FlexColumn` : `FlexRow Gap24`
                    }`}
                  >
                    <Form.Item
                      name={PayrollModalFields.document_number}
                      label={
                        <span className="SubtitleFirstText">
                          {modalText[PayrollModalFields.document_number].label}
                        </span>
                      }
                      rules={[...rules.required_long, ...documentNumberRule]}
                    >
                      <InputComponent
                        placeholder={
                          modalText[PayrollModalFields.document_number]
                            .placeholder
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name={PayrollModalFields.sum}
                      label={
                        <span className="SubtitleFirstText">
                          {modalText[PayrollModalFields.sum].label}
                        </span>
                      }
                      rules={[...rules.comma_number, ...rules.required_long]}
                    >
                      <InputComponent
                        placeholder={
                          modalText[PayrollModalFields.sum].placeholder
                        }
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="FlexRow FlexWrap Gap8">
                      {reqFiles.map((file: FilesList) => (
                        <FileComponent
                          key={`file_${file.name}`}
                          name={file.name}
                          size={file.size}
                          link={file.url}
                          deleteFile={() => {
                            changeFileList(
                              prevList =>
                                prevList.filter(
                                  item => item.uid !== file.uid
                                ) as UploadFile[]
                            )
                            setReqFiles(
                              prevList =>
                                prevList.filter(
                                  item => item.uid !== file.uid
                                ) as FilesList[]
                            )
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <div
                    className={`PayrollModalBottom ${
                      isMobile ? `FlexColumn` : `FlexRow`
                    } Gap16 PaddingTop24 MarginTop16`}
                  >
                    {!isMobile && applyButton}
                    {!hideUploadMobile && (
                      <div className="FlexRow">
                        <div
                          className={
                            fileList.length
                              ? `UploadComponentFormWrapper`
                              : `UploadComponentFormError`
                          }
                        >
                          <Form.Item
                            name={PayrollModalFields.files}
                            rules={[
                              {
                                validator: () =>
                                  fileRule(
                                    !!fileList.length || !!reqFiles.length
                                  )
                              }
                            ]}
                          >
                            <UploadComponent
                              fileList={fileList}
                              pending={pending}
                              setPending={setPending}
                              changeFileList={changeFileList}
                              allowedTypes={allowedTypes}
                              disabled={disableUpload}
                              addFile={data => setReqFiles([...reqFiles, data])}
                              maxSize={5}
                              maxCount={3}
                            />
                          </Form.Item>
                        </div>
                        <div className="FlexColumn Gap2 Padding8">
                          <span className="CaptionText Gray600Color">
                            {modalText.upload.descriptionOne}
                          </span>
                          <span className="CaptionText Gray600Color">
                            {modalText.upload.descriptionTwo}
                          </span>
                        </div>
                      </div>
                    )}
                    {isMobile && applyButton}
                  </div>
                </div>
              </Form>
            </div>
          ) : (
            <div className="FlexColumn Gap24">
              <div className="GridCenter">
                <span className="HeadlineFirstText">
                  {isEdit
                    ? modalText.success.edit_title
                    : modalText.success.add_title}
                </span>
              </div>
              <div className="GridCenter">
                <SuccessIcon />
              </div>
              <div className="GridCenter PaddingBottom40">
                <ButtonPrimary onClick={close}>
                  {modalText.success.button}
                </ButtonPrimary>
              </div>
            </div>
          )}
        </ModalFormComponent>
      ) : (
        <ModalFormComponent
          width={480}
          close={() => setCancelEdit(false)}
          withoutCross={true}
        >
          <div className="FlexColumn">
            <div className="HeadlineSecondText PaddingBottom16">
              {modalText.edit_cancel.title}
            </div>
            <div className="TextDefault PaddingBottom24">
              {modalText.edit_cancel.description}
            </div>
            <div className="FlexRow Gap4 MarginLeftAuto">
              <ButtonFaded
                className="PayrollModalCloseApprove"
                onClick={() => setCancelEdit(false)}
              >
                <span className="ButtonTextFirst">
                  {modalText.edit_cancel.cancel}
                </span>
              </ButtonFaded>
              <ButtonNotifi
                className="PayrollNotifyStatusTableConfirmModalApprove"
                onClick={close}
                color="red"
              >
                <span className="ButtonTextFirst">
                  {modalText.edit_cancel.exit}
                </span>
              </ButtonNotifi>
            </div>
          </div>
        </ModalFormComponent>
      )}
    </div>
  )
}

export default PayrollModal
