import { action } from "typesafe-actions"
import {
  STORE_MESSAGE,
  STORE_EMERGENCY_MESSAGE,
  STORE_COUNT_MESSAGE,
  CLOSE_EMERGENCY_MESSAGE,
  OPEN_EMERGENCY_MESSAGE
} from "./constants"
import { EmergencyDataType, MessageStoreState } from "./types"

export const storeCount = (payload: { count: number }) => {
  return action(STORE_COUNT_MESSAGE, payload)
}

export const storeMessage = (payload: {
  message_count: number
  emergency: EmergencyDataType[]
}) => {
  return action(STORE_MESSAGE, payload)
}

export const storeEmergency = (payload: MessageStoreState) => {
  return action(STORE_EMERGENCY_MESSAGE, payload)
}

export const closeEmergency = () => {
  return action(CLOSE_EMERGENCY_MESSAGE)
}

export const openEmergency = () => {
  return action(OPEN_EMERGENCY_MESSAGE)
}
