import React from "react"
import { formatDate, viewPenaltyModalText } from "./helper"
import { userPenaltyCardText } from "../UserPenaltyCard/helper"
import { UploadAttachment } from "../types"
import { ViewPenaltyModalMobileContentProps } from "./types"
import FileComponent from "Components/File"
import { ButtonPrimary } from "Components/Button"
import { addEditModalText } from "../../constants"

export const ViewPenaltyModalContent: React.FC<ViewPenaltyModalMobileContentProps> = ({
  finalPenaltyValue,
  finalWinValue,
  viewModalData,
  canAppeal,
  disabled,
  daysCountText,
  setAppealModal,
  status
}) => {
  return (
    <>
      <div className="ProposalModalTitleWrapper">
        <div className="ProposalModalTextTitleTextWrapper">
          <div className="ProposalModalTextTitleText">{finalPenaltyValue}</div>
          {status}
        </div>
        <div className="SubtitleFirstText">{finalWinValue}</div>
        <div className="ViewPenaltyModalHeaderDate ViewPenaltyModalHeaderDateUser">{`${
          userPenaltyCardText.hallNumber
        } ${viewModalData?.hall} · ${viewPenaltyModalText.setDate} ${formatDate(
          viewModalData?.setDate
        )}`}</div>
      </div>

      <div
        className={`ViewPenaltyContentWrapper ${
          canAppeal ? "" : "ViewPenaltyContentWrapperEmpty"
        }`}
      >
        <div className="ViewPenaltyContentLeft">
          <div>
            <div className="ViewPenaltyModalSubtitle ViewPenaltyModalSubtitleFirst">
              {viewPenaltyModalText.findDate}
            </div>
            <div>{formatDate(viewModalData?.findDate)}</div>
          </div>
          <div>
            <div className="ViewPenaltyModalSubtitle">
              {addEditModalText.reason}
            </div>
            <div className="ViewPenaltyModalTextContent">
              {viewModalData?.reason}
            </div>
          </div>

          <div>
            <div className="ViewPenaltyModalSubtitle">
              {addEditModalText.justification}
            </div>
            <div className="ViewPenaltyModalTextContent">
              {viewModalData?.reasonComment}
            </div>
          </div>

          {!!viewModalData?.attachments?.length && (
            <div>
              <div className="ViewPenaltyModalSubtitle">
                {addEditModalText.attachments}
              </div>
              <div className="ViewPenaltyFilesWrapper">
                {viewModalData?.attachments.map((file: UploadAttachment) => (
                  <FileComponent
                    key={file.size + file.name}
                    name={file.name}
                    view
                    size={Number(file.size)}
                    link={file.link}
                    type={file.type}
                  />
                ))}
              </div>
            </div>
          )}

          {viewModalData?.editPenaltyComment && (
            <div className="ViewPenaltyModalComment">
              <div className="ViewPenaltyModalSubtitle">
                {viewPenaltyModalText.adminComment}
              </div>
              <div className="ViewPenaltyModalTextContent">
                {viewModalData?.editPenaltyComment}
              </div>
            </div>
          )}
          {viewModalData?.resolveComment && (
            <div className="ViewPenaltyModalComment">
              <div className="ViewPenaltyModalSubtitle">
                {viewPenaltyModalText.adminComment}
              </div>
              <div className="ViewPenaltyModalTextContent">
                {viewModalData?.resolveComment}
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={`ViewModalButtonsGroupWrapper ${
          canAppeal ? "" : "ViewModalButtonsGroupWrapperEmpty"
        }`}
      >
        {canAppeal && !disabled && (
          <div className="ViewModalButtonsGroupLeft">
            <ButtonPrimary
              onClick={() => {
                setAppealModal()
              }}
              className="ButtonIcon_basic ViewModalResolveButton"
            >
              {viewPenaltyModalText.appealButton}
            </ButtonPrimary>
            <div className="ViewPenaltyModalUserCountBack">{daysCountText}</div>
          </div>
        )}
      </div>
    </>
  )
}
