import React, { useState, useMemo } from "react"

import { EmptyData } from "Components/EmptyTable"
import { CheckBoxComponent } from "Components/CheckBox"
import { ReactComponent as DropdownArrow } from "Components/icons/dropdown_arrow.svg"

import { TableData } from "../Table/types"
import { detailEvent, tableMobileConstants } from "./constants"
import { resultByStatus, emptyTableInsideText } from "../Table/constants"

type TableItemProps = {
  data: TableData
  openCurrent: (val: number) => void
  setPickList: (val: { bet: number; isActive: boolean }) => void
  isActive: boolean
  pending: boolean
}

const TableItem = ({
  openCurrent,
  data,
  setPickList,
  isActive
}: TableItemProps) => {
  const [detailOpen, toggleDetail] = useState(false)
  const text = useMemo(() => tableMobileConstants(data), [])

  const statusClassName = resultByStatus(data.status, true)

  const haveDetail = !!data.sport_type?.length
  return (
    <div
      onClick={() => {
        toggleDetail(!detailOpen)
        if (!isActive && !detailOpen) {
          setPickList({ bet: data.bet_number, isActive: true })
        }
      }}
      className="SportboomTableMobileItem"
    >
      <div
        className={`SportboomTableMobileItemMain ${
          isActive ? `SportboomTableMobileItemMainActive` : ``
        } ${
          !detailOpen ? `SportboomTableMobileItemMainActiveDetailClose` : ``
        }`}
      >
        <div className="FlexRow">
          <CheckBoxComponent
            checked={isActive}
            onChange={({ target: { checked } }) =>
              setPickList({ bet: data.bet_number, isActive: checked })
            }
            onClick={e => e.stopPropagation()}
          />
          <div
            className="SportboomTableMobileItemBetNumber"
            onClick={e => {
              e.stopPropagation()
              openCurrent(data.bet_number)
            }}
          >
            {text.bet_number}
          </div>
          <div className="SportboomTableMobileItemBetStart">
            {text.bet_start}
          </div>
        </div>
        <div className="FlexRow Gap4 PaddingTop16">
          <div className="HeadlineSecondText">{text.bet}</div>
          <div className="HeadlineSecondText">{text.arrow}</div>
          <div className={`HeadlineSecondText ${statusClassName}`}>
            {text.result}
          </div>
        </div>
        <div className="BodySecondText">{text.status}</div>
        <div className="FlexRow">
          <div className="TextDefault PaddingTop16">{text.description}</div>
          <div
            className={`SportboomTableMobileItemArrow SportboomTableMobileItemArrow${
              detailOpen ? `Open` : `Close`
            }`}
          >
            <DropdownArrow />
          </div>
        </div>
      </div>
      {detailOpen && (
        <div
          className={`SportboomTableMobileItemDetail ${
            isActive ? `SportboomTableMobileItemDetailActive` : ``
          }`}
        >
          {haveDetail ? (
            data.sport_type.map(detailItem => {
              const statusMarker = resultByStatus(detailItem.status, false)
              return (
                <div key={`bet_event_${detailItem.event_id}`}>
                  <div className="FlexRow">
                    <div
                      className={`${statusMarker} SportboomTableMobileItemDetailStatus`}
                    />
                    <div className="BodyFirstText PaddingLeft8">
                      {detailItem.sport_type}
                    </div>
                  </div>
                  <div className="PaddingTop8 TextDefault">
                    {detailEvent(detailItem)}
                  </div>
                </div>
              )
            })
          ) : (
            <div>
              <EmptyData
                {...emptyTableInsideText}
                titleClass="SubtitleFirstText"
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TableItem
