import React, { useEffect, useRef, useState } from "react"
import { Input, InputNumber } from "antd"
import MaskedInput from "antd-mask-input"

import { fromText } from "./constant"
import {
  InputProps,
  TextAreaProps,
  MaskedInputProps,
  InputNumberProps,
  CurrencyInputProps,
  InputNumberClearProps
} from "./types"
import { useGreyInputBackground } from "./helpers"

import { valueToCurrency } from "../../constants"

const InputComponent: React.FC<InputProps> = ({
  view,
  isMiddle,
  error = false,
  value,
  greyBackground,
  hideControls,
  errors,
  disableScrollEvent,
  constantErrorHeight,
  ...rest
}) => {
  const { greyBg } = useGreyInputBackground({ value, greyBackground })
  const [focused, setFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (disableScrollEvent && inputRef.current) {
      const handleWheel = (event: WheelEvent) => {
        event.preventDefault()
      }
      inputRef.current.addEventListener("wheel", handleWheel)
      return () => {
        inputRef?.current?.removeEventListener("wheel", handleWheel)
      }
    }
  }, [disableScrollEvent])

  return (
    <div
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={`InputWrapper ${
        !!error || (errors && errors?.length > 0) ? `ErrorInputWrapper` : ``
      } ${view ? `ViewInputWrapper` : ``} ${
        isMiddle ? `InputMiddleWrapper` : ``
      } ${!focused && greyBg ? "InputWrapperGreyBackground" : ""} ${
        hideControls ? "InputWrapperControlsHidden" : ""
      }
        ${rest.disabled && greyBg ? "InputWrapperGreyBackgroundDisabled" : ""}`}
      ref={inputRef}
    >
      <Input value={value} {...rest} />
      {(!!constantErrorHeight ? true : !!error) && (
        <div
          className="ErrorText"
          style={
            !!constantErrorHeight
              ? { height: `${constantErrorHeight}px` }
              : undefined
          }
        >
          {error}
        </div>
      )}
      {errors && errors?.length > 0 && (
        <div className="ErrorsContainer">
          {errors.map((err, key) => (
            <div key={key} className="ErrorText">
              {err}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export const InputTextAreaComponent: React.FC<TextAreaProps> = ({
  error,
  greyBackground,
  value,
  ...rest
}) => {
  const { greyBg } = useGreyInputBackground({ value, greyBackground })
  const [focused, setFocused] = useState(false)

  return (
    <div
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={`InputWrapper ${error ? `ErrorInputWrapper` : ``} ${
        !focused && greyBg ? "InputWrapperGreyBackground" : ""
      }`}
    >
      <Input.TextArea value={value} {...rest} />
      {!!error && <div className="ErrorText">{error}</div>}
    </div>
  )
}

export const InputPasswordComponent: React.FC<InputProps> = ({
  error = false,
  ...rest
}) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``}`}>
    <Input.Password {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const InputMaskComponent: React.FC<MaskedInputProps> = ({
  greyBackground,
  value,
  onChange,
  ...rest
}) => {
  const [unmaskedValue, setUnmaskedValue] = useState(value)
  const [focused, setFocused] = useState(false)

  const { greyBg } = useGreyInputBackground({
    greyBackground,
    value: unmaskedValue
  })

  return (
    <div
      className={`InputWrapper InputMaskWrapper ${
        greyBg && !focused ? "InputWrapperGreyBackground" : ""
      }`}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <MaskedInput
        allowClear={true}
        value={value}
        onChange={val => {
          setUnmaskedValue(val.unmaskedValue)
          onChange && onChange(val)
        }}
        {...rest}
      />
    </div>
  )
}

const FromPrefix = () => <div className="InputFromPrefix">{fromText}</div>
export const prepareStringCurrencyToNumber = (val?: string | number) => {
  if (!val) {
    return undefined
  } else if (Number(val)) {
    return Number(val)
  } else if (typeof val === `string` && val?.replaceAll) {
    return Number(val?.replaceAll(` `, ``))
  }
}

export const CurrencyInputComponent: React.FC<CurrencyInputProps> = ({
  value,
  setValue,
  disabled,
  widthFromPrefix,
  placeholder
}) => {
  return (
    <InputComponent
      placeholder={placeholder}
      greyBackground={true}
      value={value}
      onChange={({ target: { value } }) => {
        if (!!value && !!Number(value.replaceAll(` `, ``))) {
          const currency = valueToCurrency({
            value: Number(value.replaceAll(` `, ``)) || 0,
            withoutFixed: true
          })
          setValue(currency)
        } else if (!value) {
          setValue(undefined)
        } else {
          const newValue = value.slice(0, -1)
          setValue(newValue)
        }
      }}
      disabled={disabled}
      prefix={!!widthFromPrefix && <FromPrefix />}
    />
  )
}

export const InputNumberComponent: React.FC<InputNumberProps> = ({
  ...rest
}) => (
  <div className="InputWrapper">
    <InputNumber {...rest} />
  </div>
)

export default InputComponent

export const InputNumberClear: React.FC<InputNumberClearProps> = ({
  value,
  onChange,
  placeholder,
  disabled,
  suffix
}) => {
  const [focused, setFocused] = useState(false)
  return (
    <div
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={`InputWrapper InputNumberClearWrapper ${
        !focused ? "InputWrapperGreyBackground" : ""
      }
        ${disabled ? "InputWrapperGreyBackgroundDisabled" : ""}`}
    >
      <InputNumber
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        className="InputNumberClear"
      />
      {!!suffix ? (
        <div className="InputNumberClearSuffix">{suffix}</div>
      ) : (
        <div />
      )}
    </div>
  )
}
