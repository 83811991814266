import {
  CloseHandlerProps,
  CreateStep4Props,
  sendingsTypeValues,
  Step4ChangeHandlerProps
} from "../../types"
import { RadioChangeEvent } from "antd/es/radio/interface"
import { smsConstants } from "../../constants"
import TooltipComponent from "Components/Tooltip"
import { RadioGroupComponent } from "Components/Radio"
import { SelectComponent } from "Components/Select"
import { Tag } from "antd"
import { clearPhone, formatPhone } from "constants/index"
import React, { ReactElement } from "react"
import { ReactComponent as ClearRedCircleIcon } from "Components/icons/clear_circle_red.svg"
import { ReactComponent as ClearCircleIcon } from "Components/icons/clear_circle.svg"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"
import { uniq } from "lodash"

const Step4 = ({
  smsState,
  setSmsState,
  stepNumber,
  showStep
}: CreateStep4Props) => {
  const isErrorPhone = (value: string) =>
    smsState.errors?.filters?.phones?.invalid?.includes(
      value.replace(/\D/g, ``)
    )

  const getClassName = (value: string): string =>
    isErrorPhone(value)
      ? "CreateSms_error_inputPhoneTag"
      : "CreateSms_inputPhoneTag"

  const getColor = (value: string): string =>
    isErrorPhone(value) ? "error" : ""

  const getIcon = (value: string): ReactElement =>
    isErrorPhone(value) ? <ClearRedCircleIcon /> : <ClearCircleIcon />

  const onCloseHandler = ({
    value,
    smsState,
    setSmsState
  }: CloseHandlerProps) => {
    const deletedPhone = clearPhone(value)
    const phonesWithOutDeletedPhone = smsState.phones.filter(
      phone => phone !== deletedPhone
    )
    setSmsState({
      ...smsState,
      phones: [...phonesWithOutDeletedPhone]
    })
  }

  const onChangeHandler = ({
    value,
    smsState,
    setSmsState
  }: Step4ChangeHandlerProps) => {
    const phones: string[] = []

    value.forEach((el: string) => {
      let phone = clearPhone(el, true)
      if (!!phone) {
        phone = phone.slice(0, 11)
        phones.push(phone)
      }
    })

    setSmsState({
      ...smsState,
      phones: uniq(phones)
    })
  }

  const onChangeRadioHandler = ({ target: { value } }: RadioChangeEvent) => {
    if (value === sendingsTypeValues.all) {
      setSmsState({
        ...smsState,
        ...smsConstants.paramsToResetSmsState,
        sendingType: value,
        phones: []
      })
    } else {
      setSmsState({
        ...smsState,
        ...smsConstants.paramsToResetSmsState,
        sendingType: value
      })
    }
  }

  return (
    <div className="CreateSms_step">
      <div
        className={
          showStep ? "CreateSms_stepTitle" : "CreateSms_stepTitle_inactive"
        }
      >
        <span>{stepNumber}</span>
        <span>{smsConstants.step4.title}</span>
        <TooltipComponent title={smsConstants.step4.tooltip} direction="bottom">
          <InfoIcon />
        </TooltipComponent>
      </div>

      {showStep ? (
        <section className="CreateSms_inputWrapper CreateSms_inputWrapper_wide">
          <RadioGroupComponent
            value={smsState.sendingType}
            onChange={onChangeRadioHandler}
            options={smsConstants.sendingsTypeOptions}
          />

          {smsState.sendingType !== sendingsTypeValues.all && (
            <div>
              <SelectComponent
                dropdownClassName="SmsPhoneDropdown"
                greyBackground
                error={
                  !!smsState.errors?.filters?.phones?.invalid?.length
                    ? smsConstants.phonesErrText
                    : false
                }
                className="CreateSms_inputPhonesWrapper"
                mode="tags"
                tokenSeparators={smsConstants.step4.separators}
                tagRender={props => {
                  return (
                    <Tag
                      className={getClassName(props.value)}
                      color={getColor(props.value)}
                      closeIcon={getIcon(props.value)}
                      closable
                      onClose={() =>
                        onCloseHandler({
                          value: props.value,
                          smsState,
                          setSmsState
                        })
                      }
                    >
                      {props.value}
                    </Tag>
                  )
                }}
                allowClear
                onChange={value =>
                  onChangeHandler({
                    value,
                    smsState,
                    setSmsState
                  })
                }
                value={smsState.phones.map(phone => formatPhone(phone))}
              />
            </div>
          )}
          {!!smsState.errors?.phonesTotal && (
            <div className="ErrorText">{smsState.errors?.phonesTotal}</div>
          )}
        </section>
      ) : (
        <hr className="CreateSms_divider" />
      )}
    </div>
  )
}

export default Step4
