export type CancelModalProps = {
  id: number
  refreshTable: () => void
  close: () => void
}

export enum CancelModalStatus {
  check = `check`,
  block = `block`,
  cancel = `cancel`
}
