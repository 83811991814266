import React from "react"
import { TabsComponentProps } from "./types"

const TabsComponent = ({
  tabs,
  toggleActiveTab,
  activeTab
}: TabsComponentProps) => {
  return (
    <div className="TabsComponentWrapper">
      <div className="TabsComponentItemsWrapper FlexRow">
        {tabs.map(item => {
          const isActive = activeTab === item.value
          const isDisabled = !!item.disabled
          return (
            <div key={`tab_${item.label}`}>
              <div
                onClick={() => {
                  if (!isActive && !isDisabled) {
                    toggleActiveTab(item.value)
                  }
                }}
                className={`TabsComponentItem ${
                  isActive ? `TabsComponentActiveItem` : ``
                } ${isDisabled ? `TabsComponentDisabledItem` : ``}`}
              >
                {item.label}
              </div>
              <div
                className={`TabsComponentItemLine ${
                  isActive ? `TabsComponentActiveItemLine` : ``
                }`}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TabsComponent
