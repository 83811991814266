import React, { useState } from "react"
import InputComponent from "Components/Input"
import { SelectComponent } from "Components/Select"
import { RangePickerComponent } from "Components/DatePicker"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"

import { NotifiFilterStateData, initialFilter } from "../index"
import { ButtonNotifi } from "Components/Button"
import { useSelector } from "react-redux"
import { getLocationsList } from "store/dictionary/selector"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"

type NotificationsFilterProps = {
  filter: NotifiFilterStateData
  pending: boolean
  setFilter: (data: NotifiFilterStateData) => void
  mobileSetFilter: (data: NotifiFilterStateData) => void
  clear: () => void
  mobileClear: () => void
  apply: () => void
  close: () => void
}

const NotificationsFilter = ({
  filter,
  pending,
  setFilter,
  mobileSetFilter,
  apply,
  clear,
  mobileClear,
  close
}: NotificationsFilterProps) => {
  const locations = useSelector(getLocationsList)
  const countryOptions = (locations.country || [])
    .map(item => ({
      value: item.id,
      label: item.name
    }))
    .filter(item => [`Россия`, `Донецк`, `Армения`].includes(item.label))
  const [currentFilter, setCurrentFilter] = useState<NotifiFilterStateData>(
    filter
  )
  return (
    <div className="NotificationsFilterWrapper">
      <div className="NotificationsFilterWrapperMobile FlexRow">
        <div className="NotificationsFilterWrapperMobileTitle">Фильтры</div>
        <div className="NotificationsFilterWrapperMobileClose" onClick={close}>
          <CloseIcon />
        </div>
      </div>
      <div className="NotificationsFilterField">
        <div className="NotificationsFilterFieldDescription">ID рассылки</div>
        <InputComponent
          onChange={({ target: { value } }) =>
            setCurrentFilter({ ...currentFilter, id: value || undefined })
          }
          isMiddle={true}
          allowClear={true}
          disabled={pending}
          value={currentFilter.id}
          placeholder="Поиск..."
          prefix={<SearchIcon />}
        />
      </div>
      <div className="NotificationsFilterField">
        <div className="NotificationsFilterFieldDescription">Локация</div>
        <SelectComponent
          options={countryOptions}
          disabled={pending}
          value={currentFilter.country}
          onChange={value =>
            setCurrentFilter({ ...currentFilter, country: value })
          }
          isMiddle={true}
          allowClear={true}
          placeholder="Не учитывать"
        />
      </div>
      <div className="NotificationsFilterField">
        <div className="NotificationsFilterFieldDescription">Город</div>
        <InputComponent
          disabled={pending}
          onChange={({ target: { value } }) =>
            setCurrentFilter({ ...currentFilter, city: value || undefined })
          }
          isMiddle={true}
          allowClear={true}
          value={currentFilter.city}
          placeholder="Поиск..."
          prefix={<SearchIcon />}
        />
      </div>
      <div className="NotificationsFilterField">
        <div className="NotificationsFilterFieldDescription">Клуб</div>
        <InputComponent
          disabled={pending}
          onChange={({ target: { value } }) =>
            setCurrentFilter({ ...currentFilter, hall: value || undefined })
          }
          isMiddle={true}
          allowClear={true}
          value={currentFilter.hall}
          placeholder="Поиск..."
          prefix={<SearchIcon />}
        />
      </div>
      <div className="NotificationsFilterField">
        <div className="NotificationsFilterFieldDescription">Текст</div>
        <InputComponent
          disabled={pending}
          onChange={({ target: { value } }) =>
            setCurrentFilter({ ...currentFilter, text: value || undefined })
          }
          isMiddle={true}
          allowClear={true}
          value={currentFilter.text}
          placeholder="Поиск..."
          prefix={<SearchIcon />}
        />
      </div>
      <div className="NotificationsFilterField">
        <div className="NotificationsFilterFieldDescription">
          Дата или период
        </div>
        <RangePickerComponent
          disabled={pending}
          allowClear={true}
          isMiddle={true}
          value={currentFilter.date}
          onChange={value =>
            setCurrentFilter({ ...currentFilter, date: value })
          }
        />
      </div>
      <div className="FilterButtonNotifiWrapper">
        <ButtonNotifi
          color="blue"
          isMiddle={true}
          onClick={() => {
            setCurrentFilter(initialFilter.data)
            clear()
          }}
        >
          <div>
            <span>Очистить</span>
          </div>
        </ButtonNotifi>
      </div>
      <div className="FilterButtonNotifiWrapper">
        <ButtonNotifi
          color="green"
          isMiddle={true}
          onClick={() => {
            setFilter(currentFilter)
            apply()
          }}
        >
          <div>
            <span>Применить</span>
          </div>
        </ButtonNotifi>
      </div>
      <div className="FilterButtonNotifiMobileWrapper FlexRow">
        <div className="FilterButtonNotifiWrapper">
          <ButtonNotifi
            color="blue"
            isMiddle={true}
            onClick={() => {
              setCurrentFilter(initialFilter.data)
              mobileClear()
            }}
          >
            <div>
              <span>Очистить</span>
            </div>
          </ButtonNotifi>
        </div>
        <div className="FilterButtonNotifiWrapper">
          <ButtonNotifi
            color="green"
            isMiddle={true}
            onClick={() => {
              mobileSetFilter(currentFilter)
              apply()
            }}
          >
            <div>
              <span>Применить</span>
            </div>
          </ButtonNotifi>
        </div>
      </div>
    </div>
  )
}

export default NotificationsFilter
