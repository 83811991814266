import React, { useEffect, useState } from "react"

import { getRequestTotal } from "api/request"
import { useRequest, useRequestBody, useRequestParams } from "api/hooks"
import { parseOneDateWithoutGmtToBack } from "constants/index"

import NewTableComponent from "Components/TableNew"
import { EmptyData } from "Components/EmptyTable"

import { ReactComponent as InTitleIcon } from "./icons/in_title.svg"
import { ReactComponent as OutTitleIcon } from "./icons/out_title.svg"

import { cashboxTableText, initialState, cashboxColumn } from "./constants"
import {
  GuestHistoryCashboxTableProps,
  GuestHistoryCashboxTableState
} from "./types"

const GuestHistoryCashboxTable = ({
  filter
}: GuestHistoryCashboxTableProps) => {
  const { requestCurrentHall } = useRequestParams()
  const { pagination, setPagination } = useRequestBody()
  const [tableData, setTableData] = useState<GuestHistoryCashboxTableState>(
    initialState
  )

  const { request: getTableData } = useRequest({
    url: `v1/guests/history/cashbox`,
    requestBody: {
      hall: requestCurrentHall,
      ...filter,
      dates: !!filter.dates
        ? parseOneDateWithoutGmtToBack(filter.dates)
        : undefined,
      games: undefined,
      includeFreebets: undefined,
      pagination
    },
    method: `POST`
  })

  const requestDeps = JSON.stringify({ ...filter, ...pagination })

  useEffect(() => {
    setTableData({ ...tableData, pending: true })
    getTableData().then(({ data, headers }) => {
      setTableData({
        ...tableData,
        pending: false,
        data,
        total: getRequestTotal(headers)
      })
    })
  }, [requestDeps])

  return (
    <div className="GuestHistoryCashboxTableWrapper">
      <div className="GuestHistoryCashboxTableHeader">
        <div className="GuestHistoryCashboxTableTitle HeadlineThirdText">
          {cashboxTableText.title(tableData.total)}
        </div>
        {!!tableData.total && (
          <div className="GuestHistoryCashboxTableDescriptionWrapper">
            <div className="FlexRow">
              <InTitleIcon />
              <div className="GuestHistoryCashboxTableDescription TextDefault">
                {cashboxTableText.descriptionIn(tableData.data.in)}
              </div>
            </div>
            <div className="FlexRow">
              <OutTitleIcon />
              <div className="GuestHistoryCashboxTableDescription TextDefault">
                {cashboxTableText.descriptionOut(tableData.data.out)}
              </div>
            </div>
          </div>
        )}
      </div>
      {!!tableData.total || tableData.pending ? (
        <div>
          <NewTableComponent
            columns={cashboxColumn}
            data={tableData.data.data}
            loading={tableData.pending}
            pagination={{
              value: pagination,
              onChange: setPagination,
              total: tableData.total
            }}
          />
        </div>
      ) : (
        <EmptyData
          title={cashboxTableText.emptyTable.title}
          subtitle={cashboxTableText.emptyTable.subtitle}
          isSmall={true}
        />
      )}
    </div>
  )
}

export default GuestHistoryCashboxTable
