import React from "react"
import { Popover } from "antd"
import { PopoverProps } from "antd/lib"
import { TooltipPlacement } from "antd/es/tooltip"

import { ReactComponent as HelpSmall } from "Components/icons/help_16.svg"
import { ReactComponent as HelpMedium } from "Components/icons/help_24.svg"

export enum TooltipCustomSize {
  small = "small",
  medium = "medium"
}

interface TooltipComponentProps extends PopoverProps {
  children: any
  title?: string | React.ReactNode
  direction?: TooltipPlacement
  trigger?: string
  isModal?: boolean
}

const TooltipComponent = ({
  children,
  title,
  direction,
  trigger,
  className,
  isModal,
  ...rest
}: TooltipComponentProps) => (
  <Popover
    trigger={trigger || [`hover`, `click`]}
    content={title}
    placement={direction}
    overlayStyle={{ paddingTop: 0, paddingRight: 0 }}
    overlayClassName={`TooltipComponentWrapper ${className ? className : ""} ${
      isModal ? `TooltipComponentModal` : ``
    }`}
    {...rest}
  >
    <div className="TooltipComponentTargetWrapper">{children}</div>
  </Popover>
)

export default TooltipComponent

export const TooltipCustom = ({
  size,
  title
}: {
  size: TooltipCustomSize
  title: string
}) => {
  let icon = <div />

  switch (size) {
    case TooltipCustomSize.small: {
      icon = <HelpSmall />
      break
    }
    case TooltipCustomSize.medium: {
      icon = <HelpMedium />
      break
    }
  }

  return <TooltipComponent title={title}>{icon}</TooltipComponent>
}
