import React from "react"
import { contactsSectionProps } from "Containers/Contacts/types"
import { orderRequest } from "Containers/Contacts/helpers"
import { isContactsMobile } from "Containers/Contacts/index"
import { ReactComponent as ArrowIcon } from "Components/icons/arrow_left_outline.svg"
import { ButtonIcon } from "Components/Button"
import { ReactComponent as AddBtnIcon } from "Components/icons/add_square_outline.svg"
import ContactsBlock from "../ContactsBlock"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logoutUser } from "store/auth/actions"
import ContactsMap from "./ContactsMap"
import SectionHeader from "./SectionHeader"
import { blockStateStorageName } from "Containers/Contacts/constants"

const ContactsSection = ({
  contactsState,
  setContactsState,
  section
}: contactsSectionProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const logout: () => void = () => dispatch(logoutUser())

  const isLastElement = section.order_number === contactsState.data.length
  const isFirstElement = section.order_number === 1

  /* Сдвигаем раздел на 1 позицию вниз */
  const incrementOrderNumber = ({
    sectionId,
    curNumber
  }: {
    sectionId: number
    curNumber: number
  }) => {
    setContactsState({
      ...contactsState
    })

    /* Если это последний элемент, то ничего не делаем */
    if (isLastElement) return false
    /* Меняем местами текущий и следуюший элементы */
    const newData = contactsState.data.map(item => {
      /* Увеличим order_number текущего блока на 1 */
      if (item.section_id === sectionId) {
        item.order_number++
      } else if (item.order_number - curNumber === 1) {
        /* следующий за текущим блок (у которого order_number больше на 1) уменьшаем на 1 */
        item.order_number--
      }
      return item
    })
    newData.sort((a, b) => a.order_number - b.order_number)

    setContactsState({
      ...contactsState,
      data: newData,
      movingId: sectionId
    })

    orderRequest({
      newData,
      contactsState,
      setContactsState,
      logout,
      history
    })
  }

  const decrementOrderNumber = ({
    sectionId,
    curNumber
  }: {
    sectionId: number
    curNumber: number
  }) => {
    setContactsState({
      ...contactsState
    })
    /* Если это первый элемент, то ничего не делаем */
    if (isFirstElement) return false

    /* Меняем местами текущий и предыдущий элементы */
    const newData = contactsState.data.map(item => {
      /* Уменьшим order_number текущего блока на 1 */
      if (item.section_id === sectionId) {
        item.order_number--
      } else if (curNumber - item.order_number === 1) {
        /* предыдущий за текущим блок (у которого order_number меньше на 1) увеличим на 1 */
        item.order_number++
      }
      return item
    })

    newData.sort((a, b) => a.order_number - b.order_number)

    setContactsState({
      ...contactsState,
      data: newData,
      movingId: sectionId
    })

    orderRequest({
      newData,
      contactsState,
      setContactsState,
      logout,
      history
    })
  }

  return (
    <div
      className={`ContactsPageSection ${
        section.map_link ? `ContactsPageSectionHasMap` : ""
      }`}
    >
      <div className="ContactsPageAnchor" id={`${section.section_id}`}></div>
      {contactsState.isEditing && (
        <div className="ContactsPageSectionNumberWrapper">
          <div
            className="ContactsPageSectionNumber ContactsPageSectionNumberUp"
            onClick={() => {
              decrementOrderNumber({
                sectionId: section.section_id,
                curNumber: section.order_number
              })
            }}
          >
            <ArrowIcon
              className={
                isFirstElement ? "ContactsPageSectionIcon_disabled" : ""
              }
            />
          </div>
          <div>{section.order_number}</div>
          <div
            className="ContactsPageSectionNumber ContactsPageSectionNumberDown"
            onClick={() => {
              incrementOrderNumber({
                sectionId: section.section_id,
                curNumber: section.order_number
              })
            }}
          >
            <ArrowIcon
              className={
                isLastElement ? "ContactsPageSectionIcon_disabled" : ""
              }
            />
          </div>
        </div>
      )}
      {!isContactsMobile && (
        <SectionHeader
          contactsState={contactsState}
          setContactsState={setContactsState}
          section={section}
          key={section.section_id}
        />
      )}
      <div className="ContactsPageSectionOuter">
        <div className="ContactsPageSectionAllBlocksWrapper">
          {!!section.subtitle && isContactsMobile && (
            <div className="ContactsPageSectionHeader_subtitle">
              {section.subtitle}
            </div>
          )}
          {section.map_link && <ContactsMap address={section.map_link} />}
          {!!section.blocks.length &&
            section.blocks.map((block, index) => (
              <ContactsBlock
                key={index}
                contactsState={contactsState}
                setContactsState={setContactsState}
                block={block}
                section={section}
              />
            ))}
        </div>
      </div>
      {contactsState.isEditing && (
        <div className="ContactsPageSectionFooter">
          <ButtonIcon
            className="ContactsPageSectionFooter_addBlockBtn"
            icon={<AddBtnIcon />}
            onClick={() => {
              localStorage.removeItem(blockStateStorageName)
              setContactsState({
                ...contactsState,
                activeModal: true,
                isBlockEditing: true,
                editBlockId: null,
                editSectionId: section.section_id
              })
            }}
          >
            Добавить блок
          </ButtonIcon>
        </div>
      )}
    </div>
  )
}

export default ContactsSection
