import betRequest, { betRequestWithCancelToken } from "api/request"
import {
  CalcStateProps,
  CreateProps,
  DaysOptionsProps,
  ErrorProps,
  GetCalcSettings,
  GetSmsSettings,
  GetValidateProps,
  MacroItem,
  macros,
  macrosRaw,
  sendingsTypeValues,
  SmsSettingsProps,
  SmsStateProps,
  TextItem,
  TextOptionsProps
} from "./types"
import { smsConstants } from "./constants"
import { isEmpty } from "lodash"
import moment, { Moment } from "moment"
import { Durations } from "Components/FilterInfoPanel/types"
import { clearPhone, parseDateToBack } from "constants/index"
import axios, { CancelTokenSource } from "axios"

/* Опции селекта выбора дня месяца */
export const MonthNumberSelectOptions = (): DaysOptionsProps[] => {
  const days = []
  for (let i = 1; i <= 31; i++) {
    days.push({
      value: i,
      label: i
    })
  }

  return days
}

export const getSendingTypeName = (smsState: SmsStateProps): string => {
  return smsConstants.sendingsTypeOptions.filter(
    option => option.value === smsState.sendingType
  )[0].label
}

export const getSmsSettings = ({
  smsState,
  setSmsState,
  logout,
  history
}: GetSmsSettings) => {
  setSmsState({
    ...smsState,
    pending: true
  })

  betRequest({
    method: `GET`,
    url: `${smsConstants.urls.settings}/${smsState.currentHall}`,
    history,
    logout
  })
    .then(({ data }: { data: SmsSettingsProps }) => {
      setSmsState({
        ...smsState,
        pending: false,
        smsSettings: { ...data }
      })
    })
    .catch(() =>
      setSmsState({
        ...smsState,
        pending: false
      })
    )
}

export const getCalculate = ({
  smsState,
  setSmsState,
  calcState,
  setCalcState,
  logout,
  history
}: GetCalcSettings): {
  calculate: () => void
  cancelToken: CancelTokenSource
} => {
  const { request, cancelToken } = betRequestWithCancelToken({
    method: `POST`,
    url: smsConstants.urls.calculate,
    requestBody: createCalcBody(smsState),
    history,
    logout
  })

  const calculate = () => {
    setCalcState({
      ...calcState,
      calculating: true
    })
    request()
      .then(({ data }: { data: CalcStateProps | ErrorProps }) => {
        setCalcState({
          ...calcState,
          ...data,
          calculating: false
        })

        if ((data as CalcStateProps)?.phonesCount <= 0) {
          setSmsState(prev => {
            return {
              ...prev,
              errors: { phonesTotal: smsConstants.phonesCountErr }
            }
          })
        } else {
          setSmsState(prev => {
            return {
              ...prev,
              errors: {}
            }
          })
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setCalcState(smsConstants.calcStateInit)
          setSmsState(prev => {
            return {
              ...prev,
              phones: error?.response?.data?.errors?.filters?.phones
                ? [
                    ...error?.response?.data?.errors?.filters?.phones?.invalid,
                    ...error?.response?.data?.errors?.filters?.phones?.valid
                  ]
                : prev.phones,
              errors: error?.response?.data?.errors
            }
          })
        }
      })
  }
  return { calculate, cancelToken }
}

export const create = ({
  smsState,
  setSmsState,
  setSuccessModal,
  setModal,
  logout,
  setPending,
  history
}: CreateProps) => {
  setSmsState({
    ...smsState,
    creating: true
  })

  betRequest({
    method: `POST`,
    url: smsConstants.urls.create,
    requestBody: createRequestBody(smsState),
    history,
    logout
  })
    .then(() => {
      setModal(false)
      setSuccessModal(true)
      setPending(false)
    })
    .catch(error => {
      setModal(false)
      setPending(false)
      setSmsState({
        ...smsState,
        creating: false,
        phones: error?.response?.data?.errors?.filters?.phones
          ? [
              ...error.response.data.errors.filters.phones.invalid,
              ...error.response.data.errors.filters.phones.valid
            ]
          : smsState.phones,
        errors: error.response.data.errors
      })
    })
}

/* Сборка макросов для отправки на бек */
export const getMacrosParamsForRequest = (smsState: SmsStateProps) => {
  let macros = {
    address: smsState.smsSettings.address,
    date: smsState.extraParams.date
      ? moment(smsState.extraParams.date).format("YYYY-MM-DD")
      : null,
    time: smsState.extraParams.time
      ? moment(smsState.extraParams.time).format("HH:mm")
      : null,
    number: smsState.extraParams.number ? smsState.extraParams.number : null,
    time_from: smsState.extraParams.timeFrom
      ? moment(smsState.extraParams.timeFrom).format("HH:mm")
      : null,
    time_to: smsState.extraParams.timeTo
      ? moment(smsState.extraParams.timeTo).format("HH:mm")
      : null,
    bonus: {
      from: smsState.extraParams.bonusFrom
        ? parseInt(`${smsState.extraParams.bonusFrom}`)
        : null,
      to: smsState.extraParams.bonusTo
        ? parseInt(`${smsState.extraParams.bonusTo}`)
        : null
    },
    money: {
      from: smsState.extraParams.moneyFrom
        ? parseInt(`${smsState.extraParams.moneyFrom}`)
        : null,
      to: smsState.extraParams.moneyTo
        ? parseInt(`${smsState.extraParams.moneyTo}`)
        : null
    }
  }

  /* Добавляем поля для макросов команд и сумм */
  Object.keys(smsState.extraParams).forEach(key => {
    // Команды
    if (key.indexOf(smsConstants.commandFieldName) !== -1) {
      macros = {
        ...macros,
        [key]: smsState.extraParams[key]
      }
    }
    // Суммы
    if (key.indexOf(smsConstants.sumFieldName) !== -1) {
      macros = {
        ...macros,
        [key]: +smsState.extraParams[key]
      }
    }
  })

  return macros
}

/* Склеивает дату и время в единую дату */
export const createDttmToSend = (smsState: SmsStateProps): string => {
  const dateToSend = moment(smsState.dateToSend).format("YYYY-MM-DD")
  const timeToSend = moment(smsState.timeToSend).format("HH:mm:ss")
  return `${dateToSend}T${timeToSend}.000+03:00`
}

export const previewPhoneTime = (smsState: SmsStateProps) => {
  if (smsState.timeToSend && smsState.dateToSend) {
    moment.locale("ru")
    return moment(createDttmToSend(smsState)).format("dddd HH:mm")
  }
  return ""
}

/* Сборка sms для отправки на бек */
const getSmsParamsForRequest = (smsState: SmsStateProps) => ({
  typeId: smsState.selectedTypeId === -1 ? null : smsState.selectedTypeId,
  subtypeId: smsState.selectedSubTypeId ? smsState.selectedSubTypeId : null,
  textId: smsState.selectedTextId,
  text: smsState.customSmsText ? smsState.customSmsText : null
})

/* Сборка filters для отправки на бек */
const getFilterParamsForRequest = (smsState: SmsStateProps) => ({
  typePhones: smsState.sendingType,
  phones: smsState.phones,
  hallId: parseInt(`${smsState.currentHall}`),
  period: smsState.period === -1 ? null : smsState.period,
  deposit: {
    from: smsState.sumFrom ? parseInt(smsState.sumFrom) : null,
    to: smsState.sumTo ? parseInt(smsState.sumTo) : null
  },
  visits: {
    from: smsState.visitFrom ? parseInt(smsState.visitFrom) : null,
    to: smsState.visitTo ? parseInt(smsState.visitTo) : null
  },
  dates: smsState.dates
    ? parseDateToBack({
        date: [moment(smsState.dates?.from), moment(smsState.dates?.to)],
        isObj: true,
        isFromTo: true
      })
    : null
})

/* Тело запроса для создания смс */
const createRequestBody = (smsState: SmsStateProps) => ({
  comment: smsState.comment,
  dttmToSend: createDttmToSend(smsState),
  contact: {
    name: smsState.contactName,
    phone: clearPhone(smsState.contactPhone)
  },
  sms: getSmsParamsForRequest(smsState),
  filters: getFilterParamsForRequest(smsState),
  macros: getMacrosParamsForRequest(smsState)
})

/* Тело запроса для калькуляции смс */
const createCalcBody = (smsState: SmsStateProps) => {
  return {
    comment: null,
    dttmToSend:
      smsState.dateToSend && smsState.timeToSend
        ? createDttmToSend(smsState)
        : null,
    contact: {
      name: null,
      phone: null
    },
    sms: getSmsParamsForRequest(smsState),
    filters: getFilterParamsForRequest(smsState),
    macros: getMacrosParamsForRequest(smsState)
  }
}

export const validate = ({
  smsState,
  setSmsState,
  setModal,
  logout,
  history
}: GetValidateProps) => {
  setSmsState({
    ...smsState,
    pending: true
  })

  betRequest({
    method: `POST`,
    url: smsConstants.urls.validate,
    requestBody: createRequestBody(smsState),
    history,
    logout
  })
    .then(() => {
      setSmsState({
        ...smsState,
        pending: false,
        errors: {}
      })
      setModal(true)
    })
    .catch(error => {
      setSmsState({
        ...smsState,
        pending: false,
        phones: error?.response?.data?.errors?.filters?.phones
          ? [
              ...error.response.data.errors.filters.phones.invalid,
              ...error.response.data.errors.filters.phones.valid
            ]
          : smsState.phones,
        errors: error.response.data.errors
      })
    })
}

/* Создает радиогруппу текстов шаблонов в
 * зависимости от выбранных типов и подтипов
 */
export const getTextItemsByTemplateId = (
  smsState: SmsStateProps
): TextOptionsProps[] => {
  if (!smsState.selectedTypeId && !smsState.selectedSubTypeId) return []

  const texts = smsState.smsSettings.texts.filter(textObject => {
    if (smsState.selectedTypeId && !smsState.selectedSubTypeId) {
      return (
        !textObject.subtypeId && textObject.typeId === smsState.selectedTypeId
      )
    }

    if (!!smsState.selectedTypeId && !!smsState.selectedSubTypeId) {
      return (
        !!textObject.subtypeId &&
        textObject.typeId === smsState.selectedTypeId &&
        textObject.subtypeId === smsState.selectedSubTypeId
      )
    }
    return false
  })

  /* Словарь макросов, отсортированных от большей длины к меньшей,
   * чтоб проще было заменять в тексте макросы.
   * Сначала time_from, а потом time, так как он является частью time_from
   */
  const macrosList = smsState.smsSettings.macros
    .map(macro => macro.field)
    .sort(function(a, b) {
      return b.length - a.length
    })
    .join("|")

  /* Замена макросов &имя_макроса на маску */
  const reg = new RegExp(`(?:${macrosList})`, "gi")

  return texts.map(textObject => {
    const label = textObject.text.replace(reg, matched => {
      const matchedMacro = smsState.smsSettings.macros.filter(
        item => item.field === matched
      )
      return matchedMacro ? matchedMacro[0].mask : matched
    })

    return {
      label: label,
      value: textObject.textId
    }
  })
}

export const canShowStep2 = (smsState: SmsStateProps) => {
  /* Если выбран кастомный текст шаблона или если есть опции выбора в select */
  return (
    smsState.selectedTypeId === smsConstants.customSmsOptionValue ||
    !!getTextItemsByTemplateId(smsState).length
  )
}

export const canShowStep3 = (smsState: SmsStateProps) => {
  /* Если выбран существующий текст шаблона
   * или набран кастомный текст
   */
  return (
    smsState.selectedTypeId !== smsConstants.customSmsOptionValue &&
    !!smsState.selectedTextId
  )
}

export const canShowStep4 = (smsState: SmsStateProps) => {
  /* Если выбран кастомный текст шаблона, то он должен быть заполнен */
  if (smsState.selectedTypeId === smsConstants.customSmsOptionValue) {
    return !!smsState.customSmsText?.length
  }

  /* Далее возможны только варианты выбора существующего текста: */

  /* Если текст не выбран */
  if (!smsState.selectedTextId) return false

  /* Если выбран существующий текст шаблона, то
   * или smsState.extraParams должен быть не пустой
   * (потому что может быть текст и без макросов)
   * или все поля smsState.extraParams должны бьть заполнены
   */

  const copyExtraParamsWithOnlyRequiredFields = { ...smsState.extraParams }

  delete copyExtraParamsWithOnlyRequiredFields.bonusTo
  delete copyExtraParamsWithOnlyRequiredFields.moneyTo

  return (
    isEmpty(copyExtraParamsWithOnlyRequiredFields) ||
    Object.keys(copyExtraParamsWithOnlyRequiredFields).every(x => {
      // Каждое поле должно быть заполнено, кроме адреса //
      if (x !== macrosRaw.address) {
        return copyExtraParamsWithOnlyRequiredFields[x] !== ""
      } else {
        return true
      }
    })
  )
}

export const canShowStep5 = (smsState: SmsStateProps) => {
  if (!canShowStep4(smsState)) {
    return false
  }

  /* Если выбрано по Всем номерам клуба, то открываем следующий шаг */
  if (smsState.sendingType === sendingsTypeValues.all) {
    return true
  }

  /* Если не выбрано по Всем номерам клуба,
   * то открываем следующий шаг если инпут заполнен
   */
  if (smsState.sendingType !== sendingsTypeValues.all) {
    return !!smsState.phones.length
  }
  /* При всех остальных вариантах- запрещаем */
  return false
}

export const canShowStep6 = (smsState: SmsStateProps) => {
  return canShowStep5(smsState)
}

export const canShowStep7 = (smsState: SmsStateProps) => {
  return canShowStep6(smsState)
}

export const canShowStep8 = (smsState: SmsStateProps) => {
  return (
    canShowStep7(smsState) && !!smsState.dateToSend && !!smsState.timeToSend
  )
}

export const getTemplateName = (smsState: SmsStateProps): string => {
  if (smsState.selectedTypeId === smsConstants.customSmsOptionValue)
    return smsConstants.customText

  return smsState.smsSettings.types.filter(
    type => type.typeId === smsState.selectedTypeId
  )[0].name
}

export const getSubTemplateName = (smsState: SmsStateProps): string => {
  if (
    !smsState.selectedTypeId ||
    !smsState.selectedSubTypeId ||
    smsState.selectedTypeId === smsConstants.customSmsOptionValue
  ) {
    return ""
  }

  const templateObject = smsState.smsSettings.types.filter(
    type => type.typeId === smsState.selectedTypeId
  )[0]

  if (!templateObject) return ""

  return (
    templateObject.subtypes?.filter(
      subType => subType.subtypeId === smsState.selectedSubTypeId
    )[0].name ?? ""
  )
}

/* Находит выбранный в селекте текст из массива всех текстов */
export const getSmsText = (smsState: SmsStateProps): string => {
  const selectedTextItem: TextItem[] = smsState.smsSettings.texts.filter(
    (text: TextItem) => {
      /* 1) Текст принадлежит ПОДТИПУ шаблона.
       * Условие: есть подтип шаблона smsState.selectedSubTypeId
       */
      if (!!smsState.selectedSubTypeId) {
        return (
          text.textId === smsState.selectedTextId &&
          text.typeId === smsState.selectedTypeId &&
          smsState.selectedSubTypeId === text.subtypeId
        )
      } else {
        /* 2) Текст принадлежит только ТИПУ шаблона и не пренадлежит подтипу.
         * Условие: нет подтипа шаблона (smsState.selectedSubTypeId === undefined)
         * и объект text не будет иметь subtypeId
         */
        return (
          !text.subtypeId &&
          text.textId === smsState.selectedTextId &&
          text.typeId === smsState.selectedTypeId
        )
      }
    }
  )

  return selectedTextItem[0]?.text ?? ""
}

/* Поиск макросов в тексте */
export const findMacros = (text: string, smsState: SmsStateProps): string[] => {
  /* Результирующий массив с набором макросов в тексте */
  let macrosFounded: string[] = []

  /* Попробуем найти в тексте каждый из известных макросов  */
  smsState.smsSettings.macros.forEach((macro: MacroItem) => {
    /* Кроме набора известных макросов может содержаться
     * сколь угодно большое кол-во макросов команд, состоящих из
     * стандартного макроса &command с добавлением цифры после него
     * (например, &command1, &command2,... &command15)
     * При этом сам макрос &command никогда отдельно встречаться не будет
     */
    const macroName = macro.field
    const regExpSearch = `${macroName}([_0-9]+)?`

    const founded = text.match(new RegExp(regExpSearch, `g`))

    if (founded) {
      /* Очистим от повторяющихся значений и положим в результирующий массив */
      const uniqueFounded = [...Array.from(new Set(founded))]
      macrosFounded = [
        ...macrosFounded,
        ...uniqueFounded.filter(item => !item.endsWith("_"))
      ]
    }
  })

  return macrosFounded
}

export const isValidRuPhone = (phone: string): boolean => {
  const clearedPhone = clearPhone(phone)
  if (!clearedPhone) {
    return false
  }

  return clearedPhone.length === 11 && clearedPhone[0] === "7"
}

export const toCamelCase = (str: string, delimiter = "_") => {
  const strArr = str.split(delimiter)
  const length = strArr.length
  if (length === 1) return str

  let resultString = strArr[0]
  for (let i = 1; i < length; i++) {
    const capitalizedFirstLetter = strArr[i].charAt(0).toUpperCase()
    const otherPartOfWord = strArr[i].slice(1)

    resultString = `${resultString}${capitalizedFirstLetter}${otherPartOfWord}`
  }
  return resultString
}

export const removeAmpersand = (text: string): string => {
  return text.replace(smsConstants.ampersandValue, "")
}

export const replaceTextForPhonePreview = (smsState: SmsStateProps) => {
  const text = getSmsText(smsState)
  const macrosFindedArr = findMacros(text, smsState)

  const macrosList = macrosFindedArr
    .sort(function(a, b) {
      return b.length - a.length
    })
    .join("|")

  const reg = new RegExp(`(?:${macrosList})`, "gi")

  return text.replace(reg, matched => {
    const matchedAsParam = removeAmpersand(matched)

    switch (matchedAsParam) {
      case macrosRaw.date:
        return moment(smsState.extraParams[toCamelCase(matchedAsParam)]).format(
          "DD MMMM"
        )
      case macrosRaw.time:
      case macrosRaw.timeFrom:
      case macrosRaw.timeTo:
        return moment(smsState.extraParams[toCamelCase(matchedAsParam)]).format(
          "HH:mm"
        )
      case macrosRaw.bonus:
      case macrosRaw.money:
        const filteredMacro = smsState.smsSettings.macros.filter(
          macro => macro.field === macros[matchedAsParam]
        )
        return filteredMacro[0]?.mask ?? matched
      case macrosRaw.address:
        if (isEmpty(smsState.extraParams[toCamelCase(matchedAsParam)])) {
          return (
            smsState.smsSettings.macros.filter(
              macro => macro.field === macros[matchedAsParam]
            )[0]?.mask ?? matched
          )
        } else {
          return (
            (smsState.extraParams[toCamelCase(matchedAsParam)] as string) ??
            matchedAsParam
          )
        }
      default:
        return (
          (smsState.extraParams[toCamelCase(matchedAsParam)] as string) ??
          matchedAsParam
        )
    }
  })
}

/* Разрешен Выбор даты */
export const smsDateRestrictions = (current: Moment) =>
  (current &&
    current >
      moment()
        .add(20, Durations.day)
        .endOf(Durations.day)) ||
  (current && current < moment().startOf(Durations.day))

export const getStepName = (stepNumber: number, rename: boolean): number => {
  return rename ? stepNumber - 1 : stepNumber
}

export const smsStateInitPropsCreator = (hallId: number) => ({
  ...smsConstants.smsStateInitWithoutHallId,
  currentHall: hallId
})

export const formatValueWithSpaces = (str: string) => {
  if (!!str) {
    return new Intl.NumberFormat("ru").format(parseInt(str.trim()))
  }

  return str
}
