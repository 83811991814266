import React from "react"
import { isEmpty, noop } from "lodash"
import moment, { Moment } from "moment"

import { SideFilterDates } from "Components/SideFilter/types"

import { formatToCurrency } from "constants/index"
import { getFilterStateFromLocalStorage } from "api/helpers"

import { ReactComponent as ReplenishmentIcon } from "./icons/replenishment.svg"
import { ReactComponent as WithdrawalIcon } from "./icons/withdrawal.svg"
import { ReactComponent as WinIcon } from "./icons/win.svg"
import {
  columnKeys,
  initialFilterData,
  MAILING_HISTORY_FILTER,
  periodText
} from "./constant"
import { GetColumnsProps, HistoryTypeDict } from "./types"
import { TableColumnWidth } from "Components/Tabs/types"

export const getInitialFilterState = () => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    MAILING_HISTORY_FILTER,
    true
  )
  const emptyFromStorage = isEmpty(initFilterStateFromStorage)

  return {
    isOpen: true,
    isActive: !emptyFromStorage,
    data: emptyFromStorage ? initialFilterData : initFilterStateFromStorage,
    mobileFilter: false
  }
}

export const getDateDescriptionLayout = (dates?: SideFilterDates) => {
  const fromDate = moment(dates?.from).format(columnKeys.date.format)
  const toDate = moment(dates?.to).format(columnKeys.date.format)
  return !!dates ? (
    <div className="FlexRow MailingHistoryDateDescription">
      <span className="MailingHistoryDateDescriptionDef">
        {periodText.label}
      </span>
      <span className="MailingHistoryDateDescriptionBold">{fromDate}</span>
      <span className="MailingHistoryDateDescriptionDef">
        {columnKeys.date.hyphen}
      </span>
      <span className="MailingHistoryDateDescriptionBold">{toDate}</span>
    </div>
  ) : (
    <div />
  )
}

export const getColumnActiveClass = (field: string | undefined, key: string) =>
  field === key ? `ActiveSortTableColumn` : ``

const getConfigByType = (type: HistoryTypeDict) => {
  switch (type) {
    case HistoryTypeDict.replenishment:
      return {
        label: `Пополнение`,
        icon: <ReplenishmentIcon />
      }
    case HistoryTypeDict.withdrawal:
      return {
        label: `Вывод`,
        icon: <WithdrawalIcon />
      }
    case HistoryTypeDict.win:
      return {
        label: `Выигрыш`,
        icon: <WinIcon />
      }
  }
}

export const historyColumn = ({ sort }: GetColumnsProps) => [
  {
    title: () => (
      <div className="MailingHistoryThCell MailingHistoryThCellDate">
        {columnKeys.date.label}
      </div>
    ),
    dataIndex: columnKeys.date.id,
    className:
      sort.field && sort.order
        ? getColumnActiveClass(sort.field, columnKeys.date.id)
        : ``,
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.date.id,
    width: 200,
    render: (date: Moment) => (
      <div className="FlexRow">
        <span className="MailingHistoryTdDate">
          {moment(date).format(columnKeys.date.format)}
        </span>
        <span className="MailingHistoryTdDateDescription">
          {moment(date).format(columnKeys.date.time)}
        </span>
      </div>
    )
    //
  },
  {
    title: () => (
      <div className="MailingHistoryThCell">{columnKeys.hall.label}</div>
    ),
    dataIndex: columnKeys.hall.id,
    className:
      sort.field && sort.order
        ? getColumnActiveClass(sort.field, columnKeys.hall.id)
        : ``,
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.hall.id,
    width: TableColumnWidth.s
  },
  {
    title: () => (
      <div className="MailingHistoryThCell">{columnKeys.type.label}</div>
    ),
    dataIndex: columnKeys.type.id,
    className:
      sort.field && sort.order
        ? getColumnActiveClass(sort.field, columnKeys.type.id)
        : ``,
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.type.id,
    width: 200,
    render: (type: HistoryTypeDict) => {
      const typeConfig = getConfigByType(type)
      return (
        <div className="FlexRow MailingHistoryTdType">
          {typeConfig.icon}
          <span>{typeConfig.label}</span>
        </div>
      )
    }
  },
  {
    title: () => (
      <div className="MailingHistoryThCell">{columnKeys.sum.label}</div>
    ),
    dataIndex: columnKeys.sum.id,
    className:
      sort.field && sort.order
        ? getColumnActiveClass(sort.field, columnKeys.sum.id)
        : ``,
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.sum.id,
    width: 200,
    render: (sum: number) => {
      const currency = formatToCurrency({
        amount: sum,
        withoutFixed: true
      })
      return <div className="MailingDetailTableCell">{currency || `—`}</div>
    }
  },
  {
    title: () => (
      <div className="MailingHistoryThCell">{columnKeys.phone.label}</div>
    ),
    dataIndex: columnKeys.phone.id,
    showSorterTooltip: false,
    key: columnKeys.phone.id,
    width: 220
  }
]
