import React from "react"

import { AccessTemplates, DashboardsKeys } from "store/access/types"

import superwinIcon from "./icons/superwin.png"
import balanceIcon from "./icons/balance.png"
import newRegistrationsIcon from "./icons/new_registrations.png"
import cashboxIcon from "./icons/cashbox.png"
import visitIcon from "./icons/visit_count.png"

import { ReactComponent as CloseIcon } from "Components/icons/close_round_20.svg"

import { FormPropsData } from "./types"
import { CardsListData } from "../Cards/types"
import { AccessStatuses } from "../types"

export const labelText = {
  full_name: {
    label: `ФИО`,
    field: `full_name`,
    placeholder: `Введите ФИО`
  },
  login: {
    label: `Логин`,
    field: `login`,
    placeholder: `Введите логин`,
    custom_rule: {
      validator: <T, D>(_: T, value: D) => {
        if (!!value && `${value}`?.length < 6) {
          return Promise.reject(
            new Error(`Минимальное значение логина, 6 символов`)
          )
        } else {
          return Promise.resolve()
        }
      }
    }
  },
  template_name: {
    label: `Шаблон`,
    field: `template_name`,
    placeholder: `Выберите шаблон`
  },
  custom_template_name: {
    label: `Должность сотрудника`,
    field: `custom_template_name`,
    placeholder: `Введите название должности`
  },
  dashboards: {
    label: `Дашборды`,
    tooltip: `Дашборды, которые будут видны на главной странице, если в доступных пунктах меню был выбран раздел Главная. Можно выбрать все, несколько или ни одного дашборда.`,
    field: `dashboards`,
    placeholder: `Выберите дашборды`
  },
  halls: {
    field: `halls`,
    label: `Доступ к клубам`,
    placeholder: `Выберите клубы`
  },
  status: {
    label: `Статус доступа`,
    tooltip: `Возможны статусы Активен, Приостановлен и Возобновление. При создании нового доступа возможно выбрать только статусы Активен и Приостановлен, если доступ в статусе Приостановлен, возможно выбрать только статус Возобновление. В статусе Возобновление можно выбрать только статус Приостановлен.`,
    field: `status`,
    placeholder: `Выберите статус`
  },
  phone: {
    label: `Номер телефона`,
    tooltip: ``,
    field: `phone`,
    placeholder: `Введите номер телефона`
  },
  email: {
    label: `Email`,
    field: `email`,
    placeholder: `Введите Email`
  },
  password: {
    label: `Пароль`,
    field: `password`,
    placeholder: `Введите пароль`,
    link: `Сгенерировать`
  },
  send_password: {
    label: `Отправить пароль на почту`,
    field: `send_password`
  },
  submit: {
    create: `Создать`,
    save: `Сохранить`
  },
  menu_items: {
    label: `Пункты меню`,
    placeholder: `Выберите пункты меню`,
    field: `menu_items`
  }
}

export const statusOptions = ({ current }: { current?: AccessStatuses }) => {
  const active = [
    {
      value: AccessStatuses.active,
      label: `Активен`
    }
  ]
  const resuming = [
    {
      value: AccessStatuses.resuming,
      label: `Возобновление`
    }
  ]
  const paused = [
    {
      value: AccessStatuses.paused,
      label: `Приостановлен`
    }
  ]
  if (!current) {
    return [...active, ...paused]
  } else if (current === AccessStatuses.active) {
    return [...active, ...paused]
  } else {
    return [...resuming, ...paused]
  }
}

export const initialRequiredList = [`home`, `messages`]

export const initialForm = (data?: CardsListData): FormPropsData => {
  return {
    full_name: data?.full_name || undefined,
    login: data?.login || undefined,
    template_name: data?.template_name || undefined,
    custom_template_name: data?.custom_template_name || undefined,
    send_to_email: false,
    halls: data?.halls || undefined,
    dashboards: data?.dashboards || undefined,
    status: data?.status || undefined,
    phone: data?.phone || undefined,
    email: data?.email || undefined,
    menu_items: data?.menu_items || initialRequiredList
  }
}

export const generatePassword = () => {
  const length = 11
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?"
  let result = ""
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

const dashboardOptions = [
  {
    value: DashboardsKeys.attendance,
    label: `Количество посещений`,
    icon: (
      <img
        src={visitIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  },
  {
    value: DashboardsKeys.payments,
    label: `Кассовый результат`,
    icon: (
      <img
        src={cashboxIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  },
  {
    value: DashboardsKeys.new_guests,
    label: `Новые регистрации`,
    icon: (
      <img
        src={newRegistrationsIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  },
  {
    value: DashboardsKeys.balance,
    label: `Баланс для выплат`,
    icon: (
      <img
        src={balanceIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  },
  {
    value: DashboardsKeys.superwins,
    label: `Супервыигрыш`,
    icon: (
      <img
        src={superwinIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  }
]

export const getDashboardOptions = (dashboards: DashboardsKeys[]) => {
  return dashboards.map(item => {
    return dashboardOptions.find(option => option.value === item)
  })
}

export const DashboardTagRender = (props: any) => {
  const { onClose, value } = props
  const currentDashboard = dashboardOptions.find(item => item.value === value)

  return (
    <div className="FlexRow Gap8 AccessDashboardTag">
      <div>{currentDashboard?.icon}</div>
      <div className="HoverItem" onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  )
}

export const menuConstants = {
  label: `Доступные пункты меню:`,
  tags: `Добавленные пункты меню:`,
  title_create: `Создание доступа`,
  title_edit: `Редактирование доступа`
}

export const defaultMenuItemsByRole = (template?: AccessTemplates | string) => {
  if (
    !!template &&
    (template === AccessTemplates.admin || template === AccessTemplates.manager)
  ) {
    return [`home`, `messages`, `wiki`]
  }
  return []
}

export enum CustomTempalesOptions {
  input = `input`,
  select = `select`
}

export const CustomTempalesRadioOptions = [
  { label: `Создать`, value: CustomTempalesOptions.input },
  { label: `Выбрать`, value: CustomTempalesOptions.select }
]

export const cancelModal = {
  title: `Продолжить редактирование?`,
  description: `Вы уверены что хотите выйти? Изменения не будут \nсохранены.`,
  cancel: `Выйти`,
  apply: `Продолжить`
}
