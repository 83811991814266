export enum MailingHallStatus {
  active = `active`,
  inactive = `inactive`
}

export type MailingFilterData = {
  halls: number[]
  status: MailingHallStatus[] | undefined
  search: string | undefined
}

export type GetInitialFilterState = {
  halls: number[]
}

export type MailingFilterState = {
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
  data: MailingFilterData
}
