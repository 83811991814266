import React from "react"
import {
  RiskProfile,
  WalletColumn,
  WalletInfoHistory,
  WalletModalColumns
} from "./types"
import { WalletIdCell } from "../Table/helper"
import TooltipComponent from "Components/Tooltip"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"
import { TableColumns } from "../Table/constants"
import moment from "moment"

export const text = {
  title: "Информация о госте",
  rogerThatButton: "Понятно",
  historyButton: "История изменений",
  columns: {
    [WalletModalColumns.risk_profile_name]: "Риск профиль",
    [WalletModalColumns.limit_percent]: "Лимит процент",
    [WalletModalColumns.delay]: "Задержка",
    [TableColumns.wallet_id]: "№ кошелька",
    change_date: "Дата и время\n изменения"
  },
  arrow: "→",
  empty: "Информация по гостю отсутствуют"
}

export interface WalletCellWithHistoryProps {
  old?: string | Element
  new?: string | Element
}

const delayColumn = {
  title: text.columns[WalletModalColumns.delay],
  dataIndex: WalletModalColumns.delay,
  key: WalletModalColumns.delay,
  render: (value: number, record: RiskProfile | WalletInfoHistory) => {
    let title: null | number = value
    let titleNew: null | number = null
    if (
      (record as WalletInfoHistory)?.old &&
      (record as WalletInfoHistory)?.new
    ) {
      title = (record as WalletInfoHistory).old?.[WalletModalColumns.delay]
      titleNew = (record as WalletInfoHistory).new?.[WalletModalColumns.delay]
    }

    if (!title) {
      title = titleNew
    }

    return titleNew && title !== titleNew ? (
      <div className="SportBoomWalletModalRiskProfileContainerWithHistory">
        <div className="SportBoomWalletModalRiskProfileContainerWithHistoryOld">{`${title} ${text.arrow}`}</div>
        <div className="SportBoomWalletModalRiskProfileContainer">
          <div className="SportBoomWalletModalRiskProfileContainerWithHistoryNew">
            {titleNew}
          </div>
        </div>
      </div>
    ) : (
      <div className="SportBoomWalletModalRiskProfileContainer">
        <div>{title || "-"}</div>
      </div>
    )
  }
}

export const rootWalletColumns = [
  {
    title: text.columns[WalletModalColumns.risk_profile_name],
    dataIndex: WalletModalColumns.risk_profile_name,
    key: WalletModalColumns.risk_profile_name,
    render: (value: string, record: RiskProfile | WalletInfoHistory) => {
      let title = value
      let titleNew = ""
      let tooltip = (record as RiskProfile)?.[
        WalletModalColumns.risk_profile_description
      ]
      if (
        (record as WalletInfoHistory)?.old &&
        (record as WalletInfoHistory)?.new
      ) {
        title = (record as WalletInfoHistory).old?.[
          WalletModalColumns.risk_profile_name
        ]
        titleNew = (record as WalletInfoHistory).new?.[
          WalletModalColumns.risk_profile_name
        ]
      }

      if (!title) {
        title = titleNew
      }

      if (!tooltip) {
        tooltip = (record as WalletInfoHistory).new?.[
          WalletModalColumns.risk_profile_description
        ]
      }
      return titleNew && title !== titleNew ? (
        <div className="SportBoomWalletModalRiskProfileContainerWithHistory">
          <div className="SportBoomWalletModalRiskProfileContainerWithHistoryOld">{`${title} ${text.arrow}`}</div>
          <div className="SportBoomWalletModalRiskProfileContainer">
            <div className="SportBoomWalletModalRiskProfileContainerWithHistoryNew">
              {titleNew}
            </div>
            <TooltipComponent title={tooltip} placement={"top"}>
              <InfoIcon />
            </TooltipComponent>
          </div>
        </div>
      ) : (
        <div className="SportBoomWalletModalRiskProfileContainer">
          <div>{title}</div>
          {tooltip && (
            <TooltipComponent
              title={tooltip}
              overlayClassName="TooltipComponentWrapper TooltipComponentWrapper_SportBoomToolTipWrapper"
            >
              <InfoIcon />
            </TooltipComponent>
          )}
        </div>
      )
    }
  },
  {
    title: text.columns[WalletModalColumns.limit_percent],
    dataIndex: WalletModalColumns.limit_percent,
    key: WalletModalColumns.limit_percent,
    render: (value: number, record: RiskProfile | WalletInfoHistory) => {
      let title: null | number = value
      let titleNew: null | number = null
      if (
        (record as WalletInfoHistory)?.old &&
        (record as WalletInfoHistory)?.new
      ) {
        title = (record as WalletInfoHistory).old?.[
          WalletModalColumns.limit_percent
        ]
        titleNew = (record as WalletInfoHistory).new?.[
          WalletModalColumns.limit_percent
        ]
      }

      if (!title) {
        title = titleNew
      }

      return titleNew && title !== titleNew ? (
        <div className="SportBoomWalletModalRiskProfileContainerWithHistory">
          <div className="SportBoomWalletModalRiskProfileContainerWithHistoryOld">{`${title} ${text.arrow}`}</div>
          <div className="SportBoomWalletModalRiskProfileContainer">
            <div className="SportBoomWalletModalRiskProfileContainerWithHistoryNew">
              {titleNew}
            </div>
          </div>
        </div>
      ) : (
        <div className="SportBoomWalletModalRiskProfileContainer">
          <div>{title || "-"}</div>
        </div>
      )
    }
  }
]

export const columnsRightModalWallet: WalletColumn[] = [
  {
    title: () => (
      <div className="PaddingLeft12">{text.columns.change_date}</div>
    ),
    dataIndex: "change_date",
    key: "change_date",
    render: (date: string) => {
      const dateFormatted = !!date ? moment(date).format("DD.MM.YYYY") : ""
      const timeFormatted = !!date ? moment(date).format("HH:mm") : ""

      return (
        <div className="SportBoomDateTimeCell">
          <div>{dateFormatted}</div>
          <div className="SportBoomDateTimeCell_time">{timeFormatted}</div>
        </div>
      )
    }
  },
  ...rootWalletColumns
]

export const makeColumnsRightModalWalletMobile = (
  data?: WalletInfoHistory | null,
  isManager?: boolean
) =>
  (isManager ? columnsRightModalWalletFull : columnsRightModalWallet).map(
    el => ({
      description: el.title,
      text: data && el.render(data[el.key as keyof WalletInfoHistory], data)
    })
  )

export const columnsModalWallet = [
  {
    title: () => (
      <div className="PaddingLeft12">
        {text.columns[TableColumns.wallet_id]}
      </div>
    ),
    //    width: isMobile ? 90 : 140,
    dataIndex: TableColumns.wallet_id,
    key: TableColumns.wallet_id,
    render: (wallet_id: number) => (
      <WalletIdCell
        id={wallet_id}
        additionalClassName={"SportBoomWalletModalCellWalletId"}
      />
    )
  },
  ...rootWalletColumns
]

export interface MobileCellProps {
  title: string
  value: string | number | null
  tooltip?: string
}

export const MobileCell: React.FC<MobileCellProps> = ({
  title,
  value,
  tooltip
}) => {
  return (
    <td>
      <div className="SportBoomWalletModalMobileTableCell">
        <div className="SportBoomWalletModalMobileTableCellTitle">{title}</div>
        <div className="SportBoomWalletModalMobileTableCellValue">
          <div className="SportBoomWalletModalMobileTableCellValueInner">
            {value || "-"}
          </div>
          {tooltip && (
            <TooltipComponent
              title={tooltip}
              overlayClassName="TooltipComponentWrapper TooltipComponentWrapper_SportBoomToolTipWrapper"
            >
              <InfoIcon />
            </TooltipComponent>
          )}
        </div>
      </div>
    </td>
  )
}

export const columnsModalWalletFull = [...columnsModalWallet, delayColumn]
export const columnsRightModalWalletFull = [
  ...columnsRightModalWallet,
  delayColumn
]
