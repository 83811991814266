import React, { useState } from "react"

import { isMobile } from "constants/index"

import { ReactComponent as BackIcon } from "Components/icons/arrow_left_outline_28.svg"

import { ModalFormComponent } from "Components/Modal"
import { ButtonNotifi, ButtonPrimary } from "Components/Button"

import { cancelModal, menuConstants } from "./constants"

const CancelModal = ({
  close,
  isEdit
}: {
  close: () => void
  isEdit: boolean
}) => {
  const [confirmModal, toggleConfirmModal] = useState(false)
  return (
    <>
      <div className="FlexRow Gap12 PaddingBottom24">
        <div
          onClick={() => {
            if (isEdit) {
              toggleConfirmModal(true)
            } else {
              close()
            }
          }}
          className="PaddingTop2 HoverItem"
        >
          <BackIcon />
        </div>
        <span className="HeadlineFirstText">
          {isEdit ? menuConstants.title_edit : menuConstants.title_create}
        </span>
      </div>
      {confirmModal && (
        <ModalFormComponent
          width={480}
          close={() => toggleConfirmModal(false)}
          withoutCross={true}
        >
          <div className={`FlexColumn Gap16 ${isMobile ? `` : `Padding8`}`}>
            <div className="HeadlineSecondText">{cancelModal.title}</div>
            <div className="TextTwo MarginBottom8">
              {cancelModal.description}
            </div>
            <div className="MarginLeftAuto FlexRow Gap8 RemoveAccessButtons">
              <ButtonNotifi color="gray" onClick={close}>
                <span className="ButtonTextFirst">{cancelModal.cancel}</span>
              </ButtonNotifi>
              <ButtonPrimary
                onClick={() => {
                  toggleConfirmModal(false)
                }}
              >
                <span className="ButtonTextFirst">{cancelModal.apply}</span>
              </ButtonPrimary>
            </div>
          </div>
        </ModalFormComponent>
      )}
    </>
  )
}

export default CancelModal
