import { FilterRangeFields } from "../types"

export const baseFields = {
  name: `name`,
  wallet_number: `wallet_number`,
  visits_count: `visits_count`
}

export const tableText = {
  url: `v1/guests/guests-filters`,
  initialData: {
    pending: false,
    total: 0,
    data: []
  },
  table: {
    games: {
      more: (count: number) => `...ещё ${count}`,
      cancel: `свернуть`,
      sportboom: `Спорт`
    }
  },
  [FilterRangeFields.bets_sum]: {
    field: `bets_sum`,
    title: `Сумма\nставок`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.win_sum]: {
    field: `win_sum`,
    title: `Сумма\nвыигрыша`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.bonus_bets_sum]: {
    field: `bonus_bets_sum`,
    title: `Бонусных\nставок`,
    sportboom_title: `Фрибет\nставок`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.freebet_bets_sum]: {
    field: `bonus_bets_sum`,
    title: `Фрибет\nставок`,
    width: 104,
    isCurrency: true
  },
  game_ids: {
    field: `game_ids`,
    title: `Играл в`,
    width: 256,
    withoutSort: true,
    isCurrency: false
  },
  [FilterRangeFields.cash_in]: {
    field: `cash_in`,
    title: `Ввод \nналичн.`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.noncash_in]: {
    field: `noncash_in`,
    title: `Ввод\nбезнал.`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.cash_out]: {
    field: `cash_out`,
    title: `Вывод \nналичн.`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.noncash_out]: {
    field: `noncash_out`,
    title: `Вывод\nбезнал.`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.money_balance]: {
    field: `money_balance`,
    title: `Денежный\nбаланс`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.bonus_balance]: {
    field: `bonus_balance`,
    title: `Бонусный\nбаланс`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.transfer_to]: {
    field: `transfer_to`,
    title: `Перенос\nв клуб`,
    width: 104,
    isCurrency: true
  },
  [FilterRangeFields.transfer_from]: {
    field: `transfer_from`,
    title: `Перенос\nиз клуба`,
    width: 104,
    isCurrency: true
  },
  base_columns_arr: [
    {
      field: `wallet_number`,
      title: `№ \nкошелька`,
      width: 120,
      isCurrency: false,
      fixed: true
    },
    {
      field: `name`,
      title: `ФИО`,
      width: 200,
      isCurrency: false,
      withoutSort: true
    },
    {
      field: `visits_count`,
      title: `Кол-во\nвизитов`,
      isCurrency: false,
      width: 104
    }
  ]
}
