import React from "react"

import { CheckBoxComponent } from "Components/CheckBox"

import { mailingModalText } from "./constant"
import { getClearByType } from "./helper"
import { FieldCheckBoxProps } from "./types"

const FieldCheckBox = ({
  form,
  field,
  isType,
  active,
  setActive
}: FieldCheckBoxProps) => {
  return (
    <div className="MailingModalCheckboxField">
      <CheckBoxComponent
        checked={!!active[field]}
        label={mailingModalText.form.checkbox[field]?.label}
        onChange={({ target: { checked } }) => {
          const clearErrorsData = getClearByType(isType)
          form.setFields(clearErrorsData)
          setActive({
            ...active,
            [field]: checked
          })
        }}
      />
    </div>
  )
}

export default FieldCheckBox
