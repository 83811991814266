import betRequest from "api/request"

export const STORE_MESSAGE = "STORE_MESSAGE"
export const STORE_EMERGENCY_MESSAGE = "STORE_EMERGENCY_MESSAGE"
export const STORE_COUNT_MESSAGE = "STORE_COUNT_MESSAGE"
export const CLOSE_EMERGENCY_MESSAGE = "CLOSE_EMERGENCY_MESSAGE"
export const OPEN_EMERGENCY_MESSAGE = "OPEN_EMERGENCY_MESSAGE"

import RequestParamsProps from "constants/type"
import {
  EmergencyDataType,
  MessagesResponse,
  MessageCountResult,
  MessageStoreState
} from "./types"

interface IApiStoreMessageProps extends RequestParamsProps {
  emergency?: EmergencyDataType[]
  storeMessageCount: (value: MessageStoreState) => void
}

export const apiStoreMessage = ({
  history,
  addDeveloperLink,
  developer,
  logout,
  storeMessageCount,
  emergency
}: IApiStoreMessageProps) => {
  return betRequest({
    url: `message/count`,
    method: `GET`,
    history,
    logout,
    developer,
    addDeveloperLink
  }).then(
    ({
      data: {
        show_popup,
        result: { unread }
      }
    }: MessagesResponse<MessageCountResult>) =>
      storeMessageCount({
        message_count: unread,
        emergency: emergency || [],
        emergency_open: show_popup && !!emergency
      })
  )
}
