import React, { useEffect, useMemo, useState } from "react"

import { useRequest, useRequestParams } from "api/hooks"
import betRequest from "api/request"

import ButtonDefault, { ButtonNotifi, ButtonPrimary } from "Components/Button"
import { SelectComponent } from "Components/Select"
import InputComponent from "Components/Input"

import { ReactComponent as DeleteIcon } from "Components/icons/delete_24.svg"
import { ReactComponent as CloseIcon } from "Components/icons/close-big.svg"
import { ReactComponent as MoveIcon } from "Components/icons/move_24.svg"
import { ReactComponent as ViewOnIcon } from "Components/icons/view_on.svg"
import { ReactComponent as ViewOffIcon } from "Components/icons/view_off.svg"

import DragAndDropList from "Components/DragList"
import ModalComponent from "Components/Modal"

import { SportBoomTabs } from "Containers/SportBoom/constants"

import {
  configModalText,
  templateListToConfig,
  initialRemove
} from "./constants"
import {
  TableConfig,
  TableConfigData,
  TableConfigTemplateList,
  TableConfigTemplateResponse
} from "./types"
import { TableColumns, tableConstants } from "../constants"

import { Select } from "antd"

const Option = Select.Option

type TableConfigModalProps = {
  config: TableConfig
  changeConfig: (value: TableConfig) => void
  activeTab: SportBoomTabs
}

const TableConfigModal = ({
  config,
  changeConfig,
  activeTab
}: TableConfigModalProps) => {
  const [currentConfig, setCurrentConfig] = useState<TableConfig>(config)
  const [templateList, setTemplateList] = useState<TableConfigTemplateList>({
    pending: false,
    save: undefined,
    error: undefined,
    list: []
  })

  const [isRemoveTemplateModalOpen, toggleRemoveTemplateModal] = useState<{
    id: number
    label: string
    open: boolean
  }>(initialRemove)

  const isSaveOpen = templateList.save !== undefined

  const isAllHide = useMemo(
    () => currentConfig.hideCount === currentConfig.data.length,
    [currentConfig.hideCount]
  )

  const changeView = (field: TableColumns) => {
    let changeCount = 0
    const newArray = currentConfig.data.map(item => {
      const isCurrentField = item.field === field
      if (isCurrentField) {
        changeCount = item.visible ? 1 : -1
      }
      return isCurrentField ? { ...item, visible: !item.visible } : item
    })
    setCurrentConfig({
      ...currentConfig,
      data: newArray,
      hideCount: currentConfig.hideCount + changeCount,
      template: null
    })
  }

  const { request: getTemplates } = useRequest({
    url: `v1/sportboom/table/templates/${activeTab}`,
    method: `GET`
  })

  const { developer, addDeveloperLink, logout, history } = useRequestParams()

  const changeTemplates = (templates: TableConfig, name: string) => {
    const list: any = {}
    for (let i = 0; i < templates.data.length; i++) {
      list[templates.data[i].field] = templates.data[i].visible
    }
    return betRequest({
      url: `v1/sportboom/table/templates/${activeTab}`,
      method: `POST`,
      developer,
      addDeveloperLink,
      logout,
      history,
      requestBody: {
        name,
        columns: list
      }
    })
  }

  const removeTemplates = (id: number) => {
    return betRequest({
      url: `v1/sportboom/table/templates/${activeTab}/${id}`,
      method: `DELETE`,
      developer,
      addDeveloperLink,
      logout,
      history
    })
  }

  useEffect(() => {
    setTemplateList({ ...templateList, pending: true })
    getTemplates().then(({ data }: { data: TableConfigTemplateResponse[] }) => {
      const list = templateListToConfig(data)
      setTemplateList({
        pending: false,
        list: [
          {
            label: configModalText.initialTemplate.label,
            value: configModalText.initialTemplate.id,
            data: configModalText.initialTemplate.data
          },
          ...list
        ]
      })
    })
  }, [])

  const listItemByContent = (item: TableConfigData) => {
    return (
      <div
        className="FlexRow TableConfigModalListItem"
        key={`view_list_${item.field}`}
      >
        <MoveIcon />
        <span className="TextLabelText Shark500Color">
          {tableConstants[item.field].label}
        </span>
        <div
          className="TableConfigModalListItemView"
          onClick={() => changeView(item.field)}
        >
          {item.visible ? <ViewOnIcon /> : <ViewOffIcon />}
        </div>
      </div>
    )
  }

  const changeOrder = (list: any[]) =>
    setCurrentConfig({ ...currentConfig, data: list, template: null })

  return (
    <div className="TableConfigModalWrapper">
      <div className="FlexRow">
        <span className="HeadlineSecondText">{configModalText.title}</span>
        <div
          className="TableConfigModalClose"
          onClick={() => {
            changeConfig({ ...config, open: false })
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="TableConfigModalTemplateWrapper FlexColumn">
        <span className="BodyFirstText">
          {configModalText.template.templateTitle}
        </span>
        <SelectComponent
          allowClear={false}
          placeholder={configModalText.template.templatePlaceholder}
          disabled={templateList.pending}
          value={currentConfig.template}
          onChange={value => {
            const current = templateList.list.find(item => item.value === value)
            const hideCount =
              current?.data.filter(item => !item.visible).length || 0
            setCurrentConfig({
              ...currentConfig,
              hideCount,
              data: current?.data || [],
              template: current?.label || null
            })
          }}
          dropdownRender={elem => (
            <div>
              {elem}
              {isSaveOpen ? (
                <div className="FlexColumn TableConfigModalTemplateAddForm">
                  <InputComponent
                    placeholder=""
                    error={templateList.error}
                    value={templateList.save || ``}
                    onChange={({ target: { value } }) =>
                      setTemplateList({ ...templateList, save: value || null })
                    }
                  />
                  <div className="FlexRow Gap16">
                    <span
                      className="Gray600Color HoverItem"
                      onClick={() =>
                        setTemplateList({ ...templateList, save: undefined })
                      }
                    >
                      {configModalText.template.cancel}
                    </span>
                    <span
                      className="LinkText HoverItem"
                      onClick={() => {
                        if (!!templateList.save) {
                          setTemplateList({ ...templateList, pending: true })
                          changeTemplates(currentConfig, templateList.save)
                            .then(({ data }) => {
                              if (!!templateList.save) {
                                setTemplateList({
                                  ...templateList,
                                  save: undefined,
                                  pending: false,
                                  list: [
                                    ...templateList.list,
                                    {
                                      label: templateList.save,
                                      value: data.id,
                                      data: currentConfig.data
                                    }
                                  ]
                                })
                                setCurrentConfig({
                                  ...currentConfig,
                                  template: templateList.save
                                })
                              }
                            })
                            .catch(() =>
                              setTemplateList({
                                ...templateList,
                                error: `Ошибка создания`
                              })
                            )
                        } else {
                          setTemplateList({
                            ...templateList,
                            error: `Заполните поле`
                          })
                        }
                      }}
                    >
                      {configModalText.template.add}
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className="LinkText HoverItem TableConfigModalTemplateAdd"
                  onClick={() => setTemplateList({ ...templateList, save: `` })}
                >
                  {configModalText.template.templateAdd}
                </div>
              )}
            </div>
          )}
        >
          {templateList.list.map(item => (
            <Option
              key={`value_${item.value}`}
              value={item.value}
              className="TableConfigModalTemplateListItem"
            >
              <div className="FlexRow">
                <span className="TextLabelText Shark500Color">
                  {item.label}
                </span>
                {item.label !== configModalText.initialTemplate.label && (
                  <DeleteIcon
                    onClick={e => {
                      e.stopPropagation()
                      toggleRemoveTemplateModal({
                        id: item.value,
                        label: item.label,
                        open: true
                      })
                    }}
                  />
                )}
              </div>
            </Option>
          ))}
        </SelectComponent>
      </div>
      <div className="TableConfigModalListWrapper">
        <div className="FlexRow TableConfigModalListTitle">
          <span className="BodyFirstText">{configModalText.list.title}</span>
          <span
            className="LinkText HoverItem"
            onClick={() => {
              const newArr = currentConfig.data.map(item => ({
                ...item,
                visible: isAllHide
              }))
              setCurrentConfig({
                ...currentConfig,
                data: newArr,
                hideCount: isAllHide ? 0 : newArr.length,
                template: null
              })
            }}
          >
            {!isAllHide
              ? configModalText.list.hideAll
              : configModalText.list.showAll}
          </span>
        </div>
        <div className="TableConfigModalList">
          <DragAndDropList
            list={currentConfig.data}
            renderListItem={listItemByContent}
            changeOrder={changeOrder}
          />
        </div>
        <div className="TableConfigModalApply">
          <ButtonPrimary
            disabled={isAllHide}
            onClick={() => changeConfig(currentConfig)}
          >
            {configModalText.apply}
          </ButtonPrimary>
        </div>
        {isRemoveTemplateModalOpen.open && (
          <div>
            <ModalComponent width={424}>
              <div className="SportboomConfigRemoveModal FlexColumn">
                <div className="HeadlineSecondText">
                  {configModalText.removeText.title}
                </div>
                <div>
                  {configModalText.removeText.subtitle(
                    isRemoveTemplateModalOpen.label
                  )}
                </div>
                <div className="FlexRow SportboomConfigRemoveModalButtons">
                  <ButtonDefault
                    onClick={() => toggleRemoveTemplateModal(initialRemove)}
                  >
                    {configModalText.removeText.cancel}
                  </ButtonDefault>
                  <ButtonNotifi
                    color="red"
                    disabled={templateList.pending}
                    onClick={() => {
                      removeTemplates(isRemoveTemplateModalOpen.id).then(() => {
                        const list = templateList.list.filter(
                          listItem =>
                            listItem.value !== isRemoveTemplateModalOpen.id
                        )
                        setTemplateList({
                          pending: false,
                          list
                        })
                        if (
                          currentConfig.template ===
                          isRemoveTemplateModalOpen.label
                        ) {
                          setCurrentConfig({ ...currentConfig, template: null })
                        }
                        toggleRemoveTemplateModal(initialRemove)
                      })
                    }}
                  >
                    <div className="FlexRow">
                      <DeleteIcon className="SportboomConfigRemoveModalButtonsRemove" />
                      <span>{configModalText.removeText.remove}</span>
                    </div>
                  </ButtonNotifi>
                </div>
              </div>
            </ModalComponent>
          </div>
        )}
      </div>
    </div>
  )
}

export default TableConfigModal
