/**
 *
 * Утилита копирования текста в буфер обмена
 */

/**
 * Первая функция fallbackCopyTextToClipboard является поллифилом для старых браузеров, в случае если в объекте navigator нет clipboard
 */

function fallbackCopyTextToClipboard(text: string) {
  // Создаем textarea со значением которое хотим скопировать
  const textArea = document.createElement("textarea")
  textArea.value = text

  //скрываем его что бы не было видно
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  //Добавляем элемент в DOM и выделеяем текст внутри textarea
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  //вызываем функцию execCommand для копирования выделенного текста (но она устаревшая)
  document.execCommand("copy")

  //Удаляем элемент из DOM
  document.body.removeChild(textArea)
}

/**
 *
 * Основная функция копироавния текста в буфер
 */
export function copyTextToClipboard(text: string) {
  return new Promise((resolve, reject) => {
    //В случае если мы в старом браузере и там еще нет clipboard, то мы воспользуемся поллифилом
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return resolve(text)
    }

    // тут просто пользуемся встроенной утилитой для копирования
    return setTimeout(
      () =>
        navigator.clipboard
          .writeText(text)
          .then(resolve)
          .catch(reject),
      0
    )
  })
}
