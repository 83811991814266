import { getFilterStateFromLocalStorage } from "api/helpers"
import { OfficialSmsColumns, OfficialSmsFilter } from "./types"
import { officialSmsConstants } from "./constants"
import { isEmpty } from "lodash"
import { UseRequestBodySort } from "api/hooks"

export const initialFilterStateCreator = (
  current_hall: number
): OfficialSmsFilter => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    officialSmsConstants.OFFICIAL_SMS_FILTER,
    true
  )

  const initFilter = {
    halls: [Number(current_hall)],
    ...initFilterStateFromStorage
  }

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      ...initFilter
    }
  }
}

export const getActiveClasses = (
  fieldName: OfficialSmsColumns,
  sort: UseRequestBodySort
) => (fieldName === sort.field && !!sort.order ? `ActiveSortTableColumn` : ``)
