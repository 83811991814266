import React, { useCallback, useEffect, useMemo, useState } from "react"

import { getDateDescriptionLayout } from "constants/index"
import moment from "moment"
import { useSelector } from "react-redux"
import { getGamesList } from "store/dictionary/selector"
import { isEmpty } from "lodash"
import {
  useRequestBody,
  UseRequestBodyPagination,
  useRequestParams
} from "api/hooks"

import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import TableNew from "Components/TableNew"
import { sortForTable } from "Components/Table"
import { Sorter } from "Components/Table/types"
import DetailComponent from "Components/Detail"
import { ReactComponent as EmptyFilter } from "Components/icons/betsHistory/empty_filter.svg"
import { ReactComponent as NotFoundIcon } from "Components/icons/illustrations/not_found.svg"

import { detailConfigByData, useHistoryBetsRequests } from "./helpers"
import {
  HistoryBetsDataState,
  HistoryBetsDataSummaryState,
  HistoryBetsFilter
} from "./types"
import { CustomFilter, CustomFilterAdditional } from "./CustomFilter"
import {
  columns,
  HISTORY_BETS_FILTER,
  initialFilterStateCreator,
  text
} from "./constants"

const HistoryBets = () => {
  const {
    pagination,
    setPagination,
    setSort,
    sort,
    resetPagination
  } = useRequestBody(20)
  const { currentHallAsNumber } = useRequestParams()
  const gamesHistory = useSelector(getGamesList).filter(
    el => el.is_game_bets_history
  )
  const initialFilterState = useMemo(
    () => initialFilterStateCreator(currentHallAsNumber, gamesHistory),
    [currentHallAsNumber, gamesHistory]
  )

  const initialFilterStateSkipLs = useMemo(
    () => initialFilterStateCreator(currentHallAsNumber, gamesHistory, true),
    [currentHallAsNumber, gamesHistory]
  )

  const setPaginationCb = useCallback(val => {
    setPagination(val)
    setIsInitialRequested(true)
  }, [])

  const setSortCb = useCallback(val => {
    sortForTable(val)
    setIsInitialRequested(true)
  }, [])

  const [filterState, setFilterState] = useState(initialFilterState)
  const [historyBetsData, setHistoryBetsData] = useState<HistoryBetsDataState>({
    isLoading: false,
    total: 0,
    data: []
  })

  const [historyBetsDataSummary, setHistoryBetsDataSummary] = useState<
    HistoryBetsDataSummaryState
  >({
    isLoading: false,
    data: {}
  })

  const setFilterStateCb = useCallback(filter => {
    setFilterState(filter)
  }, [])

  const toggleFilterStateCb = useCallback(
    () => setFilterState({ ...filterState, isOpen: !filterState.isOpen }),
    [filterState]
  )

  const [isInitialRequested, setIsInitialRequested] = useState(false)

  const toggleFilterStateMobileCb = useCallback(
    () =>
      setFilterState({
        ...filterState,
        mobileFilter: !filterState.mobileFilter
      }),
    [filterState]
  )

  const { getHistoryBets, getHistoryBetsSummary } = useHistoryBetsRequests({
    filter: filterState.data,
    sort,
    pagination
  })

  useEffect(() => {
    if (
      (filterState.data.bets_date || filterState.data.game_number) &&
      filterState.isActive &&
      !historyBetsData.isLoading &&
      !historyBetsDataSummary.isLoading
    ) {
      setHistoryBetsDataSummary(prev => ({ ...prev, isLoading: true }))
      setHistoryBetsData(prev => ({ ...prev, isLoading: true }))
      getHistoryBets()
        .then(({ data }) => {
          setHistoryBetsData({
            isLoading: false,
            data: data.items,
            total: data.total_count
          })
        })
        .catch(() => {
          setHistoryBetsData({
            ...historyBetsData,
            isLoading: false
          })
        })
        .finally(() => {
          setFilterState({ ...filterState, isActive: false })
          setHistoryBetsData(prev => ({ ...prev, isLoading: false }))
        })
      getHistoryBetsSummary()
        .then(({ data }) => {
          setHistoryBetsDataSummary({ isLoading: false, data })
        })
        .catch(() => {
          setHistoryBetsDataSummary({
            ...historyBetsDataSummary,
            isLoading: false
          })
        })
        .finally(() => {
          setFilterState({ ...filterState, isActive: false })
          setHistoryBetsDataSummary(prev => ({ ...prev, isLoading: false }))
        })
    }
  }, [filterState])

  useEffect(() => {
    if (isInitialRequested) {
      setHistoryBetsData(prev => ({ ...prev, isLoading: true }))
      getHistoryBets()
        .then(({ data }) => {
          setHistoryBetsData({
            isLoading: false,
            data: data.items,
            total: data.total_count
          })
        })
        .catch(() => {
          setHistoryBetsData({
            ...historyBetsData,
            isLoading: false
          })
        })
        .finally(() => {
          setIsInitialRequested(false)
        })
    }
  }, [isInitialRequested])

  const config = useMemo(
    () => detailConfigByData(historyBetsDataSummary.data),
    [historyBetsDataSummary.data]
  )

  const showEmpty =
    (filterState.data.bets_date || filterState.data.game_number) &&
    !historyBetsData.isLoading &&
    historyBetsData.data.length === 0

  const showEmptyFilters =
    !filterState.data.bets_date && !filterState.data.game_number

  const showReport =
    (filterState.data.bets_date || filterState.data.game_number) && !showEmpty

  return (
    <ReportWithFilter
      filterComponent={
        <SideFilter
          filterName={HISTORY_BETS_FILTER}
          filterState={filterState}
          setFilterState={setFilterStateCb}
          resetPagination={resetPagination}
          filters={[
            Filters.addCustomItem,
            Filters.searchInput,
            Filters.customItem
          ]}
          getDisabled={filter => {
            if (
              filter.game_ids?.length &&
              (!!filter.game_number || !!filter.bets_date)
            ) {
              return false
            }
            return true
          }}
          CustomComponent={CustomFilter}
          AdditionalCustomComponent={CustomFilterAdditional}
          filterText={{
            searchInputFilter: {
              header: text.filter.search,
              placeholder: text.filter.searchPlaceholder
            }
          }}
          initialForClear={initialFilterStateSkipLs.data}
          additionClearDeps={filter =>
            !isEmpty(filter.bet_sum) ||
            !!filter.bets_date ||
            !!filter.game_number
          }
          isLoading={
            historyBetsDataSummary.isLoading || historyBetsData.isLoading
          }
          customFilterKeys={{ [Filters.searchInput]: "game_number" }}
          filterConfig={{
            searchNumber: true,
            searchFilterValidator: val => val.length <= 12
          }}
        />
      }
      pageContent={
        <div>
          <Title
            titleText={text.title}
            tooltipText={text.tooltip}
            bottomAdditionalCmp={
              <>
                {filterState.data?.bets_date &&
                  getDateDescriptionLayout({
                    dates: [
                      moment(filterState.data.bets_date),
                      moment(filterState.data.bets_date)
                    ],
                    show: !!filterState.data?.bets_date,
                    isShift: false,
                    shift: [
                      filterState.data.shift || 8,
                      filterState.data.shift || 8
                    ],
                    hidePeriodTitle: true,
                    calendarIcon: true,
                    className: "HistoryBetsPeriod"
                  })}
              </>
            }
          />
          {showEmpty && (
            <div className="RaceErrContainer">
              <NotFoundIcon />
              <div className="RaceErrContainerTextMain">
                {text.notFound.title}
              </div>
              <div className="SubtitleSecondText">{text.notFound.subtitle}</div>
            </div>
          )}
          {showEmptyFilters && (
            <div className="HistoryErrContainer">
              <div className="HistoryErrSubContainer">
                <div className="RaceErrContainerTextMain">
                  {text.emptyFilters.title}
                </div>
                <EmptyFilter />
                <div className="SubtitleSecondText">
                  {text.emptyFilters.subtitle}
                </div>
              </div>
            </div>
          )}
          {showReport && (
            <div className="HistoryBetsDetailContentContainer">
              <DetailComponent
                detailConfig={config}
                pending={historyBetsDataSummary.isLoading}
              />
              <TableNew
                columns={columns(gamesHistory)}
                data={historyBetsData.data}
                pagination={{
                  value: pagination,
                  onChange: setPaginationCb,
                  total: historyBetsData.total
                }}
                onChange={(
                  pagination: UseRequestBodyPagination,
                  filter: HistoryBetsFilter,
                  sorter: Sorter
                ) => setSortCb({ sorter, setSort })}
                loading={historyBetsData.isLoading}
              />
            </div>
          )}
        </div>
      }
      isOpen={filterState.isOpen}
      toggleFilter={toggleFilterStateCb}
      mobileFilter={filterState.mobileFilter}
      toggleMobileFilter={toggleFilterStateMobileCb}
    />
  )
}

export default HistoryBets
