import React, { useEffect, useMemo, useRef } from "react"

import { isMobile } from "constants/index"

import FileComponent from "Components/File"
import { UltimatePending } from "Components/Spin"
import { EmptyData } from "Components/EmptyTable"

import { ReportHallData } from "./types"
import { downloadFileHallPdf, reportText } from "./constants"

const ReportHallPDF = ({ file, pending }: ReportHallData) => {
  const refIframe: any = useRef(null)

  useEffect(() => {
    if (!!file && !isMobile) {
      refIframe.current.setAttribute("src", file?.url)
    }
  }, [file?.url])

  const correctUrl = useMemo(
    () => file?.url?.replace("bingo.zone", "betboom.ru") || "",
    [file?.url]
  )

  return (
    <div className="FlexColumn Gap16 ReportHallPDFWrapper">
      {pending && (
        <div className="GridCenter PaddingTop32">
          <UltimatePending />
        </div>
      )}
      {isMobile && !!file && !pending && (
        <div className="ReportHallPDFMobile MarginLeft16">
          <FileComponent
            name={file.name}
            link={correctUrl}
            size={Number(file.size)}
            view={true}
            downloadFile={() => downloadFileHallPdf(correctUrl, file.name)}
          />
        </div>
      )}
      {!!file &&
        !pending &&
        (isMobile ? (
          <div className="PaddingLeft16">
            <a
              href={file.url}
              target="_blank"
              rel="noreferrer"
              className="LinkText"
            >
              {reportText.mobileLinkText}
            </a>
          </div>
        ) : (
          <embed
            ref={refIframe}
            title={file.name}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        ))}
      {!pending && !file && (
        <EmptyData
          title={reportText.emptyData.title}
          subtitle={reportText.emptyData.subtitle}
        />
      )}
    </div>
  )
}

export default ReportHallPDF
