import React from "react"
import {
  allowedTypes,
  FormItemNames,
  formRules,
  proposalModalText
} from "./helper"
import {
  actionAllowed,
  formatZendeskId,
  ProposalNullishZendeskId,
  StatusLabel
} from "../helper"
import DateTime from "Components/DateTime"
import { Form, Upload } from "antd"
import InputComponent, { InputTextAreaComponent } from "Components/Input"
import { UploadAttachment, UploadAttachments } from "../types"
import FileComponent from "Components/File"
import { ButtonIcon, ButtonNotifi, ButtonPrimary } from "Components/Button"
import { ReactComponent as CancelIcon } from "Components/icons/cancel.svg"
import { ReactComponent as ReloadIcon } from "Components/icons/reload.svg"
import { ReactComponent as AttachIcon } from "Components/icons/attach.svg"
import { ReactComponent as SuccessIcon } from "Components/icons/success_large.svg"
import { ProposalModalContentProps } from "./types"

export const ProposalModalContent: React.FC<ProposalModalContentProps> = ({
  viewMode,
  passedModalData,
  statuses,
  form,
  onFinish,
  onFieldsChange,
  modalData,
  setModalData,
  setCancelId,
  loading,
  duplicate,
  successModal
}) => {
  return (
    <>
      {successModal ? (
        <div className="SupportProposalSuccess">
          <div className="SupportProposalSuccessText">
            {proposalModalText.success}
          </div>
          <SuccessIcon className="SupportProposalSuccessTextIcon" />
        </div>
      ) : (
        <>
          <div className="ProposalModalTitleWrapper">
            {viewMode ? (
              <div className="ProposalModalTitleViewMode">
                <div className="ProposalModalTitleAndStatus">
                  <div className="ProposalModalTextTitleText">
                    <div className="ProposalModalTextTitleMain">
                      {proposalModalText.currentTitle}
                    </div>
                    {passedModalData?.zendeskId ? (
                      formatZendeskId(passedModalData?.zendeskId)
                    ) : (
                      <ProposalNullishZendeskId />
                    )}
                  </div>
                  {statuses && passedModalData && (
                    <StatusLabel
                      statuses={statuses}
                      id={passedModalData?.status}
                    />
                  )}
                </div>
                <div className="ProposalMobileCardTitleDate">
                  <DateTime date={passedModalData?.date} />
                </div>
              </div>
            ) : (
              <div className="ProposalModalTextTitleText">
                {proposalModalText.newTitle}
              </div>
            )}
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            autoComplete="off"
            className={viewMode ? "ProposalFormViewMode" : ""}
          >
            <div className={"ProposalModalContentWrapper"}>
              <Form.Item
                name={FormItemNames.username}
                label={
                  <div className="ProposalModalSubTitleWrapper">
                    {proposalModalText.username}
                  </div>
                }
                rules={formRules.input}
              >
                {viewMode ? (
                  <div>{passedModalData?.details.username}</div>
                ) : (
                  <InputComponent
                    className="ProposalModalNameInput"
                    placeholder={proposalModalText.usernameInputPlaceholder}
                    greyBackground
                  />
                )}
              </Form.Item>
              <Form.Item
                name={FormItemNames.phone}
                label={
                  <div className="ProposalModalSubTitleWrapper">
                    {proposalModalText.phone}
                  </div>
                }
                rules={formRules.phoneInput}
              >
                {viewMode ? (
                  <div>{passedModalData?.details.phone}</div>
                ) : (
                  <InputComponent
                    allowClear
                    className="ProposalModalPhoneInput"
                    placeholder={"+7"}
                    type={"number"}
                    greyBackground
                    style={{ backgroundColor: "#FFFFFF" }}
                  />
                )}
              </Form.Item>
              <Form.Item
                name={FormItemNames.text}
                label={
                  <div className="ProposalModalSubTitleWrapper">
                    {proposalModalText.text}
                  </div>
                }
                rules={formRules.textarea}
              >
                {viewMode ? (
                  <div>{passedModalData?.text}</div>
                ) : (
                  <InputTextAreaComponent
                    className="ProposalModalTextTextArea"
                    placeholder={proposalModalText.textInputPlaceholder}
                    autoSize={{ minRows: 10, maxRows: 10 }}
                    greyBackground
                  />
                )}
              </Form.Item>
              {viewMode && !!passedModalData?.details.supportMessages.length && (
                <div className="ProposalModalSupportCard">
                  <div className="ProposalModalSubTitleWrapper">
                    {proposalModalText.supportResponseHeader}
                  </div>
                  {passedModalData?.details.supportMessages.map(
                    (message, i) => (
                      <div
                        key={i}
                        className="ProposalModalSubTitleWrapperSupportMessage"
                      >
                        {message}
                      </div>
                    )
                  )}
                </div>
              )}
              {!!modalData.attachments.length && (
                <div>
                  <div className="ProposalModalSubTitleWrapper">
                    {proposalModalText.attachments}
                  </div>
                  <div className="ProposalModalFilesWrapper">
                    {(modalData?.attachments).map((file: UploadAttachment) => (
                      <FileComponent
                        key={file.size + file.name}
                        name={file.name}
                        size={file.size}
                        link={file.link}
                        view={viewMode}
                        deleteFile={() => {
                          setModalData(prev => {
                            const next = prev.attachments.filter(
                              att => att.name !== file.name
                            ) as UploadAttachments
                            return { ...prev, attachments: next }
                          })
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div
              className={`ProposalModalButtonsGroup ${
                !!modalData.attachments.length || viewMode
                  ? "ProposalModalButtonsGroupTopLine"
                  : ""
              } `}
            >
              {viewMode ? (
                <>
                  {actionAllowed(passedModalData?.status) && (
                    <ButtonIcon
                      className="ButtonIcon_basic ProposalModalButtonCancel"
                      onClick={() => {
                        if (passedModalData) {
                          setCancelId(passedModalData.id)
                        }
                      }}
                      icon={<CancelIcon />}
                    >
                      {proposalModalText.buttonCancel}
                    </ButtonIcon>
                  )}
                  <ButtonIcon
                    className="ButtonIcon_basic ProposalModalButtonDuplicate"
                    onClick={() => {
                      if (passedModalData) {
                        duplicate(passedModalData)
                      }
                    }}
                    icon={<ReloadIcon />}
                  >
                    {proposalModalText.buttonDuplicate}
                  </ButtonIcon>
                </>
              ) : (
                <>
                  <Form.Item>
                    <ButtonPrimary loading={loading} htmlType="submit">
                      {proposalModalText.buttonSend}
                    </ButtonPrimary>
                  </Form.Item>
                  <Form.Item name={FormItemNames.file}>
                    <Upload
                      customRequest={() => null}
                      itemRender={() => null}
                      accept={allowedTypes}
                      multiple
                    >
                      <ButtonNotifi className="ProposalModalAttachmentButton">
                        <AttachIcon className="ProposalModalAttachmentIcon" />
                      </ButtonNotifi>
                    </Upload>
                  </Form.Item>
                </>
              )}
            </div>
          </Form>
        </>
      )}
    </>
  )
}
