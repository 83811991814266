import React, { useCallback, useEffect, useState } from "react"
import { CheckBoxComponent } from "Components/CheckBox"
import { uniq } from "lodash"
import { StatusesCheckBoxFilterProps } from "./types"
import {
  defaultStatusesCheckBoxFilterText,
  getAllStatusesIds,
  selectAllStatus
} from "./helpers"
import { StatusItem } from "../../types"

export const StatusesCheckBoxFilter: React.FC<StatusesCheckBoxFilterProps> = ({
  statuses,
  statusesDict,
  text,
  onChange
}) => {
  const [selectAllStatuses, setSelectAllStatuses] = useState(false)

  useEffect(() => {
    setSelectAllStatuses(statusesDict?.length === statuses?.length)
  }, [statusesDict, statuses])

  const onSelectAllChanged = useCallback(
    ({ target }) => {
      if (target.checked) {
        setSelectAllStatuses(true)
        onChange(getAllStatusesIds(statusesDict))
      } else {
        onChange([])
        setSelectAllStatuses(false)
      }
    },
    [statusesDict]
  )

  const onChanged = useCallback(
    ({ target }) => {
      if (target.checked) {
        onChange(uniq([...statuses, target.value]))
      } else {
        onChange(statuses.filter((status: number) => status !== target.value))
      }
    },
    [statuses]
  )

  return (
    <>
      <div className="FlexRow">
        <div className="SideFilterContentLabel">
          {text?.header || defaultStatusesCheckBoxFilterText.header}
        </div>
        <div className="SideFilterStatusesCounter">{statuses?.length}</div>
      </div>
      <div className="SideFilterCheckBoxWrapper">
        <div className="SideFilterCheckBoxGroupWrapper">
          <div className="CheckboxWrapper">
            <CheckBoxComponent
              key={`hall_value_${selectAllStatus.id}`}
              label={selectAllStatus.name}
              value={selectAllStatus.id}
              checked={selectAllStatuses}
              onChange={onSelectAllChanged}
            />
          </div>

          {statusesDict.map((item: StatusItem) => (
            <div key={item.id} className="CheckboxWrapper">
              <CheckBoxComponent
                key={`hall_value_${item.id}`}
                label={item.name}
                value={item.id}
                checked={statuses?.includes(item.id)}
                onChange={onChanged}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
