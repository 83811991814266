import moment, { Moment } from "moment"

const rangeRestrictions = (current: Moment) =>
  moment(current).valueOf() <
    moment()
      .subtract(2, `month`)
      .subtract(1, `day`)
      .valueOf() || moment(current).valueOf() > moment().valueOf()

export default rangeRestrictions
