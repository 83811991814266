import React, { useCallback, useMemo, useState } from "react"

import { useRequestParams } from "api/hooks"

import { getDateDescriptionLayout } from "constants/index"

import { noop } from "lodash"

import ReportWithFilter from "Components/ReportWithFilter"
import { SideFilter } from "Components/SideFilter/SideFilter"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import NotActiveFilter from "Components/NotActiveFilter"

import {
  getInitialFiltersValue,
  sportboomMainText,
  SPORT_BOOM_FILTERS,
  initialValuesWithHalls,
  isSpecialRoles
} from "./constants"
import { SportBoomFilterState } from "./types"

import { ReactComponent as NotActiveIcon } from "./img/not_active.svg"

import SportBoomContent from "./Content"
import { CustomFilterBetNumber, CustomFilterWalletNumber } from "./CustomFilter"

const SportBoomReport = () => {
  const { current_hall } = useRequestParams()
  const initialFilterValues = useMemo(
    () => initialValuesWithHalls(`${current_hall}`),
    []
  )
  const initialFilter = useMemo(
    () => getInitialFiltersValue(`${current_hall}`),
    []
  )
  const [filterState, setFilterState] = useState<SportBoomFilterState>(
    initialFilter
  )
  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  const titleDescription = useMemo(() => {
    if (!!filterState.data.dates) {
      return getDateDescriptionLayout({
        dates: filterState.data.dates,
        shift: filterState.data.shift,
        isShift: filterState.data.isShift,
        show: filterState.isActive
      })
    }
    return ``
  }, [filterState.data.dates, filterState.data.shift])

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={SPORT_BOOM_FILTERS}
            filterState={filterState}
            initialForClear={initialFilterValues}
            setFilterState={setFilterStateCb}
            resetPagination={noop}
            filters={[
              Filters.InfoPanel,
              Filters.customItem,
              Filters.InfoPanelHalls
            ]}
            additionClearDeps={filter =>
              !!filter.bet_number || !!filter.wallet_number
            }
            getDisabled={filter =>
              !filter?.dates && !filter.bet_number && !filter.wallet_number
            }
            infoPanelConfig={{
              alwaysMoscow: false,
              clearRange: true,
              disabledShift: false,
              withoutPeriod: true,
              withoutGmt: false,
              gmtSelect: isSpecialRoles()
            }}
            CustomComponent={CustomFilterWalletNumber}
            CustomInfoPanelHalls={CustomFilterBetNumber}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <>
            {filterState.isActive && (
              <div>
                <Title
                  titleText={sportboomMainText.title}
                  tooltipText={sportboomMainText.tooltip}
                  bottomAdditionalCmp={
                    !!filterState.data.dates ? (
                      <div>{titleDescription}</div>
                    ) : (
                      <div />
                    )
                  }
                  wrapperClass="SportboomMainTitle"
                />
                <SportBoomContent
                  filter={{
                    ...filterState.data,
                    bet_number: !!filterState.data.bet_number
                      ? Number(filterState.data.bet_number)
                      : undefined
                  }}
                />
              </div>
            )}
            {!filterState.isActive && (
              <NotActiveFilter
                title={sportboomMainText.notActiveFilter.title}
                description={sportboomMainText.notActiveFilter.description}
                icon={<NotActiveIcon />}
              />
            )}
          </>
        }
        modalCmp={<div />}
      />
    </>
  )
}

export default SportBoomReport
