import React, { useEffect, useState } from "react"

import moment, { Moment } from "moment"

import { useRequest, useRequestParams } from "api/hooks"

import { SwitchFilterComponent } from "Components/Switch"
import { InputMaskComponent } from "Components/Input"
import { defaultFilterText } from "Components/SideFilter/helpers"
import { Filters } from "Components/SideFilter/types"
import RangePickerFilter from "Components/SideFilter/Components/RangePickerFilter"

import { customFilterText, initialActiveDates } from "./constant"

import { GuestHistoryFilterProps, GuestsHistoryActiveDates } from "../types"

export const CustomCashboxComponent = ({
  filter,
  setFilter
}: GuestHistoryFilterProps) => {
  return (
    <div>
      <SwitchFilterComponent
        label={customFilterText.onlyActiveDays}
        checked={filter.onlyActiveDays}
        onChange={() =>
          setFilter({ ...filter, onlyActiveDays: !filter.onlyActiveDays })
        }
      />
    </div>
  )
}

export const CustomRangePickerWithPhone = ({
  filter,
  setFilter
}: GuestHistoryFilterProps) => {
  return (
    <div>
      <SwitchFilterComponent
        label={customFilterText.onlyActiveDays}
        checked={filter.onlyActiveDays}
        onChange={() =>
          setFilter({ ...filter, onlyActiveDays: !filter.onlyActiveDays })
        }
      />
    </div>
  )
}

export const CustomBetsComponent = ({
  filter,
  setFilter
}: GuestHistoryFilterProps) => {
  return (
    <div>
      <SwitchFilterComponent
        label={customFilterText.includeFreebets}
        checked={filter.includeFreebets}
        onChange={() =>
          setFilter({ ...filter, includeFreebets: !filter.includeFreebets })
        }
      />
    </div>
  )
}

export const AdditionalCustomFilter = ({
  filter,
  setFilter
}: GuestHistoryFilterProps) => {
  const [activeDates, setActiveDates] = useState<GuestsHistoryActiveDates>(
    initialActiveDates
  )

  const { currentHallAsNumber } = useRequestParams()
  const { request: getActiveDates } = useRequest({
    url: `v1/guests/history/activity`,
    requestBody: { phone: filter.phone, hall: currentHallAsNumber }
  })
  useEffect(() => {
    if (filter.phone?.length == 11) {
      setActiveDates({ ...activeDates, pending: true })
      getActiveDates()
        .then(({ data }) =>
          setActiveDates({ dates: data.bet_dates, pending: false })
        )
        .catch(() => setActiveDates({ ...activeDates, pending: false }))
    }
  }, [filter.phone])

  return (
    <div className="FlexColumn">
      <div className="SideFilterField">
        <div className="SideFilterContentLabel">
          {defaultFilterText.labels[Filters.phoneInput]}
        </div>
        <div className="InputWrapper ProposalModalPhoneInput">
          <InputMaskComponent
            mask={"+7 000 000-00-00"}
            value={filter.phone}
            allowClear={true}
            onChange={x => {
              setFilter({ ...filter, phone: `7${x.unmaskedValue}` })
            }}
          />
        </div>
      </div>
      <div className="GuestsHistoryCustomRangePicker">
        <RangePickerFilter
          dateRender={(currentDate: Moment) => {
            const day = moment(currentDate).format(`D`)
            const isActiveDate = activeDates.dates.includes(
              moment(currentDate).format(`YYYY-MM-DDT00:00:00+00:00`)
            )
            return (
              <div className="GuestsHistoryActiveDay ant-picker-cell-inner">
                <div className="GuestsHistoryActiveDayValue">{day}</div>
                {isActiveDate && (
                  <div className="GuestsHistoryActiveDayLabel" />
                )}
              </div>
            )
          }}
          disabled={activeDates.pending}
          allowClear={true}
          dates={filter.dates}
          onChange={value => setFilter({ ...filter, dates: value })}
        />
      </div>
    </div>
  )
}
