import React from "react"
import { SelectComponent } from "../../Select"
import { SelectSearchProps } from "../types"

const SelectSearch = ({
  value,
  selectOptions,
  toggleShowSearch,
  title,
  setSearch,
  ...rest
}: SelectSearchProps) => (
  <SelectComponent
    autoFocus
    value={value}
    greyBackground
    allowClear={true}
    options={selectOptions}
    onBlur={() => {
      toggleShowSearch(false)
    }}
    className="TableSearchSelect"
    placeholder={title}
    onChange={value => {
      setSearch(value)
      toggleShowSearch(false)
    }}
    getPopupContainer={() => document.body}
    {...rest}
  />
)

export default SelectSearch
