import React from "react"
import moment from "moment"
import { Step3FieldProps } from "Containers/Sms/types"
import DatePickerComponent from "Components/DatePicker"
import { smsDateRestrictions } from "Containers/Sms/helper"
import { smsConstants } from "Containers/Sms/constants"

const Date = ({ smsState, setSmsState, ...rest }: Step3FieldProps) => {
  return (
    <div className="CreateSms_input CreateSms_inputShort CreateSms_inputShort_single">
      <label className="CreateSms_inputLabel">
        {smsConstants.step3.dateTitle}
      </label>
      <div className="CreateSms_inputInner">
        <DatePickerComponent
          checkOffset
          value={
            !!smsState.extraParams.date
              ? moment(smsState.extraParams.date)
              : undefined
          }
          onChange={value =>
            setSmsState({
              ...smsState,
              extraParams: {
                ...smsState.extraParams,
                date: moment(value).valueOf()
              }
            })
          }
          disabledDate={smsDateRestrictions}
          greyBackground
          placeholder={smsConstants.step3.datePlaceholder}
          {...rest}
        />
      </div>
    </div>
  )
}

export default Date
