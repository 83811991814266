import React, { useState } from "react"

import { SwitchFilterComponent } from "Components/Switch"

import { FilterProps } from "./index"
import { customFilterText } from "./constants"
import RangeComponent from "./RangeComponent"

import { FilterRangeFields } from "../types"
import { initialFilterTransferBalance } from "../constants"

const TransferFilter = ({ filter, setFilter }: FilterProps) => {
  const [showFilter, toggleFilter] = useState(false)
  return (
    <div>
      <SwitchFilterComponent
        checked={showFilter}
        onChange={() => {
          const initial = initialFilterTransferBalance(!showFilter)
          setFilter({ ...filter, ...initial })
          toggleFilter(!showFilter)
        }}
        label={customFilterText.label.transferBalance}
      />
      {showFilter && (
        <div className="FlexColumn Gap12">
          <RangeComponent
            label={customFilterText.fieldLabels[FilterRangeFields.transfer_to]}
            field={FilterRangeFields.transfer_to}
            filter={filter}
            setFilter={setFilter}
          />
          <RangeComponent
            label={
              customFilterText.fieldLabels[FilterRangeFields.transfer_from]
            }
            field={FilterRangeFields.transfer_from}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      )}
    </div>
  )
}

export default TransferFilter
