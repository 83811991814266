import { isMobile } from "constants/index"

export const mailingTabsTitleText = {
  add: `Добавить рассылку`,
  title: isMobile
    ? `Рассылка \nо пополнениях и выплатах`
    : `Рассылка о пополнениях и выплатах`,
  tooltip: `Раздел предназначен для оперативного оповещения о пополнении и снятии денежных средств с балансов гостей, а также о выигрышах. При создании рассылки вы можете указать тип операции (пополнение, вывод или выигрыш) и суммы, при которых будет происходить оповещение. По типам операций "пополнение" или "вывод" оповещение поступит при однократном или суммарном достижении гостем указанного при настройках значения в выбранный временной диапазон. В список рассылок можно добавить до пяти, отличающихся по номеру телефона или email, адресатов. Время отображается по мск.`,
  tabs: {
    hall: `Участники рассылок`,
    history: `История рассылок`
  }
}
