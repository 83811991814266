import axios from "axios"

import { ILoginData } from "./index"
import { IUSERState } from "store/auth/types"
import { IDevelopState } from "store/developer/types"

export const makeLogout = ({ logout }: { logout: () => void }) => {
  const userFromStorage: string = localStorage.getItem(`user`) || ``
  const user: { token: string } | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage
  axios({
    url: `${process.env.REACT_APP_AUTH_URL}/logout`,
    method: `post`,
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Credentials": true,
      token: user?.token
    }
  })
    .then(() => {
      logout()
      window.location.href = `/auth/login`
    })
    .catch(() => {
      logout()
      window.location.href = `/auth/login`
    })
}

const makeLogin = ({
  loginData,
  login,
  setLoginData,
  developer,
  addDeveloperLink
}: {
  loginData: ILoginData
  login: (data: IUSERState) => void
  setLoginData: (loginDataState: ILoginData) => ILoginData | void
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
}) => {
  const { password, username } = loginData
  setLoginData({ ...loginData, pending: true })
  axios({
    url: `${process.env.REACT_APP_AUTH_URL}/login`,
    method: `post`,
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Credentials": true
    },
    data: { username, password }
  })
    .then(response => {
      const { data, headers } = response
      if (data.cookie) {
        const domain = `.betboom.ru`
        document.cookie = `bbp-cookie=${data.cookie}; path=/; domain=${domain}`
      }
      const responseData: IUSERState = {
        fullName: data.fullName || ``,
        role: data.role || ``,
        token: data.token || ``,
        isAuth: data.isAuth || false,
        halls: data.halls || [],
        hallsAddress: data.hallsAddress || {},
        isAdmin: data.role === `ROLE_TRAINING` || data.role === `ROLE_SUPPORT`,
        canaryReleases: data.canaryReleases,
        userId: data.id
      }

      setLoginData({ ...loginData, pending: false, errors: false })
      localStorage.removeItem("user")
      localStorage.setItem(`user`, JSON.stringify(responseData))
      localStorage.setItem(`role`, data.role)
      localStorage.setItem(`hall`, `[]`)
      localStorage.setItem(`halls`, JSON.stringify(data.halls))
      localStorage.setItem(`hallsAddress`, JSON.stringify(data.hallsAddress))
      if (data.role === `ROLE_DEVELOPER`) {
        localStorage.setItem(
          `developer`,
          JSON.stringify({
            devLinks: [],
            isDeveloper: data.role === `ROLE_DEVELOPER`,
            iconNumber: Math.floor(Math.random() * 5)
          })
        )
      }
      if (headers[`x-debug-token-link`]) {
        addDeveloperLink({
          ...developer,
          [`login`]: { url: `login`, link: headers[`x-debug-token-link`] }
        })
      }
      login({ ...responseData, isAuth: true })
    })
    .catch(({ response }) => {
      localStorage.removeItem(`user`)
      localStorage.removeItem(`hall`)
      localStorage.removeItem(`halls`)
      localStorage.removeItem(`developer`)
      localStorage.removeItem(`hallsAddress`)
      setLoginData({
        ...loginData,
        pending: false,
        errors: response ? response.data.description : ``
      })
    })
}

export default makeLogin
