import { CheckboxValueType } from "antd/lib/checkbox/Group"

import { valueToCurrency } from "constants/index"

import { SuperWinTableDataProps } from "./types"

export const superwinText = {
  item: {
    title: `Супервыигрыши`,
    getHall: (hall: CheckboxValueType) => `Клуб ${hall}`,
    roleAccessArr: [
      `PARTNER`,
      `PARTNER-OPERATOR`,
      `PARTNER-ADMINISTRATOR`,
      `COMPANY-DEVELOPER`,
      `COMPANY-SUPPORT`,
      `COMPANY-MANAGER`,
      `PARTNER-PRUDNIKOV-GROUP`,
      "PARTNER-1314",
      "ADMIN",
      "PARTNER-EKT2"
    ],
    increaseButton: `Увеличить`,
    showVipLabel: `VIP зона`,
    games: {
      tennisOne: `Теннис 1`,
      tennisTwo: `Теннис 2`,
      tennisFightOne: `Теннисный \nбой 1`,
      tennisFightTwo: `Теннисный \nбой 2`
    },
    table: {
      main: `Общая зона`,
      vip: `VIP зона`,
      rowOne: `Лицевой`,
      rowTwo: `Фоновый`
    }
  }
}

export const getSuperwinTableValue = (data: SuperWinTableDataProps) => {
  const sum_per = valueToCurrency({
    value: data.sum_per,
    withoutFixed: true,
    withoutZero: true
  })
  const sum_back = valueToCurrency({
    value: data.sum_back,
    withoutFixed: true,
    withoutZero: true
  })

  return {
    nominal: `${data.price} ₽`,
    sum_per: `${sum_per} ₽`,
    sum_back: `${sum_back} ₽`
  }
}

export const superwinTableText = {
  nominal: `Номинал квитанций`,
  face: `Лицевой`,
  back: `Фоновый`
}
