import React from "react"
import { valueToCurrency } from "constants/index"

import moment from "moment"

import {
  rowType,
  SuperwinItem,
  SuperwinItemData,
  SuperwinLimits
} from "../types"

import { SuccessModalState } from "./types"
import _ from "lodash"

// CRUD

export const increaseModalText = (data: SuperwinItemData, is_vip: boolean) => {
  const canAdd = valueToCurrency({
    value: Number(data.remaining_daily_limit),
    withoutFixed: true,
    withoutZero: true
  })
  const daySumLimit = valueToCurrency({
    value: Number(data.day_sum_limit),
    withoutFixed: true,
    withoutZero: true
  })
  const maxSum = valueToCurrency({
    value: Number(data.max_sum_limit),
    withoutFixed: true,
    withoutZero: true
  })
  const nominal = valueToCurrency({
    value: Number(data.nominal),
    withoutFixed: true,
    withoutZero: true
  })
  const currentSum = valueToCurrency({
    value: Number(data.current_sum),
    withoutFixed: true,
    withoutZero: true
  })

  const lastChange = moment(
    data.last_change_at,
    `YYYY-MM-DDTHH:mm:ss+00:00`
  ).format(`DD.MM.YYYY в HH:mm`)

  const nextChangeMoment = moment(
    data.next_change_at,
    `YYYY-MM-DDTHH:mm:ss+00:00`
  )
  const needWait = nextChangeMoment.valueOf() > moment().valueOf()
  const nextChange = nextChangeMoment.format(`DD.MM.YYYY в HH:mm`)
  const status = {
    allow: data.status === SuperwinLimits.Allow,
    maxSum: data.status === SuperwinLimits.MaxSumLimit,
    daySum: data.status === SuperwinLimits.DaySumLimit
  }
  // const increaseRestriction = valueToCurrency({ value: data.})
  return {
    title: `Увеличить супервыигрыш`,
    alert: `Внимание!`,
    increaseRestriction: (
      <div className="TextDefault">
        Увеличение супервыигрыша ограничено суммой{" "}
        <b>{daySumLimit} ₽ в сутки!</b>
      </div>
    ),
    limit: (
      <div className="TextDefault">
        Значение супервыигрыша, до которого возможно ручное увеличение:{" "}
        <b>{maxSum} ₽.</b>
      </div>
    ),
    canAdd: (
      <div className="TextDefault">
        Вы можете добавить не более <b>{canAdd} ₽.</b>
      </div>
    ),
    canAddValue: Number(data.remaining_daily_limit),
    area: `Зона клуба`,
    areaValue: is_vip ? `VIP` : "Общая",
    nominal: `Номинал`,
    nominalValue: `${nominal} ₽`,
    current: `Текущий лицевой супервыигрыш`,
    currentValue: `${currentSum} ₽`,
    increaseLabel: `Увеличить супервыигрыш на`,
    apply: status.maxSum || status.daySum ? `Понятно` : `Применить`,
    limitAlert: `Достигнут лимит ручного увеличения супервыигрыша`,
    dateAlert: `Увеличение было произведено ${lastChange}. Возможность дальнейшего увеличения значения супервыигрыша будет доступно с ${nextChange}.`,
    needWait,
    ...status
  }
}

// Success

export const successText = (data: SuccessModalState) => ({
  title: `Супервыигрыш увеличен!`,
  description: `Вы увеличели супервыигрыш номиналом ${data?.nominal} ₽ на ${data?.sum} ₽`,
  close: `Хорошо`
})

// table const

export const getColumnItemsText = (data?: SuperwinItemData) => {
  if (data) {
    const value = valueToCurrency({
      value: data.current_sum
    })
    return {
      nominal: `${data.nominal} ₽`,
      value: `${value} ₽`
    }
  }
  return {
    nominal: "",
    value: ""
  }
}
export const getTableText = (hall: number) => ({
  hall: `Клуб ${hall}`,
  [rowType.MAIN]: `Общая зона`,
  [rowType.VIP]: `VIP-зона`
})

export const mergeArrays = (dataItem: SuperwinItem) => {
  const main = dataItem[rowType.MAIN]
  const vip = dataItem[rowType.VIP]
  const resArr = [...main]
  if (!!vip) {
    resArr.push(...vip)
  }
  return _.uniqBy(resArr, "nominal").sort((a, b) => a.nominal - b.nominal)
}
