import React, { useMemo } from "react"

import TableComponent from "Components/Table"

import { FreebetsData } from "../../types"
import { textByData, columns } from "./constants"

const FreebetsDetail = ({
  data = [],
  sum
}: {
  data: FreebetsData[]
  sum: string
}) => {
  const count = data.length

  const { title, description } = useMemo(() => textByData({ sum, count }), [])
  return (
    <div className="FreebetsDetailWrapper">
      <div className="FreebetsDetailTitle FlexColumn">
        <span className="HeadlineSecondText">{title}</span>
        <span className="TextDefault">{description}</span>
      </div>
      <div className="FreebetsDetailTable">
        <TableComponent
          columns={columns}
          data={data}
          scroll={{ y: `calc(100vh - 210px)` }}
          tableClassName="OrangeHeaderTable"
        />
      </div>
    </div>
  )
}

export default FreebetsDetail
