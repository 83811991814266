import { Reducer, AnyAction } from "redux"

import { IUSERState } from "./types"
import { LOGIN, LOGOUT } from "./constants"
const init: IUSERState = {
  fullName: null,
  token: null,
  role: null,
  isAuth: !!localStorage.getItem(`user`) || false,
  halls: [],
  isAdmin: false,
  canaryReleases: []
}

export const authReducer: Reducer<IUSERState, AnyAction> = (
  state: IUSERState = init,
  action
): IUSERState => {
  const { type, payload } = action
  switch (type) {
    case LOGIN: {
      return { ...state, ...payload }
    }

    case LOGOUT: {
      localStorage.removeItem(`error`)
      localStorage.removeItem(`role`)
      localStorage.removeItem(`menu`)
      localStorage.setItem(`developer`, ``)
      localStorage.setItem(`user`, ``)
      localStorage.clear()
      document.cookie =
        "bbp-cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
      return { ...init, isAuth: false }
    }

    default:
      return state
  }
}
