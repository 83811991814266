import React, { useEffect, useMemo, useState } from "react"

import { useRequest, useRequestBody, useRequestParams } from "api/hooks"
import { parseOneDateWithoutGmtToBack } from "constants/index"

import ExcelButton from "Components/ExcelButton"

import NewTableComponent from "Components/TableNew"
import { EmptyData } from "Components/EmptyTable"

import { GuestsHistoryFilterData } from "Containers/GuestsHistory/types"

import { getConstants, initialData, getColumns } from "./constants"
import { BetsState } from "./types"

const BetsTable = ({ filter }: { filter: GuestsHistoryFilterData }) => {
  const [betsTableData, setBetTableData] = useState<BetsState>({
    pending: false,
    data: initialData
  })

  const { pagination, setPagination } = useRequestBody()
  const { requestCurrentHall } = useRequestParams()

  const filterForRequest = {
    hall: requestCurrentHall,
    ...filter,
    dates: !!filter.dates
      ? parseOneDateWithoutGmtToBack(filter.dates)
      : undefined,
    onlyActiveDays: undefined,
    pagination
  }

  const { request: getTableData } = useRequest({
    url: `v1/guests/history/bets`,
    requestBody: filterForRequest,
    method: `POST`
  })

  const requestDeps = JSON.stringify({ ...filter, ...pagination })

  useEffect(() => {
    setBetTableData(prevState => ({ ...prevState, pending: true }))
    getTableData().then(({ data, headers }) => {
      setBetTableData({
        ...betsTableData,
        pending: false,
        data: {
          total_count: Number(headers[`total-count`] || 0),
          ...data
        }
      })
    })
  }, [requestDeps])

  const textConstants = useMemo(() => getConstants(betsTableData.data), [
    betsTableData.data
  ])

  const columns = useMemo(() => getColumns(filter.includeFreebets), [
    filter.includeFreebets
  ])

  return (
    <div>
      <div className="GuestHistoryBetsTableHeader FlexRow">
        <div>
          <div className="HeadlineThirdText">{textConstants.title}</div>
          <div className="FlexRow GuestHistoryBetsTableHeaderDescription">
            <div className="TextDefault GuestHistoryBetsTableHeaderDescriptionItem">
              {textConstants.descriptionSum}
            </div>
            <div className="TextDefault GuestHistoryBetsTableHeaderDescriptionItem">
              {textConstants.descriptionTime}
            </div>
          </div>
        </div>
        {!!betsTableData.data.total_count && (
          <ExcelButton
            requestBody={filterForRequest}
            url={textConstants.exelUrl}
          />
        )}
      </div>
      {!!betsTableData.data.total_count || betsTableData.pending ? (
        <div className="GuestHistoryBetsTableWrapper">
          <NewTableComponent
            columns={columns}
            data={betsTableData.data.data}
            loading={betsTableData.pending}
            pagination={{
              value: pagination,
              onChange: setPagination,
              total: betsTableData.data.total_count
            }}
          />
        </div>
      ) : (
        <EmptyData
          title={textConstants.emptyTable.title}
          subtitle={textConstants.emptyTable.subtitle}
          isSmall={true}
        />
      )}
    </div>
  )
}

export default BetsTable
