import React, { useMemo, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import Filter, { CONSOLIDATED_FILTER_NAME } from "./Filter"
import ConsolidatedState, {
  ConsolidatedFilter,
  initialFilterData
} from "./types"
import getConsolidated from "./helper"
import ConsolidatedContent from "./Content"

import { getDateDescriptionLayout, shiftMoscowGmtFirst } from "constants/index"

import { ReactComponent as FilterIcon } from "Components/icons/filter_arrow.svg"
import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"

import EmptyTable from "Components/EmptyTable"
import NotActiveFilter from "Components/NotActiveFilter"
import BaseFooter from "Components/Footer"

import { ReactComponent as EmptyIcon } from "./filter_appertain.svg"

import { getAddressList, getCurrentHall } from "store/halls/selector"
import { IDevelopState } from "store/developer/types"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { getDeveloperList } from "store/developer/selector"
import { addLink } from "store/developer/actions"
import { logoutUser } from "store/auth/actions"
import { getFilterStateFromLocalStorage } from "api/helpers"
import { isEmpty } from "lodash"
import { useRequestBody } from "api/hooks"
import { text } from "./constants"
import Title from "Components/Title"

const ConsolidatedComponent = () => {
  const history = useHistory()
  // use redux main
  const current_hall: (string | CheckboxValueType)[] = useSelector(
    getCurrentHall
  )
  const developer: IDevelopState = useSelector(getDeveloperList)
  const dispatch = useDispatch()
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))
  const logout: () => void = () => dispatch(logoutUser())

  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    CONSOLIDATED_FILTER_NAME
  )

  const locations: {
    gmt: string
    region: string
  }[] = useSelector(getAddressList)

  const [filterState, setFilterState] = useState<ConsolidatedFilter>({
    isOpen: true,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      ...initialFilterData,
      shift: shiftMoscowGmtFirst({ gmt: locations[0].gmt }),
      halls: current_hall,
      ...initFilterStateFromStorage
    }
  })
  const [consolidatedState, setConsolidatedState] = useState<ConsolidatedState>(
    {
      pending: false,
      total: 0,
      data: []
    }
  )

  const { pagination, setPagination } = useRequestBody()
  const depsHalls = useMemo(() => current_hall.join(`,`), [current_hall])

  const requestDeps = JSON.stringify({ ...filterState.data, ...pagination })

  useEffect(() => {
    if (filterState.isActive && !consolidatedState.pending)
      getConsolidated({
        halls: filterState.data.halls,
        consolidatedState,
        setConsolidatedState,
        logout,
        history,
        developer,
        addDeveloperLink,
        filter: filterState.data,
        pagination
      })
  }, [depsHalls, requestDeps])

  const { dates, isShift, shift } = filterState.data

  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(
    window.innerWidth < 921
  )

  return (
    <div className="FlexRow">
      <div>
        <div
          className={`MainPageContentWrapper ${
            filterState.isOpen
              ? `MainPageContentWrapperFilter`
              : `MainPageContentWrapperUnFilter`
          }`}
        >
          <div
            className="MobileFilterIcon"
            onClick={() => setMobileFilterOpen(true)}
          >
            <FilterMobileIcon />
          </div>
          <div className="MainPageContentRestrictions">
            {filterState.isActive && (
              <Title
                titleText={text.title}
                tooltipText={text.tooltip}
                defaultBottomCmpMargin
                bottomAdditionalCmp={
                  <>
                    {dates &&
                      getDateDescriptionLayout({
                        dates,
                        shift,
                        isShift,
                        show: filterState.isActive
                      })}
                  </>
                }
              />
            )}
            {!filterState.isActive && !consolidatedState.pending && (
              <NotActiveFilter
                title={text.filter.title}
                description={text.filter.description}
                icon={<EmptyIcon />}
              />
            )}
            {!!consolidatedState.data &&
              !!consolidatedState.data.length &&
              filterState.isActive && (
                <ConsolidatedContent
                  report={consolidatedState.data}
                  total={consolidatedState.total}
                  pending={consolidatedState.pending}
                  isOpen={filterState.isOpen}
                  period={filterState.data.period}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              )}
            {!!consolidatedState.data &&
              !consolidatedState.data.length &&
              !consolidatedState.pending &&
              filterState.isActive && <EmptyTable />}
            <div className="FlexRow">
              <div
                className={`MainPageFilterButtonWrapper ${
                  filterState.isOpen
                    ? `MainPageFilterButtonWrapperActive`
                    : `MainPageFilterButtonWrapperUnActive`
                }`}
                onClick={() =>
                  setFilterState({
                    ...filterState,
                    isOpen: !filterState.isOpen
                  })
                }
              >
                <FilterIcon />
              </div>
            </div>
          </div>
          {mobileFilterOpen && (
            <Filter
              current_hall={current_hall}
              filterState={filterState}
              setFilterState={setFilterState}
              mobileFilterOpen={mobileFilterOpen}
              setMobileFilterOpen={setMobileFilterOpen}
            />
          )}
        </div>
        <BaseFooter />
      </div>
      <Filter
        current_hall={current_hall}
        filterState={filterState}
        setFilterState={setFilterState}
      />
    </div>
  )
}

export default ConsolidatedComponent
