import React, { useEffect, useState } from "react"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

import { GameFilterDataType, GameFilterState } from "../types"
import GamesList from "./Games"

import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"

import InfoPanelComponent from "Components/FilterInfoPanel"
import { ButtonPrimary } from "Components/Button"
import SwitchComponents from "Components/Switch"
import { useDispatch, useSelector } from "react-redux"
import { getGamesList } from "store/dictionary/selector"
import { useRequest } from "api/hooks"
import { IDictionaryState } from "store/dictionary/types"
import { storeDictionaryData } from "store/dictionary/actions"
import {
  getFilterStateFromLocalStorage,
  saveFilterInLocalStorage
} from "api/helpers"

export const GAMES_FILTER_NAME = "gamesFilter"

const Filter = ({
  filterState,
  setFilterState,
  current_hall,
  mobileFilterOpen,
  setMobileFilterOpen
}: {
  filterState: GameFilterState
  setFilterState: (data: GameFilterState) => void
  current_hall: (string | CheckboxValueType)[]
  mobileFilterOpen?: boolean
  setMobileFilterOpen?: (data: boolean) => void
}) => {
  const dispatch = useDispatch()
  const storeDictionary: (data: IDictionaryState) => void = data =>
    dispatch(storeDictionaryData(data))

  const games: { id: string | number; name: string }[] = useSelector(
    getGamesList
  )
  const allGameIds = games.map(item => item.id)
  const initState = getFilterStateFromLocalStorage(
    {
      ...filterState.data,
      games: allGameIds
    },
    GAMES_FILTER_NAME
  )
  const [filter, setFilter] = useState<GameFilterDataType>(initState)

  const { request: getDictionary } = useRequest({
    url: `dictionary`,
    method: `POST`,
    withHall: filter.halls
  })

  useEffect(() => {
    if (filter.halls && filter.halls.length) {
      getDictionary().then(({ data }: { data: IDictionaryState }) => {
        storeDictionary(data)
      })
    }
  }, [filter.halls])

  useEffect(() => {
    setFilter({
      ...filter,
      games: initState.games ? initState.games : allGameIds
    })
  }, [games])

  const isSport = filter.type === `sport`
  return (
    <div
      className={`
      ${mobileFilterOpen ? `MainFilterMobile` : `MainFilterWrapper`}
      ${
        filterState.isOpen
          ? `MainFilterWrapperActive`
          : `MainFilterWrapperUnActive`
      }`}
    >
      <div
        className="MainFilterMobileClose"
        onClick={() => {
          if (mobileFilterOpen && setMobileFilterOpen) {
            setMobileFilterOpen(false)
          }
        }}
      >
        <CloseIcon />
      </div>
      <div className="MainFilterHeader FlexRow">
        <span>Фильтры</span>
      </div>
      <div className="MainFilterContent">
        <div className="InfoPanelComponentWrapper">
          <InfoPanelComponent<GameFilterDataType>
            filter={filter}
            setFilter={setFilter}
            current_hall={current_hall}
            filterHalls={filter.halls}
          />
        </div>
        <div className="GameFilterContentWrapper">
          <div className="FlexRow">
            <span className="MainFilterContentLabel">SportBoom</span>
            <div className="InfoPanelWithoutShiftTypeSwitcher">
              <SwitchComponents
                label=""
                checked={isSport}
                onClick={checked =>
                  setFilter({
                    ...filter,
                    type: checked ? `sport` : `games`
                  })
                }
              />
            </div>
          </div>
          {!isSport && (
            <GamesList
              filterGames={filter.games}
              setFilterGames={(data: (string | number)[]) =>
                setFilter({ ...filter, games: data })
              }
            />
          )}
        </div>
      </div>
      <div className="MainFilterApplyWrapper GameFilterApplyWrapper">
        <ButtonPrimary
          disabled={
            !filter.dates ||
            !filter.halls.length ||
            (filter.type === `games` && !filter.games?.length)
          }
          onClick={() => {
            if (mobileFilterOpen && setMobileFilterOpen) {
              setMobileFilterOpen(false)
            }
            const newFilterState = {
              ...filterState,
              data: { ...filterState.data, ...filter },
              isActive: true
            }
            setFilterState(newFilterState)
            saveFilterInLocalStorage(newFilterState.data, GAMES_FILTER_NAME)
          }}
        >
          Применить
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default Filter
