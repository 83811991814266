import React from "react"
import { ReactComponent as AddSectionIcon } from "Components/icons/icon_color.svg"
import { ButtonPrimary } from "Components/Button"
import { addSectionBtnProps } from "../types"

const AddSectionBtn = ({
  contactsState,
  setContactsState
}: addSectionBtnProps) => {
  return (
    <ButtonPrimary
      className="ContactsPage_addBlockBtn ContactsBtn"
      onClick={() =>
        setContactsState({
          ...contactsState,
          activeModal: true,
          isSectionEditing: true
        })
      }
      icon={<AddSectionIcon />}
    >
      Добавить раздел
    </ButtonPrimary>
  )
}

export default AddSectionBtn
