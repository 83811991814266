import React from "react"
import InputComponent from "Components/Input"
import { Step3FieldProps } from "Containers/Sms/types"
import TooltipComponent from "Components/Tooltip"
import { smsConstants } from "Containers/Sms/constants"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"

const Address = ({ smsState, setSmsState, ...rest }: Step3FieldProps) => {
  return (
    <div className="CreateSms_input">
      <label className="CreateSms_inputLabel">
        {smsConstants.step3.address}
      </label>
      <InputComponent
        greyBackground
        readOnly
        value={smsState.extraParams.address}
        placeholder={smsConstants.step3.addressPlaceholder}
        {...rest}
      />
      <TooltipComponent
        title={smsConstants.step3.addressTooltip}
        direction="bottom"
      >
        <InfoIcon />
      </TooltipComponent>
    </div>
  )
}

export default Address
