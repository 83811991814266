import React, { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import boldIcon from "./icons/bold.svg"
import italicIcon from "./icons/italic.svg"
import underlineIcon from "./icons/underline.svg"
import strikethroughIcon from "./icons/strikethrough.svg"
import paragraphIcon from "./icons/paragraph.svg"
import colorsIcon from "./icons/colors.svg"
import linkIcon from "./icons/link.svg"
import listIcon from "./icons/list.svg"
import { EditorComponentProps } from "./types"

const editorLabels = {
  // Generic
  "generic.add": "Добавить",
  "generic.cancel": "Отмена",

  // BlockType
  "components.controls.blocktype.normal": "Обычный текст",
  "components.controls.blocktype.h1": "Заголовок 1",
  "components.controls.blocktype.h2": "Заголовок 2",
  "components.controls.blocktype.h3": "Заголовок 3",
  // "components.controls.blocktype.blockquote": "Цитата",
  "components.controls.blocktype.blocktype": "Список",
  // "components.controls.blocktype.code": "Код",

  // Color Picker
  "components.controls.colorpicker.colorpicker": "Color Picker",
  "components.controls.colorpicker.text": "Текст",
  "components.controls.colorpicker.background": "Фон",

  // Embedded
  "components.controls.embedded.embedded": "Embedded",
  "components.controls.embedded.embeddedlink": "Embedded Link",
  "components.controls.embedded.enterlink": "Enter link",

  // FontFamily
  "components.controls.fontfamily.fontfamily": "Font",

  // FontSize
  "components.controls.fontsize.fontsize": "Font Size",

  // History
  "components.controls.history.history": "History",
  "components.controls.history.undo": "Undo",
  "components.controls.history.redo": "Redo",

  // Image
  "components.controls.image.image": "Image",
  "components.controls.image.fileUpload": "File Upload",
  "components.controls.image.byURL": "URL",
  "components.controls.image.dropFileText": "Drop the file or click to upload",

  // Inline
  "components.controls.inline.bold": "Bold",
  "components.controls.inline.italic": "Italic",
  "components.controls.inline.underline": "Underline",
  "components.controls.inline.strikethrough": "Strikethrough",
  "components.controls.inline.monospace": "Monospace",
  "components.controls.inline.superscript": "Superscript",
  "components.controls.inline.subscript": "Subscript",

  // Link
  "components.controls.link.linkTitle": "Текст ссылки",
  "components.controls.link.linkTarget": "Адрес",
  "components.controls.link.linkTargetOption": "Открывать в новом окне?",
  "components.controls.link.link": "Link",

  // List
  "components.controls.list.list": "List",
  "components.controls.list.unordered": "Unordered",
  "components.controls.list.ordered": "Ordered",
  "components.controls.list.indent": "Indent",
  "components.controls.list.outdent": "Outdent",

  // Remove
  "components.controls.remove.remove": "Remove",

  // TextAlign
  "components.controls.textalign.textalign": "Text Align",
  "components.controls.textalign.left": "Left",
  "components.controls.textalign.center": "Center",
  "components.controls.textalign.right": "Right",
  "components.controls.textalign.justify": "Justify"
}

const EditorComponent: React.FC<EditorComponentProps> = ({
  disabled,
  initial,
  setFormData,
  error,
  stripPastedStyles
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [content, setContent] = useState(editorState.getCurrentContent())
  const [className, setClassName] = useState("RichEditor-editor")

  useEffect(() => {
    const contentBlock = htmlToDraft(initial || ``)
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )
    const editorInitialState = EditorState.createWithContent(contentState)
    if (editorInitialState) {
      setEditorState(editorInitialState)
    }
  }, [initial, disabled])

  const onEditorStateChange = (value: any) => {
    setEditorState(value)
    setFormData(draftToHtml(convertToRaw(value.getCurrentContent())))
  }

  useEffect(() => {
    setContent(editorState.getCurrentContent())
  }, [editorState])

  useEffect(() => {
    if (!content.hasText()) {
      if (
        content
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        setClassName(prev => (prev += " RichEditor-hidePlaceholder"))
      }
    }
  }, [content])

  return (
    <div className="EditorWrapperRoot">
      {disabled ? (
        <div className="EditorWrapperView">
          <span dangerouslySetInnerHTML={{ __html: initial || `` }} />
        </div>
      ) : (
        <div className="RichEditor-root">
          <div className={className}>
            <Editor
              localization={{ locale: "en", translations: editorLabels }}
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              wrapperClassName="EditorWrapper"
              editorClassName={`EditorTextArea ${
                !!error ? `EditorErrorTextArea` : ``
              }`}
              toolbarClassName="EditorToolbar"
              placeholder="Введите сообщение для клубов"
              toolbar={{
                options: [
                  "blockType",
                  "colorPicker",
                  "inline",
                  "fontSize",
                  "link",
                  "list",
                  "history"
                ],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ["bold", "italic", "underline", "strikethrough"],
                  bold: {
                    className: `EditorToolbarItem`,
                    icon: boldIcon
                  },
                  italic: { icon: italicIcon, className: undefined },
                  underline: { icon: underlineIcon, className: undefined },
                  strikethrough: {
                    icon: strikethroughIcon,
                    className: undefined
                  }
                },
                blockType: {
                  icon: paragraphIcon,
                  inDropdown: true,
                  options: ["Normal", "H1", "H2", "H3"],
                  Normal: {
                    className: `EditorToolbarItem`,
                    icon: boldIcon
                  },
                  className: `EditorToolbarItemBlockType`,
                  dropdownClassName: `EditorToolbarItemBlockTypeDropdown`
                },
                fontSize: {
                  // icon: fontSize,
                  options: [
                    8,
                    9,
                    10,
                    11,
                    12,
                    14,
                    16,
                    18,
                    24,
                    30,
                    36,
                    48,
                    60,
                    72,
                    96
                  ],
                  className: `EditorToolbarItemFontSize`,
                  dropdownClassName: `EditorToolbarItemFontSizeDropdown`
                },
                list: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ["ordered", "unordered"],
                  ordered: { icon: listIcon, className: undefined }
                },
                colorPicker: {
                  icon: colorsIcon,
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  colors: [
                    "rgb(97,189,109)",
                    "rgb(26,188,156)",
                    "rgb(84,172,210)",
                    "rgb(44,130,201)",
                    "rgb(147,101,184)",
                    "rgb(71,85,119)",
                    "rgb(204,204,204)",
                    "rgb(65,168,95)",
                    "rgb(0,168,133)",
                    "rgb(61,142,185)",
                    "rgb(41,105,176)",
                    "rgb(85,57,130)",
                    "rgb(40,50,78)",
                    "rgb(0,0,0)",
                    "rgb(247,218,100)",
                    "rgb(251,160,38)",
                    "rgb(235,107,86)",
                    "rgb(226,80,65)",
                    "rgb(163,143,132)",
                    "rgb(239,239,239)",
                    "rgb(255,255,255)",
                    "rgb(250,197,28)",
                    "rgb(243,121,52)",
                    "rgb(209,72,65)",
                    "rgb(184,49,47)",
                    "rgb(124,112,107)",
                    "rgb(209,213,216)"
                  ]
                },
                link: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  dropdownClassName: undefined,
                  showOpenOptionOnHover: true,
                  defaultTargetOption: "_self",
                  options: ["link"],
                  link: { icon: linkIcon, className: `LinkButton` },
                  linkCallback: undefined
                },
                embedded: {
                  // icon: embedded,
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  embedCallback: undefined,
                  defaultSize: {
                    height: "auto",
                    width: "auto"
                  }
                },
                image: {
                  // icon: image,
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: false,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "auto"
                  }
                },
                // remove: { icon: eraser, className: undefined, component: undefined },
                history: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ["undo", "redo"]
                  // undo: { icon: undo, className: undefined },
                  // redo: { icon: redo, className: undefined }
                }
              }}
              stripPastedStyles={stripPastedStyles}
            />
          </div>
        </div>
      )}
      {!!error && <div className="ErrorText">{error}</div>}
    </div>
  )
}

export default EditorComponent
