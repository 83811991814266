import React from "react"

import { LoyaltyGuestDashboardType } from "../types"

import {
  DashboardOptions,
  defaultDescriptions,
  DashboardPrefixOptions
} from "Components/DashboardNew/constants"

const dashboardTextConfig = {
  bonus: {
    title: `Начисленные\nбонусы`,
    description: `Сумма бонусов, начисленных гостю на бонусный баланс, за выбранный период.`
  },
  status: {
    title: `Статус\nгостя`,
    description: `Статус гостя на текущий момент.`
  },
  cashback: {
    title: `Процент\nкэшбэка`,
    description: `Процент кэшбэка гостя на текущий момент, в соответствии со статусом.`
  },
  accumulatedBonus: {
    title: `Накопленные\nбонусы`,
    description: `Бонусы, которые были начислены гостю на карточку “КЭШБЭК” согласно условиям акции.`
  },
  nextLevel: {
    title: `Условия для повы- \nшения статуса`,
    description: `Информация об общем количестве баллов, которое необходимо набрать гостю для повышения статуса согласно условиям акции.`,
    max: `Максимальный кэшбэк`
  }
}

export default dashboardTextConfig

export const getConfig = (data: LoyaltyGuestDashboardType) => {
  return [
    {
      title: dashboardTextConfig.bonus.title,
      type: DashboardOptions.upcoming_bonuses,
      value: data.bonus,
      isCurrency: true,
      isString: true,
      prefix: DashboardPrefixOptions.BON,
      description: defaultDescriptions.current_period,
      tooltipText: dashboardTextConfig.bonus.description
    },
    {
      title: dashboardTextConfig.status.title,
      type: DashboardOptions.guest_status,
      value: data.status,
      isCurrency: false,
      isString: true,
      description: defaultDescriptions.now,
      tooltipText: dashboardTextConfig.status.description
    },
    {
      title: dashboardTextConfig.cashback.title,
      type: DashboardOptions.cashback_percent,
      value: data.cashback,
      isCurrency: true,
      isString: true,
      prefix: DashboardPrefixOptions.PER,
      description: defaultDescriptions.now,
      tooltipText: dashboardTextConfig.cashback.description
    },
    {
      title: dashboardTextConfig.accumulatedBonus.title,
      type: DashboardOptions.save_bonuses,
      value: data.accumulatedBonus,
      isCurrency: true,
      isString: true,
      prefix: DashboardPrefixOptions.BON,
      description: defaultDescriptions.now,
      tooltipText: dashboardTextConfig.accumulatedBonus.description
    }
  ]
}
