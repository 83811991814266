import React from "react"

import { ButtonOutlined } from "Components/Button"

import { ReactComponent as InfoIcon } from "./info_icon.svg"
import { textConst } from "../constants"

const Information = ({ openModal }: { openModal: () => void }) => {
  return (
    <div className="Geoservices_InformationWrapper">
      <div className="FlexColumn Gap4">
        <div className="FlexRow Gap4">
          <div>
            <InfoIcon />
          </div>
          <div>
            {textConst.rules.map((rule, index) => (
              <div
                key={`${rule}_${index}`}
                className="Geoservices_InformationWrapper__Rules"
              >
                {index + 1}
                <span dangerouslySetInnerHTML={{ __html: textConst.space }} />
                <div dangerouslySetInnerHTML={{ __html: rule }} />
              </div>
            ))}
          </div>
        </div>
        <div className="Geoservices_InformationWrapper__Info">
          {textConst.rulesInfo}
        </div>
      </div>
      <div>
        <ButtonOutlined onClick={openModal}>
          {textConst.moreInfo}
        </ButtonOutlined>
      </div>
    </div>
  )
}

export default Information
