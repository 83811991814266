import React, { useState } from "react"
import { ButtonNotifi, ButtonPrimary } from "Components/Button"
import BaseFooter from "Components/Footer"

import { ReactComponent as BackIcon } from "Components/icons/back_large.svg"
import { ReactComponent as FilterIcon } from "Components/icons/filter.svg"
import { ReactComponent as DeleteIcon } from "Components/icons/delete_red.svg"
import { ReactComponent as Delete24Icon } from "Components/icons/delete_24.svg"
import { ReactComponent as AddIcon } from "Components/icons/add_24.svg"
import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"

import { isArray, isEqual } from "lodash"
import { useNow } from "api/hooks"

import DeleteModal from "./DeleteModal"

import CrudModal from "./CrudModal"
import { CrudDataType } from "./CrudModal/types"
import { crudInitial } from "./CrudModal/constants"

import NotificationsFilter from "./Filters"
import NotificationsTable from "./Table"

import { Moment } from "moment"

export type NotifiFilterStateData = {
  id: string | undefined
  country: number | undefined
  city: string | undefined
  hall: string | undefined
  text: string | undefined
  files: string | undefined
  date: [Moment, Moment] | undefined
  outgoing?: boolean
}

export type NotifiFilterState = {
  data: NotifiFilterStateData
  active: boolean
  pending: boolean
}

export const initialFilter: NotifiFilterState = {
  data: {
    id: undefined,
    country: undefined,
    city: undefined,
    hall: undefined,
    text: undefined,
    files: undefined,
    date: undefined
  },
  active: false,
  pending: false
}

const initialDelete = { data: [], active: false }

const NotificationsComponent = () => {
  const [filter, setFilter] = useState<NotifiFilterState>(initialFilter)
  const [modal, setModal] = useState<{
    open: boolean
    id: number | string
    initial: CrudDataType
  }>({ open: false, id: `new`, initial: crudInitial })
  const filterClear = isEqual(filter.data, initialFilter.data)

  const [activeTabs, setActiveTabs] = useState<string>(`list`)

  // notification list data
  const [deleteData, setDeleteData] = useState<{
    data: number[] | string
    active: boolean
  }>(initialDelete)
  const changeTabWithStateClear = (value: string) => {
    setActiveTabs(value)
    setFilter(initialFilter)
    setDeleteData(initialDelete)
  }
  let filterCount = 0
  for (const key in filter.data) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!!filter.data[`${key}`]) ++filterCount
  }
  const [hallStatistic, setHallStatistic] = useState<{
    hall: string | undefined
    type: string | undefined
  }>({ hall: undefined, type: undefined })
  const isHallStatistic = !!hallStatistic.hall && !!hallStatistic.type
  let hallStatisticTitle = undefined
  switch (hallStatistic.type) {
    case `read`:
      hallStatisticTitle = `Прочитанные сообщения `
      break
    case `unread`:
      hallStatisticTitle = `Не прочитанные сообщения `
      break
    case `total`:
      hallStatisticTitle = `Все сообщения `
      break
  }
  const { timestamp, refresh } = useNow()

  return (
    <div className="NotifiRouteWrapper">
      <div
        className="MobileFilterIcon"
        onClick={() => setFilter({ ...filter, active: !filter.active })}
      >
        <FilterMobileIcon />
      </div>
      <div className="MainPageContentWrapper">
        <div className="MainPageContentRestrictions">
          <div className="FlexRow NotificationsHeader">
            {isHallStatistic ? (
              <div className="FlexRow">
                <div
                  className="NotificationMainPageTitleBack"
                  onClick={() =>
                    setHallStatistic({ hall: undefined, type: undefined })
                  }
                >
                  <BackIcon />
                </div>
                <div className="MainPageTitle NotificationMainPageTitle">{`${hallStatisticTitle} (клуб № ${hallStatistic.hall})`}</div>
              </div>
            ) : (
              <div className="MainPageTitle NotificationMainPageTitle FlexRow">
                <span>Сообщения</span>
                {(activeTabs === `list` || activeTabs === `outgoing`) && (
                  <div
                    className="NotificationMainPageTitleMobileAdd"
                    onClick={() =>
                      setModal({ ...modal, open: true, id: `new` })
                    }
                  >
                    <AddIcon />
                  </div>
                )}
                <div className="NotificationMainPageTitleMobileDeleteWrapper FlexRow">
                  {isArray(deleteData.data) && !!deleteData.data.length && (
                    <div
                      className="NotificationMainPageTitleMobileDelete"
                      onClick={() =>
                        setDeleteData({ ...deleteData, active: true })
                      }
                    >
                      <Delete24Icon />
                    </div>
                  )}
                  {activeTabs === `basket` && (
                    <div
                      className="NotificationMainPageTitleMobileClearBasket"
                      onClick={() =>
                        setDeleteData({ data: `all`, active: true })
                      }
                    >
                      Очистить все
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="NotificationsHeaderButtons FlexRow">
              {isArray(deleteData.data) && !!deleteData.data.length && (
                <ButtonNotifi
                  isMiddle={true}
                  onClick={() => setDeleteData({ ...deleteData, active: true })}
                >
                  <div className="FlexRow">
                    <DeleteIcon />
                    <span>Удалить</span>
                  </div>
                </ButtonNotifi>
              )}
              {(activeTabs === `list` || activeTabs === `outgoing`) && (
                <div className="NotificationsHeaderButtonsItem">
                  <ButtonNotifi
                    isMiddle={true}
                    active={filter.active}
                    onClick={() =>
                      setFilter({ ...filter, active: !filter.active })
                    }
                  >
                    <div className="FlexRow">
                      {filterClear ? (
                        <FilterIcon />
                      ) : (
                        <div className="NotificationsFilterCounter">
                          {filterCount}
                        </div>
                      )}
                      <span>Фильтр</span>
                    </div>
                  </ButtonNotifi>
                </div>
              )}
              {(activeTabs === `list` || activeTabs === `outgoing`) && (
                <ButtonPrimary
                  isMiddle={true}
                  onClick={() => setModal({ ...modal, open: true, id: `new` })}
                >
                  Новое сообщение
                </ButtonPrimary>
              )}
              {activeTabs === `basket` && (
                <div className="NotificationsHeaderButtonsItem">
                  <ButtonNotifi
                    isMiddle={true}
                    color="redBorder"
                    onClick={() => setDeleteData({ data: `all`, active: true })}
                  >
                    <div>
                      <span>Очистить корзину</span>
                    </div>
                  </ButtonNotifi>
                </div>
              )}
            </div>
          </div>
          <div className="NotificationsHeaderTabs FlexRow">
            <div
              className={`FlexColumn NotificationsHeaderTabsItem ${
                activeTabs === `list`
                  ? `NotificationsHeaderTabsActiveItemText`
                  : ``
              }`}
              onClick={() => {
                if (activeTabs !== `list`) changeTabWithStateClear(`list`)
              }}
            >
              <span>Отправленные</span>
              {activeTabs === `list` && (
                <div className="NotificationsHeaderTabsActiveItem" />
              )}
            </div>
            <div
              className={`FlexColumn NotificationsHeaderTabsItem ${
                activeTabs === `outgoing`
                  ? `NotificationsHeaderTabsActiveItemText`
                  : ``
              }`}
              onClick={() => {
                if (activeTabs !== `outgoing`)
                  changeTabWithStateClear(`outgoing`)
              }}
            >
              <span>Запланированные</span>
              {activeTabs === `outgoing` && (
                <div className="NotificationsHeaderTabsActiveItem" />
              )}
            </div>
            <div
              className={`FlexColumn NotificationsHeaderTabsItem ${
                activeTabs === `draft`
                  ? `NotificationsHeaderTabsActiveItemText`
                  : ``
              }`}
              onClick={() => {
                if (activeTabs !== `draft`) changeTabWithStateClear(`draft`)
              }}
            >
              <span>Черновики</span>
              {activeTabs === `draft` && (
                <div className="NotificationsHeaderTabsActiveItem" />
              )}
            </div>
            <div
              className={`FlexColumn NotificationsHeaderTabsItem ${
                activeTabs === `basket`
                  ? `NotificationsHeaderTabsActiveItemText`
                  : ``
              }`}
              onClick={() => {
                if (activeTabs !== `basket`) changeTabWithStateClear(`basket`)
              }}
            >
              <span>Корзина</span>
              {activeTabs === `basket` && (
                <div className="NotificationsHeaderTabsActiveItem" />
              )}
            </div>
          </div>
          {/*)}*/}
          {(activeTabs === `list` || activeTabs === `outgoing`) &&
            filter.active && (
              <NotificationsFilter
                filter={filter.data}
                setFilter={(data: NotifiFilterStateData) =>
                  setFilter({ ...filter, data })
                }
                mobileSetFilter={(data: NotifiFilterStateData) =>
                  setFilter({ ...filter, data, active: false })
                }
                pending={filter.pending}
                clear={() => {
                  setFilter({ ...filter, data: initialFilter.data })
                  setDeleteData({ ...deleteData, data: [] })
                  refresh()
                }}
                mobileClear={() => {
                  setFilter({
                    ...filter,
                    data: initialFilter.data,
                    active: false
                  })
                  setDeleteData({ ...deleteData, data: [] })
                  refresh()
                }}
                apply={() => {
                  setDeleteData({ ...deleteData, data: [] })
                  refresh()
                }}
                close={() => setFilter({ ...filter, active: !filter.active })}
              />
            )}
          {activeTabs === `list` && (
            <NotificationsTable
              listUrl="admin/message/all"
              setModal={setModal}
              deleteData={deleteData.data}
              setDeleteData={setDeleteData}
              trigger={timestamp}
              filter={filter.data}
              filterCount={filterCount}
              type={activeTabs}
              setFilterPending={(value: boolean) =>
                setFilter({ ...filter, pending: value })
              }
            />
          )}
          {activeTabs === `outgoing` && (
            <NotificationsTable
              listUrl="admin/message/all"
              setModal={setModal}
              deleteData={deleteData.data}
              setDeleteData={setDeleteData}
              trigger={timestamp}
              filter={filter.data}
              filterCount={filterCount}
              type={activeTabs}
              setFilterPending={(value: boolean) =>
                setFilter({ ...filter, pending: value })
              }
            />
          )}
          {activeTabs === `draft` && (
            <NotificationsTable
              listUrl="admin/message/draft/all"
              setModal={setModal}
              deleteData={deleteData.data}
              setDeleteData={setDeleteData}
              trigger={timestamp}
              filter={filter.data}
              filterCount={filterCount}
              setFilterPending={(value: boolean) =>
                setFilter({ ...filter, pending: value })
              }
            />
          )}
          {/*{activeTabs === `statistic` && (*/}
          {/*  <Statistic*/}
          {/*    setModal={setModal}*/}
          {/*    hallStatistic={hallStatistic}*/}
          {/*    setHallStatistic={setHallStatistic}*/}
          {/*  />*/}
          {/*)}*/}
          {activeTabs === `basket` && (
            <NotificationsTable
              listUrl="admin/message/basket/all"
              setModal={setModal}
              deleteData={deleteData.data}
              setDeleteData={setDeleteData}
              trigger={timestamp}
              filter={filter.data}
              filterCount={filterCount}
              setFilterPending={(value: boolean) =>
                setFilter({ ...filter, pending: value })
              }
            />
          )}
          {deleteData.active && (
            <DeleteModal
              type={activeTabs}
              deleteData={deleteData.data}
              successDelete={() => {
                setDeleteData({ data: [], active: false })
                refresh()
              }}
              failedDelete={() =>
                setDeleteData({ ...deleteData, active: false })
              }
            />
          )}
        </div>
      </div>
      <BaseFooter />

      {modal.open && (
        <CrudModal
          type={activeTabs}
          id={modal.id}
          initial={modal.initial}
          close={() => {
            setModal({ initial: crudInitial, open: false, id: `new` })
          }}
          refresh={refresh}
        />
      )}
    </div>
  )
}

export default NotificationsComponent
