import React, { useCallback, useState } from "react"

import { Form } from "antd"

import { useRequestParams } from "api/hooks"
import betRequest from "api/request"

import { isMobile } from "constants/index"

import { rules } from "Components/Form/rules"
import { ModalFormComponent } from "Components/Modal"
import InputComponent from "Components/Input"
import { CheckBoxComponent } from "Components/CheckBox"
import { ButtonNotifi, ButtonPrimary } from "Components/Button"

import { ReactComponent as SuccessIcon } from "Components/icons/illustrations/thumb_up.svg"

import {
  cancelEditText,
  fieldText,
  initialValues,
  successText
} from "./constants"

import { SportsBroadcastData } from "../types"

const SportsBroadcastModal = ({
  data,
  close,
  refresh
}: {
  data?: SportsBroadcastData
  close: () => void
  refresh: () => void
}) => {
  const isEdit = !!data
  const [form] = Form.useForm()
  const {
    developer,
    addDeveloperLink,
    history,
    logout,
    currentHallAsNumber
  } = useRequestParams()

  const [pending, setPending] = useState(false)
  const [closeModal, toggleCloseModal] = useState(false)
  const [successModal, toggleSuccessModal] = useState(false)

  const [hasSupersport, toggleHasSupersport] = useState(!!data?.is_base_plus)

  const onFinish = (finishData: any) => {
    setPending(true)
    betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      method: `POST`,
      url: `v1/hall/sports_broadcast/save`,
      requestBody: {
        ...finishData,
        hall: currentHallAsNumber,
        tv: isEdit ? data.tv : undefined
      }
    })
      .then(() => {
        toggleSuccessModal(true)
      })
      .finally(() => setPending(false))
  }

  const closeSuccess = useCallback(() => {
    close()
    refresh()
  }, [])
  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={
        isEdit
          ? { ...data, cart_number: String(data.cart_number) }
          : initialValues
      }
    >
      {!closeModal ? (
        <ModalFormComponent
          width={800}
          withBigCross={!isMobile}
          close={
            !successModal
              ? () => {
                  if (isEdit) {
                    toggleCloseModal(true)
                  } else {
                    close()
                  }
                }
              : undefined
          }
        >
          {!successModal ? (
            <div className="SportsBroadcast__ModalWrapper PaddingTop14">
              <div className="HeadlineFirstText PaddingBottom20">
                {isEdit ? fieldText.editTitle : fieldText.addTitle}
              </div>
              <div className="FlexColumn">
                <div className={isMobile ? `FlexColumn` : `FlexRow Gap24`}>
                  <div className="FlexColumn Gap4 FullSizeWidth SportsBroadcast__ModalWrapper_FormItem">
                    <div className="SubtitleFirstText">
                      {fieldText.receiver_number.title}
                    </div>
                    <Form.Item
                      rules={[
                        ...fieldText.receiver_number.rules,
                        ...rules.required
                      ]}
                      name={fieldText.receiver_number.field}
                    >
                      <InputComponent
                        disabled={pending}
                        maxLength={20}
                        placeholder={fieldText.receiver_number.placeholder}
                      />
                    </Form.Item>
                  </div>
                  <div className="FlexColumn Gap4 FullSizeWidth SportsBroadcast__ModalWrapper_FormItem">
                    <div className="SubtitleFirstText">
                      {fieldText.cart_number.title}
                    </div>
                    <Form.Item
                      rules={[...rules.required]}
                      name={fieldText.cart_number.field}
                    >
                      <InputComponent
                        disabled={pending}
                        maxLength={12}
                        placeholder={fieldText.cart_number.placeholder}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="FullSizeWidth SportsBroadcast__ModalWrapper_FormItem">
                  <Form.Item name={fieldText.is_base_plus.field}>
                    <CheckBoxComponent
                      checked={hasSupersport}
                      disabled={pending}
                      label={fieldText.is_base_plus.title}
                      onChange={({ target: { checked } }) => {
                        toggleHasSupersport(checked)
                        form.setFieldValue(
                          fieldText.is_base_plus.field,
                          checked
                        )
                      }}
                    />
                  </Form.Item>
                </div>
                <div
                  className={`Rectangle ${
                    isMobile ? `MarginBottom16` : `MarginBottom24`
                  }`}
                />
                <div className="SportsBroadcast__ModalWrapper_FormItem_Button">
                  <Form.Item>
                    <ButtonPrimary loading={pending} htmlType="submit">
                      {isEdit ? fieldText.save : fieldText.add}
                    </ButtonPrimary>
                  </Form.Item>
                </div>
              </div>
            </div>
          ) : (
            <div className="FlexColumn Gap24 Padding16">
              <div className="GridCenter HeadlineSecondText">
                {isEdit ? successText.edit_title : successText.title}
              </div>
              <div className="GridCenter">
                <SuccessIcon />
              </div>
              <div className="GridCenter">
                <ButtonPrimary
                  onClick={() => {
                    closeSuccess()
                  }}
                >
                  {successText.button}
                </ButtonPrimary>
              </div>
            </div>
          )}
        </ModalFormComponent>
      ) : (
        <ModalFormComponent width={480}>
          <div className="FlexColumn Gap16 Padding8">
            <div className="HeadlineSecondText">{cancelEditText.title}</div>
            <div className="TextTwo">{cancelEditText.description}</div>
            <div className="FlexRow PaddingTop8 Gap8 MarginLeftAuto">
              <ButtonNotifi
                onClick={() => toggleCloseModal(false)}
                color="gray"
              >
                {cancelEditText.cancel}
              </ButtonNotifi>
              <ButtonNotifi onClick={close} color="red">
                {cancelEditText.approve}
              </ButtonNotifi>
            </div>
          </div>
        </ModalFormComponent>
      )}
    </Form>
  )
}

export default SportsBroadcastModal
