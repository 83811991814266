import { useSelector } from "react-redux"
import { getCurrentHall } from "store/halls/selector"
import { useRequest } from "api/hooks"
import { io } from "socket.io-client"

const useChatApi = () => {
  const hallState = useSelector(getCurrentHall)
  const hallLs = JSON.parse(localStorage.getItem("hall") || "")
  const currentHall = hallState?.length > 0 ? hallState : hallLs

  const { request: getAllMessages } = useRequest({
    url: `${currentHall}`,
    method: `GET`,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customRootUrl: "https://chat.bingo.zone/api/messages"
  })

  return {
    getAllMessages
  }
}

export const allowedTypes = ".pdf,.jpg,.jpeg,.png"

export const socketGuidebook = () => {
  const userFromStorage: string = localStorage.getItem(`user`) || ``
  const user: { token: string } | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage

  const hallState = useSelector(getCurrentHall)
  const hallLs = JSON.parse(localStorage.getItem("hall") || "")
  const currentHall = hallState?.length > 0 ? hallState : hallLs

  return io("wss://chat.bingo.zone", {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    query: `hall=${currentHall[0]}`,
    extraHeaders: {
      token: user ? user.token : ``
    }
  })
}

export default useChatApi
