import React, { useEffect, useState } from "react"

import { useRequest } from "api/hooks"

import { isMobile } from "constants/index"

import { CheckBoxComponent } from "Components/CheckBox"
import { CustomComponentProps } from "Components/SideFilter/types"

import { filterText } from "../constants"

const SuperwinHistoryNominalsFilter = ({
  filter,
  setFilter,
  forceApply
}: CustomComponentProps) => {
  const [allNominals, setAllNominals] = useState<{
    checked: string[]
    all: boolean
    allValues: string[]
    firstApply: boolean
  }>({ checked: [], all: false, allValues: [], firstApply: true })

  const requestHall = Number(filter.hall || filter.halls?.[0])
  const { request: getInitialNominals } = useRequest({
    url: `v1/superwins/nominals`,
    requestBody: { hall: requestHall }
  })

  useEffect(() => {
    if (filter.hall) {
      getInitialNominals().then(({ data }) => {
        const responseNominals = (data || []) as number[]
        const initialNominals = responseNominals?.map(String)
        setAllNominals({
          ...allNominals,
          checked: initialNominals,
          all: true,
          allValues: initialNominals
        })
      })
    }
  }, [filter.hall])

  useEffect(() => {
    const numberNominals = allNominals.checked.map(Number)
    setFilter({ ...filter, nominals: numberNominals })
    if (allNominals.firstApply) {
      const initialNominals = (allNominals.allValues || []).map(item =>
        Number(item)
      )
      if (!!forceApply && allNominals.firstApply && !isMobile) {
        forceApply({ ...filter, nominals: initialNominals })
        setAllNominals({
          ...allNominals,
          firstApply: false
        })
      }
    }
  }, [allNominals.checked?.toString(), allNominals.all, allNominals.firstApply])
  return (
    <div className="PaddingBottom16">
      <div className="PaddingBottom10 FlexRow Gap8">
        <div className="SubtitleFirstText">{filterText.nominals}</div>
        <div className="Gray500Color SubtitleFirstText PaddingTop1">
          {filter.nominals?.length || ``}
        </div>
      </div>
      <CheckBoxComponent
        label={filterText.all}
        checked={allNominals.all}
        onChange={({ target: { checked } }) => {
          setAllNominals({
            ...allNominals,
            checked: checked ? allNominals.allValues : [],
            all: checked
          })
        }}
      />
      <div className="CheckListFilterScroll FlexColumn Gap10 PaddingTop10">
        {allNominals.allValues?.map(item => {
          const isChecked = allNominals.checked.includes(item)
          return (
            <div key={`item_${item}`} className="CheckListFilterItem">
              <CheckBoxComponent
                label={`${item} ₽`}
                checked={isChecked}
                onChange={({ target: { checked } }) => {
                  const newList = allNominals.checked
                  if (!checked) {
                    setAllNominals({
                      ...allNominals,
                      checked: allNominals.checked.filter(
                        listItem => listItem !== item
                      ),
                      all: false
                    })
                  }
                  if (checked) {
                    newList.push(item)
                    setAllNominals({
                      ...allNominals,
                      checked: newList,
                      all: newList.length === allNominals.allValues.length
                    })
                  }
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SuperwinHistoryNominalsFilter
