import React, { useEffect, useMemo, useRef, useState } from "react"

import { useRequest, useRequestParams } from "api/hooks"
import { formatToCurrency, parseOneDateWithoutGmtToBack } from "constants/index"

import moment from "moment"

import HighchartsReact from "highcharts-react-official"
import * as HighchartsOne from "highcharts"
import Highcharts from "highcharts/highstock"

import { SelectComponent } from "Components/Select"
import EmptyTable from "Components/EmptyTable"

import { ChartTypeComponent, ChartType } from "../../Components/chartType"

import {
  periodOptions,
  heightGradient,
  lowGradient,
  tooltipLabels
} from "./constants"
import {
  ChartPeriod,
  GuestHistoryCashboxChartData,
  GuestHistoryCashboxChartProps,
  GuestHistoryCashboxChartState
} from "./types"

import {
  dataEmptyChart,
  dataWithoutPeriod
} from "Containers/GuestsHistory/constants"
import { useSelector } from "react-redux"
import { getCurrentHall } from "store/halls/selector"
import { getTransferHalls } from "store/dictionary/selector"

const GuestHistoryCashboxChart = ({
  filter
}: GuestHistoryCashboxChartProps) => {
  const { requestCurrentHall } = useRequestParams()
  const [period, setPeriod] = useState<ChartPeriod>(ChartPeriod.daily)

  const [inActive, toggleInActive] = useState(true)
  const [outActive, toggleOutActive] = useState(true)
  const [attendanceActive, toggleAttendanceActive] = useState(false)
  const transferHalls = useSelector(getTransferHalls)
  const hall = useSelector(getCurrentHall)

  const [chartData, setChartData] = useState<GuestHistoryCashboxChartState>({
    pending: false,
    data: []
  })

  const { request: getChart } = useRequest({
    url: `v1/guests/history/cashbox/chart`,
    requestBody: {
      hall: requestCurrentHall,
      ...filter,
      type: period,
      dates: !!filter.dates
        ? parseOneDateWithoutGmtToBack(filter.dates)
        : undefined,
      games: undefined,
      includeFreebets: undefined
    },
    method: `POST`
  })

  const requestDeps = JSON.stringify({ ...filter, period })

  useEffect(() => {
    setChartData({ ...chartData, pending: true })
    getChart().then(({ data }) => {
      setChartData({ data, pending: false })
    })
  }, [requestDeps])

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const chartSecondChartRef = useRef<HighchartsReact.RefObject>(null)

  const { categories, series, attendance, parseData } = useMemo(() => {
    const categories = []
    const series = []
    const attendance = []

    const parseData: { [key: string]: any } = {}

    const seriesObj: any = {
      in: {
        color: "rgba(140, 233, 154, 1)",
        data: []
      },
      out: {
        color: "rgba(255, 168, 168, 1)",
        data: []
      },
      cross: {
        color: "rgba(255, 107, 107, 1)",
        data: []
      }
    }

    for (let key = 0; key < chartData.data.length; key++) {
      const currentData = chartData.data[key]

      const currentDate = currentData?.date || ``
      const currentMoment = moment(currentDate, `DD.MM.YYYY`)

      let dateX = ``

      if (period === ChartPeriod.daily) {
        dateX = currentDate?.slice(0, 5)
        categories.push(dateX)
      }
      if (period === ChartPeriod.weekly) {
        const start = currentMoment.format(`DD.MM`)
        const end = moment(currentDate, `DD.MM.YYYY`)
          .add(1, `week`)
          .format(`DD.MM`)
        dateX = `${start} - ${end}`
        categories.push(dateX)
      }
      if (period === ChartPeriod.monthly) {
        const date = currentMoment.format(`MMM`)
        dateX = date
        categories.push(date)
      }

      if (inActive && outActive) {
        if (currentData.in > currentData.out) {
          seriesObj.in.data.push(currentData.in)
          seriesObj.cross.data.push(currentData.out)
          seriesObj.out.data.push(currentData.out)
        } else {
          seriesObj.in.data.push(currentData.in)
          seriesObj.cross.data.push(currentData.in)
          seriesObj.out.data.push(currentData.out)
        }
      } else {
        if (inActive) seriesObj.in.data.push(currentData.in)
        if (outActive) seriesObj.out.data.push(currentData.out)
      }

      attendance.push(Number(currentData.attendance))
      parseData[`${dateX}`] = currentData
    }

    if (inActive) series.push(seriesObj.in)
    if (outActive) series.push(seriesObj.out)
    if (inActive && outActive) series.push(seriesObj.cross)

    return { categories, series, attendance, parseData }
  }, [chartData, period, inActive, outActive])

  const haveData = !!series[0]?.data.length
  const minWidth = haveData ? series[0]?.data.length * 50 : 0

  const highchartsOptionsLabels: any = {
    align: `center`,
    formatter: function() {
      const { value } = this
      const label = value
      const isWeekly = period === ChartPeriod.weekly

      const weeklyFirst = `${value}`?.slice(0, 5)
      const weeklySecond = `${value}`?.slice(8, 13)
      return !isWeekly
        ? `<div class="CaptionText" style="color: #868E96">` + label + `</div>`
        : `<div class="CaptionText" style="color: #868E96">` +
            weeklyFirst +
            `-` +
            `<br />` +
            weeklySecond +
            `</div>`
    }
  }

  const options: Highcharts.Options = {
    chart: {
      type: "column",
      scrollablePlotArea: {
        minWidth: minWidth
      },
      marginLeft: 50,
      marginRight: 0
    },
    navigator: {
      enabled: true,
      series: [
        {
          type: "column"
        }
      ]
    },
    title: {
      text: ""
    },
    xAxis: {
      categories,
      crosshair: {
        className: `ChartsCrosshair`,
        width: 1,
        dashStyle: `Dash`,
        color: `rgba(52, 199, 89, 1)`
      },
      tickWidth: 0,
      labels: highchartsOptionsLabels
    },
    yAxis: {
      crosshair: {
        width: 1,
        color: `#E9ECEF`
      },
      title: {
        text: ""
      },
      gridLineDashStyle: `Dash`,
      gridLineColor: `#F2F2F7`,
      gridLineWidth: 1,
      gridZIndex: 0,
      width: 200,
      plotLines: [
        {
          dashStyle: `Solid`,
          color: "#AEAEB2",
          value: 0,
          width: 1
        }
      ],
      labels: {
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this)
          const fullLabel = Number(
            label.replace(`k`, `000`).replace(`M`, `000000`)
          )
          if (!!fullLabel) {
            if (fullLabel < 1000) {
              return `${fullLabel}`
            } else if (fullLabel < 1000000) {
              return `${fullLabel / 1000}к`
            } else {
              return `${fullLabel / 1000000}кк`
            }
          }
          return label
        }
      }
    },
    legend: {
      shadow: false,
      enabled: false
    },
    tooltip: {
      outside: true,
      positioner: function(labelWidth, labelHeight, point) {
        const tooltipX = point.plotX - labelWidth / 2 + 32
        const tooltipY = point.plotY - labelHeight - 8
        return {
          x: tooltipX,
          y: tooltipY
        }
      },
      shared: true,
      borderRadius: 10,
      borderWidth: 0,
      useHTML: true,
      split: true,
      className: "InOutTooltip",
      formatter: function() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const pointData: GuestHistoryCashboxChartData = parseData[this.x]

        const date = moment(pointData.date, `DD.MM.YYYY`)
        const currentDay = moment(pointData.date, `DD.MM.YYYY`)

        let firstDate = ``
        switch (period) {
          case ChartPeriod.daily: {
            firstDate = `${currentDay.format(`dd`)}., ${currentDay.format(
              `DD MMMM YYYY`
            )}`
            break
          }
          case ChartPeriod.weekly: {
            const nextWeekDay = currentDay.add(1, `week`)
            const isSameMonth = nextWeekDay.month() === nextWeekDay.month()
            firstDate = isSameMonth
              ? `${date.format(`DD`)} - ${nextWeekDay.format(
                  `DD`
                )} ${date.format(`MMMM YYYY`)}`
              : `${date.format(`DD MMMM`)} - ${nextWeekDay.format(
                  `DD MMMM`
                )} ${date.format(`YYYY`)}`
            break
          }
          case ChartPeriod.monthly: {
            const isCurrentYear = currentDay.year() === moment().year()
            firstDate = isCurrentYear
              ? currentDay.format(`MMMM`)
              : currentDay.format(`MMMM YYYY`)
            break
          }
        }

        const isNullDay =
          !pointData.in && !pointData.out && !pointData.attendance

        if (!isNullDay) {
          const inValue = formatToCurrency({
            amount: pointData.in,
            withoutFixed: true
          })
          const outValue = formatToCurrency({
            amount: pointData.out,
            withoutFixed: true
          })
          const betsValue = formatToCurrency({
            amount: pointData.bets,
            withoutFixed: true
          })

          const transfer_in = formatToCurrency({
            amount: pointData.transfer_in,
            withoutFixed: true
          })

          const transferInField =
            `<div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
            tooltipLabels.transfer_in +
            `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
            transfer_in +
            ` ₽</span>
            </div>`

          const transfer_out = formatToCurrency({
            amount: pointData.transfer_out,
            withoutFixed: true
          })

          const transferOutField =
            `<div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
            tooltipLabels.transfer_out +
            `</span>
            <span class="BodyFirstText" style="color: #FFFFFF">` +
            transfer_out +
            ` ₽</span>
          </div>`

          const transfer =
            pointData.transfer_out !== undefined &&
            pointData.transfer_in !== undefined &&
            transferHalls.includes(Number(hall[0]))
              ? `<div style="display: flex; padding-bottom: 12px">
            ` +
                `${transferInField} ${transferOutField}` +
                `
          </div>`
              : ``

          return (
            `<div class="ChartTooltip">
            <div class="HeadlineThirdText" style="color: #FCE000; text-transform: capitalize; padding-bottom: 8px">` +
            firstDate +
            `</div>
          <div style="display: flex; padding-bottom: 12px">
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
            tooltipLabels.inValue +
            `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
            inValue +
            ` ₽</span>
            </div>
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
            tooltipLabels.outValue +
            `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
            outValue +
            ` ₽</span>
            </div>
          </div>
          <div style="display: flex; padding-bottom: 12px">
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
            tooltipLabels.betsValue +
            `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
            betsValue +
            ` ₽</span>
            </div>
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
            tooltipLabels.win +
            `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
            pointData.win +
            ` %</span>
            </div>
          </div>
          ` +
            transfer +
            `
          <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
            tooltipLabels.games +
            `</span>
                <span class="BodyFirstText" style="color: #FFFFFF; max-width: 328px">` +
            pointData.games.join(`, `) +
            `</span>
            </div>
          </div>`
          )
        }
      },
      backgroundColor: `#434345`
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
        borderRadius: 8,
        pointWidth: 45,
        point: {
          events: {
            mouseOver: function() {
              if (attendanceActive) {
                const index = chartComponentRef.current?.chart?.series[0]?.data?.findIndex(
                  item => item?.category === this?.category
                )
                if (!!index || index === 0) {
                  chartSecondChartRef.current?.chart?.series[0]?.data[
                    index
                  ]?.onMouseOver()
                }
              }
            }
          }
        }
      },
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, heightGradient || ``],
            [1, lowGradient || ``]
          ]
        },
        marker: {
          radius: 1,
          fillColor: `rgba(34, 184, 207, 1)`,
          states: {
            hover: {
              radius: 8
            }
          }
        },
        lineWidth: 2,
        lineColor: `rgba(34, 184, 207, 1)`,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        threshold: null
      }
    },
    series
  }

  const optionsArea: Highcharts.Options = {
    title: {
      text: ""
    },
    xAxis: {
      categories,
      crosshair: {
        className: `ChartsCrosshair`,
        width: 1,
        dashStyle: `Dash`,
        color: `rgba(59, 201, 219, 1)`
      },
      tickWidth: 0,
      labels: highchartsOptionsLabels
    },
    yAxis: {
      crosshair: {
        width: 1,
        color: `#E9ECEF`
      },
      title: {
        text: ""
      },
      gridLineDashStyle: `Dash`,
      gridLineColor: `#F2F2F7`,
      gridLineWidth: 1,
      gridZIndex: 0,
      plotLines: [
        {
          dashStyle: `Solid`,
          color: "#AEAEB2",
          value: 0,
          width: 1
        }
      ]
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, heightGradient || ``],
            [1, lowGradient || ``]
          ]
        },
        marker: {
          radius: 1,
          fillColor: `rgba(59, 201, 219, 1)`,
          states: {
            hover: {
              radius: 8
            }
          }
        },
        lineWidth: 2,
        lineColor: `rgba(59, 201, 219, 1)`,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        threshold: null
      }
    },
    tooltip: {
      outside: true,
      positioner: function(labelWidth, labelHeight, point) {
        const tooltipX = point.plotX - labelWidth / 2 + 32
        const tooltipY = point.plotY - labelHeight
        return {
          x: tooltipX,
          y: tooltipY
        }
      },
      borderRadius: 10,
      borderWidth: 0,
      useHTML: true,
      split: true,
      className: "AttendanceTooltip",
      formatter: function() {
        const pointData: GuestHistoryCashboxChartData = parseData[this.x]

        const label = `${pointData.attendance} посещений`

        return (
          `<div class="ChartTooltip">
            <div class="HeadlineThirdText" style="color: #FCE000;">` +
          label +
          `</div>
          </div>`
        )
      },
      backgroundColor: `#434345`
    },
    chart: {
      type: "areaspline",
      marginLeft: 48,
      scrollablePlotArea: {
        minWidth: minWidth,
        opacity: 0.5
      },
      height: 200
    },
    series: [
      {
        type: "areaspline",
        data: attendance
      }
    ]
  }

  useEffect(() => {
    const chart = chartComponentRef?.current?.chart
    const secondChart = chartSecondChartRef?.current?.chart

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const scrollContainerInOutChart = chart?.scrollingContainer

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const scrollContainerAttendanceChart = secondChart?.scrollingContainer

    if (!!chart && !!scrollContainerInOutChart) {
      scrollContainerInOutChart?.addEventListener(`scroll`, () => {
        if (scrollContainerAttendanceChart) {
          scrollContainerAttendanceChart.scrollLeft =
            scrollContainerInOutChart?.scrollLeft
        }
      })

      scrollContainerInOutChart.scrollLeft = 0

      scrollContainerAttendanceChart?.addEventListener(`scroll`, () => {
        if (scrollContainerAttendanceChart) {
          scrollContainerInOutChart.scrollLeft =
            scrollContainerAttendanceChart?.scrollLeft
        }
      })
    }
  }, [series, attendanceActive])

  const emptyDataSource = !filter.dates ? dataWithoutPeriod : dataEmptyChart

  return !!chartData.data.length ? (
    <div>
      <div className="GuestHistoryCashboxChartsHeader FlexRow">
        <div className="GuestHistoryCashboxChartsPeriod">
          <SelectComponent
            options={periodOptions}
            value={period}
            onChange={value => {
              setPeriod(value)
              if (value === ChartPeriod.daily && attendanceActive) {
                toggleAttendanceActive(false)
              }
            }}
            allowClear={false}
            greyBackground={true}
          />
        </div>
        <div className="GuestHistoryCashboxChartsTypes">
          <ChartTypeComponent
            isActive={inActive}
            type={ChartType.in}
            switchActive={() => {
              if (!inActive || outActive) {
                toggleInActive(!inActive)
              }
            }}
          />
          <ChartTypeComponent
            isActive={outActive}
            type={ChartType.out}
            switchActive={() => {
              if (inActive || !outActive) {
                toggleOutActive(!outActive)
              }
            }}
          />
          {period !== ChartPeriod.daily && (
            <ChartTypeComponent
              isActive={attendanceActive}
              type={ChartType.attendance}
              switchActive={() => {
                toggleAttendanceActive(!attendanceActive)
              }}
            />
          )}
        </div>
      </div>
      <div className="GuestHistoryCashboxChartsWrapper">
        <div className={`${attendanceActive ? `HideScrollHighcharts` : ``}`}>
          <HighchartsReact
            ref={chartComponentRef}
            highcharts={HighchartsOne}
            options={options}
          />
        </div>
        {attendanceActive && (
          <div
            style={{ display: attendanceActive ? `` : `none` }}
            className="GuestHistoryCashboxChartsWrapperAttendance"
          >
            <HighchartsReact
              constructorType={""}
              ref={chartSecondChartRef}
              highcharts={HighchartsOne}
              options={optionsArea}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="GuestHistoryEmptyDataWrapper">
      <EmptyTable
        mobile={emptyDataSource.mobile}
        desktop={emptyDataSource.desktop}
      />
    </div>
  )
}

export default GuestHistoryCashboxChart
