import React from "react"
import { Select } from "antd"
import { ReactComponent as ClockIcon } from "./clock_select.svg"

export const hoursOptions = [
  { value: 0, label: `00:00` },
  { value: 1, label: `01:00` },
  { value: 2, label: `02:00` },
  { value: 3, label: `03:00` },
  { value: 4, label: `04:00` },
  { value: 5, label: `05:00` },
  { value: 6, label: `06:00` },
  { value: 7, label: `07:00` },
  { value: 8, label: `08:00` },
  { value: 9, label: `09:00` },
  { value: 10, label: `10:00` },
  { value: 11, label: `11:00` },
  { value: 12, label: `12:00` },
  { value: 13, label: `13:00` },
  { value: 14, label: `14:00` },
  { value: 15, label: `15:00` },
  { value: 16, label: `16:00` },
  { value: 17, label: `17:00` },
  { value: 18, label: `18:00` },
  { value: 19, label: `19:00` },
  { value: 20, label: `20:00` },
  { value: 21, label: `21:00` },
  { value: 22, label: `22:00` },
  { value: 23, label: `23:00` }
]

type SelectHoursProps = {
  from: number
  to: number
  disabled: boolean
  onChange: (value: [number, number]) => void
}

const SelectHours = ({ from, to, onChange }: SelectHoursProps) => {
  return (
    <div className="SelectHoursWrapper FlexRow">
      <Select
        className="HourSelector"
        dropdownClassName="HourSelectorDropdownWrapper"
        options={hoursOptions}
        value={from}
        onChange={(value: number) => onChange([value, to])}
        suffixIcon={<ClockIcon />}
      />
      <Select
        className="HourSelector"
        dropdownClassName="HourSelectorDropdownWrapper"
        options={hoursOptions}
        value={to}
        onChange={(value: number) => onChange([from, value])}
        suffixIcon={<ClockIcon />}
      />
    </div>
  )
}

export default SelectHours
