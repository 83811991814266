import React, { useEffect, useState } from "react"

import { useRequest } from "api/hooks"

import DatePickerComponent from "Components/DatePicker"
import { Durations, PickerFormats } from "Components/FilterInfoPanel/types"

import { CustomDateFilterProps, EnabledDatesState } from "./types"
import { topThreeText, getCurrenHallFromFilter } from "./constants"
import moment, { Moment } from "moment"

const CustomDateFilter = ({ filter, setFilter }: CustomDateFilterProps) => {
  const currentHall = getCurrenHallFromFilter(filter)

  const [enabledDates, setEnabledDates] = useState<EnabledDatesState>({
    pending: false,
    data: []
  })

  const { request: geAvailableDates } = useRequest({
    url: `v1/promotions/top3/filters/config`,
    requestBody: { hall: currentHall }
  })

  const disabledDateDefault = (current: Moment) =>
    current &&
    (current > moment().endOf(Durations.day) ||
      !enabledDates.data.includes(current.format(PickerFormats.dayMonthYear)))

  useEffect(() => {
    setEnabledDates({ ...enabledDates, pending: true })
    geAvailableDates()
      .then(({ data }: { data: { enabled_dates: string[] } }) => {
        const enabledList = (data?.enabled_dates || []).map(item => {
          return moment(item, PickerFormats.dateShortFromBack).format(
            PickerFormats.dayMonthYear
          )
        })
        setFilter({ ...filter, date: undefined })
        setEnabledDates({ pending: false, data: enabledList })
      })
      .catch(() => setEnabledDates({ ...enabledDates, pending: false }))
  }, [currentHall])

  return (
    <div className="FlexColumn Gap8">
      <div className="SubtitleFirstText">{topThreeText.dateFilter}</div>
      <DatePickerComponent
        disabled={enabledDates.pending || !enabledDates.data.length}
        disabledDate={disabledDateDefault}
        value={filter.date}
        format={PickerFormats.dayMonthYear}
        onChange={date => setFilter({ ...filter, date })}
      />
    </div>
  )
}

export default CustomDateFilter
