import React from "react"
import { ErrorsContainerProps } from "./types"
import { raceText } from "./constants"
import { ReactComponent as AlarmIcon } from "Components/icons/illustrations/alarm_dude_large.svg"
import { ReactComponent as LaptopIcon } from "Components/icons/illustrations/laptop.svg"
import { ReactComponent as NotFoundIcon } from "Components/icons/illustrations/not_found.svg"

export const ErrorsContainer: React.FC<ErrorsContainerProps> = ({
  tableData
}) => {
  return (
    <>
      {tableData.isGuestNotFound && (
        <div className="RaceErrContainer">
          <NotFoundIcon />
          <div className="RaceErrContainerTextMain">
            {raceText.guestInfoNotFoundTitle}
          </div>
          <div className="SubtitleSecondText">{raceText.guestInfoNotFound}</div>
        </div>
      )}
      {tableData.isPhoneEmpty && (
        <div className="RaceErrContainer">
          <LaptopIcon />
          <div className="SubtitleSecondText">{raceText.guestInfoStart}</div>
        </div>
      )}
      {tableData.isGuestInfoEmpty && (
        <div className="RaceErrContainer">
          <AlarmIcon />
          <div className="RaceErrContainerTextMain">
            {raceText.guestInfoEmptyTitle}
          </div>
          <div className="SubtitleSecondText">{raceText.guestInfoEmpty}</div>
        </div>
      )}
    </>
  )
}
