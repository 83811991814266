import React, { useState } from "react"
import { useSelector } from "react-redux"

import { Checkbox } from "antd"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

import { getGamesList } from "store/dictionary/selector"
import { CheckBoxComponent } from "Components/CheckBox"

const CheckboxGroup = Checkbox.Group

const GamesList = ({
  filterGames,
  setFilterGames
}: {
  filterGames: (string | number)[]
  setFilterGames: (data: (string | number)[]) => void
}) => {
  const games = useSelector(getGamesList)

  const allIds = games.map(item => item.id)

  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(filterGames.length === games.length)

  const options: { value: number | string; label: string }[] = games.map(
    item => ({
      value: item.id,
      label: item.name
    })
  )

  const onCheckAllChange = (e: any) => {
    setFilterGames(e.target.checked ? allIds : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }
  return (
    <div>
      <div className="GamesListHeader">Игры</div>
      <div className="GamesListFilterCheckboxAllWrapper">
        <CheckBoxComponent
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        />
        <div className="GamesListFilterCheckboxAllLabel">Выбрать все</div>
      </div>
      <CheckboxGroup
        className="GamesListFilterCheckboxGroupWrapper"
        options={options}
        value={filterGames}
        onChange={(list: (number | CheckboxValueType)[]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFilterGames(list)
          setIndeterminate(!!list.length && list.length < games.length)
          setCheckAll(list.length === games.length)
        }}
      />
    </div>
  )
}

export default GamesList
