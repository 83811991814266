export const selectHoursOptions = () => {
  let options: Record<string, unknown>[] = []

  for (let i = 0; i <= 23; i++) {
    const hour = {
      value: i > 9 ? `${i}:00` : `0${i}:00`,
      label: i > 9 ? `${i}:00` : `0${i}:00`
    }
    const hourAndHalf = {
      value: i > 9 ? `${i}:30` : `0${i}:30`,
      label: i > 9 ? `${i}:30` : `0${i}:30`
    }
    options = [...options, hour, hourAndHalf]
  }

  return options
}
