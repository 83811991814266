import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useRequest, useRequestBody, useRequestParams } from "api/hooks"
import { initialFilterStateCreator } from "./helpers"
import {
  OfficialSmsColumns,
  OfficialSmsFilter,
  OfficialSmsReport
} from "./types"
import { getColumns, officialSmsConstants } from "./constants"
import { Filters } from "Components/SideFilter/types"
import SideFilter from "Components/SideFilter"
import ReportWithFilter from "Components/ReportWithFilter"
import NotActiveFilter from "Components/NotActiveFilter"
import Title from "Components/Title"
import TableNew from "Components/TableNew"
import { Sorter, SortOrders } from "Components/Table/types"
import { sortForTable } from "Components/Table"
import { ReactComponent as NotActiveFilterIcon } from "./img/notActiveFilter.svg"
import { getDateDescriptionLayout, isMobile } from "../../constants"

const OfficialSms = () => {
  const { current_hall } = useRequestParams()

  const {
    pagination,
    setPagination,
    setSort,
    sort,
    resetPagination
  } = useRequestBody()

  const initState = initialFilterStateCreator(current_hall[0] as number)

  const [filterState, setFilterState] = useState<OfficialSmsFilter>(initState)

  const setFilterStateCb = useCallback(value => setFilterState(value), [])

  const [reportData, setReportData] = useState<OfficialSmsReport>({
    pending: false,
    total: 0,
    data: []
  })

  const initRequestBody = {
    filter: {
      hall: filterState.data.halls[0]
    },
    sort: {
      field: sort.order ? sort.field : OfficialSmsColumns.date,
      order: sort.order ? sort.order : SortOrders.desc
    },
    pagination
  }

  const { request } = useRequest({
    url: officialSmsConstants.url,
    method: `POST`,
    requestBody: filterState.data.dates
      ? {
          ...initRequestBody,
          filter: {
            ...initRequestBody.filter,
            dates: [
              filterState.data.dates.from.format(
                officialSmsConstants.dateFormat
              ),
              filterState.data.dates.to.format(officialSmsConstants.dateFormat)
            ]
          }
        }
      : initRequestBody
  })

  useEffect(() => {
    if (!filterState.data.dates) return

    setReportData({ ...reportData, pending: true })

    request()
      .then(({ data }: any) => {
        setReportData({
          ...reportData,
          pending: false,
          total: data.totalCount,
          data: data.items.length ? [data.summary, ...data.items] : []
        })
      })
      .catch(() => {
        setReportData({ ...reportData, pending: false })
      })
  }, [
    filterState.data.halls,
    pagination.offset,
    pagination.limit,
    sort,
    filterState.data.dates,
    filterState.isActive
  ])

  const onChange = (pagination: any, filters: any, sorter: Sorter) =>
    sortForTable({ sorter, setSort })

  const titleDescription = useMemo(() => {
    if (!!filterState.data.dates) {
      return getDateDescriptionLayout({
        dates: [filterState.data.dates.from, filterState.data.dates.to],
        shift: [8, 20],
        hideTime: true,
        isShift: false,
        show: filterState.isActive
      })
    }
    return ``
  }, [filterState.data.dates])

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={officialSmsConstants.OFFICIAL_SMS_FILTER}
            filterState={filterState}
            setFilterState={setFilterStateCb}
            resetPagination={resetPagination}
            filters={[Filters.rangePicker, Filters.hallPickerRadio]}
            getDisabled={filter => !filter?.dates}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <>
            {filterState.isActive && (
              <div>
                <Title
                  trigger={isMobile ? officialSmsConstants.clickEvent : ``}
                  direction={officialSmsConstants.tooltipDirection}
                  titleText={officialSmsConstants.title}
                  tooltipText={officialSmsConstants.mainTooltip}
                  wrapperClass={`OfficialSmsTitleWrapper`}
                  bottomAdditionalCmp={<div>{titleDescription}</div>}
                />
                <TableNew
                  tableClassName={`OfficialSmsTable`}
                  onChange={onChange}
                  withTotal={true}
                  columns={getColumns(sort)}
                  data={reportData.data}
                  loading={reportData.pending}
                  scroll={officialSmsConstants.scroll}
                  pagination={{
                    value: pagination,
                    total: reportData.total,
                    onChange: setPagination
                  }}
                />
              </div>
            )}

            {!filterState.isActive && (
              <NotActiveFilter
                title={officialSmsConstants.nonActiveTitle}
                description={officialSmsConstants.nonActiveDescription}
                icon={<NotActiveFilterIcon />}
              />
            )}
          </>
        }
      />
    </>
  )
}

export default OfficialSms
