import { QiwiFilter } from "../types"

import { CrudModalState } from "../Modal/types"

export enum QiwiTableFields {
  id = `id`,
  date = `created_date`,
  type = `type`,
  halls = `halls`,
  hall = `hall`,
  name = `name`,
  comment = `comment`,
  phone = `phone`,
  email = `email`,
  sum = `requested_sum`,
  form_sum = `sum`,
  approved_sum = `approved_sum`,
  status = `status`,
  status_reason = `status_reason`
}

export enum QiwiTableStatus {
  new = `new`,
  approved = `approved`,
  canceled = `canceled`,
  rejected = `rejected`
}

export enum QiwiTableTypes {
  replenishment = `replenishment_balance`,
  order = `acquiring_order`,
  replenishment_yandex_taxi = `replenishment_yandex_taxi`
}

export type QiwiTableData = {
  [QiwiTableFields.id]: number
  [QiwiTableFields.date]: string
  [QiwiTableFields.email]: string
  [QiwiTableFields.halls]: number
  [QiwiTableFields.status]: QiwiTableStatus
  [QiwiTableFields.phone]: string
  [QiwiTableFields.name]: string
  [QiwiTableFields.sum]: number
  [QiwiTableFields.form_sum]: string
  [QiwiTableFields.comment]: string
  [QiwiTableFields.type]: QiwiTableTypes
  [QiwiTableFields.status_reason]: string
}

export type QiwiTableState = {
  data: QiwiTableData[]
  total: number
}

export type QiwiOrderTableProps = {
  reqData: QiwiFilter
  setReqData: (data: QiwiFilter) => void
  qiwiPending: boolean
  setQiwiPending: (data: boolean) => void
  crudModal: CrudModalState
  setCrudModal: (data: CrudModalState) => void
}

export interface ColumnsArg {
  reqData: QiwiFilter
  setReqData: (val: QiwiFilter) => void
  setCrudModal: (data: any) => void
  setCancelModal: (id: number) => void
}
