import React from "react"
import {
  CreateColumnsProps,
  FreeBetsDetailProps,
  GuestBalanceFilter,
  GuestBalanceType
} from "./types"
import { getFilterStateFromLocalStorage } from "api/helpers"
import { guestBalanceConsts } from "./constants"
import { FromToFilterSetterProps } from "Components/TableNew/TableFilters/FromToFilter/types"
import { TableHeader } from "Components/TableNew"
import { FromToFilter } from "Components/TableNew/TableFilters/FromToFilter"
import { UseRequestBodyPagination } from "api/hooks"
import { ReactComponent as RubleIcon } from "Components/icons/money_circle_outline.svg"
import { ReactComponent as BonusIcon } from "Components/icons/bonus_circle_outline.svg"
import { ReactComponent as FreeBetIcon } from "Components/icons/freebet_circle_outline.svg"
import { ReactComponent as InfoGrayIcon } from "Components/icons/info.svg"
import { ReactComponent as InfoColorIcon } from "Components/icons/info_color.svg"

export const createRequestBody = (
  filterState: GuestBalanceFilter,
  pagination: UseRequestBodyPagination
) => {
  const initRequestBody = {
    filter: {
      hall: Number(filterState.data.halls[0]),
      zeroBalance: filterState.data.zeroBalance
    },
    sort:
      filterState.data.sort.field && filterState.data.sort.order
        ? {
            field: filterState.data.sort.field,
            order: filterState.data.sort.order
          }
        : undefined,
    pagination
  }

  const bonusEl = filterState.data.balance[GuestBalanceType.bonus]
  const moneyEl = filterState.data.balance[GuestBalanceType.money]
  const freeBetCountEl = filterState.data.balance[GuestBalanceType.freeBetCount]
  const freeBetSumEl = filterState.data.balance[GuestBalanceType.freeBetSum]

  const searchElements = [
    bonusEl,
    moneyEl,
    freeBetCountEl,
    freeBetSumEl
  ].filter(i => i && i.field)

  let balance = {}

  searchElements.forEach(el => {
    balance = {
      ...balance,
      [el.field as string]: {
        from: el.from,
        to: el.to
      }
    }
  })

  return {
    ...initRequestBody,
    filter: {
      ...initRequestBody.filter,
      balance
    }
  }
}

export const initialFilterStateCreator = (
  current_hall: number
): GuestBalanceFilter => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {
      halls: [current_hall],
      zeroBalance: true,
      balance: {},
      sort: {}
    },
    guestBalanceConsts.GUEST_BALANCE_FILTER
  )

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: false,
    data: {
      ...initFilterStateFromStorage
    }
  }
}

const setter = (
  filterState: GuestBalanceFilter,
  setFilterState: (value: GuestBalanceFilter) => void,
  resetPagination: () => void
) => {
  return (searchState: FromToFilterSetterProps) => {
    setFilterState({
      ...filterState,
      data: {
        ...filterState.data,
        balance: {
          ...filterState.data.balance,
          [searchState.filerField as string]: {
            field: searchState.filerField,
            from: searchState.from,
            to: searchState.to
          }
        },
        sort: {
          field: searchState.sortField as GuestBalanceType,
          order: searchState.order
        }
      }
    })
    resetPagination()
  }
}

const moneyRender = (value: string) =>
  new Intl.NumberFormat("ru-RU").format(parseFloat(value))

export const createColumns = ({
  reportData,
  filterState,
  setFilterState,
  resetPagination,
  freeBetsDetailState,
  setFreeBetsDetailState,
  initialLimits
}: CreateColumnsProps) => {
  const moneyEl = filterState.data.balance[GuestBalanceType.money]
  const moneyField = moneyEl?.field
  const bonusEl = filterState.data.balance[GuestBalanceType.bonus]
  const bonusField = bonusEl?.field
  const freeBetCountEl = filterState.data.balance[GuestBalanceType.freeBetCount]
  const freeBetCountField = freeBetCountEl?.field
  const freeBetSumEl = filterState.data.balance[GuestBalanceType.freeBetSum]
  const freeBetSumField = freeBetSumEl?.field

  const moneyFrom =
    moneyField === GuestBalanceType.money
      ? moneyEl.from
      : reportData.data.limits?.money.min

  const moneyTo =
    moneyField === GuestBalanceType.money && moneyEl.to
      ? moneyEl.to
      : reportData.data.limits?.money.max

  const bonusFrom =
    bonusField === GuestBalanceType.bonus
      ? bonusEl.from
      : reportData.data.limits?.bonus.min

  const bonusTo =
    bonusField === GuestBalanceType.bonus && bonusEl.to
      ? bonusEl.to
      : reportData.data.limits?.bonus.max

  const freeBetCountFrom =
    freeBetCountField === GuestBalanceType.freeBetCount
      ? freeBetCountEl.from
      : reportData.data.limits?.freeBetCount.min

  const freeBetCountTo =
    freeBetCountField === GuestBalanceType.freeBetCount && freeBetCountEl.to
      ? freeBetCountEl.to
      : reportData.data.limits?.freeBetCount.max

  const freeBetSumFrom =
    freeBetSumField === GuestBalanceType.freeBetSum
      ? freeBetSumEl.from
      : reportData.data.limits?.freeBetSum.min

  const freeBetSumTo =
    freeBetSumField === GuestBalanceType.freeBetSum && freeBetSumEl.to
      ? freeBetSumEl.to
      : reportData.data.limits?.freeBetSum.max

  const isActiveField = (
    filterState: GuestBalanceFilter,
    fieldName: GuestBalanceType
  ): boolean => {
    const isSortedByCurrentField = filterState.data.sort.field === fieldName

    const isFilteredByCurrentField =
      filterState.data.balance[fieldName]?.field === fieldName &&
      ((filterState.data.balance[fieldName]?.from !== undefined &&
        filterState.data.balance[fieldName]?.from !==
          reportData.data.limits[fieldName]?.min) ||
        (filterState.data.balance[fieldName]?.to !== undefined &&
          filterState.data.balance[fieldName]?.to !==
            reportData.data.limits[fieldName]?.max))

    return isSortedByCurrentField || isFilteredByCurrentField
  }

  return [
    {
      title: guestBalanceConsts.walletsTitle,
      dataIndex: `walletId`,
      className: "TableHeaderWithoutSpacing",
      showSorterTooltip: true,
      key: `walletId`,
      width: 104,
      minWidth: 104
    },
    {
      dataIndex: GuestBalanceType.money,
      showSorterTooltip: true,
      key: GuestBalanceType.money,
      width: 104,
      minWidth: 104,
      render: moneyRender,
      title: () => {
        return (
          <TableHeader
            isActiveField={isActiveField(filterState, GuestBalanceType.money)}
            title={guestBalanceConsts.moneyTitle}
          >
            <FromToFilter
              suffix={<RubleIcon />}
              fieldName={GuestBalanceType.money}
              title={guestBalanceConsts.moneyTitle}
              min={reportData.data.limits?.money.min}
              max={reportData.data.limits?.money.max}
              from={moneyFrom}
              to={moneyTo}
              currentSort={filterState.data.sort}
              defaultSort={guestBalanceConsts.defaultSort}
              defaultFilter={{
                ...guestBalanceConsts.defaultBalance,
                from: initialLimits?.money.min,
                to: initialLimits?.money.max
              }}
              currentFilter={filterState.data.balance[GuestBalanceType.money]}
              setter={setter(filterState, setFilterState, resetPagination)}
            />
          </TableHeader>
        )
      }
    },
    {
      dataIndex: GuestBalanceType.bonus,
      showSorterTooltip: true,
      key: GuestBalanceType.bonus,
      width: 104,
      minWidth: 104,
      render: moneyRender,
      title: () => (
        <TableHeader
          title={guestBalanceConsts.bonusTitle}
          isActiveField={isActiveField(filterState, GuestBalanceType.bonus)}
        >
          <FromToFilter
            suffix={<BonusIcon />}
            fieldName={GuestBalanceType.bonus}
            title={guestBalanceConsts.bonusTitle}
            min={reportData.data.limits?.bonus.min}
            max={reportData.data.limits?.bonus.max}
            from={bonusFrom}
            to={bonusTo}
            currentSort={filterState.data.sort}
            defaultSort={guestBalanceConsts.defaultSort}
            defaultFilter={{
              ...guestBalanceConsts.defaultBalance,
              from: initialLimits?.bonus.min,
              to: initialLimits?.bonus.max
            }}
            currentFilter={filterState.data.balance[GuestBalanceType.bonus]}
            setter={setter(filterState, setFilterState, resetPagination)}
          />
        </TableHeader>
      )
    },
    {
      dataIndex: GuestBalanceType.freeBetCount,
      showSorterTooltip: true,
      key: GuestBalanceType.freeBetCount,
      width: 104,
      minWidth: 104,
      title: () => (
        <TableHeader
          title={guestBalanceConsts.freeBetCountTitle}
          isActiveField={isActiveField(
            filterState,
            GuestBalanceType.freeBetCount
          )}
        >
          <FromToFilter
            suffix={<FreeBetIcon />}
            fieldName={GuestBalanceType.freeBetCount}
            title={guestBalanceConsts.freeBetCountTitle}
            min={reportData.data.limits?.freeBetCount.min}
            max={reportData.data.limits?.freeBetCount.max}
            from={freeBetCountFrom}
            to={freeBetCountTo}
            currentSort={filterState.data.sort}
            defaultSort={guestBalanceConsts.defaultSort}
            defaultFilter={{
              ...guestBalanceConsts.defaultBalance,
              from: initialLimits?.freeBetCount.min,
              to: initialLimits?.freeBetCount.max
            }}
            currentFilter={
              filterState.data.balance[GuestBalanceType.freeBetCount]
            }
            setter={setter(filterState, setFilterState, resetPagination)}
          />
        </TableHeader>
      ),
      render: (value: string, record: FreeBetsDetailProps) => {
        return (
          <div
            className={value ? "guestsBalance_freeBetCountClickable" : ""}
            onClick={() => {
              value &&
                setFreeBetsDetailState({
                  ...freeBetsDetailState,
                  walletId: record.walletId,
                  freeBetCount: record.freeBetCount,
                  freeBetSum: record.freeBetSum,
                  isOpen: true
                })
            }}
          >
            {value ? (
              <InfoColorIcon className="guestsBalanceFreeBetCountIcon" />
            ) : (
              <InfoGrayIcon className="guestsBalanceFreeBetCountIcon" />
            )}
            {moneyRender(value)}
          </div>
        )
      }
    },
    {
      dataIndex: GuestBalanceType.freeBetSum,
      showSorterTooltip: true,
      key: GuestBalanceType.freeBetSum,
      width: 104,
      minWidth: 104,
      render: moneyRender,
      title: () => (
        <TableHeader
          title={guestBalanceConsts.freeBetSumTitle}
          isActiveField={isActiveField(
            filterState,
            GuestBalanceType.freeBetSum
          )}
        >
          <FromToFilter
            suffix={<FreeBetIcon />}
            fieldName={GuestBalanceType.freeBetSum}
            title={guestBalanceConsts.freeBetSumTitle}
            min={reportData.data.limits?.freeBetSum.min}
            max={reportData.data.limits?.freeBetSum.max}
            from={freeBetSumFrom}
            to={freeBetSumTo}
            currentSort={filterState.data.sort}
            defaultSort={guestBalanceConsts.defaultSort}
            defaultFilter={{
              ...guestBalanceConsts.defaultBalance,
              from: initialLimits?.freeBetSum.min,
              to: initialLimits?.freeBetSum.max
            }}
            currentFilter={
              filterState.data.balance[GuestBalanceType.freeBetSum]
            }
            setter={setter(filterState, setFilterState, resetPagination)}
          />
        </TableHeader>
      )
    }
  ]
}
