import React, { useState } from "react"
import { isEmpty } from "lodash"
import noop from "lodash/noop"
import moment from "moment"
import { getFilterStateFromLocalStorage } from "api/helpers"
import insurancesConstants from "./constants"
import { UseRequestBodySort } from "api/hooks"
import {
  formatPhone,
  formatToCurrency,
  gameKindsTranslator,
  isLaptop,
  isMobile
} from "constants/index"
import {
  GamesInsuranceData,
  GamesList,
  GetColumnsProps,
  InsurancesColumns,
  InsurancesFilter,
  InsurancesReportItem,
  InsuranceStatus,
  InsurancesWinners,
  InsurancesWinnersColumns,
  InsuranceTranslateStatus
} from "./types"
import { ReactComponent as CupIcon } from "Components/icons/cup_outline_24.svg"

export const initialFilterStateCreator = (
  current_hall: number
): InsurancesFilter => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    insurancesConstants.INSURANCES_FILTER,
    true
  )

  const initFilter = {
    halls: [Number(current_hall)],
    ...initFilterStateFromStorage
  }

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      ...initFilter
    }
  }
}

export const getActiveClasses = (
  fieldName: InsurancesColumns,
  sort: UseRequestBodySort
) => (fieldName === sort.field && !!sort.order ? `ActiveSortTableColumn` : ``)

const formatToDate = (time: string) =>
  !!time ? moment(time).format("DD.MM.YYYY") : ""

const formatToTime = (time: string) =>
  !!time ? moment(time).format("HH:mm") : ""

const renderDate = (value: string) => (
  <div className="InsurancesDateTimeCell">
    <div>{formatToDate(value)}</div>
    <div className="InsurancesTime">{formatToTime(value)}</div>
  </div>
)

const GamesListComponent = ({ games, gamesDict }: GamesList) => {
  const [showAllState, setShowAllState] = useState<boolean>(false)

  let minLength = 4
  if (isLaptop) {
    minLength = 4
  }
  if (isMobile) {
    minLength = 2
  }

  const minGamesListArr = games.slice(0, minLength)
  const moreGamesListArr = games.slice(minLength)

  const gamesList = (gamesArr: number[], gamesDict: GamesInsuranceData) => {
    return gamesArr.map(gameKind => {
      const currentGame = gamesDict.filter(game => game.id === gameKind)
      return (
        <div className="InsurancesGamesItem" key={gameKind}>
          {getGameIcon(gameKind, currentGame)}
          <span>{getGameName(gameKind, currentGame)}</span>
        </div>
      )
    })
  }

  const hasHiddenGames = moreGamesListArr.length > 0

  return (
    <>
      <div className="InsurancesGamesList">
        {!showAllState
          ? gamesList(minGamesListArr, gamesDict)
          : gamesList(games, gamesDict)}
        {hasHiddenGames && (
          <div
            className="InsurancesMore"
            onClick={() => setShowAllState(!showAllState)}
          >
            {showAllState ? (
              <span>{insurancesConstants.notShowMore}</span>
            ) : (
              <span>
                {insurancesConstants.showMore} {moreGamesListArr.length}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  )
}

const getWinnersBtnText = (value: InsurancesWinners) => {
  return value?.length
    ? `${value.length} ${insurancesConstants.winnersWord}`
    : insurancesConstants.winnersEmpty
}

export const translateInsuranceStatus = (value: string | undefined) => {
  switch (value) {
    case InsuranceStatus.CANCELED:
      return InsuranceTranslateStatus[InsuranceStatus.CANCELED]
    case InsuranceStatus.FINISHED:
      return InsuranceTranslateStatus[InsuranceStatus.FINISHED]
    case InsuranceStatus.PENDING:
      return InsuranceTranslateStatus[InsuranceStatus.PENDING]
    default:
      return value
  }
}

export const getInsuranceStatusClass = (value: string | undefined) => {
  switch (value) {
    case InsuranceStatus.CANCELED:
      return `InsuranceStatusCanceled`
    case InsuranceStatus.FINISHED:
      return `InsuranceStatusFinished`
    case InsuranceStatus.PENDING:
      return `InsuranceStatusPending`
    default:
      return ""
  }
}

export const getColumns = ({
  sort,
  setShowModal,
  insurancesState,
  setInsurancesState,
  games
}: GetColumnsProps) => {
  return [
    {
      title: insurancesConstants.columnNames[InsurancesColumns.id],
      dataIndex: InsurancesColumns.id,
      key: InsurancesColumns.id,
      width: 80,
      minWidth: 80,
      render: (value: number) => {
        return <span className="InsurancesCellInner">{value}</span>
      }
    },
    {
      title: insurancesConstants.columnNames[InsurancesColumns.status],
      dataIndex: InsurancesColumns.status,
      key: InsurancesColumns.status,
      width: 136,
      minWidth: 136,
      render: (value: string) => {
        return (
          <div className="InsurancesCellInner">
            <div className={getInsuranceStatusClass(value)}>
              {translateInsuranceStatus(value)}
            </div>
          </div>
        )
      }
    },
    {
      title: insurancesConstants.columnNames[InsurancesColumns.period],
      dataIndex: InsurancesColumns.period,
      className: `${getActiveClasses(InsurancesColumns.period, sort)}`,
      key: InsurancesColumns.period,
      width: 176,
      minWidth: 176,
      sorter: () => noop,
      showSorterTooltip: false,
      render: (value: [string, string]) => {
        return (
          <div>
            {renderDate(value[0])}
            {renderDate(value[1])}
          </div>
        )
      }
    },
    {
      title: insurancesConstants.columnNames[InsurancesColumns.maxPayout],
      dataIndex: InsurancesColumns.maxPayout,
      className: `${getActiveClasses(InsurancesColumns.maxPayout, sort)}`,
      key: InsurancesColumns.maxPayout,
      width: 136,
      minWidth: 136,
      sorter: () => noop,
      showSorterTooltip: false,
      render: (value: string) => {
        return (
          <span className="InsurancesMaxPayout InsurancesCellInner">
            {formatToCurrency({
              amount: Number(value),
              withoutFixed: true
            })}
          </span>
        )
      }
    },
    {
      title: insurancesConstants.columnNames[InsurancesColumns.games],
      dataIndex: InsurancesColumns.games,
      key: InsurancesColumns.games,
      width: 580,
      minWidth: 580,
      render: (value: number[]) => (
        <GamesListComponent games={value} gamesDict={games} />
      )
    },
    {
      title: insurancesConstants.columnNames[InsurancesColumns.winners],
      dataIndex: InsurancesColumns.winners,
      key: InsurancesColumns.winners,
      width: 176,
      minWidth: 176,
      render: (value: InsurancesWinners, record: InsurancesReportItem) => {
        return value?.length ? (
          <div className="InsurancesCellInner">
            <div
              className="InsurancesWinnersButton"
              onClick={() => {
                if (!value.length) return

                setShowModal(true)
                setInsurancesState({
                  ...insurancesState,
                  insuranceId: record.id
                })
              }}
            >
              <CupIcon />
              <span>{getWinnersBtnText(value)}</span>
            </div>
          </div>
        ) : (
          <div className="InsurancesWinnersEmpty InsurancesCellInner">
            {getWinnersBtnText(value)}
          </div>
        )
      }
    }
  ]
}

export const getWinnersColumn = () => {
  return [
    {
      title:
        insurancesConstants.winnersColumnNames[
          InsurancesWinnersColumns.walletId
        ],
      dataIndex: InsurancesWinnersColumns.walletId,
      key: InsurancesWinnersColumns.walletId,
      width: 104,
      minWidth: 104
    },
    {
      title:
        insurancesConstants.winnersColumnNames[
          InsurancesWinnersColumns.payoutSum
        ],
      dataIndex: InsurancesWinnersColumns.payoutSum,
      key: InsurancesWinnersColumns.payoutSum,
      width: 104,
      minWidth: 104,
      render: (value: number) => {
        return formatToCurrency({
          amount: value,
          withoutFixed: false
        })
      }
    },
    {
      title:
        insurancesConstants.winnersColumnNames[InsurancesWinnersColumns.phone],
      dataIndex: InsurancesWinnersColumns.phone,
      key: InsurancesWinnersColumns.phone,
      width: 104,
      minWidth: 104,
      render: (value: string) => {
        return formatPhone(value, true)
      }
    }
  ]
}

export const getGameIcon = (
  gameKind: number,
  currentGame: GamesInsuranceData
) => {
  return currentGame?.length ? (
    <img className="InsuranceGameImg" src={currentGame[0].image} />
  ) : (
    <div />
  )
}

export const getGameName = (
  gameKind: number,
  currentGame: GamesInsuranceData
) => {
  return currentGame?.length
    ? currentGame[0].name
    : gameKindsTranslator[gameKind] || gameKind
}
