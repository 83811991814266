import React, { useEffect, useState } from "react"
import {
  contactsPageProps,
  ContactsSectionModalProps
} from "Containers/Contacts/types"
import {
  createInitSectionState,
  sectionRequest
} from "Containers/Contacts/helpers"
import ButtonDefault, { ButtonIcon, ButtonPrimary } from "Components/Button"
import InputComponent, { InputTextAreaComponent } from "Components/Input"
import { ReactComponent as DeleteIcon } from "Components/icons/delete_red.svg"
import { ReactComponent as CloseIcon } from "Components/icons/close_icon.svg"
import { CheckBoxComponent } from "Components/CheckBox"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logoutUser } from "store/auth/actions"
import {
  addAnchorSection,
  addSectionMsg,
  cancelMsg,
  contactsIcons,
  deleteSectionMsg,
  editSectionMsg,
  imgObj,
  sectionCoordinates,
  sectionCoordinatesPlaceholder,
  sectionDeletePromptMsg,
  sectionDescription,
  sectionDescriptionPlaceholder,
  sectionIconName,
  sectionName,
  sectionNamePlaceholder,
  sectionSubtitle,
  sectionSubtitlePlaceholder
} from "Containers/Contacts/constants"

const ContactsSectionModal = ({
  contactsState,
  setContactsState,
  showConfirm
}: contactsPageProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const logout: () => void = () => dispatch(logoutUser())

  /* Редактируем или создаем новый раздел */
  const isSectionEdit = !!contactsState.editSectionId

  const [sectionState, setSectionState] = useState<ContactsSectionModalProps>(
    createInitSectionState(contactsState)
  )

  const denySectionRequest =
    !contactsState.sectionPending ||
    !contactsState.isSectionEditing ||
    !contactsState.activeModal

  useEffect(() => {
    if (denySectionRequest) return

    sectionRequest({
      contactsState,
      setContactsState,
      sectionState,
      logout,
      history
    })
  }, [
    contactsState.sectionPending,
    contactsState.isSectionEditing,
    contactsState.activeModal
  ])
  const icons = contactsIcons.map(name => {
    return (
      <div
        key={name}
        onClick={() => {
          setSectionState({
            ...sectionState,
            icon: name
          })
        }}
        className={`ContactsPageSectionHeader_icon ContactsPageSectionEdit_icon ${
          name === sectionState.icon ? `ContactsIcon_selected` : ``
        }`}
      >
        <img src={imgObj[name]} alt={name} />
      </div>
    )
  })

  return (
    <div className="ContactsEdit">
      <div className="ContactsEdit_top">
        <div className="ContactsEdit_header">
          {isSectionEdit ? editSectionMsg : addSectionMsg}
          <div className="ContactsEdit_closeIcon">
            <CloseIcon
              onClick={() => {
                setContactsState({
                  ...contactsState,
                  editSectionId: null,
                  editBlockId: null,
                  editStaffId: null,
                  activeModal: false,
                  isBlockEditing: false,
                  isStaffEditing: false,
                  sectionErrors: []
                })
              }}
            />
          </div>
        </div>
        <div className="ContactsEdit_title">
          <div>
            <div className="ContactsEdit_fieldname">{sectionName}</div>
            <div className="ContactsEdit_titleInputWrapper">
              <InputComponent
                greyBackground
                className="ContactsEdit_titleInput"
                placeholder={sectionNamePlaceholder}
                value={sectionState.title}
                onChange={e =>
                  setSectionState({
                    ...sectionState,
                    title: e.target.value
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="ContactsEdit_title">
          <div>
            <div className="ContactsEdit_fieldname">{sectionIconName}</div>
            <div className="ContactsEdit_titleInputWrapper">{icons}</div>
          </div>
        </div>
        <div className="ContactsEdit_subtitle">
          <div className="ContactsEdit_fieldname">{sectionSubtitle}</div>
          <InputComponent
            greyBackground
            className="ContactsEdit_subtitleInput"
            placeholder={sectionSubtitlePlaceholder}
            value={sectionState.subtitle}
            onChange={e =>
              setSectionState({
                ...sectionState,
                subtitle: e.target.value
              })
            }
          />
        </div>
        <div className="ContactsEdit_mapLink">
          <div className="ContactsEdit_fieldname">{sectionCoordinates}</div>
          <InputComponent
            greyBackground
            className="ContactsEdit_subtitleInput"
            placeholder={sectionCoordinatesPlaceholder}
            value={sectionState.address}
            onChange={e =>
              setSectionState({
                ...sectionState,
                address: e.target.value
              })
            }
          />
        </div>
        <div className="ContactsEdit_AnchorCheckbox">
          <CheckBoxComponent
            checked={!!sectionState.tabAnchor}
            onChange={e =>
              setSectionState({
                ...sectionState,
                tabAnchor: e.target.checked ? sectionState.title : ""
              })
            }
          >
            {addAnchorSection}
          </CheckBoxComponent>
        </div>
        <div className="ContactsEdit_Subtitle">
          <div className="ContactsEdit_fieldname">{sectionDescription}</div>
          <InputTextAreaComponent
            greyBackground
            placeholder={sectionDescriptionPlaceholder}
            value={sectionState.description}
            onChange={e =>
              setSectionState({
                ...sectionState,
                description: e.target.value
              })
            }
          />
        </div>
        <div className="ContactsEdit_error">
          {contactsState.sectionErrors.join("<br/>")}
        </div>
      </div>
      <div className="ContactsEdit_btns">
        <ButtonPrimary
          className="ContactsEdit_saveBtn ContactsBtn"
          disabled={contactsState.sectionPending}
          onClick={() => {
            setContactsState({
              ...contactsState,
              sectionPending: true,
              url: isSectionEdit
                ? `v1/admin/contacts/section/${contactsState.editSectionId}`
                : `v1/admin/contacts/section`,
              method: isSectionEdit ? `PUT` : `POST`
            })
          }}
        >
          {isSectionEdit ? "Сохранить" : "Добавить"}
        </ButtonPrimary>
        {isSectionEdit ? (
          <ButtonIcon
            icon={<DeleteIcon />}
            disabled={contactsState.sectionPending}
            className="ContactsEdit_DeleteBtn ContactsBtn"
            onClick={() =>
              showConfirm({
                msg: sectionDeletePromptMsg(sectionState.title),
                newState: {
                  ...contactsState,
                  sectionPending: true,
                  url: `v1/admin/contacts/section/${contactsState.editSectionId}`,
                  method: `DELETE`
                }
              })
            }
          >
            {deleteSectionMsg}
          </ButtonIcon>
        ) : (
          <ButtonDefault
            className="ContactsEdit_CancelBtn ContactsBtn"
            disabled={contactsState.sectionPending}
            onClick={() => {
              setContactsState({
                ...contactsState,
                activeModal: false,
                editSectionId: null,
                sectionErrors: []
              })
            }}
          >
            {cancelMsg}
          </ButtonDefault>
        )}
      </div>
    </div>
  )
}

export default ContactsSectionModal
