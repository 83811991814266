import React from "react"

import { GuestHistoryBetsByTimeProps } from "./types"

import { betsByTimeConstants, getTextByData, sumKey } from "./constants"

import TooltipComponent from "Components/Tooltip"

const GuestHistoryBetsByTime = ({
  isFreebetActive,
  chartData,
  activeWins,
  activeBest
}: GuestHistoryBetsByTimeProps) => (
  <div className="GuestHistoryBetsByTimeWrapper">
    {chartData.map(gameData => {
      const game = gameData.game
      return (
        <div key={`row_${game}`} className="FlexRow">
          <div className="GuestHistoryBetsByTimeGameTitle TextDefault">
            {game}
          </div>
          <div className="GuestHistoryBetsByTimeGameTable FlexRow">
            {betsByTimeConstants.options.map((hour, key) => {
              const hoursData = gameData.hours[hour]
              const {
                avg_bet_sum,
                avg_win_sum,
                hours,
                avg_bonus_sum,
                win_in_percent
              } = getTextByData(hoursData)
              const winPercent =
                hoursData?.win_in_percent > betsByTimeConstants.fullPercent ||
                !activeBest
                  ? betsByTimeConstants.fullPercent
                  : hoursData?.win_in_percent
              const betPercent =
                !winPercent || !activeWins
                  ? betsByTimeConstants.fullPercent
                  : betsByTimeConstants.fullPercent - winPercent

              const isSum = hour === sumKey

              //tooltip
              const nextHour =
                betsByTimeConstants.options[key + 1] !== sumKey
                  ? `– ${betsByTimeConstants.options[key + 1]}`
                  : `– ${betsByTimeConstants.options[0]}`
              const title = isSum
                ? `${game}, всего за сутки`
                : `${game}, ${hour} ${nextHour}`

              return (
                <div key={`${hour}_${game}`}>
                  <TooltipComponent
                    trigger="hover"
                    direction="right"
                    className="GuestHistoryBetsByTimeItemTooltip"
                    title={
                      <div className="GuestHistoryBetsByTimeItemTooltip">
                        <div className="GuestHistoryBetsByTimeItemTooltipHeader HeadlineThirdText">
                          {title}
                        </div>
                        <div className="FlexRow GuestHistoryBetsByTimeItemTooltipRow">
                          <div className="GuestHistoryBetsByTimeItemTooltipItem FlexColumn">
                            <span className="OverlineText GuestHistoryBetsByTimeItemTooltipDescription">
                              {betsByTimeConstants.tooltip.avg_bet_sum}
                            </span>
                            <span className="BodyFirstText GuestHistoryBetsByTimeItemTooltipValue">
                              {avg_bet_sum}
                            </span>
                          </div>
                          <div className="GuestHistoryBetsByTimeItemTooltipItem FlexColumn">
                            <span className="OverlineText GuestHistoryBetsByTimeItemTooltipDescription">
                              {betsByTimeConstants.tooltip.avg_win_sum}
                            </span>
                            <span className="BodyFirstText GuestHistoryBetsByTimeItemTooltipValue">
                              {avg_win_sum}
                            </span>
                          </div>
                        </div>
                        <div className="FlexRow GuestHistoryBetsByTimeItemTooltipRow">
                          <div className="GuestHistoryBetsByTimeItemTooltipItem FlexColumn">
                            <span className="OverlineText GuestHistoryBetsByTimeItemTooltipDescription">
                              {betsByTimeConstants.tooltip.avg_bonus_sum}
                            </span>
                            <span className="BodyFirstText GuestHistoryBetsByTimeItemTooltipValue">
                              {isFreebetActive ? avg_bonus_sum : `-`}
                            </span>
                          </div>
                          <div className="GuestHistoryBetsByTimeItemTooltipItem FlexColumn">
                            <span className="OverlineText GuestHistoryBetsByTimeItemTooltipDescription">
                              {betsByTimeConstants.tooltip.win_in_percent}
                            </span>
                            <span className="BodyFirstText GuestHistoryBetsByTimeItemTooltipValue">
                              {win_in_percent}
                            </span>
                          </div>
                        </div>
                        <div className="FlexRow GuestHistoryBetsByTimeItemTooltipRow">
                          <div className="GuestHistoryBetsByTimeItemTooltipItem FlexColumn">
                            <span className="OverlineText GuestHistoryBetsByTimeItemTooltipDescription">
                              {betsByTimeConstants.tooltip.minutes_in_play}
                            </span>
                            <span className="BodyFirstText GuestHistoryBetsByTimeItemTooltipValue">
                              {hours}
                            </span>
                          </div>
                          <div className="GuestHistoryBetsByTimeItemTooltipItem FlexColumn">
                            <span className="OverlineText GuestHistoryBetsByTimeItemTooltipDescription">
                              {betsByTimeConstants.tooltip.bet_count}
                            </span>
                            <span className="BodyFirstText GuestHistoryBetsByTimeItemTooltipValue">
                              {hoursData.bet_count}
                            </span>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div
                      style={{
                        borderRight: isSum ? `1px dashed #E9ECEF` : `none`
                      }}
                      className="GuestHistoryBetsByTimeGameTableItem FlexColumn"
                    >
                      <div
                        className="GuestHistoryBetsByTimeGameTableItemBet"
                        style={{
                          height: `${
                            activeBest && !!hoursData.bet_count ? betPercent : 0
                          }%`
                        }}
                      />
                      <div
                        className="GuestHistoryBetsByTimeGameTableItemWin"
                        style={{
                          height: `${
                            hoursData.win_in_percent && activeWins
                              ? winPercent
                              : 0
                          }%`
                        }}
                      />
                    </div>
                  </TooltipComponent>
                </div>
              )
            })}
          </div>
        </div>
      )
    })}
    <div className="FlexRow">
      <div className="GuestHistoryBetsByTimeGameTitle" />
      {betsByTimeConstants.options.map(hour => {
        const showHour = betsByTimeConstants.activeOptions.includes(hour)
        const isSum = hour === sumKey
        const hourLabel = isSum ? betsByTimeConstants.allLabel : hour
        return (
          <div
            key={`label_row_${hour}`}
            className="GuestHistoryBetsByTimeGameTableItemLabel FlexColumn"
          >
            <div
              className={`${isSum ? `OverlineText` : `TextDefault`} ${
                isSum
                  ? `GuestHistoryBetsByTimeGameHourSumLabel`
                  : `GuestHistoryBetsByTimeGameHourLabel`
              }
              ${
                showHour
                  ? `GuestHistoryBetsByTimeGameTableItemLabelPosition`
                  : ``
              }`}
            >
              {showHour ? hourLabel : ``}
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default GuestHistoryBetsByTime
