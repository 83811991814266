import React from "react"
import { Slider, SliderSingleProps } from "antd"
import { SliderRangeProps } from "antd/es/slider"

interface ISliderComponent extends SliderSingleProps {
  periodType?: boolean
  rest?: any
}

const time = (value: number) => {
  const zeroHour = value === 24 ? 0 : value
  return zeroHour > 9 ? zeroHour : `0${zeroHour}`
}

const SliderComponent = ({ periodType, ...rest }: ISliderComponent) => {
  const sliderLabel = `${time(rest.defaultValue || 0)}:00`
  return (
    <div className="SliderComponentWrapper">
      {!periodType && (
        <div
          style={{
            marginLeft: `${(rest.defaultValue || 0) * 10 -
              20 +
              (rest.defaultValue || 1) / 2}px`
          }}
          className="SliderComponentValueWrapper"
        >
          {sliderLabel}
        </div>
      )}
      <Slider
        tooltipVisible={false}
        reverse={!!periodType}
        tipFormatter={value => <div>{value}</div>}
        trackStyle={{ backgroundColor: `#FFDD2D` }}
        handleStyle={{ backgroundColor: `#F2F2F7` }}
        {...rest}
      />
    </div>
  )
}

interface ISliderRangeComponent extends SliderRangeProps {
  rest?: any
}

export const SliderTimeRangeComponent = ({
  ...rest
}: ISliderRangeComponent) => {
  const startLabel = `${time(rest.defaultValue ? rest.defaultValue[0] : 0)}:00`
  const endLabel = `${time(rest.defaultValue ? rest.defaultValue[1] : 0)}:00`
  const calculateStart = (start: number, end: number) => {
    if (end - start > 4 || end === start) {
      return start * 10 - 20 + start / 2
    } else if (end - start === 1) {
      return start * 10 - 40
    } else {
      return start * 10 - 30
    }
  }
  const calculateEnd = (start: number, end: number) => {
    if (end - start > 4) {
      return (end - start - 2) * 10 - 20 + (end - start) / 2
    } else if (end - start === 1) {
      return (end - start) * 10
    } else {
      return (end - start) * 10 - 10
    }
  }
  return (
    <div className="SliderComponentWrapper">
      {!!rest.defaultValue && (
        <div className="FlexRow">
          <div
            style={{
              marginLeft: `${calculateStart(
                rest.defaultValue[0],
                rest.defaultValue[1]
              )}px`
            }}
            className="SliderComponentValueWrapper"
          >
            {startLabel}
          </div>
          {rest.defaultValue[0] !== rest.defaultValue[1] && (
            <div
              style={{
                marginLeft: `${calculateEnd(
                  rest.defaultValue[0],
                  rest.defaultValue[1]
                )}px`
              }}
              className="SliderComponentValueWrapper"
            >
              {endLabel}
            </div>
          )}
        </div>
      )}
      <Slider
        tooltipVisible={false}
        tipFormatter={value => <div>{value}</div>}
        trackStyle={[{ backgroundColor: `#FFDD2D` }]}
        handleStyle={[{ backgroundColor: `#F2F2F7` }]}
        {...rest}
      />
    </div>
  )
}

export const SliderRangeComponent = ({
  min,
  max,
  value,
  onChange,
  range
}: ISliderRangeComponent) => {
  return (
    <div className="SliderComponentWrapper">
      <Slider
        tooltip={{ formatter: null }}
        trackStyle={[{ backgroundColor: `#FFDD2D` }]}
        min={min}
        max={max}
        range={range}
        value={value}
        defaultValue={[min || 0, max || Number.MAX_SAFE_INTEGER]}
        onChange={onChange}
      />
      <div className="SliderComponentLegend">
        <div>мин. {min}</div>
        <div>макс. {max}</div>
      </div>
    </div>
  )
}

export default SliderComponent
