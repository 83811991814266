import React, { useMemo } from "react"
import { useSelector } from "react-redux"

import { getAccessList } from "store/access/reducer"
import { MenuItemsAccess } from "store/access/types"

import { SelectComponent } from "Components/Select"

import { labelText } from "./constants"

const AccessMenuMobile = ({
  items,
  required_items,
  disabledAll,
  changeItems
}: {
  items: string[]
  disabledAll: boolean
  required_items: string[]
  changeItems: (val: string[]) => void
}) => {
  const { menu_items } = useSelector(getAccessList)

  const options = useMemo(() => {
    const allDataArr = menu_items
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const itemToOption = (item: MenuItemsAccess[]) => {
      return item.map(option => {
        const isDisabled = required_items.includes(option.name) || disabledAll
        return {
          label: option.label,
          value: option.name,
          disabled: isDisabled
        }
      })
    }
    const options = itemToOption(allDataArr)
    return options
  }, [menu_items, required_items, disabledAll])
  return (
    <div className="AccessMenuMobileWrapper">
      <SelectComponent
        allowClear={false}
        mode="multiple"
        value={items}
        options={options}
        className="AccessMenuMobileSelectWrapper"
        placeholder={labelText.menu_items.placeholder}
        onChange={value => {
          changeItems(value)
        }}
      />
    </div>
  )
}

export default AccessMenuMobile
