import { useState, useCallback, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
import { useHistory, RouteComponentProps } from "react-router-dom"

import { Method } from "axios"

import { CheckboxValueType } from "antd/lib/checkbox/Group"

import { IDevelopState } from "store/developer/types"
import { getDeveloperList } from "store/developer/selector"
import { addLink } from "store/developer/actions"
import { GamesData } from "store/dictionary/types"
import { logoutUser } from "store/auth/actions"
import { getAddressList, getCurrentHall } from "store/halls/selector"

import { SortOrders } from "Components/Table/types"

import betRequest from "./request"

export const useRequestParams = () => {
  const history = useHistory()
  const current_hall: (string | CheckboxValueType)[] = useSelector(
    getCurrentHall
  )
  const developer: IDevelopState = useSelector(getDeveloperList)
  const locations: {
    gmt: string
    region: string
  }[] = useSelector(getAddressList)
  const dispatch = useDispatch()
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))
  const logout: () => void = () => dispatch(logoutUser())

  const requestCurrentHall = Number(current_hall[0])
  const current_hall_number = [Number(current_hall[0])]

  return {
    history,
    current_hall,
    current_hall_number,
    requestCurrentHall,
    currentHallAsNumber: Number(current_hall[0]),
    developer,
    locations,
    addDeveloperLink,
    logout
  }
}

export const useNow = () => {
  const [timestamp, setTimestamp] = useState<number>(Date.now())

  const refresh = useCallback(() => setTimestamp(Date.now()), [])

  return { timestamp, refresh }
}

type downloadFileProps = {
  logout: () => void
  source: string
  requestBody: any
  fileName?: string
  loadSetPending: (data: boolean) => void
  sort?: {
    field: string
    order: string
  }
  method?: Method | undefined
  history: RouteComponentProps["history"]
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
}

export const downloadFile = ({
  logout,
  source,
  requestBody,
  loadSetPending,
  sort,
  method,
  developer,
  addDeveloperLink,
  history
}: downloadFileProps) => {
  loadSetPending(true)
  betRequest({
    url: source,
    logout,
    history,
    developer,
    addDeveloperLink,
    requestBody: { ...requestBody, download: 1, sorter: sort || undefined },
    method: method || `POST`
  })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .then(({ data, data: { link } }) => {
      const myRegexp = /uploads\/(.*)/
      const filenameFromLink = myRegexp.exec(link)
      const loadLink = document.createElement(`a`)
      loadLink.setAttribute(`href`, link)
      loadLink.setAttribute(
        `download`,
        `${filenameFromLink ? filenameFromLink[1] : `Отчёт.xlsx`}`
      )
      loadLink.style.display = `none`
      document.body.appendChild(loadLink)
      loadLink.click()
      document.body.removeChild(loadLink)
      loadSetPending(false)
    })
    .catch(() => loadSetPending(false))
}

interface IRequestProps {
  url: string
  method?: Method
  requestBody?: any
  params?: any
  withHall?: (string | CheckboxValueType)[]
  customRootUrl?: string
  skip404Handler?: boolean
}

export const useRequest = ({
  url,
  method,
  requestBody,
  params,
  withHall,
  customRootUrl,
  skip404Handler
}: IRequestProps) => {
  const history = useHistory()
  const developer: IDevelopState = useSelector(getDeveloperList)
  const dispatch = useDispatch()
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))
  const logout: () => void = () => dispatch(logoutUser())

  return {
    request: (req: Record<string, any> = {}) =>
      betRequest({
        history,
        logout,
        developer,
        addDeveloperLink,
        method: method || `POST`,
        url,
        requestBody: { ...requestBody, ...req },
        params,
        withHall,
        customRootUrl,
        skip404Handler
      })
  }
}

export type UseRequestBodyPagination = {
  limit: number
  offset: number
}

export type UseRequestBodySort = {
  field?: string | undefined
  order?: SortOrders
}

type useRequestBodyType = {
  pagination: UseRequestBodyPagination
  sort: UseRequestBodySort
}

export const getPaginationDefault = (perPage?: number) => ({
  limit: perPage || 30,
  offset: 0
})

export const paginationAll = {
  limit: 0,
  offset: 0
}

export const useRequestBody = (perPage?: number) => {
  const paginationDefault = getPaginationDefault(perPage)

  const [requestBody, setRequestBody] = useState<useRequestBodyType>({
    pagination: paginationDefault,
    sort: { field: undefined, order: undefined }
  })
  const setPagination = (value: UseRequestBodyPagination) =>
    setRequestBody({ ...requestBody, pagination: value })

  const resetPagination = () =>
    setRequestBody({ ...requestBody, pagination: paginationDefault })

  const setSort = (value: UseRequestBodySort) =>
    setRequestBody({ ...requestBody, sort: value })

  return {
    pagination: requestBody.pagination,
    sort: requestBody.sort,
    paginationDefault,
    setPagination,
    resetPagination,
    setSort
  }
}

export const useOnScreen = (ref: any) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}

// Get games list for halls[]
export type UseGameListProps = {
  halls: (number | string)[]
}

export type UseGameListState = {
  pending: boolean
  games: GamesData[]
}

export const useGameList = ({ halls }: UseGameListProps) => {
  const [gamesList, setGamesList] = useState<UseGameListState>({
    pending: false,
    games: []
  })

  const { request: getGames } = useRequest({
    url: `dictionary/games`,
    requestBody: { halls }
  })
  const request = () => {
    setGamesList({ ...gamesList, pending: true })
    getGames()
      .then(({ data }) => setGamesList({ pending: false, games: data.games }))
      .catch(() => setGamesList({ ...gamesList, pending: false }))
  }

  return { pending: gamesList.pending, games: gamesList.games, request }
}
