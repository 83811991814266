import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { GuidebookCardInfo, GuidebookPageData } from "../types"

import {
  GuidebookStore,
  GuidebookStoreAddImg,
  GuidebookStoreConsolidateSection,
  GuidebookStoreInfo,
  GuidebookStoreRemoveImg,
  GuidebookStoreSwitchImg
} from "./types"

import moment from "moment"
import produce from "immer"

const initialState = { info: {}, status: null, date: null } as GuidebookStore
const currentDate = moment().format()
export const guidebookSlice = createSlice({
  name: "guidebook",
  initialState,
  reducers: {
    addImgList: (state, action: PayloadAction<GuidebookPageData>) => {
      const { info, status, date } = action.payload
      const isFirst = !status
      const list: GuidebookCardInfo[] = info
      const initialImgList = {} as GuidebookStoreInfo
      list.reduce(
        (newList, cardInfo) => ({ ...newList, [cardInfo.id]: cardInfo }),
        {}
      )
      for (let section = 0; section < list.length; section++) {
        const sectionAttachments = info[section].attachments.reduce(
          (newAttach, cardAttach) => ({
            ...newAttach,
            [cardAttach.id]: cardAttach
          }),
          {}
        )

        const {
          maxCount,
          status,
          minCount,
          required,
          updatedDate,
          uploadType
        } = list[section]

        initialImgList[list[section].id] = {
          status: status,
          haveChanges: isFirst ? false : !status,
          attachments: sectionAttachments,
          maxCount,
          minCount,
          required,
          updatedDate,
          uploadType
        }
      }
      return {
        ...state,
        status,
        date,
        info: initialImgList
      }
    },
    // add new attach for section
    addImgStore: (state, action: PayloadAction<GuidebookStoreAddImg>) => {
      const { sectionId, attach } = action.payload
      return {
        ...state,
        date: currentDate,
        info: {
          ...state.info,
          [sectionId]: {
            ...state.info[sectionId],
            haveChanges: true,
            attachments: {
              ...state.info[sectionId].attachments,
              [attach.id]: attach
            }
          }
        }
      }
    },
    // remove attach for section
    removeImgStore: (state, action: PayloadAction<GuidebookStoreRemoveImg>) => {
      const { sectionId, imgId } = action.payload

      // eslint-disable-next-line
      const { [imgId]: remove, ...rest } = state.info[sectionId].attachments

      return {
        ...state,
        date: currentDate,
        info: {
          ...state.info,
          [sectionId]: {
            ...state.info[sectionId],
            attachments: rest,
            haveChanges: true
          }
        }
      }
    },
    // change status for all status: `null` attaches in section on consideration
    considerationSectionStore: (
      state,
      action: PayloadAction<GuidebookStoreConsolidateSection>
    ) => {
      const { sectionId, info } = action.payload

      const sectionAttachments = info.attachments.reduce(
        (a, v) => ({ ...a, [v.id]: v }),
        {}
      )

      return {
        ...state,
        date: info.updatedDate || null,
        info: {
          ...state.info,
          [sectionId]: {
            ...state.info[sectionId],
            attachments: sectionAttachments,
            haveChanges: false,
            updatedDate: info.updatedDate
          }
        }
      }
    },
    // switch attach on new
    switchChanges: (state, action: PayloadAction<GuidebookStoreSwitchImg>) => {
      const { sectionId, value, attach } = action.payload

      const currentSectionData = state.info[sectionId]
      const attachFromStore = state.info[sectionId].attachments[attach.id]
      const nextAttach = { ...attachFromStore, ...attach, status: null }
      const next = produce(currentSectionData, draft => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.attachments[attach.id] = nextAttach
      })

      return {
        ...state,
        info: {
          ...state.info,
          [sectionId]: {
            ...next,
            haveChanges: value
          }
        }
      }
    },
    changeState: (state, action: PayloadAction<GuidebookStoreSwitchImg>) => {
      return { ...state, ...action.payload }
    }
  }
})

export const {
  addImgList,
  switchChanges,
  addImgStore,
  removeImgStore,
  considerationSectionStore,
  changeState
} = guidebookSlice.actions

export default guidebookSlice.reducer
