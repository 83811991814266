import { ReportText } from "../types"
import { ReactElement } from "react"

export type BalanceOperationComment = {
  comment: string
}

export interface BalanceOperationText extends ReportText {
  comment: string
  roger: string
  more: string
}

export enum StatusesDict {
  in = "in",
  out = "out",
  freezing = "freezing"
}

export type StatusesMap = {
  [key in StatusesDict]: { icon: ReactElement; value: string }
}
