import { CardState, ExteriorPlaces, ImageGroup, InteriorPlaces } from "./types"

export const localStorageKey = "GEOSERVICES_PAGE_VISITED"
export const textConst = {
  space: ".&nbsp;",
  title: "Фото для геосервисов",
  titleTooltip:
    "Раздел предназначен для загрузки фотографий клуба и размещения их в дальнейшем на геосервисах в профиле BetBoom по адресу клуба.",
  rules: [
    `Ориентация фотографии: <span>горизонтальная</span>`,
    `Макс. размер одной фотографии: <span>10 Мб</span>`,
    `Формат изображения: <span>PNG, JPG.</span>`
  ],
  rulesInfo: "Фотографии будут загружены по адресу клуба на геосервисах",
  moreInfo: "Подробная информация",
  exterior: "Экстерьер",
  minExteriorPhotos: "Загрузите минимум 2 фотографии",
  minCountExterior: 2,
  interior: "Интерьер",
  minInteriorPhotos: "Загрузите минимум 5 фотографий",
  minCountInterior: 5,
  addPhoto: "Добавить фото",
  exteriorPlaces: [
    { value: "Входная группа", label: "Входная группа" },
    { value: "Фасад здания", label: "Фасад здания" }
  ],
  interiorPlaces: [
    { value: "Основная зона", label: "Основная зона" },
    { value: "Барная/кассовая зона", label: "Барная/кассовая зона" },
    { value: "Санузел", label: "Санузел" },
    { value: "Тамбур", label: "Тамбур" },
    { value: "Спортивная зона", label: "Спортивная зона" },
    { value: "VIP зона", label: "VIP зона" }
  ],
  popupTitle: "Требования к фотографиям",
  popupConfirm: "Ознакомлен",
  photoRequirements: "Технические требования к фотографиям:",
  photoRequirementsList: [
    "Ориентация фотографии – горизонтальная",
    "Минимальное разрешение – 500×500, максимальное — 7000×7000.",
    "Формат изображения: PNG, JPG.",
    "Разрешение 300 dpi",
    "Максимальный размер одной фотографии 10 Мб",
    "Все задействованные в кадре экраны, терминалы, телевизоры должны быть включены и показывать строго спортивные трансляции/спортивный контент"
  ],
  notAllowed: "Не допускается:",
  notAllowedList: [
    "Фото в низком качестве и/или с неровной линией горизонта",
    "Фото со старым логотипом (BingoBoom)",
    "Фото с трансляциями событий на терминалах и экранах",
    "Присутствие людей в кадре",
    "Фото с квитанциями",
    "Наличие посторонних предметов, не относящихся к BetBoom",
    "Фото с плохим освещением",
    "Фото с несогласованным интерьером клуба"
  ],
  moderationCondition: "Условия модерации фотографий:",
  moderationConditionList: [
    "Не имеют отношения к компании;",
    "Дублируют добавленные ранее;",
    "Низкого качества (например, темные, нечеткие);",
    "Имеют надписи, которые мешают просмотру основного изображения (допускаются надписи как часть логотипа);",
    "Нарушают права третьих лиц;",
    `Содержат информацию, распространение которой запрещено законодательством России и <a style="text-decoration: underline" target="_blank" rel="noopener" href="https://yandex.ru/legal/rules/">Пользовательским соглашением сервисов Яндекса</a>.`
  ],
  moderationLength:
    "Модерация фотографий на геосервисах занимает до 2-х недель",
  send: "Отправить",
  confirmDeleteTitle: "Удалить фотографию?",
  confirmDeleteText: "Вы действительно хотите удалить фотографию?",
  confirmDeleteApply: "Да, Удалить",
  confirmDeleteCancel: "Закрыть",
  successSend: `Заявка успешно отправлена`
}

export const exteriorInitialState: CardState[] = [
  {
    zone: ExteriorPlaces.Entrance,
    image: null,
    group: ImageGroup.EXTERIOR
  },
  {
    zone: ExteriorPlaces.Facade,
    image: null,
    group: ImageGroup.EXTERIOR
  }
]

export const interiorInitialState: CardState[] = [
  {
    zone: InteriorPlaces.Main,
    image: null,
    group: ImageGroup.INTERIOR
  },
  {
    zone: InteriorPlaces.BarCash,
    image: null,
    group: ImageGroup.INTERIOR
  },
  {
    zone: InteriorPlaces.Vestibule,
    image: null,
    group: ImageGroup.INTERIOR
  },
  {
    zone: InteriorPlaces.SportZone,
    image: null,
    group: ImageGroup.INTERIOR
  },
  {
    zone: InteriorPlaces.Vip,
    image: null,
    group: ImageGroup.INTERIOR
  }
]

export const initialInteriorCard: CardState = {
  zone: InteriorPlaces.Main,
  image: null,
  group: ImageGroup.INTERIOR
}

export const initialExteriorCard: CardState = {
  zone: ExteriorPlaces.Entrance,
  image: null,
  group: ImageGroup.EXTERIOR
}
