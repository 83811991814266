export const guestHistoryMetricsConstants = {
  title: `График изменения прибыли`,
  date: {
    format: `DD.MM.YYYY`,
    week: `ddd`
  },
  switcher: [
    {
      label: `За выбранный период`,
      value: 1
    },
    {
      label: `За все время`,
      value: 0
    }
  ]
}
