import React from "react"

import moment from "moment"

import { isMobile } from "constants/index"
import betRequest from "api/request"
import { useRequest, useRequestParams } from "api/hooks"
import { formatToCurrency, valueToCurrency } from "constants/index"

import { mainUrl } from "Containers/Mailing/constant"
import { CheckboxDict } from "Containers/Mailing/Modal/types"
import { Popover } from "antd"

import { ReactComponent as ActionIcon } from "./icons/actions.svg"
import { ReactComponent as PauseIcon } from "./icons/pause.svg"
import { ReactComponent as PlayIcon } from "./icons/play.svg"
import { ReactComponent as DeleteIcon } from "./icons/delete.svg"
import { ReactComponent as EditIcon } from "./icons/edit_user.svg"

import { DetailData, DetailDataUsers, GetColumnsProps } from "./types"
import { statusByActive, detailTableText } from "./constant"

export const useDetailRequests = (data: DetailData) => {
  const { request: getDetail } = useRequest({
    url: `${mainUrl}/${data.hall}`,
    method: `GET`
  })

  const { request: switchPeriod } = useRequest({
    url: `${mainUrl}/${data.hall}`,
    method: `PATCH`,
    requestBody: { period: data.period }
  })

  const { request: activeAll } = useRequest({
    url: `${mainUrl}/${data.hall}`,
    method: `POST`,
    requestBody: { active: !data.active }
  })

  const { request: deleteAll } = useRequest({
    url: `${mainUrl}/${data.hall}`,
    method: `DELETE`
  })

  return { getDetail, switchPeriod, activeAll, deleteAll }
}

export const useDetailUserRequests = (hall: number) => {
  const { developer, addDeveloperLink, history, logout } = useRequestParams()

  const activeCurrent = ({
    id,
    active,
    refresh
  }: {
    id: number
    active: boolean
    refresh: () => void
  }) =>
    betRequest({
      developer,
      addDeveloperLink,
      url: `${mainUrl}/${hall}/${id}`,
      history,
      logout,
      method: `PATCH`,
      requestBody: { active: !active }
    }).then(() => refresh())

  const deleteCurrent = ({
    id,
    refresh
  }: {
    id: number
    refresh: () => void
  }) =>
    betRequest({
      developer,
      addDeveloperLink,
      url: `${mainUrl}/${hall}/${id}`,
      history,
      logout,
      method: `DELETE`
    }).then(() => refresh())

  return { activeCurrent, deleteCurrent }
}

export const getInitialByData = (data: DetailData, id?: number) => {
  return {
    id: id || undefined,
    halls: [data.hall],
    name: undefined,
    [CheckboxDict.replenishment]: undefined,
    [CheckboxDict.withdrawal]: undefined,
    [CheckboxDict.win]: undefined,
    [CheckboxDict.phone]: undefined,
    [CheckboxDict.email]: undefined
  }
}

export const getInitialByUser = (user: DetailDataUsers, hall: number) => {
  const replenishment = user[CheckboxDict.replenishment]
    ? valueToCurrency({
        value: Number(user[CheckboxDict.replenishment]),
        withoutFixed: true
      })
    : undefined
  const withdrawal = user[CheckboxDict.withdrawal]
    ? valueToCurrency({
        value: Number(user[CheckboxDict.withdrawal]),
        withoutFixed: true
      })
    : undefined
  const win = user[CheckboxDict.win]
    ? valueToCurrency({
        value: Number(user[CheckboxDict.win]),
        withoutFixed: true
      })
    : undefined

  return {
    id: user.id,
    halls: [hall],
    name: user.name,
    [CheckboxDict.replenishment]: replenishment,
    [CheckboxDict.withdrawal]: withdrawal,
    [CheckboxDict.win]: win,
    [CheckboxDict.phone]: user.contacts[CheckboxDict.phone],
    [CheckboxDict.email]: user.contacts[CheckboxDict.email]
  }
}

export const getColumns = ({
  hall,
  refreshAll,
  activeCurrent,
  toggleOpen,
  deleteCurrent
}: GetColumnsProps) => {
  return [
    {
      title: () => (
        <div className="MailingDetailTableThCell">
          {detailTableText.columns.name.label}
        </div>
      ),
      dataIndex: detailTableText.columns.name.field,
      key: detailTableText.columns.name.field,
      className: `MailingDetailTableCell`
    },
    {
      title: () => (
        <div className="MailingDetailTableThCell">
          {detailTableText.columns.contacts.label}
        </div>
      ),
      dataIndex: detailTableText.columns.contacts.field,
      className: `MailingDetailTableCell`,
      key: detailTableText.columns.contacts.field,
      render: (contacts: { email: string; phone: string }) => {
        return (
          <div>
            <div className="MailingDetailTableSmallCell MailingDetailTablePhoneCell">
              {contacts.phone}
            </div>
            <div className="MailingDetailTableSmallCell">{contacts.email}</div>
          </div>
        )
      }
    },
    {
      title: () => (
        <div className="MailingDetailTableThCell">
          {detailTableText.columns.replenishment.label}
        </div>
      ),
      dataIndex: detailTableText.columns.replenishment.field,
      key: detailTableText.columns.replenishment.field,
      className: `MailingDetailTableCell`,
      render: (replenishment: number) => {
        const currency = formatToCurrency({
          amount: replenishment,
          withoutFixed: true
        })
        return <div className="MailingDetailTableCell">{currency || `—`}</div>
      }
    },
    {
      title: () => (
        <div className="MailingDetailTableThCell">
          {detailTableText.columns.withdrawal.label}
        </div>
      ),
      dataIndex: detailTableText.columns.withdrawal.field,
      key: detailTableText.columns.withdrawal.field,
      className: `MailingDetailTableCell`,
      render: (withdrawal: number) => {
        const currency = formatToCurrency({
          amount: withdrawal,
          withoutFixed: true
        })
        return <div className="MailingDetailTableCell">{currency || `—`}</div>
      }
    },
    {
      title: () => (
        <div className="MailingDetailTableThCell">
          {detailTableText.columns.win.label}
        </div>
      ),
      dataIndex: detailTableText.columns.win.field,
      key: detailTableText.columns.win.field,
      className: `MailingDetailTableCell`,
      render: (win: number) => {
        const currency = formatToCurrency({
          amount: win,
          withoutFixed: true
        })
        return <div className="MailingDetailTableCell">{currency || `—`}</div>
      }
    },
    {
      title: () => (
        <div className="MailingDetailTableThCell">
          {detailTableText.columns.date.label}
        </div>
      ),
      dataIndex: detailTableText.columns.date.field,
      key: detailTableText.columns.date.field,
      className: `MailingDetailTableCell`,
      render: (value: string) => {
        const date = moment(value).format(
          detailTableText.columns.date.dateFormat
        )
        const time = moment(value).format(
          detailTableText.columns.date.timeFormat
        )
        return (
          <div className="FlexRow">
            <span className="MailingDetailTableDate">{date}</span>
            <span className="MailingDetailTableTime">{time}</span>
          </div>
        )
      }
    },
    {
      title: () => (
        <div className="MailingDetailTableThCell">
          {detailTableText.columns.active.label}
        </div>
      ),
      dataIndex: detailTableText.columns.active.field,
      key: detailTableText.columns.active.field,
      className: `MailingDetailTableCell`,
      render: (active: boolean) => {
        const status = active ? statusByActive.active : statusByActive.pause
        return (
          <div
            className="MailingDetailTableStatus MailingDetailTableCell"
            style={{ color: status.color, backgroundColor: status.background }}
          >
            {status.text}
          </div>
        )
      }
    },
    {
      title: () => (
        <div className="MailingDetailTableThCell">
          {detailTableText.columns.actions.label}
        </div>
      ),
      dataIndex: detailTableText.columns.id,
      className: `MailingDetailTableCell`,
      key: detailTableText.columns.id,
      render: (id: number, record: DetailDataUsers) => {
        const initialForEdit = getInitialByUser(record, hall)
        return (
          <Popover
            overlayClassName="InfoPopoverWrapper"
            placement={isMobile ? `bottom` : `right`}
            content={() => (
              <div className="MailingDetailTableActionsPopover">
                <div
                  className="FlexRow"
                  onClick={() =>
                    toggleOpen({ open: true, initial: initialForEdit })
                  }
                >
                  <EditIcon />
                  <span>{detailTableText.columns.actions.edit}</span>
                </div>
                <div
                  className="FlexRow"
                  onClick={() => {
                    activeCurrent({
                      id,
                      active: record.active,
                      refresh: refreshAll
                    })
                  }}
                >
                  {record.active ? <PauseIcon /> : <PlayIcon />}
                  <span>
                    {record.active
                      ? detailTableText.columns.actions.pause
                      : detailTableText.columns.actions.play}
                  </span>
                </div>
                <div
                  className="FlexRow"
                  onClick={() => {
                    deleteCurrent({
                      id,
                      refresh: refreshAll
                    })
                  }}
                >
                  <DeleteIcon />
                  <span>{detailTableText.columns.actions.delete}</span>
                </div>
              </div>
            )}
            trigger="hover"
          >
            <div className="MailingDetailTableActions">
              <ActionIcon />
            </div>
          </Popover>
        )
      }
    }
  ]
}
