import React, { useCallback, useMemo, useState } from "react"

import { noop } from "lodash"

import { getPaginationDefault, useRequestBody } from "api/hooks"

import { getDateDescriptionLayout } from "constants/index"

import ReportWithFilter from "Components/ReportWithFilter"
import { SideFilter } from "Components/SideFilter/SideFilter"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import NotActiveFilter from "Components/NotActiveFilter"

import { ReactComponent as EmptyFilter } from "./EmptyFilter.svg"

import {
  FilterType,
  PromotionBonusesLegoConfig,
  PromotionBonusesLegoFilter
} from "./types"
import { getInitialFilter } from "./constants"

import CustomFilter from "./CustomFilter"
import BonusLegoTable from "./Table"
import SummaryLegoBonus from "./Summary"

const notActiveFilterTitle = `Чтобы получить отчёт, выберите нужные фильтры \nи нажмите кнопку «Применить»`

const PromotionBonusesLego = ({
  config
}: {
  config: PromotionBonusesLegoConfig
}) => {
  const initialFilter: PromotionBonusesLegoFilter = useMemo(
    () => getInitialFilter(config.filterStorageName),
    []
  )
  const [filterState, setFilterState] = useState<PromotionBonusesLegoFilter>(
    initialFilter
  )

  const { pagination, setPagination } = useRequestBody()

  const titleDescription = useMemo(() => {
    if (!!filterState.data.dates) {
      return getDateDescriptionLayout({
        dates: filterState.data.dates,
        hideTime: false,
        isShift: false,
        hidePeriodTitle: true,
        calendarIcon: true,
        show: filterState.isActive,
        datesAsIs: true
      })
    }
    return ``
  }, [filterState.data.dates])
  const setFilterStateCb = useCallback(
    value => {
      const defPagination = getPaginationDefault(pagination.limit)
      setFilterState(value)
      setPagination(defPagination)
    },
    [pagination.limit]
  )

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={config.filterStorageName}
            filterState={filterState}
            setFilterState={setFilterStateCb}
            resetPagination={noop}
            filters={[Filters.customItem]}
            getDisabled={filter => {
              if (filter.type === config.filterTypeHall) {
                return !filter.dates
              }

              if (filter.type === config.filterTypeGuest) {
                return !filter?.phone || filter?.phone.length < 11
              }

              return true
            }}
            CustomComponent={CustomFilter}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <>
            {filterState.isActive && (
              <div className="PromotionWrapper">
                <Title
                  titleText={config.title}
                  tooltipText={config.tool}
                  bottomAdditionalCmp={
                    !!filterState.data.dates &&
                    filterState.data.type === FilterType.hall ? (
                      <div>{titleDescription}</div>
                    ) : (
                      <div />
                    )
                  }
                />
                {filterState.data.type === FilterType.hall && (
                  <SummaryLegoBonus filter={filterState.data} config={config} />
                )}
                <BonusLegoTable
                  filter={filterState.data}
                  type={filterState.data.type}
                  config={config}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </div>
            )}
            {!filterState.isActive && (
              <div className="PromotionWrapper PromotionWrapperInActive">
                <Title
                  titleText={config.title}
                  tooltipText={config.tool}
                  bottomAdditionalCmp={
                    !!filterState.data.dates &&
                    filterState.data.type === FilterType.hall ? (
                      <div>{titleDescription}</div>
                    ) : (
                      <div />
                    )
                  }
                />
                <NotActiveFilter
                  title={notActiveFilterTitle}
                  description={config.notActiveFilterDescription}
                  icon={<EmptyFilter />}
                />
                <div className="EmptyHelper" />
              </div>
            )}
          </>
        }
        modalCmp={<div />}
      />
    </>
  )
}

export default PromotionBonusesLego
