import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { UseRequestBodyPagination } from "../../api/hooks"
import { Moment } from "moment"

export type BooleanSetter = (value: boolean) => void
export type Func = () => void

export enum ActiveTabs {
  creating = `creating`,
  history = `history`
}

export type CreateTabsProps = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  activeTab: ActiveTabs
  setActiveTab: (data: ActiveTabs) => void
}

export type HistoryTabsProps = {
  requestStatuses: SmsRequestStatus[]
  activeTab: ActiveTabs
  setActiveTab: (data: ActiveTabs) => void
  hallId: number
}

export type HistoryRequestProps = {
  search: HistorySearchProps
  hallId: number
  pagination: UseRequestBodyPagination
}

export type SmsTabsProps = {
  activeTab: ActiveTabs
  setActiveTab: (data: ActiveTabs) => void
  requestBody?: HistoryRequestProps
}

export type SubTemplateItem = {
  subtypeId: number
  name: string
}

export type TemplateItem = {
  typeId: number
  name: string
  subtypes?: SubTemplateItem[]
}

export type TextItem = {
  textId: number
  typeId: number
  subtypeId?: number
  text: string
}

export type MacroItem = {
  mask: string
  field: string
  label: string
}

export type FindMacroProps = {
  macrosArr: MacroItem[]
  matched: string
}

export type SmsRequestStatus = {
  id: number
  name: string
  color: string
  background: string
}

export type SmsSettingsProps = {
  types: TemplateItem[]
  texts: TextItem[]
  macros: MacroItem[]
  requestStatuses: SmsRequestStatus[]
  address: string
}

export type ExtraParamsProps = Record<string, any>

export type FromToErrorsProps = {
  from?: string
  to?: string
}

export type MacrosErrorsProps = Record<string, any>

export type ErrorPhones = {
  invalid?: string[]
  valid?: string[]
}

export type FilterErrorsProps = {
  phones?: ErrorPhones
  deposit?: FromToErrorsProps | string
  visits?: FromToErrorsProps | string
  period?: string
  dates?: string
}

export type SmsErrorProps = {
  text?: string
}

export type ErrorProps = {
  dttmToSend?: string
  filters?: FilterErrorsProps
  macros?: MacrosErrorsProps
  sms?: SmsErrorProps
  phonesTotal?: string
}

export type CalcStateProps = {
  characterCount: number
  smsCount: number
  smsLength: number
  phonesCount: number
  price: number
  calculating: boolean
}

export type SmsStateProps = {
  creating: boolean
  pending: boolean
  currentHall: number
  selectedTypeId: number | null
  selectedSubTypeId: number | null
  selectedTextId: number
  customSmsText: string | undefined
  sendingType: number
  phones: string[]
  contactPhoneError: string
  dateToSend: number | undefined
  timeToSend: number | undefined
  contactName: string
  comment: string
  contactPhone: string
  sumFrom: string
  sumTo: string
  visitFrom: string
  visitTo: string
  dates: { from: Moment; to: Moment } | null
  errors: ErrorProps
  period: number | null
  extraParams: ExtraParamsProps
  smsSettings: SmsSettingsProps
}

export type GetCalcSettings = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  calcState: CalcStateProps
  setCalcState: (value: CalcStateProps) => void
  logout: Func
  history: RouteComponentProps["history"]
}

export type GetValidateProps = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  setModal: BooleanSetter
  logout: Func
  history: RouteComponentProps["history"]
}

export type CreateProps = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  setSuccessModal: BooleanSetter
  setModal: BooleanSetter
  setPending: (val: boolean) => void
  logout: Func
  history: RouteComponentProps["history"]
}
export type GetSmsSettings = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  logout: Func
  history: RouteComponentProps["history"]
}

export type TextOptionsProps = {
  label: string
  value: number
}

export type DaysOptionsProps = {
  label: number
  value: number
}

export type CreateStepProps = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  templateSmsTextSelected: boolean
}

export type CreateStep3Props = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  macrosFindedArr: string[]
}

export type CreateStep4Props = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  stepNumber: number
  showStep: boolean
}

export type CreateStep5Props = CreateStep4Props

export type CreateStep6Props = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  stepNumber: number
}

export type CreateStep7Props = CreateStep6Props

export type CreateStep8Props = CreateStep5Props

export type PhonePreviewProps = {
  smsState: SmsStateProps
  calcState: CalcStateProps
  showStep4: boolean
  templateSmsTextSelected: boolean
}

export type SMSModalProps = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  setModal: BooleanSetter
  templateSmsTextSelected: boolean
  calcState: CalcStateProps
  setSuccessModal: BooleanSetter
  logout: Func
  history: RouteComponentProps["history"]
}

export type SmsSuccessModalProps = {
  smsState: SmsStateProps
  setSuccessModal: BooleanSetter
  setActiveTab: (data: ActiveTabs) => void
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
}

export enum macros {
  address = `&address`,
  date = `&date`,
  time = `&time`,
  sum = `&sum`,
  number = `&number`,
  timeFrom = `&time_from`,
  timeTo = `&time_to`,
  command = `&command`,
  bonus = `&bonus`,
  money = `&money`
}

export enum macrosRaw {
  address = `address`,
  date = `date`,
  time = `time`,
  sum = `sum`,
  number = `number`,
  timeFrom = `time_from`,
  timeTo = `time_to`,
  command = `command`,
  bonus = `bonus`,
  money = `money`
}

export enum sendingsTypeValues {
  all = 1,
  only = 2,
  excepted = 3
}

export enum SmsRequestStatuses {
  matching = 1, //"На согласовании"
  agreed = 2, //"Согласована"
  canceled = 3, //"Отменена"
  processing = 4, //"В процессе отправки"
  sended = 5, //"Отправлена"
  error = 6, //"Ошибка"
  gettingStatusesInfo = 9 //"Загрузка статусов сообщений"
}

export type Step3CommandFieldProps = {
  macroName: string
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  placeHolder: string
}

export type Step3FieldProps = {
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
  error?: any
}

export type HistoryReportProps = {
  id: number
  requestDate: number
  favoriteSendDate: number
  status: number
  comment: string
  text: string
  sendedText: string
  sendDate: number
  smsCount: number
  smsSendedCount: number
  walletCount: number
  cost: number
}

export type HistorySearchProps = { [key: string]: any }

export type HistoryPaginationProps = {
  limit: number
  offset: number
  total: number
}

export type HistoryStateprops = {
  reload: boolean
  pending: boolean
  hallId: number
  report: HistoryReportProps[]
  total: number
}

export type GetHistoryProps = {
  historyState: HistoryStateprops
  setHistoryState: (value: HistoryStateprops) => void
  logout: Func
  history: RouteComponentProps["history"]
}

export type SearchSmsProps = { [key: string]: any }
export type SetSearchSmsProps = (value: SearchSmsProps) => void

export type columnCreatorProps = {
  search: { [key: string]: any }
  setSearch: SetSearchSmsProps
  requestStatuses: SmsRequestStatus[]
  historyState: HistoryStateprops
  setHistoryState: (value: HistoryStateprops) => void
}

export type CancelButtonProps = {
  requestId: number
  historyState: HistoryStateprops
  setHistoryState: (value: HistoryStateprops) => void
}

export type CustomTagProps = {
  label: React.ReactNode
  value: any
  disabled: boolean
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  closable: boolean
}

export type CloseHandlerProps = {
  value: string
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
}

export type Step4ChangeHandlerProps = {
  value: string[]
  smsState: SmsStateProps
  setSmsState: React.Dispatch<React.SetStateAction<SmsStateProps>>
}
