import React, { useEffect, useMemo, useState } from "react"
import { HistoryStateprops, HistoryTabsProps, SearchSmsProps } from "../types"
import SmsTabs from "../SmsTabs"
import { useRequest, useRequestBody } from "api/hooks"
import { historyColumnsCreator } from "./histrotyHelper"
import { smsConstants } from "../constants"
import NewTableComponent from "Components/TableNew"

const HistorySms = ({
  activeTab,
  setActiveTab,
  hallId,
  requestStatuses
}: HistoryTabsProps) => {
  const [historyState, setHistoryState] = useState<HistoryStateprops>({
    pending: false,
    reload: false,
    hallId,
    report: [],
    total: 0
  })

  const { pagination, setPagination } = useRequestBody(20)

  const [search, setSearch] = useState<SearchSmsProps>({})

  const requestBody = useMemo(() => {
    return {
      hallId: parseInt(`${hallId}`),
      search,
      pagination
    }
  }, [pagination, search, historyState])

  const { request } = useRequest({
    url: smsConstants.urls.history,
    method: `POST`,
    requestBody
  })

  useEffect(() => {
    setHistoryState({
      ...historyState,
      pending: true
    })

    request()
      .then(({ data, headers }) => {
        setHistoryState({
          ...historyState,
          pending: false,
          report: data,
          total: headers[`total-count`]
        })
      })
      .catch(() => {
        setHistoryState({
          ...historyState,
          pending: false
        })
      })
  }, [pagination, pagination, search, historyState.reload])

  const columns = historyColumnsCreator({
    search,
    setSearch,
    requestStatuses,
    historyState,
    setHistoryState
  })

  return (
    <div>
      <SmsTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        requestBody={requestBody}
      />
      <div className="SmsWrapper">
        <div className="HistorySmsTableWithHeader">
          <NewTableComponent
            pagination={{
              value: pagination,
              total: historyState.total,
              onChange: setPagination
            }}
            columns={columns}
            data={historyState.report}
            loading={historyState.pending}
            width={smsConstants.tableScrollX}
          />
        </div>
      </div>
    </div>
  )
}

export default HistorySms
