import React, { useState } from "react"
import { isString } from "lodash"
import { CreateStep6Props } from "../../types"
import { canShowStep6, formatValueWithSpaces } from "../../helper"
import { smsConstants } from "../../constants"
import TooltipComponent from "Components/Tooltip"
import { Radio } from "antd"
import InputComponent from "Components/Input"
import { RangePickerComponent } from "Components/DatePicker"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"
import { ReactComponent as DatePickerIcon } from "Components/icons/calendar_outline_24.svg"
import {
  PickerDurations,
  PickerFormats
} from "Components/FilterInfoPanel/types"
import { Moment } from "moment"

type RangeValue = [Moment | null, Moment | null] | null

const Step6 = ({ smsState, setSmsState, stepNumber }: CreateStep6Props) => {
  const showStep6 = canShowStep6(smsState)

  const radioBtns = smsConstants.periodTypeOptions.map(item => (
    <Radio
      key={`periodTypeOption_${item.value}`}
      value={item.value}
      onClick={() => {
        if (smsState.period === item.value) {
          setSmsState({
            ...smsState,
            period: smsConstants.periodTypeAllTime,
            dates: null
          })
        }
      }}
    >
      {item.label}
    </Radio>
  ))

  radioBtns.push(
    <Radio
      className="CreateSms_calendarIcon"
      key={`periodTypeOption_${smsConstants.periodTypeOptionsDates}`}
      value={smsConstants.periodTypeOptionsDates}
      onClick={() => {
        setSmsState({
          ...smsState,
          period:
            smsState.period === smsConstants.periodTypeOptionsDates
              ? null
              : smsConstants.periodTypeOptionsDates
        })
      }}
    >
      <DatePickerIcon />
    </Radio>
  )

  const [dates, setDates] = useState<RangeValue>(null)
  const [value, setValue] = useState<RangeValue>(null)

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false
    }

    //Нельзя выбрать даты с разницей > 14 дней
    const tooLate =
      dates[0] && current.diff(dates[0], "days") < smsConstants.periodDays
    const tooEarly =
      dates[1] && dates[1].diff(current, "days") < smsConstants.periodDays

    //Нельзя выбрать одинаковые даты
    const equalDatesLate = dates[0] && current.isSame(dates[0])
    const equalDatesEarly = dates[1] && current.isSame(dates[1])

    return !!tooEarly || !!tooLate || !!equalDatesLate || !!equalDatesEarly
  }

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null])
    } else {
      setDates(null)
    }
  }

  const onChange = (val: any) => {
    setSmsState({
      ...smsState,
      dates: {
        from: val[0],
        to: val[1]
      }
    })
    setValue(val)
  }

  const onCalendarChange = (val: any) => setDates(val)

  return (
    <div className="CreateSms_step">
      <div
        className={
          showStep6
            ? "CreateSms_stepTitle CreateSms_stepTitle_CustomTooltip"
            : "CreateSms_stepTitle_inactive"
        }
      >
        <span>{stepNumber}</span>
        <span>{smsConstants.step6.title}</span>
        <div className="CreateSms_pseudoTooltipWrapper">
          <div className="CreateSms_pseudoTooltipHelper"></div>
          <InfoIcon className="CreateSms_pseudoTooltipIcon" />
          <div className="CreateSms_pseudoTooltip">
            <div className="CreateSms_pseudoTooltipArrow">
              <div className="CreateSms_pseudoTooltipArrowContent"></div>
            </div>
            <div className="CreateSms_pseudoTooltipInner">
              <p>{smsConstants.step6.tooltip_header}</p>
              <ul>
                <li>{smsConstants.step6.tooltip_listItem1}</li>
                <li>{smsConstants.step6.tooltip_listItem2}</li>
                <li>{smsConstants.step6.tooltip_listItem3}</li>
              </ul>
              <p>{smsConstants.step6.tooltip_bottom}</p>
            </div>
          </div>
        </div>
      </div>

      {showStep6 ? (
        <section className="CreateSms_inputWrapper">
          <span className="CreateSms_inputInfo">
            {smsConstants.step6.title2}
          </span>
          <div className="CreateSms_input">
            <label className="CreateSms_inputLabel CreateSms_inputLabel_withTooltip">
              {smsConstants.step6.activeGuestsTitle}
              <TooltipComponent
                direction="bottom"
                title={smsConstants.step6.periodTooltip}
              >
                <InfoIcon />
              </TooltipComponent>
            </label>
            <Radio.Group
              optionType="button"
              className="PeriodRadio"
              value={smsState.period}
              onChange={({ target: { value } }) => {
                setSmsState({
                  ...smsState,
                  period: value,
                  dates: null
                })
              }}
            >
              {radioBtns}
            </Radio.Group>
            {smsState.period === 0 && (
              <div className="CreateSms_input">
                <div className="CreateSms_inputInner CreateSms_RangePickerContainer">
                  <RangePickerComponent
                    value={dates || value}
                    disabledDate={disabledDate}
                    onCalendarChange={onCalendarChange}
                    onChange={onChange}
                    onOpenChange={onOpenChange}
                    error={smsState.errors?.filters?.dates}
                    picker={PickerDurations.date}
                    format={PickerFormats.dayMonthYear}
                  />
                </div>
              </div>
            )}
            {!!smsState.errors?.filters?.period ||
              (!!smsState.errors?.phonesTotal && (
                <div className="ErrorText">
                  {smsState?.errors?.filters?.period ||
                    smsState.errors?.phonesTotal}
                </div>
              ))}
          </div>
          <div className="CreateSms_input CreateSms_inputShort">
            <label className="CreateSms_inputLabel CreateSms_inputLabel_withTooltip">
              {smsConstants.step6.sumTitle}
              <TooltipComponent
                title={smsConstants.step6.sumTooltip}
                direction="bottom"
              >
                <InfoIcon />
              </TooltipComponent>
            </label>
            <div className="CreateSms_inputInner">
              <InputComponent
                error={
                  isString(smsState.errors?.filters?.deposit)
                    ? smsState.errors?.filters?.deposit
                    : smsState.errors?.filters?.deposit?.from
                }
                greyBackground
                suffix={smsConstants.rubleSymbol}
                prefix={smsConstants.fromText}
                hideControls
                value={formatValueWithSpaces(smsState.sumFrom)}
                onChange={({ target: { value } }) => {
                  setSmsState({
                    ...smsState,
                    sumFrom: value.replace(/[^0-9]/g, "")
                  })
                }}
                placeholder=""
              />
              <InputComponent
                error={
                  isString(smsState.errors?.filters?.deposit)
                    ? smsState.errors?.filters?.deposit
                    : smsState.errors?.filters?.deposit?.to
                }
                greyBackground
                hideControls
                suffix={smsConstants.rubleSymbol}
                prefix={smsConstants.toText}
                value={formatValueWithSpaces(smsState.sumTo)}
                onChange={({ target: { value } }) => {
                  setSmsState({
                    ...smsState,
                    sumTo: value.replace(/[^0-9]/g, "")
                  })
                }}
                placeholder=""
              />
            </div>
          </div>
          <div className="CreateSms_input CreateSms_inputShort">
            <label className="CreateSms_inputLabel CreateSms_inputLabel_withTooltip">
              {smsConstants.step6.visitTitle}
              <TooltipComponent
                title={smsConstants.step6.visitTooltip}
                direction="bottom"
              >
                <InfoIcon />
              </TooltipComponent>
            </label>
            <div className="CreateSms_inputInner">
              <InputComponent
                error={
                  isString(smsState.errors?.filters?.visits)
                    ? smsState.errors?.filters?.visits
                    : smsState.errors?.filters?.visits?.from
                }
                greyBackground
                hideControls
                prefix={smsConstants.fromText}
                value={formatValueWithSpaces(smsState.visitFrom)}
                onChange={({ target: { value } }) => {
                  setSmsState({
                    ...smsState,
                    visitFrom: value.replace(/[^0-9]/g, "")
                  })
                }}
                placeholder=""
              />
              <InputComponent
                error={
                  isString(smsState.errors?.filters?.visits)
                    ? smsState.errors?.filters?.visits
                    : smsState.errors?.filters?.visits?.to
                }
                greyBackground
                hideControls
                value={formatValueWithSpaces(smsState.visitTo)}
                prefix={smsConstants.toText}
                onChange={({ target: { value } }) => {
                  setSmsState({
                    ...smsState,
                    visitTo: value.replace(/[^0-9]/g, "")
                  })
                }}
                placeholder=""
              />
            </div>
          </div>
        </section>
      ) : (
        <hr className="CreateSms_divider" />
      )}
    </div>
  )
}

export default Step6
