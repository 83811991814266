export interface WalletModalProps {
  data?: RiskProfile | null
  id: number | null
  loading: boolean
  onHistoryButtonClick: () => void
  onClose: () => void
}

export enum WalletModalColumns {
  risk_profile_name = "risk_profile_name",
  risk_profile_description = "risk_profile_description",
  limit_percent = "limit_percent",
  delay = "delay"
}

export interface RiskProfile {
  [WalletModalColumns.risk_profile_name]: string
  [WalletModalColumns.risk_profile_description]: string
  [WalletModalColumns.limit_percent]: number
  [WalletModalColumns.delay]: number
}

export interface WalletInfoHistory {
  old: RiskProfile
  new: RiskProfile
  change_date: string
  [key: string]: RiskProfile | string
}

export interface WalletInfoData {
  current: RiskProfile
  history: WalletInfoHistory[]
}

export interface WalletIdCellProps {
  id: number
  onWalletIdClick?: (walletId: number) => void
  additionalClassName?: string
}

export type WalletColumn = {
  title: string | (() => JSX.Element)
  key: keyof WalletInfoHistory
  dataIndex: keyof WalletInfoHistory
  render: (value: any, item: WalletInfoHistory) => JSX.Element
}
