import React from "react"

import ModalComponent from "Components/Modal"
import { ButtonPrimary } from "Components/Button"
import { ReactComponent as SuccessIcon } from "Components/icons/success_large.svg"

import { SuccessModalProps } from "./types"
import { successText } from "./constants"

const SuccessModal = ({ data, close }: SuccessModalProps) => {
  const text = successText(data)
  return (
    <ModalComponent width={500}>
      <div className="SuperwinSuccessModalWrapper FlexColumn Gap16">
        <div className="GridCenter">
          <SuccessIcon />
        </div>
        <div className="FlexColumn Gap8 SuperwinSuccessModalDescription">
          <div className="GridCenter">
            <div className="HeadlineSecondText">{text.title}</div>
          </div>
          <div className="GridCenter">
            <div className="SubtitleSecondText TextAlignCenter">
              {text.description}
            </div>
          </div>
        </div>
        <ButtonPrimary onClick={close}>{text.close}</ButtonPrimary>
      </div>
    </ModalComponent>
  )
}

export default SuccessModal
