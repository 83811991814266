import React from "react"
import moment from "moment"
import { TimePickerComponent } from "Components/DatePicker"
import { Step3FieldProps } from "Containers/Sms/types"
import { smsConstants } from "Containers/Sms/constants"

const Time = ({ smsState, setSmsState, ...rest }: Step3FieldProps) => {
  return (
    <div className="CreateSms_input CreateSms_inputShort CreateSms_inputShort_single">
      <label className="CreateSms_inputLabel">
        {smsConstants.step3.timeTitle}
      </label>
      <div className="CreateSms_inputInner">
        <TimePickerComponent
          value={
            !!smsState.extraParams.time
              ? moment(smsState.extraParams.time)
              : undefined
          }
          onChange={value =>
            setSmsState({
              ...smsState,
              extraParams: {
                ...smsState.extraParams,
                time: moment(value).valueOf()
              }
            })
          }
          greyBackground
          placeholder={smsConstants.step3.timePlaceholder}
          {...rest}
        />
      </div>
    </div>
  )
}

export default Time
