import React from "react"

import { ModalFormComponent } from "Components/Modal"

import { ButtonPrimary } from "../Button"
import { ReactComponent as SuccessIcon } from "../icons/illustrations/thumb_up.svg"

type SuccessModalProps = {
  close: () => void
  title: string
}

const defaultText = {
  button: `Хорошо`
}

const SuccessModal = ({ close, title }: SuccessModalProps) => {
  return (
    <ModalFormComponent width={480}>
      <div className="FlexColumn Gap24 Padding16">
        <div className="GridCenter HeadlineSecondText">{title}</div>
        <div className="GridCenter">
          <SuccessIcon />
        </div>
        <div className="GridCenter">
          <ButtonPrimary onClick={close}>{defaultText.button}</ButtonPrimary>
        </div>
      </div>
    </ModalFormComponent>
  )
}

export default SuccessModal
