import React, { useCallback, useEffect, useState } from "react"
import { createHistoryColumns, useNominalsRequests } from "../helper"
import TableComponent from "Components/TableNew"
import {
  initialModalState,
  initialTableFilters,
  nominalsText
} from "../constants"
import { useRequestBody } from "api/hooks"
import { ModalFormComponent } from "Components/Modal"
import { ButtonPrimary } from "Components/Button"
import { useSelector } from "react-redux"
import { getNominalsGamesList } from "store/dictionary/selector"

export const HistoryTab = () => {
  const { pagination, setPagination, setSort, sort } = useRequestBody()
  const [total, setTotal] = useState(0)
  const [tableFilters, setTableFilters] = useState(initialTableFilters)
  const [commentModal, setCommentModal] = useState(initialModalState)
  const [tableData, setTableData] = useState([])

  const games = useSelector(getNominalsGamesList)

  const handleMoreClicked = useCallback((comment: string) => {
    setCommentModal({ comment, isActive: true })
  }, [])

  const handleDatesChanged = useCallback(
    dates => {
      setTableFilters({ ...tableFilters, dates })
    },
    [tableFilters]
  )

  const handleGamesChanged = useCallback(
    games => {
      setTableFilters({ ...tableFilters, game_ids: games })
    },
    [tableFilters]
  )

  const columns = createHistoryColumns({
    onMoreClicked: handleMoreClicked,
    onDatesChanged: handleDatesChanged,
    onGamesChanged: handleGamesChanged,
    onSortChanged: setSort,
    gamesDict: games,
    filterDates: tableFilters.dates,
    filterGames: tableFilters.game_ids,
    sort
  })

  const { getNominalsHistory } = useNominalsRequests({
    historyArgs: {
      sort,
      ...pagination,
      ...tableFilters
    }
  })

  useEffect(() => {
    getNominalsHistory()
      .then(res => {
        setTableData(res.data)
        setTotal(res.headers.total)
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }, [pagination, sort, tableFilters])

  return (
    <div>
      <TableComponent
        columns={columns}
        data={tableData}
        pagination={{
          value: pagination,
          total,
          onChange: setPagination
        }}
      />
      {commentModal.isActive && (
        <ModalFormComponent width={545} contentClassName="HistoryModal">
          <div className="HistoryModalContainer">
            <div className="HistoryModalContainer__header">
              {nominalsText.comment}
            </div>
            <div className="HistoryModalContainer__body">
              {commentModal.comment}
            </div>
            <div className="HistoryModalContainer__footer">
              <ButtonPrimary
                className="HistoryModalContainer__footer__button"
                onClick={() => {
                  setCommentModal(initialModalState)
                }}
              >
                {nominalsText.rogerThat}
              </ButtonPrimary>
            </div>
          </div>
        </ModalFormComponent>
      )}
    </div>
  )
}
