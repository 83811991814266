import { GetLabelProps, HeaderTypesList } from "./types"
import moment from "moment"
import { isArray } from "lodash"

export const getColumnActiveClass = (field: string | undefined, key: string) =>
  `TableColumn ${field === key ? `ActiveSortTableColumn` : ``}`

export const getLabel = ({
  type,
  search,
  title,
  selectOptions
}: GetLabelProps) => {
  switch (type) {
    case HeaderTypesList.SelectSearch:
      if (!search) return title
      const option = selectOptions?.filter(el => el.value === search)[0]
      return option === undefined ? title : option.label
    case HeaderTypesList.RangeDateSearch:
      return isArray(search)
        ? `${moment(search[0], "YYYY MM DD").format("DD MM YYYY")}-${moment(
            search[1],
            "YYYY MM DD"
          ).format("DD MM YYYY")}`
        : title
    default:
      return search || title
  }
}
