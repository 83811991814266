import {
  AccessTemplates,
  MenuItemsAccess,
  TemplatesItem,
  TemplatesList
} from "./types"
import { IRootState } from "../index"

const initialState = {
  templates: [],
  custom_template_names: [],
  dashboards: [],
  menu_items: []
} as TemplatesList

const SET_LIST = `SET_LIST`

import { action } from "typesafe-actions"

export function accessReducer(
  state: TemplatesList = initialState,
  action: { type: string; payload: TemplatesList }
): TemplatesList {
  const { type, payload } = action
  switch (type) {
    case SET_LIST: {
      return { ...state, ...payload }
    }

    default:
      return state
  }
}

// action

export const listUrl = `v1/access/templates`

export const setList = (payload: TemplatesList) => {
  return action(SET_LIST, payload)
}

// selectors
export const getAccessList = (state: IRootState): TemplatesList => state.access
export const getTemplatesList = (state: IRootState): TemplatesItem[] =>
  state.access.templates
export const getTemplatesItem = (
  state: IRootState,
  key: AccessTemplates
): TemplatesItem | undefined => {
  const templates = state.access.templates
  return templates.find(item => item.key === key)
}
export const getDashboardsList = (state: IRootState): string[] =>
  state.access.dashboards
export const getMenuList = (state: IRootState): MenuItemsAccess[] =>
  state.access.menu_items

export const getMenuListByKeys = (state: IRootState) => {
  const availableMenu = state.access.menu_items
  const menuObj = {} as {
    [key: string]: {
      name: string
      label: string
    }
  }
  const goByMenu = (data: MenuItemsAccess[]) => {
    for (let i = 0; i < data.length; i++) {
      const current = data[i] as MenuItemsAccess
      if (current) {
        if (!!current.inside?.length) {
          goByMenu(current.inside)
        } else {
          menuObj[current.name] = { name: current.name, label: current.label }
        }
      }
    }
  }
  goByMenu(availableMenu)
  return menuObj
}
