import React, { useEffect, useState } from "react"

import { useNow, useRequest } from "api/hooks"

import { isMobile } from "constants/index"

import MobileList from "./MobileList"
import IncreaseModal from "./IncreaseModal"
import { IncreaseModalState, SuccessModalState } from "./types"
import SuccessModal from "./Success"
import { getColumnItemsText, getTableText, mergeArrays } from "./constants"

import { ReactComponent as CrownIcon } from "../icons/crown_24.svg"
import { ReactComponent as ClubIcon } from "../icons/club_24.svg"
import { rowType, SuperwinItemState } from "../types"

import { SuperwinFilterManageData } from "../../types"
import { SuperwinListRow } from "./SuperwinListRow"

const SuperwinList = ({ halls }: SuperwinFilterManageData) => {
  const [state, setState] = useState<SuperwinItemState>({
    pending: false,
    data: []
  })
  const { timestamp, refresh } = useNow()

  const { request: getSuperwinManage } = useRequest({
    url: `v1/superwins/manage`,
    requestBody: { halls: halls?.map(Number) }
  })

  const reqDeps = JSON.stringify(halls)
  useEffect(() => {
    setState({ ...state, pending: false })
    getSuperwinManage().then(({ data }) => setState({ pending: false, data }))
  }, [reqDeps, timestamp])

  const [modal, setModal] = useState<IncreaseModalState>({
    data: undefined,
    is_vip: false
  })

  const [successModal, toggleSuccessModal] = useState<SuccessModalState>(
    undefined
  )

  return (
    <div className="FlexColumn Gap12">
      {!isMobile ? (
        state.data.map(dataItem => {
          const mainColumnText = getTableText(dataItem.hall)
          const withVip = !!dataItem[rowType.VIP]
          return (
            <div
              key={`list_item_${dataItem.hall}`}
              className="FlexRow SuperwinListRow"
            >
              <div className="FlexColumn SuperwinListFirstColumn">
                <div className="SuperwinListHeader SuperwinListItem BodySecondText">
                  {mainColumnText.hall}
                </div>
                <div
                  className={`SuperwinListMainRow SuperwinListItem FlexRow Gap8 ${
                    !withVip ? `SuperwinListVipRow` : ``
                  }`}
                >
                  <div className="GridCenter">
                    <ClubIcon />
                  </div>
                  <div className="GridCenter">
                    <div className="TextDefaultBold">
                      {mainColumnText[rowType.MAIN]}
                    </div>
                  </div>
                </div>
                {withVip && (
                  <div className="SuperwinListVipRow SuperwinListItem FlexRow Gap8">
                    <div className="GridCenter">
                      <CrownIcon />
                    </div>
                    <div className="GridCenter">
                      <div className="TextDefaultBold">
                        {mainColumnText[rowType.VIP]}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {mergeArrays(dataItem).map((mainItem, count, arr) => {
                const mainItemVip = dataItem[rowType.VIP]?.find(
                  el => el.nominal === mainItem.nominal
                )
                const mainItemMain = dataItem[rowType.MAIN]?.find(
                  el => el.nominal === mainItem.nominal
                )
                const isLast = count === arr.length - 1
                const columnMainText = getColumnItemsText(mainItemMain)
                const columnVipText = getColumnItemsText(mainItemVip)
                const mainText = getColumnItemsText(mainItem)
                return (
                  <div
                    key={`list_item_${dataItem.hall}_${mainItem.nominal}`}
                    className={`FlexColumn SuperwinListColumn ${
                      isLast ? `SuperwinListlastColumn` : ``
                    }`}
                  >
                    <div className="SuperwinListHeader SuperwinListNominalHeader SuperwinListItem TableHeaderText">
                      {mainText.nominal}
                    </div>
                    <SuperwinListRow
                      value={columnMainText.value}
                      data={mainItemMain}
                      hall={dataItem.hall}
                      setModal={setModal}
                    />
                    {withVip && (
                      <SuperwinListRow
                        value={columnVipText.value}
                        data={mainItemVip}
                        hall={dataItem.hall}
                        setModal={setModal}
                        is_vip={true}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          )
        })
      ) : (
        <MobileList data={state.data} setModal={setModal} />
      )}
      {!!modal.data && modal.hall && (
        <IncreaseModal
          data={modal.data}
          is_vip={modal.is_vip}
          hall={modal.hall}
          close={() => setModal({ data: undefined, is_vip: false })}
          success={toggleSuccessModal}
        />
      )}
      {!!successModal && (
        <SuccessModal
          data={successModal}
          close={() => {
            toggleSuccessModal(undefined)
            refresh()
          }}
        />
      )}
    </div>
  )
}

export default SuperwinList
