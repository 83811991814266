import { CrudDataType, NotifiValidateData } from "./types"

export const getTypes = (type: string, id: number | string) => ({
  isNew: id === `new` || !id,
  isDraftType: type === `draft`,
  isBasketType: type === `basket`,
  isStatisticType: type === `statistic`
})

export const getLocationPlaceholder = (show: boolean) =>
  show ? `Заполните хотя бы одно из полей Локация / Город / Клубы` : ``
export const getNecessaryText = (show: boolean) =>
  show ? `Поле обязательно для заполнения` : ``
export const getEmptyTextArea = (text?: string) =>
  !text ||
  `${text}` ==
    `<p></p>
`
    ? `Поле обязательно для заполнения`
    : ``

export const checkValue = (value: (string | number)[]) =>
  !!value.length &&
  value[value.length - 1] &&
  typeof value[value.length - 1] === `number`

export const crudInitial: CrudDataType = {
  text: undefined,
  title: undefined,
  comment: undefined,
  region_id: [],
  city_id: [],
  country_id: [],
  halls: [],
  date: null,
  is_require: true,
  is_push: true,
  is_draft: false,
  files: []
}

export const validateInitial: NotifiValidateData = {
  location: ``,
  text: ``,
  title: ``
}

export const countryArr = [`Россия`, `Донецк`, `Армения`]

export const typography = {
  hallsNumbersCopied: "Номера клубов скопированы"
}

export const urls = {
  dictCities: "dictionary/cities",
  dictHallsByCities: "dictionary/halls-by-cities"
}
