import React from "react"
import { Link } from "react-router-dom"
import { ConfigItemProps } from "../types"

export const ConfigItemCmp: React.FC<ConfigItemProps> = ({ configItems }) => (
  <ul className="WikiLinksList">
    {configItems.map(item => (
      <li key={item.name}>
        <Link
          to={
            (item.route as string) ||
            encodeURIComponent(item.name.replace(/ /g, "-"))
          }
          className="SubtitleSecondText"
        >
          {item.name}
        </Link>
        {item.inside && <ConfigItemCmp configItems={item.inside} />}
      </li>
    ))}
  </ul>
)
