import React, { useState } from "react"

import { ButtonFaded, ButtonPrimary, buttonText } from "Components/Button"
import DatePickerComponent, {
  RangePickerComponent,
  TimePickerComponent
} from "Components/DatePicker"

import SortTableFilter from "../SortFilter"

import { FromToDatesFilterProps, FromToDatesFilterState } from "./types"

import moment, { Moment } from "moment"
import { SortOrders } from "../../types"
import { fromToConstants } from "../FromToFilter/constants"

const fromToDateText = {
  datePlaceholder: `__.__.____`,
  fromLabel: `Дата и время от`,
  timePlaceholder: `__:__`,
  toLabel: `Дата и время до`,
  rangeLabel: "Укажите период"
}

export const getInitialByFilter = (filter?: {
  from?: string | null
  to?: string | null
}) => {
  let initialFromDate
  let initialFromTime
  let initialToDate
  let initialToTime

  const changeDate = (value: string) =>
    moment?.utc(value, `YYYY-MM-DDTHH:mm:ss`)
  const changeTime = (value: string) => moment(value, `YYYY-MM-DDTHH:mm:ss`)

  if (!!filter?.from) {
    initialFromDate = changeDate(filter.from)

    initialFromTime = changeTime(filter.from)
  }

  if (!!filter?.to) {
    initialToDate = changeDate(filter.to)

    initialToTime = changeTime(filter.to)
  }

  return { initialFromDate, initialFromTime, initialToDate, initialToTime }
}

const FromToDatesFilter = ({
  fromTime,
  fromDate,
  toDate,
  toTime,
  hasSort,
  order,
  changeFilter,
  closeFunc,
  rangeOnly,
  skipDisabled
}: FromToDatesFilterProps) => {
  const initialActive = !!fromDate || !!toDate || hasSort

  const [state, setState] = useState<FromToDatesFilterState>({
    fromTime: fromTime || undefined,
    fromDate: fromDate || undefined,
    toDate: toDate || undefined,
    toTime: toTime || undefined,
    order: hasSort ? order : SortOrders.asc,
    hasSort
  })

  const disabledFrom = rangeOnly
    ? !state.fromDate
    : (!!state.fromDate && !state.fromTime) ||
      (!state.fromDate && !!state.fromTime)
  const disabledTo = rangeOnly
    ? !state.toDate
    : (!!state.toDate && !state.toTime) || (!state.toDate && !!state.toTime)

  const disableDateFrom = (current: Moment) => {
    const currentValue = current
      ?.utc()
      .startOf(`day`)
      .valueOf()
    const toDateValue = state.toDate
      ?.utc()
      .endOf(`day`)
      .valueOf()
    return (
      currentValue >
        moment()
          .endOf(`day`)
          .valueOf() ||
      (!!toDateValue && currentValue > toDateValue)
    )
  }

  const disableDateTo = (current: Moment) => {
    const currentValue = current
      ?.utc()
      .endOf(`day`)
      .valueOf()
    const fromDateValue = state.fromDate
      ?.utc()
      .startOf(`day`)
      .valueOf()
    return (
      currentValue >
        moment()
          .endOf(`day`)
          .valueOf() ||
      (!!fromDateValue && fromDateValue > currentValue)
    )
  }

  const disabledTime = () => {
    if (!!state.toDate && !!state.fromDate && !!state.toTime) {
      const from = state.fromDate.startOf(`day`).valueOf()
      const to = state.toDate.startOf(`day`).valueOf()
      if (from === to) {
        const hourTo = state.toTime.hour()
        const minutesTo = state.toTime.minutes()
        const resultHours: number[] = []
        for (let i = !!minutesTo ? hourTo + 1 : hourTo; i <= 24; i++) {
          resultHours.push(i)
        }
        return {
          disabledHours: () => resultHours,
          disabledMinutes: (selectedHour: number) => {
            if (selectedHour === hourTo) {
              const resultMinutes: number[] = []
              for (let i = minutesTo; i <= 60; i++) {
                resultMinutes.push(i)
              }
              return resultMinutes
            }
            return []
          }
        }
      }
      return {
        disabledHours: () => []
      }
    } else {
      return { disabledHours: () => [] }
    }
  }

  const disabledTimeTo = () => {
    if (!!state.toDate && !!state.fromDate && !!state.fromTime) {
      const from = state.fromDate.startOf(`day`).valueOf()
      const to = state.toDate.startOf(`day`).valueOf()
      if (from === to) {
        const hourTo = state.fromTime.hour()
        const minutesTo = state.fromTime.minutes()
        const resultHours: number[] = []
        for (let i = 0; i <= hourTo - 1; i++) {
          resultHours.push(i)
        }
        return {
          disabledHours: () => resultHours,
          disabledMinutes: (selectedHour: number) => {
            if (selectedHour === hourTo) {
              const resultMinutes: number[] = []
              for (let i = 0; i <= minutesTo; i++) {
                resultMinutes.push(i)
              }
              return resultMinutes
            }
            return []
          }
        }
      }
      return {
        disabledHours: () => []
      }
    } else {
      return { disabledHours: () => [] }
    }
  }

  return (
    <div>
      <SortTableFilter
        order={state.order}
        changeOrder={value => setState({ ...state, order: value || undefined })}
        hasSort={state.hasSort}
        changeHasSort={value => setState({ ...state, hasSort: value })}
      />
      <div className="FlexColumn Gap18 PaddingTop18">
        {rangeOnly ? (
          <div>
            <div className="BodySecondText PaddingBottom6">
              {fromToDateText.rangeLabel}
            </div>
            <RangePickerComponent
              datesAsObject
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={{ from: state.fromDate!, to: state.toDate! }}
              disabledDate={(current: Moment) =>
                skipDisabled
                  ? false
                  : moment(current).valueOf() >
                    moment()
                      .endOf(`day`)
                      .valueOf()
              }
              onChange={dates => {
                setState({
                  ...state,
                  fromDate: dates[0],
                  toDate: dates[1]
                })
              }}
            />
          </div>
        ) : (
          <>
            <div>
              <div className="BodySecondText PaddingBottom6">
                {fromToDateText.fromLabel}
              </div>
              <div className="FromToDatesItem FlexRow Gap8">
                <DatePickerComponent
                  value={state.fromDate}
                  disabledDate={disableDateFrom}
                  format="DD.MM.YYYY"
                  placeholder={fromToDateText.datePlaceholder}
                  onChange={value => {
                    const fromTimeChange =
                      !state.fromTime && value
                        ? moment()
                            .hour(0)
                            .minutes(0)
                            .second(0)
                        : state.fromTime
                    setState({
                      ...state,
                      fromDate: value || undefined,
                      fromTime: fromTimeChange
                    })
                  }}
                />
                <TimePickerComponent
                  popupClassName="FromToDatesItemTimePickerPopup"
                  value={state.fromTime}
                  disabledTime={disabledTime}
                  placeholder={fromToDateText.timePlaceholder}
                  onChange={value =>
                    setState({ ...state, fromTime: value || undefined })
                  }
                />
              </div>
            </div>
            <div>
              <div className="BodySecondText PaddingBottom6">
                {fromToDateText.toLabel}
              </div>
              <div className="FromToDatesItem FlexRow Gap8">
                <DatePickerComponent
                  value={state.toDate}
                  disabledDate={disableDateTo}
                  format="DD.MM.YYYY"
                  onChange={value => {
                    const toTimeChange =
                      !state.toTime && value
                        ? moment()
                            .hour(0)
                            .minutes(0)
                            .second(0)
                        : state.toTime
                    setState({
                      ...state,
                      toDate: value || undefined,
                      toTime: toTimeChange
                    })
                  }}
                  placeholder={fromToDateText.datePlaceholder}
                />
                <TimePickerComponent
                  popupClassName="FromToDatesItemTimePickerPopup"
                  value={state.toTime}
                  disabledTime={disabledTimeTo}
                  onChange={value =>
                    setState({ ...state, toTime: value || undefined })
                  }
                  placeholder={fromToDateText.timePlaceholder}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="MarginTop16 FlexRow FilterButtonsWrapper">
        {initialActive && (
          <ButtonFaded
            onClick={() => {
              changeFilter({
                from: undefined,
                to: undefined,
                order: undefined,
                hasSort: false
              })

              !!closeFunc && closeFunc()
            }}
          >
            {fromToConstants.clearText}
          </ButtonFaded>
        )}
        <ButtonPrimary
          disabled={(disabledFrom || disabledTo) && !state.hasSort}
          onClick={() => {
            let newFrom =
              state.fromDate && state.fromTime
                ? `${state.fromDate.format(
                    `YYYY-MM-DD`
                  )}T${state.fromTime.format(`HH:mm:ss`)}.000Z`
                : undefined

            let newTo =
              state.toDate && state.toTime
                ? `${state.toDate.format(`YYYY-MM-DD`)}T${state.toTime.format(
                    `HH:mm:ss`
                  )}.000Z`
                : undefined
            if (rangeOnly) {
              newFrom = state.fromDate && state.fromDate.toISOString()
              newTo = state.toDate && state.toDate.toISOString()
            }
            changeFilter({
              from: newFrom,
              to: newTo,
              order: state.order,
              hasSort: state.hasSort
            })
            if (!!closeFunc) {
              closeFunc()
            }
          }}
        >
          {buttonText.apply}
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default FromToDatesFilter
