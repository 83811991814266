import React, { useState } from "react"
import { ButtonPrimary } from "Components/Button"
import TooltipComponent from "Components/Tooltip"
import { contactsHeaderPageProps } from "../types"
import { getHrefFromSectionId } from "../helpers"
import { isContactsMobile } from "../index"
import { contactsInfoTitle, editMsg, endEditingTitle } from "../constants"

const ContactsHeader = ({
  contactsState,
  setContactsState,
  allowEditContacts
}: contactsHeaderPageProps) => {
  const [linkState, setLinkState] = useState(0)

  const tabs = contactsState.data.map(item => {
    return (
      item.tab_anchor && (
        <TooltipComponent
          key={item.section_id}
          className="ContactsPageTooltip"
          placement="top"
          title={item.title}
        >
          <a
            key={item.section_id}
            href={getHrefFromSectionId(item.section_id)}
            className={`ContactsPageHeader_tab  ${
              linkState === item.section_id
                ? `ContactsPageHeader_tabActive`
                : ``
            }`}
            onClick={() => setLinkState(item.section_id)}
          >
            <span className="ContactsPageHeader_tab_text">{item.title}</span>
          </a>
        </TooltipComponent>
      )
    )
  })

  return (
    <>
      <div className="ContactsPageHeaderMainWrapper">
        <div className="ContactsPageHeaderWrapper">
          <div className="ContactsPageHeader_title">{contactsInfoTitle}</div>
          {allowEditContacts && (
            <>
              {contactsState.isEditing ? (
                <div className="ContactsPageHeader_editBtnWrapper">
                  <ButtonPrimary
                    className="ContactsPageHeader_cancelBtn ContactsBtn"
                    onClick={() =>
                      setContactsState({
                        ...contactsState,
                        isEditing: false
                      })
                    }
                  >
                    {endEditingTitle}
                  </ButtonPrimary>
                </div>
              ) : (
                !isContactsMobile && (
                  <ButtonPrimary
                    className="ContactsPageHeader_editBtn ContactsBtn"
                    disabled={!allowEditContacts || !contactsState.data.length}
                    onClick={() =>
                      setContactsState({
                        ...contactsState,
                        isEditing: true
                      })
                    }
                  >
                    {editMsg}
                  </ButtonPrimary>
                )
              )}
            </>
          )}
        </div>
      </div>
      {!!contactsState.data.length && (
        <div className="ContactsPageHeader_tabsWrapper">
          <div className="ContactsPageHeader_tabsInner">{tabs}</div>
        </div>
      )}
    </>
  )
}

export default ContactsHeader
