import React from "react"

import {
  NotifyUserData,
  NotifyUserDataFields,
  UserFieldsWithId
} from "../types"

import moment from "moment"

import TableActions from "Components/TableNew/TableActions"
import { ReactComponent as EditIcon } from "Components/icons/crud/edit_28.svg"
import { ReactComponent as PauseIcon } from "Components/icons/crud/pause-28.svg"
import { ReactComponent as DeleteIcon } from "Components/icons/crud/delete_red_24.svg"
import { UserCrudFields } from "../UserCrud/types"

export const getCount = (length: number) =>
  `Пользователей в рассылке: ${length}`

export const tableButtons = {
  add: `Добавить пользователя`,
  pause: `Остановить рассылку`,
  resume: `Возобновить рассылку`,
  remove: `Очистить список`
}

const tableActions = {
  width: 220,
  edit: `Редактировать`,
  pause: `Остановить рассылку`,
  resume: `Возобновить рассылку`,
  remove: `Удалить`
}

export const textByField = {
  [NotifyUserDataFields.name]: `Имя сотрудника`,
  [NotifyUserDataFields.email]: `Электронная почта`,
  [NotifyUserDataFields.updated_date]: {
    title: `Дата добавления`,
    date: `DD.MM.YYYY`,
    time: `HH:mm`
  },
  [NotifyUserDataFields.is_active]: {
    title: `Статус`,
    active: {
      label: `В рассылке`,
      background: `Green10Background`,
      color: `Green600Color`
    },
    not_active: {
      label: `Остановлена`,
      background: `Gray100Background`,
      color: `Gray700Color`
    }
  },
  action: {
    title: `Действия`,
    edit: `Редактировать`,
    pause: `Остановить рассылку`,
    remove: `Удалить`
  }
}

type GetColumnsProps = {
  pauseCurrent: (id: number, is_active: boolean) => void
  removeCurrent: (id: number) => void
  editCurrent: (id: UserFieldsWithId) => void
  hall: number
}

export const getColumns = ({
  pauseCurrent,
  removeCurrent,
  editCurrent,
  hall
}: GetColumnsProps) => [
  {
    title: () => (
      <div className="MarginLeft16">
        {textByField[NotifyUserDataFields.name]}
      </div>
    ),
    width: 200,
    dataIndex: NotifyUserDataFields.name,
    key: NotifyUserDataFields.name,
    render: (name: string) => {
      return <div className="MarginLeft16 TextOne">{name}</div>
    }
  },
  {
    title: textByField[NotifyUserDataFields.email],
    width: 120,
    dataIndex: NotifyUserDataFields.email,
    key: NotifyUserDataFields.email,
    render: (email: string) => {
      return <div className="TextOne NotifyStatusTableEmail">{email}</div>
    }
  },
  {
    title: textByField[NotifyUserDataFields.updated_date].title,
    width: 120,
    dataIndex: NotifyUserDataFields.updated_date,
    key: NotifyUserDataFields.updated_date,
    render: (updated_date: string) => {
      const date = moment(updated_date).format(
        textByField[NotifyUserDataFields.updated_date].date
      )
      const time = moment(updated_date).format(
        textByField[NotifyUserDataFields.updated_date].time
      )
      return (
        <div className="FlexRow Gap8">
          <span className="TextOne Shark500Color">{date}</span>
          <span className="TextOne Gray600Color">{time}</span>
        </div>
      )
    }
  },
  {
    title: textByField[NotifyUserDataFields.is_active].title,
    width: 120,
    dataIndex: NotifyUserDataFields.is_active,
    key: NotifyUserDataFields.is_active,
    render: (is_active: boolean) => {
      const config = is_active
        ? textByField[NotifyUserDataFields.is_active].active
        : textByField[NotifyUserDataFields.is_active].not_active
      return (
        <div
          className={`NotifyStatusTableStatus GridCenter ${config.background}`}
        >
          <span className={`${config.color} StatusText`}>{config.label}</span>
        </div>
      )
    }
  },
  {
    title: () => (
      <div className="MarginRight16">{textByField.action.title}</div>
    ),
    width: 120,
    dataIndex: NotifyUserDataFields.user_id,
    key: NotifyUserDataFields.user_id,
    align: `right`,
    render: (user_id: number, record: NotifyUserData) => {
      const isActive = record[NotifyUserDataFields.is_active]
      return (
        <div className="MarginRight16">
          <TableActions width={tableActions.width}>
            <div className="FlexColumn Gap24">
              <div
                className="FlexRow Gap8"
                onClick={() =>
                  editCurrent({
                    id: record[NotifyUserDataFields.user_id],
                    [UserCrudFields.name]: record[NotifyUserDataFields.name],
                    [UserCrudFields.email]: record[NotifyUserDataFields.email],
                    [UserCrudFields.halls]: [hall]
                  })
                }
              >
                <EditIcon />
                <span className="TextOne">{tableActions.edit}</span>
              </div>
              <div
                className="FlexRow Gap8"
                onClick={() =>
                  pauseCurrent(user_id, record[NotifyUserDataFields.is_active])
                }
              >
                <PauseIcon />
                <span className="TextOne">
                  {isActive ? tableActions.pause : tableActions.resume}
                </span>
              </div>
              <div
                className="FlexRow Gap8"
                onClick={() => removeCurrent(user_id)}
              >
                <DeleteIcon />
                <span className="TextOne">{tableActions.remove}</span>
              </div>
            </div>
          </TableActions>
        </div>
      )
    }
  }
]
