import moment, { Moment } from "moment"

export const viewPenaltyModalText = {
  title: "Штраф",
  setDate: "Нарушение выставлено",
  findDate: "Дата выявления нарушения",
  appealDate: "Дата обращения",
  responsible: "Ответственный от клуба",
  comment: "Комментарий к устранению",
  resolveAttachments: "Документы об устранении",
  editButton: "Редактировать",
  cancelButton: "Отменить",
  resolveButton: "Согласовать",
  rejectButton: "Отклонить",
  adminComment: "Комментарий отдела контроля"
}

export const formatDate = (date?: string | Moment) =>
  moment(date).format("DD.MM.YYYY")
