import React from "react"

import { formatToCurrency, isMobile } from "constants/index"

import moment from "moment"

import { ReactComponent as EmptyChart } from "Containers/SportBoom/img/empty_chart.svg"
import { ChartRequestDetailData } from "./types"

export enum ChartTypes {
  retention = `retention`,
  guest_count = `guest_count`,
  all_bets = `all_bets`,
  win = `win`
}

export const chartColortByType = {
  [ChartTypes.retention]: `#845EF7`,
  [ChartTypes.guest_count]: `#22B8CF`,
  [ChartTypes.all_bets]: `#51CF66`,
  [ChartTypes.win]: `#339AF0`
}

export const defaultChartTypeStyles = {
  color: `#DEE2E6`,
  background: `#FFFFFF`
}

export const initialChartType = {
  value: ChartTypes.guest_count,
  color: chartColortByType[ChartTypes.guest_count],
  background: `#E3FAFC`,
  label: `Кол-во гостей`
}

export const chartTypesOptions = [
  initialChartType,
  {
    value: ChartTypes.all_bets,
    color: chartColortByType[ChartTypes.all_bets],
    background: `#EBFBEE`,
    label: `Объем денежных ставок`
  },
  {
    value: ChartTypes.win,
    color: chartColortByType[ChartTypes.win],
    background: `#E7F5FF`,
    label: `WIN`
  },
  {
    value: ChartTypes.retention,
    color: chartColortByType[ChartTypes.retention],
    background: `#F3F0FF`,
    label: `Удержание`
  }
]

export enum ChartPeriod {
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly"
}

export const chartConfig = {
  chart: {
    type: "column",
    marginLeft: 60,
    marginRight: 0,
    height: 425
  },
  title: {
    text: ""
  },
  xAxis: {
    crosshair: {
      className: `ChartsCrosshair`,
      width: 1
    },
    tickWidth: 0
  },
  yAxis: {
    crosshair: {
      width: 1,
      color: `#E9ECEF`
    },
    title: {
      text: ""
    },
    gridLineDashStyle: `Dash`,
    gridLineColor: `#F2F2F7`,
    gridLineWidth: 1,
    gridZIndex: 0,
    plotLines: [
      {
        dashStyle: `Solid`,
        color: "#AEAEB2",
        value: 0,
        width: 1
      }
    ]
  },
  tooltip: {
    outside: true,
    shared: true,
    borderRadius: 10,
    borderWidth: 0,
    useHTML: true,
    split: true,
    className: "SportboomInOutTooltip"
  },
  legend: {
    shadow: false,
    enabled: false
  },

  plotOptions: {
    column: {
      grouping: false,
      shadow: false,
      borderWidth: 0,
      borderRadius: 8,
      pointWidth: 45,
      cursor: `pointer`
    }
  },
  series: {
    name: ``
  }
}

export const chartText = {
  title: `Показатели за период`,
  guest_count: `Кол-во гостей`,
  win: `WIN`,
  ggr: `GGR`,
  ggr_tooltip: `GGR или Gross Gaming Revenue (Валовой игровой доход) - игровая прибыль. Рассчитывается по формуле: GGR = IN – OUT, где IN – сумма всех ставок, OUT – сумма всех выплат по ставкам.`,
  retention: `Удержание`,
  retention_tooltip: `Удержание - маржинальность спортивных ставок. Показывает, какую долю составляет GGR в объеме всех ставок в клубе. Показатель выражается в процентах.`,
  bets_count: `Кол-во ставок ${isMobile ? `\n` : ``}денежный`,
  freebets_count: `Кол-во ставок ${isMobile ? `\n` : ``}фрибеты`,
  all_bets_count: `Объем ставок ${isMobile ? `\n` : ``}денежный`,
  all_freebets_count: `Объем ставок ${isMobile ? `\n` : ``}фрибеты`,
  tableLink: `Откройте данные в таблице при нажатии\nна столбец`,
  win_bets_sum: `Сумма сыгравших ставок`,
  bets_sum: `Сумма ставок в игре`
}

export const dataEmptyChart = {
  desktop: {
    title: `Нет данных`,
    subtitle: `За необходимый период по данному клубу\n нет данных для построения графика`,
    icon: <EmptyChart />
  },
  mobile: {
    title: `Нет данных`,
    subtitle: `За необходимый период\n по данному клубу нет данных\n для построения графика`,
    icon: <EmptyChart />
  }
}

export const ChartLabelByData = (
  pointData: ChartRequestDetailData,
  period: ChartPeriod,
  pointDate: string,
  type: ChartTypes
) => {
  const date = moment(pointDate, `DD.MM.YYYY`).format(`ddd, DD MMMM YYYY`)

  const ggrValue = formatToCurrency({
    amount: Number(pointData.ggr),
    withoutFixed: true
  })
  const win = formatToCurrency({
    amount: Number(pointData.win)
  })
  const bets_countValue = formatToCurrency({
    amount: Number(pointData.bets_count),
    withoutFixed: true
  })
  const freebets_countValue = formatToCurrency({
    amount: Number(pointData.freebets_count),
    withoutFixed: true
  })
  const all_bets_countValue = formatToCurrency({
    amount: Number(pointData.all_bets_count),
    withoutFixed: true
  })
  const all_freebets_countValue = formatToCurrency({
    amount: Number(pointData.all_freebets_count),
    withoutFixed: true
  })
  const win_bets_sum = formatToCurrency({
    amount: Number(pointData.win_bets_sum),
    withoutFixed: true
  })
  const bets_sum = formatToCurrency({
    amount: Number(pointData.bets_sum),
    withoutFixed: true
  })

  const showLinkField =
    (type === ChartTypes.win && !!pointData.win) ||
    (type === ChartTypes.guest_count && pointData.guest_count) ||
    (type === ChartTypes.retention && !!pointData.retention) ||
    (type === ChartTypes.all_bets && !!pointData.all_bets_count)

  const linkField =
    isMobile || !showLinkField
      ? `<div />`
      : `<div class="FlexRow">
           <div style="padding-right: 4px">
             <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 4C3.25 3.58579 3.58579 3.25 4 3.25H8.33333C8.74755 3.25 9.08333 3.58579 9.08333 4C9.08333 4.41421 8.74755 4.75 8.33333 4.75H4.95C4.83954 4.75 4.75 4.83954 4.75 4.95V15.05C4.75 15.1605 4.83954 15.25 4.95 15.25H15.05C15.1605 15.25 15.25 15.1605 15.25 15.05V11.6667C15.25 11.2525 15.5858 10.9167 16 10.9167C16.4142 10.9167 16.75 11.2525 16.75 11.6667V16C16.75 16.4142 16.4142 16.75 16 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16V4ZM11.5556 4.75C11.1413 4.75 10.8056 4.41421 10.8056 4C10.8056 3.58579 11.1413 3.25 11.5556 3.25H16C16.4142 3.25 16.75 3.58579 16.75 4V8.44444C16.75 8.85866 16.4142 9.19444 16 9.19444C15.5858 9.19444 15.25 8.85866 15.25 8.44444V6.2935C15.25 6.11532 15.0346 6.02609 14.9086 6.15208L8.53033 12.5303C8.23744 12.8232 7.76256 12.8232 7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697L13.8479 5.09142C13.9739 4.96543 13.8847 4.75 13.7065 4.75H11.5556Z" fill="#FCE000"/>
              </svg>
           </div>
           <div class="TextDefault" style="color: #FCE000">` +
        chartText.tableLink +
        `</div>
           </div>`

  return (
    `<div class="ChartTooltip">
            <div class="HeadlineThirdText" style="color: #FCE000; text-transform: capitalize; padding-bottom: 8px">` +
    date +
    `</div>
          <div style="display: flex; padding-bottom: 12px">
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
    chartText.guest_count +
    `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
    pointData.guest_count +
    `</span>
            </div>
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
    chartText.win +
    `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
    win +
    ` ₽</span>
            </div>
          </div>
          <div style="display: flex; padding-bottom: 12px">
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
    chartText.ggr +
    `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
    ggrValue +
    ` ₽</span>
            </div>
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
    chartText.retention +
    `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
    pointData.retention +
    ` %</span>
            </div>
          </div>
          <div style="display: flex; padding-bottom: 12px">
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
    chartText.bets_count +
    `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
    bets_countValue +
    `</span>
            </div>
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
    chartText.freebets_count +
    `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
    freebets_countValue +
    `</span>
            </div>
          </div>
          <div style="display: flex; padding-bottom: 12px">
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
    chartText.all_bets_count +
    `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
    all_bets_countValue +
    ` ₽</span>
            </div>
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
    chartText.all_freebets_count +
    `</span>
                <span class="BodyFirstText" style="color: #FFFFFF">` +
    all_freebets_countValue +
    ` Ф</span>
        </div>
        </div>
          <div style="display: flex; padding-bottom: 12px">
            <div style="display: flex; flex-direction: column; width: 50%">
                <span class="OverlineText" style="color: #868E96">` +
    chartText.win_bets_sum +
    `</span>
    <span class="BodyFirstText" style="color: #FFFFFF">` +
    win_bets_sum +
    ` ₽</span>
    </div>
    <div style="display: flex; flex-direction: column; width: 50%">
      <span class="OverlineText" style="color: #868E96">` +
    chartText.bets_sum +
    `</span>
    <span class="BodyFirstText" style="color: #FFFFFF">` +
    bets_sum +
    ` ₽</span>
        </div>
    </div>` +
    linkField +
    `</div>`
  )
}
