import React from "react"
import InputComponent from "Components/Input"
import { Step3FieldProps } from "Containers/Sms/types"
import { smsConstants } from "Containers/Sms/constants"

const Sum = ({ smsState, setSmsState, ...rest }: Step3FieldProps) => {
  return (
    <div className="CreateSms_input CreateSms_inputShort CreateSms_inputShort_single">
      <label className="CreateSms_inputLabel">
        {smsConstants.step3.sumTitle}
      </label>
      <div className="CreateSms_inputInner">
        <InputComponent
          greyBackground
          type="number"
          min={0}
          hideControls
          value={smsState.extraParams.sum}
          onChange={({ target: { value } }) => {
            setSmsState({
              ...smsState,
              extraParams: {
                ...smsState.extraParams,
                sum: value
              }
            })
          }}
          placeholder={smsConstants.step3.sumTitle}
          {...rest}
        />
      </div>
    </div>
  )
}

export default Sum
