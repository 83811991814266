export const CASHBOX_FILTER_NAME = "cashboxFilter"

export enum CashboxFilterValues {
  cashbox = "cashbox",
  collection = "collection",
  transfer = "transfer",
  cashbox_collection = "cashbox_collection"
}

export const CashboxFilterLabel = {
  [CashboxFilterValues.cashbox]: "Касса",
  [CashboxFilterValues.collection]: "Инкассация",
  [CashboxFilterValues.transfer]: "Перенос баланса",
  [CashboxFilterValues.cashbox_collection]: "Касса и инкассация"
}

export const CashBoxFilterOptions = [
  {
    value: CashboxFilterValues.cashbox,
    label: CashboxFilterLabel[CashboxFilterValues.cashbox]
  },
  {
    value: CashboxFilterValues.collection,
    label: CashboxFilterLabel[CashboxFilterValues.collection]
  }
]

export const CashBoxFilterOptionsCanary = [
  ...CashBoxFilterOptions,
  {
    value: CashboxFilterValues.transfer,
    label: CashboxFilterLabel[CashboxFilterValues.transfer]
  }
]
