import React, { useCallback, useMemo, useState } from "react"

import { DetailModalProps, TableData } from "../types"
import {
  classByStatus,
  emptyTableInsideText,
  tableConstants
} from "../constants"
import { DetailComponentSmallRow } from "Components/Detail"
import { ButtonNotifi, MobileBackButton } from "Components/Button"
import NewTableComponent from "Components/TableNew"
import { ReactComponent as DeleteIcon } from "Components/icons/delete_red.svg"
import TableCard from "Components/TableNew/TableCards/TableCard"
import TableCardCollapsible from "Components/TableNew/TableCards/TableCardCollapsible"
import TabsComponent from "Components/Tabs"
import { EmptyData, EmptyTableLine } from "Components/EmptyTable"

import {
  detailConfigByData,
  tableDetailConstants,
  createCommonCardItems,
  createGuestCardItems,
  createCollapsibleConfig,
  betNumberText,
  getDetailColumns
} from "./constants"

import { isMobile } from "constants/index"
import { BetBuilderTable } from ".."

type TableDetailMobileProps = {
  close: () => void
  listState: number[]
  activeTab: number
  toggleActiveTab: (bet: number) => void
  deleteItem: (bet: number) => void
  compareMobileData: TableData
  isComparison: boolean
  onWalletIdClick: (walletId: number) => void
}

const TableDetailMobile = ({
  listState,
  activeTab,
  toggleActiveTab,
  compareMobileData,
  deleteItem,
  isComparison,
  close,
  onWalletIdClick
}: TableDetailMobileProps) => {
  const tabsOptions = listState.map(item => ({
    value: item,
    label: betNumberText(item)
  }))

  const config1 = createCommonCardItems(compareMobileData)
  const config2 = createGuestCardItems(compareMobileData, onWalletIdClick)
  const configCollapsible = createCollapsibleConfig(compareMobileData)

  const currentNumber = betNumberText(compareMobileData.bet_number)

  const haveDetails = !!compareMobileData.details?.length

  return !!compareMobileData ? (
    <>
      <div className="MarginBottom16 MarginTop16">
        <MobileBackButton onClick={close} />
      </div>
      {!isComparison ? (
        <div className="HeadlineSecondText PaddingBottom8">{currentNumber}</div>
      ) : (
        <div className="SportboomTableDetailMobileTabs">
          <TabsComponent
            tabs={tabsOptions}
            activeTab={activeTab}
            toggleActiveTab={tab => toggleActiveTab(Number(tab))}
          />
        </div>
      )}
      <TableCard config={config1} />
      <TableCard config={config2} />
      {haveDetails ? (
        <TableCardCollapsible config={configCollapsible} />
      ) : (
        <div>
          <EmptyData {...emptyTableInsideText} titleClass="SubtitleFirstText" />
        </div>
      )}
      {isComparison && (
        <ButtonNotifi
          color="gray"
          className="SportboomTableDetailMobileDelete"
          onClick={() => deleteItem(compareMobileData.bet_number)}
        >
          <div className="GridCenter">
            <div className="FlexRow">
              <DeleteIcon />
              <span>{tableDetailConstants.deleteItem}</span>
            </div>
          </div>
        </ButtonNotifi>
      )}
    </>
  ) : (
    <div />
  )
}

const TableDetail = ({
  detailOpen,
  list,
  removeItem,
  close,
  onWalletIdClick
}: {
  detailOpen: DetailModalProps
  list: number[]
  removeItem: (value: number) => void
  close: () => void
  onWalletIdClick: (walletId: number) => void
}) => {
  const isComparison = !detailOpen.current
  const [listState, setListState] = useState<number[]>(list)
  const [activeTab, toggleActiveTab] = useState<number>(listState[0])
  const [isBetbuilderExpand, setIsBetbuilderExpand] = useState(false)
  const compareMobileData = useMemo(() => {
    const current = listState.includes(activeTab)
    if (current) {
      return detailOpen.dataList[activeTab]
    } else {
      const newActiveTab = listState[0]
      toggleActiveTab(newActiveTab)
    }
  }, [activeTab, listState])

  const mobileData = isComparison ? compareMobileData : detailOpen.currentData

  const deleteItem = useCallback(
    (bet: number) => {
      const filterList = listState.filter(item => item !== bet)
      removeItem(bet)
      setListState(filterList)
      if (!filterList[0]) {
        close()
      }
    },
    [listState]
  )

  const data = useMemo(() => {
    if (!!detailOpen.current && !!detailOpen.currentData) {
      return [detailOpen.currentData]
    } else {
      return listState.map(item => detailOpen.dataList[item])
    }
  }, [listState])

  return (
    <div
      className={
        isMobile
          ? "TableCardsContainer"
          : "SportboomTableDetailWrapper FlexColumn Gap32"
      }
    >
      {!(isMobile && !!mobileData) ? (
        data.map(item => {
          if (!item) {
            return <div key={`bet_undefined`} />
          }
          const title = `Ставка ${item?.bet_number}`
          const statusClassname = classByStatus(item.status)
          const detailData = detailConfigByData(item)

          return (
            <div key={`bet_${item.bet_number}`} className="FlexColumn Gap16">
              <div className="FlexRow SportboomTableDetail">
                <span className="HeadlineFirstText">{title}</span>
                <div className={statusClassname}>
                  {tableConstants.status.text[item.status]}
                </div>
              </div>
              <div className="SportboomTableDetailInfo FlexWrap FlexRow">
                <DetailComponentSmallRow data={detailData} pending={false} />
                {isComparison && (
                  <ButtonNotifi
                    color="gray"
                    onClick={() => deleteItem(item.bet_number)}
                  >
                    <div className="FlexRow">
                      <DeleteIcon />
                      <span>{tableDetailConstants.deleteItem}</span>
                    </div>
                  </ButtonNotifi>
                )}
              </div>
              <div>
                <NewTableComponent
                  expandable={{
                    onExpand: () => {
                      setIsBetbuilderExpand(prev => !prev)
                    },
                    expandRowByClick: true,
                    expandIconColumnIndex: -1,
                    rowExpandable: (record: any) => record.has_bet_builder,
                    expandedRowRender: (record: any) => {
                      return (
                        <BetBuilderTable
                          isOrange
                          tableClassName="SportboomInsideTableWrapper BorderNone"
                          rowClassName="SportboomInsideTableRow Detail BorderNone"
                          marketKey="market"
                          record={record}
                          columns={getDetailColumns({})}
                        />
                      )
                    }
                  }}
                  rowClassName="SportboomInsideTableRow Detail"
                  rowClassNameByRecord={record => {
                    if (record.has_bet_builder && isBetbuilderExpand)
                      return "BorderNone"

                    return ""
                  }}
                  columns={getDetailColumns({ isBetbuilderExpand })}
                  data={item.details.map(_item => ({
                    ..._item,
                    bet_number: item.bet_number
                  }))}
                  width={1200}
                  isOrange={true}
                  locale={{
                    emptyText: (
                      <div>
                        <EmptyTableLine
                          description={emptyTableInsideText.title}
                        />
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          )
        })
      ) : (
        <>
          <TableDetailMobile
            onWalletIdClick={onWalletIdClick}
            close={close}
            listState={listState}
            activeTab={activeTab}
            toggleActiveTab={toggleActiveTab}
            compareMobileData={mobileData}
            deleteItem={deleteItem}
            isComparison={isComparison}
          />
        </>
      )}
    </div>
  )
}

export default TableDetail
