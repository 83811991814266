import { StatusesDict } from "../../types"
import { StatusesCheckBoxFilterText } from "./types"

export const selectAllStatus = {
  id: 999,
  name: "Все"
}

export const defaultStatusesCheckBoxFilterText: StatusesCheckBoxFilterText = {
  header: "Статус"
}

export const getAllStatusesIds = (statuses?: StatusesDict) =>
  statuses?.map(status => status.id) || []
