import React, { useMemo } from "react"

import axios, { AxiosError } from "axios"

import { Link } from "react-router-dom"

import { message, notification, Upload } from "antd"
import { UploadFile, RcFile } from "antd/lib/upload/interface"

import { checkFileFormat } from "api/helpers"

import { ButtonNotifi } from "Components/Button"
import { ReactComponent as AttachIcon } from "Components/icons/attach.svg"
import { ReactComponent as CloseIcon } from "Components/icons/close.svg"
import { ReactComponent as DownloadIcon } from "./download.svg"
import { ReactComponent as DownloadIconSmall } from "./download-small-white.svg"
import { ReactComponent as ErrorFileIcon } from "../icons/error_toast.svg"

import {
  FileComponentProps,
  FilesList,
  MinifiedFileComponentProps
} from "./types"
import {
  iconsColorsClasses,
  typeIcon,
  convertSizeToString,
  fileUploadText
} from "./constans"

const textConstants = {
  uploadingFileError: "Ошибка загрузки файла"
}

export const MinifiedFileComponent: React.FC<MinifiedFileComponentProps> = ({
  type,
  name,
  link
}) => (
  <div
    className={`MinifiedFileComponent ${
      iconsColorsClasses[type] ? iconsColorsClasses[type] : ""
    }`}
  >
    <div className="MinifiedFileComponentName">{name}</div>
    <div>
      <Link
        className="MinifiedFileComponentLink"
        to={`${link}`}
        target="_blank"
        download={name}
      >
        <DownloadIconSmall />
      </Link>
    </div>
  </div>
)

export const UploadComponent = ({
  allowedTypes,
  disabled,
  maxCount,
  maxSize,
  addFile,
  fileList,
  changeFileList,
  pending,
  setPending
}: {
  maxSize?: number
  fileList: UploadFile[]
  addFile: (data: FilesList) => void
  changeFileList: (val: UploadFile[]) => void
  allowedTypes: string
  disabled?: boolean
  maxCount: number
  pending: boolean
  setPending: (data: boolean) => void
}) => {
  const uploadSingleFile = (options: any) => {
    const { file } = options

    const userFromStorage: string | undefined =
      localStorage.getItem(`user`) || undefined
    const user: { token: string; hall: number } | undefined = userFromStorage
      ? JSON.parse(userFromStorage)
      : userFromStorage

    const getFile = new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)

      reader.onload = () => {
        resolve({
          uid: file.uid,
          name: String(file.name).toLowerCase(),
          size: file.size,
          type: file.type,
          content: reader.result
        })
      }
      reader.onerror = error => reject(error)
    })

    const formData = new FormData()

    getFile.then((fileData: any) => {
      const size = fileData.size / 1024 / 1024

      formData.append(
        "file",
        new File([fileData.content as ArrayBuffer], fileData.name, {
          type: fileData.type
        })
      )

      if (!maxSize || size < maxSize) {
        return axios
          .post(
            `${process.env.REACT_APP_AUTH_URL}/v1/files/upload/single`,
            formData,
            {
              headers: {
                token: user ? user.token : ``,
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(({ data }) => {
            addFile({ ...data, uid: fileData.uid })
          })
          .catch((error: AxiosError) => {
            let errorText = textConstants.uploadingFileError

            if (
              error.response?.data?.errors &&
              Array.isArray(error.response?.data?.errors)
            ) {
              errorText = error.response.data.errors[0] || errorText
            }

            message.error(`${errorText}. Код ошибки: ${error.response?.status}`)
          })
          .finally(() => setPending(false))
      }
    })
  }

  const beforeUploadCheckSize = (file: RcFile): any => {
    return new Promise((resolve, reject) => {
      // check the file size - you can specify the file size you'd like here:
      if (maxSize) {
        const isLt5M = file.size / 1024 / 1024 <= maxSize
        if (!isLt5M) {
          notification.error({
            message: (
              <span className="FileErrorToastTitle">
                {fileUploadText.errors.title}
              </span>
            ),
            description: (
              <div className="FileErrorToastTextField">
                <span className="FileErrorToastToastText">
                  {fileUploadText.errors.size.label}
                </span>
                <br />
                <span className="FileErrorToastToastText">
                  {fileUploadText.errors.size.description(maxSize)}
                </span>
              </div>
            ),
            placement: "topRight",
            className: "FileErrorToastWrapper",
            duration: 5,
            icon: <ErrorFileIcon />,
            closeIcon: <CloseIcon />
          })
          reject(false)
        }
      }
      resolve(true)
    })
  }

  return (
    <Upload
      customRequest={options => {
        setPending(true)
        uploadSingleFile(options)
      }}
      beforeUpload={beforeUploadCheckSize}
      onChange={props => {
        let nextFileList = props.fileList
        if (maxSize) {
          nextFileList = props.fileList.filter(
            el => el.size && el?.size / 1024 / 1024 <= maxSize
          )
        }
        changeFileList(nextFileList)
      }}
      itemRender={() => null}
      fileList={fileList}
      accept={allowedTypes}
      maxCount={maxCount}
    >
      <ButtonNotifi
        className="UploadAttachmentsButton"
        disabled={disabled || pending}
      >
        <AttachIcon className="UploadAttachmentsIcon" />
      </ButtonNotifi>
    </Upload>
  )
}

const FileComponent: React.FC<FileComponentProps> = ({
  name,
  link,
  type,
  size,
  view,
  deleteFile,
  downloadFile,
  formattedSize,
  customDownloadLink
}) => {
  const icon = useMemo(() => typeIcon(type || checkFileFormat(name) || ``), [
    type
  ])
  const sizeString = useMemo(() => {
    if (size) {
      return convertSizeToString(size)
    } else if (formattedSize) {
      return formattedSize
    }
  }, [size, formattedSize])

  return (
    <div className="FileItemWrapper FlexRow">
      {icon}
      <div className="FileItemText FlexColumn">
        <div className="FileItemTextName">{name}</div>
        <div className="FileItemTextSize">{sizeString}</div>
      </div>
      {!!deleteFile && !view && (
        <div className="FileItemDelete" onClick={deleteFile}>
          <CloseIcon />
        </div>
      )}
      {(!downloadFile ?? (view && !!link)) && (
        <div className="FileItemDownload" onClick={downloadFile}>
          {!downloadFile ? (
            <Link
              className="FileItemDownloadLink"
              to={
                customDownloadLink
                  ? { pathname: customDownloadLink }
                  : `${link}`
              }
              target="_blank"
              download={name}
            >
              <DownloadIcon />
            </Link>
          ) : (
            <DownloadIcon />
          )}
        </div>
      )}
    </div>
  )
}

export default FileComponent
