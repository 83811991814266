import React from "react"

import moment from "moment"

import { ReactComponent as FilterMobileIcon } from "Components/icons/filter_settings.svg"
import { ReactComponent as FilterIcon } from "Components/icons/filter_arrow.svg"

import { ReportWithFilterProps } from "./types"
import { reportWithFilterText } from "./helpers"

export const MainPageFooter = () => (
  <div className="BaseLayoutContentFooter">{`${
    reportWithFilterText.footer
  }, ${moment().year()}`}</div>
)

const ReportWithFilter = ({
  filterComponent,
  isOpen,
  toggleFilter,
  pageContent,
  additionalContent,
  modalCmp,
  mobileFilter,
  toggleMobileFilter,
  additionalClassName,
  filterIcon
}: ReportWithFilterProps) => {
  return (
    <div
      className={`FlexRow ${additionalClassName ? additionalClassName : ""}`}
    >
      {mobileFilter && filterComponent}
      <div className="MobileFilterIcon" onClick={() => toggleMobileFilter()}>
        {filterIcon || <FilterMobileIcon />}
      </div>
      <div className="FlexRow">
        <div
          className={`MainPageFilterButtonWrapper ${
            isOpen
              ? `MainPageFilterButtonWrapperActive`
              : `MainPageFilterButtonWrapperUnActive`
          }`}
          onClick={toggleFilter}
        >
          <FilterIcon />
        </div>
      </div>
      <div>
        <div
          className={`MainPageContentWrapper ${
            isOpen
              ? `MainPageContentWrapperFilter`
              : `MainPageContentWrapperUnFilter`
          }`}
        >
          <div className="MainPageContentRestrictions">{pageContent}</div>
        </div>
        {additionalContent}
        {modalCmp}
        <MainPageFooter />
      </div>
      <div
        className={`MainFilterWrapper ${
          isOpen ? `MainFilterWrapperActive` : `MainFilterWrapperUnActive`
        }`}
      >
        {filterComponent}
      </div>
    </div>
  )
}

export default ReportWithFilter
