export enum GamesKeys {
  is_game_bets_history = "is_game_bets_history",
  is_super_win = "is_super_win",
  is_nominal = "is_nominal"
}

export type GamesData = {
  id: number | string
  name: string
  [GamesKeys.is_super_win]: string
  [GamesKeys.is_nominal]: boolean
  [GamesKeys.is_game_bets_history]: boolean
  image: string
  background_color: string | null
}

type countryLabels = "ru" | "kz" | "am" | "kg" | "dn"

export type TransactionStatuses = {
  statuses:
    | [
        {
          id: number
          name: string
        }
      ]
    | []
}

export type LocationsData = {
  country: { id: number; name: string; label: countryLabels }[]
  city: { [value: string]: { id: number; name: string }[] }
  region: { [value: string]: { id: number; name: string }[] }
}

export type TransferDictionary = {
  halls: number[]
}

export interface IDictionaryState {
  games: GamesData[]
  branch_halls: number[]
  locations: LocationsData
  transaction: TransactionStatuses
  transfer: TransferDictionary
}
