import { isMobile } from "constants/index"
import { sendingsTypeValues } from "./types"

export const smsConstants = {
  customText: "Свободный текст",
  modalSuccessText: "Ура! Заявка отправлена!",
  modalInfoText: "Активные гости за последний(ие):",
  modalDepositText: "Сумма депозита:",
  modalVisitText: "Количество посещений:",
  modalTitle: "Новая заявка на СМС-рассылку",
  modalTypeText: "Тип шаблона",
  modalSubTypeText: "Подтип шаблона",
  modalSmsText: "Текст рассылки",
  modalSendingTypeText: "Тип рассылки",
  modalCommentText: "Комментарий",
  modalExtraText: "Дополнительные параметры",
  modalFavoriteDateText: "Желаемая дата и время отправки:",
  modalCreditText: "Контактная информация:",
  modalFullNameText: "ФИО:",
  modalPhoneText: "Номер телефона:",
  modalCostText: "Стоимость рассылки:",
  msk: "(по МСК)",
  previewTitle: "Здесь можно  посмотреть как будет выглядеть ваша СМС-рассылка",
  previewSymbolCountText: "Количество символов:",
  previewTotalSmsText: "Всего СМС:",
  previewInfoText: `Обработка заявок на СМС-рассылку производится с понедельника по четверг, 
    с 10:00 до 18:00 по Москве. В пятницу — с 10:00 до 17:00. 
    При выборе типа шаблона "Свободный текст" указанный вами вариант будет рассмотрен и, в случае необходимости, скорректирован в течение 2-х рабочих дней.
    Просьба учитывать это при формировании запросов.`,
  previewSmsSizeText: "Длина одного СМС:",
  previewCountPhonesText: "Номеров для отправки:",
  previewLinkHref: "https://lk.betboom.ru/media/pdf/sms_require.pdf",
  previewLinkText: "Основные требования к составлению СМС",
  fromText: "от",
  fromTextCapitalized: "От",
  toText: "до",
  toTextCapitalized: "До",
  infoText:
    "Для формирования заявки на СМС-рассылку следуйте пошаговой инструкции ниже",
  smsTabsTitleText: {
    add: `Добавить рассылку`,
    title: `СМС-рассылки`,
    tooltip: `В данном разделе возможно как оставить заявку на СМС рассылку гостям, зарегистрированным в клубе, 
      так и посмотреть историю заявок. Вы можете выбрать предложенные шаблоны текстов или оставить заявку с вашим текстом
      - для этого необходимо выбрать тип шаблона “Свободный текст”. Дополнительные параметры выбора аудитории позволят
      отфильтровать гостей по активности, сумме депозита и количеству посещений. 
      При составлении заявки поле с адресом будет заполнено автоматически. 
      Если вам понадобится отредактировать адрес, вы можете указать желаемый адрес в комментариях. 
      В каждом пункте присутствуют подсказки, которые помогут вам составить заявку.`,
    tabs: {
      creating: `Создать заявку`,
      history: `История заявок`
    }
  },
  cancelBtnTxt: "Отменить",
  phonesErrText: "Некоторые номера введены неправильно. Пожалуйста, проверьте",
  phonesCountErr: "В СМС рассылке не может быть 0 номеров",
  calcTimeOut: 3000,
  smsUnCheckedPeriodValue: -1,
  urls: {
    settings: `v1/sms/settings`,
    validate: `v1/sms/validate`,
    create: `v1/sms/create`,
    calculate: `v1/sms/calculate`,
    history: `v1/sms/history`,
    cancel: `v1/sms/cancel`,
    excel: `v1/sms/history/excel`
  },
  step1: {
    orderNumber: 1,
    templatePlaceholder: `Выберите шаблон`,
    title: "Выберите шаблон рассылки",
    subTypeTitle: "Выберите подтип шаблона",
    tooltip: `Выбрав тему рассылки будут доступны шаблоны текстов для СМС. Символы вида ХХХ, при их наличии в тексте шаблона
       - это параметры, которые необходимо указать в следующем шаге`
  },
  step2: {
    orderNumber: 2,
    usualTitle: `Выберите текст рассылки`,
    customTitle: `Укажите текст рассылки`,
    customPlaceholder: `Введите текст СМС-рассылки`,
    info: `Обращаем ваше внимание, символы "XX" в тексте это переменные, которые будут указаны в шаге №3`
  },
  step3: {
    orderNumber: 3,
    address: "Адрес",
    addressPlaceholder: "Укажите адрес",
    bonusTooltip: "Учитываются только несгораемые бонусы",
    addressTooltip:
      "Адрес указывается автоматически. Если вам необходимо изменить адрес для рассылки - укажите новый адрес в поле Комментарий",
    title: "Выберите параметры рассылки",
    tooltip: `Ввод необходимых для формирования текста СМС параметров (например, дата/время для приглашения на мероприятие). 
      Поля для ввода меняются в зависимости от выбора текста шаблона в предыдущем шаге`,
    bonusTitle: "Сумма бонусов",
    commandPlaceholder: "Команда",
    dateTitle: "Дата мероприятия",
    datePlaceholder: "Укажите дату",
    moneyTitle: "Денежный баланс",
    monthNumberTitle: "Число месяца",
    monthNumberPlaceholder: "Число",
    sumTitle: "Сумма",
    timeTitle: "Время мероприятия",
    timePlaceholder: "__:__",
    timeFromTitle: "Время с",
    timeToTitle: "Время по"
  },
  step4: {
    orderNumber: 4,
    title: "Выберите тип рассылки",
    tooltip:
      "База гостей для рассылки СМС. Возможно отправить рассылку по всем зарегистрированным номерам в клубе, только по указанным или исключая указанные",
    separators: [",", " "]
  },
  step5: {
    orderNumber: 5,
    title: "Напишите комментарий к рассылке",
    tooltip:
      "Комментарий необходим в том случае, если шаблон требует корректировки или дополнения",
    info: "Это необязательный шаг",
    commentPlaceholder: "Комментарий"
  },
  step6: {
    orderNumber: 6,
    title: "Выберите дополнительные параметры",
    tooltip: `Пункт с дополнительным набором фильтров: - период активности гостей; - сумма депозита; - количество посещений. Есть возможность применить как один фильтр, так и все из перечисленных, заполняя необходимые поля`,
    tooltip_header: `Пункт с дополнительным набором фильтров:`,
    tooltip_listItem1: `период активности гостей`,
    tooltip_listItem2: `сумма депозита`,
    tooltip_listItem3: `количество посещений`,
    tooltip_bottom: `Есть возможность применить как один фильтр, так и все из перечисленных, заполняя необходимые поля`,
    periodTooltip: `Период, в который гости посещали клуб и совершали ставки. Пополнения через кассу без совершения ставок не считаются активностью. При отсутствии выбора периода, будут учтены активные гости за весь период работы клуба`,
    title2: "Это необязательный шаг",
    activeGuestsTitle: "Активные гости за",
    sumTitle: "Сумма депозита",
    sumTooltip: "Сумма денежных пополнений через кассу за указанный период",
    visitTitle: "Количество посещений",
    visitTooltip:
      "Количество посещений гостем клуба за выбранный период. Посещением считается только если гость совершал ставки. Пополнения через кассу без совершения ставок не считаются активностью. При отсутствии выбора периода, будут учтены посещения гостей за весь период работы клуба"
  },
  step7: {
    orderNumber: 7,
    title: "Выберите желаемую дату и время отправки",
    tooltip:
      "Пункт, в котором необходимо указать дату и время отправки рассылки (время указывается по мск)",
    dateTitle: "Дата",
    datePlaceholder: "Укажите дату",
    timeTitle: "Время",
    timePlaceholder: "Укажите время"
  },
  step8: {
    title: "Заполните контактную информацию",
    orderNumber: 8,
    phoneErrorText: "Неправильный номер",
    tooltip:
      "ФИО и контактный номер телефона ответственного от клуба для оперативной связи при возникновении вопросов по рассылке",
    fullName: "ФИО",
    namePlaceholder: "Укажите ваше имя",
    phoneTitle: "Номер телефона",
    phonePlaceholder: "Введите номер телефона"
  },
  tableColumns: {
    orderedTitle: "Заказано",
    doneTitle: "Выполнено",
    requestDateText: "Дата заявки (мск)",
    idText: "ID",
    favoriteSendDateText: "Желаемая\nдата отправки (мск)",
    statusText: "Статус",
    commentText: "Комментарий",
    simpleText: "Исходный текст",
    sendedText: "Отправленный текст",
    sendDateText: "Дата отправки (мск)",
    smsCountText: "Количество\nсмс",
    smsSendedCountText: "Отправлено\nсмс",
    walletCountText: "Количество\nкошельков",
    costText: "Стоимость\nрассылки"
  },
  tableColumnsKeys: {
    id: "id",
    requestDate: "requestDate",
    favoriteSendDate: "favoriteSendDate",
    status: "status",
    comment: "comment",
    text: "text",
    sendedText: "sendedText",
    sendDate: "sendDate",
    smsCount: "smsCount",
    smsSendedCount: "smsSended_count",
    walletCount: "walletCount",
    cost: "cost"
  },
  tableComment: {
    more: `...еще`,
    title: `Комментарий`,
    close: `Понятно`
  },
  tableScrollX: 800,
  tableScrollY: "calc(100vh - 17rem)",
  applyText: "Оформить",
  macrosAdditional: {
    date: "date",
    bonus: {
      bonusFrom: "bonusFrom",
      bonusTo: "bonusTo"
    },
    money: {
      moneyFrom: "moneyFrom",
      moneyTo: "moneyTo"
    },
    time: {
      timeFrom: "timeFrom",
      timeTo: "timeTo"
    }
  },
  periodTypeOptions: [
    { value: 1, label: `1 мес.` },
    { value: 6, label: `2 мес.` },
    { value: 2, label: `3 мес.` },
    { value: 3, label: `6 мес.` },
    { value: 4, label: `Год` }
  ],
  periodTypeOptionsDates: 0,
  periodDays: 14,
  periodTypeAllTime: null,
  sendingsTypeOptions: [
    {
      label: "По всем номерам клуба",
      value: sendingsTypeValues.all
    },
    {
      label: "Только по указанным номерам",
      value: sendingsTypeValues.only
    },
    {
      label: "По всем номерам, исключая указанные",
      value: sendingsTypeValues.excepted
    }
  ],
  customSmsOptionValue: -1 /* Значение опции кастомного текста в селекте выбора Типов шаблона */,
  ampersandValue: "&",
  rubleSymbol: "\u20bd",
  smsModalWidth: isMobile ? 340 : 680,
  paramsToResetSmsState: {
    comment: "",
    contactName: "",
    contactPhone: "",
    dateToSend: undefined,
    timeToSend: undefined,
    errors: {},
    pending: false,
    period: null,
    dates: null,
    sendingType: sendingsTypeValues.all,
    sumFrom: "",
    sumTo: "",
    contactPhoneError: "",
    visitFrom: "",
    visitTo: ""
  },
  calcStateInit: {
    calculating: false,
    characterCount: 0,
    smsCount: 0,
    smsLength: 0,
    phonesCount: 0,
    price: 0
  },
  smsStateInitWithoutHallId: {
    creating: false,
    pending: false,
    selectedTypeId: null,
    selectedSubTypeId: null,
    selectedTextId: 0,
    customSmsText: undefined,
    sendingType: sendingsTypeValues.all,
    extraParams: {},
    phones: [],
    dateToSend: undefined,
    timeToSend: undefined,
    period: null,
    contactName: "",
    comment: "",
    contactPhone: "",
    sumFrom: "",
    sumTo: "",
    visitFrom: "",
    visitTo: "",
    dates: null,
    contactPhoneError: "",
    errors: {},
    smsSettings: {
      types: [],
      texts: [],
      address: "",
      macros: [],
      requestStatuses: []
    }
  },
  pagination: {
    showText: "Показывать по",
    options: [
      {
        value: 20,
        label: 20
      },
      {
        value: 40,
        label: 40
      },
      {
        value: 60,
        label: 60
      }
    ]
  },
  sumFieldName: "sum",
  commandFieldName: "command"
}
