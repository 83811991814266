import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useHistory } from "react-router-dom"

import { message } from "antd"

import { isArray } from "lodash"

import { copyTextToClipboard } from "utils/copy"

import getNotifiData, {
  notificationsColumns,
  notificationsDataType
} from "./helper"
import { NotifiFilterStateData } from "Containers/Tools/Notifications"

import PaginationComponent from "Components/Pagination"
import TableComponent from "Components/Table"

import { IDevelopState } from "store/developer/types"
import { addLink } from "store/developer/actions"
import { logoutUser } from "store/auth/actions"
import { getDeveloperList } from "store/developer/selector"

import NotifiEmptyTable from "./EmptyTable"
import { CrudDataType } from "../CrudModal/types"
import getCurrentNotifi from "../CrudModal/helper"

type NotificationsFilterProps = {
  filter?: NotifiFilterStateData
  filterCount: number
  search?: {
    hall?: string
    type?: string
    search?: string
    pending: boolean
  }
  withoutDelete?: boolean
  setSearchPending?: (value: boolean) => void
  setFilterPending?: (value: boolean) => void
  trigger: number
  setDeleteData: (data: { data: number[]; active: boolean }) => void
  deleteData: number[] | string
  setModal: (data: {
    open: boolean
    id: number | string
    initial: CrudDataType
  }) => void
  listUrl: string
  type?: string
}

const NotificationsTable = ({
  setDeleteData,
  filter,
  filterCount,
  search,
  setSearchPending,
  setFilterPending,
  deleteData,
  setModal,
  listUrl,
  withoutDelete,
  trigger,
  type
}: NotificationsFilterProps) => {
  const history = useHistory()
  const logout: () => void = () => dispatch(logoutUser())
  const dispatch = useDispatch()
  const developer: IDevelopState = useSelector(getDeveloperList)
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))

  const [tableData, setTableData] = useState<{
    data: notificationsDataType
    total: number
  }>({ data: [], total: 0 })

  const [tableState, setTableState] = useState<{
    pending: boolean
    pagination: { limit: number; offset: number }
  }>({ pending: false, pagination: { limit: 20, offset: 0 } })

  const rowSelection = withoutDelete
    ? undefined
    : {
        onChange: (selectedRowKeys: number[]) => {
          setDeleteData({ data: selectedRowKeys, active: false })
        },
        selectedRowKeys: isArray(deleteData) ? deleteData : []
      }

  useEffect(() => {
    getNotifiData({
      listUrl,
      type,
      setTableData,
      setTablePending: (value: boolean) =>
        setTableState({ ...tableState, pending: value }),
      developer,
      pagination: tableState.pagination,
      addDeveloperLink,
      history,
      filter,
      setFilterPending,
      search,
      setSearchPending,
      logout
    })
  }, [trigger, tableState.pagination.offset])

  const handleIconClick = (type: string, data?: string) => {
    if (type === "comment" && data) {
      copyTextToClipboard(data).then(() => {
        message.success("Комментрий скопирован")
      })
    }
  }

  const isEmptyFilterTable =
    tableData.data &&
    !tableData.data.length &&
    !tableState.pending &&
    !!filterCount

  return (
    <div>
      {isEmptyFilterTable ? <NotifiEmptyTable /> : <div />}
      <div className="NotificationsTable">
        <TableComponent
          scrollX={920}
          data={tableData.data}
          columns={notificationsColumns({
            onIconClick: handleIconClick
          })}
          rowSelection={rowSelection}
          loading={tableState.pending}
          withoutScroll={true}
          onRow={({ id }: { id: number }) => {
            return {
              onClick: () => {
                getCurrentNotifi({
                  developer,
                  addDeveloperLink,
                  history,
                  logout,
                  id,
                  setModal
                })
              }
            }
          }}
        />
        <PaginationComponent
          total={tableData.total}
          value={tableState.pagination}
          pageSize={20}
          onChange={(value: { limit: number; offset: number }) => {
            setTableState({ ...tableState, pagination: value })
            setDeleteData({ active: false, data: [] })
          }}
        />
      </div>
    </div>
  )
}

export default NotificationsTable
