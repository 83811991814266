import { Moment } from "moment"

import { FilesList } from "Components/File/types"

export enum PayrollModalFields {
  hall = `hall`,
  billing_period = `billing_period`,
  preparation_date = `preparation_date`,
  sum = `sum`,
  document_number = `document_number`,
  files = `files`
}

export type PayrollModalFormValues = {
  [PayrollModalFields.hall]?: number
  [PayrollModalFields.document_number]?: string
  [PayrollModalFields.billing_period]?: Moment
  [PayrollModalFields.preparation_date]?: Moment
  [PayrollModalFields.sum]?: string
  [PayrollModalFields.files]: FilesList[]
  id?: string
}

export type ModalData = {
  open: boolean
  editData?: PayrollModalFormValues
}

export type PayrollModalProps = {
  data?: PayrollModalFormValues
  close: () => void
}
