import React, { useEffect, useState } from "react"
import {
  AddEditPenaltyModalSelect,
  formRules
} from "../AddEditPenaltyModal/helper"
import { Form, Modal } from "antd"
import InputComponent, { InputTextAreaComponent } from "Components/Input"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { appealModalRadioButtonsOptions, appealModalText } from "./helper"
import { AppealModalProps, FinishFormItem, FormItemNames } from "./types"
import { ModalRadioButtons } from "../helper"
import { addEditModalText } from "../../constants"

export const AppealModal: React.FC<AppealModalProps> = ({
  appealModal,
  winOptions,
  win,
  amount,
  closeAppealModal,
  setAppealModalData,
  loading
}) => {
  const [form] = Form.useForm()

  const [radioValue, setRadioValue] = useState(
    appealModalRadioButtonsOptions[0].value
  )
  const [isCancel, setIsCancel] = useState(true)

  const onFinish = (val: FinishFormItem) => {
    setAppealModalData({
      status: isCancel ? 6 : 4,
      resolveComment: val.resolveComment,
      resolvePartner: val.partner,
      penaltyValueResolved: isCancel ? 0 : val.amount,
      winResolved: isCancel ? null : val.win
    })
  }

  useEffect(() => {
    if (form && appealModal) {
      form.setFieldsValue({
        [FormItemNames.amount]: amount,
        [FormItemNames.win]: win
      })
    }
  }, [form, win, amount, appealModal])

  useEffect(() => {
    if (isCancel) {
      form.setFields([
        {
          name: FormItemNames.amount,
          errors: []
        },
        {
          name: FormItemNames.win,
          errors: []
        }
      ])
    }
    if (!isCancel) {
      form.setFields([
        {
          name: FormItemNames.amount,
          value: amount
        },
        {
          name: FormItemNames.win,
          value: win
        }
      ])
    }
  }, [isCancel])

  useEffect(() => {
    //decrease penalty value
    if (radioValue === appealModalRadioButtonsOptions[1].value) {
      setIsCancel(false)
    } else {
      setIsCancel(true)
    }
  }, [radioValue])

  useEffect(() => {
    if (!appealModal) {
      form.resetFields()
    }
  }, [appealModal])

  return (
    <Modal
      className="SupportProposalModal"
      visible={appealModal}
      footer={null}
      width={678}
      closeIcon={<></>}
    >
      <>
        <div className="ProposalModalTitleWrapper">
          <div className="ProposalModalTextTitleText">
            {appealModalText.title}
          </div>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label={
              <div className="ProposalModalSubTitleWrapper">
                {appealModalText.actions}
              </div>
            }
          >
            <ModalRadioButtons
              value={radioValue}
              onChange={val => setRadioValue(val)}
              options={appealModalRadioButtonsOptions}
            />
          </Form.Item>
          <div
            className={` AddEditPenaltyModalFieldsRow ${
              isCancel ? "ModalDisabledFormItem" : ""
            }`}
          >
            <Form.Item
              name={FormItemNames.amount}
              label={
                <div className="ProposalModalSubTitleWrapper">
                  {addEditModalText.amount}
                </div>
              }
              rules={isCancel ? formRules.inputSuccess : formRules.input}
            >
              <InputComponent
                type="number"
                min={0}
                className="AddEditPenaltyModalAmountInput"
                placeholder={addEditModalText.amountPlaceholder}
                greyBackground
                hideControls
                disabled={isCancel}
              />
            </Form.Item>

            <Form.Item
              name={FormItemNames.win}
              label={
                <div className="ProposalModalSubTitleWrapper">
                  {addEditModalText.win}
                </div>
              }
              rules={isCancel ? [] : formRules.select}
            >
              <AddEditPenaltyModalSelect
                options={winOptions}
                winSelect
                placeholder={addEditModalText.winPlaceholder}
                disabled={isCancel}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={FormItemNames.resolveComment}
            label={
              <div className="ProposalModalSubTitleWrapper">
                {addEditModalText.comment}
              </div>
            }
            rules={formRules.textarea}
          >
            <InputTextAreaComponent
              className="ProposalModalTextTextArea"
              placeholder={addEditModalText.commentPlaceholder}
              autoSize={{ minRows: 10, maxRows: 10 }}
              greyBackground
            />
          </Form.Item>

          <Form.Item
            name={FormItemNames.partner}
            label={
              <div className="ProposalModalSubTitleWrapper">
                {appealModalText.partner}
              </div>
            }
            rules={formRules.inputText}
          >
            <InputComponent
              className="AddEditPenaltyModalAmountInput"
              placeholder={appealModalText.partnerPlaceholder}
              greyBackground
            />
          </Form.Item>
          <div className="ProposalModalButtonsGroup EditCancelRejectFinalModalButtonsGroup">
            <Form.Item>
              <ButtonPrimary loading={loading} htmlType="submit">
                {addEditModalText.sendButton}
              </ButtonPrimary>
            </Form.Item>
            <ButtonDefault onClick={() => closeAppealModal()}>
              {addEditModalText.back}
            </ButtonDefault>
          </div>
        </Form>
      </>
    </Modal>
  )
}
