import React, { useMemo } from "react"
import moment from "moment"

import Title from "Components/Title"
import ExcelButton from "Components/ExcelButton"
import { getDateDescriptionLayout } from "constants/index"

import { textConstants } from "../constants"
import { PageTitleProps } from "../types"

const PageTitle = ({ date, hall, isActiveDescription }: PageTitleProps) => {
  const preparedBody = {
    filter: {
      date,
      hall
    }
  }
  const titleDescription = useMemo(() => {
    if (!!date) {
      return getDateDescriptionLayout({
        dates: [moment(date).startOf("month"), moment(date).endOf("month")],
        hideTime: false,
        isShift: false,
        hidePeriodTitle: true,
        calendarIcon: true,
        show: !!date,
        datesAsIs: true
      })
    }
    return ``
  }, [date])

  return (
    <Title
      wrapperClass="RegistrationReportTitle"
      titleText={textConstants.title}
      tooltipText={textConstants.tooltip}
      rightAdditionalCmp={
        date &&
        isActiveDescription && (
          <ExcelButton
            requestBody={preparedBody}
            url="v1/reports/registrations/excel"
          />
        )
      }
      rightAdditionalClassName="RegistrationExcelButton"
      bottomAdditionalCmp={date && <div>{titleDescription}</div>}
    />
  )
}

export default PageTitle
