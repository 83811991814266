import React from "react"

import { getFilterStateFromLocalStorage } from "api/helpers"

import { ReactComponent as NotActiveIcon } from "./not_active_filter.svg"
import { CupisReportFilter } from "./types"

import { isEmpty } from "lodash"

export const cupisReportText = {
  title: `Сводный отчет (ЦУПИС)`,
  tooltip: `Отчет предназначен для вывода статистики по активности гостей, совершающих ставки на сайте betboom.ru, первая регистрация которых происходила в данном клубе.В отчете представлены данные по сумме ставок, выигрышей, ставки с бонусного баланса, комиссия по транзакциям, остаток, чистый заработок, процент чистого заработка от остатка. Также отображаются денежные и бонусные начисления по бонус-акциям, детализация по гостям и кассовая детализация. Отчет возможно построить по нескольким клубам. Начало смены для просмотра данных всегда 08:00 по московскому времени.`,
  notActiveFilter: {
    title: `Чтобы получить сводный отчет (ЦУПИС),\nвам необходимо выбрать нужные \nпараметры фильтрации`,
    description: `Сводный отчет (ЦУПИС) содержит данные, такие как суммы\n ставок и выплат, размер комиссий и чистый заработок, а также\n детализация начислений бонус-акций, детализация по гостям\nи кассовая детализация`,
    icon: <NotActiveIcon />
  }
}

export const CUPIS_REPORT_FILTER = `CUPIS_REPORT_FILTER`

export const initialFilterValues = {
  period: "daily",
  dates: undefined
}

export const getInitialFilter = (hall: string): CupisReportFilter => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    CUPIS_REPORT_FILTER
  )

  const haveFilterFromStorage = !isEmpty(initFilterStateFromStorage)
  const initialWithHalls = { ...initialFilterValues, halls: [hall] }

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: haveFilterFromStorage,
    data: {
      ...initialWithHalls,
      ...initFilterStateFromStorage
    }
  }
}
