export const mailingText = {
  title: `Рассылка о пополнениях и выплатах`,
  tooltip: `Какаято инфа...хер пока знает`,
  tabs: {
    hall: `Участники рассылок`,
    history: `История рассылок`
  },
  cart: {
    replenishment: `Пополнение`,
    withdrawal: `Вывод`,
    win: `Выигрыш`
  },
  notSet: `Не задан`
}

export const mainUrl = `v1/hall/mailing`
