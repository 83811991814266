import React, { useCallback } from "react"
import { GameTabItemProps } from "../types"
import { Image } from "antd"

export const GameTabItem: React.FC<GameTabItemProps> = ({
  label,
  id,
  icon,
  isActive,
  onActiveGameIdClick
}) => {
  const handleClick = useCallback(() => {
    onActiveGameIdClick(id)
  }, [id])

  return (
    <div
      className={`ChangeTabTennisContainer_item ${
        isActive ? "ChangeTabTennisContainer_item__active" : ""
      }`}
      onClick={handleClick}
    >
      <Image src={icon} width={36} height={36} preview={false} />
      <div className="ChangeTabTennisContainer_title">{label}</div>
    </div>
  )
}
