import React from "react"
import { Step3FieldProps } from "Containers/Sms/types"
import { SelectComponent } from "Components/Select"
import { MonthNumberSelectOptions } from "Containers/Sms/helper"
import { smsConstants } from "Containers/Sms/constants"

const MonthNumber = ({ smsState, setSmsState, ...rest }: Step3FieldProps) => {
  return (
    <div className="CreateSms_input CreateSms_inputShort CreateSms_inputShort_single">
      <label className="CreateSms_inputLabel">
        {smsConstants.step3.monthNumberTitle}
      </label>
      <div className="CreateSms_inputInner">
        <SelectComponent
          placeholder={smsConstants.step3.monthNumberPlaceholder}
          greyBackground
          allowClear={false}
          value={smsState.extraParams.number}
          options={MonthNumberSelectOptions()}
          onChange={value => {
            setSmsState({
              ...smsState,
              extraParams: {
                ...smsState.extraParams,
                number: value
              }
            })
          }}
          {...rest}
        />
      </div>
    </div>
  )
}

export default MonthNumber
