import React from "react"
import { RangePickerComponent } from "../../DatePicker"
import { RangeDateSearchProps } from "../types"
import { PickerDurations } from "../../FilterInfoPanel/types"
import { isArray } from "lodash"

const RangeDateSearch = ({
  toggleShowSearch,
  value,
  setSearch
}: RangeDateSearchProps) => (
  <RangePickerComponent
    picker={PickerDurations.date}
    onBlur={() => {
      toggleShowSearch(false)
    }}
    value={!!value && isArray(value) ? value : undefined}
    autoFocus
    allowClear
    onChange={value => {
      setSearch(value || undefined)
      toggleShowSearch(false)
    }}
  />
)

export default RangeDateSearch
