import { CheckboxDict, MailingModalData } from "./types"
import { mainUrl } from "../constant"

export const getModalTitle = (isNew: boolean) =>
  isNew ? `Добавить рассылку` : `Редактирование рассылки`

export const modalDataInitial: MailingModalData = {
  halls: []
}

export const modalActiveFieldsInitial = {
  [CheckboxDict.replenishment]: true,
  [CheckboxDict.withdrawal]: true,
  [CheckboxDict.win]: true,
  phone: true,
  email: true
}

export const mailingModalText = {
  rules: {
    input: `Необходимо заполнить поле`,
    hall: `Необходимо выбрать клубы`,
    type: `Необходимо выбрать тип операции`,
    mailing: `Необходимо выбрать вид рассылки`,
    email: `Неверный формат электронной почты`
  },
  success: {
    title: {
      one: `Рассылка`,
      getSecond: (isNew: boolean) =>
        isNew ? `успешно создана` : `успешно отредактирована`
    }
  },
  form: {
    halls: {
      field: `halls`,
      label: `Клубы`,
      placeholder: `Введите номера клубов через запятую`
    },
    name: {
      field: `name`,
      label: `Имя сотрудника`,
      placeholder: `Его увидят только администраторы`,
      maxLength: 200
    },
    checkbox: {
      all: `Все`,
      [CheckboxDict.replenishment]: {
        placeholder: ``,
        label: `Пополнение`
      },
      [CheckboxDict.withdrawal]: {
        placeholder: ``,
        label: `Вывод`
      },
      [CheckboxDict.win]: {
        placeholder: ``,
        label: `Выигрыш`
      },
      [CheckboxDict.phone]: {
        label: `СМС`,
        placeholder: `Введите номер телефона`,
        phoneMask: "+7 900 000-00-00"
      },
      [CheckboxDict.email]: {
        label: `Электронное письмо`,
        placeholder: `Введите адрес почты`
      }
    },
    types: {
      label: `Тип операции`,
      tooltip: `Some tooltip`
    },
    mailing: {
      label: `Вид рассылки`
    },
    submit: {
      url: mainUrl,
      urlById: (id: number, hall: number) => `${mainUrl}/${hall}/${id}`,
      label: `Добавить`
    }
  }
}
