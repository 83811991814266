import moment, { Moment } from "moment"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

// NEW WORLD

export type CashboxFilterData = {
  halls: (string | CheckboxValueType)[]
  types: CheckboxValueType[]
  period: string
  dates: [Moment, Moment] | null
  isShift: boolean
  shift: [number, number]
  gmt: string
}

export interface CashboxFilterProps {
  isOpen: boolean
  isActive: boolean
  data: CashboxFilterData
}

export const initialFilterData: CashboxFilterData = {
  halls: [],
  types: ["cashbox"],
  period: "hourly",
  dates: [moment(), moment()],
  isShift: true,
  shift: [8, 8],
  gmt: `3`
}

export type CashboxReportType = {
  total: {
    income: string
    outcome: string
    result: string
  }
  date: string
  income: number
  outcome: number
  result: number
  halls: {
    hall: string
    income: {
      cash: number
      non_cash: number
      collection: number
      transfer: string
    }
    outcome: {
      cash: number
      non_cash: number
      collection: number
      transfer: string
    }
    result: {
      cash: number
      non_cash: number
      collection: number
      transfer: string
    }
    total: {
      income: number
      outcome: number
      result: number
    }
  }[]
}

export type CashboxStateDataType = {
  money_balance: number
  bonus_balance: number
  qiwi_balance: number
  qiwi_balances: { hall: number; [value: string]: number }[]
  cashbox_result: number
  report: CashboxReportType[]
}

export interface CashboxState {
  pending: boolean
  data: CashboxStateDataType
  total: number
}

export const initialCashboxData: CashboxState = {
  pending: false,
  total: 0,
  data: {
    money_balance: 0.0,
    bonus_balance: 0.0,
    qiwi_balance: 0.0,
    qiwi_balances: [],
    cashbox_result: 0.0,
    report: []
  }
}

// OLD WORLD
export type IFilterDataInfoType = {
  type: number
  period: null | [Moment, Moment]
  shift: null | [Moment] | [Moment, Moment]
}

export type ICashBoxTableData = {
  dashboard: {
    count: string | null | number
    result: string | null | number
    check: string | null | number
    wallet: string | null | number
  }
  table: {
    date: Moment
    proceeds: string | null
    payments: string | null
    balance: string | null
  }[]
}

export interface ICashBoxData {
  filter: {
    applied: boolean
    report_type: number
    info: IFilterDataInfoType
  }
  data: ICashBoxTableData
}

export interface IFilterDataTypes {
  applied: boolean
  report_type: number
  info: IFilterDataInfoType
}
