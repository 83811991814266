export const paymentText = {
  title: `Заявки на оплату`,
  tooltip: `Раздел позволяет направить заявку на оплату специалистам для обработки и произведения оплаты со счета компании. Можно отследить статус заявки, воспользоваться фильтрацией для поиска нужной информации, а новый функционал дублирования позволит быстрее оформить новую заявку, скорректировав её при необходимости. Для оперативного уведомления об изменении статуса заявки можно настроить уведомление на электронный почтовый ящик.`,
  buttons: {
    clear: `Фильтры`,
    notify: `Оповещения о статусах`,
    addRequest: `Добавить заявку`
  },
  notifyBaseUrl: `payments`,
  more: "...еще",
  moreSelectButton: "Добавить",
  paymentPurposeSelect: "Выберите назначение платежа"
}
