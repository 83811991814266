import React from "react"

import { isEmpty } from "lodash"
import moment from "moment"

import { checkArrayRole, valueToCurrency } from "constants/index"

import { getFilterStateFromLocalStorage } from "api/helpers"

import { SortProps } from "Components/TableNew/types"
import { getColumnSort } from "Components/TableNew/helpers"
import { TableHeader } from "Components/TableNew"
import OnlySortFilter from "Components/TableNew/TableFilters/OnlySortFilter"

import { ReactComponent as NotActiveFilterImg } from "./notActiveFilter.svg"
import { DateTimeCell } from "../HistoryBets/constants"

export const textConstants = {
  title: `Проверка регистраций`,
  notActiveFilter: {
    title: `Чтобы получить информацию по регистрациям гостя, \nнеобходимо ввести его номер телефона`,
    description: `По номеру телефона гостя выводится информация, в каким клубах сети Свердловской области он зарегистрирован.`,
    icon: <NotActiveFilterImg />
  }
}

export const filterStorageName = "REGISTRATION_CHECK_FILTER"

export const initialFilterValues = { phone: undefined }

export const getInitialFilter = () => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    filterStorageName
  )

  const haveFilterFromStorage = !isEmpty(initFilterStateFromStorage)

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: haveFilterFromStorage,
    data: initFilterStateFromStorage
  }
}

export enum TableFields {
  hall = `hall`,
  registered_at = `registered_at`,
  wallet_number = `wallet_number`,
  in = `in`,
  out = `out`,
  cashbox = `cashbox`
}

export const columnsTextTitle = {
  [TableFields.hall]: `№ клуба`,
  mobile_hall: `Клуб №`,
  [TableFields.registered_at]: `Дата и время регистрации`,
  [TableFields.wallet_number]: `№ кошелька`,
  [TableFields.in]: `IN, ₽`,
  [TableFields.out]: `OUT, ₽`
}

const inOutColumns = [
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {columnsTextTitle[TableFields.in]}
      </div>
    ),
    dataIndex: TableFields.cashbox,
    key: TableFields.in,
    render: (cashbox: { in: string }) => {
      const currency = valueToCurrency({
        value: Number(cashbox.in),
        withoutSpace: true
      })
      return <span className="TextDefault">{currency}</span>
    }
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {columnsTextTitle[TableFields.out]}
      </div>
    ),
    dataIndex: TableFields.cashbox,
    key: TableFields.out,
    render: (cashbox: { out: string }) => {
      const currency = valueToCurrency({
        value: Number(cashbox.out),
        withoutSpace: true
      })
      return <span className="TextDefault">{currency}</span>
    }
  }
]

export const getColumns = ({
  sort,
  setSort
}: {
  sort?: SortProps
  setSort: (val?: SortProps) => void
}) => {
  const cashboxColumn = checkArrayRole([`PARTNER`, `COMPANY-DEVELOPER`])
    ? inOutColumns
    : []

  return [
    {
      title: () => (
        <div className="TableHeaderWithoutSpacing PaddingLeft24">
          {columnsTextTitle[TableFields.hall]}
        </div>
      ),
      dataIndex: TableFields.hall,
      key: TableFields.hall,
      render: (hall: number) => <div className="PaddingLeft24">{hall}</div>
    },
    {
      title: () => {
        const isSort =
          sort && !!sort?.order && sort?.field === TableFields.registered_at
            ? getColumnSort(sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={sort && sort?.field === TableFields.registered_at}
            className=""
            title={columnsTextTitle[TableFields.registered_at]}
          >
            <OnlySortFilter
              setSort={setSort}
              currentSort={sort}
              field={TableFields.registered_at}
            />
          </TableHeader>
        )
      },
      dataIndex: TableFields.registered_at,
      key: TableFields.registered_at,
      render: (registered_at: string) => {
        const dateByFormat = moment(registered_at)
        const date = dateByFormat.format(`DD.MM.YYYY`)
        const time = dateByFormat.format(`HH:mm:ss`)
        return <DateTimeCell date={date} time={time} />
      }
    },
    {
      title: () => (
        <div className="TableHeaderWithoutSpacing">
          {columnsTextTitle[TableFields.wallet_number]}
        </div>
      ),
      dataIndex: TableFields.wallet_number,
      key: TableFields.wallet_number
    },
    ...cashboxColumn
  ]
}

export const emptyTableConfig = {
  desktop: {
    title: `Гость не найден`,
    subtitle: `Гость с таким номером телефона не \nзарегистрирован`
  },
  mobile: {
    title: `Гость не найден`,
    subtitle: `Гость с таким \nномером телефона не \nзарегистрирован`
  }
}
