import React from "react"
import { CreateStep5Props } from "../../types"
import TooltipComponent from "Components/Tooltip"
import { InputTextAreaComponent } from "Components/Input"
import { smsConstants } from "../../constants"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"

const Step5 = ({
  smsState,
  setSmsState,
  showStep,
  stepNumber
}: CreateStep5Props) => {
  return (
    <div className="CreateSms_step">
      <div
        className={
          showStep ? "CreateSms_stepTitle" : "CreateSms_stepTitle_inactive"
        }
      >
        <span>{stepNumber}</span>
        <span>{smsConstants.step5.title}</span>
        <TooltipComponent title={smsConstants.step5.tooltip} direction="bottom">
          <InfoIcon />
        </TooltipComponent>
      </div>

      {showStep ? (
        <section className="CreateSms_inputWrapper">
          <span className="CreateSms_inputInfo">{smsConstants.step5.info}</span>
          <div className="CreateSms_input">
            <InputTextAreaComponent
              greyBackground
              value={smsState.comment}
              onChange={({ target: { value } }) => {
                setSmsState({
                  ...smsState,
                  comment: value
                })
              }}
              placeholder={smsConstants.step5.commentPlaceholder}
              autoComplete="off"
              autoCorrect="off"
            />
          </div>
        </section>
      ) : (
        <hr className="CreateSms_divider" />
      )}
    </div>
  )
}

export default Step5
