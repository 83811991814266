import { IRootState } from "store"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

export const getHallsListFromStore = (state: IRootState): string[] =>
  state.halls.halls

export const getCurrentHall = (
  state: IRootState
): (string | CheckboxValueType)[] => state.halls.current_hall

export const getCanaryReleases = (state: IRootState): string[] =>
  state.auth.canaryReleases

const locationsByGmt = (
  addresses: { gmt: string; region: string }[]
): { gmt: string; region: string }[] =>
  addresses.map((item: { gmt: string; region: string }) => {
    switch (item.gmt) {
      case `2`:
        return {
          gmt: item.gmt,
          region: `Калининград`
        }
      case `3`:
        return {
          gmt: item.gmt,
          region: `Москва`
        }
      case `4`:
        return {
          gmt: item.gmt,
          region: `Самара`
        }
      case `5`:
        return {
          gmt: item.gmt,
          region: `Екатеринбург`
        }
      case `6`:
        return {
          gmt: item.gmt,
          region: `Омск`
        }
      case `7`:
        return {
          gmt: item.gmt,
          region: `Новосибирск`
        }
      case `8`:
        return {
          gmt: item.gmt,
          region: `Иркутск`
        }
      case `9`:
        return {
          gmt: item.gmt,
          region: `Якутск`
        }
      case `10`:
        return {
          gmt: item.gmt,
          region: `Владивосток`
        }
      case `11`:
        return {
          gmt: item.gmt,
          region: `Магадан`
        }
      case `12`:
        return {
          gmt: item.gmt,
          region: `Камчатский край`
        }
      default:
        return {
          gmt: item.gmt,
          region: `Не найден`
        }
    }
  })

export const getAddressList = (
  state: IRootState
): { gmt: string; region: string }[] => locationsByGmt(state.halls.addresses)
