import React, { useEffect } from "react"
import { ReactComponent as DropdownMenuIcon } from "Components/icons/dropdown_arrow.svg"
import { ContactsStateProps } from "../types"
import AddSectionBtn from "../AddSectionBtn"
import ContactsSection from "./ContactsSection"
import { Collapse } from "antd"
import { isContactsMobile } from "../index"
import SectionHeader from "./ContactsSection/SectionHeader"

const { Panel } = Collapse

const ContactsPage = ({
  contactsState,
  setContactsState
}: {
  contactsState: ContactsStateProps
  setContactsState: (data: ContactsStateProps) => void
}) => {
  useEffect(() => {
    if (contactsState.movingId) {
      const el = document.getElementById(`${contactsState.movingId}`)
      el &&
        el.scrollIntoView({
          block: "start",
          behavior: "smooth"
        })
    }
  }, [contactsState.data, contactsState.movingId])

  const sortedData = contactsState.data.sort(
    (a, b) => a.order_number - b.order_number
  )

  const sections = isContactsMobile
    ? sortedData.map((section, index) => (
        <Panel
          key={section.section_id}
          header={
            <SectionHeader
              contactsState={contactsState}
              setContactsState={setContactsState}
              section={section}
              key={section.section_id}
            />
          }
        >
          <ContactsSection
            key={`section_${index}`}
            contactsState={contactsState}
            setContactsState={setContactsState}
            section={section}
            index={index}
          />
        </Panel>
      ))
    : sortedData.map((section, index) => (
        <ContactsSection
          key={`section_${index}`}
          contactsState={contactsState}
          setContactsState={setContactsState}
          section={section}
          index={index}
        />
      ))

  return (
    <div
      className={`ContactsPageWrapper ${
        contactsState.isEditing ? `ContactsPageSectionBlock_editing` : ""
      }`}
    >
      {isContactsMobile ? (
        <Collapse
          expandIconPosition="right"
          expandIcon={panelProps => (
            <div className="ContactsCollapse">
              <DropdownMenuIcon
                className={`AccordionItemArrow 
                      ${
                        panelProps.isActive
                          ? `AccordionItemUp`
                          : `AccordionItemDown`
                      }
                    `}
              />
            </div>
          )}
        >
          {sections}
        </Collapse>
      ) : (
        <>{sections}</>
      )}
      {contactsState.isEditing && (
        <div className="ContactsPage_addBlockBtnFooter">
          <AddSectionBtn
            setContactsState={setContactsState}
            contactsState={contactsState}
          />
        </div>
      )}
    </div>
  )
}

export default ContactsPage
