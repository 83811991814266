import { UserFields } from "./UserCrud/types"

export enum ModalType {
  table = `table`,
  add = `add`
}

export enum NotifyUserDataFields {
  user_id = `user_id`,
  name = `name`,
  email = `email`,
  updated_date = `updated_date`,
  is_active = `is_active`
}

export type NotifyUserData = {
  [NotifyUserDataFields.user_id]: number
  [NotifyUserDataFields.name]: string
  [NotifyUserDataFields.email]: string
  [NotifyUserDataFields.updated_date]: string
  [NotifyUserDataFields.is_active]: boolean
}

export interface UserFieldsWithId extends UserFields {
  id: number
}

export type NotifyModalState = {
  open?: ModalType
  editData?: UserFieldsWithId
  activeHall?: number
  pending: boolean
}

export interface NotifyRightModalProps {
  baseUrl: string
}
