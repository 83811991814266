import React, { useState } from "react"

import moment from "moment/moment"
import { Image } from "antd"
import { isString } from "lodash"

import { getFilterStateFromLocalStorage } from "api/helpers"
import { GamesData } from "store/dictionary/types"

import {
  DateTimeCellProps,
  GameCellProps,
  HistoryBetsColumns,
  HistoryBetsDataElement,
  HistoryBetsFilter,
  HistoryBetsFilterState,
  HistoryBetsSummaryKeys
} from "./types"

export const text = {
  title: "История игровых ставок",
  tooltip:
    "Отчет предназначен для поиска информации по всем совершенным ставкам во все этапы по событиям Теннис и Форсаж. В отчете можно узнать детальную информацию по каждой совершенной ставке, а именно комбинации чисел в ставке гостя, порядок чисел в розыгрыше этапа, сумму ставки и сумму выигрыша. Можно отфильтровать ставки с выигрышем или без, а также по диапазону сумм ставок, найти ставки в конкретный этап по номеру игры. Отчет строится за выбранный в фильтрах период (при поиске по номеру игры данный фильтр не обязателен). Данные выводятся только в рамках одного клуба.",
  columns: {
    [HistoryBetsColumns.game_number]: "№ игры",
    [HistoryBetsColumns.game_start_date]: "Время игры",
    [HistoryBetsColumns.is_bonus_balance]: "За бонусы",
    [HistoryBetsColumns.game_kind_id]: "Игра",
    [HistoryBetsColumns.nominal]: "Номинал",
    [HistoryBetsColumns.win_sum]: "Выигрыш",
    [HistoryBetsColumns.bet_drop_order]: "Ставка",
    [HistoryBetsColumns.drop_order]: "Порядок выпадения"
  },
  live: "Игра идёт",
  filter: {
    date: "Дата ставок",
    datePlaceholder: "__.__.____",
    search: "№ игры",
    searchPlaceholder: "Введите № игры",
    shift: "Начало смены",
    win: "Выигрыш, ₽",
    rangeBet: "Размер ставки",
    games: "Игры"
  },
  development: "В разработке",
  detail: {
    [HistoryBetsSummaryKeys.bets_count]: "Количество ставок",
    [HistoryBetsSummaryKeys.money_bets_count]: "Кол-во денежных ставок",
    [HistoryBetsSummaryKeys.money_bets_sum]: "Объем денежных ставок",
    [HistoryBetsSummaryKeys.guests_count]: "Количество гостей",
    [HistoryBetsSummaryKeys.bonus_bets_count]: "Кол-во ставок за бонусы",
    [HistoryBetsSummaryKeys.bonus_bets_sum]: "Объем ставок за бонусы"
  },
  notFound: {
    title: "Ставок не найдено",
    subtitle: "По заданным фильтрам ничего не найдено"
  },
  emptyFilters: {
    title:
      "Чтобы получить информацию по истории игровых ставок,\nвыберите нужные фильтры и нажмите кнопку «Применить»",
    subtitle:
      "Отчет по ставкам в событиях Теннис и Форсаж.\nСодержит детальную информацию по каждой совершенной ставке."
  },
  yes: "Да",
  no: "Нет",
  from: "от",
  to: "до",
  more: "...еще",
  collapse: "свернуть"
}

export const radioFilterOptions = [
  { label: "Любой", value: "any" },
  { label: "Без выигрыша", value: "without_win" },
  { label: "С выигрышем", value: "with_win" }
]

export const HISTORY_BETS_FILTER = "HISTORY_BETS_FILTER"
export const ROOT_URL = "v1/bets/game-bets-history"

const GAME_CELL_LONG_LENGTH = 15

export const GameCell: React.FC<GameCellProps> = ({
  value,
  record,
  passedKey
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const isStringValue = isString(value)
  const isLong = !isStringValue && value.length > GAME_CELL_LONG_LENGTH

  const mappedValue = isLong ? value.slice(0, GAME_CELL_LONG_LENGTH) : value
  const remainingElements = isLong ? value.slice(GAME_CELL_LONG_LENGTH) : []

  const showGreen = record.win_sum > 0 && isString(record.bet_drop_order)

  const toggleOpen = () => {
    setIsOpened(!isOpened)
  }

  return value.length > 0 ? (
    <div className={`HistoryBetCell`}>
      {isStringValue ? (
        <div className={showGreen ? "HistoryBetCell_NumberGreen" : ""}>
          {value}
        </div>
      ) : (
        <>
          {(mappedValue as []).map((el, key) => (
            <div
              key={key}
              className={
                record[passedKey].includes(el) || showGreen
                  ? "HistoryBetCell_NumberGreen"
                  : ""
              }
            >
              {el}
            </div>
          ))}
          {isLong && (
            <>
              {isOpened ? (
                remainingElements.map((el, key) => (
                  <div
                    key={key}
                    className={
                      (record[passedKey] as number[]).includes(el) || showGreen
                        ? "HistoryBetCell_NumberGreen"
                        : ""
                    }
                  >
                    {el}
                  </div>
                ))
              ) : (
                <div
                  onClick={toggleOpen}
                  className="HistoryBetsMore"
                >{`${text.more} ${remainingElements.length}`}</div>
              )}
              {isOpened && (
                <div className="HistoryBetsMore" onClick={toggleOpen}>
                  {text.collapse}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  ) : (
    <div className="HistoryBetsDevelopment">{text.development}</div>
  )
}

export const DateTimeCell: React.FC<DateTimeCellProps> = ({ date, time }) => (
  <div className="HistoryBetsDateTimeCell">
    <div className="HistoryBetsDateTimeCellDate">{date}</div>
    <div className="HistoryBetsDateTimeCellDate HistoryBetsDateTimeCellDate_Time">
      {time}
    </div>
  </div>
)

export const columns = (gamesDict: GamesData[]) => {
  return [
    {
      title: () => (
        <div className="PaddingLeft12">
          {text.columns[HistoryBetsColumns.game_number]}
        </div>
      ),
      dataIndex: HistoryBetsColumns.game_number,
      key: HistoryBetsColumns.game_number,
      render: (value: number) => <div className="PaddingLeft12">{value}</div>
    },
    {
      title: text.columns[HistoryBetsColumns.game_start_date],
      dataIndex: HistoryBetsColumns.game_start_date,
      key: HistoryBetsColumns.game_start_date,
      sorter: true,
      render: (date: string, record: any) => {
        const startDate = moment(date).format("DD.MM.YYYY")
        const startTime = moment(date).format("HH:mm")
        let endDate = ""
        let endTime = ""
        let isLive = true
        const nextEnd = record[HistoryBetsColumns.game_end_date]

        if (nextEnd) {
          endDate = moment(nextEnd).format("DD.MM.YYYY")
          endTime = moment(nextEnd).format("HH:mm")
          isLive = false
        }

        return (
          <div className="HistoryBetsDateTimeCellWrapper">
            <DateTimeCell date={startDate} time={startTime} />
            {isLive ? (
              <div className="HistoryLiveCellWrapper">
                <div className="RedCircleHistory" />
                <div className="HistoryLiveCell">{text.live}</div>
              </div>
            ) : (
              <DateTimeCell date={endDate} time={endTime} />
            )}
          </div>
        )
      }
    },
    {
      title: text.columns[HistoryBetsColumns.is_bonus_balance],
      dataIndex: HistoryBetsColumns.is_bonus_balance,
      key: HistoryBetsColumns.is_bonus_balance,
      render: (value: boolean) => <div>{value ? text.yes : text.no}</div>
    },
    {
      title: text.columns[HistoryBetsColumns.game_kind_id],
      dataIndex: HistoryBetsColumns.game_kind_id,
      key: HistoryBetsColumns.game_kind_id,
      render: (gameId: number) => {
        const foundedGame = gamesDict.find(el => el.id === gameId)
        if (foundedGame) {
          return (
            <div className="NominalsColumns_GameCell">
              <Image
                src={foundedGame.image}
                width={24}
                height={24}
                preview={false}
              />
              <div>{foundedGame.name}</div>
            </div>
          )
        }
      }
    },
    {
      title: text.columns[HistoryBetsColumns.nominal],
      dataIndex: HistoryBetsColumns.nominal,
      key: HistoryBetsColumns.nominal,
      sorter: true
    },
    {
      title: text.columns[HistoryBetsColumns.win_sum],
      dataIndex: HistoryBetsColumns.win_sum,
      key: HistoryBetsColumns.win_sum,
      sorter: true,
      render: (value: number) => (
        <div className={value > 0 ? "HistoryBetRed" : ""}>{value}</div>
      )
    },
    {
      title: text.columns[HistoryBetsColumns.bet_drop_order],
      dataIndex: HistoryBetsColumns.bet_drop_order,
      key: HistoryBetsColumns.bet_drop_order,
      render: (value: number[] | string, record: HistoryBetsDataElement) => (
        <GameCell
          value={value}
          record={record}
          passedKey={HistoryBetsColumns.drop_order}
        />
      )
    },
    {
      title: text.columns[HistoryBetsColumns.drop_order],
      dataIndex: HistoryBetsColumns.drop_order,
      key: HistoryBetsColumns.drop_order,
      width: 250,
      render: (value: number[], record: HistoryBetsDataElement) => (
        <GameCell
          value={value}
          record={record}
          passedKey={HistoryBetsColumns.bet_drop_order}
        />
      )
    }
  ]
}

export const initialFilterStateCreator = (
  current_hall: number,
  games: GamesData[],
  skipLs?: boolean
): HistoryBetsFilterState => {
  const init: HistoryBetsFilter = {
    hall: current_hall,
    game_ids: games.map(el => Number(el.id)),
    shift: 8,
    win_type: radioFilterOptions[0].value
  }

  let initFilterStateFromStorage = init

  if (!skipLs) {
    initFilterStateFromStorage = getFilterStateFromLocalStorage(
      init,
      HISTORY_BETS_FILTER
    )
  }

  let isActive = false

  if (!!initFilterStateFromStorage.bets_date) {
    isActive = true
  }

  return {
    isOpen: true,
    mobileFilter: false,
    isActive,
    data: {
      ...initFilterStateFromStorage,
      hall: current_hall
    }
  }
}
