import React, { useEffect, useState } from "react"

import { isMobile } from "constants/index"

import Title from "Components/Title"
import TabsComponent from "Components/Tabs"
import ExcelButton from "Components/ExcelButton"

import {
  SuperwinReportState,
  SuperwinReportTabs,
  SuperwinTitleProps
} from "./types"
import ManageContent from "./ManageContent"
import HistoryContent from "./HistoryContent"
import { superwinMainText } from "./ManageContent/constants"
import { activeTabKeyLs, EXEL_URL, EXEL_WINS_URL, tabsData } from "./constants"

export const SuperwinTitleComponent = ({
  filter,
  isWin
}: SuperwinTitleProps) => (
  <div>
    <Title
      titleText={superwinMainText.title}
      tooltipText={superwinMainText.tooltip}
      rightAdditionalCmp={
        !!filter ? (
          <div className="SuperwinTitleExcelButton PaddingLeft8">
            <ExcelButton
              url={isWin ? EXEL_WINS_URL : EXEL_URL}
              requestBody={
                isWin
                  ? { filter: { ...filter, gmt: Number(filter.gmt) } }
                  : { ...filter }
              }
              minimalist={true}
              onlyIcon={isMobile}
            />
          </div>
        ) : (
          <div />
        )
      }
    />
    <div />
  </div>
)

export const SuperwinTabsComponent = ({
  activeTab,
  toggleActiveTab
}: SuperwinReportState) => (
  <div>
    <TabsComponent
      tabs={tabsData}
      activeTab={activeTab}
      toggleActiveTab={value => {
        const rightValue = value as SuperwinReportTabs
        toggleActiveTab(rightValue)
      }}
    />
  </div>
)

const SuperwinReport = () => {
  const [activeTab, toggleActiveTab] = useState<SuperwinReportTabs>(
    (localStorage.getItem(activeTabKeyLs) as SuperwinReportTabs) ||
      SuperwinReportTabs.MANAGE
  )

  useEffect(() => {
    localStorage.setItem(activeTabKeyLs, activeTab)
  }, [activeTab])

  const isManage = activeTab === SuperwinReportTabs.MANAGE
  const isHistory = activeTab === SuperwinReportTabs.HISTORY
  const isSuperWinsHistory = activeTab === SuperwinReportTabs.WINS_HISTORY

  return (
    <>
      {isManage && (
        <ManageContent
          activeTab={activeTab}
          toggleActiveTab={toggleActiveTab}
        />
      )}
      {isHistory && (
        <HistoryContent
          activeTab={activeTab}
          toggleActiveTab={toggleActiveTab}
        />
      )}
      {isSuperWinsHistory && (
        <HistoryContent
          activeTab={activeTab}
          toggleActiveTab={toggleActiveTab}
          isSuperWinsHistory={isSuperWinsHistory}
        />
      )}
    </>
  )
}

export default SuperwinReport
