import React, { useEffect, useState } from "react"

import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"
import { ReactComponent as CloseFormIcon } from "Components/icons/close.svg"
import { ReactComponent as BigCloseFormIcon } from "Components/icons/close_28.svg"
import { ReactComponent as CloseNewIcon } from "Components/icons/close_new.svg"

import { ModalComponentProps, ModalRightDetailProps } from "./types"
import { noop } from "lodash"

export const ModalFormComponent = ({
  close,
  children,
  title,
  withoutCross,
  withBigCross,
  width,
  contentClassName,
  className
}: ModalComponentProps) => (
  <div className="GridCenter">
    <div
      className={`ModalFormWrapper FlexRow ${className || ``}`}
      style={
        width
          ? {
              width: `${width}px`,
              left: `calc(50vw - ${width / 2}px)`
            }
          : undefined
      }
    >
      <div className={`ModalFormContent ${contentClassName || ""}`}>
        {(!!close || !!title) && (
          <div className="ModalTitleWrapper FlexRow">
            <div className="ModalFormTitle">{title || ``}</div>
            {withBigCross && !!close && (
              <div className="ModalFormBigClose HoverItem" onClick={close}>
                <BigCloseFormIcon />
              </div>
            )}
            {!!close && !withoutCross && !withBigCross && (
              <div className="ModalFormClose" onClick={close}>
                <CloseFormIcon />
              </div>
            )}
          </div>
        )}
        <div>{children}</div>
      </div>
    </div>
    <div className="Overlay" onClick={close} />
  </div>
)

const ModalComponent = ({
  close,
  children,
  width,
  closeIcon,
  className,
  withMargin
}: ModalComponentProps) => (
  <div className={`GridCenter ${className ? className : ""}`}>
    <div
      className={`ModalWrapper FlexRow ${
        withMargin ? `ModalMarginWrapper` : ``
      }`}
      style={
        width
          ? {
              width: `${width}px`,
              left: `calc(50vw - ${width / 2}px)`
            }
          : undefined
      }
    >
      <div className="ModalContent">{children}</div>
      {!!close && (
        <div className="ModalCloseWrapper" onClick={close}>
          {closeIcon ? closeIcon : <CloseIcon />}
        </div>
      )}
    </div>
    <div className="Overlay" onClick={close} />
  </div>
)

export default ModalComponent

export const ModalRightDetail = ({
  close,
  initialWidth,
  className,
  children,
  hideClose,
  maskClosable
}: ModalRightDetailProps) => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(initialWidth || 880)
  }, [])

  return (
    <div className={className || ``}>
      <div style={{ width: `${width}px` }} className="ModalRightDetail">
        {!hideClose && (
          <div onClick={close} className="ModalRightDetailClose">
            <CloseNewIcon />
          </div>
        )}
        <div className="ModalRightDetailContent">{children}</div>
      </div>
      <div className="Overlay" onClick={maskClosable ? close : noop} />
    </div>
  )
}
