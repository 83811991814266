import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { Checkbox } from "antd"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

import { getGamesList } from "store/dictionary/selector"
import { CheckBoxComponent } from "Components/CheckBox"

import { sportGames } from "./constants"
import { FilterGamesProps } from "./types"
import { GamesData } from "store/dictionary/types"

const CheckboxGroup = Checkbox.Group

const GamesList = ({
  filterGames,
  setFilterGames,
  withSport,
  customFilter
}: FilterGamesProps) => {
  const games = useSelector(getGamesList)

  let gamesWithSport = withSport ? [...games, sportGames] : games

  if (customFilter) {
    gamesWithSport = gamesWithSport.filter(
      item => (item as GamesData)[customFilter]
    )
  }

  const allIds = gamesWithSport.map(item => item.id)

  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(
    filterGames.length === gamesWithSport.length
  )

  const options: {
    value: number | string
    label: string
  }[] = gamesWithSport.map(item => ({
    value: item.id,
    label: item.name
  }))

  const onCheckAllChange = (e: any) => {
    setFilterGames(e.target.checked ? allIds : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    setCheckAll(filterGames.length === gamesWithSport.length)
  }, [filterGames])

  return (
    <div>
      <div className="GamesListFilterCheckboxAllWrapper">
        <CheckBoxComponent
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        />
        <div className="GamesListFilterCheckboxAllLabel">Выбрать все</div>
      </div>
      <CheckboxGroup
        className="GamesListFilterCheckboxGroupWrapper"
        options={options}
        value={filterGames}
        onChange={(list: (number | CheckboxValueType)[]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFilterGames(list)
          setIndeterminate(!!list.length && list.length < gamesWithSport.length)
          setCheckAll(list.length === gamesWithSport.length)
        }}
      />
    </div>
  )
}

export default GamesList
