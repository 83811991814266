import React, { useEffect, useState, useMemo } from "react"
import { useSelector } from "react-redux"
import { useRequest } from "api/hooks"
import { getCurrentHall } from "store/halls/selector"
import Title from "Components/Title"
import { ButtonPrimary } from "Components/Button"

import {
  exteriorInitialState,
  initialExteriorCard,
  initialInteriorCard,
  interiorInitialState,
  localStorageKey,
  textConst
} from "./constants"

import Information from "./Components/Information"
import HallsCards from "./Components/HallsCards"
import RulesPopup from "./Components/RulesPopup"
import SuccessModal from "Components/SuccessModal"
import PhotoPreviewModal from "Components/Photo/PreviewModal"

import "./styles.less"

import { CardState, ImageGroup, PreviewData } from "./types"
import { compareArrays, validatePhotos } from "./helpers"

const Geoservices = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [visited, setVisited] = useState(false)
  const hallState = useSelector(getCurrentHall)
  const [exteriorState, setExteriorState] = useState<CardState[]>([])
  const handleExteriorState = (data: CardState[]) => {
    setExteriorState(data)
  }
  const [interiorState, setInteriorState] = useState<CardState[]>([])
  const handleInteriorState = (data: CardState[]) => {
    setInteriorState(data)
  }
  const [initialData, setInitialData] = useState<CardState[]>([])
  const [preview, togglePreview] = useState<PreviewData | undefined>(undefined)

  const [pending, setPending] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  const preparedData = useMemo(
    () => ({
      hall: hallState[0],
      images: [
        ...exteriorState.filter(item => !!item.image || !!item.link),
        ...interiorState.filter(item => !!item.image || !!item.link)
      ]
    }),
    [hallState, exteriorState, interiorState]
  )

  const isDisabled = useMemo(() => {
    const isValid =
      validatePhotos(textConst.minCountExterior, exteriorState) &&
      validatePhotos(textConst.minCountInterior, interiorState)
    if (initialData.length && isValid) {
      return compareArrays(initialData, preparedData.images)
    }
    return !isValid
  }, [exteriorState, interiorState, initialData, preparedData])

  const { request: getCardsInfo } = useRequest({
    method: "POST",
    url: "v1/hall/geoservice-photos",
    requestBody: {
      filter: {
        hall: Number(hallState[0])
      }
    }
  })
  const getCards = () => {
    getCardsInfo().then(({ data }: { data: CardState[] }) => {
      const dataExterior = data.length
        ? [
            ...data.filter(item => item.group === ImageGroup.EXTERIOR),
            initialExteriorCard
          ]
        : exteriorInitialState
      const dataInterior = data.length
        ? [
            ...data.filter(item => item.group === ImageGroup.INTERIOR),
            initialInteriorCard
          ]
        : interiorInitialState

      setExteriorState(dataExterior)
      setInteriorState(dataInterior)
      setInitialData(data)
    })
  }

  useEffect(() => {
    const isVisited = localStorage.getItem(localStorageKey)
    if (!isVisited) {
      localStorage.setItem("GEOSERVICES_PAGE_VISITED", "true")
      setIsModalOpen(true)
    }
    setVisited(!!isVisited)
  }, [])

  useEffect(() => {
    getCards()
  }, [])

  const requestData = useMemo(() => {
    const images = preparedData.images.map(item => {
      if (!!item.link) {
        return { ...item, link: undefined, image: item.link }
      }
      return item
    })

    return { ...preparedData, images }
  }, [preparedData])

  const { request: sendCardInfo } = useRequest({
    method: "POST",
    url: "v1/hall/geoservice-photos/save",
    requestBody: requestData
  })

  return (
    <div>
      <div className="MainPageContentWrapper">
        <div className="MainPageContentRestrictions">
          <Title
            titleText={textConst.title}
            tooltipText={textConst.titleTooltip}
          />
          <div className="FlexColumn Geoservices">
            <Information openModal={() => setIsModalOpen(true)} />
            <HallsCards
              state={exteriorState}
              minCount={textConst.minCountExterior}
              setState={handleExteriorState}
              titleText={textConst.exterior}
              minCardsText={textConst.minExteriorPhotos}
              selectOptions={textConst.exteriorPlaces}
              initialCard={initialExteriorCard}
              hallType={ImageGroup.EXTERIOR}
              openPreview={togglePreview}
            />
            <HallsCards
              state={interiorState}
              minCount={textConst.minCountInterior}
              setState={handleInteriorState}
              titleText={textConst.interior}
              minCardsText={textConst.minInteriorPhotos}
              selectOptions={textConst.interiorPlaces}
              initialCard={initialInteriorCard}
              hallType={ImageGroup.INTERIOR}
              openPreview={togglePreview}
            />
            <ButtonPrimary
              disabled={isDisabled || pending}
              onClick={() => {
                setPending(true)
                sendCardInfo()
                  .then(({ data }) => {
                    setInitialData(data)
                    setSuccessModal(true)
                  })
                  .finally(() => setPending(false))
              }}
            >
              {textConst.send}
            </ButtonPrimary>
          </div>
        </div>
      </div>
      <RulesPopup
        close={() => {
          setIsModalOpen(!isModalOpen)
          setVisited(true)
        }}
        isOpened={isModalOpen}
        isVisited={visited}
      />
      {successModal && (
        <SuccessModal
          close={() => {
            setSuccessModal(false)
          }}
          title={textConst.successSend}
        />
      )}
      {!!preview && (
        <PhotoPreviewModal
          close={() => {
            togglePreview(undefined)
          }}
          link={preview?.link}
          title={preview?.zone}
          description={preview?.description}
        />
      )}
    </div>
  )
}

export default Geoservices
