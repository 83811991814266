import React from "react"
import { Collapse } from "antd"
import { ReactComponent as DropdownMenuIcon } from "Components/icons/dropdown.svg"
import { TableCardItemCollapsibleConfig } from "../TableCard/types"
import TableCard from "../TableCard"
import TableCardHeader from "./TableCardHeader"

// Карточка Collapsible мобильного представления для таблицы.
// config - массив строк карты и текст заголовка (опционально с классом).

const TableCardCollapsible = ({
  config
}: {
  config: TableCardItemCollapsibleConfig[]
}) => {
  const { Panel } = Collapse
  const content = config.map((card, index) => (
    <Panel
      key={`${index}`}
      header={
        <TableCardHeader
          text={card.title}
          prefix={card.titlePrefix}
          headerClassName={`TableCardCollapsibleHeader ${
            card.titleClassName ? card.titleClassName : ""
          }`}
        />
      }
    >
      <TableCard config={card.items} collapsible={true} />
    </Panel>
  ))
  return (
    <div className={"TableCardCollapsible"}>
      <Collapse
        expandIconPosition="right"
        expandIcon={panelProps => (
          <div>
            <DropdownMenuIcon
              className={
                panelProps.isActive ? `AccordionItemUp` : `AccordionItemDown`
              }
            />
          </div>
        )}
      >
        {content}
      </Collapse>
    </div>
  )
}

export default TableCardCollapsible
