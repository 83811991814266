import React from "react"

import { Image } from "antd"

import moment from "moment"
import _ from "lodash"
import produce from "immer"
import plural from "plural-ru"

import { useRequest, useRequestParams } from "api/hooks"

import { moneyRender } from "constants/index"
import { GamesData } from "store/dictionary/types"

import { TableHeader } from "Components/TableNew"
import FromToDateFilter from "Components/TableNew/TableFilters/FromToDates"
import CheckListFilter from "Components/TableNew/TableFilters/CheckListFilter"
import Input from "Components/Input"

import { NominalsStatusesDict, nominalsText, sectionsDict } from "./constants"
import {
  CreateHistoryColumnsArgs,
  MakeBetColumnsAndDataArgs,
  MakeBetColumnsAndDataArgsRow,
  MinMaxElement,
  NominalHistoryStatus,
  NominalSection,
  NominalSectionKeys,
  NominalsGame,
  NominalsMinMaxKeys,
  NominalsNew,
  NominalsRequests,
  TableRow,
  TableRowMinMax,
  TableRowMinMaxCol
} from "./types"

export const useNominalsRequests = ({
  historyArgs,
  validateArgs,
  changeArgs
}: NominalsRequests) => {
  const { currentHallAsNumber } = useRequestParams()
  const { limit, offset, ...rest } = historyArgs || {}
  let newValidateArgs = { ...validateArgs }
  let newChangeArgs = { ...changeArgs }
  if (newValidateArgs?.vip?.nominals?.length === 0) {
    // eslint-disable-next-line
    const { vip, ...rest } = newValidateArgs
    newValidateArgs = { ...rest }
  }

  if (newChangeArgs?.vip?.nominals?.length === 0) {
    // eslint-disable-next-line
    const { vip, ...rest } = newChangeArgs
    newChangeArgs = { ...rest }
  }

  const { request: getNominalsHistory } = useRequest({
    url: `v1/nominals/history`,
    method: `POST`,
    requestBody: {
      hall: currentHallAsNumber,
      pagination: {
        limit,
        offset
      },
      ...rest
    }
  })

  const { request: getCurrentNominals } = useRequest({
    url: `v1/nominals/manage`,
    method: `POST`,
    requestBody: {
      hall: currentHallAsNumber
    }
  })

  const { request: validateNewNominals } = useRequest({
    url: `v1/nominals/manage/validate`,
    method: `POST`,
    requestBody: {
      hall: currentHallAsNumber,
      ...newValidateArgs
    },
    skip404Handler: true
  })

  const { request: changeNewNominals } = useRequest({
    url: `v1/nominals/manage/change`,
    method: `POST`,
    requestBody: {
      hall: currentHallAsNumber,
      ...newChangeArgs,
      ...newValidateArgs
    }
  })

  return {
    getNominalsHistory,
    getCurrentNominals,
    validateNewNominals,
    changeNewNominals
  }
}

const generateTableDataForZone = (
  zoneData: NominalSection,
  zoneKey: NominalSectionKeys
) => {
  const tableData: TableRow[] = []
  if (zoneData?.nominals.length) {
    const newRow: TableRow = {
      id: {
        title: sectionsDict[zoneKey].text,
        img: sectionsDict[zoneKey].icon
      },
      max_limit: { zone: zoneKey, value: zoneData.max_limit }
    }
    zoneData.nominals.forEach(nominal => {
      newRow[`nominal_${nominal.id}`] = {
        value: nominal.value,
        zone: zoneKey,
        id: nominal.id
      }
    })
    tableData.push(newRow)
  }
  return tableData
}

export const generateTableData = (game?: NominalsGame) => {
  if (game) {
    const { vip, main } = game
    const isMinMax = Boolean(main?.limits)

    const vipTableData = generateTableDataForZone(vip, NominalSectionKeys.vip)
    const mainTableData = generateTableDataForZone(
      main,
      NominalSectionKeys.main
    )

    return { isMinMax, vipTableData, mainTableData }
  }
  return { isMinMax: false, vipTableData: [], mainTableData: [] }
}

export const createHistoryColumns = ({
  onMoreClicked,
  onDatesChanged,
  onGamesChanged,
  gamesDict,
  filterDates,
  filterGames,
  onSortChanged,
  sort
}: CreateHistoryColumnsArgs) => {
  return [
    {
      title: nominalsText.reqNumber,
      dataIndex: "id",
      key: "id"
    },
    {
      dataIndex: "created_at",
      key: "created_at",
      title: () => (
        <TableHeader title={nominalsText.dateNTime}>
          <FromToDateFilter
            hasSort
            rangeOnly
            order={sort?.order}
            fromDate={filterDates?.from ? moment(filterDates.from) : undefined}
            toDate={filterDates?.to ? moment(filterDates.to) : undefined}
            changeFilter={(newFilter: any) => {
              if (newFilter.from && newFilter.to) {
                onDatesChanged({ from: newFilter.from, to: newFilter.to })
              }
              onSortChanged({
                field: "created_at",
                order: newFilter?.order || "desc"
              })
            }}
          />
        </TableHeader>
      ),
      render: (date?: string) =>
        date && (
          <div className="FlexColumn">
            <span className="NominalsTableDate">
              {moment(date).format(`DD.MM.YYYY`)}
            </span>
            <span className="NominalsTableTime">
              {moment(date).format(`HH:mm`)}
            </span>
          </div>
        )
    },
    {
      title: nominalsText.applicant,
      dataIndex: "name",
      key: "name"
    },
    {
      title: nominalsText.phone,
      dataIndex: "phone",
      key: "phone",
      width: 150,
      minWidth: 150
    },
    {
      dataIndex: "game_id",
      key: "game_id",
      title: () => (
        <TableHeader title={nominalsText.game}>
          <CheckListFilter
            list={gamesDict.map((game: GamesData) => ({
              value: String(game.id),
              label: game.name
            }))}
            initialList={filterGames ? filterGames.map(String) : []}
            changeFilter={(newList: number[]) => {
              onGamesChanged(newList.map(Number))
            }}
            hideAllSelect
          />
        </TableHeader>
      ),
      render: (gameId: number) => {
        const foundedGame = gamesDict.find(el => el.id === gameId)
        if (foundedGame) {
          return (
            <div className="NominalsColumns_GameCell">
              <Image
                src={foundedGame.image}
                width={24}
                height={24}
                preview={false}
              />
              <div>{foundedGame.name}</div>
            </div>
          )
        }
      }
    },
    {
      title: nominalsText.status,
      dataIndex: "status",
      key: "status",
      render: (val: NominalHistoryStatus) => (
        <div className={`NominalStatus NominalStatus_${val}`}>
          {NominalsStatusesDict[val]}
        </div>
      )
    },
    {
      title: nominalsText.comment,
      dataIndex: "comment",
      key: "comment",
      width: 400,
      minWidth: 400,
      render: (comment?: string) =>
        comment && (
          <div className="NominalsTableCommentContainer">
            <div className="NominalsTableCommentWrapper">{comment}</div>
            {comment.length > 100 && (
              <div
                className="NominalsTableCommentWrapperMore"
                onClick={() => {
                  onMoreClicked(comment)
                }}
              >
                {nominalsText.more}
              </div>
            )}
          </div>
        )
    }
  ]
}

export const customizer = (objValue: any, srcValue: any) => {
  if (_.isArray(objValue)) {
    return objValue.map((objItem, index) => {
      const srcItem = srcValue[index]
      if (srcItem) {
        if (objItem.limits && srcItem.limits) {
          return {
            ...objItem,
            limits: {
              min: srcItem.limits.min ? srcItem.limits.min : objItem.limits.min,
              max: srcItem.limits.max ? srcItem.limits.max : objItem.limits.max
            }
          }
        } else {
          return {
            ...objItem,
            ...srcItem
          }
        }
      }
      return objItem
    })
  }
}

export const checkResult = ({
  result,
  obj,
  nominals
}: {
  result: NominalsNew
  obj: { vip: NominalSection; main: NominalSection }
  nominals: NominalsNew
}) => {
  const mainNominals = nominals[NominalSectionKeys.main].nominals
  const mainObj = obj[NominalSectionKeys.main].nominals

  const mapped = mainNominals.map(item => ({ [item.id]: item.value }))
  const newObj = Object.assign({}, ...mapped)

  const main = mainObj.map(item =>
    !!newObj[item.id] ? { ...item, value: newObj[item.id] } : item
  )

  return {
    ...result,
    [NominalSectionKeys.main]: {
      ...result[NominalSectionKeys.main],
      nominals: main
    }
  }
}

const makeMinMaxRowData = (
  data: MinMaxElement[],
  rowTitle: string,
  minMaxKey: NominalsMinMaxKeys,
  zone: NominalSectionKeys
) => {
  const reducedData = data.reduce((acc, el) => {
    acc[String(el.number_count)] = {
      zone,
      minMaxKey,
      value: el.value,
      numberCount: el.number_count
    }
    return acc
  }, {} as TableRowMinMaxCol)
  ;(reducedData as any).betTitle = rowTitle

  return reducedData
}

const makeMinMaxTableColumns = ({
  maxLimit,
  isMinMax,
  nominalsNew,
  nominalsErrors,
  setNominalsNew,
  zone
}: MakeBetColumnsAndDataArgsRow) => {
  if (maxLimit && isMinMax && typeof maxLimit === "object") {
    const initialColumns = [
      {
        title: () => <div className="NominalsBetTitle">{nominalsText.bet}</div>,
        dataIndex: "betTitle",
        key: "betTitle",
        render: (value: string) => (
          <div className="ChangeTabColWrapper">
            <div className="ChangeTabColWrapper_TitleImageContainer">
              <div className="ChangeTabColWrapper_TitleImageContainer_Text">
                {value}
              </div>
            </div>
            <div className="ChangeTabColWrapper_TitleImageContainer_Text-secondary">
              {nominalsText.change}
            </div>
          </div>
        )
      }
    ]
    let dataForColumns = maxLimit?.max
    if (dataForColumns?.length < maxLimit?.min?.length) {
      dataForColumns = maxLimit.min
    }
    const formattedColumns = dataForColumns?.map(({ number_count }) => ({
      title: `${number_count} ${plural(
        number_count,
        ...(nominalsText.plural as [string, string, string])
      )}`,
      dataIndex: number_count,
      key: number_count,
      render: ({ value, minMaxKey, zone, numberCount }: TableRowMinMax) => (
        <div className="ChangeTabColNominalChange_Wrapper">
          <div className="ChangeTabColNominalChange_Title">{`${moneyRender(
            value
          )} ${nominalsText.rubles}`}</div>
          <Input
            value={
              nominalsNew[zone]?.limits?.[minMaxKey]?.find(
                el => el.number_count === numberCount
              )?.value
            }
            errors={nominalsErrors[zone]?.limits?.[minMaxKey]
              ?.filter(el => el.number_count === numberCount)
              .map(el => el.error)}
            greyBackground
            type={"number"}
            hideControls
            className="ChangeTabColNominalChange_Input"
            onChange={({ target }) => {
              const limitsObj = nominalsNew[zone]?.limits
              const founedKey =
                limitsObj &&
                limitsObj[minMaxKey]?.findIndex(
                  el => el.number_count === numberCount
                )
              setNominalsNew(
                produce(nominalsNew, draft => {
                  if (founedKey !== undefined && founedKey > -1) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    draft[zone].limits![minMaxKey][founedKey].value = Number(
                      target.value
                    )
                  } else {
                    if (!draft[zone].limits) {
                      draft[zone].limits = {
                        [NominalsMinMaxKeys.min]: [],
                        [NominalsMinMaxKeys.max]: []
                      }
                    }
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    draft[zone].limits![minMaxKey].push({
                      number_count: numberCount,
                      value: Number(target.value)
                    })
                  }
                })
              )
            }}
          />
        </div>
      )
    }))
    const nextColumns = [...initialColumns, ...formattedColumns]
    const valueMin = makeMinMaxRowData(
      maxLimit.min,
      nominalsText.minLimitShort,
      NominalsMinMaxKeys.min,
      zone
    )
    const valueMax = makeMinMaxRowData(
      maxLimit.max,
      nominalsText.maxLimitShort,
      NominalsMinMaxKeys.max,
      zone
    )
    const nextData = [valueMin, valueMax]
    return { nextColumns, nextData }
  }
  return { nextData: [], nextColumns: [] }
}

export const makeBetColumnsAndData = ({
  activeGame,
  isMinMax,
  nominalsNew,
  nominalsErrors,
  setNominalsNew
}: MakeBetColumnsAndDataArgs) => {
  if (activeGame) {
    const {
      nextColumns: betColumnsMain,
      nextData: betDataMain
    } = makeMinMaxTableColumns({
      maxLimit: isMinMax
        ? activeGame[NominalSectionKeys.main]?.limits
        : activeGame.main?.max_limit,
      zone: NominalSectionKeys.main,
      nominalsNew,
      nominalsErrors,
      setNominalsNew,
      isMinMax
    })
    const {
      nextColumns: betColumnsVip,
      nextData: betDataVip
    } = makeMinMaxTableColumns({
      maxLimit: isMinMax
        ? activeGame[NominalSectionKeys.vip]?.limits
        : activeGame.vip?.max_limit,
      zone: NominalSectionKeys.vip,
      nominalsNew,
      nominalsErrors,
      setNominalsNew,
      isMinMax
    })

    return {
      betColumnsVip,
      betColumnsMain,
      betDataVip,
      betDataMain
    }
  }
  return {
    betColumnsVip: [],
    betColumnsMain: [],
    betDataVip: [],
    betDataMain: []
  }
}

export const getMinMaxKey = (structure: TableRowMinMaxCol) => {
  let minMaxValue

  for (const key in structure) {
    if (typeof structure[key] !== "object") continue
    const currentMinMaxKey = structure[key].minMaxKey
    if (!minMaxValue) {
      minMaxValue = currentMinMaxKey
    } else {
      if (currentMinMaxKey !== minMaxValue) {
        return undefined
      }
    }
  }
  return minMaxValue
}

export const isValidPhoneNumber = (value: string) => {
  const regex = /^\+7 \(\d{3}\) \d{3} \d{2} \d{2}$/
  return regex.test(value)
}

export const getNominalsGames = ({
  games,
  nominals
}: {
  games: { label: string; id: string | number; icon: string }[]
  nominals: NominalsGame[]
}) => {
  const allGamesId: (number | string)[] = []
  nominals.map(item => {
    if (!allGamesId.includes(item.game_id)) {
      allGamesId.push(item.game_id)
    }
  })
  return games.filter(item => allGamesId.includes(item.id)) as {
    label: string
    id: string | number
    icon: string
  }[]
}
