import React from "react"

import moment, { Moment } from "moment"

import { isEmpty } from "lodash"

import { getPaginationDefault } from "api/hooks"

import { valueToCurrency } from "constants/index"

import { getColumnSort } from "Components/TableNew/helpers"
import { TableHeader } from "Components/TableNew"
import SearchFilter from "Components/TableNew/TableFilters/SearchFilter"
import SelectFilter from "Components/TableNew/TableFilters/SelectFilter"
import { FromToFilter } from "Components/TableNew/TableFilters/FromToFilter"
import FromToDates from "Components/TableNew/TableFilters/FromToDates"
import TooltipComponent from "Components/Tooltip"

import { FromToFilterSetterProps } from "Components/TableNew/TableFilters/FromToFilter/types"
import { FilesModalState, MoreModalState } from "./Components/types"

import { ReactComponent as AttachIcon } from "Components/icons/attach_grey.svg"
import { ReactComponent as StatusCommentIcon } from "Components/icons/info.svg"
import { ReactComponent as RubleIcon } from "Components/icons/money_circle_outline.svg"
import { ReactComponent as CopyIcon } from "Components/icons/copy_outline_20.svg"

import {
  FilterState,
  FilterTable,
  PaymentFile,
  PaymentTableFields,
  PaymentTableState,
  PaymentTableStatus
} from "./types"
import { PaymentSetModalProp } from "../types"
import { paymentText } from "../constants"

export const initialState: PaymentTableState = {
  data: [],
  pending: false,
  total_count: 0
}

export const initialFilter: FilterState = {
  table: {
    sort: {}
  },
  pagination: getPaginationDefault(30)
}
interface ColumnsArg extends PaymentSetModalProp {
  filters: FilterState
  setFilter: (val: FilterState) => void
  changeFilesModal: (val: FilesModalState) => void
  onCommentClick: (val: MoreModalState) => void
}

export const prepareTableFilter = (filters: FilterTable) => {
  const sort = filters.sort?.field ? filters.sort : undefined

  function areAllPropsUndefined(obj: any) {
    for (const key in obj) {
      if (obj[key] !== undefined) {
        return false
      }
    }
    return true
  }
  const filtersWithSort = { ...filters, sort: undefined }
  const isEmptyFilter =
    areAllPropsUndefined(filtersWithSort) || isEmpty(filtersWithSort)
  const filter = isEmptyFilter ? undefined : filtersWithSort

  return { filter, sort }
}

export const formatDate = (input?: string | Moment): string => {
  if (!!input) {
    if (moment.isMoment(input)) {
      return input.format("DD.MM.YYYY")
    } else {
      const [year, month, day] = input.split("-")
      return `${day}.${month}.${year}`
    }
  }
  return ""
}

export const statusText = {
  [PaymentTableStatus.new]: `Новая`,
  [PaymentTableStatus.accepted]: `Принята`,
  [PaymentTableStatus.paid]: `Оплачена`,
  [PaymentTableStatus.canceled]: `Отменена`,
  [PaymentTableStatus.failed]: `Ошибка`
}

const statusOptions = [
  {
    value: PaymentTableStatus.new,
    label: statusText[PaymentTableStatus.new]
  },
  {
    value: PaymentTableStatus.accepted,
    label: statusText[PaymentTableStatus.accepted]
  },
  {
    value: PaymentTableStatus.paid,
    label: statusText[PaymentTableStatus.paid]
  },
  {
    value: PaymentTableStatus.canceled,
    label: statusText[PaymentTableStatus.canceled]
  },
  {
    value: PaymentTableStatus.failed,
    label: statusText[PaymentTableStatus.failed]
  }
]

export const textByField = {
  main: {
    filterLabel: `Фильтровать`
  },
  [PaymentTableFields.id]: {
    title: `№`,
    label: `№`
  },
  [PaymentTableFields.contractor]: {
    title: `Контрагент`,
    label: `Контрагент`,
    placeholder: `Введите контрагента`
  },
  [PaymentTableFields.sum]: {
    title: `Сумма, ₽`,
    label: `Сумма, ₽`
  },
  [PaymentTableFields.payment_purpose]: {
    title: `Назначение платежа`,
    label: `Назначение платежа`,
    placeholder: `Введите назначение платежа`
  },

  [PaymentTableFields.payment_period]: {
    title: `Период оплаты`,
    label: `Период оплаты`
  },
  [PaymentTableFields.created_date]: {
    title: `Дата создания`,
    label: `Дата создания`
  },
  [PaymentTableFields.status]: {
    title: `Статус`,
    label: `Статус`,
    placeholder: `Выберите статус`
  },
  [PaymentTableFields.inn]: {
    title: `ИНН`,
    label: `ИНН`
  },
  [PaymentTableFields.comment]: {
    title: `Комментарий`,
    label: `Комментарий`
  },
  [PaymentTableFields.files]: {
    title: `Файлы`,
    empty: `-`
  }
}

export const getColumns = ({
  filters,
  setFilter,
  changeFilesModal,
  setPaymentModal,
  onCommentClick
}: ColumnsArg) => {
  return [
    {
      title: () => (
        <div className="MarginLeft24">
          {textByField[PaymentTableFields.id].title}
        </div>
      ),
      width: 110,
      dataIndex: PaymentTableFields.id,
      key: PaymentTableFields.id,
      render: (id: string) => <div className="MarginLeft24">{id}</div>
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PaymentTableFields.contractor
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!filters.table[PaymentTableFields.contractor] ||
              filters.table.sort?.field === PaymentTableFields.contractor
            }
            className=""
            title={textByField[PaymentTableFields.contractor].title}
          >
            <SearchFilter
              value={filters.table[PaymentTableFields.contractor]}
              withSort={false}
              withEqual={false}
              iconSearch={true}
              label={textByField.main.filterLabel}
              placeholder={
                textByField[PaymentTableFields.contractor].placeholder
              }
              currentSort={filters.table.sort}
              changeFilter={newValue =>
                setFilter({
                  ...filters,
                  pagination: getPaginationDefault(filters.pagination.limit),
                  table: {
                    ...filters.table,
                    [PaymentTableFields.contractor]:
                      newValue?.value || undefined,
                    sort: {
                      order: newValue?.order,
                      field: newValue?.sortField
                    }
                  }
                })
              }
            />
          </TableHeader>
        )
      },
      width: 80,
      dataIndex: PaymentTableFields.contractor,
      key: PaymentTableFields.contractor
    },
    {
      title: textByField[PaymentTableFields.inn].title,
      width: 136,
      dataIndex: PaymentTableFields.inn,
      key: PaymentTableFields.inn
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PaymentTableFields.sum
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!filters.table[PaymentTableFields.sum] ||
              filters.table.sort?.field === PaymentTableFields.sum
            }
            className=""
            title={textByField[PaymentTableFields.sum].title}
            modalTitle={textByField[PaymentTableFields.sum].label}
          >
            <FromToFilter
              suffix={<RubleIcon />}
              isFloatNumber
              label={textByField.main.filterLabel}
              fieldName={PaymentTableFields.sum}
              title={textByField[PaymentTableFields.sum].label}
              from={filters.table[PaymentTableFields.sum]?.from}
              to={filters.table[PaymentTableFields.sum]?.to}
              currentSort={filters.table.sort}
              defaultFilter={{
                field: PaymentTableFields.sum,
                from: filters.table[PaymentTableFields.sum]?.from,
                to: filters.table[PaymentTableFields.sum]?.to
              }}
              currentFilter={{
                ...filters.table[PaymentTableFields.sum],
                field: PaymentTableFields.sum
              }}
              setter={(fromToState: FromToFilterSetterProps) => {
                const newSort = {
                  field: fromToState.sortField,
                  order: fromToState.order
                }
                setFilter({
                  ...filters,
                  pagination: getPaginationDefault(filters.pagination.limit),
                  table: {
                    ...filters.table,
                    sort: newSort,
                    [PaymentTableFields.sum]:
                      !!fromToState.from || !!fromToState.to
                        ? {
                            from: fromToState?.from?.toFixed(2),
                            to: fromToState?.to?.toFixed(2)
                          }
                        : undefined
                  }
                })
              }}
            />
          </TableHeader>
        )
      },
      tittle: textByField[PaymentTableFields.sum].title,
      width: 136,
      dataIndex: PaymentTableFields.sum,
      key: PaymentTableFields.sum,
      render: (sum: string) => {
        const currency = valueToCurrency({
          value: Number(sum),
          withoutSpace: true
        })
        return <div>{currency}</div>
      }
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PaymentTableFields.payment_purpose
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!filters.table[PaymentTableFields.payment_purpose] ||
              filters.table.sort?.field === PaymentTableFields.payment_purpose
            }
            className=""
            title={textByField[PaymentTableFields.payment_purpose].title}
          >
            <SearchFilter
              value={filters.table[PaymentTableFields.payment_purpose]}
              withSort={true}
              withEqual={false}
              iconSearch={true}
              label={textByField.main.filterLabel}
              placeholder={
                textByField[PaymentTableFields.payment_purpose].placeholder
              }
              currentSort={filters.table.sort}
              changeFilter={newValue =>
                setFilter({
                  ...filters,
                  pagination: getPaginationDefault(filters.pagination.limit),
                  table: {
                    ...filters.table,
                    [PaymentTableFields.payment_purpose]:
                      newValue?.value || undefined,
                    sort: {
                      order: newValue?.order,
                      field: newValue?.sortField
                    }
                  }
                })
              }
              field={PaymentTableFields.payment_purpose}
            />
          </TableHeader>
        )
      },
      width: 136,
      dataIndex: PaymentTableFields.payment_purpose,
      key: PaymentTableFields.payment_purpose
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PaymentTableFields.payment_period
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        const fromDate = !!filters.table[PaymentTableFields.payment_period]
          ?.from
          ? moment(filters.table[PaymentTableFields.payment_period]?.from)
          : undefined
        const toDate = !!filters.table[PaymentTableFields.payment_period]?.to
          ? moment(filters.table[PaymentTableFields.payment_period]?.to)
          : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!filters.table[PaymentTableFields.payment_period] ||
              filters.table.sort?.field === PaymentTableFields.payment_period
            }
            className=""
            title={textByField[PaymentTableFields.payment_period].title}
            modalTitle={textByField[PaymentTableFields.payment_period].label}
          >
            <FromToDates
              rangeOnly
              hasSort={
                filters.table.sort.field === PaymentTableFields.payment_period
              }
              order={filters.table.sort.order}
              fromDate={fromDate}
              toDate={toDate}
              changeFilter={(newFilters: any) => {
                const falseSort =
                  filters.table.sort.field === PaymentTableFields.payment_period
                    ? {}
                    : filters.table.sort
                setFilter({
                  table: {
                    ...filters.table,
                    [PaymentTableFields.payment_period]:
                      newFilters.from && newFilters.to
                        ? {
                            from: moment(newFilters.from).utc(true),
                            to: moment(newFilters.to).utc(true)
                          }
                        : undefined,
                    sort: newFilters.hasSort
                      ? {
                          order: newFilters.order,
                          field: PaymentTableFields.payment_period
                        }
                      : falseSort
                  },
                  pagination: getPaginationDefault(filters.pagination.limit)
                })
              }}
              skipDisabled
            />
          </TableHeader>
        )
      },
      width: 136,
      dataIndex: PaymentTableFields.payment_period,
      key: PaymentTableFields.payment_period,
      render: (payment_period: { from: string; to: string }) => {
        const dateByFormatFrom = moment(
          payment_period.from,
          `YYYY-MM-DDTHH:mm:ss`
        )
        const dateByFormatTo = moment(payment_period.to, `YYYY-MM-DDTHH:mm:ss`)
        const from = dateByFormatFrom.format(`DD.MM.YYYY`)
        const to = dateByFormatTo.format(`DD.MM.YYYY`)
        return (
          <div>
            <div>{from} –</div>
            <div>{to}</div>
          </div>
        )
      }
    },
    {
      title: textByField[PaymentTableFields.comment].title,
      width: 176,
      dataIndex: PaymentTableFields.comment,
      key: PaymentTableFields.comment,
      render: (comment: string) => {
        const isLong = comment.length > 25
        return (
          comment && (
            <div className="PaymentsTableCommentContainer">
              <div
                className={`PaymentsTableCommentWrapper ${
                  isLong ? "PaymentsTableCommentWrapper_overflow" : ""
                }`}
              >
                {comment}
              </div>
              {isLong && (
                <div
                  className="PaymentsTableCommentWrapperMore"
                  onClick={() => {
                    onCommentClick({ isActive: true, comment })
                  }}
                >
                  {paymentText.more}
                </div>
              )}
            </div>
          )
        )
      }
    },
    {
      title: textByField[PaymentTableFields.files].title,
      width: 80,
      dataIndex: PaymentTableFields.files,
      key: PaymentTableFields.files,
      render: (files: PaymentFile[]) => {
        const filesCount = files.length
        return files.length ? (
          <div
            className="FlexRow Gap8 PaymentTableFiles"
            onClick={() => changeFilesModal({ open: true, data: files })}
          >
            <AttachIcon />
            <span className="TextOne Gray600Color PaddingTop2">
              {filesCount}
            </span>
          </div>
        ) : (
          <div className="CaptionText">
            {textByField[PaymentTableFields.files].empty}
          </div>
        )
      }
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PaymentTableFields.created_date
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        const fromDate = !!filters.table[PaymentTableFields.created_date]?.from
          ? moment(filters.table[PaymentTableFields.created_date]?.from)
          : undefined
        const toDate = !!filters.table[PaymentTableFields.created_date]?.to
          ? moment(filters.table[PaymentTableFields.created_date]?.to)
          : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={
              !!filters.table[PaymentTableFields.created_date] ||
              filters.table.sort?.field === PaymentTableFields.created_date
            }
            className=""
            title={textByField[PaymentTableFields.created_date].title}
            modalTitle={textByField[PaymentTableFields.created_date].label}
          >
            <FromToDates
              rangeOnly
              hasSort={
                filters.table.sort.field === PaymentTableFields.created_date
              }
              order={filters.table.sort.order}
              fromDate={fromDate}
              toDate={toDate}
              changeFilter={(newFilters: any) => {
                const falseSort =
                  filters.table.sort.field === PaymentTableFields.created_date
                    ? {}
                    : filters.table.sort
                setFilter({
                  table: {
                    ...filters.table,
                    [PaymentTableFields.created_date]:
                      newFilters.from && newFilters.to
                        ? {
                            from: moment(newFilters.from).utc(true),
                            to: moment(newFilters.to).utc(true)
                          }
                        : undefined,
                    sort: newFilters.hasSort
                      ? {
                          order: newFilters.order,
                          field: PaymentTableFields.created_date
                        }
                      : falseSort
                  },
                  pagination: getPaginationDefault(filters.pagination.limit)
                })
              }}
            />
          </TableHeader>
        )
      },
      width: 136,
      dataIndex: PaymentTableFields.created_date,
      key: PaymentTableFields.created_date,
      render: (created_date: string) => {
        const dateByFormat = moment(created_date, `YYYY-MM-DDTHH:mm:ss`)
        const date = dateByFormat.format(`DD.MM.YYYY`)
        const time = dateByFormat.format(`HH:mm`)
        return (
          <div className="FlexColumn Gap4">
            <span className="TextOne">{date}</span>
            <span className="TextOne Gray600Color">{time}</span>
          </div>
        )
      }
    },
    {
      title: () => {
        const isSort =
          filters.table.sort &&
          !!filters.table.sort?.order &&
          filters.table.sort?.field === PaymentTableFields.status
            ? getColumnSort(filters.table.sort?.order)
            : undefined
        return (
          <TableHeader
            isSort={isSort}
            isActiveField={!!filters.table[PaymentTableFields.status]}
            className=""
            title={textByField[PaymentTableFields.status].label}
          >
            <SelectFilter
              value={filters.table[PaymentTableFields.status]}
              placeholder={textByField[PaymentTableFields.status].placeholder}
              withSort={false}
              options={statusOptions}
              currentSort={filters.table.sort}
              currentField={PaymentTableFields.status}
              changeFilter={newValue =>
                setFilter({
                  ...filters,
                  pagination: getPaginationDefault(filters.pagination.limit),
                  table: {
                    ...filters.table,
                    [PaymentTableFields.status]: newValue.value,
                    sort: newValue.sort
                  }
                })
              }
            />
          </TableHeader>
        )
      },
      width: 176,
      dataIndex: PaymentTableFields.status,
      key: PaymentTableFields.status,
      render: (
        status: PaymentTableStatus,
        record: { status_reason: string }
      ) => (
        <div
          className={`PaymentStatusTableColumn PaymentsStatus-${status} FlexRow`}
        >
          <span className="StatusText">{statusText[status]}</span>

          <div className="MarginLeftAuto">
            <TooltipComponent
              title={record.status_reason}
              className={!!record.status_reason ? "" : "PaymentsPopover_hidden"}
              direction="top"
            >
              <div className="PaymentStatusTableComment">
                <StatusCommentIcon />
              </div>
            </TooltipComponent>
          </div>
        </div>
      )
    },
    {
      title: "",
      width: 56,
      dataIndex: `actions`,
      key: `actions`,
      render: (id: number, record: any) => {
        // eslint-disable-next-line
        const { payment_period, comment, ...rest } = record
        return (
          <div className="PaymentTableEditItem">
            {record.status !== PaymentTableStatus.failed && (
              <div
                onClick={() => setPaymentModal({ open: true, editData: rest })}
              >
                <CopyIcon />
              </div>
            )}
          </div>
        )
      }
    }
  ]
}
