import React from "react"
import ReportLego from "../ReportLego"
import {
  clubBalanceColumn,
  clubBalanceOperationText,
  HALL_BALANCE_FILTER_NAME,
  HALL_BALANCE_URL,
  HALL_BALANCE_URL_EXEL
} from "./helper"

const ClubBalance = () => {
  return (
    <div>
      <ReportLego
        report={{
          url: HALL_BALANCE_URL,
          tableScroll: 1200,
          getColumn: clubBalanceColumn
        }}
        exelConfig={{
          url: HALL_BALANCE_URL_EXEL,
          title: `clubBalanceOperationText.title`
        }}
        title={clubBalanceOperationText.title}
        emptyFilterTitle={clubBalanceOperationText.filterTitle}
        emptyFilterDescription={clubBalanceOperationText.filterDescription}
        filterName={HALL_BALANCE_FILTER_NAME}
        titleInfo={clubBalanceOperationText.tooltip}
      />
    </div>
  )
}

export default ClubBalance
