import React, { useCallback, useEffect, useRef, useState } from "react"
import rehypeRaw from "rehype-raw"
import axios from "axios"
import ReactMarkdown from "react-markdown"
import { useDispatch, useSelector } from "react-redux"
import { getMenuData } from "store/menu/selector"
import { storeMenuDataWiki } from "store/wiki/actions"
import { ConfigItem, WikiActiveUrl, WikiOptions } from "./types"
import {
  DashboardWikiMap,
  MenuKeys,
  text,
  WikiMenu,
  WikiSubheaderMap
} from "./constants"
import { Link, useHistory, useLocation } from "react-router-dom"
import SpinComponent from "Components/Spin"
import { BreadcrumbItem } from "Components/Breadcrumbs/types"
import Breadcrumbs from "Components/Breadcrumbs"
import { getWikiOptions } from "store/wiki/selector"
import { ConfigItemCmp } from "./components/ConfigItem"
import {
  buildConfigFromWikiMenu,
  findBreadcrumbPath,
  findInsideElementsByName,
  rehypeSummaryPlugin
} from "./helpers"
import ReportWithFilter from "Components/ReportWithFilter"
import { WIKI } from "../BaseLayout/constants"
import { isMobile } from "../../constants"
import { WikiFilter } from "./components/WikiFilter"
import { WikiSelect } from "./components/WikiSelect"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"

export const Wiki = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const [markdown, setMarkdown] = useState("")
  const [activeUrl, setActiveUrl] = useState<WikiActiveUrl>({
    name: MenuKeys.none,
    url: null,
    filesLink: ""
  })
  const [loading, setLoading] = useState(false)
  const menuData = useSelector(getMenuData)
  const optionsWikiMenu = useSelector(getWikiOptions)
  const [options, setOptions] = useState<WikiOptions>([])
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[] | null>([])
  const [renderKey, setRenderKey] = useState(0)
  const [isMain, setIsMain] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false)
  const [currentTree, setCurrentTree] = useState<ConfigItem[]>([])

  const containerRef = useRef<HTMLDivElement | null>(null)
  const uniqueIds = useRef(new Set<string>())
  const anchors = useRef<{ id: string; text: string }[]>([])

  useEffect(() => {
    if (WikiMenu && menuData) {
      const { newConfig, options } = buildConfigFromWikiMenu(WikiMenu, [
        ...menuData.main,
        ...menuData.options,
        ...menuData.bottom
      ])
      setOptions(options)
      dispatch(
        storeMenuDataWiki({
          options: newConfig
        })
      )
    }
  }, [])

  const handleSummaryAnchorCreated = (id: string, text: string) => {
    if (!uniqueIds.current.has(id)) {
      uniqueIds.current.add(id)
      anchors.current = [...anchors.current, { id, text }]
      setRenderKey(prevRenderKey => prevRenderKey + 1)
    }
  }

  useEffect(() => {
    const regex = new RegExp(`^${WIKI}`)
    const founded = options.find(el => el.value === pathname.replace(regex, ""))
    const replaced = pathname.replace(regex, "")
    const splitted = replaced.split("/")
    const clear = splitted[splitted.length - 1].replace(/-/g, " ")
    const result = findInsideElementsByName(optionsWikiMenu, clear)
    if (pathname === WIKI || pathname === `${WIKI}/`) {
      setIsMain(true)
    } else {
      setIsMain(false)
    }
    if (founded) {
      setActiveUrl({
        name: founded.label as MenuKeys,
        url: founded.value,
        filesLink: founded.filesLink
      })
    } else if (result && result.tree && result.flat) {
      setActiveUrl({
        url: splitted[splitted.length - 1],
        name: clear as MenuKeys,
        filesLink: ""
      })
      setCurrentTree(result.tree)
    }
  }, [pathname, options, optionsWikiMenu])

  useEffect(() => {
    const id = history.location.hash
    if (id && markdown) {
      const anchorElement = containerRef.current?.querySelector(id)
      const node = anchorElement?.parentNode
      if (node instanceof Element) {
        node.setAttribute("open", "")
      }
      const links = containerRef.current?.querySelectorAll(".WikiFilterLink")
      if (links?.length) {
        links.forEach(link => {
          if (link.classList.contains(`WikiFilterLink_${id}`)) {
            link.classList.add("WikiFilterLink_Active")
          } else {
            link.classList.remove("WikiFilterLink_Active")
          }
        })
      }
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [history.location.hash, markdown, renderKey, activeUrl])

  useEffect(() => {
    setBreadcrumbs(
      findBreadcrumbPath(
        [...menuData.main, ...menuData.options, ...menuData.bottom],
        activeUrl.url as string
      )
    )
    if (activeUrl.url === WIKI) {
      history.push(WIKI)
      setMarkdown("")
    }
    if (activeUrl.url && activeUrl.name && !activeUrl.filesLink) {
      history.push(activeUrl.url)
      setMarkdown("")
      const breadcrumbIdx = breadcrumbs?.findIndex(
        el => el.label === activeUrl.name
      )
      if (breadcrumbIdx !== undefined && breadcrumbIdx >= 0 && breadcrumbs) {
        setBreadcrumbs(breadcrumbs.slice(0, breadcrumbIdx + 1))
      }
    }
    if (activeUrl.url && activeUrl.filesLink) {
      setLoading(true)
      axios
        .get(`/wiki/md${activeUrl.filesLink}`)
        .then(response => {
          setMarkdown(response.data)
          anchors.current = []
          uniqueIds.current = new Set()
        })
        .catch(error => {
          setMarkdown("")
          // eslint-disable-next-line no-console
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
      setBreadcrumbs(
        findBreadcrumbPath(
          [...menuData.main, ...menuData.options, ...menuData.bottom],
          activeUrl.url as string
        )
      )
    } else if (!activeUrl.url && !activeUrl.filesLink && activeUrl.name) {
      setMarkdown("")
      const breadcrumbIdx = breadcrumbs?.findIndex(
        el => el.label === activeUrl.name
      )
      if (breadcrumbIdx !== undefined && breadcrumbIdx >= 0 && breadcrumbs) {
        setBreadcrumbs(breadcrumbs.slice(0, breadcrumbIdx + 1))
      }
    }
  }, [activeUrl, options])

  useEffect(() => {
    if (!markdown && !loading) {
      anchors.current = []
      uniqueIds.current = new Set()
      setRenderKey(renderKey + 1)
    }
  }, [markdown, loading])

  const handleBreadcrumbClicked = useCallback(
    (value: BreadcrumbItem) => {
      let filesLink = ""
      if (value.url) {
        const founded = options.find(el => el.value === value.url)
        if (founded?.filesLink) {
          filesLink = founded.filesLink
        }
      }
      setActiveUrl({
        name: value.label as MenuKeys,
        url: value.url
          ? value.url
          : encodeURIComponent(value.label.replace(/ /g, "-")),
        filesLink
      })
    },
    [activeUrl]
  )

  const handleMobileFilterOpen = useCallback((value: boolean) => {
    setMobileFilterOpen(value)
  }, [])

  return isMain ? (
    <div className="WikiContainer">
      <div className={isMobile ? "" : "MainPageContentRestrictions"}>
        <div className="HeadlineFirstText">{text.main}</div>
        <WikiSelect options={options} history={history} />
        <div className="HeadlineSecondText">{text.mainSubheader}</div>
        <div className="WikiDashboardCardContainer">
          {DashboardWikiMap.map(el => {
            const groupLinks = options.filter(option => {
              return el.elements.includes(option.value as string)
            })
            if (groupLinks.length) {
              return (
                <div key={el.name} className="WikiDashboardCard">
                  <div className="WikiDashboardIcon">{el.icon}</div>
                  <div className="WikiDashboardCardContainerText">
                    <div className="WikiDashboardCardName">{el.name}</div>
                    <div className="WikiDashboardCardLinksContainer">
                      {groupLinks.map(text => (
                        <Link key={text.filesLink} to={`${WIKI}${text.value}`}>
                          <div>{text.label}</div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  ) : (
    <div ref={containerRef}>
      <ReportWithFilter
        additionalClassName={"WikiReportContainer"}
        filterComponent={
          <WikiFilter
            history={history}
            options={options}
            anchors={anchors}
            mobileFilterOpen={mobileFilterOpen}
            onMobileFilterOpen={handleMobileFilterOpen}
          />
        }
        filterIcon={<SearchIcon />}
        pageContent={
          <div className="WikiContainer WikiContainerDetails">
            {breadcrumbs?.length && (
              <Breadcrumbs
                breadcrumbs={breadcrumbs}
                onBreadcrumbClick={handleBreadcrumbClicked}
              />
            )}
            {loading ? (
              <SpinComponent />
            ) : markdown ? (
              <ReactMarkdown
                className="WikiMarkdownContainer"
                rehypePlugins={[
                  [rehypeRaw],
                  [
                    rehypeSummaryPlugin,
                    {
                      onSummaryAnchorCreated: handleSummaryAnchorCreated
                    }
                  ]
                ]}
              >
                {markdown}
              </ReactMarkdown>
            ) : (
              <div>
                <div className="HeadlineFirstText WikiGroupHeader">
                  {activeUrl.name}
                </div>
                <div className="SubtitleSecondText">
                  {WikiSubheaderMap[activeUrl.name]}
                </div>
                <div className="WikiListSubheader SubtitleFirstText">
                  {text.groupSubHeader}
                </div>
                <ConfigItemCmp configItems={currentTree} />
              </div>
            )}
            <div key={renderKey} />
          </div>
        }
        isOpen={isOpen}
        toggleFilter={() => setIsOpen(!isOpen)}
        mobileFilter={mobileFilterOpen}
        toggleMobileFilter={() => {
          setMobileFilterOpen(!mobileFilterOpen)
        }}
      />
    </div>
  )
}
