import React from "react"

import { ModalFormComponent } from "Components/Modal"
import { ButtonFaded, ButtonNotifi } from "Components/Button"

import { useNotifyMethods, getConfirmTextByConfig } from "./constants"
import { ConfirmModalData } from "./types"

const ConfirmModal = ({
  baseUrl,
  hall,
  haveActive,
  confirmData,
  refreshTable,
  close
}: {
  baseUrl: string
  confirmData: ConfirmModalData
  hall: number
  haveActive: boolean
  refreshTable: () => void
  close: () => void
}) => {
  const approveRequest = useNotifyMethods({
    hall,
    haveActive,
    confirmData,
    baseUrl
  })
  const text = getConfirmTextByConfig(confirmData)

  return (
    <div>
      <ModalFormComponent width={480} withoutCross={true}>
        <div className="FlexColumn">
          <div className="HeadlineSecondText PaddingBottom16">{text.title}</div>
          <div className="TextDefault PaddingBottom24">{text.description}</div>
          <div className="FlexRow Gap4 MarginLeftAuto ">
            <ButtonFaded
              className="NotifyStatusTableConfirmModalButton"
              onClick={close}
            >
              <span className="ButtonTextFirst">{text.cancel}</span>
            </ButtonFaded>
            <ButtonNotifi
              className="NotifyStatusTableConfirmModalApprove"
              onClick={() =>
                approveRequest()
                  .then(() => {
                    refreshTable()
                    close()
                  })
                  .catch(err => {
                    // eslint-disable-next-line no-console
                    console.log(err)
                  })
              }
              color="red"
            >
              <span className="ButtonTextFirst">{text.approve}</span>
            </ButtonNotifi>
          </div>
        </div>
      </ModalFormComponent>
    </div>
  )
}

export default ConfirmModal
