import React, { useEffect, useState, useMemo } from "react"

import { useRequest } from "api/hooks"

import DetailComponent from "Components/Detail"
import ModalComponent from "Components/Modal"
import { ButtonPrimary } from "Components/Button"

import { SportBoomContentProps } from "../../types"
import { SportBoomTabs, parseDates } from "../../constants"

import {
  initialDetail,
  detailConfigByData,
  getDetailPopupText
} from "./constants"
import { DetailState } from "./types"

type SportboomDetailProps = {
  activeTab: SportBoomTabs
  filter: SportBoomContentProps
}

const SportboomDetail = ({ activeTab, filter }: SportboomDetailProps) => {
  const filterDeps = JSON.stringify({ ...filter, activeTab })

  const [detailState, setDetail] = useState<DetailState>(initialDetail)
  const [allBetsModal, toggleAllBetsModal] = useState(false)

  const { request: getDetail } = useRequest({
    url: `v1/sportboom/detail`,
    requestBody: {
      filters: {
        ...filter,
        dates: parseDates(filter)
      },
      type: activeTab
    },
    method: `POST`
  })
  useEffect(() => {
    if (!filter.wallet_number || activeTab === SportBoomTabs.sport_bets) {
      setDetail({ ...detailState, pending: true })
      getDetail()
        .then(({ data }) => setDetail({ pending: false, data }))
        .catch(() => setDetail({ ...detailState, pending: false }))
    }
  }, [filterDeps])

  const { data, pending } = detailState

  const detailConfig = useMemo(
    () => detailConfigByData(data, toggleAllBetsModal),
    [data]
  )

  const detailPopupText = getDetailPopupText(data)

  return (
    <div>
      <DetailComponent detailConfig={detailConfig} pending={pending} />
      {allBetsModal && (
        <ModalComponent width={500}>
          <div className="FlexColumn Gap16 SportboomDetailBetSumModal">
            <div className="HeadlineSecondText">{detailPopupText.title}</div>
            <div className="FlexRow SportboomDetailBetSumModalTable">
              <div className="FlexColumn SportboomDetailBetSumModalTableItem">
                <div className="Overline Gray600Color">
                  {detailPopupText.win_bets_sum}
                </div>
                <div className="SubtitleSecondText">
                  {detailPopupText.winBetsSumValue}
                </div>
              </div>
              <div className="FlexColumn Gap2 SportboomDetailBetSumModalTableItem">
                <div className="Overline Gray600Color">
                  {detailPopupText.bets_sum}
                </div>
                <div className="SubtitleSecondText">
                  {detailPopupText.betsSumValue}
                </div>
              </div>
            </div>
            <ButtonPrimary onClick={() => toggleAllBetsModal(false)}>
              {detailPopupText.close}
            </ButtonPrimary>
          </div>
        </ModalComponent>
      )}
    </div>
  )
}

export default SportboomDetail
