import React, { useEffect } from "react"
import {
  CreateStep3Props,
  ExtraParamsProps,
  macros,
  macrosRaw
} from "../../types"
import { smsConstants } from "Containers/Sms/constants"
import TooltipComponent from "Components/Tooltip"
import { canShowStep3 } from "../../helper"
import Step3Fields from "./step3Helper"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"
import moment from "moment"

const Step3 = ({
  smsState,
  setSmsState,
  macrosFindedArr
}: CreateStep3Props) => {
  const showStep3 = canShowStep3(smsState)

  const initExtraParams: ExtraParamsProps = {}

  macrosFindedArr.forEach(el => {
    switch (el) {
      case macros.bonus:
        initExtraParams[smsConstants.macrosAdditional.bonus.bonusFrom] = ""
        initExtraParams[smsConstants.macrosAdditional.bonus.bonusTo] = ""
        break
      case macros.money:
        initExtraParams[smsConstants.macrosAdditional.money.moneyFrom] = ""
        initExtraParams[smsConstants.macrosAdditional.money.moneyTo] = ""
        break
      case macros.date:
        initExtraParams[smsConstants.macrosAdditional.date] = moment()
        break
      case macros.address:
        initExtraParams[macrosRaw.address] = !!smsState.smsSettings.address
          ?.length
          ? smsState.smsSettings.address
          : ""
        break
      case macros.timeFrom:
        initExtraParams[smsConstants.macrosAdditional.time.timeFrom] = ""
        break
      case macros.timeTo:
        initExtraParams[smsConstants.macrosAdditional.time.timeTo] = ""
        break
      default:
        const replacedEl = el.replace("&", "")
        initExtraParams[replacedEl] = ""
    }
  })

  useEffect(() => {
    !!smsState.selectedTextId &&
      setSmsState({
        ...smsState,
        extraParams: { ...initExtraParams }
      })
  }, [smsState.selectedTextId])

  return (
    <div className="CreateSms_step">
      <div
        className={
          showStep3 ? "CreateSms_stepTitle" : "CreateSms_stepTitle_inactive"
        }
      >
        <span>{smsConstants.step3.orderNumber}</span>
        <span>{smsConstants.step3.title}</span>
        <TooltipComponent title={smsConstants.step3.tooltip} direction="bottom">
          <InfoIcon />
        </TooltipComponent>
      </div>

      {showStep3 ? (
        <Step3Fields
          smsState={smsState}
          setSmsState={setSmsState}
          macrosFindedArr={macrosFindedArr}
        />
      ) : (
        <hr className="CreateSms_divider" />
      )}
    </div>
  )
}

export default Step3
