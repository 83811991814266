import { IIypesState } from "../index"

export type TypesItem = {
  title: string
  name: string
  color: string
}

export const typesItems: TypesItem[] = [
  {
    title: `Выручка`,
    name: `income`,
    color: `rgba(52, 199, 89, 1)`
  },
  {
    title: `Выплаты`,
    name: `result`,
    color: `rgba(255, 149, 0, 1)`
  },
  {
    title: `Результат`,
    name: `outcome`,
    color: `rgba(255, 204, 0, 1)`
  }
]

type GetTypeItemsType = {
  typesState: IIypesState
  setTypesState: (data: IIypesState) => void
}

const getTypeItems = ({ typesState, setTypesState }: GetTypeItemsType) => {
  setTypesState({ ...typesState, pending: true })
  // some betRequest for getting types
  setTimeout(() => {
    setTypesState({
      ...typesState,
      active: 0,
      pending: false,
      data: typesItems
    })
  }, 1000)
}

export default getTypeItems
