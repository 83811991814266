import { Moment } from "moment"
import { UseRequestBodySort } from "../../api/hooks"

type InsurancesWinner = {
  walletId: number
  payoutSum: number
  phone: string
}

export type InsurancesWinners = InsurancesWinner[]

export type InsurancesReportItem = {
  id: number
  period: string[]
  status: string
  maxPayout: string
  games: number[]
  winners: InsurancesWinners
}

export type InsurancesReport = InsurancesReportItem[]

export type InsurancesState = {
  pending: boolean
  total: number
  insuranceId?: number
  report: InsurancesReport
}

export type InsurancesFilterData = {
  dates?: { from: Moment; to: Moment }
  halls: [number]
}

export type InsurancesFilter = {
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
  data: InsurancesFilterData
}

export enum InsurancesColumns {
  id = "id",
  period = "period",
  status = "status",
  maxPayout = "maxPayout",
  games = "games",
  winners = "winners"
}

export enum InsurancesWinnersColumns {
  walletId = "walletId",
  payoutSum = "payoutSum",
  phone = "phone"
}

export enum InsuranceStatus {
  CANCELED = "CANCELED",
  PENDING = "PENDING",
  FINISHED = "FINISHED"
}

export enum InsuranceTranslateStatus {
  CANCELED = "Отменена",
  PENDING = "Не завершена",
  FINISHED = "Завершена"
}

export type WinnersDetailProps = {
  data: InsurancesReport
  insuranceId: number
  close: () => void
}

export type GamesInsuranceData = {
  id: number | string
  name: string
  image?: string
}[]

export type GamesList = {
  games: number[]
  gamesDict: GamesInsuranceData
}

export type GetColumnsProps = {
  sort: UseRequestBodySort
  setShowModal: (value: boolean) => void
  insurancesState: InsurancesState
  setInsurancesState: (state: InsurancesState) => void
  games: GamesInsuranceData
}
