import moment from "moment"
import { mainUrl } from "../../constant"

export const getValueString = (value: number) => {
  if (value < 1000) {
    return `От ${value} ₽`
  } else if (value < 1000000) {
    const thousand = (value / 1000).toFixed(value % 1000 === 0 ? 0 : 1)
    return `От ${thousand} тыс. ₽`
  } else {
    const millions = (value / 10000000).toFixed(value % 1000 === 0 ? 0 : 1)
    return `От ${millions} млн. ₽`
  }
}

export const initialState = {
  total: 0,
  pending: false,
  data: []
}

export const emptyDate = `Ожидаем событие для рассылки`

export const mailingHallContentConstants = {
  url: mainUrl,
  title: (hall: number) => `Клуб № ${hall}`,
  userRowText: {
    additionalOne: `+1`,
    additionalMore: (val: number) => `+${val}`
  },
  dateDescription: (date: string) =>
    !!date
      ? `Последняя рассылка ${moment(date).format(`DD.MM.YYYY в HH:mm`)}`
      : emptyDate,
  empty: `Не задан`,
  replenishment: `Пополнение`,
  withdrawal: `Вывод`,
  win: `Выигрыш`
}
