import React, { useCallback, useEffect, useMemo, useState } from "react"
import TabsComponent from "Components/Tabs"
import {
  initialRaceData,
  initialRaceHistoryData,
  RACE_FILTER,
  RACE_TABLE_SCROLL,
  RaceListTableColNames,
  raceStatusesDict,
  raceText,
  tabsConfig,
  tabsIds,
  tabsIdsModal
} from "./constants"
import { useRequestBody, useRequestParams } from "api/hooks"
import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import {
  disableDates,
  makeInitialFilter,
  makeRacesHistoryTableColumns,
  makeRacesListTableColumns,
  RaceMoneyCell,
  RaceWinnersTableCell,
  useRaceRequests
} from "./helpers"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import TableNew, { expandIcon } from "Components/TableNew"
import { useSelector } from "react-redux"
import { getGamesList } from "store/dictionary/selector"
import {
  RaceHistoryState,
  RaceListItem,
  RaceListState,
  RaceModalState,
  RacesHistoryResponse,
  RacesListResponse,
  RaceTableExpandCellProps
} from "./types"
import { AxiosResponse } from "axios"
import { Row, Col } from "antd"
import { RaceModal } from "./RaceModal"
import { Sorter } from "Components/Table/types"
import { sortForTable } from "Components/Table"
import { ErrorsContainer } from "./ErrorsContainer"

const Race = () => {
  const { currentHallAsNumber } = useRequestParams()
  const games = useSelector(getGamesList)

  const initialFilter = makeInitialFilter(currentHallAsNumber, RACE_FILTER)
  const [filterState, setFilterState] = useState(initialFilter)
  const [activeTab, setActiveTab] = useState(tabsIds.infoTab)
  const [racesList, setRaceList] = useState(initialRaceData)
  const [racesHistory, setRacesHistory] = useState(initialRaceHistoryData)
  const [isInfoTab, setIsInfoTab] = useState(activeTab === tabsIds.infoTab)
  const [activeTabModal, setActiveTabModal] = useState(tabsIdsModal.raceTab)
  const [currentTableData, setCurrentTableData] = useState<
    RaceListState | RaceHistoryState
  >(isInfoTab ? initialRaceData : initialRaceHistoryData)
  const [raceModalState, setRaceModalState] = useState<RaceModalState>({
    data: null,
    isOpen: false
  })
  const [expandedRowMoreIds, setExpandedRowMoreIds] = useState<number[]>([])

  useEffect(() => {
    setIsInfoTab(activeTab === tabsIds.infoTab)
  }, [activeTab])

  const handleTabChangesModal = useCallback(tab => {
    setActiveTabModal(tab)
  }, [])

  const {
    pagination,
    setPagination,
    setSort,
    sort,
    resetPagination
  } = useRequestBody()

  const { getRacesGuestsList, getRacesList } = useRaceRequests({
    hall: filterState.data.halls?.[0]
      ? filterState.data.halls[0]
      : currentHallAsNumber,
    sort,
    pagination,
    phone: filterState.data.phone,
    dates: filterState.data.dates
  })

  useEffect(() => {
    setFilterState(prev => ({ ...prev, isActive: true }))
  }, [pagination, sort])

  const toggleActiveTab = useCallback(tab => {
    setActiveTab(tab)
  }, [])

  const setFilterStateCb = useCallback(value => setFilterState(value), [])

  const toggleFilter = useCallback(() => {
    setFilterState(prevState => ({
      ...prevState,
      isOpen: !prevState.isOpen
    }))
  }, [filterState.isOpen])

  const toggleMobileFilter = useCallback(() => {
    setFilterState(prevState => ({
      ...prevState,
      mobileFilter: !prevState.mobileFilter
    }))
  }, [filterState.mobileFilter])

  const handleRaceModalState = useCallback(
    (data: RaceModalState, modalTab: number) => {
      setRaceModalState(data)
      setActiveTabModal(modalTab)
    },
    []
  )

  const columns = useMemo(() => {
    if (isInfoTab && games) {
      return makeRacesListTableColumns(
        games,
        handleRaceModalState,
        expandedRowMoreIds
      )
    } else {
      return makeRacesHistoryTableColumns()
    }
  }, [isInfoTab, games, expandedRowMoreIds])

  useEffect(() => {
    if (filterState.isActive) {
      setCurrentTableData(prev => ({ ...prev, loading: true }))
      if (isInfoTab) {
        getRacesList()
          .then((resp: AxiosResponse<RacesListResponse>) => {
            const { items, total_count } = resp.data
            setRaceList({ total: total_count, data: items, loading: false })
          })
          .catch(() => {
            setRaceList(prev => ({ ...prev, loading: false }))
          })
      } else if (filterState.data.phone?.length > 1) {
        getRacesGuestsList()
          .then((resp: AxiosResponse<RacesHistoryResponse>) => {
            const { items, total_count } = resp.data
            setRacesHistory(prev => ({
              ...prev,
              total: total_count,
              data: items,
              loading: false,
              isGuestInfoEmpty: items.length === 0,
              isGuestNotFound: false,
              isPhoneEmpty: false
            }))
          })
          .catch(err => {
            if (err.status === 404) {
              setRacesHistory(prev => ({
                ...prev,
                loading: false,
                isGuestNotFound: true,
                isPhoneEmpty: false,
                isGuestInfoEmpty: false
              }))
            }
          })
      } else {
        setRacesHistory(prev => ({
          ...prev,
          loading: false,
          isPhoneEmpty: true,
          isGuestNotFound: false,
          isGuestInfoEmpty: false
        }))
      }
      setFilterState(prev => ({ ...prev, isActive: false }))
    }
  }, [filterState])

  useEffect(() => {
    setFilterState(prev => ({ ...prev, isActive: true }))
  }, [isInfoTab])

  useEffect(() => {
    const nextCurrentTableData = isInfoTab ? racesList : racesHistory
    setCurrentTableData({ ...nextCurrentTableData, loading: false })
  }, [racesList, racesHistory])

  const expandedRowRender = useCallback(
    (record: RaceListItem) => {
      if (record.federal) {
        return (
          <Row className="RaceExpandedTableRow">
            <Col span={2} className="RaceExpandedTableCell_LocalContainer">
              <div className="RaceExpandedTableCell RaceExpandedTableCell__Text">
                {raceText.localTableCellContent}
              </div>
            </Col>
            <Col
              span={6}
              className="RaceExpandedTableCell RaceExpandedTableCell_StatusContainer"
            >
              <div
                className={`RaceStatus ${
                  raceStatusesDict[record.local.status].className
                }`}
              >
                {raceStatusesDict[record.local.status].text}
              </div>
            </Col>
            <Col span={2} className="RaceExpandedTableCell">
              <RaceMoneyCell
                money={record.local.prize_pool_sum}
                type={record.local.prize_pool_type}
              />
            </Col>
            <Col span={11} className="RaceExpandedTableCell_Empty" />
            <Col span={3}>
              <div className="RaceExpandedTableCell RaceExpanded_WinnerCell">
                <RaceWinnersTableCell
                  record={record}
                  onWinnerClick={handleRaceModalState}
                  value={record.local}
                  className="RaceExpandedTableCell__Winners"
                  isFederal={false}
                />
              </div>
            </Col>
          </Row>
        )
      }
      return null
    },
    [columns]
  )

  const rowExpandable = useCallback((record: RaceListItem) => {
    return !!record.federal
  }, [])

  const expandable = useMemo(() => {
    if (isInfoTab) {
      return {
        expandedRowRender,
        rowExpandable,
        expandIcon: (props: RaceTableExpandCellProps) => {
          if (props.expandable) {
            return expandIcon({
              record: props.record,
              expanded: props.expanded,
              onExpand: (data, e) => {
                if (!props.expanded) {
                  setExpandedRowMoreIds(prev => [...prev, data.id])
                } else {
                  setExpandedRowMoreIds(prev =>
                    prev.filter(id => id !== data.id)
                  )
                }
                props.onExpand(data, e)
              },
              show: true
            })
          }
          return <></>
        }
      }
    }
    return {}
  }, [isInfoTab])

  const handleFilterChange = useCallback(
    (pagination: any, filters: any, sorter: Sorter) => {
      sortForTable({ sorter, setSort })
    },
    [setSort, sortForTable]
  )

  const handleFilterDisabled = useCallback(
    filter =>
      isInfoTab ? false : filter.phone ? filter.phone?.length < 11 : true,
    [isInfoTab]
  )

  const handleCloseModal = useCallback(() => {
    setRaceModalState(prev => ({ ...prev, isOpen: false }))
  }, [raceModalState])

  return (
    <div>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={RACE_FILTER}
            filterState={filterState}
            setFilterState={setFilterStateCb}
            resetPagination={resetPagination}
            filters={[
              Filters.hallPickerRadio,
              isInfoTab ? Filters.rangePicker : Filters.phoneInput
            ]}
            filterLabels={
              isInfoTab
                ? { [Filters.rangePicker]: raceText.filterDates }
                : { [Filters.phoneInput]: raceText.guestInfoFilter }
            }
            getDisabled={handleFilterDisabled}
            disabledRangeDates={disableDates}
          />
        }
        pageContent={
          <>
            <Title
              titleText={raceText.title}
              tooltipText={raceText.titleTooltip}
            />
            <TabsComponent
              tabs={tabsConfig}
              activeTab={activeTab}
              toggleActiveTab={toggleActiveTab}
            />
            <ErrorsContainer tableData={currentTableData as RaceHistoryState} />
            {!(currentTableData as RaceHistoryState).isGuestInfoEmpty &&
              !(currentTableData as RaceHistoryState).isGuestNotFound &&
              !(currentTableData as RaceHistoryState).isPhoneEmpty && (
                <TableNew
                  rowKey={RaceListTableColNames.id}
                  tableClassName={`RaceGamesTable ${
                    isInfoTab
                      ? "RaceGamesTable__InfoTab"
                      : "RaceGamesTable__HistoryTab"
                  }`}
                  onChange={handleFilterChange}
                  columns={columns}
                  data={currentTableData.data}
                  loading={currentTableData.loading}
                  scroll={RACE_TABLE_SCROLL}
                  pagination={{
                    value: pagination,
                    total: currentTableData.total,
                    onChange: setPagination
                  }}
                  width={1396}
                  expandable={expandable}
                />
              )}
          </>
        }
        isOpen={filterState.isOpen}
        toggleFilter={toggleFilter}
        mobileFilter={filterState.mobileFilter}
        toggleMobileFilter={toggleMobileFilter}
      />
      {raceModalState.isOpen && raceModalState.data && (
        <RaceModal
          {...raceModalState.data}
          onCloseModal={handleCloseModal}
          onTabChange={handleTabChangesModal}
          activeTab={activeTabModal}
        />
      )}
    </div>
  )
}

export default Race
