import React from "react"
import { EmptyTableProps } from "./types"
import { ReactComponent as EmptyIcon } from "./icons/empty_filter.svg"
import { ReactComponent as EmptyMobileIcon } from "./icons/empty_filter_mobile.svg"

export const initialData: EmptyTableProps = {
  desktop: {
    title: `Ничего не найдено`,
    subtitle: `По заданным фильтрам нет совпадений.\nПопробуйте их изменить и применить ещё раз`,
    icon: <EmptyIcon />
  },
  mobile: {
    title: `По заданным фильтрам нет совпадений.\nПопробуйте их изменить и применить ещё раз`,
    subtitle: `По заданным фильтрам\nнет совпадений. Попробуйте их изменить\nи применить ещё раз`,
    icon: <EmptyMobileIcon />
  }
}

export const defaultEmptyData = {
  title: `Ничего не найдено`,
  subtitle: `По заданным фильтрам ничего не найдено`
}
