import React, { useCallback, useEffect, useState } from "react"
import {
  NominalSectionKeys,
  TemplateCardProps,
  TemplateDataState
} from "../types"
import { nominalsText, sectionsDict } from "../constants"
import Switch from "Components/Switch"
import { CheckBoxComponent } from "Components/CheckBox"
import plural from "plural-ru"
import { TemplateCardTableRow } from "./TemplateCardTableRow"
import { isMobile, moneyRender } from "constants/index"

export const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  idx,
  isMinMax,
  onTemplateActivated,
  isActive,
  isModalTemplate,
  sectionName,
  isVipCheckBoxDisabled
}) => {
  const [numbersRow, setNumbersRow] = useState<number[]>([])
  const [selectedSectionKeys, setSelectedSectionKeys] = useState<
    NominalSectionKeys[]
  >([])

  useEffect(() => {
    if (template.limits?.max && template.limits?.min) {
      let longestRow = template.limits?.max
      if (longestRow?.length < template.limits?.min?.length) {
        longestRow = template.limits?.min
      }
      setNumbersRow(longestRow?.map(el => el.number_count))
    }
  }, [template])

  useEffect(() => {
    if (selectedSectionKeys.length > 0) {
      const dataForTemplate = selectedSectionKeys.reduce(
        (acc, key) => {
          acc[key] = template
          return acc
        },
        {
          id: idx,
          isActive: true
        } as TemplateDataState
      )

      onTemplateActivated && onTemplateActivated(dataForTemplate)
    } else if (isActive) {
      onTemplateActivated &&
        onTemplateActivated({
          id: null,
          isActive: false
        })
    }
  }, [selectedSectionKeys])

  const handleCheckboxChange = useCallback(
    (newSectionKey, event) => {
      if (event.target.checked) {
        setSelectedSectionKeys([...selectedSectionKeys, newSectionKey])
      } else {
        setSelectedSectionKeys(
          selectedSectionKeys.filter(key => key !== newSectionKey)
        )
      }
    },
    [selectedSectionKeys]
  )

  useEffect(() => {
    if (isActive && setSelectedSectionKeys.length !== 1) {
      setSelectedSectionKeys([NominalSectionKeys.main])
    } else if (!isActive && selectedSectionKeys.length !== 0) {
      setSelectedSectionKeys([])
    }
  }, [isActive])

  const handleTemplateActivation = useCallback((isActivated: boolean) => {
    if (isActivated) {
      setSelectedSectionKeys([NominalSectionKeys.main])
    } else {
      setSelectedSectionKeys([])
    }
  }, [])

  return (
    <div
      className={`NominalsTemplateCardWrapper ${
        isMinMax ? "NominalsTemplateCardWrapper_MinMax" : ""
      } ${
        selectedSectionKeys.length > 0
          ? "NominalsTemplateCardWrapper_Active"
          : ""
      } ${isModalTemplate ? "NominalsTemplateCardWrapper_Modal" : ""} ${
        isModalTemplate && isMinMax
          ? "NominalsTemplateCardWrapper_Modal_MinMax"
          : ""
      }`}
    >
      {isModalTemplate ? (
        <div className="ChangeTabColWrapper_TitleImageContainer">
          {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
          {sectionsDict[sectionName!].icon}
          <div className="ChangeTabColWrapper_TitleImageContainer_Text">
            {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
            {sectionsDict[sectionName!].text}
          </div>
        </div>
      ) : (
        <div className="NominalsTemplateCard_TitleContainer">
          <div className="NominalsTemplateCard_LeftTitle">
            <div className="NominalsTemplateCard_Title">
              {`${nominalsText.templateTitle} ${idx}`}
            </div>
            <Switch
              className="NominalsTemplateCard_Title"
              checked={selectedSectionKeys.length > 0}
              onChange={handleTemplateActivation}
            />
          </div>
          <div
            className="NominalsTemplateCard_RightTitle"
            key={selectedSectionKeys.length}
          >
            <CheckBoxComponent
              disabled={selectedSectionKeys.length <= 0}
              checked={selectedSectionKeys.includes(NominalSectionKeys.main)}
              label={nominalsText.main}
              onChange={handleCheckboxChange.bind(
                null,
                NominalSectionKeys.main
              )}
            />
            <CheckBoxComponent
              disabled={
                selectedSectionKeys.length <= 0 || isVipCheckBoxDisabled
              }
              checked={selectedSectionKeys.includes(NominalSectionKeys.vip)}
              label={nominalsText.vip}
              onChange={handleCheckboxChange.bind(null, NominalSectionKeys.vip)}
            />
          </div>
        </div>
      )}

      {(isMobile ? !isModalTemplate : true) && (
        <div className="NominalsTemplateCard_NominalsRow">
          {template.nominals?.map((el, idx) => (
            <div key={el.id} className="NominalsTemplateCard_Cell">
              <div className="NominalsTemplateCard_Cell_Title">{`${
                nominalsText.nominalColName
              } ${idx + 1}`}</div>
              <div className="NominalsTemplateCard_Cell_SubTitle_Container">
                <div className="NominalsTemplateCard_Cell_SubTitle_Value">
                  {moneyRender(el.value)}
                </div>
                <div className="NominalsTemplateCard_Cell_SubTitle_Currency">
                  {nominalsText.rubles}
                </div>
              </div>
            </div>
          ))}
          {typeof template.max_limit === "number" && (
            <div className="NominalsTemplateCard_Cell">
              <div className="NominalsTemplateCard_Cell_Title">{`${nominalsText.maxLimitShort}`}</div>
              <div className="NominalsTemplateCard_Cell_SubTitle_Container">
                <div className="NominalsTemplateCard_Cell_SubTitle_Value_Max">
                  {moneyRender(template.max_limit)}
                </div>
                <div className="NominalsTemplateCard_Cell_SubTitle_Currency_Max">
                  {nominalsText.rubles}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isModalTemplate && isMobile && !isMinMax && (
        <div className="MobileTableCardRow">
          {template.nominals?.map((el, idx) => (
            <div
              key={el.id}
              className="MobileTableCardRowItem MobileTableCardRowItem__WithDots"
            >
              <div className="MobileTableCardRowItemTitle">{`${
                nominalsText.nominalColName
              } ${idx + 1}`}</div>
              <div className="NominalsTemplateCard_Cell_SubTitle_Container">
                <div>{moneyRender(el.value)}</div>
                <div>{nominalsText.rubles}</div>
              </div>
            </div>
          ))}
          {typeof template.max_limit === "number" && (
            <div className="MobileTableCardRowItem MobileTableCardRowItem__WithDots">
              <div className="MobileTableCardRowItemTitle">{`${nominalsText.maxLimitShort}`}</div>
              <div className="NominalsTemplateCard_Cell_SubTitle_Container">
                <div>{moneyRender(template.max_limit)}</div>
                <div>{nominalsText.rubles}</div>
              </div>
            </div>
          )}
        </div>
      )}

      {isMinMax && isMobile && (
        <>
          <div className="NominalsTemplateCard_NominalsRow_MinMaxLimitsTitle_Mobile">
            {nominalsText.minLimit}
          </div>
          <div className="NominalsTemplateCard_NominalsRow">
            {template?.limits?.min.map((el, idx) => (
              <div key={el.number_count} className="NominalsTemplateCard_Cell">
                <div className="NominalsTemplateCard_Cell_Title">{`${
                  nominalsText.nominalColName
                } ${idx + 1}`}</div>
                <div className="NominalsTemplateCard_Cell_SubTitle_Container">
                  <div className="NominalsTemplateCard_Cell_SubTitle_Value">
                    {moneyRender(el.value)}
                  </div>
                  <div className="NominalsTemplateCard_Cell_SubTitle_Currency">
                    {nominalsText.rubles}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {isMinMax && isMobile && (
        <>
          <div className="NominalsTemplateCard_NominalsRow_MinMaxLimitsTitle_Mobile">
            {nominalsText.maxLimit}
          </div>
          <div className="NominalsTemplateCard_NominalsRow">
            {template?.limits?.max.map((el, idx) => (
              <div key={el.number_count} className="NominalsTemplateCard_Cell">
                <div className="NominalsTemplateCard_Cell_Title">{`${
                  nominalsText.nominalColName
                } ${idx + 1}`}</div>
                <div className="NominalsTemplateCard_Cell_SubTitle_Container">
                  <div className="NominalsTemplateCard_Cell_SubTitle_Value">
                    {moneyRender(el.value)}
                  </div>
                  <div className="NominalsTemplateCard_Cell_SubTitle_Currency">
                    {nominalsText.rubles}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {numbersRow.length > 0 && isMinMax && !isMobile && (
        <table className="NominalsTemplateCard_NumbersTable">
          <thead>{""}</thead>
          <tbody className="NominalsTemplateCard_NumbersTable_Numbers">
            <tr>
              {numbersRow.map((el, idx) => (
                <td
                  key={idx}
                  className="NominalsTemplateCard_NumbersTable_Cell"
                >{`${el} ${plural(
                  el,
                  ...(nominalsText.plural as [string, string, string])
                )}`}</td>
              ))}
            </tr>
          </tbody>
          <TemplateCardTableRow
            limits={template.limits?.min ?? []}
            title={nominalsText.minLimit}
          />
          <TemplateCardTableRow
            limits={template.limits?.max ?? []}
            title={nominalsText.maxLimit}
          />
        </table>
      )}
    </div>
  )
}
