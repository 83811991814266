import React, { useState } from "react"

import {
  ConsolidatedFilter,
  ConsolidatedFilterData
} from "Containers/Consolidated/types"

import InfoPanelComponent from "Components/FilterInfoPanel"
import { ButtonPrimary } from "Components/Button"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"

import { filterConstants } from "./constants"

import {
  getFilterStateFromLocalStorage,
  saveFilterInLocalStorage
} from "api/helpers"

export const CONSOLIDATED_FILTER_NAME = "consolidatedFilter"

const Filter = ({
  filterState,
  setFilterState,
  current_hall,
  mobileFilterOpen,
  setMobileFilterOpen
}: {
  filterState: ConsolidatedFilter
  setFilterState: (data: ConsolidatedFilter) => void
  current_hall: (string | CheckboxValueType)[]
  mobileFilterOpen?: boolean
  setMobileFilterOpen?: (data: boolean) => void
}) => {
  const [filter, setFilter] = useState<ConsolidatedFilterData>(
    getFilterStateFromLocalStorage(
      { ...filterState.data },
      CONSOLIDATED_FILTER_NAME
    )
  )
  return (
    <div>
      <div
        className={`
        ${mobileFilterOpen ? `MainFilterMobile` : `MainFilterWrapper`}
        ${
          filterState.isOpen
            ? `MainFilterWrapperActive`
            : `MainFilterWrapperUnActive`
        }`}
      >
        <div
          className="MainFilterMobileClose"
          onClick={() => {
            if (mobileFilterOpen && setMobileFilterOpen) {
              setMobileFilterOpen(false)
            }
          }}
        >
          <CloseIcon />
        </div>
        <div className="MainFilterHeader FlexRow">
          <span>{filterConstants.title}</span>
        </div>
        <div className="MainFilterContent">
          <InfoPanelComponent<ConsolidatedFilterData>
            filter={filter}
            filterHalls={filter.halls}
            setFilter={setFilter}
            current_hall={current_hall}
            disabledShift={true}
            alwaysMoscow={true}
            periodOptions={filterConstants.periodOptions}
          />
        </div>
        <div className="MainFilterApplyWrapper GridCenter">
          <ButtonPrimary
            disabled={!filter.dates || !filter.halls.length}
            onClick={() => {
              setFilterState({
                ...filterState,
                data: {
                  ...filterState.data,
                  ...filter,
                  gmt: filterConstants.mskGmt
                },
                isActive: true
              })
              saveFilterInLocalStorage(filter, CONSOLIDATED_FILTER_NAME)
              if (mobileFilterOpen && setMobileFilterOpen) {
                setMobileFilterOpen(false)
              }
            }}
          >
            {filterConstants.apply}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

export default Filter
