import React, { useEffect, useRef, useState } from "react"
import { RadioGroupComponent } from "Components/Radio"
import { VisitCalendarProps } from "../types"
import { ReactComponent as CalendarIcon } from "Components/icons/calendar.svg"
import { textConstants, visitCalendarOptions } from "../constants"

const VisitCalendar: React.FC<VisitCalendarProps> = ({
  visitHoursState,
  setVisitHoursState
}: VisitCalendarProps) => {
  const [showCalendar, setShowCalendar] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  /* Для клика вне элемента, чтобы закрыть меню с выбором действий */
  useEffect(() => {
    const checkIfClickedOutside = ({ target }: MouseEvent) => {
      if (
        showCalendar &&
        ref &&
        ref.current &&
        !ref.current.contains(target as Node)
      ) {
        setShowCalendar(false)
      }
    }
    document.addEventListener("click", checkIfClickedOutside)
    return () => {
      document.removeEventListener("click", checkIfClickedOutside)
    }
  }, [showCalendar])
  return (
    <div
      className="VisitsCalendarTrigger"
      onClick={() => setShowCalendar(!showCalendar)}
    >
      <CalendarIcon />
      <div
        ref={ref}
        className={showCalendar ? `VisitCalendarShow` : `VisitCalendarHidden`}
      >
        <div className="VisitsCalendarTitle">
          {textConstants.daysAnaliticsTitle}
        </div>
        <RadioGroupComponent
          value={visitHoursState.chart_days}
          onChange={({ target: { value } }) => {
            setVisitHoursState({
              ...visitHoursState,
              chart_days: value
            })
          }}
          options={visitCalendarOptions}
        />
      </div>
    </div>
  )
}

export default VisitCalendar
