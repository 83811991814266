import React from "react"
import { isString } from "lodash"
import { Step3FieldProps } from "Containers/Sms/types"
import InputComponent from "Components/Input"
import { smsConstants } from "Containers/Sms/constants"

const Money = ({ smsState, setSmsState }: Step3FieldProps) => {
  return (
    <div className="CreateSms_input CreateSms_inputShort">
      <label className="CreateSms_inputLabel">
        {smsConstants.step3.moneyTitle}
      </label>
      <div className="CreateSms_inputInner">
        <InputComponent
          error={
            isString(smsState.errors?.macros?.money)
              ? smsState.errors?.macros?.money
              : smsState.errors?.macros?.money?.from
          }
          type="number"
          min={0}
          hideControls
          greyBackground
          value={smsState.extraParams.moneyFrom ?? ""}
          onChange={({ target: { value } }) => {
            setSmsState({
              ...smsState,
              extraParams: {
                ...smsState.extraParams,
                moneyFrom: value
              }
            })
          }}
          placeholder={smsConstants.fromTextCapitalized}
        />
        <InputComponent
          error={
            isString(smsState.errors?.macros?.money)
              ? smsState.errors?.macros?.money
              : smsState.errors?.macros?.money?.to
          }
          greyBackground
          type="number"
          min={0}
          hideControls
          value={smsState.extraParams.moneyTo ?? ""}
          onChange={({ target: { value } }) => {
            setSmsState({
              ...smsState,
              extraParams: {
                ...smsState.extraParams,
                moneyTo: value
              }
            })
          }}
          placeholder={smsConstants.toTextCapitalized}
        />
      </div>
    </div>
  )
}
export default Money
