import React, { useCallback, useMemo, useState } from "react"

import { noop } from "lodash"

import ReportWithFilter from "Components/ReportWithFilter"
import { SideFilter } from "Components/SideFilter/SideFilter"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import NotActiveFilter from "Components/NotActiveFilter"

import { RegistrationCheckFilter } from "./types"
import {
  getInitialFilter,
  filterStorageName,
  initialFilterValues,
  textConstants
} from "./constants"

import RegistrationCheckTable from "./Table"

const RegistrationCheck = () => {
  const initialFilter: RegistrationCheckFilter = useMemo(
    () => getInitialFilter(),
    []
  )
  const [filterState, setFilterState] = useState<RegistrationCheckFilter>(
    initialFilter
  )
  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={filterStorageName}
            filterState={filterState}
            initialForClear={initialFilterValues}
            setFilterState={setFilterStateCb}
            resetPagination={noop}
            filters={[Filters.phoneInput]}
            getDisabled={filter => !filter?.phone || filter?.phone.length < 11}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <>
            {filterState.isActive && (
              <div>
                <Title titleText={textConstants.title} />
                <RegistrationCheckTable phone={filterState.data.phone} />
              </div>
            )}
            {!filterState.isActive && (
              <NotActiveFilter
                title={textConstants.notActiveFilter.title}
                description={textConstants.notActiveFilter.description}
                icon={textConstants.notActiveFilter.icon}
              />
            )}
          </>
        }
        modalCmp={<div />}
      />
    </>
  )
}

export default RegistrationCheck
