import { WikiState } from "./types"

import { STORE_MENU_WIKI } from "./constants"

const init: WikiState = {
  options: []
}

export function menuWikiReducer(
  state: WikiState = init,
  action: { type: string; payload: WikiState }
): WikiState {
  switch (action.type) {
    case STORE_MENU_WIKI: {
      return { ...state, ...action.payload }
    }

    default:
      return { ...state }
  }
}
