import { ReactComponent as BalancePayoutIcon } from "./balance_payout.svg"
import { ReactComponent as BonusBalanceIcon } from "./bonus_balance.svg"
import { ReactComponent as CashboxPercentIcon } from "./cashback_percent.svg"
import { ReactComponent as CashboxResultIcon } from "./cashbox_result.svg"
import { ReactComponent as FreebetSumIcon } from "./freebet_sum.svg"
import { ReactComponent as FreebetAmountIcon } from "./freebets_amount.svg"
import { ReactComponent as GuestStatusIcon } from "./guest_status.svg"
import { ReactComponent as LastSuperwinIcon } from "./last_superwin.svg"
import { ReactComponent as LevelupStatusIcon } from "./levelup_status.svg"
import { ReactComponent as MoneyBalanceIcon } from "./money_balance.svg"
import { ReactComponent as NewRegistrationsIcon } from "./new_registrations.svg"
import { ReactComponent as NewRegistrationsOrange } from "./new_registrations_orange.svg"
import { ReactComponent as SaveBonusesIcon } from "./save_bonuses.svg"
import { ReactComponent as UniqRegistrationsIcon } from "./uniq_registrations.svg"
import { ReactComponent as UpcomingBonusesIcon } from "./upcoming_bonuses.svg"
import { ReactComponent as VisitCountIcon } from "./visit_count.svg"
import { ReactComponent as WalletsAmountIcon } from "./wallets_amount.svg"
import { ReactComponent as GuestsWithBonusesIcon } from "./guests_with_bonuses.svg"
import { ReactComponent as BonusesSumIcon } from "./bonuses_sum.svg"
import { ReactComponent as CardsAmountIcon } from "./cards_amount.svg"
import { ReactComponent as CardsActiveIcon } from "./cards_active.svg"
import { ReactComponent as TennisFightOneIcon } from "./tennis_fight_one.svg"

export default {
  BalancePayoutIcon,
  BonusBalanceIcon,
  CashboxPercentIcon,
  CashboxResultIcon,
  FreebetSumIcon,
  FreebetAmountIcon,
  GuestStatusIcon,
  LastSuperwinIcon,
  LevelupStatusIcon,
  MoneyBalanceIcon,
  NewRegistrationsIcon,
  NewRegistrationsOrange,
  SaveBonusesIcon,
  UniqRegistrationsIcon,
  UpcomingBonusesIcon,
  VisitCountIcon,
  WalletsAmountIcon,
  GuestsWithBonusesIcon,
  BonusesSumIcon,
  CardsAmountIcon,
  CardsActiveIcon,
  TennisFightOneIcon
}
