import React, { useCallback, useMemo, useState } from "react"

import { useRequestParams } from "api/hooks"

import { getDateDescriptionLayout } from "constants/index"

import ReportWithFilter from "Components/ReportWithFilter"
import { SideFilter } from "Components/SideFilter/SideFilter"
import Title from "Components/Title"
import NotActiveFilter from "Components/NotActiveFilter"
import { Filters } from "Components/SideFilter/types"

import {
  CUPIS_REPORT_FILTER,
  getInitialFilter,
  initialFilterValues,
  cupisReportText
} from "./constants"
import { CupisReportFilter } from "./types"

import CupisContent from "./Content"

import { noop } from "lodash"

const CupisReport = () => {
  const { current_hall } = useRequestParams()
  const initialFilter: CupisReportFilter = useMemo(
    () => getInitialFilter(`${current_hall[0]}`),
    []
  )
  const [filterState, setFilterState] = useState<CupisReportFilter>(
    initialFilter
  )

  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  const titleDescription = useMemo(() => {
    if (!!filterState.data.dates) {
      return getDateDescriptionLayout({
        dates: filterState.data.dates,
        shift: [8, 20],
        isShift: true,
        show: filterState.isActive
      })
    }
    return ``
  }, [filterState.data.dates])

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={CUPIS_REPORT_FILTER}
            filterState={filterState}
            initialForClear={{
              ...initialFilterValues,
              halls: [`${current_hall}`]
            }}
            setFilterState={setFilterStateCb}
            resetPagination={noop}
            filters={[Filters.InfoPanel]}
            getDisabled={filter => !filter?.dates}
            infoPanelConfig={{
              alwaysMoscow: true,
              disabledShift: true,
              withoutPeriod: false,
              withoutGmt: true
            }}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <>
            {filterState.isActive && (
              <div>
                <Title
                  titleText={cupisReportText.title}
                  tooltipText={cupisReportText.tooltip}
                  bottomAdditionalCmp={
                    <div className="CupisTitleTextDescription">
                      {titleDescription}
                    </div>
                  }
                />
                <CupisContent filter={filterState.data} />
              </div>
            )}
            {!filterState.isActive && (
              <NotActiveFilter
                title={cupisReportText.notActiveFilter.title}
                description={cupisReportText.notActiveFilter.description}
                icon={cupisReportText.notActiveFilter.icon}
              />
            )}
          </>
        }
        modalCmp={<div />}
      />
    </>
  )
}

export default CupisReport
