export const appealModalText = {
  title: "Обжаловать штраф",
  penaltyFrom: "Штраф от",
  inHall: "в клубе №",
  onSum: "на сумму",
  success:
    "Обращение \n успешно отправлено.\n Ответ поступит в течение \n 5-ти рабочих дней",
  responsible: "Ответственный от клуба",
  responsiblePlaceholder: "Его увидят только администраторы",
  comment: "Комментарий к устранению",
  commentPlaceholder: "Введите комментарий для отдела контроля",
  attachments: "Документы об устранении",
  checkRulesTitle: "Подтверждение об ознакомлении с правилами",
  checkRulesText: "Отправляя указанный запрос, я подтверждаю ознакомление с",
  checkRulesLink: "правилами обжалования штрафов"
}

export enum FormItemNames {
  name = "name",
  comment = "comment",
  attachments = "attachments",
  rulesCheck = "rulesCheck"
}
