import React from "react"

import moment from "moment"

import { valueToCurrency } from "constants/index"

import { PromotionBonusesLegoConfig, PromotionBonusesTableItem } from "../types"

export const getColumnsByConfig = (config: PromotionBonusesLegoConfig) =>
  config.table.map((item, key) => {
    const firstClassItem = key === 0 ? `TextOne PaddingLeft24` : `TextOne`
    const firstClassHeader =
      key === 0 ? `TableHeader PaddingLeft24` : `TableHeader`
    return {
      title: () => {
        return <div className={firstClassHeader}>{item.title}</div>
      },
      dataIndex: item.field,
      key: item.field,
      render: (item_value: string | number) => {
        let value = item_value
        if (item.type === PromotionBonusesTableItem.sum) {
          value = valueToCurrency({
            value: Number(item_value),
            withoutZero: true,
            withoutFixed: true
          })
        } else if (item.type === PromotionBonusesTableItem.date) {
          value = moment(item_value).format(`DD.MM.YYYY`)
        } else if (item.type === PromotionBonusesTableItem.time) {
          value = moment(item_value).format(`HH:mm`)
        }
        value = item.suffix ? `${value} ${item.suffix}` : value
        return <div className={firstClassItem}>{value}</div>
      }
    }
  })
