import { useEffect, useState } from "react"
import { InputType, UseGreyInputBackground, ValidationResult } from "./types"

export const useGreyInputBackground = ({
  greyBackground,
  value,
  allowNullValue
}: UseGreyInputBackground) => {
  const [greyBg, setGreyBg] = useState(false)

  useEffect(() => {
    if (greyBackground && value !== 0 && !Boolean(value)) {
      setGreyBg(true)
      if (value === null && allowNullValue) {
        setGreyBg(false)
      }
    } else {
      setGreyBg(false)
    }
  }, [value, greyBackground])

  return { greyBg }
}

export const validateInput = (
  inputValue: string,
  minValue: number,
  maxValue: number,
  inputType: InputType,
  otherValue?: string
): ValidationResult => {
  const regex = /(-0)/g
  const numericOtherValue =
    otherValue !== undefined ? Number(otherValue) : undefined

  if (inputValue === "0" && minValue !== 0 && maxValue !== 0) {
    return { isValid: false, isPotential: false }
  }

  if (inputValue === "") {
    return { isValid: false, isPotential: true }
  }

  if (inputValue.includes(".")) {
    return { isValid: false, isPotential: false }
  }

  if (inputValue === "-") {
    return { isValid: false, isPotential: true }
  }

  if (regex.test(inputValue)) {
    return { isValid: false, isPotential: false }
  }

  const numericValue = Number(inputValue)
  if (isNaN(numericValue)) {
    return { isValid: false, isPotential: false }
  }

  if (
    inputType === "min" &&
    numericOtherValue !== undefined &&
    numericValue > numericOtherValue
  ) {
    return { isValid: false, isPotential: false }
  }

  if (
    inputType === "max" &&
    numericOtherValue !== undefined &&
    numericValue < numericOtherValue
  ) {
    return { isValid: false, isPotential: false }
  }

  return {
    isValid: numericValue >= minValue && numericValue <= maxValue,
    isPotential: true
  }
}

export const getErrorText = (
  value?: number,
  otherValue?: number,
  min?: number,
  max?: number,
  inputType?: InputType
): string | undefined => {
  const numericValue = value !== undefined ? Number(value) : undefined
  const numericOtherValue =
    otherValue !== undefined ? Number(otherValue) : undefined

  if (max !== undefined && numericValue !== undefined && numericValue > max) {
    return "Значение больше максимального"
  }

  if (min !== undefined && numericValue !== undefined && numericValue < min) {
    return "Значение меньше минимального"
  }

  if (
    inputType === "min" &&
    numericOtherValue !== undefined &&
    numericValue !== undefined &&
    numericValue > numericOtherValue
  ) {
    return "Значение больше верхнего предела"
  }

  if (
    inputType === "max" &&
    numericOtherValue !== undefined &&
    numericValue !== undefined &&
    numericValue < numericOtherValue
  ) {
    return "Значение меньше нижнего предела"
  }

  return undefined
}
