import React from "react"

import { formatToCurrency, valueToCurrency } from "constants/index"

import { CupisContentReportDataInside } from "./types"

import moment from "moment"

export const initialData = {
  pending: false,
  total: 0,
  data: []
}

const columnConstants = {
  date: {
    name: `date`,
    label: `Дата`,
    total: `Итого`,
    format: `DD.MM.YYYY`,
    formatMonth: `MMM YYYY`,
    formatYear: `YYYY`
  },
  currency: [
    {
      name: `bet_sum`,
      label: `Сумма ставок, ₽`
    },
    {
      name: `payments_sum`,
      label: `Сумма выплат, ₽`
    },
    {
      name: `bonus`,
      label: `Бонусные ставки, Б`
    },
    {
      name: `commission`,
      label: `Комиссия, ₽`
    },
    {
      name: `balance`,
      label: `Остаток, ₽`
    },
    {
      name: `income`,
      label: `Чистый заработок, ₽`
    }
  ],
  percent: {
    name: `percent`,
    label: `Процент, %`
  }
}

const currencyColumns = columnConstants.currency.map(item => {
  const classCurrency =
    item.name === `bonus` || item.name === `income`
      ? `TableHeaderCupisWidth`
      : `TableHeaderCupisCurrencyWidth`
  return {
    title: () => (
      <div className={`TableHeaderWithoutSpacing ${classCurrency}`}>
        {item.label}
      </div>
    ),
    width: 120,
    dataIndex: item.name,
    key: item.name,
    render: (value: string) => {
      const currency = valueToCurrency({
        value: Number(value),
        withoutSpace: true
      })
      return <span className="TextDefault">{currency}</span>
    }
  }
})

export const getColumns = (period: string) => [
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh TableHeaderCupisDateWidth">
        {columnConstants.date.label}
      </div>
    ),
    width: 120,
    dataIndex: columnConstants.date.name,
    key: columnConstants.date.name,
    render: (date: string | undefined) => {
      let dateByFormat
      if (!!date) {
        switch (period) {
          case `daily`:
            dateByFormat = moment(date, columnConstants.date.format).format(
              columnConstants.date.format
            )
            break
          case `monthly`:
            dateByFormat = moment(date, columnConstants.date.format).format(
              columnConstants.date.formatMonth
            )
            break
          case `yearly`:
            dateByFormat = moment(date, columnConstants.date.format).format(
              columnConstants.date.formatYear
            )
            break
        }
      } else {
        dateByFormat = columnConstants.date.total
      }
      return (
        <span className="TextDefaultBold CapitalizeText">{dateByFormat}</span>
      )
    }
  },
  ...currencyColumns,
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderCupisPersentWidth">
        {columnConstants.percent.label}
      </div>
    ),
    width: 60,
    dataIndex: columnConstants.percent.name,
    key: columnConstants.percent.name,
    render: (percent: string) => {
      return <span className="TextDefault">{percent}</span>
    }
  }
]

export const insideText = {
  bonusAndMoney: {
    header: `Детализация начислений бонус-акций`,
    bonus: `Бонусные\n начисления, Б`,
    money: `Денежные\n начисления, ₽`
  },
  guests: {
    header: `Детализация по гостям`,
    count: `Кол-во\nактивных гостей`,
    attached: `Кол-во закрепленных\nза клубом гостей`
  },
  details: {
    header: `Кассовая детализация`,
    sum_in: `Сумма вводов, ₽`,
    sum_out: `Сумма выводов, ₽`,
    commission: `Комиссия, ₽`
  }
}

const dash = `–`

export const insideTextByData = (data: CupisContentReportDataInside) => {
  const moreArr =
    data.money?.length > data.bonus?.length ? data.money : data.bonus

  const bonusAndMoneyValuesInitial = [
    {
      bonusField: undefined,
      bonusValue: dash,
      moneyValue: dash,
      moneyField: undefined
    }
  ]
  const bonusAndMoneyValues = moreArr.map((item, key) => ({
    bonusField: data.bonus[key]?.field || dash,
    bonusValue: data.bonus[key]?.value || dash,
    moneyValue: data.money[key]?.value
      ? formatToCurrency({
          amount: Number(data.money[key]?.value || 0)
        })
      : dash,
    moneyField: (data.money || [])[key]?.field || dash
  }))
  return {
    hall: data.hall ? `Клуб № ${data.hall}` : null,
    bonusAndMoneyValues: !!bonusAndMoneyValues.length
      ? bonusAndMoneyValues
      : bonusAndMoneyValuesInitial,
    sum_in: formatToCurrency({ amount: Number(data.details?.sum_in || 0) }),
    sum_out: formatToCurrency({ amount: Number(data.details?.sum_out || 0) }),
    commission: formatToCurrency({
      amount: Number(data.details?.commission || 0)
    })
  }
}
