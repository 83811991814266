import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useRequest, useRequestBody, useRequestParams } from "api/hooks"
import { useSelector } from "react-redux"
import { initialFilterStateCreator } from "./helpers"
import {
  GamesInsuranceData,
  InsurancesColumns,
  InsurancesFilter,
  InsurancesState
} from "./types"
import { Sorter, SortOrders } from "Components/Table/types"
import insurancesConstants from "./constants"
import { getColumns } from "./helpers"
import { sortForTable } from "Components/Table"
import { getDateDescriptionLayout, isMobile } from "../../constants"
import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import TableNew from "Components/TableNew"
import { ModalRightDetail } from "Components/Modal"
import WinnersDetail from "./WinnersDetail"
import { Moment } from "moment/moment"
import { getGamesList } from "store/dictionary/selector"

const InsurancesComponent = () => {
  const { current_hall } = useRequestParams()
  const {
    pagination,
    setPagination,
    setSort,
    sort,
    resetPagination
  } = useRequestBody()

  const initState = initialFilterStateCreator(current_hall[0] as number)
  const [filterState, setFilterState] = useState<InsurancesFilter>(initState)
  const setFilterStateCb = useCallback(value => setFilterState(value), [])

  const [insurancesState, setInsurancesState] = useState<InsurancesState>({
    pending: false,
    total: 0,
    report: []
  })

  const initRequestBody = {
    filter: {
      hall: filterState.data.halls[0]
    },
    sort: {
      field: sort.order ? sort.field : InsurancesColumns.id,
      order: sort.order ? sort.order : SortOrders.desc
    },
    pagination
  }

  const { request } = useRequest({
    url: insurancesConstants.url,
    method: `POST`,
    requestBody: {
      ...initRequestBody,
      filter: {
        ...initRequestBody.filter,
        dates: filterState.data.dates
          ? [
              filterState.data.dates.from.format(
                insurancesConstants.dateFormat
              ),
              filterState.data.dates.to.format(insurancesConstants.dateFormat)
            ]
          : null
      }
    }
  })

  useEffect(() => {
    setInsurancesState({ ...insurancesState, pending: true })

    request()
      .then(({ data, headers }: any) => {
        setInsurancesState({
          ...insurancesState,
          pending: false,
          total: headers[`total`],
          report: [...data]
        })
      })
      .catch(() => {
        setInsurancesState({ ...insurancesState, pending: false })
      })
  }, [
    filterState.data.halls,
    pagination.offset,
    pagination.limit,
    sort,
    filterState.data.dates,
    filterState.isActive
  ])

  const onChange = (pagination: any, filters: any, sorter: Sorter) =>
    sortForTable({ sorter, setSort })

  const titleDescription = useMemo(() => {
    let dates = null

    if (!!filterState.data.dates) {
      dates = [filterState.data.dates.from, filterState.data.dates.to]
    } else if (insurancesState.report.length) {
      const maxDate = new Date(
        Math.max(
          ...insurancesState.report.map(element => {
            return new Date(element.period[1]).getTime()
          })
        )
      )

      const minDate = new Date(
        Math.min(
          ...insurancesState.report.map(element => {
            return new Date(element.period[0]).getTime()
          })
        )
      )

      dates = [minDate, maxDate]
    }

    return getDateDescriptionLayout({
      dates: dates as [Moment, Moment],
      shift: [8, 20],
      hideTime: true,
      isShift: false,
      show: true,
      className: "InsuranceDatesDescription"
    })
  }, [filterState.data.dates, insurancesState.report])

  const [showModal, setShowModal] = useState(false)

  const games: GamesInsuranceData = useSelector(getGamesList)

  const columns = getColumns({
    sort,
    setShowModal,
    insurancesState,
    setInsurancesState,
    games
  })

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={insurancesConstants.INSURANCES_FILTER}
            filterState={filterState}
            setFilterState={setFilterStateCb}
            resetPagination={resetPagination}
            filters={[Filters.rangePicker, Filters.hallPickerRadio]}
            initialForClear={{
              halls: [Number(current_hall[0])]
            }}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <>
            <div>
              <Title
                direction={insurancesConstants.tooltipDirection}
                titleText={insurancesConstants.title}
                tooltipText={insurancesConstants.mainTooltip}
                wrapperClass={`InsurancesTitleWrapper`}
                bottomAdditionalCmp={<div>{titleDescription}</div>}
              />
              <TableNew
                tableClassName={`InsurancesTable`}
                onChange={onChange}
                columns={columns}
                data={insurancesState.report}
                loading={insurancesState.pending}
                scroll={insurancesConstants.scroll}
                pagination={{
                  value: pagination,
                  total: insurancesState.total,
                  onChange: setPagination
                }}
                width={1402}
              />
            </div>
            {showModal && insurancesState.insuranceId && (
              <ModalRightDetail
                initialWidth={536}
                close={() => setShowModal(false)}
                hideClose={isMobile ? true : false}
              >
                <WinnersDetail
                  data={insurancesState.report}
                  insuranceId={insurancesState.insuranceId}
                  close={() => setShowModal(false)}
                />
              </ModalRightDetail>
            )}
          </>
        }
      />
    </>
  )
}

export default InsurancesComponent
