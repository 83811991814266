import React from "react"
import { VisitAndRegProps } from "../types"
import OnlyRegSelection from "./OnlyRegSelection"
import ByTypeSelection from "./ByTypeSelection"
import VisitChart from "./VisitChart"

const VisitAndReg = ({
  reportState,
  filterState,
  setFilterState
}: VisitAndRegProps) => {
  return (
    <div className="VisitsBlock">
      <OnlyRegSelection
        filterState={filterState}
        setFilterState={setFilterState}
      />
      <div>
        <VisitChart reportState={reportState} filterState={filterState} />
      </div>
      <ByTypeSelection
        filterState={filterState}
        onlyReg={!!filterState.data.onlyReg}
        setFilterState={setFilterState}
      />
    </div>
  )
}

export default VisitAndReg
