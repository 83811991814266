import { Moment } from "moment"

import { FilesList } from "Components/File/types"

export enum PaymentModalFields {
  contractor = `contractor`,
  inn = `inn`,
  sum = "sum",
  payment_purpose = `payment_purpose`,
  comment = `comment`,
  files = `files`,
  payment_period_type = `payment_period_type`,
  payment_period = `payment_period`
}

export type PaymentModalFormValues = {
  [PaymentModalFields.contractor]?: string
  [PaymentModalFields.inn]?: string
  [PaymentModalFields.sum]?: string
  [PaymentModalFields.payment_purpose]?: string
  [PaymentModalFields.comment]?: string
  [PaymentModalFields.payment_period_type]?: string
  [PaymentModalFields.payment_period]?: {
    from: Moment
    to: Moment
  }
  [PaymentModalFields.files]: FilesList[]
}

export type ModalData = {
  open: boolean
  editData?: PaymentModalFormValues
}

export type PaymentModalProps = {
  data?: PaymentModalFormValues
  close: () => void
}
