import { MAIN_PAGE } from "../Containers/BaseLayout/constants"

export const redirectByHalls = (hall: string) => {
  const roleFromStorage = localStorage.getItem(`role`) || ``
  const origin = window.location.origin || `https://lk.betboom.ru`
  const redirectList: any = {
    "NON-PARTNER": `/ru/${hall}/brandbook_photo`,
    "COMPANY-CONTRACTOR": `/ru/${hall}/brandbook_photo`,

    "PARTNER-CURATOR": `/ru/${hall}/ticket/unrealized`,

    "COMPANY-LOGIST": `/ru/${hall}/orders`
  }
  if (redirectList[roleFromStorage]) {
    window.location.href = `${origin}${redirectList[roleFromStorage] || ``}`
  }

  return false
}

export const baseRoute = (data: (string | null)[]) => {
  const roleFromStorage = localStorage.getItem(`role`) || ``
  const cashboxList: any = {
    "PARTNER-KZ": `/cashbox`,
    PPS_EMPLOYER: `/cashbox`,
    "PARTNER-CASHBOX": `/cashbox`,
    "PARTNER-TAITOV": `/cashbox`
  }
  if (!!cashboxList[roleFromStorage]) return `/cashbox`
  const gamesList: any = {
    "PARTNER-OWN-RETAIL": `/games`,
    "PARTNER-CASHIER": `/games`
  }
  if (!!gamesList[roleFromStorage]) return `/games`

  const macrosList: any = {
    "NON-PARTNER": `/ru/hall/brandbook_photo`,
    "COMPANY-CONTRACTOR": `/ru/hall/brandbook_photo`,

    "PARTNER-CURATOR": `/ru/hall/ticket/unrealized`,

    "COMPANY-LOGIST": `/ru/hall/orders`
  }
  if (!!macrosList[roleFromStorage]) return macrosList[roleFromStorage]

  const baseAvailable = data.find(el => !!el)

  if (baseAvailable) {
    return baseAvailable
  }

  return MAIN_PAGE
}

const canClickOnLogo = () => {
  const canHalls = [
    `partner-operator`,
    `partner-coowner`,
    `partner-administrator`,
    `partner`,
    `company-support`,
    `company-manager`,
    `company-invest`,
    `company-finance`,
    `company-developer`
  ]
  const roleFromStorage = localStorage.getItem(`role`) || ``

  return !!canHalls.filter(item => item.toUpperCase() === roleFromStorage)
    .length
}

export default canClickOnLogo
