import React, { useEffect, useMemo } from "react"
import { Select } from "antd"
import moment, { Moment } from "moment"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

import { RadioGroupComponent } from "Components/Radio"
import DatePickerComponent, {
  RangePickerComponent
} from "Components/DatePicker"
import SliderComponent from "Components/Slider"
import SelectHours from "Components/SelectHour"

import { LocationLabel } from "Containers/Main/Filter"
import SwitchComponents from "Components/Switch"
import { HallListFilter } from "Components/HallPicker/HallPickerModal"
import { get, sortBy, uniqBy } from "lodash"
import { locationsByGmt } from "api/helpers"
import {
  Durations,
  FilterDurations,
  InfoPanelComponentProps,
  Picker,
  PickerDurations,
  PickerFormats,
  PickerFormatsShortYear
} from "./types"
import {
  dateKeys,
  localStorageKeys,
  shiftKeys,
  initPeriodOptions,
  infoPanelComponentText
} from "./helpers"
import { ReactComponent as InfoIcon } from "Components/icons/help_16.svg"
import TooltipComponent from "../Tooltip"
import {
  MOSCOW_GMT,
  shiftMoscowGmtFirst,
  shiftMoscowGmtFirstSingle,
  SHIFT_LIMIT_MIN,
  SHIFT_LIMIT_MAX
} from "constants/index"
import { SelectComponent } from "../Select"

const time = (value: number) => {
  const zeroHour = value === 24 ? 0 : value
  return zeroHour > 9 ? zeroHour : `0${zeroHour}`
}

const InfoPanelComponent = <F extends Record<string, unknown>>({
  filter,
  filterHalls,
  setFilter,
  reportType,
  current_hall,
  disabledShift,
  disabledHalls,
  disabledShiftSwitch,
  periodOptions,
  withoutPickDateType,
  withoutShift,
  withoutGmt,
  gmtSelect,
  periodOptionsTooltipText,
  alwaysMoscow,
  clearRange,
  shiftSlider = true,
  disabledRangeDates,
  // Custom component for InfoPanel
  CustomInfoPanelHalls,
  rangeTooltip
}: InfoPanelComponentProps<F>) => {
  let pickerType: Picker
  let pickerFormat: PickerFormats | PickerFormatsShortYear =
    PickerFormats.dayMonthYear
  let pickerFormatShortYear: PickerFormatsShortYear =
    PickerFormatsShortYear.dayMonthYear
  switch (filter.period) {
    case FilterDurations.hourly:
      pickerType = undefined
      pickerFormatShortYear = PickerFormatsShortYear.dayMonth
      break
    case FilterDurations.daily:
      pickerType = PickerDurations.date
      pickerFormat = PickerFormatsShortYear.dayMonthYear
      break
    case FilterDurations.monthly:
      pickerType = PickerDurations.month
      pickerFormat = PickerFormats.shortMonthYear
      pickerFormatShortYear = PickerFormatsShortYear.dayMonth
      break
    case FilterDurations.yearly:
      pickerType = PickerDurations.year
      pickerFormat = PickerFormats.year
      pickerFormatShortYear = PickerFormatsShortYear.dayMonthYear
      break
    default:
      pickerType = PickerDurations.date
  }

  const gmt = filter.gmt || MOSCOW_GMT
  const shift =
    filter.shift || shiftMoscowGmtFirst({ gmt, isShift: filter.isShift })
  const isShift = filter.isShift !== undefined ? filter.isShift : true

  useEffect(() => {
    if (filter.gmt) {
      setFilter(prev => ({
        ...prev,
        shift: shiftMoscowGmtFirst({
          gmt,
          isShift: filter.isShift,
          alwaysMoscow
        })
      }))
    }
  }, [filter.gmt, filter.isShift])

  const hallAddressesStorage: string =
    localStorage.getItem(localStorageKeys.hallsAddress) || ``
  const hallAddresses: {
    [key: string]: { gmt: string; region: string }
  } = hallAddressesStorage ? JSON.parse(hallAddressesStorage) : []

  const gmtLocations: { gmt: string; region: string; short: string }[] = uniqBy(
    (filterHalls?.length
      ? filterHalls
      : current_hall
    ).map((item: string | CheckboxValueType) =>
      locationsByGmt(hallAddresses[`${item}`]?.gmt || ``)
    ),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dateKeys.gmt
  )

  const options = sortBy(
    gmtLocations.map(item => ({
      value: item.gmt,
      label: item.region,
      short: item.short,
      customLabel: <LocationLabel {...item} />
    })),
    [dateKeys.gmt]
  )

  useEffect(() => {
    const checkNewLocations =
      gmtLocations.filter(item => item.gmt === gmt).length > 0
    if (!checkNewLocations) setFilter({ ...filter, gmt: gmtLocations[0].gmt })
  }, [gmtLocations, filter.halls])

  const currentOption = options.filter(item => item.value === gmt)
  const locationLabelForHour = get(
    currentOption,
    isShift ? shiftKeys.label : shiftKeys.short,
    ``
  )

  const moscowFirstHour = shift[0] - Number(gmt) + 3
  const moscowSecondHour = shift[1] - Number(gmt) + 3
  const moscowTimeForHour = [
    moscowFirstHour >= 0 ? moscowFirstHour : 24 + moscowFirstHour,
    moscowSecondHour >= 0 ? moscowSecondHour : 24 + moscowSecondHour
  ]

  const moscowStartForHour = useMemo(() => {
    if (!filter.dates) return [``, ``]
    else {
      const startBack = shift[0] - Number(gmt) + 3 >= 0
      const endForwardGmt = shift[1] - Number(gmt) + 3 > 23 ? 1 : 0
      const endSubtract =
        shift[1] - moscowTimeForHour[1] < 0 &&
        moscowTimeForHour[1] < moscowTimeForHour[0]
          ? -1
          : 0

      const endForward = moscowTimeForHour[1] < moscowTimeForHour[0] ? 1 : 0

      const startMoscow = moment(filter.dates[0])
        .subtract(startBack ? 0 : 1, Durations.day)
        .format(pickerFormatShortYear)
      const endMoscow = moment(filter.dates[1])
        .add(endForwardGmt + endForward + endSubtract, Durations.day)
        .format(pickerFormatShortYear)
      return [startMoscow, endMoscow]
    }
  }, [filter.dates, shift, pickerFormatShortYear, gmt, withoutShift])
  const locationHourHorAlwaysMoscow = useMemo(() => {
    if (alwaysMoscow && !!filter.dates) {
      const newHour = gmt - 3 + shift[0]
      if (newHour > 9) {
        return newHour
      } else {
        return `0${newHour}`
      }
    } else {
      return shiftMoscowGmtFirstSingle(gmt)
    }
  }, [filter.dates, shift, pickerFormatShortYear, gmt, withoutShift])

  const haveManyLocations = options.length > 1

  const firstTime = `${time(shift[0])}:00 (${
    alwaysMoscow ? `Москва` : locationLabelForHour
  }) `

  const secondTime = alwaysMoscow
    ? `${locationHourHorAlwaysMoscow}:00 (${locationLabelForHour}) `
    : ` | ${time(moscowTimeForHour[0])}:00 (Москва)`

  const disabledDate = (current: Moment) => {
    switch (filter.period) {
      case FilterDurations.hourly:
        return current && current > moment().endOf(Durations.day)
      case FilterDurations.daily:
        return current && current > moment().endOf(Durations.day)
      case FilterDurations.monthly:
        return current && current > moment().endOf(Durations.month)
      case FilterDurations.yearly:
        return current && current > moment().endOf(Durations.year)
      default:
        return current && current > moment().endOf(Durations.day)
    }
  }

  return (
    <div>
      {!disabledHalls && (
        <HallListFilter
          current_halls={filter.halls}
          setCurrentHalls={halls => {
            if (!halls.length) {
              setFilter({
                ...filter,
                halls
              })
            } else {
              setFilter({
                ...filter,
                halls
              })
            }
          }}
        />
      )}
      {reportType ? reportType : <div />}
      {!withoutPickDateType && (
        <div>
          <div className="FlexRow Gap4">
            <span className="FilterContentLabel">
              {infoPanelComponentText.info}
            </span>
            {!!periodOptionsTooltipText && (
              <TooltipComponent
                direction="left"
                title={periodOptionsTooltipText}
              >
                <div className="MarginTop4">
                  <InfoIcon />
                </div>
              </TooltipComponent>
            )}
          </div>
          <div className="InfoFilterContent">
            <RadioGroupComponent
              value={filter.period}
              onChange={({ target: { value } }) =>
                setFilter({ ...filter, period: value, dates: null })
              }
              options={periodOptions || initPeriodOptions}
            />
          </div>
        </div>
      )}
      {!!CustomInfoPanelHalls && (
        <div className="InfoFilterCustomInfoPanelHalls">
          <CustomInfoPanelHalls filter={filter} setFilter={setFilter} />
        </div>
      )}
      <div className="FlexRow Gap4">
        <span className="FilterContentLabel">
          {infoPanelComponentText.period}
        </span>
        {!!rangeTooltip && (
          <TooltipComponent title={rangeTooltip} placement="bottom">
            <div className="MarginTop4">
              <InfoIcon />
            </div>
          </TooltipComponent>
        )}
      </div>
      <div className="InfoFilterContent">
        {filter.period === FilterDurations.hourly && !withoutPickDateType ? (
          <DatePickerComponent
            value={filter.dates ? filter.dates[0] : null}
            disabledDate={disabledDate}
            onChange={value =>
              setFilter({ ...filter, dates: value ? [value, value] : null })
            }
          />
        ) : (
          <RangePickerComponent
            value={filter.dates}
            picker={pickerType}
            format={pickerFormat}
            allowClear={!!clearRange}
            disabledDate={disabledRangeDates || disabledDate}
            onChange={value => {
              if (filter.period === FilterDurations.monthly) {
                setFilter({
                  ...filter,
                  dates: [
                    moment(value[0]).startOf(Durations.month),
                    moment(value[1]).endOf(Durations.month)
                  ]
                })
              } else if (filter.period === FilterDurations.yearly) {
                setFilter({
                  ...filter,
                  dates: [
                    moment(value[0]).startOf(Durations.year),
                    moment(value[1]).endOf(Durations.year)
                  ]
                })
              } else setFilter({ ...filter, dates: value })
            }}
          />
        )}
      </div>
      {haveManyLocations && !withoutGmt && (
        <div className="FlexColumn Gap8">
          <span className="FilterContentLabel">
            {infoPanelComponentText.timezone}
          </span>
          {gmtSelect ? (
            <div className="PaddingBottom16">
              <SelectComponent
                className="GmtSelectWrapper"
                value={gmt}
                allowClear={false}
                onChange={value => setFilter({ ...filter, gmt: value })}
              >
                {options.map(item => {
                  const gmtLabel = ` UTC${Number(item.value) > 0 ? `+` : `-`}${
                    Number(item.value) > 9 ? gmt : `0${item.value}`
                  }:00`
                  return (
                    <Select.Option value={item.value} key={item.value}>
                      <div className="FlexRow Gap8 GmtSelectActiveValue">
                        <div>{item.label}</div>
                        <div className="Gray600Color">{gmtLabel}</div>
                      </div>
                    </Select.Option>
                  )
                })}
              </SelectComponent>
            </div>
          ) : (
            <div className="MainFilterGmtWrapper InfoFilterContent">
              <RadioGroupComponent
                options={options}
                value={gmt}
                onChange={({ target: { value } }) =>
                  setFilter({ ...filter, gmt: value })
                }
              />
            </div>
          )}
        </div>
      )}
      {!disabledShift && !disabledShiftSwitch && (
        <div className="FlexRow">
          <span className="FilterContentLabel">
            {infoPanelComponentText.change}
          </span>
          <div className="InfoPanelWithoutShiftTypeSwitcher">
            <SwitchComponents
              label=""
              checked={isShift}
              onChange={checked =>
                setFilter({
                  ...filter,
                  isShift: checked,
                  shift: shiftMoscowGmtFirst({
                    gmt: filter.gmt,
                    isShift: checked
                  })
                })
              }
            />
          </div>
        </div>
      )}

      {shiftSlider && (
        <>
          {isShift ? (
            <SliderComponent
              min={SHIFT_LIMIT_MIN}
              max={SHIFT_LIMIT_MAX}
              disabled={!!disabledShift}
              defaultValue={shift[0]}
              onChange={(value: number) =>
                setFilter({ ...filter, shift: [value, value] })
              }
            />
          ) : (
            <SelectHours
              from={shift[0]}
              to={shift[1]}
              disabled={!!disabledShift}
              onChange={(value: [number, number]) =>
                setFilter({ ...filter, shift: value })
              }
            />
          )}
          <div className="InfoPanelLocationLabelWrapper">
            {isShift ? (
              <div className="MainFilterGmtHourWrapper FlexRow">
                <div className="MainFilterGmtHour">{firstTime}</div>
                {gmt !== `3` && (
                  <div className="MainFilterGmtHour MainFilterGmtHourMoscow">
                    {secondTime}
                  </div>
                )}
              </div>
            ) : (
              <div className="MainFilterGmtHourWrapper">
                <div className="MainFilterGmtHour">{`${
                  !!filter.dates
                    ? moment(filter.dates[0]).format(pickerFormatShortYear)
                    : ``
                } ${time(shift[0])}:00 — ${
                  !!filter.dates
                    ? moment(filter.dates[1])
                        .add(shift[0] >= shift[1] ? 1 : 0, Durations.day)
                        .format(pickerFormatShortYear)
                    : ``
                } ${time(shift[1])}:00 (${locationLabelForHour})`}</div>
                {gmt !== `3` && (
                  <div
                    style={{ paddingTop: `4px` }}
                    className="MainFilterGmtHour MainFilterGmtHourMoscow"
                  >{`
              ${moscowStartForHour[0]} 
              ${time(moscowTimeForHour[0])}:00 — ${
                    moscowStartForHour[1]
                  } ${time(moscowTimeForHour[1])}:00 (Мск)`}</div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default InfoPanelComponent
