import React, { useEffect, useMemo, useState } from "react"

import { useRequest } from "api/hooks"
import { PayrollTableState } from "./types"

import TableComponent from "Components/TableNew"

import { initialState, getColumns, prepareTableFilter } from "./constants"
import FilesModal from "./Components/FileModals"
import { FilesModalState } from "./Components/types"

import { PayrollTableProps } from "../types"

const PayrollTable = ({
  filters,
  setFilter,
  setPayrollModal,
  refreshTrigger
}: PayrollTableProps) => {
  const [tableData, setTableData] = useState<PayrollTableState>(initialState)

  const { filter, sort } = prepareTableFilter(filters.table)

  const { request: getTable } = useRequest({
    url: `v1/requests/payrolls`,
    method: "POST",
    requestBody: { pagination: filters.pagination, filter, sort }
  })

  const [filesModal, changeFilesModal] = useState<FilesModalState>({
    open: false,
    data: []
  })

  const requestDeps = JSON.stringify(filters)

  useEffect(() => {
    setTableData({ ...tableData, pending: true })
    getTable()
      .then(({ data }) =>
        setTableData({
          data: data.items || [],
          pending: false,
          total_count: data.total_count
        })
      )
      .catch(() => setTableData({ ...tableData, pending: false }))
  }, [requestDeps, refreshTrigger])

  const columns = useMemo(
    () => getColumns({ filters, setFilter, changeFilesModal, setPayrollModal }),
    [filters]
  )

  return (
    <div>
      {filesModal.open && (
        <FilesModal
          close={() => changeFilesModal({ open: false, data: [] })}
          data={filesModal.data}
        />
      )}
      <TableComponent
        rowClassName="PayrollTableRow"
        width={1200}
        data={tableData.data}
        loading={tableData.pending}
        columns={columns}
        pagination={{
          value: filters.pagination,
          onChange: pag => setFilter({ ...filters, pagination: pag }),
          total: tableData.total_count
        }}
      />
    </div>
  )
}

export default PayrollTable
