// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { UploadFile } from "antd/es/upload/interface"
import { message } from "antd"
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface"

export const calculateStep = (size: number | undefined) =>
  !!size ? (70000 * 100) / size : 10

export const beforeUpload = (
  file: UploadFile,
  fileList: UploadFile[],
  isVideoSection?: boolean,
  setNotificationsArr: (el: [{ uid: string; text: string }]) => void
) => {
  if (!!file) {
    const isUniq = !fileList.filter(item => item.name === file.name).length
    const isVideo = file.type?.includes(`video`)
    if (isVideoSection && !isVideo) {
      setNotificationsArr(prev => {
        const msg = "Вы можете загрузить только видео в эту секцию!"
        const foundedIdx = prev.findIndex(
          el => el.uid === file.uid && el.text === msg
        )
        if (foundedIdx === -1) {
          message.error(msg)
          return [
            ...prev,
            {
              uid: file.uid,
              text: msg
            }
          ]
        }
        return prev
      })
      return false
    }

    if (!isVideoSection && isVideo) {
      setNotificationsArr(prev => {
        const msg =
          "Вы можете загрузить только только JPG/PNG файлы в эту секцию!"
        const foundedIdx = prev.findIndex(
          el => el.uid === file.uid && el.text === msg
        )
        if (foundedIdx === -1) {
          message.error(msg)
          return [
            ...prev,
            {
              uid: file.uid,
              text: msg
            }
          ]
        }
        return prev
      })
      return false
    }

    const isJpgOrPngOrVideo =
      file.type === "image/jpeg" || file.type === "image/png" || isVideo
    const isLt2M = isVideo
      ? (file?.size || 0) / 1024 / 1024 < 512
      : (file?.size || 0) / 1024 / 1024 < 5
    if (!isJpgOrPngOrVideo) {
      setNotificationsArr(prev => {
        const msg = "Вы можете загрузить только JPG/PNG файлы!"
        const foundedIdx = prev.findIndex(
          el => el.uid === file.uid && el.text === msg
        )
        if (foundedIdx === -1) {
          message.error(msg)
          return [
            ...prev,
            {
              uid: file.uid,
              text: msg
            }
          ]
        }
        return prev
      })
      return false
    } else if (!isLt2M) {
      setNotificationsArr(prev => {
        const msg = `Размер файла не должен превышать ${isVideo ? 512 : 5}Mb`
        const foundedIdx = prev.findIndex(
          el => el.uid === file.uid && el.text === msg
        )
        if (foundedIdx === -1) {
          message.error(msg)
          return [
            ...prev,
            {
              uid: file.uid,
              text: msg
            }
          ]
        }
        return prev
      })
      return false
    }
    return isJpgOrPngOrVideo && isLt2M && isUniq
  }
  return false
}

export const dummyRequest = (options: RcCustomRequestOptions) => {
  setTimeout(() => {
    if (!!options.onSuccess) {
      options.onSuccess(options.file)
    }
  }, 0)
}

export const recalculatAllFiles = (
  files: UploadFile[],
  current: UploadFile
): {
  [uid: string]: { step: number; progress: number }
} => {
  const currentSize = current.size
  const currentUid = current.uid
  const filesData = files.map(item => {
    const loadStep = calculateStep(item.size)
    const isReady = loadStep > 100
    const loadPercent =
      !!item.size && !!currentSize ? (currentSize / item.size) * 100 : 100
    const returnData =
      currentUid === item.uid
        ? {
            step: 0,
            progress: 100,
            uid: item.uid
          }
        : {
            step: isReady ? 0 : loadStep,
            progress: isReady ? 100 : loadPercent,
            uid: item.uid
          }
    return returnData
  })

  const returnData: {
    [key: string]: {
      step: number
      progress: number
    }
  } = {}

  for (let i = 0; i < filesData.length; i++) {
    const currentFileData: {
      step: number
      progress: number
      uid: string
    } = filesData[i]
    returnData[currentFileData.uid] = {
      step: currentFileData.step,
      progress: currentFileData.progress
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return returnData
}

export class Chunk {
  name: string
  size: number
  url: string
  totalNumber: number
  start: number
  identity: string
  codes: [number]
  loadValue: number
  percentValue: number

  constructor(props: {
    name: string
    size: number
    url: string
    request: (data: FormData) => Promise<any>
    setLoading: (data: {
      [uid: string]: { step: number; progress: number }
    }) => void
    setNewFileId: (dileID) => void
    uid: string
  }) {
    this.name = props.name
    this.size = props.size
    this.url = props.url
    this.request = props.request
    this.setLoading = props.setLoading
    this.setNewFileId = props.setNewFileId
    this.uid = props.uid

    this.totalNumber = 0
    this.start = 0
    this.file = {}
    this.identity = this.generateRandomString()
    this.codes = [400, 404, 415, 500, 501]
    this.loadValue = 0
    this.percentValue = 0
  }

  setFile(file) {
    this.file = file
    this.setTotalNumber()
  }

  setTotalNumber() {
    const total = Math.ceil(this.file.size / this.size)
    this.percentValue = 100 / total
    this.totalNumber = total > 0 ? total : 1
  }

  getNumber() {
    return this.start / this.size + 1
  }

  generateRandomString(length = 32) {
    return [...Array(length)]
      .map(() => (~~(Math.random() * 36)).toString(36))
      .join("")
  }

  slice(start, end) {
    return this.file.slice(start, end - 1)
  }

  commit() {
    this.push(this.start, this.start + this.size + 1)
  }

  push(start, end) {
    const data = new FormData()
    data.append("file", this.slice(start, end))

    const res = Array.from(data.entries(), ([key, prop]) => ({
      [key]: {
        ContentLength: typeof prop === "string" ? prop.length : prop.size
      }
    }))

    const chunkSize = res[0].file.ContentLength

    data.append("filename", this.file.name)
    data.append("totalSize", this.file.size)
    data.append("totalChunks", this.totalNumber)
    data.append("chunkNumber", this.getNumber())
    data.append("chunkSize", this.size)
    data.append("currentChunkSize", chunkSize)
    data.append("identifier", this.identity)

    this.request(data)
      .then(response => {
        this.start += this.size
        switch (response.status) {
          // done
          case 201:
            this.setNewFileId(prev => ({
              ...prev,
              [this.uid]: response.data.attachments[0].id
            }))
            this.setLoading({})
            this.loadValue = 0
            break

          // asking for the next chunk...
          case 200:
            this.setLoading(prev => ({
              ...prev,
              [this.uid]: {
                step: this.loadValue,
                progress:
                  this.loadValue * this.percentValue === 0
                    ? 1
                    : this.loadValue * this.percentValue
              }
            }))
            this.loadValue = this.loadValue + 1
            if (this.start < this.file.size) {
              this.commit()
            }
            break
        }
      })
      .catch(error => {
        if (error.response) {
          if (this.codes.includes(error.response.status)) {
            // eslint-disable-next-line no-console
            console.warn(error.response.status, "Failed to upload the chunk.")
          } else if (error.response.status === 422) {
            // eslint-disable-next-line no-console
            console.warn("Validation Error", error.response.data)
          } else {
            this.commit()
          }
        } else {
        }
      })
  }
}
