import React, { useCallback, useMemo, useState } from "react"

import { useRequestParams } from "api/hooks"

import { isMobile } from "constants/index"

import { ButtonFaded } from "Components/Button"
import { ModalRightDetail } from "Components/Modal"

import { getInitialState, statusNotifyConstants } from "./constants"
import {
  ModalType,
  NotifyModalState,
  NotifyRightModalProps,
  UserFieldsWithId
} from "./types"

import NotifyStatusTable from "./Table"
import UserCrud from "./UserCrud"

export const StatusNotifyModal = ({ baseUrl }: NotifyRightModalProps) => {
  const { current_hall } = useRequestParams()

  const initialNotifyState = getInitialState(Number(current_hall[0]))
  const [modalData, changeModalData] = useState<NotifyModalState>(
    initialNotifyState
  )

  const openEditModal = useCallback(
    (editData?: UserFieldsWithId) => {
      changeModalData({ ...modalData, editData, open: ModalType.add })
    },
    [changeModalData]
  )
  const backToTable = useCallback(() => {
    changeModalData(prev => ({
      ...prev,
      editData: undefined,
      open: ModalType.table
    }))
  }, [modalData])
  const closeAllModal = useCallback(() => changeModalData(initialNotifyState), [
    changeModalData
  ])

  const halls: number[] = useMemo(() => {
    return JSON.parse(localStorage.getItem(`halls`) || ``) || []
  }, [])

  return (
    <div>
      <ButtonFaded
        onClick={() => changeModalData({ ...modalData, open: ModalType.table })}
      >
        {statusNotifyConstants.button}
      </ButtonFaded>
      {modalData.open === ModalType.table && (
        <ModalRightDetail
          className="StatusNotifyModal"
          initialWidth={820}
          close={closeAllModal}
          maskClosable={true}
        >
          <div
            className={`PaddingLeft24 ${
              isMobile
                ? `HeadlineThirdText PaddingTop64`
                : `PaddingTop40 HeadlineFirstText`
            }`}
          >
            {statusNotifyConstants.title}
          </div>
          <div
            className={`StatusNotifyHallPicker  ${
              modalData.pending ? `StatusNotifyHallPickerPending` : ``
            } FlexRow Gap8`}
          >
            {halls.map(item => {
              return (
                <div
                  key={`notify_status_hall_${item}`}
                  className={`FlexRow Gap4 StatusNotifyHallPickerItem ${
                    item === modalData.activeHall
                      ? `StatusNotifyHallPickerItemActive`
                      : ``
                  }`}
                  onClick={() => {
                    if (modalData.activeHall !== item && !modalData.pending) {
                      changeModalData({ ...modalData, activeHall: item })
                    }
                  }}
                >
                  <span className="TableHeader Gray500Color">
                    {statusNotifyConstants.hallPrefix}
                  </span>
                  <span className="TableHeader">{item}</span>
                </div>
              )
            })}
          </div>
          {modalData.activeHall && (
            <NotifyStatusTable
              baseUrl={baseUrl}
              openEditModal={openEditModal}
              pending={modalData.pending}
              hall={modalData.activeHall}
              changePending={newPending =>
                changeModalData({ ...modalData, pending: newPending })
              }
            />
          )}
        </ModalRightDetail>
      )}
      {modalData.open === ModalType.add && (
        <ModalRightDetail
          className="StatusNotifyModal"
          initialWidth={620}
          close={closeAllModal}
          maskClosable={true}
        >
          <UserCrud
            modalData={modalData}
            back={backToTable}
            baseUrl={baseUrl}
          />
        </ModalRightDetail>
      )}
    </div>
  )
}
