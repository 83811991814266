import React, { useEffect, useState } from "react"
import { OnFinish, ProposalModalProps } from "./types"
import { UploadAttachments, DetailsData, UploadAttachment } from "../types"
import { proposalModalText, FormItemNames } from "./helper"
import { ReactComponent as CloseIcon } from "Components/icons/close-big.svg"
import { ReactComponent as ArrowLeftIcon } from "Components/icons/arrow_left.svg"
import { Form, Modal } from "antd"
import { FieldData } from "rc-field-form/lib/interface"
import { uniqBy } from "lodash"
import { getFilesBase64 } from "utils/files"
import { ProposalModalContent } from "./ProposalModalContent"

const initialModalData: DetailsData = {
  text: "",
  username: "",
  phone: "",
  supportMessages: [],
  attachments: []
}

export const ProposalModal: React.FC<ProposalModalProps> = ({
  toggleModal,
  passedModalData,
  viewMode,
  statuses,
  visible,
  resetProposal,
  setCancelId,
  duplicate,
  setModalDataCb,
  isMobile,
  loading,
  successModal
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setModalData(initialModalData)
    }
  }, [visible])

  useEffect(() => {
    if (form) {
      if (passedModalData) {
        form.setFieldsValue({
          [FormItemNames.username]: passedModalData.details.username,
          [FormItemNames.phone]: passedModalData.details.phone,
          [FormItemNames.text]: passedModalData.text
        })
        if (viewMode) {
          setModalData(prev => ({
            ...prev,
            attachments: passedModalData?.details.attachments
          }))
        } else {
          setModalData(prev => ({
            ...prev,
            attachments: []
          }))
        }
      }
    }
  }, [form, passedModalData, viewMode])

  const [modalData, setModalData] = useState(initialModalData)

  const onFinish = (value: OnFinish) => {
    const errors = form.getFieldError(FormItemNames.file)
    if (!errors.length) {
      setModalData(prev => ({
        ...prev,
        text: value.text,
        username: value.username,
        phone: value.phone
      }))
      setModalDataCb({
        attachments: modalData.attachments,
        text: value.text,
        username: value.username,
        phone: value.phone,
        supportMessages: modalData.supportMessages
      })
    }
  }

  const onFieldsChange = (e: FieldData[]) => {
    if ((e[0].name as [string])[0] === FormItemNames.file) {
      getFilesBase64([e[0].value.file]).then(data => {
        setModalData(prev => ({
          ...prev,
          attachments: uniqBy(
            [...prev.attachments, ...data],
            "name"
          ) as UploadAttachments
        }))
      })
    }
  }

  useEffect(() => {
    if (modalData.attachments.length > 10) {
      form.setFields([
        {
          name: FormItemNames.file,
          errors: [proposalModalText.fileUploadCountError]
        }
      ])
    } else if (modalData.attachments.length <= 10) {
      const totalSize = (modalData.attachments as [UploadAttachment]).reduce(
        (acc: number, att: UploadAttachment) => {
          return (acc += att.size)
        },
        0
      )
      if (totalSize > 4e7) {
        form.setFields([
          {
            name: FormItemNames.file,
            errors: [proposalModalText.fileSizeValidationError]
          }
        ])
      } else {
        form.setFields([
          {
            name: FormItemNames.file,
            errors: []
          }
        ])
      }
    } else {
      form.setFields([
        {
          name: FormItemNames.file,
          errors: []
        }
      ])
    }
  }, [modalData.attachments])

  return isMobile ? (
    <>
      {visible && (
        <div className="ProposalModalContentMobileView">
          <div
            onClick={() => {
              toggleModal(false)
              resetProposal()
              form.resetFields()
              setModalData(initialModalData)
            }}
            className="ProposalModalContentMobileViewBack"
          >
            <ArrowLeftIcon />
            {proposalModalText.back}
          </div>
          <ProposalModalContent
            form={form}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            modalData={modalData}
            setModalData={setModalData}
            passedModalData={passedModalData}
            viewMode={viewMode}
            setCancelId={setCancelId}
            duplicate={duplicate}
            loading={loading}
            successModal={successModal}
            statuses={statuses}
          />
        </div>
      )}
    </>
  ) : (
    <Modal
      className={`SupportProposalModal ${
        isMobile ? "SupportProposalModalMobile" : ""
      }`}
      visible={visible}
      footer={null}
      closeIcon={
        successModal ? (
          <></>
        ) : (
          <CloseIcon
            className="ProposalModalCloseIcon"
            onClick={() => {
              toggleModal(false)
              resetProposal()
              form.resetFields()
              setModalData(initialModalData)
            }}
          />
        )
      }
      maskClosable={true}
      width={860}
    >
      <ProposalModalContent
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        modalData={modalData}
        setModalData={setModalData}
        passedModalData={passedModalData}
        viewMode={viewMode}
        setCancelId={setCancelId}
        duplicate={duplicate}
        loading={loading}
        successModal={successModal}
        statuses={statuses}
      />
    </Modal>
  )
}
