import React, { useCallback, useEffect, useRef, useState } from "react"

import { ReactComponent as TableSearchIcon } from "./icons/table_search.svg"
import { ReactComponent as TableSearchClearIcon } from "./icons/table_search_clear.svg"

import { Table } from "antd"
import {
  ExpandIconProps,
  HeaderTypesList,
  SearchTableHeaderProps,
  TableComponentProps,
  TableHeaderProps,
  TableHeaderSortProps
} from "./types"
import SearchHeaderComponent from "./SearchHeaderComponent"
import { getLabel } from "./helpers"

import { TablePaginationComponent } from "../Pagination"
import SpinComponent from "../Spin"

import { ReactComponent as ArrowIcon } from "../icons/dropdown_arrow_sochi.svg"
import { ReactComponent as CloseIcon } from "../icons/close.svg"
import { ReactComponent as SortIcon } from "../icons/sort_up_down.svg"
import { SortOrders } from "../Table/types"

export const TableHeader = (props: TableHeaderProps) => {
  const {
    title,
    modalTitle,
    className,
    isActiveField,
    isSort,
    children
  } = props

  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  return (
    <div className={className ? className : ""}>
      <div
        className={`TableHeaderTitle ${
          isActiveField ? `TableHeaderTitle_Active` : ``
        } ${isSort || ``}`}
        onClick={() => setModalVisible(true)}
      >
        {title}
        <SortIcon className={"TableHeaderSortIcon"} />
      </div>
      {isModalVisible && (
        <div className="TableHeaderModal">
          <div className="ModalWrapper">
            <div className="ModalContent">
              <div className="TableHeaderModalTitle">{modalTitle || title}</div>
              {
                <CloseIcon
                  className="TableHeaderModalCloseIcon"
                  onClick={() => setModalVisible(false)}
                />
              }
              {/* Добавим функцию закрытия модального окна в приходящий элемент */}
              {React.cloneElement(children as React.ReactElement, {
                closeFunc: () => setModalVisible(false)
              })}
            </div>
          </div>
          <div
            className="OverlayFilterTable"
            onClick={() => setModalVisible(false)}
          />
        </div>
      )}
    </div>
  )
}

export const TableHeaderSort = (props: TableHeaderSortProps) => {
  const { title, className, isSort, setSort, sort, field } = props

  const triggerSortCb = useCallback(() => {
    if (!sort.order || sort.field !== field) {
      setSort({ field, order: SortOrders.asc })
    } else if (sort.order === SortOrders.asc) {
      setSort({ field, order: SortOrders.desc })
    } else if (sort.order === SortOrders.desc) {
      setSort({ field: undefined, order: undefined })
    }
  }, [sort])

  return (
    <div className={className ? className : ""} onClick={triggerSortCb}>
      <div className={`TableHeaderTitle  ${isSort || ``}`}>
        {title}
        <SortIcon className={"TableHeaderSortIcon"} />
      </div>
    </div>
  )
}

export const SearchTableHeader = ({
  search,
  setSearch,
  title,
  type = HeaderTypesList.InputSearch,
  selectOptions = [],
  headerClass,
  passedClassName,
  popupClassName
}: SearchTableHeaderProps) => {
  const [show, toggleShowSearch] = useState<boolean>(false)
  const [value, setValue] = useState<string | undefined>(search)
  const clearSearch = () => {
    setValue(undefined)
    setSearch(undefined)
    toggleShowSearch(false)
  }

  return (
    <div>
      {show ? (
        <SearchHeaderComponent
          search={search}
          clearSearch={clearSearch}
          value={value}
          setValue={setValue}
          toggleShowSearch={toggleShowSearch}
          setSearch={setSearch}
          title={title}
          type={type}
          selectOptions={selectOptions}
          passedClassName={passedClassName}
          popupClassName={popupClassName}
        />
      ) : (
        <div className="FlexRow">
          <div
            className={`TableSearchTitle ${
              !!search ? `TableSearchTitleActive` : ``
            } ${headerClass || ``}`}
            onClick={() => toggleShowSearch(true)}
          >
            {getLabel({
              type,
              search,
              title,
              selectOptions
            })}
          </div>
          <div
            className={`TableSearchClearIcon ${
              !!search ? `TableSearchClearIconActive` : ``
            }`}
          >
            {search ? (
              <TableSearchClearIcon onClick={clearSearch} />
            ) : (
              <TableSearchIcon onClick={() => toggleShowSearch(true)} />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export const expandIcon = ({
  record,
  expanded,
  onExpand,
  withTotal,
  show
}: ExpandIconProps) => {
  const showExpandIcon = record.date || withTotal || show
  return (
    <div onClick={e => onExpand(record, e)}>
      {showExpandIcon ? (
        <div
          className={`ReportTableArrowItem ${
            expanded ? `TableRowItemUp` : `TableRowItemDown`
          } ${withTotal && !record.date ? `ReportTableTotalArrowItem` : ``}`}
        >
          <ArrowIcon />
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

const TableComponent = ({
  columns,
  data,
  expandable,
  scrollX,
  withoutScroll,
  rowClassName,
  rowClassNameByRecord,
  rowSelection,
  onRow,
  loading,
  onChange,
  tableClassName,
  withTotal,
  withMinHeight,
  width = 0,
  isOrange,
  pagination,
  showHeader = true,
  locale,
  wrapperClassName = "",
  rowKey,
  withoutFiltersHeader,
  ...rest
}: TableComponentProps) => {
  const withPagination = !!pagination
  const scrollProp = !withoutScroll ? { x: scrollX || 0 } : {}
  const isSelected = !!rowSelection

  const [scrollShadow, setScrollShadow] = useState<{
    left: boolean
    right: boolean
  }>({ left: false, right: false })

  const tableRef = useRef(null)

  useEffect(() => {
    const current = tableRef?.current
    if (!!current) {
      const tableScroll = document.getElementsByClassName(`ant-table-content`)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tableScroll[0]?.addEventListener(
        "scroll",
        (event: { target: HTMLInputElement }) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const maxScrollLeft =
            event.target.scrollWidth - event.target.clientWidth
          const scrollValue = event.target.scrollLeft
          if (scrollValue > 0 && scrollValue < maxScrollLeft) {
            setScrollShadow({ left: true, right: true })
          } else if (scrollValue === 0) {
            setScrollShadow({ left: false, right: true })
          } else {
            setScrollShadow({ left: true, right: false })
          }
        }
      )
    }
  }, [tableRef])

  const tableLoading = {
    spinning: !!loading,
    indicator: <SpinComponent color={"@yellow-300"} />
  }

  return (
    <div className={wrapperClassName}>
      <Table
        ref={tableRef}
        pagination={false}
        className={`NewTableComponentWrapper ${
          isSelected ? `TableWithSelected` : ``
        } ${withoutFiltersHeader ? `WithoutFiltersHeader` : ``} ${
          withMinHeight ? `WithMinHeight` : ""
        } ${
          withPagination
            ? `PaginationTableComponentWrapper`
            : `WithoutPaginationTableComponentWrapper`
        } ${isOrange ? `OrangeHeaderTable` : ``} ${tableClassName || ``}`}
        rowClassName={(record: any) => {
          const classByRecord = !!rowClassNameByRecord
            ? rowClassNameByRecord(record)
            : ``
          return `${rowClassName || ``} ${
            withTotal && !record.date ? `PaginationTableComponentTotalRow` : ``
          } ${classByRecord}`
        }}
        columns={columns}
        dataSource={data}
        expandable={expandable}
        scroll={width ? { x: width } : {}}
        {...scrollProp}
        onRow={onRow}
        rowSelection={rowSelection}
        loading={tableLoading}
        onChange={onChange}
        showHeader={!!showHeader}
        locale={locale}
        rowKey={rowKey}
        {...rest}
      />
      {withPagination && (
        <div
          className={`TablePaginationShadowWrapper ${
            scrollShadow.right && scrollShadow.left
              ? `TablePaginationAllShadow`
              : ``
          } ${
            scrollShadow.right && !scrollShadow.left
              ? `TablePaginationRightShadow`
              : ``
          } ${
            scrollShadow.left && !scrollShadow.right
              ? `TablePaginationLeftShadow`
              : ``
          }`}
        >
          <TablePaginationComponent
            value={pagination.value}
            onChange={pagination.onChange}
            total={pagination.total}
          />
        </div>
      )}
    </div>
  )
}

export default TableComponent
