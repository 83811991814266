export const filterConstants = {
  title: `Фильтры`,
  apply: `Применить`,
  periodOptions: [
    { value: `daily`, label: `Дням` },
    { value: `monthly`, label: `Месяцам` },
    { value: `yearly`, label: `Годам` }
  ],
  mskGmt: `3`
}
