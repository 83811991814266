import { ReactElement } from "react"

export enum EmptyTableVersion {
  MOBILE = `mobile`,
  DESKTOP = `desktop`
}

export type EmptyTableProps = {
  [EmptyTableVersion.DESKTOP]?: {
    title: string
    subtitle: string
    icon?: ReactElement
  }
  [EmptyTableVersion.MOBILE]?: {
    title: string
    subtitle: string
    icon?: ReactElement
  }
}

export type EmptyTableDataProps = {
  title?: string
  subtitle?: string
  isSmall?: boolean
  pending?: boolean
  icon?: ReactElement
  titleClass?: string
  className?: string
}
