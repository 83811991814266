import { SortOrders } from "Components/TableNew/types"
import { PaginationValue } from "Components/Pagination/types"
import { Moment } from "moment"

export type PaymentFile = {
  name: string
  url: string
  size: number
  mime_type: string
}

export enum PaymentTableStatus {
  new = `new`,
  accepted = `accepted`,
  paid = `paid`,
  canceled = `canceled`,
  failed = `failed`
}

export enum PaymentTableFields {
  id = `id`,
  created_date = `created_date`,
  status = `status`,
  contractor = `contractor`,
  inn = `inn`,
  sum = `sum`,
  payment_purpose = `payment_purpose`,
  comment = `comment`,
  files = `files`,
  payment_period_type = `payment_period_type`,
  payment_period = `payment_period`
}

export type FilterTableSort = {
  order?: SortOrders
  field?: string
}

export type FilterTable = {
  [PaymentTableFields.contractor]?: string
  [PaymentTableFields.sum]?: {
    from?: string
    to?: string
  }
  [PaymentTableFields.payment_purpose]?: string
  [PaymentTableFields.payment_period]?: {
    from: Moment
    to: Moment
  }
  [PaymentTableFields.created_date]?: {
    from: Moment
    to: Moment
  }
  [PaymentTableFields.status]?: string
  [PaymentTableFields.payment_period_type]?: string
  [PaymentTableFields.id]?: number
  [PaymentTableFields.inn]?: string
  [PaymentTableFields.comment]?: string
  [PaymentTableFields.files]?: PaymentFile[]
  sort: FilterTableSort
}

export type FilterState = {
  table: FilterTable
  pagination: PaginationValue
}

export type PaymentTableData = {
  id: number
  created_date: Moment
  status: string
  contractor: string
  inn: string
  sum: string
  payment_purpose: string
  comment: string
  files: PaymentFile[]
  payment_period_type: Moment
  payment_period: { from: Moment; to: Moment }
}

export type PaymentTableState = {
  total_count: number
  pending: boolean
  data: PaymentTableData[]
}
