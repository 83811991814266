import React, { useMemo } from "react"
import TableComponent from "Components/Table"
import { columns, createText } from "../constants"
import { FreeBetsDetailProps } from "../types"

const GuestBalanceFreeBetsDetail = ({
  state
}: {
  state: FreeBetsDetailProps
}) => {
  const { title, description } = useMemo(
    () =>
      createText({
        sum: state.freeBetSum,
        count: state.freeBetCount,
        hallId: state.hallId,
        walletId: state.walletId
      }),
    []
  )
  return (
    <div className="FreebetsDetailWrapper">
      <div className="FreebetsDetailTitle FlexColumn">
        <span className="HeadlineSecondText">{title}</span>
        <span className="FreeBetsDetailDescription">{description}</span>
      </div>
      <div className="FreebetsDetailTable">
        <TableComponent
          columns={columns}
          loading={state.pending}
          data={state.report}
          scroll={{ y: `calc(100vh - 210px)` }}
          tableClassName="OrangeHeaderTable"
        />
      </div>
    </div>
  )
}

export default GuestBalanceFreeBetsDetail
