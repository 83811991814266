import React, { useEffect, useRef, useState } from "react"

import { ReactComponent as TableSearchIcon } from "./icons/table_search.svg"
import { ReactComponent as TableSearchClearIcon } from "./icons/table_search_clear.svg"

import { Table } from "antd"
import {
  HeaderTypesList,
  SearchTableHeaderProps,
  SortForTable,
  SortOrder,
  SortOrders,
  TableComponentProps
} from "./types"
import SearchHeaderComponent from "./SearchHeaderComponent"
import { getLabel } from "./helpers"
import { SortOrdersPassed } from "../TableNew/types"

export const SearchTableHeader = (props: SearchTableHeaderProps) => {
  const {
    search,
    setSearch,
    title,
    type = HeaderTypesList.InputSearch,
    selectOptions = [],
    headerClass,
    popupClassName,
    passedClassName
  } = props

  const [show, toggleShowSearch] = useState<boolean>(false)
  const [value, setValue] = useState<string | undefined>(search)
  const clearSearch = () => {
    setValue(undefined)
    setSearch(undefined)
    toggleShowSearch(false)
  }

  return (
    <div>
      {show ? (
        <SearchHeaderComponent
          search={search}
          clearSearch={clearSearch}
          value={value}
          setValue={setValue}
          toggleShowSearch={toggleShowSearch}
          setSearch={setSearch}
          title={title}
          type={type}
          selectOptions={selectOptions}
          popupClassName={popupClassName}
          passedClassName={passedClassName}
        />
      ) : (
        <div className="FlexRow">
          <div
            className={`TableSearchTitle ${
              !!search ? `TableSearchTitleActive` : ``
            } ${headerClass || ``}`}
            onClick={() => toggleShowSearch(true)}
          >
            {getLabel({
              type,
              search,
              title,
              selectOptions
            })}
          </div>
          <div
            className={`TableSearchClearIcon ${
              !!search ? `TableSearchClearIconActive` : ``
            }`}
          >
            {search ? (
              <TableSearchClearIcon onClick={clearSearch} />
            ) : (
              <TableSearchIcon onClick={() => toggleShowSearch(true)} />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const TableComponent = ({
  columns,
  data,
  expandable,
  scrollX,
  scroll,
  withoutScroll,
  rowClassName,
  rowSelection,
  onRow,
  loading,
  onChange,
  tableClassName
}: TableComponentProps) => {
  const topScroll = useRef(null)

  useEffect(() => {
    const tableScroll = document.getElementsByClassName(`ant-table-wrapper`)
    if (!!topScroll.current && !!tableScroll && !!tableScroll[0]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      topScroll.current.addEventListener(
        "scroll",
        (event: { target: HTMLInputElement }) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tableScroll[0].scrollTo({ left: event.target.scrollLeft })
        }
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tableScroll[0].addEventListener(
        "scroll",
        (event: { target: HTMLInputElement }) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          topScroll.current.scrollTo({ left: event.target.scrollLeft })
        }
      )
    }
  }, [topScroll])
  const scrollProp = !withoutScroll ? { x: scrollX || 0 } : {}
  return (
    <div className={`TableColumnWrapper ${tableClassName || ""}`}>
      {!!scrollX && (
        <div ref={topScroll} className="TableComponentTopScrollWrapper">
          {/* long text for reveal scroll to the world! */}
          <div
            style={{ width: scrollX - 300 }}
            className="TableComponentTopScroll"
          >
            ________________________________________ __________________________
            ___________________ ________________________________________
          </div>
        </div>
      )}
      <Table
        className="TableComponentWrapper"
        pagination={false}
        columns={columns}
        dataSource={data}
        expandable={expandable}
        scroll={scroll}
        {...scrollProp}
        onRow={onRow}
        rowClassName={rowClassName}
        rowSelection={rowSelection}
        loading={loading}
        onChange={onChange}
      />
    </div>
  )
}

export default TableComponent

export const sortForTable = ({ sorter, setSort }: SortForTable) => {
  let sortOrder: SortOrder
  switch (sorter.order) {
    case SortOrders.asc:
    case SortOrdersPassed.ascend:
      sortOrder = SortOrders.asc
      break
    case SortOrders.desc:
    case SortOrdersPassed.descend:
      sortOrder = SortOrders.desc
      break
    default:
      sortOrder = undefined
  }
  const sortData = {
    field: sorter.field || undefined,
    order: sortOrder || undefined
  }
  setSort(sortData)
}
