import React from "react"

import { formatToCurrency } from "constants/index"

import { BetsData } from "./types"

import { ReactComponent as MoneyIcon } from "../icons/money.svg"
import { ReactComponent as TimeIcon } from "../icons/time.svg"

import moment from "moment"

export const getConstants = (data: BetsData) => {
  const title = data.total_count
    ? `Всего ${data.total_count} ставок  `
    : `Нет ставок`
  const descriptionSum = !!data.total_count ? (
    <div className="FlexRow">
      <MoneyIcon />
      <span>{`Общая сумма — ${formatToCurrency({
        amount: data.total,
        isDash: false
      })} ₽`}</span>
    </div>
  ) : (
    <div />
  )
  const descriptionHours = Math.floor(data.total_time / 60)
  const descriptionMinutes = data.total_time - descriptionHours * 60
  const descriptionTime = !!data.total_count ? (
    <div className="FlexRow">
      <TimeIcon />
      <span>{`Время в игре — ${
        descriptionHours ? `${descriptionHours} часов` : ``
      } ${descriptionMinutes} минут`}</span>
    </div>
  ) : (
    <div />
  )

  return {
    title,
    descriptionSum,
    descriptionTime,
    exelUrl: `v1/guests/history/bets/excel`,
    emptyTable: {
      title: `Нет данных`,
      subtitle: `В этом клубе и за указанный период \nгость не делал ставок `
    }
  }
}

export const initialData = {
  total: 0,
  total_time: 0,
  total_count: 0,
  data: []
}

const tableColumnText = {
  start: {
    label: `Дата и время`,
    key: `start`,
    format: `DD.MM.YYYY`,
    time: `HH:mm:ss`
  },
  game: {
    label: `Название игры`,
    key: `game`
  },
  game_number: {
    label: `№ игры`,
    key: `game_number`
  },
  id: {
    label: `№ ставки`,
    key: `id`
  },
  sum: {
    label: `Номинал, ₽`,
    key: `sum`
  },
  win: {
    label: `Выигрыш, ₽`,
    key: `win`
  },
  bonus: {
    label: `Бонусы/Фрибеты`,
    key: `bonus`,
    pos: `Да`,
    neg: `Нет`
  },
  drop_order: {
    label: `Порядок выпадения`,
    key: `drop_order`
  }
}

export const getColumns = (withFreebets: boolean) => {
  const freebetColumn = withFreebets
    ? [
        {
          title: () => (
            <div className="TableHeaderWithoutSpacing">
              {tableColumnText.bonus.label}
            </div>
          ),
          width: 200,
          dataIndex: tableColumnText.bonus.key,
          key: tableColumnText.bonus.key,
          render: (bonus: boolean) => (
            <span className="">
              {bonus ? tableColumnText.bonus.pos : tableColumnText.bonus.neg}
            </span>
          )
        }
      ]
    : []
  return [
    {
      title: () => (
        <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
          {tableColumnText.start.label}
        </div>
      ),
      dataIndex: tableColumnText.start.key,
      key: tableColumnText.start.key,
      width: 220,
      render: (start: string, record: { end: string }) => (
        <div className="TableHeaderFirstTh">
          <div className="FlexRow">
            <span className="TextDefault">
              {moment(start).format(tableColumnText.start.format)}
            </span>
            <span className="TextDefault TableTimeDescription">
              {moment(start).format(tableColumnText.start.time)}
            </span>
          </div>
          <div className="FlexRow">
            <span className="TextDefault">
              {moment(record.end).format(tableColumnText.start.format)}
            </span>
            <span className="TextDefault TableTimeDescription">
              {moment(record.end).format(tableColumnText.start.time)}
            </span>
          </div>
        </div>
      )
    },
    {
      title: () => (
        <div className="TableHeaderWithoutSpacing">
          {tableColumnText.game.label}
        </div>
      ),
      width: 200,
      dataIndex: tableColumnText.game.key,
      key: tableColumnText.game.key
    },
    {
      title: () => (
        <div className="TableHeaderWithoutSpacing">
          {tableColumnText.game_number.label}
        </div>
      ),
      width: 200,
      dataIndex: tableColumnText.game_number.key,
      key: tableColumnText.game_number.key
    },
    {
      title: () => (
        <div className="TableHeaderWithoutSpacing">
          {tableColumnText.id.label}
        </div>
      ),
      width: 200,
      dataIndex: tableColumnText.id.key,
      key: tableColumnText.id.key
    },
    {
      title: () => (
        <div className="TableHeaderWithoutSpacing">
          {tableColumnText.sum.label}
        </div>
      ),
      width: 200,
      dataIndex: tableColumnText.sum.key,
      key: tableColumnText.sum.key,
      render: (sum: string) => (
        <span className="">
          {formatToCurrency({ amount: Number(sum), withoutSpace: true })}
        </span>
      )
    },
    {
      title: () => (
        <div className="TableHeaderWithoutSpacing">
          {tableColumnText.win.label}
        </div>
      ),
      width: 200,
      dataIndex: tableColumnText.win.key,
      key: tableColumnText.win.key,
      render: (sum: string) => (
        <span className="">
          {formatToCurrency({ amount: Number(sum), withoutSpace: true })}
        </span>
      )
    },
    ...freebetColumn,
    {
      title: tableColumnText.drop_order.label,
      width: 200,
      dataIndex: tableColumnText.drop_order.key,
      key: tableColumnText.drop_order.key,
      render: (drop_order: string) => <span>{drop_order}</span>
    }
  ]
}
