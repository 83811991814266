import { NotifyModalState, NotifyRightModalProps } from "../types"

export interface UserCrudProps extends NotifyRightModalProps {
  modalData: NotifyModalState
  back: () => void
}

export enum UserCrudFields {
  name = `name`,
  email = `email`,
  halls = `halls`
}

export interface UserFields {
  [UserCrudFields.name]?: string | null
  [UserCrudFields.email]?: string | null
  [UserCrudFields.halls]: number[]
}
