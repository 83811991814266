import React, { useEffect, useState } from "react"
import {
  contactsPageProps,
  ContactsStaffModalProps
} from "Containers/Contacts/types"
import { createInitStaffState, staffRequest } from "Containers/Contacts/helpers"
import { ReactComponent as ReturnIcon } from "Components/icons/chevron_left_outline_24.svg"
import { ReactComponent as CloseIcon } from "Components/icons/close_icon.svg"
import InputComponent, { InputTextAreaComponent } from "Components/Input"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logoutUser } from "store/auth/actions"
import { CheckBoxComponent } from "Components/CheckBox"
import { selectHoursOptions } from "./selectHoursOptions"
import { Select } from "antd"
import { ReactComponent as ClockIcon } from "Components/SelectHour/clock_select.svg"
import {
  addedPhone1Title,
  addedPhone2Title,
  addedPhonePlaceholder,
  addStaffMsg,
  editStaffMsg,
  emailPlaceholder,
  emailTitle,
  phone1Title,
  phone2Title,
  phonePlaceholder,
  staffName,
  staffNamePlaceholder,
  staffPosition,
  staffPositionPlaceholder,
  telegramName,
  telegramPlaceholder,
  whatsAppName,
  workHoursPlaceholder,
  workHoursTitle
} from "../../constants"
import { formatPhone } from "constants/index"

const ContactsStaffModal = ({
  contactsState,
  setContactsState
}: contactsPageProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const logout: () => void = () => dispatch(logoutUser())

  /* Редактируем или создаем нового сотрудника */
  const isStaffEdit = !!contactsState.editStaffId

  const [staffState, setStaffState] = useState<ContactsStaffModalProps>(
    createInitStaffState(contactsState)
  )

  useEffect(() => {
    if (
      !contactsState.staffPending ||
      !contactsState.isStaffEditing ||
      !contactsState.activeModal
    )
      return

    staffRequest({
      contactsState,
      setContactsState,
      staffState,
      logout,
      history
    })
  }, [
    contactsState.staffPending,
    contactsState.isStaffEditing,
    contactsState.activeModal
  ])

  const prepareTime = (office_hours: string | null) => {
    if (!office_hours) return null
    const [startHour, finishHour] = office_hours.split(",")
    return {
      startHour,
      finishHour
    }
  }

  const [showTime, setShowTime] = useState(!!staffState.office_hours)

  return (
    <div className="ContactsEdit">
      <div className="ContactsEdit_top">
        <div className="ContactsEdit_header ContactsEdit_header_arrBack">
          <div className="ContactsEdit_MainTitleWrapper">
            <div
              className="ContactsEdit_returnIcon"
              onClick={() => {
                setContactsState({
                  ...contactsState,
                  isStaffEditing: false,
                  isBlockEditing: true,
                  editStaffId: null,
                  sectionErrors: []
                })
              }}
            >
              <ReturnIcon />
            </div>
            <div className="ContactsEdit_MainTitleWrapper">
              {contactsState.editStaffId ? editStaffMsg : addStaffMsg}
            </div>
            <div className="ContactsEdit_closeIcon">
              <CloseIcon
                onClick={() => {
                  setContactsState({
                    ...contactsState,
                    activeModal: false,
                    editStaffId: null,
                    sectionErrors: []
                  })
                }}
              />
            </div>
          </div>
        </div>
        <div className="ContactsEdit_name">
          <div className="ContactsEdit_fieldname">{staffName}</div>
          <InputComponent
            greyBackground
            className="ContactsEdit_nameInput"
            placeholder={staffNamePlaceholder}
            value={staffState.name}
            onChange={e =>
              setStaffState({
                ...staffState,
                name: e.target.value
              })
            }
          />
        </div>
        <div className="ContactsEdit_position">
          <div className="ContactsEdit_fieldname">{staffPosition}</div>
          <InputTextAreaComponent
            greyBackground
            className="ContactsEdit_positionInput"
            placeholder={staffPositionPlaceholder}
            value={staffState.position}
            onChange={e =>
              setStaffState({
                ...staffState,
                position: e.target.value
              })
            }
          />
        </div>
        <div className="ContactsEdit_inputsColumnWrapper">
          <div className="ContactsEdit_inputsColumn">
            <div className="ContactsEdit_phone1">
              <div className="ContactsEdit_fieldname">{phone1Title}</div>
              <div className="ContactsEdit_phoneWrapper">
                <InputComponent
                  greyBackground
                  className="ContactsEdit_phone1Input"
                  placeholder={phonePlaceholder}
                  value={formatPhone(staffState.phone)}
                  onChange={e =>
                    setStaffState({
                      ...staffState,
                      phone: formatPhone(e.target.value)
                    })
                  }
                />
              </div>
            </div>
            <div className="ContactsEdit_phone2">
              <div className="ContactsEdit_fieldname">{phone2Title}</div>
              <div className="ContactsEdit_phoneWrapper">
                <InputComponent
                  greyBackground
                  className="ContactsEdit_phone2Input"
                  placeholder={phonePlaceholder}
                  value={formatPhone(staffState.phone2)}
                  onChange={e =>
                    setStaffState({
                      ...staffState,
                      phone2: formatPhone(e.target.value)
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="ContactsEdit_inputsColumn">
            <div className="ContactsEdit_addPhone1">
              <div className="ContactsEdit_fieldname">{addedPhone1Title}</div>
              <InputComponent
                greyBackground
                className="ContactsEdit_addPhone1Input"
                placeholder={addedPhonePlaceholder}
                value={staffState.extension_number}
                onChange={e =>
                  setStaffState({
                    ...staffState,
                    extension_number: e.target.value
                  })
                }
              />
            </div>
            <div className="ContactsEdit_phone2">
              <div className="ContactsEdit_fieldname">{addedPhone2Title}</div>
              <InputComponent
                greyBackground
                className="ContactsEdit_phone2Input"
                placeholder={addedPhonePlaceholder}
                value={staffState.extension_number2}
                onChange={e =>
                  setStaffState({
                    ...staffState,
                    extension_number2: e.target.value
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="ContactsEdit_telegram">
          <div className="ContactsEdit_fieldname">{telegramName}</div>
          <InputComponent
            greyBackground
            className="ContactsEdit_telegramInput"
            placeholder={telegramPlaceholder}
            value={staffState.telegram}
            onChange={e =>
              setStaffState({
                ...staffState,
                telegram: e.target.value
              })
            }
          />
        </div>
        <div className="ContactsEdit_email">
          <div className="ContactsEdit_fieldname">{emailTitle}</div>
          <InputComponent
            greyBackground
            className="ContactsEdit_email"
            placeholder={emailPlaceholder}
            value={staffState.email}
            onChange={e =>
              setStaffState({
                ...staffState,
                email: e.target.value
              })
            }
          />
        </div>
        <div className="ContactsEdit_whatsapp">
          <div className="ContactsEdit_fieldname">{whatsAppName}</div>
          <div className="ContactsEdit_phoneWrapper">
            <InputComponent
              greyBackground
              className="ContactsEdit_phone2Input"
              placeholder={phonePlaceholder}
              value={formatPhone(staffState.whatsapp)}
              onChange={e =>
                setStaffState({
                  ...staffState,
                  whatsapp: formatPhone(e.target.value)
                })
              }
            />
          </div>
        </div>
        <div className="ContactsEdit_hours">
          <CheckBoxComponent
            className="ContactsEdit_AnchorCheckbox"
            checked={showTime}
            onChange={e => {
              setShowTime(e.target.checked)
              if (!e.target.checked) {
                setStaffState({
                  ...staffState,
                  office_hours: ""
                })
              }

              if (e.target.checked && !staffState.office_hours) {
                setStaffState({
                  ...staffState,
                  office_hours: workHoursTitle
                })
              }

              if (!e.target.checked && !staffState.office_hours) {
                setStaffState({
                  ...staffState,
                  office_hours: ""
                })
              }
            }}
          >
            {workHoursPlaceholder}
          </CheckBoxComponent>
          {showTime && (
            <div className="ContactsEdit_hours">
              <div className="SelectHoursWrapper FlexRow">
                <Select
                  className="HourSelector"
                  dropdownClassName="HourSelectorDropdownWrapper"
                  options={selectHoursOptions()}
                  value={prepareTime(staffState.office_hours)?.startHour}
                  onChange={value => {
                    setStaffState({
                      ...staffState,
                      office_hours: `${value},${
                        staffState.office_hours.split(",")[1]
                      }`
                    })
                  }}
                  suffixIcon={<ClockIcon />}
                />
                <Select
                  className="HourSelector"
                  dropdownClassName="HourSelectorDropdownWrapper"
                  options={selectHoursOptions()}
                  value={prepareTime(staffState.office_hours)?.finishHour}
                  onChange={value => {
                    setStaffState({
                      ...staffState,
                      office_hours: `${
                        staffState.office_hours.split(",")[0]
                      },${value}`
                    })
                  }}
                  suffixIcon={<ClockIcon />}
                />
              </div>
            </div>
          )}
        </div>
        <div className="ContactsEdit_error">
          {contactsState.staffErrors.join("<br />")}
        </div>
      </div>
      <div className="ContactsEdit_btns">
        <ButtonPrimary
          className="ContactsEdit_AddBtn ContactsBtn"
          disabled={false}
          onClick={() => {
            setContactsState({
              ...contactsState,
              staffPending: true,
              url: isStaffEdit
                ? `v1/admin/contacts/stuff/${contactsState.editStaffId}`
                : `v1/admin/contacts/stuff`,
              method: isStaffEdit ? `PUT` : `POST`
            })
          }}
        >
          {isStaffEdit ? "Сохранить" : "Добавить"}
        </ButtonPrimary>
        <ButtonDefault
          className="ContactsStadffEdit_CancelBtn ContactsBtn"
          disabled={false}
          onClick={() => {
            setContactsState({
              ...contactsState,
              activeModal: false,
              editBlockId: null,
              editStaffId: null,
              isBlockEditing: false,
              sectionErrors: []
            })
          }}
        >
          Отмена
        </ButtonDefault>
      </div>
    </div>
  )
}

export default ContactsStaffModal
