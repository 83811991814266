import React, { useCallback, useState, useMemo } from "react"

import { useRequestBody, UseRequestBodySort } from "api/hooks"

import { getDateDescriptionLayout, isMobile } from "constants/index"

import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import NotActiveFilter from "Components/NotActiveFilter"

import { FilterRangeFields, GuestFiltersState } from "./types"
import {
  disableDates,
  getInitialFilter,
  guestFiltersText,
  checkFilterForDisabled,
  GuestTotalTitle
} from "./constants"

import CustomFilters from "./CustomFIlters"
import FiltersTable from "./Table"

import { ReactComponent as NotActiveIcon } from "./Table/NotActive.svg"
import { baseFields } from "./Table/constants"

const GuestFilters = () => {
  const initialFilter = useMemo(() => getInitialFilter(), [])

  const [filterState, setFilterState] = useState<GuestFiltersState>(
    initialFilter
  )
  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  const setFilterSortCb = useCallback(
    (newSort: UseRequestBodySort) => {
      setFilterState({ ...filterState, sort: newSort, isOpen: false })
    },
    [filterState]
  )

  const disabledFilter = (filter: any): boolean =>
    checkFilterForDisabled(filter)

  const { pagination, setPagination, resetPagination } = useRequestBody()

  const [total, setTotal] = useState(0)
  const titleDescription = useMemo(() => {
    if (!!filterState.data.dates) {
      return getDateDescriptionLayout({
        dates: filterState.data.dates,
        shift: [8, 20],
        isShift: true,
        show: filterState.isActive,
        hidePeriodTitle: isMobile,
        calendarIcon: isMobile
      })
    }
    return ``
  }, [filterState.data.dates])
  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={guestFiltersText.filterName}
            filterState={filterState}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setFilterState={(newFilter: GuestFiltersState) => {
              if (
                !filterState.sort?.field ||
                filterState.sort.field in baseFields
              ) {
                setFilterStateCb(newFilter)
              }
              if (filterState.sort.field) {
                const field = filterState.sort.field as FilterRangeFields

                if (
                  (field === FilterRangeFields.bonus_bets_sum &&
                    newFilter.data.sportboom) ||
                  (field === FilterRangeFields.freebet_bets_sum &&
                    !newFilter.data.sportboom)
                ) {
                  setFilterStateCb({ ...newFilter, sort: initialFilter.sort })
                } else if (newFilter.data[field]?.enabled) {
                  setFilterStateCb(newFilter)
                } else {
                  setFilterStateCb({ ...newFilter, sort: initialFilter.sort })
                }
              }
            }}
            resetPagination={resetPagination}
            filters={[Filters.InfoPanel, Filters.customItem]}
            getDisabled={filter => disabledFilter(filter)}
            disabledHalls={true}
            infoPanelConfig={{ clearRange: true }}
            periodOptions={guestFiltersText.infoFilter.dates.periodOptions}
            CustomComponent={CustomFilters}
            disabledRangeDates={disableDates}
            rangeTooltip={guestFiltersText.infoFilter.dates.tooltip}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <div>
            <Title
              titleText={guestFiltersText.title}
              tooltipText={guestFiltersText.description}
              bottomAdditionalCmp={
                !!titleDescription ? (
                  <div
                    className={`${
                      isMobile ? `FlexColumn` : `FlexRow`
                    } PaddingTop4`}
                  >
                    <div>{titleDescription}</div>
                    <GuestTotalTitle total={total} />
                  </div>
                ) : (
                  <div />
                )
              }
            />
            {!filterState.isActive ? (
              <NotActiveFilter
                icon={<NotActiveIcon />}
                title={guestFiltersText.notActive.title}
                description={guestFiltersText.notActive.description}
              />
            ) : (
              <FiltersTable
                filter={filterState.data}
                setTotal={setTotal}
                pagination={pagination}
                setPagination={setPagination}
                sort={filterState.sort}
                setSort={setFilterSortCb}
              />
            )}
          </div>
        }
      />
    </>
  )
}

export default GuestFilters
