import React, { useEffect, useMemo, useRef, useState } from "react"

import { ReactComponent as MoreIcon } from "Components/icons/more_28.svg"

const calculatePosition = (elem: HTMLDivElement | null, width: number) => {
  const position = elem?.getBoundingClientRect()

  if (!!position) {
    return {
      top: position.top - position.height + 60,
      left: position.left - width / 2
    }
  }
  return {
    display: `none`
  }
}

const TableActions = ({
  children,
  width
}: {
  children: JSX.Element
  width: number
}) => {
  const [showActions, setShowActions] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const moreRef = useRef<HTMLDivElement>(null)

  /* Для клика вне элемента, чтобы закрыть меню с выбором действий */

  useEffect(() => {
    const checkIfClickedOutside = ({ target }: MouseEvent) => {
      if (
        showActions &&
        ref &&
        ref.current &&
        !ref.current.contains(target as Node)
      ) {
        setShowActions(false)
      }
    }
    document.addEventListener("click", checkIfClickedOutside)
    return () => {
      document.removeEventListener("click", checkIfClickedOutside)
    }
  }, [showActions])

  const morePosition = useMemo(
    () => calculatePosition(moreRef?.current, width),
    [moreRef, showActions]
  )

  return (
    <div className="TableActionsModalTrigger">
      <div
        ref={moreRef}
        onClick={() => setShowActions(true)}
        className={`MarginLeftAuto ${
          showActions ? `TableActionsModalTriggerActive` : ``
        }`}
      >
        <MoreIcon />
      </div>
      <div
        ref={ref}
        className={`TableActionsModal ${
          showActions ? `TableActionsModalActive` : `TableActionsModalHidden`
        }`}
        style={{ ...morePosition, width: `${width}px` }}
      >
        {children}
      </div>
    </div>
  )
}

export default TableActions
