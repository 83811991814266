export const text = {
  title: "Сводный отчёт",
  tooltip:
    "Отчет по денежному обороту клуба. Содержит в себе информацию по играм и ставкам на спорт, а также детализацию по денежным и бонусным начислениям в клубе.",
  filter: {
    title:
      "Чтобы получить отчёт по денежному обороту, вам необходимо выбрать нужные параметры фильтрации",
    description:
      "Отчёт по денежному обороту клуба. Содержит в себе информацию по играм и ставкам на спорт, а также детализацию по денежным и бонусным начислениям в клубе."
  },
  total: "Итого"
}
