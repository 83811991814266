import React, { useEffect, useState } from "react"
import { ButtonNotifi, ButtonPrimary } from "Components/Button"
import { ReactComponent as DeleteWhiteIcon } from "Components/icons/delete_white.svg"
import { ReactComponent as DeleteLargeIcon } from "Components/icons/delete_large.svg"
import { ReactComponent as SuccessLargeIcon } from "Components/icons/success_large.svg"
import betRequest from "api/request"
import { useHistory } from "react-router-dom"
import { logoutUser } from "store/auth/actions"
import { useDispatch, useSelector } from "react-redux"
import { IDevelopState } from "store/developer/types"
import { getDeveloperList } from "store/developer/selector"
import { addLink } from "store/developer/actions"

type DeleteModalProps = {
  type: string
  deleteData: number[] | string
  successDelete: () => void
  failedDelete: () => void
}

type ResurrectModalProps = {
  type: string
  resurrectId: number[]
  successResurrect: () => void
  failedResurrect: () => void
}

const DeleteModal = ({
  type,
  deleteData,
  successDelete,
  failedDelete
}: DeleteModalProps) => {
  const history = useHistory()
  const logout: () => void = () => dispatch(logoutUser())
  const dispatch = useDispatch()
  const developer: IDevelopState = useSelector(getDeveloperList)
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))

  const isAll = deleteData === `all`

  const draftText = `Сообщения будут перемещены в корзину и не будут отображаться в списке черновиков`
  const listText = `Сообщения будут перемещены в корзину и не будут отображаться у партнёра`
  const basketText = isAll
    ? `Очистка корзины приведёт к безвозвратному удалению всех сообщений`
    : `Вы хотите удалить выбранные сообщения безвозвратно?`

  const [deletePending, setDeletePending] = useState<{
    pending: boolean
    success: boolean
  }>({
    pending: false,
    success: false
  })

  let deleteUrl = `admin/message/delete`

  switch (type) {
    case `list`:
      deleteUrl = `admin/message/delete`
      break
    case `basket`:
      {
        if (isAll) {
          deleteUrl = `admin/message/basket/delete/all`
        } else {
          deleteUrl = `admin/message/basket/delete`
        }
      }
      break
    case `draft`:
      deleteUrl = `admin/message/draft/delete`
      break
  }

  const deleteNotification = () => {
    setDeletePending({ success: false, pending: true })
    betRequest({
      url: deleteUrl,
      requestBody: isAll ? {} : { id: deleteData },
      logout,
      history,
      addDeveloperLink,
      developer,
      method: isAll ? `DELETE` : `POST`
    })
      .then(() => {
        setDeletePending({ success: true, pending: false })
      })
      .catch(() => {
        setDeletePending({ success: false, pending: false })
      })
  }

  useEffect(() => {
    let waitForIt
    if (deletePending.success) {
      clearTimeout(waitForIt)
      waitForIt = setTimeout(() => successDelete(), 2000)
    }
  }, [deletePending.success])

  return (
    <div className="NotifiDeleteModal">
      {deletePending.success ? (
        <div className="NotifiDeleteModalSuccessWrapper FlexColumn">
          {basketText ? (
            <div className="NotifiDeleteModalSuccessTitle">
              Сообщения удалены
            </div>
          ) : (
            <div className="NotifiDeleteModalSuccessTitle">
              Сообщения перемещены <br /> в корзину
            </div>
          )}
          <div className="NotifiDeleteModalSuccessIcon">
            <DeleteLargeIcon />
          </div>
        </div>
      ) : (
        <div className="NotifiDeleteModalWrapper">
          <div className="NotifiDeleteModalTitle">Удалить сообщения?</div>
          <div className="NotifiDeleteModalText">
            {type === `draft` ? draftText : ``}
            {type === `list` || type === `outgoing` ? listText : ``}
            {type === `basket` ? basketText : ``}
          </div>
          <div className="NotifiDeleteModalButtons FlexRow">
            <div className="NotifiDeleteModalButtonsFirst">
              <ButtonNotifi
                disabled={deletePending.pending}
                onClick={failedDelete}
              >
                <div>
                  <span>Отмена</span>
                </div>
              </ButtonNotifi>
            </div>
            <ButtonNotifi
              onClick={() => {
                deleteNotification()
              }}
              color="red"
              disabled={deletePending.pending}
            >
              <div className="FlexRow">
                <DeleteWhiteIcon />
                <span>Удалить</span>
              </div>
            </ButtonNotifi>
          </div>
        </div>
      )}
      <div className="Overlay NotifiCrudModalDeletedOverlay" />
    </div>
  )
}

export default DeleteModal

export const ResurrectModal = ({
  resurrectId,
  successResurrect,
  failedResurrect
}: ResurrectModalProps) => {
  const history = useHistory()
  const logout: () => void = () => dispatch(logoutUser())
  const dispatch = useDispatch()
  const developer: IDevelopState = useSelector(getDeveloperList)
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))

  const [resurrectPending, setResurrectPending] = useState<{
    pending: boolean
    success: boolean
  }>({
    pending: false,
    success: false
  })

  const resurrectNotification = () => {
    setResurrectPending({ success: false, pending: true })
    betRequest({
      url: `admin/message/basket/resurrect`,
      requestBody: { id: resurrectId },
      logout,
      history,
      addDeveloperLink,
      developer,
      method: `POST`
    })
      .then(() => {
        setResurrectPending({ success: true, pending: false })
      })
      .catch(() => {
        setResurrectPending({ success: false, pending: false })
      })
  }

  useEffect(() => {
    let waitForIt
    if (resurrectPending.success) {
      clearTimeout(waitForIt)
      waitForIt = setTimeout(() => successResurrect(), 2000)
    }
  }, [resurrectPending.success])

  return (
    <div className="NotifiDeleteModal">
      {resurrectPending.success ? (
        <div className="NotifiDeleteModalSuccessWrapper FlexColumn">
          <div className="NotifiDeleteModalSuccessTitle">
            Сообщение успешно <br />
            восстановленно
          </div>
          <div className="NotifiDeleteModalSuccessIcon">
            <SuccessLargeIcon />
          </div>
        </div>
      ) : (
        <div className="NotifiDeleteModalWrapper">
          <div className="NotifiDeleteModalTitle">Внимание!</div>
          <div className="NotifiDeleteModalText" style={{ width: `390px` }}>
            Сообщение будет восстановленно в общий список со статусом при
            удалении и будет снова доступно выбранным клубам
          </div>
          <div className="NotifiDeleteModalButtons FlexRow">
            <div className="NotifiDeleteModalButtonsFirst">
              <ButtonNotifi
                disabled={resurrectPending.pending}
                onClick={failedResurrect}
              >
                <div>
                  <span>Отмена</span>
                </div>
              </ButtonNotifi>
            </div>
            <ButtonPrimary
              onClick={() => {
                resurrectNotification()
              }}
              disabled={resurrectPending.pending}
            >
              Восстановить
            </ButtonPrimary>
          </div>
        </div>
      )}
      <div className="Overlay NotifiCrudModalDeletedOverlay" />
    </div>
  )
}
