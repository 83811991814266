import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import getMenu, { getDictionary } from "./helper"
import { redirectByHalls } from "api/role"

import { Input } from "antd"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"
import { ReactComponent as HallLogout } from "Components/icons/arrow_back.svg"

import { getHallsListFromStore } from "store/halls/selector"
import { setCurrentHall } from "store/halls/actions"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { IDevelopState } from "store/developer/types"
import { getDeveloperList } from "store/developer/selector"
import { addLink } from "store/developer/actions"
import { storeMenuData } from "store/menu/actions"
import { storeDictionaryData } from "store/dictionary/actions"
import { IMenuState } from "store/menu/types"
import { logoutUser } from "store/auth/actions"
import { IDictionaryState } from "store/dictionary/types"

const HallPickerPage = () => {
  const history = useHistory()
  const halls = useSelector(getHallsListFromStore)
  const developer: IDevelopState = useSelector(getDeveloperList)
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))
  const logout: () => void = () => dispatch(logoutUser())
  const dispatch = useDispatch()
  const setCurrentHalls: (
    data: (string | CheckboxValueType)[]
  ) => void = data => dispatch(setCurrentHall(data))
  const storeMenu: (data: IMenuState) => void = data =>
    dispatch(storeMenuData(data))
  const storeDictionary: (data: IDictionaryState) => void = data =>
    dispatch(storeDictionaryData(data))

  const [search, setSearch] = useState<string | undefined>(undefined)
  const [pending, setPending] = useState<boolean>(false)

  const filteredHallsList = !!search
    ? halls.filter(item => `${item}`.indexOf(search) >= 0)
    : halls

  return (
    <div className="HallPickerPageWrapper">
      <div className="HallPickerPageContainer">
        <div className="FlexRow FlexWrap">
          <div className="HallPickerPageTitle FlexRow">
            <div className="HallPickerLogout" onClick={logout}>
              <HallLogout />
            </div>
            <span>Доступные клубы:</span>
          </div>
          <div className="HallPickerPageSearchWrapper">
            <Input
              value={search}
              prefix={<SearchIcon />}
              placeholder="Поиск"
              onChange={({ target: { value } }) => setSearch(`${value}`)}
            />
          </div>
        </div>
        <div className="HallPickerPageContent FlexRow FlexWrap">
          {filteredHallsList.map((item: string) => (
            <div
              key={`hall_item_${item}`}
              className={`HallPickerPageItem FlexRow ${
                pending ? `HallPickerModalDisabledItem` : ``
              }`}
              onClick={() => {
                if (!pending)
                  getDictionary({
                    history,
                    developer,
                    addDeveloperLink,
                    logout,
                    hall: item,
                    storeDictionary,
                    setPending
                  }).then(() =>
                    getMenu({
                      history,
                      developer,
                      addDeveloperLink,
                      logout,
                      hall: item,
                      storeMenu,
                      setPending
                    }).then(() => {
                      localStorage.setItem(`hall`, `[\"${item}\"]`)
                      setCurrentHalls([`${item}`])
                      redirectByHalls(`${item}`)
                    })
                  )
              }}
            >
              <span className="HallPickerModalItemDescription">№</span>
              <span className="HallPickerModalItemLabel">{item}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HallPickerPage
