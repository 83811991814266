import { FilterType } from "../types"

export enum FilterPeriod {
  month = `month`,
  date = `date`
}

export const formatByPeriod = {
  [FilterPeriod.date]: `DD.MM.YYYY`,
  [FilterPeriod.month]: `MMMM`
}

export const typeOptions = [
  { label: "По клубу", value: FilterType.hall },
  { label: "По гостю", value: FilterType.guest }
]

export const filterText = {
  type: `Тип отчёта`,
  typeOptions: [
    { label: "По клубу", value: FilterType.hall },
    { label: "По гостю", value: FilterType.guest }
  ],
  period: `Информация по`,
  periodOptions: [
    { label: "Дням", value: FilterPeriod.date },
    { label: "Месяцам", value: FilterPeriod.month }
  ],
  dates: `Период отчёта`
}
