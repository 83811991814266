import React, { useCallback, useEffect, useMemo, useState } from "react"

import { noop } from "lodash"

import { useRequest, useRequestParams } from "api/hooks"

import ReportWithFilter from "Components/ReportWithFilter"
import { SideFilter } from "Components/SideFilter/SideFilter"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import NotActiveFilter from "Components/NotActiveFilter"

import {
  reportText,
  getInitialFilter,
  initialFilterValues,
  getDescriptionByType,
  reportTypes
} from "./constants"
import { ReportHallData, ReportHallFilter } from "./types"

import HallReportFilter from "./Filters"
import ReportHallPDF from "./Pdf"

const ReportHall = () => {
  const { current_hall } = useRequestParams()
  const initialFilter: ReportHallFilter = useMemo(
    () => getInitialFilter(`${current_hall[0]}`),
    []
  )
  const [filterState, setFilterState] = useState<ReportHallFilter>(
    initialFilter
  )

  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  const { request: getFile } = useRequest({
    url: `v1/reports/hall`,
    requestBody: {
      filter: {
        ...filterState.data
      }
    }
  })

  const [data, setData] = useState<ReportHallData>({
    file: undefined,
    pending: false
  })

  useEffect(() => {
    if (filterState.data.period) {
      setData({ ...data, pending: true })
      getFile()
        .then(({ data }) => {
          setData({ file: data.file, pending: false })
        })
        .catch(() => setData({ ...data, pending: false }))
    }
  }, [filterState.data])

  const period_description = useMemo(() => {
    if (!!filterState.data.period) {
      return getDescriptionByType({
        period: filterState.data.period,
        period_type: filterState.data.period_type,
        onlyMonth:
          filterState.data.report_type === reportTypes.balance ||
          filterState.data.report_type === reportTypes.acquiring
      })
    }
  }, [filterState.data.period])

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={reportText.filter.store_name}
            filterState={filterState}
            initialForClear={{
              ...initialFilterValues,
              halls: [`${current_hall}`]
            }}
            setFilterState={setFilterStateCb}
            resetPagination={noop}
            filters={[Filters.customItem]}
            CustomComponent={HallReportFilter}
            getDisabled={filter => !filter?.period}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <>
            {filterState.isActive && (
              <div className="FlexColumn Gap16">
                <Title
                  titleText={reportText.title}
                  tooltipText={reportText.tooltip}
                  bottomAdditionalCmp={
                    <div className="ReportHallTitleTextDescription FlexRow Gap8">
                      <span className="TextDefault Gray600Color">
                        {reportText.period}
                      </span>
                      <span className="TextDefault Shark500Color">
                        {period_description}
                      </span>
                    </div>
                  }
                />
                <ReportHallPDF file={data.file} pending={data.pending} />
              </div>
            )}
            {!filterState.isActive && (
              <NotActiveFilter
                title={reportText.notActiveFilter.title}
                description={reportText.notActiveFilter.description}
                icon={reportText.notActiveFilter.icon}
              />
            )}
          </>
        }
        modalCmp={<div />}
      />
    </>
  )
}

export default ReportHall
