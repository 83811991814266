import React from "react"
import { useSelector } from "react-redux"

import { getMenuListByKeys } from "store/access/reducer"

import { menuConstants } from "./constants"

import { ReactComponent as RemoveIcon } from "Components/icons/clear_circle.svg"

const MenuTags = ({
  menu_items,
  required_items,
  removeMenuItem
}: {
  menu_items: string[]
  required_items: string[]
  removeMenuItem: (val: string) => void
}) => {
  const menu = useSelector(getMenuListByKeys)
  return (
    <div className="AccessCrudMenuTags">
      <div className="SubtitleFirstText MarginBottom6">
        {menuConstants.tags}
      </div>
      <div className="CrudAccessMenuWrapper Padding16 Gap8 FlexRow FlexWrap">
        {menu_items.map(item => {
          const currentMenuItem = menu[item]
          if (!currentMenuItem?.name) {
            return <div key={`MenuTagsError_${item}`} className="HideBlock" />
          }
          const isDefault = required_items.includes(currentMenuItem.name)
          return (
            <div
              key={`access_tag_${currentMenuItem.name}`}
              className="AccessMenuTagsWrapper FlexRow Gap8"
            >
              <div className="TextOne MenuTagsItemLabel">
                {currentMenuItem.label}
              </div>
              {!isDefault && (
                <div
                  className="MarginTop2 HoverItem"
                  onClick={() => {
                    removeMenuItem(item)
                  }}
                >
                  <RemoveIcon />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MenuTags
