import React from "react"
import {
  balanceColumn,
  balanceOperationText,
  BALANCE_OPERATION_FILTER_NAME,
  BALANCE_OPERATION_URL_EXCEL,
  BALANCE_OPERATION_URL
} from "./helper"
import ReportLego from "../ReportLego"

const BalanceOperation = () => {
  return (
    <div>
      <ReportLego
        report={{
          url: BALANCE_OPERATION_URL,
          tableScroll: 1200,
          getColumn: balanceColumn
        }}
        exelConfig={{
          url: BALANCE_OPERATION_URL_EXCEL,
          title: `balanceOperationText.title`
        }}
        title={balanceOperationText.title}
        emptyFilterTitle={balanceOperationText.filterTitle}
        emptyFilterDescription={balanceOperationText.filterDescription}
        filterName={BALANCE_OPERATION_FILTER_NAME}
        titleInfo={balanceOperationText.tooltip}
      />
    </div>
  )
}

export default BalanceOperation
