import { ReactElement } from "react"

export type ReportLegoReportType = any

export type ReportLegoQiwiType = {
  hall: number
  value: number
}

export type ReportDataState = {
  data: {
    report: ReportLegoReportType[]
    qiwi_balance: {
      sum: string
      data: ReportLegoQiwiType[]
    }
    total_sum: string
  }
  total: number
  pending: boolean
}

export const initialReportData: ReportDataState = {
  data: {
    report: [],
    qiwi_balance: {
      sum: `0`,
      data: []
    },
    total_sum: `0`
  },
  total: 0,
  pending: false
}

type ReportLegoExelConfig = {
  url: string
  title: string
}

export type QiwiType = {
  hall: number
  value: number
}

export interface ReportLegoModalProps {
  modalData: any
  tableScroll: any
  toggleModal: any
  statuses: any
}

type ReportLegoProps = {
  title: string
  titleInfo: string
  exelConfig?: ReportLegoExelConfig
  report: {
    url: string
    tableScroll: number
    getColumn: (
      field: string | undefined,
      search: { [key: string]: any },
      setSearch: (value: string | undefined, id: string) => void
    ) => any[]
  }
  filterName: string
  emptyFilterTitle: string
  emptyFilterDescription: string
  modalCmp?: ({
    modalData,
    tableScroll,
    toggleModal
  }: ReportLegoModalProps) => ReactElement
  disableLastRowClick?: boolean
  withTotal?: boolean
}

export default ReportLegoProps
