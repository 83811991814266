import React from "react"

import { Moment } from "moment"
import { GamesKeys } from "store/dictionary/types"

import SliderComponent from "Components/Slider"
import { RadioGroupComponent } from "Components/Radio"
import DatePickerComponent from "Components/DatePicker"
import GamesList from "Components/SideFilter/Components/Games"

import { radioFilterOptions, text } from "./constants"
import { CustomFilterProps } from "./types"
import { InputRangeComponent } from "./helpers"

export const CustomFilter: React.FC<CustomFilterProps> = ({
  filter,
  setFilter
}) => {
  return (
    <div className="HistoryBetsCustomFilter">
      <div className="SideFilterField">
        <div className="SideFilterContentLabel">{text.filter.shift}</div>
        <SliderComponent
          min={0}
          max={23}
          defaultValue={filter.shift}
          onChange={e => {
            setFilter(prev => ({
              ...prev,
              shift: e
            }))
          }}
        />
      </div>
      <div className="SideFilterField">
        <div className="SideFilterContentLabel">{text.filter.win}</div>
        <RadioGroupComponent
          options={radioFilterOptions}
          value={filter.win_type}
          onChange={e => {
            setFilter(prev => ({
              ...prev,
              win_type: e.target.value
            }))
          }}
        />
        {filter.win_type === radioFilterOptions[2].value && (
          <InputRangeComponent
            valueFrom={filter.win_sum?.from}
            valueTo={filter.win_sum?.to}
            prefixFrom={text.from}
            prefixTo={text.to}
            onChangeFrom={x => {
              setFilter(prev => ({
                ...prev,
                win_sum: {
                  ...prev.win_sum,
                  from: x
                }
              }))
            }}
            onChangeTo={x => {
              setFilter(prev => ({
                ...prev,
                win_sum: {
                  ...prev.win_sum,
                  to: x
                }
              }))
            }}
            className="HistoryCustomFilter_RangeWin"
          />
        )}
      </div>
      <div className="SideFilterField">
        <div className="SideFilterContentLabel">{text.filter.rangeBet}</div>
        <InputRangeComponent
          valueFrom={filter.bet_sum?.from}
          valueTo={filter.bet_sum?.to}
          prefixFrom={text.from}
          prefixTo={text.to}
          onChangeFrom={x => {
            setFilter(prev => ({
              ...prev,
              bet_sum: {
                ...prev.bet_sum,
                from: x
              }
            }))
          }}
          onChangeTo={x => {
            setFilter(prev => ({
              ...prev,
              bet_sum: {
                ...prev.bet_sum,
                to: x
              }
            }))
          }}
        />
      </div>
      <div className="SideFilterField">
        <div className="SideFilterContentLabel">{text.filter.games}</div>
        <GamesList
          filterGames={filter.game_ids || []}
          setFilterGames={x => {
            setFilter(prev => ({ ...prev, game_ids: x.map(Number) }))
          }}
          customFilter={GamesKeys.is_game_bets_history}
        />
      </div>
    </div>
  )
}

export const CustomFilterAdditional: React.FC<CustomFilterProps> = ({
  filter,
  setFilter
}) => {
  return (
    <div className="SideFilterField">
      <div className="SideFilterContentLabel">{text.filter.date}</div>
      <DatePickerComponent
        value={filter.bets_date}
        placeholder={text.filter.datePlaceholder}
        onChange={x => {
          setFilter(prev => ({ ...prev, bets_date: x as Moment }))
        }}
      />
    </div>
  )
}
