import React from "react"

import PromotionBonusesLego from "../Lego"

import { config } from "./constants"

const BetBonus = () => {
  return <PromotionBonusesLego config={config} />
}

export default BetBonus
