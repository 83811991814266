import React, { useEffect, useMemo, useState } from "react"
import { ReactComponent as CloseIcon } from "Components/icons/close-big.svg"
import { Modal } from "antd"
import { ViewPenaltyModalProps } from "./types"
import { userPenaltyCardText } from "../UserPenaltyCard/helper"
import { StatusLabel } from "../helper"
import moment from "moment-business-days"
import { ReactComponent as ArrowLeftIcon } from "Components/icons/arrow_left.svg"
import { proposalModalText } from "../../../Proposal/ProposalModal/helper"
import { ViewPenaltyModalContent } from "./ViewPenaltyModalContent"
import { countBusinessDaysDiff, PenaltyStatuses } from "../../helpers"
import { viewPenaltyModalText } from "./helper"
import { moneyRender } from "constants/index"

//TODO добавить праздники
moment.updateLocale("ru", {
  workingWeekdays: [1, 2, 3, 4, 5]
})

export const ViewPenaltyModal: React.FC<ViewPenaltyModalProps> = ({
  closeModal,
  visible,
  viewModalData,
  statuses,
  setAppealModal,
  winDict,
  isMobile,
  appealModal,
  appealable
}) => {
  const [finalPenaltyValue, setFinalPenaltyValue] = useState("")
  const [finalWinValue, setFinalWinValue] = useState("")
  const [disabled, setDisabled] = useState(false)

  const daysCountText = useMemo(() => {
    if (viewModalData?.setDate) {
      const { isBefore, businessDiffText } = countBusinessDaysDiff(
        viewModalData.setDate,
        14
      )
      setDisabled(isBefore)
      return businessDiffText
    }
  }, [viewModalData?.setDate])

  useEffect(() => {
    let final = viewModalData?.penaltyValue
    let result
    let finalWin = viewModalData?.win
    if (viewModalData?.status === PenaltyStatuses.reviewed) {
      finalWin = viewModalData?.winResolved
      final = viewModalData?.penaltyValueResolved
    }
    let resultWin
    if (!final) {
      result = userPenaltyCardText.warning
    } else {
      result = `${viewPenaltyModalText.title} на ${moneyRender(final)} ${
        userPenaltyCardText.rubles
      }`
    }

    if (finalWin !== null) {
      const foundedWin = winDict.find(x => x.id == finalWin)?.value
      if (foundedWin) {
        resultWin = `${userPenaltyCardText.winExist} ${foundedWin}`
      } else {
        resultWin = `${userPenaltyCardText.winExist} ${finalWin}`
      }
    } else {
      resultWin = userPenaltyCardText.winNull
    }
    setFinalPenaltyValue(result)
    setFinalWinValue(resultWin)
  }, [viewModalData])

  const StatusCmp = useMemo(
    () => <StatusLabel statuses={statuses} id={viewModalData?.status} />,
    [statuses, viewModalData?.status]
  )

  return isMobile ? (
    <>
      {visible && !appealModal && (
        <div className="ProposalModalContentMobileView">
          <div
            onClick={() => {
              closeModal()
            }}
            className="ProposalModalContentMobileViewBack"
          >
            <ArrowLeftIcon />
            {proposalModalText.back}
          </div>
          <ViewPenaltyModalContent
            finalPenaltyValue={finalPenaltyValue}
            finalWinValue={finalWinValue}
            viewModalData={viewModalData}
            canAppeal={appealable}
            disabled={disabled}
            daysCountText={daysCountText}
            setAppealModal={setAppealModal}
            isMobile={isMobile}
            status={StatusCmp}
          />
        </div>
      )}
    </>
  ) : (
    <>
      <Modal
        className="SupportProposalModal"
        visible={visible}
        footer={null}
        closeIcon={
          <CloseIcon
            className="ProposalModalCloseIcon"
            onClick={() => {
              closeModal()
            }}
          />
        }
        maskClosable={true}
        width={678}
        zIndex={50}
      >
        <ViewPenaltyModalContent
          finalPenaltyValue={finalPenaltyValue}
          finalWinValue={finalWinValue}
          viewModalData={viewModalData}
          canAppeal={appealable}
          disabled={disabled}
          daysCountText={daysCountText}
          setAppealModal={setAppealModal}
          isMobile={isMobile}
          status={StatusCmp}
        />
      </Modal>
    </>
  )
}
