import React from "react"
import { ByGameOrReg, ByTypeSelectionProps } from "Containers/Visit/types"
import { byGameTypes, byRegTypes } from "Containers/Visit/constants"

const ByTypeSelection = ({
  filterState,
  setFilterState,
  onlyReg
}: ByTypeSelectionProps) => {
  const selectTypes = onlyReg ? byRegTypes : byGameTypes
  return (
    <div className="VisitsStatisticTypesWrapper StatisticTypesWrapper FlexRow">
      {selectTypes.map((item: ByGameOrReg) => {
        const isActive = item.name === filterState.data.type
        return (
          <div
            key={`${item.name}_type`}
            className={`VisitsStatisticTypesItem StatisticTypesItem GridCenter ${
              isActive
                ? ``
                : `VisitsStatisticTypesItemHovered StatisticTypesItemHovered`
            }`}
            style={{
              border: `1px solid ${isActive ? item.color : `#DEE2E6`}`,
              backgroundColor: `${isActive ? item.bg : `transparent`}`
            }}
            onClick={() => {
              if (!isActive)
                setFilterState({
                  ...filterState,
                  data: { ...filterState.data, type: item.name }
                })
            }}
          >
            <div className="TypeItemTopWrapper FlexRow">
              <div
                className="TypeItemMarkerWrapper"
                style={{ backgroundColor: item.color }}
              />
              <span className="TypeItemTitle">{item.title}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ByTypeSelection
