import React, { useEffect } from "react"
import { Form, Modal } from "antd"
import { FormItemNames, formRules } from "../AddEditPenaltyModal/helper"
import { InputTextAreaComponent } from "Components/Input"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { EditCancelRejectFinalModalProps } from "./types"
import { addEditModalText } from "../../constants"

export const EditCancelRejectFinalModal: React.FC<EditCancelRejectFinalModalProps> = ({
  edit,
  cancel,
  reject,
  setEditFinalModalData,
  setCancelFinalModalData,
  setRejectFinalModalData,
  closeCancelFinalModal,
  closeEditFinalModal,
  closeRejectFinalModal,
  id,
  loading
}) => {
  const [form] = Form.useForm()

  const setter =
    (edit && setEditFinalModalData) ||
    (cancel && setCancelFinalModalData) ||
    (reject && setRejectFinalModalData)

  const onFinish = (val: { comment: string }) => {
    setter && setter(val.comment)
  }

  const title =
    (edit && addEditModalText.editTitle) ||
    (cancel && addEditModalText.cancelTitle) ||
    (reject && addEditModalText.rejectTitle)

  const closeModal =
    (edit && closeEditFinalModal) ||
    (cancel && closeCancelFinalModal) ||
    (reject && closeRejectFinalModal)

  useEffect(() => {
    if (!edit) {
      setEditFinalModalData("")
    }
    if (!cancel) {
      setCancelFinalModalData("")
    }
    if (!reject) {
      setRejectFinalModalData("")
    }
    if (!edit && !cancel && !reject) {
      form.resetFields()
    }
  }, [edit, cancel, reject])

  return (
    <Modal
      className="SupportProposalModal"
      visible={edit || cancel || reject}
      footer={null}
      width={678}
      closeIcon={<></>}
      zIndex={10000}
    >
      <>
        <div className="ProposalModalTitleWrapper">
          <div className="ProposalModalTextTitleText">{`${title} #${id}`}</div>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name={FormItemNames.comment}
            label={
              <div className="ProposalModalSubTitleWrapper">
                {addEditModalText.comment}
              </div>
            }
            rules={formRules.textarea}
          >
            <InputTextAreaComponent
              className="ProposalModalTextTextArea"
              placeholder={addEditModalText.commentPlaceholder}
              autoSize={{ minRows: 10, maxRows: 10 }}
              greyBackground
            />
          </Form.Item>
          <div className="ProposalModalButtonsGroup EditCancelRejectFinalModalButtonsGroup">
            <Form.Item>
              <ButtonPrimary
                loading={loading}
                htmlType="submit"
                className={
                  cancel || reject
                    ? "EditCancelRejectFinalModalButton__red"
                    : ""
                }
              >
                {addEditModalText.sendButton}
              </ButtonPrimary>
            </Form.Item>
            <ButtonDefault onClick={() => closeModal && closeModal()}>
              {addEditModalText.back}
            </ButtonDefault>
          </div>
        </Form>
      </>
    </Modal>
  )
}
