import { valueToCurrency } from "constants/index"

import { SuperwinItemData } from "../../types"

export const mobileListText = {
  hall: `№`,
  getZone: (is_vip: boolean) => (is_vip ? `VIP зона` : `Общая зона`)
}

export const getMobileListItemText = (row: SuperwinItemData) => {
  const nominal = valueToCurrency({
    value: Number(row.nominal),
    withoutFixed: true,
    withoutZero: true,
    withoutSpace: true
  })
  const value = valueToCurrency({
    value: Number(row.current_sum)
  })
  return {
    nominal: `${nominal} ₽`,
    current: `${value} ₽`
  }
}
