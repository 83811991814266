import React from "react"

import { isMobile } from "constants/index"

import TooltipComponent from "Components/Tooltip"

import { ReactComponent as NegativeIcon } from "Components/icons/cancel.svg"
import { ReactComponent as PositiveIcon } from "Components/icons/check/check_20.svg"
import { ReactComponent as LockIcon } from "Components/icons/lock/lock_outline_20.svg"
import { ReactComponent as DeleteIcon } from "Components/icons/crud/delete_outline_20.svg"
import { ReactComponent as WriteIcon } from "Components/icons/write/write_outline_20.svg"

import { SportsBroadcastData } from "../types"

export const tableText = {
  empty: `Здесь будет список подключенных трансляций`,
  tv: {
    title: `Телевизор`,
    title_mobile: `Теле-\nвизор`,
    field: `tv`,
    show_field: `sequence_number`
  },
  receiver_number: {
    title: `№ ресивера`,
    field: `receiver_number`
  },
  is_base_plus: {
    title: `«Базовый + Суперспорт. \nБукмекер»`,
    title_mobile: `«Базовый + Супер-\nспорт. Букмекер»`,
    field: `is_base_plus`,
    getText: (is_base_plus: boolean) => (is_base_plus ? `Да` : `Нет`),
    getIcon: (is_base_plus: boolean) =>
      is_base_plus ? <PositiveIcon /> : <NegativeIcon />
  },
  cart_number: {
    title: `№ карты`,
    field: `cart_number`
  },
  can_edit: {
    title: ``,
    field: `can_edit`,
    tooltip_lock: `Редактировать запись нельзя`
  }
}

export const removeModal = {
  title: `Удалить трансляцию?`,
  description: `Вы уверены что хотите удалить спортивную трансляцию из клуба?`,
  approve: `Да, удалить`,
  cancel: `Отмена`
}

export const getColumns = ({
  toggleEdit,
  toggleDelete
}: {
  toggleDelete: (tv: number) => void
  toggleEdit: (data: SportsBroadcastData) => void
}) => {
  return [
    {
      title: () => (
        <div className="MarginLeft20">
          {isMobile ? tableText.tv.title_mobile : tableText.tv.title}
        </div>
      ),
      field: tableText.tv.show_field,
      key: tableText.tv.show_field,
      dataIndex: tableText.tv.show_field,
      render: (sequence_number: number) => (
        <div className="MarginLeft20 TextOne">{sequence_number}</div>
      )
    },
    {
      title: tableText.receiver_number.title,
      field: tableText.receiver_number.field,
      key: tableText.receiver_number.field,
      dataIndex: tableText.receiver_number.field
    },
    {
      title: tableText.cart_number.title,
      field: tableText.cart_number.field,
      key: tableText.cart_number.field,
      dataIndex: tableText.cart_number.field
    },
    {
      title: () => (
        <span className="TableHeaderTitle">
          {isMobile
            ? tableText.is_base_plus.title_mobile
            : tableText.is_base_plus.title}
        </span>
      ),
      field: tableText.is_base_plus.field,
      key: tableText.is_base_plus.field,
      dataIndex: tableText.is_base_plus.field,
      render: (is_base_plus: boolean) => {
        const icon = tableText.is_base_plus.getIcon(is_base_plus)
        const text = tableText.is_base_plus.getText(is_base_plus)
        return (
          <div className="FlexRow Gap8">
            <div className="MarginTop2">{icon}</div>
            <span className="TextOne">{text}</span>
          </div>
        )
      }
    },
    {
      title: tableText.can_edit.title,
      field: tableText.can_edit.field,
      key: tableText.can_edit.field,
      dataIndex: tableText.can_edit.field,
      width: 80,
      render: (can_edit: boolean, record: SportsBroadcastData) => {
        return can_edit ? (
          <div className="FlexRow Gap32 MarginRight24">
            <div onClick={() => toggleEdit(record)} className="HoverItem">
              <WriteIcon />
            </div>
            <div onClick={() => toggleDelete(record.tv)} className="HoverItem">
              <DeleteIcon />
            </div>
          </div>
        ) : (
          <div className="SportsBroadcast__Table__Cell__Lock MarginRight24">
            <TooltipComponent
              title={tableText.can_edit.tooltip_lock}
              direction="top"
            >
              <LockIcon />
            </TooltipComponent>
          </div>
        )
      }
    }
  ]
}
