import React from "react"

import PromotionBonusesLego from "../Lego"

import { config } from "./constants"

const BirthdayBonus = () => {
  return <PromotionBonusesLego config={config} />
}

export default BirthdayBonus
