import React from "react"
import {
  formatZendeskId,
  ProposalNullishZendeskId,
  ProposalPopover,
  StatusLabel
} from "../helper"
import { proposalModalText } from "../ProposalModal/helper"
import DateTime from "Components/DateTime"
import { ReactComponent as AttachIcon } from "Components/icons/attach_grey.svg"
import { ReactComponent as MessageIcon } from "Components/icons/message.svg"
import { ProposalMobileCardProps } from "./types"

const ProposalMobileCard: React.FC<ProposalMobileCardProps> = ({
  proposal,
  statuses,
  setCancelId,
  duplicate,
  onClick
}) => {
  return (
    <div className="ProposalMobileCard" onClick={onClick}>
      <div className="ProposalMobileCardTitleWrapper">
        <div className="ProposalMobileCardTitle">
          <div className="ProposalMobileCardTitleText">
            {proposalModalText.currentTitle}
          </div>
          {proposal?.zendeskId ? (
            formatZendeskId(proposal.zendeskId)
          ) : (
            <ProposalNullishZendeskId />
          )}
        </div>
        <div className="ProposalMobileCardTitleDate">
          <DateTime date={proposal.date} />
        </div>
      </div>
      <div className="ProposalMobileCardText">{proposal.text}</div>
      <div className="ProposalMobileCardFooter">
        <div className="ProposalMobileCardStatus">
          <StatusLabel statuses={statuses} id={proposal.status} />
        </div>
        {!!proposal.details.supportMessages.length && (
          <div className="ProposalMobileCardElement">
            <div className="ProposalMobileCardIcon">
              <MessageIcon height={16} width={16} />
            </div>
            <div className="ProposalMobileCardCounter">
              {proposal.details.supportMessages.length}
            </div>
          </div>
        )}
        {!!proposal.details.attachments.length && (
          <div className="ProposalMobileCardElement">
            <div className="ProposalMobileCardIcon">
              <AttachIcon height={16} width={16} />
            </div>
            <div className="ProposalMobileCardCounter">
              {proposal.details.attachments.length}
            </div>
          </div>
        )}
        <div className="ProposalMobileCardElement ProposalMobileCardElementDetails">
          <div className="ProposalMobileCardIcon">
            <ProposalPopover
              setCancelId={setCancelId}
              duplicate={duplicate}
              proposal={proposal}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProposalMobileCard
