import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { Form } from "antd"

import { getBranchHallsList } from "store/dictionary/selector"

import { useRequestParams } from "api/hooks"
import betRequest from "api/request"

import { isMobile } from "constants/index"

import { ModalFormComponent } from "Components/Modal"
import InputComponent, { InputMaskComponent } from "Components/Input"
import { HallsMultiSelect, SelectComponent } from "Components/Select"
import { ButtonPrimary } from "Components/Button"
import { rules } from "Components/Form/rules"

import { ReactComponent as SuccessThumb } from "Components/icons/illustrations/thumb_up.svg"

import { CrudModalProps } from "./types"
import {
  getInitialValues,
  hallsTypeOptions,
  modalConst,
  orderOption,
  prepareDataToSave,
  QiwiModalFields
} from "./constants"

import { QiwiTableFields, QiwiTableTypes } from "../Table/types"
import { checkHallCrunch } from "../constants"

const QiwiModal = ({ initial, close, refreshTable }: CrudModalProps) => {
  const [form] = Form.useForm()
  const [successModal, toggleSuccess] = useState(false)
  const [pending, setPending] = useState(false)
  const {
    developer,
    addDeveloperLink,
    history,
    logout,
    current_hall_number
  } = useRequestParams()
  const branchList = useSelector(getBranchHallsList)
  const isBranchHall = useMemo(() => !!branchList.length, [
    current_hall_number,
    branchList
  ])

  const showNew = checkHallCrunch(current_hall_number[0])

  const initialValues = useMemo(() => {
    return getInitialValues(current_hall_number)
  }, [])
  const onFinish = (values: any) => {
    setPending(true)
    const requestBody = prepareDataToSave(values)
    betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      method: `POST`,
      url: modalConst.saveUrl,
      requestBody
    })
      .then(() => {
        setPending(false)
        toggleSuccess(true)
      })
      .catch(() => setPending(false))
  }

  const initialHalls = (initial
    ? initial[QiwiModalFields.halls]
    : initialValues[QiwiModalFields.halls]) as number[]

  const [halls, setHalls] = useState<number[]>(initialHalls)
  useEffect(() => {
    if (!!initial && halls) {
      const setHall = halls[0]
      form.setFieldValue(
        `hallSum-${setHall}`,
        `${initial[QiwiTableFields.form_sum]}`
      )
      form.setFieldValue(
        `hallComment-${setHall}`,
        initial[QiwiTableFields.comment]
      )
      form.setFieldValue(`hallType-${setHall}`, initial[QiwiTableFields.type])
    }
  }, [])
  const [sum, setSum] = useState<
    { [key: number]: string | undefined } | undefined
  >(
    initial && initialHalls
      ? {
          [initialHalls[0]]: `${initial[QiwiTableFields.form_sum]}`
        }
      : undefined
  )

  const [hallTypes, setTypes] = useState<{ [key: number]: string } | undefined>(
    initial && initialHalls
      ? {
          [initialHalls[0]]: initial[QiwiTableFields.type]
        }
      : undefined
  )

  return !successModal ? (
    <ModalFormComponent width={800} close={close} withBigCross={true}>
      <div className="QiwiModalWrapper FlexColumn Gap24">
        <span className="HeadlineFirstText">{modalConst.title}</span>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initial || initialValues}
          autoComplete="off"
        >
          <Form.Item
            name={QiwiModalFields.name}
            label={
              <span className="SubtitleFirstText">
                {modalConst[QiwiModalFields.name].label}
              </span>
            }
            rules={[...rules.required, ...rules.max_length_100, ...rules.name]}
          >
            <InputComponent
              maxLength={100}
              placeholder={modalConst[QiwiModalFields.name].placeholder}
            />
          </Form.Item>
          <div
            className={`${
              isMobile ? `FlexColumn` : `FlexRow Gap24`
            } QiwiModalFieldsRow`}
          >
            <Form.Item
              name={QiwiModalFields.phone}
              label={
                <span className="SubtitleFirstText">
                  {modalConst[QiwiModalFields.phone].label}
                </span>
              }
              rules={rules.required_phone_mask}
            >
              <InputMaskComponent
                mask={"+7 000 000-00-00"}
                value={form.getFieldValue(QiwiModalFields.phone)}
                allowClear={true}
                onChange={x => {
                  form.setFieldValue(
                    QiwiModalFields.phone,
                    `7${x.unmaskedValue}`
                  )
                }}
              />
            </Form.Item>
            <Form.Item
              name={QiwiModalFields.email}
              label={
                <span className="SubtitleFirstText">
                  {modalConst[QiwiModalFields.email].label}
                </span>
              }
              rules={rules.required_email}
            >
              <InputComponent
                placeholder={modalConst[QiwiModalFields.email].placeholder}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={QiwiModalFields.halls}
            label={
              <span className="SubtitleFirstText">
                {modalConst[QiwiModalFields.halls].label}
              </span>
            }
            rules={rules.required_array}
          >
            <HallsMultiSelect
              placeholder={modalConst[QiwiModalFields.halls].placeholder}
              onChange={(value: any) => setHalls(value)}
            />
          </Form.Item>
          <div className="FlexColumn Gap24">
            {halls?.map(item => {
              const isCurrentHallBrunch = branchList.includes(item)
              const isAcquiring =
                hallTypes &&
                hallTypes[item] &&
                hallTypes[item] === QiwiTableTypes.order

              const isBranch = isBranchHall && isCurrentHallBrunch

              const newBranchOptions = showNew
                ? [...hallsTypeOptions, orderOption]
                : [hallsTypeOptions[0], orderOption]
              const newNoBranchOptions = showNew ? hallsTypeOptions : []

              const selectOptions = isBranch
                ? newBranchOptions
                : newNoBranchOptions
              const haveComment = isBranchHall && isAcquiring
              return (
                <div
                  key={`qiwi_modal_hall${item}`}
                  className="QiwiModalHallsItemWrapper FlexRow Gap24"
                >
                  <div className="FlexColumn Gap8">
                    <span className="SubtitleFirstText">
                      {modalConst[QiwiModalFields.sum].hall}
                    </span>
                    <span className="HeadlineThirdText">{item}</span>
                  </div>
                  <div className="FlexColumn FullSizeWidth">
                    <div
                      className={`${
                        isMobile ? `FlexColumn` : `FlexRow Gap24`
                      } QiwiModalHallsItemRow`}
                    >
                      {(showNew || isBranch) && (
                        <div className="FlexColumn Gap4">
                          <span className="SubtitleFirstText">
                            {modalConst[QiwiModalFields.sum].type}
                          </span>
                          <Form.Item
                            name={`hallType-${item}`}
                            rules={rules.required}
                            className="Margin16ErrorZone"
                          >
                            <SelectComponent
                              className="QiwiModalHallsItemRowSelect"
                              placeholder={
                                modalConst[QiwiModalFields.sum].type_placeholder
                              }
                              options={selectOptions}
                              onChange={value => {
                                form.setFieldValue(`hallType-${item}`, value)
                                form.setFieldValue(
                                  `hallComment-${item}`,
                                  undefined
                                )
                                if (!!value) {
                                  setTypes({ ...hallTypes, [item]: value })
                                }
                              }}
                              allowClear={false}
                            />
                          </Form.Item>
                        </div>
                      )}
                      <div
                        className={`FlexColumn Gap4 ${
                          !(showNew || isBranch) ? `` : `QiwiModalHallsItemRow`
                        }`}
                      >
                        <span className="SubtitleFirstText">
                          {modalConst[QiwiModalFields.sum].label}
                        </span>
                        <Form.Item
                          name={`hallSum-${item}`}
                          rules={[...rules.required, ...rules.comma_number]}
                          className={`FlexColumn  ${
                            !haveComment
                              ? `Margin16ErrorZone`
                              : `Margin16BottomZone`
                          }`}
                        >
                          <InputComponent
                            placeholder={
                              modalConst[QiwiModalFields.sum].placeholder
                            }
                            value={sum ? sum[item] : undefined}
                            onChange={({ target: { value } }) => {
                              form.setFieldValue(`hallSum-${item}`, value)
                              setSum({ ...sum, [item]: value || undefined })
                            }}
                          />
                          {!!sum && !isAcquiring && (
                            <div className="PaddingTop4 Orange600Color QiwiModalHallsItemSumCommission">
                              {modalConst[QiwiModalFields.sum].commission(
                                sum[item]
                              )}
                            </div>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                    {haveComment && (
                      <div className="FlexColumn Gap4">
                        <span className="SubtitleFirstText">
                          {modalConst[QiwiModalFields.comment].label}
                        </span>
                        <Form.Item
                          name={`hallComment-${item}`}
                          rules={rules.required}
                          className="FlexColumn Margin16ErrorZone"
                        >
                          <InputComponent
                            placeholder={
                              modalConst[QiwiModalFields.comment].placeholder
                            }
                            onChange={({ target: { value } }) => {
                              form.setFieldValue(`hallComment-${item}`, value)
                            }}
                          />
                        </Form.Item>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="FlexRow Gap4 MarginLeftAuto PaddingTop24">
            <ButtonPrimary htmlType="submit" disabled={pending}>
              {modalConst.save}
            </ButtonPrimary>
          </div>
        </Form>
      </div>
    </ModalFormComponent>
  ) : (
    <ModalFormComponent width={800}>
      <div className="FlexColumn Gap24 PaddingTop16">
        <div className="GridCenter HeadlineFirstText">
          {modalConst.success.title}
        </div>
        <div className="GridCenter">
          <SuccessThumb />
        </div>
        <div className="GridCenter PaddingBottom16">
          <ButtonPrimary
            onClick={() => {
              refreshTable()
              close()
            }}
          >
            {modalConst.success.close}
          </ButtonPrimary>
        </div>
      </div>
    </ModalFormComponent>
  )
}

export default QiwiModal
