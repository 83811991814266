import React from "react"

import { SuperWinTableProps } from "./types"
import {
  getSuperwinTableValue,
  superwinTableText,
  superwinText
} from "./constants"

import { ReactComponent as VipZoneIcon } from "./icons/crown_outline_24.svg"
import { ReactComponent as MainZoneIcon } from "./icons/club_outline_24.svg"
import { ReactComponent as FaceIcon } from "./icons/face_value.svg"
import { ReactComponent as BackIcon } from "./icons/back_value.svg"

export const SuperWinTable = ({ data, isVip }: SuperWinTableProps) => {
  return (
    <div className="MainSuperTableHeaderWrapper">
      <div className="MainSuperTableWrapper FlexRow">
        <div className="MainSuperTableRow">
          {isVip ? (
            <div className="FlexRow Gap8 MainSuperTableHeaderCell MainSuperTableHeaderVip MainSuperTableLeftCell">
              <VipZoneIcon />
              <span className="TextDefaultBold">
                {superwinText.item.table.vip}
              </span>
            </div>
          ) : (
            <div className="FlexRow Gap8 MainSuperTableHeaderCell MainSuperTableLeftCell">
              <MainZoneIcon />
              <span className="TextDefaultBold">
                {superwinText.item.table.main}
              </span>
            </div>
          )}
          <div className="FlexRow Gap8 MainSuperTableFirstCell MainSuperTableCellBordered MainSuperTableLeftCell">
            <FaceIcon />
            <span className="TextDefaultBold">
              {superwinText.item.table.rowOne}
            </span>
          </div>
          <div className="FlexRow Gap8 TextDefaultBold MainSuperTableFirstCell MainSuperTableLeftCell">
            <BackIcon />
            <span className="TextDefaultBold">
              {superwinText.item.table.rowTwo}
            </span>
          </div>
        </div>
        {data &&
          data.map(item => {
            const { sum_per, sum_back, nominal } = getSuperwinTableValue(item)
            return (
              <div
                key={`ticket_value_${item.price}`}
                className="MainSuperTableRow"
              >
                <div
                  className={`MainSuperTableCell MainSuperTableHeaderCell ${
                    isVip ? `MainSuperTableHeaderVip` : ``
                  } TableHeader`}
                >
                  {nominal}
                </div>
                <div className="MainSuperTableFirstCell MainSuperTableCellBordered">
                  {sum_per}
                </div>
                <div className="MainSuperTableFirstCell">{sum_back}</div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export const SuperWinMobileTable = ({ data, isVip }: SuperWinTableProps) => {
  return (
    <div className="MainSuperTableMobileWrapper">
      <div
        className={`MainSuperTableMobileTitle GridCenter Padding12 ${
          isVip
            ? `MainSuperTableMobileTitleVip`
            : `MainSuperTableMobileTitleMain`
        }`}
      >
        <span className="TextDefaultBold">
          {isVip ? superwinText.item.table.vip : superwinText.item.table.main}
        </span>
      </div>
      <div className="MainSuperTableMobileHeader FlexRow">
        <div className="MainSuperTableMobileHeaderItem">
          {superwinTableText.nominal}
        </div>
        <div className="MainSuperTableMobileHeaderItem">
          {superwinTableText.face}
        </div>
        <div className="MainSuperTableMobileHeaderItem">
          {superwinTableText.back}
        </div>
      </div>
      {data.map((item, key) => {
        const isLast = key === data.length - 1
        return (
          <div
            key={`super_data_value_${item.price}`}
            className={`MainSuperTableMobileBodyRow FlexRow ${isLast &&
              `MainSuperTableMobileBodyRowLast`}`}
          >
            <div className="MainSuperTableMobileBodyItem">{item.price}</div>
            <div className="MainSuperTableMobileBodyItem">{item.sum_per}</div>
            <div className="MainSuperTableMobileBodyItemLast">
              {item.sum_back}
            </div>
          </div>
        )
      })}
    </div>
  )
}
