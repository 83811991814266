import { getDateByDigital } from "constants/index"
import { GetDateByDigitalTypes } from "constants/type"

export const tableText = {
  title: `Метрики гостя за все время`,
  favorite_game: `Любимая игра`,
  visit_frequency: `Частота посещения`,
  visit_frequency_unit: `раз/месяц`,
  income: `Прибыль клуба`,
  average_check: `Средний чек`,
  rub_unit: `₽`,
  next_status: `До следующего статуса`,
  next_status_max: `Макс. кэшбэк`,
  next_status_tooltip: `Количество баллов, которое гостю необходимо набрать для повышения статуса по акции "Программа лояльности"`,
  next_status_unit: `баллов`,
  average_game_duration: `Среднее время игры`,
  win: `WIN/IN`,
  win_unit: `%`,
  total_bets: `Всего ставок`,

  getAverageGameDuration: (value: number) => {
    let minutes = 0
    let hours = 0

    if (!!value) {
      hours = Math.floor(value / 60)
      minutes = value - hours * 60
    }

    const minutesWord = getDateByDigital({
      value: minutes,
      type: GetDateByDigitalTypes.minute
    })

    const hoursWord = getDateByDigital({
      value: hours,
      type: GetDateByDigitalTypes.hour
    })

    return `${hoursWord} ${minutesWord}`
  }
}

export const initialTableData = {
  favorite_game: ``,
  visit_frequency: 0,
  income: `0`,
  average_check: `0`,
  next_status: 0,
  average_game_duration: 0,
  win: 0,
  total_bets: `0`
}

export const prepareValueForChart = (str: string): number => {
  if (!str) return 0
  else {
    const withoutSpaces = str.replaceAll(` `, ``) || ``
    return Number(withoutSpaces.replace(`,`, `.`))
  }
}
