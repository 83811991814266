import React from "react"
import { Select } from "antd"
import { SmsStateProps, TemplateItem, TextOptionsProps } from "../../types"
import { smsConstants } from "../../constants"
import { ReactComponent as SmsIcon } from "Components/icons/sms_icon.svg"

/* Опции селекта Типа шаблона с добавленной опцией кастомного текста */
export const getTemplateOptions = (templates: TemplateItem[]) => {
  const options = templates.map(template => {
    return (
      <Select.Option
        value={template.typeId}
        key={template.typeId + template.name}
        className={`CreateSms_SelectDropDown`}
      >
        {template.name}
      </Select.Option>
    )
  })

  return [
    ...options,
    <Select.Option
      value={smsConstants.customSmsOptionValue}
      key={`CreateSms_customTypeId`}
      className="CreateSms_SelectDropDownCustom"
    >
      <div className="CreateSms_free_text">
        <SmsIcon />
        <span>{smsConstants.customText}</span>
      </div>
    </Select.Option>
  ]
}

/* Опции селекта подтипа шаблона */
export const getSubTemplateOptions = (
  smsState: SmsStateProps
): TextOptionsProps[] => {
  const template = smsState.smsSettings.types.filter(
    elem => elem.typeId === smsState.selectedTypeId
  )
  if (!template.length) {
    return []
  }
  if (!template[0].subtypes) return []

  return template[0].subtypes.map(template => {
    return {
      label: template.name,
      value: template.subtypeId
    }
  })
}
