import React, { useState } from "react"

import { notification, Upload } from "antd"

import moment from "moment"

import axios from "axios"

import { UploadFile } from "antd/es/upload/interface"

import { SelectComponent } from "Components/Select"
import SpinComponent from "Components/Spin"

import { textConst } from "../constants"
import { PreviewData, UploadComponentProps } from "../types"

import { ReactComponent as UploadIcon } from "./add_photo.svg"
import { ReactComponent as DeleteIcon } from "./delete_icon.svg"
import ConfirmModal from "./ConfirmModal"

const dateToDescription = (date?: string | null) => {
  if (!date) {
    return ``
  }
  return `Дата загрузки: ${moment(date).format(`DD.MM.YYYY HH:mm`)}`
}

const UploadPhoto = ({
  imageLink,
  deleteHandle,
  openPreview,
  date,
  zone
}: {
  zone: string
  imageLink: string
  deleteHandle: () => void
  date?: string | null
  openPreview: (val: PreviewData) => void
}) => {
  const description = dateToDescription(date)
  return imageLink.length ? (
    <div
      className="UploadComponent__Wrapper"
      onClick={event => {
        openPreview({ link: imageLink, description, zone })
        event.stopPropagation()
      }}
    >
      <img
        src={imageLink}
        alt="Загруженное изображение"
        className="UploadComponent__Image"
      />
      <div
        className="UploadComponent__Delete"
        onClick={event => {
          deleteHandle()
          event.stopPropagation()
        }}
      >
        <DeleteIcon />
      </div>
    </div>
  ) : (
    <>
      <UploadIcon />
      {textConst.addPhoto}
    </>
  )
}

const UploaderComponent = ({
  key,
  zone,
  options,
  date,
  setZone,
  index,
  setPhotoLink,
  deleteCardLink,
  imageStateLink,
  openPreview
}: UploadComponentProps) => {
  const [placement, setPlacement] = useState<string>(zone)
  const [imageLink, setImageLink] = useState(
    imageStateLink ? imageStateLink : ""
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false)

  const beforeUpload = (file: UploadFile): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (file.size !== undefined) {
        const isLt10M = file.size / 1024 / 1024 < 10
        if (!isLt10M) {
          notification.error({
            message: "Макс. размер одной фотографии: 10 Мб!"
          })
          reject(false)
        }
      }
      resolve(true)
    })
  }

  const handleUpload = (info: any) => {
    const file = info.file
    const fmData = new FormData()
    fmData.append("file", file)

    const userFromStorage: string | undefined =
      localStorage.getItem(`user`) || undefined
    const user: { token: string; hall: number } | undefined = userFromStorage
      ? JSON.parse(userFromStorage)
      : userFromStorage

    const getFile = new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsBinaryString(file as Blob)
      reader.onload = e => {
        resolve({
          uid: file.uid,
          name: file.name,
          size: file.size,
          type: file.type,
          content: e.target?.result
        })
      }
      reader.onerror = error => reject(error)
    })
    getFile.then(() => {
      fmData.append(`type`, `geoservice`)
      return axios
        .post(
          `${process.env.REACT_APP_AUTH_URL}/v1/files/upload/single`,
          fmData,
          {
            headers: {
              token: user ? user.token : ``,
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(({ data }) => {
          setPhotoLink(data.url, index)
          setImageLink(data.url)
        })
        .catch(({ response }) => {
          const errors = response?.data?.errors
          if (errors && errors[0]) {
            notification.error({
              message: errors
            })
          }
        })
        .finally(() => setIsLoading(false))
    })
  }

  return (
    <div
      className={`UploadComponent ${
        imageLink.length ? "UploadComponent__Uploaded" : ""
      }`}
    >
      <SelectComponent
        options={options}
        defaultValue={placement}
        onSelect={value => {
          setPlacement(value)
          setZone(value, index)
        }}
        isMiddle
        allowClear={false}
      />
      <div>
        <Upload
          key={key}
          listType="picture-card"
          beforeUpload={beforeUpload}
          accept=".png,.jpg"
          customRequest={options => {
            handleUpload(options)
            setIsLoading(true)
          }}
          maxCount={1}
          showUploadList={false}
        >
          {isLoading ? (
            <SpinComponent color="yellow" />
          ) : (
            <UploadPhoto
              imageLink={imageLink}
              zone={zone}
              date={date}
              deleteHandle={() => {
                setIsDeletePopupOpened(true)
              }}
              openPreview={openPreview}
            />
          )}
        </Upload>
      </div>
      <ConfirmModal
        open={isDeletePopupOpened}
        onCancel={() => setIsDeletePopupOpened(!isDeletePopupOpened)}
        onSubmit={() => {
          deleteCardLink(index)
          setImageLink("")
          setIsDeletePopupOpened(false)
        }}
      />
    </div>
  )
}

export default UploaderComponent
