import moment, { Moment } from "moment"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

export type GameFilterDataType = {
  period: string
  dates: [Moment, Moment] | null
  isShift: boolean
  shift: [number, number]
  gmt: string
  type: string
  games: (string | number)[]
  halls: (string | CheckboxValueType)[]
}

export interface GameFilterState {
  isOpen: boolean
  isActive: boolean
  data: GameFilterDataType
}

export const initialFilterData: GameFilterDataType = {
  period: "hourly",
  dates: [moment(), moment()],
  isShift: true,
  shift: [8, 8],
  gmt: `3`,
  type: `games`,
  games: [],
  halls: []
}

export type GameReportGameOrSportListType = {
  name: string
  timeIn: number
  timeAvg: number
  spendGame: string
  spendTime: string
  returnFact: number
  returnTheory: number
}

export type GameReportStateDataInsideType = {
  hall: string
  played: {
    check: {
      count: number
      usual: number
      bonus: number
    }
    bet: {
      usual: number
      bonus: number
    }
    usual: number
    freebet: number
  }
  totalSalePlay: number
  wined: {
    check: {
      superwin: number
      usual: number
      bonus: number
    }
    bet: {
      usual: number
      bonus: number
    }
    usual: number
    freebet: number
  }
  totalWin: number
  games: GameReportGameOrSportListType[]
}

export type GameReportStateDataType = {
  date: string
  in: number
  out: number
  inBonus: number
  result: number
  total: number
  expectedIncome: number
  showExpectedIncome: boolean
  inside: GameReportStateDataInsideType[]
}

export type GameReportStateType = {
  pending: boolean
  data: GameReportStateDataType[]
  total: number
}
