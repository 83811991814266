import { Moment } from "moment"
import { PaginationValue } from "Components/Pagination/types"

export interface PageTitleProps {
  date?: Moment
  hall: number
  isActiveDescription: boolean
}

export type RegistrationDataType = {
  date?: Moment
  hall: string
  halls: number[]
}

export interface RegistrationFilterState {
  isOpen: boolean
  isActive: boolean
  data: RegistrationDataType
  mobileFilter: boolean
}

export enum TableFields {
  sequence_number = "sequence_number",
  register_date = "register_date",
  guest_name = "guest_name",
  change_date = "change_date"
}

interface TableDataItem {
  sequence_number: number
  register_date: string
  guest_name: string
  change_date: string
}

export interface TableData {
  items: Array<TableDataItem>
  total_count: number
  registrations_count: number
}

export interface TableState {
  data: TableData | null
  pending: boolean
}

export interface CustomDateFilterProps {
  filter: RegistrationDataType
  setFilter: (date: any) => void
}

export interface RegistrationReportTableProps {
  filter: RegistrationDataType
  pagination: PaginationValue
  setPagination: (value: PaginationValue) => void
  setActive: (value: boolean) => void
}
