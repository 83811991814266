import React from "react"

import { formatToCurrency } from "constants/index"
import moment, { Moment } from "moment"

export const textByData = ({ count, sum }: { count: number; sum: string }) => ({
  title: `Всего ${count} фрибетов`,
  description: `на общую сумму ${formatToCurrency({
    amount: Number(sum),
    withoutFixed: true
  })} Ф`
})

const columnKeys = {
  value: {
    key: `value`,
    label: `Номинал фрибета, Ф`
  },
  endTimestamp: {
    key: `endTimestamp`,
    label: `Окончание действия`,
    format: `DD.MM.YYYY`
  },
  betType: {
    key: `betType`,
    label: `Тип фрибета`
  },
  betMinFactor: {
    key: `betMinFactor`,
    label: `Мин. коэф.`
  },
  betMaxFactor: {
    key: `betMaxFactor`,
    label: `Макс. коэф.`
  }
}

export const columns = [
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {columnKeys.endTimestamp.label}
      </div>
    ),
    dataIndex: columnKeys.endTimestamp.key,
    key: columnKeys.endTimestamp.key,
    width: 200,
    render: (date: Moment) => (
      <div className="FlexRow TableHeaderFirstTh">
        <span className="TextDefaultBold">
          {date ? moment(date).format(columnKeys.endTimestamp.format) : `-`}
        </span>
      </div>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">
        {columnKeys.betType.label}
      </div>
    ),
    dataIndex: columnKeys.betType.key,
    key: columnKeys.betType.key,
    render: (betType: string) => (
      <div className="FlexRow">
        <span className="TextDefault CapitalizeText">{betType}</span>
      </div>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">{columnKeys.value.label}</div>
    ),
    dataIndex: columnKeys.value.key,
    key: columnKeys.value.key,
    width: 200,
    render: (value: number) => (
      <div className="FlexRow">
        <span className="TextDefault">
          {formatToCurrency({ amount: value, withoutFixed: true })}
        </span>
      </div>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">
        {columnKeys.betMinFactor.label}
      </div>
    ),
    dataIndex: columnKeys.betMinFactor.key,
    key: columnKeys.betMinFactor.key,
    render: (betMinFactor: number) => (
      <div className="FlexRow">
        <span className="TextDefault">{betMinFactor}</span>
      </div>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">
        {columnKeys.betMaxFactor.label}
      </div>
    ),
    dataIndex: columnKeys.betMaxFactor.key,
    key: columnKeys.betMaxFactor.key,
    render: (betMaxFactor: number) => (
      <div className="FlexRow">
        <span className="TextDefault">{betMaxFactor}</span>
      </div>
    )
  }
]
