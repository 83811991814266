import cloneDeep from "lodash/cloneDeep"
import { Moment } from "moment"

/** Получаем локальный часовой пояс в часах */
export const getTimezoneOffset = () => {
  return new Date().getTimezoneOffset() / 60
}

/**
 * Восстанаваливаем смещение часового пояса относительно нулей
 * Пример
 * Входная дата 01.01.2023 00:00:00
 * что бы не было никаких изменений дат в случае отката к нулевому часовому поясу (Для примера МСК) 01.01.2023 00:00:00 => 31.12.2022 22:00:00
 * в данном случае система откатила часовой пояс к гринвичу и изменилась дата
 * мы добавляем компенсирующие часы в зависимости от смещения 01.01.2023 00:00:00 => 01.01.2023 02:00:00
 */
export const restoreOffset = (date: Moment) => {
  const offset = inverseTimezone(getTimezoneOffset())

  return cloneDeep(date).add(offset, "hours")
}

/**
 * Инварсируем смещение часового пояса
 * -2 => 2
 * 2 => -2
 */
function inverseTimezone(offset: number) {
  if (offset < 0) {
    return Math.abs(offset)
  }

  if (offset > 0) {
    return -offset
  }

  return offset
}
