export const chunkStatuses = [
  { id: 0, name: "В обработке" },
  { id: 1, name: "Успешно" },
  { id: 2, name: "Ошибка" },
  { id: 3, name: "В ожидании" },
  { id: 4, name: "Неуспешно" }
]

export const noncashExcelConfig = {
  url: `reports/noncash/transaction/download`,
  title: `Выгрузить`
}
