import { RaceHistoryState, RaceListState } from "./types"

export enum RaceStatuses {
  waiting = "waiting",
  pending = "pending",
  finished = "finished",
  canceled = "canceled",
  failed = "failed"
}

export const raceText = {
  title: "Гонка",
  titleTooltip:
    "Раздел содержит информацию как по незавершенным акциям “Гонка”, так и по уже прошедшим.\n" +
    "В таблице можно увидеть период акции, её статус, список игр, участвующих в акции, а также список победителей и информацию о призовом фонде.\n" +
    "Нажав на активную кнопку в строке акции откроется дополнительное окно со списком победителей, если они определены, и призовыми начислениями.\n" +
    "Строка с гонкой между несколькими клубами, параллельно с которой проходит локальная гонка в каждом клубе, отмечена фирменным значком. Раскрыв такую строку, будет видна информация по локальной гонке.\n" +
    'Во вкладке “Информация по гостю”, указав номер телефона гостя, зарегистрированном в клубе, можно узнать, в каких акциях "Гонка" и когда гость занимал призовые места и получал вознаграждение в данном клубе.\n' +
    "Время в столбце “Период акции” указано местное.",
  infoTab: "Информация по акциям",
  guestTab: "Информация по гостю",
  filterDates: "Период проведения акции",
  columns: {
    number: "№ акции",
    status: "Статус",
    period: "Период акции",
    prizeFund: "Призовой фонд",
    games: "Список игр",
    winners: "Победители"
  },
  columnsGuestInfo: {
    win: "Выигрыш в гонке",
    winLocal: "Выигрыш в локальной гонке",
    place: "Место",
    winAmount: "Сумма выигрыша"
  },
  columnsModal: {
    place: "Место",
    prize: "Выигрыш",
    phone: "Номер телефона",
    fullName: "ФИО"
  },
  noWinners: "Нет победителей",
  more: "...ещё",
  ppl: "чел.",
  localRace: "Локальная гонка",
  localTableCellContent: "Локальная",
  number: "№",
  emptyWinners: "Победители гонки по клубу не определены",
  guestInfoStart:
    "Что бы посмотреть информацию о гонках, в которых гость\nполучил выигрыш, введите его номер телефона",
  guestInfoNotFoundTitle: "Гость не найден",
  guestInfoNotFound: "В вашем клубе гость с таким номером телефона не найден",
  guestInfoEmptyTitle: "Информации по гостю нет",
  guestInfoEmpty:
    "Гость, зарегистрированный по этому номеру телефона,\nне участвовал в акциях «Гонки», или не занимал в них призовые места",
  guestInfoFilter: "Номер телефона гостя",
  currency: {
    rub: "₽",
    bonus: "Б"
  },
  statuses: {
    [RaceStatuses.waiting]: "Не завершена",
    [RaceStatuses.finished]: "Завершена",
    [RaceStatuses.pending]: "Не завершена",
    [RaceStatuses.failed]: "Неуспешно",
    [RaceStatuses.canceled]: "Отменена"
  },
  separator: "·",
  showMore: "...еще",
  notShowMore: "свернуть",
  raceCellTooltip: "Гонка, в которой участвуют гости из разных клубов"
}

export const RACE_FILTER = "RACE_FILTER"

export const RACE_TABLE_SCROLL = {
  x: 1396,
  y: "calc(100vh - 17rem)"
}

export const tabsIds = {
  infoTab: 1,
  guestTab: 2
}

export const tabsIdsModal = {
  raceTab: 3,
  localRaceTab: 4
}
export const tabsConfig = [
  {
    label: raceText.infoTab,
    value: tabsIds.infoTab
  },
  { label: raceText.guestTab, value: tabsIds.guestTab }
]

export const tabsModalLocal = {
  label: raceText.localRace,
  value: tabsIdsModal.localRaceTab,
  disabled: false
}

export const tabsModalRace = {
  label: raceText.title,
  value: tabsIdsModal.raceTab,
  disabled: false
}

export const tabsModalRaceDisabled = {
  ...tabsModalRace,
  disabled: true
}

export const tabsConfigModal = [tabsModalLocal, tabsModalRace]

export const tabsConfigModalRaceDisabled = [
  tabsModalLocal,
  tabsModalRaceDisabled
]

export enum WinnerBaseKeys {
  federal = "federal",
  local = "local"
}

export enum RaceListTableColNames {
  id = "id",
  status = "status",
  period = "period",
  prize_pool_sum = "prize_pool_sum",
  games = "game_ids",
  federal = "federal",
  local = "local",
  prize_pool = "prize_pool"
}

export enum RaceHistoryTableColNames {
  id = "id",
  period = "period",
  place = "place",
  win_sum = "win_sum",
  federal = "federal",
  local = "local"
}

export enum RaceModalTableColNames {
  place = "place",
  name = "name",
  phone = "phone",
  prize_pool_sum = "prize_pool_sum",
  prize_pool_type = "prize_pool_type"
}

export const initialRaceData: RaceListState = {
  loading: true,
  data: [],
  total: 0
}

export const initialRaceHistoryData: RaceHistoryState = {
  ...initialRaceData,
  data: [],
  isGuestInfoEmpty: false,
  isGuestNotFound: false,
  isPhoneEmpty: false
}

export enum CurrencyKeys {
  money = "money",
  bonus = "bonus"
}

export const currencyDict = {
  [CurrencyKeys.money]: raceText.currency.rub,
  [CurrencyKeys.bonus]: raceText.currency.bonus
}

export const raceStatusesDict = {
  [RaceStatuses.waiting]: {
    text: raceText.statuses[RaceStatuses.waiting],
    className: "RaceStatus__waiting"
  },
  [RaceStatuses.finished]: {
    text: raceText.statuses[RaceStatuses.finished],
    className: "RaceStatus__finished"
  },
  [RaceStatuses.pending]: {
    text: raceText.statuses[RaceStatuses.pending],
    className: "RaceStatus__pending"
  },
  [RaceStatuses.failed]: {
    text: raceText.statuses[RaceStatuses.failed],
    className: "RaceStatus__failed"
  },
  [RaceStatuses.canceled]: {
    text: raceText.statuses[RaceStatuses.canceled],
    className: "RaceStatus__cancelled"
  }
}
