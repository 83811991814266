import React, { useCallback, useMemo, useState, useRef } from "react"

import ModalComponent from "Components/Modal"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { Input } from "antd"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

import { RadioGroupComponent } from "Components/Radio"
import { CheckBoxComponent } from "Components/CheckBox"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"

import { getHallsListFromStore } from "store/halls/selector"

import { HallPickerModalProps, HallListFilterProps } from "./types"
import { hallPickerModalConstants } from "./helper"
import { VISIT_FILTER_NAME } from "Containers/Visit/VisitFilter"

const HallPickerModalComponent = ({
  current_hall,
  close
}: HallPickerModalProps) => {
  const history = useHistory()
  const halls = useSelector(getHallsListFromStore)

  const [search, setSearch] = useState<string | undefined>(undefined)

  const filteredHallsList = !!search
    ? halls.filter(item => `${item}`.indexOf(search) >= 0)
    : halls
  return (
    <ModalComponent close={close} width={hallPickerModalConstants.modalWidth}>
      <div>
        <div className="HallPickerModalHeader GridCenter">
          {hallPickerModalConstants.title}
        </div>
        <div className="HallPickerModalSearch HallPickerModalSearchWrapper">
          <Input
            value={search}
            suffix={<SearchIcon />}
            placeholder={hallPickerModalConstants.placeholder}
            onChange={({ target: { value } }) => setSearch(`${value}`)}
          />
        </div>
        <div className="HallPickerModalContentWrapper">
          <div className="FlexRow FlexWrap HallPickerModalList">
            {filteredHallsList.map(item => (
              <div
                key={`hall_picker_item_${item}`}
                className={`HallPickerModalItem FlexRow ${
                  item == current_hall[0] ? `HallPickerModalActiveItem` : ``
                }`}
                onClick={() => {
                  if (item != current_hall[0]) {
                    localStorage.setItem(
                      hallPickerModalConstants.storage,
                      `[\"${item}\"]`
                    )
                    localStorage.removeItem(VISIT_FILTER_NAME)
                    history.go(0)
                  }
                }}
              >
                <div className="HallPickerModalItemDescription">
                  {hallPickerModalConstants.numberLabel}
                </div>
                <div className="HallPickerModalItemLabel">{item}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalComponent>
  )
}

export const HallListFilter = ({
  current_halls,
  setCurrentHalls,
  radio,
  withoutResetButton
}: HallListFilterProps) => {
  const isRadio = !!radio
  const checkManyHalls = useMemo(() => current_halls.length > 1, [
    current_halls
  ])
  const halls = useSelector(getHallsListFromStore)
  const currentHalls = current_halls.map(item => `${item}`)

  let options = [
    ...currentHalls,
    ...halls.map(item => `${item}`).filter(item => !currentHalls.includes(item))
  ]

  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(currentHalls.length === halls.length)

  const onCheckAllChange = (e: any) => {
    setCurrentHalls(e.target.checked ? options : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [radioChanged, setRadioChanged] = useState(false)
  const sortedOptions = useRef(options)

  const radioIdx = options.indexOf(String(radio?.value))
  if (radioIdx !== -1 && !search && !radioChanged) {
    options = [
      String(radio?.value),
      ...options.slice(0, radioIdx).concat(options.slice(radioIdx + 1))
    ]
    sortedOptions.current = options
  } else if (!search && radioChanged) {
    options = sortedOptions.current
  }

  const filteredHallsList = !!search
    ? options.filter(item => `${item}`.indexOf(search) >= 0)
    : options

  const checkboxOnChange = useCallback(
    (item: CheckboxValueType, checked: boolean) => {
      if (checked) {
        setCurrentHalls([...current_halls, `${item}`])
      } else {
        const newList = current_halls.filter(hall => hall !== item) || []
        setCurrentHalls(newList)
      }
    },
    [setCurrentHalls]
  )
  const radioOnChange = useCallback(
    (value: number) => {
      radio?.setValue(value)
      setRadioChanged(true)
    },
    [radio?.setValue]
  )
  return (
    <div>
      <div className="FlexRow">
        <div className="HallPickerHeader">
          {hallPickerModalConstants.getPickerType(isRadio)}
        </div>
        {checkManyHalls ? (
          <div className="HallPickerHeaderCounter">{current_halls.length}</div>
        ) : (
          <div />
        )}
        {checkManyHalls && !withoutResetButton ? (
          <div
            onClick={() => {
              setCurrentHalls([])
              setCheckAll(false)
            }}
            className="HallPickerHeaderReset"
          >
            {hallPickerModalConstants.reset}
          </div>
        ) : (
          <div />
        )}
      </div>
      {options && (
        <div>
          {!isRadio && options.length > 3 && (
            <div className="HallPickerFilterCheckboxAllWrapper">
              <CheckBoxComponent
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              />
              <div className="HallPickerFilterCheckboxAllLabel">
                {hallPickerModalConstants.all}
              </div>
            </div>
          )}
          {options.length > 9 && (
            <div className="HallPickerModalSearch">
              <Input
                value={search}
                suffix={<SearchIcon />}
                placeholder={hallPickerModalConstants.searchPlaceholder}
                onChange={({ target: { value } }) => setSearch(`${value}`)}
              />
            </div>
          )}
        </div>
      )}
      <div className="HallPickerCheckboxWrapper">
        <div className="HallPickerFilterCheckboxGroupWrapper">
          {!radio &&
            filteredHallsList.map(item => (
              <CheckBoxComponent
                key={`hall_value_${item}`}
                label={`${item}`}
                value={item}
                checked={current_halls.indexOf(`${item}`) >= 0}
                onChange={({ target: { checked } }) =>
                  checkboxOnChange(item, checked)
                }
              />
            ))}
          {!!radio && (
            <div className="HallPickerFilterRadioGroupWrapper">
              <RadioGroupComponent
                value={radio.value}
                onChange={({ target: { value } }) => radioOnChange(value)}
                options={filteredHallsList.map(item => ({
                  value: Number(item),
                  label: `${item}`
                }))}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HallPickerModalComponent
