import React, { useRef } from "react"
import HighchartsReact from "highcharts-react-official"
import * as HighchartsOne from "highcharts"
import Highcharts from "highcharts/highstock"

import { formatToCurrency, getDateByDigital } from "constants/index"

import {
  BetsBySumByGameState,
  BetsBySumDataCategories,
  BetsBySumDataRequestData,
  BetsBySumDataSeries
} from "./types"

import { GetDateByDigitalTypes } from "constants/type"

const GuestHistoryBetsBySum = ({
  isFreebetActive,
  chartDataByGames,
  categories,
  wins,
  bets,
  cross
}: {
  isFreebetActive: boolean
  chartDataByGames: BetsBySumByGameState
  categories: BetsBySumDataCategories
  wins: BetsBySumDataSeries
  bets: BetsBySumDataSeries
  cross: BetsBySumDataSeries
}) => {
  const chartComponentRef = useRef(null)

  const games = Object.keys(chartDataByGames)
  const maxLengthGame = Math.max(...games.map(el => el.length)) * 9

  const gamesLength = (games || []).length * 70
  const chartHeight = gamesLength > 300 ? gamesLength : 300

  const series = [bets, wins, cross]
  const options: Highcharts.Options = {
    chart: {
      type: "bar",
      marginLeft: maxLengthGame,
      marginRight: 0,
      height: chartHeight
    },
    title: {
      text: ""
    },
    xAxis: {
      categories,
      crosshair: {
        className: `ChartsCrosshair`,
        width: 1,
        dashStyle: `Dash`,
        color: `rgba(52, 199, 89, 1)`
      },
      tickWidth: 0,
      labels: {
        align: `center`,
        formatter: function() {
          const { value } = this
          return (
            `<div class="CaptionText" style="color: #868E96">` +
            value +
            `</div>`
          )
        }
      }
    },
    yAxis: {
      crosshair: {
        width: 1,
        color: `#E9ECEF`
      },
      title: {
        text: ""
      },
      gridLineDashStyle: `Dash`,
      gridLineColor: `#F2F2F7`,
      gridLineWidth: 1,
      gridZIndex: 0,
      plotLines: [
        {
          dashStyle: `Solid`,
          color: "#AEAEB2",
          value: 0,
          width: 1
        }
      ]
    },
    legend: {
      shadow: false,
      enabled: false
    },
    tooltip: {
      outside: false,
      shared: true,
      borderRadius: 10,
      borderWidth: 0,
      useHTML: true,
      split: true,
      className: "InOutTooltip",
      formatter: function() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const title = this.x
        const pointData: BetsBySumDataRequestData = chartDataByGames[title]

        if (!!pointData) {
          const bet_sum = formatToCurrency({
            amount: Number(pointData.bet_sum)
          })
          const win_sum = formatToCurrency({
            amount: Number(pointData.win_sum)
          })
          const bonus_sum = isFreebetActive
            ? formatToCurrency({
                amount: Number(pointData.bonus_sum),
                isDash: false
              })
            : `-`

          const bonus_sum_div = isFreebetActive
            ? `<div class="ChartTooltipItem">
              <span class="OverlineText" style="color: #868E96">Сумма бонусов/фрибетов</span>
              <span class="BodyFirstText" style="color: #FFFFFF">` +
              bonus_sum +
              `</span>
          </div>`
            : ``

          const bet_count = formatToCurrency({
            amount: Number(pointData.bet_count),
            isDash: false,
            withoutFixed: true
          })
          const { win_in_percent, minutes_in_play } = pointData
          const bet_hours = Math.floor(minutes_in_play / 60)

          const hoursText = getDateByDigital({
            value: bet_hours,
            type: GetDateByDigitalTypes.hour
          })
          return (
            `<div class="ChartTooltip">
          <div class="HeadlineThirdText" style="color: #FCE000; text-transform: capitalize; padding-bottom: 8px">` +
            title +
            `</div>
        <div style="display: flex; flex-wrap: wrap;">
          <div class="ChartTooltipItem">
              <span class="OverlineText" style="color: #868E96">Сумма ставок</span>
              <span class="BodyFirstText" style="color: #FFFFFF">` +
            bet_sum +
            ` ₽</span>
          </div>
          <div class="ChartTooltipItem">
              <span class="OverlineText" style="color: #868E96">Выигрыш</span> 
              <span class="BodyFirstText" style="color: #FFFFFF">` +
            win_sum +
            ` ₽</span>
          </div>` +
            bonus_sum_div +
            `
          <div class="ChartTooltipItem">
              <span class="OverlineText" style="color: #868E96">WIN/IN</span>
              <span class="BodyFirstText" style="color: #FFFFFF">` +
            win_in_percent +
            ` %</span>
          </div>
          <div class="ChartTooltipItem">
              <span class="OverlineText" style="color: #868E96">Время в игре</span>
              <span class="BodyFirstText" style="color: #FFFFFF">` +
            hoursText +
            `</span>
          </div>
          <div class="ChartTooltipItem">
              <span class="OverlineText" style="color: #868E96">Количество ставок</span>
              <span class="BodyFirstText" style="color: #FFFFFF">` +
            bet_count +
            `</span>
          </div>
        </div>`
          )
        }
      },
      backgroundColor: `#434345`
    },
    plotOptions: {
      bar: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
        borderRadius: 8,
        pointWidth: 45
      }
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    series
  }

  return (
    <HighchartsReact
      ref={chartComponentRef}
      highcharts={HighchartsOne}
      options={options}
    />
  )
}

export default GuestHistoryBetsBySum
