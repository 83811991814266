import betRequest, { getRequestTotal } from "api/request"
import ConsolidatedState, { ConsolidatedFilterData } from "./types"

import { PaginationValue } from "Components/Pagination/types"

import { RouteComponentProps } from "react-router-dom"
import { IDevelopState } from "store/developer/types"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { parseDateToBack } from "constants/index"

type getConsolidatedType = {
  consolidatedState: ConsolidatedState
  setConsolidatedState: (data: ConsolidatedState) => void
  logout: () => void
  history: RouteComponentProps["history"]
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  filter: ConsolidatedFilterData
  halls: (string | CheckboxValueType)[]
  pagination: PaginationValue
}

const getConsolidated = ({
  consolidatedState,
  setConsolidatedState,
  logout,
  history,
  developer,
  addDeveloperLink,
  filter,
  pagination
}: getConsolidatedType) => {
  setConsolidatedState({ ...consolidatedState, pending: true })
  const dates = parseDateToBack({
    date: filter.dates,
    gmt: filter.gmt
  })

  betRequest({
    method: `POST`,
    url: `reports/summary`,
    requestBody: {
      period: filter.period,
      halls: filter.halls,
      dates,
      pagination
    },
    developer,
    addDeveloperLink,
    history,
    logout
  })
    .then(({ data, headers }) => {
      setConsolidatedState({
        ...consolidatedState,
        pending: false,
        data: data,
        total: getRequestTotal(headers)
      })
    })
    .catch(() =>
      setConsolidatedState({
        ...consolidatedState,
        pending: false
      })
    )
}

export default getConsolidated
