import { TableColumns } from "./constants"

import { UseRequestBodySort, UseRequestBodyPagination } from "api/hooks"
import { DetailTableDataInside } from "./Detail/types"
import { TableConfigData } from "./Config/types"
import { Omit } from "@material-ui/core"

export enum TableDataBetTypeValue {
  ordinary = `ordinary`,
  express = `express`,
  system = `system`
}

export type TableDataBetType = {
  value: TableDataBetTypeValue
  isLive: boolean
  count: number
}

export enum TableDataStatus {
  win = `win`,
  loose = `loose`,
  in_game = `in_game`,
  redeem = `redeem`,
  return_bet = `return_bet`
}

export enum TableDataBalance {
  money = `money`,
  freebet = `freebet`
}

export type FilterTableData = {
  [TableColumns.bet_number]?: undefined
  [TableColumns.hall]?: undefined
  [TableColumns.freebet_id]?: undefined
  [TableColumns.wallet_id]?: undefined
  [TableColumns.bet]?: {
    from?: number
    to?: number
  }
  [TableColumns.win]?: {
    from?: number
    to?: number
  }
  [TableColumns.balance]?: TableDataBalance[]
  [TableColumns.event_id]?: {
    equal: boolean
    value: string | null
  }
  [TableColumns.sport_type]?: string[]
  [TableColumns.bet_type]?: TableDataBetTypeValue[]
  [TableColumns.coefficient]?: {
    from?: number
    to?: number
  }
  [TableColumns.result]?: {
    from?: number
    to?: number
  }
  [TableColumns.status]?: TableDataStatus[]
  [TableColumns.guest]?: {
    value: string | null
  }
  [TableColumns.period]?: {
    from: string | null
    to: string | null
  }
}

export type FilterData = {
  sort: UseRequestBodySort
  pagination: UseRequestBodyPagination
  table: FilterTableData
}

export type SportTypeData = {
  name: string
  description: string
  bet_type_description: string
  status: TableDataStatus
  coefficient: number
  event_id: string
  sport_type: string
  has_bet_builder: boolean
  bet_number: number
}

export type TableData = {
  key: number
  bet_number: number
  sport_type: SportTypeData[]
  event_id: number
  freebet_id: number
  wallet_id: number
  bet_start: string
  bet_end: string
  bet_type: TableDataBetType
  bet: number
  win: number
  coefficient: number
  result: number
  status: TableDataStatus
  guest: string
  balance: TableDataBalance
  hall: number
  details: DetailTableDataInside[]
}

export type TableDataState = {
  data: TableData[]
  total: number
  pending: boolean
}

export type GameListItem = {
  name: string
  id: number
}

export type GameList = {
  data: GameListItem[]
  pending: boolean
}

export type DetailModalProps = {
  open: boolean
  list: number[]
  current?: number
  currentData?: TableData
  dataList: { [key: number]: TableData }
}

export interface GetColumnsMethodProps {
  tableConfig: TableConfigData[]
  gameList: GameListItem[]
  setTableFilter: (val: FilterData) => void
  tableFilter: FilterData
  openDetailCurrent: (list: number) => void
  selectedList: number[]
  onWalletIdClick?: (walletId: number) => void
  isBetbuilderExpand?: boolean
}

export interface ColumnsOptionsProps
  extends Omit<GetColumnsMethodProps, "tableConfig"> {
  firstElem: TableColumns
  key: TableColumns
}

export type InsideColumnsOptionsMethodProps = Omit<
  ColumnsOptionsProps,
  "gameList" | "setTableFilter" | "tableFilter" | "openDetailCurrent"
>

export type GetInsideColumnsMethodProps = Omit<
  GetColumnsMethodProps,
  "gameList" | "setTableFilter" | "tableFilter" | "openDetailCurrent"
>

export interface FromToColumnProps
  extends Omit<
    GetColumnsMethodProps,
    "gameList" | "openDetailCurrent" | "tableConfig" | "selectedList"
  > {
  checkIsFirstClass: (field: TableColumns) => string
  checkIsFirstFix: (field: TableColumns) => string
  fromToField:
    | TableColumns.win
    | TableColumns.coefficient
    | TableColumns.result
    | TableColumns.bet
}

export interface CheckColumnTitle
  extends Omit<
    GetColumnsMethodProps,
    "gameList" | "openDetailCurrent" | "tableConfig" | "selectedList"
  > {
  checkIsFirstClass: (field: TableColumns) => string
  checkField: TableColumns.balance | TableColumns.status
}
