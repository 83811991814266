export enum ActiveTabs {
  hall = `hall`,
  history = `history`
}

export type MailingTabsProps = {
  successRefresh?: () => void
  activeTab: ActiveTabs
  setActiveTab: (data: ActiveTabs) => void
}
