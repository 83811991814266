import React, { useCallback, useMemo, useState } from "react"
import { noop } from "lodash"

import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import { useRequestBody, useRequestParams } from "api/hooks"

import PageTitle from "./components/PageTitle"
import RegistrationReportTable from "./components/Table"
import CustomDateFilter from "./components/CustomDateFilter"

import {
  filterStorageName,
  getCurrenHallFromFilter,
  getInitialFilter,
  initialFilterValues
} from "./constants"
import { RegistrationFilterState } from "./types"

import "./styles.less"

const SopdReport = () => {
  const { current_hall } = useRequestParams()
  const initialFilter = useMemo(() => getInitialFilter(`${current_hall}`), [])
  const [filterState, setFilterState] = useState<RegistrationFilterState>(
    initialFilter
  )
  const [activeDescription, setActiveDescription] = useState(false)
  const { pagination, setPagination } = useRequestBody()
  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])
  const currentHall = getCurrenHallFromFilter(filterState.data)
  return (
    <ReportWithFilter
      filterComponent={
        <SideFilter
          filterName={filterStorageName}
          filterState={filterState}
          getDisabled={filter => !filter.date}
          initialForClear={initialFilterValues}
          setFilterState={setFilterStateCb}
          filters={[Filters.hallPickerRadio, Filters.customItem]}
          CustomComponent={CustomDateFilter}
          resetPagination={noop}
        />
      }
      mobileFilter={filterState.mobileFilter}
      toggleFilter={() =>
        setFilterState({ ...filterState, isOpen: !filterState.isOpen })
      }
      toggleMobileFilter={() =>
        setFilterState({
          ...filterState,
          mobileFilter: !filterState.mobileFilter
        })
      }
      pageContent={
        <div className="RegistrationReportWrapper">
          <PageTitle
            date={filterState.data.date}
            hall={currentHall}
            isActiveDescription={activeDescription}
          />
          <RegistrationReportTable
            setActive={setActiveDescription}
            filter={filterState.data}
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      }
      isOpen={filterState.isOpen}
    />
  )
}

export default SopdReport
