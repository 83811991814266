import React from "react"

import moment, { Moment } from "moment"

export const DateTime: React.FC<{
  date?: string | Moment
  independentDate?: boolean
}> = ({ date, independentDate }) => {
  let timeFormat
  let dateFormat
  if (independentDate && typeof date === `string`) {
    // TODO: moment can't do it, mb new date lib can...
    const dateParts = date.split(/[T.:+]/)
    timeFormat = `${dateParts[1]}:${dateParts[2]}`

    const [year, month, day] = date.substring(0, 10).split("-")

    dateFormat = `${day}.${month}.${year}`
  } else {
    const momentDate = moment(date)
    dateFormat = momentDate.format(`DD.MM.YYYY`)
    timeFormat = momentDate.format(`HH:mm`)
  }
  return (
    <div className="FlexRow">
      <span className="DateTimeDate">{dateFormat}</span>
      <span className="DateTimeTime">{timeFormat}</span>
    </div>
  )
}
