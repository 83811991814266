import { action } from "typesafe-actions"
import { SET_CURRENT_HALLS, STORE_HALLS } from "./constants"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

export const storeHalls = () => {
  return action(STORE_HALLS)
}

export const setCurrentHall = (payload: (string | CheckboxValueType)[]) => {
  return action(SET_CURRENT_HALLS, payload)
}
