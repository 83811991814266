import React from "react"

import { Skeleton } from "antd"

import { TooltipCustom, TooltipCustomSize } from "Components/Tooltip"
import { ReactComponent as InfoIcon } from "Components/icons/info_circle_24.svg"

import { DetailComponentRowProps, DetailComponentProps } from "./types"

export const DetailComponentRow = ({
  data,
  pending
}: DetailComponentRowProps) => {
  return data.map((item, key) => {
    const isFirst = key === 0
    const isLast = key === data.length - 1

    return (
      <div
        key={`detail_row_${item.label}`}
        className={`DetailTableComponentRowItem FlexColumn ${
          isFirst ? `DetailTableComponentRowFirstItem` : ``
        } ${isLast ? `DetailTableComponentRowLastItem` : ``}`}
      >
        {!pending ? (
          <div>
            <div className="FlexRow Gap8">
              <div className="HeadlineThirdText">{item.value}</div>
              {!!item.openPopup && (
                <div onClick={item.openPopup} className="HoverItem">
                  <InfoIcon />
                </div>
              )}
            </div>
            <div className="FlexRow">
              <div className="TextDefault DetailTableComponentRowItemLabel">
                {item.label}
              </div>
              {!!item.tooltip && (
                <TooltipCustom
                  title={item.tooltip}
                  size={TooltipCustomSize.small}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="FlexColumn">
            <Skeleton.Button
              className="DetailComponentSkeletonValue"
              active
              size="small"
            />
            <Skeleton.Button
              className="DetailComponentSkeletonLabel"
              active
              size="small"
            />
          </div>
        )}
      </div>
    )
  })
}

export const DetailComponentSmallRow = ({
  data,
  pending
}: DetailComponentRowProps) => {
  return (
    <div className="DetailTableComponentRowSmallWrapper">
      {data.map((item, key) => {
        const isFirst = key === 0
        const isLast = key === data.length - 1
        return (
          <div
            key={`detail_row_small_${item.label}`}
            className={`DetailTableComponentRowItem FlexColumn ${
              isFirst ? `DetailTableComponentRowFirstItem` : ``
            } ${
              isLast ? `DetailTableComponentRowLastItem` : ``
            } DetailTableComponentRowSmall`}
          >
            {!pending ? (
              <div>
                <div className="FlexRow">
                  <div className="OverlineText DetailTableComponentRowItemLabel">
                    {item.label}
                  </div>
                  {!!item.tooltip && (
                    <TooltipCustom
                      title={item.tooltip}
                      size={TooltipCustomSize.small}
                    />
                  )}
                </div>
                <div className="TextDefaultBold">{item.value}</div>
              </div>
            ) : (
              <div className="FlexColumn">
                <Skeleton.Button
                  className="DetailComponentSmallSkeletonValue"
                  active
                  size="small"
                />
                <Skeleton.Button
                  className="DetailComponentSmallSkeletonLabel"
                  active
                  size="small"
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const DetailComponent = ({ detailConfig, pending }: DetailComponentProps) => {
  const isOneRowDetail = detailConfig.lastRow.length === 0
  return (
    <div className="DetailTableComponentWrapper">
      <div
        className={`DetailTableComponentRow DetailTableComponentFirstRow FlexRow ${
          isOneRowDetail ? `DetailTableComponentLastRow` : ``
        }`}
      >
        {DetailComponentRow({ data: detailConfig.firstRow, pending })}
      </div>
      <div className="DetailTableComponentRow DetailTableComponentLastRow FlexRow">
        {DetailComponentRow({ data: detailConfig.lastRow, pending })}
      </div>
    </div>
  )
}

export default DetailComponent
