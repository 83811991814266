import { IRootState } from "store/index"
import { LocationsData, GamesData } from "store/dictionary/types"
import { forEach } from "lodash"

export const getGamesList = (state: IRootState): GamesData[] =>
  state.dictionary.games

export const getNominalsGamesList = (state: IRootState): GamesData[] =>
  state.dictionary.games.filter(game => game.is_nominal)

export const getLocationsList = (state: IRootState): LocationsData =>
  state.dictionary.locations

export const getTransactionsList = (state: IRootState) =>
  state.dictionary.transaction.statuses

export const getBranchHallsList = (state: IRootState) =>
  state.dictionary.branch_halls

export const getTransferHalls = (state: IRootState) =>
  state.dictionary.transfer.halls

export const getCityList = (state: IRootState) => {
  let list: any[] = []
  forEach(state.dictionary.locations.city, function(
    value: { id: number; name: string }[]
  ) {
    list = list.concat(value)
  })

  return list.map(item => ({
    value: item.id,
    label: item.name
  }))
}

export const getRegions = (state: IRootState) => {
  let list: any[] = []
  forEach(state.dictionary.locations.region, function(
    value: { id: number; name: string }[]
  ) {
    list = list.concat(value)
  })

  return list.map(item => ({
    value: item.id,
    label: item.name
  }))
}
