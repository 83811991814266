import React, { useState, useMemo } from "react"

import moment from "moment"

import { CashboxReportType } from "Containers/Cashbox/types"

import { ICashboxStatisticsData, ChartsDataType } from "./types"
import StatisticTypes from "./Types/index"
import CashboxCharts from "./Charts"
import { TypesItem } from "./Types/helper"

export interface IIypesState {
  active: number
  pending: boolean
  data: TypesItem[]
}

export interface StatisticsComponentProps {
  report: CashboxReportType[]
  period: string
  total: number
}

const prepareValueForChart = (str: string): number => {
  if (!str) return 0
  else {
    const withoutSpaces = str.replaceAll(` `, ``) || ``
    return Number(withoutSpaces.replace(`,`, `.`))
  }
}

const StatisticsComponent = ({
  report,
  period,
  total
}: StatisticsComponentProps) => {
  let periodFormat: { backFormat: string; name: string }
  switch (period) {
    case `hourly`:
      periodFormat = { backFormat: `HH:mm`, name: `HH:mm` }
      break
    case `daily`:
      periodFormat = { backFormat: `DD.MM.YYYY`, name: `DD.MM` }
      break
    case `monthly`:
      periodFormat = { backFormat: `MMMM YYYY`, name: `MMMM YYYY` }
      break
    case `yearly`:
      periodFormat = { backFormat: `YYYY`, name: `YYYY` }
      break
    default:
      periodFormat = { backFormat: `DD.MM.YYYY`, name: `DD.MM` }
  }

  const [activeStatisticsType, setActiveStaticType] = useState<string>(`income`)
  const chartsData: ICashboxStatisticsData = useMemo(() => {
    const incomeData: ChartsDataType[] = []
    const outcomeData: ChartsDataType[] = []
    const resultData: ChartsDataType[] = []
    for (let i = 1; i < report.length; i++) {
      incomeData.push({
        x: moment(report[i].date, periodFormat.backFormat).valueOf(),
        y: prepareValueForChart(`${report[i].income}`),
        value: report[i].income,
        name: moment(report[i].date, periodFormat.backFormat).format(
          periodFormat.name
        )
      })
      outcomeData.push({
        x: moment(report[i].date, periodFormat.backFormat).valueOf(),
        y: prepareValueForChart(`${report[i].outcome}`),
        value: report[i].outcome,
        name: moment(report[i].date, periodFormat.backFormat).format(
          periodFormat.name
        )
      })
      resultData.push({
        x: moment(report[i].date, periodFormat.backFormat).valueOf(),
        y: prepareValueForChart(`${report[i].result}`),
        value: report[i].result,
        name: moment(report[i].date, periodFormat.backFormat).format(
          periodFormat.name
        )
      })
    }
    return {
      income: {
        chart: incomeData,
        color: `rgba(52, 199, 89, 1)`
      },
      outcome: {
        chart: resultData,
        color: `rgba(255, 204, 0, 1)`
      },
      result: {
        chart: outcomeData,
        color: `rgba(255, 149, 0, 1)`
      }
    }
  }, [report])

  return (
    <div>
      <CashboxCharts
        data={chartsData[activeStatisticsType]}
        period={period}
        total={total}
      />
      <StatisticTypes
        activeStatisticsType={activeStatisticsType}
        setActiveStaticType={setActiveStaticType}
      />
    </div>
  )
}

export default StatisticsComponent
