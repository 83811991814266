import React, { useEffect, useMemo, useState } from "react"

import { parseDates, SportBoomTabs } from "../../constants"
import { SportBoomContentProps } from "../../types"

import TooltipComponent from "Components/Tooltip"

import { ReactComponent as InfoIcon } from "Components/icons/info_circle_24.svg"
import { useRequest } from "api/hooks"
import { detailTooltipText } from "./constants"

type DetailTooltipProps = {
  activeTab: SportBoomTabs
  filter: SportBoomContentProps
}

type DetailTooltipContentProps = {
  data: { name: string }[]
  activeTab: SportBoomTabs
}

const DetailTooltipContent = ({
  data,
  activeTab
}: DetailTooltipContentProps) => (
  <div className="DetailTooltipContentWrapper">
    <div className="DetailTooltipContentTitle">
      {detailTooltipText.title(activeTab)}
    </div>
    <div className="FlexRow FlexWrap Gap12">
      {data.map((item, key) => {
        const place = key + 1
        return (
          <div
            key={`top_10_${place}`}
            className="DetailTooltipContentItem FlexColumn"
          >
            <div className="Gray600Color Overline">
              {detailTooltipText.placeTitle(place)}
            </div>
            <div className="White100Color BodyFirstText">{item.name}</div>
          </div>
        )
      })}
    </div>
  </div>
)

const DetailInfoTooltip = ({ activeTab, filter }: DetailTooltipProps) => {
  const showInfo = activeTab !== SportBoomTabs.sport_bets

  const [data, setData] = useState<{ name: string }[]>([])

  const { request: getTop } = useRequest({
    url: `v1/sportboom/tournaments`,
    method: `POST`,
    requestBody: {
      type: activeTab,
      filters: { ...filter, dates: parseDates(filter) }
    }
  })

  const requestDeps = JSON.stringify({ ...filter, activeTab })

  const topRequestDeps = useMemo(() => ({ ...filter, activeTab }), [
    requestDeps
  ])

  useEffect(() => {
    if (activeTab !== SportBoomTabs.sport_bets) {
      getTop().then(({ data }) => setData(data))
    }
  }, [topRequestDeps])
  return (
    <div>
      {showInfo ? (
        <TooltipComponent
          direction="bottom"
          content={<DetailTooltipContent activeTab={activeTab} data={data} />}
        >
          <div className="MarginLeft8">
            <InfoIcon />
          </div>
        </TooltipComponent>
      ) : (
        <div />
      )}
    </div>
  )
}

export default DetailInfoTooltip
