import React from "react"
import { TableCardItemLength, TableCardProps } from "./types"

// Карточка мобильного представления для таблицы.
// config - массив строк карты. Может быть единичной длины (TableCardItemLength.single)
// или во всю ширину (в 2 колонки)

const TableCard = ({ config, collapsible }: TableCardProps) => {
  const content = config.map((item, index) => (
    <div
      key={`${item.description}_${index}`}
      className={`TableCardRow ${
        item.cardLength === TableCardItemLength.single
          ? "TableCardRowFullWidth"
          : ""
      } ${item.className ? item.className : ""}`}
    >
      <div className="TableCardKey">{item.description}</div>
      <div className="TableCardValue">{item.text}</div>
    </div>
  ))

  return (
    <div className={collapsible ? "TableCardCollapsibleInner" : "TableCard"}>
      {content}
    </div>
  )
}

export default TableCard
