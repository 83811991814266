import { Moment } from "moment"
import { RouteComponentProps } from "react-router-dom"
import { CheckboxValueType } from "antd/lib/checkbox/Group"

export interface VisitFilterProps {
  isOpen: boolean
  isActive: boolean
  data: MainFilter
}

export type MainFilter = {
  hall: number
  type: string
  onlyReg?: boolean
  period: string
  dates: [Moment, Moment] | null
  isShift: boolean
  shift: [number, number]
  shiftDashboards: number
}

export type RegStatRequest = {
  hall: number
  period: string
  dates: [Moment, Moment] | null
  isShift: boolean
  shift: [number, number]
}

export type VisitHoursRequest = {
  hall: number
  day: number
  chart_days: number
}

export type VisitAndRegsChart = {
  count: number
  date: Moment
}

export type RegStatChart = {
  count: number
  name: string
}

export type VisitHoursChart = {
  count: number
  hour: string
}[]

export type VisitAndRegsReport = {
  pending: boolean
  chart: VisitAndRegsChart[]
}

export type RegStatReport = {
  pending: boolean
  chart: RegStatChart[]
}

export type VisitHoursReport = {
  pending: boolean
  day: number
  chart_days: number
  chart: VisitHoursChart[]
  rushhour_weekday: number[]
}

export type GetVisit = {
  reportState: VisitAndRegsReport
  setReportState: (value: VisitAndRegsReport) => void
  filterState: VisitFilterProps
  logout: () => void
  history: RouteComponentProps["history"]
}

export type GetRegStat = {
  regStatState: RegStatReport
  setRegStatState: (value: RegStatReport) => void
  filterState: VisitFilterProps
  logout: () => void
  history: RouteComponentProps["history"]
}

export type GetVisitHours = {
  filterState: VisitFilterProps
  visitHoursState: VisitHoursReport
  setVisitHoursState: (value: VisitHoursReport) => void
  logout: () => void
  history: RouteComponentProps["history"]
}

export type ByGameOrReg = {
  title: string
  name: string
  color: string
  bg: string
}

export type RegStatProps = {
  regStatState: RegStatReport
  filterState: VisitFilterProps
}

export type RegStatSeries = {
  y: number
  name: string
  color: string
}[]

export type VisitAndRegProps = {
  reportState: VisitAndRegsReport
  filterState: VisitFilterProps
  setFilterState: (value: VisitFilterProps) => void
}

export type ByTypeSelectionProps = {
  filterState: VisitFilterProps
  setFilterState: (value: VisitFilterProps) => void
  onlyReg: boolean
}

export type OnlyRegSelectionProps = {
  filterState: VisitFilterProps
  setFilterState: (value: VisitFilterProps) => void
}

export type VisitChartProps = {
  reportState: VisitAndRegsReport
  filterState: VisitFilterProps
}

export type DashboardsStateProps = {
  all_visits?: number
  new_guests?: number
  unique_guests?: number
  pending: boolean
}

export type GetDashboards = {
  filterState: VisitFilterProps
  logout: () => void
  history: RouteComponentProps["history"]
  dashBoardState: DashboardsStateProps
  setDashBoardState: (value: DashboardsStateProps) => void
}

export type DashboardReport = {
  all_visits: number
  new_guests: number
  unique_guests: number
}

export type SelectHourProps = {
  from: number
  onChange: (value: number) => void
}

export enum ChartType {
  column = "column",
  area = "area"
}

export type VisitCalendarProps = {
  visitHoursState: VisitHoursReport
  setVisitHoursState: (value: VisitHoursReport) => void
}

export type VisitFilterComponentProps = {
  filterState: VisitFilterProps
  setFilterState: (data: VisitFilterProps) => void
  current_hall: (string | CheckboxValueType)[]
  mobileFilterOpen?: boolean
  setMobileFilterOpen?: (data: boolean) => void
}
