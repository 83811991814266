import React, { useEffect, useState } from "react"
import betRequest from "api/request"
import { useHistory } from "react-router-dom"
import { logoutUser } from "store/auth/actions"
import { useDispatch, useSelector } from "react-redux"
import { IDevelopState } from "store/developer/types"
import { getDeveloperList } from "store/developer/selector"
import { addLink } from "store/developer/actions"

type HallsCheckProps = {
  city: number[]
  location: number[]
  halls: number[]
  setSaveDisabled: (data: boolean) => void
}

const HallsCheckComponent = ({
  city,
  halls,
  location,
  setSaveDisabled
}: HallsCheckProps) => {
  const history = useHistory()
  const logout: () => void = () => dispatch(logoutUser())
  const dispatch = useDispatch()
  const developer: IDevelopState = useSelector(getDeveloperList)
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))

  const [hallsError, setHallsError] = useState<{
    not_exist: number[]
    wrong_location: number[]
  }>({ not_exist: [], wrong_location: [] })

  const effectConditions = [...city, ...halls, ...location].join(`, `)
  useEffect(() => {
    if (!!halls.length) {
      setSaveDisabled(true)
      betRequest({
        developer,
        addDeveloperLink,
        history,
        logout,
        method: `POST`,
        requestBody: { city_id: city, country_id: location, halls },
        url: `admin/message/validate`
      })
        .then(
          ({
            data: { result }
          }: {
            data: {
              result: {
                not_exist: number[]
                wrong_location: number[]
              }
            }
          }) => {
            setHallsError({
              not_exist: result.not_exist,
              wrong_location: result.wrong_location
            })
            if (!result.not_exist.length && !result.wrong_location.length) {
              setSaveDisabled(false)
            }
          }
        )
        .catch(() => setSaveDisabled(false))
    } else {
      setHallsError({ not_exist: [], wrong_location: [] })
      setSaveDisabled(false)
    }
  }, [effectConditions])
  return (
    <div>
      {hallsError.wrong_location && !!hallsError.wrong_location.length && (
        <div className="ErrorTextWrapper">
          <span className="ErrorText">
            Данные клубы не корректны для выбраной локации:{" "}
            {hallsError.wrong_location.join(`, `)}
          </span>
        </div>
      )}
      {hallsError.not_exist && !!hallsError.not_exist.length && (
        <div className="ErrorTextWrapper">
          <span className="ErrorText">
            Данные клубы не существуют в природе:{" "}
            {hallsError.not_exist.join(`, `)}
          </span>
        </div>
      )}
    </div>
  )
}

export default HallsCheckComponent
