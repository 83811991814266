import React from "react"
import HighchartsReact from "highcharts-react-official"
import * as Highcharts from "highcharts"
import { VisitHoursReport } from "../types"

const VisitPeaks = ({
  visitHoursState
}: {
  visitHoursState: VisitHoursReport
}) => {
  const day = visitHoursState.day
  const seriesData = !!visitHoursState.chart.length
    ? visitHoursState.chart[day].map(item => ({
        y: item.count as number,
        value: item.count as number,
        name: item.hour
      }))
    : []

  const options: Highcharts.Options = {
    chart: {
      height: 265,
      marginLeft: 0,
      marginTop: 100,
      marginRight: 0
    },
    title: {
      text: ""
    },
    xAxis: {
      tickWidth: 0,
      tickInterval: 4,
      labels: {
        rotation: 0,
        align: `center`,
        formatter: function() {
          let label = this.axis.defaultLabelFormatter.call(this)
          if (label.length > 1) {
            label = `${label}:00`
          } else {
            label = `0${label}:00`
          }
          return `<span id="hoveredLabel" class="VisitPeaksLabel">${label}</span>`
        }
      }
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      crosshair: {
        width: 1,
        color: `#fff`
      },
      title: {
        useHTML: true,
        text: "",
        align: "high",
        style: {
          fontWeight: "500",
          fontSize: "12px",
          textAlign: "left",
          color: "#868E96"
        },
        rotation: 0,
        x: 65,
        y: -50
      },
      labels: {
        enabled: false
      },
      gridLineDashStyle: `Dash`,
      gridLineColor: `#F2F2F7`,
      gridLineWidth: 1,
      gridZIndex: 0
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        // eslint-disable-next-line
        //@ts-ignore
        borderRadiusTopLeft: 4,
        // eslint-disable-next-line
        //@ts-ignore
        borderRadiusTopRight: 4
      },
      column: {
        pointPadding: 0,
        groupPadding: 0
      },
      area: {
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 8
            }
          }
        },
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        threshold: null
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        type: `column`,
        data: seriesData,
        color: `#63E6BE`
      }
    ]
  }

  return (
    <div className="CashboxChartsWrapper">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default VisitPeaks
