import React from "react"

import moment from "moment"

import { GamesData } from "store/dictionary/types"

import { valueToCurrency } from "constants/index"

import { PickerFormats } from "Components/FilterInfoPanel/types"

import { ReactComponent as EmptyTable } from "./empty_tale.svg"

import { TopThreeFilter } from "./types"

export const topThreeText = {
  title: `Топ 3`,
  tooltip: `Раздел содержит информацию по результатам акции Топ 3. При переходе в раздел отобразится рейтинг гостей за текущую дату, если акция в клубе активна. В календаре фильтров доступны даты только в дни проведения акции. Даты, когда акция не проводилась, не будут доступны для выбора. Просматривать информацию можно только в рамках одного клуба и только за один день.`,
  dateFilter: `Дата проведения акции`,
  empty: {
    title: `Нет акции`,
    description: (
      <div className="FlexColumn">
        <span>{`Сегодня акция «Топ 3» в этом клубе не проводится.`}</span>
        <span>{`Посмотреть предыдущие акции можно`}</span>
        <span>{`выбрав необходимую дату в фильтрах.`}</span>
      </div>
    ),
    icon: <EmptyTable />
  }
}

export const getInitialFilter = (hall: string): TopThreeFilter => {
  return {
    isOpen: false,
    mobileFilter: false,
    isActive: true,
    data: {
      date: moment().startOf(`day`),
      hall
    }
  }
}

export const getCurrenHallFromFilter = (filter: {
  hall: string
  halls?: number[]
}) => (filter?.halls || [])[0] || Number(filter?.hall)

export const TABLE_WIDTH = 1300

export const TOP_THREE_FILTERS = `TOP_THREE_FILTERS`

const placeConfig = {
  1: `Yellow300Background`,
  2: `Gray300Background`,
  3: `Orange300Background`
}

export const getColumns = (gameList: GamesData[]) => [
  {
    title: () => (
      <span className="TableHeaderFirst TableHeader">{`Место`}</span>
    ),
    dataIndex: "place_number",
    key: "place_number",
    render: (place_number: 1 | 2 | 3) => {
      return (
        <div
          className={`TopThreePlaceWrapper TextDefaultBold ${placeConfig[place_number]}`}
        >
          {place_number}
        </div>
      )
    }
  },
  {
    title: () => <span className="TableHeaderFirst TableHeader">{`Дата`}</span>,
    dataIndex: "date",
    key: "date",
    render: (date: string) => {
      const currentDate = moment(date, PickerFormats.dateLongFromBack)
      return (
        <div className="TableItemMaxWidth TableHeaderFirst FlexRow Gap8">
          <span className="TextOne">
            {currentDate.format(PickerFormats.dayMonthYear)}
          </span>
        </div>
      )
    }
  },
  {
    title: () => <span className="TableHeaderFirst TableHeader">{`Игра`}</span>,
    dataIndex: "game_id",
    key: "game_id",
    render: (game_id: string) => {
      const currentGame = gameList.find(item => item.id === game_id)
      return (
        <div className="FlexRow Gap8 TableHeaderFirst">
          <img className="TopTreeGameImg" src={currentGame?.image} />
          <div className="TextOne PaddingTop2">{currentGame?.name}</div>
        </div>
      )
    }
  },
  {
    title: () => (
      <span className="TableHeaderFirst TableHeader">{`Номер игры`}</span>
    ),
    dataIndex: "game_number",
    key: "game_number",
    render: (game_number: string) => (
      <div className="TableItemMaxWidth TableHeaderFirst TextOne">
        {game_number}
      </div>
    )
  },
  {
    title: () => (
      <span className="TableHeaderFirst TableHeader">{`Номинал ставки`}</span>
    ),
    dataIndex: "bet_nominal",
    key: "bet_nominal",
    render: (bet_nominal: string) => {
      const value = valueToCurrency({
        value: Number(bet_nominal),
        withoutZero: true,
        withoutFixed: true
      })
      return (
        <div className="TableItemMaxWidth TableHeaderFirst TextOne">{`${value} ₽`}</div>
      )
    }
  },
  {
    title: () => (
      <span className="TableHeaderFirst TableHeader">{`Выигрыш`}</span>
    ),
    dataIndex: "win_sum",
    key: "win_sum",
    render: (win_sum: string) => {
      const value = valueToCurrency({
        value: Number(win_sum),
        withoutZero: true,
        withoutFixed: true
      })
      return (
        <div className="TableItemMaxWidth TableHeaderFirst TextOne">{`${value} ₽`}</div>
      )
    }
  },
  {
    title: () => (
      <span className="TableHeaderFirst TableHeader ">{`Телефон гостя`}</span>
    ),
    dataIndex: "guest_phone",
    key: "guest_phone",
    render: (guest_phone: string) => {
      return (
        <div className="TableItemMaxWidth TableHeaderFirst TextOne">
          {guest_phone}
        </div>
      )
    }
  },
  {
    title: () => (
      <span className="TableHeaderFirst TableHeader">{`Распределение ПФ`}</span>
    ),
    dataIndex: "bonus_sum",
    key: "bonus_sum",
    render: (bonus_sum: string) => {
      const value = valueToCurrency({
        value: Number(bonus_sum),
        withoutZero: true,
        withoutFixed: true
      })
      return (
        <div className="TableItemMaxWidth TableHeaderFirst TextOne">{`${value} Б`}</div>
      )
    }
  },
  {
    title: () => (
      <span className="TableHeaderFirst TableHeader">{`Начисления`}</span>
    ),
    dataIndex: "has_payouts",
    key: "has_payouts",
    render: (has_payouts: boolean) => {
      return (
        <div
          className={`TableItemMaxWidth TableHeaderFirst TextOne ${
            has_payouts ? `Green700Color` : `Red600Color`
          }`}
        >
          {has_payouts ? `Да` : `Нет`}
        </div>
      )
    }
  }
]
