import React, { useEffect, useState } from "react"

import { useRequest, useRequestParams } from "api/hooks"
import moment from "moment"

import { parseOneDateWithoutGmtToBack } from "constants/index"

import { SelectComponent } from "Components/Select"
import EmptyTable from "Components/EmptyTable"

import {
  ChartsDataType,
  GuestHistoryMetricChart,
  GuestHistoryMetricChartData,
  GuestHistoryMetricsProps,
  GuestHistoryMetricTable
} from "./types"
import { guestHistoryMetricsConstants } from "./constants"

import GuestMetricTableComponent from "./Table"
import GuestHistoryMetricChartComponent from "./Charts"
import { initialTableData, prepareValueForChart } from "./Table/constants"

import { dataWithoutPeriod, dataEmptyChart } from "../../constants"

const GuestHistoryMetrics = ({ filter }: GuestHistoryMetricsProps) => {
  const { requestCurrentHall } = useRequestParams()
  const [tableData, setTableData] = useState<GuestHistoryMetricTable>({
    pending: false,
    data: initialTableData
  })

  const [chartPeriod, toggleChartPeriod] = useState<0 | 1>(0)
  const [chartData, setChartData] = useState<GuestHistoryMetricChart>({
    pending: false,
    data: []
  })

  const { request: getTableData } = useRequest({
    url: `v1/guests/history/metrics`,
    method: `POST`,
    requestBody: { phone: filter.phone, hall: requestCurrentHall }
  })

  const { request: getChart } = useRequest({
    url: `v1/guests/history/profit`,
    method: `POST`,
    requestBody: {
      hall: requestCurrentHall,
      ...filter,
      dates:
        !!chartPeriod && filter.dates
          ? parseOneDateWithoutGmtToBack(filter.dates)
          : undefined,
      games: undefined,
      includeFreebets: undefined,
      onlyActiveDays: undefined
    }
  })

  useEffect(() => {
    setTableData(prev => ({ ...prev, pending: true }))
    getTableData()
      .then(({ data }) =>
        setTableData(prev => ({ ...prev, pending: true, data }))
      )
      .catch(() => setTableData(prev => ({ ...prev, pending: false })))
  }, [filter.phone])

  useEffect(() => {
    getChart().then(({ data }: { data: GuestHistoryMetricChartData[] }) => {
      if (!!data) {
        const responseChartData: ChartsDataType[] = data.map(item => ({
          x: moment(
            item.date,
            guestHistoryMetricsConstants.date.format
          ).valueOf(),
          y: prepareValueForChart(`${item.amount}`),
          value: item.amount,
          name: `${moment(item.date, guestHistoryMetricsConstants.date.format)
            .format(guestHistoryMetricsConstants.date.week)
            ?.toUpperCase()} ${moment(
            item.date,
            guestHistoryMetricsConstants.date.format
          ).format(guestHistoryMetricsConstants.date.format)}`
        }))

        setChartData({ ...chartData, data: responseChartData })
      }
    })
  }, [filter.phone, filter.dates, chartPeriod])

  const emptyDataSource = !filter.dates ? dataWithoutPeriod : dataEmptyChart

  return (
    <div>
      <GuestMetricTableComponent data={tableData.data} />
      <div className="GuestHistoryMetricChartHeader FlexRow FlexWrap">
        <div className="GuestHistoryMetricChartTitle HeadlineThirdText">
          {guestHistoryMetricsConstants.title}
        </div>
        <div className="GuestHistoryMetricChartSwitcher">
          <SelectComponent
            value={chartPeriod}
            onChange={value => toggleChartPeriod(value)}
            greyBackground={true}
            options={guestHistoryMetricsConstants.switcher}
            allowClear={false}
          />
        </div>
      </div>
      {(filter.dates || chartPeriod === 0) && !!chartData.data?.length ? (
        <GuestHistoryMetricChartComponent data={chartData.data} />
      ) : (
        <div className="GuestHistoryEmptyDataWrapper">
          <EmptyTable
            mobile={emptyDataSource.mobile}
            desktop={emptyDataSource.desktop}
          />
        </div>
      )}
    </div>
  )
}

export default GuestHistoryMetrics
