export const commentText = {
  modalTitle: `Заменить комментарий`,

  save: `Сохранить`,
  add: `Добавить пустой`,
  cancel: `Отмена`,
  back: `Назад`,

  labelInput: `Новый комментарий по гостю`,
  maxLength: 128,
  placeholder: `Добавьте комментарий по гостю`,

  labelHistory: `История комментариев`,
  title: `Заменить комментарий`,
  emptyHistory: `Комментариев по этому гостю не оставляли. Сконцентрируйтесь и станьте первым.`,

  format: `DD.MM.YYYY`,
  smallFormat: `DD MMM YYYY`,

  defaultCurrent: `Добавьте комментарий по гостю...`
}
