import { useRequest } from "api/hooks"

import { ConfirmModalData, ConfirmTypes } from "./types"

export const useNotifyMethods = ({
  hall,
  confirmData,
  baseUrl
}: {
  hall: number
  haveActive: boolean
  confirmData: ConfirmModalData
  baseUrl: string
}) => {
  const { request: pause } = useRequest({
    url: `v1/requests/${baseUrl}/mailing/status`,
    requestBody: {
      hall,
      is_active: !confirmData.is_active,
      user_id: confirmData.id
    }
  })
  const { request: remove } = useRequest({
    url: `v1/requests/${baseUrl}/mailing/remove`,
    requestBody: { hall, user_id: confirmData.id }
  })

  const isRemove =
    confirmData.type === ConfirmTypes.removeOne ||
    confirmData.type === ConfirmTypes.removeAll

  return isRemove ? remove : pause
}

export const getConfirmTextByConfig = (data: ConfirmModalData) => {
  switch (data.type) {
    case ConfirmTypes.pauseOne:
      return {
        title: `Остановить рассылку?`,
        cancel: `Отмена`,
        approve: `Остановить рассылку`,
        description: `Остановить рассылку для пользователя?`
      }
    case ConfirmTypes.pauseAll:
      return {
        title: `Остановить рассылку?`,
        cancel: `Отмена`,
        approve: `Остановить рассылку`,
        description: `Остановка рассылки для всех пользователей`
      }
    case ConfirmTypes.resumeOne:
      return {
        title: `Возобновить рассылку?`,
        cancel: `Отмена`,
        approve: `Возобновить рассылку`,
        description: `Возобновить рассылки для пользователя?`
      }
    case ConfirmTypes.resumeAll:
      return {
        title: `Возобновить рассылку?`,
        cancel: `Отмена`,
        approve: `Возобновить рассылку`,
        description: `Возобновить рассылки для всех пользователей`
      }
    case ConfirmTypes.removeOne:
      return {
        title: `Удалить пользователя?`,
        cancel: `Отмена`,
        approve: `Удалить пользователя`,
        description: `Удалить пользователя из списка тех, кто будет получать оповещения о смене статуса?`
      }
    case ConfirmTypes.removeAll:
      return {
        title: `Очистить список?`,
        cancel: `Отмена`,
        approve: `Очистить список`,
        description: `Удалить всех пользователей из списка тех, кто будет получать оповещения о смене статуса?`
      }
  }
}
