import React, { useMemo } from "react"

import { useSelector } from "react-redux"

import { getInfoContent, isMobile } from "constants/index"

import { getTransferHalls } from "store/dictionary/selector"

import { ReactComponent as CoinsIcon } from "Components/icons/money_coins.svg"
import { ReactComponent as CartIcon } from "Components/icons/money_cart.svg"
import { ReactComponent as BagIcon } from "Components/icons/money_bag.svg"
import { ReactComponent as TransferIcon } from "Components/icons/money_transfer.svg"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle.svg"

import TooltipComponent from "Components/Tooltip"

import { insideTableText } from "./constants"
import { CashboxInsideProps, DetailsData } from "./types"

const getDetailData = ({
  source,
  showTransfer
}: {
  source?: DetailsData
  showTransfer?: boolean
}) => (
  <div>
    <div>
      {source?.income?.cash && (
        <div className="CashboxInsideRow">
          <div className="CashboxTableItem FlexRow">
            <div>
              <CoinsIcon />
            </div>
            <span className="CashboxTableInsideItemDescription">
              {insideTableText.cash}
            </span>
          </div>
          <div className="CashboxTableItem CashboxTableItemValue">
            {source.income.cash}
          </div>
          <div className="CashboxTableItem CashboxTableItemValue">
            {source.outcome.cash}
          </div>
          <div
            className={`CashboxTableItem CashboxTableItemValue ${insideTableText.checkStyle(
              source.result?.cash
            )}`}
          >
            {source.result.cash}
          </div>
        </div>
      )}
      {source?.income?.non_cash && (
        <div className="CashboxInsideRow">
          <div className="CashboxTableItem FlexRow">
            <div>
              <CartIcon />
            </div>
            <span className="CashboxTableInsideItemDescription">
              {insideTableText.non_cash}
            </span>
          </div>
          <div className="CashboxTableItem CashboxTableItemValue">
            {source.income.non_cash}
          </div>
          <div className="CashboxTableItem CashboxTableItemValue">
            {source.outcome.non_cash}
          </div>
          <div
            className={`CashboxTableItem CashboxTableItemValue ${insideTableText.checkStyle(
              source.result?.non_cash
            )}`}
          >
            {source.result.non_cash}
          </div>
        </div>
      )}
      {source?.income?.collection && (
        <div className="CashboxInsideRow">
          <div className="CashboxTableItem FlexRow">
            <div>
              <BagIcon />
            </div>
            <span className="CashboxTableInsideItemDescription">
              {insideTableText.collection}
            </span>
          </div>
          <div className="CashboxTableItem CashboxTableItemValue">
            {source?.income?.collection}
          </div>
          <div className="CashboxTableItem CashboxTableItemValue">
            {source.outcome.collection}
          </div>
          <div
            className={`CashboxTableItem CashboxTableItemValue ${insideTableText.checkStyle(
              source.result?.collection
            )}`}
          >
            {source.result.collection}
          </div>
        </div>
      )}
      {source?.income?.transfer && showTransfer && (
        <div className="CashboxInsideRow">
          <div className="CashboxTableItem FlexRow">
            <div>
              <TransferIcon />
            </div>
            <span className="CashboxTableInsideItemDescription">
              {insideTableText.transfer}
            </span>
          </div>
          <div className="CashboxTableItem CashboxTableItemValue">
            {source.income?.transfer}
          </div>
          <div className="CashboxTableItem CashboxTableItemValue">
            {source.outcome?.transfer}
          </div>
          <div
            className={`CashboxTableItem CashboxTableItemValue ${insideTableText.checkStyle(
              source.result?.transfer
            )}`}
          >
            {source.result?.transfer}
          </div>
        </div>
      )}
    </div>
  </div>
)

const CashboxInside = ({ halls, date, details }: CashboxInsideProps) => {
  const isTotal = !date
  const transferHalls = useSelector(getTransferHalls)
  const hallsOnly = useMemo(() => halls.map(Number), [halls])
  return (
    <div className="CashboxTableInsideContent">
      {isTotal &&
        !!details &&
        getDetailData({
          source: details,
          showTransfer: transferHalls.some(el => hallsOnly.includes(el))
        })}
      {(!isTotal || (isTotal && halls.length > 1)) &&
        halls.map(item => {
          return (
            <div
              key={`${item.hall}`}
              className={halls.length > 1 ? "CashboxTableInsideWithBorder" : ""}
            >
              {item?.total && (
                <div className="CashboxInsideRow CashboxInsideRowTotal">
                  <div className="CashboxTableItem CashboxTableItemValue">
                    <div className="FlexRow">
                      <span>{insideTableText.hall(item.hall)}</span>
                      <TooltipComponent
                        title={
                          <div className="CashboxHallInfoPopover">
                            {getInfoContent({ hall: item.hall })}
                          </div>
                        }
                        trigger="hover"
                        direction={isMobile ? `bottom` : `right`}
                      >
                        <div className="ReportInsideHallInfo">
                          <InfoIcon />
                        </div>
                      </TooltipComponent>
                    </div>
                  </div>
                  <div className="CashboxTableItem CashboxTableItemValue">
                    {item.total?.income}
                  </div>
                  <div className="CashboxTableItem CashboxTableItemValue">
                    {item.total?.outcome}
                  </div>
                  <div
                    className={`CashboxTableItem CashboxTableItemValue ${insideTableText.checkStyle(
                      item.total?.result
                    )}`}
                  >
                    {item.total?.result}
                  </div>
                </div>
              )}
              {getDetailData({
                source: item,
                showTransfer: transferHalls.includes(Number(item.hall))
              })}
            </div>
          )
        })}
    </div>
  )
}
export default CashboxInside
