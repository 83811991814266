import React, { useCallback, useMemo } from "react"

import { ReactComponent as InfoIcon } from "./info_icon.svg"
import { ReactComponent as DoneIcon } from "./done_circle_16.svg"
import { StateProps } from "../types"
import UploaderComponent from "./UploaderComponent"
import { validatePhotos } from "../helpers"

const HallsCards = ({
  state,
  minCount,
  setState,
  minCardsText,
  titleText,
  selectOptions,
  initialCard,
  openPreview
}: StateProps) => {
  const isValid = useMemo(() => validatePhotos(minCount, state), [state])

  const handleSetZone = (zone: string, index: number) => {
    const newState = state.map((item, i) =>
      i === index ? { ...item, zone } : item
    )
    setState(newState)
  }
  const handleSetPhotoLink = (link: string, index: number) => {
    const newState = state.map((item, i) =>
      i === index ? { ...item, link } : item
    )

    setState([...newState, initialCard])
  }

  const handleDeleteCardLink = useCallback(
    (index: number) => {
      const newState = state.filter((_, i) => i !== index)
      setState(newState)
    },
    [state]
  )

  return (
    <div className="Geoservices_Photos">
      <span className="HeadlineThirdText">{titleText}</span>
      <div className="Geoservices_Photos__Info">
        {isValid ? (
          <DoneIcon className="Geoservices_Photos__Info_Done" />
        ) : (
          <InfoIcon className="Geoservices_Photos__Info_Icon" />
        )}
        <span
          className={`Geoservices_Photos__Info_Text ${isValid &&
            "Geoservices_Photos__Info_Text-Valid"}`}
        >
          {minCardsText}
        </span>
      </div>
      <div className="Geoservices_Photos__Upload">
        {state.map((card, index) => {
          return (
            <UploaderComponent
              key={`${card.image}_${index}`}
              index={index}
              zone={card.zone}
              date={card.uploaded_at}
              setZone={handleSetZone}
              imageStateLink={card.image}
              setPhotoLink={handleSetPhotoLink}
              deleteCardLink={handleDeleteCardLink}
              options={selectOptions}
              openPreview={openPreview}
            />
          )
        })}
      </div>
    </div>
  )
}

export default HallsCards
