import React, { useState } from "react"
import betRequest from "api/request"

import { parseDateToBack } from "constants/index"

import { ReactComponent as AttachIcon } from "Components/icons/attach_grey.svg"
import { ReactComponent as AttencionIcon } from "Components/icons/attencion.svg"
import { ReactComponent as NotificationIcon } from "Components/icons/notification.svg"
import { ReactComponent as OutgoingIcon } from "Components/icons/outgoing.svg"
import { ReactComponent as HistoryIcon } from "Components/icons/history_notifications.svg"
import { ReactComponent as CloseModalIcon } from "Components/icons/close_modal.svg"
import { ReactComponent as CommentIcon } from "Components/icons/comment.svg"

import moment, { Moment } from "moment"

import { IDevelopState } from "store/developer/types"
import { RouteComponentProps } from "react-router-dom"
import { NotifiFilterStateData } from "../index"
import { Tooltip } from "antd"

export type notificationsDataType = {
  key: number
  id: number
  date: string
  title: string
  text: string
  comment: string
  files: string[]
  hall_total: number[]
  author: string
  is_push: boolean
  is_require: boolean
}[]

type getPropsType = {
  listUrl: string
  setTableData: (data: { data: notificationsDataType; total: number }) => void
  setTablePending: (data: boolean) => void
  pagination: { limit: number; offset: number }
  filter?: NotifiFilterStateData
  setFilterPending?: (value: boolean) => void
  search?: {
    hall?: string
    type?: string
    search?: string
    pending: boolean
  }
  setSearchPending?: (value: boolean) => void
  logout: () => void
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  history: RouteComponentProps["history"]
  type?: string
}

const getNotifiData = ({
  listUrl,
  type,
  setTableData,
  setTablePending,
  filter,
  search,
  setSearchPending,
  setFilterPending,
  pagination,
  logout,
  developer,
  addDeveloperLink,
  history
}: getPropsType) => {
  if (!!filter && !!setFilterPending) {
    setTablePending(true)
    const dateToSave = !!filter.date
      ? parseDateToBack({ date: filter.date, gmt: `3`, isObj: true })
      : undefined
    const withOutGoing = !!type && type === `outgoing` ? { outgoing: true } : {}
    const withoutOutGoing = !!type && type === `list` ? { outgoing: false } : {}

    betRequest({
      url: listUrl,
      method: `POST`,
      logout,
      requestBody: {
        ...withOutGoing,
        ...withoutOutGoing,
        limit: pagination.limit,
        offset: pagination.offset,
        filter: { ...filter, date: dateToSave }
      },
      developer,
      addDeveloperLink,
      history
    })
      .then(({ data: { result }, headers }: any) => {
        const resultWithKey = result.map((item: any) => ({
          ...item,
          key: item.id
        }))
        setTableData({ data: resultWithKey, total: headers[`total-count`] })
        setTablePending(false)
        setFilterPending(false)
      })
      .catch(() => {
        setTablePending(false)
        setFilterPending(false)
      })
  }
  if (!!search && !!setSearchPending) {
    setTablePending(true)
    setSearchPending(true)
    betRequest({
      url: listUrl,
      method: `POST`,
      logout,
      requestBody: {
        limit: pagination.limit,
        offset: pagination.offset,
        type: search.type,
        search: search.search
      },
      developer,
      addDeveloperLink,
      history
    })
      .then(({ data: { result }, headers }: any) => {
        const resultWithKey = result.map((item: any) => ({
          ...item,
          key: item.id
        }))
        setTableData({ data: resultWithKey, total: headers[`total-count`] })
        setTablePending(false)
        setSearchPending(false)
      })
      .catch(() => {
        setTablePending(false)
        setSearchPending(false)
      })
  }
}

export default getNotifiData

const HistoryComponent = ({
  author,
  record
}: {
  author: string
  record: {
    id: number
    changes: { name: string; status: string; date: Moment }[]
  }
}) => {
  const [historyModal, setHistoryModal] = useState<boolean>(false)
  const getStatusData = (status: string): { color: string; text: string } => {
    switch (status) {
      case `Создал`:
        return { color: `#EBFBEE`, text: `Создал` }
      case `Изменил`:
        return { color: `#FFF4E6`, text: `Изменил` }
      case `Удалил`:
        return { color: `#FFF5F5`, text: `Удалил` }
      case `Восстановил`:
        return { color: `#FFF9DB`, text: `Восстановил` }
      case `Окончательно удалил`:
        return { color: `#FFF5F5`, text: `Удалил` }
      default:
        return { color: `#EBFBEE`, text: `Создал` }
    }
  }
  return (
    <div className="FlexRow">
      {!!record.changes && record.changes.length > 1 && (
        <div
          className="NotificationsRowToolsAuthorHistoryIcon"
          onClick={e => {
            e.stopPropagation()
            setHistoryModal(!historyModal)
          }}
        >
          <HistoryIcon />
          {historyModal && (
            <div>
              <div className="NotificationsRowToolsAuthorHistoryArea FlexRow">
                <div
                  className="HistoryModal"
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <div className="ModalTitle">
                    История сообщения №{record.id}
                  </div>
                  <div className="ModalTable">
                    {record.changes.map((item, key) => {
                      const statusData = getStatusData(item.status)
                      const isLast = key === record.changes.length - 1
                      return (
                        <div
                          key={`history_${moment(item.date)}`}
                          className="FlexRow ModalTableRow"
                          style={{
                            borderBottom: isLast ? `none` : `1px solid #E9ECEF`
                          }}
                        >
                          <div
                            className="ModalTableStatus"
                            style={{ backgroundColor: statusData.color }}
                          >
                            <div className="ModalTableStatusTitle FlexRow">
                              {statusData.text}
                              <div className="ModalTableStatusTitlePrefix">
                                (а)
                              </div>
                            </div>
                          </div>
                          <div className="ModalTableName">{item.name}</div>
                          <div className="ModalTableDate">
                            {moment(item.date).format(`DD.MM.YYYY в HH:mm`)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div
                  className="HistoryModalClose"
                  onClick={() => setHistoryModal(!historyModal)}
                >
                  <CloseModalIcon />
                </div>
              </div>
              <div className="Overlay" />
            </div>
          )}
        </div>
      )}
      <div className="NotificationsRowToolsAuthor">{author}</div>
    </div>
  )
}

export const notificationsColumns = ({
  onIconClick
}: {
  onIconClick?: (type: string, data?: string) => void
}) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Дата и время",
      width: 120,
      dataIndex: "date",
      className: `NotifiTableDateColumn`,
      key: "date",
      render: (date: any) => {
        const dateFormat = moment(date).format(`DD.MM.YYYY`)
        const timeFormat = moment(date).format(`HH:mm`)
        const isOngoing = moment(date).valueOf() > moment().valueOf()
        return (
          <div className="NotificationsTableDate FlexColumn">
            <span className="NotificationsTableTitle">{dateFormat}</span>
            <div className="FlexRow">
              {isOngoing && (
                <Tooltip
                  overlayClassName="InfoTooltipWrapper"
                  placement="top"
                  title={`Будет отправлено ${moment(date).format(
                    `DD.MM.YY в HH:mm`
                  )}`}
                  trigger="hover"
                  color="#343A40"
                >
                  <OutgoingIcon />
                </Tooltip>
              )}
              <span>{timeFormat}</span>
            </div>
          </div>
        )
      }
    },
    {
      title: "Сообщение",
      dataIndex: "text",
      key: "text",
      render: (text: string, record: { title: string; comment: string }) => {
        const { title } = record
        const firstLine = (text || ``).split(`\n`)[0]
        return (
          <div className="NotificationsTableText FlexColumn">
            {title && <span className="NotificationsTableTitle">{title}</span>}
            <div className="FlexRow NotificationsTableTextRow">
              <span dangerouslySetInnerHTML={{ __html: firstLine }} />
            </div>
          </div>
        )
      }
    },
    {
      title: "Кол-во",
      dataIndex: "halls",
      key: "halls",
      width: 100,
      render: (halls: number[]) => (halls ? <div>{halls.length}</div> : <div />)
    },
    {
      title: "Автор",
      dataIndex: "author",
      key: "author",
      width: 200,
      render: (author: string, record: any) => (
        <HistoryComponent author={author} record={record} />
      )
    },
    {
      title: "Доп. функционал",
      width: 100,
      dataIndex: "is_push",
      key: "is_push",
      render: (
        is_push: boolean,
        record: { is_require: boolean; files: string[]; comment: string | null }
      ) => {
        const { is_require, files, comment } = record
        return (
          <div className="NotificationsRowTools FlexRow">
            {!!files && !!files.length && (
              <Tooltip
                overlayClassName="InfoTooltipWrapper"
                placement="top"
                title="Вложения"
                trigger="hover"
                color="#343A40"
              >
                <div>
                  <AttachIcon />
                </div>
              </Tooltip>
            )}
            {is_push && (
              <Tooltip
                overlayClassName="InfoTooltipWrapper"
                placement="top"
                title="Пуш-уведомление"
                trigger="hover"
                color="#343A40"
              >
                <div className="NotificationsRowToolsIcon">
                  <NotificationIcon />
                </div>
              </Tooltip>
            )}
            {is_require && (
              <Tooltip
                overlayClassName="InfoTooltipWrapper"
                placement="top"
                title="Обязательное"
                trigger="hover"
                color="#343A40"
              >
                <div className="NotificationsRowToolsIcon">
                  <AttencionIcon />
                </div>
              </Tooltip>
            )}
            {comment && (
              <Tooltip
                overlayClassName="InfoTooltipWrapper"
                title={comment}
                trigger="hover"
                color="#343A40"
              >
                <div
                  onClick={e => {
                    e.stopPropagation()

                    onIconClick?.("comment", comment)
                  }}
                  className="NotificationsRowToolsIcon"
                >
                  <CommentIcon />
                </div>
              </Tooltip>
            )}
          </div>
        )
      }
    }
  ]
}
