import { configureStore } from "@reduxjs/toolkit"
import guidebookReducer from "./index"

import { hallsReducer } from "store/halls/reducer"
import { authReducer } from "store/auth/reducer"

import { forEach, size } from "lodash"

const main = configureStore({
  reducer: {
    guidebook: guidebookReducer,
    halls: hallsReducer,
    auth: authReducer
  }
})

export default main

export type RootState = ReturnType<typeof main.getState>
export type AppDispatch = typeof main.dispatch

const selectors = {
  getList: (state: RootState) => state.guidebook,
  getCard: (state: RootState, id: number) => state.guidebook.info[id],
  getCardInfo: (state: RootState, id: number) => {
    const { status, haveChanges, attachments } = state.guidebook.info[id]
    return { status, haveChanges, attachments }
  },
  isReadyForFirstConsideration: (state: RootState) => {
    let isReady = true
    const guidebookStatus = state.guidebook.status
    const guidebookInfo = state.guidebook.info
    if (!!guidebookStatus) {
      return false
    } else {
      forEach(guidebookInfo, function(value) {
        if (
          value.required &&
          (!value.attachments || value.minCount > size(value.attachments))
        ) {
          isReady = false
        }
      })
      return isReady
    }
  }
}

export const {
  getList,
  getCard,
  getCardInfo,
  isReadyForFirstConsideration
} = selectors
