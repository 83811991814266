import React, { useEffect, useMemo, useState } from "react"
import { useRequest, useRequestBody } from "api/hooks"

import { CupisContentData, CupisContentReportData } from "./types"
import { initialData, getColumns } from "./constants"

import { CupisReportFilterData } from "../types"

import InsideContent from "./Inside"

import NewTableComponent, { expandIcon } from "Components/TableNew"
import EmptyTable from "Components/EmptyTable"
import { parseDateToBack } from "constants/index"

const CupisContent = ({ filter }: { filter: CupisReportFilterData }) => {
  const [reportData, setReportData] = useState<CupisContentData>(initialData)

  const { pagination, setPagination } = useRequestBody()

  const dates = parseDateToBack({
    date: filter.dates
  })

  const requestDeps = JSON.stringify({ ...filter, ...pagination })
  const { request: getData } = useRequest({
    url: `v1/reports/cupis`,
    method: `POST`,
    requestBody: {
      ...filter,
      dates,
      pagination
    }
  })

  useEffect(() => {
    setReportData({ ...reportData, pending: true })
    getData()
      .then(({ data, headers }) => {
        const dataWithKeys = data.map(
          (item: CupisContentReportData, key: number) => ({ ...item, key })
        )
        setReportData({
          ...reportData,
          pending: false,
          data: dataWithKeys,
          total: Number(headers[`total-count`] || 0)
        })
      })
      .catch(() => setReportData({ ...reportData, pending: false }))
  }, [requestDeps])

  const columnsByPeriod = useMemo(() => getColumns(filter.period), [
    filter.period
  ])

  return (
    <div>
      {!!reportData.total && (
        <NewTableComponent
          pagination={{
            value: pagination,
            total: reportData.total,
            onChange: setPagination
          }}
          loading={reportData.pending}
          columns={columnsByPeriod}
          data={reportData.data}
          width={920}
          withTotal={true}
          rowClassName="ExpandableTableRow"
          expandable={{
            expandRowByClick: true,
            rowExpandable: (record: CupisContentReportData) => {
              return record.inside && record.inside.length > 0
            },
            expandedRowRender: (
              record: CupisContentReportData,
              index: any,
              indent: any,
              expanded: any
            ) => (
              <div>
                {expanded && !reportData.pending && (
                  <InsideContent data={record.inside} date={record.date} />
                )}
              </div>
            ),
            expandIcon: ({
              record,
              expanded,
              onExpand
            }: {
              expanded: boolean
              record: any
              onExpand: (data: any, e: any) => void
              withTotal: boolean
            }) =>
              expandIcon({
                record,
                expanded,
                onExpand,
                withTotal: record.inside?.length
              })
          }}
        />
      )}
      {!reportData.total && !reportData.pending && <EmptyTable />}
    </div>
  )
}

export default CupisContent
