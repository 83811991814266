import React from "react"
import { Modal } from "antd"

import { ButtonPrimary } from "Components/Button"

import { textConst } from "../constants"
import { RulesPopupProps } from "../types"

const RulesPopup = ({ close, isOpened, isVisited }: RulesPopupProps) => {
  return (
    <Modal
      open={isOpened}
      centered
      wrapClassName="RulesPopup"
      closable={isVisited}
      footer={null}
      onCancel={close}
    >
      <div className="FlexColumn Gap16 RulesPopup__Wrapper">
        <span className="HeadlineFirstText">{textConst.popupTitle}</span>
        <div>
          <span className="SubtitleFirstText">
            {textConst.photoRequirements}
          </span>
          <ul>
            {textConst.photoRequirementsList.map(rule => (
              <li key={rule} className="Text1">
                {rule}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <span className="SubtitleFirstText">{textConst.notAllowed}</span>
          <ul>
            {textConst.notAllowedList.map(rule => (
              <li key={rule} className="Text1">
                {rule}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <span className="SubtitleFirstText">
            {textConst.moderationCondition}
          </span>
          <ul>
            {textConst.moderationConditionList.map(rule => (
              <li
                key={rule}
                className="Text1"
                dangerouslySetInnerHTML={{ __html: rule }}
              />
            ))}
          </ul>
        </div>
        <span className="Text1">{textConst.moderationLength}</span>
        {!isVisited && (
          <ButtonPrimary onClick={close}>
            {textConst.popupConfirm}
          </ButtonPrimary>
        )}
      </div>
    </Modal>
  )
}

export default RulesPopup
