import React from "react"
import { SelectComponent } from "Components/Select"
import { ChartPeriod } from "./types"

export const ChartPeriodComponent = ({
  value,
  setValue,
  disabled
}: {
  value: ChartPeriod
  setValue: (val: ChartPeriod) => void
  disabled?: boolean
}) => {
  const options = [
    {
      label: `По дням`,
      value: ChartPeriod.daily
    },
    {
      label: `По неделям`,
      value: ChartPeriod.weekly
    },
    {
      label: `По месяцам`,
      value: ChartPeriod.monthly
    }
  ]

  return (
    <div className="ChartPeriodWrapper">
      <SelectComponent
        value={value}
        disabled={disabled}
        options={options}
        onChange={selectValue => setValue(selectValue)}
        greyBackground={true}
        allowClear={false}
      />
    </div>
  )
}
