import React, { useState } from "react"
import moment from "moment"
import {
  create,
  getSendingTypeName,
  getSubTemplateName,
  getTemplateName,
  replaceTextForPhonePreview
} from "../helper"
import { formatPhone } from "constants/index"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import ModalComponent from "Components/Modal"
import { SMSModalProps } from "../types"
import SpinComponent from "Components/Spin"
import { smsConstants } from "../constants"
import { ReactComponent as CloseIcon } from "Components/icons/close.svg"
import ModalParams from "../ModalParams"

const SmsModalPreview = ({
  smsState,
  setSmsState,
  calcState,
  setModal,
  setSuccessModal,
  templateSmsTextSelected,
  logout,
  history
}: SMSModalProps) => {
  const [pending, setPending] = useState(false)
  const dateMsg = `${moment(smsState.dateToSend).format(
    "DD.MM.YYYY"
  )} в ${moment(smsState.timeToSend).format("HH:mm")} ${smsConstants.msk}`

  return (
    <ModalComponent
      close={() => setModal(false)}
      width={smsConstants.smsModalWidth}
      closeIcon={<CloseIcon />}
      className="SMSCreateModal"
    >
      <div className="SMSCreateModalWrapper">
        <div className="SMSCreateModal_title">{smsConstants.modalTitle}</div>
        <div className="SMSCreateModal_Content">
          <div className="SMSCreateModal_ContentRow">
            <div className="SMSCreateModal_label">
              {smsConstants.modalTypeText}
            </div>
            <div className="SMSCreateModal_infoWrapper">
              <div className="SMSCreateModal_info">
                {getTemplateName(smsState)}
              </div>
            </div>
          </div>
          <div className="SMSCreateModal_ContentRow">
            <div className="SMSCreateModal_label">
              {smsConstants.modalSubTypeText}
            </div>
            <div className="SMSCreateModal_infoWrapper">
              <div className="SMSCreateModal_info">
                {getSubTemplateName(smsState)}
              </div>
            </div>
          </div>
          <div className="SMSCreateModal_ContentRow">
            <div className="SMSCreateModal_label">
              {smsConstants.modalSmsText}
            </div>
            <div className="SMSCreateModal_infoWrapper">
              <div className="SMSCreateModal_info">
                {templateSmsTextSelected
                  ? replaceTextForPhonePreview(smsState)
                  : smsState.customSmsText}
              </div>
            </div>
          </div>
          <div className="SMSCreateModal_ContentRow">
            <div className="SMSCreateModal_label">
              {smsConstants.modalSendingTypeText}
            </div>
            <div className="SMSCreateModal_infoWrapper">
              {getSendingTypeName(smsState)}
            </div>
          </div>
          <div className="SMSCreateModal_ContentRow">
            <div className="SMSCreateModal_label">
              {smsConstants.modalCommentText}
            </div>
            <div className="SMSCreateModal_infoWrapper">
              <div className="SMSCreateModal_info">{smsState.comment}</div>
            </div>
          </div>
          <div className="SMSCreateModal_ContentRow">
            <div className="SMSCreateModal_label">
              {smsConstants.modalExtraText}
            </div>
            <ModalParams smsState={smsState} />
          </div>
          <div className="SMSCreateModal_ContentRow">
            <div className="SMSCreateModal_label">
              {smsConstants.modalFavoriteDateText}
            </div>
            <div className="SMSCreateModal_infoWrapper">
              <div className="SMSCreateModal_info">{dateMsg}</div>
            </div>
          </div>
          <div className="SMSCreateModal_ContentRow">
            <div className="SMSCreateModal_label">
              {smsConstants.modalCreditText}
            </div>
            <div className="SMSCreateModal_infoWrapper">
              <div className="SMSCreateModal_info">
                <div className="SmsModal_param">
                  {smsConstants.modalFullNameText}
                </div>
                <div className="SmsModal_value">{smsState.contactName}</div>
              </div>
            </div>
            <div className="SMSCreateModal_infoWrapper">
              <div className="SMSCreateModal_info">
                <div className="SmsModal_param">
                  {smsConstants.modalPhoneText}
                </div>
                <div className="SmsModal_value">
                  {formatPhone(smsState.contactPhone)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="SMSCreateModal_Price">
          <div className="SMSCreateModal_PriceLabel">
            {smsConstants.modalCostText}
          </div>
          {calcState.calculating ? (
            <SpinComponent />
          ) : (
            <div className="SMSCreateModal_PriceValue">
              {calcState.price} {smsConstants.rubleSymbol}
            </div>
          )}
        </div>
        <div className="SMSCreateModal_btns">
          <ButtonPrimary
            loading={pending}
            onClick={() => {
              setPending(true)
              create({
                smsState,
                setSmsState,
                setSuccessModal,
                setModal,
                setPending,
                logout,
                history
              })
            }}
          >
            {smsConstants.applyText}
          </ButtonPrimary>
          <ButtonDefault onClick={() => setModal(false)}>
            {smsConstants.cancelBtnTxt}
          </ButtonDefault>
        </div>
      </div>
    </ModalComponent>
  )
}

export default SmsModalPreview
