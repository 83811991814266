import React from "react"
import { Moment } from "moment"

import { RangePickerComponent } from "Components/DatePicker"

import { TooltipCustom, TooltipCustomSize } from "Components/Tooltip"

type TableDates = {
  dates?: [Moment, Moment]
  setDates: (val: [Moment, Moment] | undefined) => void
  filterDates?: [Moment, Moment]
  disabled: boolean
}

const tooltip = `После построения отчета по периоду при помощи бокового фильтра, в таблице по умолчанию отображается информация не более чем за 31 день установленного периода в связи с большим объемом данных. Максимальное количество дней отчета для построения таблицы, в рамках выбранного в боковом фильтре периода, не более чем 31 день. `

const TableDates = ({ dates, setDates, filterDates, disabled }: TableDates) => {
  return (
    <div>
      {!!dates && !disabled ? (
        <div className="FlexRow Gap10">
          <RangePickerComponent
            value={filterDates}
            disabled={disabled}
            onChange={(value: [Moment, Moment]) => {
              if (!!value) {
                // check - if range more than 31 days
                const start = value[0]
                const end = value[1]

                const diff = end.diff(start, `days`)
                if (diff > 31) {
                  const newEnd = start.add(31, `day`)
                  setDates([start, newEnd])
                } else {
                  setDates(value)
                }
              }
            }}
            disabledDate={(current: Moment) => {
              const diffDates = dates
              if (!!diffDates) {
                const start = diffDates[0].valueOf()
                const end = diffDates[1].valueOf()
                const currentVal = current.valueOf()
                return currentVal > end || currentVal < start
              }
              return false
            }}
          />
          <div className="MarginTop12">
            <TooltipCustom title={tooltip} size={TooltipCustomSize.medium} />
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

export default TableDates
