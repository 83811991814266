import React from "react"
import { Map, Placemark, YMaps } from "react-yandex-maps"

const ContactsMap = ({ address }: { address: string }) => {
  const MoscowCoords = [55.718836, 37.661769]
  const coordsArr = address.split(",")

  const coords =
    !address || coordsArr.length !== 2
      ? MoscowCoords
      : [parseFloat(coordsArr[0]), parseFloat(coordsArr[1])]

  return (
    <div className="ContactsPageSection_mapWrapper">
      <YMaps
        query={{
          lang: "ru_RU",
          apikey: "e46b7dab-34d6-48f2-bbe3-a46392e338da"
        }}
      >
        <Map
          modules={["geocode"]}
          state={{ center: coords, zoom: 13 }}
          width=" 100%"
          height="100%"
        >
          <Placemark geometry={coords} />
        </Map>
      </YMaps>
    </div>
  )
}

export default ContactsMap
