import React from "react"

import { ReactComponent as CardIcon } from "./icons/payment_card.svg"

import { SearchTableHeader } from "Components/Table"

import noop from "lodash/noop"
import { ReactComponent as ClockIcon } from "./icons/clock.svg"
import { ReactComponent as DoneCircleIcon } from "./icons/done_circle.svg"
import { ReactComponent as WrongIcon } from "./icons/red_cross_circle.svg"
import { ColumnDateTime, getColumnActiveClass } from "../helper"
import { TransactionsReportText } from "./types"
import { chunkStatuses } from "./constants"
import { TableColumnWidth } from "Components/Tabs/types"

export const transactionsStatusMap = {
  0: { className: "TransactionEnterPin" },
  1: { className: "TransactionSuccess" },
  2: { className: "TransactionUnsuccessful" },
  3: { className: "TransactionPending" },
  4: { className: "TransactionContact" }
}

export const transactionsModalTitleIconMap = {
  0: <ClockIcon />,
  1: <DoneCircleIcon />,
  2: <WrongIcon />,
  3: <ClockIcon />,
  4: <ClockIcon />
}

export const transactionsText: TransactionsReportText = {
  title: `Отчёт по транзакциям`,
  filterTitle: `Чтобы получить отчёт по транзакциям,вам необходимо выбрать нужные параметры фильтрации`,
  filterDescription: `Отчёт по транзакциям содержит информацию о каждой проведённой транзакции безналичных выплат на банковские карты гостям в выбранный отчетный период`,
  tooltip:
    "Отчёт содержит информацию о дате начала операции и финальном статусе транзакции, сумме и размере комиссии, а также частично содержит информации о карте гостя для упрощения идентификации. Отчёт формируется за необходимый период.",
  modalId: "Транзакция ID",
  club: "Клуб №"
}

export const TRANSACTION_FILTER_NAME = "TRANSACTION_FILTER_NAME"
export const TRANSACTION_URL = "reports/noncash/transaction"

export const getModalTitleIcon = (id: number) => {
  // eslint-disable-next-line
  //@ts-ignore
  return transactionsStatusMap[id]
}

export const getTransactionDetailsUrl = (id: string) =>
  `reports/noncash/transaction/${id}/details`

const columnKeys = {
  id: "id",
  hall: "hall",
  date: "date",
  card: "card",
  sum: "sum",
  status: "status",
  commission: "commission"
}

export const transactionColumn = (
  field: string | undefined,
  search: { [key: string]: any },
  setSearch: (value: string | undefined, id: string) => void,
  statuses: [{ id: number; name: string }] | []
) => [
  {
    title: () => (
      <SearchTableHeader
        search={search[columnKeys.id]}
        setSearch={value => setSearch(value, columnKeys.id)}
        title="ID транзакции"
      />
    ),
    dataIndex: columnKeys.id,
    className: getColumnActiveClass(field, columnKeys.id),
    showSorterTooltip: false,
    key: columnKeys.id,
    width: 170,
    minWidth: 170
  },
  {
    title: "Клуб №",
    dataIndex: columnKeys.hall,
    className: getColumnActiveClass(field, columnKeys.hall),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.hall
  },
  {
    title: "Дата и время",
    dataIndex: columnKeys.date,
    className: getColumnActiveClass(field, columnKeys.date),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.date,
    render: (date?: string) => date && <ColumnDateTime date={date} />
  },
  {
    title: () => (
      <SearchTableHeader
        search={search["card"]}
        setSearch={value => setSearch(value, "card")}
        title="Карта гостя"
      />
    ),
    dataIndex: columnKeys.card,
    className: getColumnActiveClass(field, columnKeys.card),
    showSorterTooltip: false,
    key: columnKeys.card,
    render: (value?: number) =>
      value && (
        <div className="FlexRow">
          <span className="NoncashTableTypeIcon">
            <CardIcon />
          </span>
          <span className="NoncashTableTypeValue">{value}</span>
        </div>
      )
  },
  {
    title: "Сумма, ₽",
    dataIndex: columnKeys.sum,
    key: columnKeys.sum,
    className: getColumnActiveClass(field, columnKeys.sum),
    showSorterTooltip: false,
    sorter: () => noop
  },
  {
    title: "Финализация",
    dataIndex: columnKeys.status,
    className: getColumnActiveClass(field, columnKeys.status),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.status,
    width: TableColumnWidth.s,
    minWidth: TableColumnWidth.s,
    render: (type: number) => {
      const validStatus = statuses.find(x => x.id === type)
      return (
        validStatus && (
          // eslint-disable-next-line
          //@ts-ignore
          <span className={transactionsStatusMap[validStatus?.id]?.className}>
            {validStatus?.name}
          </span>
        )
      )
    }
  }
]

export const transactionModalColumn = (field: string | undefined) => [
  {
    title: "ID Партнера",
    dataIndex: columnKeys.id,
    className: getColumnActiveClass(field, columnKeys.id),
    sorter: true,
    showSorterTooltip: false,
    key: columnKeys.id,
    width: TableColumnWidth.xs,
    minWidth: TableColumnWidth.xs
  },
  {
    title: "Карта гостя",
    dataIndex: columnKeys.card,
    className: getColumnActiveClass(field, columnKeys.card),
    showSorterTooltip: false,
    key: columnKeys.card,
    render: (value: number) => (
      <div className="FlexRow">
        <span className="BalanceTableTypeIcon">
          <CardIcon />
        </span>
        <span className="BalanceTableTypeValue">{value}</span>
      </div>
    )
  },
  {
    title: "Сумма, ₽",
    dataIndex: columnKeys.sum,
    className: getColumnActiveClass(field, columnKeys.sum),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.sum
  },
  {
    title: "Комиссия, ₽",
    dataIndex: columnKeys.commission,
    className: getColumnActiveClass(field, columnKeys.commission),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.commission,
    width: TableColumnWidth.xs,
    minWidth: TableColumnWidth.xs
  },
  {
    title: "Статус",
    dataIndex: columnKeys.status,
    className: getColumnActiveClass(field, columnKeys.status),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnKeys.status,
    render: (type: number) => {
      const validStatus = chunkStatuses.find(x => x.id === type)
      return (
        validStatus && (
          // eslint-disable-next-line
          //@ts-ignore
          <span className={transactionsStatusMap[validStatus?.id]?.className}>
            {validStatus?.name}
          </span>
        )
      )
    }
  },
  {
    title: "Начало операции",
    dataIndex: columnKeys.date,
    className: getColumnActiveClass(field, columnKeys.date),
    sorter: () => noop,
    width: 180,
    minWidth: 180,
    showSorterTooltip: false,
    key: columnKeys.date,
    render: (date: string) => <ColumnDateTime date={date} />
  }
]
