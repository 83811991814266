import React from "react"
import { ReactComponent as EditIcon } from "Components/icons/write_outline.svg"
import { isContactsMobile } from "Containers/Contacts/index"
import {
  ContactsSectionProps,
  ContactsStateProps
} from "Containers/Contacts/types"
import { blockStateStorageName, imgObj } from "Containers/Contacts/constants"

const SectionHeader = ({
  section,
  contactsState,
  setContactsState
}: {
  section: ContactsSectionProps
  contactsState: ContactsStateProps
  setContactsState: (data: ContactsStateProps) => void
}) => {
  return (
    <div className="ContactsPageSectionHeader">
      <div className="ContactsPageSectionHeader_inner">
        <div className="ContactsPageSectionHeader_icon">
          <img src={imgObj[section.icon]} alt={section.icon} />
        </div>
        <div className="ContactsPageSectionHeader_titleWrapper">
          {!!section.title && (
            <div className="ContactsPageSectionHeader_title">
              {section.title}
            </div>
          )}
          {!!section.subtitle && !isContactsMobile && (
            <div className="ContactsPageSectionHeader_subtitle">
              {section.subtitle}
            </div>
          )}
        </div>
        {contactsState.isEditing && (
          <div
            onClick={() => {
              localStorage.removeItem(blockStateStorageName)
              setContactsState({
                ...contactsState,
                activeModal: true,
                isSectionEditing: true,
                editSectionId: section.section_id
              })
            }}
            className="ContactsPageEditIcon"
          >
            <EditIcon />
          </div>
        )}
      </div>
      {!!section.description && !isContactsMobile && (
        <div className="ContactsPageSectionDescription">
          {section.description}
        </div>
      )}
    </div>
  )
}

export default SectionHeader
