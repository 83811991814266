import { FilterDurations } from "./types"

export const localStorageKeys = {
  hallsAddress: "hallsAddress"
}

export const dateKeys = {
  gmt: "gmt"
}

export const shiftKeys = {
  label: "[0].label",
  short: "[0].short"
}

export const initPeriodOptions = [
  { value: FilterDurations.hourly, label: `Часам` },
  { value: FilterDurations.daily, label: `Дням` },
  { value: FilterDurations.monthly, label: `Месяцам` },
  { value: FilterDurations.yearly, label: `Годам` }
]

export const infoPanelComponentText = {
  info: "Информация по",
  period: "Период отчёта",
  timezone: "Часовой пояс",
  change: "Выбрать по смене"
}
