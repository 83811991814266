import React, { useState } from "react"

import { ReactComponent as RequestIcon } from "./icons/money_request.svg"
import { ReactComponent as WalletIcon } from "./icons/wallet_outline.svg"
import { ReactComponent as SendIcon } from "./icons/money_send.svg"

import { SearchTableHeader } from "Components/Table"
import ModalComponent from "Components/Modal"
import { ButtonPrimary } from "Components/Button"

import noop from "lodash/noop"
import {
  BalanceOperationComment,
  BalanceOperationText,
  StatusesDict,
  StatusesMap
} from "./types"
import { ColumnDateTime, getColumnActiveClass } from "../helper"
import { TableColumnWidth } from "Components/Tabs/types"

export const BALANCE_OPERATION_FILTER_NAME = "BALANCE_OPERATION_FILTER_NAME"
export const BALANCE_OPERATION_URL_EXCEL =
  "reports/noncash/balanceOperation/excel"
export const BALANCE_OPERATION_URL = "reports/noncash/balanceOperation"

export const balanceOperationText: BalanceOperationText = {
  title: `Отчёт по операциям \n с балансом клуба`,
  filterTitle: `Чтобы получить отчёт по операциям с балансом клуба,
                  вам необходимо выбрать нужные
                  параметры фильтрации`,
  filterDescription: `Отчёт по операциям с балансом клуба. Содержит такие данные, как суммы и типы транзакций в клубах, а также комментарии по проведенным транзакциям`,
  tooltip: `Отчёт содержит информацию об операциях по балансу клуба, такие как пополнение, вывод, резерв и возврат из резерва безналичных денежных средств. Данные строятся за необходимый период.`,
  comment: "Комментарий",
  roger: "Понятно",
  more: "еще"
}

const columnsKeys = {
  date: "date",
  hall: "hall",
  type: "type",
  sum: "sum",
  comment: "comment"
}

//TODO добавить в словарь по аналогии с transactions
const statusesMap: StatusesMap = {
  [StatusesDict.in]: { icon: <RequestIcon />, value: `Пополнение` },
  [StatusesDict.out]: { icon: <SendIcon />, value: `Вывод` },
  [StatusesDict.freezing]: { icon: <WalletIcon />, value: `Резерв` }
}

const CommentModal = ({ comment }: BalanceOperationComment) => {
  const [showModal, toggleModal] = useState<boolean>(false)
  return (
    <div>
      <div
        className="BalanceTableCommentMore"
        onClick={() => toggleModal(true)}
      >
        {balanceOperationText.more}
      </div>
      {showModal && (
        <ModalComponent width={544}>
          <div className="BalanceTableCommentModal">
            <div className="BalanceTableCommentModalTitle">
              {balanceOperationText.comment}
            </div>
            <div className="BalanceTableCommentModalBody">{comment}</div>
            <div className="BalanceTableCommentModalClose">
              <ButtonPrimary onClick={() => toggleModal(false)}>
                {balanceOperationText.roger}
              </ButtonPrimary>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  )
}

export const balanceColumn = (
  field: string | undefined,
  search: { [key: string]: any },
  setSearch: (value: string | undefined, id: string) => void
) => [
  {
    title: "Дата и время",
    dataIndex: columnsKeys.date,
    className: getColumnActiveClass(field, columnsKeys.date),
    sorter: () => noop,
    width: TableColumnWidth.s,
    showSorterTooltip: false,
    key: columnsKeys.date,
    render: (date: string) => <ColumnDateTime date={date} />
  },
  {
    title: "Клуб №",
    dataIndex: columnsKeys.hall,
    className: getColumnActiveClass(field, columnsKeys.hall),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnsKeys.hall,
    width: 100
  },
  {
    title: "Тип транзакции",
    dataIndex: columnsKeys.type,
    className: getColumnActiveClass(field, columnsKeys.type),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnsKeys.type,
    width: 100,
    render: (type: StatusesDict) => {
      let typeData = statusesMap[type]
      if (!typeData) {
        typeData = statusesMap[StatusesDict.freezing]
      }
      return (
        <div className="FlexRow">
          <span className="NoncashTableTypeIcon">{typeData.icon}</span>
          <span className="NoncashTableTypeValue">{typeData.value}</span>
        </div>
      )
    }
  },
  {
    title: "Сумма, ₽",
    dataIndex: columnsKeys.sum,
    key: columnsKeys.sum,
    className: getColumnActiveClass(field, columnsKeys.sum),
    width: 200,
    showSorterTooltip: false,
    sorter: () => noop
  },
  {
    title: () => {
      return (
        <SearchTableHeader
          search={search[columnsKeys.comment]}
          setSearch={value => setSearch(value, columnsKeys.comment)}
          title="Комментарий"
        />
      )
    },
    dataIndex: columnsKeys.comment,
    key: columnsKeys.comment,
    width: 350,
    className: `NoncashTableColumn`,
    render: (comment: string) => {
      const isLongText = comment && comment.length > 70
      return (
        <div className="FlexRow BalanceTableComment FlexWrap">
          <div>{isLongText ? comment.slice(0, 40) : comment}</div>
          <div className="FlexRow">
            {isLongText ? `${comment.slice(40, 70)}...` : ``}
            {isLongText ? <CommentModal comment={comment} /> : <div />}
          </div>
        </div>
      )
    }
  }
]
