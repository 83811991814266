import React from "react"

import { TypesItem, typesItems } from "./helper"

type StatisticTypesProps = {
  activeStatisticsType: string
  setActiveStaticType: (data: string) => void
}

const StatisticTypes = ({
  activeStatisticsType,
  setActiveStaticType
}: StatisticTypesProps) => {
  return (
    <div className="StatisticTypesWrapper FlexRow FlexWrap">
      {typesItems.map((item: TypesItem) => {
        const isActive = item.name === activeStatisticsType
        return (
          <div
            key={`${item.name}_type`}
            className={`StatisticTypesItem GridCenter ${
              isActive ? `` : `StatisticTypesItemHovered`
            }`}
            style={{
              border: `2px solid ${isActive ? item.color : `#F2F2F7`}`
            }}
            onClick={() => {
              if (!isActive) setActiveStaticType(item.name)
            }}
          >
            <div className="TypeItemTopWrapper FlexRow">
              <div
                className="TypeItemMarkerWrapper"
                style={{ backgroundColor: item.color }}
              />
              <span className="TypeItemTitle">{item.title}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default StatisticTypes
