import React, { useState, useEffect } from "react"

import { Popover } from "antd"

import { useRequest } from "api/hooks"

import SpinComponent from "Components/Spin"
import {
  getInfoContent,
  parseDateByShift,
  parseDateToBack
} from "constants/index"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle.svg"

import {
  GameFilterDataType,
  GameReportStateDataInsideType,
  GameReportGameOrSportListType
} from "Containers/Games/types"

const GamesTable = ({ data }: { data: GameReportGameOrSportListType[] }) => {
  return (
    <div className="GamesTableWrapper">
      <div className="GamesTableHeader FlexRow">
        <div
          style={{ borderTopLeftRadius: `8px` }}
          className="GamesTableHeaderItem GamesTableBoldItem"
        >
          Игра
        </div>
        <div className="GamesTableHeaderItem">
          Время в игре,
          <br /> мин
        </div>
        <div className="GamesTableHeaderItem">
          Среднее время
          <br /> в игре
        </div>
        <div className="GamesTableHeaderItem">
          Скорость трат,
          <br /> ₽/розыгрыш
        </div>
        <div className="GamesTableHeaderItem">
          Скорость трат,
          <br /> ₽/мин
        </div>
        <div className="GamesTableHeaderItem">
          Отдача
          <br /> фактическая
        </div>
        <div
          style={{ borderTopRightRadius: `8px` }}
          className="GamesTableHeaderLastItem"
        >
          Отдача
          <br /> теоретическая
        </div>
      </div>
      <div className="GamesTableBody">
        {data.map((item, key) => {
          const rowClassName =
            key !== data.length - 1
              ? `GamesTableRowItem`
              : `GamesTableRowLastItem`
          return (
            <div
              key={`games_table_row_${item.name}`}
              className="GamesTableRow FlexRow"
            >
              <div className={`${rowClassName} GamesTableBoldItem`}>
                {item.name}
              </div>
              <div
                className={`${rowClassName} ${
                  `${item.timeIn}`.includes(`-`) ? `TableItemNegativeValue` : ``
                }`}
              >
                {item.timeIn}
              </div>
              <div
                className={`${rowClassName} ${
                  `${item.timeAvg}`.includes(`-`)
                    ? `TableItemNegativeValue`
                    : ``
                }`}
              >
                {item.timeAvg}
              </div>
              <div
                className={`${rowClassName} ${
                  `${item.spendGame}`.includes(`-`)
                    ? `TableItemNegativeValue`
                    : ``
                }`}
              >
                {item.spendGame}
              </div>
              <div
                className={`${rowClassName} ${
                  `${item.spendTime}`.includes(`-`)
                    ? `TableItemNegativeValue`
                    : ``
                }`}
              >
                {item.spendTime}
              </div>
              <div
                className={`${rowClassName} ${
                  `${item.returnFact}`.includes(`-`)
                    ? `TableItemNegativeValue`
                    : ``
                }`}
              >
                {item.returnFact}
              </div>
              <div
                className={`${rowClassName} GamesTableRowItemWithoutRight ${
                  `${item.returnTheory}`.includes(`-`)
                    ? `TableItemNegativeValue`
                    : ``
                }`}
              >
                {item.returnTheory}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const GamesDetail = ({ data }: { data: GameReportStateDataInsideType }) => {
  return (
    <data className="GamesDetailWrapper FlexRow">
      <div className="GamesDetailPlayWrapper">
        <div
          style={{ borderTopLeftRadius: `8px` }}
          className="GamesDetailPlayTitle"
        >
          Сыграно
        </div>
        <div className="GamesDetailPlaySubTitle FlexRow">
          <div>Квитанций</div>
          <div>Ставок</div>
        </div>
        <div className="FlexRow">
          <div className="GamesDetailValueTitle GridCenter">Кол-во</div>
          <div className="GamesDetailValueTitle GridCenter">Обычных</div>
          <div className="GamesDetailValueTitle GridCenter">Бонусных</div>
          <div className="GamesDetailValueTitle GridCenter">Обычных</div>
          <div className="GamesDetailValueTitle GridCenter">Бонусных</div>
        </div>
        <div className="FlexRow">
          <div className="GamesDetailValue GridCenter">
            {data.played.check.count}
          </div>
          <div className="GamesDetailValue GridCenter">
            {data.played.check.usual}
          </div>
          <div className="GamesDetailValue GridCenter">
            {data.played.check.bonus}
          </div>
          <div className="GamesDetailValue GridCenter">
            {data.played.bet.usual}
          </div>
          <div className="GamesDetailValue GridCenter">
            {data.played.bet.bonus}
          </div>
        </div>
      </div>
      <div className="GamesDetailPlayTotal">
        <div className="GamesDetailPlayTotalTitle">Всего</div>
        <div className="GamesDetailValue GamesDetailValueBold GridCenter">
          {data.totalSalePlay}
        </div>
      </div>
      <div className="GamesDetailWinWrapper">
        <div className="GamesDetailWinTitle">Выиграно</div>
        <div className="GamesDetailWinSubTitle FlexRow">
          <div>Квитанций</div>
          <div>Ставок</div>
        </div>
        <div className="FlexRow">
          <div className="GamesDetailValueTitle GridCenter">Обычных</div>
          <div className="GamesDetailValueTitle GridCenter">Бонусных</div>
          <div className="GamesDetailValueTitle GridCenter">Супер-выигрыш</div>
          <div className="GamesDetailValueTitle GridCenter">Обычных</div>
          <div className="GamesDetailValueTitle GridCenter">Бонусных</div>
        </div>
        <div className="FlexRow">
          <div className="GamesDetailValue GridCenter">
            {data.wined.check.usual}
          </div>
          <div className="GamesDetailValue GridCenter">
            {data.wined.check.bonus}
          </div>
          <div className="GamesDetailValue GridCenter">
            {data.wined.check.superwin}
          </div>
          <div className="GamesDetailValue GridCenter">
            {data.wined.bet.usual}
          </div>
          <div className="GamesDetailValue GridCenter">
            {data.wined.bet.bonus}
          </div>
        </div>
      </div>
      <div className="GamesDetailWinTotal">
        <div
          style={{ borderTopRightRadius: `8px` }}
          className="GamesDetailWinTotalTitle"
        >
          Всего
        </div>
        <div className="GamesDetailValue GamesDetailValueBold GamesDetailValueWithoutRightBorder GridCenter">
          {data.totalWin}
        </div>
      </div>
    </data>
  )
}

const SportDetail = ({ data }: { data: GameReportStateDataInsideType }) => {
  return (
    <data className="SportDetailWrapper FlexRow">
      <div className="SportDetailPlayWrapper">
        <div className="SportDetailPlayTitle">Сыграно SportBoom</div>
        <div className="FlexRow">
          <div className="SportDetailPlaySubTitle">Обычных</div>
          <div className="SportDetailPlaySubTitle">Freebet</div>
        </div>
        <div className="FlexRow">
          <div className="SportDetailPlayValue">{data.played.usual}</div>
          <div className="SportDetailPlayValue">{data.played.freebet}</div>
        </div>
      </div>
      <div className="SportDetailPlayTotal">
        <div className="SportDetailPlayTotalTitle">Всего</div>
        <div className="SportDetailTotalValue">{data.totalSalePlay}</div>
      </div>
      <div className="SportDetailWinWrapper">
        <div className="SportDetailWinTitle">Выиграно SportBoom</div>
        <div className="FlexRow">
          <div className="SportDetailPlaySubTitle">Обычных</div>
          <div className="SportDetailPlaySubTitle">Freebet</div>
        </div>
        <div className="FlexRow">
          <div className="SportDetailPlayValue">{data.wined.usual}</div>
          <div className="SportDetailPlayValue">{data.wined.freebet}</div>
        </div>
      </div>
      <div className="SportDetailWinTotal">
        <div className="SportDetailWinTotalTitle">Всего</div>
        <div className="SportDetailTotalValue">{data.totalWin}</div>
      </div>
    </data>
  )
}

type InsideContentProps = {
  pending: boolean
  filter: GameFilterDataType
  date: string
}

const InsideContent = ({ filter, pending, date }: InsideContentProps) => {
  const isGames = filter.type === `games`

  const [hallsReport, setData] = useState<{
    pending: boolean
    data: GameReportStateDataInsideType[]
  }>({ pending: false, data: [] })

  const { request: getDetail } = useRequest({
    url: `reports/games/detail`,
    method: `POST`,
    requestBody: {
      ...filter,
      dates: filter.dates
        ? parseDateToBack({
            date: parseDateByShift({
              dates: filter.dates,
              isShift: filter.isShift,
              shift: filter.shift
            }),
            gmt: filter.gmt
          })
        : null,
      shift: filter.isShift ? filter.shift[0] : filter.shift,
      date,
      groupHalls: !!date
    }
  })

  useEffect(() => {
    if (!pending) {
      setData({ ...hallsReport, pending: true })
      getDetail()
        .then(({ data }: { data: GameReportStateDataInsideType[] }) =>
          setData({ ...hallsReport, data, pending: false })
        )
        .catch(() => setData({ ...hallsReport, pending: false }))
    }
  }, [pending])

  return (
    <div className="ReportTableInsideWrapper">
      <div>
        {hallsReport.pending || pending ? (
          <div style={{ padding: `32px` }} className="GridCenter">
            <SpinComponent />
          </div>
        ) : (
          hallsReport.data.map((item, key) => (
            <div
              key={`games_report_inside_${key}`}
              className="GamesInsideWrapper"
            >
              <div className="GamesInsideHall">
                {!!date ? (
                  <div className="FlexRow GamesInsideHallTitle">
                    <span>{`Клуб № ${item.hall}`}</span>
                    <Popover
                      overlayClassName="InfoPopoverWrapper"
                      placement="right"
                      content={getInfoContent({ hall: item.hall })}
                      trigger="hover"
                    >
                      <div className="ReportInsideHallInfo">
                        <InfoIcon />
                      </div>
                    </Popover>
                  </div>
                ) : (
                  <div />
                )}
                {!!item.played.check && isGames && <GamesDetail data={item} />}
                {!item.played.check && !isGames && <SportDetail data={item} />}
                {filter.period !== `hourly` && <GamesTable data={item.games} />}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default InsideContent
