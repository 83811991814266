import React, { useState } from "react"

import { RadioGroupComponent } from "Components/Radio"
import { RangePickerComponent } from "Components/DatePicker"
import { InputMaskComponent } from "Components/Input"

import { CustomFilterProps, FilterType } from "../types"

import { FilterPeriod, filterText, formatByPeriod } from "./constants"
import { config } from "../../BetBonus/constants"

const CustomFilter: React.FC<CustomFilterProps> = ({ filter, setFilter }) => {
  const [period, changePeriod] = useState<FilterPeriod>(FilterPeriod.date)

  return (
    <div className="FlexColumn BetBonusFilter">
      <div className="FlexColumn Gap8">
        <span className="SubtitleFirstText">{filterText.type}</span>
        <RadioGroupComponent
          options={filterText.typeOptions}
          value={filter.type}
          onChange={({ target: { value } }) =>
            setFilter({ ...filter, type: value })
          }
        />
      </div>
      {filter.type === FilterType.hall && (
        <div className="FlexColumn Gap24 PaddingTop24">
          <div className="FlexColumn Gap8">
            <span className="SubtitleFirstText">{filterText.period}</span>
            <RadioGroupComponent
              options={filterText.periodOptions}
              value={period}
              onChange={({ target: { value } }) => {
                changePeriod(value)
                setFilter({ ...filter, dates: undefined })
              }}
            />
          </div>
          <div className="FlexColumn Gap8">
            <span className="SubtitleFirstText">{filterText.dates}</span>
            <RangePickerComponent
              value={filter.dates}
              allowClear={false}
              disableFutureDates={true}
              onChange={value => {
                const newDates =
                  period === config.monthPeriod
                    ? [value[0].startOf("month"), value[1].endOf("month")]
                    : value
                setFilter({
                  ...filter,
                  dates: newDates || undefined
                })
              }}
              picker={period}
              format={
                period === config.monthPeriod
                  ? `MMMM YYYY`
                  : formatByPeriod[FilterPeriod.date]
              }
            />
          </div>
        </div>
      )}
      {filter.type === FilterType.guest && (
        <div className="PaddingTop8">
          <InputMaskComponent
            mask={"+7 000 000-00-00"}
            value={filter.phone}
            allowClear={true}
            onChange={x => {
              setFilter({ ...filter, phone: `7${x.unmaskedValue}` })
            }}
          />
        </div>
      )}
    </div>
  )
}

export default CustomFilter
