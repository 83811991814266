import { Moment } from "moment"
import {
  DashboardOptions,
  DashboardPrefixOptions
} from "Components/DashboardNew/constants"

export enum FilterType {
  hall = `hall`,
  guest = `guest`
}

export enum PromotionBonusesTableItem {
  sum = `sum`,
  date = `date`,
  time = `time`,
  wallet = `wallet`
}

export type PromotionBonusesLegoConfigSummary = {
  title?: string
  prefix?: DashboardPrefixOptions
  type: DashboardOptions
  field: string
  isCurrency: boolean
  tooltipText: string
}

export type PromotionBonusesLegoConfigTable = {
  title: string
  field: string
  type: PromotionBonusesTableItem
  suffix?: string
}

export type PromotionBonusesLegoConfig = {
  title: string
  tool: string
  filterStorageName: string
  notActiveFilterDescription: string
  summary: PromotionBonusesLegoConfigSummary[]
  table: PromotionBonusesLegoConfigTable[]
  tableUrl: string
  summaryUrl: string
  filterTypeHall: string
  filterTypeGuest: string
  monthPeriod: string
}

export type PromotionBonusesLegoFilterData = {
  type: FilterType
  hall: number
  dates?: [Moment, Moment]
  phone?: string
}

export type CustomFilterProps = {
  filter: PromotionBonusesLegoFilterData
  setFilter: (data: PromotionBonusesLegoFilterData) => void
}

export type PromotionBonusesLegoFilter = {
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
  data: PromotionBonusesLegoFilterData
}
