import React, { useEffect, useState } from "react"

import { getPaginationDefault, paginationAll } from "api/hooks"

import EmptyTable from "Components/EmptyTable"
import QiwiModalComponent from "Components/QiwiModal"
import Title from "Components/Title"
import DataSwitcher from "Components/DataSwitcher"

import CashboxTable from "./Table"
import StatisticsComponent from "./Statistics"
import CashboxDashboard from "./Dashboards"

import { getDateDescriptionLayout } from "constants/index"

import { CashboxContentProps } from "./types"

import cashboxConstants from "../constants"

const CashboxContent = ({
  qiwiData,
  cashboxState,
  isEmptyData,
  dates,
  shift,
  isShift,
  showDescription,
  period,
  types,
  pagination,
  setPagination,
  total
}: CashboxContentProps) => {
  const [activeContent, toggleActiveContent] = useState(true)
  const [qiwiModal, toggleQiwiModal] = useState<boolean>(false)
  const haveQiwiModal = qiwiData && qiwiData.length && qiwiData.length > 1

  useEffect(() => {
    if (activeContent) {
      setPagination(getPaginationDefault())
    } else {
      setPagination(paginationAll)
    }
  }, [activeContent])

  return (
    <div className="CashboxContentComponentWrapper">
      <Title
        titleText={cashboxConstants.title}
        tooltipText={cashboxConstants.tooltip}
        wrapperClass="CashboxContentTitle"
        defaultBottomCmpMargin
        bottomAdditionalCmp={
          <>
            {dates &&
              getDateDescriptionLayout({
                dates,
                shift,
                isShift,
                show: showDescription
              })}
            {!isEmptyData && (
              <DataSwitcher
                activeContent={activeContent}
                toggleActiveContent={toggleActiveContent}
              />
            )}
          </>
        }
        bottomAdditionalClassName="CashboxTitleBottomWrapper"
      />
      <div className="PaddingBottom4">
        <CashboxDashboard
          haveQiwiModal={haveQiwiModal}
          toggleQiwiModal={toggleQiwiModal}
          cashboxState={cashboxState}
        />
      </div>
      <div>
        {isEmptyData && !cashboxState.pending ? (
          <EmptyTable />
        ) : (
          <div className="CashboxContentTableWrapper">
            {activeContent && (
              <CashboxTable
                cashboxState={cashboxState}
                period={period}
                types={types}
                pagination={pagination}
                setPagination={setPagination}
              />
            )}
            {!activeContent && (
              <StatisticsComponent
                total={total}
                period={period}
                report={cashboxState.data.report || []}
              />
            )}
          </div>
        )}
      </div>
      {qiwiModal && (
        <QiwiModalComponent
          close={() => toggleQiwiModal(false)}
          data={qiwiData}
          name={cashboxConstants.qiwi.name}
          title={cashboxConstants.qiwi.title}
          color={cashboxConstants.qiwi.color}
        />
      )}
    </div>
  )
}

export default CashboxContent
