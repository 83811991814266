import React, { useState } from "react"

import { ButtonPrimary, buttonText } from "Components/Button"

import SortTableFilter from "../SortFilter"
import { SortOrders, SortProps } from "../../types"

const OnlySortFilter = ({
  field,
  currentSort,
  setSort
}: {
  field: string
  currentSort?: SortProps
  setSort: (val?: SortProps) => void
}) => {
  const [state, setState] = useState<{ sort?: SortProps; hasSort: boolean }>({
    sort: currentSort,
    hasSort: currentSort?.field === field
  })
  return (
    <>
      <SortTableFilter
        order={state.sort?.order}
        changeOrder={value => {
          if (!!value) {
            setState({
              ...state,
              sort: { field, order: value }
            })
          }
        }}
        hasSort={state.hasSort}
        changeHasSort={sortVal => {
          if (sortVal && !state.sort) {
            setState({
              ...state,
              hasSort: sortVal,
              sort: { field, order: SortOrders.asc }
            })
          } else {
            setState({
              ...state,
              hasSort: sortVal
            })
          }
        }}
      />
      <div className="FilterButtonsWrapper MarginTop16">
        <ButtonPrimary
          onClick={() => {
            if (state.hasSort && state.sort) {
              setSort({ field, order: state.sort?.order })
            } else {
              setSort(undefined)
            }
          }}
        >
          {buttonText.apply}
        </ButtonPrimary>
      </div>
    </>
  )
}

export default OnlySortFilter
