import React, { useMemo, useState } from "react"
import { CheckListFilterProps, CheckListFilterState } from "./types"

import { CheckBoxComponent } from "Components/CheckBox"
import { ButtonPrimary, buttonText } from "Components/Button"
import InputComponent from "Components/Input"

import { SearchFilterState, SearchField } from "../SearchFilter"

const text = {
  anyText: `Любой`,
  searchLabel: `Применить фильтр`,
  searchPlaceholder: `Укажите название`
}

const CheckListFilter = ({
  list,
  searchFieldConfig,
  changeFilter,
  closeFunc,
  initialList,
  withSearch,
  hideAllSelect
}: CheckListFilterProps) => {
  const [search, setSearch] = useState<string | undefined>(undefined)

  const allValues = list.map(item => item.value)
  const [checkedList, changeCheckedList] = useState<CheckListFilterState>({
    checked: initialList || allValues,
    all: !initialList
  })

  const listBySearch = useMemo(() => {
    if (search && withSearch) {
      return list.filter(item => item.label.toLowerCase().includes(search))
    } else {
      return list
    }
  }, [search])

  const haveAdditionSearchField = !!searchFieldConfig
  const [searchState, setSearchState] = useState<SearchFilterState>({
    value: searchFieldConfig?.value,
    hasSort: false,
    equal: 1
  })

  return (
    <div className="CheckListFilterWrapper">
      {haveAdditionSearchField && (
        <div className="MarginBottom16 FlexColumn Gap6">
          <label className="TextDefaultBold">{searchFieldConfig.title}</label>
          <SearchField
            state={searchState}
            setState={setSearchState}
            placeholder={searchFieldConfig.placeholder}
          />
        </div>
      )}
      {withSearch && (
        <div className="Gap6 MarginBottom16 FlexColumn">
          <label className="TextDefaultBold">{text.searchLabel}</label>
          <InputComponent
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
            placeholder={text.searchPlaceholder}
          />
        </div>
      )}
      {!hideAllSelect && (
        <CheckBoxComponent
          label={text.anyText}
          checked={checkedList.all}
          onChange={({ target: { checked } }) => {
            if (checked) {
              changeCheckedList({ checked: allValues, all: true })
            } else {
              changeCheckedList({ checked: [], all: false })
            }
          }}
        />
      )}
      <div className="CheckListFilterScroll">
        {listBySearch.map(item => {
          const isChecked = checkedList.checked.includes(item.value)
          return (
            <div key={`item_${item.label}`} className="CheckListFilterItem">
              <CheckBoxComponent
                label={item.label}
                checked={isChecked}
                onChange={({ target: { checked } }) => {
                  const newList = checkedList.checked
                  if (!checked) {
                    changeCheckedList({
                      checked: checkedList.checked.filter(
                        listItem => listItem !== item.value
                      ),
                      all: false
                    })
                  }
                  if (checked) {
                    newList.push(item.value)
                    changeCheckedList({
                      checked: newList,
                      all: newList.length === allValues.length
                    })
                  }
                  if (withSearch) {
                    setSearch(undefined)
                  }
                }}
              />
            </div>
          )
        })}
      </div>
      <div className="MarginTop16">
        <ButtonPrimary
          disabled={!checkedList.checked.length}
          onClick={() => {
            changeFilter(checkedList.checked, searchState.value)
            if (!!closeFunc) {
              closeFunc()
            }
          }}
        >
          {buttonText.apply}
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default CheckListFilter
