import React, { useState } from "react"

import DataSwitcher from "Components/DataSwitcher"
import EmptyTable from "Components/EmptyTable"

import BetsTable from "./Table"
import BetsChart from "./Chart"

import { GuestsHistoryFilterData } from "../../types"
import { dataWithoutPeriod } from "../../constants"

const GuestHistoryBets = ({ filter }: { filter: GuestsHistoryFilterData }) => {
  const [betsView, toggleView] = useState(false)

  return (
    <div className="GuestHistoryBetsWrapper">
      {filter.dates ? (
        <div>
          <div className="GuestHistoryBetsDataSwitcher">
            <DataSwitcher
              activeContent={betsView}
              toggleActiveContent={toggleView}
            />
          </div>
          {betsView ? (
            <BetsTable filter={filter} />
          ) : (
            <BetsChart filter={filter} />
          )}
        </div>
      ) : (
        <EmptyTable
          mobile={dataWithoutPeriod.mobile}
          desktop={dataWithoutPeriod.desktop}
        />
      )}
    </div>
  )
}

export default GuestHistoryBets
