import React from "react"

import { formatToCurrency, isMobile } from "constants/index"

import { CupisContentReportDataInside } from "./types"
import { insideTextByData, insideText } from "./constants"

const InsideContent = ({
  data,
  date
}: {
  data: CupisContentReportDataInside[]
  date: string
}) => {
  return (
    <div className="CupisInsideContentWrapper">
      {data.map(item => {
        const constants = insideTextByData(item)

        const bonusAndMoneyComponent = (
          <div className="FlexRow">
            <div className="FlexColumn CupisInsideBonusColumn">
              {constants.bonusAndMoneyValues.map((bonusItem, key) => (
                <div className="FlexColumn" key={`bonus_${key}`}>
                  {!!bonusItem.bonusField && (
                    <div className="CupisInsideBonusValue TextDefault">
                      {bonusItem.bonusField}
                    </div>
                  )}
                  <div className="CupisInsideBonusValue TextDefaultBold">
                    {formatToCurrency({
                      amount: Number(bonusItem.bonusValue),
                      customEmpty: bonusItem.bonusValue
                    })}
                  </div>
                </div>
              ))}
            </div>
            <div className="FlexColumn CupisInsideBonusColumn">
              {constants.bonusAndMoneyValues.map((bonusItem, key) => (
                <div className="FlexColumn" key={`money_${key}`}>
                  {!!bonusItem.bonusField && (
                    <div className="CupisInsideBonusValue TextDefault CupisInsideMobileNoRightBorder">
                      {bonusItem.moneyField}
                    </div>
                  )}
                  <div className="CupisInsideBonusValue TextDefaultBold CupisInsideMobileNoRightBorder ">
                    {formatToCurrency({
                      amount: Number(bonusItem.moneyValue),
                      customEmpty: bonusItem.moneyValue
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )

        const guestComponent = item.guests ? (
          <div className="FlexRow">
            <div className="CupisInsideValue CupisInsideGuestsColumn TextDefault">
              {item.guests.count}
            </div>
            <div className="CupisInsideValue CupisInsideGuestsColumn CupisInsideMobileNoRightBorder TextDefault">
              {item.guests.attached}
            </div>
          </div>
        ) : (
          <div />
        )

        const detailComponent = item.details ? (
          <div className="FlexRow">
            <div className="CupisInsideValue  CupisInsideLastMobileBottom TextDefault">
              {constants.sum_in}
            </div>
            <div className="CupisInsideValue  TextDefault">
              {constants.sum_out}
            </div>
            <div className="CupisInsideValue  CupisInsideNoRightBorder CupisInsideLastBottom TextDefault">
              {constants.commission}
            </div>
          </div>
        ) : (
          <div />
        )

        return (
          <div key={`cupis_inside_${date}_${item.hall}`}>
            {!!constants.hall && (
              <span className="TextDefaultBold">{constants.hall}</span>
            )}
            <div className="CupisInsideTable">
              <div className={isMobile ? `FlexColumn` : `FlexRow`}>
                <div className="CupisInsideTableBonus FlexColumn">
                  <div className="BodySecondText CupisInsideTitle CupisInsideFirstLeft CupisInsideMobileNoRightBorder">
                    {insideText.bonusAndMoney.header}
                  </div>
                  <div className="FlexRow">
                    <div className="ButtonTextSecondCupis CupisInsideSubtitle CupisInsideSubtitleBonus CupisInsideMobileNoRightBorder">
                      {insideText.bonusAndMoney.bonus}
                    </div>
                    <div className="ButtonTextSecondCupis CupisInsideSubtitle CupisInsideSubtitleBonus CupisInsideMobileNoRightBorder">
                      {insideText.bonusAndMoney.money}
                    </div>
                  </div>
                </div>
                {isMobile ? bonusAndMoneyComponent : <div />}
                {!!item.guests && (
                  <div className="CupisInsideTableGuests FlexColumn">
                    <div className="BodySecondText CupisInsideTitle CupisInsideMobileNoRightBorder">
                      {insideText.guests.header}
                    </div>
                    <div className="FlexRow">
                      <div className="ButtonTextSecondCupis CupisInsideSubtitle CupisInsideSubtitleGuests">
                        {insideText.guests.count}
                      </div>
                      <div className="ButtonTextSecondCupis CupisInsideSubtitle CupisInsideSubtitleGuests CupisInsideMobileNoRightBorder">
                        {insideText.guests.attached}
                      </div>
                    </div>
                  </div>
                )}
                {isMobile ? guestComponent : <div />}
                {item.details && (
                  <div className="CupisInsideTableDetail FlexColumn">
                    <div className="BodySecondText CupisInsideTitle CupisInsideNoRightBorder CupisInsideLastTop">
                      {insideText.details.header}
                    </div>
                    <div className="FlexRow">
                      <div className="ButtonTextSecondCupis CupisInsideSubtitle CupisInsideSubtitleDetail ">
                        {insideText.details.sum_in}
                      </div>
                      <div className="ButtonTextSecondCupis CupisInsideSubtitle CupisInsideSubtitleDetail">
                        {insideText.details.sum_out}
                      </div>
                      <div className="ButtonTextSecondCupis CupisInsideSubtitle CupisInsideSubtitleDetail CupisInsideNoRightBorder">
                        {insideText.details.commission}
                      </div>
                    </div>
                  </div>
                )}
                {isMobile ? detailComponent : <div />}
              </div>
              {isMobile ? (
                <div />
              ) : (
                <div className="FlexRow">
                  {bonusAndMoneyComponent}
                  {guestComponent}
                  {detailComponent}
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default InsideContent
