import React, { useState } from "react"

import ModalComponent from "Components/Modal"

import superman from "Components/icons/develop/superman.svg"
import robin from "Components/icons/develop/robin.svg"
import batman from "Components/icons/develop/batman.svg"
import green from "Components/icons/develop/green.svg"
import flash from "Components/icons/develop/flash.svg"
import wonder from "Components/icons/develop/wonder.svg"

import { useSelector } from "react-redux"
import { IDevelopState } from "store/developer/types"
import { getDeveloperList } from "store/developer/selector"

const DeveloperComponent = ({ iconNumber }: { iconNumber: number }) => {
  const developer: IDevelopState = useSelector(getDeveloperList)
  const devLinks = Object.entries(developer).map(item => item[1]) || []

  const heroIcon = [batman, superman, green, robin, flash, wonder]

  const [modal, toggleModal] = useState(false)
  const isEmptyList = !devLinks?.length

  return !isEmptyList ? (
    <div className="DeveloperProfileWrapper">
      <div
        className={`DeveloperComponentIconWrapper DeveloperComponentIconWrapper-${
          isEmptyList ? `disabled` : `active`
        }`}
        style={{
          background: `url(${heroIcon[iconNumber]}) center center no-repeat`
        }}
        onClick={() => toggleModal(true)}
      />
      {modal ? (
        <ModalComponent close={() => toggleModal(false)} width={340}>
          <div className="DeveloperLinksHeader">Отладочная панель</div>
          <div className="DeveloperLinksWrapper">
            {devLinks.map(item => (
              <div key={`${item.url}_profile`} className="DeveloperLinksItem">
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div className="FlexRow">
                    <span>Запрос:</span>
                    <div className="TextDefaultBold PaddingLeft8">
                      {item.url}
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </ModalComponent>
      ) : (
        <div />
      )}
    </div>
  ) : (
    <div />
  )
}

export default DeveloperComponent
