import React, { useCallback, useEffect, useState } from "react"
import { AddEditPenaltyModalProps, OnFinish, AddEditModalData } from "./types"
import { UploadAttachments, UploadAttachment } from "../types"
import { FormItemNames } from "./helper"
import { ReactComponent as CloseIcon } from "Components/icons/close-big.svg"
import { Form, Modal } from "antd"
import { FieldData } from "rc-field-form/lib/interface"
import { uniqBy } from "lodash"
import { getFilesBase64 } from "utils/files"
import { AddEditPenaltyModalContent } from "./AddEditPenaltyModalContent"
import moment from "moment"
import { addEditModalText } from "../../constants"

const initialModalData: AddEditModalData = {
  hall: undefined,
  findDate: "",
  setDate: "",
  reason: "",
  reasonComment: "",
  penaltyValue: 0,
  win: "",
  attachments: []
}

export const AddEditPenaltyModal: React.FC<AddEditPenaltyModalProps> = ({
  newModal,
  editModal,
  loading,
  reasonOptions,
  winOptions,
  closeModal,
  setModalDataCb,
  editModalData,
  setEditFinalModal
}) => {
  const [form] = Form.useForm()
  const [modalData, setModalData] = useState<AddEditModalData>(initialModalData)

  useEffect(() => {
    if (!editModal && !newModal) {
      form.resetFields()
      setModalData(initialModalData)
    }
  }, [editModal, newModal])

  const [nextReasonOptions, setNextReasonOptions] = useState(reasonOptions)

  useEffect(() => {
    setNextReasonOptions(reasonOptions)
  }, [reasonOptions])

  const setNextOptionCb = useCallback(
    value => {
      setNextReasonOptions(prev => [value, ...prev])
    },
    [nextReasonOptions]
  )

  useEffect(() => {
    if (form && editModalData && (editModal || newModal)) {
      setModalData(editModalData)
      form.setFieldsValue({
        [FormItemNames.hall]: editModalData.hall,
        [FormItemNames.date]: editModalData.findDate,
        [FormItemNames.reason]: editModalData.reason,
        [FormItemNames.amount]: editModalData.penaltyValue,
        [FormItemNames.win]: editModalData.win,
        [FormItemNames.comment]: editModalData.reasonComment,
        [FormItemNames.file]: editModalData.attachments
      })
    }
  }, [form, editModalData, editModal, newModal])

  const onFinish = (value: OnFinish) => {
    const errors = form.getFieldError(FormItemNames.file)
    if (editModal) {
      setEditFinalModal()
    }

    if (!errors.length) {
      setModalDataCb({
        hall: Number(value.hall),
        findDate: value.date,
        setDate: moment(),
        reason:
          typeof value?.reason === "number"
            ? value.reason
            : reasonOptions.find(x => x.value === value.reason)?.id,
        reasonComment: value.comment,
        penaltyValue: value?.amount ? value.amount : 0,
        win: value.win,
        attachments: modalData.attachments
      })
    }
  }

  const onFieldsChange = (e: FieldData[]) => {
    if ((e[0].name as [string])[0] === FormItemNames.file) {
      getFilesBase64([e[0].value.file], true).then(data => {
        setModalData(prev => ({
          ...prev,
          attachments: uniqBy(
            [...prev.attachments, ...data],
            "name"
          ) as UploadAttachments
        }))
      })
    }
  }

  useEffect(() => {
    if (modalData.attachments.length > 5) {
      form.setFields([
        {
          name: FormItemNames.file,
          errors: [addEditModalText.fileUploadCountError]
        }
      ])
    } else if (modalData.attachments.length <= 5) {
      const totalSize = (modalData.attachments as [UploadAttachment]).reduce(
        (acc: number, att: UploadAttachment) => {
          acc += Number(att.size)
          return acc
        },
        0
      )
      if (totalSize > 4e7) {
        form.setFields([
          {
            name: FormItemNames.file,
            errors: [addEditModalText.fileSizeValidationError]
          }
        ])
      } else {
        form.setFields([
          {
            name: FormItemNames.file,
            errors: []
          }
        ])
      }
    } else {
      form.setFields([
        {
          name: FormItemNames.file,
          errors: []
        }
      ])
    }
  }, [modalData.attachments])

  return (
    <Modal
      className="SupportProposalModal"
      visible={editModal || newModal}
      footer={null}
      closeIcon={
        <CloseIcon
          className="ProposalModalCloseIcon"
          onClick={() => {
            closeModal()
            form.resetFields()
            setModalData(initialModalData)
          }}
        />
      }
      maskClosable={true}
      width={678}
    >
      <AddEditPenaltyModalContent
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        newModal={newModal}
        editModal={editModal}
        setModalData={val => setModalData(val)}
        loading={loading}
        modalData={modalData}
        reasonOptions={nextReasonOptions}
        winOptions={winOptions}
        setNextOptionCb={setNextOptionCb}
      />
    </Modal>
  )
}
