import React, { useState } from "react"
import { ButtonPrimary } from "Components/Button"
import InfoPanelComponent from "Components/FilterInfoPanel"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"
import {
  getFilterStateFromLocalStorage,
  saveFilterInLocalStorage
} from "api/helpers"
import { MainFilter, VisitFilterComponentProps } from "../types"
import { textConstants } from "../constants"
import { isDisabledFilter } from "../helper"

export const VISIT_FILTER_NAME = "visitFilter"

const VisitFilter: React.FC<VisitFilterComponentProps> = ({
  filterState,
  setFilterState,
  current_hall,
  mobileFilterOpen,
  setMobileFilterOpen
}: VisitFilterComponentProps) => {
  const [filter, setFilter] = useState<MainFilter>(
    getFilterStateFromLocalStorage(filterState.data, VISIT_FILTER_NAME)
  )

  return (
    <div
      className={`
      ${mobileFilterOpen ? `MainFilterMobile` : `MainFilterWrapper`}
      ${
        filterState.isOpen
          ? `MainFilterWrapperActive`
          : `MainFilterWrapperUnActive`
      }`}
    >
      <div
        className="MainFilterMobileClose"
        onClick={() => {
          if (mobileFilterOpen && setMobileFilterOpen) {
            setMobileFilterOpen(false)
          }
        }}
      >
        <CloseIcon />
      </div>
      <div className="MainFilterHeader FlexRow">
        <span>Фильтры</span>
      </div>
      <div className="MainFilterContent">
        <div className="InfoPanelComponentWrapper">
          <InfoPanelComponent<MainFilter>
            periodOptionsTooltipText={textConstants.visitFilterByHourTooltip}
            disabledHalls
            filter={filter}
            setFilter={setFilter}
            current_hall={current_hall}
          />
        </div>
      </div>
      <div className="MainFilterApplyWrapper GameFilterApplyWrapper">
        <ButtonPrimary
          disabled={isDisabledFilter(filter)}
          onClick={() => {
            if (mobileFilterOpen && setMobileFilterOpen) {
              setMobileFilterOpen(false)
            }

            /* Время начала смены могло измениться */
            const shiftDashboards = getFilterStateFromLocalStorage(
              filterState.data,
              VISIT_FILTER_NAME
            ).shiftDashboards

            const newFilterState = {
              ...filterState,
              data: {
                ...filterState.data,
                ...filter,
                shiftDashboards
              },
              isActive: true
            }
            setFilterState(newFilterState)

            const newData = newFilterState.data
            delete newData.onlyReg
            saveFilterInLocalStorage(newData, VISIT_FILTER_NAME)
          }}
        >
          Применить
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default VisitFilter
