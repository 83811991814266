import React from "react"

import { ButtonPrimary } from "Components/Button"
import { ModalFormComponent } from "Components/Modal"

import { ReactComponent as SuccessDudeIcon } from "Components/icons/illustrations/thumb_up.svg"

const text = {
  title_create: `Доступ к кабинету создан`,
  title_edit: `Изменения сохранены`,
  apply: `Хорошо`
}

const AccessSuccess = ({
  close,
  isEdit
}: {
  close: () => void
  isEdit?: boolean
}) => {
  return (
    <ModalFormComponent width={720} withoutCross={true} close={close}>
      <div className="FlexColumn Gap24">
        <div className="GridCenter">
          <div className="HeadlineFirstText">
            {isEdit ? text.title_edit : text.title_create}
          </div>
        </div>
        <div className="GridCenter">
          <SuccessDudeIcon />
        </div>
        <div className="GridCenter">
          <ButtonPrimary onClick={close}>{text.apply}</ButtonPrimary>
        </div>
      </div>
    </ModalFormComponent>
  )
}

export default AccessSuccess
