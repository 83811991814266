import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useSelector } from "react-redux"
import { getGamesList } from "store/dictionary/selector"

import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import Title from "Components/Title"
import EmptyTable from "Components/EmptyTable"
import NotActiveFilter from "Components/NotActiveFilter"
import { sportGames } from "Components/SideFilter/Components/Games/constants"

import { noop } from "lodash"

import { useRequest, useRequestParams } from "api/hooks"

import {
  GuestHistoryTabItems,
  GuestsHistoryFilter,
  GuestsHistoryReport
} from "./types"
import {
  filterLabels,
  getFiltersByTab,
  GUEST_HISTORY_FILTER,
  guestHistoryText,
  initialFilter,
  initialFilterValues,
  initialReport,
  noGuestsConstants,
  notActiveFilterConstants
} from "./constants"

import {
  CustomBetsComponent,
  CustomCashboxComponent,
  AdditionalCustomFilter
} from "./FilterCustom"
import GuestHistoryReports from "./GuestReports"
import GuestHistoryCard from "./GuestCard"

const GuestsHistory = () => {
  const { current_hall } = useRequestParams()
  const games = useSelector(getGamesList)

  const initialFilterMemo = useMemo(() => {
    const initial = initialFilter()
    const gamesWithSport = [...games, sportGames].map(item => item.id)
    return { ...initial, data: { ...initial.data, games: gamesWithSport } }
  }, [])
  const [filterState, setFilterState] = useState<GuestsHistoryFilter>(
    initialFilterMemo
  )

  const [reportData, setReportData] = useState<GuestsHistoryReport>(
    initialReport
  )

  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  const { request } = useRequest({
    url: `v1/guests/history/card`,
    method: `POST`,
    requestBody: {
      phone: filterState.data.phone,
      hall: Number(current_hall[0])
    }
  })

  useEffect(() => {
    if (filterState.data.phone) {
      setReportData({ ...reportData, pending: true })
      request()
        .then(({ data, headers }: any) =>
          setReportData({
            ...reportData,
            pending: false,
            data,
            total: headers[`total-count`]
          })
        )
        .catch(() => setReportData({ ...reportData, pending: false }))
    }
  }, [filterState.data.phone])

  const [activeTab, toggleActiveTab] = useState<GuestHistoryTabItems>(
    GuestHistoryTabItems.metric
  )

  const changeTab = useCallback(
    (tab: any) => {
      toggleActiveTab(tab)
    },
    [activeTab]
  )

  const filterConfig = useMemo(() => getFiltersByTab(activeTab), [activeTab])

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={GUEST_HISTORY_FILTER}
            filterState={filterState}
            initialForClear={{
              ...initialFilterValues,
              games: [...games, sportGames]
            }}
            setFilterState={setFilterStateCb}
            resetPagination={noop}
            filters={filterConfig}
            filterLabels={filterLabels}
            getDisabled={filter => filter?.phone?.length !== 11}
            CustomComponent={
              activeTab === GuestHistoryTabItems.cashbox
                ? CustomCashboxComponent
                : CustomBetsComponent
            }
            AdditionalCustomComponent={AdditionalCustomFilter}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <>
            <Title
              titleText={guestHistoryText.title}
              tooltipText={guestHistoryText.tooltip}
              rightAdditionalClassName={"GuestHistoryTitleContainer"}
            />
            {reportData.data?.name && filterState.isActive && (
              <div>
                <GuestHistoryCard
                  data={reportData.data}
                  phone={filterState.data.phone}
                />
                <GuestHistoryReports
                  activeTab={activeTab}
                  changeTab={changeTab}
                  filter={filterState.data}
                />
              </div>
            )}
            {!reportData.pending &&
              !reportData.data?.name &&
              filterState.isActive && (
                <EmptyTable
                  desktop={noGuestsConstants.desktop}
                  mobile={noGuestsConstants.mobile}
                />
              )}
            {!filterState.isActive && (
              <NotActiveFilter
                title={notActiveFilterConstants.title}
                description={notActiveFilterConstants.description}
                icon={notActiveFilterConstants.icon}
              />
            )}
          </>
        }
        modalCmp={<div />}
      />
    </>
  )
}

export default GuestsHistory
