import React from "react"
import { trim } from "lodash"
import { convertPhoneToWhatsApp } from "../../helpers"
import { contactsPersonProps } from "../../types"
import {
  emailBeforeTitle,
  phoneBeforeTitlte,
  telegramBeforeTitle,
  whatsAppBeforeTitle
} from "../../constants"
import { formatPhone } from "constants/index"

const ContactsPerson: React.FC<contactsPersonProps> = ({
  person
}: contactsPersonProps) => {
  const officeHours = person.office_hours
    ? person.office_hours.split(",").map(trim)
    : null

  return (
    <div
      className={`ContactsPageSectionStaffItemWrapper ${
        person.name || person.position
          ? ""
          : `ContactsPageSectionStaffItemWrapperEmptyNamePosition`
      }`}
    >
      {(person.name || person.position) && (
        <div className="ContactsPageSectionNameAndPosition">
          {person.name && (
            <div className="ContactsPageSectionStaff_name">{person.name}</div>
          )}
          {person.position && (
            <div className="ContactsPageSectionStaff_position">
              {person.position}
            </div>
          )}
        </div>
      )}
      <div className="ContactsPageSectionCredits">
        {(!!person.phone || !!person.extension_number) && (
          <div className="ContactsPageSectionPhoneWithExtWrapper">
            {!!person.phone && (
              <div className="ContactsPageSectionPhone">
                <span className="ContactsPageSectionCredits_prefix">
                  {phoneBeforeTitlte}
                </span>
                <a href={`tel:+${person.phone}`}>{formatPhone(person.phone)}</a>
                {!!person.extension_number &&
                  ` (доб. ${person.extension_number})`}
              </div>
            )}
          </div>
        )}
        {(!!person.phone2 || !!person.extension_number2) && (
          <div className="ContactsPageSectionPhoneWithExtWrapper">
            {!!person.phone2 && (
              <div className="ContactsPageSectionPhone">
                <span className="ContactsPageSectionCredits_prefix">
                  {phoneBeforeTitlte}
                </span>
                <a href={`tel:+${person.phone2}`}>
                  {formatPhone(person.phone2)}
                </a>
                {!!person.extension_number2 &&
                  ` (доб. ${person.extension_number2})`}
              </div>
            )}
          </div>
        )}
        {!!person.whatsapp && (
          <div className="ContactsPageSectionWhatsApp">
            <span className="ContactsPageSectionCredits_prefix">
              {whatsAppBeforeTitle}
            </span>
            <a
              href={`https://wa.me/${convertPhoneToWhatsApp(person.whatsapp)}`}
            >
              {formatPhone(person.whatsapp)}
            </a>
          </div>
        )}
        {!!person.telegram && (
          <div className="ContactsPageSectionTelegramm">
            <span className="ContactsPageSectionCredits_prefix">
              {telegramBeforeTitle}
            </span>
            <a href={`https://t.me/${person.telegram}`}>{person.telegram}</a>
          </div>
        )}
        {!!person.email && (
          <div className="ContactsPageSectionEmail">
            <span className="ContactsPageSectionCredits_prefix">
              {emailBeforeTitle}
            </span>
            <a href={`mailto:${person.email}`}>{person.email}</a>
          </div>
        )}
      </div>
      {officeHours && (
        <div className="ContactsPageSectionWorkHours">
          {officeHours[0]} - {officeHours[1]}
        </div>
      )}
    </div>
  )
}

export default ContactsPerson
