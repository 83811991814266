/* eslint react/prop-types: "off" */
import React from "react"

import CashboxInside from "../InsideTable"
import { getReportTableDateHeader } from "api/helpers"

import { columnCashbox } from "./helper"
import { CashboxTableProps } from "./types"

import NewTableComponent, { expandIcon } from "Components/TableNew"
import constants from "../../constants"
import { TableWidth } from "Components/Tabs/types"

export const CashboxTable: React.FC<CashboxTableProps> = ({
  cashboxState: { data, pending, total },
  period,
  types,
  pagination,
  setPagination
}) => {
  const report = data.report || []

  const reportWithKey = report.map((item, key) => {
    if (item.date) {
      return { ...item, key, isLast: false }
    }

    return {
      income: item.total.income,
      outcome: item.total.outcome,
      result: item.total.result,
      halls: item.halls,
      isLast: true,
      details: {
        income: item.income,
        outcome: item.outcome,
        result: item.result
      }
    }
  })
  const columnWithDate = [
    {
      title: getReportTableDateHeader(period),
      dataIndex: "date",
      width: 200,
      key: "date",
      render: (record: string | null) => (
        <span className="TextDefaultBold CapitalizeText">
          {record || constants.total}
        </span>
      )
    },
    ...columnCashbox
  ]
  return (
    <div className="ReportTableContentWrapper">
      <NewTableComponent
        pagination={{
          value: pagination,
          total,
          onChange: setPagination
        }}
        width={TableWidth.default}
        tableClassName="CashboxTableWrapper"
        loading={pending}
        columns={columnWithDate}
        data={reportWithKey}
        withTotal={true}
        rowClassName="ExpandableTableRow"
        expandable={{
          expandRowByClick: true,
          expandedRowClassName: () => `Zhopka`,
          expandedRowRender: (
            record: any,
            index: any,
            indent: any,
            expanded: any
          ) => (
            <div>
              {expanded && !pending && !!types?.length && (
                <CashboxInside
                  details={record?.details}
                  halls={record.halls}
                  date={record.date}
                  types={types}
                />
              )}
            </div>
          ),
          expandIcon: ({
            record,
            expanded,
            onExpand
          }: {
            expanded: boolean
            record: any
            onExpand: (data: any, e: any) => void
            withTotal: boolean
          }) =>
            expandIcon({
              record,
              expanded,
              onExpand,
              withTotal: record.halls?.length
            })
        }}
      />
    </div>
  )
}

export default CashboxTable
