import React, { useMemo, useState } from "react"
import { ReactComponent as CloseIcon } from "Components/icons/close-big.svg"
import { Modal } from "antd"
import { ViewPenaltyModalProps } from "./types"
import { formatDate, viewPenaltyModalText } from "./helper"
import { adminPenaltyCardText } from "../AdminPenaltyCard/helper"
import {
  countBusinessDaysDiff,
  getLabelById,
  PenaltyStatuses
} from "../../helpers"
import { UploadAttachment } from "../types"
import FileComponent from "Components/File"
import { ButtonIcon, ButtonPrimary } from "Components/Button"
import { ReactComponent as CancelIcon } from "Components/icons/cancel.svg"
import { ReactComponent as EditIcon } from "Components/icons/edit.svg"
import { ReactComponent as RejectIcon } from "Components/icons/reject_large.svg"
import { moneyRender } from "constants/index"
import { addEditModalText } from "../../constants"

export const ViewPenaltyModal: React.FC<ViewPenaltyModalProps> = ({
  closeModal,
  visible,
  viewModalData,
  statuses,
  setEditModalDataCb,
  setCancelFinalModal,
  setRejectFinalModal,
  setAppealModal,
  winDict
}) => {
  const [showDaysCountText, setShowDaysCountText] = useState(false)

  const showActionButton = useMemo(() => {
    return viewModalData?.status !== PenaltyStatuses.cancel
  }, [viewModalData?.status])

  const showCancelButton = useMemo(() => {
    return viewModalData?.status !== PenaltyStatuses.cancel
  }, [viewModalData?.status])

  const daysCountText = useMemo(() => {
    if (viewModalData?.setDate) {
      const { isBefore, businessDiffText } = countBusinessDaysDiff(
        viewModalData.setDate,
        14
      )
      setShowDaysCountText(
        (viewModalData?.status === PenaltyStatuses.new ||
          viewModalData?.status === PenaltyStatuses.viewed) &&
          !isBefore
      )
      return businessDiffText
    }
  }, [viewModalData?.setDate, viewModalData?.status])

  return (
    <>
      <Modal
        className="SupportProposalModal"
        visible={visible}
        footer={null}
        closeIcon={
          <CloseIcon
            className="ProposalModalCloseIcon"
            onClick={() => {
              closeModal()
            }}
          />
        }
        maskClosable={true}
        width={
          viewModalData?.appeal &&
          Object.values(viewModalData.appeal)?.length > 0
            ? 1324
            : 678
        }
        zIndex={50}
      >
        <div className="ProposalModalTitleWrapper">
          <div className="ProposalModalTextTitleText">
            {`${viewPenaltyModalText.title} #${
              viewModalData?.id
            } ${getLabelById(viewModalData?.status, statuses)}`}
          </div>
          <div className="ViewPenaltyModalHeaderCards">
            <div className="PenaltyAdminCardFooterCard">{`${adminPenaltyCardText.hallNumber} ${viewModalData?.hall}`}</div>
            {viewModalData?.status === PenaltyStatuses.reviewed ? (
              <>
                <div className="PenaltyAdminCardFooterCard">
                  {viewModalData?.penaltyValueResolved
                    ? `${moneyRender(viewModalData?.penaltyValueResolved)} ${
                        adminPenaltyCardText.rubles
                      }`
                    : adminPenaltyCardText.warning}
                </div>
                <div className="PenaltyAdminCardFooterCard">
                  {viewModalData?.winResolved !== null
                    ? winDict.find(x => x.id == viewModalData?.winResolved)
                        ?.value
                    : adminPenaltyCardText.winNull}
                </div>
              </>
            ) : (
              <>
                <div className="PenaltyAdminCardFooterCard">
                  {viewModalData?.penaltyValue
                    ? `${moneyRender(viewModalData?.penaltyValue)} ${
                        adminPenaltyCardText.rubles
                      }`
                    : adminPenaltyCardText.warning}
                </div>
                <div className="PenaltyAdminCardFooterCard">
                  {viewModalData?.win !== null
                    ? winDict.find(x => x.id == viewModalData?.win)?.value
                    : adminPenaltyCardText.winNull}
                </div>
              </>
            )}
          </div>
          <div className="ViewPenaltyModalHeaderDate">{`${
            viewPenaltyModalText.setDate
          } ${formatDate(viewModalData?.setDate)}`}</div>
        </div>

        <div className="ViewPenaltyContentWrapper">
          <div className="ViewPenaltyContentLeft">
            <div>
              <div className="ViewPenaltyModalSubtitle ViewPenaltyModalSubtitleFirst">
                {viewPenaltyModalText.findDate}
              </div>
              <div>{formatDate(viewModalData?.findDate)}</div>
            </div>

            <div>
              <div className="ViewPenaltyModalSubtitle">
                {addEditModalText.reason}
              </div>
              <div className="ViewPenaltyModalTextContent">
                {viewModalData?.reason}
              </div>
            </div>

            <div>
              <div className="ViewPenaltyModalSubtitle">
                {addEditModalText.justification}
              </div>
              <div className="ViewPenaltyModalTextContent">
                {viewModalData?.reasonComment}
              </div>
            </div>

            {!!viewModalData?.attachments?.length && (
              <div>
                <div className="ViewPenaltyModalSubtitle">
                  {addEditModalText.attachments}
                </div>
                <div className="ViewPenaltyFilesWrapper">
                  {viewModalData?.attachments.map((file: UploadAttachment) => (
                    <FileComponent
                      key={file.size + file.name}
                      name={file.name}
                      view
                      size={Number(file.size)}
                      link={file.link}
                      type={file.type}
                    />
                  ))}
                </div>
              </div>
            )}

            {viewModalData?.editPenaltyComment && (
              <div className="ViewPenaltyModalComment">
                <div className="ViewPenaltyModalSubtitle">
                  {viewPenaltyModalText.adminComment}
                </div>
                <div className="ViewPenaltyModalTextContent">
                  {viewModalData?.editPenaltyComment}
                </div>
              </div>
            )}
            {viewModalData?.resolveComment && (
              <div className="ViewPenaltyModalComment">
                <div className="ViewPenaltyModalSubtitle">
                  {viewPenaltyModalText.adminComment}
                </div>
                <div className="ViewPenaltyModalTextContent">
                  {viewModalData?.resolveComment}
                </div>
              </div>
            )}
          </div>

          {viewModalData?.appeal &&
            Object.values(viewModalData.appeal)?.length > 0 && (
              <div className="ViewPenaltyContentRight">
                <div>
                  <div className="ViewPenaltyModalSubtitle ViewPenaltyModalSubtitleFirst">
                    {viewPenaltyModalText.appealDate}
                  </div>
                  <div>{formatDate(viewModalData?.appeal?.date)}</div>
                </div>

                <div>
                  <div className="ViewPenaltyModalSubtitle">
                    {viewPenaltyModalText.responsible}
                  </div>
                  <div className="ViewPenaltyModalTextContent">
                    {viewModalData?.appeal?.name}
                  </div>
                </div>

                <div>
                  <div className="ViewPenaltyModalSubtitle">
                    {viewPenaltyModalText.comment}
                  </div>
                  <div className="ViewPenaltyModalTextContent">
                    {viewModalData?.appeal?.comment}
                  </div>
                </div>

                {!!viewModalData?.appeal?.attachments?.length && (
                  <div>
                    <div className="ViewPenaltyModalSubtitle">
                      {viewPenaltyModalText.resolveAttachments}
                    </div>
                    <div className="ViewPenaltyFilesWrapper">
                      {viewModalData?.appeal?.attachments.map(
                        (file: UploadAttachment) => (
                          <FileComponent
                            key={file.size + file.name}
                            name={file.name}
                            view
                            size={Number(file.size)}
                            link={file.link}
                          />
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>

        <div className="ViewModalButtonsGroupWrapper">
          <div className="ViewModalButtonsGroupLeft">
            <ButtonIcon
              className="ButtonIcon_basic ViewModalEditButton"
              icon={<EditIcon />}
              onClick={() => {
                setEditModalDataCb(viewModalData)
              }}
            >
              {viewPenaltyModalText.editButton}
            </ButtonIcon>

            {showCancelButton && (
              <ButtonIcon
                className="ButtonIcon_basic ViewModalCancelButton"
                icon={<CancelIcon />}
                onClick={() => setCancelFinalModal()}
              >
                {viewPenaltyModalText.cancelButton}
              </ButtonIcon>
            )}
            {showDaysCountText && (
              <div className="ViewPenaltyModalUserCountBack">
                {daysCountText}
              </div>
            )}
          </div>
          {showActionButton &&
            viewModalData?.appeal &&
            Object.values(viewModalData.appeal)?.length > 0 && (
              <div className="ViewModalButtonsGroupRight">
                <ButtonPrimary
                  onClick={() => setAppealModal()}
                  className="ButtonIcon_basic ViewModalResolveButton"
                >
                  {viewPenaltyModalText.resolveButton}
                </ButtonPrimary>

                <ButtonIcon
                  className="ButtonIcon_basic ViewModalRejectButton"
                  icon={<RejectIcon />}
                  onClick={() => setRejectFinalModal()}
                >
                  {viewPenaltyModalText.rejectButton}
                </ButtonIcon>
              </div>
            )}
        </div>
      </Modal>
    </>
  )
}
