export enum MessageActiveTab {
  read = `read`,
  unread = `unread`,
  all = `all`,
  pinned = `pinned`
}

export type MessageItemProp = {
  id: number
  title: string
  text: string
  pinned: boolean
  files: {
    name: string
    link: string
    size: number
  }[]
  date: string
  halls: string[]
}

export type MessageListProp = {
  data: {
    read: MessageItemProp[]
    unread: MessageItemProp[]
  }
  total: number
  pending: boolean
}

export type PinnedComponentProps = {
  pin: boolean
  id: number
  isSmall: boolean
  successAction?: () => void
}
