import React, { useCallback, useEffect } from "react"
import { MobileTableCardProps, NominalsMinMaxKeys, TableRow } from "../types"
import { nominalsText } from "../constants"
import { ReactComponent as DropDownIcon } from "Components/icons/dropdown_12.svg"
import { getMinMaxKey } from "../helper"

export const MobileTableCard: React.FC<MobileTableCardProps> = ({
  data,
  columns,
  minMaxColumns,
  minMaxData,
  isMinMax
}) => {
  const [isLimitsShown, setLimitsShown] = React.useState(false)
  const [activeLimit, setActiveLimit] = React.useState(NominalsMinMaxKeys.min)

  const toggleLimits = useCallback(() => {
    setLimitsShown(!isLimitsShown)
  }, [isLimitsShown])

  useEffect(() => {
    if (!isMinMax) {
      setLimitsShown(false)
    }
  }, [isMinMax])

  const handleLimitSwitchCLick = useCallback((limit: NominalsMinMaxKeys) => {
    setActiveLimit(limit)
  }, [])

  return (
    <div className="MobileTableCardContainer">
      {data.map((rowData, rowIndex) => (
        <div className="MobileTableCardRow" key={rowIndex}>
          {columns.map((column, colIndex) => {
            const cellData = rowData[column.dataIndex as keyof TableRow]
            if (column.render) {
              return (
                <div
                  className={`MobileTableCardRowItem ${
                    colIndex === 0
                      ? "MobileTableCardRowItem_TitleRow"
                      : "MobileTableCardRowItem__WithDots"
                  }`}
                  key={colIndex}
                >
                  {colIndex !== 0 && (
                    <div className="MobileTableCardRowItemTitle">
                      {column.title}
                    </div>
                  )}
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/*@ts-ignore*/}
                  {column.render(cellData, rowData, rowIndex)}
                </div>
              )
            }
            return null
          })}
        </div>
      ))}
      {isLimitsShown && (
        <div className="MobileTableCardLimitsSwitchContainer">
          <div
            className={`MobileTableCardLimitsSwitchItem ${
              activeLimit === NominalsMinMaxKeys.min
                ? "MobileTableCardLimitsSwitchItem_active"
                : ""
            }`}
            onClick={handleLimitSwitchCLick.bind(null, NominalsMinMaxKeys.min)}
          >
            {nominalsText.minLimit}
          </div>
          <div
            className={`MobileTableCardLimitsSwitchItem ${
              activeLimit === NominalsMinMaxKeys.max
                ? "MobileTableCardLimitsSwitchItem_active"
                : ""
            }`}
            onClick={handleLimitSwitchCLick.bind(null, NominalsMinMaxKeys.max)}
          >
            {nominalsText.maxLimit}
          </div>
        </div>
      )}
      {isLimitsShown && (
        <div>
          {minMaxData?.map((rowData, rowIndex) => (
            <div
              className={`MobileTableCardRow MobileTableCardRowMinMax ${
                getMinMaxKey(rowData) === activeLimit
                  ? "MobileTableCardRowMinMax__visible"
                  : ""
              }`}
              key={rowIndex}
            >
              {minMaxColumns?.map((column, colIndex) => {
                const cellData = rowData[column.dataIndex as keyof TableRow]
                if (column.render && colIndex !== 0) {
                  return (
                    <div
                      className="MobileTableCardRowItem MobileTableCardRowItem__WithDots"
                      key={colIndex}
                    >
                      <div className="MobileTableCardRowItemTitle">
                        {column.title}
                      </div>
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/*@ts-ignore*/}
                      {column.render(cellData, rowData, rowIndex)}
                    </div>
                  )
                }
                return null
              })}
            </div>
          ))}
        </div>
      )}
      {isMinMax && (
        <div
          className={`MobileTableCardLimitsToggleContainer ${
            isLimitsShown ? "MobileTableCardLimitsToggleContainer_shown" : ""
          }`}
          onClick={toggleLimits}
        >
          <div className="MobileTableCardLimitsToggleContainer_Text">
            {isLimitsShown ? nominalsText.hideLimits : nominalsText.showLimits}
          </div>
          <DropDownIcon />
        </div>
      )}
    </div>
  )
}
