import React, { useState } from "react"
import { Calendar, Popover, TimePicker, Tooltip } from "antd"
import MaskedInput from "antd-mask-input"

import { ButtonNotifi, ButtonPrimary } from "../Button"
import { ReactComponent as SendLaterIcon } from "Components/icons/send_later.svg"
import { ReactComponent as PrevMonthIcon } from "./prev.svg"
import { ReactComponent as NextMonthIcon } from "./next.svg"
import moment, { Moment } from "moment"

type CalendarPopupProps = {
  setDate: (value: Moment | null) => void
  date: Moment | null
}

const CalendarPopup = ({ date, setDate }: CalendarPopupProps) => {
  const [value, setValue] = useState<any>(date || moment())
  const [visible, toggleVisible] = useState<boolean>(false)

  const disablePrev =
    value &&
    moment(value).month() === moment().month() &&
    moment(value).year() === moment().year()
  const isToday =
    moment(value)
      .startOf(`day`)
      .valueOf() ===
    moment()
      .startOf(`day`)
      .valueOf()
  return (
    <Popover
      overlayClassName="CalendarPopupOverlayWrapper"
      trigger="click"
      visible={visible}
      destroyTooltipOnHide={true}
      content={() => (
        <div>
          <div style={{ width: 264 }}>
            <Calendar
              className="CalendarPopupWrapper"
              fullscreen={false}
              value={value}
              onChange={value => setValue(value)}
              disabledDate={(current: Moment) =>
                moment(current).valueOf() <
                moment()
                  .startOf(`day`)
                  .valueOf()
              }
              headerRender={({ value }) => {
                return (
                  <div className="CalendarPopupHeader FlexRow">
                    <div
                      onClick={() => {
                        if (!disablePrev) {
                          const newValue = moment(value).subtract(1, `month`)
                          newValue &&
                          moment(newValue).month() === moment().month() &&
                          moment(newValue).year() === moment().year()
                            ? setValue(moment())
                            : setValue(newValue)
                        }
                      }}
                      className={
                        disablePrev
                          ? `CalendarPopupHeaderArrowDisabled`
                          : `CalendarPopupHeaderArrow`
                      }
                    >
                      <PrevMonthIcon />
                    </div>
                    <div className="CalendarPopupHeaderTitle FlexRow">
                      {moment(value).format(`MMMM  YYYY`)}
                    </div>
                    <div
                      onClick={() => {
                        const newValue = moment(value).add(1, `month`)
                        setValue(newValue)
                      }}
                      className="CalendarPopupHeaderArrow"
                    >
                      <NextMonthIcon />
                    </div>
                  </div>
                )
              }}
            />
          </div>
          <div className="CalendarPopupPickers FlexRow">
            <div className="CalendarPopupDatePicker FlexColumn">
              <div className="CalendarPopupPickersLabel">Дата</div>
              <MaskedInput
                mask={`00.00.0000`}
                bordered={false}
                value={moment(value).format(`DD.MM.YYYY`)}
                onChange={({ target: { value: date } }) => {
                  if (
                    !!date &&
                    !date.includes(`_`) &&
                    moment(
                      `${date}:${moment(value).format(`HH:mm`)}`,
                      `DD.MM.YYYY:HH:mm`
                    ).isValid() &&
                    moment(date, `DD.MM.YYYY`).valueOf() >=
                      moment()
                        .startOf(`day`)
                        .valueOf()
                  ) {
                    setValue(
                      moment(
                        `${date}:${moment(value).format(`HH:mm`)}`,
                        `DD.MM.YYYY:HH:mm`
                      )
                    )
                  }
                }}
              />
            </div>
            <div className="CalendarPopupDatePicker FlexColumn">
              <div className="CalendarPopupPickersLabel">Время</div>
              <TimePicker
                suffixIcon={<div />}
                format="HH:mm"
                disabledHours={() => {
                  const currentHour: number = moment().hour()
                  const disabledHours: number[] = isToday
                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      [...Array(currentHour).keys()]
                    : []
                  return disabledHours
                }}
                disabledMinutes={(selectedHour: number) => {
                  const currentMinute = moment().minute()
                  const disabledMinutes =
                    isToday && moment().hour() === selectedHour
                      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        [...Array(currentMinute).keys(currentMinute)]
                      : []
                  return disabledMinutes
                }}
                value={value}
                bordered={false}
                showNow={false}
                onSelect={time => setValue(time)}
                allowClear={false}
                className="CalendarPopupPickersTime"
                popupClassName="CalendarPopupPickersTimeDropdown"
              />
            </div>
          </div>
          <div className="CalendarPopupButtons FlexRow">
            <ButtonPrimary
              isMiddle={true}
              onClick={() => {
                setDate(value || null)
                toggleVisible(false)
              }}
            >
              Сохранить
            </ButtonPrimary>
            <ButtonNotifi
              isMiddle={true}
              onClick={() => {
                setDate(null)
                toggleVisible(false)
              }}
            >
              <div>
                <span>Отменить</span>
              </div>
            </ButtonNotifi>
          </div>
        </div>
      )}
    >
      <Tooltip
        overlayClassName="InfoTooltipWrapper"
        placement="bottom"
        title="Отложенное сообщение"
        trigger="hover"
        color="#343A40"
      >
        <ButtonNotifi onClick={() => toggleVisible(!visible)}>
          <div className="FlexRow">
            <div style={{ paddingTop: `4px` }}>
              <SendLaterIcon />
            </div>
            {!!date && (
              <div className="CalendarPopupSendAfterText FlexColumn">
                <div>Будет отправлено</div>
                <div>{`${date.format(`D MMM YYYY в HH:mm `)} (Мск)`}</div>
              </div>
            )}
          </div>
        </ButtonNotifi>
      </Tooltip>
    </Popover>
  )
}

export default CalendarPopup
