import React, { useCallback, useMemo, useState } from "react"

import { noop } from "lodash"

import { useRequestParams } from "api/hooks"

import { isMobile } from "constants/index"

import SuperwinsGameList from "./Components/GamesList"
import SuperwinList from "./Components/SuperwinList"
import { SideFilter } from "Components/SideFilter/SideFilter"
import ReportWithFilter from "Components/ReportWithFilter"
import { Filters } from "Components/SideFilter/types"

import { SuperwinFilterManage, SuperwinReportState } from "../types"
import { SuperwinTabsComponent, SuperwinTitleComponent } from "../index"

import { initialManageFilter, SUPERWIN_FILTERS_MANAGE } from "./constants"

const ManageContent = ({ activeTab, toggleActiveTab }: SuperwinReportState) => {
  const { current_hall } = useRequestParams()
  const initialManageFilterValues = useMemo(
    () => initialManageFilter(`${current_hall}`),
    []
  )
  const [filter, setFilter] = useState<SuperwinFilterManage>(
    initialManageFilterValues
  )
  const setFilterStateCb = useCallback(value => {
    setFilter(value)
  }, [])

  return (
    <ReportWithFilter
      filterComponent={
        <SideFilter
          filterName={SUPERWIN_FILTERS_MANAGE}
          filterState={filter}
          setFilterState={setFilterStateCb}
          filters={[Filters.hallPicker]}
          getDisabled={filter => !filter?.halls?.length}
          resetPagination={noop}
        />
      }
      mobileFilter={filter.mobileFilter}
      isOpen={filter.isOpen}
      toggleFilter={() => setFilter({ ...filter, isOpen: !filter.isOpen })}
      toggleMobileFilter={() =>
        setFilter({
          ...filter,
          mobileFilter: !filter.mobileFilter
        })
      }
      pageContent={
        <>
          <SuperwinTitleComponent />
          <SuperwinTabsComponent
            activeTab={activeTab}
            toggleActiveTab={toggleActiveTab}
          />
          <div className="FlexColumn">
            {!isMobile && <SuperwinsGameList />}
            <SuperwinList halls={filter.data.halls} />
            {isMobile && <SuperwinsGameList />}
          </div>
        </>
      }
      modalCmp={<div />}
    />
  )
}
export default ManageContent
