import React, { useCallback, useEffect, useMemo, useState } from "react"
import Title from "Components/Title"
import { ButtonIcon, ButtonPrimary } from "Components/Button"
import EditorComponent from "Components/Editor"
import { ReactComponent as EditIcon } from "Components/icons/edit.svg"
import { ReactComponent as CancelIcon } from "Components/icons/cancel.svg"
import { allowedRoles, newHallText, useNewHallRequests } from "./helpers"
import { NewHallContent } from "./types"

export const NewHall = () => {
  const [editMode, setEditMode] = useState(false)
  const [content, setContent] = useState<NewHallContent | null>(null)
  const [loading, setLoading] = useState(false)
  const [editorContent, setEditorContent] = useState("")
  const [contentChanged, setContentChanged] = useState(false)

  const editAllow = useMemo(() => {
    const roleFromLs = localStorage.getItem("role")
    return allowedRoles.some(role => role === roleFromLs)
  }, [])

  const toggleEditModeCb = useCallback(() => {
    setEditMode(!editMode)
  }, [editMode])

  const setNewContentCb = useCallback(() => {
    setContent({ ...content, text: editorContent } as NewHallContent)
    setContentChanged(true)
  }, [editorContent, content])

  const cancelNewContentCb = useCallback(() => {
    setEditorContent(content?.text || "")
    setEditMode(false)
  }, [content])

  const setEditorContentCb = useCallback(
    (value: string) => {
      setEditorContent(value)
    },
    [content, editorContent]
  )

  const { getNewHallContent, setNewHallContent } = useNewHallRequests(content)

  useEffect(() => {
    setLoading(true)
    getNewHallContent()
      .then(res => {
        setContent(res.data)
        setEditorContent(res.data.text)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (contentChanged) {
      setLoading(true)
      setNewHallContent().finally(() => {
        setContentChanged(false)
        setLoading(false)
        setEditMode(false)
      })
    }
  }, [contentChanged])

  return (
    <div className="NewHallContent MainPageContentWrapper">
      <Title
        titleText={newHallText.title}
        tooltipText={newHallText.tooltip}
        rightAdditionalCmp={
          editAllow &&
          (editMode ? (
            <div className="NewHallContent_ButtonsGroup">
              <ButtonPrimary onClick={setNewContentCb} loading={loading}>
                {newHallText.saveButton}
              </ButtonPrimary>
              <ButtonIcon onClick={cancelNewContentCb} icon={<CancelIcon />}>
                {newHallText.cancelButton}
              </ButtonIcon>
            </div>
          ) : (
            <ButtonIcon onClick={toggleEditModeCb} icon={<EditIcon />} />
          ))
        }
        rightAdditionalClassName={"ProposalNewButtonTitleContainer"}
      />
      <EditorComponent
        initial={content?.text}
        error=""
        disabled={!editMode}
        setFormData={setEditorContentCb}
        stripPastedStyles
      />
    </div>
  )
}
