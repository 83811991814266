import React from "react"

import { TooltipPlacement } from "antd/es/tooltip"

import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"

import TooltipComponent from "Components/Tooltip"
import { TitleProps } from "./types"
import { isMobile } from "constants/index"

export const TitleTooltipComponent = ({
  tooltipText,
  direction,
  icon
}: {
  tooltipText: string
  direction?: TooltipPlacement
  icon?: React.ReactNode
}) => {
  const defaultDirection = isMobile ? `rightTop` : `bottom`
  return (
    <div className="TitleTooltip">
      <TooltipComponent
        direction={direction ? direction : defaultDirection}
        title={tooltipText}
      >
        {icon || <InfoIcon />}
      </TooltipComponent>
    </div>
  )
}

export const Title: React.FC<TitleProps> = ({
  titleText,
  tooltipText,
  rightAdditionalCmp,
  bottomAdditionalCmp,
  rightAdditionalClassName,
  bottomAdditionalClassName,
  wrapperClass,
  direction,
  defaultBottomCmpMargin
}) => (
  <div className={`TitleWrapper ${wrapperClass || ""}`}>
    <div className="TitleDescription">
      <div className="TitleText FlexRow">
        <span>
          {titleText}
          {!!tooltipText && (
            <TitleTooltipComponent
              tooltipText={tooltipText}
              direction={direction}
            />
          )}
        </span>
      </div>
      {!!rightAdditionalCmp && (
        <div
          className={`TitleRightAdditional ${rightAdditionalClassName || ""}`}
        >
          {rightAdditionalCmp}
        </div>
      )}
    </div>
    {!!bottomAdditionalCmp && (
      <div
        className={`${
          defaultBottomCmpMargin ? "TitleBottomAdditionalDefault" : ""
        } ${bottomAdditionalClassName || ""}`}
      >
        {bottomAdditionalCmp}
      </div>
    )}
  </div>
)

export default Title
