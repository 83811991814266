import React, { useEffect, useState } from "react"
import {
  addEditModalRadioButtonsOptions,
  AddEditPenaltyModalDatePicker,
  AddEditPenaltyModalSelect,
  allowedTypes,
  FormItemNames,
  formRules
} from "./helper"
import { Form, Upload } from "antd"
import InputComponent, { InputTextAreaComponent } from "Components/Input"
import { UploadAttachment, UploadAttachments } from "../types"
import FileComponent from "Components/File"
import { ButtonNotifi, ButtonPrimary } from "Components/Button"
import { ReactComponent as AttachIcon } from "Components/icons/attach.svg"
import { AddEditPenaltyModalContentProps } from "./types"
import { ModalRadioButtons } from "../helper"
import { addEditModalText } from "../../constants"

export const AddEditPenaltyModalContent: React.FC<AddEditPenaltyModalContentProps> = ({
  form,
  onFinish,
  onFieldsChange,
  modalData,
  setModalData,
  loading,
  newModal,
  reasonOptions,
  winOptions,
  setNextOptionCb
}) => {
  const [warning, setWarning] = useState(false)

  const [radioValue, setRadioValue] = useState(
    addEditModalRadioButtonsOptions[0].value
  )

  useEffect(() => {
    //warning
    if (radioValue === addEditModalRadioButtonsOptions[1].value) {
      setWarning(true)
    } else {
      setWarning(false)
    }
  }, [radioValue])

  useEffect(() => {
    if (warning) {
      form.setFields([
        {
          name: FormItemNames.amount,
          errors: [],
          value: 0
        },
        {
          name: FormItemNames.win,
          errors: [],
          value: null
        }
      ])
    } else {
      form.setFields([
        {
          name: FormItemNames.amount,
          errors: [],
          value: modalData?.penaltyValue || ""
        }
      ])
    }
  }, [warning, winOptions])

  return (
    <>
      <div className="ProposalModalTitleWrapper">
        <div className="ProposalModalTextTitleText">
          {newModal
            ? addEditModalText.newTitle
            : `${addEditModalText.editTitle} #${modalData?.id}`}
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        autoComplete="off"
      >
        <div className={"ProposalModalContentWrapper"}>
          <div className="AddEditPenaltyModalFieldsRow">
            <Form.Item
              name={FormItemNames.hall}
              label={
                <div className="ProposalModalSubTitleWrapper">
                  {addEditModalText.hallNumber}
                </div>
              }
              rules={formRules.input}
            >
              <InputComponent
                className="AddEditPenaltyModalHallNumberInput"
                placeholder={addEditModalText.hallNumberPlaceholder}
                greyBackground
                type={"number"}
                min={0}
                hideControls
              />
            </Form.Item>

            <Form.Item
              name={FormItemNames.date}
              label={
                <div className="ProposalModalSubTitleWrapper">
                  {addEditModalText.date}
                </div>
              }
              rules={formRules.date}
            >
              <AddEditPenaltyModalDatePicker />
            </Form.Item>
          </div>

          <Form.Item
            name={FormItemNames.reason}
            label={
              <div className="ProposalModalSubTitleWrapper">
                {addEditModalText.reason}
              </div>
            }
            rules={formRules.select}
          >
            <AddEditPenaltyModalSelect
              options={reasonOptions}
              placeholder={addEditModalText.reasonTooltip}
              setNextOptionCb={setNextOptionCb}
            />
          </Form.Item>

          <Form.Item
            label={
              <div className="ProposalModalSubTitleWrapper">
                {addEditModalText.actions}
              </div>
            }
          >
            <ModalRadioButtons
              value={radioValue}
              onChange={val => setRadioValue(val)}
              options={addEditModalRadioButtonsOptions}
            />
          </Form.Item>

          <div
            className={` AddEditPenaltyModalFieldsRow ${
              warning ? "ModalDisabledFormItem" : ""
            }`}
          >
            <Form.Item
              name={FormItemNames.amount}
              label={
                <div className="ProposalModalSubTitleWrapper">
                  {addEditModalText.amount}
                </div>
              }
              rules={warning ? [] : formRules.input}
            >
              <InputComponent
                type="number"
                min={0}
                className="AddEditPenaltyModalAmountInput"
                placeholder={addEditModalText.amountPlaceholder}
                greyBackground
                hideControls
                disabled={warning}
              />
            </Form.Item>

            <Form.Item
              name={FormItemNames.win}
              label={
                <div className="ProposalModalSubTitleWrapper">
                  {addEditModalText.win}
                </div>
              }
              rules={warning ? [] : formRules.select}
            >
              <AddEditPenaltyModalSelect
                options={winOptions}
                winSelect
                placeholder={addEditModalText.winPlaceholder}
                disabled={warning}
              />
            </Form.Item>
          </div>

          <Form.Item
            name={FormItemNames.comment}
            label={
              <div className="ProposalModalSubTitleWrapper">
                {addEditModalText.justification}
              </div>
            }
            rules={formRules.textarea}
          >
            <InputTextAreaComponent
              className="ProposalModalTextTextArea"
              placeholder={addEditModalText.commentPlaceholder}
              autoSize={{ minRows: 7, maxRows: 7 }}
              greyBackground
            />
          </Form.Item>
          <div>
            <div className="ProposalModalSubTitleWrapper">
              {addEditModalText.attachments}
            </div>
            <div className="ProposalModalFilesWrapper">
              {(modalData?.attachments).map((file: UploadAttachment) => (
                <FileComponent
                  key={file.size + file.name}
                  name={file.name}
                  size={Number(file.size)}
                  link={file.link}
                  deleteFile={() => {
                    setModalData(prev => {
                      const next = prev.attachments.filter(
                        att => att.name !== file.name
                      ) as UploadAttachments
                      return { ...prev, attachments: next }
                    })
                  }}
                />
              ))}
              <Form.Item name={FormItemNames.file}>
                <Upload
                  customRequest={() => null}
                  itemRender={() => null}
                  accept={allowedTypes}
                  multiple
                >
                  <ButtonNotifi className="AddEditModalPenaltyAttachmentsButton">
                    <AttachIcon className="AddEditModalPenaltyAttachmentsIcon" />
                  </ButtonNotifi>
                </Upload>
              </Form.Item>
              {!modalData.attachments.length && (
                <div className="AddEditModalPenaltyAttachmentsTooltip">
                  {addEditModalText.attachmentsTooltip}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="ProposalModalButtonsGroup ProposalModalButtonsGroupTopLine">
          <Form.Item>
            <ButtonPrimary loading={loading} htmlType="submit">
              {addEditModalText.sendButton}
            </ButtonPrimary>
          </Form.Item>
        </div>
      </Form>
    </>
  )
}
