import React from "react"

const CounterComponent = ({ count }: { count: number }) => {
  const showCount = count > 0
  return showCount ? (
    <div className="CounterComponentWrapper">{count}</div>
  ) : (
    <div />
  )
}

export default CounterComponent
