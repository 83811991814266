import React from "react"

import { CheckBoxComponent } from "Components/CheckBox"
import { SelectComponent } from "Components/Select"
import { SortOrders } from "../../types"

const sortText = {
  label: `Сортировать`,
  options: [
    {
      value: SortOrders.asc,
      label: `По возрастанию`
    },
    {
      value: SortOrders.desc,
      label: `По убыванию`
    }
  ]
}

type SortTableFilterProps = {
  order: SortOrders | undefined
  hasSort: boolean
  changeHasSort: (val: boolean) => void
  changeOrder: (val: SortOrders | undefined) => void
}

const SortTableFilter = ({
  order,
  hasSort,
  changeHasSort,
  changeOrder
}: SortTableFilterProps) => (
  <div className="TableFilterSortWrapper">
    <div
      className="FlexRow TableFilterSortCheckBoxWrapper"
      onClick={e => {
        changeHasSort(!hasSort)
        e.stopPropagation()
      }}
    >
      <CheckBoxComponent checked={hasSort} />
      <span className="SubtitleFirstText">{sortText.label}</span>
    </div>
    <SelectComponent
      value={order || SortOrders.asc}
      options={sortText.options}
      onChange={value => changeOrder(value)}
      allowClear={false}
    />
  </div>
)

export default SortTableFilter
