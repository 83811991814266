export const customFiltersConstants = {
  search: {
    label: `Поиск`,
    placeholder: `ФИО, телефон или почта`
  },
  status: {
    label: `Статус`,
    any: `Любой`,
    tooltip: {
      first: `Активен - активный статус пользователей, которые авторизуются в течении 30 дней 
с момента последнего входа`,
      second: `Приостановлен - устанавливается, если пользователь не авторизовывается более 
30 дней с момента последнего входа`,
      third: `Возобновление - после установки 
данного статуса, пользователь должен авторизоваться в течении суток. 
Если авторизация не произошла, статус пользователя меняется на “Приостановлен”`
    }
  }
}
