import React from "react"

import { Pagination } from "antd"

import { ReactComponent as NextIcon } from "./go-next.svg"
import { ReactComponent as PrevIcon } from "./go-prev.svg"

import { SelectComponent } from "../Select"

import {
  PaginationComponentProps,
  TablePaginationComponentProps
} from "./types"
import { paginationText, options } from "./constants"

const PaginationComponent = ({
  value: { offset, limit },
  pageSize = 30,
  onChange,
  total
}: PaginationComponentProps) => {
  const currentValue = offset / limit + 1
  const hiddenPag = total < 11
  const disableNext = offset + pageSize >= total
  const disablePrev = offset === 0

  return (
    <div className="PaginationWrapper">
      {!hiddenPag && (
        <div className="FlexRow">
          {!disablePrev && (
            <div
              className="PaginationWrapperButtons"
              onClick={() => {
                onChange({ limit: pageSize, offset: offset - pageSize })
              }}
            >
              <PrevIcon />
            </div>
          )}
          <Pagination
            hideOnSinglePage={true}
            showSizeChanger={false}
            total={total}
            pageSize={pageSize}
            current={currentValue}
            onChange={page => {
              onChange({ limit: pageSize, offset: (page - 1) * pageSize })
            }}
          />
          {!disableNext && (
            <div
              className="PaginationWrapperButtons"
              onClick={() => {
                onChange({ limit: pageSize, offset: offset + pageSize })
              }}
            >
              <NextIcon />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default PaginationComponent

export const TablePaginationComponent = ({
  value: { offset, limit },
  onChange,
  total
}: TablePaginationComponentProps) => {
  const currentValue = offset / limit + 1
  const hiddenPag = total < 11
  const disableNext = offset + limit >= total
  const disablePrev = offset === 0
  return (
    <div className="TablePaginationWrapper FlexRow">
      {!hiddenPag && (
        <div className="FlexRow">
          {!disablePrev && (
            <div
              className="TablePaginationWrapperButtons"
              onClick={() => {
                onChange({ limit, offset: offset - limit })
              }}
            >
              <PrevIcon />
            </div>
          )}
          <Pagination
            hideOnSinglePage={true}
            showSizeChanger={false}
            itemRender={(
              page: number,
              type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
              originalElement
            ) => {
              if (type === "jump-prev" || type === "jump-next") {
                return (
                  <div className="TextOne TablePaginationJump">
                    {paginationText.jumpDots}
                  </div>
                )
              }
              return originalElement
            }}
            pageSize={limit}
            total={total}
            current={currentValue}
            onChange={(page, pageSize) => {
              onChange({ limit: pageSize, offset: (page - 1) * pageSize })
            }}
          />
          {!disableNext && (
            <div
              className="TablePaginationWrapperButtons"
              onClick={() => {
                onChange({ limit, offset: offset + limit })
              }}
            >
              <NextIcon />
            </div>
          )}
        </div>
      )}
      <div className="TablePaginationPageSizeWrapper FlexRow">
        <span className="TextDefault">{paginationText.pageSizeLabel}</span>
        <SelectComponent
          value={limit}
          isPagination={true}
          allowClear={false}
          options={options}
          onChange={value => onChange({ limit: value, offset: 0 })}
        />
      </div>
    </div>
  )
}
