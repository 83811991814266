import React, { useMemo } from "react"

import { formatToCurrency } from "constants/index"

import { ReactComponent as SuccessIcon } from "Components/icons/success_small.svg"

import { tableText } from "./constants"
import { GuestHistoryMetricTableData } from "../types"

import { TooltipCustom, TooltipCustomSize } from "Components/Tooltip"

const GuestMetricTableComponent = ({
  data
}: {
  data: GuestHistoryMetricTableData
}) => {
  const averageVisitTime = useMemo(
    () => tableText.getAverageGameDuration(data.average_game_duration),
    [data.average_game_duration]
  )
  const isMaxStatus = data.next_status === null
  return (
    <div>
      <div className="GuestMetricTableTitle">{tableText.title}</div>
      <div className="GuestMetricTableWrapper">
        <div className="GuestMetricTableRow GuestMetricTableRowFirst">
          <div className="GuestMetricTableItem GuestMetricTableItemFirst GuestMetricTableItemFirstMobile">
            <span className="HeadlineThirdText GuestMetricTableItemValue">
              {data.favorite_game}
            </span>
            <span className="TextDefault GuestMetricTableItemDescription">
              {tableText.favorite_game || `-`}
            </span>
          </div>
          <div className="GuestMetricTableItem">
            {isMaxStatus ? (
              <div className="FlexRow">
                <div className="GuestMetricTableItemMaxCashback">
                  <SuccessIcon />
                </div>
                <span className="HeadlineThirdText">
                  {tableText.next_status_max}
                </span>
              </div>
            ) : (
              <span className="HeadlineThirdText GuestMetricTableItemValue FlexRow">
                {`${formatToCurrency({
                  amount: data.next_status,
                  withoutFixed: true,
                  withoutSpace: true
                })} ${tableText.next_status_unit}`}
                {
                  <TooltipCustom
                    title={tableText.next_status_tooltip}
                    size={TooltipCustomSize.small}
                  />
                }
              </span>
            )}
            <span className="TextDefault GuestMetricTableItemDescription">
              {tableText.next_status}
            </span>
          </div>
          <div className="GuestMetricTableItem">
            <span className="HeadlineThirdText GuestMetricTableItemValue">
              {`${data.visit_frequency} ${tableText.visit_frequency_unit}`}
            </span>
            <span className="TextDefault GuestMetricTableItemDescription">
              {tableText.visit_frequency}
            </span>
          </div>
          <div className="GuestMetricTableItem GuestMetricTableItemLast">
            <span className="HeadlineThirdText GuestMetricTableItemValue">
              {averageVisitTime}
            </span>
            <span className="TextDefault GuestMetricTableItemDescription">
              {tableText.average_game_duration}
            </span>
          </div>
        </div>
        <div className="GuestMetricTableRow">
          <div className="GuestMetricTableItem">
            <span className="HeadlineThirdText GuestMetricTableItemValue">
              {`${formatToCurrency({
                amount: Number(data.income),
                withoutSpace: true
              })} ${tableText.rub_unit}`}
            </span>
            <span className="TextDefault GuestMetricTableItemDescription">
              {tableText.income}
            </span>
          </div>
          <div className="GuestMetricTableItem">
            <span className="HeadlineThirdText GuestMetricTableItemValue">
              {`${data.win} ${tableText.win_unit}`}
            </span>
            <span className="TextDefault GuestMetricTableItemDescription">
              {tableText.win}
            </span>
          </div>
          <div className="GuestMetricTableItem">
            <span className="HeadlineThirdText GuestMetricTableItemValue">
              {`${formatToCurrency({
                amount: Number(data.average_check),
                withoutSpace: true,
                withoutZero: true
              })} ${tableText.rub_unit}`}
            </span>
            <span className="TextDefault GuestMetricTableItemDescription">
              {tableText.average_check}
            </span>
          </div>
          <div className="GuestMetricTableItem GuestMetricTableItemLast GuestMetricTableItemLastMobile">
            <span className="HeadlineThirdText GuestMetricTableItemValue">
              {formatToCurrency({
                amount: Number(data.total_bets),
                withoutSpace: true,
                withoutZero: true
              })}
            </span>
            <span className="TextDefault GuestMetricTableItemDescription">
              {tableText.total_bets}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GuestMetricTableComponent
