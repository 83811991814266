const deleteModalConst = {
  title: `Внимание!`,
  width: 512,
  getDesc: (hall: number) =>
    `Список рассылки по клубу № ${hall} будет безвозвратно удален.`,
  cancel: `Отмена`,
  accept: `Удалить`
}

export default deleteModalConst
