import React from "react"
import InputComponent from "Components/Input"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"
import { defaultSearchInputFilter } from "./helpers"
import { SearchInputFilterProps } from "./types"

export const SearchInputFilter: React.FC<SearchInputFilterProps> = ({
  isNumber,
  text,
  value,
  onChange
}) => {
  return (
    <div className="SideFilterField">
      <div className="SideFilterContentLabel">
        {text?.header || defaultSearchInputFilter.header}
      </div>
      <InputComponent
        value={value}
        onChange={({ target: { value: changedValue } }) => {
          if (!!isNumber) {
            const isNan = Number.isNaN(changedValue)
            if (
              changedValue === undefined ||
              (changedValue.length === 1 && isNan)
            ) {
              onChange(undefined)
            } else if (isNan) {
              onChange(changedValue.slice(0, -1))
            } else {
              onChange(changedValue)
            }
          } else onChange(changedValue)
        }}
        allowClear={true}
        placeholder={text?.placeholder || defaultSearchInputFilter.placeholder}
        suffix={!value ? <SearchIcon /> : <div />}
      />
    </div>
  )
}
