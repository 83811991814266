import React from "react"
import { SwitchFilterComponent } from "Components/Switch"
import { GuestBalanceFilterProps } from "../types"
import { guestBalanceConsts } from "../constants"

export const CustomZeroBalanceComponent = ({
  filter,
  setFilter
}: GuestBalanceFilterProps) => {
  return (
    <div>
      <SwitchFilterComponent
        label={guestBalanceConsts.zeroBalanceTitle}
        checked={filter.zeroBalance}
        onChange={() =>
          setFilter({ ...filter, zeroBalance: !filter.zeroBalance })
        }
      />
    </div>
  )
}

export default CustomZeroBalanceComponent
