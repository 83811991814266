import React, { useMemo } from "react"

import { ModalFormComponent } from "Components/Modal"
import ButtonDefault, { ButtonNotifi } from "Components/Button"

import { ReactComponent as DeleteIcon } from "../icons/delete_accept.svg"

import { MailingDeleteModalProps } from "./types"
import deleteModalConst from "./constant"

const MailingDeleteModal: React.FC<MailingDeleteModalProps> = ({
  hall,
  cancel,
  accept
}) => {
  const description = useMemo(() => deleteModalConst.getDesc(hall), [])
  return (
    <div>
      <ModalFormComponent
        width={deleteModalConst.width}
        withoutCross={true}
        close={cancel}
      >
        <div className="MailingDeleteContent">
          <div className="ModalFormTitle">{deleteModalConst.title}</div>
          <div className="MailingDeleteDescription">{description}</div>
          <div className="FlexRow MailingDeleteButtons">
            <ButtonDefault onClick={cancel}>
              {deleteModalConst.cancel}
            </ButtonDefault>
            <ButtonNotifi onClick={accept} color="red">
              <div className="FlexRow">
                <DeleteIcon />
                <span>{deleteModalConst.accept}</span>
              </div>
            </ButtonNotifi>
          </div>
        </div>
      </ModalFormComponent>
      <div className="Overlay" />
    </div>
  )
}

export default MailingDeleteModal
