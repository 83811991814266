import React, { useState } from "react"

import { Form } from "antd"

import { isMobile } from "constants/index"

import ButtonDefault, { ButtonPrimary } from "Components/Button"
import InputComponent from "Components/Input"
import { HallsMultiSelect } from "Components/Select"

import { rules } from "Components/Form/rules"
import { ReactComponent as BackIcon } from "Components/icons/arrow_back.svg"

import { UserCrudFields, UserCrudProps, UserFields } from "./types"
import { initialFields, userCrudText, userRule } from "./constants"
import { useRequestParams } from "api/hooks"
import betRequest from "api/request"

const UserCrud = ({ modalData, back, baseUrl }: UserCrudProps) => {
  const isEdit = !!modalData.editData
  const title = isEdit ? userCrudText.editTitle : userCrudText.addTitle

  const [form] = Form.useForm()
  const [pending, setPending] = useState(false)
  const { developer, addDeveloperLink, history, logout } = useRequestParams()
  const onFinish = (values: UserFields) => {
    setPending(true)
    betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      method: `POST`,
      url: `v1/requests/${baseUrl}/mailing/save`,
      requestBody: {
        ...values,
        user_id: modalData.editData?.id
      }
    })
      .then(() => {
        setPending(false)
        back()
      })
      .catch(() => setPending(false))
  }

  return (
    <div className="FlexColumn StatusNotifyUserCrudWrapper">
      <div className="FlexRow Gap24 PaddingTop32 PaddingLeft24 StatusNotifyUserCrudTitle">
        <ButtonDefault onClick={back} disabled={pending}>
          <BackIcon />
        </ButtonDefault>
        <span
          className={
            isMobile ? `HeadlineThirdText PaddingTop16` : `HeadlineFirstText`
          }
        >
          {title}
        </span>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={isEdit ? modalData.editData : initialFields}
        autoComplete="off"
      >
        <div className="Padding24 StatusNotifyUserCrudContent">
          <Form.Item<UserFields>
            name={UserCrudFields.name}
            label={
              <span className="SubtitleFirstText">
                {userCrudText[UserCrudFields.name].label}
              </span>
            }
            rules={[...rules.required, ...userRule]}
          >
            <InputComponent
              placeholder={userCrudText[UserCrudFields.name].placeholder}
            />
          </Form.Item>
          <Form.Item<UserFields>
            name={UserCrudFields.email}
            label={
              <span className="SubtitleFirstText">
                {userCrudText[UserCrudFields.email].label}
              </span>
            }
            rules={rules.email}
          >
            <InputComponent
              placeholder={userCrudText[UserCrudFields.email].placeholder}
            />
          </Form.Item>
          <Form.Item<UserFields>
            name={UserCrudFields.halls}
            label={
              <span className="SubtitleFirstText">
                {userCrudText[UserCrudFields.halls].label}
              </span>
            }
            rules={rules.required_halls}
          >
            <HallsMultiSelect
              placeholder={userCrudText[UserCrudFields.halls].placeholder}
            />
          </Form.Item>
        </div>
        <div className="FlexRow Gap8 StatusNotifyUserCrudButtons">
          <Form.Item>
            <ButtonPrimary disabled={pending} htmlType="submit">
              {isEdit ? userCrudText.finish_edit : userCrudText.finish_add}
            </ButtonPrimary>
          </Form.Item>
          <Form.Item>
            <ButtonDefault disabled={pending} onClick={back}>
              <span className="ButtonTextFirst">{userCrudText.cancel}</span>
            </ButtonDefault>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default UserCrud
