export const MAILING_HISTORY_FILTER = `MAILING_HISTORY_FILTER`

export const initialFilterData = {
  halls: [],
  dates: undefined
}

export const initialPagination = {
  limit: 30,
  offset: 0
}

export const initialHistoryState = {
  pending: false,
  data: [],
  total: 0
}

export const periodText = {
  label: `За период:`,
  time: `08:00`
}

export const historyUrl = `v1/hall/mailing/history`
export const emptyFilterText = {
  title: `Чтобы получить историю рассылок, вам необходимо выбрать нужные параметры фильтрации`,
  description: `История рассылок о пополнениях и выплатах содержит сведения по типам и суммам операций в рассылках за выбранный отчетный период`
}

export const columnKeys = {
  date: {
    header: `Период отчёта`,
    id: `date`,
    label: `Дата и время (мск)`,
    format: `DD.MM.YYYY`,
    time: `HH:mm:ss`,
    hyphen: `—`
  },
  hall: {
    id: `hall`,
    label: `Клуб №`
  },
  type: {
    id: `type`,
    label: `Тип операции`
  },
  sum: {
    id: `sum`,
    label: `Сумма, ₽`
  },
  phone: {
    id: `phone`,
    label: `Номер гостя`
  }
}
