import {
  DictElements,
  GetInitialFilterState,
  StatusesDict
} from "./Admin/types"
import { getFilterStateFromLocalStorage } from "api/helpers"
import { isEmpty } from "lodash"
import moment, { Moment } from "moment"
import plural from "plural-ru"
import { viewPenaltyModalText } from "./User/ViewPenaltyModal/helper"

export const statusesDict: StatusesDict = {
  new: "Новый",
  viewed: "Просмотрен",
  consideration: "На рассмотрении",
  reviewed: "Рассмотрен",
  "request-canceled": "Запрос отклонен",
  cancel: "Отменен"
}

export const winEmpty = {
  id: null,
  value: "Без повышения WIN"
}

export const statusReadableLabels = (dict: DictElements | []) =>
  dict?.map(el => ({ ...el, value: statusesDict[el.value] }))

export const getLabelById = (
  id: number | undefined,
  statuses: DictElements | []
) => statuses.find(el => el.id === id)?.value

export const initialFilterData = {
  halls: [],
  statuses: [],
  dates: undefined
}

export const getInitialFilterState = ({
  storage,
  isAdmin
}: GetInitialFilterState) => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    storage,
    true
  )
  const empty = isEmpty(initFilterStateFromStorage)
  const statuses = empty
    ? isAdmin
      ? [3]
      : []
    : initFilterStateFromStorage.statuses
  return {
    isOpen: true,
    isActive: !empty,
    data: {
      ...initialFilterData,
      ...initFilterStateFromStorage,
      statuses
    },
    mobileFilter: false,
    searchAsObj: true
  }
}

export const formatDayMonthYear = (date: string | null | Moment) =>
  date ? moment(date).format("DD.MM.YYYY") : "—"

export const countBusinessDaysDiff = (
  date: string | Moment,
  daysCount: number
) => {
  const todayNew = moment().startOf("day")
  const lastValidDate = moment(date)
    .startOf("day")
    .businessAdd(daysCount, "days")
  const businessDiffCount = lastValidDate.businessDiff(todayNew)
  const isBefore = lastValidDate.isBefore()
  const businessDiffText = `${
    viewPenaltyModalText.appealButtonCountBack
  } ${businessDiffCount} ${plural(
    businessDiffCount,
    viewPenaltyModalText.dayFirst,
    viewPenaltyModalText.daySecond,
    viewPenaltyModalText.dayThird
  )}`
  return { businessDiffText, isBefore }
}

export enum PenaltyStatuses {
  new = 1,
  viewed = 2,
  consideration = 3,
  reviewed = 4,
  request_canceled = 5,
  cancel = 6
}
