import React from "react"
import { MailingDataProps } from "./types"
import { getValueString, mailingHallContentConstants } from "./helper"
import { statusText } from "../helper"
import UsersRow from "./UsersRow"
import { ReactComponent as MailIcon } from "../icons/mail.svg"
import { ReactComponent as SmsIcon } from "../icons/sms.svg"
import { ReactComponent as AttentionIcon } from "../icons/attention.svg"

const ValueRow = ({
  value,
  active
}: {
  value: number | null
  active: boolean
}) => {
  const valueClass = active
    ? `MailingHallItemValue`
    : `MailingHallItemValue MailingHallItemValueInactiveColor`

  const attentionClass = active
    ? `MailingHallUserAttentionActiveColor`
    : `MailingHallUserAttentionInactiveColor`

  return (
    <div className={valueClass}>
      {!!value ? (
        <span>{getValueString(value)}</span>
      ) : (
        <div className="FlexRow">
          <span>{mailingHallContentConstants.empty}</span>
          <AttentionIcon className={attentionClass} />
        </div>
      )}
    </div>
  )
}

const MailingData: React.FC<MailingDataProps> = ({ item, openDetail }) => {
  const isActive = item.active

  const flagsClass = isActive
    ? `MailingHallItemFlagActiveColor`
    : `MailingHallItemFlagInactiveColor`

  return (
    <div className="MailingHallItem FlexRow" onClick={openDetail}>
      <div className="MailingHallItemLeft FlexColumn">
        <div className="MailingHallItemField MailingHallItemTitle">
          {mailingHallContentConstants.title(item.hall)}
        </div>
        <div className="MailingHallItemField">
          <div className="MailingHallItemDescription">
            {mailingHallContentConstants.replenishment}
          </div>
          <ValueRow value={item.replenishment.value} active={isActive} />
        </div>
        <div className="MailingHallItemField">
          <div className="MailingHallItemDescription">
            {mailingHallContentConstants.withdrawal}
          </div>
          <ValueRow value={item.withdrawal.value} active={isActive} />
        </div>
        <div className="MailingHallItemField">
          <div className="MailingHallItemDescription">
            {mailingHallContentConstants.win}
          </div>
          <ValueRow value={item.win.value} active={isActive} />
        </div>
        {item.active ? (
          <div className="MailingHallItemActive">{statusText.active}</div>
        ) : (
          <div className="MailingHallItemInactive">{statusText.inactive}</div>
        )}
      </div>
      <div className="MailingHallItemRight FlexColumn">
        <div className="MailingHallItemDescription MailingHallItemDate">
          {mailingHallContentConstants.dateDescription(item.date)}
        </div>
        <div>
          <UsersRow users={item.replenishment.users} active={isActive} />
          <UsersRow users={item.withdrawal.users} active={isActive} />
          <UsersRow users={item.win.users} active={isActive} />
        </div>
        <div className="MailingHallItemFlags FlexRow">
          <div>
            <MailIcon className={item.email ? flagsClass : ""} />
          </div>
          <div>
            <SmsIcon className={item.sms ? flagsClass : ""} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MailingData
