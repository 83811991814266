import React, { useState } from "react"

import { useRequest } from "api/hooks"

import { ReactComponent as PinIcon } from "./icons/pin.svg"
import { ReactComponent as SmallPinIcon } from "./icons/pin-small.svg"

import { MessageActiveTab, PinnedComponentProps } from "./types"

export const PinComponent = ({
  pin,
  id,
  isSmall,
  successAction
}: PinnedComponentProps) => {
  const [isPin, toggleIsPin] = useState(pin)
  const pinIcon = isSmall ? <SmallPinIcon /> : <PinIcon />
  const { request: pinRequest } = useRequest({
    url: `message/${id}`,
    method: `PUT`,
    requestBody: { pinned: !isPin }
  })
  return (
    <div
      className={`${isSmall ? `SmallPinIconWrapper` : `PinIconWrapper`} ${
        isPin ? `PinnedIconWrapper` : `UnPinnedIconWrapper`
      }`}
      onClick={e => {
        e.stopPropagation()
        toggleIsPin(!isPin)
        pinRequest()
          .then(() => {
            if (!!successAction) {
              successAction()
            }
          })
          .catch(() => {
            toggleIsPin(!isPin)
          })
      }}
    >
      {pinIcon}
    </div>
  )
}

export const initialMessageList = {
  data: { read: [], unread: [] },
  pending: false,
  total: 0
}

export const initialFilterState = {
  text: undefined,
  date: undefined,
  isActive: false
}

export const messageText = {
  route: `/messages`,
  idRoute: `/messages/`,
  perPage: 20,
  title: `Сообщения`,
  [MessageActiveTab.all]: `Все сообщения`,
  [MessageActiveTab.read]: `Прочитанные`,
  [MessageActiveTab.unread]: `Новые`,
  [MessageActiveTab.pinned]: `Закрепленные`
}
