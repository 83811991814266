import React from "react"
import { CreateStep3Props, macros } from "../../types"
import Address from "./Step3Components/Address"
import Date from "./Step3Components/Date"
import Time from "./Step3Components/Time"
import Sum from "./Step3Components/Sum"
import MonthNumber from "./Step3Components/MonthNumber"
import TimeFrom from "./Step3Components/TimeFrom"
import TimeTo from "./Step3Components/TimeTo"
import Bonus from "./Step3Components/Bonus"
import Money from "./Step3Components/Money"
import IncrementedMacro from "./Step3Components/IncrementedMacro"
import { smsConstants } from "../../constants"

const Step3Fields = ({
  smsState,
  setSmsState,
  macrosFindedArr
}: CreateStep3Props) => {
  let fieldsArr: JSX.Element[] = []

  macrosFindedArr.forEach((macroName: string, index: number) => {
    const key = `${macroName}${index}`
    if (macroName.indexOf(macros.command) !== -1) {
      fieldsArr = [
        ...fieldsArr,
        <IncrementedMacro
          macroName={macroName}
          smsState={smsState}
          setSmsState={setSmsState}
          placeHolder={smsConstants.step3.commandPlaceholder}
          key={key}
        />
      ]
    } else if (macroName.indexOf(macros.sum) !== -1) {
      fieldsArr = [
        ...fieldsArr,
        <IncrementedMacro
          macroName={macroName}
          smsState={smsState}
          setSmsState={setSmsState}
          placeHolder={smsConstants.step3.sumTitle}
          key={key}
        />
      ]
    } else {
      switch (macroName) {
        case macros.address:
          fieldsArr = [
            ...fieldsArr,
            <Address
              error={smsState.errors?.macros?.address}
              smsState={smsState}
              setSmsState={setSmsState}
              key={key}
            />
          ]
          break
        case macros.date:
          fieldsArr = [
            ...fieldsArr,
            <Date
              error={smsState.errors?.macros?.date}
              smsState={smsState}
              setSmsState={setSmsState}
              key={key}
            />
          ]
          break
        case macros.time:
          fieldsArr = [
            ...fieldsArr,
            <Time
              error={smsState.errors?.macros?.time}
              smsState={smsState}
              setSmsState={setSmsState}
              key={key}
            />
          ]
          break
        case macros.sum:
          fieldsArr = [
            ...fieldsArr,
            <Sum
              error={smsState.errors?.macros?.sum}
              smsState={smsState}
              setSmsState={setSmsState}
              key={key}
            />
          ]
          break
        case macros.number:
          fieldsArr = [
            ...fieldsArr,
            <MonthNumber
              error={smsState.errors?.macros?.number}
              smsState={smsState}
              setSmsState={setSmsState}
              key={key}
            />
          ]
          break
        case macros.timeFrom:
          fieldsArr = [
            ...fieldsArr,
            <TimeFrom
              error={smsState.errors?.macros?.timeFrom}
              smsState={smsState}
              setSmsState={setSmsState}
              key={key}
            />
          ]
          break
        case macros.timeTo:
          fieldsArr = [
            ...fieldsArr,
            <TimeTo
              error={smsState.errors?.macros?.timeTo}
              smsState={smsState}
              setSmsState={setSmsState}
              key={key}
            />
          ]
          break
        case macros.bonus:
          fieldsArr = [
            ...fieldsArr,
            <Bonus smsState={smsState} setSmsState={setSmsState} key={key} />
          ]
          break
        case macros.money:
          fieldsArr = [
            ...fieldsArr,
            <Money smsState={smsState} setSmsState={setSmsState} key={key} />
          ]
          break
      }
    }
  })

  return (
    <section className="CreateSms_inputWrapper">
      <div className="CreateSms_inputContainer">{fieldsArr}</div>
    </section>
  )
}

export default Step3Fields
