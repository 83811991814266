import React, { useEffect, useRef } from "react"
import { useOnScreen } from "api/hooks"

import { ViewTriggerProps } from "./types"

const ViewTrigger = ({ needTrigger, trigger }: ViewTriggerProps) => {
  const ref = useRef(null)

  const triggerScroll = useOnScreen(ref)

  useEffect(() => {
    if (triggerScroll && needTrigger) {
      trigger()
    }
  }, [needTrigger, triggerScroll])

  return (
    <div
      className={`ScrollTrigger ${!needTrigger && "ScrollTriggerHide"}`}
      ref={ref}
    />
  )
}

export default ViewTrigger
