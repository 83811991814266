import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { Collapse } from "antd"

import NotificationCount from "Components/NotificationCount"
import CounterComponent from "Components/Counter"

import { ReactComponent as HomeIcon } from "Components/icons/home.svg"
import { ReactComponent as MailIcon } from "Components/icons/mail_menu.svg"
import { ReactComponent as ReportIcon } from "Components/icons/report_menu.svg"
import { ReactComponent as ContactsIcon } from "Components/icons/contacts_menu.svg"
import { ReactComponent as DropdownMenuIcon } from "Components/icons/dropdown_arrow.svg"

import { getMenuBottom, getMenuMain, getMenuOptions } from "store/menu/selector"
import { getCurrentHall } from "store/halls/selector"
import { MenuOptionsType } from "store/menu/types"
import { WIKI } from "../constants"
import { getWikiOptions } from "store/wiki/selector"
import { buildUrlForExternalMenuItem } from "../helpers"
const { Panel } = Collapse

const getIconByName = (name: string) => {
  switch (name) {
    case `Главная`:
      return <HomeIcon />
    case `Сообщения`:
      return <MailIcon />
    case `Штрафы`:
      return <ReportIcon />
    case `Контакты`:
      return <ContactsIcon />
  }
}

const MainMenu = ({
  closeMenu,
  bottom = false
}: {
  closeMenu: () => void
  bottom?: boolean
}) => {
  const { pathname } = useLocation()
  const mainMenu = bottom
    ? useSelector(getMenuBottom)
    : useSelector(getMenuMain)
  return (
    <div>
      {mainMenu.map(item => {
        const icon = getIconByName(item.name)
        const isMessage =
          item.route === `/messages` &&
          item.name === `Сообщения` &&
          !!item.count
        const dataInside = (
          <div
            key={`main_menu_${item.name}`}
            className={`FlexRow ${
              item.route && pathname === item.route
                ? `BaseLayoutLeftMenuItemActive`
                : `BaseLayoutLeftMenuItem`
            } `}
            onClick={() => {
              if (!!item.route) closeMenu()
            }}
          >
            <div className="BaseLayoutLeftMenuItemActiveMarker" />
            {icon}
            <span>{item.name}</span>
            {!!item.count &&
              (isMessage ? (
                <NotificationCount count={item.count} />
              ) : (
                <CounterComponent count={item.count} />
              ))}
          </div>
        )
        return !!item.route ? (
          <Link
            key={`Link_${item.route}`}
            className="BaseLayoutLeftMenuLink"
            to={item.route}
          >
            {dataInside}
          </Link>
        ) : (
          <a
            key={`Link_${item.url}`}
            className="BaseLayoutLeftMenuLink"
            href={item.url || ``}
          >
            {dataInside}
          </a>
        )
      })}
    </div>
  )
}

type OptionsMenuData = {
  name: string
  route: string
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  inside: OptionsMenuData[] | null
}

const LeftMenu = ({ closeMenu }: { closeMenu: () => void }) => {
  const { pathname } = useLocation()
  const optionsMenu = useSelector(getMenuOptions)
  const optionsWikiMenu = useSelector(getWikiOptions)
  const current_hall = useSelector(getCurrentHall)
  const isWiki = pathname.includes(WIKI)
  const userFromStorage: string = localStorage.getItem(`user`) || ``
  const user: { token: string } | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage

  const [options, setOptions] = useState<JSX.Element[]>([])

  useEffect(() => {
    const mapOptionsData = (
      menuArg: MenuOptionsType[],
      insideCount?: number
    ): any => {
      const iterationArray = []
      const marginCount = insideCount || 0
      // start
      for (let i = 0; i < menuArg.length; i++) {
        if (!!menuArg[i].inside) {
          iterationArray.push(
            <div className="BaseLayoutMenuAccordion">
              <Collapse
                ghost
                expandIconPosition="right"
                accordion
                defaultActiveKey={[pathname]}
                expandIcon={panelProps => (
                  <div style={{ padding: `6px 0` }}>
                    <DropdownMenuIcon
                      className={`AccordionItemArrow 
                      ${
                        panelProps.isActive
                          ? `AccordionItemUp`
                          : `AccordionItemDown`
                      }
                    `}
                    />
                  </div>
                )}
              >
                <Panel
                  className="BaseLayoutMenuAccordionHeader"
                  header={menuArg[i].name}
                  key={`left_menu_accord_name_${menuArg[i].name}`}
                >
                  {// eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  mapOptionsData(menuArg[i].inside, marginCount + 1)}
                </Panel>
              </Collapse>
            </div>
          )
        } else {
          const dataInside = (
            <div
              className={`FlexRow ${
                menuArg[i].route && pathname === menuArg[i].route
                  ? `BaseLayoutLeftMenuItemActive`
                  : `BaseLayoutLeftMenuItem`
              } `}
              onClick={() => {
                if (!!menuArg[i].route) {
                  closeMenu()
                }
              }}
            >
              <div
                className="BaseLayoutLeftMenuItemActiveMarker"
                style={{
                  marginLeft: `-${marginCount * 15}px`,
                  marginRight: `${marginCount * 15}px`
                }}
              />
              <span className="BaseLayoutLeftMenuItemWithoutIcons">
                {menuArg[i].name}
              </span>
            </div>
          )
          iterationArray.push(
            !!menuArg[i].route ? (
              menuArg[i].route === WIKI ? (
                <a
                  className="BaseLayoutLeftMenuLink"
                  href={menuArg[i].route || ``}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {dataInside}
                </a>
              ) : (
                <Link
                  className="BaseLayoutLeftMenuLink"
                  to={menuArg[i].route || ``}
                >
                  {dataInside}
                </Link>
              )
            ) : (
              <a
                className="BaseLayoutLeftMenuLink"
                href={buildUrlForExternalMenuItem(
                  menuArg[i] || null,
                  user?.token
                )}
              >
                {dataInside}
              </a>
            )
          )
        }
      }
      return iterationArray
    }
    setOptions(mapOptionsData(isWiki ? optionsWikiMenu : optionsMenu))
  }, [current_hall, pathname])

  return (
    <div>
      {!isWiki && <MainMenu closeMenu={closeMenu} />}
      {!isWiki && <div className="BaseLayoutLeftMenuRectangle" />}
      <div
        className={`BaseLayoutLeftMenuContent ${
          isWiki ? "BaseLayoutLeftMenuContent_Wiki" : ""
        }`}
      >
        {options.map((item, key) => (
          <div key={`options_item_${key}`}>{item}</div>
        ))}
      </div>
      {!isWiki && <div className="BaseLayoutLeftMenuRectangle" />}
      {!isWiki && <MainMenu closeMenu={closeMenu} bottom />}
    </div>
  )
}

export default LeftMenu
