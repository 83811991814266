import React, { useEffect, useMemo, useState } from "react"
import { useRequest } from "api/hooks"
import { getFilterStateFromLocalStorage } from "api/helpers"
import { isEmpty } from "lodash"
import {
  HistoryListItem,
  Race,
  RaceDateTimeCellProps,
  RaceFilter,
  RaceFilterDates,
  RaceGameCellProps,
  RaceListItem,
  RaceModalState,
  RaceMoneyCellProps,
  RaceWinnersTableCellProps,
  UseRaceRequests,
  Winner
} from "./types"
import {
  currencyDict,
  RaceHistoryTableColNames,
  RaceListTableColNames,
  RaceModalTableColNames,
  RaceStatuses,
  raceStatusesDict,
  raceText,
  tabsIdsModal,
  WinnerBaseKeys
} from "./constants"
import { SortOrders } from "Components/Table/types"
import DateTime from "Components/DateTime"
import { GamesData } from "store/dictionary/types"
import { Image } from "antd"
import { ReactComponent as CupIcon } from "Components/icons/cup_outline_24.svg"
import { ReactComponent as BBIcon } from "Components/icons/bb-logo-3d.svg"
import { formatPhone, isLaptop, isMobile, moneyRender } from "constants/index"
import moment, { Moment } from "moment"
import TooltipComponent from "Components/Tooltip"

export const useRaceRequests = ({
  hall,
  sort,
  pagination,
  dates,
  phone
}: UseRaceRequests) => {
  const makeRaceSort = () => {
    if (isEmpty(sort.field) || isEmpty(sort.order)) {
      return { field: RaceListTableColNames.period, order: SortOrders.desc }
    }
    if (sort.field === RaceListTableColNames.prize_pool_sum) {
      return { ...sort, field: RaceListTableColNames.prize_pool }
    }
    return sort
  }
  const makeRaceFilterDates = () => {
    if (isEmpty(dates?.from) || isEmpty(dates?.to)) {
      return undefined
    }
    return dates
  }

  const { request: getRacesList } = useRequest({
    url: `v1/promotions/race`,
    method: `POST`,
    requestBody: {
      filter: {
        hall,
        dates: makeRaceFilterDates()
      },
      sort: makeRaceSort(),
      pagination
    }
  })

  const { request: getRacesGuestsList } = useRequest({
    url: `v1/promotions/race/guest`,
    method: `POST`,
    requestBody: {
      filter: { hall, phone },
      sort: makeRaceSort(),
      pagination
    },
    skip404Handler: true
  })

  return { getRacesList, getRacesGuestsList }
}

export const makeInitialFilter = (
  hall: number,
  filterName: string
): RaceFilter => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    filterName,
    true
  )

  const initFilter = {
    hall,
    ...initFilterStateFromStorage
  }

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      ...initFilter
    }
  }
}

const RaceDateTimeCell: React.FC<RaceDateTimeCellProps> = ({ dates }) => {
  return (
    <div className="RaceDateTimeCell">
      <DateTime date={dates?.from} independentDate={true} />
      <DateTime date={dates?.to} independentDate={true} />
    </div>
  )
}

const RaceGameCell: React.FC<RaceGameCellProps> = ({
  games,
  dict,
  isExpanded
}) => {
  const [showAllState, setShowAllState] = useState(isExpanded)

  useEffect(() => {
    setShowAllState(isExpanded)
  }, [isExpanded])

  let minLength = 4
  if (isLaptop) {
    minLength = 4
  }
  if (isMobile) {
    minLength = 2
  }

  const {
    validGamesDict,
    validGamesList,
    minGamesListArr,
    moreGamesListArr,
    hasHiddenGames
  } = useMemo(() => {
    const validGamesDict = dict.filter(game =>
      games?.includes(game.id as number)
    )
    const validGamesList = games?.filter(game =>
      validGamesDict.some(g => g.id === game)
    )
    const minGamesListArr = validGamesList?.slice(0, minLength)
    const moreGamesListArr = validGamesList?.slice(minLength)
    const hasHiddenGames = moreGamesListArr && moreGamesListArr.length > 0

    return {
      validGamesDict,
      validGamesList,
      minGamesListArr,
      moreGamesListArr,
      hasHiddenGames
    }
  }, [games, dict])

  const gamesList = (gamesArr: number[] = [], gamesDict: GamesData[] = []) => {
    return gamesArr.map(gameKind => {
      const currentGame = gamesDict.find(game => game.id === gameKind)
      return (
        currentGame && (
          <div className="RaceGamesItem" key={gameKind}>
            <Image
              src={currentGame?.image}
              width={24}
              height={24}
              preview={false}
            />
            <div className="RaceGameCell_Container_Element_Text">
              {currentGame?.name}
            </div>
          </div>
        )
      )
    })
  }

  const toggleShowMoreState = () => {
    setShowAllState(!showAllState)
  }

  return (
    <>
      <div className="RaceGamesList">
        {showAllState
          ? gamesList(validGamesList, validGamesDict)
          : gamesList(minGamesListArr, validGamesDict)}
        {hasHiddenGames && (
          <div className="RaceMore" onClick={toggleShowMoreState}>
            {showAllState ? (
              <span>{raceText.notShowMore}</span>
            ) : (
              <span>
                {raceText.showMore} {moreGamesListArr?.length}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export const RaceMoneyCell: React.FC<RaceMoneyCellProps> = ({
  money,
  type,
  skipBold
}) => {
  const moneyAsNumber = Number(money)
  if (money && type && moneyAsNumber >= 0) {
    return (
      <div className={skipBold ? "" : "TextDefaultBold"}>{`${moneyRender(
        moneyAsNumber
      )} ${currencyDict[type]}`}</div>
    )
  }
  return <>-</>
}

export const RaceWinnersTableCell: React.FC<RaceWinnersTableCellProps> = ({
  value,
  record,
  onWinnerClick,
  className,
  isFederal
}) => {
  return value?.winners?.length ? (
    <div className={`RaceCellInner ${className || ""}`}>
      <div
        className="RaceWinnersButton"
        onClick={() => {
          onWinnerClick(
            {
              isOpen: true,
              data: {
                id: record.id,
                federal: record.federal,
                local: record.local,
                period: record.period,
                status: record.status
              }
            },
            isFederal ? tabsIdsModal.raceTab : tabsIdsModal.localRaceTab
          )
        }}
      >
        <CupIcon />
        <span>{`${value?.winners.length} ${raceText.ppl}`}</span>
      </div>
    </div>
  ) : (
    <div className="RaceWinnersEmpty RaceCellInner">{raceText.noWinners}</div>
  )
}

const makeDefaultRaceTableHistoryColChildren = (key: WinnerBaseKeys) => [
  {
    title: raceText.columnsGuestInfo.place,
    dataIndex: RaceHistoryTableColNames.place,
    key: RaceHistoryTableColNames.place,
    render: (place: number, record: HistoryListItem) =>
      record[key]?.place ? <div>{record[key].place}</div> : <div>-</div>
  },
  {
    title: raceText.columnsGuestInfo.winAmount,
    dataIndex: RaceHistoryTableColNames.win_sum,
    key: RaceHistoryTableColNames.win_sum,
    render: (place: number, record: HistoryListItem) => (
      <RaceMoneyCell
        money={record[key]?.win_sum}
        type={record[key]?.prize_pool_type}
        skipBold
      />
    )
  }
]

export const makeRacesListTableColumns = (
  gamesDict: GamesData[],
  onWinnerClick: (val: RaceModalState, modalTab: number) => void,
  expandedIds: number[]
) => {
  return [
    {
      title: raceText.columns.number,
      dataIndex: RaceListTableColNames.id,
      key: RaceListTableColNames.id,
      width: 100,
      minWidth: 100,
      render: (id: number, record: RaceListItem) => (
        <div className="RaceCellId">
          <div className="TextDefaultBold">{id}</div>
          {record[WinnerBaseKeys.federal] && record[WinnerBaseKeys.local] && (
            <TooltipComponent title={raceText.raceCellTooltip}>
              <BBIcon />
            </TooltipComponent>
          )}
        </div>
      )
    },
    {
      title: raceText.columns.status,
      dataIndex: RaceListTableColNames.status,
      key: RaceListTableColNames.status,
      width: 136,
      minWidth: 136,
      render: (status: RaceStatuses) => (
        <div className={`RaceStatus ${raceStatusesDict[status]?.className}`}>
          {raceStatusesDict[status]?.text}
        </div>
      )
    },
    {
      title: raceText.columns.period,
      dataIndex: RaceListTableColNames.period,
      key: RaceListTableColNames.period,
      sorter: true,
      width: 176,
      minWidth: 176,
      render: (dates: RaceFilterDates) => <RaceDateTimeCell dates={dates} />
    },
    {
      title: raceText.columns.prizeFund,
      dataIndex: RaceListTableColNames.prize_pool_sum,
      key: RaceListTableColNames.prize_pool_sum,
      sorter: true,
      width: 136,
      minWidth: 136,
      render: (value: string, record: RaceListItem) => (
        <RaceMoneyCell money={value} type={record.prize_pool_type} />
      )
    },
    {
      title: raceText.columns.games,
      dataIndex: RaceListTableColNames.games,
      key: RaceListTableColNames.games,
      width: 580,
      minWidth: 580,
      maxWidth: 580,
      render: (games: number[], record: RaceListItem) => {
        const isExpanded = expandedIds.includes(record.id)
        return (
          games && (
            <RaceGameCell
              games={games}
              dict={gamesDict}
              isExpanded={isExpanded}
            />
          )
        )
      }
    },
    {
      title: raceText.columns.winners,
      dataIndex: RaceListTableColNames.local,
      key: RaceListTableColNames.local,
      width: 176,
      minWidth: 176,
      render: (value: Race, record: RaceListItem) => {
        const raceLocalDataProps: RaceWinnersTableCellProps = {
          record,
          onWinnerClick,
          value,
          isFederal: !!record.local && !!record.federal
        }

        if (record.local && record.federal) {
          raceLocalDataProps.value = record.federal
        }

        return <RaceWinnersTableCell {...raceLocalDataProps} />
      }
    }
  ]
}

export const makeRacesHistoryTableColumns = () => {
  return [
    {
      title: raceText.columns.number,
      dataIndex: RaceHistoryTableColNames.id,
      key: RaceHistoryTableColNames.id,
      render: (value: string) => <div className="TextDefaultBold">{value}</div>
    },
    {
      title: raceText.columns.period,
      dataIndex: RaceHistoryTableColNames.period,
      key: RaceHistoryTableColNames.period,
      sorter: true,
      render: (dates: RaceFilterDates) => <RaceDateTimeCell dates={dates} />
    },
    {
      title: () => (
        <div className="RaceTableColumnMainTitle">
          {raceText.columnsGuestInfo.win}
        </div>
      ),
      dataIndex: RaceHistoryTableColNames.federal,
      key: RaceHistoryTableColNames.federal,
      children: makeDefaultRaceTableHistoryColChildren(WinnerBaseKeys.federal)
    },
    {
      title: () => (
        <div className="RaceTableColumnMainTitle">
          {raceText.columnsGuestInfo.winLocal}
        </div>
      ),
      dataIndex: RaceHistoryTableColNames.local,
      key: RaceHistoryTableColNames.local,
      children: makeDefaultRaceTableHistoryColChildren(WinnerBaseKeys.local)
    }
  ]
}

export const makeRaceModalTableColumns = () => {
  return [
    {
      title: raceText.columnsModal.place,
      dataIndex: RaceModalTableColNames.place,
      key: RaceModalTableColNames.place,
      minWidth: 80,
      width: 80
    },
    {
      title: raceText.columnsModal.prize,
      dataIndex: RaceModalTableColNames.prize_pool_sum,
      key: RaceModalTableColNames.prize_pool_sum,
      minWidth: 110,
      width: 110,
      render: (value: string, record: Winner) => (
        <RaceMoneyCell money={value} type={record.prize_pool_type} />
      )
    },
    {
      title: raceText.columnsModal.phone,
      dataIndex: RaceModalTableColNames.phone,
      key: RaceModalTableColNames.phone,
      minWidth: 180,
      width: 180,
      render: (value: string) => <div>{formatPhone(value)}</div>
    },
    {
      title: raceText.columnsModal.fullName,
      dataIndex: RaceModalTableColNames.name,
      key: RaceModalTableColNames.name
    }
  ]
}

export const disableDates = (current: Moment | null): boolean => {
  const startDate = moment("2020-01-01")
  const today = moment()
  return current ? !current.isBetween(startDate, today, "day", "[]") : false
}
