import React, { useEffect, useState } from "react"
import {
  ContactsBlockModalProps,
  contactsPageProps,
  ContactsStateDataBlocksStaffSingleProps
} from "Containers/Contacts/types"
import InputComponent, { InputTextAreaComponent } from "Components/Input"
import { SelectComponent } from "Components/Select"
import TableComponent from "Components/Table"
import ButtonDefault, { ButtonIcon, ButtonPrimary } from "Components/Button"
import { ReactComponent as DeleteIcon } from "Components/icons/delete_red.svg"
import { ReactComponent as AddIcon } from "Components/icons/user_add.svg"
import { ReactComponent as CloseIcon } from "Components/icons/close_icon.svg"
import {
  blockRequest,
  createInitBlockState,
  renderPhone,
  staffRequest
} from "Containers/Contacts/helpers"
import StaffEditMenu from "./StaffEditMenu"
import {
  addBlockMsg,
  addMsg,
  addStaffMsg,
  blockDeletePromptMsg,
  blockDescription,
  blockDescriptionPlaceholder,
  blockName,
  blockNamePlaceholder,
  blockStateStorageName,
  cancelMsg,
  deleteBlockMsg,
  editBlockMsg,
  saveMsg,
  sectionOwnerTitle,
  staffListTitle
} from "../../constants"
import { useRequestParams } from "api/hooks"
import { formatPhone } from "constants/index"

const ContactsBlockModal = ({
  contactsState,
  setContactsState,
  showConfirm
}: contactsPageProps) => {
  const { history, logout } = useRequestParams()

  /* Редактируем или создаем новый блок */
  const isBlockEdit = !!contactsState.editBlockId

  const [blockState, setBlockState] = useState<ContactsBlockModalProps>(
    createInitBlockState(contactsState)
  )

  const denyGetBlockInfo =
    !contactsState.blockPending ||
    !contactsState.isBlockEditing ||
    !contactsState.activeModal

  useEffect(() => {
    if (denyGetBlockInfo) return

    blockRequest({
      contactsState,
      setContactsState,
      blockState,
      setBlockState,
      logout,
      history
    })
  }, [
    contactsState.blockPending,
    contactsState.isSectionEditing,
    contactsState.activeModal
  ])

  /* Список для селекта названий разделов. */
  const sectionsTitles = contactsState.data.map(section => ({
    value: section.section_id,
    label: section.title
  }))

  const columns = [
    {
      title: "Имя сотрудника",
      width: 176,
      dataIndex: "name",
      key: "name",
      render: (value: string) => (
        <div className="ContactsTableInner">{value}</div>
      )
    },
    {
      title: "Должность сотрудника",
      width: 176,
      dataIndex: "position",
      key: "position",
      render: (value: string) => (
        <div className="ContactsTableInner">{value}</div>
      )
    },
    {
      title: "Телефон № 1",
      width: 176,
      dataIndex: "phone",
      key: "phone",
      render: (
        _: string | null,
        record: ContactsStateDataBlocksStaffSingleProps
      ) => (
        <div className="ContactsTableInner">{renderPhone("phone", record)}</div>
      )
    },
    {
      title: "Телефон № 2",
      width: 176,
      dataIndex: "phone2",
      key: "phone2",
      render: (
        _: string | null,
        record: ContactsStateDataBlocksStaffSingleProps
      ) => (
        <div className="ContactsTableInner">
          {renderPhone("phone2", record)}
        </div>
      )
    },
    {
      title: "E-mail",
      width: 176,
      dataIndex: "email",
      key: "email",
      render: (value: string) => (
        <div className="ContactsTableInner">{value}</div>
      )
    },
    {
      title: "WhatsApp",
      width: 176,
      dataIndex: "whatsapp",
      key: "whatsapp",
      render: (
        _: string | null,
        record: ContactsStateDataBlocksStaffSingleProps
      ) => (
        <div className="ContactsTableInner">{formatPhone(record.whatsapp)}</div>
      )
    },
    {
      title: "Telegram",
      width: 176,
      dataIndex: "telegram",
      key: "telegram",
      render: (value: string) => (
        <div className="ContactsTableInner">{value}</div>
      )
    },
    {
      title: "Режим работы",
      width: 136,
      dataIndex: "office_hours",
      key: "office_hours",
      render: (value: string | undefined) => {
        const timeArr = !!value ? value.split(",") : ""
        return (
          <div className="ContactsTableInner ContactsTableInner_narrow">
            {timeArr.length === 2 ? `${timeArr[0]} - ${timeArr[1]}` : ``}
          </div>
        )
      }
    },
    {
      title: "Действия",
      width: 104,
      key: "actions",
      render: (_: any, record: ContactsStateDataBlocksStaffSingleProps) => {
        return (
          <StaffEditMenu
            contactsState={contactsState}
            setContactsState={setContactsState}
            record={record}
            showConfirm={showConfirm}
          />
        )
      }
    }
  ]

  useEffect(() => {
    if (contactsState.method === "DELETE" && contactsState.staffPending) {
      staffRequest({
        contactsState,
        setContactsState,
        logout,
        history
      })
    }
  }, [contactsState.staffPending, contactsState.method])

  return (
    <div className="ContactsEdit ContactsEdit_Block">
      <div className="ContactsEdit_top">
        <div className="ContactsEdit_header">
          <div className="ContactsEdit_MainTitle">
            {isBlockEdit ? editBlockMsg : addBlockMsg}
          </div>
          <div className="ContactsEdit_closeIcon">
            <CloseIcon
              onClick={() => {
                localStorage.removeItem(blockStateStorageName)

                !contactsState.blockPending &&
                  setContactsState({
                    ...contactsState,
                    editBlockId: null,
                    activeModal: false,
                    blockErrors: []
                  })
              }}
            />
          </div>
        </div>
        <div className="ContactsEdit_inputsColumnWrapper">
          <div className="ContactsEdit_inputsColumn">
            <div className="ContactsEdit_title">
              <div className="ContactsEdit_fieldname">{blockName}</div>
              <InputComponent
                greyBackground
                className="ContactsEdit_titleInput"
                placeholder={blockNamePlaceholder}
                value={blockState.title}
                onChange={e =>
                  setBlockState({
                    ...blockState,
                    title: e.target.value
                  })
                }
              />
            </div>
            <div className="ContactsEdit_sectionOwner">
              <div className="ContactsEdit_fieldname">{sectionOwnerTitle}</div>
              <SelectComponent
                className="ContactsEdit_SectionSelect"
                greyBackground
                allowClear={false}
                value={blockState.sectionId}
                options={sectionsTitles}
                onChange={value => {
                  setBlockState({
                    ...blockState,
                    sectionTitle: value,
                    sectionId: value
                  })
                }}
              />
            </div>
          </div>
          <div className="ContactsEdit_inputsColumn">
            <div className="ContactsEdit_sectionDescription">
              <div className="ContactsEdit_fieldname">{blockDescription}</div>
              <InputTextAreaComponent
                greyBackground
                placeholder={blockDescriptionPlaceholder}
                value={blockState.description}
                onChange={e =>
                  setBlockState({
                    ...blockState,
                    description: e.target.value
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="ContactsEdit_table">
          <div className="ContactsEdit_fieldname">{staffListTitle}</div>
          <TableComponent
            rowClassName="ContactsEdit_tableRow"
            columns={columns}
            data={blockState.staff}
          />
        </div>
        <div className="ContactsEdit_error">
          {contactsState.blockErrors.join("<br />")}
        </div>
      </div>
      <div className="ContactsEdit_btns">
        <ButtonPrimary
          className="ContactsEdit_saveBtn ContactsBtn"
          disabled={contactsState.blockPending}
          onClick={() =>
            setContactsState({
              ...contactsState,
              blockPending: true,
              url: isBlockEdit
                ? `v1/admin/contacts/block/${contactsState.editBlockId}`
                : `v1/admin/contacts/block`,
              method: isBlockEdit ? `PUT` : `POST`
            })
          }
        >
          {isBlockEdit ? saveMsg : addMsg}
        </ButtonPrimary>

        {/* Добавить контакт можно только при редактировании блока - не при создании */}
        <ButtonIcon
          icon={<AddIcon />}
          className="ContactsEdit_AddBtn ContactsBtn"
          disabled={contactsState.blockPending || !contactsState.editBlockId}
          onClick={() => {
            localStorage.setItem(
              blockStateStorageName,
              JSON.stringify(blockState)
            )
            setContactsState({
              ...contactsState,
              isBlockEditing: false,
              isStaffEditing: true
            })
          }}
        >
          {addStaffMsg}
        </ButtonIcon>

        {isBlockEdit ? (
          <ButtonIcon
            icon={<DeleteIcon />}
            className="ContactsEdit_DeleteBtn ContactsBtn"
            disabled={contactsState.blockPending}
            onClick={() => {
              showConfirm({
                msg: blockDeletePromptMsg(blockState.title),
                newState: {
                  ...contactsState,
                  blockPending: true,
                  url: `v1/admin/contacts/block/${contactsState.editBlockId}`,
                  method: `DELETE`
                }
              })
            }}
          >
            {deleteBlockMsg}
          </ButtonIcon>
        ) : (
          <ButtonDefault
            className="ContactsEdit_CancelBtn ContactsBtn"
            disabled={contactsState.blockPending}
            onClick={() => {
              setContactsState({
                ...contactsState,
                activeModal: false,
                editBlockId: null,
                editStaffId: null,
                isStaffEditing: false,
                blockErrors: []
              })
            }}
          >
            {cancelMsg}
          </ButtonDefault>
        )}
      </div>
    </div>
  )
}

export default ContactsBlockModal
