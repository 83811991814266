import React, { useEffect, useState } from "react"
import Title from "Components/Title"
import { cardConst, HallCardDto, hallCardTextConst } from "./constants"
import { ReactComponent as PlaceIcon } from "Components/icons/place_outline_20.svg"
import { ReactComponent as RegistrationIcon } from "Components/icons/notebook_check_outline_20.svg"
import { ReactComponent as LicenseIcon } from "Components/icons/billhead_outline_24.svg"
import { ReactComponent as ManagersIcon } from "Components/icons/users_3_outline_20.svg"
import "./styles.less"
import Card from "./components/Card"
import { isMobile } from "constants/index"
import { useRequest } from "api/hooks"
import { useSelector } from "react-redux"
import { getCurrentHall } from "store/halls/selector"
import { HallCards } from "./types"
import PrintButton from "./components/PrintButton"
import BaseFooter from "Components/Footer"
import moment from "moment"

const HallCard = () => {
  const [cardInfo, setCardInfo] = useState<HallCards>(HallCardDto)
  const hallState = useSelector(getCurrentHall)
  const { request: getCardsInfo } = useRequest({
    url: "v1/hall/card",
    method: "POST",
    requestBody: {
      hall: Number(hallState[0])
    }
  })

  // Effects
  useEffect(() => {
    getCardsInfo().then(({ data }) => setCardInfo(data))
  }, [])

  return (
    <div>
      <div className="MainPageContentWrapper">
        <div className="MainPageContentRestrictions">
          <div className={isMobile ? "FlexColumn" : "FlexRow"}>
            <Title
              titleText={hallCardTextConst.header}
              tooltipText={hallCardTextConst.tooltipText}
            />
            <div className={isMobile ? "HallCardPrinterBtnWrapper" : ""}>
              <PrintButton hallId={Number(hallState[0])} />
            </div>
          </div>
          <div className="HallCardWrapper FlexRow FlexWrap">
            <Card headerIcon={<PlaceIcon />} header={hallCardTextConst.main}>
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.general.hall_id}
                </span>
                <span className="HallCardRow__Subtitle">
                  {cardInfo.general.hall || "-"}
                </span>
              </div>
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.general.op_code}
                </span>
                <span className="HallCardRow__Subtitle">
                  {cardInfo.general.op_code || "-"}
                </span>
              </div>
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.general.address}
                </span>
                <span className="HallCardRow__Subtitle">
                  {cardInfo.general.address || "-"}
                </span>
              </div>
            </Card>
            <Card
              headerIcon={<RegistrationIcon />}
              header={hallCardTextConst.registration}
            >
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.registration.date_op}
                </span>
                <span className="HallCardRow__Subtitle">
                  {(cardInfo.registration.date_op &&
                    moment(cardInfo.registration.date_op).format(
                      "DD.MM.YYYY"
                    )) ||
                    "-"}
                </span>
              </div>
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.registration.date_pps}
                </span>
                <span className="HallCardRow__Subtitle">
                  {(cardInfo.registration.date_pps &&
                    moment(cardInfo.registration.date_pps).format(
                      "DD.MM.YYYY"
                    )) ||
                    "-"}
                </span>
              </div>
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.registration.address}
                </span>
                <span className="HallCardRow__Subtitle">
                  {cardInfo.registration.address || "-"}
                </span>
              </div>
            </Card>
            <Card
              headerIcon={<LicenseIcon />}
              header={hallCardTextConst.license}
            >
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.license.license_number}
                </span>
                <span className="HallCardRow__Subtitle">
                  {cardInfo.license.license_number || "-"}
                </span>
              </div>
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.license.license_date}
                </span>
                <span className="HallCardRow__Subtitle">
                  {(cardInfo.license.license_date &&
                    moment(cardInfo.license.license_date).format(
                      "DD.MM.YYYY"
                    )) ||
                    "-"}
                </span>
              </div>
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.license.op_order_date}
                </span>
                <span className="HallCardRow__Subtitle">
                  {(cardInfo.license.op_order_date &&
                    moment(cardInfo.license.op_order_date).format(
                      "DD.MM.YYYY"
                    )) ||
                    "-"}
                </span>
              </div>
              <div className="HallCardRow">
                <span className="TableHeader HallCardRow__Title">
                  {cardConst.license.extract_number}
                </span>
                <span className="HallCardRow__Subtitle">
                  {cardInfo.license.extract_number || "-"}
                </span>
              </div>
            </Card>
            <Card
              headerIcon={<ManagersIcon />}
              header={hallCardTextConst.managers}
            >
              {cardInfo.managers.length
                ? cardInfo.managers.map(manager => (
                    <div className="HallCardRow" key={manager.manager_name}>
                      <span className="TableHeader HallCardRow__Title">
                        {manager.manager_role}
                      </span>
                      <span className="HallCardRow__Subtitle">
                        {manager.manager_name || "-"}
                      </span>
                      {manager.manager_email && (
                        <span className="SubtitleSecondText">
                          {cardConst.managers.email}
                          <a href={`mailto:${manager.manager_email}`}>
                            {manager.manager_email || "-"}
                          </a>
                        </span>
                      )}
                      {manager.manager_phone && (
                        <span className="SubtitleSecondText">
                          {cardConst.managers.phone}
                          <a href={`tel:${manager.manager_phone}`}>
                            {manager.manager_phone || "-"}
                          </a>
                        </span>
                      )}
                    </div>
                  ))
                : "-"}
            </Card>
          </div>
        </div>
      </div>
      <BaseFooter />
    </div>
  )
}

export default HallCard
