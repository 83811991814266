import { IRootState } from "store"
import { EmergencyDataType } from "./types"

export const getMessageCount = (state: IRootState): number =>
  state.message.message_count

export const getEmergencyCount = (
  state: IRootState
): { emergency: EmergencyDataType[]; emergency_open: boolean } => ({
  emergency: state.message.emergency,
  emergency_open: state.message.emergency_open
})
