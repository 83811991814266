import { IconsColorsClasses } from "./types"
import { ReactComponent as JpgIcon } from "./jpg.svg"
import { ReactComponent as PngIcon } from "./png.svg"
import { ReactComponent as XlsIcon } from "./xls.svg"
import { ReactComponent as DocIcon } from "./doc.svg"
import { ReactComponent as PdfIcon } from "./PDF.svg"
import React from "react"

export const iconsColorsClasses: IconsColorsClasses = {
  jpg: "MinifiedFileComponentJpg",
  jpeg: "MinifiedFileComponentJpg",
  png: "MinifiedFileComponentPng",
  pdf: "MinifiedFileComponentPdf"
}

export const typeIcon = (type: string): JSX.Element => {
  switch (type.toLocaleLowerCase()) {
    case `jpg`: {
      return <JpgIcon />
    }
    case `jpeg`: {
      return <JpgIcon />
    }
    case `png`: {
      return <PngIcon />
    }
    case `xls`: {
      return <XlsIcon />
    }
    case `xlsx`: {
      return <XlsIcon />
    }
    case `doc`: {
      return <DocIcon />
    }
    case `docx`: {
      return <DocIcon />
    }
    case `pdf`: {
      return <PdfIcon />
    }
    default: {
      return <div />
    }
  }
}

export const convertSizeToString = (bytes: number, dp = 1) => {
  const thresh = 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }

  const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + " " + units[u]
}

export const fileUploadText = {
  errors: {
    title: `Ошибка загрузки файла`,
    size: {
      label: `Файл слишком большой`,
      description: (maxSize: number) =>
        `Размер файлов не должен превышать ${maxSize}Mb`
    }
  }
}
