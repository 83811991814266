import { IMenuState } from "./types"

import { STORE_MENU } from "./constants"

const init: IMenuState = {
  data: {
    main: [],
    bottom: [],
    options: []
  },
  routes: []
}

export function menuReducer(
  state: IMenuState = init,
  action: { type: string; payload: IMenuState }
): IMenuState {
  const routesFromStorage = localStorage.getItem(`routes`)
  const routes: (string | null)[] = routesFromStorage
    ? JSON.parse(routesFromStorage)
    : []
  switch (action.type) {
    case STORE_MENU: {
      return { ...state, ...action.payload }
    }

    default:
      return { ...state, routes }
  }
}
