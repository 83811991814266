import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import moment from "moment"
import { notification } from "antd"
import { getMessaging } from "firebase/messaging"

import { useRequestParams } from "api/hooks"

import { onMessageListener, requestForToken } from "./firebase"
import { ReactComponent as LogoIcon } from "./Lk_notifi_logo.svg"
import { ReactComponent as CloseIcon } from "./close_notifi.svg"

import { NotifiCountProps } from "./types"
import { initialState, notifiCountText } from "./constants"

import { MESSAGES_PAGE } from "Containers/BaseLayout/constants"

import { storeEmergency, storeCount } from "store/message/actions"
import { getMessageCount } from "store/message/selector"
import { apiStoreMessage } from "store/message/constants"
import { MessageStoreState } from "store/message/types"

const NotificationCount = ({ count }: { count: number }) => {
  let messaging: any
  try {
    messaging = getMessaging()
  } catch (error_msg) {
    // eslint-disable-next-line no-console
    console.error(`firebase test error!`, error_msg)
  }
  const { history, addDeveloperLink, developer, logout } = useRequestParams()
  const { pathname } = useLocation()
  // storeData
  const dispatch = useDispatch()
  const countFromStorage = useSelector(getMessageCount)
  const storeMessageData: (value: MessageStoreState) => void = (
    value: MessageStoreState
  ) => dispatch(storeEmergency(value))
  const storeCountNumber: (value: { count: number }) => void = (value: {
    count: number
  }) => dispatch(storeCount(value))

  const [notificationState, setNotification] = useState<NotifiCountProps>(
    initialState
  )

  const notify = () => {
    notification.info({
      message: (
        <span className="NotificationToastTitle">{notifiCountText.title}</span>
      ),
      description: (
        <div className="NotificationToastTextField">
          <span className="NotificationToastTheme">
            {notificationState.title}.
          </span>
          <span className="NotificationToastText">
            {notificationState.body}
          </span>
        </div>
      ),
      key: `${notificationState.id}`,
      placement: "bottomRight",
      className: "NotificationToastWrapper",
      duration: 0,
      icon: <LogoIcon />,
      closeIcon: <CloseIcon />,
      onClick: () => {
        history.push(`${MESSAGES_PAGE}/${notificationState.id}`)
        notification.close(`${notificationState.id}`)
      }
    })
  }

  useEffect(() => {
    for (let i = 0; i < notificationState.all.length; i++) {
      notification.close(`${notificationState.all[i]}`)
    }
  }, [pathname])

  useEffect(() => {
    requestForToken(messaging)
    storeCountNumber({ count })
  }, [])

  useEffect(() => {
    if (!!notificationState.title) {
      notificationState.isRequire
        ? apiStoreMessage({
            developer,
            addDeveloperLink,
            logout,
            history,
            storeMessageCount: storeMessageData,
            emergency: notificationState.isRequire
              ? [
                  {
                    date: moment().format(`DD.MM.YYYY mm:HH`),
                    id: Number(notificationState.id),
                    text: notificationState.body || ``
                  }
                ]
              : []
          })
        : notify()
    }
  }, [notificationState])

  onMessageListener(messaging).then((payload: any) => {
    setNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body,
      isRequire: payload?.data?.isRequire !== `0`,
      id: payload?.data?.id,
      all: [...notificationState.all, payload?.data?.id]
    })
  })

  const showCount = countFromStorage > 0

  return showCount ? (
    <div className="CounterComponentWrapper NNCounterComponentWrapper">
      {countFromStorage}
    </div>
  ) : (
    <div />
  )
}

export default NotificationCount
