import * as React from "react"

export enum TableCardItemLength {
  single = "single"
}

export type TableCardItem = {
  description: string | React.ReactNode
  text?: string | number | null | React.ReactNode
  cardLength?: TableCardItemLength
  className?: string
}

export type TableCardItemCollapsibleConfig = {
  titlePrefix?: React.ReactNode
  title: string
  titleClassName?: string
  items: TableCardItem[]
}

export type TableCardProps = {
  config: TableCardItem[]
  collapsible?: boolean
}
