import React from "react"

import { getInfoContent } from "constants/index"

import { Popover } from "antd"

import { ReactComponent as GiftOrangeIcon } from "Components/icons/gift_orange.svg"
import { ReactComponent as MoneyGreenIcon } from "Components/icons/money_green.svg"
import { ReactComponent as FreebetIcon } from "Components/icons/freebet.svg"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle.svg"

type InsideProps = {
  data: {
    hall: string
    bonus: {
      field: string
      value: string
    }[]
    money: {
      field: string
      value: string
    }[]
    freebet: {
      field: string
      value: string
    }[]
  }[]
  date: string | null
}

const negativeValue = (value: string) =>
  value.length > 1 && `${value}`.includes(`-`) ? `TableItemNegativeValue` : ``

const ConsolidatedInside = ({ data, date }: InsideProps) => (
  <div className="ConsolidatedInsideWrapper">
    {data.map(insideItem => {
      const bonusData =
        insideItem.bonus && insideItem.bonus.length
          ? insideItem.bonus
          : [{ field: `—`, value: `—` }]
      const bonusDataPercent = `${
        insideItem.bonus && insideItem.bonus.length
          ? `${100 / insideItem.bonus.length}%`
          : `100%`
      }`
      const bonusMinLength =
        insideItem.bonus && insideItem.bonus.length
          ? 210 * insideItem.bonus.length
          : 240
      const moneyData =
        insideItem.money && insideItem.money.length
          ? insideItem.money
          : [{ field: `—`, value: `—` }]
      const moneyDataPercent = `${
        insideItem.money && insideItem.money.length
          ? `${100 / insideItem.money.length}%`
          : `100%`
      }`
      const moneyMinLength =
        insideItem.money && insideItem.money.length
          ? 210 * insideItem.money.length
          : 240
      const freebetData =
        insideItem.freebet && insideItem.freebet.length
          ? insideItem.freebet
          : [{ field: `—`, value: `—` }]
      const freebetDataPercent = `${
        insideItem.freebet && insideItem.freebet.length
          ? `${100 / insideItem.freebet.length}%`
          : `100%`
      }`
      const freebetMinLength =
        insideItem.freebet && insideItem.freebet.length
          ? 210 * insideItem.freebet.length
          : 240

      return (
        <div
          key={`summary_report_inside_hall${insideItem.hall}`}
          className="ReportTableInsideWrapper"
        >
          {!!date ? (
            <div className="FlexRow ConsolidatedInsideHallTitle">
              <span>{`Клуб № ${insideItem.hall}`}</span>
              <Popover
                overlayClassName="InfoPopoverWrapper"
                placement="right"
                content={getInfoContent({ hall: insideItem.hall })}
                trigger="hover"
              >
                <div className="ReportInsideHallInfo">
                  <InfoIcon />
                </div>
              </Popover>
            </div>
          ) : (
            <div />
          )}
          <div className="ReportTableInsideContent FlexRow">
            <div
              className="ConsolidatedInsideTableBonus"
              style={{
                minWidth: `${bonusMinLength}px`,
                width: `50%`
              }}
            >
              <div
                style={{
                  backgroundColor: `#FFF4E8`,
                  borderBottom: `1px solid #E9EAEA`,
                  borderTopLeftRadius: `8px`,
                  minWidth: `${bonusMinLength}px`
                }}
                className="GridCenter"
              >
                <div className="ConsolidatedInsideTableHeader FlexRow">
                  <GiftOrangeIcon />
                  <span>Бонусные начисления</span>
                </div>
              </div>
              <div className="FlexRow">
                {bonusData.map(item => (
                  <div
                    style={{ width: bonusDataPercent }}
                    key={`consolidated_bonus_${item.field}`}
                  >
                    <div className="ConsolidatedInsideTableItem ConsolidatedInsideTableItemHeader">
                      {item.field || `—`}
                    </div>
                    <div
                      className={`ConsolidatedInsideTableItem ${negativeValue(
                        item.value
                      )}`}
                    >
                      {item.value || `—`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="ConsolidatedInsideTableMoney"
              style={{
                borderRight: `1px solid #E9EAEA`,
                minWidth: `${moneyMinLength}px`,
                width: `25%`
              }}
            >
              <div
                className="GridCenter"
                style={{
                  backgroundColor: `#E7F8F4`,
                  borderLeft: `1px solid #E9EAEA`,
                  borderBottom: `1px solid #E9EAEA`,
                  minWidth: `${moneyMinLength}px`
                }}
              >
                <div className="ConsolidatedInsideTableHeader FlexRow">
                  <MoneyGreenIcon />
                  <span>Денежные начисления</span>
                </div>
              </div>

              <div className="FlexRow">
                {moneyData.map(item => (
                  <div
                    style={{
                      width: moneyDataPercent,
                      borderLeft: `1px solid #E9EAEA`
                    }}
                    key={`consolidated_money_${item.field}`}
                  >
                    <div className="ConsolidatedInsideTableItem ConsolidatedInsideTableItemHeader">
                      {item.field || `—`}
                    </div>
                    <div
                      className={`ConsolidatedInsideTableItem ${negativeValue(
                        item.value
                      )}`}
                    >
                      {item.value || `—`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="ConsolidatedInsideTableFreebet"
              style={{
                minWidth: `${freebetMinLength}px`,
                width: `25%`
              }}
            >
              <div
                className="GridCenter"
                style={{
                  backgroundColor: `#F0F0FE`,
                  borderBottom: `1px solid #E9EAEA`,
                  borderTopRightRadius: `8px`,
                  minWidth: `${freebetMinLength}px`
                }}
              >
                <div className="ConsolidatedInsideTableHeader FlexRow">
                  <FreebetIcon />
                  <span>Фрибетные начисления</span>
                </div>
              </div>
              <div className="FlexRow">
                {freebetData.map(item => (
                  <div
                    style={{ width: freebetDataPercent }}
                    key={`consolidated_freebet_${item.field}`}
                  >
                    <div className="ConsolidatedInsideTableItem ConsolidatedInsideTableItemHeader">
                      {item.field || `—`}
                    </div>
                    <div
                      className={`ConsolidatedInsideTableItem ${negativeValue(
                        item.value
                      )}`}
                    >
                      {item.value || `—`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )
    })}
  </div>
)
export default ConsolidatedInside
