import React, { useEffect, useState } from "react"

import { useRequest } from "api/hooks"

import EmptyTable from "Components/EmptyTable"
import ViewTrigger from "Components/ViewTrigger"

import MailingData from "./MailingData"
import MailingHallDetail from "./Detail"

import { MailingHallState, MailingHallContentProps } from "./types"
import { initialState, mailingHallContentConstants } from "./helper"

const MailingHallContent: React.FC<MailingHallContentProps> = ({
  refreshTrigger,
  filterIsOpen,
  refreshHalls,
  pagination,
  nextPage,
  filterState
}) => {
  const [mailing, setMailing] = useState<MailingHallState>(initialState)

  const [detailModal, setDetailModal] = useState<undefined | number>(undefined)

  const { request: getData } = useRequest({
    url: mailingHallContentConstants.url,
    method: `POST`,
    requestBody: {
      ...filterState.data,
      pagination
    }
  })

  const requestData = (isPagination: boolean) => {
    setMailing(prev => ({ ...prev, pending: true }))
    getData()
      .then(({ data, headers }) => {
        setMailing(prev => ({
          ...prev,
          data: isPagination ? [...prev.data, ...data] : data,
          pending: false,
          total: Number(headers[`total-count`])
        }))
      })
      .catch(() => setMailing(prev => ({ ...prev, pending: false })))
  }

  useEffect(() => {
    requestData(false)
  }, [filterState.data, refreshTrigger])

  useEffect(() => {
    if (pagination.offset !== 0) {
      requestData(true)
    }
  }, [pagination])

  const onlyTwoCard = mailing.total === 2
  const needPagination = mailing.total > pagination.limit

  return (
    <div
      className={`${
        filterIsOpen
          ? `MailingHallContentWithFilterWrapper`
          : `MailingHallContentWithoutFilterWrapper`
      } ${
        onlyTwoCard ? `MailingHallContentOnlyTwoWrapper` : ``
      } MailingHallContentWrapper FlexWrap FlexRow`}
    >
      {!mailing.pending && !mailing.total && (
        <div className="MailingEmptyTableWrapper">
          <EmptyTable />
        </div>
      )}
      {mailing.data.map(item => (
        <div
          key={`mailing_hall_${item.hall}`}
          className="MailingDataItemWrapper"
        >
          <MailingData
            item={item}
            openDetail={() => setDetailModal(item.hall)}
          />
        </div>
      ))}
      {!!detailModal && (
        <MailingHallDetail
          hall={detailModal}
          refreshHalls={refreshHalls}
          close={() => setDetailModal(undefined)}
        />
      )}
      <ViewTrigger needTrigger={needPagination} trigger={nextPage} />
    </div>
  )
}

export default MailingHallContent
