import { QiwiTableTypes } from "../Table/types"

export const initialData = {
  initial: undefined,
  open: false
}

export enum QiwiModalFields {
  name = `name`,
  phone = `phone`,
  email = `email`,
  halls = `halls`,
  sum = `sum`,
  comment = `comment`
}

export const modalConst = {
  title: `Новая заявка`,
  saveUrl: `v1/order/qiwi/save`,
  save: `Отправить`,
  cancel: `Отменить`,
  success: {
    title: `Заявка оформлена`,
    close: `Хорошо`
  },
  [QiwiModalFields.name]: {
    label: `ФИО`,
    placeholder: `Введите ФИО`
  },
  [QiwiModalFields.phone]: {
    label: `Номер телефона`,
    placeholder: `Введите номер телефона`
  },
  [QiwiModalFields.email]: {
    label: `Эл. почта`,
    placeholder: `Введите вашу эл. почту`
  },
  [QiwiModalFields.halls]: {
    label: `Клуб(ы)`,
    placeholder: `Выберите клуб(ы)`
  },
  [QiwiModalFields.sum]: {
    label: `Сумма, ₽`,
    placeholder: `Введите сумму`,
    hall: `Клуб`,
    type: `Тип заявки`,
    type_placeholder: `Выберите тип`,
    commission: (sum?: string) => {
      const prepareSum = sum ? Number(sum.replaceAll(`,`, `.`)) : 0
      if (prepareSum) {
        const commission = prepareSum - prepareSum * (1.1 / 100)
        return `Сумма с учётом комиссии (1,1%) = ${commission.toFixed(2)} ₽`
      }
    }
  },
  [QiwiModalFields.comment]: {
    label: `Коммертарий`,
    placeholder: `Введите комментарий`
  }
}

export const getInitialValues = (hall: number[]) => ({
  [QiwiModalFields.name]: undefined,
  [QiwiModalFields.phone]: undefined,
  [QiwiModalFields.email]: undefined,
  [QiwiModalFields.halls]: hall
})

export const hallsTypeOptions = [
  {
    label: `Баланс для выплат`,
    value: QiwiTableTypes.replenishment
  },
  {
    label: `Яндекс такси`,
    value: QiwiTableTypes.replenishment_yandex_taxi
  }
]

export const orderOption = {
  label: `Возврат эквайринга`,
  value: QiwiTableTypes.order
}
const formatCurrency = (input: string): string => {
  // Remove any existing commas or periods
  const cleanedInput = input.replace(`,`, ".")

  // If the cleaned input is empty or not a valid number, return "0.00"
  if (!cleanedInput) {
    return "0.00"
  }

  // Parse the cleaned input as a number and format it with two decimal places
  const formattedString = parseFloat(cleanedInput).toFixed(2)

  return formattedString
}

export const prepareDataToSave = (value: any) => {
  const hallsData = value.halls.map((hall: number) => {
    const sum = value[`hallSum-${hall}`]
    const comment = value[`hallComment-${hall}`] || undefined
    const prepareSum = formatCurrency(sum)
    return {
      hall,
      sum: prepareSum,
      type: value[`hallType-${hall}`] || QiwiTableTypes.replenishment,
      comment
    }
  })
  return {
    email: value.email,
    data: hallsData,
    name: value.name,
    phone: value.phone
  }
}
