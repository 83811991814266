import React from "react"

import { formatToCurrency } from "constants/index"

import moment, { Moment } from "moment"

import { ReactComponent as CashIcon } from "./icons/cash_table.svg"
import { ReactComponent as NonCashIcon } from "./icons/noncash_table.svg"
import { ReactComponent as TransferOutIcon } from "./icons/transfer_out.svg"

import { GuestHistoryCashboxTableDataColumnsCalculation } from "./types"

export const cashboxTableText = {
  title: (count: number) =>
    !!count ? `Всего ${count} операций` : `Нет операций`,
  descriptionIn: (count: number) => {
    const value = formatToCurrency({ amount: count })
    return `Операции IN — ${value} ₽`
  },
  descriptionOut: (count: number) => {
    const value = formatToCurrency({ amount: count })
    return `Операции OUT — ${value} ₽`
  },
  emptyTable: {
    title: `Нет данных`,
    subtitle: `За необходимый период по данному клубу \nнет данных`
  }
}

const columnKeys = {
  date: {
    label: `Дата и время`,
    key: `date`,
    format: `DD.MM.YYYY`,
    time: `HH:mm:ss`
  },
  type: {
    label: `Тип операции`,
    key: `type`
  },
  sum: {
    label: `Сумма, ₽`,
    key: `sum`
  },
  calculation_type: {
    label: `Тип расчета`,
    key: `calculation_type`
  }
}

export const initialState = {
  pending: false,
  total: 0,
  data: { in: 0, out: 0, data: [] }
}

export const cashboxColumn = [
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing TableHeaderFirstTh">
        {columnKeys.date.label}
      </div>
    ),
    dataIndex: columnKeys.date.key,
    key: columnKeys.date.key,
    // width: 200,
    render: (date: Moment) => (
      <div className="FlexRow">
        <span className="MailingHistoryTdDate">
          {moment(date).format(columnKeys.date.format)}
        </span>
        <span className="MailingHistoryTdDateDescription">
          {moment(date).format(columnKeys.date.time)}
        </span>
      </div>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">{columnKeys.type.label}</div>
    ),
    dataIndex: columnKeys.type.key,
    key: columnKeys.type.key,
    render: (date: string) => <span className="Uppercase">{date}</span>
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">{columnKeys.sum.label}</div>
    ),
    dataIndex: columnKeys.sum.key,
    key: columnKeys.sum.key,
    render: (sum: string) => (
      <span className="">
        {formatToCurrency({ amount: Number(sum), withoutSpace: true })}
      </span>
    )
  },
  {
    title: () => (
      <div className="TableHeaderWithoutSpacing">
        {columnKeys.calculation_type.label}
      </div>
    ),
    dataIndex: columnKeys.calculation_type.key,
    key: columnKeys.calculation_type.key,
    render: (
      calculation_type: GuestHistoryCashboxTableDataColumnsCalculation
    ) => {
      const config = {
        [GuestHistoryCashboxTableDataColumnsCalculation.cash]: {
          label: `Наличный`,
          icon: <CashIcon />
        },
        [GuestHistoryCashboxTableDataColumnsCalculation.noncash]: {
          label: `Безналичный`,
          icon: <NonCashIcon />
        },
        [GuestHistoryCashboxTableDataColumnsCalculation.transfer]: {
          label: `Перенос в клуб`,
          icon: <TransferOutIcon />
        }
      }
      return (
        <div className="FlexRow">
          {config[calculation_type]?.icon}
          <span className="GuestHistoryCashboxTableType">
            {config[calculation_type]?.label}
          </span>
        </div>
      )
    }
  }
]
