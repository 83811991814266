import { RouteComponentProps } from "react-router-dom"

import { PaginationValue } from "Components/Pagination/types"

import { parseDateByShift, parseDateToBack } from "constants/index"
import betRequest from "api/request"

import { CashboxFilterProps, CashboxState } from "./types"
import { IDevelopState } from "store/developer/types"

type GetCashboxData = {
  cashboxState: CashboxState
  setCashboxState: (data: CashboxState) => void
  filterState: CashboxFilterProps
  logout: () => void
  history: RouteComponentProps["history"]
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  pagination: PaginationValue
}

export const getCashboxData = ({
  cashboxState,
  setCashboxState,
  filterState,
  logout,
  developer,
  addDeveloperLink,
  history,
  pagination
}: GetCashboxData) => {
  setCashboxState({
    ...cashboxState,
    pending: true
  })

  const dates = filterState.data.dates
    ? parseDateToBack({
        date: parseDateByShift({
          dates: filterState.data.dates,
          isShift: filterState.data.isShift,
          shift: filterState.data.shift
        }),
        gmt: filterState.data.gmt
      })
    : null

  betRequest({
    method: `POST`,
    url: `reports/cashbox`,
    requestBody: {
      ...filterState.data,
      shift: filterState.data.isShift
        ? filterState.data.shift[0]
        : filterState.data.shift,
      dates,
      pagination
    },
    developer,
    addDeveloperLink,
    history,
    logout
  })
    .then(({ data, headers }) => {
      setCashboxState({
        ...cashboxState,
        pending: false,
        data: data,
        total: headers[`total-count`]
      })
    })
    .catch(() =>
      setCashboxState({
        ...cashboxState,
        pending: false
      })
    )
}
