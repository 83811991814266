import React, { useEffect, useMemo, useState } from "react"

import { useNow, useRequest } from "api/hooks"

import TableComponent from "Components/TableNew"

import { QiwiOrderTableProps, QiwiTableState } from "./types"
import { getColumns, tableConst } from "./constants"
import QiwiModal from "../Modal"
import { initialData } from "../Modal/constants"
import CancelModal from "../Cancel"

const QiwiOrderTable = ({
  reqData,
  setReqData,
  qiwiPending,
  setQiwiPending,
  crudModal,
  setCrudModal
}: QiwiOrderTableProps) => {
  const [table, setTable] = useState<QiwiTableState>({
    data: [],
    total: 0
  })
  const [cancelModal, setCancelModal] = useState<number | undefined>(undefined)

  const { request: getData } = useRequest({
    url: tableConst.url,
    requestBody: {
      filter: reqData.filters,
      pagination: reqData.pagination,
      sort: reqData.sort
    }
  })

  const { timestamp, refresh } = useNow()

  // wait back
  useEffect(() => {
    setQiwiPending(true)
    getData()
      .then(({ data }) => {
        const { items, total_count } = data
        setTable({
          data: items,
          total: total_count
        })
        setQiwiPending(false)
      })
      .catch(() => setQiwiPending(false))
  }, [reqData, timestamp])

  const columns = useMemo(
    () => getColumns({ reqData, setReqData, setCrudModal, setCancelModal }),
    [reqData]
  )

  return (
    <div>
      <TableComponent
        loading={qiwiPending}
        columns={columns}
        data={table.data}
        width={1380}
        pagination={{
          total: table.total,
          value: reqData.pagination,
          onChange: newPagination =>
            setReqData({ ...reqData, pagination: newPagination })
        }}
      />
      {crudModal.open && (
        <QiwiModal
          initial={crudModal.initial}
          close={() => setCrudModal(initialData)}
          refreshTable={refresh}
        />
      )}
      {!!cancelModal && (
        <CancelModal
          id={cancelModal}
          refreshTable={refresh}
          close={() => setCancelModal(undefined)}
        />
      )}
    </div>
  )
}

export default QiwiOrderTable
