export const appealModalText = {
  title: "Согласовать обжалование штрафа",
  actions: "Действия",
  partner: "Партнер",
  partnerPlaceholder: "Укажите имя партнера"
}

export const appealModalRadioButtonsOptions = [
  {
    value: 1,
    label: "Отменить штраф"
  },
  { value: 2, label: "Уменьшить сумму штрафа" }
]
