import React, { useEffect, useState, useCallback, useMemo, useRef } from "react"
import { Provider, useSelector, useDispatch } from "react-redux"
import { Image, Modal, Popover } from "antd"

import moment from "moment"
import { message } from "antd"

import main, { getList, isReadyForFirstConsideration } from "./store/main"
import { addImgList } from "./store"

import { roleConstants } from "constants/index"

import {
  textConstants,
  initialState,
  initialRightPanel,
  ComponentByStatus,
  statusConfig,
  modalSelectOptions
} from "./constants"
import {
  GuidebookPageState,
  GuidebookPageData,
  ModalData,
  GuidebookCardInfo
} from "./types"
import useGuidebookApi from "./helper"

import GuidebookCard from "./Card"
import GuidebookRightPanel from "./RightPanel"

import { ReactComponent as DownloadIcon } from "./icons/download.svg"
import { ReactComponent as CommentIcon } from "./icons/comment.svg"
import { ReactComponent as SendIcon } from "./icons/send.svg"

import ReportWithFilter from "Components/ReportWithFilter"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { ReactComponent as CloseIcon } from "Components/icons/close-big.svg"
import { ReactComponent as DetailsIcon } from "Components/icons/details.svg"
import { ReactComponent as EditButtonIcon } from "./icons/edit_button.svg"
import { ReactComponent as DeleteButtonIcon } from "./icons/delete_button.svg"
import TooltipComponent from "Components/Tooltip"
import { InputTextAreaComponent } from "Components/Input"
import produce from "immer"
import { CustomOption, SelectComponent } from "Components/Select"
import SwitchComponents from "Components/Switch"
import { socketGuidebook } from "./Chat/helper"
import { SocketChatEvents } from "./Chat/constants"
import { Socket } from "socket.io-client"
import { getCurrentHall } from "store/halls/selector"

export const commentModeDataInit = {
  activeButton: false,
  activeTooltip: false,
  text: "",
  attachment: 0,
  position: {
    top: -1,
    left: -1
  }
}

const editCommentInitial = {
  active: false,
  text: undefined,
  position: {
    top: 0,
    left: 0
  }
}

const Guidebook = () => {
  const { isAdmin } = roleConstants()

  const dispatch = useDispatch()
  const setCurrentList: (data: GuidebookPageData) => void = (
    data: GuidebookPageData
  ) => dispatch(addImgList(data))
  const imgList = useSelector(getList)
  const isReady = useSelector(isReadyForFirstConsideration)

  const firstCreate = !imgList.status

  const [cardState, setCardState] = useState<GuidebookPageState>(initialState)
  const [rightPanel, setRightPanel] = useState(initialRightPanel)
  const [editCardId, setEditCardId] = useState(0)
  const [modal, setModal] = useState<ModalData>({
    visible: false,
    link: ""
  })
  const [currentCommentId, setCurrentCommentId] = useState<number | undefined>(
    undefined
  )
  const [currentSection, setCurrentSection] = useState<undefined | number>(
    undefined
  )
  const [editCommentData, setEditCommentData] = useState<{
    active: boolean
    text?: string
    position?: {
      top: number
      left: number
    }
  }>(editCommentInitial)
  const [oldImage, setOldImage] = useState(false)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const unreads = useMemo(() => {
    const fromLS = localStorage.getItem("GbChatUnread")
    return fromLS ? Number(JSON.parse(fromLS)) : 0
  }, [])
  const timeoutRefModal = useRef<NodeJS.Timeout | null>(null)
  const [unreadMessagesCounter, setUnreadMessagesCounter] = useState(unreads)
  const hallState = useSelector(getCurrentHall)
  const hallLs = JSON.parse(localStorage.getItem("hall") || "")
  const currentHall = hallState?.length > 0 ? hallState : hallLs

  const isMobile = window.innerWidth < 921

  const socketRef = useRef<Socket>(socketGuidebook())
  const imageRef = useRef()
  const [dimensions, setDimensions] = useState({
    top: 0,
    left: 0
  })

  const resetUnreadMessages = useCallback(() => {
    setUnreadMessagesCounter(0)
  }, [])

  useEffect(() => {
    localStorage.setItem("GbChatUnread", JSON.stringify(unreadMessagesCounter))
  }, [unreadMessagesCounter])

  useEffect(() => {
    socketRef?.current?.connect()
    socketRef?.current?.on(SocketChatEvents.newMessage, () => {
      setUnreadMessagesCounter(prev => (prev += 1))
    })
    return () => {
      socketRef.current?.disconnect()
    }
  }, [])

  const comments = useMemo(() => {
    return (
      cardState.data.info
        .reduce((acc, el) => {
          const comments = el.attachments.reduce(
            (commentsAcc, commentsEl, index) => {
              const nextComments = commentsEl.comments.map(comment => {
                return {
                  ...comment,
                  title: el.title,
                  index: index + 1,
                  id: commentsEl.id,
                  link: commentsEl.link,
                  status: el.status,
                  attachId: commentsEl.id,
                  commentId: comment.id,
                  sectionId: el.id
                }
              })
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              return commentsAcc.concat(nextComments)
            },
            []
          )

          return acc.concat(comments)
        }, [])
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    )
  }, [cardState.data.info])

  useEffect(() => {
    if (!modal.isSingle) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setModal(prev => ({
        ...prev,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        comments: comments.filter(el => el.attachId === modal.attachId) || []
      }))
    }
  }, [comments, modal.attachId])

  const [commentModeData, setCommentModeData] = useState(commentModeDataInit)
  const [statusData, setStatusData] = useState(modal.uplStatus)
  const [statusRequestNeeded, setStatusRequestNeeded] = useState(false)

  useEffect(() => {
    if (statusData && statusRequestNeeded) {
      changeStatus()
        .then(() => {
          setCardState(prev => ({ ...prev, pending: true }))
          getInitialData()
            .then(({ data }) => {
              const { date, info, status } = data
              setCurrentList({ info, status, date })
              setCardState(prev => ({ ...prev, data: data, pending: false }))
              setModal(prev => ({ ...prev, uplStatus: statusData }))
            })
            .catch(() => setCardState(prev => ({ ...prev, pending: false })))
          setStatusRequestNeeded(false)
        })
        .catch(() => {
          setStatusRequestNeeded(false)
        })
    }
  }, [statusData, statusRequestNeeded])

  const {
    getInitialData,
    sendOnApprove,
    addComment,
    changeStatus,
    getArchive,
    editComment,
    deleteComment
  } = useGuidebookApi({
    addCommentData: {
      text: commentModeData.text,
      position: commentModeData.position,
      attachment: modal.attachId
    },
    editCommentData: {
      text: editCommentData.text,
      position: editCommentData.position
    },
    status: statusData,
    commentId: currentCommentId
  })

  useEffect(() => {
    if (!modal.visible) {
      setModal({ visible: false, link: "" })
    }
  }, [modal.visible])

  const setModalCb = useCallback(data => {
    setModal(data)
  }, [])

  useEffect(() => {
    setCardState(prev => ({ ...prev, pending: true }))

    getInitialData()
      .then(({ data }) => {
        const { date, info, status } = data
        setCurrentList({ info, status, date })
        setCardState(prev => ({ ...prev, data: data, pending: false }))
      })
      .catch(() => setCardState(prev => ({ ...prev, pending: false })))
    return () => {
      if (timeoutRefModal.current) {
        clearTimeout(timeoutRefModal.current)
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const [chatActive, setChatActive] = useState(false)

  return (
    <div>
      <ReportWithFilter
        isOpen={rightPanel.isOpen}
        mobileFilter={rightPanel.mobileFilter}
        filterComponent={
          <GuidebookRightPanel
            info={cardState.data.info}
            isOpen={rightPanel.isOpen}
            setModalData={setModalCb}
            socket={socketRef.current}
            resetUnreadMessages={resetUnreadMessages}
            toggleFilter={() =>
              setRightPanel(prev => ({ ...prev, isOpen: !prev.isOpen }))
            }
            toggleMobileFilter={() =>
              setRightPanel(prev => ({
                ...prev,
                mobileFilter: !prev.mobileFilter
              }))
            }
            setChatActive={setChatActive}
            unreadMessagesCounter={unreadMessagesCounter}
          />
        }
        additionalClassName={
          rightPanel?.isOpen && chatActive
            ? "GuideBookFilterLarge"
            : "GuideBookFilter"
        }
        toggleFilter={() =>
          setRightPanel(prev => ({ ...prev, isOpen: !prev.isOpen }))
        }
        toggleMobileFilter={() =>
          setRightPanel(prev => ({ ...prev, mobileFilter: !prev.mobileFilter }))
        }
        pageContent={
          <>
            <div
              className={`GuidebookHeader FlexRow ${
                isMobile ? "GuidebookHeaderMobile" : ""
              }`}
            >
              <div
                className={`GuidebookTitle ${
                  isMobile ? "HeadlineSecondText" : "HeadlineFirstText"
                }`}
              >
                {textConstants.title}
              </div>
              <div className="StatusDateDefaultWrapper">
                <div>
                  {!!imgList.status && (
                    <ComponentByStatus status={imgList.status} />
                  )}
                </div>
                {!!imgList.date && (
                  <div className="FlexRow GuidebookTitleDateDescription">
                    <span>
                      {moment(imgList.date).format(textConstants.dateFormat)}
                    </span>
                    <span className="GuidebookTitleTimeDescription">
                      {moment(imgList.date).format(textConstants.timeFormat)}
                    </span>
                  </div>
                )}
              </div>
              {!firstCreate && !isMobile && (
                <div
                  className="GuidebookTitleArchive"
                  onClick={() =>
                    getArchive().then(res => {
                      const baseUrl = process.env.REACT_APP_AUTH_URL?.replace(
                        "/api/site",
                        ""
                      )
                      const fileUrl = `${baseUrl}${res.data.link}`
                      const link = document.createElement("a")
                      link.href = fileUrl
                      link.download = `archive_${currentHall}.zip`
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                    })
                  }
                >
                  <ButtonDefault>
                    <div className="FlexRow GuidebookTitleArchiveText">
                      <DownloadIcon />
                      <span className="TableHeaderText">
                        {textConstants.archive}
                      </span>
                    </div>
                  </ButtonDefault>
                </div>
              )}
            </div>
            {cardState.data.info.map((item, count) => (
              <GuidebookCard
                editCardId={editCardId}
                setEditCardId={setEditCardId}
                key={`${item.title}_${item.id}`}
                data={item}
                count={count + 1}
                setModalData={setModalCb}
              />
            ))}
            {firstCreate && !isAdmin && (
              <ButtonPrimary
                disabled={!isReady}
                onClick={() => {
                  sendOnApprove()
                    .then(response => {
                      setCurrentList(response.data)
                    })
                    .catch(error =>
                      message.error(
                        error.response?.data?.errors || textConstants.saveError
                      )
                    )
                }}
              >
                {textConstants.send}
              </ButtonPrimary>
            )}
          </>
        }
      />
      <Modal
        className="GuideBookAttachModal"
        visible={modal.visible}
        footer={null}
        closeIcon={
          <CloseIcon
            className="ProposalModalCloseIcon"
            onClick={() => {
              setCommentModeData(commentModeDataInit)
              setStatusRequestNeeded(false)
              if (timeoutRefModal.current) {
                clearTimeout(timeoutRefModal.current)
              }
              timeoutRefModal.current = setTimeout(() => {
                setModal({ visible: false, link: "" })
                timeoutRefModal.current = null
              }, 0)
              setOldImage(false)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              imageRef.current = null
            }}
          />
        }
        onCancel={() => {
          setCommentModeData(commentModeDataInit)
          setStatusRequestNeeded(false)
          if (timeoutRefModal.current) {
            clearTimeout(timeoutRefModal.current)
          }
          timeoutRefModal.current = setTimeout(() => {
            setModal({ visible: false, link: "" })
            timeoutRefModal.current = null
          }, 0)
          setOldImage(false)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          imageRef.current = null
        }}
        maskClosable={true}
        destroyOnClose
        width={1080}
      >
        <div className="GuidebookImageWithCommentContainer">
          <div
            className={`GuidebookImageWithCommentContainerClickable ${
              modal.isVideo
                ? "GuidebookImageWithCommentContainerClickableVideo"
                : ""
            }`}
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            ref={imageRef}
          >
            {modal?.isVideo ? (
              <video controls>
                <source src={modal.link} type="video/mp4" />
              </video>
            ) : (
              <Image
                preview={false}
                onLoad={e => {
                  setDimensions({
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    top: e.target.clientHeight,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    left: e.target.clientWidth
                  })
                }}
                src={oldImage ? modal.old : modal.link}
                className={`GuidebookModalImage ${
                  commentModeData.activeTooltip
                    ? "GuidebookModalImage_Active"
                    : ""
                }`}
                onClick={e => {
                  setEditCommentData({
                    text: "",
                    active: false
                  })
                  if (commentModeData.activeTooltip) {
                    setCommentModeData({
                      ...commentModeData,
                      position: {
                        top: Math.floor(
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          (e.nativeEvent.layerY / dimensions.top) * 100
                        ),
                        left: Math.floor(
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          (e.nativeEvent.layerX / dimensions.left) * 100
                        )
                      },
                      activeTooltip: false
                    })
                    if (timeoutRef.current) {
                      clearTimeout(timeoutRef.current)
                    }
                    timeoutRef.current = setTimeout(() => {
                      setCommentModeData(prev => ({
                        ...prev,
                        activeTooltip: true
                      }))
                      timeoutRef.current = null
                    }, 0)
                  }
                }}
              />
            )}
            {modal?.commentPosition && (
              <div
                className={"GuidebookAttachModalImageCommentWrapper"}
                style={{
                  top: `${dimensions.top *
                    (modal.commentPosition.top / 100)}px`,
                  left: `${dimensions.left *
                    (modal.commentPosition.left / 100)}px`
                }}
              >
                <TooltipComponent
                  trigger={"click"}
                  title={
                    <div className="GuidebookAttachModalImageComment">
                      {modal.commentText}
                      {modal?.commentDate && (
                        <div className="GuidebookPhotoDateTime CaptionText">
                          <div>
                            {moment(modal.commentDate).format(
                              textConstants.dateFormat
                            )}
                          </div>
                          <div>
                            {moment(modal.commentDate).format(
                              textConstants.timeFormat
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  }
                  className="GuidebookAttachModalImageCommentTooltip"
                  autoAdjustOverflow
                >
                  <div className="GuidebookAttachModalImageCommentPoint" />
                </TooltipComponent>
              </div>
            )}
            {!oldImage &&
              modal.comments?.map(comment => (
                <div
                  className={"GuidebookAttachModalImageCommentWrapper"}
                  style={{
                    top: `${
                      comment?.position
                        ? dimensions.top * (comment?.position.top / 100)
                        : 0
                    }px`,
                    left: `${
                      comment.position
                        ? dimensions.left * (comment?.position.left / 100)
                        : 0
                    }px`
                  }}
                  key={`${comment.text}` + `${comment.date}`}
                >
                  <TooltipComponent
                    trigger={"click"}
                    title={
                      editCommentData.active ? (
                        <div className="GuidebookAttachModalImageComment">
                          <div className="GuidebookAttachModalAddCommentContainer">
                            <InputTextAreaComponent
                              value={editCommentData.text}
                              onChange={e =>
                                setEditCommentData({
                                  ...editCommentData,
                                  text: e.target.value
                                })
                              }
                            />
                            <div className="GuidebookAttachModalSendIconContainer">
                              <SendIcon
                                onClick={() =>
                                  editComment().then(({ data }) => {
                                    setCardState(prev => {
                                      const foundedGroupIdx = cardState.data.info.findIndex(
                                        (el: GuidebookCardInfo) =>
                                          el.id === data.section
                                      )
                                      let foundedAttachIdx = -1
                                      let next = cardState
                                      if (foundedGroupIdx !== -1) {
                                        foundedAttachIdx = cardState.data.info[
                                          foundedGroupIdx
                                        ]?.attachments.findIndex(
                                          el => el.id === data.attachment
                                        )
                                      }
                                      if (foundedAttachIdx !== -1) {
                                        const foundedCommentIdx = cardState.data.info[
                                          foundedGroupIdx
                                        ].attachments[
                                          foundedAttachIdx
                                        ].comments.findIndex(
                                          comment =>
                                            comment.id === currentCommentId
                                        )
                                        if (foundedCommentIdx !== -1) {
                                          next = produce(prev, draft => {
                                            draft.data.info[
                                              foundedGroupIdx
                                            ].attachments[
                                              foundedAttachIdx
                                            ].comments[foundedCommentIdx] = data
                                          })
                                        }
                                      }
                                      return next
                                    })
                                    setEditCommentData(editCommentInitial)
                                    if (timeoutRef.current) {
                                      clearTimeout(timeoutRef.current)
                                    }
                                    setCommentModeData(prev => ({
                                      ...prev,
                                      activeTooltip: false,
                                      activeButton: false
                                    }))
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="GuidebookAttachModalImageComment">
                          <div className="GuidebookAttachModalImageCommentSubWrapper">
                            <div>
                              <div>{comment.text}</div>
                              {comment?.date && (
                                <div className="GuidebookPhotoDateTime CaptionText">
                                  <div>
                                    {moment(comment.date).format(
                                      textConstants.dateFormat
                                    )}
                                  </div>
                                  <div>
                                    {moment(comment.date).format(
                                      textConstants.timeFormat
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            {isAdmin && (
                              <Popover
                                trigger={"click"}
                                content={
                                  <div>
                                    <div className="ProposalPopoverWrapper">
                                      <div
                                        onClick={e => {
                                          e.stopPropagation()
                                          setEditCommentData(prev => ({
                                            ...prev,
                                            active: true,
                                            text: comment.text,
                                            position: comment.position
                                          }))
                                        }}
                                        className="ProposalPopoverButton"
                                      >
                                        <EditButtonIcon />
                                        <div className="ProposalPopoverButtonText">
                                          Редактировать
                                        </div>
                                      </div>
                                      <div
                                        onClick={e => {
                                          e.stopPropagation()
                                          deleteComment().then(() => {
                                            setCardState(prev => {
                                              const foundedGroupIdx = cardState.data.info.findIndex(
                                                (el: GuidebookCardInfo) =>
                                                  el.id === currentSection
                                              )
                                              let foundedAttachIdx = -1
                                              let next = cardState
                                              if (foundedGroupIdx !== -1) {
                                                foundedAttachIdx = cardState.data.info[
                                                  foundedGroupIdx
                                                ]?.attachments.findIndex(
                                                  el => el.id === modal.attachId
                                                )
                                              }
                                              if (foundedAttachIdx !== -1) {
                                                next = produce(prev, draft => {
                                                  const nextComments = draft.data.info[
                                                    foundedGroupIdx
                                                  ]?.attachments[
                                                    foundedAttachIdx
                                                  ].comments.filter(
                                                    el =>
                                                      el.id !== currentCommentId
                                                  )
                                                  draft.data.info[
                                                    foundedGroupIdx
                                                  ].attachments[
                                                    foundedAttachIdx
                                                  ].comments = nextComments
                                                })
                                              }
                                              return next
                                            })
                                            if (timeoutRef.current) {
                                              clearTimeout(timeoutRef.current)
                                            }
                                            setCommentModeData(prev => ({
                                              ...prev,
                                              activeTooltip: false,
                                              activeButton: false
                                            }))
                                          })
                                        }}
                                        className="ProposalPopoverButton"
                                      >
                                        <DeleteButtonIcon />
                                        <div className="ProposalPopoverButtonText">
                                          Удалить
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              >
                                <DetailsIcon className="GuidebookDetailsIcon" />
                              </Popover>
                            )}
                          </div>
                        </div>
                      )
                    }
                    className="GuidebookAttachModalImageCommentTooltip"
                  >
                    <div
                      className="GuidebookAttachModalImageCommentPoint"
                      onClick={() => {
                        setCurrentCommentId(comment?.commentId)
                        setCurrentSection(comment?.sectionId)
                      }}
                    />
                  </TooltipComponent>
                </div>
              ))}
            {commentModeData.activeTooltip &&
              commentModeData.position.top >= 0 &&
              commentModeData.position.top <= 98 &&
              commentModeData.position.left >= 0 &&
              commentModeData.position.left <= 98 && (
                <div
                  className={"GuidebookAttachModalImageCommentWrapper"}
                  style={{
                    top: `${dimensions.top *
                      (commentModeData.position.top / 100)}px`,
                    left: `${dimensions.left *
                      (commentModeData.position.left / 100)}px`
                  }}
                >
                  <TooltipComponent
                    trigger={"click"}
                    title={
                      <div className="GuidebookAttachModalImageComment">
                        <div className="GuidebookAttachModalAddCommentContainer">
                          <InputTextAreaComponent
                            onChange={e =>
                              setCommentModeData({
                                ...commentModeData,
                                text: e.target.value
                              })
                            }
                          />
                          <div className="GuidebookAttachModalSendIconContainer">
                            <SendIcon
                              onClick={() =>
                                addComment().then(({ data }) => {
                                  setCardState(prev => {
                                    const foundedGroupIdx = cardState.data.info.findIndex(
                                      (el: GuidebookCardInfo) =>
                                        el.id === data.section
                                    )
                                    let foundedAttachIdx = -1
                                    let next = cardState
                                    if (foundedGroupIdx !== -1) {
                                      foundedAttachIdx = cardState.data.info[
                                        foundedGroupIdx
                                      ]?.attachments.findIndex(
                                        el => el.id === data.attachment
                                      )
                                    }
                                    if (foundedAttachIdx !== -1) {
                                      next = produce(prev, draft => {
                                        draft.data.info[
                                          foundedGroupIdx
                                        ]?.attachments[
                                          foundedAttachIdx
                                        ].comments.push(data)
                                      })
                                    }
                                    return next
                                  })
                                  if (timeoutRef.current) {
                                    clearTimeout(timeoutRef.current)
                                  }
                                  setCommentModeData(prev => ({
                                    ...prev,
                                    activeTooltip: false,
                                    activeButton: false
                                  }))
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    }
                    className="GuidebookAttachModalImageCommentTooltip"
                    visible={commentModeData.activeTooltip}
                    destroyTooltipOnHide
                  >
                    <div className="GuidebookAttachModalImageCommentPoint" />
                  </TooltipComponent>
                </div>
              )}
          </div>
        </div>

        <div className="GuidebookAttachModalContainerRoot">
          <div>
            <div className="GuidebookAttachModalContainerTop">
              <div className="GuidebookCommentTitle BodySecondText">
                <div>{modal.rootTitle}:</div>
                {!modal.isVideo && (
                  <div className="GuidebookCommentTitleIndex">
                    {modal?.isExample
                      ? modal.exampleText
                      : `${textConstants.photoNumber} ${modal.index}`}
                  </div>
                )}
              </div>
              {modal.uplStatus && (
                <SelectComponent
                  allowClear={false}
                  value={statusConfig[modal.uplStatus]?.title}
                  className={`GuidebookModalSelect GuidebookModalSelect_${modal.uplStatus}`}
                  disabled={!isAdmin}
                  showArrow={isAdmin}
                  onChange={val => {
                    setStatusData(val)
                    setStatusRequestNeeded(true)
                  }}
                >
                  {modalSelectOptions.map(option => (
                    <CustomOption
                      value={option.value}
                      key={option.label + option.value}
                      className={`CaptionTextBold GuidebookModalSelectDropDown GuidebookModalSelectDropDown_${option.value}`}
                    >
                      {option.label}
                    </CustomOption>
                  ))}
                </SelectComponent>
              )}
              {isAdmin && !modal.isExample && modal?.old && (
                <div className="GuideBookSwitchPhotoWrapper">
                  <div
                    className={`GuideBookSwitchPhotoText ${
                      oldImage ? "GuideBookSwitchPhotoTextInactive" : ""
                    }`}
                  >
                    {textConstants.newPhoto}
                  </div>
                  <SwitchComponents
                    label={""}
                    checked={oldImage}
                    onChange={checked => {
                      setOldImage(checked)
                    }}
                  />
                  <div
                    className={`GuideBookSwitchPhotoText ${
                      oldImage ? "" : "GuideBookSwitchPhotoTextInactive"
                    }`}
                  >
                    {textConstants.oldPhoto}
                  </div>
                </div>
              )}
            </div>
            {!modal.isExample && modal?.uploadDate && (
              <div className="GuidebookPhotoDateTime CaptionText">
                <div>
                  {textConstants.dateUpload}{" "}
                  {moment(modal.uploadDate).format(textConstants.dateFormat)}
                </div>
                <div>
                  {moment(modal.uploadDate).format(textConstants.timeFormat)}
                </div>
              </div>
            )}
          </div>
          <div className="GuidebookCommentsIconContainer">
            <div
              className="GuidebookCommentIconContainer"
              onClick={() => {
                const baseUrl = process.env.REACT_APP_AUTH_URL?.replace(
                  "/api/site",
                  ""
                )
                const fileUrl = `${baseUrl}${modal.link}`
                const link = document.createElement("a")
                link.href = fileUrl
                link.download = "image.jpg"
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }}
            >
              <DownloadIcon />
            </div>
            {isAdmin &&
              !modal?.isVideo &&
              !isMobile &&
              !oldImage &&
              !modal.isExample && (
                <div
                  className={`GuidebookCommentIconContainer ${
                    commentModeData.activeButton
                      ? "GuidebookCommentIconContainer_Active"
                      : ""
                  }`}
                  onClick={() => {
                    setCommentModeData({
                      ...commentModeDataInit,
                      activeButton: !commentModeData.activeButton,
                      activeTooltip: !commentModeData.activeButton
                    })
                  }}
                >
                  <CommentIcon className="GuidebookCommentIcon" />
                </div>
              )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

const GuidebookWithStore = () => {
  return (
    <Provider store={main}>
      <Guidebook />
    </Provider>
  )
}

export default GuidebookWithStore
