import React from "react"
import { useSelector } from "react-redux"

import { getTransferHalls } from "store/dictionary/selector"
import { useRequestParams } from "api/hooks"

import { GuestFiltersData } from "../types"

import BetsFilter from "./BetsFilter"
import CashboxFilter from "./CashboxFilter"
import BalanceFilter from "./BalanceFilter"
import TransferFilter from "./TansferFilter"

export interface FilterProps {
  filter: GuestFiltersData
  setFilter: (val: GuestFiltersData) => void
}

const CustomFilters = ({ filter, setFilter }: FilterProps) => {
  const transferHalls = useSelector(getTransferHalls)
  const { current_hall_number } = useRequestParams()

  const showTransfer = transferHalls?.includes(current_hall_number[0])
  return (
    <div className="PaddingTop24">
      <BetsFilter filter={filter} setFilter={setFilter} />
      <CashboxFilter filter={filter} setFilter={setFilter} />
      <BalanceFilter filter={filter} setFilter={setFilter} />
      {!!showTransfer && (
        <TransferFilter filter={filter} setFilter={setFilter} />
      )}
    </div>
  )
}

export default CustomFilters
