import React, { useState, useRef } from "react"
import { useSelector } from "react-redux"

import { Upload } from "antd"
import { UploadFile, UploadProps } from "antd/es/upload/interface"

import { isEmpty } from "lodash"

import { ReactComponent as NewIcon } from "./icons/new.svg"

import { InitialUpload, UploaderComponentProps } from "./types"
import { uploadConstants } from "./constants"
import {
  beforeUpload,
  calculateStep,
  Chunk,
  dummyRequest,
  recalculatAllFiles
} from "./helper"

import UploaderAttach from "./UploaderAttach"

import { getList } from "../../store/main"
import { statusBadge } from "../constants"
import { GuidebookStatuses } from "../../types"

const UploaderComponent = ({
  large,
  initial,
  isEditMode,
  removeReq,
  changeReq,
  addReq,
  sectionId,
  onAttachClick,
  isVideoSection,
  uploadVideo
}: UploaderComponentProps) => {
  const list = useSelector(getList)

  const info = list.info[sectionId]
  const attachments = info.attachments

  // eslint-disable-next-line
  const [notificationsArr, setNotificationsArr] = useState<
    [{ uid: string; text: string }] | []
  >([])

  const [loading, setLoading] = useState<{
    [uid: string]: { step: number; progress: number }
  }>({})
  const [newFileByUid, setNewFileId] = useState<{ [uid: string]: number }>({})

  const [fileList, setFileList] = useState<UploadFile[]>(initial)
  const [changeInfo, setChangeInfo] = useState<{
    uid: string
    changeNeeded: boolean
    id?: number
  }>({
    uid: "",
    changeNeeded: false
  })

  // eslint-disable-next-line
  const [isMultiple, setIsMultiple] = useState(false)

  const [isChangeClicked, setIsChangeClicked] = useState(false)

  const enoughAttach = fileList.length > 0 && fileList.length >= info.maxCount

  const uploadRef = useRef<HTMLDivElement | null>()

  const handleChange: UploadProps["onChange"] = ({
    file,
    fileList: newFileList
  }) => {
    if (file.status === "uploading") {
      setLoading({ ...loading, [file.uid]: { step: 0, progress: 1 } })
      if (changeInfo.changeNeeded) {
        const checkedFile = beforeUpload(
          file,
          fileList,
          isVideoSection,
          setNotificationsArr
        )

        if (checkedFile) {
          const foundedIndex = fileList.findIndex(
            el => el.uid === changeInfo.uid
          )
          if (foundedIndex !== -1) {
            setFileList(prev => {
              prev[foundedIndex] = file
              return [...prev]
            })
          }
        } else {
          setLoading({})
        }
      } else {
        const checkedList = newFileList.filter(file => {
          return beforeUpload(
            file,
            fileList,
            isVideoSection,
            setNotificationsArr
          )
        })
        if (checkedList.length <= 0) {
          setLoading({})
        }
        setFileList([...fileList, ...checkedList])
      }
    }

    if (file.status === "done") {
      const isVideo = file.type?.includes(`video`)
      if (isVideo) {
        const chunk = new Chunk({
          name: "file", // request name
          size: 10485760, // chunk size
          url: `/v1/hall/guidebook/${101}/${sectionId}/video`, // destination
          request: uploadVideo,
          setLoading: setLoading,
          setNewFileId: setNewFileId,
          uid: file.uid
        })

        chunk.setFile(file.originFileObj)

        chunk.commit()
      } else {
        const loadStep = calculateStep(file.size)
        setLoading({
          ...loading,
          [file.uid]: { step: loadStep, progress: 1 }
        })
        let loadValue = 1
        const interval = setInterval(() => {
          loadValue = loadValue + loadStep
          setLoading(prev => ({
            ...prev,
            [file.uid]: { step: loadStep, progress: loadValue }
          }))
          if (loadValue + loadStep > 99) {
            clearInterval(interval)
          }
        }, 100)
        const typeFile = { ...file, base64: undefined }
        const reader = new FileReader()
        reader.onload = e => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          typeFile.base64 = e.target?.result
          if (!!e.target?.result && typeof e.target?.result === `string`) {
            const base: string = e.target?.result
            const baseWithoutType = base.substring(base.indexOf(`;`) + 1)
            if (changeInfo.changeNeeded) {
              changeReq(changeInfo.id as number, {
                uri: baseWithoutType,
                name: file.name,
                type: file.type as string
              }).then(response => {
                clearInterval(interval)
                const newData = recalculatAllFiles(newFileList, file)
                setLoading(prev => ({
                  ...prev,
                  ...newData
                }))
                setNewFileId(prev => ({
                  ...prev,
                  [file.uid]: response.data.id
                }))

                const currentUid = file.uid
                const lastFileUid = newFileList[newFileList.length - 1]?.uid
                if (lastFileUid === currentUid) {
                  setLoading({})
                }
              })
              setChangeInfo({
                uid: "",
                changeNeeded: false
              })
            } else {
              addReq({ uri: baseWithoutType, name: file.name }).then(
                response => {
                  clearInterval(interval)
                  const newData = recalculatAllFiles(newFileList, file)
                  setLoading(prev => ({
                    ...prev,
                    ...newData
                  }))
                  setNewFileId(prev => ({
                    ...prev,
                    [file.uid]: response.data.id
                  }))

                  const currentUid = file.uid
                  const lastFileUid = newFileList[newFileList.length - 1]?.uid
                  if (lastFileUid === currentUid) {
                    setLoading({})
                  }
                }
              )
            }
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reader.readAsDataURL(typeFile?.originFileObj)
      }
    }
  }

  const uploadButton = (
    <div
      className={`${large ? `UploadsNewLarge` : `UploadsNewSmall`} ${
        enoughAttach ? `UploadsNewHide` : ``
      } FlexColumn UploadsNew`}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={uploadRef}
      onClick={() => {
        if (isChangeClicked) {
          setIsMultiple(true)
          setIsChangeClicked(false)
        } else {
          setIsMultiple(!isVideoSection)
        }
      }}
    >
      <NewIcon />
      <span className="UploadsNewText">
        {isVideoSection
          ? uploadConstants.newTextVideo
          : uploadConstants.newText}
      </span>
    </div>
  )

  return (
    <Upload
      listType="picture-card"
      className={`UploadWrapper ${
        enoughAttach ? `UploadsNewHideWrapper` : ``
      } ${isEditMode ? `` : `UploadViewMode`} ${
        large && isEmpty(loading) ? `UploadViewLargeMode` : ``
      }`}
      showUploadList={{ showDownloadIcon: true }}
      multiple={isChangeClicked ? false : !isVideoSection}
      customRequest={dummyRequest}
      onChange={handleChange}
      fileList={fileList}
      maxCount={info.maxCount}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      itemRender={(originNode, file: InitialUpload) => {
        const updateProgress = loading[file.uid]?.progress
        const fileId = !!file.id ? file.id : newFileByUid[file.uid]

        const status =
          fileId && attachments[fileId]
            ? statusBadge[attachments[fileId].status]
            : null

        let showChangeIcon = false
        let showDeleteIcon = false
        if (fileId) {
          showChangeIcon =
            attachments[fileId]?.status === GuidebookStatuses.rejected
          showDeleteIcon =
            attachments[fileId]?.status !== GuidebookStatuses.rejected
        }

        const isVideo = file.type?.includes(`video`)
        const haveVideoBadge =
          file.type?.includes(`video`) || !!file.isVideoSection
        return (
          <div>
            <UploaderAttach
              video={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isVideo ? URL.createObjectURL(file.originFileObj) : undefined
              }
              haveVideoBadge={haveVideoBadge}
              uid={file.uid}
              old={file.old}
              link={file.thumbUrl}
              isEditMode={isEditMode}
              deleteItem={e => {
                e.stopPropagation()
                removeReq(fileId).then(() => {
                  const newList = fileList.filter(item => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    const itemId = !!item.id ? item.id : newFileByUid[item.uid]
                    return itemId !== fileId
                  })
                  setFileList(newList)
                })
              }}
              changeItem={e => {
                e.stopPropagation()
                setIsChangeClicked(true)
                setTimeout(() => {
                  uploadRef?.current?.click()
                  setChangeInfo({
                    uid: file.uid,
                    changeNeeded: true,
                    id: file.id || newFileByUid[file.uid]
                  })
                }, 0)
              }}
              onAttachClicked={() => {
                const founded = initial.find(el => el.id === fileId)
                onAttachClick &&
                  onAttachClick({
                    index: file?.index || founded?.index,
                    rootTitle: file?.rootTitle || founded?.rootTitle,
                    uploadDate: file.uploadDate || founded?.uploadDate,
                    uplStatus:
                      (attachments[fileId]?.status as GuidebookStatuses) ||
                      founded?.uplStatus,
                    link: file?.url || founded?.url,
                    attachId: file?.id || founded?.id,
                    isVideo: file.isVideoSection || founded?.isVideoSection,
                    old: file.old || founded?.old
                  })
              }}
              updateProgress={updateProgress}
              statusBadge={status}
              showChangeIcon={showChangeIcon}
              showDeleteIcon={showDeleteIcon}
            />
          </div>
        )
      }}
    >
      {uploadButton}
    </Upload>
  )
}

export default UploaderComponent
