import { combineReducers, createStore } from "redux"
// auth
import { authReducer } from "./auth/reducer"
import { IUSERState } from "./auth/types"
// developer
import { IDevelopState } from "./developer/types"
import { developerReducer } from "./developer/reducer"
// halls
import { hallsReducer } from "./halls/reducer"
import { IHallsState } from "./halls/types"
// message
import { messageReducer } from "./message/reducer"
import { MessageStoreState } from "./message/types"
// dictionary
import { dictionaryReducer } from "./dictionary/reducer"
import { IDictionaryState } from "./dictionary/types"
// menu
import { menuReducer } from "./menu/reducer"
import { IMenuState } from "./menu/types"

import { composeWithDevTools } from "redux-devtools-extension"
import { WikiState } from "./wiki/types"
import { menuWikiReducer } from "./wiki/reducer"

// access
import { TemplatesList } from "./access/types"
import { accessReducer } from "./access/reducer"

export interface IRootState {
  auth: IUSERState
  developer: IDevelopState
  halls: IHallsState
  dictionary: IDictionaryState
  menu: IMenuState
  wikiMenu: WikiState
  message: MessageStoreState
  access: TemplatesList
}
const store = createStore<IRootState, any, any, any>(
  combineReducers({
    auth: authReducer,
    developer: developerReducer,
    halls: hallsReducer,
    dictionary: dictionaryReducer,
    menu: menuReducer,
    wikiMenu: menuWikiReducer,
    message: messageReducer,
    access: accessReducer
  }),
  composeWithDevTools()
)

export default store
