import React, { useCallback } from "react"

import SideFilter from "Components/SideFilter"
import { CheckBoxComponent } from "Components/CheckBox"

import { HallFilterProps } from "./types"
import { sideFilterText } from "./helper"

import { MailingFilterData, MailingHallStatus } from "../types"
import { filterConstants, MAILING_HALL_FILTER, statusText } from "../helper"

import { Filters } from "Components/SideFilter/types"

const CustomComponent: React.FC<{
  filter: MailingFilterData
  setFilter: (data: MailingFilterData) => void
}> = ({ filter, setFilter }) => (
  <div>
    <span className="FilterContentLabel">{filterConstants.status.label}</span>
    <div className="MailingFilterContentStatus FlexColumn">
      {[MailingHallStatus.active, MailingHallStatus.inactive].map(item => (
        <CheckBoxComponent
          key={`status_${item}`}
          checked={filter.status?.includes(item)}
          label={statusText[item]}
          onChange={({ target: { checked } }) => {
            const isActive = item === MailingHallStatus.active
            if (!checked) {
              const haveTwo = filter.status?.length === 2
              setFilter({
                ...filter,
                status: haveTwo
                  ? [
                      isActive
                        ? MailingHallStatus.inactive
                        : MailingHallStatus.active
                    ]
                  : []
              })
            } else {
              setFilter({
                ...filter,
                status: filter.status
                  ? [...filter.status, MailingHallStatus[item]]
                  : [MailingHallStatus.active]
              })
            }
          }}
        />
      ))}
    </div>
  </div>
)

const MailingHallFilter = ({
  filterState,
  setFilterState,
  resetPagination
}: HallFilterProps) => {
  const setFilterStateCb = useCallback(value => setFilterState(value), [])

  return (
    <div>
      <SideFilter
        filterName={MAILING_HALL_FILTER}
        filterState={filterState}
        filterText={sideFilterText}
        setFilterState={setFilterStateCb}
        resetPagination={resetPagination}
        filters={[Filters.hallPicker, Filters.customItem, Filters.searchInput]}
        CustomComponent={CustomComponent}
        getDisabled={filter =>
          !filter?.halls?.length || !filter?.status?.length
        }
      />
    </div>
  )
}

export default MailingHallFilter
