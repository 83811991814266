import React from "react"

import AllIcons from "./icons/index"

export const defaultDescriptions = {
  today: `За сегодня`,
  current_period: `За выбранный период`,
  now: `На текущий момент`
}

export enum DashboardPrefixOptions {
  RUB = `RUB`,
  BON = `BON`,
  FREE = `FREE`,
  PER = `PER`
}

export const defaultMoreText = {
  more: `Подробнее`,
  specify: `Уточнить`
}

export const prefixOptions = {
  [DashboardPrefixOptions.RUB]: `₽`,
  [DashboardPrefixOptions.BON]: `Б`,
  [DashboardPrefixOptions.FREE]: `Ф`,
  [DashboardPrefixOptions.PER]: `%`
}

export enum DashboardOptions {
  balance_payout = `balance_payout`,
  money_balance = `money_balance`,
  bonus_balance = `bonus_balance`,
  cashbox_result = `cashbox_result`,
  freebet_amount = `freebet_amount`,
  freebet_sum = `freebet_sum`,
  cashback_percent = `cashback_percent`,
  guest_status = `guest_status`,
  last_superwin = `last_superwin`,
  levelup_status = `levelup_status`,
  new_registrations = `new_registrations`,
  new_registrations_orange = `new_registrations_orange`,
  save_bonuses = `save_bonuses`,
  uniq_registrations = `uniq_registrations`,
  upcoming_bonuses = `upcoming_bonuses`,
  visit_count = `visit_count`,
  wallet_amount = `wallet_amount`,
  guests_with_bonuses = `guests_with_bonuses`,
  bonuses_sum = `bonuses_sum`,
  cards_amount = `cards_amount`,
  cards_active = `cards_active`,
  super_win = `super_win`
}

export const configByOption = {
  [DashboardOptions.balance_payout]: {
    title: `Баланс \nдля выплат`,
    color: `#FFE8CC`,
    icon: <AllIcons.BalancePayoutIcon />
  },
  [DashboardOptions.money_balance]: {
    title: `Денежный \nбаланс`,
    color: `#E9FAC8`,
    icon: <AllIcons.MoneyBalanceIcon />
  },
  [DashboardOptions.bonus_balance]: {
    title: `Бонусный \nбаланс`,
    color: `#D3F9D8`,
    icon: <AllIcons.BonusBalanceIcon />
  },
  [DashboardOptions.cashbox_result]: {
    title: `Кассовый \nрезультат`,
    color: `#F3D9FA`,
    icon: <AllIcons.CashboxResultIcon />
  },
  [DashboardOptions.freebet_amount]: {
    title: `Количество \nфрибетов`,
    color: `#F3D9FA`,
    icon: <AllIcons.FreebetAmountIcon />
  },
  [DashboardOptions.freebet_sum]: {
    title: `Сумма фрибет \nбалансов`,
    color: `#FFF3BF`,
    icon: <AllIcons.FreebetSumIcon />
  },
  [DashboardOptions.cashback_percent]: {
    title: `Процент \nкэшбэка`,
    color: `#FFDEEB`,
    icon: <AllIcons.CashboxPercentIcon />
  },
  [DashboardOptions.guest_status]: {
    title: `Статус \nгостя`,
    color: `#FFF3BF`,
    icon: <AllIcons.GuestStatusIcon />
  },
  [DashboardOptions.last_superwin]: {
    title: `Последние \nсупервыигрыши`,
    color: `#E5DBFF`,
    icon: <AllIcons.LastSuperwinIcon />
  },
  [DashboardOptions.levelup_status]: {
    title: `Условия повы-\nшения статуса`,
    color: `#D0EBFF`,
    icon: <AllIcons.LevelupStatusIcon />
  },
  [DashboardOptions.new_registrations]: {
    title: `Новые \nрегистрации`,
    color: `#DBE4FF`,
    icon: <AllIcons.NewRegistrationsIcon />
  },
  [DashboardOptions.new_registrations_orange]: {
    title: `Новые \nрегистрации`,
    color: `#FFE8CC`,
    icon: <AllIcons.NewRegistrationsOrange />
  },
  [DashboardOptions.save_bonuses]: {
    title: `Накопленные \nбонусы`,
    color: `#C5F6FA`,
    icon: <AllIcons.SaveBonusesIcon />
  },
  [DashboardOptions.uniq_registrations]: {
    title: `Уникальные \nрегистрации`,
    color: `#D3F9D8`,
    icon: <AllIcons.UniqRegistrationsIcon />
  },
  [DashboardOptions.upcoming_bonuses]: {
    title: `Начисленные \nбонусы`,
    color: `#E5DBFF`,
    icon: <AllIcons.UpcomingBonusesIcon />
  },
  [DashboardOptions.visit_count]: {
    title: `Количество \nпосещений`,
    color: `#D0EBFF`,
    icon: <AllIcons.VisitCountIcon />
  },
  [DashboardOptions.wallet_amount]: {
    title: `Количество \nкошельков`,
    color: `#FFE8CC`,
    icon: <AllIcons.WalletsAmountIcon />
  },
  [DashboardOptions.guests_with_bonuses]: {
    title: `Кол-во гостей,\nполучивших бонус`,
    color: `#E5DBFF`,
    icon: <AllIcons.GuestsWithBonusesIcon />
  },
  [DashboardOptions.bonuses_sum]: {
    title: `Сумма\nполученных бонусов...`,
    color: `#C5F6FA`,
    icon: <AllIcons.BonusesSumIcon />
  },
  [DashboardOptions.cards_amount]: {
    title: `Карт\nполучено`,
    color: `#FFF3BF`,
    icon: <AllIcons.CardsAmountIcon />
  },
  [DashboardOptions.cards_active]: {
    title: `Карт\nактивировано`,
    color: `#FFE8CC`,
    icon: <AllIcons.CardsActiveIcon />
  },
  [DashboardOptions.super_win]: {
    title: `Супервыигрыш`,
    color: `#C5F6FA`,
    icon: <AllIcons.TennisFightOneIcon />
  }
}
