import React from "react"
import InputComponent from "Components/Input"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"

import { AccessFiltersData } from "../types"

import { customFiltersConstants } from "./constants"

const AccessSearchFilter = ({
  filter,
  setFilter
}: {
  filter: AccessFiltersData
  setFilter: (data: AccessFiltersData) => void
}) => {
  return (
    <div className="FlexColumn Gap8 PaddingBottom16">
      <span className="SubtitleFirstText">
        {customFiltersConstants.search.label}
      </span>
      <InputComponent
        value={filter.search}
        suffix={<SearchIcon />}
        placeholder={customFiltersConstants.search.placeholder}
        onChange={({ target: { value } }) =>
          setFilter({ ...filter, search: value })
        }
        allowClear={true}
      />
    </div>
  )
}

export default AccessSearchFilter
