import React from "react"

import { Moment } from "moment"

import { SortOrders } from "../Table/types"
import { StatusesCheckBoxFilterText } from "./Components/StatusesCheckBoxFilter/types"
import { RangePickerFilterText } from "./Components/RangePickerFilter/types"
import { SearchInputFilterText } from "./Components/SearchInputFilter/types"

import { PeriodOptionsData } from "../FilterInfoPanel/types"

export type Statuses = number[]

export interface DefaultFilterProps {
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
}

export interface StatusItem {
  id: number
  name: string
}

export type StatusesDict = [StatusItem] | []

export interface SideFilterDates {
  from: Moment
  to: Moment
}

export interface SearchInputValueObj {
  field: string
  value?: string
}

export interface SideFilterData {
  statuses?: Statuses
  dates?: SideFilterDates
  phone?: string
  hall?: number
  halls?: number[]
  search?: string
  games?: number[]
  isShift?: boolean
  shift?: [number, number]
  sort?: {
    field: string
    order: SortOrders
  }
  [key: string]: unknown
}

export interface SideFilterState {
  isOpen: boolean
  isActive: boolean
  data: SideFilterData
  mobileFilter: boolean
  searchAsObj?: boolean
}

export enum Filters {
  statusesCheckBox = "StatusesCheckBox",
  rangePicker = "RangePicker",
  searchInput = "SearchInput",
  hallPicker = "HallPicker",
  hallPickerRadio = "HallPickerRadio",
  phoneInput = "PhoneInput",
  gamesPicker = "GamesPicker",
  gamesSportPicker = "GamesSportPicker",
  shiftSwitch = "ShiftSwitch",
  customItem = "CustomItem",
  addCustomItem = "AdditionalCustomItem",
  InfoPanel = "InfoPanel",
  InfoPanelHalls = "InfoPanelHalls"
}

export interface FilterText {
  header?: string
  applyButton?: string
  reset?: string
  statusesCheckBoxFilter?: StatusesCheckBoxFilterText
  rangePickerFilter?: RangePickerFilterText
  searchInputFilter?: SearchInputFilterText

  labels?: {
    [key: string]: string
  }
}

export type CustomComponentProps = {
  filter: any
  setFilter: (data: any) => void
  forceApply?: (outsideFilter?: SideFilterState | any) => void
}

export type FilterHookProps = {
  filterState: SideFilterState | any
  setFilterState: (value: SideFilterState | any) => void
}

export interface SideFilterProps {
  filterName: string
  filterText?: FilterText
  filterState: SideFilterState | any
  initialForClear?: SideFilterData | any
  statusesDict?: StatusesDict
  forceClear?: number
  setFilterState: React.Dispatch<React.SetStateAction<SideFilterState>>
  resetPagination: () => void
  filters: Filters[]
  filterLabels?: { [key: string]: string }
  CustomInfoPanelHalls?: React.FC<CustomComponentProps>
  AdditionalCustomComponent?: React.FC<CustomComponentProps>
  CustomComponent?: React.FC<CustomComponentProps>
  getDisabled?: (filter: any) => boolean
  infoPanelConfig?: {
    disabledShift?: boolean
    clearRange?: boolean
    gmtSelect?: boolean
    alwaysMoscow?: boolean
    withoutGmt?: boolean
    withoutPeriod?: boolean
    shiftSlider?: boolean
  }
  filterConfig?: {
    clearRange?: boolean
    searchNumber?: boolean
    searchFilterValidator?: (val: string) => boolean
  }
  rangeTooltip?: string
  additionClearDeps?: (filter: any) => boolean
  disabledRangeDates?: (currentDate: Moment | null) => boolean
  customFilterKeys?: { [K in Filters]?: string }
  isLoading?: boolean
  disabledHalls?: boolean
  periodOptions?: PeriodOptionsData[]
}
