import React, { useCallback, useEffect, useState, useMemo } from "react"

import { getPaginationDefault, useRequest } from "api/hooks"
import { isMobile } from "constants/index"

import TabsComponent from "Components/Tabs"
import DataSwitcher from "Components/DataSwitcher"
import { ChartPeriodComponent } from "Components/Chart"

import { ReactComponent as SettingsIcon } from "Components/icons/settings_28.svg"

import {
  initialWalletDetailsData,
  isSpecialRoles,
  sportboomMainText,
  SportBoomTabs,
  tabsConfig
} from "../constants"
import { SportBoomContentProps, WalletDetailsData } from "../types"

import SportboomDetail from "./Detail"
import { detailText } from "./Detail/constants"
import DetailInfoTooltip from "./Detail/DetailInfoTooltip"

import SportBoomChart from "./Chart"
import { ChartPeriod } from "./Chart/constants"

import SportboomTable from "./Table"
import { initialColumnList, initialFilters } from "./Table/constants"
import { TableConfig } from "./Table/Config/types"

import SportboomTableMobile from "./TableMobile"

import ClearFilter from "./Table/Clear"
import TableConfigModal from "./Table/Config"
import { FilterData, GameList } from "./Table/types"
import TableDates from "./TableDate"

import moment, { Moment } from "moment"
import { WalletModal } from "./WalletModal"
import { SideWalletModal } from "./SideWalletModal"

const SportBoomContent = ({ filter }: { filter: SportBoomContentProps }) => {
  // switcher chart <-> table
  const [activeTab, toggleActiveTab] = useState<SportBoomTabs>(
    SportBoomTabs.sport_bets
  )

  // change period for chart
  const [chartPeriod, toggleChartPeriod] = useState<ChartPeriod>(
    ChartPeriod.daily
  )

  // game list for table/detail
  const [gameList, setGameList] = useState<GameList>({
    pending: false,
    data: []
  })

  const [walletDetailsData, setWalletDetailsData] = useState<WalletDetailsData>(
    initialWalletDetailsData
  )

  const { request: getGameList } = useRequest({
    url: `v1/sportboom/filters`,
    method: `GET`
  })

  const { request: getGuestInfo } = useRequest({
    url: `v1/sportboom/guest-info`,
    method: `POST`,
    requestBody: { filter: { wallet_number: walletDetailsData.activeWalletId } }
  })

  const handleWalletClick = useCallback((value: number) => {
    setWalletDetailsData(prev => ({
      ...prev,
      activeWalletId: value,
      isWalletModalActive: true,
      loading: true
    }))
  }, [])

  useEffect(() => {
    if (
      walletDetailsData.activeWalletId &&
      walletDetailsData.isWalletModalActive
    ) {
      getGuestInfo()
        .then(resp => {
          setWalletDetailsData(prev => ({
            ...prev,
            data: resp.data,
            loading: false
          }))
        })
        .catch(() => {
          setWalletDetailsData(prev => ({
            ...prev,
            loading: false
          }))
        })
    }
  }, [walletDetailsData.activeWalletId])

  const handleWalletModalClose = useCallback(() => {
    setWalletDetailsData(initialWalletDetailsData)
  }, [])

  const handleSideWalletModalClose = useCallback(() => {
    setWalletDetailsData(prev => ({ ...prev, isWalletSideModalActive: false }))
  }, [])

  const handleSideWalletModalOpen = useCallback(() => {
    setWalletDetailsData(prev => ({ ...prev, isWalletSideModalActive: true }))
  }, [])

  useEffect(() => {
    setGameList({ ...gameList, pending: true })
    getGameList()
      .then(({ data }) =>
        setGameList({ data: data?.sport_type || [], pending: false })
      )
      .catch(() => setGameList({ ...gameList, pending: false }))
  }, [])

  // content settings toggle - chart period <-> table config/dates/clear filter
  const [contentView, toggleView] = useState(isSpecialRoles())

  // table dates with 31 days restrictions
  const [tableDates, setTableDates] = useState<[Moment, Moment] | undefined>(
    undefined
  )
  // table dates subject to 31 day restrictions
  useEffect(() => {
    const dates = filter.dates
    if (!!dates) {
      const start = dates[0].valueOf()
      const end = dates[1].valueOf()
      const diff = moment(end).diff(moment(start), `days`)
      if (diff > 31) {
        const startMonth = moment(end).subtract(31, `day`)
        setTableDates([moment(startMonth), moment(end)])
      } else {
        setTableDates([moment(start), moment(end)])
      }
    } else {
      setTableDates(undefined)
    }
  }, [filter.dates])

  // table config - visible and order on columns
  const [tableConfig, setTableConfig] = useState<TableConfig>(initialColumnList)
  // table filter - base filter + filter from table header + sort
  const [tableFilter, setTableFilter] = useState<FilterData>(initialFilters)

  // change table filter from chart (by click on column)
  const setDateFromChart = useCallback(
    (dates: { from: string; to: string }) => {
      setTableFilter({
        ...tableFilter,
        table: { ...tableFilter.table, period: dates }
      })
      toggleView(true)
    },
    [tableFilter]
  )

  const tabs = useMemo(
    () => tabsConfig(!!filter.bet_number || !!filter.wallet_number),
    [filter.bet_number, filter.wallet_number]
  )
  return (
    <div>
      <TabsComponent
        tabs={tabs}
        activeTab={activeTab}
        toggleActiveTab={(value: any) => {
          toggleActiveTab(value)
          setTableFilter({ ...tableFilter, pagination: getPaginationDefault() })
        }}
      />
      {!!filter.dates && !filter.bet_number && (
        <div className="SportboomDetailWrapper">
          <div className="FlexRow">
            <div className="HeadlineThirdText SportboomDetailLabel">
              {detailText.title}
            </div>
            <DetailInfoTooltip activeTab={activeTab} filter={filter} />
          </div>
          <SportboomDetail activeTab={activeTab} filter={filter} />
        </div>
      )}
      <div>
        <div className="SportboomContentSwitcherWrapper FlexRow">
          {contentView ? (
            <TableDates
              disabled={!!filter.bet_number || !!filter.wallet_number}
              dates={filter.dates}
              filterDates={tableDates}
              setDates={val => setTableDates(val)}
            />
          ) : (
            <ChartPeriodComponent
              value={chartPeriod}
              setValue={newPeriod => toggleChartPeriod(newPeriod)}
              disabled={contentView}
            />
          )}
          <div
            className={`SportboomContentSwitcherMargin ${
              contentView && isMobile ? `SportboomContentSwitcherHide` : ``
            }`}
          />
          {contentView && !isMobile && (
            <div className="FlexRow">
              <ClearFilter
                tableFilter={tableFilter}
                gameList={gameList.data}
                clear={() => setTableFilter(initialFilters)}
              />
              <div
                className="SportboomContentTableConfig FlexRow"
                onClick={() => setTableConfig({ ...tableConfig, open: true })}
              >
                <SettingsIcon />
                <span className="ButtonTextSecond">
                  {sportboomMainText.tableConfig.title}
                </span>
                {!!tableConfig.hideCount ? (
                  <div className="SportboomContentTableConfigCount ButtonTextSecond">
                    {tableConfig.hideCount}
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          )}
          {tableConfig.open && (
            <TableConfigModal
              config={tableConfig}
              changeConfig={setTableConfig}
              activeTab={activeTab}
            />
          )}
          <DataSwitcher
            activeContent={contentView}
            toggleActiveContent={(view: boolean) => {
              toggleView(view)
              setTableFilter({
                ...tableFilter,
                pagination: getPaginationDefault()
              })
            }}
          />
        </div>
        <div
          className={`SportboomContentWrapper ${
            walletDetailsData.isWalletModalActive &&
            !walletDetailsData.isWalletSideModalActive
              ? "SportboomContentWrapper_modal-opened"
              : ""
          }`}
        >
          {contentView &&
            (isMobile ? (
              <SportboomTableMobile
                onWalletIdClick={handleWalletClick}
                filter={{ ...filter, dates: tableDates }}
                activeTab={activeTab}
              />
            ) : (
              <SportboomTable
                activeTab={activeTab}
                filter={{
                  ...filter,
                  dates: !!filter.wallet_number ? filter.dates : tableDates
                }}
                chartPeriod={chartPeriod}
                tableConfig={tableConfig.data}
                clearConfig={() => setTableConfig(initialColumnList)}
                gameList={gameList}
                tableFilter={tableFilter}
                setTableFilter={setTableFilter}
                toggleActiveTab={toggleActiveTab}
                onWalletIdClick={handleWalletClick}
              />
            ))}
          {!contentView && (
            <SportBoomChart
              setDateFromChart={setDateFromChart}
              activeTab={activeTab}
              filter={filter}
              chartPeriod={chartPeriod}
            />
          )}
          {walletDetailsData.isWalletModalActive &&
            !walletDetailsData.isWalletSideModalActive && (
              <WalletModal
                id={walletDetailsData.activeWalletId}
                onClose={handleWalletModalClose}
                data={walletDetailsData.data?.current}
                onHistoryButtonClick={handleSideWalletModalOpen}
                loading={walletDetailsData.loading}
              />
            )}
          {walletDetailsData.isWalletSideModalActive && (
            <SideWalletModal
              data={walletDetailsData.data?.history}
              onClose={handleSideWalletModalClose}
              id={walletDetailsData.activeWalletId}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SportBoomContent
