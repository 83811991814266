import React, { useCallback, useState } from "react"
import { ActiveTabs, SmsStateProps } from "./types"
import CreateSms from "./CreateSms"
import HistorySms from "./HistorySms"
import { smsStateInitPropsCreator } from "./helper"
import { useRequestParams } from "../../api/hooks"

const SmsComponent = () => {
  const [activeTab, setActiveTab] = useState<ActiveTabs>(ActiveTabs.creating)
  const isCreate = activeTab === ActiveTabs.creating
  const isHistory = activeTab === ActiveTabs.history
  const switchTabCb = useCallback(
    (tab: ActiveTabs) => {
      if (tab !== activeTab) {
        setActiveTab(tab)
      }
    },
    [activeTab]
  )

  const { current_hall } = useRequestParams()

  const smsStateInitProps = smsStateInitPropsCreator(current_hall[0] as number)

  const [smsState, setSmsState] = useState<SmsStateProps>(smsStateInitProps)

  return (
    <div className="MainPageContentWrapper">
      {isCreate && (
        <CreateSms
          smsState={smsState}
          setSmsState={setSmsState}
          activeTab={activeTab}
          setActiveTab={switchTabCb}
        />
      )}
      {isHistory && (
        <HistorySms
          hallId={smsState.currentHall}
          requestStatuses={smsState.smsSettings.requestStatuses}
          activeTab={activeTab}
          setActiveTab={switchTabCb}
        />
      )}
    </div>
  )
}

export default SmsComponent
