import React from "react"

import { getReportTableDateHeader } from "api/helpers"
import { isMobile } from "constants/index"

import NewTableComponent, { expandIcon } from "Components/TableNew"

import InsideContent from "./Inside"
import { GameContentProps } from "./types"

import {
  textConstants,
  columnGames,
  columnSport,
  TABLE_WIDTH
} from "../constant"

const GamesContent = ({
  report,
  pending,
  total,
  filter,
  pagination,
  setPagination
}: GameContentProps) => {
  // add key for table expandable
  const reportWithKey = report.map((item, key) => ({ ...item, key }))
  const columnWithDate = [
    {
      title: getReportTableDateHeader(filter.period),
      width: isMobile ? 90 : 140,
      dataIndex: "date",
      key: "date",
      fixed: "left",
      render: (record: string | null) => (
        <span className="TextDefaultBold CapitalizeText PaddingLeft12">
          {record || textConstants.total}
        </span>
      )
    },
    ...(filter.type === `games` ? columnGames : columnSport)
  ]

  return (
    <div className="ReportTableContentWrapper">
      <NewTableComponent
        pagination={{
          value: pagination,
          total,
          onChange: setPagination
        }}
        loading={pending}
        columns={columnWithDate}
        data={reportWithKey}
        width={TABLE_WIDTH}
        withTotal={true}
        rowClassName="ExpandableTableRow"
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (
            record: any,
            index: any,
            indent: any,
            expanded: any
          ) => (
            <div>
              {expanded && !pending && (
                <InsideContent
                  date={record.date}
                  filter={filter}
                  pending={pending}
                />
              )}
            </div>
          ),
          expandIcon
        }}
      />
    </div>
  )
}
export default GamesContent
