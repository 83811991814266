import moment from "moment"
import { ByGameOrReg } from "./types"
import { isMobile } from "Components/../constants"

export const textConstants = {
  title: `Посещаемость клуба`,
  emptyDataTitle: `Нет данных`,
  emptyDataText: `За необходимый период по данному клубу недостаточно данных для построения аналитики посещаемости`,
  visitCountsTitle: `Кол-во<br>гостей`,
  shiftDefault: 8,
  daysAnaliticsTitle: `Аналитика за:`,
  daysAnaliticsOptions_1: 30,
  daysAnaliticsOptions_2: 90,
  daysAnaliticsOptions_3: 180,
  daysAnaliticsOptionsTitle_1: `За 30 дней`,
  daysAnaliticsOptionsTitle_2: `За 90 дней`,
  daysAnaliticsOptionsTitle_3: `За 180 дней`,
  regCountsTitle: `Кол-во<br>региcтраций`,
  statRegTitle: `Статистика регистраций`,
  visitRegYAxisTitleXVisitPosition: 75,
  visitRegYAxisTitleXRegPosition: 110,
  visitPeaksTitle: `Пиковые часы посещаемости`,
  weekDays: ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"],
  maxHoursDescription: `обычно повышенная загруженность`,
  timeShiftDashboardsTitle: `За сегодня с`,
  timeShiftDashboardsSubTitle: `(Москва)`,
  dashboards: {
    visits: {
      title: `Количество посещений`,
      color: `#E7F5FF`
    },
    new: {
      title: `Новые регистрации`,
      color: `#F3F0FF`
    },
    unique: {
      title: `Уникальные регистрации`,
      color: `#E6FCF5`
    }
  },
  spinColor: `#F8E800`,
  regStat: {
    newTitle: `Новая`,
    uniqueTitle: `Уникальная`
  },
  period: {
    hourly: `hourly`,
    daily: `daily`,
    monthly: `monthly`,
    yearly: `yearly`
  },
  visitsByGames: {
    all: `total`,
    sport: `sport`,
    tennis: `bingo`
  },
  onlyReg: `Только регистрации`,
  visitsType: {
    responseAll: `all`,
    all: `total`,
    new: `new`,
    unique: `unique`
  },
  empty: {
    title: `Чтобы получить отчёт по посещаемости клуба, вам необходимо выбрать нужные параметры фильтрации`,
    description: `Отчёт по денежному обороту клуба. Содержит в себе информацию по играм и ставкам на спорт, а также детализацию по денежным и бонусным начислениям для клуба`
  },
  visitAndRegsUrls: {
    visitUrl: `attendance/visits`,
    regUrl: `attendance/registrations`,
    regStatUrl: `attendance/registrations/statistic`,
    visitHoursUrl: `attendance/visits/hours`,
    dashboards: `attendance/dashboards`
  },
  exelConfig: {
    visitUrl: `attendance/visits/excel`,
    regUrl: `attendance/registrations/excel`
  },
  gmtDefault: `3`,
  tooltipPeople: `чел.`,
  titleRegLastWords: [`регистрация`, `регистрации`, `регистраций`],
  allGuests: `Всего гостей`,
  sportLine: isMobile ? `«Спорт»` : `Линия «Спорт»`,
  tennisLine: isMobile ? `«Теннис»` : `Линия «Теннис»`,
  yearFormat: `YYYY`,
  total: `Всего`,
  new: `Новые`,
  unique: `Уникальные`,
  visitFilterByHourTooltip: `При выборе отчета по часам, график покажет информацию о количестве активных кошельков за каждый час. Графики по дням, месяцам и годам покажут общее количество гостей`,
  visitCountTooltip: `Количество гостей с 8 утра по Москве (по умолчанию) и по текущий момент в данном клубе.
Гостями считаются только те, кто совершал ставки как с денежного, так и с бонусного балансов.
При изменении времени начала периода, в индивидуальном фильтре рядом с дашбордами, относительно выбранных параметров по текущий момент.`,
  newRegTooltip: `Количество гостей, для которых был заведен новый кошелек в данном клубе с 8 утра по Москве (по умолчанию) по текущий момент.
При изменении времени начала периода, в индивидуальном фильтре рядом с дашбордами, относительно выбранных параметров по текущий момент.`,
  uniqueRegTooltip: `Количество гостей, для которых был заведен новый кошелек в данном клубе с 8 утра по Москве (по умолчанию) по текущий момент, который является уникальным для сети клубов BetBoom.
Уникальной регистрация считается при условии, что гость ранее не был зарегистрирован ни в одном из клубов BetBoom.
При изменении времени начала периода, в индивидуальном фильтре рядом с дашбордами, относительно выбранных параметров по текущий момент.`,
  onlyRegTooltip: `Переключатель “Только регистрации”, при переключении которого в активное положение на графике отобразятся данные только по регистрациям в клубе за выбранный в фильтрах период.
Информация на графике также отображается при помощи переключения кнопок "Всего", "Новые" и "Уникальные".

`,
  visitClubTooltip: `Отчет предназначен для предоставления информации как о количестве посещений, так и о количестве регистраций в клубе за необходимый период. График отчета по посещаемости также покажет количество гостей, которые предпочитали совершать ставки только на линию "спорт" или только на линию "теннис".
Параметр "Всего" отображает общее количество гостей. При помощи переключателя "Только регистрации" отобразится график отчета по регистрациям, который покажет как общее количество регистраций, так и отдельно "Новые" для клуба и Уникальные" для клуба и сети BetBoom.
По кнопке "Выгрузить в Exсel" данные из отчета выгрузятся в таблицу Exсel на ПК.
При построении отчета по дням, месяцам или годам, под основным графиком появятся дополнительные - "Статистика регистраций" и "Пиковые часы посещаемости", подробнее о которых можно узнать из информационного всплывающего окошка рядом с графиком.`,
  regStatTooltip: `Отображает количество новых и уникальных регистраций от общего количества количества регистраций в клубе.`,
  peakHoursTooltip: `Отображает среднюю загруженность клуба по часам в каждый день недели за последние месяц, три месяца или пол года от текущей даты (не зависит от периода в фильтрах отчета!).
Изменить период можно, кликнув на значок календаря в правом верхнем углу графика.
При этом, день с наибольшей средней посещаемостью будет обозначен значком "звёздочка" радом с названием дня.`
}

export const getInitialFilterData = (hall: number) => {
  return {
    period: "hourly",
    dates: [moment(), moment()],
    isShift: true,
    shift: [textConstants.shiftDefault, textConstants.shiftDefault],
    type: textConstants.visitsByGames.all,
    hall: hall,
    shiftDashboards: textConstants.shiftDefault
  }
}

export const byGameTypes: ByGameOrReg[] = [
  {
    title: textConstants.allGuests,
    name: textConstants.visitsByGames.all,
    color: "#22B8CF",
    bg: "#E3FAFC"
  },
  {
    title: textConstants.sportLine,
    name: textConstants.visitsByGames.sport,
    color: "#51CF66",
    bg: "#EBFBEE"
  },
  {
    title: textConstants.tennisLine,
    name: textConstants.visitsByGames.tennis,
    color: "#339AF0",
    bg: "#E7F5FF"
  }
]

export const byRegTypes: ByGameOrReg[] = [
  {
    title: textConstants.total,
    name: textConstants.visitsType.all,
    color: "#22B8CF",
    bg: "#E3FAFC"
  },
  {
    title: textConstants.new,
    name: textConstants.visitsType.new,
    color: "#FF922B",
    bg: "#FFF4E6"
  },
  {
    title: textConstants.unique,
    name: textConstants.visitsType.unique,
    color: "#5C7CFA",
    bg: "#EDF2FF"
  }
]

export const visitCalendarOptions = [
  {
    value: textConstants.daysAnaliticsOptions_1,
    label: textConstants.daysAnaliticsOptionsTitle_1
  },
  {
    value: textConstants.daysAnaliticsOptions_2,
    label: textConstants.daysAnaliticsOptionsTitle_2
  },
  {
    value: textConstants.daysAnaliticsOptions_3,
    label: textConstants.daysAnaliticsOptionsTitle_3
  }
]

export const chartXLabelMinimize = {
  [textConstants.period.hourly]: 24,
  [textConstants.period.daily]: 22,
  [textConstants.period.monthly]: 12,
  [textConstants.period.yearly]: 12
}

export const chartXLabelMobileMinimize = {
  [textConstants.period.hourly]: 7,
  [textConstants.period.daily]: 7,
  [textConstants.period.monthly]: 7,
  [textConstants.period.yearly]: 7
}

export const chartXLabelRotation = {
  [textConstants.period.hourly]: -45,
  [textConstants.period.daily]: 0,
  [textConstants.period.monthly]: 0,
  [textConstants.period.yearly]: 0
}
