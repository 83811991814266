import { ChartPeriod } from "./types"

export const periodOptions = [
  {
    value: ChartPeriod.daily,
    label: `По дням`
  },
  {
    value: ChartPeriod.weekly,
    label: `По неделям`
  },
  {
    value: ChartPeriod.monthly,
    label: `По месяцам`
  }
]

export const heightGradient = `rgba(34, 184, 207, 0.2)`
export const lowGradient = `rgba(34, 184, 207, 0)`

export const tooltipLabels = {
  inValue: `Касса (IN)`,
  outValue: `Касса (OUT)`,
  betsValue: `Ставки (IN)`,
  win: `WIN/IN (за день)`,
  transfer_in: `Перенос в клуб`,
  transfer_out: `Перенос из клуба`,
  games: `Игры, в которые играл`
}

export const getYInOut = (attendanceActive: boolean) => ({
  height: attendanceActive ? "70%" : "100%",
  lineWidth: 1,
  resize: {
    enabled: false
  },
  crosshair: {
    width: 1,
    color: `#E9ECEF`
  },
  title: {
    text: ""
  },
  gridLineDashStyle: `Dash`,
  gridLineColor: `#F2F2F7`,
  gridLineWidth: 1,
  gridZIndex: 1,
  plotLines: [
    {
      dashStyle: `Solid`,
      color: "#AEAEB2",
      value: 0,
      width: 1
    }
  ]
})

export const yAttendance = {
  top: "70%",
  height: "30%",
  lineWidth: 1,
  resize: {
    enabled: true
  },
  crosshair: {
    width: 1,
    color: `#E9ECEF`
  },
  title: {
    text: ""
  },
  gridLineDashStyle: `Dash`,
  gridLineColor: `#F2F2F7`,
  gridLineWidth: 1,
  gridZIndex: 0,
  plotLines: [
    {
      dashStyle: `Solid`,
      color: "#AEAEB2",
      value: 0,
      width: 1
    }
  ]
}
