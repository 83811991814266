import React, { useMemo } from "react"
import { AdminPenaltyCardFormattedDates, AdminPenaltyCardProps } from "./types"
import { adminPenaltyCardText, AdminPenaltyPopover } from "./helper"
import { ReactComponent as AttachIcon } from "Components/icons/attach_grey.svg"
import { ReactComponent as MessageIcon } from "Components/icons/message.svg"
import moment from "moment"
import { MinifiedFileComponent } from "Components/File"
import {
  formatDayMonthYear,
  getLabelById,
  PenaltyStatuses
} from "../../helpers"
import { moneyRender } from "constants/index"

moment.updateLocale("ru", {
  relativeTime: {
    s: adminPenaltyCardText.now,
    ss: adminPenaltyCardText.now
  }
})

export const AdminPenaltyCard: React.FC<AdminPenaltyCardProps> = ({
  penalty,
  statuses,
  setViewModalData,
  winDict,
  setCancelFinalModal,
  setEditModalDataCb
}) => {
  const statusText = useMemo(() => {
    if (
      penalty?.status === PenaltyStatuses.reviewed ||
      penalty?.status === PenaltyStatuses.request_canceled
    ) {
      return adminPenaltyCardText.edited
    }
    return adminPenaltyCardText.canceled
  }, [penalty?.status])

  const {
    findDateFormatted,
    setDateFormatted,
    viewDateFormatted,
    fixDateFormatted,
    findDateFromNow,
    setDateFromNow,
    viewDateFromNow,
    fixDateFromNow
  } = useMemo(() => {
    const result: AdminPenaltyCardFormattedDates = {}
    result.findDateFromNow = moment(penalty.findDate)
      .subtract(60, "seconds")
      .fromNow() //TODO поправить это. Момент не умеет менять экземпляр локали. А текст слишком длинный для иконки
    result.setDateFromNow = moment(penalty.setDate)
      .subtract(60, "seconds")
      .fromNow(true)
    result.viewDateFromNow =
      penalty.viewDate && moment(penalty.viewDate).fromNow(true)
    result.fixDateFromNow =
      penalty.fixDate && moment(penalty.fixDate).fromNow(true)
    result.findDateFormatted = formatDayMonthYear(penalty.findDate)
    result.setDateFormatted = formatDayMonthYear(penalty.setDate)
    result.viewDateFormatted = formatDayMonthYear(penalty.viewDate)
    result.fixDateFormatted = formatDayMonthYear(penalty.fixDate)

    return result
  }, [penalty.findDate, penalty.setDate, penalty.fixDate, penalty.viewDate])

  return (
    <div className="PenaltyAdminCard" key={penalty.id}>
      <div className="PenaltyAdminCardHeader">
        <div className="PenaltyAdminCardHeaderWithStatus">{`#${
          penalty.id
        } · ${getLabelById(penalty.status, statuses)}`}</div>
        <div className="PenaltyAdminCardHeaderDate">{findDateFromNow}</div>

        <div className="PenaltyAdminCardHeaderDetails">
          <AdminPenaltyPopover
            penalty={penalty}
            setEditModalData={() => setEditModalDataCb(penalty)}
            setCancelFinalModal={setCancelFinalModal}
          />
        </div>
      </div>

      <div className="PenaltyAdminCardContent">
        <div className="PenaltyAdminCardLeft">
          <div
            className="PenaltyAdminCardReasonHeader"
            onClick={() => setViewModalData(penalty)}
          >
            {penalty.reason}
          </div>
          <div
            className="PenaltyAdminCardReasonComment"
            onClick={() => setViewModalData(penalty)}
          >
            {penalty.reasonComment}
          </div>
          <div className="PenaltyAdminCardFiles">
            {(penalty?.attachments).map(file => (
              <MinifiedFileComponent
                key={file.size + file.name}
                name={file.name}
                link={file.link}
                type={file.type}
              />
            ))}
          </div>
        </div>

        <div className="PenaltyAdminCardRight">
          <div className="PenaltyAdminCardDatesWrapper">
            <div className="PenaltyAdminCardDatesElement">
              <div className="PenaltyAdminCardDatesElementText">
                {adminPenaltyCardText.detected}
              </div>
              <div className="PenaltyAdminCardDatesElementDate">
                {findDateFormatted}
              </div>
            </div>
            <div className="PenaltyAdminCardDatesElement">
              <div className="PenaltyAdminCardDatesElementText">
                {adminPenaltyCardText.set}
              </div>
              <div className="PenaltyAdminCardDatesElementDate">
                {setDateFormatted}
              </div>
              <div className="PenaltyAdminCardDatesFromNow">
                {setDateFromNow}
              </div>
            </div>
            <div className="PenaltyAdminCardDatesElement">
              <div className="PenaltyAdminCardDatesElementText">
                {adminPenaltyCardText.viewed}
              </div>
              <div className="PenaltyAdminCardDatesElementDate">
                {viewDateFormatted}
              </div>
              <div className="PenaltyAdminCardDatesFromNow">
                {viewDateFromNow}
              </div>
            </div>
            <div className="PenaltyAdminCardDatesElement">
              <div className="PenaltyAdminCardDatesElementText">
                {statusText}
              </div>
              <div className="PenaltyAdminCardDatesElementDate">
                {fixDateFormatted}
              </div>
              <div className="PenaltyAdminCardDatesFromNow">
                {fixDateFromNow}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="PenaltyAdminCardFooter">
        <div className="PenaltyAdminCardFooterLeft">
          <div className="PenaltyAdminCardFooterCard">{`${adminPenaltyCardText.hallNumber} ${penalty.hall}`}</div>
          {penalty?.status === PenaltyStatuses.reviewed ? (
            <>
              <div className="PenaltyAdminCardFooterCard">
                {penalty?.penaltyValueResolved
                  ? `${moneyRender(penalty?.penaltyValueResolved)} ${
                      adminPenaltyCardText.rubles
                    }`
                  : adminPenaltyCardText.warning}
              </div>
              <div className="PenaltyAdminCardFooterCard">
                {penalty.winResolved !== null
                  ? winDict.find(x => x.id == penalty.winResolved)?.value
                  : adminPenaltyCardText.winNull}
              </div>
            </>
          ) : (
            <>
              <div className="PenaltyAdminCardFooterCard">
                {penalty?.penaltyValue
                  ? `${moneyRender(penalty?.penaltyValue)} ${
                      adminPenaltyCardText.rubles
                    }`
                  : adminPenaltyCardText.warning}
              </div>
              <div className="PenaltyAdminCardFooterCard">
                {penalty.win !== null
                  ? winDict.find(x => x.id == penalty.win)?.value
                  : adminPenaltyCardText.winNull}
              </div>
            </>
          )}
        </div>

        <div
          className="PenaltyAdminCardFooterRight"
          onClick={() => setViewModalData(penalty)}
        >
          <div className="PenaltyAdminCardFooterAppealName">
            {penalty?.appeal?.name}
          </div>
          <div className="PenaltyAdminCardFooterAppealComment">
            <MessageIcon /> {penalty?.appeal?.comment ? 1 : 0}
          </div>
          <div className="PenaltyAdminCardFooterAppealAttach">
            <AttachIcon />
            {penalty?.appeal?.attachments?.length ?? 0}
          </div>
        </div>
      </div>
    </div>
  )
}
