import React from "react"
import { Link } from "@material-ui/core"
import { previewPhoneTime, replaceTextForPhonePreview } from "../helper"
import SpinComponent from "Components/Spin"
import { PhonePreviewProps } from "../types"
import { smsConstants } from "../constants"

const SmsPhonePreview = ({
  smsState,
  calcState,
  showStep4,
  templateSmsTextSelected
}: PhonePreviewProps) => {
  return (
    <section className="CreateSms_phoneWrapper">
      <div className="CreateSms_phoneBody">
        <div className="CreateSms_phoneInner">
          <div className="CreateSms_phoneTime">
            {previewPhoneTime(smsState)}
          </div>
          <div className="CreateSms_phoneMessage">
            {showStep4
              ? templateSmsTextSelected
                ? replaceTextForPhonePreview(smsState)
                : smsState.customSmsText
              : smsConstants.previewTitle}
          </div>
        </div>
      </div>

      <div className="CreateSms_phoneCalcWrapper">
        {calcState.calculating ? (
          <div className="CreateSms_phoneCalc">
            <SpinComponent />
          </div>
        ) : (
          <div className="CreateSms_phoneCalc">
            <div className="CreateSms_phoneCalcRow">
              <span className="CreateSms_phoneCalcRowLabel">
                {smsConstants.previewSymbolCountText}
              </span>
              <span className="CreateSms_phoneCalcRowValue">
                {calcState.characterCount}
              </span>
            </div>
            <div className="CreateSms_phoneCalcRow">
              <span className="CreateSms_phoneCalcRowLabel">
                {smsConstants.previewTotalSmsText}
              </span>
              <span className="CreateSms_phoneCalcRowValue">
                {calcState.smsCount}
              </span>
            </div>
            <div className="CreateSms_phoneCalcRow">
              <span className="CreateSms_phoneCalcRowLabel">
                {smsConstants.previewSmsSizeText}
              </span>
              <span className="CreateSms_phoneCalcRowValue">
                {calcState.smsLength}
              </span>
            </div>
            <div className="CreateSms_phoneCalcRow">
              <span className="CreateSms_phoneCalcRowLabel">
                {smsConstants.previewCountPhonesText}
              </span>
              <span className="CreateSms_phoneCalcRowValue">
                {calcState.phonesCount}
              </span>
            </div>
            <div className="CreateSms_phoneCalcTotal">
              <span className="CreateSms_phoneCalcTotalRowLabel">
                {smsConstants.modalCostText}
              </span>
              <span className="Createms_phoneCalcTotalRowValue">
                {calcState.price}
                <span className="Createms_phoneCalcTotalRowValue Createms_RubleSigne">
                  {smsConstants.rubleSymbol}
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="CreateSms_phoneBottomText">
        <span>{smsConstants.previewInfoText}</span>
        <Link
          className="Createms_Link"
          target="_blank"
          href={smsConstants.previewLinkHref}
        >
          {smsConstants.previewLinkText}
        </Link>
      </div>
    </section>
  )
}

export default SmsPhonePreview
