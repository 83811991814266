import React from "react"

import { valueToCurrency } from "constants/index"

import { SortOrders } from "Components/TableNew/types"

import {
  FilterTable,
  FilterTableSort,
  PaymentTableFields,
  PaymentTableStatus
} from "../types"
import { statusText, textByField } from "../constants"
import moment from "moment"

export const tableComponentsConstants = {
  title: `Прикреплённые документы`,
  buttons: {
    clear: `Фильтры`,
    clearModalTitle: `Примененные фильтры`,
    clearModalBtn: `Очистить фильтры`
  },
  moreTitle: `Комментарий`,
  rogerThat: `Понятно`
}

// Clear modal component
const checkField = (filters: FilterTable, field: PaymentTableFields) =>
  !!filters[field] ||
  (field === PaymentTableFields.sum &&
    (filters[field]?.from || filters[field]?.to)) ||
  filters.sort?.field === field

export const checkClearIsEmpty = (filter: FilterTable): number => {
  let filterCount = 0

  for (const item in PaymentTableFields) {
    const currentField = item as PaymentTableFields
    if (checkField(filter, currentField)) {
      filterCount++
    }
  }

  return filterCount
}

const checkSort = (field: PaymentTableFields, sort: FilterTableSort) => {
  if (field === sort.field) {
    return `Сортировка по ${
      sort?.order === SortOrders.asc ? `возрастанию` : `убыванию`
    }`
  }
  return undefined
}

export const getFilterInfoByData = (filters: FilterTable): JSX.Element => {
  const dataArr = []

  for (const item in PaymentTableFields) {
    const currentField = item as PaymentTableFields
    const sortData = checkSort(currentField, filters.sort)
    if (
      (!!filters[currentField] || sortData) &&
      (currentField === PaymentTableFields.contractor ||
        currentField === PaymentTableFields.payment_purpose)
    ) {
      dataArr.push({
        value: filters[currentField],
        label: textByField[currentField].label,
        sort: sortData
      })
    }
    if (
      (currentField === PaymentTableFields.payment_period ||
        currentField === PaymentTableFields.created_date) &&
      (!!filters[currentField]?.from || !!filters[currentField]?.to || sortData)
    ) {
      let from
      let to
      if (!!filters[currentField]?.from) {
        from = `от ${moment(
          filters[currentField]?.from,
          `YYYY-MM-DDTHH:mm:ss`
        ).format(`DD.MM.YYYY`)}`
      }
      if (!!filters[currentField]?.to) {
        to = `до ${moment(
          filters[currentField]?.to,
          `YYYY-MM-DDTHH:mm:ss`
        ).format(`DD.MM.YYYY`)}`
      }
      dataArr.push({
        value: `${from || ``} ${to || ``}`,
        label: textByField[currentField].label,
        sort: checkSort(currentField, filters.sort)
      })
    }
    if (
      (!!filters[currentField] || sortData) &&
      currentField === PaymentTableFields.sum
    ) {
      const from = filters[currentField]?.from
        ? `от ${valueToCurrency({
            value: Number(filters[currentField]?.from),
            withoutFixed: true,
            withoutZero: true
          })} `
        : ``
      const to = filters[currentField]?.to
        ? `до ${valueToCurrency({
            value: Number(filters[currentField]?.to),
            withoutFixed: true,
            withoutZero: true
          })}`
        : ``
      dataArr.push({
        value: `${from}${to}`,
        label: textByField[currentField].label,
        sort: checkSort(currentField, filters.sort),
        isCapitalize: false
      })
    }
    if (!!filters[currentField] && currentField === PaymentTableFields.status) {
      const currentStatus = filters[currentField] as PaymentTableStatus
      dataArr.push({
        value: statusText[currentStatus],
        label: textByField[currentField].label,
        sort: checkSort(currentField, filters.sort)
      })
    }
  }

  return (
    <div className="FlexColumn Gap16 PaddingTop16">
      {dataArr.map(item => (
        <div key={`Payment_filter_${item.label}`} className="FlexColumn Gap4">
          <span className="BodySecondText">{item.label}</span>
          <span
            className={`TextDefaultBold Cyan700Color ${
              item.isCapitalize ? `CapitalizeText` : ``
            }`}
          >
            {item.value}
          </span>
          {!!item.sort && (
            <span className="TextOne Gray600Color">{item.sort}</span>
          )}
        </div>
      ))}
    </div>
  )
}
export const initialMoreModalState = {
  isActive: false,
  comment: ``
}
