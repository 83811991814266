import React from "react"

import { ReactComponent as EmptyTable } from "./empty_table.svg"
import { textConstants } from "../constants"

const EmptyFilters = () => {
  return (
    <div className="RegistrationEmptyContainer">
      <div className="RegistrationEmptySubContainer">
        <div className="RegistrationEmptyContainerTextMain">
          {textConstants.notActiveFilter.title}
        </div>
        <EmptyTable />
        <div className="SubtitleSecondText">
          {textConstants.notActiveFilter.description}
        </div>
      </div>
    </div>
  )
}

export default EmptyFilters
