import { CheckboxValueType } from "antd/lib/checkbox/Group"
import moment, { Moment } from "moment"

export type ConsolidatedFilterData = {
  halls: (string | CheckboxValueType)[]
  period: string
  dates: [Moment, Moment] | null
  isShift: boolean
  shift: [number, number]
  gmt: string
}

export type ConsolidatedFilter = {
  isOpen: boolean
  isActive: boolean
  data: ConsolidatedFilterData
}

export const initialFilterData: ConsolidatedFilterData = {
  halls: [],
  period: "daily",
  dates: [moment(), moment()],
  isShift: true,
  shift: [8, 8],
  gmt: `3`
}

export type ConsolidatedStateData = {
  date: string
  inw: string
  outw: string
  inwBonus: string
  win: string
  sum: string
  sportInw: string
  sportOutw: string
  sportInwBonus: string
  sportWin: string
  inside: {
    hall: string
    bonus: {
      field: string
      value: string
    }[]
    money: {
      field: string
      value: string
    }[]
    freebet: {
      field: string
      value: string
    }[]
  }[]
}[]

type ConsolidatedState = {
  pending: boolean
  total: number
  data: ConsolidatedStateData
}

export default ConsolidatedState
