import React from "react"
import {
  TRANSACTION_FILTER_NAME,
  transactionColumn,
  transactionsText,
  TRANSACTION_URL
} from "./helper"
import ReportLego from "../ReportLego"
import { useSelector } from "react-redux"
import { getTransactionsList } from "store/dictionary/selector"
import { TransactionModal } from "./TransactionModal"
import { noncashExcelConfig } from "./constants"

const Transaction = () => {
  const transactionsDict = useSelector(getTransactionsList)

  return (
    <div className="Noncash">
      <ReportLego
        report={{
          url: TRANSACTION_URL,
          tableScroll: 800,
          getColumn: (field, search, setSearch) =>
            transactionColumn(field, search, setSearch, transactionsDict)
        }}
        title={transactionsText.title}
        emptyFilterTitle={transactionsText.filterTitle}
        emptyFilterDescription={transactionsText.filterDescription}
        filterName={TRANSACTION_FILTER_NAME}
        titleInfo={transactionsText.tooltip}
        // eslint-disable-next-line
        //@ts-ignore
        modalCmp={({ ...props }) => () => (
          <TransactionModal {...props} statuses={transactionsDict} />
        )}
        withTotal
        disableLastRowClick
        exelConfig={{
          url: noncashExcelConfig.url,
          title: noncashExcelConfig.title
        }}
      />
    </div>
  )
}

export default Transaction
