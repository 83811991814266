import React, { useCallback, useMemo } from "react"

import { getDateDescriptionLayout, isMobile } from "constants/index"

import { ModalRightDetail } from "Components/Modal"
import { MobileBackButton } from "Components/Button"
import TableComponent from "Components/TableNew"
import TabsComponent from "Components/Tabs"
import { ReactComponent as NotFoundIcon } from "Components/icons/illustrations/not_found.svg"

import {
  raceStatusesDict,
  raceText,
  tabsConfigModal,
  tabsConfigModalRaceDisabled,
  tabsIdsModal
} from "./constants"
import { makeRaceModalTableColumns } from "./helpers"
import { RaceModalProps } from "./types"

export const RaceModal: React.FC<RaceModalProps> = ({
  onCloseModal,
  local,
  federal,
  period,
  id,
  status,
  onTabChange,
  activeTab
}) => {
  const columns = useMemo(() => makeRaceModalTableColumns(), [])
  const isRaceTab = activeTab === tabsIdsModal.raceTab
  const tabsConfig =
    federal && local ? tabsConfigModal : tabsConfigModalRaceDisabled

  const { tableData, datesTitle } = useMemo(() => {
    const currentData = isRaceTab ? federal : local
    return {
      tableData: currentData?.winners,
      datesTitle: getDateDescriptionLayout({
        dates: [period.from, period.to],
        shift: [8, 20],
        hideTime: true,
        isShift: false,
        show: true,
        hidePeriodTitle: true
      })
    }
  }, [isRaceTab, federal, period])

  const toggleActiveTab = useCallback(
    tab => {
      onTabChange && onTabChange(tab)
    },
    [onTabChange]
  )

  return (
    <ModalRightDetail
      initialWidth={590}
      close={onCloseModal}
      hideClose={isMobile}
      maskClosable
    >
      <div className="RaceDetailWrapper">
        <div className="RaceDetailHeader">
          {isMobile && (
            <div className="MarginBottom16">
              <MobileBackButton onClick={onCloseModal} />
            </div>
          )}
          <div className="FlexColumn">
            <span className="HeadlineSecondText">
              {raceText.columns.winners}
            </span>
            <div className="RaceDetailSubtitle">
              <span className="RaceDetailText">{`${raceText.number} ${id}`}</span>
              <span className="RaceDetailSeparator">{raceText.separator}</span>
              <span className={`TextDefault`}>
                {raceStatusesDict[status].text}
              </span>
              <span className="RaceDetailSeparator">{raceText.separator}</span>
              <span className="RaceDetailText">{datesTitle}</span>
            </div>
          </div>
        </div>
        <div className="RaceModalDetailWrapper">
          <TabsComponent
            tabs={tabsConfig}
            activeTab={activeTab as number}
            toggleActiveTab={toggleActiveTab}
          />
          {tableData ? (
            <TableComponent
              columns={columns}
              scroll={{ y: "calc(100vh - 15rem)" }}
              data={tableData}
              isOrange
              tableClassName="RaceTableModal"
            />
          ) : (
            <div className="RaceModalEmpty">
              <NotFoundIcon />
              <div className="RaceModalEmptyText">{raceText.emptyWinners}</div>
            </div>
          )}
        </div>
      </div>
    </ModalRightDetail>
  )
}
