import React, { ChangeEvent } from "react"

import { useRequest } from "api/hooks"
import { isNumberWithinRange, moneyRenderSafe } from "constants/index"
import InputComponent from "Components/Input"

import { ROOT_URL, text } from "./constants"
import {
  HistoryBetsDataSummary,
  HistoryBetsSummaryKeys,
  InputRangeComponentProps,
  UseHistoryBetsRequests
} from "./types"

export const useHistoryBetsRequests = ({
  filter,
  sort,
  pagination
}: UseHistoryBetsRequests) => {
  const { request: getHistoryBets } = useRequest({
    url: ROOT_URL,
    method: `POST`,
    requestBody: {
      filter,
      sort: sort && Object.values(sort).includes(undefined) ? undefined : sort,
      pagination
    }
  })

  const { request: getHistoryBetsSummary } = useRequest({
    url: `${ROOT_URL}/summary`,
    method: `POST`,
    requestBody: {
      filter
    }
  })

  return { getHistoryBets, getHistoryBetsSummary }
}

export const detailConfigByData = (data: HistoryBetsDataSummary) => ({
  firstRow: [
    {
      label: text.detail[HistoryBetsSummaryKeys.bets_count],
      value: data[HistoryBetsSummaryKeys.bets_count]
    },
    {
      label: text.detail[HistoryBetsSummaryKeys.money_bets_count],
      value: moneyRenderSafe(data[HistoryBetsSummaryKeys.money_bets_count])
    },
    {
      label: text.detail[HistoryBetsSummaryKeys.money_bets_sum],
      value: moneyRenderSafe(data[HistoryBetsSummaryKeys.money_bets_sum], "₽")
    }
  ],
  lastRow: [
    {
      label: text.detail[HistoryBetsSummaryKeys.guests_count],
      value: data[HistoryBetsSummaryKeys.guests_count]
    },
    {
      label: text.detail[HistoryBetsSummaryKeys.bonus_bets_count],
      value: moneyRenderSafe(data[HistoryBetsSummaryKeys.bonus_bets_count])
    },
    {
      label: text.detail[HistoryBetsSummaryKeys.bonus_bets_sum],
      value: moneyRenderSafe(data[HistoryBetsSummaryKeys.bonus_bets_sum], "Б")
    }
  ]
})

export const InputRangeComponent: React.FC<InputRangeComponentProps> = ({
  valueFrom,
  valueTo,
  prefixFrom,
  prefixTo,
  onChangeFrom,
  onChangeTo,
  className
}) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (value?: number) => void,
    valuePrev?: number
  ) => {
    if (isNumberWithinRange(e.target.value)) {
      onChange(Number(e.target.value))
    } else if (
      e.target.value !== "" &&
      isNumberWithinRange(String(valuePrev))
    ) {
      onChange(valuePrev)
    } else {
      onChange(undefined)
    }
  }

  return (
    <div className={`HistoryCustomFilter_Range ${className || ""}`}>
      <InputComponent
        value={valueFrom === undefined ? "" : valueFrom}
        prefix={prefixFrom}
        onChange={e => handleChange(e, onChangeFrom, valueFrom)}
      />
      <InputComponent
        value={valueTo === undefined ? "" : valueTo}
        prefix={prefixTo}
        onChange={e => handleChange(e, onChangeTo, valueTo)}
      />
    </div>
  )
}
