import noop from "lodash/noop"
import { ReportText } from "../types"
import { getColumnActiveClass } from "../helper"

const moneyRender = (value: string) =>
  new Intl.NumberFormat("ru-RU").format(parseFloat(value))

export const HALL_BALANCE_FILTER_NAME = "HALL_BALANCE_FILTER_NAME"
export const HALL_BALANCE_URL = "reports/noncash/hallbalance"
export const HALL_BALANCE_URL_EXEL = "reports/noncash/hallbalance/excel"

export const clubBalanceOperationText: ReportText = {
  title: `Отчёт по балансу \n клуба`,
  filterTitle: `Чтобы получить отчёт по балансу клуба,
                  вам необходимо выбрать нужные
                  параметры фильтрации`,
  filterDescription: `Отчёт по балансу клуба содержит данные о движении безналичных денежных средств, а также информацию о состоянии баланса на выбранный отчетный период`,
  tooltip:
    "Отчёт содержит информацию о текущем состоянии баланса клуба безналичных денежных средств, расходу и пополнениям, размере комиссии, а также о состоянии баланса на выбранный отчетный период."
}

const columnsKeys = {
  hallId: "hall_id",
  balanceStart: "balance_start_amount",
  balanceEnd: "balance_end_amount",
  balanceOut: "balance_out_amount",
  fee: "fee_amount",
  payOut: "pay_out_amount",
  balanceIn: "balance_in_amount"
}

export const clubBalanceColumn = (field: string | undefined) => [
  {
    title: "Клуб №",
    dataIndex: columnsKeys.hallId,
    className: getColumnActiveClass(field, columnsKeys.hallId),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnsKeys.hallId,
    width: 100
  },
  {
    title: "Баланс на начало периода, ₽",
    dataIndex: columnsKeys.balanceStart,
    className: getColumnActiveClass(field, columnsKeys.balanceStart),
    sorter: () => noop,
    showSorterTooltip: false,
    key: columnsKeys.balanceStart,
    width: 310,
    render: moneyRender
  },
  {
    title: "Баланс на конец периода, ₽",
    dataIndex: columnsKeys.balanceEnd,
    key: columnsKeys.balanceEnd,
    className: getColumnActiveClass(field, columnsKeys.balanceEnd),
    width: 300,
    showSorterTooltip: false,
    sorter: () => noop,
    render: moneyRender
  },
  {
    title: "Расход за период, ₽",
    dataIndex: columnsKeys.balanceOut,
    key: columnsKeys.balanceOut,
    width: 180,
    className: getColumnActiveClass(field, columnsKeys.balanceOut),
    showSorterTooltip: false,
    sorter: () => noop,
    render: moneyRender
  },
  {
    title: "Комиссия, ₽",
    dataIndex: columnsKeys.fee,
    key: columnsKeys.fee,
    width: 140,
    className: getColumnActiveClass(field, columnsKeys.fee),
    showSorterTooltip: false,
    sorter: () => noop,
    render: moneyRender
  },
  {
    title: "Сумма выплат, ₽",
    dataIndex: columnsKeys.payOut,
    key: columnsKeys.payOut,
    width: 200,
    className: getColumnActiveClass(field, columnsKeys.payOut),
    showSorterTooltip: false,
    sorter: () => noop,
    render: moneyRender
  },
  {
    title: "Зачислено, ₽",
    dataIndex: columnsKeys.balanceIn,
    key: columnsKeys.balanceIn,
    width: 200,
    className: getColumnActiveClass(field, columnsKeys.balanceIn),
    showSorterTooltip: false,
    sorter: () => noop,
    render: moneyRender
  }
]
