import React from "react"
import { OnlyRegSelectionProps } from "Containers/Visit/types"
import SwitchComponents from "Components/Switch"
import { textConstants } from "Containers/Visit/constants"
import { ReactComponent as InfoIcon } from "Components/icons/help_24.svg"
import TooltipComponent from "Components/Tooltip"

const OnlyRegSelection = ({
  filterState,
  setFilterState
}: OnlyRegSelectionProps) => {
  return (
    <div className="VisitsRegSelection">
      <SwitchComponents
        label={textConstants.onlyReg}
        checked={filterState.data.onlyReg}
        onClick={checked =>
          setFilterState({
            ...filterState,
            data: {
              ...filterState.data,
              onlyReg: checked,
              type: textConstants.visitsByGames.all
            }
          })
        }
      />
      <div className="VisitsRegSelectionTooltip">
        <TooltipComponent
          direction="right"
          title={textConstants.onlyRegTooltip}
        >
          <InfoIcon />
        </TooltipComponent>
      </div>
    </div>
  )
}

export default OnlyRegSelection
