import React from "react"
import { ListRowProps } from "./types"
import { ReactComponent as RiseIcon } from "../icons/btn.svg"

export const SuperwinListRow: React.FC<ListRowProps> = ({
  value,
  data,
  hall,
  is_vip = false,
  setModal
}) => {
  const handleClick = () => {
    if (value) {
      setModal({
        data,
        hall,
        is_vip
      })
    }
  }

  return (
    <div className="FlexRow Gap8 SuperwinListItem">
      <div className="SuperwinListMainRow">{value || "—"}</div>
      {value && (
        <div className="SuperwinListMainRowIcon" onClick={handleClick}>
          <RiseIcon />
        </div>
      )}
    </div>
  )
}
