import React from "react"

import { LabeledValue } from "antd/lib/select"
import { TableLocale } from "antd/es/table/interface"

import { Moment } from "moment"

import { UseRequestBodySort } from "api/hooks"

export enum SortOrdersPassed {
  ascend = "ascend",
  descend = "descend"
}

export enum SortOrders {
  asc = "asc",
  desc = "desc"
}

export interface TableHeaderProps {
  className?: string
  isActiveField?: boolean
  isSort?: string
  title: string
  modalTitle?: string
  children: React.ReactNode
}

export interface TableHeaderSortProps {
  isSort?: string
  className?: string
  title: string
  field: string
  sort: UseRequestBodySort
  setSort: (val: UseRequestBodySort) => void
}

export interface SearchTableHeaderProps {
  search: string | undefined
  setSearch: (value: string | undefined) => void
  title: string
  headerClass?: string
  type?: HeaderTypesList
  autoFocus?: boolean
  selectOptions?: LabeledValue[]
  popupClassName?: string
  passedClassName?: string
}

export interface TableComponentProps {
  locale?: TableLocale
  columns: any[]
  data: any[]
  width?: number
  withMinHeight?: boolean
  expandable?: any
  rowSelection?: any
  scrollX?: number | null
  scroll?: { x?: number | string; y?: number | string }
  withoutScroll?: boolean
  onRow?: any
  rowClassName?: any
  loading?: boolean
  onChange?: any
  tableClassName?: string
  withTotal?: boolean

  withoutFiltersHeader?: boolean
  showHeader?: boolean
  rowClassNameByRecord?: (record: any) => void
  isOrange?: boolean
  pagination?: {
    total: number
    value: { limit: number; offset: number }
    onChange: ({ limit, offset }: { limit: number; offset: number }) => void
  }
  rowKey?: string
  summary?: any //TODO fix it
  wrapperClassName?: string
  bordered?: boolean
}

export type SortOrder = SortOrders | undefined

export type SortOrderPassed = SortOrdersPassed | undefined

export type Sorter = {
  order: SortOrderPassed
  field: string
}

export type SortProps = {
  order: SortOrders
  field: string
}

export type SortForTable = {
  sorter: Sorter
  setSort: (value: UseRequestBodySort) => void
}

export enum HeaderTypesList {
  InputSearch = "InputSearch",
  SelectSearch = "SelectSearch",
  RangeDateSearch = "RangeDateSearch"
}

export type TextSearchProps = {
  value: string | undefined
  toggleShowSearch: (value: boolean) => void
  setValue: (value: string | undefined) => void
  clearSearch: () => void
  search: string | undefined
  title: string
}

export type RangeDateSearchProps = {
  value: string | [Moment, Moment] | undefined
  toggleShowSearch: (value: boolean) => void
  setSearch: (value: string | undefined) => void
  title: string
}

export type SelectSearchProps = {
  value: string | undefined
  selectOptions: LabeledValue[]
  toggleShowSearch: (value: boolean) => void
  title: string
  setSearch: (value: string | undefined) => void
  popupClassName?: string
  passedClassName?: string
}

export type SearchHeaderProps = {
  value: string | undefined
  toggleShowSearch: (value: boolean) => void
  title: string
  clearSearch: () => void
  selectOptions: LabeledValue[]
  setSearch: (value: string | undefined) => void
  search: string | undefined
  setValue: (value: string | undefined) => void
  type: string
  popupClassName?: string
  passedClassName?: string
}

export type GetLabelProps = {
  type: string
  search: string | undefined
  title: string
  selectOptions: LabeledValue[]
}

export interface ExpandIconProps {
  expanded: boolean
  record: any
  onExpand: (data: any, e: any) => void
  withTotal?: boolean
  show?: boolean
}
