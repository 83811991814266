import { UseRequestBodySort } from "api/hooks"
import { LabeledValue } from "antd/lib/select"
import { Moment } from "moment"

export enum SortOrdersPassed {
  ascend = "ascend",
  descend = "descend"
}

export enum SortOrders {
  asc = "asc",
  desc = "desc"
}

export interface SearchTableHeaderProps {
  search: string | undefined
  setSearch: (value: string | undefined) => void
  title: string
  headerClass?: string
  type?: HeaderTypesList
  autoFocus?: boolean
  selectOptions?: LabeledValue[]
  popupClassName?: string
  passedClassName?: string
}

export interface TableComponentProps {
  columns: any[]
  data: any[]
  expandable?: any
  rowSelection?: any
  scrollX?: number | null
  scroll?: { x?: number | string; y?: number | string }
  withoutScroll?: boolean
  onRow?: any
  rowClassName?: any
  loading?: boolean
  onChange?: any
  tableClassName?: string
}

export type SortOrder = SortOrders | undefined

export type SortOrderPassed = SortOrdersPassed | undefined

export type Sorter = {
  order: SortOrders
  field: string
}

export type SorterPassed = { order: SortOrdersPassed; field: string }

export type SortForTable = {
  sorter: Sorter | SorterPassed
  setSort: (value: UseRequestBodySort) => void
}

export enum HeaderTypesList {
  InputSearch = "InputSearch",
  SelectSearch = "SelectSearch",
  RangeDateSearch = "RangeDateSearch"
}

export type TextSearchProps = {
  value: string | undefined
  toggleShowSearch: (value: boolean) => void
  setValue: (value: string | undefined) => void
  setSearch: (value: string | undefined) => void
  clearSearch: () => void
  search: string | undefined
  title: string
}

export type RangeDateSearchProps = {
  value: string | [Moment, Moment] | undefined
  toggleShowSearch: (value: boolean) => void
  setSearch: (value: string | undefined) => void
  title: string
}

export type SelectSearchProps = {
  value: string | undefined
  selectOptions: LabeledValue[]
  toggleShowSearch: (value: boolean) => void
  title: string
  setSearch: (value: string | undefined) => void
  popupClassName?: string
  passedClassName?: string
}

export type SearchHeaderProps = {
  value: string | undefined
  toggleShowSearch: (value: boolean) => void
  title: string
  clearSearch: () => void
  selectOptions: LabeledValue[]
  setSearch: (value: string | undefined) => void
  search: string | undefined
  setValue: (value: string | undefined) => void
  type: string
  popupClassName?: string
  passedClassName?: string
}

export type GetLabelProps = {
  type: string
  search: string | undefined
  title: string
  selectOptions: LabeledValue[]
}
