import { GetInitialFilterState, MailingHallStatus } from "./types"

export const statusText = {
  active: `Активна`,
  inactive: `Неактивна`
}

export const initialPagination = {
  limit: 30,
  offset: 0
}

export const MAILING_HALL_FILTER = `MAILING_HALL_FILTER`

export const filterConstants = {
  status: {
    label: `Статус рассылки`,
    active: `active`,
    inactive: `inactive`
  },
  search: {
    label: `Поиск сотрудника`,
    placeholder: `Введите контакты`
  }
}

export const getInitialFilterState = ({ halls }: GetInitialFilterState) => {
  return {
    isOpen: true,
    isActive: true,
    data: {
      halls,
      status: [MailingHallStatus.active, MailingHallStatus.inactive],
      search: undefined
    },
    mobileFilter: false
  }
}
