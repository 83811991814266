import React from "react"
import { SmsStateProps } from "../types"
import { smsConstants } from "../constants"

const ModalParams = ({ smsState }: { smsState: SmsStateProps }) => {
  const period = smsConstants.periodTypeOptions.filter(
    option => option.value === smsState.period
  )[0]?.label

  const depositFrom =
    smsState.sumFrom !== ""
      ? `${smsConstants.fromText} ${smsState.sumFrom} ${smsConstants.rubleSymbol}`
      : ""
  const depositTo =
    smsState.sumTo !== ""
      ? `${smsConstants.toText} ${smsState.sumTo} ${smsConstants.rubleSymbol}`
      : ""

  const visitFrom =
    smsState.visitFrom !== ""
      ? `${smsConstants.fromText} ${smsState.visitFrom}`
      : ""
  const visitTo =
    smsState.visitTo !== "" ? `${smsConstants.toText} ${smsState.visitTo}` : ""

  return (
    <div className="SMSCreateModal_infoWrapper">
      <div className="SMSCreateModal_info">
        <div className="SmsModal_param">{smsConstants.modalInfoText}</div>
        <div className="SmsModal_value">{period}</div>
      </div>
      {!(depositFrom && depositTo) && (
        <div className="SMSCreateModal_info">
          <div className="SmsModal_param">{smsConstants.modalDepositText}</div>
          <div className="SmsModal_value">
            {depositFrom} {depositTo}
          </div>
        </div>
      )}
      {!(visitFrom && visitTo) && (
        <div className="SMSCreateModal_info">
          <div className="SmsModal_param">{smsConstants.modalVisitText}</div>
          <div className="SmsModal_value">
            {visitFrom} {visitTo}
          </div>
        </div>
      )}
    </div>
  )
}

export default ModalParams
