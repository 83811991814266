import React, { useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { v4 as uuidv4 } from "uuid"

import { Form, Select } from "antd"

import { useRequest } from "api/hooks"

import { isMobile } from "constants/index"

import { AccessTemplates } from "store/access/types"
import { getAccessList } from "store/access/reducer"

import { rules } from "Components/Form/rules"
import { CheckBoxComponent } from "Components/CheckBox"
import { ButtonPrimary } from "Components/Button"
import InputComponent, { InputMaskComponent } from "Components/Input"
import { HallsMultiSelect, SelectComponent } from "Components/Select"
import { RadioGroupComponent } from "Components/Radio"

import { TooltipCustom, TooltipCustomSize } from "Components/Tooltip"

import { CardsListData } from "../Cards/types"
import CancelModal from "./cancel"

import {
  CustomTempalesOptions,
  CustomTempalesRadioOptions,
  DashboardTagRender,
  generatePassword,
  getDashboardOptions,
  initialForm,
  initialRequiredList,
  labelText,
  statusOptions
} from "./constants"
import { FormProps } from "./types"

import CrudAccessMenu from "./menu"
import MenuTags from "./tags"
import AccessMenuMobile from "./menu_mobile"

const AccessModal = ({
  closeModal,
  data
}: {
  closeModal: (withRefresh?: boolean, isEdit?: boolean) => void
  data?: CardsListData
}) => {
  const { dashboards, templates, custom_template_names } = useSelector(
    getAccessList
  )

  const dashboardOptions = useMemo(() => getDashboardOptions(dashboards), [
    dashboards
  ])

  const [form] = Form.useForm()
  const isEdit = !!data

  const initialData = useMemo(() => initialForm(data), [])

  const [formState, setFormState] = useState<FormProps>({
    pending: false,
    data: initialData
  })
  const [phone, setPhone] = useState<string | undefined>(initialData?.phone)

  const templatesOptions = templates
    .map(item => ({
      value: item.key,
      label: item.name
    }))
    .filter(option => {
      if (
        option.value === AccessTemplates.by_request &&
        (!isEdit || initialData.template_name !== AccessTemplates.by_request)
      )
        return false
      return option
    })
  const customTemplatesOptions = custom_template_names.map(item => ({
    value: item,
    label: item
  }))
  const isNotCustom = formState.data.template_name !== AccessTemplates.custom
  const customFieldClass = useMemo(() => (isNotCustom ? `HideBlock` : ``), [
    formState.data.template_name
  ])

  const statuses = useMemo(
    () => statusOptions({ current: initialData.status }),
    [formState.data.status]
  )

  const removeMenuItem = useCallback(
    (removeId: string) => {
      if (!!formState.data.menu_items) {
        const newMenu = formState.data.menu_items.filter(
          item => item !== removeId
        )
        setFormState({
          ...formState,
          data: { ...formState.data, menu_items: newMenu }
        })
      }
    },
    [formState]
  )

  const { request: saveData } = useRequest({
    url: `v1/access/save`,
    requestBody: { id: data?.id, ...formState.data, phone }
  })

  const [customMode, toggleCustomMode] = useState<CustomTempalesOptions>(
    CustomTempalesOptions.select
  )

  const initialRequired = useMemo(() => {
    if (isEdit) {
      const currentTemplate = templates.find(
        item => item.key === data.template_name
      )
      return currentTemplate?.menu_items_required || initialRequiredList
    } else {
      return initialRequiredList
    }
  }, [])

  const [currentRequiredList, setCurrentRequiredList] = useState<string[]>(
    initialRequired
  )

  const disableTemplates = useMemo(
    () => isEdit && initialData.template_name === AccessTemplates.by_request,
    []
  )

  const onFinish = () => {
    setFormState({ ...formState, pending: true })
    saveData()
      .then(() => {
        setFormState({ ...formState, pending: false })
        closeModal(true, isEdit)
      })
      .catch(({ response }) => {
        if (response?.data?.errors) {
          if (response?.data?.errors?.login) {
            form.setFields([
              {
                name: labelText.login.field,
                errors: [response?.data?.errors?.login]
              }
            ])
          }
          if (response?.data?.errors?.email) {
            form.setFields([
              {
                name: labelText.email.field,
                errors: [response?.data?.errors?.email]
              }
            ])
          }
        }
        setFormState({ ...formState, pending: false })
      })
  }

  return (
    <div className={`AccessModalWrapper MainPageContentWrapper FlexColumn`}>
      <CancelModal close={() => closeModal(false)} isEdit={isEdit} />
      <div className="AccessModalContent FullSizeWidth">
        <Form onFinish={onFinish} form={form} initialValues={initialData}>
          <div
            className={`MarginBottom32 ${
              isMobile ? `FlexColumn Gap16` : `FlexRow Gap32`
            }`}
          >
            <div className="FlexColumn AccessModalContentColumn">
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">
                  {labelText.full_name.label}
                </div>
                <Form.Item
                  rules={[...rules.required, ...rules.name]}
                  name={labelText.full_name.field}
                >
                  <InputComponent
                    disabled={formState.pending}
                    maxLength={100}
                    placeholder={labelText.full_name.placeholder}
                    onChange={({ target: { value } }) => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, full_name: value }
                      })
                    }}
                  />
                </Form.Item>
              </div>
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">{labelText.login.label}</div>
                <Form.Item
                  rules={[
                    ...rules.required,
                    ...rules.login,
                    labelText.login.custom_rule
                  ]}
                  name={labelText.login.field}
                >
                  <InputComponent
                    disabled={formState.pending}
                    maxLength={50}
                    placeholder={labelText.login.placeholder}
                    onChange={({ target: { value } }) => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, login: value }
                      })
                    }}
                  />
                </Form.Item>
              </div>
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">
                  {labelText.template_name.label}
                </div>
                <Form.Item
                  rules={[...rules.required]}
                  name={labelText.template_name.field}
                >
                  <SelectComponent
                    allowClear={false}
                    disabled={formState.pending || disableTemplates}
                    options={templatesOptions}
                    placeholder={labelText.template_name.placeholder}
                    onChange={value => {
                      const currentTemplate = templates?.find(
                        item => item.key === value
                      )
                      setFormState({
                        ...formState,
                        data: {
                          ...formState.data,
                          template_name: value,
                          dashboards: currentTemplate?.dashboards,
                          menu_items: currentTemplate?.menu_items || []
                        }
                      })
                      setCurrentRequiredList(
                        currentTemplate?.menu_items_required || []
                      )
                    }}
                  />
                </Form.Item>
              </div>
              <div className={customFieldClass}>
                <div>
                  <RadioGroupComponent
                    className="CustomTemplateNameRadio MarginBottom24"
                    options={CustomTempalesRadioOptions}
                    value={customMode}
                    onChange={({ target: { value } }) => {
                      toggleCustomMode(value)
                    }}
                  />
                </div>
                <div
                  className={`FlexColumn Gap4 FullSizeWidth ${
                    customMode === CustomTempalesOptions.input
                      ? `HideBlock`
                      : ``
                  }`}
                >
                  <div className="SubtitleFirstText">
                    {labelText.custom_template_name.label}
                  </div>
                  <Form.Item
                    rules={!isNotCustom ? [...rules.required] : []}
                    name={labelText.custom_template_name.field}
                  >
                    <SelectComponent
                      allowClear={false}
                      disabled={formState.pending}
                      options={customTemplatesOptions}
                      placeholder={labelText.custom_template_name.placeholder}
                      onChange={value => {
                        setFormState({
                          ...formState,
                          data: {
                            ...formState.data,
                            custom_template_name: value
                          }
                        })
                      }}
                    />
                  </Form.Item>
                </div>
                <div
                  className={`FlexColumn Gap4 FullSizeWidth  ${
                    customMode === CustomTempalesOptions.select
                      ? `HideBlock`
                      : ``
                  }`}
                >
                  <div className="SubtitleFirstText">
                    {labelText.custom_template_name.label}
                  </div>
                  <Form.Item
                    rules={!isNotCustom ? [...rules.required] : []}
                    name={labelText.custom_template_name.field}
                  >
                    <InputComponent
                      disabled={formState.pending}
                      placeholder={labelText.login.placeholder}
                      onChange={({ target: { value } }) => {
                        setFormState({
                          ...formState,
                          data: {
                            ...formState.data,
                            custom_template_name: value
                          }
                        })
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="FlexRow Gap4">
                  <div className="SubtitleFirstText">
                    {labelText.status.label}
                  </div>
                  <div className="MarginTop4">
                    <TooltipCustom
                      size={TooltipCustomSize.small}
                      title={labelText.status.tooltip}
                    />
                  </div>
                </div>
                <Form.Item
                  rules={[...rules.required]}
                  name={labelText.status.field}
                >
                  <SelectComponent
                    allowClear={false}
                    disabled={formState.pending}
                    options={statuses}
                    placeholder={labelText.status.placeholder}
                    onChange={value => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, status: value }
                      })
                    }}
                  />
                </Form.Item>
              </div>
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">{labelText.halls.label}</div>
                <Form.Item
                  rules={[...rules.required_array]}
                  name={labelText.halls.field}
                >
                  <HallsMultiSelect
                    allowClear={false}
                    disabled={formState.pending}
                    placeholder={labelText.halls.placeholder}
                    onChange={(value: any) => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, halls: value }
                      })
                    }}
                  />
                </Form.Item>
              </div>
              {isMobile && (
                <div className="FlexColumn Gap4 FullSizeWidth MarginBottom24">
                  <div className="SubtitleFirstText">
                    {labelText.menu_items.label}
                  </div>
                  <AccessMenuMobile
                    items={formState.data.menu_items}
                    required_items={currentRequiredList}
                    disabledAll={!formState.data.template_name}
                    changeItems={(value: string[]) => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, menu_items: value }
                      })
                    }}
                  />
                </div>
              )}
              <div className="FlexColumn Gap4 FullSizeWidth MarginBottom24">
                <div className="FlexRow Gap4">
                  <div className="SubtitleFirstText">
                    {labelText.dashboards.label}
                  </div>
                  <div className="MarginTop4">
                    <TooltipCustom
                      size={TooltipCustomSize.small}
                      title={labelText.dashboards.tooltip}
                    />
                  </div>
                </div>
                <SelectComponent
                  allowClear={false}
                  mode="multiple"
                  tagRender={DashboardTagRender}
                  disabled={formState.pending}
                  value={formState.data.dashboards}
                  className="AccessDashboardSelectWrapper"
                  placeholder={labelText.dashboards.placeholder}
                  onChange={value => {
                    setFormState({
                      ...formState,
                      data: { ...formState.data, dashboards: value }
                    })
                  }}
                >
                  {dashboardOptions.map(item =>
                    !!item ? (
                      <Select.Option
                        className="AccessDashboardSelectItemWrapper"
                        key={`dashboard_${item.value}`}
                        value={item.value}
                      >
                        <div className="FlexRow Gap6">
                          <CheckBoxComponent
                            onChange={({ target: { checked } }) => {
                              const currentDashboards =
                                formState.data.dashboards || []
                              form.setFieldValue(
                                labelText.dashboards.field,
                                currentDashboards
                              )
                              if (checked) {
                                setFormState({
                                  ...formState,
                                  data: {
                                    ...formState.data,
                                    dashboards: [
                                      ...currentDashboards,
                                      item.value
                                    ]
                                  }
                                })
                              } else {
                                const newDashboards = currentDashboards.filter(
                                  dash => dash !== item.value
                                )
                                setFormState({
                                  ...formState,
                                  data: {
                                    ...formState.data,
                                    dashboards: newDashboards
                                  }
                                })
                              }
                            }}
                            checked={formState.data.dashboards?.includes(
                              item.value
                            )}
                          />
                          <div>{item.icon}</div>
                          <div className="TextOne">{item.label}</div>
                        </div>
                      </Select.Option>
                    ) : (
                      <div key={uuidv4()} />
                    )
                  )}
                </SelectComponent>
              </div>
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">{labelText.phone.label}</div>
                <Form.Item
                  rules={rules.required_phone_mask}
                  name={labelText.phone.field}
                >
                  <InputMaskComponent
                    mask={"+7 000 000-00-00"}
                    value={phone}
                    allowClear={false}
                    onChange={x => {
                      const value = `7${x.unmaskedValue}`
                      form.setFieldValue(labelText.phone.field, value)
                      setPhone(value)
                    }}
                  />
                </Form.Item>
              </div>
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">{labelText.email.label}</div>
                <Form.Item
                  rules={rules.required_email}
                  name={labelText.email.field}
                >
                  <InputComponent
                    disabled={formState.pending}
                    maxLength={100}
                    placeholder={labelText.email.placeholder}
                    onChange={({ target: { value } }) => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, email: value }
                      })
                    }}
                  />
                </Form.Item>
              </div>
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="FlexRow FullSizeWidth">
                  <div className="SubtitleFirstText">
                    {labelText.password.label}
                  </div>
                  <div
                    className="MarginLeftAuto LinkText HoverItem"
                    onClick={() => {
                      const newPassword = generatePassword()
                      form.setFieldValue(labelText.password.field, newPassword)
                      setFormState({
                        ...formState,
                        data: { ...formState.data, password: newPassword }
                      })
                    }}
                  >
                    {labelText.password.link}
                  </div>
                </div>
                <Form.Item
                  rules={isEdit ? [] : rules.required}
                  name={labelText.password.field}
                >
                  <InputComponent
                    disabled={formState.pending}
                    placeholder={labelText.password.placeholder}
                    onChange={({ target: { value } }) => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, password: value }
                      })
                    }}
                  />
                </Form.Item>
              </div>
              <div className="FlexColumn Gap4 MarginBottom8 FullSizeWidth AccessCrudPasswordSend">
                <Form.Item name={labelText.send_password.field}>
                  <CheckBoxComponent
                    disabled={formState.pending || !formState.data.password}
                    label={labelText.send_password.label}
                    checked={formState.data.send_to_email}
                    onChange={({ target: { checked } }) => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, send_to_email: checked }
                      })
                    }}
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <ButtonPrimary loading={formState.pending} htmlType="submit">
                  {isEdit ? labelText.submit.save : labelText.submit.create}
                </ButtonPrimary>
              </Form.Item>
            </div>
            {!isMobile && (
              <div className="FlexColumn Gap16 AccessModalContentColumn">
                <CrudAccessMenu
                  checked={formState.data.menu_items}
                  disabledAll={!formState.data.template_name}
                  requiredList={currentRequiredList}
                  onChange={(val: string[]) => {
                    setFormState({
                      ...formState,
                      data: { ...formState.data, menu_items: val }
                    })
                  }}
                />
              </div>
            )}
            {!isMobile && (
              <div className="FlexColumn Gap16 AccessModalContentColumn">
                <MenuTags
                  menu_items={formState.data.menu_items}
                  required_items={currentRequiredList}
                  removeMenuItem={removeMenuItem}
                />
              </div>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AccessModal
