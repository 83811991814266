import React, { useState, useMemo } from "react"

import { useSelector } from "react-redux"
import { getHallsListFromStore } from "store/halls/selector"

import { useRequestBody, useNow } from "api/hooks"

import { MailingFilterState } from "./types"
import { MailingTabsProps } from "../types"

import { getInitialFilterState, initialPagination } from "./helper"

import MailingHallFilter from "./Filter"
import MailingHallContent from "./Content"
import MailingTabs from "../Tabs"

import ReportWithFilter from "Components/ReportWithFilter"

const MailingHall = ({ activeTab, setActiveTab }: MailingTabsProps) => {
  const { timestamp, refresh } = useNow()

  const { pagination, setPagination } = useRequestBody(initialPagination.limit)

  const halls = useSelector(getHallsListFromStore)
  const hallNumbers = useMemo(() => {
    return halls.map(item => Number(item))
  }, [halls])

  const initFilterState = getInitialFilterState({
    halls: hallNumbers
  })
  const [filterState, setFilterState] = useState<MailingFilterState>(
    initFilterState
  )

  return (
    <div>
      <ReportWithFilter
        filterComponent={
          <MailingHallFilter
            filterState={filterState}
            setFilterState={setFilterState}
            resetPagination={() => setPagination(initialPagination)}
          />
        }
        isOpen={filterState.isOpen}
        mobileFilter={filterState.mobileFilter}
        toggleFilter={() =>
          setFilterState(prev => ({
            ...prev,
            isOpen: !prev.isOpen
          }))
        }
        toggleMobileFilter={() =>
          setFilterState(prev => ({
            ...prev,
            mobileFilter: !prev.mobileFilter
          }))
        }
        pageContent={
          <div>
            <MailingTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              successRefresh={refresh}
            />
            <div className="MailingContentWrapper">
              <MailingHallContent
                filterState={filterState}
                filterIsOpen={filterState.isOpen}
                pagination={pagination}
                refreshTrigger={timestamp}
                refreshHalls={refresh}
                nextPage={() =>
                  setPagination({
                    ...pagination,
                    offset: pagination.offset + initialPagination.limit,
                    limit: pagination.limit + initialPagination.limit
                  })
                }
              />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default MailingHall
