import React, { useState } from "react"

import { useRequest, useRequestParams } from "api/hooks"

import { ModalFormComponent } from "Components/Modal"
import { ButtonNotifi } from "Components/Button"

import { removeModal } from "./constants"

const SportsBroadcastDelete = ({
  tv,
  toggleDeleteModal,
  refresh
}: {
  tv?: number
  toggleDeleteModal: (val?: number) => void
  refresh: () => void
}) => {
  const { currentHallAsNumber } = useRequestParams()
  const { request: deleteReq } = useRequest({
    url: `v1/hall/sports_broadcast/remove`,
    requestBody: { hall: currentHallAsNumber, tv }
  })

  const [pending, setPending] = useState(false)
  return (
    <ModalFormComponent width={480}>
      <div className="FlexColumn Gap16 Padding8">
        <div className="HeadlineSecondText">{removeModal.title}</div>
        <div className="TextTwo">{removeModal.description}</div>
        <div className="FlexRow PaddingTop8 Gap8 MarginLeftAuto">
          <ButtonNotifi
            disabled={pending}
            onClick={() => toggleDeleteModal(undefined)}
            color="gray"
          >
            {removeModal.cancel}
          </ButtonNotifi>
          <ButtonNotifi
            disabled={pending}
            color="red"
            onClick={() => {
              setPending(true)
              deleteReq()
                .then(() => {
                  toggleDeleteModal()
                  refresh()
                })
                .finally(() => setPending(false))
            }}
          >
            {removeModal.approve}
          </ButtonNotifi>
        </div>
      </div>
    </ModalFormComponent>
  )
}

export default SportsBroadcastDelete
