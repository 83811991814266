import React from "react"
import { Modal } from "antd"

import Button from "Components/Button"

import { textConst } from "../constants"

const ConfirmModal = ({
  open,
  onCancel,
  onSubmit
}: {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
}) => {
  return (
    <Modal
      open={open}
      centered
      wrapClassName="Geoservices_ConfirmPopup"
      footer={null}
      closable={false}
    >
      <div className="FlexColumn Gap16">
        <span className="HeadlineSecondText">
          {textConst.confirmDeleteTitle}
        </span>
        <span className="TextTwo">{textConst.confirmDeleteText}</span>
        <div className="Geoservices_ConfirmPopup__Footer">
          <Button
            className="Geoservices_ConfirmPopup__Button"
            onClick={onCancel}
          >
            {textConst.confirmDeleteCancel}
          </Button>
          <Button
            className="Geoservices_ConfirmPopup__Button Geoservices_ConfirmPopup__Delete"
            onClick={onSubmit}
          >
            {textConst.confirmDeleteApply}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
