import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { CheckboxValueType } from "antd/lib/checkbox/Group"
import get from "lodash/get"

import { checkArrayRole } from "constants/index"

import { IDevelopState } from "store/developer/types"

import { superwinText } from "./constants"

import { SuperWinState, SuperwinTypes } from "./types"
import getSuperWinData from "./helper"

import SpinComponent from "Components/Spin"

import HallItem from "./HallItem"

const MainSuperWin = ({
  logout,
  developer,
  addDeveloperLink,
  filterData,
  defaultOpen
}: {
  logout: () => void
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  filterData: {
    gmt: string
    shift: number
    halls: (string | CheckboxValueType)[]
  }
  defaultOpen?: boolean
}) => {
  const history = useHistory()
  const [winData, setWinData] = useState<SuperWinState>({
    pending: false,
    data: {
      [SuperwinTypes.main]: {},
      [SuperwinTypes.vip]: {}
    }
  })

  useEffect(() => {
    getSuperWinData({
      filterData,
      setWinData,
      winData,
      logout,
      developer,
      addDeveloperLink,
      history
    })
  }, [filterData])

  const showRise = checkArrayRole(superwinText.item.roleAccessArr)

  return (
    <div>
      {!winData.pending ? (
        filterData.halls.map(hall => {
          const currentHallMainData = get(
            winData.data,
            `${SuperwinTypes.main}.${hall}`,
            []
          )
          const currentHallVipData = get(
            winData.data,
            `${SuperwinTypes.vip}.${hall}`,
            []
          )
          const haveVip = !!currentHallVipData.length
          const haveMain = !!currentHallMainData.length
          return (
            (haveVip || haveMain) && (
              <HallItem
                hall={hall}
                defaultOpen={defaultOpen}
                dataMain={currentHallMainData}
                dataVip={currentHallVipData}
                haveVip={haveVip}
                showRise={showRise}
              />
            )
          )
        })
      ) : (
        <div className="MainSuperWinSpinWrapper">
          <SpinComponent />
        </div>
      )}
    </div>
  )
}

export default MainSuperWin
