import { MessageStoreState, EmergencyDataType } from "./types"

import {
  STORE_EMERGENCY_MESSAGE,
  STORE_COUNT_MESSAGE,
  CLOSE_EMERGENCY_MESSAGE,
  OPEN_EMERGENCY_MESSAGE
} from "./constants"

const init: MessageStoreState = {
  message_count: 0,
  emergency: [],
  emergency_open: false
}

export function messageReducer(
  state: MessageStoreState = init,
  action: {
    type: string
    payload: {
      count: number
      emergency: EmergencyDataType[]
      emergency_open: boolean
    }
  }
): MessageStoreState {
  const { type, payload } = action
  switch (type) {
    case STORE_COUNT_MESSAGE: {
      return {
        ...state,
        message_count: payload.count
      }
    }
    case STORE_EMERGENCY_MESSAGE: {
      return {
        ...state,
        emergency: payload.emergency,
        emergency_open: payload.emergency_open
      }
    }
    case CLOSE_EMERGENCY_MESSAGE: {
      return {
        ...state,
        emergency: [],
        emergency_open: false
      }
    }
    case OPEN_EMERGENCY_MESSAGE: {
      return {
        ...state,
        emergency_open: true
      }
    }
    default:
      return state
  }
}
