import React, { useMemo } from "react"
import {
  checkDescription,
  dateDescription,
  fromToDescription,
  getFilterClear,
  searchDescription
} from "./helper"
import {
  FilterData,
  GameListItem,
  TableDataBalance,
  TableDataBetTypeValue,
  TableDataStatus
} from "../types"

import TooltipComponent from "Components/Tooltip"
import { ButtonNotifi } from "Components/Button"
import { filterLists, TableColumns, tableConstants } from "../constants"

const ClearFilter = ({
  tableFilter,
  gameList,
  clear
}: {
  gameList: GameListItem[]
  tableFilter: FilterData
  clear: () => void
}) => {
  const configForClearModal = useMemo(() => getFilterClear(tableFilter), [
    tableFilter
  ])
  const count = configForClearModal.length

  const text = {
    button: `Очистить фильтры`,
    modalTitle: `Примененные фильтры`
  }

  return (
    <div>
      {!!count ? (
        <TooltipComponent
          direction="left"
          trigger="hover"
          isModal={true}
          content={() => (
            <div className="SportboomClearFilterModal FlexColumn">
              <span className="HeadlineSecondText MarginBottom16">
                {text.modalTitle}
              </span>
              <div className="SportboomClearFilterModalList FlexColumn Gap16">
                {configForClearModal.map(item => {
                  let description = <div />
                  if (
                    item.field === TableColumns.period ||
                    tableFilter.sort.field === TableColumns.period
                  ) {
                    const value = tableFilter.table[TableColumns.period]
                    description = dateDescription(value, item.sort)
                  }
                  if (
                    item.field === TableColumns.bet ||
                    item.field === TableColumns.win ||
                    item.field === TableColumns.coefficient ||
                    item.field === TableColumns.result
                  ) {
                    const field:
                      | TableColumns.bet
                      | TableColumns.win
                      | TableColumns.coefficient
                      | TableColumns.result = item.field
                    const value = tableFilter.table[field]
                    description = fromToDescription(value, item.sort)
                  }
                  if (
                    item.field === TableColumns.event_id ||
                    item.field === TableColumns.guest
                  ) {
                    const field: TableColumns.event_id | TableColumns.guest =
                      item.field
                    const value = tableFilter.table[field]
                    description = searchDescription(value, item.sort)
                  }
                  if (item.field === TableColumns.sport_type) {
                    const field: TableColumns.sport_type = item.field
                    const value = tableFilter.table[field] || []
                    const arrOfLabels = gameList
                      .filter(item => value.includes(`${item.id}`))
                      .map(listItems => listItems.name)
                    description = checkDescription(arrOfLabels, item.sort)
                  }
                  if (item.field === TableColumns.bet_type) {
                    const field: TableColumns.bet_type = item.field
                    const value = tableFilter.table[field] || []
                    const arrOfLabels = filterLists[
                      TableColumns.bet_type
                    ].preview
                      .filter(item => value.includes(item))
                      .map(
                        (betTypeItem: TableDataBetTypeValue) =>
                          tableConstants[TableColumns.bet_type].types[
                            betTypeItem
                          ]
                      )
                    description = checkDescription(arrOfLabels, item.sort)
                  }
                  if (item.field === TableColumns.status) {
                    const field: TableColumns.status = item.field
                    const value = tableFilter.table[field] || []
                    const arrOfLabels = filterLists[TableColumns.status].preview
                      .filter(item => value.includes(item))
                      .map(
                        (statusItem: TableDataStatus) =>
                          tableConstants[TableColumns.status].text[statusItem]
                      )
                    description = checkDescription(arrOfLabels, item.sort)
                  }
                  if (item.field === TableColumns.balance) {
                    const field: TableColumns.balance = item.field
                    const value = tableFilter.table[field] || []
                    const arrOfLabels = filterLists[field].preview
                      .filter(item => value.includes(item))
                      .map(
                        (balanceItem: TableDataBalance) =>
                          tableConstants[TableColumns.balance].text[balanceItem]
                      )
                    description = checkDescription(arrOfLabels, item.sort)
                  }
                  return (
                    <div key={`${item.title}_filter`}>
                      <span className="BodyFirstText">{item.title}</span>
                      <div className="FlexRow">{description}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        >
          <div className="MarginRight16">
            <ButtonNotifi disabled={!count} onClick={clear}>
              <div className="FlexRow">
                <span className="ButtonTextSecond">{text.button}</span>
                {!!count && (
                  <div className="SportboomClearFilterCount ButtonTextSecond">
                    {count}
                  </div>
                )}
              </div>
            </ButtonNotifi>
          </div>
        </TooltipComponent>
      ) : (
        <div className="MarginRight16">
          <ButtonNotifi disabled={true}>
            <span className="ButtonTextSecond">{text.button}</span>
          </ButtonNotifi>
        </div>
      )}
    </div>
  )
}

export default ClearFilter
