import buh from "Components/icons/b.png"
import bb from "Components/icons/bb.png"
import da from "Components/icons/da.png"
import dd from "Components/icons/dd.png"
import ds from "Components/icons/ds.png"
import du from "Components/icons/du.png"
import fd from "Components/icons/fd.png"
import ido from "Components/icons/ido.png"
import kt from "Components/icons/kt.png"
import od from "Components/icons/od.png"
import za from "Components/icons/za.png"
import idefault from "Components/icons/idefault.png"

export const defaultIcon = "idefault.png"
export const bbIcon = "bb.png"
export const odIcon = "od.png"
export const fdIcon = "fd.png"
export const dsIcon = "ds.png"
export const doIcon = "ido.png"
export const duIcon = "du.png"
export const daIcon = "da.png"
export const ddIcon = "dd.png"
export const buhIcon = "b.png"
export const ktIcon = "kt.png"
export const zaIcon = "za.png"

export const contactsIcons = [
  "idefault.png",
  "bb.png",
  "od.png",
  "fd.png",
  "ds.png",
  "ido.png",
  "du.png",
  "da.png",
  "dd.png",
  "b.png",
  "kt.png",
  "za.png"
]

export const imgObj: { [key: string]: string } = {
  [buhIcon]: buh,
  [bbIcon]: bb,
  [daIcon]: da,
  [ddIcon]: dd,
  [dsIcon]: ds,
  [duIcon]: du,
  [fdIcon]: fd,
  [doIcon]: ido,
  [ktIcon]: kt,
  [odIcon]: od,
  [zaIcon]: za,
  [defaultIcon]: idefault
}

export const blockStateStorageName = "contacts_blockState"
export const editMsg = "Редактировать"
export const deleteMsg = "Удалить"
export const saveMsg = "Сохранить"
export const addMsg = "Добавить"
export const cancelMsg = "Отмена"
export const editBlockMsg = "Редактировать блок"
export const editStaffMsg = "Редактировать контакт"
export const addBlockMsg = "Добавить блок"
export const deleteBlockMsg = "Удалить блок"
export const deleteSectionMsg = "Удалить раздел"
export const editSectionMsg = "Редактировать раздел"
export const addSectionMsg = "Добавить раздел"
export const addStaffMsg = "Добавить контакт"
export const blockName = "Название блока"
export const sectionName = "Название раздела"
export const sectionSubtitle = "Подзаголовок"
export const sectionDescription = "Описание раздела"
export const sectionDescriptionPlaceholder =
  "Укажите краткую информацию по разделу."
export const sectionSubtitlePlaceholder =
  "Укажите краткую информацию по разделу"
export const contactsInfoTitle = "Контактная информация"
export const endEditingTitle = "Завершить"
export const sectionNamePlaceholder = "Укажите название раздела"
export const sectionIconName = "Иконка раздела"
export const sectionCoordinates = "Координаты"
export const sectionCoordinatesPlaceholder = "Добавьте координаты"
export const addAnchorSection = "Добавить “якорь” раздела в подменю"
export const blockNamePlaceholder = "Это может быть название отдела или службы"
export const sectionOwnerTitle = "Принадлежность к разделу"
export const blockDescription = "Описание блока"
export const blockDescriptionPlaceholder = "Укажите краткую информацию по блоку"
export const staffListTitle = "Список контактов"
export const staffName = "Имя сотрудника"
export const telegramName = "Telegram"
export const whatsAppName = "WhatsApp"
export const emailTitle = "E-mail"
export const emailPlaceholder = "Введите e-mail"
export const telegramPlaceholder = "Введите логин"
export const phone1Title = "Телефон № 1"
export const phone2Title = "Телефон № 2"
export const workHoursTitle = "09:00,18:00"
export const workHoursPlaceholder = "Указать режим работы"
export const addedPhone1Title = "Доб. номер для телефона № 1"
export const addedPhone2Title = "Доб. номер для телефона № 2"
export const addedPhonePlaceholder = "Укажите добавочный номер"
export const phonePlaceholder = "Введите номер телефона"
export const staffPosition = "Должность сотрудника"
export const staffPositionPlaceholder = "Укажите должность сотрудника"
export const staffNamePlaceholder = "Укажите имя сотрудника"
export const phoneBeforeTitlte = "Тел:"
export const whatsAppBeforeTitle = "WhatsApp:"
export const telegramBeforeTitle = "Telegram:"
export const emailBeforeTitle = "E-mail:"
export const staffDeletePromptMsg = (name: string) =>
  `Контакт ${name ?? ``} будет безвозвратно удален.`
export const blockDeletePromptMsg = (name: string) =>
  `Блок ${name ?? ``} будет безвозвратно удален.`
export const sectionDeletePromptMsg = (name: string) =>
  `Раздел ${name ?? ``} будет безвозвратно удален.`
