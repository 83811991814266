import { useEffect, useState } from "react"
import { UseGreyDateInputBackground } from "./types"

export const useGreyDateInputBackground = ({
  greyBackground,
  value
}: UseGreyDateInputBackground) => {
  const [greyBg, setGreyBg] = useState(false)

  useEffect(() => {
    if (greyBackground && !Boolean(value)) {
      setGreyBg(true)
    } else {
      setGreyBg(false)
    }
  }, [value, greyBackground])

  return { greyBg }
}
