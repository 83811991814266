import React from "react"

import { getFilterStateFromLocalStorage } from "api/helpers"

import { Filters } from "Components/SideFilter/types"

import { isEmpty } from "lodash"

import {
  GuestHistoryTabItems,
  GuestsHistoryFilter,
  GuestsHistoryFilterData,
  GuestsHistoryReport
} from "./types"

import { ReactComponent as NotActiveFilter } from "./img/notActiveFilter.svg"
import { ReactComponent as NoGuests } from "./img/noGuests.svg"
import { ReactComponent as NoPeriod } from "./img/empty_period.svg"
import { ReactComponent as EmptyChart } from "./img/empty_chart.svg"

export const GUEST_HISTORY_FILTER = `GUEST_HISTORY_FILTER`

export const initialFilterValues: GuestsHistoryFilterData = {
  dates: undefined,
  phone: undefined,
  games: [],
  onlyActiveDays: false,
  includeFreebets: false
}

export const initialFilter = (): GuestsHistoryFilter => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    GUEST_HISTORY_FILTER,
    true
  )

  const haveFilterFromStorage = !isEmpty(initFilterStateFromStorage)

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: haveFilterFromStorage,
    data: {
      ...initialFilterValues,
      ...initFilterStateFromStorage
    }
  }
}

export const getFiltersByTab = (tab: GuestHistoryTabItems): Filters[] => {
  const defaultFilter = [
    Filters.phoneInput,
    Filters.rangePicker,
    Filters.shiftSwitch
  ]
  if (tab === GuestHistoryTabItems.metric) {
    return [Filters.addCustomItem, Filters.shiftSwitch]
  } else if (tab === GuestHistoryTabItems.bets) {
    return [
      Filters.addCustomItem,
      Filters.shiftSwitch,
      Filters.gamesPicker,
      Filters.customItem
    ]
  } else if (tab === GuestHistoryTabItems.cashbox) {
    return [Filters.addCustomItem, Filters.shiftSwitch, Filters.customItem]
  } else {
    return defaultFilter
  }
}

export const filterLabels: { [key: string]: string } = {
  [Filters.phoneInput]: `Телефон гостя`,
  [Filters.shiftSwitch]: `Начало смены`
}

export const initialReport: GuestsHistoryReport = {
  pending: false,
  total: 0,
  data: undefined
}

export const notActiveFilterConstants = {
  title: "Чтобы получить историю гостя, необходимо ввести его номер телефона",
  description:
    "История гостя содержит в себе пользовательские метрики, историю по кассе и историю ставок",
  icon: <NotActiveFilter />
}

export const noGuestsConstants = {
  desktop: {
    title: `Гость не найден`,
    subtitle: `В вашем клубе гость с таким номером телефона не найден`,
    icon: <NoGuests />
  },
  mobile: {
    title: `Гость не найден`,
    subtitle: `В вашем клубе гость с \nтаким номером телефона не найден`,
    icon: <NoGuests />
  }
}

export const dataWithoutPeriod = {
  desktop: {
    title: `Не выбран период`,
    subtitle: `Для построения графика/таблицы\n дополнительно укажите в фильтрах\n период отчета`,
    icon: <NoPeriod />
  },
  mobile: {
    title: `Не выбран период`,
    subtitle: `Для построения графика/таблицы\n дополнительно укажите в фильтрах\n период отчета`,
    icon: <NoPeriod />
  }
}

export const dataEmptyChart = {
  desktop: {
    title: `Нет данных`,
    subtitle: `За необходимый период по данному клубу\n нет данных для построения графика`,
    icon: <EmptyChart />
  },
  mobile: {
    title: `Нет данных`,
    subtitle: `За необходимый период\n по данному клубу нет данных\n для построения графика`,
    icon: <EmptyChart />
  }
}

export const guestHistoryText = {
  title: `История гостя`,
  tooltip: `Отчет представляет собой набор статистических данных для анализа игрового поведения гостя, зарегистрированного в клубе. Из отчета можно узнать даты первого и последнего посещения, дату рождения, статус в программе лояльности, его игровую активность и предпочтения, историю пополнений и выплат, историю ставок и т.д.. На главной странице также показан набор метрик за весь период, график прибыли клуба и информационные дашборды о текущем состоянии денежного, бонусного и фрибет балансов гостя. По гостю возможно оставить комментарий до 100 символов для того, чтобы зафиксировать важную информацию, например, о его предпочтении в напитках, играх и т.д.. В истории сохранятся три последних комментария и дата их добавления. Начало смены для просмотра данных всегда 08:00 по московскому времени.`
}
