import React from "react"
import HighchartsReact from "highcharts-react-official"
import * as Highcharts from "highcharts"
import { RegStatProps, RegStatSeries } from "../types"
import { textConstants } from "../constants"

const RegStat: React.FC<RegStatProps> = ({ regStatState }: RegStatProps) => {
  const seriesData: RegStatSeries = []
  let allCount = 0

  regStatState.chart.forEach(item => {
    if (item.name === textConstants.visitsType.responseAll) {
      allCount = item.count
    }
  })

  regStatState.chart.forEach(item => {
    if (item.name !== textConstants.visitsType.responseAll) {
      seriesData.push({
        y: item.count,
        name: item.name,
        color:
          item.name === textConstants.visitsType.new ? `#FFA94D` : `#748FFC`
      })
    }
  })

  const formatTitle = (value: number, words: string[]) => {
    const valueAbs = Math.abs(value) % 100
    const num = valueAbs % 10
    if (valueAbs > 10 && valueAbs < 20) return words[2]
    if (num > 1 && num < 5) return words[1]
    if (num === 1) return words[0]
    return words[2]
  }

  const titleChartText = `<div class="RegStatTitle">${allCount}</div><div class="RegStatSubTitle">${formatTitle(
    allCount,
    textConstants.titleRegLastWords
  )}</div>`

  const options: Highcharts.Options = {
    tooltip: {
      enabled: false
    },
    chart: {
      plotShadow: false,
      height: 285,
      type: "pie"
    },
    title: {
      useHTML: true,
      text: titleChartText,
      verticalAlign: "middle"
    },
    plotOptions: {
      pie: {
        dataLabels: {
          formatter: function() {
            const pct = Math.floor(((this.point.y as number) / allCount) * 100)
            const className =
              this.point.name === textConstants.visitsType.new
                ? "regStatLabelNew"
                : "regStatLabelUnique"
            if (!pct) return ""
            return `<span class="regStatLabel ${className}">${pct} %</span>`
          },
          enabled: true,
          distance: -30
        },
        allowPointSelect: true,
        slicedOffset: 5,
        startAngle: 180,
        cursor: "pointer",
        fillColor: !!allCount ? "" : "#CED4DA",
        innerSize: 130,
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            halo: null,
            opacity: 1,
            brightness: 0
          },
          select: {
            halo: null
          }
        }
      }
    },
    series: [
      {
        type: "pie",
        data: seriesData
      }
    ]
  }

  return (
    <div className="CashboxChartsWrapper RegStatChartWrapper">
      <HighchartsReact highcharts={Highcharts} options={options} />
      {!!allCount && (
        <div className="regStat_legendBox">
          <div className="regStat_legendInfo">
            <div className="regStat_legendColor regStat_legendColorNew"></div>
            <div className="regStat_legendName">
              {textConstants.regStat.newTitle}
            </div>
          </div>
          <div className="regStat_legendInfo">
            <div className="regStat_legendColor regStat_legendColorUnique"></div>
            <div className="regStat_legendName">
              {textConstants.regStat.uniqueTitle}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RegStat
