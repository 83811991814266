import React, { useEffect, useState } from "react"

import { useRequest, useRequestParams } from "api/hooks"
import { parseOneDateWithoutGmtToBack } from "constants/index"

import EmptyTable from "Components/EmptyTable"

import { dataEmptyChart } from "Containers/GuestsHistory/constants"

import GuestHistoryBetsByTime from "./chart"
import { initialData } from "./constants"
import {
  BetsByTimeDataRequestData,
  BetsByTimeDataState,
  BetsChartProps
} from "./types"

const BetsChart = ({ filter, activeBest, activeWins }: BetsChartProps) => {
  const [chartData, setChartData] = useState<BetsByTimeDataState>(initialData)

  const { requestCurrentHall } = useRequestParams()
  const { request: getChartsData } = useRequest({
    url: `v1/guests/history/bets/time`,
    requestBody: {
      hall: requestCurrentHall,
      ...filter,
      dates: !!filter.dates
        ? parseOneDateWithoutGmtToBack(filter.dates)
        : undefined,
      onlyActiveDays: undefined
    },
    method: `POST`
  })

  const requestDeps = JSON.stringify(filter)

  useEffect(() => {
    setChartData(prevState => ({ ...prevState, pending: true }))
    getChartsData()
      .then(({ data }: { data: BetsByTimeDataRequestData[] }) => {
        setChartData({ pending: false, data })
      })
      .catch(() => setChartData(prevState => ({ ...prevState, pending: true })))
  }, [requestDeps])

  return (
    <div className="GuestHistoryBetsBySumWrapper">
      {!chartData.pending && !!chartData.data?.length && (
        <GuestHistoryBetsByTime
          isFreebetActive={filter.includeFreebets}
          chartData={chartData.data}
          activeBest={activeBest}
          activeWins={activeWins}
        />
      )}
      {!chartData.pending && !chartData.data?.length && (
        <div className="GuestHistoryEmptyDataWrapper">
          <EmptyTable
            mobile={dataEmptyChart.mobile}
            desktop={dataEmptyChart.desktop}
          />
        </div>
      )}
    </div>
  )
}

export default BetsChart
