import { ButtonPrimary } from "Components/Button"
import { ModalFormComponent } from "Components/Modal"
import React from "react"
import { MoreModalProps } from "./types"
import { tableComponentsConstants } from "./constants"

export const MoreModal: React.FC<MoreModalProps> = ({
  comment,
  onCloseModal
}) => (
  <ModalFormComponent width={545} contentClassName="HistoryModal">
    <div className="HistoryModalContainer">
      <div className="HistoryModalContainer__header">
        {tableComponentsConstants.moreTitle}
      </div>
      <div className="HistoryModalContainer__body">{comment}</div>
      <div className="HistoryModalContainer__footer">
        <ButtonPrimary
          className="HistoryModalContainer__footer__button"
          onClick={onCloseModal}
        >
          {tableComponentsConstants.rogerThat}
        </ButtonPrimary>
      </div>
    </div>
  </ModalFormComponent>
)
