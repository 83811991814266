export enum SuperwinTypes {
  main = `main`,
  vip = `vip`
}

export type SuperWinTableDataProps = {
  price: string
  sum_per: number
  sum_back: number
}

export type SuperWinTableProps = {
  data: SuperWinTableDataProps[]
  isVip?: boolean
}

export interface SuperWinData {
  [key: string]: SuperWinTableDataProps[]
}

export interface SuperWinTwoData {
  [SuperwinTypes.main]: SuperWinData
  [SuperwinTypes.vip]: SuperWinData
}

export interface SuperWinState {
  pending: boolean
  data: SuperWinTwoData
}
