export const initialValues = {
  tv: undefined,
  receiver_number: undefined,
  cart_number: undefined,
  is_base_plus: false
}

export const fieldText = {
  editTitle: `Редактирование трансляции`,
  addTitle: `Новая трансляция`,
  receiver_number: {
    title: `№ ресивера`,
    field: `receiver_number`,
    placeholder: `Не более 20 символов`,
    rules: [
      {
        validator: <T>(_: T, value?: string) => {
          const spacesRegex = /\s/
          if (!!value && spacesRegex.test(value)) {
            return Promise.reject(new Error(`Пробелы не допустимы.`))
          } else {
            return Promise.resolve()
          }
        }
      }
    ]
  },
  cart_number: {
    title: `№ карты`,
    field: `cart_number`,
    placeholder: `Не более 12 цифр`
  },
  is_base_plus: {
    title: `«Базовый + Суперспорт. Букмекер»`,
    field: `is_base_plus`
  },
  add: `Добавить`,
  save: `Сохранить`
}

export const cancelEditText = {
  title: `Выйти из редактирования?`,
  description: `Уверены, что хотите выйти из редактирования трансляции? Изменения не сохранятся.`,
  approve: `Выйти`,
  cancel: `Отмена`
}

export const successText = {
  title: `Трансляция добавлена`,
  edit_title: `Трансляция отредактирована`,
  button: `Хорошо`
}
