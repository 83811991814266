import TextSearch from "../TextSearch"
import React from "react"
import { HeaderTypesList, SearchHeaderProps } from "../types"
import SelectSearch from "../SelectSearch"
import RangeDateSearch from "../RangeDateSearch"

const SearchHeaderComponent = ({
  value,
  toggleShowSearch,
  title,
  clearSearch,
  selectOptions,
  setSearch,
  search,
  setValue,
  type,
  popupClassName,
  passedClassName
}: SearchHeaderProps) => {
  switch (type) {
    case HeaderTypesList.SelectSearch:
      return (
        <SelectSearch
          value={value}
          toggleShowSearch={toggleShowSearch}
          title={title}
          selectOptions={selectOptions}
          setSearch={setSearch}
          popupClassName={popupClassName}
          passedClassName={passedClassName}
        />
      )
    case HeaderTypesList.RangeDateSearch:
      return (
        <RangeDateSearch
          value={value}
          title={title}
          setSearch={setSearch}
          toggleShowSearch={toggleShowSearch}
        />
      )
    default:
      return (
        <TextSearch
          search={search}
          clearSearch={clearSearch}
          value={value}
          setValue={setValue}
          setSearch={setSearch}
          toggleShowSearch={toggleShowSearch}
          title={title}
        />
      )
  }
}

export default SearchHeaderComponent
