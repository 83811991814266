import React from "react"
import { nominalsText } from "../constants"
import { TemplateCardTableRowProps } from "../types"
import { moneyRender } from "constants/index"

export const TemplateCardTableRow: React.FC<TemplateCardTableRowProps> = ({
  limits,
  title
}) => (
  <>
    <thead className="NominalsTemplateCard_NumbersTable_MinMaxHead">
      {title}
    </thead>
    <tbody>
      <tr>
        {limits?.map((el, idx) => (
          <td key={idx}>
            <div className="NominalsTemplateCard_NumbersTable_Cell NominalsTemplateCard_NumbersTable_Cell_Currency_Wrapper">
              <div className="NominalsTemplateCard_NumbersTable_Cell_Value">
                {moneyRender(el.value)}
              </div>
              <div className="NominalsTemplateCard_NumbersTable_Cell_Currency">
                {nominalsText.rubles}
              </div>
            </div>
          </td>
        ))}
      </tr>
    </tbody>
  </>
)
