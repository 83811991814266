import { SortOrders } from "Components/TableNew/types"
import { PaginationValue } from "Components/Pagination/types"
import { Moment } from "moment"

export type PayrollFile = {
  name: string
  url: string
  size: number
  mime_type: string
}

export enum PayrollTableStatus {
  delivered = `delivered`,
  denied = `denied`,
  failed = `failed`,
  success = `success`,
  new = `new`
}

export enum PayrollTableFields {
  document_number = `document_number`,
  hall = `hall`,
  sum = `sum`,
  billing_period = `billing_period`,
  preparation_date = `preparation_date`,
  created_date = `created_date`,
  files = `files`,
  status = `status`,
  status_reason = `status_reason`
}

export type FilterTableSort = {
  order?: SortOrders
  field?: string
}

export type FilterTable = {
  [PayrollTableFields.document_number]?: string
  [PayrollTableFields.hall]?: string
  [PayrollTableFields.sum]?: {
    from?: number
    to?: number
  }
  [PayrollTableFields.billing_period]?: Moment
  [PayrollTableFields.preparation_date]?: {
    from: Moment
    to: Moment
  }
  [PayrollTableFields.status]?: string
  sort: FilterTableSort
  [PayrollTableFields.files]?: string
  [PayrollTableFields.created_date]?: string
  [PayrollTableFields.status_reason]?: string
}

export type FilterState = {
  table: FilterTable
  pagination: PaginationValue
}

export type PayrollTableData = {
  billing_period: string
  created_date: string
  document_number: string
  files: PayrollFile[]
  hall: string
  preparation_date: string
  status: PayrollTableStatus
  status_reason: string
  sum: string
}

export type PayrollTableState = {
  total_count: number
  pending: boolean
  data: PayrollTableData[]
}
