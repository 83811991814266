import React from "react"

import { FilterData } from "../types"
import { TableColumns, tableConstants } from "../constants"

import moment from "moment"

export const dateDescription = (
  val: { from: string | null; to: string | null } | undefined,
  sort?: string
) => {
  return (
    <div>
      <span className="SubtitleSecondText">{sort || ``}</span>
      {!!val?.from && (
        <div className="SubtitleSecondText FlexRow SportboomFilterClearDescription">
          <p>
            {`Дата и время от «`}
            <span className="Cyan600Color BodyFirstText">
              {moment(val.from, `YYYY-MM-DDTHH:mm:ss`).format(
                `DD.MM.YYYY HH:mm`
              )}
            </span>
            {`»`}
          </p>
        </div>
      )}
      {!!val?.to && (
        <div className="SubtitleSecondText FlexRow SportboomFilterClearDescription">
          <p>
            {`Дата и время до «`}
            <span className="Cyan600Color BodyFirstText">
              {moment(val.to, `YYYY-MM-DDTHH:mm:ss`).format(`DD.MM.YYYY HH:mm`)}
            </span>
            {`»`}
          </p>
        </div>
      )}
    </div>
  )
}
export const fromToDescription = (
  val:
    | {
        from?: number
        to?: number
      }
    | undefined,
  sort?: string
) => (
  <div className="FlexColumn">
    <span className="SubtitleSecondText">{sort || ``}</span>
    <div className="FlexRow">
      {!!val?.from && (
        <div className="FlexRow SubtitleSecondText SportboomFilterClearDescription">
          <p>
            {`Диапазон от «`}
            <span className="Cyan600Color BodyFirstText">{val.from}</span>
            {`»`}&nbsp;
          </p>
        </div>
      )}
      {!!val?.to && (
        <div className="FlexRow SubtitleSecondText SportboomFilterClearDescription">
          <p>
            {!val?.from && !sort ? sort : ``}
            {!val?.from ? `Диапазон до «` : ` до «`}
            <span className="Cyan600Color BodyFirstText">{val.to}</span>
            {`»`}
          </p>
        </div>
      )}
    </div>
  </div>
)

export const searchDescription = (
  val:
    | {
        value?: string | null
        equal?: boolean
      }
    | undefined,
  sort?: string
) => (
  <div className="FlexRow">
    {!!val?.value && (
      <div className="FlexRow SubtitleSecondText SportboomFilterClearDescription">
        <p>
          {sort || ``}
          {!!val?.equal || val?.equal === undefined
            ? `${sort ? `с` : `С`}одержит «`
            : `${sort ? `н` : `Н`}е содержит «`}

          <span className="Cyan600Color BodyFirstText">{val.value}</span>
          {`»`}
        </p>
      </div>
    )}
  </div>
)

export const checkDescription = (val: string[], sort?: string) => (
  <div className="FlexRow">
    <div className="FlexRow SubtitleSecondText SportboomFilterClearDescription">
      <p>
        {sort || ``}
        {`${sort ? `с` : `С`}одержит «`}
        <span className="Cyan600Color BodyFirstText">{val.join(`, `)}</span>
        {`»`}
      </p>
    </div>
  </div>
)

const sortTitle = (order: `asc` | `desc`) =>
  `Сортировка по ${order === `asc` ? `возрастанию` : `убыванию`}`

interface FilterDataWithSort {
  sort?: string
  title: string
  field: TableColumns
}

export const getFilterClear = (filter: FilterData) => {
  const arrayOfFields: FilterDataWithSort[] = []
  const allKeys = Object.keys(filter.table) as TableColumns[]

  allKeys.forEach((key: TableColumns) => {
    const currentFilterNotEmpty = !!filter.table[key]
    const checkConst = tableConstants[key]
    if (!!checkConst && (currentFilterNotEmpty || filter.sort.field === key)) {
      const item = {
        title: checkConst.label,
        field: key
      }
      if (filter.sort && filter.sort.order && filter.sort.field === key) {
        const sort = sortTitle(filter.sort.order)
        arrayOfFields.push({ ...item, sort })
      } else {
        arrayOfFields.push({ ...item })
      }
    }
  })
  return arrayOfFields
}
