import { text } from "../constants"
import { SelectComponent } from "Components/Select"
import React from "react"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"
import { WikiSelectProps } from "../types"
import { WIKI } from "Containers/BaseLayout/constants"

export const WikiSelect: React.FC<WikiSelectProps> = ({
  options,
  history,
  filter,
  onSelectChange
}) => {
  return (
    <SelectComponent
      className={`WikiSearchSelect ${filter ? "WikiSearchSelectFilter" : ""}`}
      suffixIcon={<SearchIcon />}
      placeholder={text.optionsTooltip}
      filterOption={(input, option) =>
        ((option?.label ?? "") as string)
          .toLowerCase()
          ?.includes(input.toLowerCase())
      }
      options={options}
      onChange={value => {
        if (value) {
          onSelectChange && onSelectChange()
          history.push(`${WIKI}${value}`)
        }
      }}
      showSearch
      allowClear
      autoClearSearchValue
    />
  )
}
