import React from "react"
import { contactsBlockProps } from "Containers/Contacts/types"
import { ReactComponent as EditIcon } from "Components/icons/write_outline.svg"
import ContactsPerson from "../ContactsPerson"

const ContactsBlock = ({
  contactsState,
  setContactsState,
  block,
  section
}: contactsBlockProps) => {
  return (
    <div className="ContactsPageSectionBlock">
      {contactsState.isEditing && (
        <div
          onClick={() =>
            setContactsState({
              ...contactsState,
              activeModal: true,
              isBlockEditing: true,
              editSectionId: section.section_id,
              editBlockId: block.block_id
            })
          }
          className="ContactsPageEditIcon"
        >
          <EditIcon />
        </div>
      )}
      {block.title && (
        <div className="ContactsPageSectionBlock_title">{block.title}</div>
      )}
      {block.description && (
        <div className="ContactsPageSectionBlock_description">
          {block.description}
        </div>
      )}
      {!!block.staff.length && (
        <div className="ContactsPageSectionTotalStaffWrapper">
          {block.staff.map(person => (
            <ContactsPerson key={`person_${person.staff_id}`} person={person} />
          ))}
        </div>
      )}
    </div>
  )
}

export default ContactsBlock
