import React from "react"
import { UsersRowProps } from "./types"
import { ReactComponent as UserIcon } from "../icons/user.svg"
import { ReactComponent as AdditionalOneUserIcon } from "../icons/additional_one_user.svg"
import { mailingHallContentConstants } from "./helper"
import { ReactComponent as AdditionalManyUserIcon } from "../icons/additional_many_user.svg"

const UsersRow = ({ users, active }: UsersRowProps) => {
  const length = users.length

  const usersClass = active
    ? !!length
      ? `MailingHallUserActiveColor`
      : `MailingHallUserEmptyActiveColor`
    : `MailingHallUserInactiveColor`

  const isOneAddUsers = length === 2
  const isManyAddUsers = length > 2

  const userRowClass =
    isOneAddUsers || isManyAddUsers
      ? `MailingHallAddUserText`
      : `MailingHallUserText`

  return (
    <div className="MailingHallItemField">
      {!!length ? (
        <div className="MailingHallUsersWrapper FlexRow">
          <div className={`MailingHallUser FlexRow ${usersClass}`}>
            <UserIcon />
            <span className={userRowClass}>{users[0]}</span>
          </div>
          {isOneAddUsers && (
            <div
              className={`MailingHallUser MailingHallAdditional FlexRow ${usersClass}`}
            >
              <AdditionalOneUserIcon />
              <span className={userRowClass}>
                {mailingHallContentConstants.userRowText.additionalOne}
              </span>
            </div>
          )}
          {isManyAddUsers && (
            <div
              className={`MailingHallUser MailingHallAdditional FlexRow ${usersClass}`}
            >
              <AdditionalManyUserIcon />
              <span className={userRowClass}>
                {mailingHallContentConstants.userRowText.additionalMore(
                  length - 1
                )}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="MailingHallUsersWrapper FlexRow">
          <div className={`MailingHallUser FlexRow ${usersClass}`}>
            <span>{mailingHallContentConstants.empty}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default UsersRow
