import React, { useEffect } from "react"
import { ReactComponent as SuccessIcon } from "../success.svg"
import { ButtonPrimary } from "Components/Button"

const SuccessNotifiModal = ({
  refresh,
  close
}: {
  refresh: () => void
  close: () => void
}) => {
  useEffect(() => {
    const waitForIt = setTimeout(() => {
      refresh()
      close()
    }, 2000)
    return () => {
      clearTimeout(waitForIt)
    }
  }, [])
  return (
    <div className="NotifiCrudModalSuccessWrapper">
      <div className="FlexColumn">
        <div className="NotifiCrudModalSuccessTitle">Сообщение отправлено</div>
        <div className="NotifiCrudModalSuccessImg">
          <SuccessIcon />
        </div>

        <div className="NotifiCrudModalSuccessButton">
          <ButtonPrimary
            onClick={() => {
              close()
              refresh()
            }}
          >
            К списку сообщений
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

export default SuccessNotifiModal
