import { isEmpty } from "lodash"

import { getFilterStateFromLocalStorage } from "api/helpers"

import { FilterType } from "./types"

export const initialFilterValues = {
  dates: undefined,
  phone: undefined
}

export const getInitialFilter = (filterStorageName: string) => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    filterStorageName
  )

  const haveFilterFromStorage = !isEmpty(initFilterStateFromStorage)

  if (!haveFilterFromStorage) {
    return {
      isOpen: true,
      mobileFilter: false,
      isActive: false,
      data: { type: FilterType.hall }
    }
  } else {
    const havePhone = !!initFilterStateFromStorage.phone
    return {
      isOpen: true,
      mobileFilter: false,
      isActive: true,
      data: {
        ...initFilterStateFromStorage,
        type: havePhone ? FilterType.guest : FilterType.hall
      }
    }
  }
}
