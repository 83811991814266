import React from "react"
import TooltipComponent from "Components/Tooltip"
import InputComponent from "Components/Input"
import { formatPhone } from "constants/index"
import { CreateStep8Props } from "../../types"
import { smsConstants } from "../../constants"
import { ReactComponent as InfoIcon } from "Components/icons/help_circle_gray.svg"

const Step8 = ({
  smsState,
  setSmsState,
  stepNumber,
  showStep
}: CreateStep8Props) => {
  return (
    <div className="CreateSms_step">
      <div
        className={
          showStep ? "CreateSms_stepTitle" : "CreateSms_stepTitle_inactive"
        }
      >
        <span>{stepNumber}</span>
        <span>{smsConstants.step8.title}</span>
        <TooltipComponent title={smsConstants.step8.tooltip} direction="bottom">
          <InfoIcon />
        </TooltipComponent>
      </div>

      {showStep ? (
        <section className="CreateSms_inputWrapper">
          <div className="CreateSms_input">
            <label className="CreateSms_inputLabel">
              {smsConstants.step8.fullName}
            </label>
            <InputComponent
              greyBackground
              value={smsState.contactName}
              onChange={({ target: { value } }) => {
                setSmsState({
                  ...smsState,
                  contactName: value
                })
              }}
              placeholder={smsConstants.step8.namePlaceholder}
            />
          </div>

          <div className="CreateSms_input">
            <label className="CreateSms_inputLabel">
              {smsConstants.step8.phoneTitle}
            </label>
            <InputComponent
              greyBackground
              error={smsState.contactPhoneError}
              className="CreateSms_phoneInput"
              placeholder={smsConstants.step8.phonePlaceholder}
              value={smsState.contactPhone}
              onChange={({ target: { value } }) => {
                setSmsState({
                  ...smsState,
                  contactPhone: formatPhone(value),
                  contactPhoneError: ""
                })
              }}
            />
          </div>
        </section>
      ) : (
        <hr className="CreateSms_divider" />
      )}
    </div>
  )
}

export default Step8
