import React from "react"

type TableCardHeaderProps = {
  prefix?: React.ReactNode
  text: string
  headerClassName?: string
}

const TableCardHeader = ({
  text,
  prefix,
  headerClassName
}: TableCardHeaderProps) => {
  return (
    <div className="FlexRow">
      {prefix || <div />}
      <div className={headerClassName}>{text}</div>
    </div>
  )
}

export default TableCardHeader
