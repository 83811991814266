import { CardState } from "./types"

export const validatePhotos = (
  minPhotos: number,
  photoArr: CardState[]
): boolean => {
  let countPhotos = 0
  photoArr.map(photo => {
    if (!!photo.image || !!photo.link) {
      countPhotos += 1
    }
  })

  return countPhotos >= minPhotos
}

// Сравнивает исходный массив с карточками с массивом после изменения
export const compareArrays = (
  arr1: CardState[],
  arr2: CardState[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].link !== arr2[i].link) {
      return false
    }
    if (arr1[i].image !== arr2[i].image) {
      return false
    }

    if (arr1[i].zone !== arr2[i].zone) {
      return false
    }
  }

  return true
}
