/* eslint react/prop-types: "off" */
import React, { useState } from "react"
import { Select, TreeSelect } from "antd"
import { OptionProps } from "antd/lib/select"
import { useGreyInputBackground } from "../Input/helpers"

import { SelectComponentProps } from "./types"

import { ReactComponent as PaginationSelectArrowIcon } from "./dropdown_16.svg"
import { ReactComponent as SelectArrowIcon } from "./dropdown_20.svg"

export const CustomOption = ({
  value,
  title,
  description,
  className
}: OptionProps) => {
  return (
    <Select.Option value={value} className={className}>
      <div>{title}</div>
      <div>{description}</div>
    </Select.Option>
  )
}

export const SelectComponent = ({
  children,
  placeholder,
  view,
  error = false,
  isMiddle,
  isPagination,
  large,
  value,
  greyBackground,
  passedClassName,
  defaultValue,
  disabled,
  allowNullValue,
  className,
  ...rest
}: SelectComponentProps) => {
  const { greyBg } = useGreyInputBackground({
    value: value,
    greyBackground,
    allowNullValue
  })
  const [focused, setFocused] = useState(false)

  return (
    <div
      className={`SelectWrapper ${error ? `ErrorSelectWrapper` : ``} ${
        view ? `ViewSelectWrapper` : ``
      } ${isPagination ? `PaginationSelectSmallWrapper` : ``} ${
        isMiddle ? `SelectMiddleWrapper` : ``
      } ${large ? "SelectLargeWrapper" : ""} ${
        !focused && greyBg ? "SelectWrapperGreyBackground" : ""
      } ${passedClassName ? passedClassName : ""} ${
        disabled && greyBackground ? `SelectGreyWrapperDisabled` : ``
      } ${className || ""}`}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <Select
        className="SelectComponent"
        placeholder={placeholder}
        allowClear={true}
        suffixIcon={
          isPagination ? (
            <PaginationSelectArrowIcon />
          ) : (
            <SelectArrowIcon className="SelectSuffixIcon" />
          )
        }
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        getPopupContainer={trigger => trigger.parentNode}
        {...rest}
      >
        {children}
      </Select>
      {!!error && <div className="ErrorText">{error}</div>}
    </div>
  )
}

export const TreeSelectComponent = ({ error = false, ...rest }) => (
  <div className={`TreeSelectWrapper ${error ? `ErrorSelectWrapper` : ``}`}>
    <TreeSelect suffixIcon={<SelectArrowIcon />} {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const HallsMultiSelect = ({ ...rest }) => {
  const halls = JSON.parse(localStorage.getItem(`halls`) || `[]`).map(
    (item: number) => ({
      label: `${item}`,
      value: item
    })
  )
  return (
    <div>
      <SelectComponent
        options={halls}
        mode="multiple"
        className={`HallsMultiSelectWrapper ${rest.className || ``}`}
        suffixIcon={<SelectArrowIcon />}
        {...rest}
      />
    </div>
  )
}
