import React, { useCallback, useEffect, useState } from "react"
import ModalComponent from "../Modal"
import { StaticDashboardModalProps } from "./types"
import TableNew from "../TableNew"
import ButtonDefault, { ButtonPrimary } from "../Button"
import { staticDashboardModalText } from "./constants"
import { ReactComponent as NotFoundIcon } from "Components/icons/illustrations/not_found.svg"

const StaticDashboardModal: React.FC<StaticDashboardModalProps> = ({
  onClose,
  data
}) => {
  const [activeHall, setActiveHall] = useState(data.selectedHalls[0])

  const [tableData, setTableData] = useState(data.data)

  useEffect(() => {
    const filteredData = data.data.filter(el => el.hall === Number(activeHall))
    if (filteredData.length) {
      setTableData(filteredData)
    } else {
      setTableData([])
    }
  }, [activeHall])

  const handleHallClick = useCallback(hall => {
    setActiveHall(hall)
  }, [])

  const handleRedirectClick = useCallback(() => {
    if (data.onRedirect) {
      data.onRedirect()
    }
  }, [data.onRedirect])

  return (
    <ModalComponent
      close={onClose}
      width={700}
      className="StaticDashboardModalContainerModal"
    >
      <div className="StaticDashboardModalContainer">
        <div className="HeadlineThirdText">{data.title}</div>
        {data.selectedHalls.length > 1 && (
          <div className="StaticDashboardHallsContainer">
            {data.selectedHalls.map((el, idx) => (
              <div
                key={idx + `${activeHall}`}
                className={`StaticDashboardHallsSingleHall ${
                  activeHall === el
                    ? "StaticDashboardHallsSingleHall_active"
                    : ""
                }`}
                onClick={handleHallClick.bind(null, el)}
              >
                <div className="StaticDashboardHallsSingleHall_number">
                  {staticDashboardModalText.number}
                </div>
                <div>{el}</div>
              </div>
            ))}
          </div>
        )}
        {tableData.length ? (
          <TableNew
            withoutFiltersHeader={true}
            columns={data.columns}
            data={tableData}
          />
        ) : (
          <div className="StaticDashboardEmptyTableContainer">
            <div className="StaticDashboardEmptyTableContainerChild">
              <NotFoundIcon />
              <div className="SubtitleSecondText">
                {staticDashboardModalText.notFound}
              </div>
            </div>
          </div>
        )}
        <div className="StaticDashboardHallsSingleHall_ButtonsContainer">
          <ButtonDefault
            onClick={onClose}
            className="StaticDashboardHallsSingleHall_CancelButton"
          >
            {staticDashboardModalText.closeButton}
          </ButtonDefault>
          <ButtonPrimary
            onClick={handleRedirectClick}
            className="StaticDashboardHallsSingleHall_RedirectButton"
          >
            {staticDashboardModalText.redirectButton}
          </ButtonPrimary>
        </div>
      </div>
    </ModalComponent>
  )
}

export default StaticDashboardModal
