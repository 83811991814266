import { useRequest } from "api/hooks"

import {
  CheckboxDict,
  GetInitialActiveFields,
  MailingModalActiveFields,
  MailingModalData
} from "./types"
import { modalActiveFieldsInitial, mailingModalText } from "./constant"
import { useMemo } from "react"

export const useCreateMail = ({
  data,
  activeFields,
  isEditCurrent
}: {
  data: MailingModalData
  activeFields: MailingModalActiveFields
  isEditCurrent: boolean
}) => {
  const url = useMemo(
    () =>
      !!data.id && !!data?.halls[0]
        ? mailingModalText.form.submit.urlById(data.id, data.halls[0])
        : mailingModalText.form.submit.url,
    [isEditCurrent]
  )

  const requestBody = {
    ...data,
    withdrawal: activeFields.withdrawal ? data.withdrawal : null,
    replenishment: activeFields.replenishment ? data.replenishment : null,
    win: activeFields.win ? data.win : null,
    email: activeFields.email ? data.email : null,
    phone: activeFields.phone ? data.phone : null
  }

  const { request: createMail } = useRequest({
    url,
    method: `PUT`,
    requestBody
  })

  return createMail
}

export const getInitialActiveFields = ({
  data,
  isNew
}: GetInitialActiveFields) => {
  if (isNew) {
    return modalActiveFieldsInitial
  } else {
    return {
      replenishment: !!data?.replenishment,
      withdrawal: !!data?.withdrawal,
      win: !!data?.win,
      phone: !!data?.phone,
      email: !!data?.email
    }
  }
}

export const formRules = {
  type: [
    {
      validator: <T, D>(_: T, value: D) =>
        value && ((value as unknown) as string).trim()
          ? Promise.resolve()
          : Promise.reject(new Error(mailingModalText.rules.type))
    }
  ],
  mailing: [
    {
      validator: <T, D>(_: T, value: D) =>
        value && ((value as unknown) as string).trim()
          ? Promise.resolve()
          : Promise.reject(new Error(mailingModalText.rules.mailing))
    }
  ],
  halls: [
    {
      validator: <T>(_: T, value: number[]) => {
        return value && !!((value as unknown) as number[]).length
          ? Promise.resolve()
          : Promise.reject(new Error(mailingModalText.rules.hall))
      }
    }
  ]
}

export const getClearByType = (isType: boolean) =>
  isType
    ? [
        {
          name: CheckboxDict.replenishment,
          errors: []
        },
        {
          name: CheckboxDict.withdrawal,
          errors: []
        },
        {
          name: CheckboxDict.win,
          errors: []
        }
      ]
    : [
        {
          name: CheckboxDict.email,
          errors: []
        },

        {
          name: CheckboxDict.phone,
          errors: []
        }
      ]
