import React, { useEffect, useMemo, useState } from "react"
import uniq from "lodash/uniq"

import { isMobile } from "constants/index"

import {
  CheckboxDict,
  MailingModalActiveFields,
  MailingModalData,
  MailingModalProps,
  MailingModalState
} from "./types"

import FieldCheckBox from "./FieldCheckBox"

import { getModalTitle, mailingModalText, modalDataInitial } from "./constant"
import { formRules, getInitialActiveFields, useCreateMail } from "./helper"
import { ReactComponent as SuccessIcon } from "../Halls/icons/success.svg"

import { rules } from "Components/Form/rules"
import { ModalFormComponent } from "Components/Modal"
import InputComponent, {
  CurrencyInputComponent,
  InputMaskComponent,
  prepareStringCurrencyToNumber
} from "Components/Input"
import { SelectComponent } from "Components/Select"
import { ButtonPrimary } from "Components/Button"

import { Form } from "antd"

const MailingModal: React.FC<MailingModalProps> = ({
  successRefresh,
  close,
  initial
}) => {
  const isNew = !initial?.id

  const [modalData, setModalData] = useState<MailingModalState>({
    data: initial || modalDataInitial,
    pending: false,
    request: false
  })
  const initialActiveFields = useMemo(
    () => getInitialActiveFields({ data: initial, isNew }),
    []
  )
  const [activeFields, setActiveFields] = useState<MailingModalActiveFields>(
    initialActiveFields
  )

  const isEditCurrent = !!initial?.id

  const activeSum =
    activeFields.replenishment || activeFields.withdrawal || activeFields.win
  const typeRules = activeSum ? [] : formRules.type
  const activeData = activeFields.email || activeFields.phone
  const mailingRules = activeData ? [] : formRules.mailing

  const createMail = useCreateMail({
    data: modalData.data,
    activeFields,
    isEditCurrent
  })

  const [success, switchOnSuccess] = useState(false)

  useEffect(() => {
    if (modalData.request) {
      setModalData(prev => ({ ...prev, pending: true }))
      createMail()
        .then(() => {
          setModalData(prev => ({ ...prev, pending: false }))
          switchOnSuccess(true)
        })
        .catch(error => {
          const hallError =
            error?.response?.data?.errors[mailingModalText.form.halls.field]
          const emailError = error?.response?.data?.errors[CheckboxDict.email]
          const phoneError = error?.response?.data?.errors[CheckboxDict.phone]

          if (!!hallError) {
            form.setFields([
              {
                name: mailingModalText.form.halls.field,
                errors: hallError ? [hallError] : []
              }
            ])
          }
          if (!!emailError) {
            form.setFields([
              {
                name: CheckboxDict.email,
                errors: emailError ? [emailError] : []
              }
            ])
          }
          if (!!phoneError) {
            form.setFields([
              {
                name: CheckboxDict.phone,
                errors: phoneError ? [phoneError] : []
              }
            ])
          }
          setModalData(prev => ({ ...prev, pending: false, request: false }))
        })
    }
  }, [modalData.request])

  useEffect(() => {
    if (success && !!successRefresh) {
      const waitForIt = setTimeout(() => {
        close()
        successRefresh()
      }, 2000)
      return () => {
        clearTimeout(waitForIt)
      }
    }
  }, [success])

  const title = useMemo(() => getModalTitle(isNew), [])
  const [form] = Form.useForm()

  const currentHalls = Form.useWatch(mailingModalText.form.halls.field, form)
  const currentReplenishment = Form.useWatch(CheckboxDict.replenishment, form)
  const currentWithdrawal = Form.useWatch(CheckboxDict.withdrawal, form)
  const currentWin = Form.useWatch(CheckboxDict.win, form)

  const currentPhone = Form.useWatch(CheckboxDict.phone, form)

  return (
    <div>
      <ModalFormComponent
        title={
          success ? undefined : <div className="MailingModalTitle">{title}</div>
        }
        close={success ? undefined : close}
        width={678}
      >
        <div>
          {!success ? (
            <Form
              form={form}
              initialValues={initial || modalDataInitial}
              onFinish={(value: MailingModalData) => {
                const prepareHalls = value.halls.map(item => Number(item))

                const prepareData = {
                  ...value,
                  halls: prepareHalls,
                  win: prepareStringCurrencyToNumber(value.win),
                  withdrawal: prepareStringCurrencyToNumber(value.withdrawal),
                  replenishment: prepareStringCurrencyToNumber(
                    value.replenishment
                  )
                }
                setModalData(prev => ({
                  ...prev,
                  data: prepareData,
                  request: true
                }))
              }}
            >
              <div className="MailingModalContent">
                <Form.Item
                  name={mailingModalText.form.halls.field}
                  rules={formRules.halls}
                >
                  <div className="MailingModalFieldLabel">
                    {mailingModalText.form.halls.label}
                  </div>
                  <SelectComponent
                    disabled={!!initial?.halls?.length}
                    open={false}
                    greyBackground
                    allowClear
                    mode="tags"
                    placeholder={mailingModalText.form.halls.placeholder}
                    value={currentHalls}
                    onChange={value => {
                      if (currentHalls && currentHalls.length < value.length) {
                        const lastEnter = value
                          .pop()
                          .split(`,`)
                          .filter(
                            (item: string) =>
                              !!Number(item) && Number.isInteger(Number(item))
                          )
                          .map((item: any) => item)
                        const newValue = uniq([...value, ...lastEnter])
                        form.setFieldsValue({
                          [mailingModalText.form.halls.field]: newValue
                        })
                      } else {
                        form.setFieldsValue({
                          [mailingModalText.form.halls.field]: value
                        })
                      }
                    }}
                  />
                </Form.Item>
                <div className="MailingModalFieldLabel">
                  {mailingModalText.form.name.label}
                </div>
                <Form.Item
                  name={mailingModalText.form.name.field}
                  rules={rules.required}
                >
                  <InputComponent
                    greyBackground
                    maxLength={mailingModalText.form.name.maxLength}
                    placeholder={mailingModalText.form.name.placeholder}
                  />
                </Form.Item>
                <div
                  className={`${isMobile ? `` : `FlexRow`} MailingModalFields`}
                >
                  <div className="MailingModalLeft">
                    <div className="MailingModalFieldLabelWrapper">
                      <Form.Item
                        name={CheckboxDict.replenishment}
                        rules={typeRules}
                      >
                        <div className="MailingModalFieldLabel">
                          {mailingModalText.form.types.label}
                        </div>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="MailingModalCheckboxFieldWrapper"
                        name={CheckboxDict.replenishment}
                        rules={typeRules}
                      >
                        <FieldCheckBox
                          field={CheckboxDict.replenishment}
                          isType
                          active={activeFields}
                          form={form}
                          setActive={setActiveFields}
                        />
                      </Form.Item>
                      <Form.Item
                        name={CheckboxDict.replenishment}
                        rules={activeFields.replenishment ? rules.required : []}
                      >
                        <CurrencyInputComponent
                          greyBackground
                          placeholder={
                            mailingModalText.form.checkbox[
                              CheckboxDict.replenishment
                            ].placeholder
                          }
                          value={currentReplenishment}
                          setValue={(value?: string | number) =>
                            form.setFieldsValue({ replenishment: value })
                          }
                          disabled={!activeFields.replenishment}
                          widthFromPrefix={true}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="MailingModalCheckboxFieldWrapper"
                        name={CheckboxDict.withdrawal}
                        rules={typeRules}
                      >
                        <FieldCheckBox
                          field={CheckboxDict.withdrawal}
                          isType
                          active={activeFields}
                          form={form}
                          setActive={setActiveFields}
                        />
                      </Form.Item>
                      <Form.Item
                        name={CheckboxDict.withdrawal}
                        rules={activeFields.withdrawal ? rules.required : []}
                      >
                        <CurrencyInputComponent
                          greyBackground
                          placeholder={
                            mailingModalText.form.checkbox[
                              CheckboxDict.withdrawal
                            ].placeholder
                          }
                          value={currentWithdrawal}
                          setValue={(value?: string | number) =>
                            form.setFieldsValue({ withdrawal: value })
                          }
                          disabled={!activeFields.withdrawal}
                          widthFromPrefix={true}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="MailingModalCheckboxFieldWrapper"
                        name={CheckboxDict.win}
                        rules={typeRules}
                      >
                        <FieldCheckBox
                          field={CheckboxDict.win}
                          isType
                          active={activeFields}
                          form={form}
                          setActive={setActiveFields}
                        />
                      </Form.Item>
                      <Form.Item
                        name={CheckboxDict.win}
                        rules={activeFields.win ? rules.required : []}
                      >
                        <CurrencyInputComponent
                          greyBackground
                          placeholder={
                            mailingModalText.form.checkbox[CheckboxDict.win]
                              .placeholder
                          }
                          value={currentWin}
                          setValue={(value?: string | number) =>
                            form.setFieldsValue({ win: value })
                          }
                          disabled={!activeFields.win}
                          widthFromPrefix={true}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="MailingModalRight">
                    <div className="MailingModalFieldLabelWrapper">
                      <Form.Item
                        name="type"
                        rules={
                          !activeFields.phone && !activeFields.email
                            ? mailingRules
                            : []
                        }
                      >
                        <div className="MailingModalFieldLabel">
                          {mailingModalText.form.mailing.label}
                        </div>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="MailingModalCheckboxFieldWrapper"
                        name="type"
                        rules={
                          !activeFields.phone && !activeFields.email
                            ? mailingRules
                            : []
                        }
                      >
                        <FieldCheckBox
                          field={CheckboxDict.phone}
                          isType={false}
                          active={activeFields}
                          form={form}
                          setActive={setActiveFields}
                        />
                      </Form.Item>
                      <Form.Item
                        name={CheckboxDict.phone}
                        rules={activeFields.phone ? rules.required_phone : []}
                      >
                        <InputMaskComponent
                          mask={
                            mailingModalText.form.checkbox[CheckboxDict.phone]
                              .phoneMask
                          }
                          placeholder={
                            mailingModalText.form.checkbox[CheckboxDict.phone]
                              .placeholder
                          }
                          value={currentPhone}
                          allowClear={true}
                          onChange={x => {
                            form.setFieldsValue({
                              [CheckboxDict.phone]: `79${x.unmaskedValue}`
                            })
                          }}
                          disabled={!activeFields.phone}
                          greyBackground
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        className="MailingModalCheckboxFieldWrapper"
                        name="type"
                        rules={
                          !activeFields.phone && !activeFields.email
                            ? mailingRules
                            : []
                        }
                      >
                        <FieldCheckBox
                          field={CheckboxDict.email}
                          isType={false}
                          active={activeFields}
                          form={form}
                          setActive={setActiveFields}
                        />
                      </Form.Item>
                      <Form.Item
                        name={CheckboxDict.email}
                        rules={activeFields.email ? rules.required_email : []}
                      >
                        <InputComponent
                          greyBackground
                          placeholder={
                            mailingModalText.form.checkbox[CheckboxDict.email]
                              .placeholder
                          }
                          disabled={!activeFields.email}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="MailingModalRectangle" />
                <Form.Item>
                  <ButtonPrimary loading={modalData.pending} htmlType="submit">
                    {mailingModalText.form.submit.label}
                  </ButtonPrimary>
                </Form.Item>
              </div>
            </Form>
          ) : (
            <div className="MailingModalSuccess GridCenter">
              <span>{mailingModalText.success.title.one}</span>
              <span>{mailingModalText.success.title.getSecond(isNew)}</span>
              <SuccessIcon />
            </div>
          )}
        </div>
      </ModalFormComponent>
    </div>
  )
}

export default MailingModal
