import React from "react"

import SearchInputFilter from "Components/SideFilter/Components/SearchInputFilter"

import { sportboomMainText, isSpecialRoles } from "./constants"
import { SportBoomFilterData } from "./types"

export const CustomFilterWalletNumber: React.FC<{
  filter: SportBoomFilterData
  setFilter: (data: SportBoomFilterData) => void
  partOfBetNumber?: boolean
}> = ({ filter, setFilter, partOfBetNumber }) =>
  !isSpecialRoles() || partOfBetNumber ? (
    <div
      className={`${partOfBetNumber ? `` : `SportBoomCustomFilter`} FlexColumn`}
    >
      <div>
        <SearchInputFilter
          value={filter.wallet_number}
          onChange={value =>
            setFilter({
              ...filter,
              wallet_number: value || undefined
            })
          }
          text={{
            placeholder:
              sportboomMainText.customFilter.wallet_number.placeholder,
            header: sportboomMainText.customFilter.wallet_number.label
          }}
        />
      </div>
    </div>
  ) : (
    <div />
  )

export const CustomFilterBetNumber: React.FC<{
  filter: SportBoomFilterData
  setFilter: (data: SportBoomFilterData) => void
}> = ({ filter, setFilter }) => (
  <div>
    {isSpecialRoles() && (
      <CustomFilterWalletNumber
        filter={filter}
        setFilter={setFilter}
        partOfBetNumber={true}
      />
    )}
    <div className="FlexColumn">
      <div>
        <SearchInputFilter
          isNumber={true}
          value={filter.bet_number}
          onChange={value =>
            setFilter({
              ...filter,
              bet_number: value || undefined
            })
          }
          text={{
            placeholder: sportboomMainText.customFilter.bet_number.placeholder,
            header: sportboomMainText.customFilter.bet_number.label
          }}
        />
      </div>
    </div>
  </div>
)
