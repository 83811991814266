export enum rowType {
  MAIN = `main`,
  VIP = `vip`
}

export enum SuperwinLimits {
  Allow = `allow`,
  MaxSumLimit = `max_sum_limit`,
  DaySumLimit = `day_sum_limit`
}

export type SuperwinItemData = {
  current_sum: number
  day_sum_limit: string
  last_change_at: string | null
  max_sum_limit: string
  next_change_at: string | null
  nominal: number
  remaining_daily_limit: string
  rng_id: number
  status: SuperwinLimits
}

export type SuperwinItem = {
  hall: number
  [rowType.MAIN]: SuperwinItemData[]
  [rowType.VIP]?: SuperwinItemData[]
}

export type SuperwinItemState = {
  pending: boolean
  data: SuperwinItem[]
}
