import React, { useCallback, useEffect, useMemo, useState } from "react"

import { getRequestTotal } from "api/request"
import { useRequest, useRequestBody } from "api/hooks"

import EmptyTable from "Components/EmptyTable"
import PaginationComponent from "Components/Pagination"

import { SportBoomContentProps } from "../../types"
import { SportBoomTabs } from "../../constants"

import { DetailModalProps, TableDataState } from "../Table/types"
import { tableStateInitial } from "../Table/constants"
import CompareBets from "../Table/Detail/Compare"

import TableItem from "./TableItem"

type DetailModalMobileProps = Omit<DetailModalProps, "list" | "currentData">

type SportboomTableMobileProps = {
  filter: SportBoomContentProps
  activeTab: SportBoomTabs
  onWalletIdClick: (walletId: number) => void
}

const SportboomTableMobile = ({
  filter,
  activeTab,
  onWalletIdClick
}: SportboomTableMobileProps) => {
  const [tableData, setTableData] = useState<TableDataState>(tableStateInitial)

  const { pagination, setPagination } = useRequestBody()
  const { request: getTableData } = useRequest({
    url: `v1/sportboom/table`,
    requestBody: {
      filters: filter,
      type: activeTab,
      pagination
    }
  })

  const requestDeps = JSON.stringify({ ...filter, pagination, activeTab })

  // Pick bets
  const [pickList, setPickList] = useState<number[]>([])

  const checkItem = useCallback(
    ({ bet, isActive }: { bet: number; isActive: boolean }) => {
      let newList = []
      if (!isActive) {
        newList = pickList.filter(item => item !== bet)
      } else {
        newList = [...pickList, bet]
      }
      setPickList(newList)
    },
    [pickList]
  )

  useEffect(() => {
    setTableData({ ...tableData, pending: true })
    getTableData()
      .then(({ data, headers }) => {
        setTableData({
          ...tableData,
          pending: false,
          total: getRequestTotal(headers),
          data
        })
        setPickList([])
      })
      .catch(() => setTableData({ ...tableData, pending: false }))
  }, [requestDeps])

  const [detailData, setDetailData] = useState<DetailModalMobileProps>({
    open: false,
    current: undefined,
    dataList: []
  })
  const detailDataFromList = useMemo(() => {
    const data: any = {}
    const filterData = tableData.data
      .filter(dataItem => pickList.includes(dataItem.bet_number))
      .map(filterItem => {
        data[filterItem.bet_number] = filterItem
      })
    return data
  }, [pickList])

  const detailCurrentDataFromList = useMemo(() => {
    if (!!detailData.current) {
      const currentDetail = tableData.data.filter(
        dataItem => detailData.current === dataItem.bet_number
      )[0]
      return currentDetail
    }
    return undefined
  }, [detailData.current])

  return (
    <div className="SportboomTableMobileWrapper FlexColumn Gap8">
      {!tableData.total && !tableData.pending && <EmptyTable />}
      {!!tableData.total &&
        tableData.data.map(item => {
          const isActive = pickList.includes(item.bet_number)
          return (
            <div
              key={`table_bet_${item.bet_number}`}
              className={
                tableData.pending ? `SportboomTableMobileItemPending` : ``
              }
            >
              <TableItem
                data={item}
                openCurrent={bet =>
                  setDetailData({ ...detailData, current: bet, open: true })
                }
                setPickList={checkItem}
                isActive={isActive}
                pending={tableData.pending}
              />
            </div>
          )
        })}
      <div className="SportboomTableMobileListPagination">
        <PaginationComponent
          onChange={setPagination}
          value={pagination}
          total={tableData.total}
        />
      </div>
      <CompareBets
        onWalletIdClick={onWalletIdClick}
        betsList={pickList}
        removeListItem={bet => checkItem({ bet, isActive: false })}
        openDetail={detailData.open}
        detailOpen={{
          ...detailData,
          list: pickList,
          currentData: detailCurrentDataFromList
        }}
        clear={() => {
          setPickList([])
        }}
        closeModal={() =>
          setDetailData({ ...detailData, open: false, current: undefined })
        }
        openDetailModal={() => {
          setDetailData({
            ...detailData,
            open: true,
            dataList: detailDataFromList
          })
        }}
      />
    </div>
  )
}

export default SportboomTableMobile
