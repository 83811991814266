import React, { useState } from "react"

import { SwitchFilterComponent } from "Components/Switch"

import { FilterProps } from "./index"
import { customFilterText } from "./constants"
import RangeComponent from "./RangeComponent"

import { FilterRangeFields } from "../types"
import { initialFilterCashbox } from "../constants"

const BetsFilter = ({ filter, setFilter }: FilterProps) => {
  const [showCashboxFilter, toggleCashboxFilter] = useState(false)
  return (
    <div className={showCashboxFilter ? `PaddingBottom24` : ``}>
      <SwitchFilterComponent
        checked={showCashboxFilter}
        onChange={() => {
          const initial = initialFilterCashbox(!showCashboxFilter)
          setFilter({ ...filter, ...initial })
          toggleCashboxFilter(!showCashboxFilter)
        }}
        label={customFilterText.label.cashbox}
      />
      {showCashboxFilter && (
        <div className="FlexColumn Gap12">
          <RangeComponent
            label={customFilterText.fieldLabels[FilterRangeFields.cash_in]}
            field={FilterRangeFields.cash_in}
            filter={filter}
            setFilter={setFilter}
          />
          <RangeComponent
            label={customFilterText.fieldLabels[FilterRangeFields.noncash_in]}
            field={FilterRangeFields.noncash_in}
            filter={filter}
            setFilter={setFilter}
          />
          <RangeComponent
            label={customFilterText.fieldLabels[FilterRangeFields.cash_out]}
            field={FilterRangeFields.cash_out}
            filter={filter}
            setFilter={setFilter}
          />
          <RangeComponent
            label={customFilterText.fieldLabels[FilterRangeFields.noncash_out]}
            field={FilterRangeFields.noncash_out}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      )}
    </div>
  )
}

export default BetsFilter
