import {
  DashboardOptions,
  DashboardPrefixOptions,
  defaultDescriptions
} from "Components/DashboardNew/constants"

import { CashboxState } from "../../types"
import cashboxConstants from "../../constants"

export const getDashboardConfig = (
  cashboxState: CashboxState,
  toggleQiwiModal: (val: boolean) => void
) => {
  return [
    {
      type: DashboardOptions.balance_payout,
      onClick: () => toggleQiwiModal(true),
      value: cashboxState.data.qiwi_balance,
      isCurrency: true,
      prefix: DashboardPrefixOptions.RUB,
      description: defaultDescriptions.now,
      tooltipText: cashboxConstants.dashboards.qiwi_balance.tooltip
    },
    {
      type: DashboardOptions.money_balance,
      value: cashboxState.data.money_balance,
      isCurrency: true,
      prefix: DashboardPrefixOptions.RUB,
      description: defaultDescriptions.now,
      tooltipText: cashboxConstants.dashboards.money_balance.tooltip
    },
    {
      type: DashboardOptions.bonus_balance,
      value: cashboxState.data.bonus_balance,
      isCurrency: true,
      prefix: DashboardPrefixOptions.BON,
      description: defaultDescriptions.now,
      tooltipText: cashboxConstants.dashboards.bonus_balance.tooltip
    },
    {
      type: DashboardOptions.cashbox_result,
      value: cashboxState.data.cashbox_result,
      isCurrency: true,
      prefix: DashboardPrefixOptions.RUB,
      description: defaultDescriptions.current_period,
      tooltipText: cashboxConstants.dashboards.cashbox_result.tooltip
    }
  ]
}
