import React, { useState } from "react"

import InputComponent, { InputMaskComponent } from "Components/Input"
import { RadioGroupComponent } from "Components/Radio"
import { ButtonFaded, ButtonPrimary, buttonText } from "Components/Button"

import { ReactComponent as SearchIcon } from "Components/icons/search.svg"

import SortTableFilter from "../SortFilter"
import { fromToConstants } from "../FromToFilter/constants"

import { SortOrders } from "../../types"
import { ReactComponent as FilterIcon } from "../../icons/table_filter.svg"

const searchText = {
  label: `Применить фильтр`,
  options: [
    {
      value: 1,
      label: `Равно`
    },
    {
      value: 0,
      label: `Не равно`
    }
  ]
}

type SearchFilterProps = {
  placeholder?: string
  value?: string | null
  iconSearch?: boolean
  label?: string
  isPhone?: boolean
  isEqual?: boolean
  withSort?: boolean
  withEqual: boolean
  currentSort?: {
    order?: SortOrders
    field?: string
  }
  field?: string
  changeFilter: (
    initial:
      | {
          value?: string | null
          equal?: boolean
          order?: SortOrders
          sortField?: string
        }
      | undefined
  ) => void
  closeFunc?: () => void
}

export type SearchFilterState = {
  value?: string | null
  equal: 1 | 0
  hasSort: boolean
  sortField?: string
  order?: SortOrders
}

export const SearchField = ({
  isPhone,
  state,
  placeholder,
  setState,
  iconSearch
}: {
  isPhone?: boolean
  state: SearchFilterState
  placeholder?: string
  setState: (val: SearchFilterState) => void
  iconSearch?: boolean
}) =>
  !isPhone ? (
    <InputComponent
      value={state.value || undefined}
      suffix={iconSearch ? <SearchIcon /> : <FilterIcon />}
      placeholder={placeholder}
      onChange={({ target: { value } }) => setState({ ...state, value })}
      allowClear={true}
    />
  ) : (
    <InputMaskComponent
      mask={"+7 000 000-00-00"}
      value={state.value || undefined}
      allowClear={true}
      onChange={x => {
        setState({ ...state, value: `7${x.unmaskedValue}` })
      }}
    />
  )

const SearchFilter = ({
  placeholder,
  value,
  label,
  iconSearch,
  isEqual,
  withEqual,
  isPhone,
  closeFunc,
  changeFilter,
  withSort,
  currentSort,
  field
}: SearchFilterProps) => {
  const activeSort = currentSort?.field === field

  const initialState: SearchFilterState = {
    value,
    equal: !!isEqual ? 1 : 0,
    hasSort: currentSort?.field === field,
    sortField: currentSort?.field,
    order: activeSort ? currentSort?.order : SortOrders.asc
  }

  const [state, setState] = useState<SearchFilterState>(initialState)
  const showClear = !!value || (withSort && activeSort)
  const disabled = isPhone ? state.value?.length !== 11 : !state.value

  return (
    <div>
      {withSort && (
        <SortTableFilter
          order={state.order}
          changeOrder={value =>
            setState({
              ...state,
              order: value
            })
          }
          hasSort={state.hasSort}
          changeHasSort={sortVal =>
            setState({
              ...state,
              hasSort: sortVal,
              sortField: sortVal ? field : currentSort?.field
            })
          }
        />
      )}
      <div className="SubtitleFirstText MarginBottom6 MarginTop12">
        {label || searchText.label}
      </div>
      <SearchField
        isPhone={isPhone}
        state={state}
        setState={setState}
        placeholder={placeholder}
        iconSearch={iconSearch}
      />
      {withEqual && (
        <div className="MarginTop16">
          <RadioGroupComponent
            value={state.equal}
            options={searchText.options}
            onChange={({ target: { value } }) =>
              setState({ ...state, equal: !!value ? 1 : 0 })
            }
          />
        </div>
      )}
      <div className="FilterButtonsWrapper MarginTop16">
        {showClear && (
          <ButtonFaded
            onClick={() => {
              changeFilter({
                value: undefined,
                sortField: activeSort ? undefined : currentSort?.field,
                order: activeSort ? undefined : currentSort?.order
              })

              !!closeFunc && closeFunc()
            }}
          >
            {fromToConstants.clearText}
          </ButtonFaded>
        )}
        <ButtonPrimary
          disabled={disabled}
          onClick={() => {
            changeFilter(
              !!state.value || state.hasSort
                ? {
                    ...state,
                    equal: withEqual ? state.equal === 1 : undefined
                  }
                : undefined
            )
            if (!!closeFunc) {
              closeFunc()
            }
          }}
        >
          {buttonText.apply}
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default SearchFilter
