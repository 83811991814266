import { Moment } from "moment"

export enum GuestHistoryTabItems {
  metric = "metric",
  cashbox = "cashbox",
  bets = "bets"
}

export type GuestsHistoryFilterData = {
  dates?: { from: Moment; to: Moment }
  phone?: string
  games?: (number | string | null)[]
  onlyActiveDays: boolean
  includeFreebets: boolean
}

export type GuestsHistoryActiveDates = {
  pending: boolean
  dates: string[]
}

export type FreebetsData = {
  beginDatetime: string
  beginTimestamp: number
  betMaxFactor: number
  betMinFactor: number
  betType: string
  endDatetime: number
  endTimestamp: number
  freeBetId: string
  value: number
}

export type GuestsHistoryFilter = {
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
  data: GuestsHistoryFilterData
}

export type GuestHistoryFilterProps = {
  filter: GuestsHistoryFilterData
  setFilter: (data: GuestsHistoryFilterData) => void
}

export type GuestsHistoryComment = {
  text: string | null
  date: string
}

export type GuestsHistoryReportData = {
  birthday: string
  name: string
  city: string
  comment: string
  comments_history: GuestsHistoryComment[]
  dashboards: {
    money: string
    bonus: string
    freebet: string
    freebets: FreebetsData[]
  }
  firstVisit: string
  lastVisit: string
  status: string
  wallet: {
    id: string
    active: boolean
    registration_date: string
    registration_dttm: string
  }
  active: boolean
  region: string
}

export type GuestsHistoryReport = {
  pending: boolean
  total: number
  data?: GuestsHistoryReportData
}
