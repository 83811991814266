import React, { useState } from "react"

import { mailingTabsTitleText } from "./helper"
import { ActiveTabs, MailingTabsProps } from "../types"

import MailingModal from "../Modal"

import Title from "Components/Title"
import { ButtonPrimary } from "Components/Button"

const MailingTabs = ({
  activeTab,
  setActiveTab,
  successRefresh
}: MailingTabsProps) => {
  const [newModal, setNewModal] = useState(false)

  const isHall = activeTab === ActiveTabs.hall
  const isHistory = activeTab === ActiveTabs.history

  return (
    <div>
      <div className="MailingContentTitleWrapper">
        <Title
          titleText={mailingTabsTitleText.title}
          tooltipText={mailingTabsTitleText.tooltip}
          rightAdditionalCmp={
            <ButtonPrimary onClick={() => setNewModal(true)}>
              {mailingTabsTitleText.add}
            </ButtonPrimary>
          }
          rightAdditionalClassName="MailingContentTitleRight"
          wrapperClass="MailingContentTitleWrapper"
        />
      </div>
      <div className="MailingContentTabs FlexRow">
        <div className="FlexColumn">
          <div
            className={`MailingContentTabsItem ${
              isHall
                ? `MailingContentTabsItemActive`
                : `MailingContentTabsInactiveItem`
            }`}
            onClick={() => setActiveTab(ActiveTabs.hall)}
          >
            {mailingTabsTitleText.tabs.hall}
          </div>
          {isHall && <div className="MailingContentTabsActiveRestriction" />}
        </div>
        <div className="FlexColumn">
          <div
            className={`MailingContentTabsItem ${
              isHistory
                ? `MailingContentTabsItemActive`
                : `MailingContentTabsInactiveItem`
            }`}
            onClick={() => setActiveTab(ActiveTabs.history)}
          >
            {mailingTabsTitleText.tabs.history}
          </div>
          {isHistory && <div className="MailingContentTabsActiveRestriction" />}
        </div>
      </div>
      <div className="MailingContentTabsRestriction" />
      {newModal && (
        <MailingModal
          successRefresh={successRefresh}
          close={() => setNewModal(false)}
        />
      )}
    </div>
  )
}

export default MailingTabs
