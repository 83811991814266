import moment from "moment"

import betRequest from "api/request"
import { parseOneDateToBack } from "constants/index"

import { GetNotifiProps, SaveNotifiProps, CrudDataType } from "./types"

export const saveNotifi = ({
  developer,
  addDeveloperLink,
  id,
  data,
  logout,
  history,
  setPending,
  setSuccessModal,
  isNew
}: SaveNotifiProps) => {
  setPending(true)
  const prepareFiles = data.files.map(item =>
    !!item.link
      ? item
      : {
          filename: item.name,
          content: item.base64
        }
  )
  const isFutureDate = moment(data.date).valueOf() > moment().valueOf()
  const date =
    !!data.date && isFutureDate
      ? parseOneDateToBack({
          date: data.date,
          gmt: `3`
        })
      : null

  const saveData = {
    ...data,
    date,
    files: prepareFiles
  }

  betRequest({
    url: isNew ? `admin/message/` : `admin/message/${id}`,
    method: isNew ? `POST` : `PUT`,
    developer,
    addDeveloperLink,
    history,
    logout,
    requestBody: { ...saveData }
  })
    .then(() => {
      setPending(false)
      setSuccessModal(true)
    })
    .catch(() => {
      setPending(false)
    })
}

const getCurrentNotifi = ({
  developer,
  addDeveloperLink,
  id,
  setModal,
  logout,
  history
}: GetNotifiProps) => {
  betRequest({
    url: `admin/message/${id}`,
    method: `GET`,
    developer,
    addDeveloperLink,
    history,
    logout
  }).then(({ data: { result } }) => {
    const date =
      moment(result.date).valueOf() > moment().valueOf()
        ? moment(result.date)
        : null
    const responseData: CrudDataType = {
      text: result.text,
      title: result.title,
      comment: result.comment,
      city_id: result.city_id || [],
      region_id: result.region_id || [],
      country_id: result.country_id || [],
      halls: result.halls || [],
      date,
      is_require: !!result.is_require,
      is_push: !!result.is_push,
      is_draft: !!result.is_draft,
      files: result.files || []
    }

    setModal({ open: true, id, initial: responseData })
  })
}

export default getCurrentNotifi
