import {
  PromotionBonusesLegoConfig,
  PromotionBonusesTableItem
} from "../Lego/types"
import {
  DashboardOptions,
  DashboardPrefixOptions
} from "Components/DashboardNew/constants"

export const config: PromotionBonusesLegoConfig = {
  title: `День рождения гостя`,
  tool: `Отчет предназначен для просмотра информации о начислениях по акции День рождения гостя. Данные возможно посмотреть как общую по клубу, так и по конкретному гостю, за выбранный период. После применения фильтров появится информация о датах начислений, а также сумма начисленного бонуса и номер кошелька гостя.`,
  filterStorageName: `PromotionBirthday`,
  notActiveFilterDescription: `Отчёт о начислениях по акции “День рождения гостя”.`,
  tableUrl: `v1/promotions/guests-birthday`,
  summaryUrl: `v1/promotions/guests-birthday/summary`,
  summary: [
    {
      isCurrency: false,
      field: `guests_count`,
      tooltipText: `Количество гостей, получивших бонус по акции, в рамках выбранного в фильтрах периода`,
      type: DashboardOptions.guests_with_bonuses
    },
    {
      field: `bonus_sum`,
      isCurrency: true,
      prefix: DashboardPrefixOptions.BON,
      tooltipText: `Сумма бонусов, полученных по акции гостями, в рамках выбранного в фильтрах периода`,
      type: DashboardOptions.bonuses_sum
    }
  ],
  table: [
    {
      field: `request_date`,
      title: `Дата начисления`,
      type: PromotionBonusesTableItem.date
    },
    {
      field: `request_date`,
      title: `Время запроса`,
      type: PromotionBonusesTableItem.time
    },
    {
      field: `approve_date`,
      title: `Время подтверждения`,
      type: PromotionBonusesTableItem.time
    },
    {
      field: `wallet_number`,
      title: `Номер кошелька`,
      type: PromotionBonusesTableItem.wallet
    },
    {
      field: `sum`,
      title: `Начислено`,
      type: PromotionBonusesTableItem.sum,
      suffix: `Б`
    }
  ],
  filterTypeHall: `hall`,
  filterTypeGuest: `guest`,
  monthPeriod: "month"
}
