import { GamesData } from "store/dictionary/types"
import { makeSuperWinsModalColumns } from "Containers/Main/helper"
import { columns, hallCol, HISTORY_URL, WIN_HISTORY_URL } from "./constants"
import { useRequest } from "api/hooks"
import { parseDateToBack } from "constants/index"
import { SuperWinsTableColumnNames } from "Containers/Main/types"
import { Moment } from "moment"
import { UseSuperWinHistoryRequests } from "./types"

export const makeHistoryColumns = (
  gamesDict: GamesData[],
  isWinsHistory?: boolean
) => {
  if (isWinsHistory) {
    const [firstCol, ...rest] = makeSuperWinsModalColumns(gamesDict)
    return [firstCol, hallCol, ...rest].map(el => {
      const nextCol = {
        ...el,
        width: 150,
        sorter: false
      }

      if (
        el.key === SuperWinsTableColumnNames.win_sum ||
        el.key === SuperWinsTableColumnNames.date
      ) {
        nextCol.sorter = true
      }
      return nextCol
    })
  }
  return columns
}

export const useSuperWinHistoryRequests = ({
  filter,
  pagination,
  isSuperWinsHistory,
  sort
}: UseSuperWinHistoryRequests) => {
  const nextReqBody = isSuperWinsHistory
    ? {
        filter: {
          ...filter,
          halls: filter.halls || [filter.hall],
          dates: filter.dates
            ? parseDateToBack({
                date: filter.dates as [Moment, Moment],
                isObj: true,
                isFromTo: true
              })
            : undefined,
          gmt: Number(filter.gmt)
        },
        ...(sort.field && sort.order && { sort })
      }
    : {
        filter: {
          hall: Number(filter.halls?.[0] || filter.hall),
          nominals: filter.nominals,
          dates: filter.dates
            ? parseDateToBack({
                objDate: filter.dates as { from: Moment; to: Moment },
                isObj: true,
                isFromTo: true
              })
            : undefined
        }
      }

  const { request: getSuperWinHistory } = useRequest({
    url: isSuperWinsHistory ? WIN_HISTORY_URL : HISTORY_URL,
    requestBody: {
      ...nextReqBody,
      pagination
    }
  })

  return { getSuperWinHistory }
}
