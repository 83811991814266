import React, { useCallback } from "react"
import { ModalRightDetail } from "Components/Modal"
import { WalletInfoHistory } from "../WalletModal/types"
import TableNew from "Components/TableNew"
import {
  columnsRightModalWallet,
  columnsRightModalWalletFull,
  makeColumnsRightModalWalletMobile
} from "../WalletModal/constants"
import { text } from "./constants"
import { isMobile, roleConstants } from "constants/index"
import TableCard from "Components/TableNew/TableCards/TableCard"
import { MobileBackButton } from "Components/Button"

export interface SideWalletModalProps {
  data?: WalletInfoHistory[] | null
  onClose: () => void
  id: number | null
}

export const SideWalletModal: React.FC<SideWalletModalProps> = ({
  data,
  onClose,
  id
}) => {
  const { isRiskManagement } = roleConstants()

  const mobileColumns = useCallback(() => {
    return data?.map((el, key) => (
      <TableCard
        config={makeColumnsRightModalWalletMobile(el, isRiskManagement)}
        key={key}
      />
    ))
  }, [data, isMobile])
  return isMobile ? (
    <ModalRightDetail close={onClose} initialWidth={700} maskClosable hideClose>
      <div className="TableCardsContainer">
        <div className="SportBoomMobileBack">
          <MobileBackButton onClick={onClose} />
        </div>
        <div className="SportBoomMobileTitle">{`${text.title} ${id}`}</div>
        <div>{mobileColumns()}</div>
      </div>
    </ModalRightDetail>
  ) : (
    <ModalRightDetail close={onClose} initialWidth={700} maskClosable>
      <div className="SportBoomSideWalletModalContainer">
        <div className="HeadlineFirstText">{`${text.title} ${id}`}</div>
        <TableNew
          columns={
            isRiskManagement
              ? columnsRightModalWalletFull
              : columnsRightModalWallet
          }
          data={data || []}
          isOrange
        />
      </div>
    </ModalRightDetail>
  )
}
