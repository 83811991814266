import React from "react"

import ModalComponent from "Components/Modal"
import { QiwiModalComponentProps } from "./types"
import { valueToCurrency } from "../../constants"

const text = {
  title: `Информация по клубам`,
  hall: `№ Клуба`,
  defaultColor: `#E6F6F8`
}

const QiwiModalComponent: React.FC<QiwiModalComponentProps> = ({
  data,
  close,
  name,
  title,
  color
}) => {
  return (
    <ModalComponent close={close} width={448}>
      <div className="QiwiModalWrapper">
        <div className="QiwiModalHeader GridCenter">{text.title}</div>
        <div className="QiwiModalTableWrapper">
          <div
            style={{ backgroundColor: color || text.defaultColor }}
            className="QiwiModalTableHeader FlexRow"
          >
            <div className="QiwiModalTableHeaderItem">{text.hall}</div>
            <div className="QiwiModalTableHeaderItem">{title}</div>
          </div>
          <div className="QiwiModalTableBody">
            {data.map((item, key) => {
              const value = name ? item[name] : item.value
              const currencyValue = valueToCurrency({
                value,
                withoutZero: true,
                withoutFixed: true
              })
              return (
                <div
                  key={`qiwi_balance_hall_${item.hall}`}
                  className={`QiwiModalTableRow FlexRow ${
                    data.length === key + 1 ? `QiwiModalTableLastRow` : ``
                  }`}
                >
                  <div className="QiwiModalTableRowItem">{item.hall}</div>
                  <div className="QiwiModalTableRowItem">{currencyValue}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </ModalComponent>
  )
}

export default QiwiModalComponent
