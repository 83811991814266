import React from "react"
import InputComponent from "../../Input"
import { TextSearchProps } from "../types"

const InputSearch = ({
  value,
  toggleShowSearch,
  search,
  setValue,
  clearSearch,
  setSearch,
  title
}: TextSearchProps) => (
  <InputComponent
    autoFocus
    value={value}
    allowClear={true}
    onBlur={() => {
      !search && toggleShowSearch(false)
    }}
    onChange={({ target: { value } }) => {
      if (value) setValue(value)
      else clearSearch()
    }}
    onKeyDown={({ target, key }) => {
      if (key === `enter` || key === `Enter`) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setSearch(target.value || undefined)
        toggleShowSearch(false)
      } else if (key === `Escape`) {
        clearSearch()
      }
    }}
    className="TableSearchInput"
    placeholder={title}
  />
)

export default InputSearch
