import React, { useCallback, useState } from "react"
import DatePickerComponent from "Components/DatePicker"
import { SelectComponent } from "Components/Select"
import { DefaultOptionType } from "rc-select/lib/Select"
import { DictElements } from "../types"
import InputComponent from "Components/Input"
import ButtonDefault from "Components/Button"
import { addEditModalText } from "../../constants"

export const addEditModalRadioButtonsOptions = [
  {
    value: 1,
    label: "Штраф"
  },
  { value: 2, label: "Предупреждение" }
]

export const formRules = {
  input: [
    {
      validator: <T, D>(_: T, value: D) => {
        return value && ((value as unknown) as number) > 0
          ? Promise.resolve()
          : Promise.reject(new Error(addEditModalText.inputError))
      }
    }
  ],
  inputText: [
    {
      validator: <T, D>(_: T, value: D) => {
        return value && ((value as unknown) as string).trim().length > 0
          ? Promise.resolve()
          : Promise.reject(new Error(addEditModalText.inputError))
      }
    }
  ],
  inputSuccess: [
    {
      validator: () => {
        return Promise.resolve()
      }
    }
  ],
  textarea: [
    {
      validator: <T, D>(_: T, value: D) => {
        return value && ((value as unknown) as string).trim()
          ? Promise.resolve()
          : Promise.reject(new Error(addEditModalText.inputError))
      }
    }
  ],
  date: [
    {
      validator: <T, D>(_: T, value: D) =>
        value !== undefined
          ? Promise.resolve()
          : Promise.reject(new Error(addEditModalText.inputError))
    }
  ],
  select: [
    {
      validator: <T, D>(_: T, value: D) =>
        value !== undefined
          ? Promise.resolve()
          : Promise.reject(new Error(addEditModalText.inputError))
    }
  ],
  check: [
    {
      validator: <T, D>(_: T, value: D) => {
        return value
          ? Promise.resolve()
          : Promise.reject(new Error(addEditModalText.check))
      }
    }
  ]
}

export const allowedTypes = ".pdf,.jpg,.jpeg,.png"

export enum FormItemNames {
  hall = "hall",
  date = "date",
  reason = "reason",
  amount = "amount",
  win = "win",
  comment = "comment",
  file = "file"
}

export interface AddEditPenaltyModalHocProps {
  value?: any
  onChange?: any
}

export interface AddEditPenaltyModalSelectProps
  extends AddEditPenaltyModalHocProps {
  options: DictElements | []
  addOptionsFeature?: boolean
  winSelect?: boolean
  placeholder: string
  setNextOptionCb?: (value: { id: number; value: string }) => void
  disabled?: boolean
}

export const AddEditPenaltyModalDatePicker: React.FC<AddEditPenaltyModalHocProps> = ({
  value,
  onChange
}) => {
  return (
    <DatePickerComponent value={value} onChange={onChange} greyBackground />
  )
}

export interface AddEditPenaltyModalInput {
  value?: any
  onChange?: any
  setWarningPassed: (val: boolean) => void
}

export const AddEditPenaltyModalSelect: React.FC<AddEditPenaltyModalSelectProps> = ({
  value,
  onChange,
  options,
  addOptionsFeature,
  winSelect,
  placeholder,
  setNextOptionCb,
  disabled
}) => {
  const [newSelectOptionName, setNewSelectOptionName] = useState("")

  const filterCb = useCallback(
    (inputValue, option) => (option.value as string).includes(inputValue),
    [options, value]
  )

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSelectOptionName(e.target.value)
  }

  const addOption = () => {
    setNextOptionCb &&
      setNextOptionCb({
        id: Date.now(),
        value: newSelectOptionName || addEditModalText.newElement
      })
    setNewSelectOptionName("")
  }

  return (
    <SelectComponent
      options={options as DefaultOptionType[]}
      value={value}
      onChange={onChange}
      showSearch
      placeholder={placeholder}
      fieldNames={{
        label: "value",
        value: "id"
      }}
      passedClassName={`${
        winSelect ? "AddEditPenaltyModalSelectAddInputWin" : ""
      }`}
      dropdownRender={menu =>
        addOptionsFeature ? (
          <>
            {menu}
            <div className="AddEditPenaltyModalSelectAddInput">
              <InputComponent
                placeholder={""}
                value={newSelectOptionName}
                onChange={onInputChange}
              />
              <ButtonDefault onClick={addOption}>
                {addEditModalText.addButton}
              </ButtonDefault>
            </div>
          </>
        ) : (
          <>{menu}</>
        )
      }
      greyBackground
      allowClear={false}
      disabled={disabled}
      filterOption={filterCb}
      allowNullValue
    />
  )
}
