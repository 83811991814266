export const mainText = {
  title: `Баланс клуба`,
  tooltip: `Раздел позволяет направить заявку на пополнение счета клуба для возможности выплаты выигрышей гостям безналичным способом а также для пополнения счета клуба в сервисе Яндекс такси. Можно отследить статус заявки и воспользоваться фильтрацией для поиска нужной информации. В разделе доступен функционал дублирования, который ускорит заполнение новой заявки, скорректировав её при необходимости, и отмены заявки, только если заявка в статусе Новая.`,
  exelUrl: `v1/order/qiwi/excel`,
  exelModal: {
    title: `Не можем выгрузить`,
    description: `Слишком много данных. Выгрузка будет доступна при выборе только одного клуба в фильтре`
  },
  new: `Оформить заявку`
}

export const initialReq = {
  pagination: {
    limit: 30,
    offset: 0
  },
  filters: {}
}

export const checkHallCrunch = (current_hall: number) =>
  [
    101,
    46,
    1660,
    1457,
    1700,
    1229,
    1269,
    1599,
    1878,
    25,
    99,
    1650,
    1877,
    1991,
    1035,
    1045,
    420,
    820,
    1199,
    1234,
    1488,
    1940,
    1388,
    1012,
    1108,
    1422,
    1533,
    1046,
    1226,
    1076,
    1208,
    1561,
    1239,
    1153,
    1655,
    1566,
    1505,
    1500,
    1585,
    1490,
    1491,
    1704,
    1708,
    1663,
    1710,
    1664,
    1738,
    1760,
    1861,
    1806,
    1891,
    1918,
    1950,
    1955,
    1947,
    2008,
    2024,
    212,
    600,
    11,
    980,
    806,
    861,
    884,
    987,
    1966,
    1638,
    1957,
    1809,
    1851,
    223,
    1935,
    1701,
    1793,
    759,
    1799,
    1876,
    141,
    293,
    1364,
    1494,
    1540,
    1744,
    1405,
    1153,
    1760
  ].includes(current_hall)
