export const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 100, label: 100 }
]

export const paginationText = {
  pageSizeLabel: `Показать по`,
  jumpDots: `...`
}
