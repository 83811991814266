import React, { useCallback } from "react"
import { WikiFilterProps } from "../types"
import { text } from "../constants"
import { WikiSelect } from "./WikiSelect"
import { ReactComponent as CloseIcon } from "Components/icons/close_modal.svg"

export const WikiFilter: React.FC<WikiFilterProps> = ({
  options,
  history,
  anchors,
  mobileFilterOpen,
  onMobileFilterOpen
}) => {
  const handleAnchorClick = useCallback(
    (anchorId: string) => () => {
      history.push(`#${anchorId}`)
      if (mobileFilterOpen) {
        onMobileFilterOpen(false)
      }
    },
    [mobileFilterOpen]
  )

  const handleSelectChange = useCallback(() => {
    onMobileFilterOpen(false)
  }, [onMobileFilterOpen])

  return (
    <div
      className={`WikiFilterWrapper ${
        mobileFilterOpen ? "MainFilterMobile" : ""
      }`}
    >
      <div
        className="MainFilterMobileClose MainFilterMobileCloseWiki"
        onClick={() => {
          if (mobileFilterOpen) {
            onMobileFilterOpen(false)
          }
        }}
      >
        <CloseIcon />
      </div>
      <WikiSelect
        options={options}
        history={history}
        onSelectChange={handleSelectChange}
        filter
      />
      {!!anchors.current.length && (
        <div className="SubtitleFirstTextWikiFilter">{text.sidenav}</div>
      )}
      <div className="WikiFilterLinkContainer">
        {anchors.current.map(anchor => (
          <div
            key={anchor.id}
            className={`WikiFilterLink SubtitleSecondText WikiFilterLink_#${anchor.id}`}
            onClick={handleAnchorClick(anchor.id)}
          >
            {anchor.text}
          </div>
        ))}
      </div>
    </div>
  )
}
