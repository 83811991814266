import React from "react"
import { ButtonPrimary } from "Components/Button"
import { ReactComponent as WaitingMan } from "Components/icons/waiting_man.svg"
import AddSectionBtn from "../AddSectionBtn"
import { ContactsStateProps } from "../types"
import { isContactsMobile } from "../index"

const EmptyContactsPage = ({
  contactsState,
  setContactsState,
  allowEditContacts
}: {
  contactsState: ContactsStateProps
  setContactsState: (data: ContactsStateProps) => void
  allowEditContacts?: boolean
}) => {
  return (
    <div className="ContactsPageWrapper">
      {contactsState.isEditing && (
        <div className="ContactsPage_addBlockBtnWrapper">
          <AddSectionBtn
            setContactsState={setContactsState}
            contactsState={contactsState}
          />
        </div>
      )}

      {!isContactsMobile && allowEditContacts && !contactsState.isEditing && (
        <div className="ContactsEmptyPageInfoWrapper">
          <div className="ContactsEmptyPageInfo_title">
            Раздел &quot;Контактная информация&quot; пока не заполнен.
            <br />
            Заполните страницу, нажав на кнопку ниже.
          </div>
          <ButtonPrimary
            className="ContactsBtn ContactsEmptyPageInfo_startBtn"
            disabled={false}
            onClick={() =>
              setContactsState({ ...contactsState, isEditing: true })
            }
          >
            Начать заполнение
          </ButtonPrimary>
          <div className="ContactsEmptyPage_description">
            Для последующей корректировки контактной информации
            <br />
            используйте кнопку “Редактировать” в верхнем правом углу.
          </div>
        </div>
      )}

      {(isContactsMobile || !allowEditContacts) && (
        <div className="ContactsEmptyPageInfoWrapper">
          <WaitingMan />
          <div className="ContactsEmptyPageInfoNoAccess_title">
            Раздел пока не заполнен
          </div>
          <div className="ContactsEmptyPage_description">
            Рады видеть вас в ближайшее время,
            <br />
            раздел заполняется
          </div>
        </div>
      )}
    </div>
  )
}

export default EmptyContactsPage
