import React, { useEffect, useMemo, useState } from "react"

import {
  useRequest,
  UseRequestBodyPagination,
  useRequestParams
} from "api/hooks"

import { parseOneDateToBack } from "constants/index"

import { EmptyData } from "Components/EmptyTable"
import TableComponent from "Components/TableNew"

import {
  FilterType,
  PromotionBonusesLegoConfig,
  PromotionBonusesLegoFilterData
} from "../types"

import { getColumnsByConfig } from "./helper"

const BonusLegoTable = ({
  type,
  config,
  filter,
  pagination,
  setPagination
}: {
  config: PromotionBonusesLegoConfig
  type: FilterType
  filter: PromotionBonusesLegoFilterData
  pagination: UseRequestBodyPagination
  setPagination: (val: UseRequestBodyPagination) => void
}) => {
  const { currentHallAsNumber } = useRequestParams()

  const columns = useMemo(() => getColumnsByConfig(config), [config])

  const [tableData, setTableData] = useState({
    items: [],
    total_count: 0,
    pending: false
  })

  const { request: getTable } = useRequest({
    url: config.tableUrl,
    requestBody: {
      filter:
        type === FilterType.guest
          ? {
              hall: currentHallAsNumber,
              phone: filter.phone
            }
          : {
              hall: currentHallAsNumber,
              dates: !!filter.dates
                ? {
                    from: parseOneDateToBack({ date: filter.dates[0] }),
                    to: parseOneDateToBack({ date: filter.dates[1] })
                  }
                : undefined
            },
      pagination
    }
  })

  useEffect(() => {
    setTableData({ ...tableData, pending: true })
    getTable()
      .then(({ data }) => {
        setTableData({ ...data, pending: false })
      })
      .catch(() => {
        setTableData({ ...tableData, pending: false })
      })
  }, [filter, pagination])

  const withPagination = filter.type !== FilterType.guest

  return !tableData.total_count && !tableData.pending ? (
    <div className="BetBonusEmptyDataWrapper">
      <EmptyData />
    </div>
  ) : (
    <>
      <TableComponent
        columns={columns}
        data={tableData.items}
        loading={tableData.pending}
        pagination={
          withPagination
            ? {
                total: tableData.total_count,
                value: pagination,
                onChange: setPagination
              }
            : undefined
        }
      />
    </>
  )
}

export default BonusLegoTable
