import { initializeApp } from "firebase/app"
import { getToken, onMessage } from "firebase/messaging"
import axios from "axios"

const firebaseConfig = {
  apiKey: "AIzaSyBXlmYyrqc9kxGvUqCuAAafy4IiGHeJ4yI",
  authDomain: "partner-lk-dev-test-staging.firebaseapp.com",
  projectId: "partner-lk-dev-test-staging",
  storageBucket: "partner-lk-dev-test-staging.appspot.com",
  messagingSenderId: "549341230350",
  appId: "1:549341230350:web:1bfd20f69b01c8a754535f"
}

try {
  initializeApp(firebaseConfig)
} catch (error_msg) {
  // eslint-disable-next-line no-console
  console.error(`firebase test error!`, error_msg)
}

export const requestForToken = (messaging: any) => {
  if (messaging) {
    return getToken(messaging, {
      vapidKey: `BIfTGrciOh2H2bKCulgFVXu-ZDxHdnTF9bIMpECLdNjZnkozHPvByRvNH6F13K5DwPtWF_lrptoyGj39LqBGrA8`
    }).then((currentToken: any) => {
      if (currentToken) {
        const token = JSON.parse(localStorage.getItem("user") || ``).token
        axios({
          method: `POST`,
          url: `${process.env.REACT_APP_PUSH_URL}`,
          headers: { token },
          data: { appToken: currentToken }
        })
      } else {
        // Show permission request UI
        // eslint-disable-next-line no-console
        console.log("no token")
      }
    })
  }
}

export const onMessageListener = (messaging: any) =>
  new Promise(resolve => {
    if (messaging) {
      onMessage(messaging, payload => {
        resolve(payload)
      })
    }
  })
