import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useNow, useRequest } from "api/hooks"

import { isMobile } from "constants/index"

import TableComponent from "Components/TableNew"
import { ButtonNotifi } from "Components/Button"
import { ReactComponent as AddIcon } from "Components/icons/crud/user_add_24.svg"
import { ReactComponent as PauseIcon } from "Components/icons/crud/pause_black_28.svg"
import { ReactComponent as RemoveIcon } from "Components/icons/crud/delete_red_24.svg"

import ConfirmModal from "./ConfirmModal"
import { ConfirmModalData, ConfirmTypes } from "./ConfirmModal/types"

import { NotifyStatusTableProps } from "./types"
import { getColumns, getCount, tableButtons } from "./constants"

import {
  NotifyUserData,
  NotifyUserDataFields,
  UserFieldsWithId
} from "../types"

const NotifyStatusTable = ({
  baseUrl,
  pending,
  hall,
  changePending,
  openEditModal
}: NotifyStatusTableProps) => {
  const [tableData, setTableData] = useState<NotifyUserData[]>([])

  const { request: getData } = useRequest({
    url: `v1/requests/${baseUrl}/mailing`,
    requestBody: { filter: { hall } }
  })

  const { timestamp, refresh: refreshTable } = useNow()

  useEffect(() => {
    changePending(true)
    getData()
      .then(({ data }) => {
        setTableData(data)
        changePending(false)
      })
      .catch(() => changePending(false))
  }, [hall, timestamp])

  const tableCount = tableData.length
  const countText = useMemo(() => getCount(tableCount), [tableData])

  const [confirmData, setConfirmData] = useState<ConfirmModalData | undefined>(
    undefined
  )
  const pauseCurrent = useCallback(
    (id: number, is_active: boolean) =>
      setConfirmData({
        type: is_active ? ConfirmTypes.pauseOne : ConfirmTypes.resumeOne,
        id,
        is_active
      }),
    []
  )
  const removeCurrent = useCallback(
    (id: number) => setConfirmData({ type: ConfirmTypes.removeOne, id }),
    []
  )
  const editCurrent = useCallback(
    (data: UserFieldsWithId) => openEditModal(data),
    []
  )

  const haveActive: boolean = useMemo(() => {
    let hasValueLessThanTen = false
    for (let i = 0; i < tableCount; i++) {
      const item = tableData[i] as NotifyUserData
      if (item[NotifyUserDataFields.is_active]) {
        hasValueLessThanTen = true
        break
      }
    }
    return hasValueLessThanTen
  }, [tableData])

  const columns = useMemo(
    () => getColumns({ pauseCurrent, removeCurrent, editCurrent, hall }),
    [tableData]
  )

  return (
    <div className="NotifyStatusTableContent">
      <div className="MarginTop24 MarginLeft24 MarginRight24 PaddingBottom8 TextOne Gray600Color">
        {countText}
      </div>
      <div
        className={`${
          isMobile ? `` : `NotifyStatusTableWrapper`
        } MarginLeft24 MarginRight24`}
      >
        <TableComponent
          loading={pending}
          columns={columns}
          data={tableData}
          isOrange={true}
        />
      </div>
      <div
        className={`NotifyStatusTableButtons MarginTopAuto Padding24 ${
          isMobile ? `FlexColumn` : `FlexRow`
        } Gap16`}
      >
        <ButtonNotifi onClick={() => openEditModal(undefined)}>
          <div className="NotifyStatusTableButtonsAddItem FlexRow Gap4">
            <AddIcon />
            <span className="ButtonTextFirst">{tableButtons.add}</span>
          </div>
        </ButtonNotifi>
        {!!tableCount && (
          <ButtonNotifi
            className="NotifyStatusTableButtonsItem"
            onClick={() =>
              setConfirmData({
                is_active: haveActive,
                type: haveActive
                  ? ConfirmTypes.pauseAll
                  : ConfirmTypes.resumeAll
              })
            }
          >
            <div className="FlexRow NotifyStatusTableButtonsSvgItem Gap4">
              <PauseIcon />
              <span className="ButtonTextFirst">
                {haveActive ? tableButtons.pause : tableButtons.resume}
              </span>
            </div>
          </ButtonNotifi>
        )}
        {!!tableCount && (
          <ButtonNotifi
            className="NotifyStatusTableButtonsItem"
            onClick={() =>
              setConfirmData({
                type: ConfirmTypes.removeAll
              })
            }
          >
            <div className="FlexRow NotifyStatusTableButtonsSvgItem Gap4">
              <RemoveIcon />
              <span className="ButtonTextFirst">{tableButtons.remove}</span>
            </div>
          </ButtonNotifi>
        )}
      </div>
      {confirmData && (
        <ConfirmModal
          hall={hall}
          baseUrl={baseUrl}
          confirmData={confirmData}
          haveActive={haveActive}
          refreshTable={refreshTable}
          close={() => setConfirmData(undefined)}
        />
      )}
    </div>
  )
}

export default NotifyStatusTable
