import betRequest from "api/request"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { IDevelopState } from "store/developer/types"
import { RouteComponentProps } from "react-router-dom"
import { IMenuState, IMenuData, MenuOptionsType } from "store/menu/types"
import { IDictionaryState } from "store/dictionary/types"

type GetDictionaryProps = {
  hall: string | CheckboxValueType
  logout: () => void
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  history: RouteComponentProps["history"]
  storeDictionary: (data: IDictionaryState) => void
  setPending: (data: boolean) => void
}

export const getDictionary = ({
  hall,
  developer,
  addDeveloperLink,
  history,
  logout,
  storeDictionary,
  setPending
}: GetDictionaryProps) => {
  setPending(true)
  return betRequest({
    url: `dictionary`,
    method: `POST`,
    developer,
    addDeveloperLink,
    history,
    logout,
    requestBody: {
      halls: [`${hall}`]
    }
  })
    .then(({ data }: { data: IDictionaryState }) => {
      betRequest({
        url: `dictionary/transfer/halls`,
        method: `POST`,
        developer,
        addDeveloperLink,
        history,
        logout
      }).then((resp: { data: number[] }) => {
        storeDictionary({ ...data, transfer: { halls: resp.data } })
      })
    })
    .catch(() => setPending(false))
}

type GetMenuProps = {
  hall: string | CheckboxValueType
  logout: () => void
  developer: IDevelopState
  addDeveloperLink: (data: IDevelopState) => void
  history: RouteComponentProps["history"]
  storeMenu: (data: IMenuState) => void
  setPending: (data: boolean) => void
}

const getMenu = ({
  hall,
  developer,
  addDeveloperLink,
  history,
  logout,
  storeMenu,
  setPending
}: GetMenuProps) => {
  setPending(true)
  return betRequest({
    url: `menu`,
    method: `POST`,
    developer,
    addDeveloperLink,
    history,
    logout,
    requestBody: {
      hall
    }
  })
    .then(({ data }: { data: IMenuData }) => {
      setPending(false)
      // TODO: use data
      const allMenuRoutes: (string | null)[] = []

      for (let i = 0; i < data.main.length; i++) {
        if (data.main[i] && data.main[i].route)
          allMenuRoutes.push(data.main[i].route)
      }

      for (let i = 0; i < data.bottom.length; i++) {
        if (data.bottom[i] && data.bottom[i].route)
          allMenuRoutes.push(data.bottom[i].route)
      }

      const mapMenuRoutes = (menuArg: MenuOptionsType[]): any => {
        for (let i = 0; i < menuArg.length; i++) {
          if (menuArg && menuArg[i] && menuArg[i].route) {
            allMenuRoutes.push(menuArg[i].route)
          }
          if (menuArg && menuArg[i] && !!menuArg[i].inside) {
            !!menuArg[i].inside !== null
              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mapMenuRoutes(menuArg[i].inside)
              : null
          }
        }
      }
      mapMenuRoutes(data.options)

      const dataForStore: IMenuState = {
        data: data,
        routes: allMenuRoutes
      }
      localStorage.setItem(`routes`, JSON.stringify(allMenuRoutes))
      storeMenu(dataForStore)
    })
    .catch(() => setPending(false))
}

export default getMenu
