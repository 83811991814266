import React from "react"
import noop from "lodash/noop"
import { OfficialSmsColumns } from "./types"
import { getActiveClasses } from "./helpers"
import { UseRequestBodySort } from "api/hooks"
import { TooltipPlacement } from "antd/lib/tooltip"

export const officialSmsConstants = {
  OFFICIAL_SMS_FILTER: `OFFICIAL_SMS_FILTER`,
  url: `v1/sms/service`,
  dateFormat: `YYYY-MM-DD`,
  mainTooltip: `Отчет предназначен для вывода информации о количестве служебных СМС, которых отправляются гостю. Служебные СМС - это СМС, содержащие в себе пин-коды при регистрации и других действиях с аккаунтом, выводе денежных средств, СМС об изменении баланса а также СМС с электронными чеками.`,
  title: `Служебные СМС`,
  nonActiveTitle: `Чтобы получить информацию по служебным СМС, выберите период отчёта и нажмите кнопку «Применить»`,
  nonActiveDescription: `За выбранный период будет представлена информация о количестве отправленных СМС по каждому дню`,
  scroll: {
    x: 800,
    y: "calc(100vh - 17rem)"
  },
  tooltipDirection: `bottom` as TooltipPlacement,
  clickEvent: `click`
}

export const getColumns = (sort: UseRequestBodySort) => {
  return [
    {
      title: `Дата`,
      dataIndex: OfficialSmsColumns.date,
      className: `${getActiveClasses(OfficialSmsColumns.date, sort)}`,
      key: OfficialSmsColumns.date,
      width: 104,
      minWidth: 104,
      render: (record: string | null) => <span>{record || `Итого`}</span>,
      sorter: () => noop,
      showSorterTooltip: false
    },
    {
      title: `СМС с кассы`,
      dataIndex: OfficialSmsColumns.cashboxCount,
      className: `${getActiveClasses(OfficialSmsColumns.cashboxCount, sort)}`,
      key: OfficialSmsColumns.cashboxCount,
      width: 104,
      minWidth: 104,
      sorter: () => noop,
      showSorterTooltip: false
    },
    {
      title: `Электронные чеки`,
      dataIndex: OfficialSmsColumns.checksCount,
      className: `${getActiveClasses(OfficialSmsColumns.checksCount, sort)}`,
      key: OfficialSmsColumns.checksCount,
      width: 104,
      minWidth: 104,
      sorter: () => noop,
      showSorterTooltip: false
    },
    {
      title: `Всего СМС`,
      dataIndex: OfficialSmsColumns.smsCount,
      className: `${getActiveClasses(OfficialSmsColumns.smsCount, sort)}`,
      key: OfficialSmsColumns.smsCount,
      width: 104,
      minWidth: 104,
      sorter: () => noop,
      showSorterTooltip: false
    }
  ]
}
